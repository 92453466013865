import { useMemo } from 'react'

import { RichTextValue } from 'features/views/attributes/types'
import { useTableViewContext } from 'features/views/ListView/TableView/useTableViewContext'
import { useTableViewRowContext } from 'features/views/ListView/TableView/useTableViewRowContext'

export function useTableViewThumbnailState() {
    const { thumbnailImageAspectRatio, thumbnailImageField, isFetchingSlow } = useTableViewContext()
    const { record } = useTableViewRowContext()

    const hasThumbnail = !!thumbnailImageField

    const fieldValue = hasThumbnail ? record?.[thumbnailImageField?.api_name] : undefined
    const imageSrc = hasThumbnail
        ? extractSrcFromFieldValue(thumbnailImageField, fieldValue)
        : undefined

    return useMemo(
        () => ({
            hasThumbnail,
            aspectRatio: thumbnailImageAspectRatio,
            imageSrc,
            isLoading: isFetchingSlow,
        }),
        [hasThumbnail, imageSrc, isFetchingSlow, thumbnailImageAspectRatio]
    )
}

function extractSrcFromFieldValue(field: FieldDto, value: unknown) {
    if (!value) return undefined
    if (['date', 'datetime', 'lookup', 'checkbox'].includes(field.type)) {
        return undefined
    }

    if (typeof value === 'string') {
        return value
    }

    // Extract the URL from the attachment value.
    if (field.type === 'multi_file') {
        if (Array.isArray(value)) {
            return value[0]?.url
        } else if (value && typeof value === 'object') {
            return (value as any).url
        }
    }

    if (field.type === 'document') {
        return (value as RichTextValue).plainTextContent
    }

    return undefined
}

import {
    DISCARD_EDITING_REQUEST,
    DISCARD_STOP_EDITING_REQUEST,
    EDITING_START_REQUESTED,
    EDITING_STARTED,
    EDITING_STOP_REQUESTED,
    EDITING_STOPPED,
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
} from 'data/utils/constants'

// STATE
const initialState = {
    entities: {},
    fetching: [],
    selected: null,
    isEditing: false,
}

// REDUCERS
export function userReducer(state = initialState, action) {
    let fetching
    switch (action.type) {
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }

        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }

        case EDITING_STARTED:
            return {
                ...state,
                isEditing: true,
                editingStartRequested: false,
                editingStopRequested: false,
            }
        case EDITING_STOP_REQUESTED:
            return {
                ...state,
                editingStopRequested: true,
            }
        case EDITING_START_REQUESTED:
            return {
                ...state,
                editingStartRequested: true,
            }
        case DISCARD_STOP_EDITING_REQUEST:
            return {
                ...state,
                editingStopRequested: false,
            }
        case EDITING_STOPPED:
            return {
                ...state,
                isEditing: false,
                editingStartRequested: false,
                editingStopRequested: false,
            }

        case DISCARD_EDITING_REQUEST:
            return {
                ...state,
                editingStartRequested: false,
                editingStopRequested: false,
            }
        case STACK_SELECTED:
            return { ...state, isEditing: false }
        default:
            return state
    }
}

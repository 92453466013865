import React from 'react'

import { WithViewsComponent } from 'data/wrappers/WithViewsComponent'

export function withViews(Child) {
    return (props) => (
        <WithViewsComponent>
            {(viewProps) => <Child {...viewProps} {...props} />}
        </WithViewsComponent>
    )
}

import React, { FC, useCallback, useState } from 'react'

import * as Sentry from '@sentry/react'

import { isStepVisible, useExecuteAction } from 'data/hooks/actions'
import { StepTypes } from 'data/hooks/actionTypes'
import { SaveGuardContext } from 'features/records/SaveGuardContext'
import { SaveGuardContextProvider } from 'features/records/SaveGuardContextProvider'

import {
    Button,
    Collapse,
    Divider,
    Flex,
    Heading,
    Hint,
    Modal,
    RichTextEditor,
    ScrollBox,
    Text,
    useCtrlKey,
} from 'v2/ui'
import { useModalToggle } from 'v2/ui/utils/useModalToggle'

import UpdateRecordStep, { Props as UpdateRecordStepProps } from './UpdateRecordStep'

export const USE_EXECUTE_ACTION_MODAL_KEY = 'ExecuteAction'

export const ExecuteActionModal: FC = () => {
    const { isOpen, toggle, data } = useModalToggle<{
        action: ActionDto
        includeFields: string[]
        recordId: string
    }>(USE_EXECUTE_ACTION_MODAL_KEY)

    const [validity, setValidity] = useState<{ [keyof: string]: boolean }>({})
    const [stepData, setStepData] = useState<
        { [keyof: string]: { fields: Partial<RecordDto> } } | undefined
    >()
    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const action = data?.action
    const actionId = action?._sid
    const recordId = data?.recordId
    const includeFields = data?.includeFields
    const executeAction = useExecuteAction(action?.object_id)
    const ctrlKey = useCtrlKey()

    const setValid = useCallback((key: string, value: boolean) => {
        setValidity((state) => ({
            ...state,
            [key]: value,
        }))
    }, [])

    const isValid = useCallback(() => {
        let isValid = true
        Object.keys(validity).map((key) => {
            isValid = isValid && validity[key]
        })

        return isValid
    }, [validity])

    const save = useCallback(async () => {
        const data = {
            record_id: recordId,
            action_id: actionId,
            include_fields: includeFields,
            steps: stepData,
        }

        if (!isValid()) {
            setError('Please fill out the required fields')
            return
        }

        setIsSaving(true)
        setError(null)
        try {
            await executeAction(data)
            setIsSaving(false)
            toggle()
        } catch (error) {
            Sentry.withScope((scope) => {
                scope.setExtra('action', actionId)
                scope.setLevel('error')
                Sentry.captureException(error)
            })

            setIsSaving(false)
            setError('An error occurred executing this action.')
        }
    }, [actionId, executeAction, includeFields, isValid, recordId, stepData, toggle])

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                save()
            }
        },
        [save]
    )

    const updateStepData = useCallback((id: string, data: { fields: Partial<RecordDto> }) => {
        setError(null)
        setStepData((state) => ({ ...state, [id]: data }))
    }, [])

    const reset = useCallback(() => {
        setStepData(undefined)
        setValidity({})
        setError(null)
    }, [])

    const onModalClose = useCallback(() => {
        reset()
        toggle()
    }, [reset, toggle])

    if (!action) return null

    const { title, description, steps = [] } = action.options

    const hasVisibleActions = Boolean(steps.find(isStepVisible))
    if (!isOpen) return null

    return (
        <Modal isOpen onClose={onModalClose} title={title || action.name} size="600">
            <SaveGuardContextProvider>
                <SaveGuardContext.Consumer>
                    {({ canSave }) => (
                        <ScrollBox
                            maxHeight={['90%', null, null, 600]}
                            margin="-5px"
                            padding="5px"
                            onKeyDown={handleKeyDown}
                        >
                            <Collapse
                                isOpen={true}
                                isOpenDelay={0.05}
                                maxHeight={['90%', null, null, 600]}
                            >
                                <Flex column align="stretch">
                                    {description && (
                                        <>
                                            <RichTextEditor>{description}</RichTextEditor>
                                            <Divider mt={4} />
                                        </>
                                    )}
                                </Flex>
                                <ActionSteps
                                    action={action}
                                    recordId={recordId}
                                    updateStepData={updateStepData}
                                    setValid={setValid}
                                    showErrors={!!error}
                                />
                                {hasVisibleActions && <Divider mt={4} />}
                                <Flex mt={4}>
                                    <Text
                                        variant="error"
                                        flexGrow={1}
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                        whiteSpace="nowrap"
                                    >
                                        {error}
                                    </Text>
                                    <Button variant="moderateSm" onClick={toggle} mr={1}>
                                        Cancel
                                    </Button>
                                    {/* @ts-expect-error */}
                                    <Hint
                                        label={`you can press ${ctrlKey} + enter to save`}
                                        placement="bottom"
                                        desktopOnly
                                    >
                                        <Button
                                            buttonSize="sm"
                                            icon="checkmark"
                                            isLoading={isSaving}
                                            disabled={!canSave || isSaving}
                                            onClick={save}
                                        >
                                            Save
                                        </Button>
                                    </Hint>
                                </Flex>
                            </Collapse>
                        </ScrollBox>
                    )}
                </SaveGuardContext.Consumer>
            </SaveGuardContextProvider>
        </Modal>
    )
}

const StepWrapper: FC<UpdateRecordStepProps> = (props) => {
    const { title, description, type } = props.step
    const Component = StepComponents[type]

    return (
        <Flex column align="stretch" mt={4}>
            {title && (
                <Heading as="h2" value={title} variant="actionStepTitle" mb={description ? 0 : 4} />
            )}
            {description && (
                <Text variant="actionStepDescription">
                    <RichTextEditor>{description}</RichTextEditor>
                </Text>
            )}
            <Component {...props} />
        </Flex>
    )
}

const ActionSteps: FC<Omit<UpdateRecordStepProps, 'step'> & { action: ActionDto }> = ({
    action,
    ...props
}) => (
    <>
        {action?.options?.steps.map((step, index) => {
            if (step.type === StepTypes.AutomationTrigger) {
                return null
            } else {
                return <StepWrapper key={index} step={step} {...props} />
            }
        })}
    </>
)

const StepComponents = {
    [StepTypes.UpdateRecord]: UpdateRecordStep,
}

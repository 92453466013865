import React from 'react'

import type { ContextMenuItemProps as BaseProps } from '@radix-ui/react-context-menu'

import { IconName } from 'ui/components/Icon/Icon'
import { StandardComponentProps } from 'ui/helpers/styles'

import { Item, LeftSlot, MenuIcon, RightSlot } from './ContextMenu.parts'

type ContextMenuItemProps = Omit<BaseProps, 'asChild'> &
    StandardComponentProps & {
        icon?: IconName
        rightSlotContent?: React.ReactNode
    }

export const ContextMenuItem: React.FC<ContextMenuItemProps> = ({
    icon,
    rightSlotContent,
    children,
    ...props
}) => {
    return (
        <Item {...props}>
            {icon && (
                <LeftSlot>
                    <MenuIcon name={icon} />
                </LeftSlot>
            )}
            {children}
            {rightSlotContent && <RightSlot>{rightSlotContent}</RightSlot>}
        </Item>
    )
}

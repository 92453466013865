import React, { FC, useEffect, useMemo, useState } from 'react'
import { Prompt, useHistory } from 'react-router-dom'

import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Flex, Modal, Text } from 'v2/ui'
import { SolidAlert } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const UnsavedChangesModal: FC = () => {
    const [location, setLocation] = useState<Location | null>(null)

    const history = useHistory()

    const {
        state,
        close,
        openBlockedPane,
        openUnsavedChangesModal,
        closeUnsavedChangesModal,
        clearBlockedContent,
        clearOnContinueCallback,
    } = useSlidingPane()

    const blockNavigation = useMemo(
        () => (state.key ?? false) && state.isDirty,
        [state.key, state.isDirty]
    )

    const onNavigateInternally = (nextLocation: Location) => {
        // In case the current pathname and the next one are identical, do not open the modal
        // It is triggered internally by pushing a new state in the history to refresh some data
        // We still want to accept the incoming changes in the history
        if (nextLocation.pathname === history.location.pathname) {
            return true
        }

        openUnsavedChangesModal()
        setLocation(nextLocation)
        return false
    }

    const onContinueWithoutSaving = () => {
        closeUnsavedChangesModal()
        setLocation(null)

        if (state.onContinueWithoutSaving) {
            state.onContinueWithoutSaving()
            clearOnContinueCallback()
            return
        }

        if (state.blockedContent) {
            openBlockedPane()
            return
        }

        close(true)
    }

    const onCancel = () => {
        setLocation(null)
        clearBlockedContent()
        clearOnContinueCallback()
        closeUnsavedChangesModal()
    }

    useEffect(() => {
        const onBeforeUnload = (event: BeforeUnloadEvent) => {
            if (!blockNavigation) {
                return
            }

            event.preventDefault()
            event.returnValue = ''
        }

        window.addEventListener('beforeunload', onBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload)
        }
    }, [blockNavigation])

    useEffect(() => {
        if (state.isDirty || state.isUnsavedChangesModalOpen) {
            return
        }

        // if a previous location is stored, an internal navigation was previously blocked
        // now, proceed to the navigation
        if (location) {
            close(true)
            history.push(location)
            setLocation(null)
            return
        }

        if (state.blockedContent) {
            openBlockedPane()
        }
    }, [
        state.isDirty,
        state.isUnsavedChangesModalOpen,
        state.blockedContent,
        location,
        openBlockedPane,
        close,
        history,
    ])

    return (
        <>
            {/*@ts-expect-error*/}
            <Prompt when={blockNavigation} message={onNavigateInternally} />
            <Modal
                isOpen={state.isUnsavedChangesModalOpen}
                showCloseButton={false}
                size="330px"
                body={
                    <Flex direction="column">
                        <SolidAlert />
                        <Text
                            color={colors.userInterface.neutral[1000]}
                            fontSize="18px"
                            marginTop="16px"
                            marginBottom="20px"
                        >
                            You have unsaved changes
                        </Text>
                        <Text color={colors.userInterface.neutral[800]} fontSize="14px">
                            Are you sure you want to exit without saving?
                        </Text>
                    </Flex>
                }
                actions={[
                    {
                        label: 'Stay on this page',
                        variant: 'Primary',
                        buttonSize: 'md',
                        onClick: onCancel,
                    },
                    {
                        label: 'Exit without saving',
                        buttonSize: 'md',
                        variant: 'Secondary',
                        onClick: onContinueWithoutSaving,
                    },
                ]}
            />
        </>
    )
}

export default UnsavedChangesModal

import React, { ComponentProps } from 'react'

import { Button, ButtonProps } from 'ui/components/Button/Button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Icon } from 'ui/components/Icon'
import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { Link } from 'ui/components/Link'

import * as Parts from './Banner.parts'

import { ButtonStyle, LinkStyle } from './Banner.css'

type IconProps = IconNameVariantType

type LinkProps = {
    href?: string
    to?: string
    label: string
    target?: string
}

type BannerProps = ComponentProps<typeof Parts.Wrapper> & {
    title?: string
    helper?: string
    icon?: IconProps
    link?: LinkProps
    button?: ButtonProps
    dismissable?: boolean
}

export const Banner: React.FC<BannerProps> = ({
    title,
    helper,
    icon,
    button,
    link,
    type,
    dismissable,
    ...props
}) => {
    return (
        <Collapsible asChild defaultOpen={true} open={props.show}>
            <CollapsibleContent asChild animation="fade">
                <Parts.Wrapper type={type} {...props}>
                    <Parts.MainContainer>
                        {icon && (
                            <Parts.IconContainer type={type}>
                                <Icon size="2xl" {...icon} />
                            </Parts.IconContainer>
                        )}
                        <Parts.ContentContainer>
                            {title && (
                                <Parts.Title role="heading" aria-level={3}>
                                    {title}
                                </Parts.Title>
                            )}
                            {helper && <Parts.Helper>{helper}</Parts.Helper>}
                            {/* Replace with the new Link Component once available */}
                            {link && (
                                <div>
                                    <Link
                                        size="s"
                                        target={link.target}
                                        to={link.to}
                                        href={link.href}
                                        className={LinkStyle}
                                    >
                                        {link.label}
                                    </Link>
                                </div>
                            )}
                        </Parts.ContentContainer>
                        <Parts.ActionsContainer>
                            {button && (
                                <Button
                                    size="xs"
                                    className={ButtonStyle}
                                    variant="secondary"
                                    {...button}
                                />
                            )}
                            {dismissable && (
                                <CollapsibleTrigger asChild>
                                    <Button
                                        size="xs"
                                        type="button"
                                        variant="ghost"
                                        aria-label="Dismiss"
                                        startIcon={{ name: 'X' }}
                                        aria-expanded={false}
                                    />
                                </CollapsibleTrigger>
                            )}
                        </Parts.ActionsContainer>
                    </Parts.MainContainer>
                </Parts.Wrapper>
            </CollapsibleContent>
        </Collapsible>
    )
}

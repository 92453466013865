import { useCallback } from 'react'

import { throttle } from 'lodash'

export function useGrabToResizeElement<T extends Element>(
    element: T | null,
    onResize?: (height: number) => void
) {
    const handleMouseMove = useCallback(
        (startY: number, startHeight: number) => {
            return throttle((moveEvent: MouseEvent) => {
                const deltaY = moveEvent.clientY - startY
                const newHeight = startHeight + deltaY
                if (newHeight > 0) {
                    // Prevent negative height.
                    onResize?.(newHeight)
                }
            }, 10) // Throttle rate of 10ms
        },
        [onResize]
    )

    const handleMouseDown = useCallback(
        (e: React.MouseEvent<unknown>) => {
            e.preventDefault()

            const startY = e.clientY
            const startHeight = element?.clientHeight ?? 0
            const throttledMouseMove = handleMouseMove(startY, startHeight)
            const mouseMoveHandler = (moveEvent: MouseEvent) => throttledMouseMove(moveEvent)

            const handleMouseUp = () => {
                document.removeEventListener('mousemove', mouseMoveHandler)
                document.removeEventListener('mouseup', handleMouseUp)
            }

            document.addEventListener('mousemove', mouseMoveHandler)
            document.addEventListener('mouseup', handleMouseUp)
        },
        [element?.clientHeight, handleMouseMove]
    )

    return {
        handleMouseDown,
    }
}

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeSpinnerVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            default: s.color.icon,
            inverse: s.color.borderInverse,
        },
        size: {
            '3xs': pxToRem(12),
            '2xs': pxToRem(14),
            xs: pxToRem(16),
            s: pxToRem(18),
            m: pxToRem(20),
            l: pxToRem(24),
            xl: pxToRem(32),
            '2xl': pxToRem(40),
            '3xl': pxToRem(64),
        },
    }
}

import React, { useMemo } from 'react'
import ReactFocusLock from 'react-focus-lock'

import { Placement } from '@popperjs/core'

import { TaskPayload } from 'data/hooks/tasks/types'

import Popper from 'v2/ui/components/Popper'
import { autoSizeModifier } from 'v2/ui/components/popperModifiers/autoSizeModifier'
import { matchWidthModifier } from 'v2/ui/components/popperModifiers/matchWidthModifier'
import { overlayModifier } from 'v2/ui/components/popperModifiers/overlayModifier'

import { TasksEditMode } from './TasksEditMode'

type TasksEditModePopoverProps = React.ComponentPropsWithoutRef<typeof TasksEditMode> & {
    task: TaskPayload
    placement?: Placement
    open?: boolean
    targetElement?: HTMLElement
    container?: HTMLElement
    onClose?: () => void
    isVirtualElement?: boolean
    overlayOnParent?: boolean
    matchParentWidth?: boolean
}

export const TasksEditModePopover: React.FC<TasksEditModePopoverProps> = ({
    task,
    placement = 'bottom',
    open,
    targetElement,
    container,
    onClose,
    isVirtualElement,
    overlayOnParent = true,
    matchParentWidth = true,
    ...props
}) => {
    const rootNode = useMemo(() => {
        for (const value of Array.from(document.body.childNodes.values())) {
            if (
                (container && value.contains(container)) ||
                (!isVirtualElement && targetElement && value.contains(targetElement))
            ) {
                return value
            }
        }

        return
    }, [container, isVirtualElement, targetElement])

    if (!open || !targetElement) return null

    return (
        <Popper
            placement={placement}
            referenceElement={targetElement}
            onClose={onClose}
            limitOuterActionsToDescendentsOf={rootNode}
            modifiers={[
                autoSizeModifier,
                ...(overlayOnParent ? [overlayModifier] : []),
                ...(matchParentWidth ? [matchWidthModifier] : []),
                { name: 'flip', enabled: false },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: true,
                        mainAxis: true,
                        tether: false,
                        padding: {
                            top: 48,
                            bottom: -16,
                        },
                    },
                },
            ]}
            zIndex={1252}
        >
            <ReactFocusLock>
                <TasksEditMode
                    popover
                    border
                    task={task}
                    onSave={onClose}
                    onClose={onClose}
                    {...props}
                />
            </ReactFocusLock>
        </Popper>
    )
}

import React from 'react'

import { EditableBlockWrapperProps } from 'features/admin/edit-mode/Common/EditableBlockWrapper'

/**
 * Provides a wrapper for a layout block which can be edited.
 * Handles showing the dashed edit border, can be extended to show
 * edit toolbar, etc.
 *
 **/

type ListRecordWrapperProps = Omit<EditableBlockWrapperProps, 'children'> & {
    recordId: string
    children:
        | React.ReactNode
        | ((args: { isActive?: boolean; lastHoveredRecordId?: string }) => React.ReactNode)
}
export const ListRecordWrapper: React.FC<ListRecordWrapperProps> = ({ children }) => {
    return <>{typeof children === 'function' ? children({}) : children}</>
}

import { TableCell } from '@tiptap/extension-table-cell'

import { TableCellStyle } from './TableExtension.css'

export function createTableCellExtension() {
    return TableCell.configure({
        HTMLAttributes: {
            class: TableCellStyle,
        },
    })
}

import { useMemo } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/useTableViewContext'

export function useTableViewCalculationsState() {
    const { columns, isRecordActionsColumnPinned } = useTableViewContext()

    const shouldRender = columns.some((column) => !!column.calculation)

    return useMemo(
        () => ({
            columns,
            isRecordActionsColumnPinned,
            shouldRender,
        }),
        [columns, isRecordActionsColumnPinned, shouldRender]
    )
}

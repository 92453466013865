// @ts-strict-ignore
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { TabList, TabPanels } from '@chakra-ui/react'
import styled from '@emotion/styled'
import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'
import AdvancedOptions from 'v2/views/List/AdvancedOptions'
import MenuToggleDrill from 'v2/views/MenuToggle'

import { getUrl, Urls } from 'app/UrlService'
import useLDFlags from 'data/hooks/useLDFlags'
import CoverImage from 'features/pages/blocks/settings/attributes/items/form/CoverImage'
import { ObjectOrderAttribute } from 'features/pages/blocks/settings/attributes/items/form/objectOrder'
import { RolesSelect } from 'features/pages/blocks/settings/attributes/items/form/RolesSelect'
import { ObjectFieldsFilterV4 as Filters } from 'features/records/components/RecordFilters'
import { getNumOfFilters } from 'features/records/utils/userSpecificFilters'
import MenuToggle from 'features/views/List/MenuToggle'
import { formatCounterValue } from 'features/views/List/utils/formatCounterValue'
import { ListViewHeaderEditor } from 'features/views/ViewHeader/ListViewHeaderEditor'
import useTrack from 'utils/useTrack'

import { Box, Divider, Dropdown, Flex, Icon, Input, Tab, TabPanel, Tabs, Text } from 'v2/ui'
import FieldSelector from 'v2/ui/components/List/FieldSelector'
import {
    Header as HeaderIcon,
    IconSolidLayout,
    SolidFilter,
    SolidFilterInline,
    SolidImage,
    SolidLightbulb,
    SolidLock,
    SolidSort,
} from 'v2/ui/svgs'

import { Checkbox } from 'ui/components/Checkbox'

import { DISPLAY_TYPES, displayType } from './DisplayTypes'
import DownloadOption from './DownloadOption'
import InlineFiltersControls from './InlineFiltersControls'
import { isFieldEditorEnabledForDisplay } from './isFieldEditorEnabledForDisplay'
import ListViewDisplaySettings from './ListViewDisplaySettings'
import ListViewMenuToggleTrigger from './ListViewMenuToggleTrigger'

type ListViewControlsProps = {
    object: ObjectDto
    columnConfig?: ListViewColumnConfig[]
    config?: ListViewOptions
    setConfig: (config: Partial<ListViewOptions>) => void
    detailView?: any
    records?: RecordDto[]
    pageRoles?: string[]
    setPageRoles?: (roles: string[]) => void
    shouldShowRoles?: boolean
    showCreate?: boolean
    showPageSizeOptions?: boolean
    onDisplayChange?: (displayType: string) => void
    hideHeader?: boolean
    showTitle?: boolean
    additionalToggles?: React.ReactNode
    additionalAppearanceToggles?: React.ReactNode
    additionalFilteringToggles?: React.ReactNode
    displayTypes?: Partial<Record<ListViewDisplay, displayType>>
    hideActions?: boolean
    hideFieldsEditor?: boolean
    getPageSizeOptions?: (display?: string) => number[]
    getDefaultPageSize?: (display?: string) => number
    customFieldsEditor?: React.ReactElement
    customActionsEditor?: React.ReactElement
    animateFieldsEditor?: boolean
    animateActionButtonsSelector?: boolean
    isBlankPage?: boolean
    hideCoverImageOptions?: boolean
    hideEndUserFiltersOptions?: boolean
    hideAdvancedOptions?: boolean
}

const ListViewControls = ({
    object,
    columnConfig,
    config = {} as ListViewOptions,
    setConfig,
    detailView,
    records = [],
    pageRoles,
    setPageRoles,
    shouldShowRoles = true,
    showCreate = false,
    showPageSizeOptions = false,
    onDisplayChange,
    hideHeader = false,
    showTitle,
    additionalToggles,
    additionalAppearanceToggles,
    additionalFilteringToggles,
    displayTypes = DISPLAY_TYPES,
    hideActions = false,
    hideFieldsEditor = false,
    getPageSizeOptions,
    getDefaultPageSize,
    customFieldsEditor,
    customActionsEditor,
    animateFieldsEditor = true,
    animateActionButtonsSelector = true,
    isBlankPage,
    hideCoverImageOptions = false,
    hideEndUserFiltersOptions = false,
    hideAdvancedOptions = false,
}: ListViewControlsProps) => {
    const { track } = useTrack()
    let detailViewLink
    if (detailView) {
        detailViewLink = `${getUrl(Urls.AdminSetup)}${object?.url}/${detailView._sid}`
        if (records.length && object?.url) {
            detailViewLink = `${getUrl(object?.url)}/view/${records[0]._sid}`
        }
    }

    let createViewLink
    if (object?.url) createViewLink = `${getUrl(object?.url)}/new`

    const createFormDisplayed = showCreate && records.length == 0 && detailView

    // const tableSupportsSearchBar = supportsSearchBar(config)

    const handleColumnsChanged = (cs) => {
        setConfig({ columns: cs })
    }

    const handleFieldListChanged = () => {
        track('WIP - Frontend - List View - Manage fields - Saved')
    }
    const handleFieldCreated = async (field) => {
        track('WIP - Frontend - List View - Add field - Saved', { field })
    }

    const handleFieldConfigChanged = (changes) => {
        track('WIP - Frontend - List view field options - Saved', { changes })
    }

    const handleShowAllFieldsModeChanged = (showAllFields) => {
        setConfig({ showAllFields })
    }

    const fields = useMemo(
        () => object.fields.filter((field) => !field.connection_options.is_disabled),
        [object.fields]
    )

    const numOfFilters = config.filters?.length ?? 0
    const numOfUserSpecificFilters = useMemo(
        () => getNumOfFilters(config, fields),
        [config, fields]
    )

    const displaySupportsFieldEditor = useMemo(
        () => isFieldEditorEnabledForDisplay(config.display),
        [config.display]
    )

    const { flags } = useLDFlags()
    const effectiveDisplayTypes: typeof DISPLAY_TYPES = useMemo(() => {
        const providedDisplayTypes = displayTypes || DISPLAY_TYPES

        let effectiveDisplayTypes = providedDisplayTypes as typeof DISPLAY_TYPES

        if (flags.legacyTableView && providedDisplayTypes.table) {
            effectiveDisplayTypes = {
                ...providedDisplayTypes,
                table: {
                    ...providedDisplayTypes.table,
                    title: isBlankPage ? 'Table' : 'Legacy Table',
                    isReleased: true,
                },
            } as typeof DISPLAY_TYPES
        }

        if (flags.legacyBoardView && providedDisplayTypes.kanban) {
            effectiveDisplayTypes = {
                ...effectiveDisplayTypes,
                kanban: {
                    ...providedDisplayTypes.kanban,
                    title: isBlankPage ? 'Board' : 'Legacy Board',
                    isReleased: true,
                },
            } as typeof DISPLAY_TYPES
        }

        if (flags.legacyCardView && providedDisplayTypes.cardV2) {
            effectiveDisplayTypes = {
                ...effectiveDisplayTypes,
                card: {
                    ...providedDisplayTypes.card,
                    title: isBlankPage ? 'Cards' : 'Legacy Cards',
                    isReleased: true,
                },
            } as typeof DISPLAY_TYPES
        }

        return effectiveDisplayTypes
    }, [
        displayTypes,
        flags.legacyBoardView,
        flags.legacyCardView,
        flags.legacyTableView,
        isBlankPage,
    ])

    if (!object) return null

    const shouldHideTabs = !hideFieldsEditor || !hideActions

    const onChangeAllowDownload = (allowDownload: boolean) => {
        track(`Frontend - List View - Allow export CSV - ${allowDownload ? 'On' : 'Off'}`, {
            table_name: object.name,
        })

        setConfig({ allowDownload })
    }

    return (
        <>
            {showTitle && (
                <Box mb={4}>
                    <Text variant="paletteSectionLabel">Title</Text>
                    <Input
                        variant="admin"
                        value={config.title}
                        onChange={(e) => {
                            const value = e.target.value
                            setConfig({
                                title: value,
                            })
                        }}
                    />
                </Box>
            )}
            <Flex direction="row" alignItems="center" justifyContent="flex-start" mt={2}>
                <Icon icon="palette" mr={2} />
                <Text variant="adminFieldLabel">
                    Appearance{shouldShowRoles ? ' & Visibility' : ''}
                </Text>
            </Flex>
            {config.display !== 'inbox' && !hideHeader && (
                <MenuToggleDrill
                    svg={<HeaderIcon />}
                    label="Header"
                    title="Header"
                    buttonStyle={{ paddingLeft: '8px', paddingRight: '8px' }}
                    style={{ marginLeft: '-8px', marginRight: '-8px' }}
                >
                    <ListViewHeaderEditor listOptions={config} onChange={setConfig} />
                </MenuToggleDrill>
            )}

            {!hideCoverImageOptions && (
                <HoverableMenuToggle
                    customToggleComponent={(props) => (
                        <ListViewMenuToggleTrigger
                            svg={<SolidImage />}
                            label="Cover image"
                            {...props}
                        />
                    )}
                    withEventTracking={true}
                >
                    <div>
                        <CoverImage
                            showImageDisplayOptions
                            objectId={object._sid}
                            onChange={(coverImage) =>
                                setConfig({
                                    coverImage,
                                })
                            }
                            onChangeFit={(fitCoverImage) =>
                                setConfig({
                                    fitCoverImage,
                                })
                            }
                            value={config.coverImage}
                            fitImage={config.fitCoverImage}
                        />
                    </div>
                </HoverableMenuToggle>
            )}

            <HoverableMenuToggle
                customToggleComponent={(props) => (
                    <ListViewMenuToggleTrigger
                        svg={<IconSolidLayout />}
                        label="Display"
                        {...props}
                    />
                )}
                withEventTracking={true}
            >
                <ListViewDisplaySettings
                    object={object}
                    displayTypes={effectiveDisplayTypes}
                    config={config}
                    setConfig={setConfig}
                    getDefaultPageSize={getDefaultPageSize}
                    getPageSizeOptions={getPageSizeOptions}
                    onDisplayChange={onDisplayChange}
                    showPageSizeOptions={showPageSizeOptions}
                />
            </HoverableMenuToggle>

            {shouldShowRoles && setPageRoles && (
                <HoverableMenuToggle
                    customToggleComponent={(props) => (
                        <ListViewMenuToggleTrigger svg={<SolidLock />} label="Roles" {...props} />
                    )}
                    noBackground
                    withEventTracking={true}
                    contentProps={{
                        border: 0,
                        p: 0,
                    }}
                >
                    <Box position="absolute" right={0} minWidth="400px">
                        <RolesSelect
                            onChange={(pageRoles) => {
                                setPageRoles(pageRoles)
                            }}
                            value={pageRoles}
                        />
                    </Box>
                </HoverableMenuToggle>
            )}
            {additionalAppearanceToggles}
            <Flex direction="row" alignItems="center" justifyContent="flex-start" mt={2}>
                <Icon svg={<SolidLightbulb />} mr={2} />
                <Text variant="adminFieldLabel">Filtering & Sorting</Text>
            </Flex>
            <HoverableMenuToggle
                toggleMenuCursor="default"
                counter={numOfFilters}
                withEventTracking={true}
                customToggleComponent={(props) => (
                    <ListViewMenuToggleTrigger
                        svg={<SolidFilter />}
                        label={
                            'Filter' +
                            (numOfFilters > 0 ? ` (${formatCounterValue(numOfFilters)})` : '')
                        }
                        {...props}
                    />
                )}
            >
                <div style={{ minWidth: 400, maxWidth: 400 }}>
                    <Filters
                        value={config.filters}
                        object={object}
                        fields={fields}
                        onChange={(f) =>
                            setConfig({
                                filters: f,
                            })
                        }
                        place="bottom-end"
                        securityInfoMessage="Filters let you change which records are displayed in this list."
                        showRelativeDateFilters
                    />
                </div>
            </HoverableMenuToggle>

            {!hideEndUserFiltersOptions && (
                <HoverableMenuToggle
                    customToggleComponent={(props) => (
                        <ListViewMenuToggleTrigger
                            svg={<SolidFilterInline />}
                            label={
                                'Inline filters' +
                                (config.enableSpecificEndUserFilters && numOfUserSpecificFilters > 0
                                    ? ` (${formatCounterValue(numOfUserSpecificFilters)})`
                                    : '')
                            }
                            {...props}
                        />
                    )}
                    withEventTracking={true}
                >
                    <InlineFiltersControls
                        config={config}
                        setConfig={setConfig}
                        fields={fields}
                        object={object}
                    />
                </HoverableMenuToggle>
            )}

            <HoverableMenuToggle
                customToggleComponent={(props) => (
                    <ListViewMenuToggleTrigger svg={<SolidSort />} label="Sort" {...props} />
                )}
                withEventTracking={true}
            >
                <div>
                    {config.display === 'kanban' || config.display === 'boardV2' ? (
                        <>
                            <ObjectOrderAttribute
                                removeDisabledFields
                                objectId={object._sid}
                                onChange={(order) =>
                                    setConfig({
                                        order,
                                    })
                                }
                                value={config.order}
                                context={undefined}
                            />
                            <Checkbox
                                mt="m"
                                checked={config.orderType === 'manual'}
                                onCheckedChange={(value) =>
                                    setConfig({ orderType: value ? 'manual' : 'field' })
                                }
                            >
                                Let users rearrange items
                            </Checkbox>
                        </>
                    ) : (
                        <ObjectOrderAttribute
                            removeDisabledFields
                            objectId={object._sid}
                            onChange={(order) =>
                                setConfig({
                                    order,
                                })
                            }
                            value={config.order}
                            context={undefined}
                        />
                    )}
                </div>
            </HoverableMenuToggle>

            {additionalFilteringToggles}

            {!hideAdvancedOptions &&
                config.display !== 'calendar' &&
                config.display !== 'single_record' &&
                (additionalToggles || !isBlankPage) && (
                    <>
                        <Divider my={4} />
                        <AdvancedOptions panelStyle={{ marginTop: '4px' }}>
                            {additionalToggles}
                            {!isBlankPage && (
                                <>
                                    {/* {tableSupportsSearchBar && (
                                        <HideSearchBar
                                            onChange={(value) =>
                                                setConfig({ hide_search_bar: value })
                                            }
                                            hideSearchBar={config.hide_search_bar}
                                            mb={2}
                                        />
                                    )} */}
                                    <DownloadOption
                                        value={config.allowDownload}
                                        onChange={onChangeAllowDownload}
                                    />
                                </>
                            )}
                        </AdvancedOptions>
                    </>
                )}

            {shouldHideTabs && !detailView && displaySupportsFieldEditor && (
                <>
                    <Divider my={4} />
                    <Tabs
                        variant="admin"
                        d="flex"
                        flexDirection="column"
                        minHeight="310px"
                        height={animateFieldsEditor ? '100%' : undefined}
                    >
                        <TabList>
                            {!hideFieldsEditor && (
                                <Tab variant="admin" style={{ width: '50%' }}>
                                    Content
                                </Tab>
                            )}

                            {!hideActions && (
                                <Tab variant="admin" style={{ width: '50%' }}>
                                    Actions
                                </Tab>
                            )}
                        </TabList>

                        <TabPanels
                            mt={4}
                            height={animateFieldsEditor ? '100%' : undefined}
                            minHeight={0}
                        >
                            {!hideFieldsEditor && (
                                <TabPanel height={animateFieldsEditor ? '100%' : undefined}>
                                    {customFieldsEditor ? (
                                        customFieldsEditor
                                    ) : (
                                        <FieldSelector
                                            display={config.display ? config.display : 'table'}
                                            objectId={object._sid}
                                            config={columnConfig}
                                            onChange={handleColumnsChanged}
                                            editorProps={{
                                                onFieldListChanged: handleFieldListChanged,
                                                onFieldCreated: handleFieldCreated,
                                                onFieldConfigChanged: handleFieldConfigChanged,
                                                showAllFields: config.showAllFields,
                                                onShowAllFieldsModeChange:
                                                    handleShowAllFieldsModeChanged,
                                            }}
                                            animate={animateFieldsEditor}
                                        />
                                    )}
                                </TabPanel>
                            )}
                            {!hideActions && (
                                <TabPanel height={animateFieldsEditor ? '100%' : undefined}>
                                    {customActionsEditor ? (
                                        customActionsEditor
                                    ) : (
                                        <>
                                            <div style={{ marginBottom: 5, marginTop: '6px' }}>
                                                Button Style
                                            </div>
                                            <Dropdown
                                                isClearable={false}
                                                isSearchable={false}
                                                value={config.actionButtonsStyle || '1pa'}
                                                options={[
                                                    { label: '1 primary action', value: '1pa' },
                                                    { label: 'Primary actions', value: 'primary' },
                                                    {
                                                        label: 'Secondary actions',
                                                        value: 'secondary',
                                                    },
                                                ]}
                                                onChange={(
                                                    buttonsStyle: ListViewActionButtonStyle
                                                ) =>
                                                    setConfig({ actionButtonsStyle: buttonsStyle })
                                                }
                                            />
                                            <div style={{ height: 20 }} />
                                            <ActionButtonsSelector
                                                object={object}
                                                selectedButtons={config.actionButtons || []}
                                                setConfig={({
                                                    pageButtons,
                                                }: {
                                                    pageButtons: ActionButton[]
                                                }) => setConfig({ actionButtons: pageButtons })}
                                                additionalActions={[]}
                                                animate={animateActionButtonsSelector}
                                            />
                                        </>
                                    )}
                                </TabPanel>
                            )}
                        </TabPanels>
                    </Tabs>
                </>
            )}
            {!hideFieldsEditor &&
                !detailView &&
                (config.display === 'inbox' || config.display === 'kanban') && (
                    <FieldSelector
                        display={config.display ? config.display : 'table'}
                        objectId={object._sid}
                        config={columnConfig}
                        onChange={handleColumnsChanged}
                        editorProps={{
                            onFieldListChanged: handleFieldListChanged,
                            onFieldCreated: handleFieldCreated,
                            onFieldConfigChanged: handleFieldConfigChanged,
                            showAllFields: config.showAllFields,
                            onShowAllFieldsModeChange: handleShowAllFieldsModeChanged,
                        }}
                        animate={animateFieldsEditor}
                    />
                )}
            {detailView && !createFormDisplayed && (
                <Box>
                    {records.length > 0 ? (
                        <Text mt={3}>
                            Displaying the detail page for the first record found.
                            <Link to={detailViewLink}> Edit the layout</Link>
                        </Text>
                    ) : (
                        <Text mt={3}>You can only edit the layout if a record exists.</Text>
                    )}
                </Box>
            )}

            {createFormDisplayed && (
                <Box>
                    <Text mt={3}>
                        Displaying the create form as there are no visible records.
                        {createViewLink && <Link to={createViewLink}> Edit the layout</Link>}
                    </Text>
                </Box>
            )}
        </>
    )
}

export default ListViewControls

const HoverableMenuToggle = styled(MenuToggle)`
    margin-left: -8px;
    margin-right: -8px;
`

import React from 'react'

import { getUrl } from 'app/UrlService'
import { useObject } from 'data/hooks/objects'
import { useStacks } from 'data/hooks/stacks'

import { LinkButton } from 'ui/components/LinkButton'

type Props = React.ComponentPropsWithoutRef<typeof LinkButton> & {
    record: Partial<RecordDto>
    stackId?: string
}

export function RecordLink({ record, stackId, ...props }: Props) {
    const { data: stacks } = useStacks()
    const stack = stacks?.find((stack) => stack._sid === stackId)

    const { object } = useObject(record._object_id)

    if ((stackId && !stack) || !object) return null
    let url = getUrl(`${object.url}/view/${record?._sid}`, stack)
    return (
        <LinkButton
            to={url}
            variant="link"
            size="2xs"
            title={record._primary?.toString()}
            startIcon={{ name: 'FileText' }}
            {...props}
        >
            {record._primary}
        </LinkButton>
    )
}

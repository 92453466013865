import React from 'react'

import styled from '@emotion/styled'

import { Icon as V2Icon } from 'v2/ui'
import { Airtable } from 'v2/ui/svgs'

import { Box } from 'ui/components/Box'
import { Collapsible, CollapsibleContent } from 'ui/components/Collapsible'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { ConnectionDetailsTrigger } from './ConnectionDetailsTrigger'
import { DataConnectionGroupOptions } from './DataConnectionGroupOptions'
import { DataConnectionSection } from './DataConnectionSection'

export const DC_TYPE_TO_INTEGRATION_NAME = {
    nango_airtable: 'Airtable',
}

const ConnectionHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    color: inherit;
    width: 100%;
    padding-top: ${theme.space.xs};
    padding-bottom: ${theme.space.xs};
    border-style: ${theme.borderStyles.base};
    border-width: 0;
    border-bottom-width: ${theme.borderWidths[1]};
    border-bottom-color: ${theme.color.borderWeak};

    &:hover {
        background-color: ${theme.color.surfaceStrong};
    }
`
const ConnectionRowContainer = styled(ConnectionHeaderContainer)<{ isOpen: boolean }>`
    padding-top: ${theme.space.l};
    padding-bottom: ${theme.space.l};
    flex-direction: column;

    &:hover {
        background-color: ${(props) => (!props.isOpen ? theme.color.surfaceStrong : 'inherit')};
    }
`
export const DataConnectionGroupHeading: React.FC = () => {
    return (
        <ConnectionHeaderContainer>
            <Box
                style={{
                    paddingTop: theme.space.s,
                    paddingBottom: theme.space.s,
                    paddingRight: theme.space.xs,
                    paddingLeft: theme.space.xs,
                    width: '300px',
                }}
            >
                <Body size="m" weight="medium">
                    Name
                </Body>
            </Box>
            {/* TODO-Nango: add last synced column*/}
            {/*<Box flex>*/}
            {/*    <Box*/}
            {/*        style={{*/}
            {/*            paddingTop: theme.space.s,*/}
            {/*            paddingBottom: theme.space.s,*/}
            {/*            paddingRight: theme.space.xs,*/}
            {/*            paddingLeft: theme.space.xs,*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        /!*<Body size="m" weight="medium">*!/*/}
            {/*        /!*    Last synced*!/*/}
            {/*        /!*</Body>*!/*/}
            {/*    </Box>*/}

            {/*    <Box style={{ width: theme.space['9xl'] }}></Box>*/}
            {/*</Box>*/}
        </ConnectionHeaderContainer>
    )
}
type DataConnectionGroupRowProps = {
    dataConnections: DataConnectionDto[]
}
// Render a group of data connections. Every connection in the group is expected to have the same type and have the same
// nango connection id
export const DataConnectionGroupRow: React.FC<DataConnectionGroupRowProps> = ({
    dataConnections,
}) => {
    const [isDetailsOpen, setIsDetailsOpen] = React.useState(false)
    const dataConnectionsType = dataConnections[0]?.type
    const dataConnectionsExtUserEmail = dataConnections[0]?.external_user_email
    const nangoConnectionId = dataConnections[0]?.nango_connection_id
    if (
        !dataConnections.length ||
        !dataConnectionsType ||
        !nangoConnectionId ||
        dataConnectionsType == 'native_tables'
    )
        return null

    return (
        <Collapsible open={isDetailsOpen} onOpenChange={setIsDetailsOpen}>
            <ConnectionRowContainer isOpen={isDetailsOpen}>
                <Box flex style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Box
                        flex
                        style={{
                            paddingTop: theme.space.s,
                            paddingBottom: theme.space.s,
                            paddingRight: theme.space.xs,
                            paddingLeft: theme.space.xs,
                            width: '300px',
                        }}
                    >
                        <V2Icon
                            height="35px"
                            width="35px"
                            style={{
                                marginLeft: theme.space.m,
                                marginRight: theme.space.l,
                            }}
                            svg={<Airtable />}
                        />
                        <Box flex column>
                            <Body size="m" weight="medium">
                                {DC_TYPE_TO_INTEGRATION_NAME[dataConnectionsType]}
                            </Body>
                            <Body size="s">{dataConnectionsExtUserEmail}</Body>
                        </Box>
                    </Box>
                    {/* TODO-Nango: Add last synced column*/}
                    {/*<Box flex>2 hours ago</Box>*/}
                    <Box flex style={{ alignItems: 'center', paddingRight: theme.space['3xl'] }}>
                        <DataConnectionGroupOptions nangoConnectionId={nangoConnectionId} />
                        <ConnectionDetailsTrigger isOpen={isDetailsOpen} />
                    </Box>
                </Box>
                <CollapsibleContent flex column>
                    <Box
                        style={{
                            paddingTop: theme.space.xl,
                            paddingLeft: theme.space['5xl'],
                        }}
                    >
                        {dataConnections.map((dataConnection) => (
                            <DataConnectionSection
                                key={dataConnection._sid}
                                dataConnection={dataConnection}
                            />
                        ))}
                    </Box>
                </CollapsibleContent>
            </ConnectionRowContainer>
        </Collapsible>
    )
}

import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useWorkflows } from 'data/hooks/workflows/workflows'

import { Box } from 'ui/components/Box'
import { ComboboxContext } from 'ui/components/Combobox'
import * as Combobox from 'ui/components/Combobox/Combobox.parts'
import { ComboboxInput } from 'ui/components/Combobox/ComboboxInput'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'
import { Icon } from 'ui/components/Icon'
import { LoadingIndicator } from 'ui/components/LoadingIndicator'

import { filterWorkflowsForActionButton } from './helpers'

type Item = {
    label: string
    value: string
}

type WorkflowStepSettingsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    object: ObjectDto
    workflowId?: string
    onChangeWorkflow: (workflowId: string) => void
}

export const WorkflowStepSettings: React.FC<WorkflowStepSettingsProps> = ({
    object,
    workflowId,
    onChangeWorkflow,
    ...props
}) => {
    const { data: workflows = [], isLoading: isLoadingWorkflows } = useWorkflows()

    const workflowOptions = useMemo(() => {
        const filteredWorkflows = filterWorkflowsForActionButton(workflows, object._sid)

        return filteredWorkflows.map((workflow) => ({
            label: workflow.name,
            value: workflow._sid,
        }))
    }, [object._sid, workflows])

    const workflowOptionsRef = useRef(workflowOptions)
    workflowOptionsRef.current = workflowOptions

    const selectedOption = useMemo(
        () => workflowOptions.find((option) => option.value === workflowId),
        [workflowId, workflowOptions]
    )

    const providers = useMemo(
        () => [
            {
                id: 'workflows',
                getItems: async () => {
                    return Promise.resolve({
                        items: workflowOptionsRef.current,
                    })
                },
                renderItem: (props: { item: Item }) => (
                    <WorkflowItem item={props.item} selectedItem={selectedOption} />
                ),
            },
        ],
        [selectedOption]
    )

    const [isOpen, setIsOpen] = useState(false)

    const onItemSelected = useCallback(
        (item: Item) => {
            onChangeWorkflow(item.value)
            setIsOpen(false)
        },
        [onChangeWorkflow]
    )

    const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
        isOpen,
        selectedItem: selectedOption,
        onItemSelected: onItemSelected,
        providers,
        itemToString: (item: Item) => item.label,
    })

    const { isLoading, collections, showMore, items } = itemsState

    const isLoadingData = isLoading || isLoadingWorkflows

    return (
        <Box {...props}>
            <ComboboxContext.Provider value={comboboxState}>
                <ComboboxContext.Consumer>
                    {(context) => (
                        <Box position="relative">
                            <Box mb="m" flex center>
                                <Icon name="Zap" color="text" mr="s" />
                                <strong>Workflow</strong>
                            </Box>
                            <Box flex center position="relative">
                                <ComboboxInput
                                    autoFocus={!workflowId}
                                    placeholder="pick a workflow"
                                    grow
                                    onBlur={() => setIsOpen(false)}
                                    onFocus={() => setIsOpen(true)}
                                    onClick={() => setIsOpen(true)}
                                />
                                <Icon
                                    name="ChevronDown"
                                    color="textWeakest"
                                    position="absolute"
                                    right="m"
                                    role={isLoadingData ? undefined : 'button'}
                                    onClick={(e) => {
                                        setIsOpen((prev) => !prev)
                                        e.stopPropagation()
                                    }}
                                />
                                {isLoadingData && (
                                    <Box position="absolute" right="4xl" pointerEvents="none">
                                        <LoadingIndicator />
                                    </Box>
                                )}
                            </Box>
                            {items?.length !== 0 && (
                                <Combobox.Menu
                                    position="absolute"
                                    data-state={context.isOpen ? 'open' : 'closed'}
                                    width="full"
                                >
                                    <ComboboxList
                                        collections={collections}
                                        queryTerms={queryTerms}
                                        showMore={showMore}
                                        isLoading={isLoadingData}
                                    />
                                </Combobox.Menu>
                            )}
                        </Box>
                    )}
                </ComboboxContext.Consumer>
            </ComboboxContext.Provider>
        </Box>
    )
}

type WorkflowItemProps = {
    item: Item
    selectedItem?: Item
}

const WorkflowItem: React.FC<WorkflowItemProps> = ({ item, selectedItem }) => {
    const isSelected = item.value === selectedItem?.value

    return (
        <Box flex center justifyContent="space-between" width="full">
            {item.label}
            {isSelected && <Icon name="Check" />}
        </Box>
    )
}

import React, { FC, useMemo } from 'react'

import { AggregationConfiguration } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/panels/DataPanel/AggregationConfiguration'
import { InfoTooltip } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/panels/InfoTooltip'
import { isFieldSupportedForBucketing } from 'v2/blocks/blockTypes/view/aggregationBlocks/utils/isFieldSupportedForBucketing'
import { isFieldSupportedForGrouping } from 'v2/blocks/blockTypes/view/aggregationBlocks/utils/isFieldSupportedForGrouping'

import { useFields } from 'data/hooks/fields'
import FieldPicker from 'features/studio/ui/FieldPicker'

import { Box, Flex, Text } from 'v2/ui'

import BucketByDropdown from './BucketByDropdown'
import { GroupByDropdown } from './GroupByDropdown'
import type { DataPanelProps } from './types'

export const LineChartDataPanel: FC<DataPanelProps> = ({ chart, onChange }) => {
    const { data: fields } = useFields({ objectId: chart.objectId })

    const currentGroupByField = useMemo(() => {
        return fields?.find((f) => f._sid === chart.groupByFieldId)
    }, [fields, chart.groupByFieldId])

    const onChangeGroupField = (newGroupFieldId: string) => {
        onChange({
            objectId: chart.objectId,
            additionalGroupFieldId: newGroupFieldId,
        })
    }

    const onBucketByChange = (value?: Bucket) => {
        onChange({
            objectId: chart.objectId,
            bucketBy: value,
        })
    }

    return (
        <>
            <Box
                borderBottomWidth="1px"
                borderBottomStyle="solid"
                borderBottomColor="userInterface.neutral.500"
            />
            <Box>
                <Flex
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    columnGap={1}
                >
                    <Text
                        variant="paletteSectionLabel"
                        as="label"
                        htmlFor="bar-chart-x"
                        display="block"
                    >
                        Horizontal axis (x-axis)
                    </Text>
                    <InfoTooltip label="Line charts only allow date and datetime field types on the horizontal axis" />
                </Flex>
                <GroupByDropdown
                    id="bar-chart-x"
                    chart={chart}
                    fields={fields}
                    placeholder="Select a field for the x-axis"
                    filter={(field: FieldDto) =>
                        isFieldSupportedForGrouping(field) &&
                        ['date', 'datetime'].includes(field.type)
                    }
                    onChange={onChange}
                />
            </Box>
            {currentGroupByField && isFieldSupportedForBucketing(currentGroupByField) && (
                <BucketByDropdown value={chart.bucketBy} onChange={onBucketByChange} />
            )}
            <Box
                borderBottomWidth="1px"
                borderBottomStyle="solid"
                borderBottomColor="userInterface.neutral.500"
                mt={3}
            />
            {chart.objectId && (
                <AggregationConfiguration
                    aggregationWidget={chart}
                    onChange={onChange}
                    label={
                        <Flex
                            flexDirection="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            columnGap={1}
                        >
                            <Text>Vertical axis</Text>
                            <Text fontWeight="normal">(y-axis)</Text>
                        </Flex>
                    }
                    tooltip="Choose the value you'd like to display on the y-axis"
                />
            )}

            <Box>
                <Text
                    variant="paletteSectionLabel"
                    as="label"
                    htmlFor="metric-field"
                    display="block"
                >
                    Group by
                </Text>
                <FieldPicker
                    id="metric-field"
                    variant="admin"
                    placeholder="Select a field to group by"
                    objectId={chart.objectId}
                    value={chart.additionalGroupFieldId}
                    onChange={onChangeGroupField}
                    isDisabled={false}
                />
            </Box>
        </>
    )
}

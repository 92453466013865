import { useEffect, useState } from 'react'

import { JSONContent } from '@tiptap/react'
import { SerializedEditorState } from 'lexical'
import { isEqual } from 'lodash'
import { convertLexicalFormatToTipTap } from 'v2/views/Document/formatUtils'

import { getObject } from 'data/hooks/objects/getObject'

import usePrevious from 'v2/ui/hooks/usePrevious'

export type UseTipTapDocumentContentOptions = {
    content: DocumentContent | null
    format?: string
    onConvert?: (content: JSONContent | null) => void
}

export function useTipTapDocumentContent({
    content,
    format,
    onConvert,
}: UseTipTapDocumentContentOptions) {
    const [finalContent, setFinalContent] = useState<DocumentContent | null>(
        format === 'tiptap' ? content : null
    )

    const prevContent = usePrevious(content)

    useEffect(() => {
        const updateFinalContent = async () => {
            let finalContent: DocumentContent | null = content

            const contentChanged = !isEqual(prevContent, finalContent)
            if (!contentChanged) return

            if (format !== 'tiptap' && finalContent) {
                finalContent =
                    (await convertLexicalFormatToTipTap(finalContent as SerializedEditorState, {
                        objectResolver,
                    })) ?? null

                onConvert?.(finalContent)
            }

            setFinalContent(finalContent)
        }

        updateFinalContent()
    }, [content, format, onConvert, prevContent])

    return finalContent
}

async function objectResolver(objectId: string): Promise<ObjectDto | null> {
    try {
        const objects = await getObject(objectId)
        if (!objects) return null

        return objects[0] ?? null
    } catch {}

    return null
}

const gray = {
    10: '#F9F9FA',
    50: '#F2F4F6',
    100: '#EBECEE',
    200: '#cdced0',
    300: '#afb0b2',
    400: '#939496',
    500: '#777a7d',
    600: '#606B74',
    700: '#333C43',
    800: '#1F2123',
}

const blue = {
    100: 'rgb(236, 238, 251)',
    200: '#DADFF7',
    400: '#B5BEEF',
    600: '#7E8EE4',
    800: '#6B7DE0',
    1000: '#465DD8',
}

// Red, green and yellow lifted from Chakra UI: https://chakra-ui.com/docs/theming/theme
const red = {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
}

const green = {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#2F855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
}

const yellow = {
    50: '#FFFFF0',
    100: '#FEFCBF',
    200: '#FAF089',
    300: '#F6E05E',
    400: '#ECC94B',
    500: '#D69E2E',
    600: '#B7791F',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E',
}

const colors = {
    gray,
    blue,
    red,
    green,
    yellow,
    highlight: blue[1000],
    text: gray[800],
    stacker: blue[1000],
}

const V4DesignSystem = {
    colors,
    h1: {
        fontSize: 20,
        fontWeight: 600,
    },
    h2: {
        fontSize: 18,
        fontWeight: 600,
        color: gray[800],
    },
    h3: {
        fontSize: 16,
        fontWeight: 600,
        color: gray[800],
    },
    label: {
        fontSize: 14,
        color: gray[400],
        fontWeight: 600,
    },
    tabItem: {
        fontSize: 14,
        fontWeight: 600,
    },
    pageSubtitle: {
        fontSize: 14,
        fontWeight: 400,
        color: gray[600],
    },
    linkBackground: {
        primary: '#E7ECFB',
        secondary: '#E4F3EC',
    },
}

export default V4DesignSystem

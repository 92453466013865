import { useHotkeys } from 'react-hotkeys-hook'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

export function useLayoutEditorCanvasKeyboardShortcuts() {
    const { commands, selectedWidget } = useLayoutEditorContext()

    useHotkeys('ctrl+z, meta+z', commands.undo, { preventDefault: true }, [commands.undo])
    useHotkeys('ctrl+shift+z, meta+shift+z', commands.redo, { preventDefault: true }, [
        commands.redo,
    ])

    useHotkeys(
        'delete, backspace',
        () => {
            commands.removeSelectedWidget()
        },
        { enabled: !!selectedWidget },
        [commands.removeSelectedWidget]
    )

    useHotkeys(
        'ctrl+d, meta+d',
        () => {
            commands.duplicateSelectedWidget()
        },
        { enabled: !!selectedWidget, preventDefault: true },
        [commands.duplicateSelectedWidget]
    )
}

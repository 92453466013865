import { useMemo } from 'react'

import { CardViewFooterAttribute } from 'features/views/ListView/CardView/types'

type UseCardViewFooterStateOptions = {
    fields: FieldDto[]
    actions?: ActionDto[]
    cardFooter?: ListViewOptions['cardFooter']
}

export function useCardViewFooterState(options: UseCardViewFooterStateOptions) {
    const { cardFooter, fields, actions } = options

    const footerLeftValue = cardFooter?.leftFieldSid
    const footerLeftButton = useMemo(
        () => actions?.find((a) => a._sid === footerLeftValue),
        [actions, footerLeftValue]
    )

    const footerLeftAttribute = useMemo(
        () =>
            !footerLeftButton
                ? getFooterField(fields, footerLeftValue, cardFooter?.leftFieldDisplayOptions)
                : undefined,
        [fields, footerLeftButton, footerLeftValue, cardFooter?.leftFieldDisplayOptions]
    )

    const footerRightValue = cardFooter?.rightFieldSid
    const footerRightButton = useMemo(
        () => actions?.find((a) => a._sid === footerRightValue),
        [actions, footerRightValue]
    )

    const footerRightAttribute = useMemo(
        () =>
            !footerRightButton
                ? getFooterField(fields, footerRightValue, cardFooter?.rightFieldDisplayOptions)
                : undefined,
        [fields, footerRightButton, footerRightValue, cardFooter?.rightFieldDisplayOptions]
    )

    return useMemo(
        () => ({
            footerLeftButton,
            footerRightButton,
            footerLeftAttribute,
            footerRightAttribute,
        }),
        [footerLeftButton, footerRightButton, footerLeftAttribute, footerRightAttribute]
    )
}

const getFooterField = (
    fields: FieldDto[],
    value?: string,
    displayOptions?: CardFooterDisplayOptions
): CardViewFooterAttribute | undefined => {
    if (value === '_record_stats') {
        return value
    }

    const field = fields.find((f) => f._sid === value)
    if (!field) return undefined

    return {
        id: field._sid,
        title: field.label,
        field,
        rawOptions: displayOptions ?? {},
        type: 'footer',
    }
}

// @ts-strict-ignore
import React, { useState } from 'react'

import { Rights } from 'app/appUserContextConstants'
import { HELP_LINKS } from 'app/helpLinks'
import { useAppContext } from 'app/useAppContext'
import { useObjects } from 'data/hooks/objects'
import { useStackRoles } from 'data/hooks/roles'
import { FormHeader } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box, Button, Divider, Dropdown, SearchInput } from 'v2/ui'

import { PermissionTable, PermissionTableRows } from './PermissionTable'

function AppSettingsModalPermissionsSettings({ children }) {
    const { data: roles } = useStackRoles()

    const [tableFilter, setTableFilter] = useState('')
    const [roleFilter, setRoleFilter] = useState(null)

    const roleDropdownOptions = [
        { value: null, label: 'All roles' },
        ...roles
            .filter((r) => {
                const allAccess = r.options?.rights?.find((x) => x === Rights.AllDataAccess)
                return !allAccess
            })
            .map((r) => ({ value: r._sid, label: r.label })),
    ]

    const learnMore = (
        <Button variant="adminSecondary" buttonSize="sm" href={HELP_LINKS.PERMISSIONS}>
            Learn more
        </Button>
    )

    const { data: objects } = useObjects()

    const visibleObjects = objects.filter((o) => o && !o.connection_options?.data_mapping_disabled)

    return (
        <Box padding="20px" overflow="scroll" width="100%">
            <FormHeader
                title="Permissions"
                subtitle="Use permissions to set what your users can see and do in your app."
                rightSideContent={learnMore}
            />
            <Divider my={4} />
            <div style={{ display: 'inline-block', width: '250px', marginRight: '8px' }}>
                <SearchInput
                    variant="admin_white"
                    value={tableFilter}
                    onChange={setTableFilter}
                    placeholder="Search tables..."
                />
            </div>
            <div style={{ display: 'inline-block' }}>
                <Dropdown
                    variant="admin"
                    options={roleDropdownOptions}
                    value={roleFilter}
                    onChange={setRoleFilter}
                    isSearchable={false}
                    isClearable={true}
                    placeholder="All roles"
                    padding="5px" // Makes the height match the search input
                    optionHeight={26}
                />
            </div>
            {visibleObjects.length > 0 ? (
                <PermissionTable includeTableName={true}>
                    {visibleObjects.map((object) => (
                        <RenderObject
                            key={object._sid}
                            object={object}
                            tableFilter={tableFilter}
                            roleFilter={roleFilter}
                        />
                    ))}
                </PermissionTable>
            ) : null}
            {children}
        </Box>
    )
}

function RenderObject({ object, tableFilter, roleFilter }) {
    const { selectedStack } = useAppContext()

    if (!object) {
        return <></>
    }

    if (!tableFilter || object.name.toLowerCase().includes(tableFilter.toLowerCase())) {
        return (
            <PermissionTableRows
                stack={selectedStack}
                stackId={selectedStack?._sid}
                objectId={object._sid}
                showTableName={true}
                roleFilter={roleFilter}
            />
        )
    }

    return <></>
}

export default AppSettingsModalPermissionsSettings

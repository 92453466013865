import React from 'react'
import { useForm } from 'react-hook-form'

import { updateObject } from 'data/hooks/objects/objectOperations'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'

type FormData = {
    records_per_page: number
}

export function DisplaySettings({ object }: { object: ObjectDto }) {
    const { register, handleSubmit, formState } = useForm<FormData>({
        defaultValues: { records_per_page: object.options?.records_per_page ?? 1000 },
    })

    const { isDirty, isSubmitting } = formState

    async function onSubmit(data: FormData) {
        console.log(data)
        await updateObject(object._sid, { options: { records_per_page: data.records_per_page } })
    }
    return (
        <Box as="form" onSubmit={handleSubmit(onSubmit)} flex column alignItems="flex-start">
            <Input label="Records per page" {...register('records_per_page')} />
            <Button
                type="submit"
                disabled={!isDirty || isSubmitting}
                isLoading={isSubmitting}
                mt="m"
            >
                Save
            </Button>
        </Box>
    )
}

import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { sOnly?: boolean }

export const StackerLogo: React.FC<Props> = React.memo(function StackerLogo({ sOnly, ...props }) {
    if (sOnly) {
        return (
            <svg viewBox="0 0 1000 1091" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M508.648 0.840944C498.58 -1.12266 487.804 0.30607 478.148 5.61659L23.4797 255.684C23.0988 255.894 22.7214 256.108 22.3476 256.328C16.4284 259.809 11.4302 264.557 7.6709 270.167C3.19741 276.844 0.51303 284.687 0.0665424 292.938C-0.00807485 294.314 -0.0198172 295.689 0.0307055 297.061C0.256316 303.187 1.72354 309.236 4.37809 314.828C5.03404 316.21 5.76249 317.564 6.56262 318.884L209.403 653.57L23.502 755.816C9.01994 763.781 0.0223745 778.998 0.0223745 795.526C0.0223745 812.054 9.01994 827.271 23.502 835.236L476.945 1084.63C488.582 1091.49 502.999 1092.96 516.105 1087.96C518.065 1087.22 519.986 1086.33 521.852 1085.3L976.52 835.236C991.002 827.271 1000 812.054 1000 795.526C1000 786.615 997.385 778.086 992.705 770.868L790.579 437.36L976.498 335.105C990.98 327.14 999.978 311.923 999.978 295.395C999.978 278.867 990.98 263.649 976.498 255.684L522.504 5.98766C520.143 4.63863 517.671 3.5088 515.122 2.60713C513.001 1.85543 510.836 1.26671 508.648 0.840944ZM483.754 105.979L139.362 295.395L499.989 493.74L664.068 403.496L483.754 105.979ZM743.558 359.776L626.501 166.631L860.616 295.395L743.558 359.776ZM711.088 481.08L594.045 545.454L828.131 674.202L711.088 481.08ZM860.631 795.522L500.011 597.181L335.914 687.434L516.224 984.945L860.631 795.522ZM256.423 731.154L139.384 795.526L373.463 924.269L256.423 731.154ZM288.894 609.85L405.955 545.466L171.832 416.699L288.894 609.85Z"
                    fill="black"
                />
            </svg>
        )
    }
    return (
        <svg viewBox="0 0 500 126" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M58.5718 0.0968363C57.4124 -0.129276 56.1715 0.0352445 55.0597 0.646761L2.70374 29.4425C2.65986 29.4667 2.61641 29.4914 2.57337 29.5167C1.89176 29.9175 1.31621 30.4642 0.883319 31.1102C0.368188 31.8791 0.0590764 32.7823 0.00766248 33.7324C-0.000929834 33.8908 -0.00228199 34.0492 0.0035358 34.2071C0.0295152 34.9125 0.198469 35.6091 0.504146 36.2531C0.57968 36.4122 0.663562 36.5681 0.755698 36.7201L24.1131 75.2598L2.70629 87.0336C1.03866 87.9508 0.00257646 89.7031 0.00257646 91.6063C0.00257646 93.5095 1.03866 95.2618 2.70629 96.179L54.9211 124.897C56.2612 125.687 57.9213 125.857 59.4305 125.281C59.6562 125.195 59.8773 125.093 60.0922 124.975L112.448 96.179C114.116 95.2618 115.152 93.5095 115.152 91.6063C115.152 90.5802 114.851 89.598 114.312 88.7669L91.0367 50.3629L112.446 38.588C114.113 37.6708 115.149 35.9185 115.149 34.0152C115.149 32.112 114.113 30.3597 112.446 29.4425L60.1673 0.68949C59.8954 0.534147 59.6108 0.404045 59.3173 0.300216C59.073 0.213656 58.8238 0.145864 58.5718 0.0968363ZM55.7052 12.2037L16.0478 34.0152L57.5747 56.855L76.4686 46.4634L55.7052 12.2037ZM85.6221 41.4289L72.1427 19.1879L99.1016 34.0152L85.6221 41.4289ZM81.8831 55.3973L68.4054 62.8101L95.3609 77.6356L81.8831 55.3973ZM99.1032 91.6058L57.5772 68.7665L38.6812 79.1594L59.4442 113.418L99.1032 91.6058ZM29.5276 84.1938L16.0503 91.6063L43.0049 106.431L29.5276 84.1938ZM33.2666 70.2254L46.7465 62.8115L19.7868 47.9836L33.2666 70.2254Z"
                fill="black"
            />
            <path
                d="M479.886 94.226H467.715V39.5584H477.514L478.958 50.2856C482.465 42.8591 488.86 38.9395 497.937 38.9395H500V50.8013H497.937C486.591 50.8013 479.886 56.887 479.886 67.2016V94.226Z"
                fill="black"
            />
            <path
                d="M436.247 95.7731C419.537 95.7731 408.088 83.8081 408.088 67.0984C408.088 49.0478 419.95 37.908 435.216 37.908C450.688 37.908 461.002 48.7384 461.312 66.4796V69.3676H420.259C421.085 78.9603 427.067 85.4585 436.35 85.4585C442.642 85.4585 447.387 82.3641 449.244 76.8973H461.105C458.32 88.656 449.14 95.7731 436.247 95.7731ZM420.775 60.8065H448.728C447.181 52.9674 442.333 48.2227 435.216 48.2227C427.995 48.2227 422.632 52.9674 420.775 60.8065Z"
                fill="black"
            />
            <path
                d="M370.645 94.2259H358.473V16.8661H370.645V63.282L393.956 39.5583H408.809L389.005 59.2593L411.491 94.2259H397.36L380.547 67.7173L370.645 77.6193V94.2259Z"
                fill="black"
            />
            <path
                d="M326.097 95.7731C308.252 95.7731 297.835 82.983 297.835 66.4796C297.835 48.5321 309.903 37.908 325.787 37.908C340.744 37.908 350.852 47.088 351.883 60.7034H339.918C338.784 53.1737 333.42 48.3258 325.684 48.3258C316.401 48.3258 310.212 55.3398 310.212 66.4796C310.212 78.4445 317.02 85.3553 326.097 85.3553C333.523 85.3553 338.681 80.6106 339.918 72.7715H351.883C350.439 86.9025 340.64 95.7731 326.097 95.7731Z"
                fill="black"
            />
            <path
                d="M261.128 95.3605C248.544 95.3605 240.293 89.2749 240.396 79.0634C240.499 66.2733 253.186 63.5915 263.81 62.3537C272.062 61.4254 278.147 61.1159 278.25 56.7838C278.147 51.4202 274.021 47.9132 267.007 47.9132C259.787 47.9132 254.63 51.8328 253.908 57.5058H241.633C242.768 45.9534 253.083 37.908 267.214 37.908C281.964 37.908 290.112 45.9534 290.112 57.609V82.3641C290.112 83.9113 290.937 84.7365 292.794 84.7365H295.785V94.2259H290.009C284.439 94.2259 281.035 91.9567 280.107 87.8308C279.901 87.3151 279.694 86.5931 279.591 85.9742C276.394 91.8536 270.411 95.3605 261.128 95.3605ZM263.604 85.7679C271.959 85.7679 278.25 81.0232 278.25 73.6998V66.067C276.188 69.2645 270.927 69.9865 265.151 70.7086C258.343 71.5337 252.67 72.462 252.67 78.2382C252.67 82.983 256.487 85.7679 263.604 85.7679Z"
                fill="black"
            />
            <path
                d="M236.23 94.2259H229.216C217.354 94.2259 210.959 88.3465 210.959 77.5162V49.8729H201.366V39.5583H210.959V20.8888H223.13V39.5583H237.261V49.8729H223.13V76.691C223.13 81.3326 225.503 83.9112 230.041 83.9112H236.23V94.2259Z"
                fill="black"
            />
            <path
                d="M179.77 95.7731C164.814 95.7731 155.943 88.7592 155.221 76.7942H166.98C167.392 82.6735 172.447 85.7679 179.151 85.7679C185.34 85.7679 188.641 83.3956 188.641 79.1665C188.641 74.9376 185.134 74.1124 180.389 72.5652L173.169 70.1928C164.092 67.2016 156.253 64.1072 156.253 53.9988C156.253 44.3031 163.679 37.908 176.779 37.908C189.466 37.908 198.13 44.6125 199.574 56.9901H188.022C187.403 50.8013 182.968 47.9132 176.676 47.9132C171.312 47.9132 168.218 50.1824 168.218 53.8957C168.218 58.1247 172.962 59.2593 176.882 60.6002L183.483 62.7663C194.314 66.3764 200.812 69.6771 200.812 79.476C200.812 89.5843 193.179 95.7731 179.77 95.7731Z"
                fill="black"
            />
        </svg>
    )
})

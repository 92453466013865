import React, { memo } from 'react'

import { Avatar as ChakraAvatar } from '@chakra-ui/react'

type AvatarProps = React.ComponentPropsWithoutRef<typeof ChakraAvatar>

const Avatar = memo<AvatarProps>(({ ...props }) => {
    return <ChakraAvatar bg="gray.200" color="gray.500" {...props} />
})

export default Avatar

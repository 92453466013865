import { queryClient } from 'data/hooks/_helpers'
import { includeFieldsToRelatedFieldSuffix } from 'data/hooks/recordQueryKeySuffix'

import { getRecordQueryFunction } from './getRecordQueryFunction'
import { RECORD_QUERY_CONFIG } from './recordConstants'

/**
 * Fetches a record from the api and sets up the record query
 * @param {string} recordId
 * @param {string[] | undefined} includeFields
 * @param {any | undefined} options
 */
export const getRecord = async (
    recordId: string,
    includeFields: string[] | undefined,
    options: any = {}
): Promise<RecordDto | undefined> => {
    const requiredFieldSuffix = includeFieldsToRelatedFieldSuffix(includeFields)
    const queryKey = ['record', recordId, requiredFieldSuffix]
    // Note: calling prefetch query directly from queryClient won't use any of the default query config that the useQuery hook uses
    // This allows us to fetch data for a query outside of react components
    const queryOptions: any = { ...RECORD_QUERY_CONFIG }
    // We sometimes want to call this method with data that we already have, in order to prefetch the query
    if (options.initialData) {
        queryOptions.initialData = options.initialData
        delete options.initialData
    }

    const existingData = queryClient.getQueryData(queryKey)
    if (existingData && options.checkCache) {
        return Promise.resolve(existingData as RecordDto)
    }

    // We want to fetch the data first, outside of prefetch query as this will always force a fetch
    // The prefetch query below is to ensure that the query gets setup correctly for future fetches
    if (!queryOptions.initialData) {
        const newData = await getRecordQueryFunction(recordId, includeFields, options)
        // Do not pass this on to the query as it'll force a no cache fetch every time
        if (options.noCache) delete options.noCache
        queryOptions.initialData = newData
    }

    // The query might not have been setup yet, so using prefetch query will create the query if not
    await queryClient.prefetchQuery(
        queryKey,
        () => {
            if (!recordId) return Promise.resolve(undefined)
            return getRecordQueryFunction(recordId, includeFields, options)
        },
        queryOptions
    )

    return queryClient.getQueryData(queryKey)
}

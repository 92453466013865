import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { get } from 'lodash'

import stackerTheme from 'v2/ui/theme/styles/default'

import { defaultMessages } from './utils'

const colors = stackerTheme().colors

export default function FieldErrorMessage({ name, errorMessages = {} }) {
    const {
        formState: { errors },
    } = useFormContext()

    const error = get(errors, name)

    if (!error) return null

    const message =
        error?.message ||
        errorMessages[error.type] ||
        defaultMessages[error.type] ||
        'an error occurred with code: ' + error.type
    return <div style={{ padding: '3px 0', color: colors.red[700] }}>{message}</div>
}

import React from 'react'

import { ActivityType } from 'data/hooks/activityTypes'
import { ActivityFeed } from 'features/Activity/ActivityFeed'
import { useActivityFeedConfig } from 'features/Activity/useActivityFeedConfig'

import { Box as V2Box, CloseButton } from 'v2/ui'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { Box } from 'ui/components/Box'

import { DocumentCommentFeedStyle } from './DocumentCommentFeed.css'

type DocumentCommentFeedProps = React.ComponentPropsWithoutRef<typeof Box> & {
    document?: DocumentDto
    record?: RecordDto
    onClose?: () => void
    isExpanded?: boolean
}
const FeedParams = { types: [ActivityType.Comment] }

export const DocumentCommentFeed: React.FC<DocumentCommentFeedProps> = ({
    document,
    record,
    onClose,
    isExpanded,
    ...props
}) => {
    const {
        commentCount = 0,
        contextKey,
        target,
    } = useActivityFeedConfig({
        document,
        record,
    })

    const actualContextKey = `${contextKey}_Comments`

    const isMobile = useIsMobile()
    if (isMobile) return null

    return (
        <V2Box
            background="backgroundColor"
            className={DocumentCommentFeedStyle({ isExpanded })}
            {...props}
        >
            <Box pl="l" px="m" py="m" flex center>
                <Box as="h2" fontSize="headlineXs" fontWeight="headline" grow>
                    Comments
                </Box>
                <CloseButton type="button" onClick={onClose} />
            </Box>
            <Box p="m">
                {commentCount < 1 && (
                    <Box textAlign="center" fontSize="bodyL" mt="m">
                        No comments yet.
                    </Box>
                )}
                <ActivityFeed
                    key={actualContextKey}
                    contextKey={actualContextKey}
                    target={target}
                    params={FeedParams}
                    showLocationPreview={true}
                    showFilters={false}
                    showControls={false}
                    showAppIcons={false}
                    showCommentEditor={false}
                    showPlaceholder={false}
                />
            </Box>
        </V2Box>
    )
}

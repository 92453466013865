import React from 'react'

import * as Parts from './Textarea.parts'

import { ResizeHandlePathStyles } from './Textarea.css'

type TextareaResizeHandle = React.ComponentPropsWithoutRef<typeof Parts.ResizeHandle> & {}

export const TextareaResizeHandle: React.FC<TextareaResizeHandle> = (props) => {
    return (
        <Parts.ResizeHandle
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 7 7"
            fill="none"
            {...props}
        >
            <path
                d="M4 6L6 4M6 1L1 6"
                stroke="none"
                className={ResizeHandlePathStyles.styleFunction({
                    isDisabled: props.isDisabled,
                    isReadOnly: props.isReadOnly,
                })}
            />
        </Parts.ResizeHandle>
    )
}

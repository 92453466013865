import { useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { mergeFilters, removeUnsupportedFilters } from 'features/views/ListView/Filters/utils'
import { ListViewUrlParams } from 'features/views/ListView/hooks/useListViewUrlParams'
import { useSyncListViewUrlParams } from 'features/views/ListView/hooks/useSyncListViewUrlParams'

type UsePersistFiltersOptions = {
    fields: FieldDto[]
    filters: Filter[]
    setFilters: (filters: Filter[]) => void
    view: ViewDto
    filterType?: ListViewInlineFilters
}

/**
 * Persist filters in the url and in local storage, when the `shouldPersist` flag is true.
 * @param options
 */
export function usePersistFilters(options: UsePersistFiltersOptions) {
    const { filters, setFilters, fields, view, filterType } = options

    const location = useLocation()
    const searchRef = useRef(location.search)
    searchRef.current = location.search

    const fieldsRef = useRef(fields)
    fieldsRef.current = fields

    const viewRef = useRef(view)
    viewRef.current = view

    const onParamLoad = useCallback(
        (urlParams: ListViewUrlParams) => {
            // Don't load filters if inline filters are turned off.
            if (!filterType) return

            const fields = fieldsRef.current

            const view = viewRef.current
            const localStorageFilters = restoreFromLocalStorage(view, fields, filterType)

            const urlFilters = removeUnsupportedFilters(urlParams.filters, fields, filterType)

            const mergedFilters = mergeFilters(localStorageFilters, urlFilters, filterType)

            setFilters(mergedFilters)
        },
        [filterType, setFilters]
    )
    const onParamSync = useCallback(
        (urlParams: ListViewUrlParams) => {
            const view = viewRef.current
            persistToLocalStorage(filters, view)

            urlParams.setParams({
                filters,
            })
        },
        [filters]
    )
    useSyncListViewUrlParams(onParamLoad, onParamSync)
}

function getFilterStorageKey(view: ViewDto) {
    return `InlineFilters_${view.stack_id}_${view._sid}`
}

function persistToLocalStorage(filters: Filter[], view: ViewDto) {
    const serializedFilters = filters.map((f) => ({
        ...f,
        field: f.field
            ? {
                  _sid: f.field?._sid,
                  api_name: f.field?.api_name,
              }
            : undefined,
    }))

    const key = getFilterStorageKey(view)
    localStorage.setItem(key, JSON.stringify(serializedFilters))
}

function restoreFromLocalStorage(
    view: ViewDto,
    fields: FieldDto[],
    filterType?: ListViewInlineFilters
) {
    const key = getFilterStorageKey(view)
    const filters = localStorage.getItem(key)
    if (!filters) return []

    const newFilters = JSON.parse(filters)
    if (!Array.isArray(newFilters)) return []

    return removeUnsupportedFilters(newFilters, fields, filterType)
}

// @ts-strict-ignore
import { BuiltInAccountRoleNames } from 'data/hooks/accountRoleTypes'

export function isRightGranted(rights, value) {
    let matchAnyDescendants = false
    // The right requested will be in a format like this:
    //  admin.dataconnections.view
    //
    // Our matching logied allows for matching hierarchically.For instance
    // the caller asks for admin.dataconnections.view and the user has been
    // granded admin.dataconnections, then we return true.
    // Additionally we support checking for "any" child rights being granted.
    //
    // For instance, checking for "admin.*" will return true if the user has
    // admin.dataconnections.view granted as a right.
    while (value) {
        // If this right is explicitly granted, or we're matchiny any decendants
        // and a descendant right has been granted...
        if (
            rights.includes('*') ||
            rights.includes(value) ||
            (matchAnyDescendants && rights.find((right) => right.startsWith(value + '.')))
        ) {
            return true

            // otherwise, if the right we're checking has a period,
            // split it and check the next level up. For instance
            // admin.dataconnections.view will become admin.dataconnections
        } else if (value.includes('.')) {
            matchAnyDescendants = false

            // If we're ending with an asterisk, we want to go into manyAnyDescendants mode
            if (value.endsWith('.*')) {
                matchAnyDescendants = true
            }
            value = value.substring(0, value.indexOf('.'))
        } else {
            return false
        }
    }

    return false
}

export const isPortalRole = (role: AccountRole) =>
    role.api_name === BuiltInAccountRoleNames.PORTAL_USER

export const isPortalUser = (user: UserDto) =>
    user.account_role_api_name === BuiltInAccountRoleNames.PORTAL_USER

export const getWorkspaceRoles = (roles: AccountRole[] = []): AccountRole[] =>
    roles.filter((r) => !isPortalRole(r))

import React from 'react'

import { Box } from 'ui/components/Box'

import { NavigationAppsItem } from './NavigationAppsItem'
import { NavigationApp } from './types'

type NavigationAppsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    apps: NavigationApp[]
    isCollapsed?: boolean
}

export const NavigationApps: React.FC<NavigationAppsProps> = ({ apps, isCollapsed, ...props }) => {
    return (
        <Box flex column alignSelf="stretch" gap="2xs" {...props}>
            {apps.map((app) => (
                <NavigationAppsItem key={app.id} app={app} isCollapsed={isCollapsed} />
            ))}
        </Box>
    )
}

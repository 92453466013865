import {
    DISCARD_EDITING_REQUEST,
    DISCARD_STOP_EDITING_REQUEST,
    EDITING_START_REQUESTED,
    EDITING_STARTED,
    EDITING_STOP_REQUESTED,
    EDITING_STOPPED,
} from 'data/utils/constants'

let exitEditModeCheckpoints = []
let enterEditModeCheckpoints = []

export class UserActions {
    static startEditing() {
        return async (dispatch) => {
            dispatch({
                type: EDITING_START_REQUESTED,
            })
            // If we have handlers registered for this edit mode transition, then
            // we need to call each one now and wait on the promise it returns
            for (const handler of enterEditModeCheckpoints) {
                // If any handler returns false, then we cancel the transition
                if (!(await handler())) {
                    dispatch({
                        type: DISCARD_EDITING_REQUEST,
                    })
                    return false
                }
            }
            // We can now make the transition
            dispatch({
                type: EDITING_STARTED,
            })
            return true
        }
    }

    static stopEditing(options = {}) {
        return async (dispatch) => {
            dispatch({
                type: EDITING_STOP_REQUESTED,
            })
            // If we have handlers registered for this edit mode transition, then
            // we need to call each one now and wait on the promise it returns
            for (const handler of exitEditModeCheckpoints) {
                // If any handler returns false, then we cancel the transition
                if (!(await handler())) {
                    dispatch({
                        type: DISCARD_STOP_EDITING_REQUEST,
                    })
                    return false
                }
            }
            // We can now make the transition
            dispatch({
                type: EDITING_STOPPED,
                payload: { leaveSlidingPaneOpen: options?.leaveSlidingPaneOpen },
            })
            return true
        }
    }

    static discardEditingRequest() {
        return (dispatch) => {
            dispatch({
                type: DISCARD_EDITING_REQUEST,
            })
            return Promise.resolve()
        }
    }
}

export function registerExitEditModeCheckpoint(handler) {
    exitEditModeCheckpoints = [...exitEditModeCheckpoints, handler]
}
export function unregisterExitEditModeCheckpoint(handler) {
    exitEditModeCheckpoints = exitEditModeCheckpoints.filter((x) => x !== handler)
}

export function registerEnterEditModeCheckpoint(handler) {
    enterEditModeCheckpoints = [...enterEditModeCheckpoints, handler]
}
export function unregisterEnterEditModeCheckpoint(handler) {
    enterEditModeCheckpoints = enterEditModeCheckpoints.filter((x) => x !== handler)
}

export const userActions = {
    startEditing: UserActions.startEditing,
    stopEditing: UserActions.stopEditing,
    discardEditingRequest: UserActions.discardEditingRequest,
}

import React, { createContext } from 'react'

import { Bundle } from './bundles/bundleBuilder'

type AiAppBuilderContextType = {
    clearbitContext: string
    bundle: Bundle | null
    generationFailed: boolean
    description: string
    requirements: string[]
    progress: { progress: number; status: React.ReactNode }
    isGenerating: boolean
    currentStep: 'describe' | 'requirements'
    setBundle: (bundle: Bundle | null) => void
    setGenerationFailed: (failed: boolean) => void
    setDescription: (description: string) => void
    setRequirements: React.Dispatch<React.SetStateAction<string[]>>
    setProgress: (progress: { progress: number; status: React.ReactNode }) => void
    setIsGenerating: (isGenerating: boolean) => void
    setCurrentStep: (step: 'describe' | 'requirements') => void
    generateBundle: () => void
    cancelGeneration: () => void
}

export const AiAppBuilderContext = createContext<AiAppBuilderContextType | undefined>(undefined)

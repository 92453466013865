import { useMemo } from 'react'

import { useNavigationAll } from 'data/hooks/navigation'
import { usePagesAll } from 'data/hooks/pages'
import { useViewsAll } from 'data/hooks/views'
import { buildNavTree } from 'features/admin/settings/navigation/NavigationUtils'
import { NavTree } from 'features/admin/settings/navigation/types'

// Builds the nav trees of all the apps in the workspace
export function useGlobalNavigation({ enabled = true }: { enabled: boolean }) {
    const { data: navigation, isLoading: navigationLoading } = useNavigationAll({ enabled })
    const { data: views, isLoading: viewsLoading } = useViewsAll({ enabled })
    const { data: pages, isLoading: pagesLoading } = usePagesAll({ enabled })

    const isLoading = navigationLoading || viewsLoading || pagesLoading
    return useMemo(() => {
        if (isLoading || !navigation || !views || !pages) return { isLoading }

        const stacks: {
            [key: string]: {
                stackId: string
                navTree?: NavTree
                navigation: NavigationDto[]
                views: ViewDto[]
                pages: PageDto[]
            }
        } = {}

        navigation.forEach((navItem: NavigationDto) => {
            const stackId = navItem.stack_id
            if (!stacks[stackId]) {
                stacks[stackId] = { stackId, navigation: [navItem], views: [], pages: [] }
            } else {
                stacks[stackId].navigation.push(navItem)
            }
        })

        views?.forEach((item: ViewDto) => {
            const stackId = item.stack_id
            if (!stacks[stackId]) {
                stacks[stackId] = { stackId, navigation: [], views: [item], pages: [] }
            } else {
                stacks[stackId].views.push(item)
            }
        })

        pages.forEach((item: PageDto) => {
            const stackId = item.stack_id
            if (!stacks[stackId]) {
                stacks[stackId] = { stackId, navigation: [], views: [], pages: [item] }
            } else {
                stacks[stackId].pages.push(item)
            }
        })

        const globalNavTree: NavTree = []
        Object.values(stacks).forEach((stack) => {
            stack.navTree = buildNavTree(
                stack.navigation,
                stack.views,
                stack.pages,
                null,
                stack.stackId
            )
            globalNavTree.push(...stack.navTree)
        })

        return {
            stacks: Object.values(stacks),
            globalNavTree,
            isLoading,
        }
    }, [isLoading, navigation, pages, views])
}

import React, { FC, useCallback } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { createNewStackData } from 'data/hooks/stacks'

import { Button } from 'v2/ui'

import CreateAppModal from './CreateAppModal'

type NewAppButtonProps = {
    as: FC<{ onClick: () => void }>
    onBeforeCreateStack?: (stack: ReturnType<typeof createNewStackData>) => void
    spaceName?: string
}
export const NewAppButton: FC<NewAppButtonProps> = ({
    as,
    onBeforeCreateStack,
    spaceName,
    ...props
}) => {
    const newApp = useDisclosure()

    const Component = as || Button
    const onButtonClick = useCallback(() => {
        newApp.onOpen()
    }, [newApp])

    return (
        <>
            <Component {...props} onClick={onButtonClick} />
            <CreateAppModal
                isOpen={newApp.isOpen}
                onClose={newApp.onClose}
                // TODO: fix once the component is moved to TS
                // @ts-expect-error
                onBeforeCreateStack={onBeforeCreateStack}
                // @ts-expect-error
                spaceName={spaceName}
            />
        </>
    )
}
export default NewAppButton

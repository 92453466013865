import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'

import { NavigationItemStyle } from './Navigation.css'

type NavigationItemRef = HTMLElement

type NavigationItemProps = React.ComponentPropsWithoutRef<typeof Box> & {
    label: React.ReactNode
    leftSlotContent?: React.ReactNode
    rightSlotContent?: React.ReactNode
    to?: React.ComponentProps<typeof ReactRouterLink>['to']
    href?: React.ComponentProps<typeof ReactRouterLink>['href']
    isCollapsed?: boolean
}

export const NavigationItem = React.forwardRef<NavigationItemRef, NavigationItemProps>(
    (
        {
            label,
            to,
            href,
            isCollapsed,
            leftSlotContent,
            rightSlotContent,
            className,
            children,
            as: providedAs,
            ...props
        },
        ref
    ) => {
        let as = providedAs
        if (!as && !!to) as = ReactRouterLink
        else if (!as && !!href) as = 'a'
        else if (!as) as = 'button'

        return (
            <Box
                ref={ref}
                isCollapsed={isCollapsed}
                className={classNames(NavigationItemStyle, className)}
                as={as}
                to={to}
                href={href}
                {...props}
            >
                {leftSlotContent}
                {!isCollapsed && (
                    <Box flex grow center justifyContent="space-between" minWidth={0}>
                        <Box grow trim minWidth={0}>
                            {label}
                        </Box>
                        {rightSlotContent}
                    </Box>
                )}
                {children}
            </Box>
        )
    }
)

import React from 'react'

import { ActivityFeedContext } from './ActivityFeedContext'

export function ActivityFeedContextProvider({
    value,
    children,
}: {
    value: ActivityFeedContext
    children: React.ReactNode
}) {
    return <ActivityFeedContext.Provider value={value}>{children}</ActivityFeedContext.Provider>
}

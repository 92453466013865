import React, { useEffect, useState } from 'react'

import { Bundle } from 'features/AiAppBuilder/bundles/bundleBuilder'
import {
    AppSchemaType,
    FieldSchemaType,
    ListViewSchemaType,
    ObjectSchemaType,
    RecordSchemaType,
} from 'features/AiAppBuilder/bundles/zodSchema'
import { Card } from 'features/views/ListView/CardView/CardView.parts'
import { Cell, Header, Row } from 'features/views/ListView/TableView/TableView.parts'

import { Box, BoxProps } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { LinkButton } from 'ui/components/LinkButton'
import { Tag } from 'ui/components/Tag'
import { Headline } from 'ui/components/Text'

type Props = {
    bundle: Bundle
}
export function SimpleBundlePreview({ bundle }: Props) {
    const app = bundle?.apps[0]
    const [selectedObject, setSelectedObject] = useState<ObjectSchemaType | null>(app?.objects[0])
    const [selectedListView, setSelectedListView] = useState<ListViewSchemaType>()

    console.log(app)
    useEffect(() => {
        const object = app?.objects[0]

        setSelectedObject(object)
        if (object?.listViews) {
            setSelectedListView(object.listViews[0])
        }
    }, [app])

    if (!app) return null
    return (
        <Box
            flex
            background="white00"
            height="full"
            width="full"
            roundedLeft="2xl"
            overflow="hidden"
            boxShadow="xl"
        >
            <Sidebar
                app={app}
                selectedObject={selectedObject}
                setSelectedObject={setSelectedObject}
                selectedListView={selectedListView}
                setSelectedListView={setSelectedListView}
            />
            <Box flex column grow shrink>
                {selectedObject && (
                    <ObjectPreview
                        key={selectedObject.id}
                        object={selectedObject}
                        listView={selectedListView}
                        app={app}
                    />
                )}
            </Box>
        </Box>
    )
}

type SidebarProps = {
    app: AppSchemaType
    selectedObject: ObjectSchemaType | null
    setSelectedObject: (object: ObjectSchemaType | null) => void
    selectedListView?: ListViewSchemaType
    setSelectedListView: (listView: ListViewSchemaType | undefined) => void
}

function Sidebar({
    app,
    selectedObject,
    setSelectedObject,
    selectedListView,
    setSelectedListView,
}: SidebarProps) {
    return (
        <Box
            flex
            column
            borderRightWidth="base"
            background="gray50"
            noShrink
            minWidth="200px"
            maxWidth="300px"
        >
            <Box px="l" py="xl">
                <Headline size="xs">{app.name}</Headline>
            </Box>
            <Box flex column overflowY="auto" p="m" alignItems="stretch">
                {app.objects.map((object) => (
                    <>
                        {object.listViews && object.listViews.length > 1 ? (
                            <>
                                <Box px="m" fontSize="bodyS" style={{ opacity: 0.7 }} mb="s" mt="l">
                                    {object.name}
                                </Box>
                                {object.listViews?.map((x) => (
                                    <SidebarItem
                                        key={object.id}
                                        object={object}
                                        listView={x}
                                        selectedObject={selectedObject}
                                        setSelectedObject={setSelectedObject}
                                        selectedListView={selectedListView}
                                        setSelectedListView={setSelectedListView}
                                    />
                                ))}
                            </>
                        ) : (
                            <SidebarItem
                                key={object.id}
                                object={object}
                                selectedObject={selectedObject}
                                setSelectedObject={setSelectedObject}
                                setSelectedListView={setSelectedListView}
                            />
                        )}
                    </>
                ))}
            </Box>
        </Box>
    )
}

function SidebarItem({
    object,
    selectedObject,
    setSelectedObject,
    listView,
    selectedListView,
    setSelectedListView,
}: {
    object: ObjectSchemaType
    selectedObject: ObjectSchemaType | null
    setSelectedObject: (object: ObjectSchemaType | null) => void
    listView?: ListViewSchemaType
    selectedListView?: ListViewSchemaType
    setSelectedListView: (listView: ListViewSchemaType | undefined) => void
}) {
    const isSelected = selectedListView?.id === listView?.id && selectedObject?.id === object.id
    return (
        <Box
            p="m"
            role="button"
            onClick={() => {
                setSelectedObject(object)
                setSelectedListView(listView)
            }}
            rounded="s"
            background={isSelected ? 'gray200' : undefined}
            style={{
                fontWeight: isSelected ? 'bold' : 'normal',
            }}
            fontSize="bodyM"
        >
            {listView?.title || object.name}
        </Box>
    )
}

type PreviewComponentProps = {
    object: ObjectSchemaType
    listView?: ListViewSchemaType
    app: AppSchemaType
}

function ObjectPreview({
    object,
    listView,
    app,
}: {
    object: ObjectSchemaType
    listView?: ListViewSchemaType
    app: AppSchemaType
}) {
    const layout = listView?.layout || object.layout
    const statusField = object.fields?.find((x) => x.id === object.kanbanStatusField)
    let ObjectPreviewComponent: React.ComponentType<PreviewComponentProps> = TableView
    const previewProps: PreviewComponentProps = { object, listView, app }
    if (layout === 'kanban' && statusField) {
        ObjectPreviewComponent = BoardView
    } else if (layout === 'gallery') {
        ObjectPreviewComponent = CardView
    }
    return (
        <>
            <Box px="l" py="xl">
                <Headline size="xs" m="m">
                    {object.name}
                    {object.layout === 'kanban' && <span> (by {statusField?.name})</span>}
                </Headline>
            </Box>
            <Divider variant="weak" />
            <Box flex column grow overflow="auto" maxHeight="full" maxWidth="full" p="l">
                <ObjectPreviewComponent {...previewProps} />
            </Box>
        </>
    )
}

function makeGridTemplateColumns(fields: unknown[]) {
    return fields.map(() => 'minmax(max-content, auto)').join(' ')
}

function TableView({
    object,
    app,
}: {
    object: ObjectSchemaType
    listView?: ListViewSchemaType
    app: AppSchemaType
}) {
    const coverImageField = object.fields?.find((x) => x.type === 'image')

    const defaultFields = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]
    const fields = object.fields || defaultFields
    const nonCoverImageFields = fields.filter((field) => field.id !== coverImageField?.id)
    const gridTemplateColumns = makeGridTemplateColumns(nonCoverImageFields)

    return (
        <Box
            display="grid"
            width="full"
            fontSize="bodyM"
            style={{
                gridTemplateColumns,
            }}
        >
            <Row display="table-row" style={{ opacity: 0.7 }}>
                {nonCoverImageFields.map((field) => (
                    <Header key={field.id}>{field.name ? field.name : <Skeleton />}</Header>
                ))}
            </Row>
            {object.records ? (
                object.records.map((record) => (
                    <Row key={record.id}>
                        {nonCoverImageFields.map((field, idx) =>
                            field.id !== coverImageField?.id ? (
                                <Cell key={idx}>
                                    {idx === 0 && coverImageField && (
                                        <TableRowAvatar
                                            record={record}
                                            coverImageField={coverImageField}
                                        />
                                    )}
                                    <CellValue
                                        field={field}
                                        value={
                                            record.fields.find((f) => f.fieldId === field.id)?.value
                                        }
                                        app={app}
                                    />
                                </Cell>
                            ) : null
                        )}
                    </Row>
                ))
            ) : (
                <>
                    <Row>
                        {nonCoverImageFields.map((x) => (
                            <Cell key={x.id}>
                                <Skeleton />
                            </Cell>
                        ))}
                    </Row>

                    <Row>
                        {nonCoverImageFields.map((x) => (
                            <Cell key={x.id}>
                                <Skeleton />
                            </Cell>
                        ))}
                    </Row>
                    <Row>
                        {nonCoverImageFields.map((x) => (
                            <Cell key={x.id}>
                                <Skeleton />
                            </Cell>
                        ))}
                    </Row>
                </>
            )}
        </Box>
    )
}

function TableRowAvatar({
    record,
    coverImageField,
}: {
    record: RecordSchemaType
    coverImageField: FieldSchemaType
}) {
    const value = record.fields.find((f) => f.fieldId === coverImageField.id)?.value

    if (!value) {
        return (
            <Box
                background="gray200"
                flex
                center
                justifyContent="center"
                style={{
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '50%',
                    marginRight: '0.75rem',
                }}
            >
                <Icon name="Image" size="xl" opacity={0.2} />
            </Box>
        )
    }

    return (
        <img
            src={value?.toString()}
            alt="Image"
            style={{
                width: '2rem',
                height: '2rem',
                objectFit: 'cover',
                borderRadius: '50%',
                marginRight: '0.75rem',
            }}
        />
    )
}

function CardView({
    object,
    app,
}: {
    object: ObjectSchemaType
    listView?: ListViewSchemaType
    app: AppSchemaType
}) {
    const gridTemplateColumns = makeGridTemplateColumns([0, 1, 2])
    const coverImageField = object.fields?.find((x) => x.type === 'image')

    return (
        <Box
            display="grid"
            gap="m"
            width="full"
            fontSize="bodyM"
            style={{
                gridTemplateColumns,
            }}
        >
            {object.records
                ? object.records.map((record) => (
                      <Card key={record.id} border>
                          {coverImageField && (
                              <Box
                                  as="img"
                                  width="full"
                                  height="100px"
                                  style={{ objectFit: 'cover' }}
                                  src={record.fields
                                      .find((f) => f.fieldId === coverImageField.id)
                                      ?.value?.toString()}
                              />
                          )}
                          <Box flex column p="l" gap="s">
                              {record.fields.map((field, idx) =>
                                  field.fieldId === coverImageField?.id ? null : (
                                      <CellValue
                                          key={idx}
                                          field={object.fields.find((f) => f.id === field.fieldId)!}
                                          value={field.value}
                                          fontWeight={idx === 0 ? 'bodyBold' : undefined}
                                          app={app}
                                      />
                                  )
                              )}
                          </Box>
                      </Card>
                  ))
                : [0, 1, 2].map((x) => (
                      <Card key={x} border p="l">
                          <Skeleton mb="l" />
                          <Skeleton mb="l" />
                          <Skeleton mb="l" />
                          <Skeleton />
                      </Card>
                  ))}
        </Box>
    )
}

function BoardView({
    object,
    app,
}: {
    object: ObjectSchemaType
    listView?: ListViewSchemaType
    app: AppSchemaType
}) {
    const statusField = object.fields?.find((x) => x.id === object.kanbanStatusField)!
    const coverImageField = object.fields?.find((x) => x.type === 'image')

    return (
        <Box gap="m" width="full" height="full" fontSize="bodyM" flex overflowX="auto">
            {statusField.options.map((status, statusIdx) => (
                <Box
                    flex
                    column
                    key={status}
                    rounded="m"
                    background="gray100"
                    p="m"
                    width="200px"
                    noShrink
                    gap="l"
                    height="fit-content"
                >
                    <Headline size="3xs" pl="m">
                        {status}
                    </Headline>
                    {object.records
                        ? object.records
                              .filter(
                                  (x) =>
                                      x.fields.find((f) => f.fieldId === statusField.id)?.value ===
                                      status
                              )
                              .map((record) => (
                                  <Card key={record.id} border p="m">
                                      {coverImageField && (
                                          <Box
                                              as="img"
                                              width="5xl"
                                              height="5xl"
                                              rounded="pill"
                                              style={{ objectFit: 'cover' }}
                                              src={record.fields
                                                  .find((f) => f.fieldId === coverImageField.id)
                                                  ?.value?.toString()}
                                          />
                                      )}
                                      <Box flex column p="l" gap="s">
                                          {record.fields.map((field, idx) =>
                                              field.fieldId === coverImageField?.id ? null : (
                                                  <CellValue
                                                      size="2xs"
                                                      key={idx}
                                                      field={
                                                          object.fields.find(
                                                              (f) => f.id === field.fieldId
                                                          )!
                                                      }
                                                      value={field.value}
                                                      fontWeight={
                                                          idx === 0 ? 'bodyBold' : undefined
                                                      }
                                                      app={app}
                                                      fontSize="bodyS"
                                                  />
                                              )
                                          )}
                                      </Box>
                                  </Card>
                              ))
                        : Array.from({ length: 3 - statusIdx - 1 }).map((x, idx) => (
                              <Card key={idx} border p="l">
                                  <Skeleton mb="l" />
                                  <Skeleton mb="l" />
                                  <Skeleton mb="l" />
                                  <Skeleton />
                              </Card>
                          ))}
                </Box>
            ))}
        </Box>
    )
}

function CellValue({
    field,
    value,
    app,
    size = 'xs',
    ...props
}: BoxProps & {
    field: FieldSchemaType
    value: string | number | boolean | null | undefined
    app: AppSchemaType
    size?: '2xs' | 'xs'
}) {
    if (!value) return <Skeleton />

    try {
        if (field.type === 'multi_relationship') {
            return (
                <Box {...props}>
                    {value
                        ?.toString()
                        ?.split(',')
                        .map((x) => (
                            <LinkButton
                                key={x}
                                variant="secondary"
                                noShrink
                                mb="s"
                                mr="s"
                                trim
                                size={size}
                            >
                                {getRecordName(x, field.target!, app)}
                            </LinkButton>
                        ))}
                </Box>
            )
        } else if (field.type === 'relationship') {
            return (
                <Box {...props}>
                    <LinkButton variant="secondary" noShrink mb="s" mr="s" trim size={size}>
                        {getRecordName(value?.toString() || '', field.target!, app)}
                    </LinkButton>
                </Box>
            )
        } else if (field.type === 'multi_select') {
            return (
                <Box {...props}>
                    {value
                        ?.toString()
                        ?.split(',')
                        .map((x) => (
                            <Tag key={x} noShrink mb="s" mr="s" size={size}>
                                {x}
                            </Tag>
                        ))}
                </Box>
            )
        } else if (field.type === 'dropdown') {
            return (
                <Box {...props}>
                    <Tag noShrink mb="s" mr="s" size={size}>
                        {value}
                    </Tag>
                </Box>
            )
        } else if (field.type === 'image') {
            return (
                <Box {...props}>
                    <img src={value?.toString()} alt={field.name} style={{ height: '32px' }} />
                </Box>
            )
        }
        return <Box {...props}>{value}</Box>
    } catch (ex) {
        console.error(ex)
        return <Box {...props}>!!{value}</Box>
    }
}

function getRecordName(recordId: string, objectId: string, app: AppSchemaType) {
    const object = app.objects?.find((o) => o.id === objectId)
    if (!object) return recordId
    const record = object.records?.find((r) => r.id === recordId)
    if (!record) return recordId

    return record.fields[0].value
}

function Skeleton(props: BoxProps) {
    return (
        <Box
            background="gray200"
            width="full"
            grow
            rounded="m"
            style={{ minHeight: '1rem' }}
            {...props}
        ></Box>
    )
}

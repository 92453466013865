import { useCallback } from 'react'
import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query'

import shortid from 'shortid'

import { queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { RelatedToType } from 'data/hooks/activityTypes'
import { updateDocumentQuery } from 'data/hooks/documents'
import { updateTaskQuery } from 'data/hooks/tasks/tasks'
import { buildUrl, fetchAndReturn } from 'data/utils/utils'
import { FeedParameters } from 'features/Activity/types'

import { invalidateFollowingStatus } from './following'
import { ActivitiesData } from './types'
const LIST_NAME = 'activities'

/**
 *
 * @param filters
 * @param options
 * @param onActivityCreationDeletion is called after create/delete activity request is successful
 */
export function useActivities(
    filters?: FeedParameters,
    options: UseQueryOptions<ActivitiesData> = {},
    onActivityCreationDeletion?: () => void
) {
    const queryKey = useQueryKeyBuilder([LIST_NAME, filters], { includeAuthKeys: true })
    const queryResult = useQuery<ActivitiesData>(
        queryKey,
        async () => {
            const url = buildUrl(`activities/`, filters, true, false)
            const results = (await fetchAndReturn(url)) as ActivitiesData

            const existingData = {
                ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
            }
            // any pending new items get preserved.
            const newActivities =
                existingData?.activities?.filter((activity) => !!activity.pendingCreateId) ?? []
            results.activities = [...newActivities, ...(results.activities ?? [])]
            return results
        },
        options
    )

    const { mutateAsync: createActivity } = useCreateActivity(queryKey, onActivityCreationDeletion)
    const { mutateAsync: deleteActivity } = useDeleteActivity(queryKey, onActivityCreationDeletion)
    const { mutateAsync: updateActivity } = useUpdateActivity(queryKey)
    const retryFailedActivity = useRetryFailedPost(queryKey)
    return { ...queryResult, createActivity, retryFailedActivity, deleteActivity, updateActivity }
}

export function useCreateActivity(queryKey: QueryKey, onActivityCreationDeletion?: () => void) {
    return useMutation(
        async (activity: Partial<ActivityDto>) => {
            const results = (await fetchAndReturn(`activities/`, {
                method: 'POST',
                body: JSON.stringify(activity),
                headers: { 'Content-type': 'application/json' },
            })) as ActivityDto

            return results
        },
        {
            onMutate: (activity: Partial<ActivityDto>) => {
                const queryData = {
                    ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
                }

                if (!activity.pendingCreateId) {
                    activity.pendingCreateId = shortid.generate()

                    queryData.activities = [
                        activity as ActivityDto,
                        ...(queryData?.activities ?? []),
                    ]
                } else {
                    queryData.activities = queryData?.activities?.map((activity) =>
                        activity.pendingCreateId === activity.pendingCreateId
                            ? { ...activity, saveFailed: false }
                            : activity
                    )
                }

                queryClient.setQueryData(queryKey, queryData)
            },
            onSettled: (data, error, variables) => {
                if (data) {
                    onActivityCreationDeletion?.()

                    const queryData = {
                        ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
                    }

                    queryData.activities = [
                        ...(queryData?.activities?.filter(
                            (activity) =>
                                activity.pendingCreateId !== variables.pendingCreateId &&
                                activity.auto_id !== data.auto_id
                        ) ?? []),
                        data,
                    ]

                    queryClient.setQueryData(queryKey, queryData)

                    invalidateFollowingStatus()

                    if (data.related_to && data.related_to_type === RelatedToType.Task) {
                        updateTaskQuery(Number(data.related_to), (old) => ({
                            ...old,
                            _comment_count: (old._comment_count || 0) + 1,
                            _last_comment_at: new Date().toISOString(),
                        }))
                    }

                    if (data.related_to && data.related_to_type === RelatedToType.Document) {
                        const location = data.related_to_location

                        updateDocumentQuery(Number(data.related_to), (old) => {
                            const newPayload = {
                                ...old,
                                document: {
                                    ...old.document,
                                    _comment_count: (old.document?._comment_count || 0) + 1,
                                    _last_comment_at: new Date().toISOString(),
                                },
                            }

                            if (location) {
                                newPayload.document._comment_counts[location] =
                                    (old.document?._comment_counts[location] || 0) + 1
                            }

                            return newPayload
                        })
                    }
                }
            },
            onError: (err, variables) => {
                const queryData = {
                    ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
                }

                queryData.activities = queryData?.activities?.map((activity) =>
                    activity.pendingCreateId === variables.pendingCreateId
                        ? { ...activity, saveFailed: true }
                        : activity
                )
                queryClient.setQueryData(queryKey, queryData)
            },
        }
    )
}

export function useDeleteActivity(queryKey: QueryKey, onActivityCreationDeletion?: () => void) {
    return useMutation(
        async (id: number) => {
            await fetchAndReturn(`activities/${id}`, {
                method: 'DELETE',
            })
        },
        {
            onMutate: (id: number) => {
                const queryData = {
                    ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
                }
                queryData.activities = queryData?.activities?.map((activity) =>
                    activity.auto_id === id ? { ...activity, is_deleted: true } : activity
                )

                queryClient.setQueryData(queryKey, queryData)
            },
            onError: (err, id) => {
                const queryData = {
                    ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
                }
                queryData.activities = queryData?.activities?.map((activity) =>
                    activity.auto_id === id ? { ...activity, is_deleted: false } : activity
                )
                queryClient.setQueryData(queryKey, queryData)
            },
            onSuccess: (data, id) => {
                onActivityCreationDeletion?.()

                const queryData = {
                    ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
                }

                const activity = queryData?.activities?.find((activity) => activity.auto_id === id)

                if (
                    activity &&
                    activity.related_to &&
                    activity.related_to_type === RelatedToType.Task
                ) {
                    updateTaskQuery(Number(activity.related_to), (old) => ({
                        ...old,
                        _comment_count: (old._comment_count || 0) - 1,
                    }))
                }

                if (
                    activity &&
                    activity.related_to &&
                    activity.related_to_type === RelatedToType.Document
                ) {
                    const location = activity.related_to_location

                    updateDocumentQuery(Number(activity.related_to), (old) => {
                        const newPayload = {
                            ...old,
                            document: {
                                ...old.document,
                                _comment_count: (old.document?._comment_count || 0) - 1,
                            },
                        }

                        if (location) {
                            newPayload.document._comment_counts[location] =
                                (old.document?._comment_counts[location] || 0) - 1
                        }

                        return newPayload
                    })
                }
            },
        }
    )
}

export type UpdateActivityArgs = {
    id: number
    patch: Partial<ActivityDto>
    allowOptimisticUpdate?: boolean
}
export function useUpdateActivity(queryKey: QueryKey) {
    return useMutation(
        ({ id, patch }: UpdateActivityArgs) => {
            return fetchAndReturn(`activities/${id}`, {
                method: 'PATCH',
                body: JSON.stringify(patch),
                headers: { 'Content-type': 'application/json' },
            }) as Promise<ActivityDto>
        },
        {
            onMutate: ({ id, patch, allowOptimisticUpdate }: UpdateActivityArgs) => {
                if (!allowOptimisticUpdate) return
                const queryData = {
                    ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
                }
                const originalActivity = queryData?.activities?.find(
                    (activity) => activity.auto_id === id
                )
                const prePatchedFields = originalActivity
                    ? Object.keys(patch).reduce((acc, untypedKey) => {
                          const key = untypedKey as keyof Partial<ActivityDto>
                          acc[key] = originalActivity[key]
                          return acc
                      }, {} as Partial<ActivityDto>)
                    : {}

                queryData.activities = queryData?.activities?.map((activity) =>
                    activity.auto_id === id ? { ...activity, ...patch } : activity
                )

                queryClient.setQueryData(queryKey, queryData)
                return prePatchedFields
            },
            onError: (error, { id }, prePatchedFields) => {
                if (!prePatchedFields) return

                const queryData = {
                    ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
                }
                queryData.activities = queryData?.activities?.map((activity) =>
                    activity.auto_id === id ? { ...activity, ...{ prePatchedFields } } : activity
                )
                queryClient.setQueryData(queryKey, queryData)
            },
            onSuccess: (data, { id }) => {
                const queryData = {
                    ...(queryClient.getQueryData<ActivitiesData>(queryKey) ?? {}),
                }
                const returnedActivity = data as ActivityDto
                queryData.activities =
                    queryData?.activities?.map((activity) =>
                        activity.auto_id === id ? returnedActivity : activity
                    ) ?? []
                queryClient.setQueryData(queryKey, queryData)
            },
        }
    )
}

export function useRetryFailedPost(queryKey: QueryKey) {
    const { mutateAsync: createActivity } = useCreateActivity(queryKey)

    return useCallback(
        async (activity: Partial<ActivityDto>) => {
            if (!activity.auto_id) {
                return createActivity(activity)
            } else {
                // to do: retrying a failed edit
                throw new Error('Not implemented')
            }
        },
        [createActivity]
    )
}

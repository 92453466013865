import React, { useMemo, useState } from 'react'

import { orderBy } from 'lodash'

import { Rights } from 'app/accountUserContextConstants'
import { useAccountUserContext } from 'app/useAccountUserContext'
import { useAppContext } from 'app/useAppContext'
import { useAppUserContext } from 'app/useAppUserContext'
import {
    invalidateAppUserQuery,
    refetchWorkspaceUserList,
    useUpdateAccountUser,
    useWorkspaceUsers,
} from 'data/hooks/users/main'
import {
    UserAccessEditor,
    WorkspaceRoleLabel,
} from 'features/admin/settings/common/UserAccessEditor'
import { getWorkspaceRoles, isPortalUser } from 'features/auth/utils/roleUtils'
import InviteUsers from 'features/users/InviteUsers'
import { Divider } from 'features/workspace/WorkspaceSettingsModalUi'

import { Button, Flex, Icon, LoadingScreen, Modal, Text } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

const provideUserRole = (user) => user.account_role_api_name
const WorkspaceUsers = ({ formRef, children }) => {
    const { workspaceAccount } = useAppContext()
    const { user } = useAppUserContext()
    const { hasRight } = useAccountUserContext()
    const { data: allUsers = [], isLoading } = useWorkspaceUsers({ refetchOnMount: true })
    const [showWorkspaceRoleInfoModal, setShowWorkspaceRoleInfoModal] = useState(false)

    const users = useMemo(() => allUsers.filter((u) => !isPortalUser(u)), [allUsers])
    const roles = useMemo(
        () => getWorkspaceRoles(workspaceAccount?.roles),
        [workspaceAccount?.roles]
    )

    const roleOptions = useMemo(
        () =>
            roles.map((role) => {
                const is_superuser = role.options?.rights?.includes('*')
                return {
                    value: role.api_name,
                    label: role.label,
                    icon: is_superuser ? 'key' : 'avatar',
                    iconColor: is_superuser ? '#C9251B' : '#CECFD4',
                }
            }),
        [roles]
    )

    const { mutateAsync: updateAccountUsers } = useUpdateAccountUser()

    const saveChanges = (patch) => {
        const data = {
            users: Object.keys(patch).map((id) => ({
                user_id: id,
                delete: patch[id].deleted,
                options: { role: patch[id].role },
            })),
        }
        const hasOwner = users.find((user) =>
            patch[user._sid]
                ? patch[user._sid].role === 'owner' && !patch[user._sid].deleted
                : user.account_role_api_name === 'owner'
        )

        if (!hasOwner) {
            throw new Error('You need at least one owner')
        }
        return updateAccountUsers(data).then(() => {
            // if the current user was updated, refetch the updated
            // user record from the server
            if (patch[user._sid]) {
                invalidateAppUserQuery()
            }
            if (data.users.find((x) => x.delete)) {
                return refetchWorkspaceUserList()
            }
        })
    }
    // Sort by role, then by name/email
    const orderedUsers = useMemo(
        () =>
            orderBy(
                users,
                [(u) => u.account_role_api_name, (u) => (u.name || u.email).toLowerCase()],
                ['desc', 'asc']
            ),
        [users]
    )

    const showLabels = useResponsiveValue({
        mobile: false,
        tablet: true,
    })

    if (isLoading) {
        return <LoadingScreen isLoading={true} width="100%" height="100%" />
    }

    const buttons = ({ inEditMode, disabled }) => (
        <Button
            variant="adminSecondaryV4"
            icon="questionCircle"
            onClick={() => {
                setShowWorkspaceRoleInfoModal(true)
            }}
            display={inEditMode ? 'none' : 'flex'}
            disabled={disabled}
            aria-label="About collaborator types"
            mr={2}
        >
            {showLabels && 'About collaborator types'}
        </Button>
    )

    const workspaceRolesInfo = (
        <Modal
            isOpen={showWorkspaceRoleInfoModal}
            isSecondLayer
            title="About collaborator types"
            onClose={() => {
                setShowWorkspaceRoleInfoModal(false)
            }}
        >
            <Flex column align="stretch" width="100%">
                <WorkspaceRoleLabel
                    role={{ label: 'Owner', icon: 'key', iconColor: '#C9251B' }}
                    mb={2}
                    mt={1}
                    style={{ fontWeight: 'bold' }}
                />
                <Text variant="appSettingsBody">
                    Owners setup and control a Stacker Workspace. These collaborators manage the
                    workspace and its members.
                </Text>
                <Divider />
                <WorkspaceRoleLabel
                    role={{ label: 'Standard', icon: 'avatar', iconColor: '#CECFD4' }}
                    mb={2}
                    style={{ fontWeight: 'bold' }}
                />
                <Text variant="appSettingsBody" mb={2}>
                    Standard Collaborators are able to log in to the Workspace and access the Apps
                    shared with them.
                </Text>
                <Text variant="appSettingsBody" mb={2}>
                    By default Standard Collaborators can create new apps, and invite new Standard
                    Collaborators. These permissions can be configured in the 
                    <Icon
                        icon="cogs"
                        color={V4DesignSystem.colors.gray[400]}
                        size="12px"
                        style={{ display: 'inline' }}
                        ml={1}
                    />{' '}
                    <span style={{ fontWeight: 'bold' }}>Advanced</span> section of the Workspace
                    Settings.
                </Text>
            </Flex>
        </Modal>
    )

    return (
        <Flex column width="100%" height="100%" maxHeight="100%" wrap="nowrap" align="stretch">
            <InviteUsers width="100%" marginBottom="-5px" showInvitationSent={false} />
            <UserAccessEditor
                formRef={formRef}
                onSave={saveChanges}
                onlySubmitChangedUsers
                users={orderedUsers}
                disabled={!hasRight(Rights.ManageUsers)}
                roleOptions={roleOptions}
                provideUserRole={provideUserRole}
                renderAdditionalButtons={buttons}
            >
                {children}
            </UserAccessEditor>
            {workspaceRolesInfo}
        </Flex>
    )
}

export default WorkspaceUsers

import { useCallback, useState } from 'react'

import { THUMBNAILS_URL } from 'app/settings'

export const isVideoOrAudio = (attachment: Record<string, any>) => {
    const type = attachment?.type
    if (type?.startsWith('audio') || type?.startsWith('video')) return true
    const filename = attachment?.filename
    return /\.(mp4|mov|ogv|webm|ogg|oga|mp3|wav)$/i.test(filename?.toLowerCase())
}
export const getAttachmentUrlAndThumbnail = (attachment: Record<string, any>, size: string) => {
    const thumbnailSize = size === 'sm' ? 'small' : 'large'
    let url
    let filename
    let src

    if (typeof attachment === 'object') {
        url = attachment.url
        filename = attachment.filename || url
        if (attachment.thumbnails) {
            src = attachment.thumbnails[thumbnailSize].url
        }
    } else {
        // If multi_file contains an array of urls, we don't display any preview image because that doesn't contain thumbnails
        url = attachment
    }

    if (!src) {
        // Display custom icon for common file extensions (supported by icons8)
        if (/\.(pdf|zip|doc|txt|html|xls|ppt)$/i.test(filename)) {
            // File type is not that useful for making icons8 icons
            const extension = filename.split('.').slice(-1).pop()
            src = `https://img.icons8.com/ios/${size}/000000/${extension}.png`
        } else if (isVideoOrAudio(attachment?.filename)) {
            src = `https://img.icons8.com/ios/${size}/000000/video-file.png`
        } else {
            src = url
        }
    }

    return { url, thumbnail: src }
}

export function useThumbnail(attachment: Record<string, any>, size = 'sm') {
    const { url, thumbnail } = getAttachmentUrlAndThumbnail(attachment, size)
    const [src, setSrc] = useState(`${THUMBNAILS_URL}${encodeURIComponent(url)}`)
    const onError = useCallback(
        (e) => {
            // Try the specific thumbnail icon first
            if (src !== thumbnail) {
                setSrc(thumbnail)
            } else {
                e.target.onerror = null
                setSrc(`https://img.icons8.com/ios/${size}/000000/document.png`)
            }
        },
        [src, size, thumbnail]
    )

    return { url, src, onError }
}

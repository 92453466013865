import { UseQueryOptions } from 'react-query'

import { Aggregation, useAggregates } from 'data/hooks/aggregates'
import { useObject } from 'data/hooks/objects'

import { groupAggregateData } from './groupAggregateData'

const getAggregations = (aggr: MetricsQueryRequest['aggr'], fields: FieldDto[]): Aggregation[] => {
    const fieldApiName =
        aggr.type != 'count' ? fields.find((f) => f._sid === aggr.field_sid)?.api_name : undefined
    return [{ fieldApiName, type: aggr.type }]
}

const findFieldApiName = (sid: string | undefined, fields: FieldDto[]) =>
    fields.find((f) => f._sid === sid)?.api_name

export const useMetrics = (query: MetricsQueryRequest, options: UseQueryOptions<any> = {}) => {
    const { object } = useObject(query.object_sid)

    const primaryGroupFieldApiName = findFieldApiName(query.group.field_sid, object?.fields ?? [])
    const isInvalid = query.group?.type === 'value' && !primaryGroupFieldApiName
    const modernEnabled = (options.enabled ?? true) && !isInvalid

    const modernQueryOptions = {
        ...options,
        enabled: modernEnabled,
        refetchOnMount: 'always' as 'always',
    }

    const modernResult = useAggregates({
        objectId: query.object_sid,
        aggregations: object ? getAggregations(query.aggr, object.fields) : [],
        primaryGroupFieldApiName:
            query.group?.type === 'value'
                ? findFieldApiName(query.group.field_sid, object?.fields ?? [])
                : undefined,
        secondaryGroupFieldApiName: query.group?.group_field_sid
            ? findFieldApiName(query.group?.group_field_sid, object?.fields ?? [])
            : undefined,
        filters: query.filters,
        sort: query.sort,
        bucketBy: query.group?.bucketBy,
        limit: query.limit,
        previousTimePeriod: query.previousTimePeriod,
        options: modernQueryOptions,
    })

    return {
        ...modernResult,
        data: object
            ? groupAggregateData({
                  data: modernResult.data,
                  categories: query.categories,
                  aggregation: query.aggr,
                  fields: object.fields,
              })
            : undefined,
    }
}

import React from 'react'

import { Airtable } from 'v2/ui/svgs'

export const getIconForDataConnection = (dataConnection: DataConnectionDto | undefined) => {
    switch (dataConnection?.type) {
        case 'nango_airtable': {
            return <Airtable />
        }

        default: {
            return null
        }
    }
}

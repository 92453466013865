import { useCallback, useMemo } from 'react'

import { openWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'

import { useNavigationContext } from './useNavigationContext'

export function usePortalNavigationHeaderState() {
    const { permissions, showCreateAppModal } = useNavigationContext()

    const canEditPortalSettings = permissions.canEditPortalSettings
    const canCreateApp = permissions.canCreateApp

    const handleOpenPortalSettings = useCallback(() => {
        openWorkspaceSettingsModal({ page: 'general' })
    }, [])

    const handleCreateApp = useCallback(() => {
        showCreateAppModal()
    }, [showCreateAppModal])

    return useMemo(
        () => ({
            canEditPortalSettings,
            canCreateApp,
            handleOpenPortalSettings,
            handleCreateApp,
        }),
        [canEditPortalSettings, canCreateApp, handleOpenPortalSettings, handleCreateApp]
    )
}

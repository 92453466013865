import React from 'react'

import { Button } from 'ui/components/Button'

import { useMobileNavigationTriggerState } from './hooks/useMobileNavigationTriggerState'

type MobileNavigationTriggerProps = React.ComponentPropsWithoutRef<typeof Button> & {}

export const MobileNavigationTrigger: React.FC<MobileNavigationTriggerProps> = (props) => {
    const { openMobileNavigation } = useMobileNavigationTriggerState()

    return (
        <Button
            variant="ghost"
            size="s"
            startIcon={{ name: 'PanelLeftOpen' }}
            onClick={openMobileNavigation}
            {...props}
        />
    )
}

import { RecordEditManagerResult } from './useRecordEditManager'

export function isRecordPending(
    record: Partial<RecordDto>,
    recordManager: RecordEditManagerResult
): boolean {
    const { pendingNewRecords } = recordManager

    return pendingNewRecords.some((r) => r.record._sid === record._sid)
}

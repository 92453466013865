import { useMemo } from 'react'

import useLDFlags from 'data/hooks/useLDFlags'
import { useFavoritesEnabled } from 'features/favorites/useFavoritesEnabled'
import { useTasksEnabled } from 'features/tasks/useTasksEnabled'

import { useNavigationContext } from './useNavigationContext'

export function useSystemNavigationState() {
    const { isPortal } = useNavigationContext()
    const { flags } = useLDFlags()

    const showSearch = flags.search
    const showNotifications = !isPortal && flags.notifications
    const showTasks = useTasksEnabled()
    const showFavorites = useFavoritesEnabled()

    return useMemo(
        () => ({
            showSearch,
            showNotifications,
            showTasks,
            showFavorites,
        }),
        [showSearch, showNotifications, showTasks, showFavorites]
    )
}

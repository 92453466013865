import { useCallback } from 'react'

import { getRecords } from 'data/hooks/records/getRecords'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import { ContextItem } from './types'

export function useRecordItemsProvider() {
    const userObject = useStackerUsersObject()

    return useCallback(
        async (objectId: string, query?: string): Promise<ContextItem[]> => {
            const filters: Filter[] = []

            const includeFields =
                objectId === userObject?._sid
                    ? ['_primary', 'name', 'email', 'avatar']
                    : ['_primary']
            const fetchOptions = {
                includeFields,
                pageSize: 100,
                forcePartials: true,
                bypassPreviewAs: true,
            }

            if (query) {
                filters.push({
                    field: { api_name: '_search' },
                    options: { value: query, operator: 'AND' },
                })
            }

            const records = await getRecords(objectId, filters, fetchOptions)

            return (
                records?.map(
                    (record: RecordDto) =>
                        ({
                            id: record._sid,
                            name: record._primary,
                            type: 'string',
                            isLiteral: true,
                        }) as ContextItem
                ) ?? []
            )
        },
        [userObject?._sid]
    )
}

import React from 'react'

import classNames from 'classnames'
import { OpenRecordFunction, RowLinkFunction, ViewHeader, ViewRow } from 'v2/views/List/types'

import { ListRecordWrapper } from 'features/admin/edit-mode/ListView/ListRecordWrapper'

import { ConditionalWrapper, Flex, Link } from 'v2/ui'
import STYLE_CLASSES, { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { HoverContainerStyle } from 'ui/styles/hoverUtils.css'

import { RowsListCellList } from './RowsListCellList'
import { RowsListCoverImage } from './RowsListCoverImage'

type RowsListProps = {
    header?: ViewHeader[]
    data?: ViewRow[]
    rowLink?: RowLinkFunction
    hideFieldLabels?: boolean
    showEmptyValues?: boolean
    inboxView?: boolean
    useClassNames?: boolean
    object?: ObjectDto
    viewOptions?: ListViewOptions
    setConfig?: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    brandColor?: string
    selectedRow?: ViewRow
    isHovered?: boolean
    openRecord?: OpenRecordFunction
}

export const RowsList: React.FC<RowsListProps> = ({
    header = [],
    data = [],
    rowLink,
    hideFieldLabels = true,
    showEmptyValues,
    selectedRow,
    inboxView = false,
    brandColor,
    object,
    viewOptions,
    setConfig,
    useClassNames = true,
    isHovered,
    openRecord,
}) => {
    const getClassName = (className: string) => {
        if (!useClassNames) return undefined

        return className
    }

    return (
        <>
            {data.map(({ label, cells, coverImage, props, row, actionButtons }, index) => {
                const link = rowLink ? rowLink(row) : ''
                const isSelected = selectedRow?._sid === row.original._sid

                return (
                    <ConditionalWrapper
                        key={row.original._sid}
                        condition={typeof link !== 'undefined'}
                        wrapper={(children) => (
                            <Link
                                style={{
                                    boxShadow: inboxView ? 'none' : undefined,
                                }}
                                className={getClassName(
                                    `${index === 0 ? ONBOARDING_CLASSES.TABLE_ITEM : ''} ${
                                        STYLE_CLASSES.CARD_LINK
                                    }`
                                )}
                                variant="row"
                                to={link}
                                key={row.original._sid}
                                onClick={(e: React.MouseEvent) => {
                                    if (!e.ctrlKey && !e.metaKey && openRecord) {
                                        openRecord(row.original._sid)

                                        e.preventDefault()
                                        e.stopPropagation()
                                    }
                                }}
                            >
                                {children}
                            </Link>
                        )}
                    >
                        <ListRecordWrapper
                            recordId={row.original._sid}
                            h="100%"
                            highlight={isHovered}
                            style={{ borderTopWidth: index > 0 ? 0 : 1 }}
                        >
                            {({ isActive, lastHoveredRecordId }) => {
                                const editControlsVisible =
                                    isActive || (index === 0 && !lastHoveredRecordId)
                                return (
                                    <Flex
                                        className={classNames([
                                            getClassName(STYLE_CLASSES.CARD),
                                            HoverContainerStyle,
                                        ])}
                                        title={label}
                                        data-recordid={row.original._sid}
                                        variant="row"
                                        flexWrap="nowrap"
                                        position="relative"
                                        {...props}
                                        style={{
                                            borderRight: isSelected
                                                ? `5px solid ${brandColor}`
                                                : undefined,
                                            background: isSelected ? '#fbfdff' : undefined,
                                        }}
                                    >
                                        {inboxView ? (
                                            <>
                                                {coverImage && (
                                                    <RowsListCoverImage
                                                        coverImage={coverImage}
                                                        isInboxView={inboxView}
                                                    />
                                                )}

                                                <Flex
                                                    p={[2, null, null, 2]}
                                                    flex={1}
                                                    minWidth={0}
                                                    column={inboxView}
                                                    position="relative"
                                                    align="start"
                                                >
                                                    <RowsListCellList
                                                        header={header}
                                                        hideFieldLabels={hideFieldLabels}
                                                        showEmptyValues={showEmptyValues}
                                                        inboxView={inboxView}
                                                        object={object}
                                                        viewOptions={viewOptions}
                                                        setConfig={setConfig}
                                                        useClassNames={useClassNames}
                                                        editControlsVisible={editControlsVisible}
                                                        cells={cells}
                                                        row={row}
                                                    />
                                                </Flex>
                                            </>
                                        ) : (
                                            <Flex column align="left" flexGrow={1}>
                                                <Flex wrap="nowrap">
                                                    {coverImage && (
                                                        <RowsListCoverImage
                                                            coverImage={coverImage}
                                                            isInboxView={inboxView}
                                                        />
                                                    )}
                                                    <Flex column align="start" flexGrow={1}>
                                                        <Flex
                                                            p={[2, null, null, 2]}
                                                            flex={1}
                                                            minWidth={0}
                                                            column={inboxView}
                                                            flexGrow={1}
                                                            width="100%"
                                                        >
                                                            <RowsListCellList
                                                                header={header}
                                                                hideFieldLabels={hideFieldLabels}
                                                                showEmptyValues={showEmptyValues}
                                                                inboxView={inboxView}
                                                                object={object}
                                                                viewOptions={viewOptions}
                                                                setConfig={setConfig}
                                                                useClassNames={useClassNames}
                                                                editControlsVisible={
                                                                    editControlsVisible
                                                                }
                                                                cells={cells}
                                                                row={row}
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                {actionButtons && (
                                                    <Flex mb={4}>{actionButtons}</Flex>
                                                )}
                                            </Flex>
                                        )}
                                    </Flex>
                                )
                            }}
                        </ListRecordWrapper>
                    </ConditionalWrapper>
                )
            })}
        </>
    )
}

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeToggleVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        size: {
            controlWidth: pxToRem(30),
            controlHeight: pxToRem(16),
            thumb: pxToRem(12),
        },
        radius: s.radius.pill,
        borderWidth: s.borderWidths[1],
        borderStyle: 'solid',
        color: {
            thumb: s.color.iconInverse,
            thumbDisabled: s.color.gray500,

            surfaceUnchecked: s.color.gray200,
            surfaceUncheckedHover: s.color.gray300,
            surfaceUncheckedDisabled: s.color.gray300,
            surfaceChecked: s.color.theme600,
            surfaceCheckedHover: s.color.theme800,
            surfaceCheckedFocus: s.color.theme600,
            surfaceCheckedDisabled: s.color.gray300,
            surfaceCheckedError: s.color.surfaceError,

            borderChecked: s.color.transparent,
            borderUnchecked: s.color.transparent,
            borderError: s.color.borderError,
        },
        shadows: {
            focus: s.shadows.focus,
        },
        gap: {
            label: s.space.m,
            thumb: s.space['2xs'],
        },
    }
}

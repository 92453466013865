import React from 'react'

import { Editor } from '@tiptap/core'

import * as Parts from 'ui/components/Textarea/Textarea.parts'

import { TextareaToolbarItem } from './TextareaToolbarItem'

type TextareaToolbarListFormatProps = React.ComponentPropsWithoutRef<typeof Parts.ToolbarGroup> & {
    editor: Editor
    disabled?: boolean
}

export const TextareaToolbarListFormat: React.FC<TextareaToolbarListFormatProps> = ({
    editor,
    disabled,
    ...props
}) => {
    return (
        <Parts.ToolbarGroup aria-label="List format" {...props}>
            <TextareaToolbarItem
                icon={{ name: 'List' }}
                label="Bullet List"
                isActive={editor.isActive('bulletList')}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                disabled={disabled || !editor.can().toggleBulletList()}
            />
            <TextareaToolbarItem
                icon={{ name: 'ListOrdered' }}
                label="Ordered List"
                isActive={editor.isActive('orderedList')}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                disabled={disabled || !editor.can().toggleOrderedList()}
            />
        </Parts.ToolbarGroup>
    )
}

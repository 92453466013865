import { makeAppVariables } from './app'

export function makeSplitButtonVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surfacePrimaryActive: s.color.theme800,
            surfaceSecondaryActive: s.color.darkAlpha50,

            borderPrimaryActive: s.color.theme800,
            borderSecondary: s.color.gray300,
        },
        padding: {
            '2xsDropdown': s.space.xs,
            xsDropdown: s.space.xs,
            sDropdown: s.space.xs,
            mDropdown: s.space.m,
            lDropdown: s.space.m,
        },
    }
}

/**
 * Simulate a change in the value of an input element, as it if was done through a React event.
 * @param input
 * @param value
 */
export function simulateInputValueChange(
    input: HTMLInputElement | HTMLTextAreaElement,
    value: string
) {
    // Get the native setter for the `value` property of the input element.
    let nativeInputValueSetter: (v: any) => void
    if (input instanceof HTMLTextAreaElement) {
        nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLTextAreaElement.prototype,
            'value'
        )!.set!
    } else {
        nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            'value'
        )!.set!
    }
    nativeInputValueSetter.call(input, value)

    // Simulate input change.
    const changeEvent = new Event('input', { bubbles: true })
    input?.dispatchEvent(changeEvent)
}

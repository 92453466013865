import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useConfirmModal } from 'app/useConfirmModal'
import { deleteField } from 'data/hooks/fields'
import { updateObject } from 'data/hooks/objects/objectOperations'
import { Icon } from 'legacy/v1/ui'
import { getIsSyntheticField } from 'utils/fieldUtils'

import { Text } from 'v2/ui'
import { DeleteField } from 'v2/ui/svgs'
import { colors } from 'v2/ui/theme/styles/default'

export const useConfirmDeleteFieldModal = (
    object: ObjectDto | undefined,
    onAfterDelete?: () => unknown
) => {
    const [fieldForDeletion, setFieldForDeletion] = useState<FieldDto | undefined>(undefined)
    const [deletionError, setDeletionError] = useState<string | undefined>(undefined)

    const deletionModalData = useMemo(
        () => ({
            zIndex: 9999,
            title: `Delete ${fieldForDeletion?.label}?`,
            titleStyle: { colors: colors.userInterface.neutral[1000] },
            icon: <Icon svg={<DeleteField />} size="56px" ml="15px" mb={4} />,

            confirmVariant: 'PrimaryError',
            message: (
                <>
                    <Text style={{ color: colors.userInterface.neutral[900] }}>
                        Are you sure you want to delete this {fieldForDeletion?.label.toLowerCase()}
                        ?
                    </Text>

                    {getIsSyntheticField(fieldForDeletion) ? (
                        <Text mt={4} fontWeight="bold">
                            This action cannot be undone.
                        </Text>
                    ) : (
                        <Text mt={4} fontWeight="bold">
                            This will also delete all data contained in this{' '}
                            {fieldForDeletion?.label.toLowerCase()}. This action cannot be undone.
                        </Text>
                    )}

                    {deletionError && (
                        <Text mt={4} variant="error">
                            {deletionError}
                        </Text>
                    )}
                </>
            ),
            onConfirm: (modal: any) => {
                if (!fieldForDeletion) {
                    throw 'No field set for deletion'
                }
                setDeletionError(undefined)
                return deleteField(fieldForDeletion._sid, undefined)
                    .then(() => {
                        if (onAfterDelete) onAfterDelete()
                        if (!object) {
                            return
                        }
                        return afterFieldDeletedUpdateObject(fieldForDeletion, object)
                    })
                    .then(() => modal.toggle())
                    .catch(() => {
                        setDeletionError('Failed to delete the field')
                    })
            },
            onCancel: (modal: any) => {
                setDeletionError(undefined)
                modal.toggle()
            },
        }),
        [deletionError, fieldForDeletion, object, onAfterDelete]
    )

    const { show: showConfirm, setData } = useConfirmModal(deletionModalData)

    useEffect(() => {
        setData(deletionModalData)
        // This will trigger the modal to show the error message if the error message changes
    }, [deletionModalData, setData])

    const showConfirmDeleteForField = useCallback(
        (field: FieldDto) => {
            setFieldForDeletion(field)
            showConfirm(deletionModalData)
        },
        [deletionModalData, showConfirm]
    )

    return { showConfirmDeleteForField }
}

const afterFieldDeletedUpdateObject = async (deletedField: FieldDto, object: ObjectDto) => {
    if (!object?.options?.fields_order) return

    await updateObject(
        object._sid,
        {
            ...object,
            options: {
                ...object.options,
                fields_order: object.options.fields_order.filter(
                    (f) => f !== deletedField.api_name
                ),
            },
        },
        {
            allowOptimisticUpdates: true,
        }
    )
}

import React from 'react'

import { refetchFields } from 'data/hooks/fields'
import { useUpdateStack } from 'data/hooks/stacks'
import { ExpandSection } from 'legacy/v1/ui'

import { Button } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { Box } from 'ui/components/Box'

type DeleteAppSettingProps = {
    selectedStack: StackDto
    stacks: StackDto[]
    deleteStack: any
    isStackSubscribed: boolean
}

export const ExposePermanentLoginLinksSetting = ({ selectedStack }: DeleteAppSettingProps) => {
    const { mutateAsync: updateStack } = useUpdateStack()
    return (
        <ExpandSection
            heading="User Login Links"
            helpUrl=""
            helpText=""
            text={null}
            classes={{ heading: ONBOARDING_CLASSES.APP_SETTINGS_GENERAL.DELETE_APP }}
            testId="general-settings.delete-app"
        >
            {/*@ts-expect-error*/}
            <>
                <Box height="l" />
                <Button
                    variant="Secondary"
                    buttonSize="sm"
                    disabled={!selectedStack._sid}
                    style={{ alignSelf: 'flex-start' }}
                    mt="l"
                    onClick={async () => {
                        await updateStack({
                            id: selectedStack._sid,
                            patch: {
                                expose_permanent_login_links:
                                    !selectedStack.expose_permanent_login_links,
                            },
                        })

                        await refetchFields()
                    }}
                >
                    {selectedStack.expose_permanent_login_links
                        ? 'Remove Login Links from Users Table'
                        : 'Show Login Links in Users Table'}
                </Button>
            </>
        </ExpandSection>
    )
}

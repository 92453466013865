import React, { useCallback } from 'react'

import { isEqual } from 'lodash'

import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { LinkButton } from 'ui/components/LinkButton'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

import { useUserAttributeDisplayState } from './hooks/useUserAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type UserAttributeTagProps = React.ComponentPropsWithoutRef<typeof LinkButton> & {
    maxLines?: number
    maxLength?: number
    isLoading?: boolean
    showIcon?: boolean
}

export const UserAttributeTag: AttributeDisplayComponent<UserRefDto, UserAttributeTagProps> =
    React.memo(function UserAttributeTag({
        field,
        value,
        onClick,
        maxLength,
        maxLines,
        isLoading,
        style,
        showIcon,
        ...props
    }) {
        const {
            value: effectiveValue,
            isByStackerSystem,
            icon,
        } = useUserAttributeDisplayState({
            field,
            value,
            isLoading,
            showIcon,
        })

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLAnchorElement>) => {
                e.stopPropagation()

                onClick?.(e)
            },
            [onClick]
        )

        if (!effectiveValue) return null

        const { firstName, lastName, to } = effectiveValue[0]

        let startIcon: IconNameVariantType | undefined = icon
        if (!showIcon) {
            startIcon = isByStackerSystem ? undefined : { name: 'UserRound' }
        }

        return (
            <Skeleton isLoading={isLoading}>
                <LinkButton
                    to={to}
                    flex
                    center
                    onClick={handleClick}
                    size="2xs"
                    variant="secondary"
                    startIcon={startIcon}
                    noShrink
                    style={{
                        wordBreak: 'break-word',
                        ...style,
                    }}
                    pointerEvents={isByStackerSystem ? 'none' : 'auto'}
                    {...props}
                >
                    <Body
                        style={{
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            lineHeight: 'inherit',
                        }}
                        maxLength={maxLength}
                        maxLines={maxLines}
                    >
                        {firstName} {lastName}
                    </Body>
                </LinkButton>
            </Skeleton>
        )
    }, isEqual)

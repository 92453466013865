import {
    DROPDOWN_COLORS,
    DROPDOWN_TYPES,
    IMAGE_FIELD_TYPES,
    LINK_TYPES,
    TEXT_COLORS,
} from 'data/utils/fieldDefinitions'
import { COLORS_ORDER } from 'features/admin/fields/DropdownFieldConfiguration/constants'
import { FIELD_NAME_REGEX } from 'features/formulas/constants/formulaRegexConstants'

import { colorBaseConfig } from 'v2/ui/theme/styles/default/colors'

import { mapV2ColorNameToColorScheme } from 'ui/styling/helpers/ThemeInitializer'

export const getIsSyntheticField = (field?: Partial<FieldDto>): boolean =>
    !!field?.synthetic_field_type

export const isFormulaField = (field?: Partial<FieldDto>): boolean =>
    field?.synthetic_field_type === 'formula'

export const getIsRelationshipField = (field?: Partial<FieldDto>): boolean =>
    field?.synthetic_field_type === 'relationship'

export const getIsSymmetricRelationshipField = (field?: Partial<FieldDto>): boolean =>
    field?.synthetic_field_type === 'symmetric_relationship'

export const getIsMultiLookupRelationship = (field?: Partial<FieldDto>): boolean =>
    field?.type === 'multi_lookup' && getIsRelationshipField(field)

export const getIsSingleLinkField = (field?: Partial<FieldDto>): boolean => field?.type === 'lookup'
export const getIsMultiLinkField = (field?: Partial<FieldDto>): boolean =>
    field?.type === 'multi_lookup'

export const getIsLinkField = (field?: Partial<FieldDto>): boolean =>
    getIsSingleLinkField(field) || getIsMultiLinkField(field)

export const getIsMultiLookupSymmetricRelationship = (field?: Partial<FieldDto>): boolean =>
    field?.type === 'multi_lookup' && getIsSymmetricRelationshipField(field)

export const getSymmetricUnderlyingLinkField = (
    field: Partial<FieldDto>,
    objects: ObjectDto[]
): FieldDto | undefined => {
    const targetObj = objects.find((obj) => obj._sid === field?.link_target_object_id)
    return targetObj?.fields.find(
        (f) => field.connection_options?.relationship_synthetic_lookup_field === f._sid
    )
}

export const getIsFieldLink = (field: FieldDto): boolean => LINK_TYPES.includes(field.type)

export const getIsDropdownField = (field: FieldDto): boolean => DROPDOWN_TYPES.includes(field.type)

export function getDefaultDropdownFieldOptionColorName(): string {
    return COLORS_ORDER[0]
}

export const getDefaultDropdownFieldOptionColor = (): string => {
    const defaultColorName = getDefaultDropdownFieldOptionColorName()

    return DROPDOWN_COLORS[defaultColorName]
}

export function getDropdownFieldOptionColorName(
    fieldOption: Partial<FieldOptions>
): string | undefined {
    return fieldOption.color ?? undefined
}

export const getDropdownFieldOptionColor = (
    fieldOption: Partial<FieldOptions>
): string | undefined => {
    if (fieldOption.color) {
        const colorScheme = convertV2FieldColorToTheme(fieldOption.color)

        return DROPDOWN_COLORS[colorScheme] || getDefaultDropdownFieldOptionColor()
    }
    return undefined
}

export function getDropdownFieldOptionTextColor(
    fieldOption: Partial<FieldOptions>
): string | undefined {
    const { color } = fieldOption

    if (color) {
        const textColor = TEXT_COLORS[color]
        if (textColor) {
            return textColor
        }

        return TEXT_COLORS[COLORS_ORDER[0]]
    }

    return undefined
}

const formulaSupportedFields: Set<FieldType> = new Set([
    'checkbox',
    'currency',
    'date',
    'datetime',
    'dropdown',
    'image',
    'long_text',
    'lookup',
    'multi_file',
    'multi_lookup',
    'multi_select',
    'number',
    'percentage',
    'string',
    'url',
    'user_ref',
] as FieldType[])

export const isFormulaSupportedField = (field: FieldDto): boolean =>
    formulaSupportedFields.has(field.type)

export const isDisabled = (field: FieldDto): boolean => !!field.connection_options.is_disabled

export const isFieldParseable = (field: FieldDto): boolean =>
    !!`{${field.label}}`.match(FIELD_NAME_REGEX)

export const getCoverImageFields = (fields: FieldDto[]) =>
    fields
        .filter((field) => !field.connection_options.is_disabled)
        .filter((field) => IMAGE_FIELD_TYPES.includes(field.type))

export const getDocumentFields = (fields: FieldDto[]) =>
    fields
        .filter((field) => !field.connection_options.is_disabled)
        .filter((field) => field.type === 'document')

export const getIsUserRefField = (field: FieldDto): boolean => field.type === 'user_ref'

const allowedPrimaryFieldTypes = new Set<FieldType>([
    'string',
    'url',
    'number',
    'currency',
    'percentage',
    'date',
    'datetime',
])

export const canBePrimaryField = (field?: FieldDto) => {
    if (!field || field?.connection_options?.is_disabled) return false

    return allowedPrimaryFieldTypes.has(field?.type)
}

export const canBeExternalIdField = (field?: FieldDto) => {
    if (!field || field?.connection_options?.is_disabled || field?.synthetic_field_type)
        return false

    return field.type === 'string'
}

export const canBeHeaderField = (field: FieldDto) => {
    const disallowedTypes = ['multi_lookup', 'lookup', 'multi_file', 'multi_select']
    return !disallowedTypes.includes(field.type) && !field?.connection_options?.is_disabled
}

export function convertV2FieldColorToTheme(
    v2Color: string
): (typeof COLORS_ORDER)[number] | string {
    if (v2Color.startsWith('#')) return v2Color

    const mappedColor = mapV2ColorNameToColorScheme(v2Color)
    if (mappedColor) return mappedColor as (typeof COLORS_ORDER)[number]

    // If this is a v2 color without any mapping.
    const v2ColorNames = new Set(Object.keys(colorBaseConfig.appColors))
    if (v2ColorNames.has(v2Color)) {
        return 'Neutral'
    }

    // This is a new color scheme that doesn't exist in v2.
    return v2Color
}

import React, { FC } from 'react'

import { AggregationEditor } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/AggregationEditor'
import { ViewLayoutContext } from 'v2/blocks/types'

import { DataPanel } from './panels/DataPanel'
import { DisplayPanel } from './panels/DisplayPanel'
import { FiltersPanel } from './panels/FiltersPanel'

type MetricEditorProps = {
    metric: MetricsBlockAttributes
    onChange: (
        metric: Pick<MetricsBlockAttributes, 'objectId'> & Partial<MetricsBlockAttributes>
    ) => void
    currentObject: ObjectDto
    context: ViewLayoutContext
}

export const MetricEditor: FC<MetricEditorProps> = ({
    metric,
    onChange,
    currentObject,
    context,
}) => {
    if (!metric) {
        return null
    }

    return (
        <AggregationEditor
            aggregationWidget={metric}
            dataPanel={
                <DataPanel
                    context={context}
                    currentObject={currentObject}
                    metric={metric}
                    onChange={onChange}
                />
            }
            displayPanel={<DisplayPanel metric={metric} onChange={onChange} />}
            filtersPanel={<FiltersPanel metric={metric} onChange={onChange} />}
        />
    )
}

import { BackendFilter } from 'data/api/recordApiTypes'

import { preprocessChartsFilterValue } from './preprocessChartsFilterValue'

export const chartsFiltersToQueryDict = (filters: ChartFilter[]): { filters: BackendFilter[] } => {
    const processedFilters: BackendFilter[] = filters.map((filter) => {
        const value = preprocessChartsFilterValue(filter)
        return {
            target: filter.target ?? '',
            operation: filter.operation,
            value,
        }
    })

    return { filters: processedFilters }
}

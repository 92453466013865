import { useCallback, useMemo } from 'react'

import { operationRequiresUserInput } from 'features/views/ListView/ListHeader/Filters/Advanced/operations'
import { AdvancedFilter } from 'features/views/ListView/ListHeader/Filters/Advanced/types'

export type OperationOption = { label: string; value: string }

type UseOperationDropdownStateOptions = {
    field: FieldDto
    value: AdvancedFilter['options']['option']
    options: OperationOption[]
    onChange: (value: string) => void
}

export function useOperationDropdownState(options: UseOperationDropdownStateOptions) {
    const { options: opOptions, onChange, field, value } = options

    const onCheckedChange = useCallback(
        (checked: boolean, option: OperationOption) => {
            if (checked) {
                onChange(option.value)
            }
        },
        [onChange]
    )

    const activeOption = opOptions.find((option) => option.value === value)

    const label = activeOption?.label || 'Select operation'

    const requiresValue = value
        ? operationRequiresUserInput(field, value as FilterOperation)
        : false

    return useMemo(
        () => ({
            onCheckedChange,
            activeOption,
            label,
            requiresValue,
            value,
        }),
        [activeOption, label, onCheckedChange, requiresValue, value]
    )
}

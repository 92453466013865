import { RichTextValue } from 'features/views/attributes/types'

type UseRichTextAttributeDisplayStateProps = {
    value?: RichTextValue
    maxLength?: number
    isLoading?: boolean
}

export function useRichTextAttributeDisplayState({
    value,
    maxLength,
    isLoading,
}: UseRichTextAttributeDisplayStateProps) {
    const content = isLoading ? PLACEHOLDER_VALUE : (value?.plainTextContent ?? '')

    const isOverflowing = maxLength && content.length > maxLength

    return { content, isOverflowing }
}

const PLACEHOLDER_VALUE =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus sit amet nunc tincidunt aliquam.'

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react'

import { filterGreaterThan } from 'features/views/List/filterFunctions'

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

// Define a default UI for filtering
export function DefaultColumnFilter({ filterValue, setFilter }) {
    return (
        <input
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder="Search..."
        />
    )
}

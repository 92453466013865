import React from 'react'

import { Box } from 'ui/components/Box'
import { ScrollArea } from 'ui/components/ScrollArea'
import { stopPropagation } from 'ui/helpers/utilities'

import { useAppNavigationContext } from './hooks/useAppNavigationContext'
import { usePortalAppsState } from './hooks/usePortalAppsState'
import { AppNavigationContextProvider } from './AppNavigationContextProvider'
import { AppNavigationTree } from './AppNavigationTree'
import { NavigationAppsItem } from './NavigationAppsItem'
import { NavigationApp } from './types'
import { WorkspaceNavigationAppAddNewDropdown } from './WorkspaceNavigationAppAddNewDropdown'
import { WorkspaceNavigationAppDropdown } from './WorkspaceNavigationAppDropdown'

import { NavigationItemHoverStyle } from './Navigation.css'

type PortalAppsRef = HTMLDivElement

type PortalAppsProps = {}

export const PortalApps = React.forwardRef<PortalAppsRef, PortalAppsProps>((props, ref) => {
    const { apps, selectedAppId } = usePortalAppsState()

    return (
        <ScrollArea px="s" pb="xl" direction="vertical" width="full" ref={ref} {...props}>
            <Box gap="2xs" flex column width="full">
                {apps.map((app) => (
                    <PortalAppItemWithContext
                        key={app.id}
                        app={app}
                        isActive={app.id === selectedAppId}
                    />
                ))}
            </Box>
        </ScrollArea>
    )
})

type PortalAppItemProps = {
    app: NavigationApp
    isActive?: boolean
}

const PortalAppItem: React.FC<PortalAppItemProps> = ({ app, isActive }) => {
    const { menuItems } = useAppNavigationContext()

    const hasChildren = menuItems.length > 0

    return (
        <Box>
            <NavigationAppsItem
                app={app}
                isCollapsed={false}
                rightSlotContent={
                    isActive && (
                        <Box
                            flex
                            center
                            pr="xs"
                            onClick={stopPropagation}
                            className={NavigationItemHoverStyle}
                        >
                            <WorkspaceNavigationAppDropdown app={app} size="2xs" />
                            <WorkspaceNavigationAppAddNewDropdown app={app} size="2xs" />
                        </Box>
                    )
                }
                isActive={isActive && !hasChildren}
                weight="bold"
            />
            {isActive && <AppNavigationTree mt="2xs" />}
        </Box>
    )
}

const PortalAppItemWithContext: React.FC<PortalAppItemProps> = ({ app, isActive }) => {
    return (
        <AppNavigationContextProvider app={app}>
            <PortalAppItem app={app} isActive={isActive} />
        </AppNavigationContextProvider>
    )
}

import { makeAppVariables } from './app'

export function makeBannerVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        colors: {
            surfaceInfo: s.color.theme100,
            surfaceError: s.color.error100,
            surfaceWarning: s.color.warning100,
            surfaceSuccess: s.color.success100,
            surfaceNeutral: s.color.surface,
            iconInfo: s.color.theme600,
            iconError: s.color.error600,
            iconWarning: s.color.warning600,
            iconSuccess: s.color.success600,
            iconNeutral: s.color.gray500,
            dismissIcon: s.color.icon,
            dismissIconHover: s.color.gray900,
            dismissIconActive: s.color.gray600,
            title: s.color.text,
            helper: s.color.textWeak,
            link: s.color.linkText,
            borderNeutral: s.color.border,
        },
        padding: {
            surface: s.space.xl,
        },
        fontSizes: {
            title: s.fontSizes.bodyL,
            helper: s.fontSizes.bodyM,
            link: s.fontSizes.bodyS,
        },
        lineHeights: {
            title: s.lineHeights.bodyL,
            helper: s.lineHeights.bodyM,
            link: s.lineHeights.bodyS,
        },
        letterSpacings: {
            title: s.letterSpacing.bodyL,
            helper: s.letterSpacing.bodyM,
            link: s.letterSpacing.bodyS,
        },
        fontWeights: {
            title: s.fontWeights.bodyBold,
            helper: s.fontWeights.bodyRegular,
            link: s.fontWeights.bodyMedium,
        },
        gap: {
            vertical: s.space.s,
            horizontal: s.space.xl,
            betweenBanners: s.space.m,
        },
        borderWidths: {
            container: s.borderWidths[1],
        },
        borderStyles: {
            container: s.borderStyles.base,
        },
    }
}

const defaultRowSize = {
    medium: 3,
    large: 2,
    full_width: 1,
}

export const getPageSizeFromRows = (options: ListViewOptions, columnCount?: number): number => {
    const rowsPerPage = options.cardPageRows || 6
    const cardsPerRow = columnCount || defaultRowSize[options.cardCardSize || 'medium']

    return rowsPerPage * cardsPerRow
}

import React from 'react'

import { Box } from 'ui/components/Box'

type ExecutionHistoryStatusLabelProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'name'> & {
    status?: WorkflowExecutionStatus
}

export const ExecutionHistoryStatusLabel: React.FC<ExecutionHistoryStatusLabelProps> = ({
    status,
    ...props
}) => {
    const color = mapStatusToColor(status)
    const label = mapStatusToLabel(status)
    const bgColor = mapStatusToBackgroundColor(status)

    return (
        <Box
            color={color}
            background={bgColor}
            py="2xs"
            px="xs"
            borderRadius="s"
            noShrink
            {...props}
        >
            {label}
        </Box>
    )
}

function mapStatusToColor(
    status?: WorkflowExecutionStatus
): React.ComponentPropsWithoutRef<typeof Box>['color'] {
    switch (status) {
        case 'failed':
            return 'error700'
        case 'success':
            return 'success700'
        case 'running':
            return 'text'
        default:
            return 'textWeak'
    }
}

function mapStatusToBackgroundColor(
    status?: WorkflowExecutionStatus
): React.ComponentPropsWithoutRef<typeof Box>['bgColor'] {
    switch (status) {
        case 'failed':
            return 'error100'
        case 'success':
            return 'success100'
        case 'running':
            return 'surfaceStrongest'
        default:
            return 'surfaceStronger'
    }
}

function mapStatusToLabel(status?: WorkflowExecutionStatus): string {
    switch (status) {
        case 'failed':
            return 'Failed'
        case 'running':
            return 'In progress'
        case 'success':
            return 'Succeeded'
        case 'timed_out':
            return 'Timed out'
        case 'waiting':
            return 'Waiting'
        default:
            return 'Unknown'
    }
}

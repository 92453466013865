export enum FavoriteType {
    App = 0,
    NavigationItem = 1,
    CreateForm = 2,
    Record = 3,
    Document = 4,
}

export type FavoritesData = {
    favorites: FavoriteDto[]
    navigation_items: Partial<NavigationDto>[]
    objects: Partial<ObjectDto>[]
    object_urls: { [key: string]: string }
    records: Partial<RecordDto>[]
    views: Partial<ViewDto>[]
    documents: Partial<DocumentDto>[]
}

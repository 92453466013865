import React from 'react'

import { useAppUsers } from 'data/hooks/users/main'
import { UserDisplay } from 'features/users/shared/UserDisplay'

import { Box } from 'ui/components/Box'

type ConnectionAuthorProps = React.ComponentPropsWithoutRef<typeof Box> & {
    userId: string
}

export const ConnectionAuthor: React.FC<ConnectionAuthorProps> = ({ userId, ...props }) => {
    const { data: users } = useAppUsers()

    const author = (users as UserDto[])?.find((u) => u._sid === userId)
    if (!author) return null

    return (
        <Box fontSize="bodyS" {...props}>
            <UserDisplay user={author} />
        </Box>
    )
}

import React from 'react'

import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

import { useCurrencyAttributeDisplayState } from './hooks/useCurrencyAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type CurrencyAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Body> & {
    isLoading?: boolean
}

export const CurrencyAttributeDisplay: AttributeDisplayComponent<
    number,
    CurrencyAttributeDisplayProps
> = React.memo(function CurrencyAttributeDisplay({ value, field, isLoading, ...props }) {
    const { content } = useCurrencyAttributeDisplayState({ value, field, isLoading })

    return (
        <Skeleton isLoading={isLoading}>
            <Body size="m" weight="regular" {...props}>
                {content}
            </Body>
        </Skeleton>
    )
})

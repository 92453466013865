import { useAuthContext } from 'app/AuthContext/AuthContext'

import { useQuery } from './_helpers'

const ENDPOINT = 'account/account-user/'
export function useWorkspaceUser(accountSid?: string) {
    const { user, setUser, isEmailVerified } = useAuthContext()
    const enabled = !!accountSid && !!user && isEmailVerified

    return useQuery(
        ['workspace_user', accountSid],
        `${ENDPOINT}?account_sid=${accountSid}`,
        { enabled, onSuccess: setUser, keepPreviousData: false },
        {
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
            bypassMatchingStackCheck: true,
            bypassQueryDeps: true,
        }
    )
}

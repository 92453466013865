import React from 'react'

import { ListHeader } from 'features/views/ListView/ListHeader/ListHeader'

import { useCardViewListHeaderState } from './hooks/useCardViewListHeaderState'

type CardViewListHeaderProps = {}

export const CardViewListHeader: React.FC<CardViewListHeaderProps> = React.memo(
    function CardViewListHeader() {
        const { recordCount, requestFilters, requestIncludedFields } = useCardViewListHeaderState()

        return (
            <ListHeader
                recordCount={recordCount}
                requestFilters={requestFilters}
                requestIncludedFields={requestIncludedFields}
                isSticky
            />
        )
    }
)

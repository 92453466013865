import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

type UseOverflowXOptions = {
    topClassName?: string
    bottomClassName?: string
}

export function useOverflowY(options: UseOverflowXOptions) {
    const { topClassName, bottomClassName } = options

    const parentRef = useRef<HTMLElement | null>(null)
    const [scrollAreaRef, setScrollAreaRef] = useState<HTMLElement | null>(null)

    const prevOverflow = useRef<{ top: boolean; bottom: boolean } | undefined>()
    const checkOverflow = useCallback(() => {
        const el = scrollAreaRef
        if (!el) return

        const isTopOverflowing = Math.ceil(el.scrollTop) > 0
        const isBottomOverflowing =
            Math.ceil(el.scrollTop + el.clientHeight) < Math.ceil(el.scrollHeight)

        if (
            isTopOverflowing !== prevOverflow.current?.top ||
            isBottomOverflowing !== prevOverflow.current?.bottom
        ) {
            const parent = parentRef.current
            if (!parent) return

            // Add or remove the overflowing classes based on the current state.
            if (topClassName) {
                parent.classList.toggle(topClassName, isTopOverflowing)
            }
            if (bottomClassName) {
                parent.classList.toggle(bottomClassName, isBottomOverflowing)
            }
        }

        prevOverflow.current = {
            top: isTopOverflowing,
            bottom: isBottomOverflowing,
        }
    }, [topClassName, bottomClassName, scrollAreaRef])

    useLayoutEffect(() => {
        const el = scrollAreaRef
        if (!el) return

        checkOverflow()

        el.addEventListener('scroll', checkOverflow)

        return () => el.removeEventListener('scroll', checkOverflow)
    }, [checkOverflow, scrollAreaRef])

    // Make sure to check overflow when the element's contents change.
    const mutationObseverRef = useRef<MutationObserver | null>(null)
    useLayoutEffect(() => {
        const el = scrollAreaRef
        if (!el) return

        mutationObseverRef.current = new MutationObserver(() => {
            checkOverflow()
        })
        mutationObseverRef.current.observe(el, { childList: true, subtree: true })

        return () => mutationObseverRef.current?.disconnect()
    }, [checkOverflow, scrollAreaRef])

    // Make sure to check overflow when the element's size changes.
    const resizeObseverRef = useRef<ResizeObserver | null>(null)
    useLayoutEffect(() => {
        const el = scrollAreaRef
        if (!el) return

        resizeObseverRef.current = new ResizeObserver(() => {
            checkOverflow()
        })
        resizeObseverRef.current.observe(el)

        return () => resizeObseverRef.current?.disconnect()
    }, [checkOverflow, scrollAreaRef])

    return useMemo(
        () => ({
            targetRef: parentRef,
            scrollAreaRef: setScrollAreaRef,
            checkOverflow,
        }),
        [checkOverflow]
    )
}

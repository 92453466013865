import { ListViewOrderBy } from 'v2/views/utils/orderBy/types'

import { buildSortingRequest } from './buildSortingRequest'
import { chartsFiltersToQueryDict } from './chartsFiltersToQueryDict'
import type { Aggregation } from './types'

const getAggregationColumnName = (fieldApiName: string | undefined, type: string) => {
    const keys = [type]
    if (fieldApiName) {
        keys.push(fieldApiName)
    }
    return keys.join('_')
}

type BuildAggregatesUrlProps = {
    objectId: string
    aggregations: Aggregation[]
    primaryGroupFieldApiName: string | undefined
    secondaryGroupFieldApiName: string | undefined
    filters: ChartFilter[]
    sort?: ChartSort
    bucketBy?: Bucket
    limit?: number
    previousTimePeriod?: boolean
    includedFields?: string[]
    searchFields?: string[]
    orderBy?: ListViewOrderBy
}

export const getAggregatesUrlAndBody = ({
    objectId,
    aggregations,
    primaryGroupFieldApiName,
    secondaryGroupFieldApiName,
    filters,
    sort,
    bucketBy,
    limit,
    previousTimePeriod = false,
    includedFields,
    searchFields,
    orderBy,
}: BuildAggregatesUrlProps): [string, Record<string, any>] => {
    const path = `objects/${objectId}/aggregates/`
    const filtersQueryDict = chartsFiltersToQueryDict(
        filters.filter((filter) => filter.target !== '_search')
    )
    const processedAggregations = aggregations.map((aggregation) => ({
        name: getAggregationColumnName(aggregation.fieldApiName ?? '', aggregation.type),
        field_api_name: aggregation.fieldApiName,
        type: aggregation.type,
    }))
    const sortingRequest = buildSortingRequest(
        sort,
        processedAggregations.map((a) => a.name),
        primaryGroupFieldApiName
    )
    const aggregationsQueryDict = { aggrs: processedAggregations }
    const primaryGroupDict = primaryGroupFieldApiName
        ? { primary_group: primaryGroupFieldApiName }
        : {}
    const secondaryGroupDict = secondaryGroupFieldApiName
        ? { secondary_group: secondaryGroupFieldApiName }
        : {}

    const sortDict = sortingRequest ? { sort: sortingRequest } : {}
    const bucketByDict = bucketBy ? { bucket_by: bucketBy } : {}
    const limitDict = limit ? { limit: limit } : {}

    const search = (filters ?? []).find((filter) => filter?.target === '_search')?.value as
        | string
        | undefined
    const searchDict = search ? { search: search } : {}
    const includeFieldsDict = includedFields ? { include_fields: includedFields } : {}
    const searchFieldsDict = searchFields ? { search_fields: searchFields } : {}

    let orderByDict: Record<string, string> = {}
    if (orderBy) {
        orderByDict = { order_by: `${orderBy.desc ? '-' : ''}${orderBy.id}` }
    }

    return [
        path,
        {
            ...filtersQueryDict,
            ...aggregationsQueryDict,
            ...primaryGroupDict,
            ...secondaryGroupDict,
            ...sortDict,
            ...bucketByDict,
            ...limitDict,
            ...(previousTimePeriod ? { __isPreviousTimePeriod: true } : {}), // Only used for storybook testing
            ...searchDict,
            ...includeFieldsDict,
            ...searchFieldsDict,
            ...orderByDict,
        },
    ]
}

import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { defaultMemoize } from 'reselect'

export const recordsToOptions = defaultMemoize(
    (records: RecordDto[], distinctBy?: string, distinctOrderBy?: string) => {
        let list = records

        // if we're instructed to ensure uniqueness of the available options
        // by a particular field, apply that now.
        if (distinctBy) {
            if (distinctOrderBy) {
                // First sort in the specified distinction order
                list = sortBy(list, distinctOrderBy)
            }
            list = uniqBy(list, distinctBy)
        }
        return sortBy(list, recordToSortKey).map((record: RecordDto) => ({
            label: record['_primary']?.toString(),
            value: record._sid,
        }))
    }
)

const recordToSortKey = (record: RecordDto) => {
    let x = record['_primary'] || ''
    return x.toString().toLowerCase()
}

import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'

import styled from '@emotion/styled'
import { filter } from 'lodash'
import get from 'lodash/get'

import { useObject } from 'data/hooks/objects'
import { useUserProfileObjects } from 'data/hooks/userProfiles'
import { relativeFiltersLabelsByValue } from 'features/records/components/relativeFiltersLabelsByValue'

import { Box, Divider, Flex, Icon, PopoverButton, Text } from 'v2/ui'
import Button from 'v2/ui/components/Button'
import { Dropdown } from 'v2/ui/components/Dropdown'
import stackerTheme from 'v2/ui/theme/styles/default'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const { colors } = stackerTheme()

/**
 * Component for rendering individual filter elements. Used in the new v4 designs.
 * @param {*} param0
 */

const filtersWithRelativeDates = ['beforeDate', 'afterDate', 'sameDay']

const VALIDATIONS = {
    currency: (value) => {
        const errors = []
        if (isNaN(value)) {
            errors.push('Value should be a number')
        }

        return errors
    },
    number: (value) => {
        const errors = []
        if (isNaN(value)) {
            errors.push('Value should be a number')
        }

        return errors
    },
    percentage: (value) => {
        const errors = []
        if (isNaN(value)) {
            errors.push('Value should be a number')
        }

        return errors
    },
}

const InlineFilterItem = ({
    id,
    options,
    label,
    onChange,
    filterOptions,
    contextRecordObject,
    field,
    valueOptionsByType,
    roles,
    userObjectId,
    isNew,
    onRemove,
    hasFilterError,
    filterErrorMessage,
    color,
    closeOnOuterAction = false,
    usePortal,
    showRelativeDateFilters = false,
    ...props
}) => {
    const typeDropdown = useRef()
    const relativeDateDropdown = useRef()
    const [showTypeDropdown, setShowTypeDropdown] = useState(false)
    const [isShowingRelativeDateDropdown, setIsShowingRelativeDateDropDown] = useState(false)
    const { object: userObject } = useObject(userObjectId)
    const userProfileObjects = useUserProfileObjects(userObjectId)
    const filterType = filterOptions?.option
    const filterValue = filterOptions?.value

    const errors = useMemo(() => {
        if (filterValue && VALIDATIONS[field.type]) {
            return VALIDATIONS[field.type](filterValue)
        }
        return []
    }, [filterValue, field])

    const relativeDateFiltersEnabled = showRelativeDateFilters

    const relativeDateFiltersOptions = Object.entries(relativeFiltersLabelsByValue).map(
        ([k, v]) => {
            return { label: v, value: k }
        }
    )

    useEffect(() => {
        if (showTypeDropdown) {
            typeDropdown.current?.setState({ menuIsOpen: true })
            typeDropdown.current?.select.focus()
        }
    }, [showTypeDropdown])

    useEffect(() => {
        if (isShowingRelativeDateDropdown) {
            relativeDateDropdown.current?.setState({ menuIsOpen: true })
            relativeDateDropdown.current?.select.focus()
        }
    }, [isShowingRelativeDateDropdown])

    const FilterComponent = useMemo(
        () => valueOptionsByType[filterType],
        [filterType, valueOptionsByType]
    )

    const filterTypeLabel = useMemo(() => {
        const filter = options.find((f) => f.value === filterType)
        const label = get(filter, 'label')

        return label
    }, [filterType, options])

    const filterLabel = useMemo(() => {
        const operator = filterTypeLabel?.replace('...', '')

        return (
            <>
                {hasFilterError ? <AlertIcon /> : null}
                <StyledLabel>{label}</StyledLabel> {operator}{' '}
                {!filterValue && FilterComponent && '…'}
                {FilterComponent && (
                    <strong>
                        <FilterComponent
                            contextRecordObject={contextRecordObject}
                            roles={roles}
                            field={field}
                            value={filterValue}
                            userObject={userObject}
                            userProfiles={userProfileObjects}
                            editMode={false}
                            usePortal={false}
                            isInvalid={errors.length > 0}
                        />
                    </strong>
                )}
            </>
        )
    }, [
        contextRecordObject,
        filterTypeLabel,
        hasFilterError,
        label,
        filterValue,
        FilterComponent,
        roles,
        field,
        userObject,
        userProfileObjects,
        errors.length,
    ])

    const handleRelativeFilterChange = (value) => {
        onChange({
            id,
            options: {
                ...filterOptions,
                value: value === '_customDate' ? undefined : value,
            },
        })

        setIsShowingRelativeDateDropDown(false)
    }

    // A custom date will have a date/datetime value OR will be empty OR will be _customDate before a date has been selected
    const isCustomDate =
        filterValue === '_customDate' || !filterValue || !relativeFiltersLabelsByValue[filterValue]
    const isNotRelativeDateFilter = !filtersWithRelativeDates.includes(filterType)

    return (
        <>
            <PopoverButton
                ButtonComponent={FilterButton}
                defaultIsOpen={isNew}
                label={filterLabel}
                variant="adminSecondary"
                buttonSize="smDense"
                {...props}
                maxWidth="100%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                onRemove={onRemove}
                color={color}
                closeOnOuterAction={closeOnOuterAction}
                usePortal={usePortal}
            >
                {({ onToggle, buttonRef }) => (
                    <Flex
                        style={props.style}
                        column
                        wrap="nowrap"
                        align="stretch"
                        boxShadow="popup"
                        borderRadius="3px"
                        background="white"
                        padding={2}
                        fontSize="sm"
                        marginTop={`${-buttonRef.current?.offsetHeight}px`} // Offsets to have the popup cover the button
                        width={`${Math.max(165, buttonRef.current?.offsetWidth)}px`} // and match its width
                    >
                        {hasFilterError ? (
                            <StyledWarningDiv>
                                <div style={{ marginBottom: '5px' }}>
                                    <AlertIcon />
                                    <strong style={{ fontSize: '15px' }}>Warning</strong>
                                </div>
                                {filterErrorMessage}
                            </StyledWarningDiv>
                        ) : null}
                        <span style={{ marginBottom: '4px' }}>
                            <strong>{label}</strong>
                        </span>
                        {showTypeDropdown ? (
                            <Dropdown
                                usePortal={false}
                                ref={typeDropdown}
                                fontSize="0.75rem"
                                options={options}
                                value={filterType}
                                onChange={(value) => {
                                    onChange({
                                        id,
                                        options: { ...filter.options, option: value },
                                    })
                                    setShowTypeDropdown(false)
                                }}
                                onBlur={() => {
                                    setShowTypeDropdown(false)
                                }}
                                isClearable={false}
                                padding="6px"
                                controlStyle={{
                                    marginBottom: '4px',
                                }}
                            />
                        ) : (
                            <Button
                                variant="filterType"
                                icon="caretDown"
                                iconAlign="right"
                                onClick={() => {
                                    setShowTypeDropdown(true)
                                }}
                            >
                                {filterTypeLabel}
                            </Button>
                        )}
                        {relativeDateFiltersEnabled &&
                            filtersWithRelativeDates.includes(filterType) &&
                            FilterComponent &&
                            (isShowingRelativeDateDropdown ? (
                                <Dropdown
                                    usePortal={false}
                                    ref={relativeDateDropdown}
                                    fontSize="0.75rem"
                                    options={relativeDateFiltersOptions}
                                    onChange={handleRelativeFilterChange}
                                    value={filterValue}
                                    isSearchable={true}
                                    isClearable={false}
                                    controlStyle={{
                                        marginBottom: '8px',
                                    }}
                                />
                            ) : (
                                <Button
                                    variant="filterType"
                                    icon="caretDown"
                                    iconAlign="right"
                                    style={{ marginBottom: '0.5rem' }}
                                    onClick={() => {
                                        setIsShowingRelativeDateDropDown(true)
                                    }}
                                >
                                    {relativeFiltersLabelsByValue[filterValue] ?? 'Specific date'}
                                </Button>
                            ))}

                        {(isCustomDate || isNotRelativeDateFilter) && FilterComponent && (
                            <FilterComponent
                                contextRecordObject={contextRecordObject}
                                roles={roles}
                                field={field}
                                value={filterValue}
                                userObject={userObject}
                                userProfiles={userProfileObjects}
                                onChange={(value) => {
                                    onChange({
                                        id,
                                        options: {
                                            ...filterOptions,
                                            value,
                                        },
                                    })
                                }}
                                usePortal={false}
                                size="sm"
                                variant="settings"
                                isInvalid={errors.length > 0}
                            />
                        )}
                        {errors.map((error, index) => (
                            <Text mt={1} variant="error" key={index}>
                                {error}
                            </Text>
                        ))}
                        <Divider my={2} />
                        <Button variant="adminTertiary" buttonSize="sm" onClick={onToggle}>
                            Done
                        </Button>
                    </Flex>
                )}
            </PopoverButton>
        </>
    )
}

export const FilterButton = forwardRef(({ onClick, onRemove, children, color }, ref) => {
    return (
        <Box
            as={Flex}
            wrap="nowrap"
            align="center"
            ref={ref}
            bg={color === 'white' ? 'white' : V4DesignSystem.colors.gray[50]}
            borderWidth="1px"
            borderColor={V4DesignSystem.colors.gray[100]}
            borderStyle="solid"
            rounded="md"
            cursor="pointer"
            _hover={{ bg: V4DesignSystem.colors.gray[100] }}
            my="1px"
            width="100%"
            p={1}
            minWidth="0"
        >
            <Box
                role="button"
                onClick={onClick}
                fontSize="sm"
                px={1}
                flexGrow={1}
                flexShrink={1}
                minWidth={0}
                overflow="hidden"
                textOverflow="ellipsis"
            >
                {children}
            </Box>
            <Box
                onClick={onRemove}
                role="button"
                _hover={{ bg: V4DesignSystem.colors.gray[200] }}
                rounded="md"
            >
                <Icon
                    icon="x"
                    display="inline"
                    role="button"
                    size="xs"
                    mx={2}
                    color={V4DesignSystem.colors.gray[800]}
                />
            </Box>
        </Box>
    )
})

export default React.memo(InlineFilterItem)

const AlertIcon = () => {
    return (
        <Icon
            style={{ display: 'inline', marginRight: '5px' }}
            icon="alert"
            size="sm"
            color={colors.userInterface.warning[1000]}
        />
    )
}

const StyledWarningDiv = styled('div')`
    background-color: #ffe4c7;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
`

const StyledLabel = styled('span')`
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

import { useMutation, UseMutationOptions } from 'react-query'

import { useCreateWorkflow, useUpdateWorkflow } from 'data/hooks/workflows/workflows'

import { useToast } from 'v2/ui/components/useToast'

import { isDraftWorkflow } from './common'

export function useSaveWorkflow(
    options: UseMutationOptions<WorkflowDto, unknown, WorkflowDto> = {}
) {
    const toast = useToast()

    const { mutateAsync: updateWorkflow } = useUpdateWorkflow({
        onError: () => {
            toast({
                title: 'There was a problem saving the workflow. Please try again later.',
                status: 'error',
            })
        },
    })
    const { mutateAsync: createWorkflow } = useCreateWorkflow({
        onError: () => {
            toast({
                title: 'There was a problem creating the workflow. Please try again later.',
                status: 'error',
            })
        },
    })

    return useMutation(async (workflow: WorkflowDto) => {
        if (isDraftWorkflow(workflow)) {
            return createWorkflow(workflow)
        }

        return updateWorkflow({ id: workflow._sid, workflow })
    }, options)
}

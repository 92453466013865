import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { getLocale } from 'utils/date/dateUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UsePercentageAttributeDisplayStateProps = {
    field: FieldDto
    value?: number
    isLoading?: boolean
    showIcon?: boolean
}

export function usePercentageAttributeDisplayState({
    field,
    value,
    isLoading,
    showIcon,
}: UsePercentageAttributeDisplayStateProps) {
    const effectiveValue = isLoading ? PLACEHOLDER_VALUE : value

    const content = formatPercentageValue(effectiveValue)
    const cappedValue = (effectiveValue ?? 0) * 100.0

    const fieldIcon = getFieldIcon(field)
    const icon = useDeepEqualsMemoValue(
        showIcon && fieldIcon?.type === 'lucide' ? fieldIcon : undefined
    )

    return { content, cappedValue, icon }
}

const MAX_FRACTION_DIGITS = 2

function formatPercentageValue(initialValue?: number): string {
    let value = initialValue
    if (typeof value !== 'number' || Number.isNaN(value)) {
        value = 0
    }

    const locale = getLocale()
    const formatter = new Intl.NumberFormat(locale, {
        useGrouping: true,
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: MAX_FRACTION_DIGITS,
    })
    const formattedValue = formatter.format(value)

    return formattedValue
}

const PLACEHOLDER_VALUE = 0.1234

import { useCallback } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { useWorkflowEditorContext } from 'features/workflows/useWorkflowEditorContext'

import { USE_CURRENT_USER_INPUT_ID } from './createTaskActionConstants'

export function useCreateTaskActionFinalSchema() {
    const { data: schema } = useWorkflowSchema()
    const { contextSchema } = useWorkflowEditorContext()

    return useCallback(
        (config: WorkflowActionConfig): WorkflowSchemaActionType | undefined => {
            const type = schema?.nodes.find(
                (t) => t.id === config.action_type
            ) as WorkflowSchemaActionType

            if (!type) return undefined

            const hasCurrentUser = hasTriggerCurrentUser(contextSchema)

            return {
                ...type,
                inputs: type.inputs.filter((i) => {
                    if (!hasCurrentUser && i.id === USE_CURRENT_USER_INPUT_ID) {
                        return false
                    }

                    return true
                }),
            }
        },
        [contextSchema, schema?.nodes]
    )
}

export function hasTriggerCurrentUser(contextSchema?: WorkflowSchemaStateItem[]): boolean {
    const finalTriggerType = contextSchema?.find(
        (t) => t.id === 'trigger'
    ) as WorkflowSchemaStateItemGroup
    if (!finalTriggerType) return false

    return finalTriggerType.items.some((i) => i.id === INPUT_USER_STATE_ID)
}

const INPUT_USER_STATE_ID = 'current_user'

import React from 'react'

type DisclosureState = {
    isOpen: boolean
    onClose: () => any
    onToggle: () => any
}
export type SidebarState = 'open' | 'workspaceBarCollapsed' | 'fullyCollapsed'
export type NavContextType = {
    mobileDrawerState: DisclosureState
    sidebarState: SidebarState
    setSidebarState: (value: SidebarState) => void
    isPoppedOut: boolean
    setIsPoppedOut: React.Dispatch<React.SetStateAction<boolean>>
    hasMenuOpen: boolean
    setHasMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const NavContext = React.createContext<NavContextType>({} as NavContextType)
export const useNavContext = () => React.useContext(NavContext)

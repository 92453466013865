import { AppUserContextValue } from './appUserContextType'

export const defaultContext: AppUserContextValue = {
    user: undefined,
    role: undefined,
    isLoggedIn: false,
    isPreviewingAsUserOrRole: false,
    isLoading: false,
    isLoadingIncludingStack: false,
    isAdmin: false,
    authStateKey: undefined,
    hasRight: () => false,
}

import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useCreateGrant, useGrants, useUpdateGrant } from 'data/hooks/accessGrants'
import { useObjects } from 'data/hooks/objects'
import { useRoleListOptions } from 'data/hooks/roles'

import { useToast } from 'ui/components/Toast'

type FormData = Pick<
    AccessGrant,
    '_sid' | 'group_name' | 'role_sid' | 'object_sid' | 'email_field_api_name' | 'stack_sid'
> & { filters: FilterValue[] }

export const useTableGrantFormState = (tableGrant: AccessGrant | null) => {
    const { data: objects = [] } = useObjects()
    const { mutateAsync: createGrant } = useCreateGrant()
    const { mutateAsync: updateGrant } = useUpdateGrant()
    const { data: roles = [] } = useRoleListOptions('_sid', false)
    const { data: grants = [] } = useGrants()
    const toast = useToast()

    const [isRoleModalOpen, setIsRoleModalOpen] = useState(false)

    const formDefaultValues = tableGrant
        ? {
              _sid: tableGrant._sid,
              group_name: tableGrant.group_name,
              role_sid: tableGrant.role_sid,
              object_sid: tableGrant.object_sid,
              email_field_api_name: tableGrant.email_field_api_name,
              stack_sid: tableGrant.stack_sid,
              filters: tableGrant.filters || [],
          }
        : {}

    const formContext = useForm<FormData>({ defaultValues: formDefaultValues })

    const objectSid = formContext.watch('object_sid')
    const selectedObject = useMemo(
        () => objects.find(({ _sid }) => _sid === objectSid),
        [objects, objectSid]
    )

    const validateUniqueGroupName = useCallback(
        (value: string) =>
            !grants.some(
                (grant) =>
                    grant.group_name === value && (!tableGrant || grant._sid !== tableGrant._sid)
            ) || 'Group name must be unique.',
        [grants, tableGrant]
    )

    const handleCreateOrUpdate = useCallback(
        async (data: FormData, isEdit: boolean, onClose: () => void) => {
            try {
                if (isEdit && tableGrant) {
                    await updateGrant({
                        id: tableGrant._sid,
                        patch: { ...data, stack_id: tableGrant.stack_sid },
                    })
                } else {
                    await createGrant(data)
                }
                onClose()
            } catch {
                toast({
                    helperText: 'Please try again later. If the issue persists, contact support.',
                    showDismissButton: true,
                    startIcon: { name: 'AlertCircle' },
                    title: isEdit ? 'Cannot update table group' : 'Cannot create table group',
                    type: 'error',
                })
            }
        },
        [createGrant, updateGrant, tableGrant, toast]
    )

    const onRoleCreated = useCallback(
        (role: RoleDto) => {
            formContext.setValue('role_sid', role._sid, { shouldDirty: true })
        },
        [formContext]
    )

    const onAddRole = useCallback(() => setIsRoleModalOpen(true), [])

    return useMemo(
        () => ({
            roles,
            objects,
            selectedObject,
            formContext,
            validateUniqueGroupName,
            handleCreateOrUpdate,
            isEdit: !!tableGrant,
            isRoleModalOpen,
            setIsRoleModalOpen,
            onRoleCreated,
            onAddRole,
        }),
        [
            roles,
            objects,
            selectedObject,
            formContext,
            validateUniqueGroupName,
            handleCreateOrUpdate,
            tableGrant,
            isRoleModalOpen,
            onRoleCreated,
            onAddRole,
        ]
    )
}

import React, { useMemo } from 'react'

import { getUrl } from 'app/UrlService'
import { useRecentRecords } from 'data/hooks/recentRecords/recentRecords'
import { RecentRecord } from 'data/hooks/recentRecords/types'

import { ItemProvider } from 'ui/components/Combobox/useComboboxProviders'

import { matchesSearchTerms } from './helpers'
import { RecentRecordSearchResult } from './recentItemTypes'
import { RenderRecentRecord } from './RenderRecentRecord'

function getSearchItem(
    item: RecentRecord,
    objectUrls: Record<string, string>
): RecentRecordSearchResult {
    return {
        ...item,
        _sid: item.record._sid!,
        url: getUrl(
            `${objectUrls[item.object._sid!]}/view/${item.record._sid}`,
            item.stack as StackDto
        ),
        _stack_id: item.stack._sid!,
        _primary: item.record._primary!,
        _object_id: item.object._sid!,
    }
}

type useRecentItemsSearchProviderProps = {
    blockLinkClick?: boolean
}

export const useRecentItemsSearchProvider = ({
    blockLinkClick,
}: useRecentItemsSearchProviderProps = {}): ItemProvider<RecentRecordSearchResult> => {
    const { data } = useRecentRecords()

    return useMemo(
        () =>
            ({
                id: 'recent_records',
                title: 'Recent records',
                maxVisibleItemsDefault: 10,
                // show all recent items immediately on open without
                // waiting for getItems to be called
                initialItems: data.recentRecords.map((item) =>
                    getSearchItem(item, data.objectUrls)
                ),
                getItems({ queryTerms }) {
                    return Promise.resolve({
                        items: data.recentRecords
                            .filter((u) =>
                                matchesSearchTerms(u.record._primary!.toString(), queryTerms)
                            )
                            .map((item) => getSearchItem(item, data.objectUrls)),
                    })
                },
                renderItem({ item, queryTerms }) {
                    return (
                        <RenderRecentRecord
                            item={item}
                            queryTerms={queryTerms}
                            blockLinkClick={blockLinkClick}
                        />
                    )
                },
            }) as ItemProvider<RecentRecordSearchResult>,
        [blockLinkClick, data.objectUrls, data.recentRecords]
    )
}

import React from 'react'

import { useConfirmModal } from 'app/useConfirmModal'

import { Button, Icon as V2Icon, Text } from 'v2/ui'
import { DeleteTable } from 'v2/ui/svgs'

import { Icon } from 'ui/components/Icon'

type UnShareTableButtonProps = {
    object: ObjectDto
    onUnshareObject: (objectSid: string) => Promise<unknown>
    disabled: boolean
}

export const UnShareTableButton: React.FC<UnShareTableButtonProps> = ({
    object,
    onUnshareObject,
    disabled = false,
}) => {
    const unShareModalData = {
        icon: <V2Icon svg={<DeleteTable />} size="56px" ml="15px" mb={4} />,
        confirmVariant: 'PrimaryError',
        message: (
            <>
                <Text fontWeight="bold">Unshare {object?.name}?</Text>

                <Text mt={4}>Are you sure you want to unshare this table?</Text>

                <Text mt={4}>
                    This table should be removed from any apps importing it before it can be
                    unshared.
                </Text>
            </>
        ),
    }
    const { show: showConfirm, setData } = useConfirmModal({
        ...unShareModalData,
        onConfirm: (modal: any) => {
            setData({ ...unShareModalData, isSaving: true })
            onUnshareObject(object?._sid).then(() => {
                setData({ ...unShareModalData, isSaving: false })
                modal.toggle()
            })
        },
    })

    if (!object?.is_shared_original) {
        return null
    }

    return (
        <div>
            <Button
                icon={<Icon name="Unlock" size="m" mr="xs" />}
                onClick={showConfirm}
                variant="Primary"
                buttonSize="sm"
                disabled={disabled}
            >
                Unshare table
            </Button>
        </div>
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { theme } from 'ui/styling/Theme.css'

import { useMainNavigationState } from './hooks/useMainNavigationState'
import { AppNavigation } from './AppNavigation'
import * as Parts from './Navigation.parts'
import { NavigationHelpAndSupport } from './NavigationHelpAndSupport'
import { NavigationTrialBanner } from './NavigationTrialBanner'
import { PortalNavigation } from './PortalNavigation'

type MainNavigationProps = {}

export const MainNavigation: React.FC<MainNavigationProps> = () => {
    const { isNavHidden, isPortal, onBackClick, showMobileControls, showTrialBanner } =
        useMainNavigationState()

    if (isNavHidden) return null

    return (
        <Parts.MainNavigationWrapper showMobileControls={showMobileControls}>
            {showMobileControls && <MobileControls onBackClick={onBackClick} />}
            {isPortal ? <PortalNavigation /> : <AppNavigation />}
            {showTrialBanner && (
                <Box px="xs" noShrink>
                    <NavigationTrialBanner py="m" px="l" />
                </Box>
            )}
            <NavigationHelpAndSupport />
        </Parts.MainNavigationWrapper>
    )
}

type MobileControlsProps = {
    onBackClick: () => void
}

export const MobileControls: React.FC<MobileControlsProps> = ({ onBackClick }) => {
    return (
        <Box
            px="m"
            borderBottomWidth={1}
            borderColor="dividerWeak"
            background="surface"
            mb="m"
            style={{
                paddingTop: theme.button.padding.mIconOnly,
                paddingBottom: theme.button.padding.mIconOnly,
            }}
        >
            <Button
                size="xs"
                variant="ghost"
                startIcon={{ name: 'ArrowLeft' }}
                onClick={onBackClick}
            >
                Back
            </Button>
        </Box>
    )
}

import { byID } from 'data/utils/byID'
import {
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
    WORKFLOW_CREATED,
    WORKFLOW_REMOVED,
    WORKFLOW_UPDATED,
    WORKFLOWS_FETCHED,
} from 'data/utils/constants'
import { unique } from 'utils/utils'

// STATE
export const initialState = {
    workflows: [],
    entities: {},
    fetching: [],
}

// REDUCERS
export function workflowReducer(state = initialState, action) {
    let workflows
    let workflow
    let fetching
    switch (action.type) {
        case WORKFLOWS_FETCHED:
            if (!action.payload) return state
            return {
                ...state,
                workflows: unique([...state.workflows, ...action.payload.map((w) => w._sid)]),
                entities: { ...state.entities, ...byID(action.payload) },
                fetching: state.fetching.filter((item) => item !== action.key),
            }
        case WORKFLOW_CREATED:
            if (!action.payload) return state
            workflows = unique([...state.workflows, action.payload._sid])
            return {
                ...state,
                workflows,
                entities: { ...state.entities, [action.payload._sid]: action.payload },
            }
        case WORKFLOW_UPDATED:
            if (!action.payload) return state
            workflow = { ...state.entities[action.id], ...action.payload }
            return {
                ...state,
                entities: { ...state.entities, [workflow._sid]: workflow },
            }
        case WORKFLOW_REMOVED:
            workflows = state.workflows.filter((id) => id !== action.id)
            return {
                ...state,
                workflows,
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return initialState
        default:
            return state
    }
}

import React, { useState } from 'react'

import QueryString from 'qs'
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-auth-react/recipe/thirdparty'

import { GlobalStaticState } from 'app/GlobalStaticState'
import { getAbsoluteUrl, getUrl, Urls } from 'app/UrlService'

import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'

import { WorkspaceLoginFrame } from './WorkspaceLoginFrame'

export function WorkspaceLoginPage({
    account,
    zone,
    providers,
}: {
    account: Account
    zone: AccountZone
    providers?: AuthProvider[]
}) {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    const [isRedirecting, setIsRedirecting] = useState(false)

    GlobalStaticState.setSuperTokensTenant(account._sid)

    async function startFlow(thirdPartyId: string) {
        setIsRedirecting(true)
        try {
            sessionStorage.setItem(
                'redirectAfterCallback',
                decodeURIComponent(query.r?.toString() ?? '')
            )
            const url = await getAuthorisationURLWithQueryParamsAndSetState({
                thirdPartyId,
                frontendRedirectURI: getAbsoluteUrl(
                    getUrl(`${Urls.AuthThirdPartyCallback}/${thirdPartyId}`, undefined, account)
                ),
                options: {},
            })
            window.location.assign(url)
        } catch (ex) {
            console.error(ex)
            setIsRedirecting(false)
        }
    }

    return (
        <WorkspaceLoginFrame zone={zone}>
            {providers?.map((x) => (
                <Button
                    key={x._sid}
                    onClick={() => startFlow(x.third_party_id)}
                    variant="secondary"
                    isLoading={isRedirecting}
                >
                    <Icon name="Key" mr="s" /> Continue with {x.name}
                </Button>
            ))}
        </WorkspaceLoginFrame>
    )
}

import React from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { assertIsDefined } from 'data/utils/ts_utils'

import { Box } from 'ui/components/Box'

import { ActionButtonTriggerNode } from './Triggers/ActionButtonTrigger'
import { RecordChangeTriggerNode } from './Triggers/RecordChangeTrigger'
import { TriggerCondition } from './Triggers/TriggerCondition'
import { WorkflowTriggerNodeRendererComponent } from './Triggers/types'
import { DEFAULT_TRIGGER_ICON } from './common'
import { ItemIcon } from './ItemIcon'
import { WorkflowItemWrapper } from './WorkflowItemWrapper'

type WorkflowTriggerProps = {
    trigger: WorkflowTriggerConfig
    showCondition?: boolean
    showType?: boolean
}

export function WorkflowTriggerNode({ trigger }: WorkflowTriggerProps) {
    return (
        <WorkflowItemWrapper item={trigger} flex center>
            <WorkflowTrigger trigger={trigger} showCondition={true} />
        </WorkflowItemWrapper>
    )
}

export function WorkflowTrigger({ trigger, ...props }: WorkflowTriggerProps) {
    const { data: schema } = useWorkflowSchema()
    const triggerType = schema?.triggers.find((t) => t.id === trigger.trigger_type)

    const CustomRenderer = TriggerNodeRenderers[trigger.trigger_type]
    const Renderer = CustomRenderer || DefaultTriggerNodeRenderer
    if (Boolean(CustomRenderer)) assertIsDefined(triggerType)

    return (
        <Renderer
            trigger={trigger}
            triggerType={triggerType as WorkflowSchemaTriggerType}
            {...props}
        />
    )
}

const DefaultTriggerNodeRenderer: WorkflowTriggerNodeRendererComponent<{
    triggerType?: WorkflowSchemaTriggerType
}> = ({ trigger, triggerType, showCondition, showType }) => (
    <Box>
        {triggerType && (
            <>
                <Box flex center fontWeight="bodyRegular">
                    <ItemIcon
                        icon={triggerType.icon || DEFAULT_TRIGGER_ICON}
                        iconStyle={triggerType.icon_style}
                        mr="m"
                    />
                    <Box flex column alignItems="flex-start">
                        {showType && (
                            <Box fontSize="bodyXs" color="textWeakest">
                                Trigger
                            </Box>
                        )}
                        <Box flex center>
                            {trigger.name}
                        </Box>
                    </Box>
                </Box>
                {showCondition && <TriggerCondition trigger={trigger} />}
            </>
        )}
        {!triggerType && (
            <Box color="textWeaker" flex center>
                <Box width="4xl" height="4xl" background="surfaceStronger" noShrink mr="m" />
                Pick a trigger to begin
            </Box>
        )}
    </Box>
)

const TriggerNodeRenderers: Record<string, WorkflowTriggerNodeRendererComponent> = {
    record_created: RecordChangeTriggerNode,
    record_updated: RecordChangeTriggerNode,
    record_created_or_updated: RecordChangeTriggerNode,
    action_button_clicked: ActionButtonTriggerNode,
}

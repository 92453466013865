import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl, Urls } from 'app/UrlService'
import { useDeleteView } from 'data/hooks/views'

type UseDeleteViewConfirmModalStateOptions = {
    viewSid: string
    onOpenChange?: (open: boolean) => void
}

export function useDeleteViewConfirmModalState(options: UseDeleteViewConfirmModalStateOptions) {
    const { viewSid, onOpenChange } = options

    const { mutateAsync: deleteView, isError, reset } = useDeleteView()

    const handleOpenChange = useCallback(
        (isOpen: boolean) => {
            if (isOpen) reset()
            onOpenChange?.(isOpen)
        },
        [onOpenChange, reset]
    )

    const history = useHistory()

    const onDelete = useCallback(async () => {
        await deleteView(viewSid)

        history.replace(getUrl(Urls.Root))

        reset()
        handleOpenChange(false)
    }, [deleteView, handleOpenChange, history, reset, viewSid])

    return {
        onDelete,
        isError,
        handleOpenChange,
    }
}

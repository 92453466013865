import React, { memo, useLayoutEffect, useRef, useState } from 'react'

import { Switch } from '@chakra-ui/react'

import { Input, Radio } from 'v2/ui'

import { Box } from './components/Box'
import { Button } from './components/Button'
import { Toggle } from './components/Toggle'

const componentProps = {
    href: '/test',
}

const Component1 = Switch
const Component2 = Toggle

export const ComponentBenchmarkPlayground = () => {
    const [renderCount, setRenderCount] = useState(100)
    const [result, setResult] = useState<string>('')
    const [start, setStart] = useState<number>(0)
    const [component, setComponent] = useState('1')

    return (
        // TODO: fix size
        // @ts-expect-error
        <Box m={4}>
            <Box flex>
                {/* <RadioGroup onChange={setComponent} value={component}>
                    <Radio value="1">1</Radio>
                    <Radio value="2">2</Radio>
                </RadioGroup> */}
                <Radio
                    options={[
                        { label: '1', value: '1' },
                        { label: '2', value: '2' },
                    ]}
                    value={component}
                    onChange={setComponent}
                />
                Cycles:{' '}
                <Input
                    value={renderCount}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setRenderCount(parseInt(e.target.value))
                    }}
                />
                <Button onClick={() => setStart(window.performance.now())}>Start</Button>
            </Box>

            {/* TODO: fix size */}
            {/* @ts-expect-error */}
            <Box flex flexWrap="wrap" mt={4} overflow="hidden">
                {result && <div>Average render time: {result}ms</div>}

                <RenderHarness
                    key={start}
                    renderCount={renderCount}
                    Component={component == '1' ? Component1 : Component2}
                    componentProps={componentProps}
                    setResult={setResult}
                />
            </Box>
        </Box>
    )
}

type RenderHarnessProps = {
    renderCount: number
    setResult: (result: string) => void
    componentProps: any
    Component: React.ComponentType
}
const RenderHarness = memo(
    ({ renderCount, setResult, componentProps, Component }: RenderHarnessProps) => {
        const renderedAt = useRef<number>(0)
        if (renderedAt.current === 0) {
            renderedAt.current = window.performance.now()
        }

        useLayoutEffect(() => {
            const now = window.performance.now()
            setResult(((now - renderedAt.current) / renderCount).toFixed(3))
        })

        return (
            <>
                {Array.from(Array(renderCount)).map((x, index) => (
                    <Component key={index} {...componentProps} />
                ))}
            </>
        )
    }
)

import React from 'react'

import { BreadcrumbsMode } from './BreadcrumbsMode'

export type HeaderContextType = {
    colorMode: null | 'dark' | 'light'
    setColorMode: (mode: null | 'dark' | 'light') => void
    headerImageVisible: boolean
    setHeaderImageVisible: (val: boolean) => void
    showBreadcrumbs: boolean
    setShowBreadcrumbs: (val: boolean) => void
    breadcrumbsMode: BreadcrumbsMode
    setBreadcrumbsMode: (mode: BreadcrumbsMode) => void
    reset: () => void
}

export const HeaderContext = React.createContext<HeaderContextType | null>(null)

import { useCallback, useMemo } from 'react'

import { makeQuickFilterValues } from 'features/views/ListView/Filters/utils'

import { useQuickFilter } from './useQuickFilter'

type FilterOption = {
    label: string
    value: string
}

type UseQuickFilterDateStateOptions = {
    field: FieldDto
}

export function useQuickFilterDateState(options: UseQuickFilterDateStateOptions) {
    const { field } = options

    const { value: filters, setFiltersValue, isActive, label } = useQuickFilter(field)
    const filterValue = filters[0]

    const onFilterRemove = useCallback(() => {
        setFiltersValue([])
    }, [setFiltersValue])

    const onSetFilterValue = useCallback(
        (newValue: string, isEnabled: boolean) => {
            if (!isEnabled) {
                setFiltersValue([])
                return
            }

            setFiltersValue(makeQuickFilterValues(field, newValue))
        },
        [field, setFiltersValue]
    )

    const filterOptions = FILTER_OPTIONS

    const value = (filterValue?.options.value as string) ?? ''

    return useMemo(
        () => ({ value, isActive, label, onFilterRemove, onSetFilterValue, filterOptions }),
        [value, isActive, label, onFilterRemove, onSetFilterValue, filterOptions]
    )
}

const FILTER_OPTIONS: FilterOption[] = [
    {
        label: 'Today',
        value: '_today',
    },
    {
        label: 'Yesterday',
        value: '_yesterday',
    },
    {
        label: 'Tomorrow',
        value: '_tomorrow',
    },
    {
        label: 'This week',
        value: '_this_week',
    },
    {
        label: 'Last week',
        value: '_last_week',
    },
    {
        label: 'Next week',
        value: '_next_week',
    },
    {
        label: 'This quarter',
        value: '_this_quarter',
    },
    {
        label: 'Last quarter',
        value: '_last_quarter',
    },
    {
        label: 'Next quarter',
        value: '_next_quarter',
    },
    {
        label: 'This month',
        value: '_this_month',
    },
    {
        label: 'Next month',
        value: '_next_month',
    },
    {
        label: 'Last month',
        value: '_last_month',
    },
    {
        label: 'This year',
        value: '_this_year',
    },
    {
        label: 'Next year',
        value: '_next_year',
    },
    {
        label: 'Last year',
        value: '_last_year',
    },
]

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

import { isEmpty } from 'lodash'
import get from 'lodash/get'
import { useDefaultValuesForView } from 'v2/views/utils/useDefaultValuesForView'
import useWindowSize from 'v2/views/utils/useWindowSize'

import { useRecordEditManagerContext } from 'features/records/useRecordEditManagerContext'
// import { useRecordActions } from 'data/hooks/objects'
import { getDropdownFieldOptionColor } from 'utils/fieldUtils'

import { Box, Container, ContainerLabel, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import useHover from 'v2/ui/utils/useHover'
import { useOverflowing } from 'v2/ui/utils/useIsOverflowing'

import { HiddenColumn } from './HiddenColumn'
import { KanbanCard } from './KanbanCard'
import { KanbanColumn } from './KanbanColumn'
import { getDisplayedStatuses } from './kanbanUtils'

const MAX_OVERFLOW_COLUMNS = 5
const Kanban = ({
    data,
    title,
    titleIsEditable,
    buttons,
    topFilters,
    rowLink,
    search,
    emptyContent,
    onTitleChange,
    object,
    statusFieldId,
    statusColumns,
    additionalListContainerContent,
    viewOptions,
    setConfig,
    view,
    relatedListFieldId,
    relatedListFieldValue,
    relatedListViewId,
    openRecord,
}) => {
    const { editRecord } = useRecordEditManagerContext()
    const [currentDragSource, setCurrentDragSource] = useState()
    const [scrollTop, setScrollTop] = useState()
    const containerRef = useRef()
    const [isDragging, setIsDragging] = useState(false)
    const [showHiddenColumns, setShowHiddenColumns] = useState(false)
    const [disableHiddenColumns, setDisableHiddenColumns] = useState()
    const [lastDropped, setLastDropped] = useState()
    const [lastDroppedOnColumn, setLastDroppedOnColumn] = useState()
    const field = object.fields.find((x) => x._sid === statusFieldId)
    const statuses = field?.options?.options && [...field.options.options]
    const timer = useRef()
    const wheeling = useRef()
    const wheelingTimer = useRef()
    const changedRecords = useRef({})
    const windowSize = useWindowSize()
    const gridRef = useRef()
    const { left: overflowingLeft, right: overflowingRight } = useOverflowing(gridRef.current)

    const relatedListField = useMemo(() => {
        return object?.fields?.find((f) => f._sid === relatedListFieldId)
    }, [relatedListFieldId, object?.fields])

    const defaultValues = useDefaultValuesForView(relatedListViewId ?? view?._sid)

    // const recordActions = useRecordActions()
    const reorderDisabled = viewOptions?.orderType !== 'manual'

    const displayedStatuses = getDisplayedStatuses(field, statusColumns)

    const [isContainerHovered, hoverHandlers] = useHover()

    // When we get new data coming in via props, we need to load it.
    // However, we need to check that the incoming record doesn't overwrite
    // our pending change in local state if that change hasn't been fully processed yet.
    useEffect(() => {
        // If we have no local changes pending save, then we can skip this check and
        // just use the supplied data
        if (isEmpty(changedRecords.current)) return

        data.forEach((newRecord) => {
            // See if we have a changed record saved in local state
            const existingRecord = changedRecords.current[newRecord.row.original._sid]

            // When we save a record, we pass an updateId through to the action.
            // If the incoming record doesn't have a our updateId value in its _processedUpdates
            // list it means our local record has been updated and that update
            // hasn't yet been fully processed and committed to the redux store,
            //so keep our local record
            const updateId = get(existingRecord, 'row.original.updateId')
            const processedUpdates = newRecord.row.original._processedUpdates || []
            if (updateId && processedUpdates.includes(updateId)) {
                // The updates to this record have been applied, so we can drop this record from
                // our local change tracking state
                delete changedRecords[newRecord.row.origina._sid]
            }
        })
    }, [data])

    useEffect(() => {
        if (isDragging) {
            clearTimeout(timer.current)
            setShowHiddenColumns(true && !disableHiddenColumns)
        } else {
            // delay hiding the hidden column palette so the user can see the "check mark"
            // displayed upon drop
            timer.current = setTimeout(() => {
                setShowHiddenColumns(false)
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging])

    const mergeLocalChanges = (item) => {
        const sid = item.row.original._sid
        let finalItem = item

        if (changedRecords.current[sid]) {
            finalItem = {
                ...item,
                row: {
                    ...item.row,
                    original: { ...item.row.original, ...changedRecords.current[sid] },
                },
            }
        }
        return finalItem
    }

    const firstVisibleRecordId = useMemo(
        () =>
            displayedStatuses?.reduce(
                (record, statusItem) =>
                    record ||
                    data?.find(
                        (x) =>
                            mergeLocalChanges(x).row.original[field.api_name] === statusItem.value
                    )?.row.original._sid,

                null
            ),
        [data, displayedStatuses, field.api_name]
    )

    // If we don't any statuses, it means either the
    // status field is deleted, or disabled, or the current
    // user doesn't have perms. Show a semi-friendly error
    // message below so at least it doesn't seem broken.
    if (!statuses || !statuses.length) {
        return (
            <Container p={8} textAlign="center">
                No status columns available.
            </Container>
        )
    }

    if (!data.length && false) {
        return (
            <Container
                title={title}
                buttons={buttons}
                topFilters={topFilters}
                search={search}
                onChange={onTitleChange}
                className={ONBOARDING_CLASSES.TABLE_CONTAINER}
            >
                {emptyContent}
            </Container>
        )
    }

    const handleDragEnd = (result) => {
        setIsDragging(false)

        // very long cards become unwieldy to drop, especially into the overflow columns
        // due to the way rbdnd calculates the displacement, so we shrink card outer container down
        // during dragging and restore it after they drop
        const draggable = document.querySelector(`[data-rbd-draggable-id=${result.draggableId}]`)
        if (draggable) draggable.setAttribute('style', `max-height: unset;`)

        if (!result.destination) return

        const { source, destination } = result
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return
        }

        const status = destination.droppableId !== 'none' ? destination.droppableId : null

        const update = {
            [field.api_name]: status,
        }

        if (viewOptions.orderType === 'manual') {
            const records = data.filter((x) => {
                return mergeLocalChanges(x).row.original[field.api_name] === status
            })

            const targetIndex =
                source.droppableId === destination.droppableId && source.index < destination.index
                    ? destination.index + 1
                    : destination.index

            const targetRecord =
                targetIndex < records.length
                    ? records[targetIndex]?.row.original
                    : records[records.length - 1]?.row.original
            const above = targetIndex < records.length

            const targetRecordListIndex = data.findIndex(
                (x) => x.row.original._sid === targetRecord?._sid
            )
            let newTemporaryDisplayOrder = null
            if (targetRecord) {
                if (above) {
                    if (targetRecordListIndex > 0) {
                        const adjacentItem = data[targetRecordListIndex - 1].row.original
                        newTemporaryDisplayOrder =
                            targetRecord._local_display_order -
                            (targetRecord._local_display_order -
                                adjacentItem._local_display_order) /
                                2
                    } else {
                        newTemporaryDisplayOrder = targetRecord._local_display_order - 1
                    }
                } else {
                    if (targetRecordListIndex < data.length - 1) {
                        const adjacentItem = data[targetRecordListIndex + 1].row.original
                        newTemporaryDisplayOrder =
                            targetRecord._local_display_order +
                            (adjacentItem._local_display_order -
                                targetRecord._local_display_order) /
                                2
                    } else {
                        newTemporaryDisplayOrder = targetRecord._local_display_order + 1
                    }
                }
            }

            update[above ? '_display_order_before' : '_display_order_after'] = targetRecord?._sid
            update._display_order_key = targetRecord ? `${view.stack_id}_${view._sid}` : undefined
            update._local_display_order = newTemporaryDisplayOrder
            update._display_order_order_by = viewOptions.order
                ? `${viewOptions.order.desc ? '-' : ''}${viewOptions.order.id}`
                : undefined
        }
        // const updateId = new Date().getTime()
        //changedRecords.current[result.draggableId] = { ...update, updateId }

        // Send the update to the server
        // recordActions.update(result.draggableId, update, { deferStoreUpdate: true, updateId })
        const changingRecord = data.find((x) => x.row.original._sid === result.draggableId)?.row
            .original
        editRecord(changingRecord, update)
        // TODO: add error handling?

        // Set some flags noting the last dropped card, and the last
        // column dropped on to enable some nice UI effects on those items
        setLastDropped(result.draggableId)
        setLastDroppedOnColumn(destination.droppableId)
    }

    const handleDragStart = (start) => {
        // If we have at least 200 px available to the left of our canvas,
        // then we have enough screen real estate to show the hidden column
        // palette to allow the user to drop cards into hidden statuses
        const rect = containerRef.current.getBoundingClientRect()
        if (rect.left > 200) {
            if (rect.top < 0) {
                // This top position helps us position our hidden status palette
                setScrollTop(rect.top * -1 + 16)
            } else {
                setScrollTop(0)
            }
        } else {
            setDisableHiddenColumns(true)
        }

        // Need to merge in any local pending changes to this item.
        // (ie., it may be in a new status than what the incoming data collection
        // currently reflects)
        const item = mergeLocalChanges(data.find((x) => x.row.original._sid === start.draggableId))

        if (item) {
            setCurrentDragSource(item.row.original[field.api_name])
        }

        // very long cards become unwieldy to drop, especially into the overflow columns
        // due to the way rbdnd calculates the displacement, so we shrink card outer container down
        // during dragging
        const draggable = document.querySelector(`[data-rbd-draggable-id=${start.draggableId}]`)
        if (draggable) draggable.setAttribute('style', `max-height: 100px;`)

        setIsDragging(true)
        setLastDropped(null)
        setLastDroppedOnColumn(null)
    }

    // hidden columns to the left of our current drag source column.
    // Limit the number of overflow columns. Too many and it becomes unusable.
    const hiddenLeftColumns = statuses
        .filter(
            (x) =>
                !displayedStatuses.includes(x) &&
                statuses.indexOf(x) < statuses.findIndex((x) => x.value === currentDragSource)
        )
        .slice(0, MAX_OVERFLOW_COLUMNS)

    // hidden columns to the right of our current drag source column
    const hiddenRightColumns = statuses
        .filter(
            (x) =>
                !displayedStatuses.includes(x) &&
                statuses.indexOf(x) > statuses.findIndex((x) => x.value === currentDragSource)
        )
        .slice(0, MAX_OVERFLOW_COLUMNS)

    // This is a bit of a hack, but there's a glitch we haven't been able to solve
    // where using scroll-wheel, then clicking in rapid succession right after the
    // page loads results in the wrong item being clicked.
    //
    // This solution is to simply ignore any clicks within 300ms of the last
    // scroll wheel event
    const handleWheel = () => {
        wheeling.current = true
        clearTimeout(wheeling.current)
        wheelingTimer.current = setTimeout(() => (wheeling.current = false), 300)
    }

    const handleMouseDown = (e) => {
        if (wheeling.current) {
            e.preventDefault()
            e.stopPropagation()
        }
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd} onBeforeCapture={handleDragStart}>
            {title && (
                <ContainerLabel
                    isEditable={titleIsEditable}
                    buttons={buttons}
                    topFilters={topFilters}
                    search={search}
                    value={title}
                    onChange={onTitleChange}
                />
            )}
            <Box
                maxWidth="100%"
                position="relative"
                ref={containerRef}
                onWheel={handleWheel}
                onClickCapture={handleMouseDown}
            >
                {additionalListContainerContent}

                {/* We use the innerWidth condition to not render the hidden columns for smaller screens than that
                as it causes an overflow */}
                {windowSize.width > 1677 && (
                    <Box
                        position="absolute"
                        left="-175px"
                        transition="opacity .2s"
                        transform={`translateY(${scrollTop}px)`}
                        opacity={showHiddenColumns && hiddenLeftColumns.length > 0 ? 1 : 0}
                    >
                        <Text size="sm" color="gray.300" mb={2}>
                            Send to...
                        </Text>
                        {hiddenLeftColumns.map((status) => (
                            <HiddenColumn
                                key={status.value || 'none'}
                                statusKey={status.value || 'none'}
                                statusLabel={status.label}
                                lastDroppedOnColumn={lastDroppedOnColumn}
                            />
                        ))}
                    </Box>
                )}
                {windowSize.width > 1677 && (
                    <Box
                        position="absolute"
                        right="-175px"
                        transition="opacity .2s"
                        transform={`translateY(${scrollTop}px)`}
                        opacity={showHiddenColumns && hiddenRightColumns.length > 0 ? 1 : 0}
                    >
                        <Text size="sm" color="gray.300" mb={2}>
                            Send to...
                        </Text>
                        {hiddenRightColumns.map((status) => (
                            <HiddenColumn
                                key={status.value || 'none'}
                                statusKey={status.value || 'none'}
                                statusLabel={status.label}
                                lastDroppedOnColumn={lastDroppedOnColumn}
                            />
                        ))}
                    </Box>
                )}

                <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    zIndex={100}
                    opacity={overflowingLeft ? 1 : 0}
                    pointerEvents="none"
                    transition="opacity .2s"
                    style={{ boxShadow: 'inset black 20px 0px 20px -30px' }}
                />

                <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    zIndex={100}
                    opacity={overflowingRight ? 1 : 0}
                    pointerEvents="none"
                    transition="opacity .2s"
                    style={{ boxShadow: 'inset black -20px 0px 20px -30px' }}
                />
                <Box
                    ref={gridRef}
                    style={{
                        display: 'grid',
                        gridTemplateColumns:
                            'repeat(' + displayedStatuses.length + ', minmax(250px, 1fr)',
                        gridColumnGap: '12px',
                        overflowX: 'auto',
                    }}
                    maxWidth="100%"
                    position="relative"
                    className={ONBOARDING_CLASSES.TABLE_CONTAINER}
                    {...hoverHandlers}
                >
                    {displayedStatuses.map((status, index) => {
                        const columnColor = field?.options?.allow_dropdown_colors
                            ? getDropdownFieldOptionColor(status) || '#DFE2E6'
                            : '#DFE2E6'
                        return (
                            <KanbanColumn
                                key={status.value || 'none'}
                                columnNumber={index + 1}
                                statusKey={status.value || 'none'}
                                statusValue={status.value}
                                label={status.label}
                                showDropZone={isDragging && currentDragSource !== status.value}
                                columnColor={columnColor}
                                reorderDisabled={reorderDisabled}
                                statusField={field}
                                object={object}
                                relatedListField={relatedListField}
                                relatedListFieldValue={relatedListFieldValue}
                                defaultValues={defaultValues}
                            >
                                {data
                                    .filter((x) => {
                                        const fieldValue =
                                            mergeLocalChanges(x).row.original[field.api_name]

                                        return (
                                            fieldValue === status.value ||
                                            (status.value.length === 0 &&
                                                (fieldValue === null || fieldValue === undefined))
                                        )
                                    })
                                    .map((item, idx, columnRows) => {
                                        let { actionButtons, cells, coverImage, props, row } =
                                            mergeLocalChanges(item)

                                        const columnRecords = columnRows.map((x) => x.row.original)
                                        const openRecordWithColumnRecords = (recordId) =>
                                            openRecord(recordId, columnRecords)

                                        return (
                                            <KanbanCard
                                                key={item.row.original._sid}
                                                rowLink={rowLink}
                                                actionButtons={actionButtons}
                                                cells={cells}
                                                coverImage={coverImage}
                                                props={props}
                                                row={row}
                                                rowIndex={idx}
                                                colIndex={index}
                                                justDropped={item.row.original._sid === lastDropped}
                                                field={field}
                                                object={object}
                                                viewOptions={viewOptions}
                                                setConfig={setConfig}
                                                isFirstCard={
                                                    firstVisibleRecordId === item.row.original._sid
                                                }
                                                isContainerHovered={isContainerHovered}
                                                openRecord={openRecordWithColumnRecords}
                                            />
                                        )
                                    })}
                            </KanbanColumn>
                        )
                    })}
                </Box>
            </Box>
        </DragDropContext>
    )
}

export default React.memo(Kanban)

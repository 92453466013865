import * as SwitchPrimitive from '@radix-ui/react-switch'

import { SelectorLabel } from 'ui/components/Field'
import { makeComponent } from 'ui/helpers/recipes'

import { ContainerStyles, LabelStyles, ThumbStyles, ToggleStyles } from './Toggle.css'

export const Container = makeComponent('div', ContainerStyles)
export const Root = makeComponent(SwitchPrimitive.Root, ToggleStyles)
export const Thumb = makeComponent(SwitchPrimitive.Thumb, ThumbStyles)
export const Label = makeComponent(SelectorLabel, LabelStyles)

import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'

import PropTypes from 'prop-types'

import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import { withUser } from 'data/wrappers/withUser'
import { AuthPageFrame } from 'features/auth/stacker-auth/AuthPageFrame'
import { Button, Text } from 'features/auth/stacker-auth/AuthUIElements'

import { LoadingSplash } from 'v2/ui'

import { useAuthContext } from './AuthContext/AuthContext'

const InnerLogoutRoute = ({ isUserDomain, user, ...rest }) => {
    const { logOut } = useAuthContext()
    const { workspaceAccount } = useAppContext()
    const [redirectToLogin, setRedirectToLogin] = useState(false)

    useEffect(() => {
        async function logout() {
            await logOut()

            if (isUserDomain && !workspaceAccount) {
                window.location.assign(settings.STUDIO_URL + '/logout')
            } else {
                setRedirectToLogin(true)
            }
        }
        logout()
    }, [isUserDomain, workspaceAccount, user, logOut])

    if (redirectToLogin) {
        return (
            <Route
                user={user}
                {...rest}
                render={(props) => (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )}
            />
        )
    }

    if (user) {
        return <LoadingSplash />
    } else if (workspaceAccount) {
        return (
            <AuthPageFrame title={'Logged out'}>
                <Text mb={8}>You have logged out of your session.</Text>
                <Button href={getUrl(Urls.Login)}>Login</Button>
            </AuthPageFrame>
        )
    }

    return null
}
InnerLogoutRoute.propTypes = {
    isUserDomain: PropTypes.bool.isRequired,
    location: PropTypes.string.isRequired,
    userActions: PropTypes.object.isRequired, // from withUser
}

export const LogoutRoute = withUser(InnerLogoutRoute)

import { buildQueryKey, queryClient } from 'data/hooks/_helpers'

const QUERY_NAME: REACT_QUERY_DEPS_NAME = 'useUserRecord'

export function getCurrentUserRecord() {
    const key = buildQueryKey(QUERY_NAME, {
        includeAuthKeys: true,
        includeStackId: true,
    })
    const currentUser = queryClient.getQueryData(key)
    return Array.isArray(currentUser) ? currentUser[0] : currentUser
}

export function getUserProfileRecord(recordId: string) {
    const key = buildQueryKey(QUERY_NAME, {
        includeAuthKeys: true,
        includeStackId: true,
    })
    const currentUser = queryClient.getQueryData(key)
    return (currentUser as UserDto[]).find((r) => r._sid == recordId)
}

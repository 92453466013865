import React from 'react'

import type { Placement } from '@popperjs/core'

import { ContextSchema } from 'features/expressions/types'
import { SuggestionComponentHandle } from 'features/tiptap/Extensions/extensionHelpers'

import Popper from 'v2/ui/components/Popper'
import { autoSizeModifier } from 'v2/ui/components/popperModifiers/autoSizeModifier'

import { ContextItemsMenu } from './ContextItemsMenu'
import { BaseExtensionProps } from './types'

type ContextMenuPopoverProps = BaseExtensionProps & {
    provideSchema: () => ContextSchema
    open?: boolean
    placement?: Placement
    target?: HTMLElement
}
export const ContextMenuPopover = React.forwardRef<
    SuggestionComponentHandle,
    ContextMenuPopoverProps
>(({ open, placement = 'left', target, ...props }, ref) => {
    if (!open || !target) return null

    return (
        <Popper placement={placement} referenceElement={target} modifiers={[autoSizeModifier]}>
            <ContextItemsMenu ref={ref} {...props} />
        </Popper>
    )
})

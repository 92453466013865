import { useMemo } from 'react'

import { useSearchContext } from 'features/views/ListView/Search/useSearchContext'

export function useListHeaderSearch() {
    const { query, setQuery, allowSearch } = useSearchContext()

    return useMemo(
        () => ({
            query,
            setQuery,
            allowSearch,
        }),
        [allowSearch, query, setQuery]
    )
}

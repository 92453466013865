import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { AccountUserContext } from 'app/AccountUserContext'
import { useAuthContext } from 'app/AuthContext/AuthContext'
import { useAppContext } from 'app/useAppContext'
import { isRightGranted } from 'features/auth/utils/roleUtils'

export const AccountUserContextProvider = ({ children }) => {
    const { workspaceAccount } = useAppContext()
    const [role, setRole] = useState()
    const { user } = useAuthContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const roles = workspaceAccount?.roles || []

    // Update the user's role when the user, roles, or selectedStack change.
    useEffect(() => {
        if (!user || !roles?.length) return setRole(null)
        const role = roles.find((role) => role.api_name === user.account_role_api_name)
        setRole(role)
    }, [user, roles])

    // Returns whether or not the current user has the specified right
    // in the context of the current stack
    const hasRight = useCallback(
        (right) => {
            if (!user || !role) return false

            const rights = role.options?.rights || []

            return isRightGranted(rights, right)
        },
        [role, user]
    )

    const userHasRight = useCallback(
        (currentUser, right) => {
            if (!currentUser) return false
            const currentRole = roles.find(
                (role) => role.api_name === currentUser.account_role_api_name
            )
            if (!currentRole) return false
            const rights = currentRole.options?.rights || []

            return isRightGranted(rights, right)
        },
        [roles]
    )

    const contextState = useMemo(() => {
        return {
            user,
            role: role,
            hasRight: hasRight,
            isLoading: !user || !role,
            userHasRight,
        }
    }, [user, role, hasRight, userHasRight])
    return (
        <AccountUserContext.Provider value={contextState}>{children}</AccountUserContext.Provider>
    )
}

import React, { forwardRef } from 'react'

import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { useDropdownContext } from 'ui/components/Dropdown/dropdownContext'
import { stopPropagation } from 'ui/helpers/utilities'

export type SelectorOption = {
    value: string
    label: string
    disabled?: boolean
}

type SegmentSelectorRef = HTMLButtonElement

type SegmentSelectorProps = Omit<
    React.ComponentPropsWithoutRef<typeof DropdownButton>,
    'onChange'
> & {
    value?: string
    onChange: (value: string) => void
    options: SelectorOption[]
}

export const SegmentSelector = forwardRef<SegmentSelectorRef, SegmentSelectorProps>(
    ({ value, options, onChange, ...props }, ref) => {
        const selectedOption = options.find((option) => option.value === value)

        const isDisabled = options.length < 2

        return (
            <Dropdown>
                <DropdownButton
                    variant="ghost"
                    size="xs"
                    ref={ref}
                    disabled={isDisabled}
                    {...props}
                >
                    {selectedOption?.label || value}
                </DropdownButton>
                <DropdownContent
                    onClick={stopPropagation}
                    className="react-datepicker-ignore-onclickoutside"
                >
                    {options.map((option) => {
                        const isActive = option.value === value

                        return (
                            <SegmentSelectorItem
                                key={option.value}
                                isActive={isActive}
                                onChange={onChange}
                                value={option.value}
                                label={option.label}
                                disabled={option.disabled}
                            />
                        )
                    })}
                </DropdownContent>
            </Dropdown>
        )
    }
)

type SegmentSelectorItemProps = SelectorOption & {
    onChange: (value: string) => void
    isActive: boolean
}

const SegmentSelectorItem: React.FC<SegmentSelectorItemProps> = ({
    value,
    label,
    onChange,
    isActive,
}) => {
    const { setIsOpen } = useDropdownContext()!

    return (
        <DropdownItem
            label={label}
            checked={isActive}
            onCheckedChange={(checked) => {
                if (checked) {
                    onChange(value)
                }
            }}
            onSelect={() => {
                setIsOpen(false)
            }}
            multiSelect={true}
        />
    )
}

import React from 'react'

import { Activity } from 'features/Activity/Activity'

import useHover from 'v2/ui/utils/useHover'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'

import { NotificationControls } from './NotificationControls'
import { NotificationProps } from './types'

export function ActivityNotification({
    notification,
    relatedData,
    markAsRead,
    markAsArchived,
}: NotificationProps) {
    // const { activity_id } = notification
    const [isHovered, hoverHandlers] = useHover()

    const activity = relatedData.activities.find((a) => a.auto_id === notification.activity_id)

    if (!activity) return null
    return (
        <Box flex column>
            {activity && (
                <Box px="xs" {...hoverHandlers}>
                    <Activity
                        compact
                        key={notification.is_read.toString()}
                        startCollapsed={notification.is_read}
                        activity={activity}
                        controls={
                            <NotificationControls
                                showUnreadIndicator={!notification.is_read}
                                notifications={[notification]}
                                isHovered={isHovered}
                                markAsRead={markAsRead}
                                markAsArchived={markAsArchived}
                            />
                        }
                        showLocationPreview={true}
                    />
                </Box>
            )}
            <Divider my="m" />
        </Box>
    )
}

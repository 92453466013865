import React, { ChangeEvent } from 'react'

import styled from '@emotion/styled'

import { Input } from 'v2/ui'
import { SolidClose, SolidSearch } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 5px;
`

const StyledSolidClose = styled(SolidClose)`
    cursor: pointer;

    transform: scale(1.2);

    fill: ${colors.userInterface.neutral[800]};
    transition: fill 0.2s ease;

    &:hover {
        fill: ${colors.userInterface.accent[1000]};
    }
`

type Props = {
    value: string
    onChange: (search: string) => void
    onClose: () => void
    autoFocus?: boolean
    placeholder?: string
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

const SearchInput = React.forwardRef<HTMLInputElement, Props>(
    (
        { value, onChange, onClose, autoFocus, inputProps, placeholder = 'Find a data type' },
        ref
    ) => (
        <Wrapper>
            <SolidSearch fill={colors.userInterface.accent[1000]} />
            <Input
                ref={ref}
                style={{ borderWidth: 0 }}
                autoFocus={autoFocus}
                groupProps={{
                    style: { width: '100%' },
                }}
                placeholder={placeholder}
                variant="settings"
                value={value}
                onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                data-testid="search.find-data-type"
                {...inputProps}
            />
            <StyledSolidClose onClick={onClose} />
        </Wrapper>
    )
)

export default SearchInput

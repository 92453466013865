import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { getLocale } from 'utils/date/dateUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseCurrencyAttributeDisplayStateProps = {
    field: FieldDto
    value?: number
    isLoading?: boolean
    showIcon?: boolean
}

export function useCurrencyAttributeDisplayState({
    field,
    value,
    isLoading,
    showIcon,
}: UseCurrencyAttributeDisplayStateProps) {
    const content = formatCurrencyValue(field, isLoading ? PLACEHOLDER_VALUE : value)

    const fieldIcon = getFieldIcon(field)
    const icon = useDeepEqualsMemoValue(
        showIcon && fieldIcon?.type === 'lucide' ? fieldIcon : undefined
    )

    return { content, icon }
}

const MAX_FRACTION_DIGITS = 2

function formatCurrencyValue(field: FieldDto, value?: number): string {
    const currencySign = field.options?.currency_symbol

    let formattedValue = '0'
    if (typeof value === 'number' && !Number.isNaN(value)) {
        const locale = getLocale()
        const formatter = new Intl.NumberFormat(locale, {
            useGrouping: true,
            minimumFractionDigits: MAX_FRACTION_DIGITS,
            maximumFractionDigits: MAX_FRACTION_DIGITS,
        })
        formattedValue = formatter.format(value)
    }

    if (currencySign) {
        return `${currencySign}\u200A${formattedValue}`
    }
    return formattedValue
}

const PLACEHOLDER_VALUE: number = 1234.56789

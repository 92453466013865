import React from 'react'

import { NavigationApp, NavigationAppMenuItem } from './types'

export type AppNavigationContextValue = {
    menuItems: NavigationAppMenuItem[]
    executeActionInItem: (item: NavigationAppMenuItem, action: () => void) => Promise<void>
    app: NavigationApp
}

export const AppNavigationContext = React.createContext<AppNavigationContextValue | null>(null)

export const USER_TABLE_KEY = 'user_table'
export const DEFAULT_TRIGGER_ICON = 'bolt'
export const DEFAULT_ACTION_ICON = 'gear'

export const WORKFLOW_SID_PREFIX = 'flow.'

export function filterRecordDataFields(
    fields?: FieldDto[],
    options: { excludeReadOnly?: boolean } = { excludeReadOnly: false }
): FieldDto[] {
    if (!fields) return []

    return fields.filter((f) => {
        return !(f.is_read_only && options.excludeReadOnly)
    })
}

export const DRAFT_WORKFLOW_ID = 'draft'

export function makeDraftWorkflow(schemaVersion: number = 1): WorkflowDto {
    return {
        _sid: DRAFT_WORKFLOW_ID,
        _object_id: 'flow',
        name: 'Untitled Workflow',
        trigger: {
            id: 'trigger',
            kind: 'trigger',
            trigger_type: '',
        },
        chain: [],
        notes: null,
        schema_version: schemaVersion,
        is_enabled: false,
    }
}

export function isDraftWorkflow(workflow: WorkflowDto): boolean {
    return workflow._sid === DRAFT_WORKFLOW_ID
}

export function isWorkflowSid(sid: string): boolean {
    return sid.startsWith(WORKFLOW_SID_PREFIX)
}

export function getNodeAtPath(path: string[], workflow: WorkflowDto): WorkflowNode | undefined {
    if (path.length === 1) {
        return findNodeInChain(path[0], workflow.chain)
    }

    const reversedPath = path.slice().reverse()
    return findNodeAtPathInChain(reversedPath, workflow.chain)
}

export function findNodeInChain(
    id: string,
    chain: WorkflowNode[],
    deep = false
): WorkflowNode | undefined {
    if (!deep) return chain.find((item) => item.id === id)

    for (const item of chain) {
        if (item.id === id) return item
        if (item.kind === 'loop') {
            const node = findNodeInChain(id, item.chain, true)
            if (node) return node
        }
    }
}

function findNodeAtPathInChain(path: string[], chain: WorkflowNode[]): WorkflowNode | undefined {
    if (path.length < 1) return undefined

    const [id, ...tail] = path
    const node = findNodeInChain(id, chain)
    if (!node) return undefined

    if (node.kind === 'loop' && tail.length > 0) {
        return findNodeAtPathInChain(tail, node.chain)
    }

    return node
}

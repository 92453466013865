import React from 'react'

import { useAppUserContext } from 'app/useAppUserContext'
import { ActivityType } from 'data/hooks/activityTypes'
import { assertIsDefined } from 'data/utils/ts_utils'

import Avatar from 'v2/ui/components/Avatar'

import { Box } from 'ui/components/Box'

import { CommentEditor, CommentEditorProps } from './CommentEditor'
import { CommentContent } from './types'
import { useActivityFeedContext } from './useActivityFeedContext'

export type CreateCommentProps = Omit<CommentEditorProps, 'onSubmit'> & {
    replyingTo?: ActivityDto
    locationPreview?: string
}
export function CreateComment({ replyingTo, locationPreview, ...props }: CreateCommentProps) {
    const { user } = useAppUserContext()
    const { createActivity } = useActivityFeedContext()
    assertIsDefined(user)
    const handleSubmit = async (message: CommentContent) => {
        const activity: Partial<ActivityDto> = {
            created_by: user?._sid,
            related_to: replyingTo?.related_to,
            related_to_type: replyingTo?.related_to_type,
            related_to_location: replyingTo?.related_to_location,
            object_id: replyingTo?.object_id,
            stack_id: replyingTo?.stack_id,
            type: ActivityType.Comment,
            parent_activity_id: replyingTo?.auto_id,
            content: message,
        }

        const actualLocationPreview = replyingTo?.content?.locationPreview || locationPreview
        if (actualLocationPreview) {
            activity.content = {
                ...activity.content,
                locationPreview: actualLocationPreview,
            }
        }

        createActivity(activity)
    }

    return (
        <Box flex center width="full">
            {!replyingTo && (
                <Avatar src={user.avatar} name={user.name || user.email} size="xs" mr={2} />
            )}
            <Box grow style={{ minWidth: 0 }}>
                <CommentEditor onSubmit={handleSubmit} stackId={replyingTo?.stack_id} {...props} />
            </Box>
        </Box>
    )
}

export enum ObservableEvents {
    RECORD_CREATED = 'RECORD_CREATED',
    RECORD_UPDATED = 'RECORD_UPDATED',
    RECORD_DELETED = 'RECORD_DELETED',
}

type EventHandlerInputTypes = {
    [ObservableEvents.RECORD_CREATED]: { recordSid: string }
    [ObservableEvents.RECORD_UPDATED]: { recordSid: string }
    [ObservableEvents.RECORD_DELETED]: { recordSid: string }
}
type EventHandlerInputType = EventHandlerInputTypes[keyof EventHandlerInputTypes]

type handlerFn = (data: EventHandlerInputType) => void

export type EventHandler = {
    event: ObservableEvents
    handler: handlerFn
}

// mapping of event to observers for that event
const observers: Map<ObservableEvents, handlerFn[]> = new Map()

export const subscribeToEvent = ({ event, handler }: EventHandler) => {
    const handlers = observers.get(event) || []
    handlers.push(handler)
    observers.set(event, handlers)
}

export const unsubscribeToEvent = ({ event, handler }: EventHandler) => {
    const handlers = observers.get(event)
    if (handlers) {
        // Filter out the handler to be removed
        const newHandlers = handlers.filter((fn) => fn !== handler)
        observers.set(event, newHandlers)
    }
}

export const notifyEvent = ({
    event,
    data,
}: {
    event: ObservableEvents
    data: EventHandlerInputType
}) => {
    const handlers = observers.get(event)
    handlers?.forEach((handler) => handler(data))
}

import { useMemo } from 'react'

import { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

import { ExecuteActionModal, USE_EXECUTE_ACTION_MODAL_KEY } from './ExecuteActionModal'

export const useExecuteActionModal = (
    action: ActionDto,
    recordId: string,
    includeFields: string[]
) => {
    const data = useMemo(
        () => ({ action, includeFields, recordId }),
        [action, includeFields, recordId]
    )
    return useModalDeclaration(USE_EXECUTE_ACTION_MODAL_KEY, ExecuteActionModal, data)
}

import { useMemo } from 'react'

import { AppRole } from 'features/users/AppUsers/types'

import { IconName } from 'ui/components/Icon/Icon'
import { SelectOption } from 'ui/components/Select'
import { truncateText } from 'ui/helpers/utilities'

const MAX_LABEL_LENGTH = 30

type RoleOption = {
    label: string
    value: string
    isAdmin: boolean
    icon: IconName
    variant: React.ComponentPropsWithoutRef<typeof SelectOption>['variant']
}

type UseAppUsersAddUsersRoleSelectStateOptions = {
    roles: AppRole[]
    value: string
}

export function useAppUsersAddUsersRoleSelectState(
    options: UseAppUsersAddUsersRoleSelectStateOptions
) {
    const { roles, value } = options

    const roleOptions: RoleOption[] = useMemo(() => {
        const options = roles.map((role) => {
            const value = role.id
            const isAdmin = value === 'internal_admin'

            let label = truncateText(role.name, MAX_LABEL_LENGTH)
            if (isAdmin) {
                label = 'Administrator'
            }

            const icon: RoleOption['icon'] = isAdmin ? 'Crown' : 'Lock'

            const variant: RoleOption['variant'] = isAdmin ? 'action' : 'default'

            return {
                label,
                value,
                isAdmin,
                variant,
                icon,
            }
        })

        return options.sort((a, b) => {
            // Always show admins first.
            if (a.isAdmin && !b.isAdmin) return -1
            if (!a.isAdmin && b.isAdmin) return 1

            // Otherwise sort by label alphabetically.
            return a.label.localeCompare(b.label)
        })
    }, [roles])

    const selectedRoleOption = roleOptions.find((option) => option.value === value)

    return { selectedRoleOption, roleOptions }
}

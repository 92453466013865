import { FormulaType } from 'data/utils/fieldDefinitions'

import { ConfigurationEditorProps } from './formulaConfigurationTypes'
import { NumberConfigurationEditor } from './NumberConfigurationEditor'

export const CONFIGURATION_EDITOR_COMPONENTS: {
    [key in FormulaType]?: React.ComponentType<ConfigurationEditorProps>
} = {
    [FormulaType.Number]: NumberConfigurationEditor,
}

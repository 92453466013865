export function getBlobFromClipboardEvent(event: React.ClipboardEvent): Blob | undefined {
    const clipboardItems = event.clipboardData.items
    const dataTransferItems = Array.from(clipboardItems).filter((item) =>
        item.type.includes('image')
    )

    // Get the blob of image.
    const blob = dataTransferItems[0]?.getAsFile() ?? undefined

    return blob
}

export function formatCounterLabel(count: number): string {
    if (count === 1) {
        return '1 attachment'
    }

    return `${count} attachments`
}

import React, { FC, ReactNode } from 'react'

import { RadioProps, useRadio } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Text } from 'v2/ui'

const WRAPPERS = {
    full: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 0;
        gap: 0.5rem;
        cursor: pointer;
    `,
    compact: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        flex: 1 1 0;
    `,
}

type ContainerProps = {
    backgroundColorChecked?: string
    backgroundColorUnchecked?: string
}
const CONTAINERS = {
    full: styled.div<ContainerProps>`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: ${(props) => props.theme.space[4]};
        padding-bottom: ${(props) => props.theme.space[4]};
        height: 56px;
        border-width: 1px;
        border-radius: ${(props) => props.theme.radii.md};
        border-color: ${(props) => props.theme.colors.userInterface.neutral[400]};
        width: 100%;
        justify-content: center;
        background-color: ${(props) => props.backgroundColorUnchecked};
        &[data-checked] {
            border-color: ${(props) => props.theme.colors.userInterface.accent[1000]};
            background-color: ${(props) => props.backgroundColorChecked};
        }
    `,
    compact: styled.div`
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 29px;
        width: 100%;
        border-radius: 4px;
        &[data-checked] {
            background-color: ${(props) => props.theme.colors.userInterface.neutral[0]};
            box-shadow:
                0px 5px 8px -5px rgba(10, 9, 11, 0.04),
                0px 1px 4px 1px rgba(10, 9, 11, 0.02);
        }
    `,
}

const LABELS = {
    full: styled(Text)`
        overflow: hidden;
        white-space: nowrap;
    `,
    compact: styled(Text)`
        font-size: ${(props) => props.theme.fontSizes.xs};
    `,
}

type CustomRadioProps = RadioProps & {
    label: string
    icon?: ReactNode
    bgChecked?: string
    bgNotChecked?: string
    'data-testid'?: string
    variant: 'full' | 'compact'
}

export const CustomRadio: FC<CustomRadioProps> = ({
    label,
    icon,
    bgChecked,
    bgNotChecked,
    'data-testid': dataTestId,
    variant = 'full',
    ...radioProps
}) => {
    const { getInputProps, getCheckboxProps } = useRadio(radioProps)
    const input = getInputProps()
    const checkbox = getCheckboxProps()

    const Wrapper = WRAPPERS[variant]
    const Container = CONTAINERS[variant]
    const Label = LABELS[variant]

    return (
        <Wrapper as="label">
            <input {...input} />
            <Container
                {...checkbox}
                backgroundColorChecked={bgChecked}
                backgroundColorUnchecked={bgNotChecked}
                data-testid={dataTestId}
            >
                {icon}
                {/* @ts-expect-error */}
                {!icon && <Label fontWeight={input.checked ? 'bold' : 'normal'}>{label}</Label>}
            </Container>
            {/* @ts-expect-error */}
            {icon && <Label fontWeight={input.checked ? 'bold' : 'normal'}>{label}</Label>}
        </Wrapper>
    )
}

import React from 'react'

import { ActivityType } from 'data/hooks/activityTypes'

import { Box, BoxProps } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { IconName } from 'ui/components/Icon/Icon'
import { StandardStyles } from 'ui/helpers/StandardStyles.css'

const TypeIconLookup: Record<ActivityType, IconName> = {
    [ActivityType.Comment]: 'MessagesSquare',
    [ActivityType.RecordCreated]: 'Plus',
    [ActivityType.RecordUpdated]: 'Pen',
    [ActivityType.RecordDeleted]: 'Trash',
    [ActivityType.RecordRestored]: 'RotateCcw',
    [ActivityType.TaskCompleted]: 'Check',
    [ActivityType.TaskDueDateChanged]: 'Calendar',
    [ActivityType.TaskAssigneesChanged]: 'User',
    [ActivityType.Reaction]: 'MessageSquare',
}

type TypeIconProps = BoxProps & {
    type: ActivityType
    size?: StandardStyles['width'] & StandardStyles['height']
}

export function TypeIcon({ type, size = '3xl', ...props }: TypeIconProps) {
    return (
        <Box
            flex
            column
            center
            justifyContent="center"
            width={size}
            height={size}
            rounded="pill"
            background="surfaceStrongest"
            flexShrink={0}
            {...props}
        >
            <Icon name={TypeIconLookup[type]} color="text" opacity={0.5} />
        </Box>
    )
}

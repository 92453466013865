import React from 'react'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { UserMenuButton } from 'features/workspace/Sidebar'

import { Button, ButtonProps } from 'ui/components/Button'
import { theme } from 'ui/styling/Theme.css'

type UserMenuButtonInnerRef = HTMLButtonElement
type UserMenuButtonInnerProps = ButtonProps & {
    height?: number
}

const UserMenuButtonInner = React.forwardRef<UserMenuButtonInnerRef, UserMenuButtonInnerProps>(
    // Ignore height prop, since it crashes the button component.
    ({ height: _height, ...props }, ref) => {
        return (
            <Button
                ref={ref}
                {...props}
                variant="ghost"
                style={{
                    paddingLeft: theme.space.l,
                    paddingRight: theme.space.l,
                    fontWeight: theme.fontWeights.bodyRegular as any,
                    height: theme.space['5xl'],
                    borderRadius: 0,
                }}
                size="l"
            />
        )
    }
)

export function SidebarUserMenuButton({ navTheme }: { navTheme: any }) {
    const { user } = useAuthContext()
    return <UserMenuButton as={UserMenuButtonInner} width="full" user={user} navTheme={navTheme} />
}

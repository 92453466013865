import { Editor } from '@tiptap/core'
import { Range } from '@tiptap/react'

export type MentionUser = {
    id: string
    label: string
}

export function userToMentionAttrs(user: UserDto): MentionUser {
    return {
        id: user._sid,
        label: user.name || user.email,
    }
}

export function insertMention(editor: Editor, range: Range, user: UserDto) {
    // increase range.to by one when the next node is of type "text"
    // and starts with a space character
    const nodeAfter = editor.view.state.selection.$to.nodeAfter
    const overrideSpace = nodeAfter?.text?.startsWith(' ')

    if (overrideSpace) {
        range.to += 1
    }

    queueMicrotask(() => {
        editor
            .chain()
            .focus()
            .insertContentAt(range, [
                {
                    type: 'mention',
                    attrs: userToMentionAttrs(user),
                },
                {
                    type: 'text',
                    text: ' ',
                },
            ])
            .run()

        window.getSelection()?.collapseToEnd()
    })
}

import React, { useContext } from 'react'

export type PaginationContextValue = {
    currentPageIndex: number
    setCurrentPageIndex: (pageIndex: number) => void
    pageSize: number
}
export const PaginationContext = React.createContext<PaginationContextValue | null>(null)

export function usePaginationContext(): PaginationContextValue | null {
    return useContext(PaginationContext)
}

import { useMemo } from 'react'

import { useAppNavigationContext } from './useAppNavigationContext'

export const useAppNavigationTreeState = () => {
    const { menuItems } = useAppNavigationContext()

    return useMemo(
        () => ({
            menuItems,
        }),
        [menuItems]
    )
}

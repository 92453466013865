import React from 'react'

import { closestCorners, DndContext, DragOverlay } from '@dnd-kit/core'

import { useBoardViewContentState } from './hooks/useBoardViewContentState'
import { BoardViewCard } from './BoardViewCard'
import { BoardViewColumnRenderer } from './BoardViewColumnRenderer'

type BoardViewContentProps = {}

export const BoardViewContent: React.FC<BoardViewContentProps> = React.memo(
    function BoardViewContent() {
        const {
            columns,
            sensors,
            onDragStart,
            onDragEnd,
            onDragOver,
            onDragCancel,
            activeRecord,
            records,
        } = useBoardViewContentState()

        return (
            <DndContext
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                onDragCancel={onDragCancel}
            >
                {columns?.map((c) => (
                    <BoardViewColumnRenderer key={c.id} records={records?.[c.id]} column={c} />
                ))}
                <DragOverlay>
                    {activeRecord && <BoardViewCard record={activeRecord} isDraggingOverlay />}
                </DragOverlay>
            </DndContext>
        )
    }
)

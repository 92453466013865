export function getCustomBackendConfig() {
    const isActive = window.localStorage.getItem('customBackendSettings_isActive')

    if (!import.meta.env.VITE_USE_CUSTOM_BACKEND || isActive !== '1') {
        return {}
    }

    const customBackendSettings = window.localStorage.getItem('customBackendSettings')

    if (customBackendSettings) {
        return JSON.parse(customBackendSettings)
    }

    return {
        'x-origin': 'https://v4.stacker.dev',
        'x-backend': 'https://stacker.dev',
    }
}

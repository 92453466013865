import React from 'react'

import { NotificationType } from 'data/hooks/activityTypes'

import { ActivityNotification } from './ActivityNotification'
import { TaskNotification } from './TaskNotification'
import { NotificationProps } from './types'

export function Notification(props: NotificationProps) {
    switch (props.notification.type) {
        case NotificationType.Mention:
        case NotificationType.FollowedFeed:
            return <ActivityNotification {...props} />
        case NotificationType.TaskAssigned:
            return <TaskNotification {...props} />
        default:
            return null
    }
}

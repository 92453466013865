// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { useStacks } from 'data/hooks/stacks'
import { useInitialMetadata } from 'data/hooks/useInitialMetadata'
import { STACK_SELECTED } from 'data/utils/constants'
import { getStackForCurrentUrl } from 'data/utils/utils'

import { AppContext } from './AppContext'
import { GlobalStaticState } from './GlobalStaticState'
import { useWorkspaceContext } from './WorkspaceContext'

/*
    This provider keeps track of which workspace account (if any) and which
    stack we are currently running under.
*/

export const AppContextProvider = ({ children }) => {
    const { workspaceAccount, workspaceZone } = useWorkspaceContext()
    const [selectedStack, setSelectedStackState] = useState<StackDto | null>(null)
    const [lastVisitedStack, setLastVisitedStackState] = useState<StackDto | null>(null)
    const location = useLocation()
    const history = useHistory()

    const setSelectedStack = useCallback((newStack) => {
        setSelectedStackState(() => {
            // Keep the global static state object up to date
            // so these ids are accessible to non-react code such as APIs etc
            GlobalStaticState.setCurrentStack(newStack)
            return newStack
        })
        if (newStack) {
            setLastVisitedStackState(newStack)
        }
    }, [])
    const initializeSelectedStack = useCallback(
        (stacks) => {
            if (!stacks) {
                return
            }

            const newStack = getStackForCurrentUrl(workspaceAccount?._sid, stacks)
            // update the url to that app if not set already
            if (workspaceAccount?._sid && location.pathname === '/' && newStack) {
                return history.replace('/' + newStack.url_slug)
            }
            setSelectedStack(newStack)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSelectedStack, workspaceAccount, location]
    )

    const { isLoading, initialLoadComplete, loadingAccountFailed } = useInitialMetadata({
        onLoadHandlers: { stacks: initializeSelectedStack },
    })

    const { data: stacks } = useStacks()
    const dispatch = useDispatch()
    const previousStack = useRef<StackDto | null>()

    // When the stack changes, we dispatch this redux message so the reducers
    // that need to can clear state.
    useEffect(() => {
        if (previousStack.current?._sid !== selectedStack?._sid) {
            dispatch({ type: STACK_SELECTED })
        }

        previousStack.current = selectedStack
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStack])

    // Watch for change in location and if the selected stack should change
    // issue the redux action
    useMemo(() => {
        initializeSelectedStack(stacks)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, stacks, workspaceAccount, initializeSelectedStack])

    const isSubscribed = workspaceAccount?.subscription_status === 'subscribed'

    const contextData = useMemo(() => {
        return {
            workspaceAccount,
            workspaceZone: workspaceZone ?? null,
            selectedStack,
            lastVisitedStack,
            isLoading,
            initialLoadComplete,
            setSelectedStack,
            loadingAccountFailed,
            isSubscribed,
        }
    }, [
        workspaceAccount,
        workspaceZone,
        selectedStack,
        lastVisitedStack,
        isLoading,
        initialLoadComplete,
        setSelectedStack,
        loadingAccountFailed,
        isSubscribed,
    ])

    return <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
}

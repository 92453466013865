import { acceptHeaders, fetchAndReturn, StackerAPI } from 'data/utils/utils'

class FieldApi extends StackerAPI {
    path = 'fields/'

    bulkPatch(data) {
        return fetchAndReturn(this.path, {
            method: 'PATCH',
            headers: acceptHeaders,
            body: JSON.stringify(data),
        })
    }

    getObjectFields(options) {
        return fetchAndReturn(this.path, undefined, undefined, undefined, options)
    }
}

export const fieldApi = new FieldApi()

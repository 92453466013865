import { makeAppVariables } from './app'

export function makeFieldVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            labelDisabled: s.color.textLabelDisabled,
            requiredMark: s.color.textError,
            textHelper: s.color.textHelper,
            textHelperError: s.color.textError,
            infoMark: s.color.icon,
            optionalMark: s.color.textWeaker,
        },
        fontWeights: {
            label: s.fontWeights.bodyMedium,
        },
        lineHeights: {
            label: s.lineHeights.bodyM,
        },
        fontSizes: {
            label: s.fontSizes.bodyM,
        },
        gap: {
            label: s.space.m,
            requiredMark: s.space['2xs'],
            optionalMark: s.space['2xs'],
            infoMark: s.space.xs,
            helperText: s.space.xs,
        },
    }
}

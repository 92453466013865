import React from 'react'

import { useToast as useChakraToast } from '@chakra-ui/react'

import Alert from 'v2/ui/components/Alert'

export const useToast = () => {
    const toast = useChakraToast()

    return ({
        variant = 'solid',
        isClosable = false,
        duration = 2000,
        status = 'success',
        ...props
    }) => {
        toast({
            status: 'success',
            render: ({ onClose }) => (
                <Alert
                    variant={variant}
                    onClose={onClose}
                    isClosable={isClosable}
                    {...props}
                    status={status}
                />
            ),
            duration,
            ...props,
        })
    }
}

import { useMutation, UseMutationOptions } from 'react-query'

import { reloadMetadataAfterObjectChange } from 'data/hooks/refreshData'
import { fetchAndReturn } from 'data/utils/utils'

export type DataConnectionDtoPatch = Omit<Partial<DataConnectionDto>, '_sid' | '_object_id'>

export function useCreateDataConnection(
    options: UseMutationOptions<DataConnectionDtoPatch, unknown, DataConnectionDtoPatch> = {}
) {
    return useMutation(
        async (dataConnection: DataConnectionDtoPatch) => {
            return fetchAndReturn('data-connections/', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(dataConnection),
            }) as Promise<DataConnectionDtoPatch>
        },
        {
            ...options,
            onSettled: () => {
                reloadMetadataAfterObjectChange()
            },
        }
    )
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { METRIC_NARROW_WIDTH } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/InvalidBlock'

import { Text } from 'v2/ui'
import { ChartsBlockError } from 'v2/ui/svgs'

const StyledTitle = styled(Text)`
    align-self: center;
    font-weight: bold;

    @container (width <= ${METRIC_NARROW_WIDTH}px) {
        font-weight: normal;
    }
`

export const ChartBlockLoadingError: FC = () => {
    return (
        <>
            <ChartsBlockError style={{ width: '100%', alignSelf: 'center' }} />
            <StyledTitle mt={5} alignSelf="center" textAlign="center">
                We couldn&apos;t load this chart.
            </StyledTitle>
        </>
    )
}

import React from 'react'

import { Button, Flex } from 'v2/ui'

type DocumentViewEditorAdditionalActionsProps = React.ComponentPropsWithoutRef<'div'> & {
    onDuplicatePage?: () => void
}

export const DocumentViewEditorAdditionalActions: React.FC<
    DocumentViewEditorAdditionalActionsProps
> = ({ onDuplicatePage, ...props }) => {
    return (
        <Flex m={0} p={0} justifyContent="space-between" zIndex={1} alignItems="center" {...props}>
            <Button
                label="Duplicate page"
                variant="clear"
                p={0}
                onClick={onDuplicatePage}
                icon="copy"
            />
        </Flex>
    )
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import queryString from 'qs'
import { clearLoginAttemptInfo, consumeCode } from 'supertokens-web-js/recipe/passwordless'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { GlobalStaticState } from 'app/GlobalStaticState'
import { getUrl, Urls } from 'app/UrlService'
import { useGetAccountAndZone } from 'data/hooks/zones'

import { LoadingSplash } from 'v2/ui'

export function LoginLinkCallbackPage() {
    const { user } = useAuthContext()
    // @ts-expect-error
    const { slug } = useParams()
    const { account } = useGetAccountAndZone(slug)
    const submittedCode = useRef(false)
    if (account) {
        GlobalStaticState.setSuperTokensTenant(account._sid)
    }

    const [loginFailed, setLoginFailed] = useState(false)
    const [loginSuccessful, setLoginSuccessful] = useState(false)

    const history = useHistory()
    useEffect(() => {
        async function handleCallback() {
            try {
                submittedCode.current = true
                let response = await consumeCode()

                if (response.status === 'OK') {
                    // we clear the login attempt info that was added when the createCode function
                    // was called since the login was successful.
                    await clearLoginAttemptInfo()
                    setLoginSuccessful(true)
                } else {
                    // this can happen if the magic link has expired or is invalid
                    // or if it was denied due to security reasons in case of automatic account linking

                    // we clear the login attempt info that was added when the createCode function
                    // was called - so that if the user does a page reload, they will now see the
                    // enter email / phone UI again.
                    await clearLoginAttemptInfo()
                    setLoginFailed(true)
                }
            } catch (ex) {
                console.log('Login failed :', ex)
                setLoginFailed(true)
            }
        }

        if (account && !submittedCode.current) {
            handleCallback()
        }
    }, [account, history, user])

    const continueUrl = useCallback((account: Account | undefined): string => {
        const query = queryString.parse(window.location.search, { ignoreQueryPrefix: true })

        if (query.r) return decodeURIComponent(query.r.toString())

        return getUrl(Urls.Root, undefined, account)
    }, [])

    useEffect(() => {
        if (loginSuccessful && user && (!!account || !slug)) {
            history.push(continueUrl(account))
        }
    }, [loginSuccessful, history, slug, user, account, continueUrl])

    if (loginFailed) {
        return <Redirect to={`${getUrl(Urls.Login, undefined, account)}?failed`} />
    }
    return <LoadingSplash />
}

import React, { useMemo } from 'react'

import { TableViewRowContext } from './useTableViewRowContext'

export type TableViewRowContextProviderProps = {
    record?: RecordDto
}

export const TableViewRowContextProvider: React.FC<TableViewRowContextProviderProps> = ({
    children,
    record,
}) => {
    const value = useMemo(
        () => ({
            record,
        }),
        [record]
    )

    return <TableViewRowContext.Provider value={value}>{children}</TableViewRowContext.Provider>
}

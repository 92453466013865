import { AppSchemaType } from 'features/AiAppBuilder/bundles/zodSchema'

export const swapImages = async (app: AppSchemaType) => {
    const imagePrefixes = ['logos/', 'male/', 'female/', 'other/']
    for (const obj of app.objects) {
        if (!obj.records) {
            continue
        }
        for (const record of obj.records) {
            for (const field of record.fields) {
                let value = field.value
                if (
                    typeof value === 'string' &&
                    imagePrefixes.find((x) => (value as string).indexOf(x) == 0)
                ) {
                    // If this is a person, switch it out for the profile image
                    if (value.indexOf('male/') == 0) {
                        value = value.replace(
                            'male/',
                            'https://resources.go.stackerhq.com/fake-images/fake-profiles/profile-m-'
                        )

                        value += '.jpg'
                    } else if (value.indexOf('female/') == 0) {
                        value = value.replace(
                            'female/',
                            'https://resources.go.stackerhq.com/fake-images/fake-profiles/profile-f-'
                        )

                        value += '.jpg'
                    } else if (value.indexOf('logos/') == 0) {
                        value = value.replace(
                            'logos/',
                            'https://resources.go.stackerhq.com/fake-images/fake-logos/logo'
                        )

                        value += '.png'
                    } else if (value.indexOf('other/') == 0) {
                        value = value.replace(
                            'other/',
                            'https://resources.go.stackerhq.com/fake-images/other/other-'
                        )

                        value += '.png'
                    }

                    field.value = value
                }
            }
        }
    }

    return app
}

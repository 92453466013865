// @ts-strict-ignore
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

import {
    filtersToValue,
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'

import { Box, Collapse, ConditionalWrapper, Flex } from 'v2/ui'

import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Input } from 'ui/components/Input'
import { Body, Headline } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'

import { useTableGrantFormState } from './hooks/useTableGrantFormState'
import { EmailFieldPicker } from './inputs/EmailFieldPicker'
import { RolePicker } from './inputs/RolePicker'
import { TablePicker } from './inputs/TablePicker'
import { CreateRoleModal } from './CreateRoleModal'

export const TableGrantForm = ({
    onClose,
    tableGrant,
}: {
    onClose: () => void
    tableGrant?: AccessGrant | null
}) => {
    const [isLoading, setIsLoading] = useState(false)

    const {
        roles,
        objects,
        selectedObject: object,
        formContext,
        validateUniqueGroupName,
        handleCreateOrUpdate,
        isEdit,
        isRoleModalOpen,
        setIsRoleModalOpen,
        onRoleCreated,
        onAddRole,
    } = useTableGrantFormState(tableGrant ?? null)

    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors, isDirty },
    } = formContext

    useEffect(() => {
        if (!isEdit) {
            setValue('email_field_api_name', undefined)
        }
    }, [object?._sid, setValue, isEdit])

    return (
        <Flex alignItems="flex-start" mt={2}>
            <Flex width="20%">
                <Button
                    size="m"
                    startIcon={{ name: 'ArrowLeft' }}
                    variant="ghost"
                    onClick={onClose}
                >
                    Back
                </Button>
            </Flex>
            <Flex column flexGrow={1} alignItems="left">
                <Headline size="s">{isEdit ? 'Table group settings' : 'New table group'}</Headline>
                <Box h={4} />
                <Input
                    label="Name"
                    size="m"
                    type="text"
                    isError={!!errors?.group_name}
                    helperText={errors?.group_name?.message}
                    {...register('group_name', {
                        required: 'Group name is required.',
                        validate: validateUniqueGroupName,
                    })}
                />
                <Box h={4} />
                <Controller
                    control={control}
                    name="role_sid"
                    rules={{ required: 'Role is required' }}
                    render={({ field, fieldState: { error } }) => (
                        <RolePicker
                            roles={roles}
                            error={error}
                            modal={false}
                            onAddRole={onAddRole}
                            {...field}
                        />
                    )}
                />
                <Container variant="neutralMuted" p="xl" my="xl">
                    <Controller
                        control={control}
                        name="object_sid"
                        rules={{ required: 'Table is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TablePicker
                                objects={objects}
                                error={error}
                                readOnly={isEdit}
                                modal={false}
                                {...field}
                            />
                        )}
                    />
                    <Collapse isOpen={!!object}>
                        {object && (
                            <>
                                <Box h={4} />
                                <Controller
                                    control={control}
                                    name="email_field_api_name"
                                    rules={{ required: 'Email field is required' }}
                                    render={({ field, fieldState: { error } }) => (
                                        <EmailFieldPicker
                                            objectId={object._sid}
                                            objects={objects}
                                            error={error}
                                            readOnly={isEdit}
                                            modal={false}
                                            {...field}
                                        />
                                    )}
                                />
                                <Box h={4} />
                                <Body weight="medium">Only include matching users</Body>
                                <Box mt={2}>
                                    <Controller
                                        control={control}
                                        name="filters"
                                        render={({ field }) => {
                                            const { value, onChange, ...restField } = field
                                            return (
                                                <Filters
                                                    {...restField}
                                                    object={object}
                                                    fields={object?.fields}
                                                    value={filtersToValue(value, object)}
                                                    onChange={(filters) =>
                                                        onChange(formatFilters(filters))
                                                    }
                                                    color="white"
                                                    customButtonRender={(props) => (
                                                        <Button
                                                            {...props}
                                                            type="button"
                                                            variant="link"
                                                            startIcon={{ name: 'Plus' }}
                                                        >
                                                            Add filter
                                                        </Button>
                                                    )}
                                                    hideTheRecordFilter
                                                />
                                            )
                                        }}
                                    />
                                </Box>
                            </>
                        )}
                    </Collapse>
                </Container>
                <Flex flexGrow={1} justifyContent="space-between">
                    <ConditionalWrapper
                        condition={!object}
                        wrapper={(children) => (
                            <Tooltip
                                content="Select a table to create new table group"
                                side="bottom"
                                zIndex={1000}
                            >
                                {children}
                            </Tooltip>
                        )}
                    >
                        <Button
                            size="m"
                            onClick={() =>
                                handleSubmit((data) => {
                                    setIsLoading(true)
                                    handleCreateOrUpdate(data, isEdit, onClose)
                                    setIsLoading(false)
                                })()
                            }
                            isLoading={isLoading}
                            disabled={!object || !isDirty}
                        >
                            {isEdit ? 'Save changes' : 'Create table group'}
                        </Button>
                    </ConditionalWrapper>
                    <CreateRoleModal
                        isOpen={isRoleModalOpen}
                        setIsOpen={setIsRoleModalOpen}
                        onRoleCreated={onRoleCreated}
                    />
                </Flex>
            </Flex>
            <Box width="20%" />
        </Flex>
    )
}

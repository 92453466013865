import moment, { Moment } from 'moment-timezone'

import { TaskPayload } from 'data/hooks/tasks/types'

export function parseTaskDueDate(task: TaskPayload): Moment | undefined {
    if (!task.due_at) {
        return undefined
    }

    try {
        const date = moment.utc(task.due_at)

        return date
    } catch {
        return undefined
    }
}

export function isDueDateOverdue(dueDate: Moment): boolean {
    return dueDate.local().isBefore(moment(), 'day')
}

export function isDueDateToday(dueDate: Moment): boolean {
    return dueDate.local().isSame(moment(), 'day')
}

export function isDueDateSoon(dueDate: Moment): boolean {
    const localDueDate = dueDate.local()
    const in3Days = moment().add(3, 'days')

    return localDueDate.isAfter(moment(), 'day') && localDueDate.isBefore(in3Days, 'day')
}

export function isDueDateFuture(dueDate: Moment): boolean {
    const localDueDate = dueDate.local()
    const in3Days = moment().add(3, 'days')

    return localDueDate.isAfter(in3Days, 'day')
}

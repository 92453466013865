import { JSONContent } from '@tiptap/react'

import { Attachment } from 'features/attachments/types'

export type TaskDescription =
    | string
    | {
          format: 'tiptap'
          plainTextContent?: string
          content?: JSONContent
      }

export type DereferencedObject = {
    name: string
    url: string
}

export type AssigneesDisplayMode = 'always' | 'never' | 'whenNotMe'

export type SourceDisplayMode = 'always' | 'never' | 'whenNotMe'

export type CreateTaskPayload = {
    userId: string
    title: string
    assignees: string[]
    description?: TaskDescription
    attachments?: Attachment[]
    due_at?: string
    related_to?: string | null
    related_to_type?: TaskRelatedTo | null
    related_to_location?: string | null
    related_to_stack?: string | null
    parent_task_id?: string | null
    _object_id?: string
}

export enum TaskSource {
    User = 1,
    Workflow = 2,
}

export enum TaskRelatedTo {
    Record = 1,
    Document = 2,
}

export type TaskPayload = {
    auto_id: number
    title: string
    description: TaskDescription
    created_at: string
    completed_at: string | null
    is_completed: boolean
    due_at: string | null
    assignees: string[]
    attachments: Attachment[] | null
    mentions: string[]
    source: string
    source_type: TaskSource
    related_to: string | null
    related_to_type: TaskRelatedTo | null
    related_to_location: string | null
    related_to_stack: string | null
    parent_task_id: string | null
    display_order: number
    _last_comment_at: string | null
    _comment_count: number
}

export type GetTasksPayload = {
    tasks: TaskPayload[]
    records?: RecordDto[]
    users?: UserDto[]
    objects?: { [key: string]: { [key: string]: DereferencedObject } }
}

export type GetTasksParams = {
    related_to?: string
    assignees?: string[]
    created_by?: string
}

export type UpdateTaskPayload = {
    taskId: number
    title?: string
    description?: TaskDescription
    attachments?: Attachment[]
    due_at?: string | null
    parent_task_id?: string | null
    display_order?: number
    is_completed?: boolean
    assignees?: string[]
    _object_id?: string
}

export type DeleteTaskPayload = {
    taskId: number
    is_completed?: boolean
    related_to?: string
    _object_id?: string
}

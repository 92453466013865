import React, { useMemo, useRef } from 'react'

import { useStacks } from 'data/hooks/stacks'

import { ItemProvider } from 'ui/components/Combobox/useComboboxProviders'

import { RenderDocumentSearchResult, RenderRecordSearchResult } from './GlobalSearchProvider'
import { globalSearch } from './search'
import {
    SearchAuxiliaryData,
    SearchResult,
    SearchResultDocument,
    SearchResultRecord,
} from './types'

export type useGlobalSearchProviderProps = {
    blockLinkClick?: boolean
    allowedItemTypes?: string[]
}

export function useGlobalSearchProvider({
    blockLinkClick,
    allowedItemTypes,
}: useGlobalSearchProviderProps = {}): ItemProvider<SearchResult> {
    const { data: stacks } = useStacks()

    const allowedItemTypesRef = useRef(allowedItemTypes)
    allowedItemTypesRef.current = allowedItemTypes

    return useMemo(
        () => ({
            id: 'search',
            title: 'Content',
            getItems({ query }) {
                if (!query)
                    return Promise.resolve({
                        items: [] as SearchResult[],
                        data: {} as SearchAuxiliaryData,
                    })

                const allowedItemTypes = allowedItemTypesRef.current

                return globalSearch(query, stacks || [], allowedItemTypes)
            },
            renderItem({ item, data }) {
                const type = item._type

                switch (type) {
                    case 'record':
                        return (
                            <RenderRecordSearchResult
                                item={item as SearchResultRecord}
                                data={data as SearchAuxiliaryData}
                                blockLinkClick={blockLinkClick}
                            />
                        )

                    case 'document':
                        return (
                            <RenderDocumentSearchResult
                                item={item as SearchResultDocument}
                                blockLinkClick={blockLinkClick}
                            />
                        )

                    default:
                        return null
                }
            },
            getItemProps({ isLast }) {
                return { borderBottomWidth: !isLast ? 1 : 0 }
            },
        }),
        [blockLinkClick, stacks]
    )
}

export const expiredSessionModalSubscribers = []
/**
 * call this to open the modal from anywhere. No context or hooks needed
 * @param {{page: AppSettingsPage}} defaultState
 */

// defaultState is kept to have correct TS type definition. To be removed after TS conversion
// eslint-disable-next-line unused-imports/no-unused-vars
export const closeExpiredSessionModal = (defaultState = false) => {
    expiredSessionModalSubscribers.map((fn) => fn(false))
}

// @ts-strict-ignore
import * as React from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { Collapse, Icon } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Divider as NewDivider } from 'ui/components/Divider'
import { Body, Headline } from 'ui/components/Text'
import { DiscardButton } from 'ui/deprecated/forms/DiscardButton'
import SubmitButton from 'ui/deprecated/forms/SubmitButton'
import { SyncRefWithForm } from 'ui/deprecated/forms/utils'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const Header = ({ children, ...props }) => (
    <Headline size="s" {...props}>
        {children}
    </Headline>
)
const Subtitle = ({ children, ...props }) => (
    <Body size="s" color="textWeaker" mt="2xs" {...props}>
        {children}
    </Body>
)

function Spacer({ style = {} }) {
    return <div style={{ flexBasis: 15, flexShrink: 0, ...style }} />
}

export const FormHeader = ({ title, subtitle, rightSideContent }) => (
    <Flex style={{ alignItems: 'flex-start' }}>
        <Flex column style={{ flexGrow: 1 }}>
            <Header>{title}</Header>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Flex>
        {rightSideContent}
    </Flex>
)

export const FormFrame = ({
    formRef,
    title,
    subtitle,
    children,
    titleBarRightContent,
    bottomBorder = false,
    padding,
    paddingFooter,
}: {
    formRef: any
    title: string
    subtitle?: string
    children?: React.ReactNode
    titleBarRightContent?: React.ReactNode
    bottomBorder?: boolean
    padding?: number
    paddingFooter?: number
}) => {
    if (!formRef) {
        throw new Error('missing formRef')
    }

    return (
        <>
            <SyncRefWithForm formRef={formRef} />
            <Flex column style={{ width: '100%', height: '100%' }}>
                <div style={{ flex: 1, overflowY: 'auto', padding: padding ?? 20 }}>
                    <FormHeader
                        title={title ?? null}
                        subtitle={subtitle}
                        rightSideContent={titleBarRightContent}
                    />
                    {bottomBorder ? <NewDivider variant="weak" my="l" /> : <Box height="l" />}
                    {children}
                </div>
                <FormFooter padding={paddingFooter} />
            </Flex>
        </>
    )
}

export function Frame({ title, subtitle, children, titleBarRightContent, noPadding, ...props }) {
    return (
        <Flex
            column
            style={{
                width: '100%',
                height: '100%',
                padding: noPadding ? 0 : 20,
                overflowY: 'auto',
                ...props,
            }}
        >
            {title && (
                <>
                    <Flex style={{ alignItems: 'center' }}>
                        <Flex column style={{ flexGrow: 1 }}>
                            <Header>{title}</Header>
                            {subtitle && <Subtitle>{subtitle}</Subtitle>}
                        </Flex>
                        {titleBarRightContent}
                    </Flex>
                    <div style={{ height: 11, minHeight: 11 }} />
                </>
            )}
            {children}
        </Flex>
    )
}

export function FormFooter({ padding = 20, hasTopBorder = true }) {
    return (
        <>
            {hasTopBorder && <NewDivider variant="weak" />}
            <Box flex style={{ padding: padding }}>
                <SubmitButton v4 requireChanges>
                    Save
                </SubmitButton>
                <Spacer />
                <DiscardButton v4 hideByDefault>
                    Discard
                </DiscardButton>
            </Box>
        </>
    )
}

export function Divider({ dense = false, noMargin = false, style = {} }) {
    return (
        <div
            style={{
                margin: noMargin ? undefined : dense ? '6px 0px' : '12px 0px',
                height: 1,
                background: V4DesignSystem.colors.gray[100],
                alignSelf: 'stretch',
                flexShrink: 0,
                ...style,
            }}
        />
    )
}

export function CollapsableSection({ header, children, defaultIsOpen, Toggle, collapseProps }) {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: defaultIsOpen })

    const ToggleComponent =
        // can be custom
        Toggle ??
        // but defaults to a simple grey up/down caret icon
        (({ isOpen }) => {
            return (
                <Icon icon={isOpen ? 'caretUp' : 'caretDown'} size="sm" color="gray.300" mr={3} />
            )
        })

    return (
        <>
            <Flex
                style={{ alignItems: 'center', wrap: 'nowrap', cursor: 'pointer' }}
                role="button"
                onClick={onToggle}
            >
                <div style={{ flexGrow: 1 }}>{header}</div>

                <ToggleComponent isOpen={isOpen} />
            </Flex>
            <Collapse {...collapseProps} isOpen={isOpen}>
                {children}
            </Collapse>
        </>
    )
}

const Flex = ({ children, ...props }) => {
    return (
        <Box flex {...props}>
            {children}
        </Box>
    )
}

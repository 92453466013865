import { useCallback } from 'react'

import { useObjects } from 'data/hooks/objects'
import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { WorkflowSchemaActionStateItemAvailability } from 'data/hooks/workflowTypes'
import { LIST_TYPES } from 'data/utils/fieldDefinitions'
import { filterRecordDataFields } from 'features/workflows/common'
import { fieldToWorkflowExtraOptions } from 'features/workflows/fieldToWorkflowExtraOptions'

export function useRecordChangeActionFinalSchema() {
    const { data: objects } = useObjects()
    const { data: schema } = useWorkflowSchema()
    return useCallback(
        (config: WorkflowActionConfig): WorkflowSchemaNodeType | undefined => {
            const type = schema?.nodes.find(
                (t) => t.id === config.action_type
            ) as WorkflowSchemaActionType
            const object = objects?.find((o) => o._sid === config.settings?.object_id)

            const isCreate = config.action_type === 'create_record'

            if (!type || !object) {
                return undefined
            }

            return {
                ...type,
                inputs: type.inputs.map((o) => {
                    if (['record_data'].includes(o.id)) {
                        const group = o as WorkflowSchemaNodeInputGroup
                        return {
                            ...group,
                            items: group.items.concat(
                                filterRecordDataFields(object?.fields, {
                                    excludeReadOnly: true,
                                }).map((f) => ({
                                    id: f.api_name,
                                    name: f.label,
                                    type: f.type,
                                    extra_options: {
                                        ...fieldToWorkflowExtraOptions(f),
                                        allowed_input_modes:
                                            LIST_TYPES.includes(f.type) && !isCreate
                                                ? 'multi_value_modes'
                                                : undefined,
                                    },
                                })) as (WorkflowSchemaNodeInput | WorkflowSchemaNodeInputGroup)[]
                            ),
                        }
                    }
                    return o
                }),
                state: [
                    {
                        id: 'record_id',
                        name: isCreate
                            ? `New record in ${object.name}`
                            : `Updated record in ${object.name}`,
                        type: 'lookup',
                        extra_options: { link_target_object_id: object._sid },
                    },
                    ...type.state.map((o) => {
                        if (['record_data'].includes(o.id)) {
                            const group = o as WorkflowSchemaStateItemGroup
                            return {
                                ...group,
                                items: group.items.concat(
                                    filterRecordDataFields(object?.fields).map((f) => ({
                                        id: f.api_name,
                                        name: f.label,
                                        type: f.type,
                                        extra_options: fieldToWorkflowExtraOptions(f),
                                        availability:
                                            WorkflowSchemaActionStateItemAvailability.AfterExecution,
                                    })) as WorkflowSchemaActionStateItem[]
                                ),
                            }
                        }
                        return o
                    }),
                ],
            }
        },
        [objects, schema?.nodes]
    )
}

import { AnyExtension } from '@tiptap/core'
import { CodeBlock } from '@tiptap/extension-code-block'

const ALLOWED_MARKS = new Set(['activity'])

export function createCodeBlockExtension() {
    return CodeBlock.extend({
        marks() {
            const extensions = this.editor?.options.extensions as AnyExtension[]
            if (!extensions) return undefined

            const marks = extensions.reduce((acc, e) => {
                if (e.type === 'mark' && ALLOWED_MARKS.has(e.name)) {
                    acc.push(e.name)
                }

                return acc
            }, [] as string[])

            if (marks.length < 1) {
                return undefined
            }

            return marks.join(' ')
        },
    }).configure({
        HTMLAttributes: { class: 'code-block' },
    })
}

import { Component } from 'react'
import { withRouter } from 'react-router-dom'

import analytics from 'utils/analytics'

export class _ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location?.pathname !== prevProps.location?.pathname) {
            window.scrollTo(0, 0)
        }
    }

    UNSAFE_componentWillMount() {
        this.unlistenHistory = this.props.history.listen(() => {
            analytics.page()
        })
    }

    componentWillUnmount() {
        this.unlistenHistory()
    }

    render() {
        return this.props.children
    }
}

export const ScrollToTop = withRouter(_ScrollToTop)

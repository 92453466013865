import React, { VFC } from 'react'

import { DataConnectionsManager } from 'features/DataConnections/DataConnectionsManager/DataConnectionsManager'
import { SlidingPaneFrame } from 'features/workspace/AdminSideTray/common/SlidingPaneFrame'

type DataConnectionsSidePaneProps = {}
export const DataConnectionsSidePane: VFC<DataConnectionsSidePaneProps> = () => (
    <SlidingPaneFrame
        title="Data sources"
        description="Connect and manage all your data sources from one central location"
    >
        <DataConnectionsManager />
    </SlidingPaneFrame>
)

import React from 'react'

import { Avatar } from 'v2/ui'

import { Box } from 'ui/components/Box'

export type UserDisplayProps = React.ComponentPropsWithoutRef<typeof Box> & {
    user: UserDto
    showAvatar?: boolean
    showName?: boolean
    avatarSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    avatarProps?: Partial<React.ComponentPropsWithoutRef<typeof Avatar>>
}
export function UserDisplay({
    user,
    showAvatar = true,
    showName = true,
    avatarSize = 'xs',
    avatarProps,
    ...props
}: UserDisplayProps) {
    const name = user.name || user.email
    return (
        <Box flex center {...props}>
            {showAvatar && (
                <Avatar
                    src={user.avatar}
                    name={name}
                    size={avatarSize}
                    mr={showName ? 2 : undefined}
                    {...avatarProps}
                />
            )}
            {showName && <Box fontWeight="bodyBold">{name}</Box>}
        </Box>
    )
}

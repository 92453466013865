import { useMemo } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/useTableViewContext'

export function useTableViewListHeaderState() {
    const { records, requestFilters, requestIncludedFields } = useTableViewContext()

    return useMemo(
        () => ({
            recordCount: records?.length,
            requestFilters,
            requestIncludedFields,
        }),
        [records?.length, requestFilters, requestIncludedFields]
    )
}

import { useCallback, useMemo, useRef } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'

import { useOverflowX } from 'features/views/ListView/hooks/useOverflowX'
import {
    IsOverflowingLeftStyle,
    IsOverflowingRightStyle,
} from 'features/views/ListView/ListHeader/Filters/QuickFilters/QuickFiltersScrollArea.css'

const SCROLL_SPEED = 10

export function useQuickFiltersScrollAreaState() {
    const { targetRef, scrollAreaRef } = useOverflowX({
        leftClassName: IsOverflowingLeftStyle,
        rightClassName: IsOverflowingRightStyle,
    })

    const scrollAreaLocalRef = useRef<HTMLDivElement>(null)
    const scrollAreaComposedRef = useComposedRefs(scrollAreaRef, scrollAreaLocalRef)

    const currentAnimation = useRef(0)
    const animateScroll = useCallback((direction: 'left' | 'right') => {
        currentAnimation.current = requestAnimationFrame(() => {
            const element = scrollAreaLocalRef.current
            if (!element) return

            const isRight = direction === 'right'

            // Prevent scrolling when already at the edge.
            if (!isRight && element.scrollLeft === 0) return
            if (isRight && element.scrollLeft + element.clientWidth === element.scrollWidth) return

            element.scrollBy(isRight ? SCROLL_SPEED : -SCROLL_SPEED, 0)

            // Continue scrolling.
            animateScroll(direction)
        })
    }, [])
    const stopAnimation = useCallback(() => {
        cancelAnimationFrame(currentAnimation.current)
    }, [])

    const onLeftButtonMouseEnter = useCallback(() => {
        animateScroll('left')
    }, [animateScroll])
    const onLeftButtonMouseLeave = useCallback(() => {
        stopAnimation()
    }, [stopAnimation])

    const onRightButtonMouseEnter = useCallback(() => {
        animateScroll('right')
    }, [animateScroll])
    const onRightButtonMouseLeave = useCallback(() => {
        stopAnimation()
    }, [stopAnimation])

    return useMemo(
        () => ({
            targetRef,
            scrollAreaRef: scrollAreaComposedRef as unknown as React.RefObject<HTMLDivElement>,
            onLeftButtonMouseEnter,
            onLeftButtonMouseLeave,
            onRightButtonMouseEnter,
            onRightButtonMouseLeave,
        }),
        [
            targetRef,
            scrollAreaComposedRef,
            onLeftButtonMouseEnter,
            onLeftButtonMouseLeave,
            onRightButtonMouseEnter,
            onRightButtonMouseLeave,
        ]
    )
}

import { createSelector } from 'reselect'

import {
    DATA_CONNECTION_CREATED,
    DATA_CONNECTION_REMOVED,
    DATA_CONNECTION_UPDATED,
    DATA_CONNECTIONS_FETCHED,
} from 'data/utils/constants'
import { StackerActions } from 'data/utils/stackerActions'
import { StackerAPI } from 'data/utils/utils'

// API
class DataConnectionApi extends StackerAPI {
    path = 'data-connections/'

    bypassPreviewAs = true
}
export const dataConnectionApi = new DataConnectionApi()

class DataConnectionActions extends StackerActions {
    deferStoreUpdates = true
    api = dataConnectionApi

    reducer = 'dataConnections'

    name = 'dataConnections'

    actionTypes = {
        fetched: DATA_CONNECTIONS_FETCHED,
        removed: DATA_CONNECTION_REMOVED,
        created: DATA_CONNECTION_CREATED,
        updated: DATA_CONNECTION_UPDATED,
    }
}

const DCA = new DataConnectionActions()
DCA.actions = { ...DCA.actions, fetchAcceptedDataConnections: DCA.fetchAcceptedDataConnections }
export const dataConnectionActions = DCA.actions

// SELECTORS
export const getDataConnectionEntities = (state) => state.dataConnections.entities
export const getDataConnectionsList = (state) => state.dataConnections.dataConnections

export const getDataConnectionsMemoized = createSelector(
    getDataConnectionsList,
    getDataConnectionEntities,
    (dataConnections, entities) =>
        dataConnections.map((dataConnectionId) => entities[dataConnectionId])
)

import React, { useState } from 'react'

import { GoogleLogin } from '@react-oauth/google'
import * as Sentry from '@sentry/react'

import { useLoginUserWithProviderToken } from 'data/hooks/auth/auth'
import { useThirdPartyCookiesCheck } from 'features/utils/ThirdPartyCookiesCheck'
import analytics from 'utils/analytics'

import { Box, Collapse } from 'v2/ui'

const IGNORED_GOOGLE_ERRORS = ['access_denied', 'popup_closed_by_user']

const GoogleLoginButton = ({ onSuccess, onRejected, isSignUp, invitation }) => {
    const [showCookiesWarning, setShowCookiesWarning] = useState()
    const thirdPartyCookies = useThirdPartyCookiesCheck()
    const loginWithProviderToken = useLoginUserWithProviderToken()
    const handleSuccess = async (response) => {
        try {
            const user = await loginWithProviderToken({
                provider: 'google',
                jwt_token: response.credential,
                invitation_token: invitation?.token,
            })

            onSuccess(user)
        } catch (error) {
            onRejected(error)
        }
    }

    const handleFailure = (result) => {
        analytics.track('user registration failed', {
            event_category: 'user',
            event_description: 'New user registration failed',
            error: result.error,
            authentication_method: 'google',
        })

        if (result && IGNORED_GOOGLE_ERRORS.includes(result.error)) {
            // do nothing. These errors indicate the user aborted the google auth process.
            // If the user doesn't have 3rd party cookies enabled, then we can make a reasonable
            // guess that this prevented them from signing in. So we'll show a message about that now
            if (thirdPartyCookies.isUnsupported && !thirdPartyCookies.isLoading) {
                setShowCookiesWarning(true)
            }
        } else {
            // Not sure what happened. This is the catch all, so we just want to log it.
            Sentry.withScope((scope) => {
                scope.setExtra('response', result)
                scope.setLevel('error')
                Sentry.captureMessage('Sign In with Google failed')
            })
        }
    }

    return (
        <>
            <GoogleLogin
                onSuccess={handleSuccess}
                onFailure={handleFailure}
                text={isSignUp ? 'signup_with' : 'signin_with'}
                width={400}
                logo_alignment="left"
                containerProps={{
                    'data-testid': 'google-login-button',
                    style: {
                        margin: '0 auto',
                        width: '100%',
                    },
                }}
            />
            <Collapse isOpen={!!showCookiesWarning}>
                <Box mt={2} bg="grey.100" radius="sm" p={2}>
                    Your browser is blocking <strong>third-party cookies</strong>, please change
                    your browser settings or {isSignUp ? 'sign up' : 'login'} with email and
                    password instead.
                </Box>
            </Collapse>
        </>
    )
}

export default GoogleLoginButton

import React from 'react'

import { Editor } from '@tiptap/core'
import { BubbleMenu, isTextSelection } from '@tiptap/react'

import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { IconName } from 'ui/components/Icon/Icon'
import { theme } from 'ui/styling/Theme.css'

import { getActivityMark } from './Extensions/Activities/utils'
import { BlockType, BlockTypes } from './BlockTypes'

import { ToolbarButtonStyle } from './toolbarStyles.css'

type ToolbarProps = {
    editor: Editor

    allowedBlockTypes?: BlockType[]
    allowComments?: boolean
}
export function BubbleToolbar({ editor, allowedBlockTypes, allowComments }: ToolbarProps) {
    const selection = editor.state.selection
    const hasTextSelected = isTextSelection(selection)

    const activityMark = getActivityMark(selection.$from.pos, selection.$to.pos, editor.state.doc)
    const canAddActivityMark = !activityMark || activityMark.attrs.hidden

    const canComment = allowComments && hasTextSelected && canAddActivityMark

    return (
        <BubbleMenu editor={editor} tippyOptions={{ interactive: true, maxWidth: '450px' }}>
            <Container popover border>
                <BlockTypeDropdown editor={editor} allowedBlockTypes={allowedBlockTypes} />
                <ToolbarButton
                    icon="Bold"
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    isActive={editor.isActive('bold')}
                    disabled={!editor.can().toggleBold?.()}
                />
                <ToolbarButton
                    icon="Italic"
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    isActive={editor.isActive('italic')}
                    disabled={!editor.can().toggleItalic?.()}
                />
                <ToolbarButton
                    icon="Underline"
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                    isActive={editor.isActive('underline')}
                    disabled={!editor.can().toggleUnderline?.()}
                />
                <ToolbarButton
                    icon="Strikethrough"
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    isActive={editor.isActive('strike')}
                    disabled={!editor.can().toggleStrike?.()}
                />
                <ToolbarButton
                    icon="Subscript"
                    onClick={() => {
                        editor.chain().focus().toggleSubscript().unsetSuperscript().run()
                    }}
                    isActive={editor.isActive('subscript')}
                    disabled={!editor.can().toggleSubscript?.()}
                />
                <ToolbarButton
                    icon="Superscript"
                    onClick={() => {
                        editor.chain().focus().toggleSuperscript().unsetSubscript().run()
                    }}
                    isActive={editor.isActive('superscript')}
                    disabled={!editor.can().toggleSuperscript?.()}
                />
                <ToolbarButton
                    icon="Code"
                    onClick={() => editor.chain().focus().toggleCode().run()}
                    isActive={editor.isActive('code')}
                    disabled={!editor.can().toggleCode?.()}
                />
                <ToolbarButton
                    icon="List"
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    isActive={editor.isActive('bulletList')}
                    disabled={!editor.can().toggleBulletList?.()}
                />
                <ToolbarButton
                    icon="ListOrdered"
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    isActive={editor.isActive('orderedList')}
                    disabled={!editor.can().toggleOrderedList?.()}
                />
                {canComment && (
                    <ToolbarButton
                        icon="MessageSquare"
                        style={{
                            fontWeight: theme.fontWeights.bodyRegular as any,
                            fontSize: theme.fontSizes.bodyS,
                        }}
                        onClick={() => {
                            editor.commands.focus()

                            const result = editor.commands.setActivityComment()
                            if (result) {
                                queueMicrotask(() => {
                                    editor.chain().scrollIntoView().openActivityBubble().run()
                                })
                            }
                        }}
                        isActive={false}
                    >
                        Comment
                    </ToolbarButton>
                )}
                {/* <ToolbarButton
                    icon="image"
                    iconStyle="solid"
                    onClick={() => {
                        const url = window.prompt('URL')

                        if (url) {
                            editor.chain().focus().setImage({ src: url }).run()
                        }
                    }}
                    isActive={editor.isActive('image')}
                /> */}
            </Container>
        </BubbleMenu>
    )
}

type BlockTypeDropdownProps = {
    editor: Editor
    allowedBlockTypes?: BlockType[]
}
function BlockTypeDropdown({ editor, allowedBlockTypes }: BlockTypeDropdownProps) {
    const selected =
        Object.entries(BlockTypes).find(([_key, { check }]) => check(editor)) ||
        Object.entries(BlockTypes)[0]
    const value = selected ? selected[0] : undefined
    const item = selected ? selected[1] : undefined

    const supportedBlocks = Object.entries(BlockTypes).filter(
        ([key, blockType]) =>
            (allowedBlockTypes?.includes(key) ?? true) && (blockType.isAvailable?.(editor) ?? true)
    )

    return (
        // @ts-expect-error
        <Dropdown value={value} onValueChange={(value) => BlockTypes[value].command(editor)}>
            <DropdownButton
                variant="ghost"
                size="s"
                style={{
                    fontWeight: theme.fontWeights.bodyRegular as any,
                    fontSize: theme.fontSizes.bodyS,
                    borderRadius: 0,
                }}
            >
                {item?.label}
            </DropdownButton>
            <DropdownContent usePortal={false}>
                {supportedBlocks.map(([key, item]) => (
                    <DropdownItem
                        key={key}
                        label={item.label}
                        multiSelect
                        checked={value === key}
                        onCheckedChange={() => BlockTypes[key].command(editor)}
                        closeOnSelect={true}
                    />
                ))}
            </DropdownContent>
        </Dropdown>
    )
}

type ToolbarButtonProps = React.PropsWithChildren<{
    icon: IconName
    onClick: () => void
    isActive: boolean
    style?: React.CSSProperties
    disabled?: boolean
}>
export function ToolbarButton({
    isActive,
    icon,
    style: providedStyles,
    ...props
}: ToolbarButtonProps) {
    return (
        <Button
            className={ToolbarButtonStyle}
            data-state={isActive ? 'active' : undefined}
            size="s"
            variant="ghost"
            type="button"
            startIcon={{ name: icon }}
            style={{
                borderRadius: 0,
                ...providedStyles,
            }}
            {...props}
        />
    )
}

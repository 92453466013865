import React, { useState } from 'react'

import { Box } from 'ui/components/Box'

import { OutputSchemaEditorNodeList } from './OutputSchemaEditorNodeList'

type OutputSchemaEditorProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value?: WorkflowOutputSchema
    onChange: (value: WorkflowOutputSchema) => void
}

export const OutputSchemaEditor: React.FC<OutputSchemaEditorProps> = ({
    value,
    onChange,
    ...props
}) => {
    const nodes = value?.schema ?? []

    const [focusedNodeId, setFocusedNodeId] = useState<string | null>(null)

    return (
        <Box {...props}>
            <OutputSchemaEditorNodeList
                nodes={nodes}
                onChange={(newNodes) => onChange({ ...value, schema: newNodes })}
                focusedNodeId={focusedNodeId}
                setFocusedNode={setFocusedNodeId}
            />
        </Box>
    )
}

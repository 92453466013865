import { useContext } from 'react'

import { UserModalContext } from './UserModalContext'

export const useUserModal = () => {
    const context = useContext(UserModalContext)
    if (!context) {
        throw new Error('useUserModal must be used within a UserModalContextProvider')
    }
    return context
}

// @ts-strict-ignore
import React from 'react'

import { useConfirmModal } from 'app/useConfirmModal'

import { Icon, Text } from 'v2/ui'
import { DeleteField } from 'v2/ui/svgs'

const useConfirmDeleteRecords = () => {
    const { show } = useConfirmModal()

    function showConfirm(
        recordCount: number,
        onConfirm: () => Promise<void>,
        onCancel?: () => void
    ) {
        show({
            title: 'Delete data?',
            icon: <Icon svg={<DeleteField />} size="56px" ml="15px" mb={4} />,
            message: (
                <>
                    <Text>
                        Are you sure you want to delete
                        <br />
                        {recordCount > 1 ? `these ${recordCount} records` : 'this record'}?
                    </Text>
                </>
            ),
            onConfirm: (modal) => {
                return onConfirm().then(() => {
                    modal.toggle()
                })
            },
            onCancel: (modal) => {
                onCancel?.()
                modal.toggle()
            },
        })
    }
    return showConfirm
}

export default useConfirmDeleteRecords

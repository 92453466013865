export function formatCurrentCount(pageIndex: number, pageSize: number, totalItemsCount: number) {
    if (totalItemsCount < 1) return ''

    const start = pageIndex * pageSize + 1
    const end = Math.min((pageIndex + 1) * pageSize, totalItemsCount)

    return `${start}-${end}`
}

const MAX_DISPLAYED_PAGES = 4
const MIN_HIDDEN_PAGES = 2

export function computeDisplayedPages(pageCount: number, pageIndex: number): number[][] {
    const pages = Array.from({ length: pageCount }, (_, i) => i)

    // If there are less pages than the maximum displayed pages, display all pages.
    if (pageCount <= MAX_DISPLAYED_PAGES) {
        return [pages]
    }

    const middleIdx = Math.floor(MAX_DISPLAYED_PAGES / 2)
    const lastIdx = pageCount - 1

    // If the current page is within the first `middleIdx` pages, display the first `MAX_DISPLAYED_PAGES - 1` pages, as well as the last page, for a total of `MAX_DISPLAYED_PAGES` pages.
    if (pageIndex <= middleIdx) {
        let end = Math.min(lastIdx, MAX_DISPLAYED_PAGES - 1)
        const isNearBounds = pageIndex === end - 1

        // Add one more page if the current page is near the bounds.
        if (isNearBounds) end += 1

        // No need to hide anything if there's only 1 page to hide.
        if (lastIdx - (end + 1) < MIN_HIDDEN_PAGES) {
            return [pages]
        }

        return [pages.slice(0, end), [lastIdx]]
    }

    if (pageIndex >= lastIdx - middleIdx) {
        let start = pageIndex - middleIdx + 1

        const isNearBounds = pageIndex === start + 1
        if (!isNearBounds) start -= 1

        // Always display the limit of `MAX_DISPLAYED_PAGES` pages, even if the current page is near the bounds.
        start = Math.min(start, pageCount - MAX_DISPLAYED_PAGES + 1)

        // No need to hide anything if there's only 1 page to hide.
        if (start - 1 < MIN_HIDDEN_PAGES) {
            return [pages]
        }

        return [[0], pages.slice(start, pageCount)]
    }

    // Only show the current page, and one page before and after it.
    const start = pageIndex - middleIdx + 1
    const end = start + MAX_DISPLAYED_PAGES - 1

    return [[0], pages.slice(start, end), [lastIdx]]
}

import React, { useEffect, useState } from 'react'

import { Switch } from '@chakra-ui/react'
import { faRouter } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import queryString from 'query-string'

import { Flex, Input, Modal } from 'v2/ui'

import Button from 'ui/deprecated/atoms/Button'
import Form from 'ui/deprecated/forms/Form'
import InputForm from 'ui/deprecated/forms/InputForm'
import SubmitButton from 'ui/deprecated/forms/SubmitButton'
import { Label, Spacer } from 'ui/deprecated/forms/ui'

import { getCustomBackendConfig } from './getCustomBackendConfig'

export default function CustomBackendSetup() {
    if (import.meta.env.VITE_USE_CUSTOM_BACKEND) return <UpdateCustomBackend />
    return null
}

const INIT_IS_ACTIVE = localStorage.getItem('customBackendSettings_isActive') === '1'

export function UpdateCustomBackend({ defaultIsOpen = false }) {
    const [isOpen, setisOpen] = useState(defaultIsOpen)
    const [isActive, setisActive] = useState(INIT_IS_ACTIVE)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function saveActiveState() {
        localStorage.setItem('customBackendSettings_isActive', isActive ? '1' : '0')

        // save local token to reuse it when disabling the feature
        if (isActive && !INIT_IS_ACTIVE) {
            localStorage.setItem(
                'customBackendSettings_local_studio_token',
                localStorage.getItem('studio_token')
            )
        }
    }

    useEffect(() => {
        if (INIT_IS_ACTIVE && !isActive) {
            saveActiveState()
            window.location.href =
                window.location.origin +
                '/auth?token=' +
                localStorage.getItem('customBackendSettings_local_studio_token')
        }
    }, [isActive, saveActiveState])

    return (
        <>
            <style>
                {isActive
                    ? `#AppBar {
                border-right: 5px solid #C9251B !important
            }`
                    : ''}
            </style>
            <div
                style={{
                    width: 42,
                    height: 42,
                    background: isActive ? '#C9251B' : '#4C555D',
                    cursor: 'pointer',
                    borderRadius: 4,
                    lineHeight: '48px',
                    textAlign: 'center',
                }}
                onClick={() => setisOpen(true)}
            >
                <FontAwesomeIcon icon={faRouter} style={{ fontSize: 22, color: 'white' }} />
            </div>
            <Modal
                isOpen={isOpen}
                title="What backend do you want to use?"
                onClose={() => setisOpen(false)}
                size={[350, 350, 700, 700]}
            >
                <Flex column style={{ alignItems: 'stretch' }}>
                    <Flex>
                        <Label>Use another backend?</Label>
                        <div className="flex-1" />
                        <Switch
                            defaultIsChecked={isActive}
                            onChange={(event) => setisActive(event.target.checked)}
                        />
                    </Flex>
                    <Spacer />
                    {isActive && (
                        <>
                            <LoginUrl saveActiveState={saveActiveState} />
                            <Spacer />
                            <Spacer />
                            <Flex>
                                <div style={{ borderBottom: '1px solid #D7DBE1', flex: 1 }} />
                                <div style={{ margin: '0 20px', color: '#454E55' }}>Or</div>
                                <div style={{ borderBottom: '1px solid #D7DBE1', flex: 1 }} />
                            </Flex>
                            <Spacer />
                            <Spacer />
                            <Form
                                onSubmit={(data) => {
                                    saveActiveState()
                                    localStorage.setItem(
                                        'customBackendSettings',
                                        JSON.stringify(data)
                                    )
                                    window.location.reload()
                                }}
                                options={{
                                    defaultValues: {
                                        'x-origin': 'https://v4.stacker.dev',
                                        'x-backend': 'https://stacker.dev',
                                        ...getCustomBackendConfig(),
                                    },
                                }}
                            >
                                <Flex column style={{ alignItems: 'stretch' }}>
                                    <InputForm
                                        name="x-origin"
                                        label="Workspace URL"
                                        placeholder="https://v4.stacker.dev"
                                        required
                                        pattern={/^http/}
                                        errorMessages={{ pattern: 'URL expected' }}
                                    />
                                    <Spacer />
                                    <InputForm
                                        name="x-backend"
                                        label="Backend URL"
                                        placeholder="https://stacker.dev"
                                        required
                                        pattern={/^http/}
                                        errorMessages={{ pattern: 'URL expected' }}
                                    />
                                    <Spacer />
                                    <SubmitButton v4>Save & Reload</SubmitButton>
                                </Flex>
                            </Form>
                        </>
                    )}
                </Flex>
            </Modal>
        </>
    )
}

const HISTORY = JSON.parse(localStorage.getItem('customBackendSettings_history') || '[]')

// set custom backend via url - or stop it
if (import.meta.env.VITE_USE_CUSTOM_BACKEND) {
    const myQueryString = queryString.parse(window.location.search)

    // stop the feature & redirect to auth url
    if (myQueryString['custom-backend-stop'] !== undefined) {
        localStorage.setItem('customBackendSettings_isActive', '0')
        window.location.href =
            window.location.origin +
            '/auth?token=' +
            localStorage.getItem('customBackendSettings_local_studio_token')
    } else if (myQueryString['custom-backend']) {
        localStorage.setItem('customBackendSettings_isActive', '1')
        handleLoginUrl(decodeURIComponent(myQueryString['custom-backend']))
    }
}

function handleLoginUrl(value) {
    const matches = value.match(/(https?:\/\/.*\.stacker(hq)?\.(app|dev|local|com))\/(.*)$/)

    let backend
    if (!matches) {
        return null
    }

    switch (matches[3]) {
        case 'app':
        case 'com':
            backend = 'https://airportal.stacker.app'
            break
        case 'dev':
            backend = 'https://stacker.dev'
            break
        default:
            backend = 'https://local.stackerlabs.com:8443'
    }

    localStorage.setItem(
        'customBackendSettings',
        JSON.stringify({
            'x-backend': backend,
            'x-origin': matches[1],
        })
    )

    // keep the last 3 distinct items
    if (!HISTORY.includes(value))
        localStorage.setItem(
            'customBackendSettings_history',
            JSON.stringify([value, ...HISTORY].slice(0, 3))
        )

    window.location.href = `/${matches[4]}`
}

function LoginUrl({ saveActiveState }) {
    const [value, setValue] = useState('')

    function onChange(value) {
        saveActiveState()
        handleLoginUrl(value)

        return new Promise(() => {})
    }

    return (
        <>
            <Label style={{ marginBottom: 5 }}>Login url</Label>
            <Input
                name="login"
                placeholder="https://stacker.dev/?auth=xxx"
                onChange={(event) => setValue(event.target.value)}
            />
            <Spacer />
            <Button v4 onClick={() => onChange(value)}>
                Switch workspace
            </Button>
            {HISTORY.length > 0 && (
                <>
                    <Spacer />
                    <Spacer />
                    <Flex>
                        <div style={{ borderBottom: '1px solid #D7DBE1', flex: 1 }} />
                        <div style={{ margin: '0 20px', color: '#454E55' }}>History</div>
                        <div style={{ borderBottom: '1px solid #D7DBE1', flex: 1 }} />
                    </Flex>
                    <Spacer />
                    <Spacer />
                    <Flex>
                        {HISTORY.map((url) => (
                            <Button
                                key={url}
                                type="link"
                                v4
                                style={{
                                    maxWidth: 350,
                                    overflow: 'hidden',
                                }}
                                href={url}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    event.preventDefault()
                                    return onChange(url)
                                }}
                            >
                                {url.match(/\/\/([^\/]*)\//)[1] || url}
                            </Button>
                        ))}
                    </Flex>
                </>
            )}
        </>
    )
}

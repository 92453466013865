import React from 'react'

import { Box } from 'ui/components/Box'
import { DropdownSub, DropdownSubContent, DropdownSubTrigger } from 'ui/components/Dropdown'
import { Range } from 'ui/components/Range'
import { stopPropagation } from 'ui/helpers/utilities'

import { useActionMenuEditPercentageState } from './hooks/useActionMenuEditPercentageState'
import { ActionMenuEditComponent } from './types'

type ActionMenuEditPercentageProps = {}

export const ActionMenuEditPercentage: ActionMenuEditComponent<
    number,
    ActionMenuEditPercentageProps
> = React.memo(function ActionMenuEditPercentage(props) {
    const { value, fieldLabel, onEditValue, fieldIcon } = useActionMenuEditPercentageState(props)

    return (
        <DropdownSub>
            <DropdownSubTrigger
                startIcon={fieldIcon?.type === 'lucide' ? { name: fieldIcon.name } : undefined}
                label={fieldLabel}
            />
            <DropdownSubContent onClick={stopPropagation}>
                <Box py="m" px="xl" maxWidth="300px">
                    <Range showCount min={0} max={100} value={value} onValueChange={onEditValue} />
                </Box>
            </DropdownSubContent>
        </DropdownSub>
    )
})

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

import { STEP_COUNT_PRIOR_TO_BUILDER } from './common'
import { OnboardingFrame } from './OnboardingFrame'
import { OnboardingProgress } from './OnboardingProgress'

export function OnboardingMobileBlock({
    installDefaultBundle,
}: {
    installDefaultBundle: () => Promise<void>
}) {
    return (
        <OnboardingFrame
            title="Get started with Stacker"
            footerContents={
                <OnboardingProgress
                    steps={STEP_COUNT_PRIOR_TO_BUILDER + 1}
                    currentStep={STEP_COUNT_PRIOR_TO_BUILDER + 1}
                />
            }
        >
            <Box flex column center justifyContent="center" gap="3xl" grow>
                <Icon name="ScreenShare" size="4xl" opacity={0.5} />
                <Body size="l" textAlign="center">
                    Since you&apos;re on a small screen we will install a sample app for you to
                    explore.
                </Body>
                <Body size="l" textAlign="center">
                    To build custom apps, please log in on a tablet or computer.
                </Body>
                <Button onClick={installDefaultBundle} width="full">
                    Continue
                </Button>
            </Box>
        </OnboardingFrame>
    )
}

import React, { useContext, useMemo } from 'react'

import { PathContext } from 'features/utils/PathContext'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { RecordManagerContextProvider } from 'features/views/RecordManager/RecordManagerContext'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import { DetailViewContext } from './useDetailViewContext'

export type DetailViewContextProviderProps = {}

export const DetailViewContextProvider: React.FC<DetailViewContextProviderProps> = ({
    children,
}) => {
    const { isEditing } = useLayoutEditorContext()
    const { recordId: recordSid } = useContext(PathContext)

    const includeFields = isEditing ? undefined : undefined

    return (
        <RecordManagerContextProvider recordSid={recordSid} includeFields={includeFields}>
            <DetailViewContextProviderInner>{children}</DetailViewContextProviderInner>
        </RecordManagerContextProvider>
    )
}

const DetailViewContextProviderInner: React.FC = React.memo(
    function DetailViewContextProviderInner({ children }) {
        const { view } = useLayoutEditorContext()

        const recordManager = useRecordManagerContext()

        const title = (recordManager.record?._primary as string | undefined) || ''

        const value = useMemo(
            () => ({
                view,
                recordManager,
                title,
            }),
            [recordManager, title, view]
        )

        return <DetailViewContext.Provider value={value}>{children}</DetailViewContext.Provider>
    }
)

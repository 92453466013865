import React from 'react'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { isDraftWorkflow } from './common'
import { WorkflowActionDropdown } from './WorkflowActionDropdown'
import { WorkflowStatusToggle } from './WorkflowStatusToggle'
import { WorkflowTitle } from './WorkflowTitle'

type WorkflowCanvasHeaderProps = React.ComponentPropsWithoutRef<typeof Box> & {
    workflow: WorkflowDto
    tryCloseEditor: () => void
    onDelete: () => void
    onChangeTitle: (title: string) => void
    onChangeStatus: (isEnabled: boolean) => void
    onDuplicate: (workflow: WorkflowDto) => void
    showEnablePrompt?: boolean
    onHideSavePrompt?: () => void
}

export const WorkflowCanvasHeader: React.FC<WorkflowCanvasHeaderProps> = ({
    workflow,
    tryCloseEditor,
    onDelete,
    onChangeTitle,
    onChangeStatus,
    onDuplicate,
    showEnablePrompt,
    onHideSavePrompt,
    ...props
}) => {
    const isDraft = isDraftWorkflow(workflow)

    return (
        <Box flex center justifyContent="space-between" {...props}>
            <Button
                variant="ghost"
                startIcon={{ name: 'ArrowLeft' }}
                onClick={tryCloseEditor}
                size="s"
            >
                Workflows
            </Button>
            <WorkflowTitle title={workflow.name} onChange={onChangeTitle} autoFocus={isDraft} />
            <Box flex center>
                <Button
                    variant="ghost"
                    startIcon={{ name: 'ArrowLeft' }}
                    pointerEvents="none"
                    opacity={0}
                    size="s"
                >
                    Workflows
                </Button>
                <Box flex center justifyContent="flex-end" width="400px" gap="l">
                    {isDraft && <Box color="textWeakest">Draft</Box>}
                    {!isDraft && (
                        <Tooltip
                            open={showEnablePrompt}
                            label="Make sure to enable your workflow to start using it."
                            placement="bottom"
                            onRequestClose={onHideSavePrompt}
                            trigger="manual"
                        >
                            <WorkflowStatusToggle
                                onChange={onChangeStatus}
                                value={workflow.is_enabled}
                            />
                        </Tooltip>
                    )}
                    <WorkflowActionDropdown
                        workflow={workflow}
                        onDelete={onDelete}
                        onDuplicate={onDuplicate}
                    />
                </Box>
            </Box>
        </Box>
    )
}

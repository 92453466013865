import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { useField } from 'data/hooks/fields'
import { useObject } from 'data/hooks/objects'
import { useGetFieldRecords } from 'data/hooks/records'
import { WithObject } from 'data/wrappers/WithObjectComponent'
import { filtersToValue } from 'features/records/components/RecordFilters'
import { getIsRelationshipField } from 'utils/fieldUtils'

import { Editable } from 'v2/ui'
import { modes } from 'v2/ui/utils/attributeSettings'

import { resolveContextRecordFilters } from './utils/resolveContextRecordFilters'
import { AttributeValueWrapper } from './AttributeValueWrapper'
import { DisplayText } from './DisplayText'
import { RecordDropdownWithCreate } from './RecordDropdown'
import { RecordLinkDisplay } from './RecordLinkDisplay'

const SingleRecordLinkAttribute = memo(
    ({
        mode,
        onChange,
        renderOptions = {},
        controlStyle = {},
        field,
        isInlineCreate,
        inDataGrid,
        dereferencedRecords: suppliedDereferencedRecords,
        bypassPreviewAs,
        onRecordsSelected,
        placeholder,
        displayType,
        additionalRecords,
        hasFocus,
        contextRecord,
        valueContainerStyle,
        style,
        ...props
    }) => {
        const { object: targetObject } = useObject(field.link_target_object_id)
        const dereferencedRecords =
            contextRecord?._dereferenced_records || suppliedDereferencedRecords
        const { records = [], isLoading } = useGetFieldRecords({
            field,
            value: props.children,
            dereferencedRecords,
        })

        const foreignMatchField = useField(
            getIsRelationshipField(field) &&
                field?.connection_options?.relationship_target_lookup_field
        )
        // If this is a synthetic relationship field, we want to filter out any
        // target records where the match field is blank
        const recordFilter = useCallback(
            (record) => !foreignMatchField || record[foreignMatchField.api_name],
            [foreignMatchField]
        )
        const fieldFilters = field.connection_options?.relationship_target_lookup_filters
        // If any lookup filters has been specified on the link field
        // we apply them for the dropdown
        const rawFilters = useMemo(
            () => filtersToValue(fieldFilters || [], targetObject),
            [fieldFilters, targetObject]
        )

        const filters = useMemo(
            () => resolveContextRecordFilters(rawFilters, contextRecord),
            [rawFilters, contextRecord]
        )

        const dropdownRef = useRef()
        if (inDataGrid) {
            // Focus on open for the data grid.
            dropdownRef.current?.select?.inputRef?.focus()
        }
        useEffect(() => {
            if (inDataGrid) {
                // Focus on open for the AG data grid.
                dropdownRef.current?.select?.inputRef?.focus()
            }
        }, [inDataGrid])

        const { disableSearch, showCreateButton } = renderOptions
        const form = (
            <WithObject objectId={field.object_id}>
                {() => {
                    let value = props.children

                    return (
                        <RecordDropdownWithCreate
                            forwardedRef={dropdownRef}
                            className={classNames(
                                inDataGrid ? 'click-outside-ignore ag-custom-component-popup' : '',
                                props.className
                            )}
                            showCreateButton={!!showCreateButton}
                            isInlineCreate={isInlineCreate}
                            objectId={field.link_target_object_id}
                            value={props.children || value}
                            onChange={onChange}
                            autoFocus={mode === modes.editable || hasFocus}
                            isSearchable={!disableSearch}
                            filter={recordFilter}
                            filters={filters}
                            // If this is a synthetic stacker relationshpi field
                            // we want to ensure that the available options are
                            // deduplicated based on the target field value
                            distinctBy={foreignMatchField?.api_name}
                            distinctOrderBy={'_sid'}
                            controlStyle={{
                                background: 'white',
                                ...controlStyle,
                            }}
                            valueContainerProps={valueContainerStyle}
                            bypassPreviewAs={bypassPreviewAs}
                            onRecordsSelected={onRecordsSelected}
                            placeholder={placeholder}
                            additionalRecords={additionalRecords}
                            defaultMenuIsOpen={hasFocus}
                            style={style}
                        />
                    )
                }}
            </WithObject>
        )

        const record = records[0]

        const display = props.children ? (
            <AttributeValueWrapper displayType={displayType}>
                <RecordLinkDisplay
                    record={record}
                    margin="none"
                    recordId={props.children}
                    noLink={props.noLinks}
                    bypassPreviewAs={bypassPreviewAs}
                    layout={props.layout}
                    className={props.className}
                />
            </AttributeValueWrapper>
        ) : (
            <DisplayText className={props.className}>-</DisplayText>
        )

        if (isLoading && mode !== modes.editing) return null

        if (mode === modes.editable) {
            return (
                <Editable
                    input={({ end }) =>
                        React.cloneElement(form, {
                            onBlur: () => {
                                end()
                            },
                        })
                    }
                    display={() => display}
                    onChange={onChange}
                />
            )
        }
        if (mode === modes.editing) {
            return form
        }

        return display
    }
)

SingleRecordLinkAttribute.propTypes = {
    /** handles saving the changed value */
    onChange: PropTypes.func,
}
export default SingleRecordLinkAttribute

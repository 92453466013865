// @ts-strict-ignore
import React, { forwardRef } from 'react'

import { DatePicker } from 'ui/components/DatePicker'

import { Input } from './Input'
import { InputEndActionCalendar } from './InputEndActionCalendar'

type DateInputRef = HTMLInputElement

type DateInputProps = Omit<
    React.ComponentPropsWithoutRef<typeof Input>,
    'type' | 'endAction' | 'value' | 'onChange'
> & {
    value: Date | [Date, Date]
    onChange: (value: Date | [Date, Date]) => void
    minDate?: Date
    maxDate?: Date
}

export const DateInput = forwardRef<DateInputRef, DateInputProps>(
    ({ onChange, placeholder, disabled, readOnly, required, minDate, maxDate, ...props }, ref) => {
        return (
            <DatePicker
                inline={false}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                required={required}
                minDate={minDate}
                maxDate={maxDate}
                // @ts-expect-error : This needs some refactoring to get the types to align
                customInput={<CustomInput ref={ref} {...props} />}
            />
        )
    }
)

type CustomInputRef = HTMLInputElement

type CustomInputProps = Omit<React.ComponentPropsWithoutRef<typeof Input>, 'onClick'> & {
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

const CustomInput = forwardRef<CustomInputRef, CustomInputProps>(({ onClick, ...props }, ref) => {
    return (
        <Input
            ref={ref}
            {...props}
            endAction={(endActionProps) => (
                <InputEndActionCalendar {...endActionProps} onClick={onClick} />
            )}
            type="text"
        />
    )
})

import React, { useCallback } from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Input } from 'ui/components/Input'
import { stopPropagation } from 'ui/helpers/utilities'

import { ClearButtonStyles, FormStyles, InputStyles } from './ListHeaderSearchInput.css'

type ListHeaderSearchInputProps = Omit<
    React.ComponentPropsWithoutRef<typeof Input>,
    'value' | 'onChange'
> & {
    value: string
    onChange: (value: string) => void
    onClear: () => void
}

export const ListHeaderSearchInput: React.FC<ListHeaderSearchInputProps> = ({
    value,
    onChange,
    onClear,
    ...props
}) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value)
        },
        [onChange]
    )

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
                onClear()
            }
        },
        [onClear]
    )

    const hasValue = !!value

    return (
        <Box
            as="form"
            flex
            center
            className={FormStyles.styleFunction({})}
            onSubmit={stopPropagation}
        >
            <Box noShrink>
                <Icon name="Search" size="m" aria-hidden="true" color="iconHover" />
            </Box>
            <Box grow minWidth={0}>
                <Input
                    type="text"
                    variant="borderless"
                    size="m"
                    placeholder="Type a text and press enter"
                    className={InputStyles.styleFunction({})}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    {...props}
                />
            </Box>
            <Button
                type="reset"
                aria-label="Clear search"
                size="s"
                variant="ghost"
                startIcon={{ name: 'X' }}
                className={ClearButtonStyles.styleFunction({ hasValue })}
                disabled={!hasValue}
                noShrink
                onClick={onClear}
            />
        </Box>
    )
}

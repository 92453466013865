import React from 'react'

import { isEqual } from 'lodash'

import { Users } from 'features/views/attributes/Users'

import { Box } from 'ui/components/Box'
import { Pagination } from 'ui/components/Pagination'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { AppUsersListEmptyValue } from './AppUsersListEmptyValue'
import { AppUsersRoleDropdown } from './AppUsersRoleDropdown'
import { AppRole, AppUser } from './types'

import {
    AppUsersListCellStyles,
    AppUsersListHeaderStyles,
    AppUsersListRowStyles,
} from './AppUsers.css'

const MAX_USER_LENGTH = 30
const MAX_EMAIL_LENGTH = 40

type AppUsersUserListProps = {
    isLoading?: boolean
    showLastRowBorder?: boolean
    pageSize: number
    users: AppUser[]
    totalUserCount: number
    pageIndex: number
    onChangePageIndex: (index: number) => void
    roles?: AppRole[]
}

export const AppUsersUserList: React.FC<AppUsersUserListProps> = ({
    isLoading,
    showLastRowBorder = true,
    pageSize,
    users,
    totalUserCount,
    pageIndex,
    onChangePageIndex,
    roles,
}) => {
    const showRoles = !!roles

    return (
        <Box flex flexDirection="column" gap="xs">
            <Box
                display="grid"
                height="full"
                width="full"
                style={{
                    gridTemplateColumns: `repeat(${showRoles ? 3 : 2}, 1fr)`,
                }}
            >
                <Heading showRoles={showRoles} />
                {users.map((row, index) => (
                    <Row
                        key={index}
                        row={row}
                        isLoading={isLoading}
                        showBorder={index === users.length - 1 ? showLastRowBorder : true}
                        roles={roles}
                        showRoles={showRoles}
                    />
                ))}
            </Box>
            {!isLoading && (
                <Pagination
                    grow
                    pageSize={pageSize}
                    totalItemsCount={totalUserCount}
                    pageIndex={pageIndex}
                    onChangePageIndex={onChangePageIndex}
                />
            )}
        </Box>
    )
}

type HeadingProps = {
    showRoles?: boolean
}

const Heading: React.FC<HeadingProps> = ({ showRoles }) => {
    return (
        <Box className={AppUsersListRowStyles.styleFunction()}>
            <Box className={AppUsersListHeaderStyles.styleFunction()}>
                <Body size="m" weight="medium">
                    Name
                </Body>
            </Box>
            <Box className={AppUsersListHeaderStyles.styleFunction()}>
                <Body size="m" weight="medium">
                    E-mail
                </Body>
            </Box>
            {showRoles && (
                <Box
                    className={AppUsersListHeaderStyles.styleFunction()}
                    justifyContent="flex-end"
                    style={{ paddingRight: theme.space.xl }}
                >
                    <Body size="m" weight="medium">
                        Role
                    </Body>
                </Box>
            )}
        </Box>
    )
}

type RowProps = {
    row: AppUser
    isLoading?: boolean
    showBorder?: boolean
    showRoles?: boolean
    roles?: AppRole[]
}

const Row: React.FC<RowProps> = React.memo(function Row({
    row,
    isLoading,
    showBorder,
    showRoles,
    roles,
}) {
    return (
        <Box className={AppUsersListRowStyles.styleFunction({ isLoading })}>
            <Box
                flex
                center
                gap="s"
                className={AppUsersListCellStyles.styleFunction({ borderless: !showBorder })}
            >
                {row.firstName || row.lastName ? (
                    <Users
                        value={[row]}
                        avatarSize="xs"
                        isLoading={isLoading}
                        size="m"
                        maxLength={MAX_USER_LENGTH}
                        showTooltips={false}
                    />
                ) : (
                    <AppUsersListEmptyValue isLoading={isLoading} />
                )}
                {row.isAdmin && !row.isInvitePending && (
                    <Skeleton isLoading={isLoading}>
                        <Tag
                            type="solid"
                            color="Neutral"
                            shade="dark"
                            size="2xs"
                            pointerEvents="none"
                        >
                            Admin
                        </Tag>
                    </Skeleton>
                )}
                {row.isInvitePending && (
                    <Skeleton isLoading={isLoading}>
                        <Tag
                            type="solid"
                            color="Neutral"
                            shade="light"
                            size="2xs"
                            pointerEvents="none"
                        >
                            Invited
                        </Tag>
                    </Skeleton>
                )}
            </Box>
            <Box className={AppUsersListCellStyles.styleFunction({ borderless: !showBorder })}>
                {row.email ? (
                    <Skeleton isLoading={isLoading} isInline>
                        <Body size="m" maxLength={MAX_EMAIL_LENGTH}>
                            {row.email}
                        </Body>
                    </Skeleton>
                ) : (
                    <AppUsersListEmptyValue isLoading={isLoading} />
                )}
            </Box>
            {showRoles && (
                <Box
                    className={AppUsersListCellStyles.styleFunction({ borderless: !showBorder })}
                    justifyContent="flex-end"
                >
                    {roles ? (
                        <Skeleton isLoading={isLoading}>
                            <AppUsersRoleDropdown user={row} roles={roles} />
                        </Skeleton>
                    ) : (
                        <AppUsersListEmptyValue isLoading={isLoading} />
                    )}
                </Box>
            )}
        </Box>
    )
}, isEqual)

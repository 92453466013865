import React from 'react'

import { Box } from 'ui/components/Box'
import { Toggle } from 'ui/components/Toggle'

type WorkflowStatusToggleProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: boolean
    onChange: (status: boolean) => void
}

export const WorkflowStatusToggle: React.FC<WorkflowStatusToggleProps> = ({
    value,
    onChange,
    ...props
}) => {
    return (
        <Box flex alignItems="center" gap="m" fontSize="bodyM" {...props}>
            <Box as="label" htmlFor="workflow-status-toggle" grow cursor="pointer" flex center>
                <span>Status</span>
            </Box>
            <Toggle id="workflow-status-toggle" checked={value} onCheckedChange={onChange} />
        </Box>
    )
}

import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/useBoardViewContext'

export function useBoardViewWrapperState() {
    const { isEmbedded } = useBoardViewContext()

    const style = useMemo(
        () => ({
            height: isEmbedded ? undefined : '100dvh',
        }),
        [isEmbedded]
    )

    return useMemo(
        () => ({
            style,
        }),
        [style]
    )
}

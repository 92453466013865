import React, { forwardRef } from 'react'

import { Body } from 'ui/components/Text'

import * as Parts from './DatePicker.parts'

type DatePickerDayRef = HTMLDivElement

type DatePickerDayProps = {
    dayOfMonth: number
    date?: Date
}

export const DatePickerDay: React.FC<DatePickerDayProps> = forwardRef<
    DatePickerDayRef,
    DatePickerDayProps
>(({ dayOfMonth }, ref) => {
    return (
        <Parts.DayWrapper ref={ref}>
            <Parts.DayInner>
                <Body size="m">{dayOfMonth}</Body>
            </Parts.DayInner>
        </Parts.DayWrapper>
    )
})

import React from 'react'

import { BoxProps } from '@chakra-ui/react'

import { Dropdown } from 'v2/ui'

import { OwnershipFilterFields } from './permissionRuleForm.types'

type UserFieldsDropdownProps = {
    onChange: (ownershipFieldSelected: string) => void
    value?: OwnershipFilterFields
    usePortal?: boolean
    props?: BoxProps
    userFieldDropdownOptions: { label: string; value: string }[]
}
export const UserFieldsDropdown: React.FC<UserFieldsDropdownProps> = ({
    onChange,
    value,
    usePortal,
    userFieldDropdownOptions,
    ...props
}) => {
    return (
        <Dropdown
            fontSize=".825rem"
            options={userFieldDropdownOptions}
            usePortal={usePortal}
            value={value?.userFieldSelected}
            onChange={(userFieldSelected: string) => {
                onChange(userFieldSelected)
            }}
            isClearable={false}
            {...props}
        />
    )
}

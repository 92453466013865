type UseFilterLabelStateOptions = {
    value: string
    maxLength?: number
}

export function useFilterLabelState(options: UseFilterLabelStateOptions) {
    const { value, maxLength } = options

    const isOverflowing = maxLength ? value.length > maxLength : false

    return {
        isOverflowing,
    }
}

import React from 'react'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'

type TasksPageErrorStateProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const TasksPageErrorState: React.FC<TasksPageErrorStateProps> = (props) => {
    return (
        <Box {...props}>
            <Banner
                icon={{
                    name: 'AlertTriangle',
                }}
                title="There was a problem loading tasks. Please try again later."
                type="error"
            />
        </Box>
    )
}

import { Modifier } from 'react-popper'

export const matchWidthModifier: Modifier<'matchWidth', {}> = {
    name: 'matchWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`
    },
    effect: ({ state, instance }) => {
        const referenceElementWidth = state.elements.reference.getBoundingClientRect().width
        state.elements.popper.style.width = `${referenceElementWidth}px`

        const observer = new ResizeObserver(() => {
            const width = state.elements.reference.getBoundingClientRect().width
            state.elements.popper.style.width = `${width}px`

            instance.forceUpdate()
        })
        observer.observe(state.elements.reference as Element)
        return () => {
            observer.disconnect()
        }
    },
}

import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { makeComponent } from 'ui/helpers/recipes'
import { StandardComponentProps } from 'ui/helpers/styles'

import { LinkStyles, LinkVariants } from './Link.css'

type LinkRef = HTMLAnchorElement

type LinkProps = React.ComponentPropsWithoutRef<'a'> &
    StandardComponentProps &
    LinkVariants & {
        to?: React.ComponentPropsWithoutRef<typeof LinkBase>['to']
    }
export const AnchorBase = makeComponent('a', LinkStyles)
export const LinkBase = makeComponent(ReactRouterLink, LinkStyles)

export const Link = React.forwardRef<LinkRef, LinkProps>(({ children, to, ...props }, ref) => {
    const Component = (to ? LinkBase : AnchorBase) as React.ElementType<
        | React.ComponentPropsWithRef<typeof LinkBase>
        | React.ComponentPropsWithRef<typeof AnchorBase>
    >
    return (
        <Component to={to} {...props} ref={ref}>
            {children}
        </Component>
    )
})

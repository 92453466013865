import React from 'react'

import { DetailViewContent } from './DetailViewContent'
import { DetailViewContextProvider } from './DetailViewContext'

type DetailViewProps = {}

export const DetailView: React.FC<DetailViewProps> = () => {
    return (
        <DetailViewContextProvider>
            <DetailViewContent />
        </DetailViewContextProvider>
    )
}

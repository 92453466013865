export const modes = {
    editable: 'editable',
    editing: 'editing',
    readonly: 'readonly',
} as const

export const layouts = {
    inline: 'inline',
    block: 'block',
    highlighted: 'highlighted',
} as const

export const sizes = {
    small: 'sm',
    medium: 'md',
    large: 'lg',
} as const

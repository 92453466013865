import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useNavigationState } from './hooks/useNavigationState'
import { MainNavigation } from './MainNavigation'
import { MobileNavigationTrigger } from './MobileNavigationTrigger'
import * as Parts from './Navigation.parts'
import { WorkspaceNavigation } from './WorkspaceNavigation'

import { MobileNavigationWrapperStyle } from './Navigation.css'

type NavigationProps = {}

export const Navigation: React.FC<NavigationProps> = () => {
    const {
        workspaceNavState,
        mainNavState,
        closeMobileNavigation,
        showMobileControls,
        navWrapperRef,
    } = useNavigationState()

    const showMobileNavigationBar = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    return (
        <>
            <Parts.NavigationWrapper
                workspaceNavState={workspaceNavState}
                mainNavState={mainNavState}
                ref={navWrapperRef}
            >
                <WorkspaceNavigation />
                <MainNavigation />
                {showMobileControls && (
                    <Box position="absolute" top="l" style={{ right: '-32px', top: '14px' }}>
                        <Button
                            size="xs"
                            variant="ghost"
                            startIcon={{ name: 'PanelRightOpen' }}
                            data-state="active"
                            onClick={closeMobileNavigation}
                        />
                    </Box>
                )}
            </Parts.NavigationWrapper>
            {showMobileNavigationBar && (
                <Box className={MobileNavigationWrapperStyle} trim>
                    <MobileNavigationTrigger />
                </Box>
            )}
        </>
    )
}

import React from 'react'

import { Stack, useTheme } from '@chakra-ui/react'
import CreateButton from 'v2/views/List/CreateButton'
import { CustomListRendererColumn } from 'v2/views/List/types'
import { useCreateQueryParams } from 'v2/views/utils/useCreateQueryParams'

import { canCreateRecords } from 'data/utils/getObjectRecordRestrictions'

import { Box, Button, Text, Tooltip } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { SolidDownload } from 'v2/ui/svgs'

import { DataListBlockDataCounter } from './DataListBlockDataCounter'
import { DataListBlockHeaderTitle } from './DataListBlockHeaderTitle'
import DataListBlockSearchBox from './DataListBlockSearchBox'

type DataListBlockHeaderProps = React.ComponentPropsWithoutRef<typeof Box> & {
    object?: ObjectDto
    title?: string
    subtitle?: string
    hideSearch?: boolean
    hideCreateButton?: boolean
    viewOptions?: ListViewOptions
    searchQuery?: string
    handleSearch?: (value: string | undefined, data: RecordDto[]) => void
    searchColumns?: CustomListRendererColumn[]
    dereferencedRecords?: RecordDto[]
    records?: RecordDto[]
    totalResultCount?: number
    allowDownload?: boolean
    onDownloadCsv?: () => Promise<void>

    showDivider?: boolean
    showDataCounter?: boolean
}

export const DataListBlockHeader: React.FC<DataListBlockHeaderProps> = ({
    object,
    title,
    subtitle,
    viewOptions,
    hideSearch,
    hideCreateButton,
    searchQuery,
    handleSearch,
    searchColumns,
    dereferencedRecords,
    records,
    children,
    totalResultCount = 0,
    showDivider,
    showDataCounter,
    allowDownload,
    onDownloadCsv,
    ...props
}) => {
    const { colors } = useTheme()

    const display = viewOptions?.display
    const createQueryParamsConfig = {
        isInbox: display === 'inbox',
        relatedFieldMayCreateNewRecords: !hideCreateButton,
        relatedListType: 'all',
        isRecordList: true,
    }
    const queryParams = useCreateQueryParams(object, createQueryParamsConfig)

    const shouldHideCreateButton =
        hideCreateButton || !object?._permissions?.may_create || !canCreateRecords(object)

    const hideRightColumn = shouldHideCreateButton && hideSearch

    return (
        <Box
            p={5}
            borderBottomWidth={showDivider ? 1 : 0}
            borderBottomStyle="solid"
            borderBottomColor="userInterface.neutral.200"
            {...props}
        >
            <Box
                display="flex"
                maxWidth="100%"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Stack minHeight={8} display="flex" justifyContent="center">
                    <Box display="flex" alignItems="center">
                        {title && (
                            <DataListBlockHeaderTitle title={title} objectId={object?._sid} />
                        )}
                        {showDataCounter && (
                            <DataListBlockDataCounter
                                count={totalResultCount}
                                aria-hidden={true}
                                ml={title ? 2 : 0}
                            />
                        )}
                    </Box>
                    {subtitle && <Text>{subtitle}</Text>}
                </Stack>

                {!hideRightColumn && (
                    <Box display="flex" alignItems="flex-end" flexShrink={0} ml={2} flexWrap="wrap">
                        {allowDownload && (
                            <Button
                                variant="DataWidgetSearchButton"
                                buttonSize="mediumSquare"
                                label="Download data as CSV"
                                onClick={onDownloadCsv}
                                mr={1}
                                hideSpinner
                            >
                                <SolidDownload color={colors.userInterface.neutral[800]} />
                            </Button>
                        )}
                        {!hideSearch && (
                            <DataListBlockSearchBox
                                searchQuery={searchQuery}
                                searchColumns={searchColumns}
                                records={records}
                                dereferencedRecords={dereferencedRecords}
                                handleSearch={handleSearch}
                            />
                        )}
                        {!shouldHideCreateButton && (
                            <Tooltip placement="bottom-end" label="Add new record">
                                <CreateButton
                                    data-testid="DataWidget.create.button"
                                    objectId={object!._sid}
                                    queryParams={queryParams}
                                    className={ONBOARDING_CLASSES.ADD_NEW_RECORD_BUTTON}
                                    buttonSize="mediumSquare"
                                    text=""
                                    size="sm"
                                    aria-label="Add new record"
                                />
                            </Tooltip>
                        )}
                    </Box>
                )}
            </Box>
            {children}
        </Box>
    )
}

import React, { FC } from 'react'

import { getCompatibleFields } from 'features/records/components/RecordFilters/utils/getCompatibleFields'

import { Dropdown } from 'v2/ui'

import { AdminFilterProps } from './types'

export const FilterRecordFieldSelect: FC<AdminFilterProps> = ({
    contextRecordObject,
    editMode = true,
    value,
    field,
    ...props
}) => {
    // if we're not in edit mode, then just display the selected values
    // not the input control
    if (!editMode) {
        return <>{contextRecordObject?.fields.find((x) => x.api_name === value)?.label}</>
    }

    const options = contextRecordObject
        ? getCompatibleFields(contextRecordObject, field).map((x) => ({
              value: x.api_name,
              label: x.label,
          }))
        : []

    return (
        <Dropdown
            options={options}
            value={value}
            multi={false}
            {...props}
            isMulti={false}
            style={{ flex: 1 }}
        />
    )
}

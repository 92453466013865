// @ts-strict-ignore
import React, { useCallback, useState } from 'react'

import classNames from 'classnames'
import { PickerResponse } from 'filestack-js'

import ReactFilestack from 'v2/ui/ReactFilestack'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import {
    ToolbarIconStyle,
    ToolbarPanelButtonWrapperStyle,
    ToolbarPanelInputWrapperStyle,
    ToolbarPanelWrapperStyle,
} from 'ui/components/Textarea/Textarea.css'

import { useTextareaToolbarContext } from './textareaToolbarContext'
import { TextareaToolbarInput } from './TextareaToolbarInput'
import { TextareaToolbarPanel } from './TextareaToolbarPanel'

type TextareaToolbarImagePanelProps = React.ComponentPropsWithoutRef<
    typeof TextareaToolbarPanel
> & {
    enableFileUpload?: boolean
}

export const TextareaToolbarImagePanel: React.FC<TextareaToolbarImagePanelProps> = ({
    editor,
    enableFileUpload,
    ...props
}) => {
    const { setCurrentPanel } = useTextareaToolbarContext()

    const { src } = editor.getAttributes('image')

    const [url, setUrl] = useState(src)
    const isSubmitDisabled = url && !isValidUrl(url)

    const setImage = useCallback(() => {
        if (!url) {
            setCurrentPanel(null)
            return
        }

        const isValid = isValidUrl(url)
        if (!isValid) {
            return
        }

        editor.chain().focus().setImage({ src: url }).run()

        setCurrentPanel(null)
    }, [editor, setCurrentPanel, url])

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === 'Escape') {
                setCurrentPanel(null)
            }
        },
        [setCurrentPanel]
    )

    const handleFilestackUpload = useCallback(
        (data: PickerResponse) => {
            for (const file of data.filesUploaded) {
                editor.chain().focus().setImage({ src: file.url }).run()
            }

            setCurrentPanel(null)
        },
        [editor, setCurrentPanel]
    )

    return (
        <TextareaToolbarPanel
            className={classNames(ToolbarPanelWrapperStyle)}
            editor={editor}
            onKeyDown={handleKeyDown}
            {...props}
        >
            <Icon name="Image" size="xs" className={ToolbarIconStyle} />
            <Box
                as="form"
                onSubmit={(e) => {
                    e.preventDefault()
                    setImage()
                }}
                className={ToolbarPanelInputWrapperStyle}
            >
                <TextareaToolbarInput
                    placeholder="Paste an image URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    autoFocus
                />
            </Box>
            <Box className={ToolbarPanelButtonWrapperStyle}>
                {enableFileUpload && (
                    <Box>
                        <ReactFilestack
                            options={{
                                maxFiles: 1,
                            }}
                            onSuccess={handleFilestackUpload}
                            customRender={({ onPick }) => {
                                return (
                                    <Button
                                        size="2xs"
                                        startIcon={{ name: 'Upload' }}
                                        variant="ghost"
                                        onClick={onPick}
                                    />
                                )
                            }}
                        />
                    </Box>
                )}
                <Button size="2xs" variant="primary" onClick={setImage} disabled={isSubmitDisabled}>
                    Done
                </Button>
            </Box>
        </TextareaToolbarPanel>
    )
}

const urlRegexp = /^(\S+):(\/\/)?\S+$/

function isValidUrl(url: string) {
    return urlRegexp.test(url)
}

import { useMemo } from 'react'

import { NavigationPortal } from 'features/navigation/types'

import { useNavigationContext } from './useNavigationContext'

type UseNavigationPortalsItemStateProps = {
    portal: NavigationPortal
}

export function useNavigationPortalsItemState({ portal }: UseNavigationPortalsItemStateProps) {
    const { workspaceZone, isPortal } = useNavigationContext()

    const isActive = isPortal && workspaceZone?._sid === portal.id

    return useMemo(
        () => ({
            isActive,
        }),
        [isActive]
    )
}

import React from 'react'

import { useLayoutEditorWidgetControlsState } from './hooks/useLayoutEditorWidgetControlsState'
import { LayoutEditorDetailPane } from './LayoutEditorDetailPane'

type LayoutEditorWidgetControlsProps = {}

export const LayoutEditorWidgetControls: React.FC<LayoutEditorWidgetControlsProps> = () => {
    const { Component, selectedWidget, onBackClick, onChangeWidgetAttrs, label } =
        useLayoutEditorWidgetControlsState()

    if (!selectedWidget) return null

    return (
        <LayoutEditorDetailPane title={label} onBackClick={onBackClick}>
            {Component && <Component widget={selectedWidget} onChange={onChangeWidgetAttrs} />}
        </LayoutEditorDetailPane>
    )
}

import React from 'react'

import { Divider } from 'ui/components/Divider'
import * as Parts from 'ui/components/Textarea/Textarea.parts'

type TextareaToolbarSeparatorProps = React.ComponentPropsWithoutRef<
    typeof Parts.ToolbarSeparator
> & {}

export const TextareaToolbarSeparator: React.FC<TextareaToolbarSeparatorProps> = (props) => {
    return (
        <Parts.ToolbarSeparator {...props}>
            <Divider type="vertical" />
        </Parts.ToolbarSeparator>
    )
}

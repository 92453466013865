import React from 'react'

import { Icon } from 'ui/components/Icon'

import { WorkflowItemWrapper } from './WorkflowItemWrapper'

type WorkflowFinishedNodeProps = {}

export const WorkflowFinishedNode: React.FC<WorkflowFinishedNodeProps> = () => {
    return (
        <WorkflowItemWrapper role="none" background="surfaceStrong" flex center>
            <Icon name="Check" mr="m" />
            Finished
        </WorkflowItemWrapper>
    )
}

import { Image } from '@tiptap/extension-image'

export function createImageExtension() {
    return Image.extend({
        inline: false,
        addAttributes() {
            return {
                ...this.parent?.(),
                ['data-is-loading']: { default: false },
                id: { default: null },
            }
        },
        renderText({ node }) {
            return node.attrs.src
        },
    })
}

import React from 'react'

import { ListHeader } from 'features/views/ListView/ListHeader/ListHeader'

import { useTableViewListHeaderState } from './hooks/useTableViewListHeaderState'

type TableViewListHeaderProps = {}

export const TableViewListHeader: React.FC<TableViewListHeaderProps> = React.memo(
    function TableViewListHeader() {
        const { recordCount, requestFilters, requestIncludedFields } = useTableViewListHeaderState()

        return (
            <ListHeader
                recordCount={recordCount}
                requestFilters={requestFilters}
                requestIncludedFields={requestIncludedFields}
                isSticky
            />
        )
    }
)

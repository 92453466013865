import * as Primitives from '@radix-ui/react-scroll-area'

import { makeComponent } from 'ui/helpers/recipes'

import {
    CornerStyles,
    RootStyles,
    ScrollbarStyles,
    ThumbStyles,
    ViewportStyles,
} from './ScrollArea.css'

export const Root = makeComponent(Primitives.Root, RootStyles)
export const Viewport = makeComponent(Primitives.Viewport, ViewportStyles)
export const Scrollbar = makeComponent(Primitives.Scrollbar, ScrollbarStyles)
export const Thumb = makeComponent(Primitives.Thumb, ThumbStyles)
export const Corner = makeComponent(Primitives.Corner, CornerStyles)

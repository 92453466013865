import React from 'react'

import { Modal as ModalComponent } from 'v2/ui'
import { useModalToggle } from 'v2/ui/utils/useModalToggle'

import { DuplicateViewModalData, MODAL_KEY } from './duplicateViewModalConstants'
import { DuplicateViewModalContent } from './DuplicateViewModalContent'

export const DuplicateViewModal: React.FC = () => {
    const modal = useModalToggle<DuplicateViewModalData>(MODAL_KEY)
    const { isOpen, toggle, data } = modal

    const onConfirm = async () => {
        toggle()
    }

    const onCancel = () => {
        toggle()
    }

    return (
        <ModalComponent
            isOpen={isOpen}
            onClose={onCancel}
            body={
                <DuplicateViewModalContent
                    view={data?.view}
                    onConfirm={onConfirm}
                    isOpen={isOpen}
                />
            }
        />
    )
}

// @ts-strict-ignore
import React from 'react'
import { useLocation } from 'react-router-dom'

import styled from '@emotion/styled'
import { DetailView } from 'v2/views/Detail/DetailView/DetailView'

import { FrameContext } from 'features/core/frameContext'
import HeroContainer from 'features/core/HeroContainer'
import { PoweredBy } from 'features/core/PoweredBy'
import { HeaderContextProvider } from 'features/utils/HeaderContextProvider'

import { Flex } from 'v2/ui'
import List from 'v2/ui/components/List/List'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useUrlParam from 'v2/ui/utils/useUrlParam'

type InboxViewProps = {
    listProps: any
    detailViewProps: any
}

const ListContainer = styled('div')`
    margin-right: 24px;
    overflow-y: auto;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 0 0 400px;

    @media (max-width: 1200px) {
        flex: 0 0 200px;
    }
    border-right: 1px solid #dbdddf;
`
const DetailsContainer = styled('div')`
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
`

const DetailsContainerBody = styled('div')`
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
`

export const InboxView: React.FC<InboxViewProps> = (props) => {
    // Only supports one copy of a record list (prolly add index here to support multis)
    const urlParamString =
        'row_id' + (props.listProps.isRecordList ? props.detailViewProps.view.stack_id : '_base')
    const [rowId] = useUrlParam<string | 'UNSET'>(urlParamString, 'UNSET')
    const location = useLocation()
    const frameContext = React.useContext(FrameContext)
    const rowLinkFunction = React.useCallback(
        (row) => {
            const params = new URLSearchParams(location.search)
            let nextSearch = ''
            if (!params.has(urlParamString)) {
                nextSearch += `${urlParamString}=${row.original._sid}`
            }
            params.forEach((val, key) => {
                const delimeter = nextSearch.length === 0 ? '' : '&'
                if (key === urlParamString) {
                    nextSearch += `${delimeter}${urlParamString}=${row.original._sid}`
                } else {
                    nextSearch += `${delimeter}${key}=${val}`
                }
            })

            return {
                search: nextSearch,
                state: location.state,
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.search, urlParamString]
    )
    const isEmpty = !props.listProps.data.length
    const selectedRecord = React.useMemo(
        () => props.listProps.data.find((d) => d._sid === rowId) ?? props.listProps.data[0],
        [props.listProps.data, rowId]
    )
    return (
        <Flex
            wrap={'nowrap'}
            alignItems={'flex-start'}
            style={{
                overflow: 'hidden',
                height: `100dvh`,
                maxWidth: '100%',
            }}
            className={STYLE_CLASSES.INBOX}
        >
            <ListContainer
                style={{ padding: isEmpty ? '0 24px' : 0, zIndex: 1 }}
                className={STYLE_CLASSES.INBOX_LIST}
            >
                <List
                    {...props.listProps}
                    display={'inbox'}
                    rowLink={rowLinkFunction}
                    selectedRow={selectedRecord}
                    isInbox={true}
                />
            </ListContainer>
            <DetailsContainer style={{ zIndex: 0 }} className={STYLE_CLASSES.INBOX_RECORD}>
                <DetailsContainerBody className={STYLE_CLASSES.INBOX_RECORD_INNER}>
                    {props.listProps.isRecordList ? null : (
                        <HeaderContextProvider>
                            <HeroContainer
                                idOverride="inboxHeroContainer"
                                showBreadcrumbs={true}
                                breadCrumbsStyleOverride={{
                                    paddingLeft: 0,
                                }}
                            />
                        </HeaderContextProvider>
                    )}
                    {selectedRecord && (
                        <DetailView
                            // The some of the attribute components are not currently set up to reload when
                            // data changes. So for now the sure way to make sure the UI displays the data from
                            // the current record is to recreate the detail view completely upon selected record changing.
                            // This isn't idea for performance, but until all the attributes are updated to handle this scenario
                            // this is the best solution.
                            key={selectedRecord._sid}
                            recordSid={selectedRecord._sid}
                            {...props.detailViewProps}
                            extraStickyOffset={frameContext.headerHeight}
                            linkToRecord={true}
                            reloadOnMount={true}
                        />
                    )}
                </DetailsContainerBody>
                {props.listProps.isRecordList ? null : (
                    <PoweredBy wrapperStyle={{ flexBasis: 0, flexShrink: 1 }} />
                )}
            </DetailsContainer>
        </Flex>
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'

import { useWorkspaceLogoState } from './hooks/useWorkspaceLogoState'
import { WorkspaceIcon } from './WorkspaceIcon'

import { WorkspaceLogoImageStyles } from './Navigation.css'

type WorkspaceLogoProps = {}

export const WorkspaceLogo: React.FC<WorkspaceLogoProps> = () => {
    const { showIconOnly, workspaceAccount, logoImageUrl, workspaceName } = useWorkspaceLogoState()

    if (showIconOnly) {
        if (!workspaceAccount) return null

        return <WorkspaceIcon workspaceAccount={workspaceAccount} />
    }

    return <WorkspaceLogoImage name={workspaceName} imageUrl={logoImageUrl} />
}

type WorkspaceLogoImageProps = {
    name: string
    imageUrl?: string
}

const WorkspaceLogoImage: React.FC<WorkspaceLogoImageProps> = ({ name, imageUrl }) => {
    return (
        <Box
            className={WorkspaceLogoImageStyles.styleFunction({
                hasImage: !!imageUrl,
            })}
            trim
        >
            {imageUrl ? (
                <Box
                    as="img"
                    height="full"
                    width="full"
                    src={imageUrl}
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                    }}
                />
            ) : (
                <Headline size="s" color="text" trim>
                    {name}
                </Headline>
            )}
        </Box>
    )
}

import { useMemo } from 'react'
import { type Link as ReactRouterLink, matchPath, RouteProps, useLocation } from 'react-router-dom'

import { getWorkspaceUrl, Urls } from 'app/UrlService'

const MAX_COUNT = 999

type UseSystemNavigationItemStateProps = {
    count?: number
    to?: React.ComponentProps<typeof ReactRouterLink>['to']
}

export function useSystemNavigationItemState({ to, count }: UseSystemNavigationItemStateProps) {
    const location = useLocation()

    const isActive = useMemo(() => {
        if (!to) return false

        const isRoot = location.pathname === getWorkspaceUrl(Urls.Root)
        // The homepage can also be accessed with the root path.
        if (matchPath(getWorkspaceUrl(Urls.Home), to as RouteProps) && isRoot) {
            return true
        }

        return !!matchPath(location.pathname, to as RouteProps)
    }, [location.pathname, to])

    const formattedCount = formatCount(count)

    return useMemo(
        () => ({
            isActive,
            formattedCount,
        }),
        [isActive, formattedCount]
    )
}

function formatCount(count?: number) {
    if (!count) return ''

    if (isNaN(count)) return ''
    if (count < 0) return ''
    if (count > MAX_COUNT) return `${MAX_COUNT}+`

    return count.toString()
}

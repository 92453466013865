import React, { useCallback, useMemo, useState } from 'react'

import styled from '@emotion/styled'
import { FieldsEditorContextProvider } from 'v2/blocks/blockTypes/view/FieldContainerEditor/FieldsEditorContextProvider'
import DownloadOption from 'v2/views/List/DownloadOption'
import { getColumnConfig } from 'v2/views/List/getColumnConfig'
import HideSearchBar from 'v2/views/List/HideSearchBar'
import ListViewControls from 'v2/views/List/ListViewControls'

import { useObject, useObjects } from 'data/hooks/objects'
import { sortFieldsByOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { RenderRowAttributeRenderProps } from 'features/pages/blocks/settings/attributes/RenderRowAttribute'
import { supportsItemsPerPage } from 'features/records/utils/supportsItemsPerPage'
import { supportsSearchBar } from 'features/records/utils/supportsSearchBar'
import { ObjectPicker } from 'features/studio/ui/ObjectPicker'
import { getCoverImageFields } from 'utils/fieldUtils'

import { Box, Input, Text, Textarea } from 'v2/ui'
import FieldSelector from 'v2/ui/components/List/FieldSelector'
import stackerTheme from 'v2/ui/theme/styles/default'

import DataListBlockEditorAllowCreate from './DataListBlockEditorAllowCreate'
import DataListBlockEditorPerPage from './DataListBlockEditorPerPage'
import { DATA_LIST_DISPLAY_TYPES, getDefaultPageSize, getPageSizesVariants } from './utils'

const { space } = stackerTheme()

const StyledTextarea = styled(Textarea)`
    min-height: 5rem;
    max-height: 5rem;
`

type DataListBlockEditorProps = RenderRowAttributeRenderProps<
    DataListBlock['config']['attributes']
> & {}

const DataListBlockEditor: React.FC<DataListBlockEditorProps> = ({
    params,
    setAttrs,
    setAttr,
    context,
    setOnlyVisible,
}) => {
    const { object_id, columns, showAllFields, display } = params
    const { object } = useObject(object_id)
    const { refetch: refetchObjects } = useObjects()

    const getDefaultColumns = (newObject: ObjectDto) => {
        const defaultFields = newObject.fields.slice(0, 5)
        return defaultFields.map((field: FieldDto) => ({
            fieldApiName: field.api_name,
            fieldId: field._sid,
            props: {
                allowEdit: true,
                hideBorder: false,
            },
            selected: true,
        }))
    }

    const updateObject = useCallback(
        (newObject?: ObjectDto) => {
            if (newObject) {
                const coverImageField = getCoverImageFields(
                    sortFieldsByOrder(newObject.fields, newObject)
                )?.[0]
                setAttrs({
                    object_id: newObject._sid,
                    columns: getDefaultColumns(newObject),
                    showAllFields: false,
                    title: newObject.name,
                    coverImage: coverImageField ? { id: coverImageField.api_name } : undefined,
                })
            } else {
                setAttrs({
                    object_id: undefined,
                    columns: undefined,
                    title: undefined,
                    coverImage: undefined,
                })
            }
        },
        [setAttrs]
    )

    const onUpdateObject = useCallback(
        async (objectId) => {
            const { data: updatedObjects } = await refetchObjects()
            const newObject = updatedObjects?.find((o) => o._sid === objectId)
            updateObject(newObject)
        },
        [updateObject, refetchObjects]
    )

    // If isShowAllFields, we map all fields to a column and override viewOptions for display options components
    const columnConfig: ListViewColumnConfig[] = useMemo(() => {
        if (!object || !columns) return []
        return getColumnConfig(object, columns, !!showAllFields)
    }, [columns, object, showAllFields])

    const tableSupportsSearchBar = supportsSearchBar(params)

    const onChangeDisplayType = (newType: string) => {
        const selectedBlockID = context.editor.selectedBlock?.id
        if (!selectedBlockID) return

        context.editor.actions.updateBlock(selectedBlockID, {
            type: newType as Block['type'],
        })
    }

    const handleChangeShowAllFieldsMode = (showAllFields: boolean) => {
        setAttr('showAllFields', showAllFields)
    }

    const handleChangeColumns = (columns: ListViewColumnConfig[]) => {
        setAttr('columns', columns)
    }

    const [isEditingField, setIsEditingField] = useState(false)

    const setIsEditingItem = useCallback(
        (isEditing: boolean) => {
            setOnlyVisible(isEditing)
            setIsEditingField(isEditing)
        },
        [setOnlyVisible]
    )

    const fieldSelector = (
        <FieldSelector
            key="field-selector"
            display={display}
            objectId={object_id}
            config={columnConfig}
            onChange={handleChangeColumns}
            editorProps={{
                isLayoutEditorComponent: true,
                setIsEditingItem,
                showAllFields,
                onShowAllFieldsModeChange: handleChangeShowAllFieldsMode,
                useContext: true,
                allowPortals: false,
            }}
            animate={false}
        />
    )

    return (
        <FieldsEditorContextProvider>
            {!isEditingField && (
                <StyledSetObjectWrapper>
                    <Text as="label" htmlFor="select-data-source" variant="paletteSectionLabel">
                        Data source
                    </Text>
                    <StyledObjectPicker
                        inputId="select-data-source"
                        value={object_id}
                        onChange={onUpdateObject}
                        placeholder="Select existing data source"
                    />
                </StyledSetObjectWrapper>
            )}

            {!isEditingField && object && (
                <>
                    <Box mb={4} role="group">
                        <Text
                            variant="paletteSectionLabel"
                            as="label"
                            htmlFor="view-title"
                            display="block"
                        >
                            Title
                        </Text>
                        <Input
                            id="view-title"
                            variant="admin"
                            value={params.title ?? ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setAttrs({
                                    title: e.target.value,
                                })
                            }}
                        />
                    </Box>

                    <Box mb={4} role="group">
                        <Text
                            variant="paletteSectionLabel"
                            as="label"
                            htmlFor="view-subtitle"
                            display="block"
                        >
                            Subtitle
                        </Text>
                        <StyledTextarea
                            id="view-subtitle"
                            variant="admin"
                            value={params.subtitle ?? ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setAttrs({
                                    subtitle: e.target.value,
                                })
                            }}
                            fullWidth={true}
                        />
                    </Box>

                    <ListViewControls
                        object={object}
                        columnConfig={columnConfig}
                        config={params}
                        setConfig={setAttrs}
                        hideHeader
                        shouldShowRoles={false}
                        onDisplayChange={onChangeDisplayType}
                        displayTypes={DATA_LIST_DISPLAY_TYPES}
                        showTitle={false}
                        getDefaultPageSize={getDefaultPageSize}
                        getPageSizeOptions={getPageSizesVariants}
                        additionalAppearanceToggles={
                            supportsItemsPerPage(params) && (
                                <DataListBlockEditorPerPage
                                    getDefaultPageSize={getDefaultPageSize}
                                    getPageSizeOptions={getPageSizesVariants}
                                    onChange={setAttrs}
                                    attrs={params}
                                />
                            )
                        }
                        additionalToggles={
                            <>
                                {tableSupportsSearchBar && (
                                    <HideSearchBar
                                        onChange={(value) => setAttrs({ hide_search_bar: value })}
                                        hideSearchBar={params.hide_search_bar}
                                        mb={2}
                                    />
                                )}
                                <DataListBlockEditorAllowCreate
                                    onChange={setAttrs}
                                    attrs={params}
                                    mb={2}
                                />
                                <DownloadOption
                                    value={params.allowDownload}
                                    onChange={(value: boolean) =>
                                        setAttrs({ allowDownload: value })
                                    }
                                />
                            </>
                        }
                        customFieldsEditor={fieldSelector}
                        animateFieldsEditor={false}
                        animateActionButtonsSelector={false}
                        isBlankPage
                    />
                </>
            )}

            {isEditingField && fieldSelector}
        </FieldsEditorContextProvider>
    )
}

export default DataListBlockEditor

const StyledObjectPicker = styled(ObjectPicker)`
    margin-bottom: ${space[3]};
`
const StyledSetObjectWrapper = styled(Box)`
    margin-bottom: ${space[5]};
`

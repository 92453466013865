import React, { useRef, useState } from 'react'

import { Tooltip } from 'v2/ui'

import { Button } from 'ui/components/Button'

import { TasksQuickCreatePopover } from './TasksQuickCreatePopover'

type TasksQuickCreatePopoverButtonProps = React.ComponentPropsWithoutRef<
    typeof TasksQuickCreatePopover
> & {
    label?: string
    buttonProps?: React.ComponentPropsWithoutRef<typeof Button>
}

export const TasksQuickCreatePopoverButton: React.FC<TasksQuickCreatePopoverButtonProps> = ({
    label = 'Add new task',
    buttonProps,
    ...props
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null)

    const [isOpen, setIsOpen] = useState(false)

    const handleButtonClick = (e: React.MouseEvent) => {
        setIsOpen(!isOpen)

        e.preventDefault()
        e.stopPropagation()
    }

    const onClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Tooltip
                label={label}
                placement="bottom-end"
                disabled={isOpen || buttonProps?.disabled}
            >
                <Button
                    aria-label={label}
                    onClick={handleButtonClick}
                    {...buttonProps}
                    ref={buttonRef}
                />
            </Tooltip>
            <TasksQuickCreatePopover
                open={isOpen}
                targetElement={buttonRef.current ?? undefined}
                onClose={onClose}
                placement="bottom-start"
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                {...props}
            />
        </>
    )
}

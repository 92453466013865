import React, { VFC } from 'react'
import { useFormContext } from 'react-hook-form'

import { capitalize, isEmpty } from 'lodash'

import { DeleteFieldButton } from 'features/admin/settings/object/DeleteFieldButton'

import { Button, Flex, Icon, Text } from 'v2/ui'
import { colors } from 'v2/ui/theme/styles/default'

import { getCanDeleteField, isFieldProtected } from './logic/availableFieldOperationUtils'

type Props = {
    field?: FieldDto | null
    object?: ObjectDto
    onCancel: () => void
    fieldName: string
    onClickDelete: () => unknown
}

export const EditFooter: VFC<Props> = ({ field, object, onCancel, fieldName, onClickDelete }) => {
    const isPrimary = !!field?.is_primary
    const { formState } = useFormContext()
    const { dirtyFields } = formState
    const isEditingField = !!field
    const canDeleteField = getCanDeleteField({ field: field ?? undefined, object })
    const isSubmitDisabled = isEmpty(dirtyFields)

    return (
        <Flex
            style={{ width: '100%', gap: '20px' }}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
        >
            {isFieldProtected(field ?? undefined) && (
                <Text size="sm" color={colors.userInterface.neutral[800]}>
                    <Icon
                        icon="lock"
                        color={colors.userInterface.neutral[800]}
                        display="inline"
                        verticalAlign="middle"
                        mr={2}
                    />
                    {field?.connection_options.is_protected_by_grant
                        ? 'This field is required in user table group'
                        : 'This field cannot be changed'}
                </Text>
            )}
            {field && canDeleteField && (
                <DeleteFieldButton
                    isDisabled={isPrimary}
                    variant="Secondary"
                    icon="trash"
                    buttonSize="medium"
                    handleClick={onClickDelete}
                >
                    Delete
                </DeleteFieldButton>
            )}
            <Flex flexDirection="row" justifyContent="flex-end" alignItems="center" flexGrow={1}>
                <Button
                    variant="Tertiary"
                    color="userInterface.neutral.850"
                    buttonSize="medium"
                    onClick={onCancel}
                    mr={4}
                >
                    Cancel
                </Button>
                <Button
                    variant="Primary"
                    buttonSize="medium"
                    disabled={!formState.isValid || (isEditingField && isSubmitDisabled)}
                    p={2}
                    submit
                    isLoading={formState.isSubmitting}
                    data-testid={isEditingField ? 'save-changes' : 'create-field'}
                >
                    {isEditingField ? 'Save Changes' : `Create ${capitalize(fieldName)}`}
                </Button>
            </Flex>
        </Flex>
    )
}

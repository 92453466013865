import { useCallback, useMemo, useRef } from 'react'

import { useListHeaderContext } from 'features/views/ListView/ListHeader/useListHeaderContext'

import useDebounce from 'v2/ui/utils/useDebounce'

import { truncateText } from 'ui/helpers/utilities'

const MAX_LABEL_LENGTH = 25
const DEBOUNCE_RATE = 200

export function useQuickFilter(field: FieldDto) {
    const { setFilters, inlineFilters, view } = useListHeaderContext()
    const inlineFiltersRef = useRef(inlineFilters)
    inlineFiltersRef.current = inlineFilters

    const currentFilters = useMemo(() => {
        return inlineFilters.filter((filter) => filter.field_sid === field._sid)
    }, [field._sid, inlineFilters])
    const currentFiltersRef = useRef(currentFilters)
    currentFiltersRef.current = currentFilters

    const setFiltersValue = useCallback(
        (filters: Filter[]) => {
            const currentFilters = currentFiltersRef.current
            const currentFiltersMap = currentFilters.reduce(
                (acc, filter) => acc.set(filter._id!, filter),
                new Map<number, Filter>()
            )

            const newFilters = filters.map((filter) => {
                const existing = currentFiltersMap.get(filter._id!)
                if (existing) {
                    return { ...existing, ...filter }
                }

                return filter
            })

            const prevFilters = inlineFiltersRef.current.filter(
                (filter) => filter.field_sid !== field._sid
            )

            setFilters([...prevFilters, ...newFilters])
        },
        [field._sid, setFilters]
    )

    const debouncedSetFiltersValue = useDebounce(setFiltersValue, DEBOUNCE_RATE, [setFiltersValue])

    const isActive = currentFilters.length > 0

    const column = view.options.columns?.find((column) => column.fieldId === field._sid)
    const label = column?.label || field.label
    const truncatedLabel = truncateText(label, MAX_LABEL_LENGTH)

    return useMemo(
        () => ({
            value: currentFilters,
            setFiltersValue: debouncedSetFiltersValue,
            isActive,
            label: truncatedLabel,
        }),
        [currentFilters, debouncedSetFiltersValue, isActive, truncatedLabel]
    )
}

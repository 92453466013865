import { useMemo as useReactMemo, useRef } from 'react'

// Overrides react useMemo with one that logs when the deps + return value change
// and which deps are changing. Useful for debugging unnecessary updates.
export function useMemo(fn, deps, name) {
    const prevDeps = useRef()
    const lastValue = useRef()
    return useReactMemo(() => {
        const changedDeps = {}
        let changedCount = 0
        // If we have deps and this isn't our first render
        // collect a list of which deps have changed
        if (deps && prevDeps.current) {
            for (let i = 0; i < deps.length; i++) {
                const prev = prevDeps.current[i]
                const current = deps[i]
                if (prevDeps.current[i] !== deps[i]) {
                    changedCount++
                    changedDeps[i] = { prev, current }
                }
            }
        }

        prevDeps.current = deps

        const newValue = fn()
        // If we're returning a new value AND we have changed deps
        // logg to the console.
        if (newValue !== lastValue.current && changedCount) {
            console.log({ [name || 'useMemo']: { changedDeps } })
        }

        lastValue.current = newValue
        return newValue
    }, deps)
}

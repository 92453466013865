import { useCallback } from 'react'

import { fetchWithAuth } from 'data/utils/fetchWithAuth'

export function useUpdateCrmContact() {
    return useCallback(async (data: Record<string, string>) => {
        const response = await fetchWithAuth('crm/update-contact/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (response.ok) {
            return await response.json()
        }
    }, [])
}

import React from 'react'

import { isEqual } from 'lodash'

import { AttachmentsAttribute } from './AttachmentsAttribute'
import { BaseAttribute } from './BaseAttribute'
import { CheckboxAttribute } from './CheckboxAttribute'
import { CurrencyAttribute } from './CurrencyAttribute'
import { DateAttribute } from './DateAttribute'
import { DropdownAttribute } from './DropdownAttribute'
import { LongTextAttribute } from './LongTextAttribute'
import { NumberAttribute } from './NumberAttribute'
import { PercentageAttribute } from './PercentageAttribute'
import { RecordLinksAttribute } from './RecordLinksAttribute'
import { RichTextAttribute } from './RichTextAttribute'
import { TextAttribute } from './TextAttribute'
import { CardViewAttributeProps } from './types'
import { UrlAttribute } from './UrlAttribute'
import { UserAttribute } from './UserAttribute'

type AttributeProps = CardViewAttributeProps<any> & {
    style?: React.CSSProperties
    className?: string
}
export const Attribute: React.FC<AttributeProps> = React.memo(function Attribute(props) {
    switch (props.attribute.field.type) {
        case 'string':
            return <TextAttribute key={props.attribute.id} {...props} />

        case 'long_text':
            return <LongTextAttribute key={props.attribute.id} {...props} />

        case 'document':
            return <RichTextAttribute key={props.attribute.id} {...props} />

        case 'url':
            return <UrlAttribute key={props.attribute.id} {...props} />

        case 'checkbox':
            return <CheckboxAttribute key={props.attribute.id} {...props} />

        case 'number':
            return <NumberAttribute key={props.attribute.id} {...props} />

        case 'currency':
            return <CurrencyAttribute key={props.attribute.id} {...props} />

        case 'percentage':
            return <PercentageAttribute key={props.attribute.id} {...props} />

        case 'date':
        case 'datetime':
            return <DateAttribute key={props.attribute.id} {...props} />

        case 'user_ref':
            return <UserAttribute key={props.attribute.id} {...props} />

        case 'multi_file':
            return <AttachmentsAttribute key={props.attribute.id} {...props} />

        case 'multi_lookup':
        case 'lookup':
            return <RecordLinksAttribute key={props.attribute.id} {...props} />

        case 'dropdown':
        case 'multi_select':
            return <DropdownAttribute key={props.attribute.id} {...props} />

        default:
            return (
                <BaseAttribute
                    key={props.attribute.id}
                    attribute={props.attribute}
                    isLoading={props.isLoading}
                >
                    {props.value && String(props.value)}
                </BaseAttribute>
            )
    }
}, isEqual)

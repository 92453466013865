import React from 'react'

import classNames from 'classnames'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Icon } from 'ui/components/Icon'
import { DisplayOnContainerHover } from 'ui/styles/hoverUtils.css'

export type DocumentCountDisplayProps = BoxProps & {
    count: number
    completed: number
    alwaysShow?: boolean
}

export const DocumentCountDisplay = React.forwardRef<HTMLDivElement, DocumentCountDisplayProps>(
    function DocumentCountDisplay(
        { count, onClick, alwaysShow, ...props }: DocumentCountDisplayProps,
        ref
    ) {
        const label = count > 0 ? `${count ?? 0} items` : 'Add a note'
        return (
            <Tooltip label={label} placement="bottom">
                <Box
                    flex
                    center
                    position="relative"
                    className={
                        !count && !alwaysShow ? classNames(DisplayOnContainerHover) : undefined
                    }
                    aria-role={onClick ? 'button' : undefined}
                    onClick={onClick}
                    ref={ref}
                    {...props}
                >
                    {count ? (
                        <Box
                            fontSize="bodyXs"
                            color="textWeaker"
                            borderWidth="base"
                            borderStyle="base"
                            borderColor="border"
                            background="surfaceStrongest"
                            px={count > 0 ? 'xs' : undefined}
                            py={count > 0 ? '2xs' : undefined}
                            rounded="xs"
                        >
                            {count}
                        </Box>
                    ) : null}
                    {count === 0 && (
                        <Icon color="textWeak" name="Notebook" opacity={0.3} size="l" />
                    )}
                </Box>
            </Tooltip>
        )
    }
)

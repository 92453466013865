import { ZodType } from 'zod'
import { zodToJsonSchema } from 'zod-to-json-schema'

export function zodToOpenAiResponseFormat(zodObject: ZodType, name: string) {
    return {
        type: 'json_schema',
        json_schema: {
            strict: true,
            name,
            schema: zodToJsonSchema(zodObject, { name: 'zod' }).definitions?.['zod'],
        },
    }
}

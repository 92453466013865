import queryString from 'query-string'

import { LIST_TYPES } from 'data/utils/fieldDefinitions'
import { FILTER_OPTIONS_LIST } from 'features/records/components/logic/recordLogic'
import { decodeCommas, encodeCommas } from 'features/utils/escapeCommas'

export const mapOptionFromUrl = (
    urlOption: string | undefined,
    fieldType: FieldDto['type']
): FilterOperation => {
    if (!urlOption) {
        if (['multi_select', 'multi_lookup'].includes(fieldType)) {
            return 'listIs'
        }
        return 'is'
    } else if (urlOption === 'is') {
        if (['multi_select', 'multi_lookup'].includes(fieldType)) {
            return 'listIs'
        } else {
            return 'is'
        }
    } else if (urlOption === 'isnt') {
        if (['multi_select', 'multi_lookup'].includes(fieldType)) {
            return 'listIsnt'
        } else {
            return 'isnt'
        }
    } else if (urlOption === 'contains') {
        if (['dropdown', 'lookup'].includes(fieldType)) {
            return 'oneOf'
        } else if (['multi_select', 'multi_lookup'].includes(fieldType)) {
            return 'containsAny'
        } else {
            return 'contains'
        }
    } else if (urlOption === 'containsNone') {
        if (['dropdown', 'lookup'].includes(fieldType)) {
            return 'noneOf'
        } else {
            return 'containsNone'
        }
    } else if (
        urlOption === 'isEmpty' ||
        urlOption === 'isNotEmpty' ||
        FILTER_OPTIONS_LIST.indexOf(urlOption as FilterOperation) >= 0
    ) {
        return urlOption as FilterOperation
    } else {
        if (['multi_select', 'multi_lookup'].includes(fieldType)) {
            return 'listIs'
        }
        return 'is'
    }
}

export const mapOptionToUrl = (
    filterOperation: FilterOperation,
    fieldType: FieldDto['type']
): string => {
    if (filterOperation === 'oneOf' && ['dropdown', 'lookup'].includes(fieldType)) {
        return 'contains'
    } else if (
        filterOperation === 'containsAny' &&
        ['multi_select', 'multi_lookup'].includes(fieldType)
    ) {
        return 'contains'
    } else if (filterOperation === 'noneOf' && ['dropdown', 'lookup'].includes(fieldType)) {
        return 'containsNone'
    } else {
        return filterOperation as string
    }
}

export const convertValueFromUrl = (urlValue: string, fieldType: FieldDto['type']) => {
    if (fieldType === 'checkbox') {
        return urlValue === '1' ? 'true' : 'false'
    } else {
        return urlValue
    }
}

export const convertValueToUrl = (value: string | undefined, fieldType: FieldDto['type']) => {
    if (fieldType === 'checkbox') {
        return value === 'true' ? '1' : '0'
    } else {
        return value
    }
}

export const getQueryFromFilters = (
    filters: Filter[],
    fields: FieldDto[],
    currentSearch: string,
    allowMultipleFiltersForField: boolean = false
) => {
    const newQuery = queryString.parse(currentSearch)
    // Cleanup query first
    Object.keys(newQuery).forEach((key) => {
        if (fields.some((field) => field.api_name === key)) {
            delete newQuery[key]
        }
    })

    filters.forEach((filter) => {
        //get field from object in case filter.field is missing some props
        const field = fields.find((field) => field.api_name === filter.field?.api_name)
        if (!field) {
            return
        }
        const key = field.api_name

        let param: string = ''
        if (
            filter.options.option === 'is' &&
            (!Array.isArray(filter.options.value) || filter.options.value?.length === 1)
        ) {
            const rawValue = Array.isArray(filter.options.value)
                ? filter.options.value[0]
                : filter.options.value

            param = convertValueToUrl(rawValue, field.type) ?? ''
        } else {
            const valueArray = Array.isArray(filter.options.value)
                ? filter.options.value
                : [convertValueToUrl(filter.options.value, field.type)]
            const filterType = mapOptionToUrl(filter.options.option!, field.type)

            param = `${filterType}${filterType ? ':' : ''}${encodeCommas(valueArray)}`
        }

        if (param) {
            const existingValue = newQuery[key]
            if (existingValue && allowMultipleFiltersForField) {
                if (Array.isArray(existingValue)) {
                    newQuery[key] = [...existingValue, param]
                } else {
                    newQuery[key] = [existingValue, param]
                }
            } else {
                newQuery[key] = param
            }
        }
    })
    return newQuery
}

export const getFiltersFromQuery = (query: queryString.ParsedQuery, fields: FieldDto[]) => {
    return Object.entries(query)
        .map(([key, value]) => {
            const options = (value as string).split(':')
            const field = fields.find((field) => field.api_name === key)
            if (!field) {
                return
            }

            return {
                _id: Math.random(),
                field: field,
                field_sid: field._sid,
                options: {
                    value:
                        options.length === 1
                            ? convertValueFromUrl(options[0], field.type)
                            : convertFilterValue(
                                  field.type === 'datetime'
                                      ? options.slice(1).join(':')
                                      : options[1],
                                  options[0] as FilterOperation,
                                  field.type
                              ),
                    option: mapOptionFromUrl(
                        options.length > 1 ? options[0] : undefined,
                        field.type
                    ),
                },
            }
        })
        .filter((f) => !!f) as Filter[]
}

function isDateOperation(operation: FilterOperation): boolean {
    return (
        operation === 'beforeDate' ||
        operation === 'afterDate' ||
        operation === 'sameDay' ||
        operation === 'withinNext' ||
        operation === 'withinLast'
    )
}

function isArrayOperation(operation: FilterOperation): boolean {
    return [
        'listIs',
        'listIsnt',
        'oneOf',
        'noneOf',
        'containsAny',
        'containsNone',
        'appearsIn',
    ].includes(operation)
}

function convertFilterValue(
    value: string,
    operation: FilterOperation,
    fieldType: FieldDto['type']
): string | string[] {
    const convertedValue = decodeCommas(value.split(','))

    const isDateSpecificOperation = isDateOperation(operation)
    if (
        isDateSpecificOperation &&
        (fieldType === 'date' || fieldType === 'datetime') &&
        convertedValue.length === 1
    ) {
        return convertedValue[0]
    }

    // If this is not an array operation or a list type, and there is only one value, return it as a single value.
    if (
        !isArrayOperation(operation) &&
        !LIST_TYPES.includes(fieldType) &&
        convertedValue.length === 1
    ) {
        return convertedValue[0]
    }

    return convertedValue
}

import { useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'

import settings from 'app/settings'
import { useAppContext } from 'app/useAppContext'
import { useAppUserContext } from 'app/useAppUserContext'

import { useAuthContext } from './AuthContext/AuthContext'
import { getUrl, getWorkspaceUrl, Urls } from './UrlService'

const environmentPrefix = () => {
    return settings.IS_PROD ? '' : `${settings.DOMAIN}#`
}

// This component updates the Datadog RUM integration with the app and user information.
const UserMonitoring = ({}) => {
    const { selectedStack, workspaceAccount, isLoading: appContextLoading } = useAppContext()
    const { isPreviewingAsUserOrRole, isLoadingIncludingStack: userContextLoading } =
        useAppUserContext()
    const { user } = useAuthContext()

    const userIdentifier = user
        ? `${environmentPrefix()}${user?._sid}`
        : `${environmentPrefix()}${selectedStack?._sid}#${user?._sid}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const userAttributes = {
        sid: user?._sid,
        id: userIdentifier,
        email: user?.email,
        name: user?._primary ?? user?.name,
        isPreviewingAsUserOrRole,
    }

    useEffect(() => {
        if (!appContextLoading) {
            datadogRum.setGlobalContextProperty('workspace_sid', workspaceAccount?._sid)
            datadogRum.setGlobalContextProperty('workspace_url', getWorkspaceUrl(Urls.Home))
        }
    }, [appContextLoading, workspaceAccount])

    useEffect(() => {
        if (!appContextLoading) {
            datadogRum.setGlobalContextProperty('stack_sid', selectedStack?._sid)
            datadogRum.setGlobalContextProperty('stack_url', getUrl(Urls.Root))
        }
    }, [appContextLoading, selectedStack])

    useEffect(() => {
        if (!userContextLoading) {
            // We have changed user
            if (userAttributes?.sid) {
                datadogRum.setUser(userAttributes)
            } else {
                datadogRum.clearUser()
            }
        }
    }, [userContextLoading, userAttributes])

    return null
}

export default UserMonitoring

import React, { useState } from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { FadeInOnHoverStyle } from 'ui/styles/hoverUtils.css'

import { OutputSchemaEditorNodeId } from './OutputSchemaEditorNodeId'
import { OutputSchemaEditorNodeList } from './OutputSchemaEditorNodeList'
import { OutputSchemaEditorNodeType } from './OutputSchemaEditorNodeType'
import {
    changeOutputSchemaNodeType,
    isOutputSchemaTypeWithChildren,
    isPlaceholderNode,
} from './utils'

type OutputSchemaEditorNodeProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: WorkflowOutputSchemaNode
    siblingNodes: WorkflowOutputSchemaNode[]
    onChange: (value: WorkflowOutputSchemaNode) => void
    onDelete: () => void
    focusedNodeId: string | null
    setFocusedNode: (nodeId: string | null) => void
    nextNodeId: string | null
}

export const OutputSchemaEditorNode: React.FC<OutputSchemaEditorNodeProps> = ({
    value,
    siblingNodes,
    onChange,
    onDelete,
    focusedNodeId,
    setFocusedNode,
    nextNodeId,
    ...props
}) => {
    const hasChildren = isOutputSchemaTypeWithChildren(value.type)
    const isPlaceholder = isPlaceholderNode(value)

    const [isOpen, setIsOpen] = useState(true)

    const isFocused = focusedNodeId === value.id

    return (
        <Box {...props}>
            <Box flex center gap="xs" justifyContent="space-between">
                <Box flex center gap="xs">
                    {hasChildren && (
                        <Button
                            startIcon={{ name: isOpen ? 'ChevronDown' : 'ChevronRight' }}
                            variant="ghost"
                            size="xs"
                            justifyContent="center"
                            onClick={() => setIsOpen((prev) => !prev)}
                            aria-expanded={isOpen}
                            aria-controls={value.id}
                            style={{ marginLeft: '-6px' }}
                        />
                    )}
                    <OutputSchemaEditorNodeId
                        value={value.id}
                        siblingNodes={siblingNodes}
                        onChange={(id) => onChange({ ...value, id })}
                        isPlaceholderNode={isPlaceholder}
                        onDelete={onDelete}
                        isFocused={isFocused}
                        onBlur={() => setFocusedNode(null)}
                        setFocusedNode={setFocusedNode}
                        nextNodeId={nextNodeId}
                    />
                </Box>
                <Box flex center gap="xs" py="2xs">
                    <OutputSchemaEditorNodeType
                        value={value.type}
                        extra_options={(value as WorkflowOutputLiteralNode).extra_options}
                        onChange={(type, extra_options) =>
                            onChange(changeOutputSchemaNodeType(value, type, extra_options))
                        }
                    />
                    <Button
                        onClick={onDelete}
                        startIcon={{ name: 'X' }}
                        variant="ghost"
                        size="xs"
                        justifyContent="center"
                        fontSize="bodyS"
                        className={FadeInOnHoverStyle}
                    />
                </Box>
            </Box>
            {isOpen && hasChildren && (
                <OutputSchemaEditorNodeList
                    id={value.id}
                    mt="xs"
                    mb="l"
                    role="region"
                    pl="l"
                    ml="xs"
                    nodes={(value as WorkflowOutputArrayNode).children}
                    onChange={(newNodes) =>
                        onChange({
                            ...value,
                            children: newNodes,
                        } as WorkflowOutputArrayNode)
                    }
                    borderLeftWidth={1}
                    setFocusedNode={setFocusedNode}
                    focusedNodeId={focusedNodeId}
                />
            )}
        </Box>
    )
}

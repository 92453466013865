// @ts-strict-ignore
import React from 'react'

import QuillRichText from 'features/pages/blocks/blockTypes/form/QuillRichText'

const RichTextEditor = ({ children, ...props }) => {
    // AirTable automatically escapes these characters with \ in their rich text fields.
    // This causes a problem for us with URLs that contain these characters. We need to
    // unescape them at render time.
    // Characters: *_\`]{
    const unescape_regex = /\\([\*\]\_\`\\\{])/g

    const fixLink = (uri) => {
        return uri && uri.replace(unescape_regex, '$1')
    }

    const fixLinks = (text) => {
        if (typeof text !== 'string') {
            return text
        }
        return (
            text &&
            text.replace(/http.*?(\s|$)/g, (match) => {
                return fixLink(match)
            })
        )
    }

    return (
        <QuillRichText
            //@ts-expect-error
            value={fixLinks(children)}
            resize="none"
            width="100%"
            margin="none"
            readOnly={true}
            defaultTheme="bubble"
            background="transparent"
            convertToMarkdown={false}
            {...props}
        />
    )
}
export default RichTextEditor

// @ts-strict-ignore
import React from 'react'

import { truncate } from 'lodash'

import { getIsStackerNativeObject } from 'features/admin/stackerNativeObjectUtils'
import { getShortFieldName } from 'features/admin/utils'

import { Icon, Text } from 'v2/ui'
import { DeleteTable } from 'v2/ui/svgs'
import { ModalDeclaration } from 'v2/ui/utils/useModalToggle'

export const isFieldShadowed = (field) => {
    return !!field?.connection_options.is_shadowed
}

export const getDeleteTableModalData = (object: ObjectDto): ModalDeclaration<unknown> => {
    const isSharedTableCopy = !!(
        object?.connection_options?.shared_object_id && !object.is_shared_original
    )
    const isNativeObject = getIsStackerNativeObject(object)
    return {
        icon: <Icon svg={<DeleteTable />} size="56px" ml="15px" mb={4} />,
        confirmVariant: 'PrimaryError',
        message: (
            <>
                <Text fontWeight="bold">
                    {isSharedTableCopy ? 'Remove' : 'Delete'} {object?.name}?
                </Text>

                <Text mt={4}>
                    {isSharedTableCopy
                        ? 'Are you sure you want to remove this shared table?'
                        : 'Are you sure you want to delete this table?'}
                </Text>

                {isNativeObject && !isSharedTableCopy ? (
                    <Text mt={4} fontWeight="bold">
                        This will also delete all data contained in this table. This action cannot
                        be undone.
                    </Text>
                ) : null}
            </>
        ),
    }
}

const TRUNCATED_FIELD_LABEL_LENGTH = 40

export function getTruncatedFieldLabel(field: FieldDto): string {
    return truncate(field.label, {
        length: TRUNCATED_FIELD_LABEL_LENGTH,
        omission: '…',
    })
}

const TRUNCATED_FIELD_DEVELOPER_NAME_LENGTH = 50

export function getTruncatedFieldDeveloperName(field: FieldDto): string {
    const developerName = getShortFieldName(field)

    return truncate(developerName, {
        length: TRUNCATED_FIELD_DEVELOPER_NAME_LENGTH,
        omission: '…',
    })
}

import React, { useMemo } from 'react'

import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'
import useViewConfig from 'v2/views/utils/useViewConfig'

import { UnsavedChangesModal } from 'app/UnsavedChangesModal'
import { useIsEditingBlankPages } from 'features/blank-pages/useIsEditingBlankPages'
import { useConfirmDeleteViewModal } from 'features/views/useConfirmDeleteViewModal'
import { useDuplicateViewModal } from 'features/views/useDuplicateViewModal'
import { ViewEditPane } from 'features/views/ViewEditPane'

import BlankPageViewEditor from './BlankPageViewEditor'
import { BlankPageViewEditorAdditionalActions } from './BlankPageViewEditorAdditionalActions'
import BlankPageViewEditorPanes from './BlankPageViewEditorPanes'

type BlankPageViewProps = {
    view: ViewDto
    page?: PageDto
    showControls?: boolean
}

const BlankPageView: React.FC<BlankPageViewProps> = ({ view, page, showControls }) => {
    useHistoryBreadcrumb({ title: view?.name, type: 'blankpage', objectId: view?.object_id })

    const {
        setViewData,
        saveConfig,
        isConfigDirty,
        viewState,
        setPageRoles,
        rolesDirty,
        pageRoles,
        revertChanges,
    } = useViewConfig(view, page)

    //remove cast when useViewConfig will be typed
    const patchedView: ViewDto = viewState.view as ViewDto

    const isDirty = isConfigDirty || rolesDirty
    const showConfirmDeleteModal = useConfirmDeleteViewModal()

    const viewPaneActions = useMemo(
        () => [
            {
                action: () => showConfirmDeleteModal({ view: patchedView }),
                label: 'Delete Page',
            },
        ],
        [patchedView, showConfirmDeleteModal]
    )

    const showDuplicateViewModal = useDuplicateViewModal()

    const isEditing = useIsEditingBlankPages()

    return (
        <>
            {showControls && (
                <>
                    <ViewEditPane
                        saveView={saveConfig}
                        setViewData={setViewData}
                        isConfigDirty={isDirty}
                        viewName={patchedView.name}
                        view={patchedView}
                        additionalActions={
                            <BlankPageViewEditorAdditionalActions
                                view={patchedView}
                                onDuplicatePage={() =>
                                    showDuplicateViewModal({ view: patchedView })
                                }
                            />
                        }
                        actions={viewPaneActions}
                    >
                        <BlankPageViewEditorPanes
                            view={patchedView}
                            onChange={setViewData}
                            pageRoles={pageRoles}
                            setPageRoles={setPageRoles}
                        />
                    </ViewEditPane>
                    <UnsavedChangesModal
                        isDirty={isDirty}
                        onSave={saveConfig}
                        revertChanges={revertChanges}
                    />
                </>
            )}
            <BlankPageViewEditor view={patchedView} onChange={setViewData} isEditing={isEditing} />
        </>
    )
}

export default BlankPageView

import React from 'react'

import { ButtonGroup } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { useRefreshUserIntegrationKey } from 'data/hooks/users/useRefreshUserIntegrationKey'

import { Banner, Button, Flex, Tooltip } from 'v2/ui'
import QuickCopyButton from 'v2/ui/components/QuickCopyButton'

import { Label, NonEditable } from './ProfileSettingsCommonComponents'

const WithAdorner = styled.div`
    width: 100%;
    display: flex;
    & > :first-child {
        height: auto;
        border-radius: 6px 0px 0px 6px;
    }
    & > :last-child {
        button {
            height: auto;
            border-radius: 0px 6px 6px 0px;
        }
    }
`

const AskBanner = styled(Banner)`
    margin-top: 12px;
`

const AskText = styled.div`
    margin-bottom: 12px;
`

export function ProfileSettingsModalIntegrationSettings() {
    const { user } = useAuthContext()
    return (
        <Flex column p={5} alignItems="flexStart">
            <Label text="Workspace ID" />
            <NonEditable>
                {user?.account_id}{' '}
                <QuickCopyButton isHidden={false} value={user?.account_id ?? ''} />
            </NonEditable>

            <div style={{ height: 24 }} />

            <>
                <Label text="Integration Key" />
                <Banner icon="info" variant="Information" my={2}>
                    This integration key is not compatible with the public Stacker integration on
                    Zapier. Please contact the Stacker team for an invitation to the development
                    integration.
                </Banner>
                <IntegrationKey user={user} />
            </>

            <div style={{ height: 24 }} />
        </Flex>
    )
}

const IntegrationKey = ({ user }: { user: AuthedUserDto | undefined }) => {
    const refresh = useRefreshUserIntegrationKey()
    const [askToRefresh, setAskToRefresh] = React.useState(false)

    if (!user?.integration_key) {
        return (
            <div>
                <Tooltip
                    placement="bottom"
                    display="inline-block"
                    label="You may need an integration key to authenticate external services such as Make or Zapier."
                >
                    <Button variant="moderateSm" padding="small" icon="key" onClick={refresh}>
                        Create an integration key
                    </Button>
                </Tooltip>
            </div>
        )
    }

    return (
        <>
            <WithAdorner>
                <NonEditable>
                    <span>{user?.integration_key}</span>
                    <QuickCopyButton isHidden={false} value={user?.integration_key ?? ''} />
                </NonEditable>
                <Tooltip label="Refresh the key" placement="bottom" display="flex">
                    <Button
                        variant="moderateSm"
                        disable={askToRefresh}
                        padding="small"
                        icon="refresh"
                        onClick={() => setAskToRefresh(true)}
                    />
                </Tooltip>
            </WithAdorner>
            {askToRefresh && (
                <AskBanner variant="Information">
                    <AskText>
                        Refreshing the key will log you out of all your current integrations. Are
                        you sure you want to refresh?
                    </AskText>
                    <ButtonGroup>
                        <Button
                            variant="adminPrimaryV4"
                            padding="small"
                            icon="refresh"
                            onClick={() => refresh().then(() => setAskToRefresh(false))}
                        >
                            Yes, please refresh
                        </Button>
                        <Button
                            variant="adminSecondaryV4"
                            padding="small"
                            onClick={() => setAskToRefresh(false)}
                        >
                            Cancel
                        </Button>
                    </ButtonGroup>
                </AskBanner>
            )}
        </>
    )
}

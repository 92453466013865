/**
 * This file is a modification of CheckboxDropdown.js.
 * Used in ChangeNavigationsRole in navigation settings modal.
 * Comments such as "Added this" and "Changed this" indicates the difference between these 2 files.
 */

import React, { useEffect, useMemo, useState } from 'react'
import Select, { components } from 'react-select'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Button, Checkbox, Flex, RadioButton, Text } from 'v2/ui'
import { withTheme } from 'v2/ui/theme/components/withTheme'

// Added this
const PADDING_LEFT_RIGHT = '15px'
const MENU_PADDING_TOP_BOTTOM = '12px'

const StyledButtonAsLink = styled(Button)`
    color: ${(props) => props.theme.colors.v4Blue};
    background: transparent;
    text-decoration: underline;
    &:hover {
        background: transparent;
    }
    border-radius: 0;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
`

const InnerToggleDropdown = (props) => {
    const style = props.style ? props.style : {}
    const { margin, width, flex } = style
    const fontSize = props.fontSize ? props.fontSize : props.theme.fontSize
    const [isAllRolesSelected, setIsAllRolesSelected] = useState()

    useEffect(() => {
        setIsAllRolesSelected(props.value.includes('*'))
    }, [props.value])

    const customStyles = {
        container: (provided) => ({
            ...provided,
            margin: margin || '',
            flex: flex || 'none',
        }),
        valueContainer: (provided) => ({
            ...provided,
            flexWrap: 'nowrap',
        }),
        multiValue: (provided) => ({
            ...provided,
            minWidth: '40px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: props.theme.fontFamily,
            fontSize,
            backgroundColor: 'none',
            color: props.color ? props.color : props.theme.fontColor,
            // Changed this
            padding: props.padding
                ? `${props.padding} ${PADDING_LEFT_RIGHT}`
                : `2px ${PADDING_LEFT_RIGHT}`,
            // Added this, children toggle elements are shifted to the right.
            paddingLeft: state.data.label !== 'All Roles' ? '35px' : PADDING_LEFT_RIGHT,
        }),
        placeholder: () => ({
            color: props.color ? props.color : props.theme.fontColor,
        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            fontFamily: props.theme.fontFamily,
            fontSize,
            color: props.color ? props.color : props.theme.fontColor,
            textAlign: 'left',
        }),
        control: (provided) => ({
            ...provided,
            background: props.theme.input,
            fontFamily: props.theme.fontFamily,
            fontSize,
            borderRadius: props.theme.elementBorderRadius,
            border: props.border
                ? props.border
                : `${props.theme.dividerThickness}px solid ${props.theme.outlineColor}`,
            outline: 'none',
            color: props.color ? props.color : props.theme.fontColor,
            boxShadow: 'none',
            minWidth: props.width ? props.width : width || '150px',
            minHeight: 'auto',
            '&:hover': {
                borderColor: props.theme.outlineColor,
            },
            ...props.controlStyle,
            display: props.hideControl ? 'none' : 'flex',
        }),
        menu: (provided) => ({
            ...provided,
            position: 'relative',
            outline: 'none',
            boxShadow: 'none',
            background: 'white',
            border: props.border
                ? props.border
                : `${props.theme.dividerThickness}px solid ${props.theme.outlineColor}`,
            margin: 0,
            paddingTop: MENU_PADDING_TOP_BOTTOM, // Changed this
            paddingBottom: MENU_PADDING_TOP_BOTTOM, // Changed this
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: props.padding ? `${props.padding} 2px` : '8px 2px',
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: props.padding ? `${props.padding} 2px` : '8px 2px',
        }),
        ...props.additionalStyles,
    }

    let selected = null
    if (props.value && props.options) {
        if (props.isMulti) {
            selected = []
            props.options.forEach((option) => {
                if (props.value.includes(option.value)) selected.push(option)
            })
        } else {
            if (props.returnObject) {
                selected = props.value
            }
            props.options.forEach((option) => {
                if (option.value === props.value) selected = option
            })
        }
    }

    // If we selected an option then unpack its value and pass to onChange
    // Otherwise we cleared the select box, so pass empty to onChange
    // In the multi-select case we receive an array of options and pass out an array of unpacked values.
    let onChange
    if (props.isMulti) {
        onChange = (optionList) => {
            if (optionList) {
                const optionValues = optionList.map((option) =>
                    props.returnObject ? option : option.value
                )
                return props.onChange && props.onChange(optionValues)
            } else {
                return props.onChange && props.onChange([])
            }
        }
    } else {
        onChange = (option) =>
            option
                ? props.onChange && props.onChange(props.returnObject ? option : option.value)
                : props.onChange && props.onChange(null)
    }

    const selectAllRoles = (e) => {
        e.stopPropagation()
        // Select all roles except the 'All Roles' option
        const options = props.options.filter((option) => option.value !== '*')
        onChange(options)
    }

    const deselectAllRoles = (e) => {
        e.stopPropagation()
        // Make sure to keep any options that are set to forceSelected
        // These are user roles that can access all pages
        const options = props.options.filter((option) => option.forceSelected)
        onChange(options)
    }

    const selectRef = React.createRef()

    const StyledLabel = styled.label`
        display: inline-block;
        white-space: ${props.singleLine ? 'nowrap' : 'wrap'};
        overflow: hidden;
        text-overflow: ellipsis;
    `

    const StyledLabelValue = styled('span')`
        font-size: 14px;
        color: ${(props) => props.theme.colors.userInterface.neutral[900]};
    `
    function getLength(options) {
        return options.reduce((acc, curr) => {
            if (curr.options) return acc + getLength(curr.options)
            return acc + 1
        }, 0)
    }

    const Option = (props) =>
        // Remove roles that are disabled
        props.isDisabled ? null : (
            <div>
                <components.Option {...props}>
                    {props.isMulti &&
                        (props.label === 'All Roles' ? (
                            <>
                                <RadioButtonHeader radioLabel="All users" {...props} />
                                <RadioButtonHeader
                                    radioLabel="Only users in roles"
                                    isSecondItem={true}
                                    {...props}
                                />
                                {!isAllRolesSelected && (
                                    <>
                                        <Flex mt={2}>
                                            <StyledButtonAsLink
                                                mr={3}
                                                ml={5}
                                                onClick={selectAllRoles}
                                            >
                                                Select All
                                            </StyledButtonAsLink>
                                            <StyledButtonAsLink onClick={deselectAllRoles}>
                                                Deselect All
                                            </StyledButtonAsLink>
                                        </Flex>
                                    </>
                                )}
                            </>
                        ) : isAllRolesSelected ? null : (
                            <>
                                <Checkbox
                                    variantColor="adminSwitch"
                                    size="sm"
                                    isChecked={props.isSelected}
                                    onChange={() => null}
                                    isDisabled={props.isDisabled}
                                >
                                    <Text
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        style={{ maxWidth: '110px' }}
                                    >
                                        {props.label}
                                    </Text>
                                </Checkbox>
                            </>
                        ))}
                </components.Option>
            </div>
        )

    const { placeholder } = props

    const ValueContainer = (props) => {
        const optionsLength = getLength(props.getValue() || [])
        return (
            <components.ValueContainer {...props}>
                <span>{optionsLength ? `${optionsLength} selected` : placeholder}</span>
            </components.ValueContainer>
        )
    }

    const Footer = ({ disabledRoles }) => {
        const roles = disabledRoles.filter((role) => role.toLowerCase() !== 'admin')

        return (
            <div style={{ padding: `0px ${PADDING_LEFT_RIGHT}` }}>
                <StyledLabelValue style={{ color: '#9099A0' }}>
                    {roles.length > 0 && (
                        <>
                            {' '}
                            and users in role(s){' '}
                            {roles.map((roleName, index) =>
                                roles.length > 1 && index !== roles.length - 1 ? (
                                    <>
                                        <StyledLabelValue>{roleName}</StyledLabelValue> or{' '}
                                    </>
                                ) : (
                                    <StyledLabelValue key={index}>{roleName}</StyledLabelValue>
                                )
                            )}
                        </>
                    )}
                </StyledLabelValue>
            </div>
        )
    }

    const MenuList = (props) => {
        const disabledRoles = useMemo(() => {
            let roles = props.children
                .filter((child) => child.props.isDisabled)
                .map((child) => child.props.children)
            return roles
        }, [props.children])

        return (
            <components.MenuList {...props}>
                <div style={{ padding: `0px ${PADDING_LEFT_RIGHT} 4px ${PADDING_LEFT_RIGHT}` }}>
                    <span style={{ fontSize: '16px' }}>Access to page</span>
                </div>
                {props.children}
                {/* Don't show footer if "All Roles" is selected or there's no disabled roles. */}
                {props.children[0].props.isSelected || disabledRoles.length === 0 ? null : (
                    <Footer disabledRoles={disabledRoles} />
                )}
            </components.MenuList>
        )
    }

    const RadioButtonHeader = (props) => {
        return (
            <Flex>
                <RadioButton
                    size="lg"
                    checkedRadioColor="#465DD8"
                    value={props.isSecondItem ? !props.isSelected : props.isSelected}
                    isChecked={props.isSecondItem ? !props.isSelected : props.isSelected}
                    disabled={props.isDisabled}
                    onChange={() => null}
                    verticalAlign="middle"
                >
                    <StyledLabel>
                        <StyledLabelValue>{props.radioLabel}</StyledLabelValue>
                    </StyledLabel>
                </RadioButton>
            </Flex>
        )
    }

    return (
        <Select
            ref={selectRef}
            {...props}
            value={selected}
            onChange={onChange}
            options={props.options}
            ignoreCase
            multi={props.isMulti}
            removeSelected={false}
            styles={customStyles}
            closeMenuOnSelect={!props.isMulti}
            hideSelectedOptions={false}
            isClearable={props.isClearable === false ? false : true}
            components={{ Option, ValueContainer, MenuList }} // Changed this
        />
    )
}

InnerToggleDropdown.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    // { value: 'one', label: 'One' },
    //  { value: 'two', label: 'Two' },
    onChange: PropTypes.func,
    isMulti: PropTypes.bool,
}

InnerToggleDropdown.defaultProps = {
    value: undefined,
    onChange: undefined,
    isMulti: false,
}

export const ToggleDropdown = withTheme(InnerToggleDropdown)

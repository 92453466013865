import { useCallback, useMemo, useRef } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { ActionMenuEditProps } from 'features/views/ListView/Actions/types'
import {
    convertV2FieldColorToTheme,
    getDefaultDropdownFieldOptionColorName,
} from 'utils/fieldUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

const MAX_LABEL_LENGTH = 25

type OptionValue = {
    label: string
    value: string
    color?: string
    isRecent?: boolean
}

type UseActionMenuEditDropdownStateOptions = ActionMenuEditProps<string | string[]> & {}

export function useActionMenuEditDropdownState(options: UseActionMenuEditDropdownStateOptions) {
    const { field, updateRecord, value, recentValues } = options

    const fieldLabel = truncateText(field.label, MAX_LABEL_LENGTH)

    const onClear = useCallback(() => {
        updateRecord({ [field.api_name]: null })
    }, [field.api_name, updateRecord])

    const currentValue = getCurrentValue(value)
    const internalValue = useDeepEqualsMemoValue(currentValue)
    const effectiveValue = useMemo(() => new Set(internalValue), [internalValue])
    const effectiveValueRef = useRef(effectiveValue)
    effectiveValueRef.current = effectiveValue

    const isSingle = field.type === 'dropdown'

    const editOptions = useMemo<OptionValue[]>(() => {
        const uniqueRecentValues = new Set(recentValues?.flat(2))

        const allowColors = !!field.options?.allow_dropdown_colors

        const options =
            field.options?.options?.map((option) => {
                let color = getDefaultDropdownFieldOptionColorName()
                if (allowColors && option?.color) {
                    // This will try to map the old color format to the new one.
                    // If the color is not found, it means that this is a new color,
                    // so use it as is.
                    color = convertV2FieldColorToTheme(option.color)
                }

                const label = truncateText(option.label ?? option.value!, MAX_LABEL_LENGTH)

                const isRecent = uniqueRecentValues.has(option.value!)

                return {
                    label,
                    value: option.value!,
                    color,
                    isRecent,
                }
            }) ?? []

        return options.sort((a, b) => {
            // Display recent values first.
            if (a.isRecent && !b.isRecent) return -1
            if (!a.isRecent && b.isRecent) return 1

            return 0
        })
    }, [field.options?.allow_dropdown_colors, field.options?.options, recentValues])

    const onEditValue = useCallback(
        (value: string, isChecked: boolean) => {
            if (field.type === 'dropdown') {
                // Just replace the value.
                updateRecord({ [field.api_name]: value })
            } else {
                const existingValue = effectiveValueRef.current
                const newValue = new Set(existingValue)

                // Add or remove the value from the list of values.
                if (isChecked) {
                    newValue.add(value)
                } else {
                    newValue.delete(value)
                }

                updateRecord({ [field.api_name]: Array.from(newValue) })
            }
        },
        [field.api_name, field.type, updateRecord]
    )

    const fieldIcon = useDeepEqualsMemoValue(getFieldIcon(field))

    return useMemo(
        () => ({
            fieldLabel,
            editOptions,
            onEditValue,
            onClear,
            value: effectiveValue,
            isSingle,
            fieldIcon,
        }),
        [fieldLabel, editOptions, onEditValue, onClear, effectiveValue, isSingle, fieldIcon]
    )
}

function getCurrentValue(value?: string | string[]) {
    if (Array.isArray(value)) {
        return value
    }

    return value ? [value] : []
}

import React from 'react'

import { Highlighter } from 'features/Search/Highlighter'
import { BlockTypeCommand } from 'features/tiptap/BlockTypes'

import { Box } from 'ui/components/Box'

import { CommandListIcon } from './CommandListIcon'

export function RenderBlockType({
    item,
    queryTerms,
}: {
    item: BlockTypeCommand
    queryTerms?: string[]
}) {
    return (
        <Box flex center maxWidth="full" width="full" color="text" tabIndex={-1}>
            <CommandListIcon name={item.icon} mr="m" />
            <Box flex column grow shrink>
                <Box mr="l" trim grow>
                    <Highlighter terms={queryTerms || []}>{item.label}</Highlighter>
                </Box>
                {/* <Box color="textWeak" fontSize="bodyS">
                    {item.description}
                </Box> */}
            </Box>
        </Box>
    )
}

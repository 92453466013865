import { useMemo } from 'react'

import { NavigationScrollAreaIsOverflowingBottomStyle } from 'features/navigation/Navigation.css'
import { useOverflowY } from 'features/views/ListView/hooks/useOverflowY'

import { useNavigationContext } from './useNavigationContext'

export function useAppNavigationState() {
    const { selectedApp } = useNavigationContext()

    const { targetRef: wrapperRef, scrollAreaRef } = useOverflowY({
        bottomClassName: NavigationScrollAreaIsOverflowingBottomStyle,
    })

    return useMemo(
        () => ({ selectedApp, wrapperRef, scrollAreaRef }),
        [scrollAreaRef, selectedApp, wrapperRef]
    )
}

import React from 'react'

import { TaskRelatedTo } from 'data/hooks/tasks/types'
import { TasksQuickCreate } from 'features/tasks/TasksQuickCreate'

import { Modal, ModalContent, ModalContentInner } from 'ui/components/Modal'

import { useCreateTaskModalState } from './hooks/useCreateTaskModalState'

type CreateTaskModalProps = {
    stackSid: string
    record?: RecordDto
    isOpen?: boolean
    onOpenChange?: (open: boolean) => void
}

export const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
    record,
    stackSid,
    isOpen,
    onOpenChange,
}) => {
    const { onInteractOutside, onCreate, onClose } = useCreateTaskModalState({
        isOpen,
        onOpenChange,
    })

    if (!record || !isOpen) return null

    return (
        <Modal open={isOpen} onOpenChange={onOpenChange}>
            <ModalContent onInteractOutside={onInteractOutside} zIndex={1500}>
                <ModalContentInner>
                    <TasksQuickCreate
                        related_to={record._sid}
                        _object_id={record._object_id}
                        related_to_type={TaskRelatedTo.Record}
                        related_to_stack={stackSid}
                        showRelatedToText={record._primary?.toString()}
                        onCreate={onCreate}
                        onClose={onClose}
                        px={0}
                        py="l"
                    />
                </ModalContentInner>
            </ModalContent>
        </Modal>
    )
}

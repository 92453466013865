import type { FC, VFC } from 'react'

import AppSettingsSidePane from 'features/workspace/AdminSideTray/app-settings/AppSettingsSidePane'
import { DataConnectionsSidePane } from 'features/workspace/AdminSideTray/data-connections/DataConnectionsSidePane'
import { EditLayoutSidePane } from 'features/workspace/AdminSideTray/edit-layout/EditLayoutSidePane'
import { ManageDataSidePane } from 'features/workspace/AdminSideTray/manage-data/ManageDataSidePane'
import { ManageUsersSidePane } from 'features/workspace/AdminSideTray/manage-users/ManageUsersSidePane'
import type { SlidingPaneKey } from 'features/workspace/AdminSideTray/types'
import { WorkflowsSidePane } from 'features/workspace/AdminSideTray/workflows/WorkflowsSidePane'

export const SLIDING_PANES = new Map<SlidingPaneKey | null, FC | VFC>()

SLIDING_PANES.set('app-settings', AppSettingsSidePane)
SLIDING_PANES.set('edit-layout', EditLayoutSidePane)
SLIDING_PANES.set('data-connections', DataConnectionsSidePane)
SLIDING_PANES.set('data-grid', ManageDataSidePane)
SLIDING_PANES.set('workflows', WorkflowsSidePane)
SLIDING_PANES.set('users', ManageUsersSidePane)

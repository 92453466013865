import React from 'react'

type EditModeListContextData = {
    disableEditControls?: boolean
    lastHoveredRecordId?: string
    hoveredEditorFieldId?: string
    activeEditorFieldId?: string
    setHoveredEditorFieldId: (key: string | undefined) => void
    setActiveEditorFieldId: (key: string | undefined) => void
    setLastHoveredRecordId: (key: string | undefined) => void
}

const defaultContext: EditModeListContextData = {
    setHoveredEditorFieldId: (_: string | undefined) => undefined,
    setActiveEditorFieldId: (_: string | undefined) => undefined,
    setLastHoveredRecordId: (_: string | undefined) => undefined,
}

export const EditModeListContext = React.createContext(defaultContext)

import React, { useCallback } from 'react'

import { useConfirmModal } from 'app/useConfirmModal'
import { DeleteIntegrationPayload, useDeleteIntegration } from 'data/hooks/workflows/integrations'

import { Icon, Text } from 'v2/ui'
import { useToast } from 'v2/ui/components/useToast'
import { DeleteData } from 'v2/ui/svgs'
import { Modal } from 'v2/ui/utils/useModalToggle'

export const useConfirmDeleteConnectionModal = () => {
    const { show } = useConfirmModal()

    const toast = useToast()

    const { mutateAsync: deleteIntegration } = useDeleteIntegration({
        onSuccess: () => {
            toast({ title: 'Connection deleted successfully.', status: 'success' })
        },
        onError: () => {
            toast({
                title: 'There was a problem deleting the connection. Please try again later.',
                status: 'error',
            })
        },
    })

    return useCallback(
        (payload: DeleteIntegrationPayload) => {
            show({
                title: 'Confirm Delete',
                confirmVariant: 'PrimaryError',
                icon: <Icon svg={<DeleteData />} size="56px" ml="15px" mb={4} />,
                message: (
                    <Text>
                        Are you sure you want to delete this connection? This action cannot be
                        undone.
                    </Text>
                ),
                onConfirm: async (modal: Modal<unknown>) => {
                    deleteIntegration(payload)

                    modal.setIsOpen(false)
                },
                onCancel: (modal: Modal<unknown>) => {
                    modal.setIsOpen(false)
                },
            })
        },
        [show, deleteIntegration]
    )
}

import { useRef } from 'react'

import { Extension } from '@tiptap/core'
import { KeyboardShortcutCommand } from '@tiptap/react'

import { useMemo } from 'utils/debugUtils'

type KeyboardShortcuts = {
    [key: string]: KeyboardShortcutCommand
}

type KeyboardShortcutOptions = {
    name: string
    shortcuts: KeyboardShortcuts
}
export function createKeyboardShortcutExtension({ name, shortcuts }: KeyboardShortcutOptions) {
    return Extension.create({
        name,
        addKeyboardShortcuts() {
            return shortcuts
        },
    })
}

export function useKeyboardShortcutsExtension(options: KeyboardShortcutOptions) {
    const optionsRef = useRef(options)
    optionsRef.current = options
    return useMemo(() => {
        const { name, shortcuts } = optionsRef.current
        const modifiedShortcuts = Object.keys(shortcuts).reduce<KeyboardShortcuts>((acc, key) => {
            acc[key] = (props) => {
                // call out to the original shortcut handler via the
                // ref so that we don't execute a stale closure
                return optionsRef.current.shortcuts[key](props)
            }

            return acc
        }, {})

        return createKeyboardShortcutExtension({ name, shortcuts: modifiedShortcuts })
    }, [])
}

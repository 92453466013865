import { useEffect, useState } from 'react'

import { fetchWithAuth } from 'data/utils/fetchWithAuth'

export function useTipTapCollabToken() {
    const [token, setToken] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const updateCollabToken = async () => {
            const response = await fetchWithAuth('collab/token')
            setToken(await response.json())
            setIsLoading(false)
        }

        updateCollabToken()
    }, [])

    return { token, isLoading }
}

import { Editor, getNodeType, isAtStartOfNode, isNodeActive } from '@tiptap/core'
import ListKeymap, { listHelpers } from '@tiptap/extension-list-keymap'
import { Node } from '@tiptap/pm/model'

export function createListKeymapExtension() {
    return ListKeymap.extend({
        addKeyboardShortcuts() {
            return {
                ...this.parent?.(),
                Backspace: ({ editor }) => {
                    let handled = false

                    for (const { itemName, wrapperNames } of this.options.listTypes) {
                        if (typeof editor.state.schema.nodes[itemName] === 'undefined') {
                            continue
                        }

                        if (handleBackspace(editor, itemName, wrapperNames)) {
                            handled = true
                        }
                    }

                    return handled
                },
                'Mod-Backspace': ({ editor }) => {
                    let handled = false

                    for (const { itemName, wrapperNames } of this.options.listTypes) {
                        if (typeof editor.state.schema.nodes[itemName] === 'undefined') {
                            continue
                        }

                        if (handleBackspace(editor, itemName, wrapperNames)) {
                            handled = true
                        }
                    }

                    return handled
                },
            }
        },
    }).configure({})
}

function handleBackspace(editor: Editor, name: string, parentListTypes: string[]) {
    // This is required to still handle the undo command.
    if (editor.commands.undoInputRule()) {
        return true
    }

    // If the current item is NOT inside a list item &
    // the previous item is a list (orderedList or bulletList)
    // move the cursor into the list and delete the current item.
    if (
        !isNodeActive(editor.state, name) &&
        listHelpers.hasListBefore(editor.state, name, parentListTypes)
    ) {
        const { $anchor } = editor.state.selection
        const $listPos = editor.state.doc.resolve($anchor.before() - 1)

        const listDescendants: Array<{ node: Node; pos: number }> = []
        $listPos.node().descendants((node, pos) => {
            if (node.type.name === name) {
                listDescendants.push({ node, pos })
            }
        })

        const lastItem = listDescendants.at(-1)
        if (!lastItem) {
            return false
        }
        const $lastItemPos = editor.state.doc.resolve($listPos.start() + lastItem.pos + 1)

        return editor
            .chain()
            .cut({ from: $anchor.start() - 1, to: $anchor.end() + 1 }, $lastItemPos.end())
            .joinForward()
            .run()
    }

    // If the cursor is not inside the current node type
    // do nothing and proceed.
    if (!isNodeActive(editor.state, name)) {
        return false
    }

    // Fix for `https://go.stackerhq.com/frontstage/dev/issues/view/is2_NAdP7SY6nNAes5LdpzAsZU`.
    const isNodeContentSelected = isEntireNodeSelected(editor, name, parentListTypes)
    if (isNodeContentSelected) {
        return editor.chain().liftListItem(name).run()
    }

    // If we're at the start of a list item, just lift it.
    if (isAtStartOfNode(editor.state)) {
        const nodeType = getNodeType(name, editor.state.schema)

        const listItemPos = listHelpers.findListItemPos(name, editor.state)
        if (!listItemPos) {
            return false
        }

        // Only continue if the cursor is at the start of the list item.
        const isStartOfListItem = listItemPos.$pos.parentOffset === 0
        if (!isStartOfListItem) {
            return false
        }

        const $listPos = editor.state.doc.resolve(listItemPos.$pos.pos - 1)
        const parentNode = $listPos.node($listPos.depth - 1)
        const isWithinSublist = parentNode.type === nodeType
        const isFirstListItem = !listHelpers.hasListItemBefore(name, editor.state)
        if (isWithinSublist && !isFirstListItem) {
            return false
        }

        return editor.chain().liftListItem(name).run()
    }

    return false
}

function isEntireNodeSelected(editor: Editor, name: string, parentListTypes: string[]) {
    const listItemPos = listHelpers.findListItemPos(name, editor.state)
    if (!listItemPos) {
        return false
    }

    const nodeStartPos = listItemPos.$pos.pos + 1

    let nodeEndPos = listItemPos.$pos.end(listItemPos.depth)
    nodeEndPos = nodeStartPos + listItemPos.$pos.node(listItemPos.depth).content.size
    listItemPos.$pos.node(listItemPos.depth).forEach((childNode, offset) => {
        if (parentListTypes.includes(childNode.type.name)) {
            nodeEndPos = nodeStartPos + offset + 2

            return false
        }
    })

    const selectionStartPos = editor.state.selection.$anchor.pos
    const selectionEndPos = editor.state.selection.$head.pos

    return selectionStartPos === nodeStartPos && selectionEndPos === nodeEndPos
}

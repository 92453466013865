import React from 'react'

import { Box } from 'ui/components/Box'
import { Checkbox } from 'ui/components/Checkbox'

import { WorkflowTriggerEditorComponent } from './types'

export const RecordUpdatedTriggerConditions: WorkflowTriggerEditorComponent = ({
    trigger,
    onChange,
}) => {
    const hasConditions = Boolean(trigger.condition?.clauses?.length)

    const matchRecordChange = (trigger.settings?.match_record_change as boolean) ?? true

    const handleChangeMatch = (value: boolean) => {
        onChange({
            settings: {
                ...trigger.settings,
                match_record_change: value,
            },
        })
    }

    return (
        <Box mt="xl">
            {hasConditions && (
                <Box flex alignItems="center" gap="m">
                    <Checkbox
                        checked={matchRecordChange}
                        onCheckedChange={handleChangeMatch}
                        id="record-changing-match"
                    />
                    <Box
                        as="label"
                        htmlFor="record-changing-match"
                        cursor="pointer"
                        fontSize="bodyS"
                    >
                        Only match when the record is changing to meet this criteria
                    </Box>
                </Box>
            )}
        </Box>
    )
}

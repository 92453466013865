// @ts-strict-ignore
import React from 'react'

import { Box } from 'ui/components/Box'
import { Range } from 'ui/components/Range'

import { useQuickFilterPercentageState } from './hooks/useQuickFilterPercentageState'
import { QuickFilter, QuickFilterButton, QuickFilterContent } from './QuickFiltersItemBase'
import { QuickFilterComponent } from './types'

type QuickFilterPercentageProps = {}

export const QuickFilterPercentage: QuickFilterComponent<QuickFilterPercentageProps> = ({
    field,
}) => {
    const { value, isActive, label, onFilterRemove, onSetFilterValue } =
        useQuickFilterPercentageState({
            field,
        })

    return (
        <QuickFilter>
            <QuickFilterButton isActive={isActive} onRemove={onFilterRemove}>
                {label}
            </QuickFilterButton>
            <QuickFilterContent align="end">
                <Box py="m" px="xl" maxWidth="300px">
                    <Range
                        isMulti
                        showCount
                        min={0}
                        max={100}
                        value={value}
                        onValueChange={onSetFilterValue}
                    />
                </Box>
            </QuickFilterContent>
        </QuickFilter>
    )
}

import React from 'react'

import { NoRecordsFilters } from 'v2/ui/svgs/list-view-empty-states/NoRecordsFilters'

import { Button } from 'ui/components/Button'

import { BaseEmptyState } from './BaseEmptyState'

type NoRecordsFiltersEmptyStateProps = React.ComponentPropsWithoutRef<typeof BaseEmptyState> & {
    onClearFilters?: () => void
}

export const NoRecordsFiltersEmptyState: React.FC<NoRecordsFiltersEmptyStateProps> = React.memo(
    function NoRecordsFiltersEmptyState({ onClearFilters, ...props }) {
        return (
            <BaseEmptyState
                image={<NoRecordsFilters />}
                title="No records found"
                description="Try adjusting your search term or filter options to find what you’re looking for."
                footer={
                    onClearFilters && (
                        <Button variant="primary" size="m" onClick={onClearFilters}>
                            Clear filters
                        </Button>
                    )
                }
                {...props}
            />
        )
    }
)

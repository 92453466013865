import React from 'react'

import { DefaultHeaderMeta } from 'features/core/DefaultHeaderMeta'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Headline } from 'ui/components/Text'
import { StackerLogo } from 'ui/graphics/StackerLogo'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

type OnboardingFrameProps = {
    children: React.ReactNode
    footerContents?: React.ReactNode
    previewContents?: React.ReactNode
    headline?: string
    title?: string
    onGoBack?: () => void
}

export function OnboardingFrame({
    children,
    footerContents,
    previewContents,
    headline,
    title,
    onGoBack,
}: OnboardingFrameProps) {
    return (
        <ThemeProvider theme="onboarding">
            {/*@ts-expect-error*/}
            <DefaultHeaderMeta title={title} />
            <Box
                flex
                width="full"
                style={{ height: '100dvh' }}
                flexDirection={{ mobile: 'column', desktop: 'row' }}
            >
                <Box
                    flex
                    column
                    height="full"
                    center
                    justifyContent="center"
                    width={{ mobile: 'full', desktop: '50%' }}
                    py={{ mobile: '2xl', tabletLarge: '5xl' }}
                    px="2xl"
                    shrink
                    overflowY="auto"
                >
                    <Box
                        flex
                        column
                        height="full"
                        justifyContent="space-between"
                        noShrink
                        gap="2xl"
                    >
                        <Box flex center>
                            <Box display={{ mobile: 'none', desktop: 'block' }}>
                                <Button
                                    variant="secondary"
                                    size="l"
                                    onClick={onGoBack}
                                    mr="2xl"
                                    width="6xl"
                                    height="6xl"
                                    style={{ visibility: onGoBack ? 'visible' : 'hidden' }}
                                >
                                    <Icon name="ArrowLeft" size="2xl" />
                                </Button>
                            </Box>
                            <StackerLogo width="130px" style={{ flexShrink: 0 }} />
                        </Box>
                        <Box flex maxWidth="full">
                            <Box
                                width="6xl"
                                mr="2xl"
                                noShrink
                                display={{ mobile: 'none', desktop: 'block' }}
                            />
                            <Box flex column gap="2xl" style={{ maxWidth: '450px' }} shrink>
                                {headline && (
                                    <Headline
                                        size={{ mobile: 'xl', tabletLarge: '3xl' }}
                                        style={{ lineHeight: '1' }}
                                    >
                                        {headline}
                                    </Headline>
                                )}
                                {children}
                            </Box>
                            <Box
                                width="6xl"
                                mr="2xl"
                                noShrink
                                display={{ mobile: 'none', desktop: 'block' }}
                            />
                        </Box>
                        <Box flex shrink maxWidth="full">
                            <Box
                                width="6xl"
                                mr="2xl"
                                noShrink
                                display={{ mobile: 'none', desktop: 'block' }}
                            />
                            <Box grow shrink>
                                {footerContents}
                            </Box>
                            <Box
                                width="6xl"
                                mr="2xl"
                                noShrink
                                display={{ mobile: 'none', desktop: 'block' }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box
                    display={{ mobile: 'none', desktop: 'flex' }}
                    column
                    center
                    width="50%"
                    justifyContent="center"
                    background="gray100"
                >
                    {previewContents}
                </Box>
            </Box>
        </ThemeProvider>
    )
}

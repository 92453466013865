import React from 'react'

import { ButtonProps } from '@chakra-ui/react'

import { Button, ConditionalWrapper, Tooltip } from 'v2/ui'

type Props = Omit<ButtonProps, 'children' | 'variant'> & {
    icon?: string
    label?: string
    buttonSize?: string
    variant?: string
    children?: React.ReactNode
    isDisabled?: boolean
    handleClick: () => unknown
}
export const DeleteFieldButton: React.FC<Props> = ({ isDisabled, handleClick, ...props }) => {
    return (
        <ConditionalWrapper
            condition={isDisabled}
            wrapper={(children: any) => (
                <Tooltip
                    placement="bottom"
                    label="This is the display name field and cannot be deleted."
                >
                    {children}
                </Tooltip>
            )}
        >
            <Button {...props} isDisabled={isDisabled} onClick={handleClick} />
        </ConditionalWrapper>
    )
}

import { FunctionArgument, Keyword } from 'features/formulas/formulaTypes'
import { StackerNonOperatorFormulaSyntaxTree } from 'features/formulas/parser/formulaParserTypes'

export const FORMULA_EDITOR_PLACEHOLDER = ['"Welcome " & {Name} & "!"']

const ARRAY_ARGUMENT: FunctionArgument = {
    name: 'arguments',
    placeholder: 'add value',
    type: 'number',
    allowFormula: true,
    isArray: true,
    width: '100%',
}

const ARRAY_OF_NUMBERS_ARGUMENT: FunctionArgument = { ...ARRAY_ARGUMENT, type: 'number' }

const NUMBER_ARGUMENT: FunctionArgument = {
    name: 'argument',
    type: 'number',
    placeholder: 'n',
    allowFormula: true,
}

const BOOLEAN_ARGUMENT: FunctionArgument = {
    name: 'argument',
    type: 'checkbox',
    placeholder: 'value',
    allowFormula: true,
    minWidth: '4rem',
    width: 'auto',
}

const DATE_ARGUMENT: FunctionArgument = {
    name: 'argument',
    type: 'datetime',
    allowFormula: true,
    placeholder: 'date',
    minWidth: '4rem',
    width: 'auto',
}
const STRING_ARGUMENT: FunctionArgument = {
    name: 'argument',
    type: 'string',
    placeholder: 'value',
    allowFormula: true,
    width: '100%',
}

const DATE_UNITS_ARGUMENT: FunctionArgument = {
    name: 'units',
    type: 'dropdown',
    width: '4rem',
    typeOptions: {
        options: [
            { id: 'year', label: 'years' },
            { id: 'month', label: 'months' },
            { id: 'week', label: 'weeks' },
            { id: 'day', label: 'days' },
            { id: 'hour', label: 'hours' },
            { id: 'minute', label: 'minutes' },
        ],
    },
}

type KeywordMap = Readonly<{
    [F in StackerNonOperatorFormulaSyntaxTree as F['function']]: Keyword
}>

export const keywordMap: Omit<KeywordMap, 'ARRAY' | 'DOCUMENT'> = {
    ABS: {
        name: 'absolute',
        insertText: 'ABS( )',
        syntax: 'ABS(number)',
        examples: ['ABS({Satisfaction Score} - {Target Score})'],
        helpText: 'Returns the absolute value.',
        returnType: 'number',
        displayTemplate: ['Abs of', NUMBER_ARGUMENT],
    },
    AND_FUN: {
        name: 'and',
        insertText: 'AND( , )',
        syntax: 'AND, AND(expression, [exp2, ...])',
        examples: ['AND({Invoice Paid}, {Order Delivered})'],
        helpText: 'Returns true if all the arguments are true, returns false otherwise.',
        returnType: 'checkbox',
        displayTemplate: ['All true ', { ...ARRAY_ARGUMENT, type: 'checkbox' }],
    },
    AVERAGE: {
        name: 'average',
        insertText: 'AVERAGE( , )',
        syntax: 'AVERAGE(number1, [number2, ...])',
        examples: ['AVERAGE({Q1 Revenue},{Q2 Revenue})'],
        helpText: 'Returns the average of the numbers.',
        returnType: 'number',
        displayTemplate: ['Avg of', ARRAY_OF_NUMBERS_ARGUMENT],
    },
    CEILING: {
        name: 'ceiling',
        insertText: 'CEILING( , )',
        syntax: 'CEILING(number, precision)',
        examples: ['CEILING(1.01) => 2', 'CEILING(1.05, 0.1) => 1.1'],
        helpText:
            'Returns the nearest number greater than or equal to the value, to a number of decimal places as specified by precision.',
        returnType: 'number',
        displayTemplate: ['Ceiling of', NUMBER_ARGUMENT],
    },
    CONCAT: {
        name: 'concat',
        insertText: 'CONCAT( , )',
        syntax: 'CONCAT(string1, [string2, ..])',
        examples: ['CONCAT({First Name}, " ", {Last Name})'],
        helpText: 'Joins together different arguments.',
        returnType: 'string',
        displayTemplate: [
            'Join',
            {
                ...ARRAY_ARGUMENT,
                type: 'string',
            },
        ],
    },
    CREATED_AT: {
        name: 'created_at',
        insertText: 'CREATED_AT()',
        syntax: 'CREATED_AT()',
        examples: ['CREATED_AT()'],
        returnType: 'datetime',
        helpText: 'Returns the date and time when the record was created.',
        recordFunction: true,
    },
    CREATED_BY: {
        name: 'created_by',
        insertText: 'CREATED_BY()',
        syntax: 'CREATED_BY()',
        examples: ['CREATED_BY()'],
        helpText: 'Returns the name of the person who created the record.',
        recordFunction: true,
    },
    DATEADD: {
        name: 'date_add',
        insertText: 'DATEADD( , , )',
        syntax: 'DATEADD(date, number, unit)',
        examples: ['DATEADD({Order Date}, 7, "day")'],
        helpText:
            'Adds specified units to a date or datetime. Unit accepts "year", "month", "week", and "day".',
        returnType: 'datetime',
        displayTemplate: [
            { ...DATE_ARGUMENT, name: 'date' },
            [
                ' + ',
                { ...NUMBER_ARGUMENT, name: 'number' },
                {
                    name: 'units',
                    type: 'dropdown',
                    width: '4rem',
                    typeOptions: {
                        options: [
                            { id: 'year', label: 'years' },
                            { id: 'month', label: 'months' },
                            { id: 'week', label: 'weeks' },
                            { id: 'day', label: 'days' },
                        ],
                    },
                },
            ],
        ],
    },
    DATEDIF: {
        name: 'datedif',
        insertText: 'DATEDIF( ,  , )',
        syntax: 'DATEDIF(date1, date2, units)',
        examples: ['DATEDIF({Meeting Time}, NOW(), "hour")'],
        helpText:
            'Returns the difference between datetimes in the unit specified. Unit accepts "year", "month", "day", "week", "hour", "minute".',
        returnType: 'number',
        displayTemplate: [
            'Diff between',
            { ...DATE_ARGUMENT, name: 'date_1' },
            'and',
            { ...DATE_ARGUMENT, name: 'date_2' },
            'in',
            DATE_UNITS_ARGUMENT,
        ],
    },
    DATESTR: {
        name: 'date_str',
        insertText: 'DATESTR( )',
        syntax: 'DATESTR(date)',
        examples: ['DATESTR({Birthday})'],
        helpText: 'Formats a date or datetime into a string (YYYY-MM-DD or YYYY-MM-DD HH:mm:SS).',
        returnType: 'string',
        displayTemplate: ['Format', { ...DATE_ARGUMENT, name: 'datetime' }],
    },
    DAY: {
        name: 'day',
        insertText: `DAY( )`,
        syntax: 'DAY(date)',
        examples: ['DAY({Birthday})'],
        helpText: 'Returns the day of a date or datetime field as a number between 1 and 31.',
        returnType: 'number',
        displayTemplate: ['Day of', { ...DATE_ARGUMENT, name: 'datetime' }],
    },
    FIND: {
        name: 'find',
        insertText: 'FIND( , )',
        syntax: 'FIND(substring, string, [start_position])',
        examples: ['FIND("Stacker", {Notes})'],
        helpText:
            'Returns the position of a substring in a string, starting from the start_position (default 1).',
        returnType: 'number',
        displayTemplate: [
            'Find',
            { ...STRING_ARGUMENT, name: 'substring' },
            'in',
            { ...STRING_ARGUMENT, name: 'string' },
            'starting at',
            { ...NUMBER_ARGUMENT, name: 'start_position' },
        ],
    },
    FLOOR: {
        name: 'floor',
        insertText: 'FLOOR( , )',
        syntax: 'FLOOR(number, precision)',
        examples: ['FLOOR(1.99) => 1', 'FLOOR(1.99, 0.1) => 1.9'],
        helpText:
            'Returns the nearest number less than or equal to the value, to a number of decimal places as specified by precision.',
        returnType: 'number',
        displayTemplate: ['Floor of', NUMBER_ARGUMENT],
    },
    FORMAT_NUMBER: {
        name: 'format_number',
        insertText: 'FORMAT_NUMBER( )',
        syntax: 'FORMAT_NUMBER(number)',
        examples: ['FORMAT_NUMBER(1234567.89)'],
        helpText: 'Converts a number into a string, using commas as thousands separators.',
        returnType: 'string',
        displayTemplate: ['Format', NUMBER_ARGUMENT],
    },
    HOUR: {
        name: 'hour',
        insertText: `HOUR( )`,
        syntax: 'HOUR(date)',
        examples: ['HOUR({Meeting Time})'],
        helpText: 'Returns the hour of a date or datetime field as a number between 0 and 23.',
        returnType: 'number',
        displayTemplate: ['Hour of', { ...DATE_ARGUMENT, name: 'datetime' }],
    },
    IF: {
        name: 'if',
        insertText: 'IF( , , )',
        syntax: 'IF(expression, ifTrue, ifFalse)',
        examples: ['IF({Order Delivered}, "Delivered", "Pending")'],
        helpText:
            'Returns ifTrue if the logical argument is true, otherwise it returns ifFalse. Can also be used to make nested IF statements.',
        displayTemplate: [
            'If',
            // condition should be type undefined, as the formula will
            // try to coerce the value to boolean. This allows implicit
            // "is empty" kind of checks, etc.
            { name: 'condition', type: undefined, width: '100%', allowFormula: true },
            'then',
            {
                name: 'then',
                type: undefined,
                width: '100%',
                allowFormula: true,
                useOuterReturnType: true,
            },
            'otherwise',
            {
                name: 'else',
                type: undefined,
                width: '100%',
                allowFormula: true,
                useOuterReturnType: true,
            },
        ],
    },

    INT: {
        name: 'int',
        insertText: 'INT( )',
        syntax: 'INT(number)',
        examples: ['INT({Unit Price})', 'INT(1.99) => 1', 'INT(-1.99) => -2'],
        helpText: 'Returns the greatest integer that is less than or equal to the number.',

        returnType: 'number',
        displayTemplate: ['Int ', NUMBER_ARGUMENT],
    },
    IS_AFTER: {
        name: 'is_after',
        insertText: 'IS_AFTER( )',
        syntax: 'IS_AFTER(date1, date2)',
        examples: ['IS_AFTER({Delivery Date}, {Target Delivery Date})'],
        helpText: 'Determines if date1 is later than date2. ',
        returnType: 'checkbox',
        displayTemplate: [
            { ...DATE_ARGUMENT, name: 'date_1' },
            ' is after ',
            { ...DATE_ARGUMENT, name: 'date_2' },
        ],
    },
    IS_BEFORE: {
        name: 'is_before',
        insertText: 'IS_BEFORE( )',
        syntax: 'IS_BEFORE(date1, date2)',
        examples: ['IS_BEFORE({Delivery Date}, {Target Delivery Date})'],
        helpText: 'Determines if date1 is before date2.',
        returnType: 'checkbox',
        displayTemplate: [
            { ...DATE_ARGUMENT, name: 'date_1' },
            ' is before ',
            { ...DATE_ARGUMENT, name: 'date_2' },
        ],
    },
    IS_BLANK: {
        name: 'is_blank',
        insertText: 'IS_BLANK( )',
        syntax: 'IS_BLANK(any)',
        examples: ['IS_BLANK({Selection})', 'IS_BLANK({Deadline})', 'IS_BLANK({Special Notes})'],
        helpText: 'Returns true if the argument is null or an empty string.',
        returnType: 'checkbox',
        displayTemplate: [STRING_ARGUMENT, 'is blank or null'],
    },
    IS_SAME: {
        name: 'is_same',
        insertText: 'IS_SAME( )',
        syntax: 'IS_SAME(date1, date2, [unit])',
        examples: [
            'IS_SAME({Delivery Date}, TODAY(), "month")',
            'IS_SAME({First Appointment Date}, {Next Appointment Date})',
        ],
        helpText:
            'Compares two dates up to a unit and determines whether they are identical. Unit accepts "year", "month", "day", "hour", "minute", "exact" (compares all units).',

        returnType: 'checkbox',
        displayTemplate: [
            { ...DATE_ARGUMENT, name: 'date_1' },
            ' and ',
            { ...DATE_ARGUMENT, name: 'date_2' },
            ' are the same',
            {
                name: 'units',
                type: 'dropdown',
                width: '4rem',
                typeOptions: {
                    options: [
                        { id: 'year', label: 'year' },
                        { id: 'month', label: 'month' },
                        { id: 'week', label: 'week' },
                        { id: 'day', label: 'day' },
                        { id: 'hour', label: 'hour' },
                        { id: 'minute', label: 'minute' },
                    ],
                },
            },
        ],
    },
    LAST_UPDATED_AT: {
        name: 'last_updated_at',
        insertText: 'LAST_UPDATED_AT()',
        syntax: 'LAST_UPDATED_AT()',
        examples: ['LAST_UPDATED_AT()'],
        helpText: 'Returns the date and time when the record was last updated.',
        returnType: 'datetime',
        recordFunction: true,
    },
    LAST_UPDATED_BY: {
        name: 'last_updated_by',
        insertText: 'LAST_UPDATED_BY()',
        syntax: 'LAST_UPDATED_BY()',
        examples: ['LAST_UPDATED_BY()'],
        helpText: 'Returns the name of the person who last modified the record.',
        recordFunction: true,
    },
    LEFT: {
        name: 'left',
        insertText: 'LEFT( , )',
        syntax: 'LEFT(string, howMany)',
        examples: ['LEFT({Order Code}, 3)'],
        helpText: 'Extracts a number of characters from the end of a string.',
        returnType: 'string',
        displayTemplate: [
            'First ',
            { ...NUMBER_ARGUMENT, name: 'count' },
            ' chars of ',
            { ...STRING_ARGUMENT, name: 'string' },
        ],
    },
    LEN: {
        name: 'length',
        insertText: 'LEN( )',
        syntax: 'LEN(string)',
        examples: ['LEN({Name})'],
        helpText: 'Returns the length of a string.',
        returnType: 'number',
        displayTemplate: ['Length of ', STRING_ARGUMENT],
    },
    LOG: {
        name: 'log',
        insertText: 'LOG( )',
        syntax: 'LOG(number, [base])',
        examples: ['LOG({Number})', 'LOG({Number}, 2)'],
        helpText: 'Returns the logarithm of a number to the base specified.',
        returnType: 'number',
        displayTemplate: [
            { ...NUMBER_ARGUMENT, name: 'number' },
            'log base',
            { ...NUMBER_ARGUMENT, name: 'base' },
        ],
    },
    LOWER: {
        name: 'lower',
        insertText: 'LOWER( )',
        syntax: 'LOWER(string)',
        examples: ['LOWER({Customer Email})'],
        helpText: 'Makes a string lowercase.',
        returnType: 'string',
        displayTemplate: ['Lowercase of ', STRING_ARGUMENT],
    },
    MAX: {
        name: 'max',
        insertText: 'MAX( , )',
        syntax: 'MAX(number, number...)',
        examples: ['MAX({Delivery Fee}, {Minimum Fee})'],
        helpText: 'Returns the largest of the given numbers.',
        returnType: 'number',
        displayTemplate: ['Max of', ARRAY_OF_NUMBERS_ARGUMENT],
    },
    MOD: {
        name: 'mod',
        insertText: 'MOD( , )',
        syntax: 'MOD(value, divisor)',
        examples: ['MOD({Cycle Number}, 5)'],
        helpText: 'Returns the remainder after dividing the first argument by the second.',
        returnType: 'number',
        displayTemplate: [
            { ...NUMBER_ARGUMENT, name: 'dividend' },
            ' modulo ',
            { ...NUMBER_ARGUMENT, name: 'divisor' },
        ],
    },
    MIN: {
        name: 'min',
        insertText: 'MIN( , )',
        syntax: 'MIN(number, number...)',
        examples: ['MIN({Delivery Fee}, {Maximum Fee})'],
        helpText: 'Returns the smallest of the given numbers.',
        returnType: 'number',
        displayTemplate: ['Min of', ARRAY_OF_NUMBERS_ARGUMENT],
    },
    MINUTE: {
        name: 'minute',
        insertText: `MINUTE( )`,
        syntax: 'MINUTE(date)',
        examples: ['MINUTE({Meeting Time})'],
        helpText: 'Returns the minute of a date or datetime field as a number between 0 and 59.',
        returnType: 'number',
        displayTemplate: ['Minute of', { ...DATE_ARGUMENT, name: 'datetime' }],
    },
    MONTH: {
        name: 'month',
        insertText: `MONTH( )`,
        syntax: 'MONTH(date)',
        examples: ['MONTH({Birthday})'],
        helpText: 'Returns the month of a date or datetime field as a number between 1 and 12.',
        returnType: 'number',
        displayTemplate: ['Month of', { ...DATE_ARGUMENT, name: 'datetime' }],
    },
    NOT_FUN: {
        name: 'not',
        insertText: 'NOT( )',
        syntax: 'NOT(expression)',
        examples: ['NOT({Invoice Paid})'],
        helpText: 'Reverses a true to false.',
        returnType: 'checkbox',
        displayTemplate: ['Not ', { ...BOOLEAN_ARGUMENT, name: 'arguments' }],
    },
    NOW: {
        name: 'now',
        insertText: `NOW()`,
        syntax: 'NOW()',
        examples: ['NOW()'],
        returnType: 'datetime',
        helpText: 'Returns the current date and time.',
    },
    OR_FUN: {
        name: 'or',
        insertText: 'OR( , )',
        syntax: 'OR, OR(expression, [exp2, ...])',
        examples: ['OR({Invoice Paid}, {Order Delivered})'],
        helpText: 'Returns true if any one of the arguments is true.',
        returnType: 'checkbox',
        displayTemplate: ['Any ', { ...ARRAY_ARGUMENT, type: 'checkbox' }],
    },
    POWER: {
        name: 'power',
        insertText: 'POWER( , )',
        syntax: 'POWER(number, power)',
        examples: ['POWER({Unit Price}, 2)'],
        helpText: 'Returns the number raised to the power of power.',
        returnType: 'number',
        displayTemplate: [
            { ...NUMBER_ARGUMENT, name: 'base' },
            ' to the power of',
            { ...NUMBER_ARGUMENT, name: 'exponent' },
        ],
    },
    RECORD_ID: {
        name: 'record_id',
        insertText: 'RECORD_ID()',
        syntax: 'RECORD_ID()',
        examples: ['RECORD_ID()'],
        helpText: 'Returns the unique Stacker record id.',
        recordFunction: true,
    },
    REGEX_EXTRACT: {
        name: 'regex_extract',
        insertText: 'REGEX_EXTRACT( , )',
        syntax: 'REGEX_EXTRACT(string, regex)',
        examples: ['REGEX_EXTRACT({Order Code}, "[0-9]+")'],
        helpText: 'Returns the first substring that matches a regular expression.',
        returnType: 'string',
        displayTemplate: [
            'First match in',
            { ...STRING_ARGUMENT, name: 'string' },
            'of',
            { ...STRING_ARGUMENT, name: 'regex', placeholder: 'regex' },
        ],
    },
    REGEX_MATCH: {
        name: 'regex_match',
        insertText: 'REGEX_MATCH( , )',
        syntax: 'REGEX_MATCH(string, regex)',
        examples: ['REGEX_MATCH({Order Code}, "ORD.*")'],
        helpText: 'Returns whether the input text matches a regular expression.',
        returnType: 'checkbox',

        displayTemplate: [
            'Does',
            { ...STRING_ARGUMENT, name: 'string' },
            'match',
            { ...STRING_ARGUMENT, name: 'regex', placeholder: 'regex' },
        ],
    },
    REGEX_REPLACE: {
        name: 'regex_replace',
        insertText: 'REGEX_REPLACE( , , )',
        syntax: 'REGEX_REPLACE(string, regex, replacement)',
        examples: ['REGEX_REPLACE({Price}, "USD", "$")'],
        helpText: 'Substitutes all matching substrings with a replacement string value.',
        returnType: 'string',
        displayTemplate: [
            'In',
            { ...STRING_ARGUMENT, name: 'string' },
            'relace',
            { ...STRING_ARGUMENT, name: 'regex', placeholder: 'regex' },
            'with',
            { ...STRING_ARGUMENT, name: 'replacement' },
        ],
    },
    RIGHT: {
        name: 'right',
        insertText: 'RIGHT( , )',
        syntax: 'RIGHT(string, howMany)',
        examples: ['RIGHT({Order Code}, 5)'],
        helpText: 'Extracts a number of characters from the end of a string.',
        returnType: 'string',
        displayTemplate: [
            'Last',
            { ...NUMBER_ARGUMENT, name: 'count' },
            'chars of',
            { ...STRING_ARGUMENT, name: 'string' },
        ],
    },
    ROUND: {
        name: 'round',
        insertText: 'ROUND( , )',
        syntax: 'ROUND(number, precision)',
        examples: ['ROUND({Unit Price}, 0)'],
        helpText:
            'Rounds to a number of decimal places as specified by precision. E.g. "0", "1", etc.',
        returnType: 'number',
        displayTemplate: [
            'Round',
            NUMBER_ARGUMENT,
            'to',
            { ...NUMBER_ARGUMENT, name: 'precision' },
        ],
    },
    ROUNDDOWN: {
        name: 'rounddown',
        insertText: 'ROUNDDOWN( , )',
        syntax: 'ROUNDDOWN(number, precision)',
        examples: [
            'ROUNDDOWN({Unit Price}, 0)',
            'ROUNDDOWN(3.5, 0) => 3',
            'ROUNDDOWN(3.8, 0) => 3',
            'ROUNDDOWN(-2.6, 0) => -2',
        ],
        helpText:
            'Rounds the value to the number of decimal places given by "precision," always rounding down (towards zero). E.g. "0", "1", etc.',
        returnType: 'number',
        displayTemplate: [
            'Round',
            NUMBER_ARGUMENT,
            ' up to',
            { ...NUMBER_ARGUMENT, name: 'precision' },
        ],
    },
    ROUNDUP: {
        name: 'roundup',
        insertText: 'ROUNDUP( , )',
        syntax: 'ROUNDUP(number, precision)',
        examples: [
            'ROUNDUP({Unit Price}, 0)',
            'ROUNDUP(3.5, 0) => 4',
            'ROUNDUP(3.4, 0) => 4',
            'ROUNDUP(-2.1, 0) => -3',
        ],
        helpText:
            'Rounds the value to the number of decimal places given by "precision," always rounding up (away from zero). E.g. "0", "1", etc.',
        returnType: 'number',
        displayTemplate: [
            'Round',
            NUMBER_ARGUMENT,
            'down to',
            { ...NUMBER_ARGUMENT, name: 'precision' },
        ],
    },
    SEARCH: {
        name: 'search',
        insertText: 'SEARCH( , )',
        syntax: 'SEARCH(substring, string, [start_position])',
        examples: ['SEARCH("Stacker", {Notes})'],
        helpText:
            'Returns the position of a substring in a string, starting from the start_position (default 1).',
        returnType: 'number',
        displayTemplate: [
            'Find',
            { ...STRING_ARGUMENT, name: 'substring' },
            'in',
            { ...STRING_ARGUMENT, name: 'string' },
            'starting at',
            { ...NUMBER_ARGUMENT, name: 'start_position' },
        ],
    },
    SUM: {
        name: 'sum',
        insertText: 'SUM( , )',
        syntax: 'SUM(number, [number2, ...])',
        examples: ['SUM({Q1 Revenue},{Q2 Revenue})'],
        helpText: 'Sums together numbers.',
        returnType: 'number',
        displayTemplate: ['Sum of', ARRAY_OF_NUMBERS_ARGUMENT],
    },
    TODAY: {
        name: 'today',
        insertText: `TODAY()`,
        syntax: 'TODAY()',
        examples: ['TODAY()'],
        returnType: 'date',
        helpText: 'Returns the current date, but not the current time.',
    },
    TRIM: {
        name: 'trim',
        insertText: 'TRIM( )',
        syntax: 'TRIM(string)',
        examples: ['TRIM({Customer Email})'],
        helpText: 'Removes whitespace at the beginning and end of string.',
        returnType: 'string',
        displayTemplate: ['Trim ', STRING_ARGUMENT],
    },
    UPPER: {
        name: 'upper',
        insertText: 'UPPER( )',
        syntax: 'UPPER(string)',
        examples: ['UPPER({Last Name})'],
        helpText: 'Makes string uppercase.',
        returnType: 'string',
        displayTemplate: ['Uppercase of ', STRING_ARGUMENT],
    },
    VALUE: {
        name: 'value',
        insertText: 'VALUE( )',
        syntax: 'VALUE(string)',
        examples: [
            'VALUE({Quoted Price})',
            'VALUE("$1000") => 1000',
            'VALUE("25 days") => 25',
            'VALUE("10lbs") => 10',
        ],
        helpText: 'Converts text string to a number.',
        returnType: 'number',
        displayTemplate: ['Value of ', STRING_ARGUMENT],
    },
    YEAR: {
        name: 'year',
        insertText: `YEAR( )`,
        syntax: 'YEAR(date)',
        examples: ['YEAR({Birthday})'],
        helpText: 'Returns the four-digit year of a datetime.',
        returnType: 'number',
        displayTemplate: ['Year of', { ...DATE_ARGUMENT, name: 'datetime' }],
    },
    // Please keep this sorted alphabetically :-)
}

export const availableKeywords: Keyword[] = Object.values(keywordMap)

// @ts-strict-ignore
import React from 'react'

import { BoxProps } from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Box, ConditionalWrapper, Icon, Tooltip } from 'v2/ui'
import type { IconProps } from 'v2/ui/components/Icon'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

export const Wrapper = styled(Box)<
    BoxProps & {
        isActive: boolean
        disabled: boolean
        activeStyle: {
            color: string
            backgroundColor: string
        }
    }
>`
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 36px;
    height: 36px;
    border-radius: 5px;

    background-color: ${(props) =>
        props.isActive ? props.activeStyle.backgroundColor : 'transparent'};

    color: ${(props) => {
        if (props.disabled) {
            return colors.userInterface.neutral[800]
        }

        if (props.isActive) {
            return props.activeStyle.color
        }

        return colors.userInterface.accent[1000]
    }};

    transition:
        background-color ease 0.25s,
        color ease 0.25s;

    ${(props) =>
        !props.disabled &&
        !props.isActive &&
        css`
            &:hover {
                background-color: ${colors.userInterface.accent[200]};
                color: ${colors.userInterface.accent[1000]};
            }
        `}
`

export type TrayButtonBaseProps = IconProps & {
    tooltip?: string
    isActive?: boolean
    disabled?: boolean
    iconActive?: string
    activeStyle?: {
        backgroundColor: string
        color: string
    }
}

export const TrayButtonBase: React.VFC<TrayButtonBaseProps> = ({
    tooltip,
    isActive = false,
    disabled = false,
    iconActive,
    activeStyle = {
        backgroundColor: colors.userInterface.accent[1000],
        color: colors.userInterface.accent[100],
    },
    onClick,
    icon,
    ...props
}) => (
    <ConditionalWrapper
        condition={!!tooltip}
        wrapper={(children) => (
            <Tooltip
                placement="left"
                label={tooltip}
                arrow={false}
                showDelay={50}
                animation="shift"
                animateFill={false}
                popperOptions={{ positionFixed: true }}
                // @ts-ignore
                theme="admintray"
            >
                {children}
            </Tooltip>
        )}
    >
        <Wrapper
            role="button"
            aria-label={tooltip}
            isActive={isActive}
            disabled={disabled}
            aria-disabled={disabled}
            onClick={!disabled ? onClick : undefined}
            activeStyle={activeStyle}
            {...props}
        >
            <Icon icon={isActive && !!iconActive ? iconActive : icon} />
        </Wrapper>
    </ConditionalWrapper>
)

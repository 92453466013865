import React from 'react'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
    DropdownItemLink,
    DropdownTitle,
    DropdownTrigger,
} from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

import { useNavigationAllAppsItemState } from './hooks/useNavigationAllAppsItemState'
import { AppIcon } from './AppIcon'
import { NavigationItem } from './NavigationItem'

import {
    AppIconWrapperStyle,
    WorkspaceNavigationAllAppsItemIconStyle,
    WorkspaceNavigationAppItemLabelStyles,
    WorkspaceNavigationAppItemStyles,
} from './Navigation.css'

const APP_NAME_MAX_LENGTH = 20

type NavigationAllAppsItemProps = {
    isCollapsed?: boolean
}

export const NavigationAllAppsItem: React.FC<NavigationAllAppsItemProps> = ({ isCollapsed }) => {
    const { spaces, searchQuery, setSearchQuery, isEmpty } = useNavigationAllAppsItemState()

    if (spaces.length < 1) return null

    return (
        <Dropdown modal={false}>
            <DropdownTrigger asChild>
                <NavigationItem
                    aria-label="All apps"
                    label={
                        <Body
                            size="m"
                            weight="bold"
                            trim
                            className={WorkspaceNavigationAppItemLabelStyles.styleFunction({
                                isActive: false,
                            })}
                        >
                            All apps
                        </Body>
                    }
                    leftSlotContent={
                        <Box className={AppIconWrapperStyle}>
                            <Icon family="lucide" name="LayoutGrid" color="icon" size="m" />
                        </Box>
                    }
                    isCollapsed={isCollapsed}
                    className={WorkspaceNavigationAppItemStyles.styleFunction({
                        isCollapsed,
                        isActive: false,
                    })}
                />
            </DropdownTrigger>
            <DropdownContent
                align="start"
                side="right"
                sideOffset={12}
                style={{ width: '300px' }}
                head={<DropdownHeadSearch />}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            >
                {isEmpty && (
                    <DropdownItem
                        label={
                            <Body size={{ mobile: 's', tablet: 'm' }} color="textHelper">
                                No apps to show
                            </Body>
                        }
                        disabled
                    />
                )}
                {spaces.map((space) => (
                    <React.Fragment key={space.id}>
                        <DropdownTitle
                            style={{
                                fontStyle: space.id === '' ? 'italic' : 'normal',
                            }}
                        >
                            {space.name}
                        </DropdownTitle>
                        {space.apps.map((app) => (
                            <DropdownItemLink
                                key={app.id}
                                to={app.url}
                                label={
                                    <Body
                                        size="m"
                                        weight="medium"
                                        maxLength={APP_NAME_MAX_LENGTH}
                                        style={{ lineHeight: 1 }}
                                    >
                                        {app.name}
                                    </Body>
                                }
                                leftSlotContent={() => (
                                    <AppIcon
                                        app={app}
                                        className={WorkspaceNavigationAllAppsItemIconStyle}
                                    />
                                )}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </DropdownContent>
        </Dropdown>
    )
}

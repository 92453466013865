import { useMemo } from 'react'

import { NavigationApp } from 'features/navigation/types'

type UseAppIconStateProps = {
    app: NavigationApp
}

export function useAppIconState({ app }: UseAppIconStateProps) {
    const { name } = app

    const initial = name.slice(0, 1).toUpperCase()

    return useMemo(
        () => ({
            initial,
        }),
        [initial]
    )
}

import React from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { canDeleteRecords } from 'data/utils/getObjectRecordRestrictions'
import FieldPermissions from 'features/AppSettings/Permissions/FieldPermissions'
import { ObjectFieldsFilterV4 as Filters } from 'features/records/components/RecordFilters'

import { Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import FieldErrorMessage from 'ui/deprecated/forms/FieldErrorMessage'
import { FormField } from 'ui/deprecated/forms/FormField'
import RadioWithContentForm from 'ui/deprecated/forms/RadioWithContentForm'
import InputForm from 'ui/deprecated/forms/unstyled/InputForm'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { PermissionRuleLabel } from './PermissionRuleLabel'
import { PermissionRuleRoleContent } from './PermissionRuleRoleContent'
import { RecordDeletion } from './RecordDeletion'
import { RecordPermissionFields } from './RecordPermissionFields'

export function PermissionsRuleConfigContent({
    object,
    userObject,
    objects,
    roles,
}: {
    object: ObjectDto
    userObject: ObjectDto
    objects: ObjectDto[]
    roles: RoleDto[]
}) {
    const form = useFormContext()

    // No ui, but this is used to store data
    useController({
        control: form.control,
        name: 'fieldPermissions',
    })

    return (
        <>
            <div style={{ marginTop: 0 }}>
                <PermissionRuleLabel text="Name" />
                {/* @ts-expect-error */}
                <InputForm name="name" variant="settings" placeholder="Rule name" required />
                <FieldErrorMessage name="name" />
            </div>

            <div style={{ marginTop: 24 }}>
                <PermissionRuleLabel text="Record Filters" />
                <Text size="small" color={V4DesignSystem.colors.gray[600]}>
                    Limit the records your users can access.
                </Text>

                <FormField
                    as={RadioWithContentForm}
                    controlled
                    name="allRecords"
                    options={[
                        {
                            label: 'All records',
                            text: 'Allow users to access all records',
                            value: true,
                        },
                        {
                            label: 'Some records',
                            text: 'Allow users to access only records that meet certain conditions',
                            value: false,
                            content: (
                                <>
                                    <PermissionRuleLabel
                                        text="Ownership filter"
                                        className={
                                            ONBOARDING_CLASSES.APP_SETTINGS_PERMISSIONS_OWNERSHIP_FILTER
                                        }
                                    />
                                    <Text size="small" color={V4DesignSystem.colors.gray[600]}>
                                        Give each user access only to the records linked to them.
                                    </Text>
                                    <div style={{ margin: '0.5rem 0 1rem', maxWidth: '500px' }}>
                                        <FormField
                                            as={RecordPermissionFields}
                                            name="ownershipFilter"
                                            objects={objects}
                                            object={object}
                                            userObject={userObject}
                                            //@ts-ignore
                                            registerOptions={{
                                                validate: (filterObject) => {
                                                    if (filterObject.active) {
                                                        return !!(
                                                            filterObject.objectFieldSelected &&
                                                            filterObject.userFieldSelected
                                                        )
                                                    }
                                                    return true
                                                },
                                            }}
                                            controlled
                                            //@ts-ignore
                                            errorMessages={{
                                                validate:
                                                    'This filter is incomplete. Check details or remove the filter',
                                            }}
                                        />
                                    </div>
                                    <PermissionRuleLabel
                                        text="Data filter"
                                        className={
                                            ONBOARDING_CLASSES.APP_SETTINGS_PERMISSIONS_OWNERSHIP_FILTER
                                        }
                                    />
                                    <Text size="small" color={V4DesignSystem.colors.gray[600]}>
                                        Set record access based on static conditions
                                    </Text>
                                    <div style={{ margin: '0.5rem 0 0', maxWidth: '500px' }}>
                                        <FormField
                                            as={Filters}
                                            name="recordFilter"
                                            object={object}
                                            fields={object.fields}
                                            controlled
                                            hideCurrentUserOption
                                            hideTheRecordFilter
                                            color="white"
                                            addButtonVariant="adminSecondary"
                                            registerOptions={{
                                                validate: (filters) => {
                                                    return !filters.some((f: any) => f.incomplete)
                                                },
                                            }}
                                            includeIncompleteFilters={true}
                                            errorMessages={{
                                                validate:
                                                    'This filter is incomplete. Check details or remove the filter',
                                            }}
                                        />
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </div>

            <PermissionRuleRoleContent roles={roles} />

            {canDeleteRecords(object) && (
                <div style={{ marginTop: 24 }}>
                    <PermissionRuleLabel text="Record Deletion" />
                    <FormField as={RecordDeletion} name="recordPermissionDelete" controlled />
                </div>
            )}

            <div style={{ marginTop: 24 }}>
                <PermissionRuleLabel text="Field Access" />
                <Text size="small" color={V4DesignSystem.colors.gray[600]}>
                    Choose exactly which fields your users can read and modify.
                </Text>

                <FormField
                    as={FieldPermissions}
                    name="fieldPermissions"
                    controlled
                    object={object}
                />
            </div>
        </>
    )
}

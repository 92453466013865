import React from 'react'

import { Tooltip } from 'legacy/v1/ui/components/Tooltip'

export const withTooltip = (Component) => {
    return (props) => {
        const { tooltip, tooltipId, tooltipAlign, tooltipTextAlign } = props

        if (!tooltip) return <Component {...props} />

        return (
            <Tooltip
                tooltip={tooltip}
                id={tooltipId}
                align={tooltipAlign}
                textAlign={tooltipTextAlign}
            >
                <Component {...props} />
            </Tooltip>
        )
    }
}

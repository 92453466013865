import React, { FC, useContext } from 'react'

import { AggregationBlockContextProvider } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/AggregationBlockContext'
import { ViewLayoutContext } from 'v2/blocks/types'

import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { getModularHeaderOptionsFromListViewOptions } from 'features/views/ViewHeader/listViewHeaderUtils'
import {
    COLLAPSIBLE_CHARTS_EDITOR_ID,
    COLLAPSIBLE_METRICS_EDITOR_ID,
} from 'features/views/ViewHeader/ViewHeaderModular/constants'

import { ViewHeaderModularBlocksEditor } from './ViewHeaderModularBlocksEditor'

type Props = {
    viewLayoutContext?: ViewLayoutContext
    onChange: (patch: Partial<ListViewOptions>) => void
    listOptions: ListViewOptions | undefined
}

export const ViewHeaderModularEditor: FC<Props> = ({
    viewLayoutContext,
    listOptions,
    onChange,
}) => {
    const { activeEditor } = useContext(LayoutEditorContext)
    const isOpen =
        activeEditor?.id === COLLAPSIBLE_CHARTS_EDITOR_ID ||
        activeEditor?.id === COLLAPSIBLE_METRICS_EDITOR_ID

    const headerOptions = getModularHeaderOptionsFromListViewOptions(listOptions)

    return (
        <AggregationBlockContextProvider
            blockId="collapsible-header-area"
            setVisibleAttributes={() => undefined}
            removeLayoutEditorCloseOverride={() => undefined}
            setLayoutEditorCloseOverride={() => undefined}
        >
            <ViewHeaderModularBlocksEditor
                isOpen={isOpen}
                viewLayoutContext={viewLayoutContext}
                metrics={headerOptions.collapsibleWidgets}
                updatePageHeader={(collapsibleWidgetsType, collapsibleWidgets) =>
                    onChange({ collapsibleWidgetsType, collapsibleWidgets })
                }
                onClose={() => undefined}
            />
        </AggregationBlockContextProvider>
    )
}

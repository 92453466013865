import React, { memo, useEffect, useMemo } from 'react'

import { ChakraProvider } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'

import { getStackTheme } from 'app/Branding'
import { useAppContext } from 'app/useAppContext'
import theme1 from 'legacy/v1/ui/styleHelpers/stackerTheme'

import GlobalStyleV2 from 'v2/ui/theme/components/GlobalStyleV2'
import theme2 from 'v2/ui/theme/styles/default'

import { determineColorScheme, updateThemeValues } from 'ui/styling/helpers/ThemeInitializer'

import { useWorkspaceContext } from './WorkspaceContext'

const ContentWrapper = styled.div`
    height: 100dvh;
    overflow-y: auto;
`

const InnerGlobalStyle = ({ children }) => {
    const { selectedStack: stack, workspaceAccount } = useAppContext()
    const { workspaceZone } = useWorkspaceContext()

    // Use the workspace theme if we don't have a stack, but we are on a workspace domain.\
    // This supports branded auth pages on workspace domains.
    const workspaceTheme = workspaceZone?.brand_color
        ? { brandColor: workspaceZone?.brand_color }
        : workspaceAccount?.options?.theme
    const clientTheme = stack?._sid ? getStackTheme(stack) : workspaceTheme
    const mergedTheme = useMemo(() => {
        return {
            ...theme1,
            ...theme2(clientTheme),
        }
    }, [clientTheme])

    useEffect(() => {
        const baseColor = mergedTheme?.colors?.brandAppColors[500]
        const colorScheme = determineColorScheme(baseColor)

        if (colorScheme) {
            updateThemeValues({ colorScheme })
        } else {
            updateThemeValues({ appColor: baseColor })
        }
    }, [mergedTheme?.colors?.brandAppColors])

    // So that we can use css variables in stylesheets
    const globalCSSVars = css`
        :root {
            --primary-font-family: 'Proxima Nova', sans-serif;
            --primary-color-300: ${mergedTheme?.colors?.brandAppColors[300]};
            --primary-color-400: ${mergedTheme?.colors?.brandAppColors[400]};
            --primary-color-500: ${mergedTheme?.colors?.brandAppColors[500]};
            --primary-color-600: ${mergedTheme?.colors?.brandAppColors[600]};
        }
    `

    // The children are wrapped into the ContentWrapper that has its height matching the viewport one
    // with the vertical overflow on auto. This is done to prevent the body to be scrollbable and
    // having this ContentWrapper scrollbable. This way, the scrollbar is located underneath the admin
    // side tray.
    return (
        <ChakraProvider theme={mergedTheme}>
            <ContentWrapper id="contentWrapper">{children}</ContentWrapper>
            <Global styles={GlobalStyleV2} />
            <Global styles={globalCSSVars} />
        </ChakraProvider>
    )
}
export const GlobalStyle = memo(InnerGlobalStyle)

import React from 'react'

import { ConfirmationModal, Icon } from 'v2/ui'
import { useModalToggle } from 'v2/ui/utils/useModalToggle'

export const CONFIRM_MODAL_KEY = 'Confirm'

export const ConfirmModal = () => {
    const modal = useModalToggle(CONFIRM_MODAL_KEY)
    const { isOpen, toggle, data = {} } = modal
    const {} = data

    const confirmVariant =
        data?.confirmVariant || (data?.endUser ? 'endUserPrimary' : 'adminPrimary')
    const cancelVariant =
        data?.cancelVariant || (data?.endUser ? 'endUserSecondary' : 'adminSecondary')

    if (!data) return null

    const handleConfirm = () => {
        // If caller has specified a confirm handler, then call it now
        if (data.onConfirm) {
            return data.onConfirm(modal)

            // otherwise, just hide the modal
        } else {
            toggle()
        }
    }

    const handleCancel = () => {
        // If caller has specified a cancel handler, then call it now
        if (data.onCancel) {
            data.onCancel(modal)

            // otherwise, just hide the modal
        } else {
            toggle()
        }
    }

    const icon =
        typeof data.icon === 'string' ? (
            <Icon
                size="56px"
                icon={data.icon || 'warning'}
                mb={4}
                color="grey.200"
                display="inline-block"
            />
        ) : (
            data.icon
        )

    let customDetailsComponent
    if (typeof data.message === 'function') {
        customDetailsComponent = data.message(modal)
    } else if (typeof data.message !== 'string') {
        customDetailsComponent = data.message
    }
    const details = !customDetailsComponent ? data.message : undefined
    return (
        <ConfirmationModal
            isOpen={isOpen}
            zIndex={data.zIndex || 9999}
            customSize={data.customSize}
            customConfirm={data.confirmButtonText}
            customCancel={data.cancelButtonText}
            inProgress={data.isSaving}
            title={data.title}
            details={details}
            icon={icon}
            customDetailsComponent={customDetailsComponent}
            onConfirm={handleConfirm}
            onClose={handleCancel}
            confirmVariant={confirmVariant}
            cancelVariant={cancelVariant}
        />
    )
}

import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { getAvatarFromUserRecord } from 'features/views/attributes/hooks/useUserRecordLinksAvatars'
import { UserItem } from 'features/views/attributes/hooks/useUsersState'
import { useLookupRecords } from 'features/views/ListView/hooks/useLookupRecords'
import { AdvancedFilter } from 'features/views/ListView/ListHeader/Filters/Advanced/types'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { truncateText } from 'ui/helpers/utilities'

const MAX_ITEMS = 2
const DEBOUNCE_RATE = 200

type FilterOption = {
    label: string
    value: string
    avatar?: {
        imageUrl: string
        firstName: string
        lastName: string
        type: 'initial' | 'image'
    }
}

type UseAdvancedFilterValueRecordLinksStateOptions = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
    maxItemLength?: number
}

export function useAdvancedFilterValueRecordLinksState(
    options: UseAdvancedFilterValueRecordLinksStateOptions
) {
    const { filter, onChange, maxItemLength, onRemoveFilter } = options

    const operation = filter.options?.option

    const field = filter.field as FieldDto

    const [searchQuery, setSearchQuery] = useState('')
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
    const applyDebouncedSearchQuery = useDebounce(setDebouncedSearchQuery, DEBOUNCE_RATE)

    const handleChangeSearchQuery = useCallback(
        (query: string) => {
            setSearchQuery(query)
            applyDebouncedSearchQuery(query)
        },
        [applyDebouncedSearchQuery]
    )

    const filterValue = useDeepEqualsMemoValue(getValueFromFilter(filter))
    const { isError, records, isFetchingSlow, isFetching } = useLookupRecords({
        field,
        searchQuery: debouncedSearchQuery,
        alwaysShowValueRecords: true,
        valueRecordSids: filterValue,
    })

    let internalValue = filterValue
    if (isFetchingSlow) {
        internalValue = PLACEHOLDER_OPTIONS.map((option) => option.value)
    }
    const filterValueRef = useRef(internalValue)
    filterValueRef.current = internalValue

    const value = useMemo(() => new Set(internalValue), [internalValue])
    const valueRef = useRef(value)
    valueRef.current = value

    const supportsMultiValue = ['listIs', 'listIsnt', 'oneOf', 'noneOf'].includes(operation ?? '')
    const isSingle = !supportsMultiValue

    const onSetFilterValue = useCallback(
        (value: string, isEnabled: boolean) => {
            const defaultValue = supportsMultiValue ? [] : ''
            const filterValue = filterValueRef.current
            const existingValue = filterValue ?? defaultValue

            let newValue: AdvancedFilter['options']['value']
            if (isEnabled) {
                if (supportsMultiValue) {
                    newValue = [...existingValue, value]
                } else {
                    newValue = value
                }
            } else {
                if (supportsMultiValue) {
                    newValue = existingValue.filter((v) => v !== value)
                } else {
                    newValue = defaultValue
                }
            }

            // Remove filter when clearing the value.
            if (newValue.length < 1) {
                onRemoveFilter()
                return
            }

            onChange(newValue)
        },
        [onChange, onRemoveFilter, supportsMultiValue]
    )

    const usersObject = useStackerUsersObject()
    const filterOptions = isFetchingSlow
        ? PLACEHOLDER_OPTIONS
        : makeFilterOptions(records, usersObject, maxItemLength)

    const valueOptions = isFetchingSlow
        ? PLACEHOLDER_OPTIONS
        : getValueOptions(value, filterOptions)
    const overflowCount = internalValue.length - valueOptions.length

    const overflowLabel = overflowCount > 0 ? `+${overflowCount}` : ''

    const hasValue = value.size > 0
    const isLabelLoading = hasValue && (!records || isFetching)

    const objectSid = field.link_target_object_id
    const isUsersObject = objectSid === usersObject?._sid

    const usersValueOptions: UserItem[] = useMemo(() => {
        if (!isUsersObject) return []

        return valueOptions.map((r) => ({ ...r.avatar! }))
    }, [isUsersObject, valueOptions])

    const [isOpen, setIsOpen] = useState(false)
    useLayoutEffect(() => {
        if (isOpen) {
            // Make sure we set the pointer events to none. This can be reset
            // if this dropdown is opened after another dropdown.
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = 'none'
            }, 0)

            return () => clearTimeout(timer)
        } else {
            document.body.style.pointerEvents = 'auto'
        }
    }, [isOpen])

    // Focus input when filter is created.
    useEffect(() => {
        if (filter.isDraft) {
            setIsOpen(true)
        }
    }, [filter.isDraft])

    // Focus input when operation is changed, and the filter is empty.
    useEffectOnlyOnUpdate(() => {
        const isEmpty = !valueRef.current.size
        if (!isEmpty) return

        setIsOpen(true)
    }, [filter.options.option])

    return useMemo(
        () => ({
            value,
            onSetFilterValue,
            filterOptions,
            isError,
            isFetchingSlow,
            searchQuery,
            setSearchQuery: handleChangeSearchQuery,
            isSingle,
            valueOptions,
            overflowLabel,
            isLabelLoading,
            isUsersObject,
            usersValueOptions,
            isOpen,
            setIsOpen,
        }),
        [
            value,
            onSetFilterValue,
            filterOptions,
            isError,
            isFetchingSlow,
            searchQuery,
            handleChangeSearchQuery,
            isSingle,
            valueOptions,
            overflowLabel,
            isLabelLoading,
            isUsersObject,
            usersValueOptions,
            isOpen,
        ]
    )
}

function makeFilterOptions(
    records?: RecordDto[],
    usersObject?: ObjectDto,
    maxItemLength?: number
): FilterOption[] {
    if (!records) {
        return []
    }

    const options = records.map((record) => {
        const avatar = getAvatarFromUserRecord(record, usersObject)

        const recordPrimary = record?._primary || ''
        const label = typeof recordPrimary === 'string' ? recordPrimary : recordPrimary.toString()

        return {
            label: maxItemLength ? truncateText(label, maxItemLength) : label,
            value: record._sid,
            avatar,
        }
    })

    options.sort((a, b) => {
        // Sort by label alphabetically.
        if (a.label.localeCompare(b.label) < 0) return -1
        if (a.label.localeCompare(b.label) > 0) return 1

        return 0
    })

    return options
}

function getValueFromFilter(filter: AdvancedFilter): string[] {
    const filterValue = filter.options?.value

    if (Array.isArray(filterValue)) {
        return filterValue
    }

    if (filterValue) {
        return [filterValue]
    }

    return []
}

function getValueOptions(value: Set<string>, filterOptions: FilterOption[]) {
    const activeOptions = filterOptions.filter((option) => value.has(option.value))

    const truncatedOptions = activeOptions.slice(0, MAX_ITEMS)

    return truncatedOptions
}

const PLACEHOLDER_OPTIONS: FilterOption[] = [
    { label: 'Loading options...', value: 'loading1' },
    { label: 'Loading options...', value: 'loading2' },
    { label: 'Loading options...', value: 'loading3' },
    { label: 'Loading options...', value: 'loading4' },
    { label: 'Loading options...', value: 'loading5' },
    { label: 'Loading options...', value: 'loading6' },
]

import { useRef } from 'react'

/**
 * Runs the specified effect only once, when `condition` is true
 * */
export default function useRunOnce(effect: () => void, condition: boolean = true) {
    const hasRun = useRef(false)
    if (condition && !hasRun.current) {
        effect()
        hasRun.current = true
    }
}

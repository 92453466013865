import { useContext } from 'react'

import { NavigationContext, NavigationContextValue } from 'features/navigation/NavigationContext'

export function useNavigationContext(): NavigationContextValue {
    const context = useContext(NavigationContext)

    if (!context) {
        throw new Error('useNavigationContext must be used within a NavigationContextProvider')
    }

    return context
}

import { getIsRelationshipField, getIsSymmetricRelationshipField } from 'utils/fieldUtils'

export const fieldHasError = (field, errors) => {
    if (errors?.find((err) => err.field_id === field._sid)) return true
    return false
}

export const isSyntheticLink = (field) => {
    return getIsRelationshipField(field) || getIsSymmetricRelationshipField(field)
}

import React, { forwardRef } from 'react'

import {
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputRightAddon,
    InputRightElement,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'

import STYLE_CLASSES from 'v2/ui/styleClasses'

const BASE_CLASS = STYLE_CLASSES.INPUT

const StyledInputGroup = styled(InputGroup)`
    & > input {
        border-color: ${(props) => props.theme.colors?.input?.border};
        font-family: ${(props) => props.theme.fonts?.button};

        &:hover {
            border-color: ${(props) => props.theme.colors?.input?.borderHover};
        }
        &:disabled {
            border-color: ${(props) => props.theme.colors?.input?.border};
        }
        &:focus-within {
            box-shadow: unset;
            border-width: 2px;
            border-color: ${(props) => props.theme.colors?.input?.borderActive};
        }
    }
    &.invalid > input {
        &:focus-within {
            box-shadow: 0 0 0px 1px ${(props) => props.theme.colors?.input?.borderError} !important;
        }
        border-color: ${(props) => props.theme.colors?.input?.borderError} !important;
    }

    ${variant({
        variants: {
            admin: {
                '&>input': {
                    borderColor: 'transparent',
                    bg: 'admin.input.background',
                    boxShadow: 'admin.input',
                    '&:focus-within': {
                        borderColor: 'admin.input.borderActive',
                    },
                },
            },
            settings: {
                '&>input': {
                    px: '12px',
                    py: '6px',
                    borderRadius: '5px',
                    height: 'auto',
                    '&:focus-within': {
                        boxShadow: 'none',
                        borderWidth: '1px',
                        borderColor: 'admin.input.borderActive',
                    },
                },
            },
            noBorder: {
                '&>input': {
                    border: 'none',
                    '&:focus-within': {
                        boxShadow: 'none',
                        border: 'none',
                    },
                },
            },
            admin_white: {
                '&>input': {
                    '&:focus-within': {
                        borderColor: 'admin.input.borderActive',
                    },
                },
            },
            authentication: {
                '&>input': {
                    bg: 'authentication.input.background',
                    '&:focus-within': {
                        borderColor: 'authentication.input.borderActive',
                    },
                },
            },
            authenticationDark: {
                '&>input': {
                    color: 'neutral.800',
                    bg: 'neutral.1000',
                    borderColor: 'neutral.1000',
                    '&:focus-within': {
                        borderColor: 'neutral.900',
                    },
                    '&:hover': {
                        borderColor: 'neutral.900',
                    },
                },
            },
            onboarding: {
                '&>input': {
                    bg: 'authentication.input.background',
                    '&:focus-within': {
                        borderColor: 'grey.200',
                    },
                },
            },
            dgTab: {
                margin: '0 4px 0 4px',
                height: '36px',
                padding: '0 12px 0 12px',
                backgroundColor: 'white',
                border: 'none',
                outline: 'none',
                borderRadius: '4px 4px 0 0',
                display: 'flex',
                appearance: 'none',

                '&>input': {
                    margin: 'auto',
                    height: '20px',
                    border: '0',
                    borderRadius: '4px 4px 0 0',
                    fontWeight: 'bold',
                    padding: '0 12px 0 12px',
                    appearance: 'none',
                    '&:focus-within': {
                        border: 'none',
                    },
                },
            },
        },
    })}
`
const StackerInput = (
    {
        leftAdorner,
        rightAdorner,
        leftAdornerProps,
        rightAdornerProps,
        leftAddon,
        rightAddon,
        leftAddonProps,
        rightAddonProps,
        groupProps,
        variant,
        value,
        placeholder,
        id,
        className = '',
        dataTestId,
        containerStyle = {},
        ...props
    },
    ref
) => {
    const joinedClassName = `${className} ${BASE_CLASS} ${
        variant ? BASE_CLASS + '-' + variant : ''
    }`

    // separate out the props we don't want to pass to the inner Input element
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { m, ml, mr, mt, mb, mx, my, autofocus, ...innerProps } = props
    // separate out the props we don't want to pass to the container element
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { onKeyDown, onKeyPress, onPaste, onChange, style: innerStyle, ...containerProps } = props

    return (
        <StyledInputGroup
            borderRadius="6px"
            width="unset"
            variant={variant}
            {...containerProps}
            {...groupProps}
            {...containerStyle}
            className={joinedClassName}
        >
            {leftAddon && (
                <InputLeftAddon
                    backgroundColor="userInterface.neutral.0"
                    borderColor="input.border"
                    borderRadius="md"
                    p={2}
                    {...leftAddonProps}
                >
                    {leftAddon}
                </InputLeftAddon>
            )}

            {leftAdorner && (
                <InputLeftElement
                    px={2}
                    justifyContent="flex-start"
                    minWidth={
                        leftAdornerProps?.leftAdornerMinWidth
                            ? leftAdornerProps?.leftAdornerMinWidth
                            : 1
                    }
                    fontSize={14}
                    lineHeight={1}
                    color="grey.300"
                >
                    {leftAdorner}
                </InputLeftElement>
            )}
            <Input
                data-testid={dataTestId}
                errorBorderColor="input.borderError"
                ref={ref}
                value={value}
                id={id}
                placeholder={placeholder}
                fontSize={['input.text', null, null, 'input.textLg']}
                pr={rightAdorner ? '2rem' : null}
                lineHeight={1}
                autoFocus={autofocus}
                borderRadius="md"
                style={innerStyle}
                variant="outline"
                background="white"
                {...innerProps}
            />
            {rightAdorner && (
                <InputRightElement color="grey.300" {...rightAdornerProps}>
                    {rightAdorner}
                </InputRightElement>
            )}

            {rightAddon && (
                <InputRightAddon
                    backgroundColor="userInterface.neutral.0"
                    borderColor="input.border"
                    borderRadius="md"
                    p={2}
                    {...rightAddonProps}
                >
                    {rightAddon}
                </InputRightAddon>
            )}
        </StyledInputGroup>
    )
}

export default forwardRef(StackerInput)

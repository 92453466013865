import React, { useReducer } from 'react'

import ButtonV2 from 'v2/ui/components/Button'

import ButtonV4 from './ButtonV4'

/**
 * @param {{
 * size?: "xs" | "sm" | "md" | "lg" | "xl"
 * type?: "primary" | "secondary" | "tertiary" | "link"
 * theme?: "primary" | "themePrimary" | "light"
 * submit?: boolean
 * v4?: boolean
 * onClick?: (params: any) => Promise<any>
 * children?: React.ReactNode
 }} param0
 */
function AtomButton(
    {
        size = 'md',
        type = 'primary',
        theme = 'primary',
        onClick,
        submit,
        v4 = false,
        ...otherProps
    },
    ref
) {
    const isMounted = React.useRef(true)
    const [state, dispatch] = useReducer(buttonReducer, getDefaultState())

    // We need to keep track of whether the component has been unmounted
    // to avoid memory leaks when resolving promises
    React.useEffect(() => {
        return () => (isMounted.current = false)
    })

    function handleClick(event) {
        if (state.status !== 'idle') {
            return null
        }

        const promise = onClick?.(event)

        if (promise?.then) {
            dispatch({ type: 'loading' })
            promise.then(
                () => {
                    if (isMounted.current) {
                        dispatch({ type: 'loaded' })
                    }
                },
                (error) => {
                    if (isMounted.current) {
                        dispatch({ type: 'failed', error })
                    }
                }
            )
        }

        return promise
    }

    if (v4) {
        if (theme === 'primary') {
            theme = 'stacker'
        }
        return (
            <ButtonV4
                onClick={handleClick}
                buttonType={submit ? 'submit' : 'button'}
                size={size}
                type={type}
                theme={theme}
                state={state}
                {...otherProps}
                isLoading={state.status === 'loading' || otherProps.isLoading}
                ref={ref}
            />
        )
    }

    return (
        <ButtonV2
            {...mapPropsToV2({ size, type, theme, state })}
            onClick={handleClick}
            type={submit ? 'submit' : 'button'}
            {...otherProps}
            ref={ref}
        />
    )
}
export default React.forwardRef(AtomButton)

function getDefaultState() {
    return {
        status: 'idle',
        error: false,
    }
}

function buttonReducer(state, action) {
    switch (action.type) {
        case 'loading':
            return {
                status: 'loading',
                error: false,
            }
        case 'loaded':
            return {
                status: 'idle',
                error: false,
            }
        case 'failed':
            return {
                status: 'failed',
                error: action.error,
            }
        default:
            return state
    }
}

function mapPropsToV2({ size, type, theme, state }) {
    const base = mapThemeToV2({ size, theme, type })

    if (state.status === 'loading') {
        base.isLoading = true
    }

    return base
}
function mapThemeToV2({ size, type, theme }) {
    const base = {
        buttonSize: { sm: 'xs', md: 'sm', lg: 'md', xl: 'lg' }[size],
        variant: {
            primary: 'adminPrimary',
            secondary: 'adminSecondary',
            tertiary: 'adminTertiary',
            link: 'adminTertiary',
        }[type],
    }

    if (base.variant === 'adminPrimary' && theme === 'themePrimary') {
        base.variant = 'sm'
    }

    return base
}

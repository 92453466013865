import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import { renderNumberValueAsText } from 'features/charts/NumberValue'
import truncateLabel from 'features/charts/recharts/utils/truncateLabel'

const SVG_NS = 'http://www.w3.org/2000/svg'

type Args = Pick<RechartsBarParam, 'metrics' | 'display' | 'config'> & {
    chartWidth: number | undefined
    numDataPoints: number
    maxItems: number | undefined
}

export default function useTickFormatter({
    metrics,
    display,
    config,
    chartWidth,
    numDataPoints,
    maxItems,
}: Args) {
    const [renderKey, forceUpdate] = useReducer(
        (i) => (i === Number.MAX_SAFE_INTEGER ? 0 : i + 1),
        0
    )

    const [xTicksRotated, setXTicksRotated] = useState(false)
    const [yTickWidth, setYTickWidth] = useState(1)

    const textSvg = useMemo(() => {
        const text = document.createElementNS(SVG_NS, 'text')
        const svg = document.createElementNS(SVG_NS, 'svg')
        svg.appendChild(text)
        document.body.appendChild(svg)

        return text
    }, [])

    const maxYTickWidth = useMemo(
        () => (chartWidth ? chartWidth / 3 : Number.POSITIVE_INFINITY),
        [chartWidth]
    )

    const xTickWidth = useMemo(() => {
        // we add 1 to the number of items to force the library to display the last tick label even if it's on the edge of the chart
        const numItems = maxItems ? Math.min(maxItems, numDataPoints) : numDataPoints

        return chartWidth && metrics?.data ? chartWidth / numItems : undefined
    }, [chartWidth, maxItems, metrics.data, numDataPoints])

    const onRotateXTicks = useCallback(() => {
        setXTicksRotated(true)
    }, [])

    const xTickFormatter = useCallback(
        (tick: number | string) => {
            if (!xTickWidth) {
                return tick.toString()
            }

            if (typeof tick === 'number') {
                return tick.toString()
            }

            // we always return an empty string here to trick the library into displaying the tick label all the time
            // the label display is handled by the custom CategoryLabel component
            // without this, the library would only display the label if it fits in the available space, making the label disappear if the chart is too small
            return ''
        },
        [xTickWidth]
    )

    const yTickFormatter = useCallback(
        (tick: number | string) => {
            const formattedTick =
                typeof tick === 'number'
                    ? renderNumberValueAsText({ value: tick, metrics, display, config })
                    : tick

            textSvg.textContent = formattedTick
            if (textSvg.getComputedTextLength() <= yTickWidth) {
                return formattedTick
            }
            // adding 3 pixels to avoid cut off
            const width = Math.min(textSvg.getComputedTextLength() + 3, maxYTickWidth)
            const { label } = truncateLabel(formattedTick, width, textSvg, width, 2)
            setYTickWidth(width)

            return label
        },
        [config, display, maxYTickWidth, metrics, textSvg, yTickWidth]
    )

    useEffect(() => {
        setXTicksRotated(false)
        setYTickWidth(1)
        forceUpdate()
    }, [metrics, display, config, maxItems, chartWidth])

    useEffect(() => {
        return () => {
            document.body.removeChild(textSvg.parentElement!)
        }
    }, [textSvg])

    return useMemo(
        () => ({
            renderKey,
            xTicksRotated,
            xTickWidth,
            xTickFormatter,
            onRotateXTicks,
            yTickWidth,
            yTickFormatter,
        }),
        [
            renderKey,
            xTickFormatter,
            xTicksRotated,
            xTickWidth,
            onRotateXTicks,
            yTickWidth,
            yTickFormatter,
        ]
    )
}

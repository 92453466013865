import React from 'react'
import { useFormContext } from 'react-hook-form'

import LogoPreview from 'v2/ui/components/LogoPreview'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'

import ImageUploadForm from './ImageUpload'

const Label = ({ isSmall = false, children, ...props }: { isSmall?: boolean; children?: any }) => (
    <Body size={isSmall ? 'm' : 'l'} weight="medium" {...props}>
        {children}
    </Body>
)

export const UploadIcon = ({
    disabled,
    isAppModal,
    logoFieldName = 'logo',
    iconFieldName = 'logo_icon',
    brandColorFieldName = 'brandColor',
}: {
    disabled: boolean
    isAppModal?: boolean
    logoFieldName?: string
    iconFieldName?: string
    brandColorFieldName?: string
}) => {
    const formContext = useFormContext()
    const [logo, logo_icon, brandColor, navColor] = formContext.watch([
        logoFieldName,
        iconFieldName,
        brandColorFieldName,
        'navColor',
    ])

    const deleteLogo = (name: string) => {
        formContext.setValue(name, null, { shouldDirty: true })
    }

    return (
        <>
            {isAppModal ? <Divider variant="weak" mt="l" /> : <Divider variant="weak" />}
            <Box flex my="m" style={{ alignItems: 'center' }}>
                <Box flex center grow gap="xs">
                    <Label isSmall={isAppModal}>Logo</Label>
                </Box>
                {logo && (
                    <>
                        <div style={{ flexBasis: 15, flexShrink: 1 }} />
                        <LogoPreview
                            navColor={navColor}
                            brandColor={brandColor}
                            url={logo}
                            showRemove={!disabled}
                            onRemove={() => deleteLogo(logoFieldName)}
                        />
                    </>
                )}
                <ImageUploadForm name={logoFieldName}>
                    {({ onClick }) =>
                        logo ? null : (
                            <Button
                                startIcon={{
                                    name: 'Plus',
                                }}
                                variant="secondary"
                                onClick={onClick}
                                disabled={disabled}
                            >
                                Upload
                            </Button>
                        )
                    }
                </ImageUploadForm>
            </Box>
            <Divider />
            <Box flex my="m" style={{ alignItems: 'center' }}>
                <Box flex center grow gap="s">
                    <Label isSmall={isAppModal}>Logo icon</Label>
                    <Tooltip
                        content="Use image with 1:1 aspect ratio (square)"
                        zIndex={1401}
                        side="bottom"
                    >
                        <Icon name="Info" size="m" aria-label="Information text" />
                    </Tooltip>
                </Box>
                {logo_icon && (
                    <>
                        <div style={{ flexBasis: 15, flexShrink: 1 }} />
                        <LogoPreview
                            navColor={navColor}
                            brandColor={brandColor}
                            url={logo_icon}
                            showRemove={!disabled}
                            onRemove={() => deleteLogo(iconFieldName)}
                        />
                    </>
                )}
                <ImageUploadForm name={iconFieldName}>
                    {({ onClick }) =>
                        logo_icon ? null : (
                            <Button
                                startIcon={{
                                    name: 'Plus',
                                }}
                                variant="secondary"
                                onClick={onClick}
                                disabled={disabled}
                            >
                                Upload
                            </Button>
                        )
                    }
                </ImageUploadForm>
            </Box>
            <Divider mb="l" />
        </>
    )
}

export function getTriggerUserOutput(usersObject: ObjectDto): WorkflowSchemaStateItem {
    return {
        id: 'user_id',
        name: 'Current User',
        type: 'lookup',
        extra_options: { link_target_object_id: usersObject._sid },
    }
}

export function triggerTypeSupportsConditions(triggerType: WorkflowSchemaTriggerType): boolean {
    return triggerType.id !== 'action_button_clicked'
}

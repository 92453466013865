import React from 'react'
import { ControllerRenderProps, FieldError } from 'react-hook-form'

import { DropdownItem, DropdownSeparator } from 'ui/components/Dropdown'
import { Select, SelectOption } from 'ui/components/Select'

type RoleOption = { value: string; label: string }

export const RolePicker = ({
    roles,
    error,
    onAddRole,
    ...rest
}: {
    roles: RoleOption[]
    error?: FieldError
    modal?: boolean
    onAddRole?: () => void
    rest?: ControllerRenderProps
}) => {
    return (
        <Select
            label="Role"
            startIcon={{ name: 'Lock' }}
            placeholder="Select role..."
            isError={!!error}
            helperText={!!error ? error.message : null}
            {...rest}
        >
            {roles.map(({ value, label }) => (
                <SelectOption key={value} value={value} label={label} />
            ))}
            {onAddRole && (
                <>
                    <DropdownSeparator />
                    <DropdownItem
                        label="Create new role"
                        startIcon={{ name: 'Plus' }}
                        onClick={onAddRole}
                        variant="action"
                    />
                </>
            )}
        </Select>
    )
}

import { useLocalStorageState } from 'utils/useLocalStorageState'

import { RecentAction } from './recentActionTypes'

export function useRecentActions(objectId: string = ''): {
    recentActions: RecentAction[]
    recordAction: (action: RecentAction) => void
} {
    const [recentActions, setRecentActions] = useLocalStorageState<RecentAction[]>(
        `${objectId}_recent_record_actions`,
        { defaultValue: [] }
    )

    return {
        recentActions,
        recordAction: (action: RecentAction) => {
            setRecentActions([
                action,
                ...recentActions
                    .filter((a: RecentAction) => {
                        return a.fieldApiName !== action.fieldApiName || a.value !== action.value
                    })
                    .slice(0, 4),
            ])
        },
    }
}

import { useCallback, useRef } from 'react'

import { isEqual } from 'lodash'

import useRunOnce from 'v2/ui/utils/useRunOnce'

import useStableState from './useStableState'

type Options<T> = {
    defaultValue?: T
}

function readValueFromStorage<T>(key: string, defaultValue?: T): T {
    const rawValue = localStorage.getItem(key)
    return rawValue && rawValue !== '' ? JSON.parse(rawValue) : defaultValue
}
export function useLocalStorageState<T>(key: string, options?: Options<T>) {
    const initialLoadedValue = useRef<T>(readValueFromStorage(key, options?.defaultValue))
    // using stable state which writes to redux so that any hooks that are
    // sharing the same key get notified when the value changes
    const [value, setValue] = useStableState(key)

    useRunOnce(() => {
        const storedValue = readValueFromStorage(key, options?.defaultValue)
        if (!isEqual(storedValue, value)) {
            setValue(storedValue)
        }
    }, true)

    const setState = useCallback(
        (value: T) => {
            setValue(value)
            const valueToSave = JSON.stringify(value || '')
            localStorage.setItem(key, valueToSave)
        },
        [key, setValue]
    )

    const effectiveValue = value !== undefined ? value : initialLoadedValue.current

    return [effectiveValue !== undefined ? effectiveValue : options?.defaultValue, setState]
}

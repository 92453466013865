import { Editor } from '@tiptap/core'

import { IconName } from 'ui/components/Icon/Icon'

export type BlockTypeCommand = {
    label: string
    icon: IconName
    requiredExtensions?: string[]
    check: (editor: Editor) => boolean
    command: (editor: Editor) => void
    isAvailable?: (editor: Editor) => boolean
}
export const BlockTypes: Record<string, BlockTypeCommand> = {
    '': {
        label: 'Normal',
        icon: 'Text',
        check: (editor: Editor) => editor.isActive('paragraph') && !editor.isActive('blockquote'),
        command: (editor: Editor) => editor.chain().focus().clearNodes().run(),
    },
    document: {
        label: 'Sub page',
        icon: 'Notebook',
        requiredExtensions: ['documentLink'],
        isAvailable: (editor: Editor) => {
            const ext = editor?.extensionManager.extensions.find((e) => e.name === 'documentLink')
            return !!ext
        },
        check: (editor: Editor) => editor.isActive('paragraph') && !editor.isActive('blockquote'),
        command: async (editor: Editor) => {
            const ext = editor.extensionManager.extensions.find((e) => e.name === 'documentLink')
            const selection = editor.state.selection

            const slice = editor.state.doc.slice(selection.from, selection.to)
            let selectionContent = slice.content

            let firstNodeText = selectionContent.firstChild?.textContent
            let title: string | undefined
            // If the first node has a text length less than 60, we'll use that as the title,
            // and move the rest of the selection to the new page
            if (firstNodeText && firstNodeText.length < 60) {
                title = firstNodeText
                const nodeSize = selectionContent.firstChild?.nodeSize
                if (typeof nodeSize === 'number') {
                    selectionContent = selectionContent.cut(nodeSize)
                }
            } else {
                // otherwise, we'll move the entire selection to the new page
                // and prompt the user for the title
            }

            const doc = await ext?.options.createDocument({ title, body: selectionContent })

            let chain = editor.chain().focus()

            chain
                .setDocumentLink({ id: doc.document.auto_id, stack_id: doc.document.stack_id })
                .run()
        },
    },
    heading1: {
        label: 'Heading 1',

        icon: 'Heading1',
        check: (editor: Editor) => editor.isActive('heading', { level: 1 }),
        command: (editor: Editor) =>
            editor.chain().focus().clearNodes().toggleHeading({ level: 1 }).run(),
    },
    heading2: {
        label: 'Heading 2',
        icon: 'Heading2',
        check: (editor: Editor) => editor.isActive('heading', { level: 2 }),
        command: (editor: Editor) =>
            editor.chain().focus().clearNodes().toggleHeading({ level: 2 }).run(),
    },
    heading3: {
        label: 'Heading 3',
        icon: 'Heading3',
        check: (editor: Editor) => editor.isActive('heading', { level: 3 }),
        command: (editor: Editor) =>
            editor.chain().focus().clearNodes().toggleHeading({ level: 3 }).run(),
    },
    codeBlock: {
        label: 'Code',

        icon: 'Code',
        check: (editor: Editor) => {
            return editor.isActive('codeBlock')
        },
        command: (editor: Editor) => editor.chain().focus().clearNodes().toggleCodeBlock().run(),
    },
    quote: {
        label: 'Quote',

        icon: 'Quote',
        check: (editor: Editor) => editor.isActive('blockquote'),
        command: (editor: Editor) => editor.chain().focus().clearNodes().toggleBlockquote().run(),
    },
    bulletList: {
        label: 'Bullet list',
        icon: 'List',
        check: (editor: Editor) => editor.isActive('bulletList'),
        command: (editor: Editor) => editor.chain().focus().clearNodes().toggleBulletList().run(),
    },
    orderedList: {
        label: 'Ordered list',
        icon: 'ListOrdered',
        check: (editor: Editor) => editor.isActive('orderedList'),
        command: (editor: Editor) => editor.chain().focus().clearNodes().toggleOrderedList().run(),
    },
    taskList: {
        label: 'Check list',
        icon: 'ListTodo',
        check: (editor: Editor) => editor.isActive('taskList'),
        command: (editor: Editor) => editor.chain().focus().clearNodes().toggleTaskList().run(),
        isAvailable: (editor: Editor) => {
            const ext = editor?.extensionManager.extensions.find((e) => e.name === 'taskList')

            return !!ext
        },
    },
    details: {
        label: 'Collapsible',
        icon: 'ListCollapse',
        check: (editor: Editor) => editor.isActive('details'),
        command: (editor: Editor) => editor.chain().focus().clearNodes().setDetails().run(),
        isAvailable: (editor: Editor) => {
            const ext = editor?.extensionManager.extensions.find((e) => e.name === 'details')

            return !!ext
        },
    },
    horizontalRule: {
        label: 'Horizontal rule',
        icon: 'Minus',
        check: (editor: Editor) => editor.isActive('horizontalRule'),
        command: (editor: Editor) => editor.chain().focus().clearNodes().setHorizontalRule().run(),
    },
    excalidraw: {
        label: 'Drawing',
        icon: 'Brush',
        check: (editor: Editor) => editor.isActive('excalidraw'),
        command: (editor: Editor) => {
            editor.chain().focus().clearNodes().insertContent({ type: 'excalidraw' }).run()

            queueMicrotask(() => editor.commands.openExcalidrawModal())
        },
        isAvailable: (editor: Editor) => {
            const ext = editor?.extensionManager.extensions.find((e) => e.name === 'excalidraw')

            return !!ext
        },
    },
}

export const HeadingTypes = Object.keys(BlockTypes)
    .filter((k) => k.startsWith('heading'))
    .map((k) => ({ type: k as BlockType, level: Number(k.replace('heading', '')) }))

export type BlockType = keyof typeof BlockTypes

export const SimpleBlockTypes: BlockType[] = [
    '',
    'codeBlock',
    'quote',
    'bulletList',
    'orderedList',
    'horizontalRule',
]

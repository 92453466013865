import { ForwardRefExoticComponent, RefAttributes } from 'react'

import { ReactRenderer } from '@tiptap/react'
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion'
import tippy, { GetReferenceClientRect, Instance } from 'tippy.js'

import { autoSizeModifier } from 'v2/ui/components/popperModifiers/autoSizeModifier'

export type SuggestionComponentHandle = {
    onKeyDown: (props: { event: KeyboardEvent }) => boolean
}
export function renderSuggestionComponent<TAdditionalProps>(
    componentToRender: ForwardRefExoticComponent<
        SuggestionProps<any> & RefAttributes<SuggestionComponentHandle> & TAdditionalProps
    >,
    additionalProps?: TAdditionalProps,
    onHide?: () => void
) {
    let component: ReactRenderer<SuggestionComponentHandle>
    let popup: Instance | undefined

    let focusHandler: () => void

    return {
        onStart: (props: SuggestionProps<any>) => {
            component = new ReactRenderer(componentToRender, {
                props: { ...props, ...additionalProps },
                editor: props.editor,
            })

            if (!props.clientRect || !props.decorationNode) return

            popup = tippy(props.decorationNode, {
                getReferenceClientRect: props.clientRect as GetReferenceClientRect,
                appendTo: document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                hideOnClick: false,
                trigger: 'manual',
                placement: 'bottom-start',
                popperOptions: {
                    modifiers: [
                        autoSizeModifier,
                        {
                            name: 'preventOverflow',
                            enabled: true,
                            options: {
                                altAxis: true,
                                mainAxis: true,
                                tether: false,
                                padding: {
                                    top: 48,
                                    bottom: -16,
                                },
                            },
                        },
                    ],
                },
                onHide,
            })

            focusHandler = () => {
                if (!popup?.state.isVisible) popup?.show()
            }
            props.editor.on('focus', focusHandler)
        },
        onUpdate(props: SuggestionProps<any>) {
            component.updateProps(props)
        },
        onKeyDown(props: SuggestionKeyDownProps) {
            if (props.event.key === 'Escape') {
                popup?.hide()

                return true
            }

            if (component.ref) return component.ref.onKeyDown(props)

            return false
        },
        onExit(props: SuggestionProps<any>) {
            props.editor.off('focus', focusHandler)

            queueMicrotask(() => {
                popup?.destroy()
                component.destroy()
            })
        },
    }
}

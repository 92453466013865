import { Extension } from '@tiptap/core'
import { Fragment, Mark, Node as ProseMirrorNode, Slice } from '@tiptap/pm/model'
import { Plugin, PluginKey } from '@tiptap/pm/state'

const NEW_LINE_REGEXP = /(\r\n|\r|\n)/g

/**
 * This extension handles pasting text and HTML into the editor. It fixes a problem where the new lines
 * are not preserved when pasting content from Slack.
 * @returns
 */
export function createPasteExtension() {
    return Extension.create({
        addProseMirrorPlugins() {
            return [
                new Plugin({
                    key: new PluginKey('pasteExtension'),
                    props: {
                        clipboardTextParser(text, $context, plain, view) {
                            const { state } = view
                            const { schema } = state

                            // Split text into lines and insert hard breaks.
                            const lines = text.trim().split(NEW_LINE_REGEXP)
                            const linesLen = lines.length

                            // Add all the formatting that we've inherited.
                            let marks: Mark[] = []
                            if (!plain) {
                                marks = $context.marks() as Mark[]
                            }

                            const nodes = lines.reduce((acc, line, idx) => {
                                const text = line.trim()
                                if (text) {
                                    acc.push(schema.text(text, marks))

                                    // If this is not the last line, add a hard break.
                                    if (idx < linesLen - 1) {
                                        acc.push(
                                            schema.nodes.hardBreak.create(
                                                undefined,
                                                undefined,
                                                marks
                                            )
                                        )
                                    }
                                }

                                return acc
                            }, [] as ProseMirrorNode[])

                            return Slice.maxOpen(Fragment.fromArray(nodes))
                        },
                    },
                }),
            ]
        },
    })
}

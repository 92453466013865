import { useMemo } from 'react'

type UseWorkspaceIconStateProps = {
    workspaceAccount: Account
}

export function useWorkspaceIconState({ workspaceAccount }: UseWorkspaceIconStateProps) {
    const workspaceName = workspaceAccount?.name ?? 'Stacker'
    const nameInitials = workspaceName.slice(0, 2)
    const imageUrl = workspaceAccount?.options?.theme?.logo_icon

    return useMemo(
        () => ({
            nameInitials,
            imageUrl,
        }),
        [imageUrl, nameInitials]
    )
}

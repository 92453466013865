import React from 'react'

import { SuggestionProps } from '@tiptap/suggestion'

import { useMentionUsersForStack } from 'features/Activity/useMentionUsersForStack'
import { MentionsList } from 'features/tiptap/Extensions/MentionsExtension'

import { InternalLinkCommandProviderProps } from './internalLinkCommandTypes'

type SearchUsersProps = SuggestionProps<any> & InternalLinkCommandProviderProps & {}
export const SearchUsers = React.forwardRef<
    React.ElementRef<typeof MentionsList>,
    SearchUsersProps
>(({ stackId, ...props }, ref) => {
    const mentionUsers = useMentionUsersForStack(stackId)

    return <MentionsList ref={ref} {...props} items={mentionUsers} />
})

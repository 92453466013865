import React from 'react'

import { queryClient } from 'data/hooks/_helpers'
import { Icon8 } from 'legacy/v1/ui'

import { Flex, Link } from 'v2/ui'

export const FailedPage = () => {
    const tryAgain = () => {
        queryClient.invalidateQueries()
    }
    return (
        <Flex
            column
            align="center"
            width="100%"
            height="100dvh"
            justify="center"
            fontFamily="sans-serif"
        >
            <Icon8
                icon="sad-cloud"
                iconStyle="material-outlined"
                displaySize="200"
                color="333333"
                noMargin
            />

            <h1>Unable to connect to the server</h1>
            <p>
                <Link onClick={tryAgain}>Click here</Link> to try again.
            </p>
        </Flex>
    )
}

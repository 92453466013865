import React from 'react'

import { AppGroup } from './types'

export type AppUsersPane = 'default' | 'createTableGroup' | 'updateTableGroup'

export type AppUsersContextValue = {
    pane: AppUsersPane
    setPane: (pane: AppUsersPane) => void
    resetPane: () => void
    searchQuery: string
    setSearchQuery: (searchQuery: string) => void
    selectedTableGroup?: AppGroup
    setSelectedTableGroup: (tableGroup?: AppGroup) => void
}

export const AppUsersContext = React.createContext<AppUsersContextValue | null>(null)

import { useCallback, useMemo } from 'react'

import { Rights } from 'app/appUserContextConstants'
import { useAppUserContext } from 'app/useAppUserContext'
import { useListHeaderContext } from 'features/views/ListView/ListHeader/useListHeaderContext'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { useIsMobile } from 'v2/ui/utils/useIsMobile'

export function useListHeaderDefaultObjectLabelState() {
    const { object } = useListHeaderContext()

    const objectLabel = object?.name || ''

    const isMobile = useIsMobile()
    const { isAdmin, hasRight } = useAppUserContext()
    const shouldShow = isAdmin && hasRight(Rights.Admin.Any) && !isMobile

    const { showManageData } = useSlidingPane()

    const onClick = useCallback(() => {
        if (!shouldShow || !object?._sid) return

        showManageData({
            objectId: object._sid,
        })
    }, [object._sid, shouldShow, showManageData])

    return useMemo(
        () => ({
            objectLabel,
            shouldShow,
            onClick,
        }),
        [objectLabel, shouldShow, onClick]
    )
}

import React from 'react'

import { Button } from 'v2/ui'
import { SolidDuplicate } from 'v2/ui/svgs'

type ListViewAdditionalActionsProps = {
    openDuplicateModal?: () => void
}

const ListViewAdditionalActions: React.FC<ListViewAdditionalActionsProps> = ({
    openDuplicateModal,
}) => (
    <>
        <Button
            label="Duplicate layout"
            variant="clear"
            p={0}
            onClick={() => openDuplicateModal?.()}
            svgIcon={<SolidDuplicate />}
        />
    </>
)

export default ListViewAdditionalActions

import { z } from 'zod'

export type AppSchemaNoFieldsNoRecordsType = z.infer<typeof AppSchemaNoFieldsNoRecords>
export type AppSchemaType = z.infer<typeof AppSchema>
export type RequirementSchemaType = z.infer<typeof RequirementSchema>
export type AppRecordsSchemaType = z.infer<typeof GeneratedAppRecordsSchema>
export type ObjectSchemaType = z.infer<typeof ObjectSchema>
export type FieldSchemaType = z.infer<typeof FieldSchema>
export type ListViewSchemaType = z.infer<typeof ListViewSchema>
export type RecordSchemaType = z.infer<typeof RecordSchema>
export type GeneratedAppRecordsSchemaType = z.infer<typeof GeneratedAppRecordsSchema>
export type GeneratedAppSchemaType = z.infer<typeof GeneratedAppSchema>

export const FieldSchema = z
    .object({
        id: z.string({
            description: 'Unique pair of object id and single-token id based on name',
        }),
        name: z.string({
            description:
                'Do not repeat object name in field name. Ie. do not use Company Name, use Name instead',
        }),
        type: z.enum([
            'checkbox',
            'currency',
            'date',
            'datetime',
            'dropdown',
            'document',
            'multi_select',
            'number',
            'percentage',
            'string',
            'relationship',
            'multi_relationship',
            'image',
        ]),
        options: z.array(z.string()).describe('options for dropdown and multi_select fields'),
        target: z.union([z.string(), z.null()]).describe('target object id if relationship field'),
    })
    .strict()

export const BasicFieldSchema = z
    .object({
        id: z.string(),
        name: z.string(),
    })
    .strict()

export const RecordValueSchema = z
    .object({
        fieldId: z.string(),
        value: z.union([z.string(), z.number(), z.boolean(), z.null()]),
    })
    .strict()

export const RecordSchema = z.object({
    id: z.string({ description: 'object id + record index' }),
    fields: z.array(RecordValueSchema),
})
export const GeneratedRecordSchema = z
    .object({
        id: z.string({ description: 'object id + record index' }),
        fields: z.array(
            z.union([z.string(), z.number(), z.boolean(), z.null()], {
                description: 'one entry per field, can be null where appropriate',
            })
        ),
    })
    .strict()

export const ListViewSchema = z
    .object({
        id: z.string(),
        title: z.string(),
        layout: z.enum(['table', 'gallery', 'kanban']),
    })
    .strict()

export const GeneratedObjectSchema = z
    .object({
        id: z.string({ description: 'Single-token unique id based on object name' }),
        name: z.string(),
        fields: z.array(FieldSchema),
        layout: z.enum(['table', 'gallery', 'kanban']),
        kanbanStatusField: z.union([z.string(), z.null()]),
    })
    .strict()

export const ObjectSchema = z.object({
    id: z.string({ description: 'Single-token unique id based on object name' }),
    name: z.string(),
    fields: z.array(FieldSchema),
    layout: z.enum(['table', 'gallery', 'kanban']),
    kanbanStatusField: z.union([z.string(), z.null()]).optional(),
    description: z.string({ description: 'less than 5 word summary of object' }),
    records: z.array(RecordSchema),
    listViews: z.array(ListViewSchema).optional(),
})

export const GeneratedObjectRecordsSchema = z
    .object({
        id: z.string({ description: 'object id' }),
        records: z.array(GeneratedRecordSchema),
    })
    .strict()

export const GeneratedAppRecordsSchema = z
    .object({
        objects: z.array(GeneratedObjectRecordsSchema),
    })
    .strict()

export const ObjectSchemaNoFieldsNoRecords = z
    .object({
        id: z.string({ description: 'Single-token unique id based on object name' }),
        name: z.string(),
        description: z.string({ description: 'less than 5 word summary of object' }),
    })
    .strict()

export const GeneratedAppSchema = z
    .object({
        id: z.string(),
        name: z.string(),
        objects: z.array(GeneratedObjectSchema),
    })
    .strict()

export const AppSchema = z.object({
    id: z.string(),
    name: z.string(),
    objects: z.array(ObjectSchema),
})

export const AppSchemaNoFieldsNoRecords = z
    .object({
        id: z.string(),
        name: z.string(),
        objects: z.array(ObjectSchemaNoFieldsNoRecords),
    })
    .strict()

export const RequirementSchema = z.object({
    requirements: z.array(z.string()),
})

import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory, useLocation } from 'react-router-dom'

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import { Node, Schema } from '@tiptap/pm/model'
import { Content } from '@tiptap/react'

import useLDFlags from 'data/hooks/useLDFlags'

import { Tooltip } from 'v2/ui'
import useDebounce from 'v2/ui/utils/useDebounce'
import useRefCallback from 'v2/ui/utils/useRefCallback'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Icon } from 'ui/components/Icon'

import { ActivityBubble } from './Extensions/Activities/ActivityBubble'
import { createActivityMark } from './Extensions/Activities/ActivityMarkExtension'
import { useDocumentLinkExtension } from './Extensions/DocumentLinkExtension'
import { ExcalidrawExtension } from './Extensions/Excalidraw/ExcalidrawExtension'
import { useKeyboardShortcutsExtension } from './Extensions/KeyboardShortcutExtension'
import { createPositionNavigatorExtension } from './Extensions/PositionNavigatorExtension'
import { TipTapEditor, TipTapEditorHandle } from './TipTapEditor'
import { useTipTapCollab } from './useTipTapCollab'
import { isSchemaVersionSupported } from './utilities'

// Make sure to bump this when changing the schema, otherwise data will be lost.
const SCHEMA_VERSION = 1

type TipTapDocumentEditorProps = React.ComponentPropsWithRef<typeof TipTapEditor> & {
    content: Content
    onChange: (
        content: Content,
        plainTextContent: string,
        references: DocumentDto['references']
    ) => void
    collaborationId?: string
    collaborationToken?: string
    documentId?: number
    stackId?: string
    recordId?: string
    fieldId?: string
    allowComments?: boolean
    dereferencedDocuments?: DocumentDto[]
    parentRecordId?: string
    documentControlsContainer?: HTMLElement | null
    documentControls?: React.ReactNode
}
export const TipTapDocumentEditor = React.forwardRef<TipTapEditorHandle, TipTapDocumentEditorProps>(
    (
        {
            content,
            onChange,
            documentId,
            collaborationId,
            collaborationToken,
            additionalExtensions = [],
            recordId,
            fieldId,
            allowComments = true,
            dereferencedDocuments,
            parentRecordId,
            documentControlsContainer,
            documentControls,
            isLoading,
            readOnly,
            renderContent,
            ...props
        }: TipTapDocumentEditorProps,
        ref
    ) => {
        const [editorRef, setEditorRef] = useRefCallback<TipTapEditorHandle>()
        const [currentContent, setCurrentContent] = useState<Content | undefined>(content)
        const [isSynced, setIsSynced] = useState(true)
        const documentLinkExtension = useDocumentLinkExtension({
            documentId,
            recordId: recordId ?? parentRecordId,
            documents: dereferencedDocuments,
        })
        const keyboardShortcuts = useKeyboardShortcutsExtension({
            name: 'shortcuts',
            shortcuts: {
                'Mod-Enter': () => {
                    return true
                },
            },
        })

        const usingCollab = !!collaborationId && !!collaborationToken
        const { extensions: collabExtensions, isLoading: collabIsLoading } = useTipTapCollab({
            editor: editorRef.current?.editor,
            content,
            collaborationId,
            collaborationToken,
        })

        const extensions = [
            keyboardShortcuts,
            createActivityMark({
                hideMark: !allowComments,
            }),
            createPositionNavigatorExtension(),
            documentLinkExtension,
            ...collabExtensions,
            ...additionalExtensions,
        ]

        const { flags } = useLDFlags()

        if (flags.excalidraw) {
            extensions.push(ExcalidrawExtension)
        }

        const documentIsLoading = isLoading || collabIsLoading

        const isSchemaSupported = isSchemaVersionSupported(currentContent, SCHEMA_VERSION)
        const effectiveReadOnlyState = readOnly || !isSchemaSupported

        const handleChange = (content: Content) => {
            if (!isSchemaSupported) return

            const plainTextContent = editorRef.current?.editor?.getText() ?? ''
            const references = {
                records: editorRef.current?.getRecordLinks() ?? [],
            }
            setCurrentContent(content)
            // Only calling the change handler if we're not in collaboration mode. In that mode
            // changes are synced to the DB via the collab provider.
            if (!usingCollab) onChange(content, plainTextContent, references)
        }

        const finalRef = useComposedRefs(ref, setEditorRef)

        const history = useHistory()
        const location = useLocation()
        useEffect(() => {
            const hash = location.hash
            const editor = editorRef?.current?.editor
            if (!editor || !hash) return

            setTimeout(() => {
                const hashUri = decodeURIComponent(hash.slice(1))
                const handled = editor.commands.restorePosition(hashUri, fieldId)
                if (handled) {
                    history.replace({
                        ...location,
                        hash: '',
                    })
                }
            }, 500)
        }, [editorRef, location.hash, history, location, fieldId])

        const schema = editorRef.current?.editor?.schema

        const checkIsSynced = useDebounce(
            useCallback((schema: Schema, serverContent: Content, clientContent: Content) => {
                try {
                    const fromServer = Node.fromJSON(schema, serverContent)
                    const fromClient = Node.fromJSON(schema, clientContent)
                    const isSynced = fromServer.eq(fromClient)
                    if (!isSynced) console.log('Not synced', serverContent, clientContent)
                    setIsSynced(isSynced)
                } catch (e) {
                    console.error(e)
                    setIsSynced(false)
                }
            }, []),
            1000
        )

        useEffect(() => {
            if (!usingCollab || !schema || !content || !currentContent) return

            checkIsSynced(schema, content, currentContent)
        }, [checkIsSynced, content, currentContent, schema, usingCollab])

        return (
            <>
                {documentControlsContainer &&
                    ReactDOM.createPortal(
                        <Box flex center gap="xs">
                            {!isSchemaSupported && <OutdatedSchemaIndicator />}
                            {!effectiveReadOnlyState && <SyncIndicator isSynced={isSynced} />}
                        </Box>,
                        documentControlsContainer
                    )}
                <TipTapEditor
                    ref={finalRef}
                    {...props}
                    // Only provide content here if we're not in collab mode.
                    content={!usingCollab ? content : undefined}
                    onChange={handleChange}
                    additionalExtensions={extensions}
                    documentStyle
                    disableHistory={usingCollab}
                    allowMentions={false}
                    allowComments={allowComments}
                    isLoading={documentIsLoading}
                    schemaVersion={SCHEMA_VERSION}
                    readOnly={effectiveReadOnlyState}
                    renderContent={renderContent}
                >
                    <Box flex center gap="xs" position="absolute" right="m" top="m">
                        {usingCollab && !effectiveReadOnlyState && !documentControlsContainer && (
                            <>
                                {!isSchemaSupported && <OutdatedSchemaIndicator />}
                                <SyncIndicator isSynced={isSynced} />
                            </>
                        )}
                        {documentControls}
                    </Box>
                </TipTapEditor>
                {allowComments && (
                    <ActivityBubble
                        editor={editorRef.current?.editor ?? undefined}
                        documentId={documentId}
                        recordId={recordId}
                        fieldId={fieldId}
                    />
                )}
            </>
        )
    }
)

function SyncIndicator({ isSynced, ...props }: BoxProps & { isSynced: boolean }) {
    return (
        <Box {...props}>
            <Tooltip
                label={isSynced ? 'Synced to the server' : 'Syncing with the server'}
                placement="bottom"
            >
                <Icon
                    name={isSynced ? 'CheckCircle2' : 'CircleEllipsis'}
                    color={isSynced ? 'success600' : 'warning600'}
                    size="m"
                    opacity={0.7}
                />
            </Tooltip>
        </Box>
    )
}

const OutdatedSchemaIndicator = (props: BoxProps) => {
    return (
        <Tooltip
            label="Editing is disabled. Refresh your browser to get the latest version."
            placement="bottom"
        >
            <Box flex center {...props}>
                <Icon name="AlertTriangle" color="warning600" opacity={0.7} />
                <Box fontSize="bodyS" color="warning600" ml="xs">
                    App out of date
                </Box>
            </Box>
        </Tooltip>
    )
}

import React, { memo, useMemo } from 'react'
// @ts-ignore
import { components } from 'react-select'

import { useAppUsers, useWorkspaceUsers } from 'data/hooks/users/main'
import { UserDisplay } from 'features/users/shared/UserDisplay'

import { Dropdown } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { ButtonStyles } from 'ui/components/Button/Button.css'
import { Icon } from 'ui/components/Icon'
import { makeComponent } from 'ui/helpers/recipes'
import { theme } from 'ui/styling/Theme.css'

import { getUserDisplayName, orderUsers } from './assigneeUtils'
import { TaskAssignee } from './types'

import { TasksAssigneesEditorValueStyle } from './TasksAssigneesEditor.css'

type Data = {
    user: UserDto
    label: string
}

type TasksAssigneesEditorProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: TaskAssignee[]
    onChange: (value: TaskAssignee[]) => void
    variant?: 'secondary' | 'tertiary'
    currentUserId?: string
    onOpen?: () => void
    onClose?: () => void
    compact?: boolean
    placeholder?: React.ReactNode
    related_to_stack?: string
}

export const TasksAssigneesEditor: React.FC<TasksAssigneesEditorProps> = memo(
    ({
        value,
        onChange,
        currentUserId,
        onOpen,
        onClose,
        compact,
        variant = 'secondary',
        placeholder = 'no assignee',
        related_to_stack,
        ...props
    }) => {
        const { data: workspaceUsers } = useWorkspaceUsers()
        const { data: stackUsers } = useAppUsers(undefined, { stackId: related_to_stack })

        // If we're working on a task related to a stack, then we want to show
        // only users with access to that app
        const users = related_to_stack ? stackUsers : workspaceUsers

        const orderedUsers: UserDto[] = useMemo(() => orderUsers(users), [users])
        const userOptions = useMemo(
            () =>
                orderedUsers.map((user) => ({
                    value: user._sid,
                    user,
                    label: user.name || user.email,
                })),
            [orderedUsers]
        )

        const selectedValue = value?.[0]
        return (
            <Box {...props}>
                <Dropdown
                    placeholder={placeholder}
                    options={userOptions}
                    value={selectedValue}
                    onMenuOpen={onOpen}
                    onMenuClose={onClose}
                    // containerComponent={DropdownContainer}
                    container
                    controlComponent={
                        variant === 'secondary' ? DropdownControlSecondary : DropdownControlTertiary
                    }
                    optionComponent={UserOption}
                    isClearable
                    onChange={(value: string) => (value ? onChange([value]) : onChange([]))}
                    renderValue={({ user, label }: Data) =>
                        user ? (
                            !compact ? (
                                <UserDisplay
                                    className={TasksAssigneesEditorValueStyle}
                                    user={{
                                        ...user,
                                        name: getUserDisplayName(user, currentUserId),
                                    }}
                                    showName={false}
                                />
                            ) : (
                                <Box fontWeight="bodyRegular" fontSize="bodyS">
                                    @{getUserDisplayName(user, currentUserId)}
                                </Box>
                            )
                        ) : (
                            label
                        )
                    }
                    valueContainerProps={{ padding: '0', fontSize: theme.fontSizes.bodyS }}
                    dropdownIndicatorProps={{
                        padding: '0px',
                        margin: '0px',
                        display: 'none',
                    }}
                    clearIndicatorProps={{
                        padding: '0px',
                        margin: '0px',
                        color: theme.color.textWeak,
                        opacity: 1,
                        display: 'block',
                    }}
                    menuProps={{ width: '200px' }}
                    components={{ ClearIndicator }}
                    additionalStyles={{
                        indicatorsContainer: () => ({
                            display: selectedValue ? 'flex' : 'none',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }),
                        indicatorSeparator: () => ({
                            display: 'none',
                        }),
                    }}
                    color={theme.input.color.textPlaceholder}
                    optionProps={{
                        color: theme.color.text,
                    }}
                />
            </Box>
        )
    }
)

type UserOptionsProps = {
    data?: Data
    onMouseMove?: () => void
    onMouseOver?: () => void
}

const UserOption: React.FC<UserOptionsProps> = (props) => {
    const user = props.data?.user
    // dropping these two handlers for perf reasons.
    // We handle the highlighting via CSS anway.
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props

    if (!user) return null

    return (
        <components.Option {...rest}>
            <UserDisplay user={user} />
        </components.Option>
    )
}

const DropdownButton = makeComponent(components.Control, ButtonStyles)

type DropdownControlProps = {
    getStyles: () => React.CSSProperties
}

// Prevent controls from overriding styles.
// eslint-disable-next-line unused-imports/no-unused-vars
const DropdownControlSecondary: React.FC<DropdownControlProps> = ({ getStyles, ...props }) => {
    return (
        <DropdownButton
            variant="secondary"
            size="s"
            {...props}
            textAlign="center"
            getStyles={() => {}}
        />
    )
}
// eslint-disable-next-line unused-imports/no-unused-vars
const DropdownControlTertiary: React.FC<DropdownControlProps> = ({ getStyles, ...props }) => {
    return (
        <DropdownButton
            variant="ghost"
            size="s"
            {...props}
            textAlign="center"
            getStyles={() => {}}
        />
    )
}

type ClearIndicatorProps = {
    getStyles: (name: string, props: {}) => React.CSSProperties
    innerProps: {
        ref: React.RefObject<SVGSVGElement>
    }
}

const ClearIndicator: React.FC<ClearIndicatorProps> = (props) => {
    const {
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props
    return (
        <Icon
            ref={ref}
            name="X"
            role="button"
            opacity={0.5}
            {...restInnerProps}
            style={getStyles('clearIndicator', props)}
        />
    )
}

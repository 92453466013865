import { useContext, useMemo } from 'react'

import { AppUserContext } from 'app/AppUserContext'
import { buildNavTree } from 'features/admin/settings/navigation/NavigationUtils'
import { PathContext } from 'features/utils/PathContext'

export function useNavTree({ navigation, views, pages, objects }) {
    const pathContext = useContext(PathContext)
    const routeUrl = pathContext.pageUrl
    const { role } = useContext(AppUserContext)

    const navTree = useMemo(() => {
        const tree = buildNavTree(navigation, views, pages)

        return tree
            .filter((item) => !item.hidden || item.children)
            .map((item) => {
                let topLevelItem

                if (!item.children) return { display: false } // Don't show at all if no available children

                let children = item.children.filter((child) => !child.hidden)
                let topLevelUrl
                let isFolder
                if (!children.length) return { display: false }
                if (children.length === 1) {
                    // If we've only got one child, display that a the top level instead
                    isFolder = false
                    topLevelItem = children[0]
                    children = []
                } else {
                    isFolder = true
                    topLevelItem = item
                    topLevelItem.url = children[0].url
                    topLevelUrl = children[0].url // If we've got multiple children, clicking on this takes us to the first child
                }

                // Are we on this route?
                let isActive = topLevelItem.url === routeUrl
                // Are we on a child's route?
                let isOnChildRoute = children.some((child) => child.url === routeUrl)

                // Am I a folder, and is one of my object detail or create pages being displayed?
                const object = objects.find((o) => o._sid === item.object_id)

                if (object && pathContext.object && object._sid === pathContext.object._sid) {
                    if (
                        pathContext.view &&
                        ['create', 'detail', 'blankpage'].includes(pathContext.view.type)
                    ) {
                        isOnChildRoute = true
                        isActive = true
                    }
                }
                const view = views.find((v) => v.url === topLevelItem.url)

                return {
                    item,
                    view,
                    isOnChildRoute,
                    isActive,
                    object,
                    isFolder,
                    topLevelItem,
                    topLevelUrl,
                    children,
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        navigation,
        views,
        pages,
        pathContext.object,
        pathContext.view,
        role /* role *is* required*/,
    ])

    return navTree
}

import { TaskActions } from 'data/hooks/tasks/tasks'
import { AssigneesDisplayMode, GetTasksPayload, SourceDisplayMode } from 'data/hooks/tasks/types'

export type TaskListContextType = GetTasksPayload &
    TaskActions & {
        related_to?: string
        emptyContext?: boolean
        _object_id?: string
        showAssignees?: AssigneesDisplayMode
        showSource?: SourceDisplayMode
    }

export const defaultTaskListContext: TaskListContextType = {
    tasks: [],
    createTask: () => Promise.reject(),
    updateTask: () => Promise.reject(),
    deleteTask: () => Promise.reject(),
    emptyContext: true,
}

import React from 'react'

import { LoadingState } from 'features/views/List/ui/utils'

import ListViewTitle from './ListViewTitle'
import { CustomLoadingState } from './types'

type ListViewLoadingStateProps = {
    setConfig: (patch: Partial<ViewDto>, shouldSave?: boolean) => void
    showControls?: boolean
    display?: string
    object?: ObjectDto
    title?: string
    isMobile?: boolean
    failed?: boolean
    loadingTimedOut?: boolean
    customLoader?: CustomLoadingState
}

const ListViewLoadingState: React.FC<ListViewLoadingStateProps> = ({
    setConfig,
    display,
    object,
    showControls,
    title,
    isMobile,
    failed,
    loadingTimedOut,
    customLoader: CustomLoader,
}) => {
    if (CustomLoader) {
        return (
            <CustomLoader
                failed={failed}
                loadingTimedOut={loadingTimedOut}
                title={title}
                object={object}
            />
        )
    }

    return (
        <>
            <ListViewTitle
                display={display}
                showControls={showControls}
                title={title}
                setConfig={setConfig}
                isMobile={isMobile}
            />
            <LoadingState
                loadingError={failed}
                loadingTimedOut={loadingTimedOut}
                errorCode={1}
                object={object}
            />
        </>
    )
}

export default ListViewLoadingState

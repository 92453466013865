import React, { useMemo } from 'react'

import { InlineFilter } from 'features/records/components/RecordFilters/inlineFilters/InlineFilter'
import { useInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/useInlineFilter'
import { getInlineFilterValueLabels } from 'features/records/components/RecordFilters/inlineFilters/valueUtils'

import type { InlineFilterComponent } from './types'

export const NumberInlineFilter: InlineFilterComponent<{}> = ({ field, columns }) => {
    const { filter, ...inlineFilterProps } = useInlineFilter({ field })
    const valueLabels = useMemo(() => getInlineFilterValueLabels(filter, []), [filter])

    return (
        <InlineFilter
            field={field}
            columns={columns}
            closeOnSelect
            valueLabels={valueLabels}
            {...inlineFilterProps}
        />
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'
import { StandardComponentProps } from 'ui/helpers/styles'

type ZoneLogoProps = StandardComponentProps & {
    zone: AccountZone
    isSidebar?: boolean
    style?: React.CSSProperties
}

export function ZoneLogo({ zone, isSidebar = false, ...props }: ZoneLogoProps) {
    if (zone.branding === 'Logo' && zone.logo_url) {
        return <Box as="img" src={zone.logo_url} alt={zone.display_name} {...props} />
    }

    if (zone.branding === 'Icon' && zone.logo_icon_url) {
        return <Box as="img" src={zone.logo_icon_url} alt={zone.display_name} {...props} />
    }

    return (
        <Headline
            size={isSidebar ? 'xs' : 'l'}
            maxLength={isSidebar ? 19 : undefined}
            textAlign="center"
            {...props}
        >
            {zone.display_name}
        </Headline>
    )
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import orderBy from 'lodash/orderBy'

import settings from 'app/settings'

const pollingInterval = 10

// A service which subscribes to performance notifications from the browser
// as well as takes periodic samples of the main thread's availability.
class MonitorService {
    constructor() {
        this.timerStart = 0
        this.periodStart = 0
        this.recordingStart = 0
        this.busyTotal = 0
        this.timerHandle = null
        this.isBusy = false
        this.logEntries = []
        this.longTaskTotal = 0
        this.paused = false
        this.enabled = false
        this.observer = null
    }

    recordEntry = (type, notes, start, duration) => {
        if (this.paused || !this.enabled) return
        if (type === 'busy') {
            this.busyTotal += duration
        }
        this.logEntries.push({
            start: (start || window.performance.now()) - this.recordingStart,
            type,
            notes,
            duration: duration || 0,
        })
    }

    // This timer fires periodically to check the availability of the main thread.
    startTimer = () => {
        this.timerStart = window.performance.now()
        if (!this.periodStart) {
            this.periodStart = this.timerStart
        }

        this.timerHandle = setTimeout(this.checkResponsiveness, pollingInterval)
    }

    recordPerformanceEntries = (list) => {
        if (this.paused) return

        list.getEntries().forEach((entry) => {
            switch (entry.entryType) {
                case 'longtask':
                    this.longTaskTotal += entry.duration
                    break
                default:
                    this.recordEntry(entry.entryType, entry.name, entry.startTime, entry.duration)
                    break
            }
        })
    }

    startRecording = () => {
        this.enabled = true
        document.addEventListener('visibilitychange', this.handleDocumentVisibilityChange)
        document.addEventListener('mousedown', this.handleMouseDown)

        // Subscribe to profiling events from the browser. This will give us
        // stats related to resources downloaded, long CPU tasks, etc.
        this.observer = new PerformanceObserver(this.recordPerformanceEntries)
        this.observer.observe({
            entryTypes: ['longtask', 'navigation', 'frame', 'resource', 'paint'],
        })

        this.recordingStart = window.performance.now()
        this.startTimer()
    }

    stopRecording = () => {
        this.enabled = false
        document.removeEventListener('visibilitychange', this.handleDocumentVisibilityChange)
        document.removeEventListener('mousedown', this.handleMouseDown)

        if (this.observer) {
            this.observer.disconnect()
        }
        clearTimeout(this.timerHandle)
        this.logEntries = []
        this.busyTotal = 0
        this.longTaskTotal = 0
        this.timerStart = 0
        this.isBusy = null
        this.periodStart = null
    }

    toggleRecording = () => {
        if (this.enabled) {
            this.stopRecording()
        } else {
            this.startRecording()
        }
    }

    pause = () => {
        clearTimeout(this.timerHandle)
        this.isBusy = null
        this.periodStart = null
        this.paused = true
    }
    resume = () => {
        this.isBusy = null
        this.periodStart = null
        this.paused = false
        this.startTimer()
    }
    reset = () => {
        this.stopRecording()
        this.startRecording()
    }

    checkResponsiveness = () => {
        const currentTime = window.performance.now()
        const diff = currentTime - this.timerStart

        // If we received the callback from our timer more than 20ms after we should have
        // then we can surmise that the main thread was busy during that time. We can measure
        // the amount of time that ellapsed and see how long the main thread was tied up.
        if (diff > pollingInterval + 20) {
            // If we were previously not busy, note the time we began being busy again
            // and set the flag
            if (!this.isBusy) {
                this.periodStart = this.timerStart
                this.isBusy = true
            }

            // If this is our first non-busy result, log the total busy time
        } else if (this.isBusy) {
            this.recordEntry('busy', null, this.periodStart, this.timerStart - this.periodStart)
            this.isBusy = false
            this.periodStart = null
        }

        if (!this.paused && !document.hidden) this.startTimer()
    }

    // don't want to keep running the timer if the tab is not active
    handleDocumentVisibilityChange = () => {
        if (document.hidden) {
            this.pause()
        } else {
            this.resume()
        }
    }

    handleMouseDown = () => {
        this.recordEntry('mouse down event')
    }
}

const service = new MonitorService()

const DEFAULT_THRESHOLD = 1000
export function PerformanceMonitor() {
    const location = useLocation()
    const [paused, setPaused] = useState(false)
    const statusElementRef = useRef()
    const metricsElementRef = useRef()
    const thresholdRef = useRef(DEFAULT_THRESHOLD)
    const [enabled, setEnabled] = useState(false)
    const [resetOnNavigate, setResetOnNavigate] = useState(true)
    const [threshold, setThresholdState] = useState(DEFAULT_THRESHOLD)
    const statusUpdateInterval = useRef()

    const setThreshold = (value) => {
        thresholdRef.current = value
        setThresholdState(value)
    }
    const handleHotKey = useCallback((event) => {
        if (event.ctrlKey || event.metaKey) {
            if (event.key === 'p') {
                setEnabled(!service.enabled)
                service.toggleRecording()
            }
        }
    }, [])

    useEffect(() => {
        if (enabled) {
            // Periodically check the service's stats and print those to the screen.
            statusUpdateInterval.current = setInterval(() => {
                if (!metricsElementRef.current || !service.enabled) return

                const busyTotal = service.busyTotal.toFixed(0)
                let content = `Busy for ${busyTotal}ms. \nTask total: ${service.longTaskTotal.toFixed(
                    0
                )}ms.`

                if (!service.isBusy && service.periodStart) {
                    metricsElementRef.current.innerText =
                        content +
                        `\nIdle for ${(
                            (window.performance.now() - service.periodStart) /
                            1000
                        ).toFixed(2)}s`
                } else {
                    metricsElementRef.current.innerText = content + '\n-'
                }

                // for automate tests (such as reflect), if our busy time
                // exceeds the specified threshold, change the status text
                // too Degraded. Reflect tests can be designed to look for this value
                // and fail if operations take longer than expected
                statusElementRef.current.innerText =
                    busyTotal > thresholdRef.current ? 'Degraded' : 'Acceptable'
            }, 400)
        } else {
            clearInterval(statusUpdateInterval.current)
        }
    }, [enabled])

    useEffect(() => {
        // Allow enabling via a URL param
        const enabledByDefault = window.location.search?.includes('profile=1')
        if (enabledByDefault) {
            setEnabled(true)
            service.startRecording()
        }

        // Or a hot-key--except on Prod.
        if (!settings.IS_PROD) {
            document.addEventListener('keydown', handleHotKey)
        }

        return () => {
            document.removeEventListener('keydown', handleHotKey)
            clearInterval(statusUpdateInterval.current)
        }
    }, [handleHotKey])

    // Prints generates a table of the profiling data and copies to clipboard.
    const print = () => {
        const orderedEntries = orderBy(service.logEntries, 'start')
        const data = orderedEntries.concat([
            {
                start: 'busy total',
                duration: service.busyTotal,
            },
            { start: 'long tasks total', duration: service.longTaskTotal },
        ])
        console.table(data)
        copyToClipboard(toCsv(data))
    }

    const toggle = () => {
        const wasPaused = paused
        setPaused(!paused)
        if (wasPaused) {
            service.resume()
        } else {
            service.pause()
        }
    }

    const locationUrl = location.pathname + location.search + location.hash
    useEffect(() => {
        if (!service.enabled) return

        service.recordEntry('location', locationUrl)
        if (resetOnNavigate) {
            service.reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationUrl])

    if (!enabled) return null
    return (
        <div
            style={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                zIndex: 9999999,
                background: 'white',
                padding: '10px',
            }}
        >
            <button onClick={print}>Print</button>
            <button onClick={toggle}>{paused ? 'Start' : 'Pause'}</button>
            <button onClick={service.reset}>Reset</button>
            <div style={{ marginTop: '4px', fontSize: '.75rem' }}>
                <input
                    type="checkbox"
                    checked={resetOnNavigate}
                    onChange={(e) => setResetOnNavigate(e.target.checked)}
                />
                reset on navigate
            </div>
            <div style={{ marginTop: '4px', marginBottom: '4px' }}>
                <div style={{ fontSize: '.75rem', color: '#aaa' }}>Degradation Threshold</div>
                <input
                    name="performance_threshold"
                    type="text"
                    value={threshold}
                    onChange={(e) => setThreshold(parseInt(e.target.value))}
                />
            </div>
            <div ref={metricsElementRef}></div>
            <div ref={statusElementRef} id="performance_status"></div>
        </div>
    )
}

function toCsv(array, suppliedFields) {
    let fields = suppliedFields || Object.keys(array[0])
    let result = fields.join('\t')
    for (let row of array) {
        if (result) result = result + '\n'
        result = result + fields.map((field) => row[field]).join('\t')
    }
    return result
}
function copyToClipboard(value) {
    var copy = function (e) {
        e.preventDefault()
        if (e.clipboardData) {
            e.clipboardData.setData('text/plain', value)
        } else if (window.clipboardData) {
            window.clipboardData.setData('Text', value)
        }
    }
    window.addEventListener('copy', copy)
    document.execCommand('copy')
    window.removeEventListener('copy', copy)
}

export default PerformanceMonitor

import React from 'react'
import { Row } from 'react-table'

import { ViewCellBaseProps, ViewHeader } from 'v2/views/List/types'

import { RowsCell } from './RowsCell'

type RowsListCellListProps = {
    header?: ViewHeader[]
    hideFieldLabels?: boolean
    showEmptyValues?: boolean
    inboxView?: boolean
    useClassNames?: boolean
    object?: ObjectDto
    viewOptions?: ListViewOptions
    setConfig?: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    editControlsVisible?: boolean
    cells?: ViewCellBaseProps[]
    row: Row<RecordDto>
}

export const RowsListCellList: React.FC<RowsListCellListProps> = ({
    header = [],
    hideFieldLabels = true,
    showEmptyValues,
    inboxView = false,
    object,
    viewOptions,
    setConfig,
    useClassNames = true,
    cells,
    row,
}) => {
    return (
        <>
            {cells?.map(({ label, value, originalValue, column }, index) => (
                <RowsCell
                    key={`${label}-${value}`}
                    header={header[index]?.id}
                    label={label}
                    value={value}
                    originalValue={originalValue}
                    column={column}
                    cells={cells}
                    index={index}
                    showEmptyValues={showEmptyValues}
                    hideFieldLabels={hideFieldLabels}
                    isInbox={inboxView}
                    viewOptions={viewOptions}
                    setConfig={setConfig}
                    dataIndex={index}
                    object={object}
                    useClassNames={useClassNames}
                    row={row}
                />
            ))}
        </>
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

import { useDeleteViewConfirmModalState } from './hooks/useDeleteViewConfirmModalState'

type DeleteViewConfirmModalProps = {
    viewSid: string
    isOpen?: boolean
    onOpenChange?: (open: boolean) => void
}

export const DeleteViewConfirmModal: React.FC<DeleteViewConfirmModalProps> = ({
    viewSid,
    isOpen,
    onOpenChange,
}) => {
    const { onDelete, isError, handleOpenChange } = useDeleteViewConfirmModalState({
        viewSid,
        onOpenChange,
    })

    return (
        <Modal open={isOpen} onOpenChange={handleOpenChange}>
            <ModalContent>
                <ModalHeader
                    subtitle="Are you sure you want to delete this layout?"
                    title="Delete layout"
                    showCloseButton={true}
                />
                <ModalFooter flexDirection="column">
                    <ModalFooterButtonGroup layout="inline">
                        <ModalCloseTrigger asChild>
                            <Button size="l" variant="ghost">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                        <Button size="l" variant="destructive" onClick={onDelete}>
                            Confirm
                        </Button>
                    </ModalFooterButtonGroup>
                    {isError && (
                        <Box mt="l">
                            <Body size="m" color="textError">
                                There was a problem deleting your layout. Please try again or
                                contact support.
                            </Body>
                        </Box>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

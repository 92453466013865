import { queryClient } from 'data/hooks/_helpers'
import { invalidateAppErrors } from 'data/hooks/appErrors'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import {
    PERMISSION_RULE_CREATED,
    PERMISSION_RULE_FETCHED,
    PERMISSION_RULE_REMOVED,
    PERMISSION_RULE_UPDATED,
    REACT_QUERY,
} from 'data/utils/constants'
import { StackerActions } from 'data/utils/stackerActions'
import { StackerAPI } from 'data/utils/utils'

class PermissionRulesApi extends StackerAPI {
    path = 'permissions/rules/'
    bypassPreviewAs = true
}
const permissionRulesApi = new PermissionRulesApi()

class PermissionRuleActions extends StackerActions {
    api = permissionRulesApi

    reducer = 'permissionRules'

    name = 'permissionRules'

    actionTypes = {
        fetched: PERMISSION_RULE_FETCHED,
        removed: PERMISSION_RULE_REMOVED,
        created: PERMISSION_RULE_CREATED,
        updated: PERMISSION_RULE_UPDATED,
    }

    // When a rule is created, the group data is stale.
    afterCreate = () => {
        invalidateObjects()
        return queryClient.invalidateQueries(REACT_QUERY.roles.listName)
    }

    // When a rule is updated the group data MAY be stale.
    afterUpdate = () => {
        invalidateAppErrors()
        invalidateObjects()
        return queryClient.invalidateQueries(REACT_QUERY.roles.listName)
    }
}

const PRA = new PermissionRuleActions()
PRA.actions = { ...PRA.actions, fetchPermissionRules: PRA.fetchPermissionRules }
export const permissionRuleActions = PRA.actions

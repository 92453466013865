import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

import { Icon } from 'ui/components/Icon'
import {
    ComplexIconStyles,
    ContentStyles,
    IconStyles,
    ItemIndicatorIconStyles,
    ItemIndicatorPlaceholderStyles,
    ItemIndicatorStyles,
    ItemStyles,
    LabelStyles,
    LeftSlotStyles,
    RightSlotStyles,
    SeparatorStyles,
    SubTriggerStyles,
} from 'ui/components/Menu/Menu.css'
import EmptyStyles from 'ui/helpers/emptyStyles.css'
import { makeComponent } from 'ui/helpers/recipes'

export const Root = DropdownMenuPrimitive.Root
export const Trigger = makeComponent(DropdownMenuPrimitive.Trigger, EmptyStyles)
export const Portal = DropdownMenuPrimitive.Portal
export const Content = makeComponent(DropdownMenuPrimitive.Content, ContentStyles)
export const Item = makeComponent(DropdownMenuPrimitive.Item, ItemStyles)
export const LeftSlot = makeComponent('div', LeftSlotStyles)
export const RightSlot = makeComponent('div', RightSlotStyles)
export const MenuIcon = makeComponent(Icon, IconStyles)
export const MenuIconComplex = makeComponent('div', ComplexIconStyles)
export const CheckboxItem = makeComponent(DropdownMenuPrimitive.CheckboxItem, ItemStyles)
export const ItemIndicator = makeComponent(DropdownMenuPrimitive.ItemIndicator, ItemIndicatorStyles)
export const ItemIndicatorIcon = makeComponent(Icon, ItemIndicatorIconStyles)
export const ItemIndicatorPlaceholder = makeComponent('div', ItemIndicatorPlaceholderStyles)
export const Label = makeComponent(DropdownMenuPrimitive.Label, LabelStyles)
export const Separator = makeComponent(DropdownMenuPrimitive.Separator, SeparatorStyles)
export const Sub = DropdownMenuPrimitive.Sub
export const SubTrigger = makeComponent(DropdownMenuPrimitive.SubTrigger, SubTriggerStyles)
export const SubContent = makeComponent(DropdownMenuPrimitive.SubContent, ContentStyles)

import { useMemo } from 'react'

import { NavigationApp } from 'features/navigation/types'

import { useNavigationContext } from './useNavigationContext'

type UseNavigationAppsItemStateProps = {
    app: NavigationApp
}

export function useNavigationAppsItemState({ app }: UseNavigationAppsItemStateProps) {
    const { id } = app

    const { selectedApp } = useNavigationContext()

    const isActive = selectedApp?.id === id

    return useMemo(
        () => ({
            isActive,
        }),
        [isActive]
    )
}

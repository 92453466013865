import React, { useMemo } from 'react'

import { Switch } from '@chakra-ui/react'

import { HELP_LINKS } from 'app/helpLinks'
import {
    filterCompatibleFields,
    filterSelectedFields,
    SelectedItem,
} from 'features/records/utils/userSpecificFilters'

import { Box, Flex, Text } from 'v2/ui'
import FieldListEditor from 'v2/ui/components/FieldsEditor/FieldListEditor'

type InlineFiltersControlsProps = {
    config: Partial<ListViewOptions>
    setConfig: (config: Partial<ListViewOptions>) => void
    fields: FieldDto[]
    object: ObjectDto
}

const InlineFiltersControls = ({
    config,
    setConfig,
    fields,
    object,
}: InlineFiltersControlsProps) => {
    const compatibleFields = useMemo(() => filterCompatibleFields(fields), [fields])

    const selectedItems = useMemo(() => {
        return filterSelectedFields(config?.specificEndUserFilters ?? [], compatibleFields)
    }, [compatibleFields, config?.specificEndUserFilters])

    const handleUpdateSelectedItems = (selectedItems: SelectedItem[]) => {
        setConfig({
            ...config,
            specificEndUserFilters: selectedItems.map((item) => item.fieldId),
        })
    }

    return (
        <Flex direction="column" w="400px" alignItems="flex-start">
            <>
                <Text fontSize="12px">
                    Activate inline filters to enable end users of your application to apply
                    filters. You can also add filters for fields hidden from the list view.
                </Text>
                <a
                    style={{
                        fontSize: '12px',
                        textDecoration: 'underline',
                        marginBottom: '10px',
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href={HELP_LINKS.INLINE_FILTERS}
                >
                    Learn more
                </a>
            </>
            <Flex direction="row" justifyContent="space-between" w="100%">
                <Text fontSize="14px">Activate inline filters</Text>
                <Switch
                    isChecked={config.enableEndUserFilters}
                    onChange={(e) =>
                        setConfig({
                            enableEndUserFilters: (e.target as any).checked,
                        })
                    }
                />
            </Flex>
            {config.enableEndUserFilters && (
                <Flex direction="row" justifyContent="space-between" w="100%" mt="10px">
                    <Text fontSize="14px">Select specific fields</Text>
                    <Switch
                        isChecked={config.enableSpecificEndUserFilters}
                        onChange={(e) =>
                            setConfig({
                                enableSpecificEndUserFilters: (e.target as any).checked,
                            })
                        }
                    />
                </Flex>
            )}
            {config.enableEndUserFilters && config.enableSpecificEndUserFilters && (
                <Box mt="10px" w="100%">
                    <FieldListEditor
                        object={object}
                        fields={compatibleFields}
                        selectedItems={selectedItems}
                        disableReorder={false}
                        allowEditSections={false}
                        disallowSections={true}
                        onUpdate={handleUpdateSelectedItems}
                        maxHeight="200px"
                    />
                </Box>
            )}
        </Flex>
    )
}

export default InlineFiltersControls

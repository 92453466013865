import { useCallback, useMemo } from 'react'

import { useCreateZoneModal } from 'features/NewAppBar/ZoneMenu/useCreateZoneModal'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationPortalsState() {
    const { portals, permissions } = useNavigationContext()

    const canCreatePortal = permissions.canCreatePortal

    const { show: showCreateZoneModal } = useCreateZoneModal()
    const createPortal = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            showCreateZoneModal(e)
        },
        [showCreateZoneModal]
    )

    return useMemo(
        () => ({
            portals,
            canCreatePortal,
            createPortal,
        }),
        [canCreatePortal, createPortal, portals]
    )
}

import React from 'react'

import { NumberAttributeDisplay } from 'features/views/attributes/NumberAttributeDisplay'
import { NumberAttributeTag } from 'features/views/attributes/NumberAttributeTag'

import { BaseAttribute } from './BaseAttribute'
import { CardViewAttributeComponent } from './types'

const MAX_LINES = 1
const MAX_LENGTH = 40
const MAX_LENGTH_TAG = 20

type NumberAttributeProps = {}

export const NumberAttribute: CardViewAttributeComponent<number, NumberAttributeProps> = ({
    value,
    attribute,
    isLoading,
    displayStyle,
    ...props
}) => {
    const { field, type } = attribute

    const isEmpty = !isLoading && typeof value === 'undefined'

    const isFooter = type === 'footer'

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {displayStyle === 'tags' ? (
                <NumberAttributeTag
                    value={value}
                    field={field}
                    maxLines={MAX_LINES}
                    maxLength={MAX_LENGTH_TAG}
                    isLoading={isLoading}
                />
            ) : (
                <NumberAttributeDisplay
                    style={{
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        letterSpacing: 'inherit',
                        fontWeight: 'inherit',
                        display: isFooter ? 'block' : undefined,
                        maxWidth: isFooter ? '100%' : undefined,
                    }}
                    value={value}
                    field={field}
                    maxLines={MAX_LINES}
                    maxLength={isFooter ? undefined : MAX_LENGTH}
                    isLoading={isLoading}
                    trim={isFooter}
                />
            )}
        </BaseAttribute>
    )
}

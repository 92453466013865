import { recordApi } from 'data/api/recordApi'

import { getSchemaTimestamp } from './getSchemaTimestamp'

/**
 * The function for fetching a new record from the server.
 * @param {string} recordId
 * @param {string[] | undefined} includeFields
 * @param {any} options
 */

export function getRecordQueryFunction(
    recordId: string,
    includeFields: string[] | undefined,
    options: any
): Promise<RecordDto> {
    return recordApi.getRecord(recordId, includeFields, options).then((response) => {
        const schemaTimestamp = getSchemaTimestamp((response as any)?._object_id)
        return (
            response
                ? // TODO: fix types
                  { ...response, _partial: !!(response as any)?._partial, schemaTimestamp }
                : response
        ) as RecordDto
    })
}

import React, { forwardRef } from 'react'

import { Button } from 'ui/components/Button'
import * as Parts from 'ui/components/Textarea/Textarea.parts'
import { Tooltip } from 'ui/components/Tooltip'

type TextareaToolbarItemRef = HTMLButtonElement

type TextareaToolbarItemProps = React.ComponentPropsWithoutRef<typeof Parts.ToolbarButton> & {
    icon: React.ComponentPropsWithoutRef<typeof Button>['startIcon']
    label: string
    isActive?: boolean
}

export const TextareaToolbarItem = forwardRef<TextareaToolbarItemRef, TextareaToolbarItemProps>(
    ({ icon, isActive, label, ...props }, ref) => {
        return (
            <Tooltip content={label} side="bottom" asChild>
                <Parts.ToolbarButton {...props} aria-label={label} asChild ref={ref}>
                    <Button variant="ghost" size="xs" startIcon={icon} aria-expanded={isActive} />
                </Parts.ToolbarButton>
            </Tooltip>
        )
    }
)

import React from 'react'

import { Box } from 'ui/components/Box'
import { DropdownHeadSearch, DropdownItem } from 'ui/components/Dropdown'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

import { useQuickFilterUserState } from './hooks/useQuickFilterUserState'
import { QuickFilter, QuickFilterButton, QuickFilterContent } from './QuickFiltersItemBase'
import { QuickFilterComponent } from './types'

type QuickFilterUserProps = {}

export const QuickFilterUser: QuickFilterComponent<QuickFilterUserProps> = ({ field }) => {
    const {
        value,
        isActive,
        label,
        onFilterRemove,
        onSetFilterValue,
        filterOptions,
        isFetchingSlow,
        isError,
        isButtonLoading,
    } = useQuickFilterUserState({
        field,
    })

    return (
        <QuickFilter>
            <QuickFilterButton isActive={isActive} onRemove={onFilterRemove}>
                {isButtonLoading ? (
                    <Box>
                        <Skeleton isLoading={true}>{label}</Skeleton>
                    </Box>
                ) : (
                    label
                )}
            </QuickFilterButton>
            <QuickFilterContent head={!isFetchingSlow && <DropdownHeadSearch />}>
                {isError && (
                    <DropdownItem
                        label={
                            <Body size="m" color="textError">
                                There was a problem displaying options
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError && filterOptions.length < 1 && (
                    <DropdownItem
                        label={
                            <Body size="m" color="textHelper">
                                No items found
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError &&
                    filterOptions.map((item) => (
                        <DropdownItem
                            key={item.value}
                            multiSelect={!isFetchingSlow}
                            label={
                                isFetchingSlow ? (
                                    <Skeleton isLoading={isFetchingSlow}>{item.label}</Skeleton>
                                ) : (
                                    item.label
                                )
                            }
                            checked={value.has(item.value)}
                            onCheckedChange={(checked) => {
                                onSetFilterValue(item.value, checked)
                            }}
                            disabled={isFetchingSlow}
                            startAvatar={item.avatar}
                        />
                    ))}
            </QuickFilterContent>
        </QuickFilter>
    )
}

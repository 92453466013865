import * as React from 'react'

import { ActionsContextProvider } from 'features/actions/ActionsContextProvider'
import ActionsList from 'features/actions/ActionsList'

// this is just a thin wrapper around ActionsList for now -
// leaving as a page for a nicer PR in case we need to add more framing later
export function AppSettingsModalDataActions({ object, ...props }) {
    return (
        <ActionsContextProvider object={object}>
            <ActionsList {...props} />
        </ActionsContextProvider>
    )
}

import { useCallback } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { convertOutputSchemaToOutputs } from 'features/workflows/OutputSchema/utils'

export function useAPIRequestActionFinalSchema() {
    const { data: schema } = useWorkflowSchema()
    return useCallback(
        (config: WorkflowActionConfig): WorkflowSchemaNodeType | undefined => {
            const type = schema?.nodes.find(
                (t) => t.id === config.action_type
            ) as WorkflowSchemaActionType
            if (!type) return undefined

            const responseSchema = config.settings?.response_schema as
                | WorkflowOutputSchema
                | undefined

            const stateItems = responseSchema ? convertOutputSchemaToOutputs(responseSchema) : []

            const inputs = type.inputs.slice()
            const requestMethod = getRequestMethod(config)
            if (supportsRequestMethodBody(requestMethod)) {
                inputs.push({
                    id: 'body',
                    name: 'Body',
                    type: 'string',
                    is_required: false,
                })
            }

            return {
                ...type,
                state: stateItems,
                inputs,
            }
        },
        [schema?.nodes]
    )
}

export function supportsRequestMethodBody(method: string): boolean {
    switch (method) {
        case 'POST':
        case 'PUT':
        case 'PATCH':
            return true
        default:
            return false
    }
}

export function getRequestMethod(config: WorkflowActionConfig): string {
    return (config.settings?.request_method as string | undefined) ?? 'GET'
}

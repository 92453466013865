import React, { useImperativeHandle } from 'react'

import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalContentInner,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Select, SelectOption } from 'ui/components/Select'

import { useNavigationCreateViewModalState } from './hooks/useNavigationCreateViewModalState'

export type NavigationCreateViewModalHandle = {
    open: (display: ListViewDisplay) => void
    close: () => void
}

type NavigationCreateViewModalProps = {}

export const NavigationCreateViewModal = React.forwardRef<
    NavigationCreateViewModalHandle,
    NavigationCreateViewModalProps
>((_props, ref) => {
    const {
        isOpen,
        setIsOpen,
        open,
        close,
        createNewLabel,
        hasNoTables,
        tables,
        createView,
        table,
        setTable,
        isSubmitDisabled,
        handleKeyDown,
    } = useNavigationCreateViewModalState()

    useImperativeHandle(ref, () => ({
        open,
        close,
    }))

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalContent onKeyDown={handleKeyDown}>
                <ModalHeader
                    title={createNewLabel}
                    subtitle="Select the source data table for your view"
                    showCloseButton={false}
                />
                <ModalContentInner>
                    <Select
                        startIcon={{ name: 'Sheet' }}
                        placeholder={hasNoTables ? 'No available data tables' : 'Select data table'}
                        disabled={hasNoTables}
                        isClearable={false}
                        value={table}
                        onChange={setTable}
                    >
                        {tables.map(({ label, value }) => (
                            <SelectOption key={value} value={value} label={label} />
                        ))}
                    </Select>
                </ModalContentInner>
                <ModalFooter>
                    <ModalFooterButtonGroup layout="inline">
                        <ModalCloseTrigger asChild>
                            <Button variant="ghost" size="l">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                        <Button
                            size="l"
                            variant="primary"
                            onClick={createView}
                            disabled={isSubmitDisabled}
                        >
                            Create view
                        </Button>
                    </ModalFooterButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
})

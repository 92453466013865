import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'

import {
    GetPropsCommonOptions,
    useCombobox,
    UseComboboxGetInputPropsOptions,
    UseComboboxProps,
    UseComboboxReturnValue,
    UseComboboxStateChange,
} from 'downshift'

import { getSearchTerms } from 'features/Search/helpers'

import {
    ItemProvider,
    useComboboxProviders,
    useComboboxProvidersReturn,
} from './useComboboxProviders'

export type useComboboxExtendedProps = Omit<UseComboboxProps<any>, 'items'> & {
    providers: ItemProvider<any>[]
    onItemSelected?: (item: any, provider: ItemProvider<any>, event?: SyntheticEvent) => void
    debounceDelay?: number
}

export type useComboboxExtendedReturn = {
    comboboxState: UseComboboxReturnValue<any>
    itemsState: useComboboxProvidersReturn
    queryTerms: string[]
}

/*
 * Wraps the downshift useCombobox hook to provide additional functionality:
 * - uses useComboboxProviders to supply the items
 * - modifies some UX such as allowing shift+home/end to select text
 * - breaks query down into terms for highlighting
 */
export function useComboboxExtended({
    providers,
    onItemSelected,
    inputValue: suppliedInputValue,
    debounceDelay,
    ...props
}: useComboboxExtendedProps): useComboboxExtendedReturn {
    const [inputValue, setInputValue] = useState('')
    const [queryTerms, setQueryTerms] = useState<string[]>([])
    const [highlightedIndex, setHighlightedIndex] = useState(0)
    const effectiveInputValue = suppliedInputValue || inputValue
    useEffect(() => {
        setQueryTerms(getSearchTerms(effectiveInputValue || ''))
    }, [effectiveInputValue])

    const itemsState = useComboboxProviders({
        query: effectiveInputValue,
        queryTerms,
        providers,
        debounceDelay,
    })

    const { items, collections } = itemsState

    const onInputValueChange = useCallback(({ inputValue }) => {
        setInputValue(inputValue)
    }, [])

    useEffect(() => {
        setHighlightedIndex(0)
    }, [effectiveInputValue])

    const onSelectedItemChange = useCallback(
        ({ selectedItem: item }: UseComboboxStateChange<any>) => {
            if (item) {
                const provider = itemsState.collections.find((c) => c.items.includes(item))?.source
                if (provider) onItemSelected?.(item, provider)
            }
        },
        [itemsState.collections, onItemSelected]
    )

    const onHighlightedIndexChange = useCallback(
        ({ highlightedIndex }: UseComboboxStateChange<any>) => {
            setHighlightedIndex(highlightedIndex || 0)
        },
        []
    )

    // If the first visible result is from the current query, then select it by default
    // otherwise don't have anything highlighted because the queries are still processing.
    // The user may instinctively hit "enter" after modifying a search, and we don't want
    // the first item of the previous query to be selected.
    const firstResultFromQuery = collections.find((c) => c.items.length > 0)?.query ?? ''
    useEffect(() => {
        setHighlightedIndex(firstResultFromQuery === effectiveInputValue ? 0 : -1)
    }, [firstResultFromQuery, effectiveInputValue])

    const result = useCombobox({
        items,
        onHighlightedIndexChange,
        highlightedIndex,
        onSelectedItemChange,
        onInputValueChange,
        inputValue,
        ...props,
    })

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const homeEndKeys = ['Home', 'End']
            // If the user is holding shift, then they're probably trying to select text
            // not nav in the list
            if (homeEndKeys.includes(e.key) && e.shiftKey) {
                e.preventDefault()
            } else if (e.key === 'Enter') {
                e.preventDefault()
                const item = items[highlightedIndex]
                if (item) {
                    const provider = itemsState.collections.find((c) =>
                        c.items.includes(item)
                    )?.source
                    if (provider) onItemSelected?.(item, provider, e)
                }
            }
        },
        [highlightedIndex, items, itemsState.collections, onItemSelected]
    )

    return useMemo(
        () => ({
            comboboxState: {
                ...result,
                getInputProps: (
                    options?: UseComboboxGetInputPropsOptions,
                    otherOptions?: GetPropsCommonOptions
                ) => {
                    const native = result.getInputProps(options, otherOptions)
                    return {
                        ...native,
                        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                            handleKeyDown(e)

                            if (!e.defaultPrevented) {
                                native.onKeyDown?.(e)
                            }
                        },
                    }
                },
            },
            itemsState,
            queryTerms,
        }),
        [handleKeyDown, result, itemsState, queryTerms]
    )
}

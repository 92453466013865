import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { HELP_LINKS } from 'app/helpLinks'
import { getWorkspaceUrl, Urls } from 'app/UrlService'
import { invalidateFields } from 'data/hooks/fields'
import useLDFlags from 'data/hooks/useLDFlags'
import { useUpdateZone } from 'data/hooks/zones'
import { CustomDomainCheckStatus, validateUrl } from 'data/utils/validateCustomDomain'
import { SlugCheckStatus, validateSlug } from 'data/utils/validateZoneSlug'
import { ConfirmationModal } from 'features/workspace/modals/ConfirmationModal'
import { FormFrame } from 'features/workspace/WorkspaceSettingsModalUi'

import useRequestConfirmModal from 'v2/ui/utils/useRequestConfirmModal'

import { Box } from 'ui/components/Box'
import { Checkbox } from 'ui/components/Checkbox'
import { Divider } from 'ui/components/Divider'
import { InfoMark } from 'ui/components/Field'
import { Body } from 'ui/components/Text'
import Form from 'ui/deprecated/forms/Form'
import { FormField } from 'ui/deprecated/forms/FormField'

import { DOMAIN_REGEX, HTTPS_PREFIX, PORTAL_DOMAIN, SLUG_REGEX } from './constants'
import { DeletePortal } from './DeletePortal'
import { SettingsInput } from './SettingsInput'
import { updateZoneUrl } from './updateZoneUrl'

type PortalGeneralSettingsProps = {
    formRef: any
    portal: AccountZone & { type: 'Portal' }
    children?: React.ReactNode
    readOnly?: boolean
}

type FormData = Pick<
    AccountZone,
    'name' | 'slug' | 'custom_domain' | 'enable_permanent_login_links'
>

const CUSTOM_DOMAIN_CHARACTER_LIMIT = 60

export const PortalGeneralSettings = ({
    formRef,
    portal,
    children,
    readOnly,
}: PortalGeneralSettingsProps) => {
    const { flags } = useLDFlags()
    const { mutateAsync: updateZone } = useUpdateZone()
    const {
        onShowConfirmation: onRequestUrlChange,
        showModal: showUrlChangeRequest,
        onAllow: onAllowUrlChange,
        onDeny: onDenyUrlChange,
    } = useRequestConfirmModal()

    const defaultValues = {
        name: portal.name,
        slug: portal.slug,
        custom_domain: portal.custom_domain,
        enable_permanent_login_links: portal.enable_permanent_login_links,
    }

    const history = useHistory()
    const onSubmit = async (values: FormData) => {
        const afterChanged = (resp: AccountZone) => {
            if (resp.enable_permanent_login_links !== portal.enable_permanent_login_links) {
                invalidateFields({ ignoreStack: true })
            }
            if (resp.slug !== portal.slug) {
                history.push(getWorkspaceUrl(Urls.Root, undefined, resp))
            }
        }
        // require explicit user confirmation if slug or custom domain is changing
        if (
            (values.slug && values.slug !== portal.slug) ||
            (values.custom_domain && values.custom_domain !== portal.custom_domain)
        ) {
            return onRequestUrlChange().then(() =>
                updateZoneUrl(updateZone, portal, values).then(afterChanged)
            )
        } else {
            return updateZoneUrl(updateZone, portal, values).then(afterChanged)
        }
    }

    const previousSlugValueRef = useRef(defaultValues.slug)
    const previousUrlValueRef = useRef(defaultValues.custom_domain)

    const validateSlugValue = async (newSlug: string) => {
        if (!newSlug) return 'Slug is required'

        // avoid re-evaluating on every form value change
        if (newSlug !== previousSlugValueRef.current) {
            previousSlugValueRef.current = newSlug
            const result = await validateSlug(newSlug)

            return result.status === SlugCheckStatus.OK || result.message
        }
    }

    const validateCustomDomain = async (newCustomDomain: string) => {
        // avoid re-evaluating on every form value change
        if (newCustomDomain !== previousUrlValueRef.current) {
            previousUrlValueRef.current = newCustomDomain
            const result = await validateUrl(newCustomDomain)

            return result.status === CustomDomainCheckStatus.OK || result.message
        }
    }

    return (
        <Form
            onSubmit={onSubmit}
            options={{ defaultValues }}
            style={{ flex: 1, width: '100%' }}
            resetOnSuccess
        >
            <FormFrame title="General" formRef={formRef}>
                <Divider variant="weak" mb="l" />
                <SettingsInput
                    formRef={formRef}
                    name="name"
                    label="Name"
                    readOnly={readOnly}
                    required
                />
                <div style={{ height: '12px' }} />
                <SettingsInput
                    formRef={formRef}
                    name="slug"
                    label="Customize URL"
                    required
                    readOnly={readOnly}
                    startAction={() => <Body color="gray500">{HTTPS_PREFIX}</Body>}
                    endAction={() => <Body color="gray500">.{PORTAL_DOMAIN}</Body>}
                    registerOptions={{
                        pattern: {
                            value: SLUG_REGEX,
                            message: 'The url slug you entered does not match the required format.',
                        },
                        validate: validateSlugValue,
                    }}
                />
                <div style={{ height: '12px' }} />
                <SettingsInput
                    formRef={formRef}
                    name="custom_domain"
                    label={
                        <Box flex alignItems="center">
                            Custom Domain
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={HELP_LINKS.CUSTOM_DOMAINS}
                            >
                                <InfoMark />
                            </a>
                        </Box>
                    }
                    readOnly={readOnly}
                    registerOptions={{
                        pattern: {
                            value: DOMAIN_REGEX,
                            message: 'The domain you entered does not match the required format.',
                        },
                        maxLength: {
                            value: CUSTOM_DOMAIN_CHARACTER_LIMIT,
                            message: `Exceeded maximum character limit of ${CUSTOM_DOMAIN_CHARACTER_LIMIT}.`,
                        },
                        validate: validateCustomDomain,
                    }}
                    startAction={() => <Body color="gray500">{HTTPS_PREFIX}</Body>}
                />
                <PermanentLoginLinksSetting />
                <DeletePortal portal={portal} />
            </FormFrame>
            <ConfirmationModal
                isOpen={showUrlChangeRequest}
                title="Confirm URL change"
                subtitle={
                    <>
                        Are you sure that you wish to update the portal URL? Any links to your
                        previous url will no longer work.
                        {flags?.permanentLoginLinks && (
                            <>
                                <br />
                                <br />
                                Existing permanent login links will become invalid and new ones will
                                be generated.
                            </>
                        )}
                    </>
                }
                onConfirm={onAllowUrlChange}
                onCancel={onDenyUrlChange}
            />
            {children}
        </Form>
    )
}

function PermanentLoginLinksSetting() {
    const { flags } = useLDFlags()
    if (!flags?.permanentLoginLinks) return null

    return (
        <>
            <FormField
                as={Checkbox}
                name="enable_permanent_login_links"
                controlled
                controlledDefaultValue={false}
                controlledRender={({ field: { onChange, name, value } }) => (
                    <Checkbox onCheckedChange={onChange} name={name} checked={value} mt="xl">
                        Generate permanent login links
                    </Checkbox>
                )}
            />
        </>
    )
}

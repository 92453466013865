import { TableViewCalculationType } from 'features/views/ListView/TableView/types'

export function getSupportedCalculationTypes(field: FieldDto): TableViewCalculationType[] {
    switch (field.type) {
        case 'number':
        case 'percentage':
        case 'currency':
            return [
                'sum',
                'avg',
                'min',
                'max',
                'range',
                'median',
                'count',
                'countempty',
                'countall',
            ]
        default:
            return ['count', 'countempty', 'countall']
    }
}

export function isCountCalculationType(type?: TableViewCalculationType): boolean {
    return ['count', 'countempty', 'countall'].includes(type!)
}

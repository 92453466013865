import React, { useCallback, useEffect, useRef, useState } from 'react'

import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import { Button, EmptyState, Flex, Icon, Input, Text } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import * as SvgIcons from 'v2/ui/svgs'
import { useSearchRecords } from 'v2/ui/utils/useSearchRecords'

const InnerListSearch = ({
    columns = [],
    doSearch,
    data = [],
    setEmptySearchResult,
    value,
    setSearchQuery,
    disableCtrlF,
    query,
    widthOverride,
    useUrl,
    dereferencedRecords = [],
    ...props
}) => {
    const [searchValue, setSearchValue] = useState(useUrl ? query.search || value : value)
    const [loadedData, setLoadedData] = useState(data)
    const searchRecords = useSearchRecords(loadedData, columns, dereferencedRecords)
    const searchInput = useRef(null)
    const skipDebounce = useRef()

    const updateSearchQuery = useCallback(() => {
        if (useUrl) {
            setSearchQuery(value)
        }
    }, [setSearchQuery, useUrl, value])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncer = useCallback(
        debounce((target) => target(), 300),
        []
    )

    const emptyViewCta = () => {
        return (
            <Flex column>
                <Text variant="emptyState" mb={5}>
                    Clear your search and start again.
                </Text>
                <Button variant="sm" ml={[0, null, 2]} mt={[2, null, 0]} onClick={clearSearch}>
                    Clear search
                </Button>
            </Flex>
        )
    }

    const EmptySearchResult = () => (
        <EmptyState
            custom="No records match your search"
            cta={emptyViewCta}
            svg={SvgIcons.EmptySearch}
        />
    )

    const searchAll = () => {
        const result = searchRecords(searchValue)
        if (searchValue && !result.length) {
            setEmptySearchResult(<EmptySearchResult />)
        } else {
            setEmptySearchResult(false)
        }
        return doSearch(searchValue, [...result])
    }

    const focusSearch = () => {
        if (searchInput.current) {
            searchInput.current.focus()
        }
    }

    const onCtrlF = (e) => {
        if (!disableCtrlF && (e.ctrlKey || e.metaKey) && e.keyCode === 70) {
            e.preventDefault()
            focusSearch()
        }
    }

    const clearSearch = () => {
        // Skip the debounce when the user clears the search. No need for delay in this case.
        skipDebounce.current = true
        setSearchValue('')
        setEmptySearchResult(false)
    }

    useEffect(() => {
        if (!useUrl) {
            setSearchValue(value)
        }
    }, [value, useUrl])

    useEffect(() => {
        window.addEventListener('keydown', onCtrlF)
        return () => window.removeEventListener('keydown', onCtrlF)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (skipDebounce.current) {
            skipDebounce.current = false
            searchAll()
        } else {
            debouncer(searchAll)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, loadedData])

    useEffect(() => {
        if (!isEqual(data, loadedData)) {
            setLoadedData(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (useUrl && value !== query.search) {
            updateSearchQuery()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, useUrl])

    useEffect(() => {
        if (useUrl && query.search !== searchValue) {
            setSearchValue(query.search)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query?.search, useUrl])

    if (columns.length > 0) {
        const inputProps = props
        if (searchValue) {
            inputProps.rightAdorner = (
                <Button
                    variant="clear"
                    label="Clear Search"
                    icon="x"
                    size={['input.icon', null, null, 'input.iconLg']}
                    h={['input.icon', null, null, 'input.iconLg']}
                    onClick={clearSearch}
                />
            )
        }

        if (!data) return null

        const icon = <Icon size={['input.icon', null, null, 'input.iconLg']} icon="search" />

        return (
            <Input
                {...inputProps}
                className={STYLE_CLASSES.LIST_SEARCH}
                leftAdorner={icon}
                ref={searchInput}
                placeholder={`Search`}
                value={searchValue || ''}
                size="sm"
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        searchAll()
                    } else if (e.key === 'Escape') {
                        clearSearch()
                    }
                }}
                onChange={(e) => {
                    setSearchValue(e.target.value)
                }}
                groupProps={{
                    order: [2, null, null, 0],
                    flexBasis: ['100%', null, null, widthOverride ?? '190px'],
                    mt: [2, null, null, 0],
                }}
                aria-label="Search"
            />
        )
    }

    return null
}

export const ListSearch = React.memo(InnerListSearch)

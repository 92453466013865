import React, { useMemo } from 'react'

import { TableViewGridContext } from './useTableViewGridContext'

export type TableViewGridContextProviderProps = {
    onColumnResize: (columnId: string, width: number) => void
}

export const TableViewGridContextProvider: React.FC<TableViewGridContextProviderProps> = ({
    children,
    onColumnResize,
}) => {
    const value = useMemo(() => ({ onColumnResize }), [onColumnResize])

    return <TableViewGridContext.Provider value={value}>{children}</TableViewGridContext.Provider>
}

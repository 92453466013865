import React from 'react'

import { getUrl } from 'app/UrlService'

import Link from 'v2/ui/components/Link'
import { layouts as layoutSettings } from 'v2/ui/utils/attributeSettings'

type getLinkComponentFromUrlProps = {
    display: any
    url: string
    noLink?: boolean
    layout?: keyof typeof layoutSettings
    stack?: StackDto
}

export const getLinkComponentFromUrl = ({
    display,
    url,
    noLink = false,
    layout,
    stack,
}: getLinkComponentFromUrlProps): React.ReactElement => {
    const displayText = display && display.toString() ? display.toString() : '-'
    let contents = displayText

    if (!noLink) {
        contents = (
            <Link
                title={contents}
                historyType="detail"
                historyTitle={contents}
                to={getUrl(url, stack)}
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    alignSelf: layout === layoutSettings.highlighted ? 'center' : 'flex-start',
                }}
                simplifiedComponent
            >
                {contents}{' '}
            </Link>
        )
    }

    return contents
}

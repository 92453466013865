import React from 'react'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { theme } from 'ui/styling/Theme.css'

import { useAppNavigationHeaderState } from './hooks/useAppNavigationHeaderState'
import { AppIcon } from './AppIcon'
import { WorkspaceNavigationAppAddNewDropdown } from './WorkspaceNavigationAppAddNewDropdown'
import { WorkspaceNavigationAppDropdown } from './WorkspaceNavigationAppDropdown'

import { AppHeaderTitleStyle, AppNavigationIconStyle } from './Navigation.css'

const APP_NAME_MAX_LINES = 2

type AppNavigationHeaderProps = {}

export const AppNavigationHeader: React.FC<AppNavigationHeaderProps> = () => {
    const { selectedApp } = useAppNavigationHeaderState()

    if (!selectedApp) return null

    return (
        <Box
            py="2xs"
            style={{
                paddingLeft: '10px',
            }}
            pr="s"
            flex
            alignItems="flex-start"
            justifyContent="space-between"
            gap="m"
        >
            <Box flex alignItems="flex-start" justifyContent="space-between" gap="m">
                <AppIcon app={selectedApp} className={AppNavigationIconStyle} />
                <Tooltip
                    zIndex={201}
                    content={selectedApp.name}
                    side="bottom"
                    align="center"
                    display="inline-flex"
                    center
                    className={AppHeaderTitleStyle}
                >
                    <Headline
                        size="2xs"
                        maxLines={APP_NAME_MAX_LINES}
                        display="inline-block"
                        style={{
                            fontWeight: theme.fontWeights.headline,
                        }}
                    >
                        {selectedApp.name}
                    </Headline>
                </Tooltip>
            </Box>
            <Box py="2xs" flex center gap="xs">
                <WorkspaceNavigationAppDropdown app={selectedApp} size="xs" />
                <WorkspaceNavigationAppAddNewDropdown app={selectedApp} size="xs" />
            </Box>
        </Box>
    )
}

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeInputVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surface: s.color.surface,
            surfaceDisabled: s.color.surfaceDisabled,
            surfaceReadOnly: s.color.surfaceDisabled,

            border: s.color.gray300,
            borderHover: s.color.gray400,
            borderDisabled: s.color.borderDisabled,
            borderFocus: s.color.borderFocus,
            borderReadOnly: s.color.gray300,
            borderError: s.color.borderError,
            borderDivider: s.color.gray300,

            text: s.color.text,
            textPlaceholder: s.color.textWeakest,
            textDisabled: s.color.textDisabled,

            textEndAction: s.color.text,
            iconEndAction: s.color.icon,
        },
        shadows: {
            focus: s.shadows.focus,
        },
        size: {
            m: pxToRem(36),
            l: pxToRem(44),
        },
        fontWeights: {
            text: s.fontWeights.bodyRegular,
            textEndAction: s.fontWeights.bodyBold,
        },
        lineHeights: {
            text: '1',
            textEndAction: s.lineHeights.bodyM,
        },
        fontSizes: {
            textS: s.fontSizes.bodyS,
            textM: s.fontSizes.bodyM,
            textL: s.fontSizes.bodyL,
            textEndAction: s.fontSizes.bodyM,
        },
        radius: {
            container: s.radius.l,
        },
        borderWidth: {
            container: s.borderWidths[1],
            divider: s.borderWidths[1],
        },
        borderStyle: {
            container: s.borderStyles.base,
            divider: s.borderStyles.base,
        },
        gap: {
            endActionParts: s.space.xs,
            inputParts: s.space.xs,
            divider: s.space.m,
            numberButtons: s.space['3xs'],
        },
        padding: {
            horizontalM: s.space.l,
            horizontalMEndAction: s.space.m,
            verticalM: s.space.m,
            horizontalL: s.space.xl,
            horizontalLEndAction: s.space.l,
            verticalL: s.space.m,
        },
    }
}

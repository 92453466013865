export type BaseFieldType =
    | 'string'
    | 'number'
    | 'boolean'
    | 'date'
    | 'string_array'
    | 'object_array'

export type FieldTypeKey =
    | FieldType
    | SyntheticFieldType
    | 'string_with_rich_text'
    | 'rollup_related_records'
    | 'lookup_related_value'
    | 'automatic_link'

export type CreatableFieldTypeKey = Exclude<
    FieldTypeKey,
    | 'address'
    | 'autoid'
    | 'relationship' // for now we're hiding option to create 'Automatic link'
    | 'automatic_link' // TODO: should we use this instead of 'relationship'?
    | 'copy'
    | 'password'
    | 'rollup' // TODO: should we use this instead of 'rollup_related_records'?
    | 'symmetric_relationship'
    | 'user_ref'
    | 'user_role'
>

// this dictionary gives you the base data type of the given field type
export const fieldBaseTypeMap: { [key in FieldType]?: BaseFieldType } = {
    string: 'string',
    number: 'number',
    lookup: 'string',
    checkbox: 'boolean',
    long_text: 'string',
    url: 'string',
    date: 'date',
    datetime: 'date',
    image: 'string',
    dropdown: 'string',
    currency: 'number',
    percentage: 'number',
    multi_select: 'string_array',
    multi_lookup: 'string_array',
    multi_file: 'object_array',
    document: 'object_array',
}

import React from 'react'

import { WithObject } from 'data/wrappers/WithObjectComponent'
import { sortFieldsByOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'

import { Dropdown } from 'v2/ui'

export const ObjectOrderAttribute = ({ context, ...props }) => (
    <WithObject objectId={props.objectId || context.block.config.attributes.objectId}>
        {({ object }) => {
            const value = props.value || {}

            let fields = object.fields
            if (props.removeDisabledFields) {
                fields = fields.filter((field) => !field.connection_options.is_disabled)
            }

            return (
                <div className="display-flex">
                    <div style={{ flex: 1 }}>
                        <Dropdown
                            options={sortFieldsByOrder(fields, object).map((field) => ({
                                value: field.api_name,
                                label: field.label,
                            }))}
                            value={value.id}
                            onChange={(id) => props.onChange({ ...value, id })}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Dropdown
                            options={[
                                { label: 'Ascending', value: 'false' },
                                { label: 'Descending', value: 'true' },
                            ]}
                            isClearable={false}
                            value={value.desc ? 'true' : 'false'}
                            onChange={(desc) => props.onChange({ ...value, desc: desc === 'true' })}
                        />
                    </div>
                </div>
            )
        }}
    </WithObject>
)

import { useContext } from 'react'

import { AiAppBuilderContext } from './AiAppBuilderContext'

export function useAiAppBuilder() {
    const context = useContext(AiAppBuilderContext)
    if (context === undefined) {
        throw new Error('useAiAppBuilder must be used within an AiAppBuilderProvider')
    }
    return context
}

import { Document } from '@tiptap/extension-document'

export function createVersionedDocumentExtension(schemaVersion?: number) {
    return Document.extend({
        addAttributes() {
            const prevAttrs = this.parent?.() ?? {}
            if (typeof schemaVersion === 'undefined') return prevAttrs

            return {
                ...prevAttrs,
                schemaVersion: { default: schemaVersion },
            }
        },
    }).configure({})
}

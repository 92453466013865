import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Editor, Range } from '@tiptap/core'
import { EditorState, Plugin, PluginKey, Selection } from '@tiptap/pm/state'

import { ContextGroup, ContextSchema } from 'features/expressions/types'
import { SuggestionComponentHandle } from 'features/tiptap/Extensions/extensionHelpers'

import { Tooltip, useCtrlKey } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Kbd } from 'ui/components/Kbd'

import { ContextMenuPopover } from './ContextMenuPopover'

const menuEmitter = new EventTarget()

type ContextMenuManagerProps = {
    editorInstanceId: string
    editor?: Editor
    container?: HTMLDivElement
    contextSchema: ContextSchema
    renderGroupTitle: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
    openOnFocus?: boolean
}

export function ContextMenuManager({
    editorInstanceId,
    editor,
    container,
    contextSchema,
    renderGroupTitle,
    openOnFocus = true,
}: ContextMenuManagerProps) {
    const menuRef = useRef<SuggestionComponentHandle>(null)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [range, setRange] = useState<Range | undefined>({ from: 0, to: 0 })
    const [query, setQuery] = useState<string>('')

    const [filteredContextSchema, setFilteredContextSchema] = useState(contextSchema)

    useEffect(() => {
        if (isMenuOpen) return

        setFilteredContextSchema(contextSchema)
    }, [isMenuOpen, contextSchema])

    const openMenu = useCallback(() => {
        menuEmitter.dispatchEvent(new Event('close'))
        setIsMenuOpen(true)
    }, [])

    useEffect(() => {
        function closeMenu() {
            setIsMenuOpen(false)
        }

        menuEmitter.addEventListener('close', closeMenu)
        return () => {
            menuEmitter.removeEventListener('close', closeMenu)
        }
    }, [openMenu])

    // stores the cursor position when the menu is shown, so we can take any text from that point forward as the
    // user types as the query text
    const menuShownPosition = useRef<number | undefined>()

    const editorRef = useRef(editor)
    editorRef.current = editor
    const currentSelection = useRef<Selection | undefined>()

    const editorState = useRef<EditorState | undefined>()
    editorState.current = editor?.state

    // extracts the text and the range from the given selection
    const processSelection = useCallback((selection: Selection, state: EditorState) => {
        // if the selection has moved to the left of the menuShownPosition, reset the menuShownPosition
        if (menuShownPosition.current === undefined || selection.from < menuShownPosition.current) {
            menuShownPosition.current = selection.from
        }
        currentSelection.current = selection
        const range = { from: menuShownPosition.current, to: selection.to }
        const query = state.doc.textBetween(menuShownPosition.current, selection.to)

        setQuery(query)
        setRange(range)
    }, [])

    useEffect(() => {
        // when the menu opens, take note of the cursor position
        if (isMenuOpen && editorState.current && editorRef.current && currentSelection.current) {
            menuShownPosition.current = currentSelection.current?.from
            processSelection(currentSelection.current, editorState.current)
            editorRef.current.chain().focus().run()
        } else if (!isMenuOpen) {
            menuShownPosition.current = undefined
        }
    }, [isMenuOpen, processSelection])

    const contextSchemaRef = useRef(contextSchema)
    contextSchemaRef.current = contextSchema

    useEffect(() => {
        if (!editor || !container) return

        editor.registerPlugin(
            new Plugin({
                key: new PluginKey('context-items-menu-handler'),
                appendTransaction: (transactions, oldState, newState) => {
                    const newSelection = newState.selection

                    // If the selection has changed, process it to get the query and range
                    if (transactions.find((x) => x.selectionSet || x.docChanged)) {
                        processSelection(newSelection, newState)
                    }

                    for (const tr of transactions) {
                        const showState = tr.getMeta('showContextItemsMenu')
                        if (typeof showState !== 'undefined') {
                            const isOpen = showState.isOpen
                            const filter = showState.filter

                            queueMicrotask(() => {
                                setIsMenuOpen(isOpen)
                                if (isOpen && typeof filter === 'function') {
                                    setFilteredContextSchema((prevSchema) => filter(prevSchema))
                                } else {
                                    setFilteredContextSchema(contextSchemaRef.current)
                                }
                            })
                        }
                    }

                    return undefined
                },
            })
        )

        function handleKeyDown(event: KeyboardEvent) {
            // only handle keydown events if the editor is focused
            if (document.activeElement?.id !== editorInstanceId) return

            if (event.key === 'Escape') {
                setIsMenuOpen(false)
                return
            }

            if (event.key === '.' && (event.ctrlKey || event.metaKey) && editorState.current) {
                // reset any range/query information  and show the menu, if it isn't already
                menuShownPosition.current = editorState.current.selection.from
                processSelection(editorState.current.selection, editorState.current)
                openMenu()
                return
            }

            menuRef.current?.onKeyDown({ event })
        }

        function handleFocus() {
            if (!editor) return

            if (openOnFocus) {
                openMenu()
            }
        }

        function handleDoubleClick(event: MouseEvent) {
            if (!editor) return

            // Don't show the menu if the user double-clicked to select some text.
            const hasSelectedText = !editor.state.selection.empty
            if (hasSelectedText) return

            openMenu()

            event.stopPropagation()
        }

        function handleBlur() {
            // Give browser time to focus the next element
            requestAnimationFrame(() => {
                // Check if the new focused element is a child of the original container
                if (!container?.contains(document.activeElement)) {
                    setIsMenuOpen(false)
                }
            })
        }

        container.addEventListener('keydown', handleKeyDown, true)
        container.addEventListener('dblclick', handleDoubleClick)
        container.addEventListener('focusin', handleFocus)
        container.addEventListener('focusout', handleBlur)

        return () => {
            container.removeEventListener('keydown', handleKeyDown, true)
            container.removeEventListener('dblclick', handleDoubleClick)
            container.removeEventListener('focusin', handleFocus)
            container.removeEventListener('focusout', handleBlur)
        }
    }, [editor, container, processSelection, openOnFocus, openMenu, editorInstanceId])

    function onItemSelected() {
        setIsMenuOpen(false)
    }

    if (!editor || !range) return null

    return (
        <>
            <ContextMenuPopover
                ref={menuRef}
                editor={editor}
                open={isMenuOpen}
                range={range}
                query={query}
                provideSchema={() => filteredContextSchema}
                target={container}
                renderGroupTitle={renderGroupTitle}
                onItemSelected={onItemSelected}
            />

            <Box position="absolute" right="m">
                <Tooltip label={<PlusButtonTooltip />} placement="bottom">
                    <Button
                        variant="secondary"
                        tabIndex={-1}
                        startIcon={{ name: 'MoreHorizontal' }}
                        aria-expanded={isMenuOpen}
                        size="2xs"
                        onClick={() => setIsMenuOpen(true)}
                    />
                </Tooltip>
            </Box>
        </>
    )
}

function PlusButtonTooltip() {
    const modifierKey = useCtrlKey()
    return (
        <Box fontSize="bodyS">
            Insert dynamic values <Kbd ml="xs">{modifierKey}</Kbd> + <Kbd>.</Kbd>
        </Box>
    )
}

import React from 'react'

import {
    TrayButtonBase,
    TrayButtonBaseProps,
} from 'features/workspace/AdminSideTray/common/TrayButtonBase'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import useTrack from 'utils/useTrack'

type ManageUsersButtonProps = TrayButtonBaseProps & {}
export const ManageUsersButton: React.VFC<ManageUsersButtonProps> = ({}) => {
    const { state, showManageUsers, close } = useSlidingPane()
    const { track } = useTrack()

    const toggleManageUsersPane = () => {
        if (state.key !== 'users') {
            showManageUsers()
        } else {
            close()
        }
    }

    const handleClick = () => {
        track('WIP - Frontend - Admin Side Tray - Manage Users - Clicked')
        toggleManageUsersPane()
    }

    return (
        <TrayButtonBase
            icon="avatar"
            tooltip="Manage users"
            onClick={handleClick}
            data-testid="admin-side-tray.manage-users-button"
            isActive={state.key === 'users'}
        />
    )
}

import React, {
    memo,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
} from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Box from 'v2/ui/components/Box'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import stackerTheme from 'v2/ui/theme/styles/default'
import { AnimateRelocateContext } from 'v2/ui/utils/AnimateRelocate'

import { OrderableItemContent } from './OrderableItemContent'
import { OrderableItemProps } from './types'

const { colors } = stackerTheme()

export const OrderableItem = memo<OrderableItemProps>(
    React.forwardRef(
        (
            {
                item,
                allowReorder,
                isChecked,
                isActive,
                isDragging,
                draggableProps,
                dragHandleProps,
                onFocus,
                onCheckedChanged,
                onEdit,
                provideLabel,
                provideIcon,
                itemKey,
                autoHideEditButton,
                showActionsOnDisabled,
                ...props
            },
            ref
        ) => {
            let domNode = useRef<HTMLDivElement>()
            let isCurrentlyActive = useRef<boolean>()
            let animator = useContext(AnimateRelocateContext)
            let label = useMemo(() => provideLabel?.(item), [item, provideLabel])
            let icon = useMemo(() => provideIcon?.(item), [item, provideIcon])
            // @ts-ignore
            const windowTimeout = useRef<null | ReturnType<typeof setTimeout>>({})

            const clearCurrentTimeout = () => {
                if (windowTimeout.current) clearTimeout(windowTimeout.current)
                windowTimeout.current = null
            }

            // When our root node is loaded, we need to save a ref
            // and also register the node with our animator provider
            const domNodeLoaded = (node: HTMLDivElement) => {
                domNode.current = node
                animator.registerNode(itemKey, node)
                // @ts-ignore
                if (ref) ref(node)
            }

            const handleClick = useCallback(() => {
                domNode.current && domNode.current.focus()
            }, [])

            const handleToggleClick = useCallback(
                () => onCheckedChanged && onCheckedChanged(item),
                [item, onCheckedChanged]
            )
            const handleEditClick = useCallback((_event) => onEdit?.(item, _event), [item, onEdit])

            const handleFocus = useCallback(() => {
                if (onFocus) onFocus(item)
            }, [item, onFocus])

            // This runs on our initial render. We call out to the
            // animator to animate us into position if we're
            // coming from a different location
            useLayoutEffect(() => {
                if (!domNode.current) return
                animator.animate(itemKey, domNode.current)
            }, [animator, itemKey])

            // This gets called when our isActive prop changes
            useLayoutEffect(() => {
                isCurrentlyActive.current = isActive

                // if isActive has just been set to true (ie., by arrow key navigation)
                // then we want to set focus on our root node. However, there is a perf hit
                // to focus(). So we want to delay it slightly and only do it if the
                // item is *still* active at that time. This ensures that the user can rapidly
                // move throught the list using the arrow keys and we won't incur the
                // focus() perf hit on each item
                if (isActive && domNode.current) {
                    clearCurrentTimeout()

                    windowTimeout.current = setTimeout(
                        () =>
                            isCurrentlyActive.current &&
                            domNode.current &&
                            domNode?.current?.focus(),
                        100
                    )
                }
            }, [isActive])

            useEffect(() => {
                return clearCurrentTimeout
            }, [])

            return (
                <ElementBox
                    ref={domNodeLoaded}
                    pl={2}
                    tabIndex={0}
                    isActive={isActive}
                    onClick={handleClick}
                    isClickable={!!item.props?.onClick}
                    isDragging={isDragging}
                    isDraggable={!item.props?.cannotBeDragged}
                    {...draggableProps}
                    {...dragHandleProps}
                    onFocus={handleFocus}
                    {...item.props}
                    className={props.className}
                >
                    <OrderableItemContent
                        allowReorder={allowReorder}
                        item={item}
                        onEdit={item.props?.allowEdit && onEdit ? handleEditClick : undefined}
                        label={label}
                        icon={icon}
                        isChecked={isChecked}
                        onCheckedChanged={handleToggleClick}
                        autoHideEditButton={autoHideEditButton}
                        data-testid={props?.['data-testid']}
                        showToggle={item.props?.showToggle}
                        showActionsOnDisabled={showActionsOnDisabled}
                        classes={
                            props.className
                                ? {
                                      editButton:
                                          ONBOARDING_CLASSES.EDIT_LAYOUT_FIELD_ITEM_EDIT_BUTTON,
                                  }
                                : ''
                        }
                    />
                </ElementBox>
            )
        }
    )
)

const activeState = css`
    > div {
        transform: scaleX(1);
        max-width: unset;
    }
`

const ElementBox = memo(styled(Box)<{
    hideBorder?: boolean
    isActive?: boolean
    isClickable?: boolean
    cannotBeDragged?: boolean
    isDragging?: boolean
    hoverBackgroundColor?: boolean
    ignoreHoverStates?: boolean
}>`
    border: ${(props) => (!props.hideBorder ? '1px solid rgba(0, 0, 0, 0.1)' : null)};
    background-color: ${(props) => (props.isActive ? colors.userInterface.neutral[100] : 'white')};
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'normal')};
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: stretch;
    border-radius: 6px;
    margin-bottom: 12px;
    overflow: hidden;
    position: relative;
    user-select: ${(props) => (props.cannotBeDragged ? 'none' : 'auto')};
    z-index: 0;
    transition:
        background-color 0.2s ease,
        box-shadow 0.2s ease;
    ${(props) => (props.isDragging ? 'box-shadow: 0px 1px 5px rgba(0,0,0,.25);' : null)}
    &:hover {
        ${(props) => !props.ignoreHoverStates && activeState}
        background-color: ${(props) => props.hoverBackgroundColor};
    }
    &:focus-within {
        background-color: ${colors.userInterface.neutral[100]};
        ${activeState}
    }
`)

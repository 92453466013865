import { useMemo } from 'react'

type UseBoardViewColumnHeaderStateOptions = {
    valueCount?: number
    isCollapsed?: boolean
}

export function useBoardViewColumnHeaderState(options: UseBoardViewColumnHeaderStateOptions) {
    const { valueCount = 0, isCollapsed } = options

    let formattedValueCount = valueCount === 1 ? '1 record' : `${valueCount} records`
    if (isCollapsed) {
        formattedValueCount = valueCount > 999 ? '999+' : valueCount.toString()
    }

    return useMemo(
        () => ({
            formattedValueCount,
        }),
        [formattedValueCount]
    )
}

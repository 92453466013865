import React, { useLayoutEffect, useRef, useState } from 'react'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'

import { validateOutputSchemaId } from './utils'

type OutputSchemaEditorNodeIdProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: string
    siblingNodes: WorkflowOutputSchemaNode[]
    onChange: (value: string) => void
    onDelete: () => void
    isPlaceholderNode?: boolean
    isFocused?: boolean
    onBlur?: () => void
    nextNodeId?: string | null
    setFocusedNode: (nodeId: string | null) => void
}

export const OutputSchemaEditorNodeId: React.FC<OutputSchemaEditorNodeIdProps> = ({
    value,
    siblingNodes,
    onChange,
    onDelete,
    isPlaceholderNode,
    isFocused,
    onBlur,
    setFocusedNode,
    nextNodeId,
    ...props
}) => {
    const [newValue, setNewValue] = useState(value)

    const [validationError, setValidationError] = useState('')

    const validateValue = (value: string) => {
        const error = validateOutputSchemaId(value, siblingNodes)
        setValidationError(error)

        return !error
    }

    const onCommit = () => {
        onBlur?.()

        if (isPlaceholderNode && !newValue) {
            onDelete()
            return
        }

        const isValid = validateValue(newValue)
        if (!isValid) return

        onChange(newValue)
    }

    const onCancel = () => {
        setValidationError('')

        if (isPlaceholderNode) {
            onDelete()
            return
        }

        setNewValue(value)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onCommit()
            if (nextNodeId) setFocusedNode(nextNodeId)
        } else if (e.key === 'Escape') {
            onCancel()
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValidationError('')
        setNewValue(e.target.value)
    }

    const inputRef = useRef<HTMLInputElement>(null)
    useLayoutEffect(() => {
        if (isFocused) inputRef.current?.focus()
    }, [isFocused])

    return (
        <Box {...props}>
            <Tooltip
                label={validationError}
                placement="bottom"
                open={!!validationError}
                interactive={true}
            >
                <Input
                    ref={inputRef}
                    value={newValue}
                    onChange={handleChange}
                    onBlur={onCommit}
                    onKeyDown={handleKeyDown}
                    isError={!!validationError}
                />
            </Tooltip>
        </Box>
    )
}

import React, { useCallback, useRef, useState } from 'react'

import { Select } from 'ui/components/Select'
import { extractStandardProps } from 'ui/helpers/styles'

import { DropdownHeadBase } from './DropdownHeadBase'
import { useKeyDownFromHead } from './useKeyDownFromHead'

type DropdownHeadSelectRef = HTMLDivElement

type InheritedSelectProps = Pick<
    React.ComponentPropsWithoutRef<typeof Select>,
    'placeholder' | 'autoFocus' | 'value' | 'defaultValue' | 'onChange' | 'multiSelect'
>

type DropdownHeadSelectProps = React.ComponentPropsWithoutRef<typeof DropdownHeadBase> &
    Partial<InheritedSelectProps> & {}

export const DropdownHeadSelect = React.forwardRef<DropdownHeadSelectRef, DropdownHeadSelectProps>(
    ({ autoFocus = true, onFocus, children, ...props }, ref) => {
        const selectRef = useRef<HTMLButtonElement>(null)

        const [headRef, setHeadRef] = useState<HTMLElement | null>(null)

        const handleOnFocus = useCallback(
            (e: React.FocusEvent<HTMLDivElement>) => {
                e.preventDefault()
                e.stopPropagation()

                // Only run this when the head is focused from another menu item.
                if (e.target !== e.currentTarget) return

                const select = selectRef.current
                if (!select) return

                select.focus()

                onFocus?.(e)
            },
            [onFocus]
        )

        const handleSelectKeyDown = useKeyDownFromHead(headRef)

        // eslint-disable-next-line unused-imports/no-unused-vars
        const [{ color, ...standardProps }, { onSelect, ...rootProps }] =
            extractStandardProps(props)

        return (
            <DropdownHeadBase
                onFocus={handleOnFocus}
                {...standardProps}
                ref={(element: HTMLDivElement) => {
                    setHeadRef(element)

                    return ref
                }}
                onSelect={onSelect}
            >
                <Select
                    ref={selectRef}
                    {...(rootProps as InheritedSelectProps)}
                    autoFocus={autoFocus}
                    onKeyDown={handleSelectKeyDown}
                >
                    {children}
                </Select>
            </DropdownHeadBase>
        )
    }
)

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeModalVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surface: s.color.surface,
            border: s.color.borderWeak,
            text: s.color.text,
        },
        radius: {
            container: s.radius['4xl'],
        },
        shadows: {
            container: s.shadows.l,
        },
        borderWidths: {
            container: s.borderWidths[1],
        },
        borderStyles: {
            container: s.borderStyles.base,
        },
        fontSizes: {
            title: s.fontSizes.headlineS,
            subtitle: s.fontSizes.bodyM,
        },
        fontWeights: {
            title: s.fontWeights.headline,
            subtitle: s.fontWeights.bodyRegular,
        },
        lineHeights: {
            title: s.lineHeights.headlineS,
            subtitle: s.lineHeights.bodyM,
        },
        padding: {
            container: s.space['3xl'],
            tabContent: s.space.l,
        },
        gap: {
            content: s.space.xl,
            headerItems: s.space.xs,
            titleContent: s.space.m,
            footerContent: s.space.m,
            footerButtons: s.space.xs,
            innerContent: s.space.m,
            screenBounds: s.space.xl,
        },
        size: {
            container: pxToRem(480),
            title: pxToRem(36),
            coverImage: pxToRem(250),
        },
        opacity: {
            coverImage: '0.4',
        },
    }
}

import { canDeleteRecords } from 'data/utils/getObjectRecordRestrictions'
import { useNotesEnabled } from 'features/documents/useNotesEnabled'
import { useTasksEnabled } from 'features/tasks/useTasksEnabled'

import { DetailViewTab } from './Detail/DetailView/types'

type Button = {
    _sid: string
    name: string
}

type UseDetailViewConfigResult = {
    systemTabs: DetailViewTab[]
    filterTabs: (tabConfig: DetailViewTab[]) => DetailViewTab[]
    systemButtons: Button[]
    filterButtons: (buttonConfig: Button[]) => Button[]
}

export const useDetailViewConfig = (object: ObjectDto): UseDetailViewConfigResult => {
    const tasksEnabled = useTasksEnabled()
    const notesEnabled = useNotesEnabled()

    const systemTabs = [
        { name: 'Details', id: 'details', treeIndex: 0, active: true },
        { name: 'Feed', id: 'activity', active: true, type: 'activity' },
        ...(tasksEnabled ? [{ name: 'Tasks', id: 'tasks', active: true, type: 'tasks' }] : []),
        ...(notesEnabled
            ? [{ name: 'Notes', id: 'documents', active: true, type: 'documents' }]
            : []),
    ] as DetailViewTab[]

    const filterTabs = (tabConfig: DetailViewTab[]) => {
        return tabConfig.filter((t) => {
            switch (t.type) {
                case 'tasks':
                    return tasksEnabled
                case 'documents':
                    return notesEnabled
                default:
                    return true
            }
        })
    }

    const systemButtons = [
        { _sid: 'edit', name: 'Edit' },
        ...(canDeleteRecords(object) ? [{ _sid: 'delete', name: 'Delete' }] : []),
        ...(tasksEnabled ? [{ _sid: 'add_task', name: 'Add Task' }] : []),
    ]

    const filterButtons = (buttonConfig: Button[]) => {
        return buttonConfig.filter((b) => (b._sid === 'add_task' ? tasksEnabled : true))
    }

    return { systemTabs, systemButtons, filterTabs, filterButtons }
}

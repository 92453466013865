import React, { useMemo } from 'react'

import { Spinner } from '@chakra-ui/react'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownSeparator,
    DropdownTitle,
} from 'ui/components/Dropdown'

import { getSchemaEditorLookupName, useSchemaEditorObjects } from './utils'

type OutputSchemaEditorNodeTypeProps = {
    value: WorkflowOutputSchemaNodeType
    extra_options?: WorkflowExtraOptions
    onChange: (value: WorkflowOutputSchemaNodeType, extra_options?: WorkflowExtraOptions) => void
}

type Option = {
    id: string
    value: WorkflowOutputSchemaNodeType
    name: string
    extra_options?: WorkflowExtraOptions
}

const STATIC_OPTIONS: Option[] = [
    {
        id: 'string',
        value: 'string',
        name: 'String',
    },
    {
        id: 'number',
        value: 'number',
        name: 'Number',
    },
    {
        id: 'boolean',
        value: 'boolean',
        name: 'Boolean',
    },
    {
        id: 'date',
        value: 'date',
        name: 'Date',
    },
    {
        id: 'datetime',
        value: 'datetime',
        name: 'Datetime',
    },
    {
        id: 'object',
        value: 'object',
        name: 'Object',
    },
    //  {
    //     id: 'array',
    //     value: 'array',
    //     name: 'List',
    // },
]

export const OutputSchemaEditorNodeType: React.FC<OutputSchemaEditorNodeTypeProps> = ({
    value,
    extra_options,
    onChange,
}) => {
    const { data: objects = [], isLoading } = useSchemaEditorObjects()

    const lookupOptions = objects.map<Option>((o) => {
        const name = getSchemaEditorLookupName(o)

        return {
            id: o._sid,
            value: 'lookup',
            name,
            extra_options: {
                link_target_object_id: o._sid,
            },
        }
    })

    const currentOption = useMemo(() => {
        if (value === 'lookup') {
            return lookupOptions.find(
                (o) =>
                    o.extra_options?.link_target_object_id === extra_options?.link_target_object_id
            )
        }

        return STATIC_OPTIONS.find((o) => o.value === value)
    }, [value, lookupOptions, extra_options?.link_target_object_id])

    return (
        <Dropdown>
            <DropdownButton variant="secondary" size="xs" endIcon={{ name: 'ChevronDown' }}>
                {currentOption?.name ?? 'Pick a type'}
            </DropdownButton>
            <DropdownContent align="end">
                {STATIC_OPTIONS.map((option) => (
                    <DropdownItem
                        key={option.id}
                        label={option.name}
                        multiSelect
                        checked={option.value === value}
                        onCheckedChange={(checked) => {
                            if (checked) onChange(option.value)
                        }}
                    />
                ))}
                <DropdownSeparator />
                {isLoading && (
                    <DropdownTitle>
                        <Spinner size="xs" color="neutral.800" />
                    </DropdownTitle>
                )}
                {lookupOptions.map((option) => (
                    <DropdownItem
                        key={option.id}
                        label={option.name}
                        multiSelect
                        checked={option.value === value}
                        onCheckedChange={(checked) => {
                            if (checked) onChange(option.value, option.extra_options)
                        }}
                    />
                ))}
            </DropdownContent>
        </Dropdown>
    )
}

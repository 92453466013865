import React from 'react'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'

import { FadeInStyle } from './Notifications.css'

type Props = {
    showUnreadIndicator: boolean
    notifications: NotificationDto[]
    isHovered: boolean
    markAsRead: (options: { notificationIds: number[]; isRead: boolean }) => void
    markAsArchived: (optionn: { notificationIds: number[]; isArchived: boolean }) => void
}

export function NotificationControls({
    showUnreadIndicator,
    notifications,
    isHovered,
    markAsRead,
    markAsArchived,
}: Props) {
    const isRead = notifications.every((n) => n.is_read)
    const isArchived = notifications.every((n) => n.is_archived)

    const toggleRead = () => {
        markAsRead({
            notificationIds: isRead
                ? [notifications[0].auto_id]
                : notifications.map((n) => n.auto_id),
            isRead: !isRead,
        })
        // if we're marking as unread, we're marking only the last notification as unread,
        // but we also need to unarchive the entire thread
        if (isRead && isArchived) {
            toggleArchived()
        }
    }

    const toggleArchived = () => {
        markAsArchived({
            notificationIds: notifications.map((n) => n.auto_id),
            isArchived: !isArchived,
        })
    }
    return (
        <Box position="relative" flex center height="m" width="4xl">
            <Container flex center position="absolute" style={{ right: 0 }} height="fit-content">
                {showUnreadIndicator && (
                    <Box pl="l" pr="xs">
                        <Box background="admin500" borderRadius="pill" width="m" height="m" />
                    </Box>
                )}
                <Container
                    flex
                    center
                    border
                    className={FadeInStyle({ show: isHovered })}
                    height="fit-content"
                >
                    <Tooltip label={!isRead ? 'Mark as read' : 'Mark as unread'} placement="bottom">
                        <Button
                            variant="ghost"
                            size="s"
                            onClick={toggleRead}
                            startIcon={{ name: !isRead ? 'CheckSquare2' : 'Square' }}
                        />
                    </Tooltip>
                    <Tooltip label={!isArchived ? 'Archive' : 'Unarchive'} placement="bottom">
                        <Button
                            variant="ghost"
                            size="s"
                            onClick={toggleArchived}
                            startIcon={{ name: !isArchived ? 'Archive' : 'Inbox' }}
                        />
                    </Tooltip>
                    {/* <Tooltip label="Reply" placement="bottom">
                    <Button variant="ghost" size="m">
                        <Icon name="comment" variant="regular" fontSize="body" />
                    </Button>
                </Tooltip> */}
                </Container>
            </Container>
        </Box>
    )
}

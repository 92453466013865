import React from 'react'

import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
    ModalParts,
} from 'ui/components/Modal'

import { useAppUsersRevokeAccessModalState } from './hooks/useAppUsersRevokeAccessModalState'
import { AppGroup, AppUser } from './types'

type AppUsersRevokeAccessModalProps = {
    isOpen: boolean
    onOpenChange: (isOpen: boolean) => void
    onConfirm: () => Promise<void>
    group?: AppGroup
    user?: AppUser
}

export const AppUsersRevokeAccessModal: React.FC<AppUsersRevokeAccessModalProps> = ({
    isOpen,
    user,
    group,
    onOpenChange,
    onConfirm,
}) => {
    const { title, groupName, userName, isGroup } = useAppUsersRevokeAccessModalState({
        user,
        group,
    })

    return (
        <Modal open={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                <ModalHeader
                    subtitle={
                        isGroup ? (
                            <ModalParts.Subtitle>
                                Are you sure you want to revoke access to the{' '}
                                <strong>{groupName}</strong> group?
                            </ModalParts.Subtitle>
                        ) : (
                            <ModalParts.Subtitle>
                                Are you sure you want to revoke access to the{' '}
                                <strong>{userName}</strong> user?
                            </ModalParts.Subtitle>
                        )
                    }
                    title={title}
                    showCloseButton={false}
                />
                <ModalFooter>
                    <ModalFooterButtonGroup layout="inline">
                        <ModalCloseTrigger asChild>
                            <Button size="l" variant="ghost">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                        <Button size="l" variant="destructive" onClick={onConfirm}>
                            Revoke access
                        </Button>
                    </ModalFooterButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

import * as React from 'react'

import styled from '@emotion/styled'

const InnerFlex = styled.div`
    display: flex;
    flex-direction: ${(p) => (p.column ? 'column' : 'row')};
`

export const Flex = ({ as, column, children, ...props }) => (
    <InnerFlex as={as} column={column} {...props}>
        {children}
    </InnerFlex>
)

// @ts-strict-ignore
import Delta from 'quill-delta'
import Op from 'quill-delta/dist/Op'
import markdown from 'remark-parse'
import unified from 'unified'

type MarkdownToQuillOptions = {
    debug?: boolean
    tableIdGenerator: () => string
}

const defaultOptions: MarkdownToQuillOptions = {
    debug: false,
    tableIdGenerator: () => {
        const id = Math.random().toString(36).slice(2, 6)
        return `row-${id}`
    },
}

export class MarkdownToQuill {
    options: MarkdownToQuillOptions

    blocks = ['paragraph', 'code', 'heading', 'blockquote', 'list', 'table']

    constructor(options?: Partial<MarkdownToQuillOptions>) {
        this.options = {
            ...defaultOptions,
            ...options,
        }
    }

    convert(text: string): Op[] {
        const processor = unified().use(markdown)
        const tree = processor.parse(text)

        if (this.options.debug) {
            console.log('tree', tree)
        }
        const delta = this.convertChildren(null, tree, {})
        return delta.ops
    }

    private convertChildren(
        parent: any | null,
        node: any,
        op: Op = {},
        indent = 0,
        extra?: any
    ): Delta {
        const { children } = node as any
        let delta = new Delta()
        if (children) {
            if (this.options.debug) {
                console.log('children:', children, extra)
            }
            children.forEach((child) => {
                switch (child.type) {
                    case 'paragraph':
                        delta = delta.concat(this.convertChildren(node, child, op, indent + 1))
                        if (!parent) {
                            delta.insert('\n')
                        }
                        break
                    case 'code':
                        const lines = String(child.value).split('\n')
                        lines.forEach((line) => {
                            if (line) {
                                delta.push({ insert: line })
                            }
                            delta.push({ insert: '\n', attributes: { 'code-block': true } })
                        })

                        break
                    case 'list':
                        delta = delta.concat(this.convertChildren(node, child, op, indent))
                        break
                    case 'listItem':
                        delta = delta.concat(this.convertListItem(node, child, indent))
                        break
                    case 'heading':
                        delta = delta.concat(this.convertChildren(node, child, op, indent + 1))
                        delta.push({
                            insert: '\n',
                            attributes: { header: child.depth || 1 },
                        })
                        break
                    case 'blockquote':
                        delta = delta.concat(this.convertChildren(node, child, op, indent + 1))
                        delta.push({ insert: '\n', attributes: { blockquote: true } })
                        break
                    case 'break':
                        // If we are expecting things to be WYSIWYG, then double space isn't a break.
                        delta.push({ insert: '' })
                        break
                    default:
                        const d = this.convertInline(node, child, op)
                        if (d) {
                            delta = delta.concat(d)
                        }
                }
            })
        }
        return delta
    }

    private isBlock(type: string) {
        return this.blocks.includes(type)
    }

    private convertInline(parent: any, child: any, op: Op): Delta | null {
        switch (child.type) {
            case 'strong':
                return this.inlineFormat(parent, child, op, { bold: true })
            case 'emphasis':
                return this.inlineFormat(parent, child, op, { italic: true })
            case 'delete':
                return this.inlineFormat(parent, child, op, { strike: true })
            case 'inlineCode':
                return this.inlineFormat(parent, child, op, { code: true })
            case 'link':
                return this.inlineFormat(parent, child, op, { link: child.url })
            case 'image':
                return this.inlineFormat(parent, child, op, {}, { image: child.url })
            case 'text':
            default:
                return this.inlineFormat(parent, child, op, {})
        }
    }

    private inlineFormat(
        parent: any,
        node: any,
        op: Op,
        attributes: any,
        insert: any | null = undefined
    ): Delta | null {
        const text = node.value && typeof node.value === 'string' ? node.value : null
        const newAttributes = { ...op.attributes, ...attributes }
        op = { ...op }
        if (text) {
            op.insert = text
        }
        if (insert) {
            op.insert = insert
        }
        if (Object.keys(newAttributes).length) {
            op.attributes = newAttributes
        }
        return node.children
            ? this.convertChildren(parent, node, op)
            : op.insert
              ? new Delta().push(op)
              : null
    }

    private convertListItem(parent: any, node: any, indent = 0): Delta {
        let delta = new Delta()
        if (node.children.length) {
            for (const child of node.children) {
                delta = delta.concat(this.convertChildren(parent, child, {}, indent + 1))
                if (child.type !== 'list') {
                    const attributes = this.handleListItemAttributes(parent, node, indent)
                    delta.push({ insert: '\n', attributes })
                }
            }
        } else {
            // No children because it's an empty list item.
            const attributes = this.handleListItemAttributes(parent, node, indent)
            delta.push({ insert: '' })
            delta.push({ insert: '\n', attributes })
        }

        if (this.options.debug) {
            console.log('list item', delta.ops)
        }
        return delta
    }

    private handleListItemAttributes(parent: any, node: any, indent: Number): any {
        let listAttribute = ''
        if (parent.ordered) {
            listAttribute = 'ordered'
        } else if (node.checked) {
            listAttribute = 'checked'
        } else if (node.checked === false) {
            listAttribute = 'unchecked'
        } else {
            listAttribute = 'bullet'
        }
        const attributes = { list: listAttribute }
        if (indent) {
            attributes['indent'] = indent
        }
        return attributes
    }
}

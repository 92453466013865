/* Code Quality: Good */

import React from 'react'

import { SettingsContent, SettingsWrapper } from 'features/admin/settings/ui/SettingsFrame'
import SettingsHeading from 'features/admin/settings/ui/SettingsHeading'
import { Frame } from 'features/core/Frame'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'
import { Heading, Section } from 'legacy/v1/ui'

export const ImpersonatingWarningPage = () => {
    const { stopPreviewing } = usePreviewServiceContext()
    return (
        <Frame header={false}>
            <SettingsHeading name="Users" />
            <SettingsWrapper>
                <SettingsContent style={{ marginBottom: 20 }}>
                    <Section>
                        <Heading onClick={() => stopPreviewing()} size="small">
                            You are currently previewing as another user or role. Click{' '}
                            <u style={{ cursor: 'pointer' }}>here</u> to stop previewing.
                        </Heading>
                    </Section>
                </SettingsContent>
            </SettingsWrapper>
        </Frame>
    )
}

import React, { ReactNode } from 'react'

import styled from '@emotion/styled'

import Button from './Button'
import Flex from './Flex'
import Modal, { StackerModalProps } from './Modal'
import Text from './Text'

export type ConfirmationModalProps = {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    inProgress?: boolean
    icon?: ReactNode
    title: string
    details?: string
    warning?: string
    customDetailsComponent?: ReactNode
    customConfirm?: string
    customCancel?: string
    confirmVariant?: string
    cancelVariant?: string
    error?: string
    customSize?: string
} & StackerModalProps

/** @deprecated
 *  For new designs use `ConfirmationModal` from `features/workspace/modals/ConfirmationModal` when possible
 */
const ConfirmationModal = ({
    isOpen,
    onClose,
    icon,
    onConfirm,
    title,
    details,
    customDetailsComponent,
    warning,
    customConfirm,
    customCancel,
    customSize,
    error,
    confirmVariant = 'Primary',
    cancelVariant = 'Secondary',
    inProgress = false,
    zIndex,
}: ConfirmationModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            noDividers
            size={customSize ?? '300px'}
            showCloseButton={false}
            zIndex={zIndex}
            body={
                <Flex direction="column">
                    {!!icon && icon}
                    {title && (
                        <ModalTitle variant="modalTitle" mt={6} fontWeight="bold" mb={4}>
                            {title}
                        </ModalTitle>
                    )}
                    {!customDetailsComponent && details && <Text>{details}</Text>}
                    {!!customDetailsComponent && customDetailsComponent}
                    {warning && (
                        <Text mt={4} fontWeight="bold">
                            {warning}
                        </Text>
                    )}
                    {error && (
                        <Text mt={4} variant="error">
                            {error}
                        </Text>
                    )}
                    <Button
                        variant={confirmVariant}
                        buttonSize="large"
                        mt={4}
                        w="100%"
                        onClick={onConfirm}
                        isDisabled={inProgress}
                        icon={inProgress ? 'refresh' : ''}
                        data-testid="actions.delete.confirm"
                    >
                        {customConfirm ?? 'Confirm'}
                    </Button>
                    <Button
                        variant={cancelVariant}
                        buttonSize="large"
                        mt={2}
                        w="100%"
                        onClick={onClose}
                        data-testid="actions.delete.cancel"
                    >
                        {customCancel ?? 'Cancel'}
                    </Button>
                </Flex>
            }
        />
    )
}

const ModalTitle = styled(Text)`
    white-space: normal;
    overflow: visible;
`

export default ConfirmationModal

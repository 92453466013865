// @ts-strict-ignore
import { useCallback, useMemo, useRef } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import {
    convertV2FieldColorToTheme,
    getDefaultDropdownFieldOptionColorName,
} from 'utils/fieldUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

import { useBoardViewAddNewCardFieldState } from './useBoardViewAddNewCardFieldState'

const MAX_LABEL_LENGTH = 25
const MAX_ITEMS = 2

type UseBoardViewAddNewCardFieldDropdownOptions = {
    field: FieldDto
    value: string | string[]
    onChange: (value?: string | string[]) => void
}

type OptionValue = {
    label: string
    value: string
    color?: string
}

export function useBoardViewAddNewCardFieldDropdownState(
    options: UseBoardViewAddNewCardFieldDropdownOptions
) {
    const { field, value, onChange } = options

    const {
        label: fieldLabel,
        onClear,
        isEditable,
    } = useBoardViewAddNewCardFieldState({ field, onChange })

    const currentValue = getCurrentValue(value)
    const internalValue = useDeepEqualsMemoValue(currentValue)
    const effectiveValue = useMemo(() => new Set(internalValue), [internalValue])
    const effectiveValueRef = useRef(effectiveValue)
    effectiveValueRef.current = effectiveValue

    const isSingle = field.type === 'dropdown'

    const editOptions = useMemo<OptionValue[]>(() => {
        const allowColors = !!field.options?.allow_dropdown_colors

        const options =
            field.options?.options?.map((option) => {
                let color = getDefaultDropdownFieldOptionColorName()
                if (allowColors && option?.color) {
                    // This will try to map the old color format to the new one.
                    // If the color is not found, it means that this is a new color,
                    // so use it as is.
                    color = convertV2FieldColorToTheme(option.color)
                }

                const label = truncateText(option.label ?? option.value!, MAX_LABEL_LENGTH)

                return {
                    label,
                    value: option.value!,
                    color,
                }
            }) ?? []

        return options
    }, [field.options?.allow_dropdown_colors, field.options?.options])

    const onEditValue = useCallback(
        (value: string, isChecked: boolean) => {
            if (field.type === 'dropdown') {
                // Just replace the value.
                onChange(value)
            } else {
                const existingValue = effectiveValueRef.current
                const newValue = new Set(existingValue)

                // Add or remove the value from the list of values.
                if (isChecked) {
                    newValue.add(value)
                } else {
                    newValue.delete(value)
                }

                onChange(Array.from(newValue))
            }
        },
        [field.type, onChange]
    )

    const label = formatLabel(effectiveValue, editOptions, fieldLabel)

    const fieldIcon = useDeepEqualsMemoValue(getFieldIcon(field))

    return useMemo(
        () => ({
            label,
            onClear,
            isSingle,
            editOptions,
            onEditValue,
            value: effectiveValue,
            isEditable,
            fieldIcon,
        }),
        [editOptions, effectiveValue, fieldIcon, isEditable, isSingle, label, onClear, onEditValue]
    )
}

function getCurrentValue(value?: string | string[]) {
    if (Array.isArray(value)) {
        return value
    }

    return value ? [value] : []
}

function formatLabel(value: Set<string>, editOptions: OptionValue[], defaultLabel: string) {
    if (value.size < 1) {
        return defaultLabel
    }

    const activeOptions = editOptions.filter((option) => value.has(option.value))

    const overflowingItemCount = activeOptions.length - MAX_ITEMS
    const truncatedOptions = activeOptions.slice(0, MAX_ITEMS)

    const formattedItems = truncatedOptions.map((option) => option.label).join(', ')

    if (overflowingItemCount > 0) {
        return `${formattedItems} +${overflowingItemCount}`
    }

    return formattedItems
}

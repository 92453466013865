// @ts-strict-ignore
/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react'

import { Progress } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { isEqual } from 'lodash'

import { Text, Tooltip } from 'v2/ui'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import useDebounce from 'v2/ui/utils/useDebounce'

import { Box, BoxProps } from 'ui/components/Box'

import { InputContainerStyle } from './CommentEditor.css'
import ReactFilestack from 'v2/ui/ReactFilestack'
import { AttachmentGallery } from 'features/attachments/AttachmentGallery'
import { CommentContent, CommentContentTipTap } from './types'
import { useAttachments } from 'features/attachments/useAttachments'
import { JSONContent } from '@tiptap/react'
import { useKeyboardShortcutsExtension } from 'features/tiptap/Extensions/KeyboardShortcutExtension'
import { TipTapEditor, TipTapEditorHandle } from 'features/tiptap/TipTapEditor'
import { SimpleBlockTypes } from 'features/tiptap/BlockTypes'
import { useLocalStorageState } from 'utils/useLocalStorageState'
import { Attachment } from 'features/attachments/types'
import { useActivityFeedContext } from './useActivityFeedContext'

const MAX_POST_SIZE = 50000

const defaultState = {
    text: '',
    message: { type: 'doc', content: [{ type: 'paragraph' }] },
    mentions: [] as string[],
    records: [] as CommentContentTipTap['records'],
}

const defaultDraft: CommentContent = {
    ...defaultState,
    format: 'tiptap',
    attachments: [],
}

export type CommentEditorProps = {
    message?: CommentContent
    onSubmit: (message: CommentContent) => Promise<any>
    placeholder?: string
    inputContainerProps?: BoxProps
    stackId?: string
    autoFocus?: boolean
    autoSaveDraft?: boolean
    draftId?: string
}

export function CommentEditor({
    message,
    onSubmit,
    placeholder = 'Enter a message...',
    inputContainerProps,
    stackId,
    autoFocus,
    autoSaveDraft,
    draftId,
}: CommentEditorProps) {
    const [commentDraft, setCommentDraft] = useLocalStorageState<CommentContent>(
        `Comment_Draft_${draftId}`,
        { defaultValue: defaultDraft }
    )

    const [messageState, setMessageState] = useState(commentDraft)
    const [error, setError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const editor = useRef<TipTapEditorHandle>(null)

    const { records } = useActivityFeedContext()
    const [postLength, setPostLength] = useState(0)
    const [uploadedFileTimestamp, setUploadedFileTimestamp] = useState(new Date().getTime())

    const {
        attachments,
        uploadingAttachments,
        uploadAttachment,
        removeAttachment,
        clearAttachments,
        onAttachmentUploaded,
        setAttachments,
    } = useAttachments()

    // Calculates the post length as a percentage of maximum. Used for
    // displaying the progress/size indicator
    const updatePostLength = useDebounce(() => {
        const message = getMessage()
        const textLength = JSON.stringify(message.message || {}).length
        const totalLength = JSON.stringify(message).length

        // Overhead is how much data is used by the JSON structure and any
        // attachment objects
        const overhead = totalLength - textLength

        // Unless it's an extremely long post where just the overhead is larger than the
        // max size, we calculate our post length % without the overhead included.
        // This makes for a more accurate prepresentation to the user (ie., when the
        // the indicator says 50%, visually if they add about the same amount of content
        // they'll hit the limit)
        if (overhead < MAX_POST_SIZE) {
            setPostLength((totalLength - overhead) / (MAX_POST_SIZE - overhead))
        } else {
            setPostLength(totalLength / MAX_POST_SIZE)
        }
    })
    const handleMessageChange = (value: JSONContent) => {
        if (!isEqual(value, messageState.message)) {
            const textValue = editor.current?.editor?.getText() ?? ''

            const mentions = editor.current?.getMentions()
            const records = editor.current?.getRecordLinks()

            setMessageState((x) => {
                const newState = {
                    ...x,
                    message: value,
                    text: textValue,
                    mentions,
                    records,
                }

                if (autoSaveDraft) {
                    setCommentDraft({
                        ...defaultDraft,
                        ...newState,
                        attachments,
                    })
                }

                return newState
            })
        }
    }

    useEffect(() => {
        updatePostLength()
        setError(false)
    }, [messageState])

    useEffect(() => {
        if (!autoSaveDraft) return

        // If the draft was updated in another component, we sync it.
        const isMessageChanged =
            commentDraft.message && !isEqual(messageState, commentDraft.message)
        if (isMessageChanged) setMessageState(commentDraft)

        const isAttachmentsChanged =
            commentDraft.attachments && !isEqual(attachments, commentDraft.attachments)
        if (isAttachmentsChanged) setAttachments(commentDraft.attachments)
    }, [commentDraft, autoSaveDraft])

    const getMessage = () => {
        // We get the message info straight from the editor as opposed to the local
        // messageState because due to debouncing, that state may not yet be fully synced.
        const content = editor.current?.editor?.getJSON() ?? {}
        const plainTextMessage = editor.current?.editor?.getText() ?? ''
        const mentions = editor.current?.getMentions()
        const records = editor.current?.getRecordLinks()

        const message: CommentContent = {
            format: 'tiptap',
            plainTextMessage,
            attachments,
            mentions,
            records,
        }

        message.message = plainTextMessage ? content : {}

        return message
    }

    const postComment = async () => {
        const message = getMessage()
        await onSubmit(message)
    }

    const clearEditor = () => {
        // This deselects the editor text before clearing it
        // The Slate editor will throw an error if we don't do this
        if (editor.current) {
            editor.current.editor?.chain().clearContent().blur().run()
        }
        setMessageState(defaultState)
        if (autoSaveDraft) setCommentDraft(defaultDraft)
    }

    const doSubmit = async () => {
        if (!_canSubmit()) return

        setError(false)
        setSubmitting(true)

        try {
            await postComment()
        } catch (e) {
            setSubmitting(false)
            setError(true)
            Sentry.captureException(e)
            return
        }
        clearEditor()
        clearAttachments()
        setSubmitting(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        doSubmit()
    }

    const keyboardShortcuts = useKeyboardShortcutsExtension({
        name: 'shortcuts',
        shortcuts: {
            'Mod-Enter': () => {
                doSubmit()
                return true
            },
        },
    })

    const hasText = messageState.text.length > 0
    const _canSubmit = () =>
        (hasText || attachments.length > 0) && uploadingAttachments.length === 0 && !submitting

    const handleUpload = (data) => {
        let newAttachments: Attachment[] = []
        for (const file of data.filesUploaded) {
            newAttachments = onAttachmentUploaded(file.url, {
                filename: file.filename,
                mimetype: file.mimetype,
                size: file.size,
                url: file.url,
            })
        }

        if (autoSaveDraft) {
            // Persist attachments to draft.
            setCommentDraft({
                ...defaultDraft,
                ...messageState,
                attachments: newAttachments,
            })
        }
    }

    const handlePaste = (event: React.ClipboardEvent) => {
        const clipboardItems = event.clipboardData.items
        const items = [].slice.call(clipboardItems).filter(function (item) {
            // Filter the image items only
            return item.type.indexOf('image') !== -1
        })
        if (items.length === 0) {
            return
        }

        const item = items[0]
        // Get the blob of image
        const blob = item.getAsFile()
        uploadAttachment(blob)
    }

    const handleAttachmentRemove = (id: string) => {
        const newAttachments = removeAttachment(id)

        if (autoSaveDraft) {
            // Persist attachments to draft.
            setCommentDraft({
                ...defaultDraft,
                ...messageState,
                attachments: newAttachments,
            })
        }
    }

    const handleAttachmentUpload = (id: string, attachment: Attachment) => {
        const newAttachments = onAttachmentUploaded(id, attachment)

        if (autoSaveDraft) {
            // Persist attachments to draft.
            setCommentDraft({
                ...defaultDraft,
                ...messageState,
                attachments: newAttachments,
            })
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box flex column onPaste={handlePaste}>
                <Box
                    className={InputContainerStyle({ isActive: hasText })}
                    {...inputContainerProps}
                    flex
                    center
                >
                    <Box
                        grow
                        className="no-paragraph-margins"
                        style={{
                            padding: '6px 0px',
                            minWidth: 0,
                        }}
                    >
                        <TipTapEditor
                            ref={editor}
                            additionalExtensions={[keyboardShortcuts]}
                            content={messageState.message}
                            onChange={handleMessageChange}
                            readOnly={submitting}
                            placeholder={placeholder}
                            allowedBlockTypes={SimpleBlockTypes}
                            autoFocus={autoFocus}
                            className="activity-comment-editor"
                            allowPastingImages={false}
                            allowDraggingImages={false}
                        />
                    </Box>

                    <Box flexShrink={0}>
                        <ReactFilestack
                            options={{
                                maxFiles: 5,
                            }}
                            onSuccess={handleUpload}
                            customRender={({ onPick }) => {
                                return (
                                    <Button
                                        variant="ghost"
                                        size="xs"
                                        startIcon={{ name: 'Paperclip' }}
                                        onClick={onPick}
                                    />
                                )
                            }}
                        />
                    </Box>
                    <Tooltip disabled={postLength <= 1} label="Your post is too long to submit">
                        <Box flex flexShrink={0}>
                            {postLength > 0.6 && (
                                <Progress
                                    width="50px"
                                    color="green"
                                    bg="gray.100"
                                    value={postLength * 100}
                                    borderRadius={postLength <= 1 ? '5px' : '5px 0px 0px 5px'}
                                    mr={postLength <= 1 ? 10 : undefined}
                                />
                            )}
                            {postLength > 1 && (
                                <Progress
                                    width={8}
                                    color="pink"
                                    bg={undefined}
                                    mr={2}
                                    borderLeft="2px solid black"
                                    value={(postLength - 1) * 100 + 10}
                                    borderRadius={'0px 5px 5px 0px'}
                                />
                            )}
                        </Box>
                    </Tooltip>
                    {(hasText || attachments.length > 0) && (
                        <Button
                            type="submit"
                            size="xs"
                            variant="ghost"
                            disabled={!_canSubmit()}
                            flexShrink={0}
                        >
                            <Icon name="Send" />
                        </Button>
                    )}
                </Box>
                <AttachmentGallery
                    attachments={attachments}
                    onDeleteAttachment={handleAttachmentRemove}
                    uploadingAttachments={uploadingAttachments}
                    onUploaded={handleAttachmentUpload}
                />
                {error && (
                    <Text variant="error" mr={4}>
                        Unable to post message. Please try again.
                    </Text>
                )}
            </Box>
        </form>
    )
}

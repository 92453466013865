import without from 'lodash/without'

import { byID } from 'data/utils/byID'
import {
    ACTION_CREATED,
    ACTION_REMOVED,
    ACTION_UPDATED,
    ACTIONS_FETCHED,
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
} from 'data/utils/constants'
import { unique } from 'utils/utils'

// STATE
export const initialState = {
    actions: [],
    entities: {},
    fetching: [],
}

// REDUCERS
export function actionReducer(state = initialState, reduxAction) {
    let actions
    let action
    let fetching
    switch (reduxAction.type) {
        case ACTIONS_FETCHED:
            if (!reduxAction.payload) return state
            return {
                ...state,
                actions: unique([...state.actions, ...reduxAction.payload.map((a) => a._sid)]),
                entities: { ...state.entities, ...byID(reduxAction.payload) },
                fetching: state.fetching.filter((item) => item !== action.key),
            }
        case ACTION_CREATED:
            if (!reduxAction.payload) return state
            actions = [...state.actions, [reduxAction.payload._sid]]
            return {
                ...state,
                actions,
                entities: { ...state.entities, [reduxAction.payload._sid]: reduxAction.payload },
            }
        case ACTION_UPDATED:
            if (!reduxAction.payload) return state
            action = { ...state.entities[reduxAction.id], ...reduxAction.payload }
            return {
                ...state,
                entities: { ...state.entities, [action._sid]: action },
            }
        case ACTION_REMOVED:
            actions = state.actions.map((as) => without(as, reduxAction.id))
            return {
                ...state,
                actions,
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, reduxAction.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== reduxAction.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return initialState
        default:
            return state
    }
}

import React from 'react'

import useLDFlags from 'data/hooks/useLDFlags'
import {
    TrayButtonBase,
    TrayButtonBaseProps,
} from 'features/workspace/AdminSideTray/common/TrayButtonBase'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

type WorkflowsButtonProps = TrayButtonBaseProps & {}

export const WorkflowsButton: React.VFC<WorkflowsButtonProps> = ({}) => {
    const { state, showWorkflows, close } = useSlidingPane()

    const { flags } = useLDFlags()
    if (!flags.automations) return null

    const handleClick = () => {
        if (state.key !== 'workflows') {
            showWorkflows()
        } else {
            close()
        }
    }

    return (
        <TrayButtonBase
            icon="bolt"
            tooltip="Build automations and custom workflows"
            onClick={handleClick}
            isActive={state.key === 'workflows'}
            data-testid="admin-side-tray.workflows-button"
        />
    )
}

import React, { forwardRef } from 'react'

import * as Parts from './Modal.parts'

type ModalFooterRef = HTMLDivElement

type ModalFooterProps = React.ComponentPropsWithoutRef<typeof Parts.Footer> & {}

export const ModalFooter = forwardRef<ModalFooterRef, ModalFooterProps>((props, ref) => {
    return <Parts.Footer ref={ref} {...props} />
})

import React from 'react'

import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'

import { usePercentageAttributeDisplayState } from './hooks/usePercentageAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type PercentageAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    isLoading?: boolean
    showIcon?: boolean
}

export const PercentageAttributeTag: AttributeDisplayComponent<
    number,
    PercentageAttributeTagProps
> = React.memo(function PercentageAttributeTag({
    field,
    value,
    isLoading,
    style,
    showIcon,
    ...props
}) {
    const { content, icon } = usePercentageAttributeDisplayState({
        field,
        value,
        isLoading,
        showIcon,
    })

    return (
        <Skeleton isLoading={isLoading}>
            <Tag
                size="2xs"
                type="outline"
                weight="regular"
                style={{ wordBreak: 'break-word', ...style }}
                pointerEvents="none"
                noShrink
                startIcon={icon}
                {...props}
            >
                {content}
            </Tag>
        </Skeleton>
    )
})

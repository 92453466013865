import React, { useCallback, useMemo, useState } from 'react'

import { TabList, TabPanels } from '@chakra-ui/react'
import get from 'lodash/get'
import { generateId } from 'v2/blocks/utils'
import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'

import { withObject } from 'data/wrappers/withObject'

import { Box, Checkbox, Dropdown, Tab, TabPanel, Tabs, Text } from 'v2/ui'

import { Label } from 'ui/deprecated/forms/ui'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { generateAllFields } from './common'
import FieldsEditor from './FieldsEditor'

const getItems = (contents) => {
    let items = contents || []

    // This code upgrades existing data. We now require that all
    // non-field elements (such as sections) have an id value.
    // Also, we no longer support adding the same field twice,
    // so we remove any duplicates.
    items = items.reduce((newList, item) => {
        if (!item.fieldId) {
            if (!item.id) item.id = generateId(items)
        } else if (newList.find((x) => x.fieldId === item.fieldId)) {
            return newList
        }
        return [...newList, item]
    }, [])

    return items
}
const FieldContainerEditor = withObject(
    ({
        context,
        object,
        filterField,
        disallowSections,
        hideFieldRequiredSetting,
        hideFieldFullWidthSetting,
        hideFieldDescriptionSetting,
        showAllFieldsModeSetting,
        maxItemsSelected,
        setOnlyVisible,
        setAttr,
        showDefaultValueSetting = false,
        showPlaceholderSetting = false,
    }) => {
        const { block } = context
        const isCreateView = get(context, 'view.creating')
        const sendUpdate = context.editor.actions.updateAttributes
        const [selectedTabIndex, selectTab] = useState(0)
        const attrs = block.config.attributes

        const contents = block?.config?.attributes?.contents
        const conditionalVisibilityFilters =
            block?.config?.attributes?.fieldConditionalVisibilityFilters
        const updateConditionalVisibilityFilters = useCallback(
            (filtersMap) => {
                sendUpdate(block.id, 'fieldConditionalVisibilityFilters', filtersMap)
            },
            [sendUpdate, block.id]
        )
        const items = useMemo(() => getItems(contents), [contents])

        const handleUpdate = useCallback(
            (items) => {
                const current = contents?.map((c) => c.id || c.fieldId)
                const patch = items?.map((c) => c.id || c.fieldId)
                const defaultFieldsSelected = block?.config?.attributes?.defaultFieldsSelected
                const showAllFields = block?.config?.attributes?.showAllFields
                const isUpdateFieldsModified = patch !== current

                sendUpdate(block.id, 'contents', items)
                // If the user has changed the fields, and we're not in all fields mode
                // then set the defaultFieldsSelected flag to false, so we know there are
                // customizations we want to preserve if they go into and back out of all fields
                // mode again
                if (defaultFieldsSelected && isUpdateFieldsModified && !showAllFields) {
                    sendUpdate(block.id, 'defaultFieldsSelected', false)
                }
            },
            [block, contents, sendUpdate]
        )

        const onShowAllFieldsModeChange = useCallback(
            (showAllFields) => {
                sendUpdate(block.id, 'showAllFields', showAllFields)
                const defaultFieldsSelected = block?.config?.attributes?.defaultFieldsSelected
                // If we're exiting all fields mode, and the user has made no
                // customizations to the list of fields, then we want to go ahead
                // and select all fields now.
                if (!showAllFields && defaultFieldsSelected) {
                    const newItems = generateAllFields(object.fields, contents, isCreateView)
                    sendUpdate(block.id, 'contents', newItems)
                }
            },
            [
                sendUpdate,
                block.id,
                block?.config?.attributes?.defaultFieldsSelected,
                object.fields,
                contents,
                isCreateView,
            ]
        )

        const updateButtons = (patch) =>
            sendUpdate(block.id, 'pageButtons', {
                buttonStyle: '1pa',
                position: 'top',
                ...block.config.attributes.pageButtons,
                ...patch,
            })

        // When editing a field, the tabs at the top look like they apply to the field (as they are
        // underneath a title which is the field name) but actually they apply to the whole block.
        // To avoid confusion, hide them like we hide conditional visibility.
        const [viewingField, setViewingField] = useState(false)
        const setIsEditingItem = (value) => {
            setViewingField(value)
            setOnlyVisible(value)
        }

        const fieldsEditor = (
            <FieldsEditor
                object={object}
                filterField={filterField}
                items={items}
                setItems={handleUpdate}
                disallowSections={disallowSections}
                maxItemsSelected={maxItemsSelected}
                hideFieldRequiredSetting={hideFieldRequiredSetting}
                hideFieldFullWidthSetting={hideFieldFullWidthSetting}
                hideFieldDescriptionSetting={hideFieldDescriptionSetting}
                showAllFieldsModeSetting={showAllFieldsModeSetting}
                hideUrlOptions={isCreateView}
                hideColumns={isCreateView}
                setIsEditingItem={setIsEditingItem}
                isLayoutEditorComponent
                style={{ marginBottom: '1rem', marginTop: '0' }}
                showAllFields={block.config.attributes.showAllFields}
                onShowAllFieldsModeChange={onShowAllFieldsModeChange}
                includePrimaryFieldInAllFieldsMode={isCreateView}
                showDefaultValueSetting={showDefaultValueSetting}
                showPlaceholderSetting={showPlaceholderSetting}
                isCreate={isCreateView}
                showConditionalVisibility
                conditionalVisibilityFilters={conditionalVisibilityFilters}
                onConditionalVisibilityFiltersChange={updateConditionalVisibilityFilters}
            />
        )

        if (block.type === 'field_highlights' || isCreateView)
            return (
                <Box mt={4} height="100%" paddingBottom="20px">
                    {fieldsEditor}
                </Box>
            )

        return (
            <>
                <Tabs
                    onChange={selectTab}
                    index={selectedTabIndex}
                    variant="admin"
                    height="100%"
                    d="flex"
                    flexDirection="column"
                >
                    {!viewingField && (
                        <TabList>
                            <Tab variant="admin" style={{ width: '33.3%' }}>
                                Content
                            </Tab>
                            <Tab variant="admin" style={{ width: '33.3%' }}>
                                Actions
                            </Tab>
                            <Tab variant="admin" style={{ width: '33.3%' }}>
                                Options
                            </Tab>
                        </TabList>
                    )}
                    <TabPanels mt={4} height="100%" minHeight={0}>
                        <TabPanel
                            d={selectedTabIndex === 0 ? 'flex' : 'none'}
                            flexDirection="column"
                            height="100%"
                        >
                            {fieldsEditor}
                        </TabPanel>

                        <TabPanel
                            d={selectedTabIndex === 1 ? 'flex' : 'none'}
                            flexDirection="column"
                            height="100%"
                        >
                            <Label style={{ marginBottom: 5 }}>Display</Label>
                            <div
                                style={{
                                    color: V4DesignSystem.colors.gray[500],
                                    fontSize: '0.9rem',
                                    marginBottom: 5,
                                }}
                            >
                                Up to three buttons will be visible at once.
                            </div>
                            <Dropdown
                                value={block.config.attributes.pageButtons?.position || 'top'}
                                options={[
                                    { label: 'Top', value: 'top' },
                                    { label: 'Bottom', value: 'bottom' },
                                ]}
                                onChange={(position) => updateButtons({ position })}
                            />
                            <div style={{ height: 20 }} />
                            <Label style={{ marginBottom: 5 }}>Button Style</Label>
                            <Dropdown
                                value={block.config.attributes.pageButtons?.buttonsStyle || '1pa'}
                                options={[
                                    { label: '1 primary action', value: '1pa' },
                                    { label: 'Primary actions', value: 'primary' },
                                    { label: 'Secondary actions', value: 'secondary' },
                                ]}
                                onChange={(buttonsStyle) => updateButtons({ buttonsStyle })}
                            />
                            <div style={{ height: 20 }} />
                            <ActionButtonsSelector
                                object={object}
                                selectedButtons={block.config.attributes.pageButtons?.buttons || []}
                                setConfig={({ pageButtons }) =>
                                    updateButtons({ buttons: pageButtons })
                                }
                                additionalActions={[]}
                                editor="fields"
                            />
                        </TabPanel>
                        <TabPanel>
                            <Text variant="paletteSectionLabel">Appearance</Text>
                            <Checkbox
                                id="allowCollapseExpand"
                                isChecked={attrs.canCollapse}
                                onChange={(e) => setAttr('canCollapse', e.target.checked)}
                                variant="admin"
                            >
                                Allow sections to collapse and expand
                            </Checkbox>
                            <Checkbox
                                id="allowCollapseExpand"
                                isChecked={attrs.startCollapsed}
                                isDisabled={!attrs.canCollapse}
                                onChange={(e) => setAttr('startCollapsed', e.target.checked)}
                                variant="admin"
                            >
                                Start collapsed
                            </Checkbox>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </>
        )
    }
)

export default FieldContainerEditor

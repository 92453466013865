import { isMultiLineText } from 'data/utils/fieldDefinitions'

export const fieldToItem = (field: FieldDto): BlockFieldAttributeContent => ({
    type: 'field',
    fieldId: field._sid,
    objectId: field.object_id,
    fieldName: field.api_name,
    required: false,
    fullWidth: isMultiLineText(field.type, field.options),
    showCreateButton: ['lookup', 'multi_lookup'].includes(field.type),
    isPrimary: field.is_primary,
})

import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/useBoardViewContext'

export function useBoardViewListHeaderState() {
    const { records, requestFilters, requestIncludedFields } = useBoardViewContext()

    const totalCount = records ? Object.values(records).flat().length : 0

    return useMemo(
        () => ({
            recordCount: totalCount,
            requestFilters,
            requestIncludedFields,
        }),
        [requestFilters, requestIncludedFields, totalCount]
    )
}

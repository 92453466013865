import { createContext, useContext } from 'react'

export type WorkspaceContextData = {
    workspaceAccount: Account | null
    workspaceUser?: UserDto
    workspaceZone?: AccountZone | null
    isOnPortalDomain: boolean
}

export const WorkspaceContext = createContext<WorkspaceContextData>({
    workspaceAccount: null,
    isOnPortalDomain: false,
})

export const useWorkspaceContext = () => useContext(WorkspaceContext)

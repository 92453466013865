import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { EndActionComponent } from './types'

type InputEndActionSearchProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const InputEndActionSearch: EndActionComponent<InputEndActionSearchProps> = ({
    disabled,
    readOnly,
    ...props
}) => {
    return (
        <Box {...props}>
            <Button
                size="xs"
                variant="ghost"
                aria-label="Variable"
                disabled={disabled || readOnly}
                startIcon={{ name: 'Search' }}
            />
        </Box>
    )
}

import React, { useMemo } from 'react'
import ReactFocusLock from 'react-focus-lock'

import type { Placement } from '@popperjs/core'

import Popper from 'v2/ui/components/Popper'
import { autoSizeModifier } from 'v2/ui/components/popperModifiers/autoSizeModifier'

import { TasksQuickCreate } from './TasksQuickCreate'

type TasksQuickCreatePopoverProps = React.ComponentPropsWithoutRef<typeof TasksQuickCreate> & {
    open?: boolean
    placement?: Placement
    targetElement?: HTMLElement
    container?: HTMLElement
    onClose?: () => void
    isVirtualElement?: boolean
}

export const TasksQuickCreatePopover: React.FC<TasksQuickCreatePopoverProps> = ({
    open,
    placement,
    targetElement,
    container,
    onClose,
    isVirtualElement,
    ...props
}) => {
    const rootNode = useMemo(() => {
        for (const value of Array.from(document.body.childNodes.values())) {
            if (
                (container && value.contains(container)) ||
                (!isVirtualElement && targetElement && value.contains(targetElement))
            ) {
                return value
            }
        }

        return
    }, [container, isVirtualElement, targetElement])

    const handleCreate = (shouldDraftNew: boolean) => {
        if (!shouldDraftNew) onClose?.()
    }

    if (!open || !targetElement) return null

    return (
        <Popper
            placement={placement}
            referenceElement={targetElement}
            onClose={onClose}
            limitOuterActionsToDescendentsOf={rootNode}
            modifiers={[autoSizeModifier, { name: 'offset', options: { offset: [0, 8] } }]}
        >
            <ReactFocusLock>
                <TasksQuickCreate
                    border
                    popover
                    width="600px"
                    p="l"
                    onCreate={handleCreate}
                    onClose={onClose}
                    {...props}
                />
            </ReactFocusLock>
        </Popper>
    )
}

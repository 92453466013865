import React from 'react'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useTextAttributeDisplayState } from './hooks/useTextAttributeDisplayState'
import { AttributeDisplayComponent } from './types'
import { UrlAttributeTag } from './UrlAttributeTag'

type TextAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    openInNewTab?: boolean
    buttonTitle?: string
    displayAsImage?: boolean
    imageAltText?: string
    treatAsUrl?: boolean
    maxOverflowLength?: number
    showOverflowTooltip?: ResponsiveValue<boolean>
    isLoading?: boolean
    showIcon?: boolean
}

export const TextAttributeTag: AttributeDisplayComponent<string, TextAttributeTagProps> =
    React.memo(function TextAttributeTag({
        field,
        value: providedValue,
        showOverflowTooltip,
        maxOverflowLength,
        maxLength,
        isLoading,
        maxLines,
        style,
        openInNewTab,
        buttonTitle,
        displayAsImage,
        imageAltText,
        treatAsUrl,
        showIcon,
        ...props
    }) {
        const effectiveShowOverflowTooltip = useResponsiveValue(showOverflowTooltip)
        const effectiveMaxLines = useResponsiveValue(maxLines)
        const effectiveMaxLength = useResponsiveValue(maxLength)

        const { isOverflowing, value, icon } = useTextAttributeDisplayState({
            field,
            value: providedValue,
            maxLength: effectiveMaxLength,
            isLoading,
            showIcon,
        })

        if (treatAsUrl) {
            return (
                <UrlAttributeTag
                    field={field}
                    showOverflowTooltip={showOverflowTooltip}
                    maxOverflowLength={maxOverflowLength}
                    value={providedValue}
                    isLoading={isLoading}
                    buttonTitle={buttonTitle}
                    displayAsImage={displayAsImage}
                    imageAltText={imageAltText}
                    openInNewTab={openInNewTab}
                    maxLength={effectiveMaxLength}
                    style={style}
                    showIcon={showIcon}
                    {...props}
                />
            )
        }

        const shouldShowOverflowTooltip = isOverflowing && effectiveShowOverflowTooltip

        const content = (
            <Box minWidth={0} tabIndex={-1} trim={effectiveMaxLines === 1}>
                <Skeleton isLoading={isLoading}>
                    <Tag
                        size="2xs"
                        type="outline"
                        maxLength={effectiveMaxLength}
                        maxLines={effectiveMaxLines}
                        style={{
                            wordBreak: 'break-word',
                            ...style,
                        }}
                        pointerEvents={shouldShowOverflowTooltip ? 'auto' : 'none'}
                        noShrink
                        startIcon={icon}
                        {...props}
                    >
                        {value}
                    </Tag>
                </Skeleton>
            </Box>
        )

        if (shouldShowOverflowTooltip) {
            return (
                <Tooltip
                    content={
                        <Body
                            style={{
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                lineHeight: 'inherit',
                            }}
                            maxLength={maxOverflowLength}
                        >
                            {value}
                        </Body>
                    }
                    zIndex={200}
                    side="bottom"
                    asChild
                >
                    {content}
                </Tooltip>
            )
        }

        return content
    })

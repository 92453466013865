import { useMemo, useRef } from 'react'

import { useDraggable } from '@dnd-kit/core'
import { useComposedRefs } from '@radix-ui/react-compose-refs'

type UseLayoutEditorWidgetPickerDnDDisplayOptions = {
    widgetType: string
}

export function useLayoutEditorWidgetPickerDnDDisplay(
    options: UseLayoutEditorWidgetPickerDnDDisplayOptions
) {
    const { widgetType } = options

    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id: widgetType,
        data: {
            type: 'widgetPickerItem',
            widgetType,
            isFromPicker: true,
        },
    })

    const widgetRef = useRef<HTMLDivElement>(null)
    const composedRef = useComposedRefs<HTMLDivElement>(widgetRef, setNodeRef)

    const listenersRef = useRef(listeners)
    listenersRef.current = listeners

    return useMemo(
        () => ({
            ref: composedRef,
            listeners: listenersRef.current,
            attributes,
            isDragging,
        }),
        [attributes, composedRef, isDragging]
    )
}

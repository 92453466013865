import React from 'react'

import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { ExecutionHistoryStatusIcon } from './ExecutionHistoryStatusIcon'

type ExecutionHistoryListProps = React.ComponentPropsWithoutRef<typeof Box> & {
    executions: WorkflowExecutionDto[]
    selectedExecutionId: string | null
    setSelectedExecutionId: (id: string) => void
}

export const ExecutionHistoryList: React.FC<ExecutionHistoryListProps> = ({
    executions,
    selectedExecutionId,
    setSelectedExecutionId,
    props,
}) => {
    return (
        <Box flex column gap="xs" {...props}>
            {executions.length < 1 && (
                <Box color="textWeak" fontSize="bodyS" px="m">
                    Once this workflow has been executed, you will see a history of all executions
                    here.
                </Box>
            )}
            {executions.map((e) => (
                <ExecutionHistoryListItem
                    key={e._sid}
                    execution={e}
                    onClick={() => setSelectedExecutionId(e._sid)}
                    isSelected={e._sid === selectedExecutionId}
                />
            ))}
        </Box>
    )
}

type ExecutionHistoryListItemProps = React.ComponentPropsWithoutRef<typeof Button> & {
    execution: WorkflowExecutionDto
    isSelected?: boolean
}

const ExecutionHistoryListItem: React.FC<ExecutionHistoryListItemProps> = ({
    execution,
    isSelected,
    ...props
}) => {
    const timeDisplay = useDisplayTimeFrom(execution.started_at)

    return (
        <Button
            size="s"
            type="button"
            variant={isSelected ? 'primary' : 'ghost'}
            style={{
                justifyContent: 'flex-start',
            }}
            {...props}
        >
            <ExecutionHistoryStatusIcon
                status={execution.status}
                color={isSelected ? 'textInverse' : undefined}
            />
            {timeDisplay}
        </Button>
    )
}

import React from 'react'

import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Input } from 'ui/components/Input'

import { SlackTriggerConfig } from './SlackTrigger'
import { WorkflowTriggerEditorComponent } from './types'

export const SlackOnReactionAddedTriggerConfig: WorkflowTriggerEditorComponent = ({
    trigger,
    onChange,
    ...props
}) => {
    return (
        <>
            <SlackTriggerConfig trigger={trigger} onChange={onChange} {...props} />
            <ConfigurationLabel>Emoji</ConfigurationLabel>
            <Input
                value={(trigger.settings?.emoji as string | undefined) ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value

                    onChange({
                        settings: {
                            ...trigger.settings,
                            emoji: value,
                        },
                    })
                }}
            />
            <ConfigurationLabel mt="m">
                Enter a single emoji like <code>smile</code> or <code>grin</code>. Leave blank to
                match all emojis.
            </ConfigurationLabel>
        </>
    )
}

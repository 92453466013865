import React, { useRef } from 'react'

import { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { CommandProps, mergeAttributes, Node, Range, ReactNodeViewRenderer } from '@tiptap/react'

import { FormulaFunctionComponent } from './FormulaFunctionComponent'
import { ContextGroup, ContextSchema } from './types'

declare module '@tiptap/core' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Commands<ReturnType> {
        expressionFunction: {
            insertFunction: (attrs: { id: string }, range: Range) => ReturnType
        }
    }
}

const createFormulaFunctionExtension = ({ name }: { name: string }) => {
    return Node.create({
        name,
        group: 'inline',
        inline: true,
        selectable: false,
        atom: true,

        addAttributes() {
            return {
                id: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-id'),
                    renderHTML: (attributes) => {
                        if (!attributes.id) {
                            return {}
                        }

                        return {
                            'data-id': attributes.id,
                        }
                    },
                },

                args: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-args'),
                    renderHTML: (attributes) => {
                        if (!attributes.args) {
                            return {}
                        }

                        return {
                            'data-label': attributes.args,
                        }
                    },
                },
            }
        },

        parseHTML() {
            return [
                {
                    tag: `span[data-type="${this.name}"]`,
                },
            ]
        },

        renderHTML({ HTMLAttributes }) {
            return ['span', mergeAttributes({ 'data-type': this.name }, HTMLAttributes)]
        },

        renderText({ node }) {
            return node.attrs.id
        },

        addKeyboardShortcuts() {
            return {
                Backspace: () =>
                    this.editor.commands.command(({ tr, state }) => {
                        let isToken = false
                        const { selection } = state
                        const { empty, anchor } = selection

                        if (!empty) {
                            return false
                        }

                        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                            if (node.type.name === this.name) {
                                isToken = true
                                tr.insertText('', pos, pos + node.nodeSize)

                                return false
                            }
                        })

                        return isToken
                    }),
            }
        },
    })
}

export function useFormulaFunctionExtension({
    className,
    clearContent,
    contextSchema,
    renderGroupTitle,
    returnType,
    returnTypeOptions,
}: {
    className?: string
    clearContent?: boolean
    contextSchema: ContextSchema
    renderGroupTitle: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
    returnType?: FieldType
    returnTypeOptions?: FieldOptions
}) {
    const contextSchemaRef = useRef(contextSchema)
    contextSchemaRef.current = contextSchema

    return createFormulaFunctionExtension({ name: 'expressionFunction' })
        .extend({
            // @ts-ignore
            addCommands() {
                return {
                    insertFunction:
                        (attrs: { id: string; label: string }, range: Range) =>
                        ({ commands }: CommandProps) => {
                            let actualRange = {
                                ...range,
                            }
                            const content = [
                                {
                                    type: this.name,
                                    attrs,
                                },
                            ]
                            if (clearContent) {
                                return commands.setContent(content, true)
                            }
                            return commands.insertContentAt(actualRange, content)
                        },
                }
            },
            addNodeView() {
                return ReactNodeViewRenderer((props: any) =>
                    FormulaFunctionComponent({
                        ...props,
                        className,
                        provideContextSchema: () => contextSchemaRef.current,
                        renderGroupTitle,
                        returnType,
                        returnTypeOptions,
                    })
                )
            },
        })
        .configure({
            renderLabel({ node }: { node: ProseMirrorNode }) {
                const label = `${node.attrs.label ?? node.attrs.id}`

                return label
            },
        })
}

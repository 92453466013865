import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/useBoardViewContext'

const NUM_PLACEHOLDER_RECORDS = 10

export function useBoardViewLoaderState() {
    const { columns, object } = useBoardViewContext()

    return useMemo(
        () => ({
            columns,
            placeholderRows: makePlaceholderRows(object._sid, NUM_PLACEHOLDER_RECORDS),
        }),
        [columns, object._sid]
    )
}

function makePlaceholderRows(objectSid: string, length: number): RecordDto[] {
    return Array.from({ length }).map((_, index) => ({
        _sid: index.toString(),
        _object_id: objectSid,
        _primary: `Record ${index + 1}`,
    }))
}

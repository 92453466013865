import React, { useCallback } from 'react'

import styled from '@emotion/styled'

import { Box, Button, CircleIconButton } from 'v2/ui'
import { isVideoOrAudio, useThumbnail } from 'v2/ui/components/Attribute/attachmentUtils'
import styledProps from 'v2/ui/utils/styledProps'
import useHover from 'v2/ui/utils/useHover'

const Container = styled(Box)`
    position: relative;
    border-radius: 10%;
`

const StyledImage = styled('img')(styledProps, `object-fit: cover;`)

const getVideoOrAudioType = (attachment) => {
    const filename = attachment?.filename
    const extension = filename?.split('.')?.pop()?.toLowerCase()
    switch (extension) {
        case 'mp4':
        case 'mov':
            return 'video/mp4'
        case 'ogv':
        case 'ogg':
            return 'video/ogg'
        case 'webm':
            return 'video/webm'
        case 'oga':
            return 'audio/oga'
        case 'mp3':
            return 'audio/mp3'
        case 'wav':
            return 'audio/wav'
    }
    // If the files have a video type set
    const type = attachment?.type
    if (type == 'video/quicktime') return 'video/mp4'
    if (type) return type
    return 'video/mp4'
}

const isEmbed = (attachment) => {
    const filename = attachment?.filename ?? attachment?.url
    return /\.(pdf|html|txt|htm|xml)$/i.test(filename) || /(pdf|html|xml)$/i.test(attachment?.type)
}

export const Attachment = ({
    canDelete,
    attachment,
    onDelete,
    size = 'sm',
    canSelect,
    onSelect,
    useFirstImageSelector,
    index,
    ...props
}) => {
    const { url, file } = useGetFile(attachment, size, index)
    const [hovered, hoverHandlers] = useHover()

    const onDeleteAttachment = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            onDelete(url)
        },
        [url, onDelete]
    )

    const onSelectAttachment = useCallback(() => {
        onSelect(url)
    }, [onSelect, url])

    const image = file
    const downloadUrl = makeUrlDownloadable(url)
    let dataAttributes = {
        'data-src': url,
        'data-download': true,
        'data-download-url': downloadUrl,
    }

    if (isVideoOrAudio(attachment)) {
        delete dataAttributes['data-src']
        dataAttributes['data-video'] = JSON.stringify({
            source: [{ src: url, type: getVideoOrAudioType(attachment) }],
            attributes: { preload: false, controls: true, fluid: true },
        })
    } else if (isEmbed(attachment)) {
        dataAttributes['data-pdf'] = true
    }

    return (
        <Box
            mt={canDelete ? 4 : 1}
            key={attachment.url}
            {...dataAttributes}
            {...hoverHandlers}
            className="attachment-gallery-item"
        >
            <Container
                width={`thumbnail.${size}`}
                height={`thumbnail.${size}`}
                mr={canDelete ? 2 : 1}
                mb={canDelete ? 2 : 1}
                flexShrink={0}
                {...props}
            >
                {image}
                {canDelete && (
                    <CircleIconButton
                        icon="x"
                        onClick={onDeleteAttachment}
                        position="absolute"
                        right="-4px"
                        top="-4px"
                        width="16px"
                        height="16px"
                        fontSize="12px"
                        transition="opacity .3s ease-in-out"
                        opacity={hovered ? 1 : 0}
                    />
                )}
                {useFirstImageSelector && canSelect && (
                    <Button
                        size="sm"
                        position="absolute"
                        bottom={2}
                        left={2}
                        right={2}
                        py={1}
                        px={2}
                        onClick={onSelectAttachment}
                    >
                        Select
                    </Button>
                )}
            </Container>
        </Box>
    )
}

const makeUrlDownloadable = (url) => {
    if (url?.indexOf('filestackcontent.com') >= 0) {
        const urlObj = new URL(url)
        urlObj.searchParams.set('dl', true)
        return urlObj.toString()
    }
    return url
}

const useGetFile = (attachment, size, index) => {
    const { url, src, onError } = useThumbnail(attachment, size)

    const file = (
        <a href={url}>
            <ClickableImage
                tabIndex={0}
                data-index={index}
                border="1px solid"
                borderColor="grey.200"
                borderRadius="10%"
                size={`thumbnail.${size}`}
                p={1}
                src={src}
                alt=""
                onError={onError}
                role="button"
            />
        </a>
    )
    return { url, file }
}

const ClickableImage = styled(StyledImage)(`cursor: pointer`)

import { useMemo } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useDropdownAttributeDisplayState } from './useDropdownAttributeDisplayState'

type UseDropdownAttributeTagStateProps = {
    field: FieldDto
    value?: string | string[]
    maxItemLength?: number
    isLoading?: boolean
    maxOptions?: number
    showIcon?: boolean
}

export function useDropdownAttributeTagState({
    field,
    value,
    maxItemLength,
    isLoading,
    maxOptions,
    showIcon,
}: UseDropdownAttributeTagStateProps) {
    const {
        options,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelFocus,
        onOverflowLabelBlur,
    } = useDropdownAttributeDisplayState({
        field,
        value,
        maxItemLength,
        isLoading,
    })

    const label = formatLabel(options, maxOptions)

    const fieldIcon = getFieldIcon(field)
    const icon = useDeepEqualsMemoValue(
        showIcon && fieldIcon?.type === 'lucide' ? fieldIcon : undefined
    )

    return useMemo(
        () => ({
            options,
            isOverflowPopupOpen,
            onOverflowPopupOpenChange,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelFocus,
            onOverflowLabelBlur,
            label,
            icon,
        }),
        [
            isOverflowPopupOpen,
            label,
            onOverflowLabelBlur,
            onOverflowLabelFocus,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowPopupOpenChange,
            options,
            icon,
        ]
    )
}

function formatLabel(
    options: ReturnType<typeof useDropdownAttributeDisplayState>['options'],
    maxOptions?: number
) {
    const finalOptions = options.slice(0, maxOptions)
    const overflowingCount = options.length - finalOptions.length

    if (overflowingCount === 0) {
        return finalOptions.map((option) => option.label).join(', ')
    }

    return `${finalOptions.map((option) => option.label).join(', ')} +${overflowingCount}`
}

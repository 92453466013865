import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ButtonGroup } from '@chakra-ui/react'

import { getUrl } from 'app/UrlService'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import { useCreateBlankPage } from 'features/blank-pages/useCreateBlankPage'

import { Box, Collapse, Icon, Input, Text } from 'v2/ui'
import { DuplicateLayout as DuplicateLayoutIcon } from 'v2/ui/svgs'

import { Button } from 'ui/components/Button'

type CreateEmptyBlankPageModalContentProps = {
    onConfirm?: () => void
    isOpen?: boolean
    onClose?: () => void
}

export const CreateEmptyBlankPageModalContent: React.FC<CreateEmptyBlankPageModalContentProps> = ({
    onConfirm,
    isOpen,
    onClose,
}) => {
    const [pageName, setPageName] = useState('')
    const [validationError, setValidationError] = useState('')

    const {
        mutateAsync: createBlankPage,
        isLoading,
        reset,
    } = useCreateBlankPage({
        onError: () => setValidationError('There was an error creating this page.'),
    })

    const cleanup = useCallback(() => {
        setPageName('')
        setValidationError('')
        reset()
    }, [reset])

    const validateForm = () => {
        if (pageName.length < 1) {
            return 'You must enter a page name'
        }

        return ''
    }

    const history = useHistory()
    const { open: openEditLayout } = useEditMode()

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        const validationMessage = validateForm()
        if (validationMessage) {
            setValidationError(validationMessage)

            return
        }

        const { view } = await createBlankPage({ name: pageName })
        history.push(getUrl(view.url))
        openEditLayout()

        await onConfirm?.()
        cleanup()
    }

    const onCancel = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        onClose?.()
    }

    useEffect(() => {
        if (!isOpen) {
            cleanup()
        }
    }, [isOpen, cleanup])

    return (
        <Box as="form" onSubmit={onSubmit}>
            <Icon svg={<DuplicateLayoutIcon />} size="2.7rem" color="userInterface.neutral.700" />
            <Box textAlign="center" mt={3} mb={6}>
                <Text as="label" htmlFor="page-name-input" variant="modalTitle" fontWeight="bold">
                    Name your custom page
                </Text>
            </Box>

            <Input
                id="page-name-input"
                alignSelf="stretch"
                placeholder="e.g. Welcome, Tutorials, News…"
                value={pageName}
                maxLength={30}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValidationError('')
                    setPageName(e.target.value)
                }}
                autoFocus={true}
                variant="admin_white"
                width="100%"
            />
            <Collapse isOpen={validationError.length > 0} mt={2} width="100%">
                <Text variant="error" size="sm" width="100%" textAlign="left">
                    {validationError}
                </Text>
            </Collapse>
            <ButtonGroup
                mt={5}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
            >
                <Button
                    variant="secondary"
                    size="l"
                    type="reset"
                    disabled={isLoading}
                    flex
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button variant="primary" size="l" type="submit" isLoading={isLoading} flex>
                    Create page
                </Button>
            </ButtonGroup>
        </Box>
    )
}

import React, { useImperativeHandle } from 'react'

import CreateAppModal from 'features/AppBar/CreateAppModal'

import { useNavigationCreateAppModalState } from './hooks/useNavigationCreateAppModalState'

export type NavigationCreateAppModalHandle = {
    open: (spaceId?: string) => void
    close: () => void
}

type NavigationCreateAppModalProps = {}

export const NavigationCreateAppModal = React.forwardRef<
    NavigationCreateAppModalHandle,
    NavigationCreateAppModalProps
>((_props, ref) => {
    const { isOpen, open, close, selectedSpace, onBeforeCreateStack } =
        useNavigationCreateAppModalState()

    useImperativeHandle(ref, () => ({
        open,
        close,
    }))

    return (
        <CreateAppModal
            isOpen={isOpen}
            onClose={close}
            // @ts-expect-error: This component is not typed.
            onBeforeCreateStack={onBeforeCreateStack}
            // @ts-expect-error: This component is not typed.
            spaceName={selectedSpace?.name}
        />
    )
})

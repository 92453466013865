export function getSearchTerms(searchText: string) {
    //The parenthesis in the regex creates a captured group within the quotes
    const re = /[^\s"]+|"([^"]*)"/gi
    const result: string[] = []

    do {
        //Each call to exec returns the next regex match as an array
        var match = re.exec(searchText)
        if (match != null) {
            //Index 1 in the array is the captured group if it exists
            //Index 0 is the matched text, which we use if no captured group exists
            result.push(match[1] ? match[1] : match[0])
        }
    } while (match != null)
    return result
}

export function matchesSearchTerms(value: string, terms: string[]) {
    const lowerValue = value.toLowerCase()
    return terms.every((term) => lowerValue.includes(term.toLowerCase()))
}

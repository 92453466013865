import React from 'react'

import { DropdownHeadSearch, DropdownItem } from 'ui/components/Dropdown'

import { useQuickFilterDateState } from './hooks/useQuickFilterDateState'
import { QuickFilter, QuickFilterButton, QuickFilterContent } from './QuickFiltersItemBase'
import { QuickFilterComponent } from './types'

type QuickFilterDateProps = {}

export const QuickFilterDate: QuickFilterComponent<QuickFilterDateProps> = ({ field }) => {
    const { value, isActive, label, onFilterRemove, onSetFilterValue, filterOptions } =
        useQuickFilterDateState({
            field,
        })

    return (
        <QuickFilter>
            <QuickFilterButton isActive={isActive} onRemove={onFilterRemove}>
                {label}
            </QuickFilterButton>
            <QuickFilterContent head={<DropdownHeadSearch />}>
                {filterOptions.map((item) => (
                    <DropdownItem
                        key={item.value}
                        multiSelect
                        label={item.label}
                        closeOnSelect
                        checked={value === item.value}
                        onCheckedChange={(checked) => {
                            onSetFilterValue(item.value, checked)
                        }}
                    />
                ))}
            </QuickFilterContent>
        </QuickFilter>
    )
}

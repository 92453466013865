import React from 'react'

import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Box, CloseButton, Flex, Text } from 'v2/ui'

type SlidingPaneHeaderProps = { title: string; description: string }
export const SlidingPaneHeader: React.VFC<SlidingPaneHeaderProps> = ({ title, description }) => {
    const { close } = useSlidingPane()

    return (
        <Flex position="relative">
            <Flex p={4} pl={3} align="start" w="100%" justifyContent="space-between">
                <Box>
                    <Text fontSize="20px" color="userInterface.neutral.0" fontWeight="bold">
                        {title}
                    </Text>
                    <Text
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        fontSize="12px"
                        color="userInterface.neutral.0"
                        mt={1}
                        mb={1}
                    >
                        {description}
                    </Text>
                </Box>
            </Flex>
            <Box position="absolute" top={0} right={0} p={2}>
                <CloseButton
                    // @ts-ignore
                    onClick={() => close()}
                    color="userInterface.neutral.0"
                    data-testid="side-menu-close"
                />
            </Box>
        </Flex>
    )
}

import { filterSupportsField } from 'features/views/ListView/Filters/fields'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useInlineFiltersAvailableFields(
    inlineFilterType?: ListViewInlineFilters
): FieldDto[] {
    const { view, allFields } = useListViewContext()

    const fields = makeFields(view, allFields, inlineFilterType)

    return useDeepEqualsMemoValue(fields)
}

function makeFields(
    view: ViewDto,
    fields: FieldDto[],
    inlineFilterType?: ListViewInlineFilters
): FieldDto[] {
    if (!view.options.enableEndUserFilters) return []

    let fieldSids: string[]

    if (view.options.enableSpecificEndUserFilters) {
        fieldSids = view.options.specificEndUserFilters ?? []
    } else {
        if (inlineFilterType === 'advanced' || view.options.showAllFields) {
            fieldSids = fields.map((field) => field._sid)
        } else {
            fieldSids = view.options.columns?.map((column) => column.fieldId) ?? []
        }
    }
    const includedFieldSids = new Set(fieldSids)

    const fieldOrder = fieldSids.reduce(
        (acc, sid, index) => acc.set(sid, index),
        new Map<string, number>()
    )

    const availableFields = fields.reduce((acc, field) => {
        if (!includedFieldSids.has(field._sid)) return acc
        if (!filterSupportsField(field, inlineFilterType)) return acc

        acc.push(field)

        return acc
    }, [] as FieldDto[])

    return availableFields.sort((a, b) => {
        const aIndex = fieldOrder.get(a._sid) ?? 0
        const bIndex = fieldOrder.get(b._sid) ?? 0

        return aIndex - bIndex
    })
}

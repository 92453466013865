import { useMemo } from 'react'

import { NavigationScrollAreaIsOverflowingBottomStyle } from 'features/navigation/Navigation.css'
import { useOverflowY } from 'features/views/ListView/hooks/useOverflowY'

export function usePortalNavigationState() {
    const { targetRef: wrapperRef, scrollAreaRef } = useOverflowY({
        bottomClassName: NavigationScrollAreaIsOverflowingBottomStyle,
    })

    return useMemo(() => ({ wrapperRef, scrollAreaRef }), [scrollAreaRef, wrapperRef])
}

import React from 'react'

import { Button } from 'ui/components/Button'

import * as Parts from './Combobox.parts'
import { useComboboxContext } from './ComboboxContext'
import { ItemCollection, ItemProvider } from './useComboboxProviders'

type ComboboxListProps = {
    collections: ItemCollection[]
    queryTerms: string[]
    itemProps?: Omit<React.ComponentPropsWithoutRef<typeof Parts.Item>, 'item' | 'index'>
    groupTitleProps?: Omit<
        React.ComponentPropsWithoutRef<typeof Parts.GroupTitle>,
        'item' | 'index'
    >
    showMore?: (sourceId: string) => void
    isLoading?: boolean
}

// Renders the collections of items returned by useComboboxProviders
export function ComboboxList({
    collections,
    queryTerms,
    itemProps,
    groupTitleProps,
    showMore,
    isLoading,
}: ComboboxListProps) {
    const { inputValue: query } = useComboboxContext()
    let index = -1
    return (
        <>
            {collections.map((collection, collectionIndex) => {
                const { source, items: allItems, visibleItems, data } = collection
                // should limit visible items if the source specifies a default limit,
                // the user hasn't expanded, and there are more collections after this one with items.
                const shouldLimitVisbleItems =
                    isLoading ||
                    collections.slice(collectionIndex + 1).some(({ items }) => items.length)

                const collectionItems = shouldLimitVisbleItems ? visibleItems : allItems

                return (
                    <>
                        {source.renderGroup ? (
                            source.renderGroup({
                                source,
                                items: collectionItems,
                            })
                        ) : source.title && collectionItems?.length ? (
                            <Parts.GroupTitle {...groupTitleProps}>{source.title}</Parts.GroupTitle>
                        ) : null}
                        {collectionItems.map((item, indexInGroup) => {
                            index++
                            return (
                                <Parts.Item
                                    {...itemProps}
                                    {...source.getItemProps?.({
                                        item,
                                        isFirst: indexInGroup === 0,
                                        isLast: indexInGroup === collectionItems.length - 1,
                                    })}
                                    key={index}
                                    item={item}
                                    index={index}
                                >
                                    <RenderItem
                                        source={source}
                                        item={item}
                                        query={query}
                                        queryTerms={queryTerms}
                                        data={data}
                                    />
                                </Parts.Item>
                            )
                        })}
                        {collectionItems.length < allItems.length && (
                            <Button ml="l" variant="link" onClick={() => showMore?.(source.id)}>
                                show {allItems.length - collectionItems.length} more...{' '}
                            </Button>
                        )}
                    </>
                )
            })}
        </>
    )
}

const RenderItem = React.memo(function RenderItem<TItem, TData>({
    source,
    item,
    query,
    queryTerms,
    data,
}: {
    source: ItemProvider<TItem, TData>
    item: TItem
    query: string
    queryTerms: string[]
    data: TData
}) {
    return <>{source.renderItem?.({ item, query, queryTerms, data })}</>
})

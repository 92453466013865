import React from 'react'

import { Button } from 'ui/components/Button'
import { theme } from 'ui/styling/Theme.css'

type TasksTitleProps = React.ComponentPropsWithoutRef<typeof Button> & {
    children: React.ReactNode
}

export const TasksTitle: React.FC<TasksTitleProps> = ({
    children,
    style: providedStyle,
    ...props
}) => {
    return (
        <Button
            variant="ghost"
            size="xs"
            style={{
                ...providedStyle,
                paddingLeft: 0,
                paddingRight: 0,
                fontWeight: theme.fontWeights.bodyBold as any,
            }}
            {...props}
        >
            {children}
        </Button>
    )
}

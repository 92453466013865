import React, { useEffect, useRef, useState } from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { HelperText } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAiAppBuilder } from './useAiAppBuilder'

export function RequirementsStep({ onMoveNext }: { onMoveNext: () => Promise<void> }) {
    const {
        requirements,
        setRequirements,
        generationFailed,
        isGenerating,
        generateBundle,
        cancelGeneration,
    } = useAiAppBuilder()

    const buttonSize = useResponsiveValue({ mobile: 'm', tabletLarge: 'l' }) as 'm' | 'l'
    const requirementsRef = useRef(requirements)
    requirementsRef.current = requirements
    const [newRequirement, setNewRequirement] = useState('')
    const [showingAddRequirement, setShowingAddRequirement] = useState(false)
    const [importFailed, setImportFailed] = useState(false)

    const handleMoveNext = async () => {
        try {
            setImportFailed(false)
            await onMoveNext()
        } catch (e) {
            console.error('Failed to move to next step', e)
            setImportFailed(true)
        }
    }

    useEffect(() => {
        // Cancel generation when the component unmountss
        return () => {
            cancelGeneration()
        }
    }, [cancelGeneration])

    useEffect(() => {
        generateBundle()
    }, [generateBundle])

    function removeRequirement(requirement: string) {
        setRequirements(requirements.filter((r) => r !== requirement))
        setTimeout(generateBundle, 100)
    }

    function saveNewItem() {
        setRequirements((existing) => [...existing, newRequirement])
        setNewRequirement('')
        setShowingAddRequirement(false)
        setTimeout(generateBundle, 100)
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            saveNewItem()
        } else if (e.key === 'Escape') {
            setShowingAddRequirement(false)
            setNewRequirement('')
            e.stopPropagation()
            e.preventDefault()
        }
    }

    return (
        <Box flex column gap="5xl">
            <Body>
                List each entity that is important to the process or workflow you want to manage
                with your app.
            </Body>
            <Box flex column overflow="auto" alignItems="flex-start" gap="s">
                {requirements.map((requirement, index) => (
                    <Tag
                        key={index}
                        showRemoveButton
                        onRemove={() => removeRequirement(requirement)}
                        color="Blue"
                    >
                        {requirement}
                    </Tag>
                ))}
                {!showingAddRequirement && (
                    <Button
                        variant="dotted"
                        border
                        size="s"
                        onClick={() => setShowingAddRequirement(true)}
                        mt="m"
                    >
                        Add a new entity
                    </Button>
                )}
                {showingAddRequirement && (
                    <Input
                        placeholder="new requirement"
                        value={newRequirement}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setNewRequirement(e.target.value)
                        }
                        onBlur={() => saveNewItem()}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        mt="m"
                    />
                )}
            </Box>

            <Box>
                <Button
                    variant="primary"
                    width="full"
                    size={buttonSize}
                    onClick={handleMoveNext}
                    disabled={isGenerating || generationFailed}
                >
                    {isGenerating ? 'Generating' : 'Take me to my app'}
                </Button>
                {importFailed && (
                    <HelperText mt="m" isError>
                        Failed to create app. Please try again.
                    </HelperText>
                )}
            </Box>
        </Box>
    )
}

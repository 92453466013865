import React from 'react'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

type EmbeddedServerErrorEmptyStateProps = {}

export const EmbeddedServerErrorEmptyState: React.FC<EmbeddedServerErrorEmptyStateProps> =
    React.memo(function EmbeddedServerErrorEmptyState() {
        return (
            <Box
                flex
                center
                flexDirection="column"
                textAlign="center"
                justifyContent="center"
                py="2xl"
            >
                <Body size="m" color="textError">
                    Something&apos;s wrong. We were unable to connect to the service
                </Body>
            </Box>
        )
    })

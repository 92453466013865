import React, { MouseEvent, useMemo } from 'react'
import ReactFocusLock from 'react-focus-lock'

import type { Placement } from '@popperjs/core'

import Popper from 'v2/ui/components/Popper'
import { autoSizeModifier } from 'v2/ui/components/popperModifiers/autoSizeModifier'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'

import { RelatedTaskList, RelatedTaskListProps } from './RelatedTaskList'

type RelatedTasksPopoverProps = RelatedTaskListProps & {
    open?: boolean
    placement?: Placement
    targetElement?: HTMLElement
    container?: HTMLElement
    onClose: () => void
}
export const RelatedTasksPopover: React.FC<RelatedTasksPopoverProps> = ({
    open,
    placement,
    targetElement,
    container,
    onClose,
    ...props
}) => {
    // Find the first descendant of the document.body which contains
    // either the container element or the target element (if container
    // isn't specified). We pass this through to the Popper component
    // so it knows which branch of the DOM to consider for "outside actions."
    const rootNode = useMemo(() => {
        let result: Node | undefined = undefined
        for (var value of Array.from(document.body.childNodes.values())) {
            if (
                (container && value.contains(container)) ||
                (!container && targetElement && value.contains(targetElement))
            ) {
                result = value
            }
        }

        return result
    }, [container, targetElement])

    if (!open || !targetElement) return null
    // keeps clicks in the popover from bubbling up and clicking on other thigns
    // in the hierarchy like record cards/rows, etc.
    const handleClick = (e: MouseEvent) => {
        e.stopPropagation()
    }

    return (
        <Popper
            placement={placement}
            referenceElement={targetElement}
            onClose={onClose}
            // Only close on outer actions on descendents of the main app node,
            // not elements in portaled components such as confirm modals, dropdowns, etc.
            limitOuterActionsToDescendentsOf={rootNode}
            modifiers={[
                autoSizeModifier,
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: true,
                        padding: 10,
                    },
                },
            ]}
            zIndex={1251}
        >
            <ReactFocusLock>
                <Container
                    border
                    popover
                    flex
                    column
                    width="600px"
                    position="relative"
                    height="fit-content"
                    minHeight="100px"
                    maxHeight="66vh"
                    onClick={handleClick}
                    overflowY="auto"
                    py="l"
                >
                    <Box as="h3" mb="m" px="l" fontSize="bodyL" fontWeight="bodyBold">
                        Tasks
                    </Box>
                    <RelatedTaskList {...props} />
                </Container>
            </ReactFocusLock>
        </Popper>
    )
}

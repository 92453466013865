import { useMemo } from 'react'

import { useCardViewContext } from 'features/views/ListView/CardView/useCardViewContext'

export function useCardViewLoaderState() {
    const { pageSize, object } = useCardViewContext()

    return useMemo(
        () => ({
            placeholderCards: makePlaceholderCards(object._sid, pageSize),
        }),
        [pageSize, object._sid]
    )
}

function makePlaceholderCards(objectSid: string, length: number) {
    return Array.from({ length }).map((_, index) => ({
        _sid: index.toString(),
        _object_id: objectSid,
        _primary: `Record ${index + 1}`,
    }))
}

import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'
import { AddNewHeaderStyle } from 'features/datagrid/headers/AddNewHeader.css'

import { useIsGridElementInViewport } from './useIsGridElementInViewport'

export function useIsAddNewFieldPanelVisible(wrapperElement: HTMLElement | null) {
    const { isTrashMode } = useDataGridContext()

    const isNewFieldButtonInViewport = useIsGridElementInViewport(
        () => {
            return (wrapperElement?.querySelector(`.${AddNewHeaderStyle}`) as HTMLElement) ?? null
        },
        () => {
            return (wrapperElement?.querySelector('.ag-header-viewport') as HTMLElement) ?? null
        }
    )

    return !isNewFieldButtonInViewport && !isTrashMode
}

import { useMemo } from 'react'

import { useSortContext } from 'features/views/ListView/Sort/useSortContext'

export function useListHeaderSort() {
    const { sortBy, setSortBy, availableFields, sortType } = useSortContext()

    return useMemo(
        () => ({
            sortBy,
            setSortBy,
            availableFields,
            sortType,
        }),
        [sortBy, setSortBy, availableFields, sortType]
    )
}

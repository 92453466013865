import React from 'react'

import { Box } from 'ui/components/Box'

export type BoardViewScrollAreaProps = {}

export const BoardViewScrollArea: React.FC<BoardViewScrollAreaProps> = ({ children }) => {
    return (
        <Box
            width="full"
            height="full"
            position="relative"
            alignSelf="stretch"
            grow
            overflowX="auto"
            style={{
                /* Expand element to show nested focus shadows. */
                padding: '5px',
                margin: '-5px',
                marginBottom: 0,
                paddingBottom: 0,
                WebkitOverflowScrolling: 'touch',
            }}
        >
            {children}
        </Box>
    )
}

import React from 'react'

import { Frame } from 'features/core/Frame'

// we show this page just to show the app chrome etc whilst waiting for
// user / role to load in, as it's necessary to do admin route auth
//
// for now just a thin wrapper over Frame, the content area of the app will just be empty
const PrivateRouteAuthLoadingPage = () => {
    return <Frame header={false} />
}

export default PrivateRouteAuthLoadingPage

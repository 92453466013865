import { useMemo } from 'react'

import { useFiltersContext } from 'features/views/ListView/Filters/useFiltersContext'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

import { usePersistFilters } from './usePersistFilters'

export function useListHeaderFilters() {
    const {
        inlineFilters,
        addFilter,
        removeFilter,
        updateFilter,
        setFilters,
        clearFilters,
        availableInlineFilterFields,
        inlineFilterType,
    } = useFiltersContext()
    const { view } = useListViewContext()

    usePersistFilters({
        fields: availableInlineFilterFields,
        filters: inlineFilters,
        setFilters,
        filterType: inlineFilterType,
        view,
    })

    return useMemo(
        () => ({
            inlineFilters,
            addInlineFilter: addFilter,
            removeInlineFilter: removeFilter,
            updateInlineFilter: updateFilter,
            clearInlineFilters: clearFilters,
            setFilters,
            availableInlineFilterFields,
            inlineFilterType,
        }),
        [
            addFilter,
            availableInlineFilterFields,
            clearFilters,
            setFilters,
            inlineFilters,
            removeFilter,
            updateFilter,
            inlineFilterType,
        ]
    )
}

import React from 'react'

import { LayoutEditorWidgetPickerDisplayStyles } from 'features/views/LayoutEditor/LayoutEditor.css'
import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

type LayoutEditorWidgetPickerDisplayRef = HTMLDivElement

type LayoutEditorWidgetPickerDisplayProps = React.ComponentPropsWithoutRef<typeof Box> & {
    widgetSchema: WidgetSchema
    isDraggingOverlay?: boolean
}

export const LayoutEditorWidgetPickerDisplay = React.forwardRef<
    LayoutEditorWidgetPickerDisplayRef,
    LayoutEditorWidgetPickerDisplayProps
>(function LayoutEditorWidgetPickerDisplay(
    { widgetSchema, onClick, isDraggingOverlay = false, style, ...props },
    ref
) {
    return (
        <Box
            ref={ref}
            p="l"
            flex
            center
            gap="xs"
            column
            rounded="s"
            borderWidth="base"
            borderStyle="base"
            role={!!onClick ? 'button' : undefined}
            onClick={onClick}
            style={{
                userSelect: 'none',
                transition: theme.transition.colors,
                ...style,
            }}
            className={LayoutEditorWidgetPickerDisplayStyles.styleFunction({ isDraggingOverlay })}
            {...props}
        >
            <Icon name={widgetSchema.icon} size="2xl" />
            <Body size="m">{widgetSchema.label}</Body>
        </Box>
    )
})

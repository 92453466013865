import React, { useCallback, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { useNavTree } from 'features/admin/settings/navigation/NavigationUtils'
import { PathContext } from 'features/utils/PathContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { AppNavigationContext } from './AppNavigationContext'
import { NavigationApp, NavigationAppMenuItem } from './types'
import { getActiveMenuItem, mapNavTreeToAppNavigationMenuItems } from './utils'

type AppNavigationContextProviderProps = {
    app: NavigationApp
}

export const AppNavigationContextProvider: React.FC<AppNavigationContextProviderProps> = ({
    children,
    app,
}) => {
    const value = useContextValue(app)

    return <AppNavigationContext.Provider value={value}>{children}</AppNavigationContext.Provider>
}

function useContextValue(app: NavigationApp) {
    const navTree = useNavTree({ stackId: app.stack._sid })
    const menuItems = useDeepEqualsMemoValue(
        mapNavTreeToAppNavigationMenuItems(navTree, undefined, app.stack)
    )

    const { pageUrl } = useContext(PathContext)
    const activeMenuItem = getActiveMenuItem(menuItems, pageUrl)
    const activeMenuItemRef = useRef(activeMenuItem)
    activeMenuItemRef.current = activeMenuItem

    const history = useHistory()
    const executeActionInItem = useCallback(
        async (item: NavigationAppMenuItem, action: () => void) => {
            return new Promise<void>((resolve) => {
                const activeMenuItem = activeMenuItemRef.current
                if (item.id !== activeMenuItem?.id) {
                    history.push(item.url)
                }

                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        action()
                        resolve()
                    })
                })
            })
        },
        [history]
    )

    return useDeepEqualsMemoValue({
        menuItems,
        executeActionInItem,
        app,
    })
}

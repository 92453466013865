import React from 'react'

import { mapThemeNameToRawVariables, ThemeContextValue } from './themeTypes'

const defaultContext: ThemeContextValue = {
    theme: 'default',
    themeClassName: undefined,
    themeRawVariables: mapThemeNameToRawVariables('default'),
    breakpoint: 'mobile',
    colorScheme: 'Indigo',
}
export const ThemeContext = React.createContext<ThemeContextValue>(defaultContext)

export function useTheme(): ThemeContextValue {
    return React.useContext(ThemeContext)
}

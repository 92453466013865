import { useMemo } from 'react'
import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query'

import { isEqual, orderBy } from 'lodash'

import {
    queryClient,
    useCanRunWorkspaceScopedQueries,
    useQueryKeyBuilder,
} from 'data/hooks/_helpers'
import { useCreateTask, useDeleteTask, useUpdateTask } from 'data/hooks/tasks/tasks'
import useLDFlags from 'data/hooks/useLDFlags'
import { useRealtimeUpdates } from 'data/realtime/realtimeUpdates'
import { buildUrl, fetchAndReturn } from 'data/utils/utils'

import {
    useCreateActivity,
    useDeleteActivity,
    useRetryFailedPost,
    useUpdateActivity,
} from './activities'
import { ActivitiesData } from './types'
const LIST_NAME = 'notifications'

// What we don't want is to fetch display "99+ unread", have them archive one,
// the count goes down to 99, but as soon as the query is refreshed, it bounces back up to 99+
// so we fetch extra here so we have time to refresh the query after they archive some.
const INBOX_PAGE_SIZE = 120

export type useNotificationsProps = { isArchived?: boolean }

export type NotificationsResponse = ActivitiesData & {
    notifications: NotificationDto[]
}
export function useNotifications(
    filters: useNotificationsProps = { isArchived: false },
    options: UseQueryOptions<NotificationsResponse> = {}
) {
    const { flags } = useLDFlags()

    const canRunWorkspaceQueries = useCanRunWorkspaceScopedQueries()
    const enabled = canRunWorkspaceQueries && flags.notifications

    const queryKey = useQueryKeyBuilder([LIST_NAME, filters?.isArchived?.toString() ?? ''], {
        includeAuthKeys: true,
    })
    const queryResult = useQuery<NotificationsResponse>(
        queryKey,
        async () => {
            const url = buildUrl(
                `notifications/`,
                { pageSize: !filters.isArchived ? INBOX_PAGE_SIZE : undefined, ...filters },
                true
            )

            const results = (await fetchAndReturn(url, undefined, undefined, undefined, {
                bypassPreviewAs: true,
            })) as NotificationsResponse

            const existingData = {
                ...(queryClient.getQueryData<NotificationsResponse>(queryKey) ?? {}),
            }
            // any pending new items get preserved.
            const newActivities =
                existingData?.activities?.filter((activity) => !!activity.pendingCreateId) ?? []
            const pendingArchiveChanges =
                existingData?.notifications?.filter((n) => n.pending_archive_change) ?? []

            // make sure we preserve any items which are pending archive change so
            // we don't get them flashing back into the list
            results.notifications = [
                ...results.notifications.filter(
                    (n) => !pendingArchiveChanges.find((p) => p.auto_id === n.auto_id)
                ),
                ...pendingArchiveChanges,
            ]
            results.activities = [...newActivities, ...(results.activities ?? [])]
            return results
        },
        {
            refetchOnMount: 'always',
            refetchOnWindowFocus: 'always',
            ...options,
            enabled: enabled && options.enabled !== false,
        }
    )

    useRealtimeUpdates({
        channel: 'notifications',
        handler: queryResult.refetch,
        disabled: !enabled,
    })
    useRealtimeUpdates({ channel: 'activities', handler: queryResult.refetch, disabled: !enabled })
    useRealtimeUpdates({ channel: 'tasks', handler: queryResult.refetch, disabled: !enabled })
    const { mutateAsync: updateTask } = useUpdateTask(undefined, queryKey)
    const { mutateAsync: createTask } = useCreateTask(undefined, queryKey)
    const { mutateAsync: deleteTask } = useDeleteTask(undefined, queryKey)
    const { mutateAsync: createActivity } = useCreateActivity(queryKey)
    const { mutateAsync: deleteActivity } = useDeleteActivity(queryKey)
    const { mutateAsync: updateActivity } = useUpdateActivity(queryKey)
    const retryFailedActivity = useRetryFailedPost(queryKey)
    const { mutate: markAsRead } = useMarkAsRead(queryKey)
    const { mutate: markAsArchived } = useMarkAsArchived(queryKey)

    const sorted = useMemo(() => {
        const filtered =
            queryResult.data?.notifications?.filter((n) => !n.pending_archive_change) ?? []
        return orderBy(filtered, ['created_at'], ['desc'])
    }, [queryResult.data?.notifications])

    return {
        ...queryResult,
        data: queryResult.data ? { ...queryResult.data, notifications: sorted } : undefined,
        createActivity,
        retryFailedActivity,
        markAsRead,
        deleteActivity,
        updateActivity,
        markAsArchived,
        updateTask,
        createTask,
        deleteTask,
    }
}

export function useUnreadNotificationCount() {
    const query = useNotifications({ isArchived: false })

    const unreadCount = query.data?.notifications?.filter((n) => !n.is_read)?.length ?? 0
    return { ...query, data: unreadCount }
}

type SetIsReadArgs = { notificationIds?: number[]; isRead: boolean }
export function useMarkAsRead(queryKey: QueryKey) {
    const queryKeyInbox = useQueryKeyBuilder([LIST_NAME, false.toString()], {
        includeAuthKeys: true,
    })
    return useMutation(
        async ({ notificationIds, isRead }: SetIsReadArgs) => {
            const results = (await fetchAndReturn(
                `notifications/`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        operation: 'set_is_read',
                        targets: notificationIds,
                        value: isRead,
                    }),
                    headers: { 'Content-type': 'application/json' },
                },
                undefined,
                undefined,
                {
                    bypassPreviewAs: true,
                }
            )) as NotificationDto

            return results
        },
        {
            onMutate: ({ notificationIds, isRead }: SetIsReadArgs) => {
                const queryData = {
                    ...(queryClient.getQueryData<NotificationsResponse>(queryKey) ?? {}),
                }

                queryData.notifications = queryData?.notifications?.map((notification) =>
                    notificationIds?.includes(notification.auto_id)
                        ? {
                              ...notification,
                              is_read: isRead,
                              is_archived: !isRead ? false : notification.is_archived,
                              pending_archive_change:
                                  !isRead && notification.is_archived ? true : undefined,
                          }
                        : notification
                )

                // any in flight query should be cancelled, as we're going to
                // invalidate the cache as soon as this operation succeeds.
                queryClient.cancelQueries(queryKey)

                queryClient.setQueryData(queryKey, queryData)

                // TODO: handle failures
            },
            onSettled: (_data, _error, { notificationIds, isRead }) => {
                const queryData = {
                    ...(queryClient.getQueryData<NotificationsResponse>(queryKey) ?? {}),
                }
                // Remove out any items whose archive state has changed
                queryData.notifications = queryData?.notifications?.filter(
                    (notification) =>
                        !(
                            notificationIds?.includes(notification.auto_id) &&
                            notification.pending_archive_change
                        )
                )

                queryClient.setQueryData(queryKey, queryData)

                // if we're marking items as unread and we're not in the inbox query, then
                // invalidate the inbox query so it refreshes when the user switches back to inbox, as
                // the items just marked as unread have also now
                if (isRead === false && !isEqual(queryKey, queryKeyInbox)) {
                    queryClient.invalidateQueries(queryKeyInbox)
                }
            },
        }
    )
}

type SetIsArchivedArgs = { notificationIds?: number[]; isArchived: boolean }
export function useMarkAsArchived(queryKey: QueryKey) {
    const queryKeyInbox = useQueryKeyBuilder([LIST_NAME, false.toString()], {
        includeAuthKeys: true,
    })
    const queryKeyArchive = useQueryKeyBuilder([LIST_NAME, true.toString()], {
        includeAuthKeys: true,
    })

    return useMutation(
        async ({ notificationIds, isArchived }: SetIsArchivedArgs) => {
            const results = (await fetchAndReturn(
                `notifications/`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        operation: 'set_is_archived',
                        targets: notificationIds,
                        value: isArchived,
                    }),
                    headers: { 'Content-type': 'application/json' },
                },
                undefined,
                undefined,
                {
                    bypassPreviewAs: true,
                }
            )) as NotificationDto

            return results
        },
        {
            onMutate: ({ notificationIds, isArchived }: SetIsArchivedArgs) => {
                const queryData = {
                    ...(queryClient.getQueryData<NotificationsResponse>(queryKey) ?? {}),
                }

                queryData.notifications = queryData?.notifications?.map((notification) =>
                    notificationIds?.includes(notification.auto_id)
                        ? {
                              ...notification,
                              is_archived: isArchived,
                              pending_archive_change: true,
                          }
                        : notification
                )

                // any in flight query should be cancelled, as we're going to
                // invalidate the cache as soon as this operation succeeds.
                queryClient.cancelQueries(queryKey)

                queryClient.setQueryData(queryKey, queryData)

                // TODO: handle failures
            },
            onSuccess: (data, { notificationIds, isArchived }) => {
                const queryData = {
                    ...(queryClient.getQueryData<NotificationsResponse>(queryKey) ?? {}),
                }
                // filter out any items whose archive state has changed
                queryData.notifications = queryData?.notifications?.filter(
                    (notification) =>
                        !(
                            notificationIds?.includes(notification.auto_id) &&
                            notification.pending_archive_change
                        )
                )

                queryClient.setQueryData(queryKey, queryData)
                // reset the query of the oppose list (inbox or archive)
                queryClient.invalidateQueries(queryKey)
                const targetQueryKey = isArchived ? queryKeyArchive : queryKeyInbox
                queryClient.invalidateQueries(targetQueryKey)
            },
        }
    )
}

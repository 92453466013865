import { getFieldIcon } from 'features/admin/fields/icons/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseTextAttributeDisplayStateProps = {
    field: FieldDto
    value?: string
    maxLength?: number
    isLoading?: boolean
    showIcon?: boolean
}

export function useTextAttributeDisplayState({
    field,
    value = '',
    maxLength,
    isLoading,
    showIcon,
}: UseTextAttributeDisplayStateProps) {
    const effectiveValue = isLoading ? PLACEHOLDER_VALUE : value

    const isOverflowing = maxLength && value.length > maxLength

    const fieldIcon = getFieldIcon(field)
    const icon = useDeepEqualsMemoValue(
        showIcon && fieldIcon?.type === 'lucide' ? fieldIcon : undefined
    )

    return { isOverflowing, value: effectiveValue, icon }
}

const PLACEHOLDER_VALUE: string = 'Loading record...'

import { useMemo } from 'react'

import { operationRequiresUserInput } from 'features/views/ListView/ListHeader/Filters/Advanced/operations'

type UseFilterValueStateOptions = {
    filter: Filter
}

export function useFilterValueState(options: UseFilterValueStateOptions) {
    const { filter } = options

    const field = filter.field as FieldDto
    const operation = filter.options.option
    const requiresValue = operation ? operationRequiresUserInput(field, operation) : false

    return useMemo(
        () => ({
            requiresValue,
        }),
        [requiresValue]
    )
}

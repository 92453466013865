import React, { forwardRef } from 'react'

import * as Parts from './Field.parts'

type OptionalMarkRef = HTMLDivElement

type OptionalMarkProps = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.OptionalMark>,
    'isDisabled'
> & {
    disabled?: boolean
}

export const OptionalMark = forwardRef<OptionalMarkRef, OptionalMarkProps>(
    ({ size = 'm', disabled, ...props }, ref) => {
        return (
            <Parts.OptionalMark ref={ref} isDisabled={disabled} size={size} {...props}>
                (optional)
            </Parts.OptionalMark>
        )
    }
)

// @ts-strict-ignore
import React, { FC, useCallback, useMemo } from 'react'

import { useTheme } from '@chakra-ui/react'
import { Bar, BarChart, CartesianGrid, Cell, Tooltip, XAxis, YAxis } from 'recharts'
import { DEFAULT_MAX_ITEMS } from 'v2/blocks/blockTypes/view/aggregationBlocks/ChartsBlock/constants'

import { useAppContext } from 'app/useAppContext'
import { renderNumberValueAsText } from 'features/charts/NumberValue'
import { CategoryLabel } from 'features/charts/recharts/common/CategoryLabel'
import useTickFormatter from 'features/charts/recharts/hooks/useTickFormatter'
import { getTooltipLabel } from 'features/charts/recharts/utils/getTooltipLabel'

import { CategoryColors } from './types'
import { prepareData } from './utils'

const getStackedBarRadius = (
    category: string,
    values: { [value: string]: number } | undefined,
    categories: CategoryColors[],
    showVertical: boolean
) => {
    if (!values) return 0
    const orderedCategories = categories.reduce((acc, { category }) => {
        Object.entries(values).forEach(([index, value]) => {
            if (category === index && value) {
                acc.push(index)
            }
        })
        return acc
    }, [] as string[])

    // Check if the current category is the last catory and return rounded top corners if so
    if (orderedCategories.indexOf(category) === orderedCategories.length - 1) {
        return showVertical ? [0, 4, 4, 0] : [4, 4, 0, 0]
    }

    return 0
}

export const RechartsBar: FC<RechartsBarParam> = ({
    metrics,
    display,
    config,
    width,
    height,
    chartObject,
    options: {
        yAxis,
        legend,
        maxItems = DEFAULT_MAX_ITEMS,
        layout = 'vertical',
        showStacked = false,
        showLabels,
        showValues,
    },
}) => {
    const { selectedStack } = useAppContext()
    const theme = useTheme()

    // Recharts vertical is our horizontal
    const showVertical = layout === 'horizontal'

    const {
        renderKey,
        xTickWidth: barWidth,
        xTickFormatter,
        xTicksRotated,
        onRotateXTicks,
        yTickWidth,
        yTickFormatter,
    } = useTickFormatter({
        metrics,
        display,
        config,
        chartWidth: width,
        numDataPoints: metrics.data.length,
        maxItems,
    })

    const { chartData, categoryColors } = useMemo(
        () =>
            prepareData(
                metrics.data,
                {
                    metrics,
                    config,
                    fields: chartObject?.fields,
                },
                theme,
                selectedStack?.options.theme.brandColor
            ),
        [config, chartObject, metrics, selectedStack?.options.theme.brandColor, theme]
    )

    const {
        CategoryAxis,
        categoryTickFormatter,
        categoryTickWidth,
        ValueAxis,
        valueTickFormatter,
        valueTickWidth,
    } = useMemo(
        () =>
            showVertical
                ? {
                      CategoryAxis: YAxis,
                      categoryTickFormatter: yTickFormatter,
                      categoryTickWidth: yTickWidth,
                      ValueAxis: XAxis,
                      valueTickFormatter: xTickFormatter,
                      valueTickWidth: undefined,
                  }
                : {
                      CategoryAxis: XAxis,
                      categoryTickFormatter: xTickFormatter,
                      categoryTickWidth: undefined,
                      ValueAxis: YAxis,
                      valueTickFormatter: yTickFormatter,
                      valueTickWidth: yTickWidth,
                  },
        [showVertical, xTickFormatter, yTickFormatter, yTickWidth]
    )

    const formatter = useCallback(
        (value: string, name: string, payload: any): [string, string] => {
            const label = getTooltipLabel(name, payload, config?.group?.bucketBy)
            const formattedValue = Number.isNaN(value)
                ? value
                : renderNumberValueAsText({
                      value: Number(value),
                      display,
                      config,
                      metrics,
                  })
            return [formattedValue, label]
        },
        [config, display, metrics]
    )

    const domain = useCallback(([dataMin, dataMax]: [number, number]): [number, number] => {
        const min = Math.min(dataMin, 0)
        return [min, dataMax]
    }, [])

    return (
        <BarChart
            key={renderKey}
            layout={showVertical ? 'vertical' : 'horizontal'}
            width={width}
            height={height}
            data={chartData}
            barSize={barWidth}
        >
            <CartesianGrid
                horizontal={!showVertical}
                vertical={showVertical}
                strokeDasharray="2 2"
            />
            {!!config.group?.group_field_sid && metrics.categories ? (
                categoryColors.map(({ category, color }) => (
                    <Bar
                        key={category}
                        isAnimationActive={renderKey < 2}
                        stackId={showStacked ? 'stack1' : undefined}
                        name={category}
                        dataKey={`values.${category}`}
                        radius={showStacked ? 4 : [4, 4, 0, 0]}
                        fill={color}
                    >
                        {showStacked &&
                            chartData.map(({ values }, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    // @ts-expect-error - https://github.com/recharts/recharts/issues/3325#issuecomment-1416203572
                                    radius={getStackedBarRadius(
                                        category,
                                        values,
                                        categoryColors,
                                        showVertical
                                    )}
                                />
                            ))}
                    </Bar>
                ))
            ) : (
                <Bar dataKey="value" radius={4} isAnimationActive={renderKey < 2}>
                    {chartData.map(({ color }) => (
                        <Cell key={`cell-${color}`} fill={color} />
                    ))}
                </Bar>
            )}
            {/*@ts-expect-error*/}
            <ValueAxis
                type="number"
                width={valueTickWidth}
                axisLine={false}
                tickLine={false}
                tick={{ fill: theme.colors.userInterface.neutral[700] }}
                tickFormatter={valueTickFormatter}
                hide={!yAxis || !showValues}
                allowDecimals={config.aggr.type !== 'count'}
                tickCount={showVertical ? 20 : 10}
                domain={domain}
                interval="preserveStartEnd"
            />
            {/*@ts-expect-error*/}
            <CategoryAxis
                dataKey="name"
                type="category"
                width={categoryTickWidth}
                axisLine={false}
                tickLine={false}
                tickMargin={0}
                minTickGap={0}
                tick={
                    showVertical
                        ? { fill: theme.colors.userInterface.neutral[1000] }
                        : (props) => (
                              <CategoryLabel
                                  {...props}
                                  barWidth={barWidth}
                                  chartHeight={height}
                                  rotateLabel={xTicksRotated}
                                  onLabelRotated={onRotateXTicks}
                              />
                          )
                }
                tickFormatter={categoryTickFormatter}
                hide={legend !== 'xAxis' || !showLabels}
            />
            <Tooltip isAnimationActive={false} formatter={formatter} />
        </BarChart>
    )
}

import React, { ReactNode, useCallback, useMemo, useState } from 'react'

import { ModalType, UserModalContext } from './UserModalContext'

export const UserModalContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [mode, setMode] = useState<ModalType>('users')
    const [editData, setEditData] = useState<any>(null)

    const handleSetMode = useCallback((mode: ModalType, grant: AccessGrant | null = null) => {
        setMode(mode)
        setEditData(grant)
    }, [])

    const contextValue = useMemo(
        () => ({
            mode,
            editData,
            setMode: handleSetMode,
        }),
        [mode, editData, handleSetMode]
    )

    return <UserModalContext.Provider value={contextValue}>{children}</UserModalContext.Provider>
}

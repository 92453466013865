import { cloneDeep } from 'lodash'

import {
    AppTemplate,
    BundleTemplate,
    FieldTemplate,
    ObjectTemplate,
    RecordFieldTemplate,
    RecordTemplate,
    TranslatedFieldTypes,
    TranslatedListLayouts,
} from './bundleTypes'
import { AppSchemaType, FieldSchemaType, ObjectSchemaType } from './zodSchema'

export type Bundle = {
    apps: AppSchemaType[]
}

const BUNDLE_TEMPLATE: BundleTemplate = {
    metadata: { fileVersion: 1 },
    apps: [],
}

const APP_TEMPLATE: AppTemplate = {
    properties: {
        name: '',
        options: {
            group: 'General',
            theme: {
                icon: 'fa-book',
                navColor: 'light',
                navIcons: true,
                brandColor: '#9333EA',
            },
            charts: false,
            ui_version: 2,
            pin_to_app_bar: true,
            new_create_form: true,
            showBreadcrumbs: true,
            multi_data_source: true,
            enable_comment_feed: true,
            enable_many_to_many: true,
            secondary_navigation: true,
            show_app_users_splash: true,
            stacker_relationships: true,
            enable_new_app_settings: true,
            roles__default_user_role: 'role.custom.user',
        },
    },
    roles: [
        {
            exportId: 'role.system.internal_admin',
            label: 'Admin',
            options: {
                type: 'internal',
                rights: ['admin', 'all_data_access'],
                all_pages: true,
                system_role: true,
            },
        },
        { exportId: 'role.custom.user', label: 'User', options: {} },
    ],
    objects: [],
}

const OBJECT_TEMPLATE: ObjectTemplate = {
    metadata: {
        exportId: '',
        name: '',
        options: { is_using_new_record_sid_format: true },
        view_options: {},
        fields: [],
    },
    data: {
        records: [],
    },
}

const FIELD_TEMPLATE: FieldTemplate = {
    exportId: '',
    name: '',
    type: 'string',
    isPrimary: false,
    options: {},
    link_target_object_sid: null,
    rollup_target_object_sid: null,
    connectionOptions: {},
    syntheticFieldType: null,
}

const RECORD_TEMPLATE: RecordTemplate = {
    _sid: null,
    fields: [],
}

const RECORD_FIELD_TEMPLATE: RecordFieldTemplate = {
    api_name: null,
    value: null,
}
const TRANSLATED_FIELD_TYPES: TranslatedFieldTypes = {
    relationship: 'lookup',
    multi_relationship: 'multi_lookup',
    image: 'multi_file',
}

const TRANSLATED_LIST_LAYOUTS: TranslatedListLayouts = {
    table: 'tableV2',
    gallery: 'cardV2',
    kanban: 'boardV2',
}

function isSymmetricField(object: ObjectSchemaType, field: FieldSchemaType, app: AppSchemaType) {
    if (field.type !== 'multi_relationship') return false

    const relatedObject = app.objects.find((obj) => obj.id === field.target)

    if (!relatedObject) {
        return false
    }
    const otherSide = relatedObject.fields.find((f) => f.target === object.id)

    // This is a symmetric field if the other side is a single relationship
    // or if the other side is a multi but the other object comes first in the app
    return (
        !!otherSide &&
        (otherSide.type === 'relationship' ||
            app.objects.indexOf(relatedObject) < app.objects.indexOf(object))
    )
}

function removeSymmetricFields(bundle: Bundle) {
    for (const app of bundle.apps) {
        for (const object of app.objects) {
            object.fields = object.fields.filter((field) => !isSymmetricField(object, field, app))
        }
    }
}

export const bundleBuilder = (bundleInput: Bundle): any => {
    const bundle = cloneDeep(bundleInput)
    const result = cloneDeep(BUNDLE_TEMPLATE)

    removeSymmetricFields(bundle)

    for (const app of bundle.apps) {
        const appObject = cloneDeep(APP_TEMPLATE)
        appObject.properties.name = app.name

        appObject.objects = []
        for (const object of app.objects) {
            const objectObject = cloneDeep(OBJECT_TEMPLATE)
            objectObject.metadata.exportId = object.id
            objectObject.metadata.name = object.name
            objectObject.metadata.view_options.list = {
                display: TRANSLATED_LIST_LAYOUTS[object.layout as keyof TranslatedListLayouts],
                showAllFields: false,
                listHeader: {
                    type: 'portal',
                    style: 'full',
                    title: '',
                    eyebrow: '',
                    subtitle: '',
                    coverImageUrl:
                        'https://resources.go.stackerhq.com/list-headers/purple-basic.svg',
                    textAlignment: 'left',
                    backgroundColor: 'Purple',
                    searchPlacement: 'toolbar',
                },
            }
            objectObject.metadata.view_options.detail = { add_default_related_lists: true }

            if (object.layout === 'gallery') {
                objectObject.metadata.view_options.list.cardLabelStyle = 'text'
                objectObject.metadata.view_options.list['cardCardTitle'] = {
                    fieldSid: object.fields[0]?.id,
                }
            } else if (object.layout === 'kanban' && object.kanbanStatusField) {
                objectObject.metadata.view_options.list.statusField = object.kanbanStatusField
                const statusField = object.fields.find((f) => f.id === object.kanbanStatusField)
                if (statusField?.options) {
                    objectObject.metadata.view_options.list.statusColumns = statusField.options
                }
                objectObject.metadata.view_options.list.boardFieldStyle = 'tags'
                objectObject.metadata.view_options.list.boardLabelStyle = 'icon'
                objectObject.metadata.view_options.list.boardTitleSize = 'small'

                const imageField = object.fields.find((f) => f.type === 'image')
                if (imageField) {
                    objectObject.metadata.view_options.list.profileImage = {
                        fieldApiName: imageField.id,
                    }
                }
            }
            objectObject.metadata.fields = []
            for (const field of object.fields) {
                const fieldObject = cloneDeep(FIELD_TEMPLATE)
                fieldObject.exportId = field.id
                fieldObject.name = field.name

                if (field.type in TRANSLATED_FIELD_TYPES) {
                    fieldObject.type =
                        TRANSLATED_FIELD_TYPES[field.type as keyof TranslatedFieldTypes]
                } else {
                    fieldObject.type = field.type
                }

                if (field.options) {
                    fieldObject.options = {
                        options: field.options.map((x) => ({ label: x, value: x })),
                    }
                }
                fieldObject.link_target_object_sid = field.target
                objectObject.metadata.fields.push(fieldObject)
            }
            objectObject.metadata.fields[0].isPrimary = true

            for (const record of object.records) {
                const recordObject = cloneDeep(RECORD_TEMPLATE)
                recordObject._sid = record.id
                recordObject.fields = []
                for (const field of record.fields) {
                    const fieldObject = cloneDeep(RECORD_FIELD_TEMPLATE)
                    fieldObject.api_name = field.fieldId
                    fieldObject.value = field.value

                    const fieldDef = objectObject.metadata.fields.find(
                        (f) => f.exportId === field.fieldId
                    )
                    if (fieldDef?.type === 'multi_lookup') {
                        fieldObject.value = fieldObject.value?.split(',') ?? null
                    }

                    if (fieldDef?.type === 'multi_file') {
                        fieldObject.value = [{ url: fieldObject.value }]
                    }

                    recordObject.fields.push(fieldObject)
                }
                objectObject.data.records.push(recordObject)
            }
            appObject.objects.push(objectObject)
        }
        result.apps.push(appObject)
    }
    return result
}

import React, { forwardRef } from 'react'

import { Icon } from 'ui/components/Icon'
import { Tooltip } from 'ui/components/Tooltip'

import * as Parts from './Popup.parts'

type PopupTitleRef = HTMLDivElement

type PopupTitleProps = React.ComponentPropsWithoutRef<typeof Parts.Title> & {
    infoText?: string
}

export const PopupTitle = forwardRef<PopupTitleRef, PopupTitleProps>(
    ({ children, infoText, ...props }, ref) => {
        return (
            <>
                <Parts.Title ref={ref} {...props}>
                    {children}
                    {infoText && (
                        <Tooltip content={infoText}>
                            <Icon
                                name="Info"
                                size="xs"
                                color="icon"
                                aria-label="Information text"
                            />
                        </Tooltip>
                    )}
                </Parts.Title>
                <Parts.Divider />
            </>
        )
    }
)

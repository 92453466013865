import React, { useMemo } from 'react'

import { formatValue } from 'data/utils/utils'
import { InlineFilter } from 'features/records/components/RecordFilters/inlineFilters/InlineFilter'
import { useInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/useInlineFilter'
import { getInlineFilterValue } from 'features/records/components/RecordFilters/inlineFilters/valueUtils'

import type { InlineFilterComponent } from './types'

export const CurrencyInlineFilter: InlineFilterComponent<{}> = ({ field, columns }) => {
    const { filter, ...inlineFilterProps } = useInlineFilter({ field })
    const valueLabels = useMemo(() => {
        const value = getInlineFilterValue(filter)
        if (!value) {
            return []
        }

        return [formatValue(field, value)]
    }, [field, filter])

    return (
        <InlineFilter
            field={field}
            columns={columns}
            closeOnSelect
            valueLabels={valueLabels}
            {...inlineFilterProps}
        />
    )
}

import React from 'react'

import { useLocalStorageState } from 'utils/useLocalStorageState'

import { Box } from 'ui/components/Box'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'

type TasksSectionProps = React.ComponentPropsWithoutRef<typeof Collapsible> & {
    name: string
    label: string
    counter?: number
    defaultExpanded?: boolean
}

export const TasksSection: React.FC<TasksSectionProps> = ({
    name,
    label,
    children,
    counter,
    defaultExpanded = true,
    ...props
}) => {
    const [isOpen, setIsOpen] = useLocalStorageState(`tasks_${name}_expanded`, {
        defaultValue: defaultExpanded,
    })

    const isCounterPositive = typeof counter !== 'undefined' && counter > 0

    const hasContent = Boolean(children)

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
            setIsOpen(!isOpen)
            e.preventDefault()
        }
    }

    return (
        <Collapsible open={isOpen} onOpenChange={setIsOpen} disabled={!hasContent} {...props}>
            <CollapsibleTrigger asChild px="3xl" onKeyDown={handleKeyDown}>
                <Box
                    flex
                    center
                    role={hasContent ? 'button' : undefined}
                    tabIndex={hasContent ? 0 : -1}
                >
                    {hasContent && (
                        <Icon name={isOpen ? 'ChevronDown' : 'ChevronRight'} mr="m" size="xl" />
                    )}
                    <Box as="h2" fontSize="headlineXs" fontWeight="bodyBold" mr="m" noShrink>
                        {label}
                    </Box>
                    {typeof counter !== 'undefined' && (
                        <Box
                            fontWeight="bodyBold"
                            background={isCounterPositive ? 'surface' : 'surfaceStrong'}
                            color="text"
                            borderRadius="s"
                            borderStyle="base"
                            borderColor="border"
                            borderWidth="base"
                            fontSize="bodyS"
                            py="xs"
                            px="s"
                        >
                            {counter}
                        </Box>
                    )}
                    <Divider width="full" ml="m" />
                </Box>
            </CollapsibleTrigger>
            <CollapsibleContent>
                {hasContent && (
                    <Box py="xl" key="collapsible-children">
                        {children}
                    </Box>
                )}
            </CollapsibleContent>
        </Collapsible>
    )
}

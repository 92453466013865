import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { makeToast } from 'utils/toaster'

const impersonatePath = `impersonate/`

export function fetchImpersonatedUser(userSid: string): Promise<UserDto> {
    return fetchWithAuth(
        impersonatePath,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userSid,
            }),
        },
        // Submit this request using the studio user's token
        // and ignore any user or role previewing.
        { bypassPreviewAs: true }
    )
        .then((response) => {
            const { status } = response
            if (status !== 200) {
                throw new Error('Failed to impersonate')
            }
            return response.json()
        })
        .then((data) => data as UserDto)
        .catch((error) => {
            makeToast(
                'impersonate_failed',
                'An error occurred. If this problem continues, please contact support.',
                'error'
            )
            return Promise.reject(error)
        })
}

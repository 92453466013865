import { AppGroup, AppUser } from 'features/users/AppUsers/types'

import { useAppUsers } from './useAppUsers'

const PAGE_SIZE = 4

type UseAppUsersGroupListGroupMembersStateOptions = {
    group: AppGroup
}

export function useAppUsersGroupListGroupMembersState(
    options: UseAppUsersGroupListGroupMembersStateOptions
) {
    const { group } = options

    const {
        users,
        totalUserCount = 0,
        isError,
        isFetchingSlow,
        hasNoUsers,
        hasNoUsersMatchingSearch,
    } = useAppUsers({
        pageSize: PAGE_SIZE,
        grantType: group.isWorkspaceGroup ? 'group' : 'table',
        grantSid: group.id,
    })

    const usersWithPlaceholders = users ? createUserPlaceholders(users, totalUserCount) : []

    return {
        users: usersWithPlaceholders,
        hasNoUsers,
        hasNoUsersMatchingSearch,
        isFetchingSlow,
        isError,
    }
}

// There are only 4 users visible. We only need to load those, and for the rest
// we can just create placeholders, to show the +N more users text.
function createUserPlaceholders(users: AppUser[], totalUserCount: number) {
    const placeholders: AppUser[] = users.slice()

    for (let i = users.length; i < totalUserCount; i++) {
        placeholders.push({
            id: `placeholder-${i}`,
            firstName: '',
            lastName: '',
            email: '',
            isAdmin: false,
            isInvitePending: false,
            role: '',
            type: 'initial',
        })
    }

    return placeholders
}

import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import Linkify from 'react-linkify'

import styled from '@emotion/styled'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, withReact } from 'slate-react'
import { variant } from 'styled-system'

import Box from 'v2/ui/components/Box'
import richTextStyles from 'v2/ui/theme/styles/richText'

const getSlateValue = (value) => {
    // An empty array will cause an error in Slate
    if (value && value.length > 0) {
        return value
    }

    return initialValue
}

const RichTextarea = forwardRef(
    (
        {
            value: propValue,
            readOnly,
            onChange,
            placeholder = 'Enter some text...',
            plugins = [],
            linkify = false,
            ...props
        },
        ref
    ) => {
        const [value, setValue] = useState(getSlateValue(propValue))
        const [editor] = useState(() => withReact(withHistory(createEditor())))
        useEffect(() => {
            callPlugins('initialize', editor)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [plugins])

        useEffect(() => {
            if (ref) ref.current = editor
        }, [editor, ref])

        useEffect(() => {
            editor.children = getSlateValue(propValue)
        }, [editor, propValue])

        const callPlugins = (method, ...args) => {
            for (let plugin of plugins) {
                if (plugin[method]) {
                    plugin[method](...args)
                }
            }
        }

        const onKeyDown = useCallback(
            (event) => {
                callPlugins('onKeyDown', event)
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [plugins]
        )

        const renderElement = useCallback(
            (props) => {
                const { attributes, children } = props

                const pluginResult = plugins.reduce(
                    (result, value) =>
                        result || (value.renderElement ? value.renderElement(props) : null),
                    null
                )

                return pluginResult || <p {...attributes}>{children}</p>
            },
            [plugins]
        )

        const renderLeaf = (e) => {
            if (e.text?.text && linkify && RegExp('(http|https)://').test(e.text.text)) {
                return (
                    <span {...e.attributes}>
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                // eslint-disable-next-line react/jsx-no-target-blank
                                <a target="_blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >
                            <span data-slate-string="true">{e.text.text}</span>
                        </Linkify>
                    </span>
                )
            }
            return <span {...e.attributes}>{e.children}</span>
        }
        return (
            <Slate
                editor={editor}
                value={value}
                onChange={(value) => {
                    setValue(value)
                    callPlugins('onChange', value)
                    if (onChange) onChange(value)
                }}
            >
                <Container {...props}>
                    <Editable
                        class={readOnly ? '' : 'notranslate'}
                        readOnly={readOnly}
                        renderElement={renderElement}
                        renderLeaf={readOnly ? renderLeaf : undefined}
                        onKeyDown={onKeyDown}
                        placeholder={placeholder}
                        autoCapitalize="off"
                        autoCorrect="off"
                    />
                    {plugins.map((x) => x.components)}
                </Container>
            </Slate>
        )
    }
)

const baseStyle = {
    px: 3,
    fontFamily: 'button',
}

const Container = styled(Box)`
    ${richTextStyles}
    ${variant({
        variants: {
            outlined: {
                ...baseStyle,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'input.border',
                borderRadius: 'input',
                background: 'white',
                '&:hover': {
                    borderColor: 'input.borderHover',
                },
                '&:focus-within': {
                    borderColor: 'input.borderActive',
                    borderWidth: '2px',
                },
            },
        },
    })}
`

const initialValue = [
    {
        children: [
            {
                text: '',
            },
        ],
    },
]

export default RichTextarea

import React, { forwardRef } from 'react'

import { Button } from 'ui/components/Button'
import { Trigger } from 'ui/components/Dropdown/Dropdown.parts'
import { useDropdownContext } from 'ui/components/Dropdown/dropdownContext'
import { Icon } from 'ui/components/Icon'
import { makeComponent } from 'ui/helpers/recipes'

import {
    FirstButtonStyles,
    LastButtonStyles,
    SplitButtonIconStyles,
    WrapperStyles,
} from './SplitButton.css'

const Wrapper = makeComponent('div', WrapperStyles)
const FirstButton = makeComponent(Button, FirstButtonStyles, undefined, ['variant'])
const LastButton = makeComponent(Button, LastButtonStyles, undefined, ['variant', 'size'])
const LastButtonIcon = makeComponent(Icon, SplitButtonIconStyles)

type SplitButtonRef = HTMLDivElement

type SplitButtonProps = Omit<React.ComponentPropsWithoutRef<typeof Button>, 'variant'> & {
    variant?: 'primary' | 'secondary'
    isDropdown?: boolean
}

export const SplitButton = forwardRef<SplitButtonRef, SplitButtonProps>(
    (
        {
            variant = 'primary',
            disabled,
            size = 'm',
            endIcon: providedEndIcon,
            isLoading,
            ...props
        },
        ref
    ) => {
        // If the SplitButton is used inside a Dropdown, then the last button should be a dropdown trigger.
        const dropdownContext = useDropdownContext()
        const isDropdown = dropdownContext !== null

        const endIcon: SplitButtonProps['endIcon'] = {
            name: 'ChevronDown',
            ...providedEndIcon,
        }
        const hasCustomIcon = typeof providedEndIcon !== 'undefined'

        const lastButton = (
            <LastButton
                disabled={disabled}
                // TODO: fix types, or add missing variant if needed
                // @ts-expect-error
                variant={variant}
                size={size}
                aria-label="Open menu"
                aria-busy={isLoading}
            >
                <LastButtonIcon {...endIcon} isCustom={hasCustomIcon} />
            </LastButton>
        )

        return (
            <Wrapper ref={ref}>
                <FirstButton
                    disabled={disabled}
                    variant={variant}
                    size={size}
                    isLoading={isLoading}
                    {...props}
                />
                {isDropdown ? <Trigger asChild>{lastButton}</Trigger> : lastButton}
            </Wrapper>
        )
    }
)

import React, { useContext, useMemo } from 'react'

import { DataListBlockContext } from 'v2/blocks/blockTypes/view/DataListBlock/DataListBlockContext'
import { DataListBlockEmptyState } from 'v2/blocks/blockTypes/view/DataListBlock/DataListBlockEmptyState'
import { DataListBlockFilters } from 'v2/blocks/blockTypes/view/DataListBlock/DataListBlockFilters'
import { DataListBlockHeader } from 'v2/blocks/blockTypes/view/DataListBlock/DataListBlockHeader'
import { DataListBlockPagination } from 'v2/blocks/blockTypes/view/DataListBlock/DataListBlockPagination'
import DataListBlockSearchEmptyState from 'v2/blocks/blockTypes/view/DataListBlock/DataListBlockSearchEmptyState'
import { DataListBlockWrapper } from 'v2/blocks/blockTypes/view/DataListBlock/DataListBlockWrapper'
import { getPageSizesVariants } from 'v2/blocks/blockTypes/view/DataListBlock/utils'
import { CustomListRendererComponent } from 'v2/views/List/types'

import { TableRendererList } from './TableRendererList'

type TableRendererProps = {}

export const TableRenderer: CustomListRendererComponent<TableRendererProps> = ({
    data,
    headers,
    object,
    rowLinkFunction,
    additionalContainerContent,
    viewOptions,
    setViewOptions,
    pageCount,
    pageIndex,
    pageSize,
    setPageSize,
    canGoToPreviousPage,
    canGoToNextPage,
    goToPage,
    showPageSizeOptions,
    isServerLoading,
    totalResultCount,
    title,
    showFilters,
    tableColumns,
    setAllFilters,
    filters,
    inlineFilters,
    setInlineFilters,
    userFilterFieldIds,
    searchQuery,
    handleSearch,
    searchColumns,
    records,
    dereferencedRecords,
    orderBy,
    setOrderBy,
    openRecord,
    downloadCsv,
}) => {
    const dataListBlockContext = useContext(DataListBlockContext)
    const { attrs, context } = dataListBlockContext ?? {}
    const pageSizeOptions = useMemo(() => getPageSizesVariants(attrs?.display), [attrs?.display])

    if (!attrs || !context) return null

    const hasData = Boolean(data?.length)
    const hasFilters = (inlineFilters?.length ?? 0) > 0

    const hideCreateButton = attrs.hide_create_button
    const hideSearch = attrs.hide_search_bar
    const allowDownload = attrs.allowDownload
    const subtitle = attrs.subtitle

    const isEditing = context.editor.isEditing

    const clearSearch = () => {
        handleSearch?.('', records ?? [])
    }

    return (
        <DataListBlockWrapper>
            <DataListBlockHeader
                title={title}
                subtitle={subtitle}
                hideSearch={hideSearch}
                hideCreateButton={hideCreateButton}
                object={object}
                viewOptions={viewOptions}
                searchQuery={searchQuery}
                handleSearch={handleSearch}
                searchColumns={searchColumns}
                dereferencedRecords={dereferencedRecords}
                records={records}
                showDivider={true}
                showDataCounter={true}
                totalResultCount={totalResultCount}
                allowDownload={allowDownload}
                onDownloadCsv={downloadCsv}
            >
                {showFilters && (
                    <DataListBlockFilters
                        tableColumns={tableColumns}
                        setAllFilters={setAllFilters}
                        filters={filters}
                        userFilterFieldIds={userFilterFieldIds}
                        inlineFilters={inlineFilters}
                        setInlineFilters={setInlineFilters}
                        object={object}
                        mt={2}
                    />
                )}
            </DataListBlockHeader>

            {hasData && (
                <>
                    <TableRendererList
                        object={object}
                        data={data}
                        headers={headers}
                        rowLinkFunction={rowLinkFunction}
                        additionalContainerContent={additionalContainerContent}
                        viewOptions={viewOptions}
                        setViewOptions={setViewOptions}
                        isEditing={isEditing}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        openRecord={openRecord}
                    />
                    <DataListBlockPagination
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        canGoToPreviousPage={canGoToPreviousPage}
                        canGoToNextPage={canGoToNextPage}
                        goToPage={goToPage}
                        showPageSizeOptions={showPageSizeOptions}
                        isLoading={isServerLoading}
                        pageSizeOptions={pageSizeOptions}
                    />
                </>
            )}

            {!hasData && !searchQuery && (
                <DataListBlockEmptyState
                    hasFilters={hasFilters}
                    onClearFilters={() => setInlineFilters?.([])}
                />
            )}
            {!hasData && searchQuery && <DataListBlockSearchEmptyState clearSearch={clearSearch} />}
        </DataListBlockWrapper>
    )
}

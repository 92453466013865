import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makePaginationVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            text: s.color.text,
            textHover: s.color.text,
            textActive: s.color.textInverse,

            surface: s.color.surfaceTransparent,
            surfaceHover: s.color.gray100,
            surfaceActive: s.color.theme600,
        },
        padding: {
            vertical: s.space.xl,
            numberVertical: s.space.s,
            numberHorizontal: s.space.l,
        },
        gap: {
            betweenNumbers: s.space.xs,
            countItems: s.space.xs,
            controls: s.space.xl,
            perPageItems: s.space.m,
        },
        radius: {
            number: s.radius.m,
        },
        size: {
            number: pxToRem(28),
        },
        shadows: {
            focus: s.shadows.focus,
        },
    }
}

import { useCallback } from 'react'

export function useKeyDownFromHead(headElement: HTMLElement | null) {
    return useCallback(
        (e: React.KeyboardEvent<HTMLElement>) => {
            e.stopPropagation()

            if (e.key === 'ArrowDown') {
                // Navigate to the next focusable item.
                const headItem = headElement
                if (!headItem) return

                const nextFocusable = getNextFocusableItem(headItem)
                if (!nextFocusable) return

                e.preventDefault()
                nextFocusable.focus()
            }
        },
        [headElement]
    )
}
function getNextFocusableItem(current: HTMLElement): HTMLElement | null {
    let sibling = current.nextElementSibling
    while (sibling) {
        if (sibling.matches('[data-radix-collection-item]:not([aria-disabled="true"])')) {
            return sibling as HTMLElement
        }
        sibling = sibling.nextElementSibling
    }

    return null
}

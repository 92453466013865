import { useCallback, useMemo } from 'react'

type FlatInputMapItem = WorkflowActionConfigInput

type FlatInputMap = Map<string, FlatInputMapItem>

export function useActionInputConfigManager(
    actionConfig: WorkflowActionConfig,
    handlePatchActionConfig: (actionConfig: Omit<Partial<WorkflowActionConfig>, 'id'>) => void
) {
    const inputs = useMemo(() => {
        const map = new Map<string, FlatInputMapItem>()
        for (const input of actionConfig.inputs) {
            buildFlatInputMap(input, [input.id], map)
        }

        return map
    }, [actionConfig.inputs])

    const setConfigAtPath = useCallback(
        (
            path: string[],
            expression?: WorkflowExpression,
            configPatch?: Partial<Omit<WorkflowActionConfigInput, 'id'>>
        ) => {
            const key = path.join('/')
            const id = path[path.length - 1]

            const updatedMap = new Map(inputs)
            const existing = inputs.get(key)
            updatedMap.set(key, {
                ...existing,
                id,
                value: expression,
                ...configPatch,
            })

            const newInputs = buildFinalInputs(updatedMap)
            handlePatchActionConfig({ inputs: newInputs })
        },
        [handlePatchActionConfig, inputs]
    )

    const getConfigAtPath = (path: string[]): Omit<WorkflowActionConfigInput, 'id'> => {
        const key = path.join('/')
        return inputs.get(key) || {}
    }

    return {
        setConfigAtPath,
        getConfigAtPath,
    }
}

/**
 * Build a flattened version of the inputs array, where each item is keyed by its path.
 * Only inputs are stored, groups are ignored.
 *
 * Example key: `group1/group2/input1`
 *
 * @param input
 * @param path
 * @param initialMap
 */
function buildFlatInputMap(
    input: WorkflowActionConfigInputItem,
    path: string[],
    initialMap: FlatInputMap
) {
    const currentPath = path.join('/')

    if ('items' in input) {
        for (const groupInput of input.items) {
            buildFlatInputMap(groupInput, [...path, groupInput.id], initialMap)
        }
    } else {
        initialMap.set(currentPath, input)
    }
}

/**
 * This generates the final inputs array from the flattened map created before.
 * @param fromMap
 */
function buildFinalInputs(fromMap: FlatInputMap): WorkflowActionConfigInputItem[] {
    const rootItems = new Map<string, WorkflowActionConfigInputItem>()

    for (const [path, item] of fromMap) {
        const pathSegments = path.split('/')

        let currentGroup: WorkflowActionConfigInputGroup | undefined
        let parentGroup: WorkflowActionConfigInputGroup | undefined

        for (let i = 0; i < pathSegments.length; i++) {
            const currSegment = pathSegments[i]
            const lastSegmentIdx = pathSegments.length - 1

            const isLastSegment = i === lastSegmentIdx

            // If this is the last segment, we're at the input item.
            if (isLastSegment) {
                if (currentGroup) {
                    currentGroup.items.push(item)
                } else {
                    rootItems.set(currSegment, item)
                }
            } else {
                const groupPath = pathSegments.slice(0, lastSegmentIdx).join('/')
                if (!groupPath) continue

                if (parentGroup) {
                    currentGroup = parentGroup.items.find(
                        (item) => item.id === currSegment
                    ) as WorkflowActionConfigInputGroup
                } else {
                    currentGroup = rootItems.get(currSegment) as WorkflowActionConfigInputGroup
                }

                if (!currentGroup) {
                    currentGroup = {
                        id: currSegment,
                        items: [],
                    }
                    if (parentGroup) {
                        parentGroup.items.push(currentGroup)
                    } else {
                        rootItems.set(currSegment, currentGroup)
                    }
                }

                parentGroup = currentGroup
            }
        }
    }

    return Array.from(rootItems.values())
}

import React from 'react'

import styled from '@emotion/styled'

import { Input, Text } from 'v2/ui'

const StyledInput = styled(Input)`
    border: 1px solid ${({ theme }) => theme.colors?.input?.border};
    transition: border-color 0.1s ease-out;

    &:hover {
        border-color: ${({ theme }) => theme.colors?.input?.borderHover};
    }

    &:focus-within {
        border-color: ${({ theme }) => theme.colors?.brand?.[500]};
    }

    & > input {
        border: 0;
    }
`

type InlineFilterInputProps = React.ComponentPropsWithRef<typeof Input> & {
    leftAddonText?: string
    rightAddonText?: string
}

export const InlineFilterInput = React.forwardRef<HTMLInputElement, InlineFilterInputProps>(
    ({ leftAddonText, rightAddonText, ...props }, ref) => {
        return (
            <StyledInput
                ref={ref}
                size="sm"
                leftAddonProps={{
                    borderWidth: 0,
                    borderRightWidth: '1px',
                    borderRightColor: 'input.border',
                    marginInlineEnd: 0,
                    userSelect: 'none',
                    p: 3,
                }}
                leftAddon={
                    leftAddonText && (
                        <Text
                            _groupFocusWithin={{
                                color: 'brand.500',
                            }}
                            fontWeight="bold"
                        >
                            {leftAddonText}
                        </Text>
                    )
                }
                rightAddonProps={{
                    borderWidth: 0,
                    borderLeftWidth: '1px',
                    borderLeftColor: 'input.border',
                    marginInlineStart: 0,
                    userSelect: 'none',
                    p: 3,
                }}
                rightAddon={
                    rightAddonText && (
                        <Text
                            _groupFocusWithin={{
                                color: 'brand.500',
                            }}
                            fontWeight="bold"
                        >
                            {rightAddonText}
                        </Text>
                    )
                }
                groupProps={{
                    role: 'group',
                }}
                {...props}
            />
        )
    }
)

import { useCallback } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { convertOutputSchemaToOutputs } from 'features/workflows/OutputSchema/utils'

export function useWebhookTriggerFinalSchema() {
    const { data: schema } = useWorkflowSchema()

    return useCallback(
        (config: WorkflowTriggerConfig): WorkflowSchemaTriggerType | undefined => {
            const type = schema?.triggers.find((t) => t.id === config.trigger_type)
            if (!type) return undefined

            const outputSchema = config.settings?.output_schema as WorkflowOutputSchema | undefined
            if (!outputSchema) return undefined

            const outputs = convertOutputSchemaToOutputs(outputSchema)

            return {
                ...type,
                outputs,
            }
        },
        [schema?.triggers]
    )
}

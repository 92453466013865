// @ts-strict-ignore
import React, { useState } from 'react'

import { Spinner } from '@chakra-ui/react'

import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'

import { ActionList, Button, Collapse, Flex, Text } from 'v2/ui'
import { ActionListItem } from 'v2/ui/components/ActionList'

import SubmitButton from 'ui/deprecated/forms/SubmitButton'
import useIsFormDirty from 'ui/deprecated/forms/useIsFormDirty'

import { GroupListItemType } from './groupListItemTypes'

type UserActionsProps = {
    user: any
    onRemove?: () => void
}
export const UserActions = ({ user, onRemove }: UserActionsProps) => {
    const { previewAsUser } = usePreviewServiceContext()
    const [isLoading, setIsLoading] = useState(false)

    const handlePreviewAsUser = async () => {
        const userId = user._sid
        setIsLoading(true)
        try {
            await previewAsUser(userId)
        } finally {
            setIsLoading(false)
        }
    }

    const actions: ActionListItem[] = []

    // Only if user has access
    if (user.role && user.role !== 'internal_admin') {
        actions.push({ icon: 'play', label: 'Preview as user', action: handlePreviewAsUser })
    }
    if (onRemove) {
        actions.push({ divider: true })
        actions.push({ icon: 'minusCircle', label: 'Remove from app', action: onRemove })
    }

    if (!actions.length) return null

    if (isLoading) return <Spinner size="sm" color="neutral.600" />
    return (
        <ActionList
            variant="iconButton"
            buttonSize="smDense"
            icon="dotsH"
            color="neutral.800"
            actions={actions}
        />
    )
}

type GroupActionsProps = {
    group: GroupListItemType
    onRemove?: () => void
}
export const GroupActions = ({ onRemove }: GroupActionsProps) => {
    const [isLoading] = useState(false)

    const actions: ActionListItem[] = []

    if (onRemove) {
        //actions.push({ divider: true })
        actions.push({ icon: 'minusCircle', label: 'Remove group from app', action: onRemove })
    }

    if (isLoading) return <Spinner size="sm" color="neutral.600" />
    return (
        // @ts-ignore
        <ActionList
            variant="iconButton"
            buttonSize="smDense"
            icon="dotsH"
            color="neutral.800"
            actions={actions}
        />
    )
}

export function SaveChangesBar({ error, cancelEdit }) {
    const hasChanges = useIsFormDirty()

    return (
        <Collapse isOpen={hasChanges}>
            <Flex column wrap="nowrap" align="flex-end" bg="neutral.200" p={2}>
                <Flex wrap="nowrap">
                    {!error && (
                        <Text mr={4} fontWeight="bold" color="neutral.800">
                            There are pending changes
                        </Text>
                    )}
                    <Collapse isOpen={!!error}>
                        <Text variant="error" mr={4}>
                            {error}
                        </Text>
                    </Collapse>
                    <Button variant="adminSecondaryV4" onClick={cancelEdit} mr={2}>
                        Cancel
                    </Button>
                    <SubmitButton v4 requireChanges>
                        Save
                    </SubmitButton>
                </Flex>
            </Flex>
        </Collapse>
    )
}

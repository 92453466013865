import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import { icons as lucideIcons } from 'lucide-react'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { IconName } from 'ui/components/Icon/Icon'
import { ScrollArea } from 'ui/components/ScrollArea'
import { Body } from 'ui/components/Text'

import * as Parts from './IconPicker.parts'

import { IconPickerScrollAreaStyles } from './IconPicker.css'

const SEARCH_DEBOUNCE_RATE = 200

const LUCIDE_ICONS = Object.keys(lucideIcons) as IconName[]

type IconVariants = 'lucide'

type IconValue = {
    lucide: IconName
}

type IconPickerRef = HTMLDivElement

type IconPickerProps<V extends IconVariants> = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.Container>,
    'asChild' | 'value' | 'defaultValue' | 'onChange' | 'onValueChange'
> & {
    variant?: V
    isClearable?: boolean
    value?: IconValue[V] | null
    defaultValue?: IconValue[V] | null
    onChange?: (value?: IconValue[V]) => void
}

const IconPickerInner = <V extends IconVariants>(
    {
        variant = 'lucide' as V,
        value: providedValue,
        defaultValue,
        onChange,
        isClearable,
        ...props
    }: IconPickerProps<V>,
    ref: React.ForwardedRef<IconPickerRef>
) => {
    const options = variant === 'lucide' ? LUCIDE_ICONS : []

    const isControlled = typeof providedValue !== 'undefined'
    const [value, setValue] = useState<IconValue[V] | null>(providedValue || defaultValue || null)
    useEffect(() => {
        setValue(providedValue || defaultValue || null)
    }, [providedValue, defaultValue])

    const handleChange = useCallback(
        (newValue: string | null) => {
            if (!isControlled) {
                setValue(newValue as IconValue[V])
            }
            onChange?.(newValue as IconValue[V])
        },
        [onChange, isControlled]
    )

    const onClear = useCallback(() => {
        handleChange(null)
    }, [handleChange])

    const [normalizedSearchQuery, setNormalizedSearchQuery] = useState('')
    const filteredOptions = useDeepEqualsMemoValue(
        options.filter((iconName) => {
            return iconName.toLowerCase().includes(normalizedSearchQuery)
        })
    )
    const filteredOptionsRef = useRef(filteredOptions)
    filteredOptionsRef.current = filteredOptions

    const onSearchChange = useCallback((query: string) => {
        requestAnimationFrame(() => {
            setNormalizedSearchQuery(query.trim().toLowerCase())
        })
    }, [])

    const onRandomize = useCallback(() => {
        const options = filteredOptionsRef.current

        const randomIndex = Math.floor(Math.random() * options.length)
        handleChange(options[randomIndex])
    }, [handleChange])

    const hasNoSearchOptions = !!normalizedSearchQuery && filteredOptions.length < 1

    return (
        <Parts.Container ref={ref} {...props}>
            <Parts.Header noShrink>
                <Box grow>
                    <SearchInput onChange={onSearchChange} />
                </Box>
                <Box>
                    <Button
                        size="2xs"
                        variant="secondary"
                        startIcon={{ name: 'Shuffle' }}
                        onClick={onRandomize}
                        aria-label="Pick a random icon"
                        disabled={hasNoSearchOptions}
                    />
                </Box>
            </Parts.Header>
            {hasNoSearchOptions ? (
                <Box grow flex center justifyContent="center" p="m">
                    <Body size="s" weight="regular" color="textHelper">
                        No icons match your search query
                    </Body>
                </Box>
            ) : (
                <Picker
                    variant={variant}
                    value={value as string}
                    onValueChange={handleChange}
                    options={filteredOptions}
                    grow
                />
            )}
            {!!value && isClearable && (
                <Box flex center noShrink>
                    <Button variant="secondary" size="s" width="full" onClick={onClear}>
                        Clear
                    </Button>
                </Box>
            )}
        </Parts.Container>
    )
}

export const IconPicker = forwardRef(IconPickerInner) as <V extends IconVariants = 'lucide'>(
    props: IconPickerProps<V> & { ref?: React.ForwardedRef<IconPickerRef> }
) => ReturnType<typeof IconPickerInner>

type PickerProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Picker>, 'asChild'> & {
    variant: IconVariants
    options: IconName[]
}

const Picker: React.FC<PickerProps> = ({ variant, options, value, ...props }) => {
    return (
        <ScrollArea
            width="full"
            height="full"
            direction="vertical"
            rootProps={{
                width: 'full',
                height: 'full',
                className: IconPickerScrollAreaStyles.styleFunction({}),
            }}
            type="scroll"
        >
            <Parts.Picker {...props} value={value} asChild={false}>
                {options.map((iconName) => (
                    <PickerItem
                        key={iconName}
                        variant={variant}
                        value={iconName}
                        isActive={value === iconName}
                    />
                ))}
            </Parts.Picker>
        </ScrollArea>
    )
}

type PickerItemRef = HTMLButtonElement

type PickerItemProps<V extends IconVariants> = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.PickerItem>,
    'asChild'
> & {
    variant: V
    value: IconValue[V]
    isActive?: boolean
}

const PickerItemInner = <V extends IconVariants>(
    { variant, value, isActive, ...props }: PickerItemProps<V>,
    ref: React.ForwardedRef<PickerItemRef>
) => {
    const localRef = useRef<HTMLButtonElement>(null)
    const composedRef = useComposedRefs(localRef, ref)

    // Scroll into view if the item is active and not in the viewport.
    useLayoutEffect(() => {
        const element = localRef.current

        if (!isActive || !element) return

        const elRect = element.getBoundingClientRect()
        const isInViewport = elRect.top >= 0 && elRect.bottom <= window.innerHeight

        if (!isInViewport) {
            localRef.current.scrollIntoView({ block: 'center' })
        }
    }, [isActive])

    return (
        <Parts.PickerItem {...props} value={value} ref={composedRef}>
            {variant === 'lucide' && <Parts.LucideIcon size="m" name={value} />}
        </Parts.PickerItem>
    )
}

const PickerItem = React.memo(forwardRef(PickerItemInner)) as <V extends IconVariants>(
    props: PickerItemProps<V> & { ref?: React.ForwardedRef<PickerItemRef> }
) => ReturnType<typeof PickerItemInner>

type SearchInputProps = {
    onChange: (newValue: string) => void
}

const SearchInput: React.FC<SearchInputProps> = ({ onChange: providedOnChange, ...props }) => {
    const [searchQuery, setSearchQuery] = useState('')
    const applyDebouncedSearchQuery = useDebounce(providedOnChange, SEARCH_DEBOUNCE_RATE)

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setSearchQuery(value)
            applyDebouncedSearchQuery(value)
        },
        [applyDebouncedSearchQuery]
    )

    return (
        <Parts.SearchInput
            variant="borderless"
            placeholder="Filter icons..."
            inputProps={{
                className: 'ignore-ios-zoom',
            }}
            size="m"
            {...props}
            value={searchQuery}
            onChange={onChange}
        />
    )
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react'

import { useObject } from 'data/hooks/objects'
import { canCreateRecords } from 'data/utils/getObjectRecordRestrictions'
import QuillRichText from 'features/pages/blocks/blockTypes/form/QuillRichText'
import { TableViewCalculationsControls } from 'features/views/ListView/TableView/Calculations/TableViewCalculationsControls'
import isRichTextField from 'utils/isRichTextField'

import { Box, Checkbox, Collapse, Flex, Input, Text, Textarea } from 'v2/ui'
import Attribute from 'v2/ui/components/Attribute/Attribute'
import {
    getDefaultDisplayType,
    shouldShowDisplayTypeOption,
} from 'v2/ui/components/Attribute/attributeDisplayOptions'
import { isFieldTypePlaceholderSupported } from 'v2/ui/components/Attribute/placeholder'
import { DefaultAttributeEditorStyle } from 'v2/ui/components/FieldsEditor/FieldAttributesEditor.css'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import isEmptyValue from 'v2/ui/utils/isEmptyValue'
import useDebounce from 'v2/ui/utils/useDebounce'

import { RadioButton, RadioGroup } from 'ui/components/Radio'
import { Body } from 'ui/components/Text'

import CheckboxAttributeEditor from './CheckboxAttributeEditor'
import { PercentAttributeEditor } from './PercentAttributeEditor'

const WRAP_FIELD_TYPES = ['string', 'long_text', 'multi_lookup']
const LEGACY_VIEWS = ['table', 'kanban', 'card']

export const FieldAttributesEditor = ({
    item,
    fields,
    onUpdate,
    hideFieldRequiredSetting,
    hideFieldFullWidthSetting,
    hideFieldDescriptionSetting,
    hideCreateButtonSetting,
    hideColumns,
    hideUrlOptions,
    hideDisabledSearchSetting: _hideDisabledSearchSetting,
    hideEnableCopyPaste: _hideEnableCopyPaste,
    display,
    showDefaultValueSetting,
    showPlaceholderSetting,
    isCreate,
}) => {
    const [state, setState] = useState({ ...item })
    const queuedChanges = useRef()

    const itemId = item?.fieldId || item?.id

    const doSendUpdate = useCallback(() => {
        // send all accumulated changes during the debounce period now
        // and reset the queue of changes.
        onUpdate(itemId, queuedChanges.current)
        queuedChanges.current = null
    }, [onUpdate, itemId])

    const sendUpdate = useDebounce(doSendUpdate, 300)

    const field = fields.find((x) => x._sid === item.fieldId)
    const { object: targetObject } = useObject(field?.link_target_object_id, {
        enabled: !!field?.link_target_object_id,
    })
    const canCreateTargetRecords = canCreateRecords(targetObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setState({ ...item, label: item.label || (field && field.label) }), [item])
    //disable editing if it's not a list view and field is displayed as button
    const enableLabel = display || !state.displayAsButton

    // Only show 'hide label' checkbox in displays where labels are not always hidden
    const showHideLabel = display !== 'inbox' && display !== 'rows' && display !== 'boardV2'

    /**
     * List of fields types that can have a "Enable quick copy" setting
     */
    const _canBeCopied = [
        'string',
        'long_text',
        'number',
        'url',
        'date',
        'datetime',
        'currency',
        'percentage',
    ].includes(field?.type)

    const canBeSearched = [
        'currency',
        'document',
        'dropdown',
        'long_text',
        'lookup',
        'multi_lookup',
        'multi_select',
        'number',
        'percentage',
        'string',
        'url',
        // Following types can not be searched
        // "checkbox"
        // "date"
        // "datetime"
        // "image"
        // "multi_file"
        // "user_ref"
    ].includes(field?.type)

    const handleQuillchange = (value) => {
        queuedChanges.current = { ...queuedChanges.current, description: value }
        sendUpdate()
        setState((state) => ({ ...state, description: value }))
    }

    const handleChange = (e) => {
        const target = e.target
        let value = e.target?.value

        if (e.target.type === 'checkbox') {
            value = e.target.checked
        }

        setState((state) => {
            if (target.id === 'label' && value === field?.label) {
                value = null
            }

            const newState = { ...state, [target.id]: value }
            // since sendUpdate is debounced, we want to batch up any changes together
            // to be processed/sent when the debounced function fires
            queuedChanges.current = { ...queuedChanges.current, [target.id]: value }
            sendUpdate()
            return newState
        })
    }

    const handleDefaultValueChange = (name, value) => {
        setState((state) => {
            const newState = { ...state, [name]: value }
            queuedChanges.current = { ...queuedChanges.current, [name]: value }
            sendUpdate()
            return newState
        })
    }

    const readOnlyField = field?.is_read_only
    const isFieldEditable = isCreate && !readOnlyField

    let isDefaultValueSupported = isFieldEditable && showDefaultValueSetting
    if (item.type !== 'field') isDefaultValueSupported = false

    const placeholder = state.lockValue ? '' : state.placeholder
    const isPlaceholderDisabled = state.lockValue

    const isPlaceholderSupported = useMemo(() => {
        if (item.type !== 'field') return false
        if (!isFieldEditable || !showPlaceholderSetting) return false

        return isFieldTypePlaceholderSupported(field?.type ?? '')
    }, [field?.type, isFieldEditable, showPlaceholderSetting, item.type])
    const showDisplayType = shouldShowDisplayTypeOption(field, display)
    const defaultDisplayType = getDefaultDisplayType(field, display)

    const isCalculationSupported = display === 'tableV2' && !!field

    const isLegacy = LEGACY_VIEWS.includes(display)

    // display only has a value for list views, we want to hide this option for non-list views
    const shouldHideSearchable = !display

    return (
        <div className={ONBOARDING_CLASSES.EDIT_LAYOUT_FIELD_ITEM_SETTINGS}>
            <Flex>
                <Box display="flex" alignItems="baseline" gap={1} flexGrow={1}>
                    <Text variant="paletteSectionLabel" mt={0}>
                        Label
                    </Text>
                    {field && (
                        <Body color="textWeaker" size="s">
                            (
                            <Body color="textWeaker" size="s" maxLength={20}>
                                {field.label}
                            </Body>
                            )
                        </Body>
                    )}
                </Box>
                {item.type !== 'section' && showHideLabel && (
                    <Checkbox
                        isDisabled={!enableLabel}
                        id="hideLabel"
                        isChecked={state.hideLabel}
                        onChange={handleChange}
                        variant="admin"
                        mt={2}
                    >
                        Hide label
                    </Checkbox>
                )}
            </Flex>
            <Input
                variant="admin"
                id="label"
                disabled={showHideLabel && (state.hideLabel || !enableLabel)}
                value={state.label}
                onChange={handleChange}
                mb={3}
            />

            {!hideFieldDescriptionSetting && (
                <>
                    <Text variant="paletteSectionLabel">Description</Text>
                    {item.type === 'section' ? (
                        <QuillRichText
                            onChange={handleQuillchange}
                            value={state?.description || ''}
                            convertToMarkdown={false}
                            height="200px"
                        />
                    ) : (
                        <Textarea
                            id="description"
                            variant="admin"
                            value={state.description}
                            onChange={handleChange}
                            width="100%"
                        />
                    )}
                </>
            )}

            {isDefaultValueSupported && (
                <>
                    <Text variant="paletteSectionLabel">Default value (optional)</Text>
                    <Box mb={3}>
                        <Attribute
                            id="defaultValue"
                            variant="admin"
                            editable
                            onChange={(value) => handleDefaultValueChange('defaultValue', value)}
                            field={field}
                            className={DefaultAttributeEditorStyle}
                        >
                            {state.defaultValue}
                        </Attribute>
                    </Box>
                </>
            )}

            {isPlaceholderSupported && (
                <>
                    <Text variant="paletteSectionLabel">Placeholder text (optional)</Text>
                    <Input
                        variant="admin"
                        id="placeholder"
                        value={placeholder}
                        onChange={handleChange}
                        mb={3}
                        disabled={isPlaceholderDisabled}
                    />
                </>
            )}

            {isCalculationSupported && (
                <TableViewCalculationsControls
                    field={field}
                    calculationType={state.calculationType}
                    onCalculationTypeChange={(value) => {
                        handleDefaultValueChange('calculationType', value)
                    }}
                />
            )}

            {item.type === 'section' && !hideColumns ? (
                <>
                    <Text variant="paletteSectionLabel">Columns</Text>{' '}
                    <Box pl={1} pr={6}>
                        <Slider
                            flex="1"
                            min={1}
                            max={4}
                            value={state.columns || 3}
                            onChange={(value) =>
                                handleChange({ target: { id: 'columns', value: value } })
                            }
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>

                            <SliderThumb fontSize="sm" width="32px" height="20px">
                                {state.columns || 3}
                            </SliderThumb>
                        </Slider>
                    </Box>
                </>
            ) : null}
            {field && !(hideFieldFullWidthSetting && hideFieldRequiredSetting) && (
                <>
                    <Text variant="paletteSectionLabel">Settings</Text>
                    <Checkbox
                        isDisabled={isDefaultValueSupported && isEmptyValue(state.defaultValue)}
                        id="lockValue"
                        isChecked={state.lockValue}
                        onChange={handleChange}
                        variant="admin"
                    >
                        Read Only
                    </Checkbox>
                    {!hideFieldFullWidthSetting && (
                        <Checkbox
                            id="fullWidth"
                            isChecked={state.fullWidth}
                            onChange={handleChange}
                            variant="admin"
                        >
                            Display as full-width
                        </Checkbox>
                    )}
                    {!hideFieldRequiredSetting && (
                        <Checkbox
                            id="required"
                            isChecked={state.required}
                            onChange={handleChange}
                            variant="admin"
                        >
                            Required
                        </Checkbox>
                    )}
                </>
            )}

            {field?.type === 'percentage' && !isCreate && (
                <PercentAttributeEditor
                    selectedOption={state?.percentageDisplay}
                    setState={setState}
                    sendUpdate={sendUpdate}
                    queuedChanges={queuedChanges}
                    display={display}
                />
            )}

            {field?.type === 'checkbox' && !isCreate && (
                <CheckboxAttributeEditor
                    selectedOptions={state?.checkboxDisplay}
                    setState={setState}
                    sendUpdate={sendUpdate}
                    queuedChanges={queuedChanges}
                />
            )}

            {!hideCreateButtonSetting &&
                canCreateTargetRecords &&
                field &&
                ['lookup', 'multi_lookup'].includes(field.type) && (
                    <Checkbox
                        id="showCreateButton"
                        isChecked={state.showCreateButton}
                        onChange={handleChange}
                        variant="admin"
                    >
                        Show add new button
                    </Checkbox>
                )}

            {field && field.type === 'string' && !isRichTextField(field) && !hideUrlOptions && (
                <Checkbox
                    id="treatAsUrl"
                    isChecked={state.treatAsUrl}
                    onChange={handleChange}
                    variant="admin"
                >
                    Treat as URL
                </Checkbox>
            )}

            {!shouldHideSearchable && canBeSearched && (
                <Checkbox
                    id="isSearchable"
                    // all fields are shown as searchable by default, so unless the value is false explicitly, it's treated as true
                    isChecked={state.isSearchable !== false}
                    onChange={handleChange}
                    variant="admin"
                >
                    Searchable
                </Checkbox>
            )}

            {field && (
                <>
                    <Collapse
                        isOpen={
                            !!(
                                (!hideUrlOptions && field && field.type === 'url') ||
                                state.treatAsUrl ||
                                field.type === 'multi_file'
                            )
                        }
                        pl={state.treatAsUrl ? 4 : 0}
                        width="100%"
                    >
                        <Checkbox
                            id="displayAsImage"
                            isChecked={state.displayAsImage}
                            onChange={handleChange}
                            variant="admin"
                        >
                            Display as image
                        </Checkbox>
                        {state.displayAsImage && (
                            <>
                                <Text variant="paletteSectionLabel">Image Alt Text</Text>
                                <Input
                                    id="imageAltText"
                                    variant="admin"
                                    value={state.imageAltText}
                                    onChange={handleChange}
                                    width="100%"
                                    mb={3}
                                />
                            </>
                        )}
                    </Collapse>

                    <Collapse
                        isOpen={
                            !!(
                                (!hideUrlOptions && field && field.type === 'url') ||
                                state.treatAsUrl
                            )
                        }
                        pl={state.treatAsUrl ? 4 : 0}
                        width="100%"
                    >
                        {!state.displayAsImage && (
                            <>
                                {isLegacy && (
                                    <Checkbox
                                        id="displayAsButton"
                                        isChecked={state.displayAsButton}
                                        onChange={handleChange}
                                        variant="admin"
                                    >
                                        Display as button
                                    </Checkbox>
                                )}
                                <Checkbox
                                    id="openInNewTab"
                                    isChecked={state.openInNewTab}
                                    onChange={handleChange}
                                    variant="admin"
                                >
                                    Open in new tab
                                </Checkbox>
                                <Text variant="paletteSectionLabel">Link Text</Text>
                                <Input
                                    variant="admin"
                                    id="buttonTitle"
                                    value={state.buttonTitle}
                                    onChange={handleChange}
                                    mb={3}
                                />
                            </>
                        )}
                    </Collapse>
                    {field &&
                        WRAP_FIELD_TYPES.includes(field.type) &&
                        (display === 'table' || display === 'inbox' || display === 'kanban') && (
                            <Checkbox
                                id="wrapText"
                                isChecked={state.wrapText}
                                onChange={handleChange}
                                variant="admin"
                            >
                                Wrap text
                            </Checkbox>
                        )}
                </>
            )}

            {showDisplayType && (
                <>
                    <Text flexGrow={2} variant="paletteSectionLabel" mt={0}>
                        Display type
                    </Text>
                    <Flex>
                        <RadioGroup
                            value={state.displayType || defaultDisplayType}
                            onValueChange={(value) =>
                                handleDefaultValueChange('displayType', value)
                            }
                        >
                            <RadioButton value="normal">Normal</RadioButton>
                            <RadioButton value="pill">Pill</RadioButton>
                            <RadioButton value="badge">Badge</RadioButton>
                        </RadioGroup>
                    </Flex>
                </>
            )}
        </div>
    )
}

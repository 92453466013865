import React from 'react'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

import { useListViewCommentCountState } from './hooks/useListViewCommentCountState'

type ListViewCommentCountProps = React.ComponentPropsWithoutRef<typeof Box> & {
    record?: RecordDto
}

export const ListViewCommentCount: React.FC<ListViewCommentCountProps> = ({ record, ...props }) => {
    const { label, iconColor, textColor, showCount, count } = useListViewCommentCountState({
        record,
    })

    if (!showCount) {
        return null
    }

    return (
        <Box
            p="s"
            gap="2xs"
            flex
            center
            justifyContent="center"
            rounded="s"
            aria-label={label}
            {...props}
        >
            <Icon name="MessageCircle" size="xs" color={iconColor} />
            <Body color={textColor} weight="medium" size="s">
                {count}
            </Body>
        </Box>
    )
}

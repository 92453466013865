import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useAppNavigationHeaderState() {
    const { selectedApp } = useNavigationContext()

    return useMemo(
        () => ({
            selectedApp,
        }),
        [selectedApp]
    )
}

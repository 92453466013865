import React from 'react'

export type WorkflowChainContextValue = {
    chainPath: string[]
}
export const WorkflowChainContext = React.createContext<WorkflowChainContextValue>({
    chainPath: [],
})

export function useWorkflowChainContext() {
    return React.useContext(WorkflowChainContext)
}

import React, { useCallback, useImperativeHandle } from 'react'

import { SuggestionProps } from '@tiptap/suggestion'

import useLDFlags from 'data/hooks/useLDFlags'
import { useRecentItemsSearchProvider } from 'features/Search/RecentItemsSearchProvider'
import { useGlobalSearchProvider } from 'features/Search/useGlobalSearchProvider'

import { Box } from 'ui/components/Box'
import { ComboboxContext } from 'ui/components/Combobox'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'
import { Container } from 'ui/components/Container'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { LoadingIndicator } from 'ui/components/LoadingIndicator'

import { SuggestionComponentHandle } from './extensionHelpers'
import { insertRecordLink } from './InsertRecordLink'

type ListProps = SuggestionProps<any> & {}

export const RecordsList = React.forwardRef<SuggestionComponentHandle, ListProps>(
    ({ query, editor, range }, ref) => {
        const { flags } = useLDFlags()

        const searchProvider = useGlobalSearchProvider({
            blockLinkClick: true,
            allowedItemTypes: ['record'],
        })
        const recentItemsProvider = useRecentItemsSearchProvider({ blockLinkClick: true })

        const providers = [
            ...(flags.search ? [searchProvider] : []),
            ...(flags.recentItems ? [recentItemsProvider] : []),
        ]

        const onItemSelected = useCallback(
            (item) => {
                insertRecordLink(editor, range, item)
            },
            [editor, range]
        )
        const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
            isOpen: true,
            inputValue: query,
            onItemSelected,
            providers: providers,
            itemToString: () => '',
            debounceDelay: 10,
        })

        const { getInputProps } = comboboxState

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }) => {
                getInputProps(undefined, { suppressRefError: true }).onKeyDown(event)
                return event.defaultPrevented
            },
        }))

        const { isLoading, collections, showMore, items } = itemsState

        return (
            <ComboboxContext.Provider value={comboboxState}>
                <Container
                    {...comboboxState.getMenuProps(undefined, { suppressRefError: true })}
                    maxWidth="100vw"
                    maxHeight="66vh"
                >
                    {!query && (
                        <Box flex center px="l" py="l" color="textWeakest">
                            <Icon name="Search" mr="m" /> Start typing to search for records...
                        </Box>
                    )}
                    {query && !isLoading && !items.length && (
                        <Box py="l" px="xl">
                            No results found
                        </Box>
                    )}
                    {!query && items.length > 0 && <Divider mb="xs" />}
                    <ComboboxList
                        collections={collections}
                        queryTerms={queryTerms}
                        showMore={showMore}
                        isLoading={isLoading}
                    />

                    {isLoading && (
                        <Box py="l" px="xl" flex center color="textWeakest">
                            <LoadingIndicator />
                        </Box>
                    )}
                </Container>
            </ComboboxContext.Provider>
        )
    }
)

import React from 'react'

import { BoxProps } from '@chakra-ui/react'

/**
 * Provides a wrapper for a layout block which can be edited.
 * Handles showing the dashed edit border, can be extended to show
 * edit toolbar, etc.
 *
 **/

export type EditableBlockWrapperProps = Omit<BoxProps, 'children'> & {
    highlight?: boolean
    onHoverStart?: () => void
    onHoverEnd?: () => void
    children?: React.ReactNode | ((args: { isHovered?: boolean }) => React.ReactNode)
}
export const EditableBlockWrapper: React.FC<EditableBlockWrapperProps> = ({ children }) => {
    return <>{typeof children === 'function' ? children({}) : children}</>
}

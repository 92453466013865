import React from 'react'

import { Box } from 'ui/components/Box'
import { theme } from 'ui/styling/Theme.css'

import { Attribute } from './attributes/Attribute'
import { useBoardViewSubtitleState } from './hooks/useBoardViewSubtitleState'

type BoardViewSubtitleProps = {
    record: RecordDto
    isLoading?: boolean
}

export const BoardViewSubtitle: React.FC<BoardViewSubtitleProps> = ({ record, isLoading }) => {
    const { attribute, value } = useBoardViewSubtitleState({ record })

    if (!attribute) return null

    return (
        <Box>
            <Attribute
                attribute={attribute}
                isLoading={isLoading}
                displayStyle="list"
                value={value}
                style={{
                    color: theme.color.textWeak,
                }}
            />
        </Box>
    )
}

// @ts-strict-ignore
import React, { useCallback, useMemo, useState } from 'react'

import { FlexProps } from '@chakra-ui/react'
import { orderBy, trim } from 'lodash'

import { useAppContext } from 'app/useAppContext'
import { workspaceGroups } from 'data/hooks/accounts'
import { Divider } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box, Divider as HeaderDivider, Flex, ScrollBox, SearchInput, Text } from 'v2/ui'
import { OldVirtualizedList } from 'v2/ui/components/OldVirtualizedList'

import GroupListItem from './GroupListItem'
import { GroupListItemType } from './groupListItemTypes'
import UserListItem from './UserListItem'

type GroupComponentType = React.ElementType<{
    group: GroupListItemType
    key: string
    visible: boolean
    filterUser: (user: any) => boolean
    hasSearchValue: boolean
}>
type UserComponentType = React.ElementType<{
    user: any
    key: string
    visible: boolean
}>

type UserListProps = {
    inEditMode?: boolean
    users: any[]
    grants: AccessGrant[]
    showHeader?: boolean
    roleOptions?: any[]
    UserComponent?: UserComponentType
    GroupComponent?: GroupComponentType
    containerProps?: FlexProps
    disableScrollBox?: boolean
}

export default function UserList(props: UserListProps) {
    const { selectedStack } = useAppContext()
    const { users, grants, showHeader } = props
    const [filterValue, setFilterValue] = useState('')
    const sharingSettings = selectedStack?.sharing
    const handleFilterChange = useCallback((value) => {
        setFilterValue(trim(value))
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterUser = (user) => {
        if (filterValue) {
            const searchValue = filterValue.toLowerCase()
            const terms = [user.name?.toLowerCase(), user.email?.toLowerCase()]
            return Boolean(terms.find((x) => x?.includes(searchValue)))
        }

        return true
    }

    const [tableGroups, userGroups, ungroupedUsers] = useMemo<
        [GroupListItemType[], GroupListItemType[], UserDto[]]
    >(() => {
        const sortedUsers = orderBy(users, (user) =>
            (user.name?.toString() || user.email).toLowerCase()
        )

        const ungroupedUsers: any[] = []
        const userGroups: GroupListItemType[] = workspaceGroups
            .map((group) => ({
                ...group,
                users: [],
                role: sharingSettings?.groups?.[group._sid] ?? '',
            }))
            .filter((group) => group.role)

        const tableGroups = grants
            .filter((grant) => grant.type === 'table')
            .map(
                (grant) =>
                    ({
                        ...grant,
                        name: grant.group_name,
                        users: [],
                        role: grant.role_api_name,
                    }) as GroupListItemType
            )

        sortedUsers.forEach((user) => {
            const group = [...tableGroups, ...userGroups].find(
                ({ _sid }) => _sid === user.group?._sid || _sid === user.group?.name
            )
            if (group) {
                group.users.push(user)
            } else {
                ungroupedUsers.push(user)
            }
            return [...tableGroups, ...userGroups]
        })

        return [tableGroups, userGroups, ungroupedUsers]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, workspaceGroups, sharingSettings])

    const GroupComponent: GroupComponentType = props.GroupComponent || GroupListItem
    const UserComponent: UserComponentType = props.UserComponent || UserListItem

    const sortRowItems = (
        items: GroupListItemType[] | UserDto[],
        isGrouped: boolean
    ): GroupListItemType[] | UserDto[] => {
        return items.sort((a, b) => {
            const aRole = a.role
            const bRole = b.role

            if (aRole === 'internal_admin' && bRole !== 'internal_admin') return -1
            if (bRole === 'internal_admin' && aRole !== 'internal_admin') return 1

            const aName = (
                isGrouped ? a.name?.toString() : a.name?.toString() || a.email
            ).toLowerCase()
            const bName = (
                isGrouped ? b.name?.toString() : b.name?.toString() || a.email
            ).toLowerCase()

            return aName.localeCompare(bName)
        })
    }
    // Get the list of rows to render, groups + ungrouped users.
    const rows = useMemo(() => {
        const groupedItems =
            [...tableGroups, ...userGroups].filter(
                (group) => !filterValue || group.users.find(filterUser)
            ) ?? []
        const ungroupedItems: UserDto[] = ungroupedUsers.filter(filterUser)

        return sortRowItems(groupedItems, true).concat(sortRowItems(ungroupedItems, false))
    }, [tableGroups, userGroups, ungroupedUsers, filterUser, filterValue])

    // Render method for rendering a given row
    const renderRow = useCallback(
        ({ item }) => {
            if (item.users) {
                return (
                    <div key={item._sid}>
                        <GroupComponent
                            group={item}
                            key={item._sid}
                            visible
                            filterUser={filterUser}
                            hasSearchValue={!!filterValue}
                        />
                        <Divider noMargin />
                    </div>
                )
            } else {
                return (
                    <div key={item._sid}>
                        <UserComponent user={item} key={item._sid} visible />
                        <Divider noMargin />
                    </div>
                )
            }
        },
        [filterValue, filterUser, GroupComponent, UserComponent]
    )

    return (
        <Flex
            column
            border="1px solid"
            borderColor="gray.200"
            borderRadius="5px"
            flexGrow={1}
            flexShrink={1}
            minHeight={0}
            wrap="nowrap"
            align="stretch"
            {...props.containerProps}
        >
            <SearchInput
                // @ts-ignore
                width="100%"
                // @ts-ignore
                variant="noBorder"
                // @ts-ignore
                borderBottom="1px solid"
                // @ts-ignore
                borderColor="gray.100"
                // @ts-ignore
                value={filterValue}
                // @ts-ignore
                onChange={handleFilterChange}
            />
            {/* @ts-ignore */}
            <ScrollBox
                id="inner"
                maxHeight={!props.disableScrollBox ? '100%' : undefined}
                flexGrow={1}
                overflowY={!props.disableScrollBox ? 'auto' : 'unset'}
                overflowX={!props.disableScrollBox ? 'hidden' : 'unset'}
            >
                {showHeader && <UserListHeader />}

                <OldVirtualizedList
                    estimatedItemSize={33}
                    items={rows}
                    // The virtualized list seems to sometimes glitch when the number of items
                    // changes, so we're just recreating completely in that case
                    key={rows.length}
                    renderItem={renderRow}
                />
            </ScrollBox>
        </Flex>
    )
}

const UserListHeader = ({}) => {
    return (
        <Box position="sticky" top={0} bg="white" zIndex={10}>
            <Flex wrap="nowrap" my={2} fontWeight="bold" fontSize="sm" px={2}>
                <Text color="neutral.800" style={{ width: '50%', flexShrink: 0 }}>
                    Name / Email
                </Text>
                <div style={{ flexGrow: 1 }}></div>

                <div style={{ width: '150px' }}></div>
                <Text color="neutral.800" style={{ width: '150px' }}>
                    Role
                </Text>
            </Flex>
            <HeaderDivider />
        </Box>
    )
}

import React, { memo } from 'react'

import { CreateView } from 'v2/views/Create/CreateView'

// Added the key here as we have a bug which causes create field values from being successfully reset.
// This means that if you are on a create view with data entered, and you click to Edit Layout, and
// you respond with Save Changes to the save changes modal, the changes are saved, but the form is not
// reset and thus you think the record didn't get created.
const CreateViewWrapper = memo((props) => <CreateView key={props.showControls} {...props} />)

export default CreateViewWrapper

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeCheckboxVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        size: {
            indicator: pxToRem(16),
        },
        radius: s.radius.checkbox,
        borderWidth: s.borderWidths[1],
        borderStyle: s.borderStyles.base,
        padding: s.space.xs,
        color: {
            surfaceUnchecked: s.color.surfaceSelectorOff,
            surfaceUncheckedDisabled: s.color.surfaceSelectorOffDisabled,
            surfaceChecked: s.color.surfaceSelectorOn,
            surfaceCheckedDisabled: s.color.surfaceSelectorOnDisabled,
            surfaceCheckedError: s.color.surfaceSelectorOnError,
            surfaceCheckedHover: s.color.surfaceSelectorOnHover,
            borderUnchecked: s.color.borderSelectorOff,
            borderUncheckedError: s.color.borderSelectorOffError,
            borderUncheckedHover: s.color.borderSelectorOffHover,
            borderChecked: s.color.borderSelectorOn,
            borderCheckedError: s.color.borderSelectorOnError,
            borderCheckedHover: s.color.borderSelectorOnHover,
            iconChecked: s.color.iconSelector,
            iconUncheckedHover: s.color.iconSelectorHover,
        },
        shadows: {
            focus: s.shadows.focus,
        },
        gap: {
            label: s.space.m,
        },
    }
}

import { COLORS_ORDER } from 'features/charts/recharts/Pie/constants'

import { ThemeConfig } from 'v2/ui/theme/styles/types'
import { AppColors } from 'v2/ui/theme/styles/types/colorBase'

export const getColoredCategories = (
    categories: GroupCategories | undefined,
    groupByField: FieldDto | undefined,
    theme: ThemeConfig
) => {
    if (!categories) {
        return []
    }

    return Object.entries(categories).map(([category], index) => {
        const color = getCategoryColor(theme, groupByField, category, index)
        return { category, color }
    })
}

function getCategoryColor(
    theme: ThemeConfig,
    groupByField: FieldDto | undefined,
    category: string,
    index: number
) {
    if (groupByField?.type !== 'dropdown' && groupByField?.type !== 'multi_select') {
        return getAppColor(theme, COLORS_ORDER[index % COLORS_ORDER.length] as keyof AppColors)
    }

    // get colors from dropdown meta
    const fieldOption = groupByField.options?.options?.find(({ value }) => value === category)

    return getAppColor(
        theme,
        (fieldOption?.color ?? COLORS_ORDER[index % COLORS_ORDER.length]) as keyof AppColors
    )
}

function getAppColor(theme: ThemeConfig, colorName: keyof AppColors) {
    return theme.colors.appColors[colorName][500]
}

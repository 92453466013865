import React, { useCallback, useState } from 'react'

import styled from '@emotion/styled'

import { useAppContext } from 'app/useAppContext'
import { useStackRoles } from 'data/hooks/roles'
import { sortFieldsByOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { getFilterFieldFromId } from 'features/utils/filterToField'

import { Button, Dropdown } from 'v2/ui'

import { getFilterSourceOptions } from './utils/utils'
import { nextFilterOptions } from './constants'
import { FieldFilter } from './FieldFilter'

export function ObjectFieldsFilter({
    fields,
    getShouldShowField,
    hideCurrentUserOption,
    hideTheRecordFilter,
    object,
    onChange,
    showRelativeDateFilters,
    showRoleFilter,
    value,
}) {
    const [filters, setFilters] = useState(value ?? [])
    const [isAdding, setIsAdding] = useState(!value)
    const { selectedStack } = useAppContext()
    const { data: roles } = useStackRoles()
    const userRoleField = selectedStack?.options?.data_mapping?.role_field
    const userObjectId = selectedStack?.options?.data_mapping?.user_object

    const select = useCallback(
        (id) => {
            const field = getFilterFieldFromId(object, id)

            setIsAdding(false)
            setFilters([
                ...filters,
                { field, field_sid: field?._sid, options: { operator: 'AND' }, _id: Math.random() },
            ])
        },
        [filters, object]
    )

    const onChangeField = useCallback(
        ({ id, options }) => {
            const { option, value } = options
            const requiresValue = option && nextFilterOptions[option]

            setIsAdding(false)
            const newFilters = filters.map((filter) => {
                if (filter._id !== id) return filter

                return {
                    ...filter,
                    options,
                }
            })
            setFilters(newFilters)

            // only add this filter if it is complete, otherwise clear it
            if (option && ((requiresValue && value) || !requiresValue)) {
                onChange(newFilters)
            } else {
                onChange(newFilters.filter((filter) => filter._id !== id))
            }
        },
        [filters, onChange]
    )

    const deleteFilter = useCallback(
        (id) => {
            const newFilters = filters.filter((filter) => filter._id !== id)
            setFilters(newFilters)
            onChange(newFilters)
            if (newFilters.length === 0) {
                setIsAdding(true)
            }
        },
        [filters, onChange]
    )

    if (!fields) {
        return null
    }
    fields = fields.filter(getShouldShowField ?? (() => true))

    const options = getFilterSourceOptions(sortFieldsByOrder(fields, object), {
        hideTheRecordFilter,
        showRoleFilter: showRoleFilter && userRoleField,
    })

    return (
        <>
            {filters.map((filter, index) => {
                const { field: currentField } = filter
                const fieldSid =
                    currentField?.api_name === '_sid'
                        ? '_sid'
                        : currentField?.type === 'user_role'
                          ? 'user_role'
                          : currentField?._sid
                // the saved filter.field could be outdated, so we get the up to date field using
                // the field sid
                const field = getFilterFieldFromId(object, fieldSid)
                return (
                    <FieldFilter
                        key={filter._sid}
                        object={object}
                        roles={roles}
                        field={field}
                        filterOptions={filter.options}
                        showRelativeDateFilters={showRelativeDateFilters ?? false}
                        hideCurrentUserOption={hideCurrentUserOption}
                        userObjectId={userObjectId}
                        options={options}
                        showLogicalOperator={index > 0}
                        onChange={onChangeField}
                        id={filter._id}
                        delete={() => deleteFilter(filter._id)}
                    />
                )
            })}
            {isAdding ? (
                <div style={{ marginTop: 10 }}>
                    <Dropdown
                        options={options}
                        style={{ flex: 1 }}
                        onChange={(value) => select(value)}
                    />
                </div>
            ) : (
                <div>
                    <ConditionButton
                        onClick={() => setIsAdding(true)}
                        variant="adminPrimary"
                        buttonSize="sm"
                        hasFilters={!!filters.length}
                    >
                        Add Condition
                    </ConditionButton>
                </div>
            )}
        </>
    )
}

const SMALL_FILTER_BREAKPOINT = '750px'

const ConditionButton = styled(Button)`
    margin-top: ${(props) => (!props.hasFilters ? '10px' : '0.5rem')};
    @media only screen and (min-width: ${SMALL_FILTER_BREAKPOINT}) {
        margin-top: ${(props) => (!props.hasFilters ? '10px' : '1.5rem')};
    }
`

import React from 'react'

import { DesktopNotificationService } from 'features/Notifications/DesktopNotificationService'
import { NotificationList } from 'features/Notifications/NotificationPane'

import { Tabs, TabsContent, TabsList, TabsTrigger } from 'ui/components/Tabs'

import { useSystemNavigationItemNotificationsState } from './hooks/useSystemNavigationItemNotificationsState'
import { SystemNavigationItem } from './SystemNavigationItem'
import { SystemNavigationPopupItem } from './SystemNavigationPopupItem'

import { SystemNavigationNotificationsPopupStyle } from './Navigation.css'

type SystemNavigationItemNotificationsProps = React.ComponentPropsWithoutRef<
    typeof SystemNavigationItem
> & {}

export const SystemNavigationItemNotifications: React.FC<SystemNavigationItemNotificationsProps> = (
    props
) => {
    const { count, isUnread } = useSystemNavigationItemNotificationsState()

    return (
        <SystemNavigationPopupItem
            iconName="notification-03"
            label="Notifications"
            count={count}
            isUnread={isUnread}
            aria-label="Notifications"
            contentProps={{
                className: SystemNavigationNotificationsPopupStyle,
            }}
            {...props}
        >
            <DesktopNotificationService />
            <Tabs flex column maxHeight="full" type="underlined">
                <TabsList noShrink width="full">
                    <TabsTrigger value="inbox" label="Inbox" />
                    <TabsTrigger value="archive" label="Archived" />
                </TabsList>

                <TabsContent value="inbox" grow shrink flex column>
                    <NotificationList isArchived={false} />
                </TabsContent>
                <TabsContent value="archive" grow shrink flex column>
                    <NotificationList isArchived />
                </TabsContent>
            </Tabs>
        </SystemNavigationPopupItem>
    )
}

import React, { memo } from 'react'

import styled from '@emotion/styled'

import { useObjects } from 'data/hooks/objects'
import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'

import Avatar from 'v2/ui/components/Avatar'
import { layouts as layoutSettings } from 'v2/ui/utils/attributeSettings'

import { DisplayTypes } from './attributeDisplayOptions'
import { AttributeValueWrapper } from './AttributeValueWrapper'
import { DisplayText } from './DisplayText'
import { getLinkComponentFromUrl } from './link_utils'

type UserRefAttributeProps = {
    children: React.ReactChildren
    layout: keyof typeof layoutSettings
    displayType: DisplayTypes
}

export const UserRefAttribute = memo(
    ({ children, layout, displayType }: UserRefAttributeProps): React.ReactElement => {
        const { data: objects = [] } = useObjects()

        const value: UserRefDto | undefined = children as any

        const isByStackerSystem = !!value && value.source === 'stacker'

        if (!value || isByStackerSystem) {
            return (
                <AttributeValueWrapper displayType={displayType} isEmpty={!value}>
                    <DisplayText>{value ? value.name : '-'}</DisplayText>
                </AttributeValueWrapper>
            )
        }

        const object = objects.find((object) => getIsStackerUserObject(object))

        const objectUrl = object?.url
        const url = `${objectUrl}/view/${value.sid}`

        const extraProps =
            displayType === 'pill'
                ? {
                      my: -1,
                      ml: -2,
                      maxHeight: '1.3rem',
                      maxWidth: '1.3rem',
                  }
                : null
        return (
            <AttributeValueWrapper displayType={displayType}>
                <Wrapper>
                    {value.avatar && (
                        <Avatar
                            src={value.avatar}
                            name={value.name}
                            size="xs"
                            mr={1}
                            {...extraProps}

                            // mr={showName ? 2 : 0}
                        />
                    )}
                    {getLinkComponentFromUrl({ display: value.name, url, layout })}
                </Wrapper>
            </AttributeValueWrapper>
        )
    }
)

const Wrapper = styled.div`
    display: flex;
    max-width: 100%;
    align-items: center;
`

import { FailedCommit, FailedCommitReasons } from './hooks/useRecordEditManager'

export function getErrorMessage(failedRecords: { [keyof: string]: FailedCommit }): string {
    const reasonsCounter: { [key in FailedCommitReasons]: number } = Object.values(
        failedRecords
    ).reduce(
        ({ isDelete, isUpdate, isCreate }, failedCommit) => ({
            isDelete: isDelete + (failedCommit.isDelete ? 1 : 0),
            isUpdate: isUpdate + (failedCommit.isUpdate ? 1 : 0),
            isCreate: isCreate + (failedCommit.isCreate ? 1 : 0),
        }),
        { isDelete: 0, isUpdate: 0, isCreate: 0 }
    )

    if (reasonsCounter['isCreate'] > 0) {
        return "Sorry, your records couldn't be created."
    }
    if (reasonsCounter['isUpdate'] > 0) {
        return "Sorry, some of your changes couldn't be saved."
    }
    if (reasonsCounter['isDelete'] > 0) {
        return "Sorry, some of your records couldn't be deleted."
    }
    return "Sorry, some of your changes couldn't be saved."
}

import React from 'react'

import { unpublishSharedTable } from 'data/hooks/objects/objectOperations'
import { useSharedObjectUsage } from 'data/hooks/sharedObjects'
import { StackIconBadge } from 'features/core/StackIconBadge'
import { Divider, ExpandSection } from 'legacy/v1/ui'

import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { Body } from 'ui/components/Text'

import { UnShareTableButton } from './UnshareTableButton'

type SharedOriginalSettingsProps = {
    object: ObjectDto
    openErrorToast: (operation?: 'update' | 'delete' | 'unshare') => void
}

export const SharedOriginalSettings: React.FC<SharedOriginalSettingsProps> = ({
    object,
    openErrorToast,
}) => {
    const { data } = useSharedObjectUsage(object._sid)

    const stacks = data?.stacks ?? []

    return (
        <>
            <Divider />
            {/*@ts-expect-error*/}
            <ExpandSection
                heading="Sharing settings"
                text="Manage how this table is shared within the workspace."
                helpUrl=""
                helpText=""
                classes={{
                    heading:
                        ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM.DELETE_OBJECT,
                }}
            >
                <Body size="m" weight="regular" style={{ marginTop: '20px', marginBottom: '10px' }}>
                    This table is used in the following apps:
                </Body>
                <Container border p="l" variant="base" style={{ marginBottom: '15px' }}>
                    <StackSettingsContent
                        stacks={stacks ?? []}
                        count={data?.count ?? stacks.length}
                    />
                </Container>
                <UnShareTableButton
                    object={object}
                    onUnshareObject={async (sid: string) => {
                        unpublishSharedTable(
                            sid,
                            () => undefined,
                            () => openErrorToast('unshare')
                        )
                    }}
                    disabled={stacks.length > 0}
                />
            </ExpandSection>
        </>
    )
}

const StackSettingsContent = ({ stacks, count }: { stacks: StackDto[]; count: number }) => {
    if (count === 0) {
        return (
            <Body size="m" color="textWeaker">
                None
            </Body>
        )
    }

    const hiddenCount = count - stacks.length

    return (
        <>
            {stacks.map((stack: StackDto) => (
                <StackRow key={stack.id} stack={stack} />
            ))}
            {hiddenCount > 0 && (
                <Box style={{ marginTop: '15px' }}>
                    <Body size="m" color="textWeaker">
                        {hiddenCount} Private {hiddenCount === 1 ? 'app' : 'apps'}
                    </Body>
                </Box>
            )}
        </>
    )
}

const StackRow = ({ stack, ...props }: { stack: StackDto }) => {
    return (
        <Box flex alignItems="center" {...props}>
            <StackIconBadge stack={stack} size={18} mr="l" />
            <Body size="m">{stack.name}</Body>
        </Box>
    )
}

import { createContext, useContext } from 'react'

type CardViewPaginationContextValue = {
    columns: number | undefined
    setContainerRef: (ref: HTMLDivElement | undefined) => void
}
const defaultContext: CardViewPaginationContextValue = {
    columns: undefined,
    setContainerRef: () => {},
}
export const CardViewPaginationContext =
    createContext<CardViewPaginationContextValue>(defaultContext)
export const useCardViewPaginationContext = (): CardViewPaginationContextValue => {
    return useContext(CardViewPaginationContext)
}

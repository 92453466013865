import React from 'react'

import { useAppContext } from 'app/useAppContext'
import { FollowingStatus, useFollowingStatus } from 'data/hooks/activities/following'
import { RelatedToType } from 'data/hooks/activityTypes'

import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Icon } from 'ui/components/Icon'
import { StandardComponentProps } from 'ui/helpers/styles'

import { checkActivityIsReadOnly } from './utils'

type FeedControlsProps = StandardComponentProps & {
    activityId?: number
    relatedTo?: string
    relatedToType?: RelatedToType
    relatedToLocation?: string
    objectId?: string
    stackId?: string
}
export function FeedControls({
    stackId,
    activityId,
    relatedTo,
    relatedToType,
    relatedToLocation,
    objectId,
    ...props
}: FeedControlsProps) {
    const { selectedStack } = useAppContext()
    const { data: status, updateStatus } = useFollowingStatus({
        stackId: stackId || selectedStack?._sid,
        activityId,
        relatedTo,
        relatedToType,
        relatedToLocation,
        objectId,
    })

    if (!status) return null

    const isFollowing = status.status === FollowingStatus.Notify

    const toggleFollowing = () => {
        if (checkActivityIsReadOnly()) return
        updateStatus(
            status?.status === FollowingStatus.Notify
                ? FollowingStatus.NotFollowing
                : FollowingStatus.Notify
        )
    }

    return (
        <Container variant="transparent" flex stretch {...props}>
            <Button
                variant={isFollowing ? 'secondary' : 'ghost'}
                size="xs"
                onClick={toggleFollowing}
                type="button"
            >
                <Icon
                    name="Bell"
                    size="xs"
                    style={{
                        fill: isFollowing ? 'currentColor' : 'transparent',
                    }}
                />
                {isFollowing ? 'Following' : 'Follow'}
            </Button>
        </Container>
    )
}

import React, { useState } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { isSupportLogin } from 'features/users/shared/utils'
import { DEFAULT_ACTION_ICON } from 'features/workflows/common'
import { ItemIcon } from 'features/workflows/ItemIcon'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Icon } from 'ui/components/Icon'

import { ExecutionHistoryJSONPreview } from './ExecutionHistoryJSONPreview'
import { ExecutionHistoryStatusLabel } from './ExecutionHistoryStatusLabel'
import { formatExecutionDuration, prettyPrintExecutionErrorTrace } from './utils'

type ExecutionHistoryNodeListProps = React.ComponentPropsWithoutRef<typeof Box> & {
    execution: WorkflowExecutionDto
}

export const ExecutionHistoryNodeList: React.FC<ExecutionHistoryNodeListProps> = ({
    execution,
    props,
}) => {
    return (
        <Box borderRadius="s" background="surface" {...props}>
            {execution.execution_data?.chain.map((node, idx, list) => {
                const result = execution.result_data.node_execution_results.find(
                    (result) => result.node.id === node.id
                )

                const isLast = idx === list.length - 1

                return (
                    <ExecutionHistoryNodeListItem
                        key={node.id}
                        node={node}
                        result={result}
                        width="full"
                        isExecutingNow={execution.execution_data?.current_node_id === node.id}
                        hasFlowError={Boolean(execution.result_data.error)}
                        borderColor="borderWeak"
                        borderStyle="base"
                        borderBottomWidth={isLast ? 0 : 1}
                        execution={execution}
                    />
                )
            })}
        </Box>
    )
}

type ExecutionHistoryNodeListItemProps = React.ComponentPropsWithoutRef<typeof Collapsible> & {
    node: WorkflowNode
    result?: WorkflowNodeExecutionResult
    isExecutingNow?: boolean
    hasFlowError?: boolean
    execution: WorkflowExecutionDto
}

const ExecutionHistoryNodeListItem: React.FC<ExecutionHistoryNodeListItemProps> = ({
    node,
    result,
    isExecutingNow,
    hasFlowError,
    execution,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const { data: schema } = useWorkflowSchema()
    const nodeType = schema?.nodes.find((t) => {
        switch (node.kind) {
            case 'action':
                return t.id === node.action_type
            case 'loop':
                return t.id === node.kind
            default:
                return false
        }
    })

    const duration = result ? formatExecutionDuration(result.execution_time_ms) : null

    let status = result?.status
    if (!status && hasFlowError) status = undefined
    if (!status && !hasFlowError && isExecutingNow) status = 'running'

    return (
        <Collapsible open={isOpen} onOpenChange={setIsOpen} disabled={!result} {...props}>
            <CollapsibleTrigger asChild>
                <Box
                    py="m"
                    px="xl"
                    flex
                    center
                    justifyContent="space-between"
                    gap="m"
                    role={result ? 'button' : undefined}
                    tabIndex={result ? 0 : -1}
                    fontSize="bodyM"
                >
                    <Box flex center gap="m">
                        {result && <Icon name={isOpen ? 'ChevronDown' : 'ChevronRight'} />}
                        <ItemIcon
                            icon={nodeType?.icon || DEFAULT_ACTION_ICON}
                            iconStyle={nodeType?.icon_style}
                            iconUrl={(node as WorkflowActionConfig).icon?.url}
                        />
                        {(node as WorkflowActionConfig).name ?? nodeType?.name}
                    </Box>
                    <Box flex center gap="m">
                        {duration && (
                            <Box color="textWeaker" fontSize="bodyS">
                                {duration}
                            </Box>
                        )}
                        <ExecutionHistoryStatusLabel fontSize="bodyS" status={status} />
                    </Box>
                </Box>
            </CollapsibleTrigger>
            <CollapsibleContent>
                {result && (
                    <ExecutionHistoryNodeListItemResult result={result} execution={execution} />
                )}
            </CollapsibleContent>
        </Collapsible>
    )
}

type ExecutionHistoryNodeListItemResultProps = React.ComponentPropsWithoutRef<typeof Box> & {
    result: WorkflowNodeExecutionResult
    execution: WorkflowExecutionDto
}

const ExecutionHistoryNodeListItemResult: React.FC<ExecutionHistoryNodeListItemResultProps> = ({
    result,
    execution,
    ...props
}) => {
    const error = result.result_data.error

    return (
        <Box pt="m" p="xl" flex column gap="xl" fontSize="bodyM" {...props}>
            {error && <ExecutionHistoryNodeError error={error} />}
            {(result.node as WorkflowActionConfig).inputs && (
                <Box>
                    <Box as="h5" fontSize="bodyM" fontWeight="bodyBold">
                        Inputs
                    </Box>
                    <ExecutionHistoryJSONPreview
                        value={(result.node as WorkflowActionConfig).inputs}
                    />
                </Box>
            )}
            {(result.node as WorkflowLoopConfig).iterable_value && (
                <Box>
                    <Box as="h5" fontSize="bodyM" fontWeight="bodyBold">
                        List
                    </Box>
                    <ExecutionHistoryJSONPreview
                        value={(result.node as WorkflowLoopConfig).iterable_value}
                    />
                </Box>
            )}
            {Boolean(result.child_node_executions?.length) && result.node.kind === 'loop' && (
                <Box>
                    <Box as="h5" fontSize="bodyM" fontWeight="bodyBold">
                        Loop steps
                    </Box>
                    {result.child_node_executions!.map((childExecution, idx, list) => {
                        const node = childExecution.node
                        const isLast = idx === list.length - 1

                        return (
                            <ExecutionHistoryNodeListItem
                                key={node.id}
                                node={node}
                                result={childExecution}
                                width="full"
                                isExecutingNow={
                                    execution.execution_data?.current_node_id === node.id
                                }
                                hasFlowError={Boolean(execution.result_data.error)}
                                borderColor="borderWeak"
                                borderStyle="base"
                                borderBottomWidth={isLast ? 0 : 1}
                                execution={execution}
                            />
                        )
                    })}
                </Box>
            )}
        </Box>
    )
}

type ExecutionHistoryNodeErrorProps = React.ComponentPropsWithoutRef<typeof Collapsible> & {
    error: WorkflowExecutionError
}

const ExecutionHistoryNodeError: React.FC<ExecutionHistoryNodeErrorProps> = ({
    error,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const errorMessage = error.message
    const trace = prettyPrintExecutionErrorTrace(error.trace)

    const supportsShowDetails = isSupportLogin()

    return (
        <Collapsible open={isOpen} onOpenChange={setIsOpen} {...props}>
            <Box flex center gap="xs" mb="xs">
                <Box as="h5" fontSize="bodyM" fontWeight="bodyBold">
                    Error
                </Box>
                {supportsShowDetails && (
                    <CollapsibleTrigger asChild>
                        <Button size="xs" variant="ghost" color="textWeak">
                            {isOpen ? 'hide details' : 'show details'}
                        </Button>
                    </CollapsibleTrigger>
                )}
            </Box>
            <Box color="error600">{errorMessage}</Box>
            <CollapsibleContent>
                <Box fontSize="bodyS" whiteSpace="pre-wrap" mt="xs">
                    {trace}
                </Box>
            </CollapsibleContent>
        </Collapsible>
    )
}

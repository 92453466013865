import React, { useMemo } from 'react'

import { ViewLayoutContext } from 'v2/blocks/types'
import ListViewAdditionalActions from 'v2/views/List/ListViewAdditionalActions'
import ListViewControls from 'v2/views/List/ListViewControls'

import { useConfirmDeleteViewModal } from 'features/views/useConfirmDeleteViewModal'
import { useDuplicateViewModal } from 'features/views/useDuplicateViewModal'
import { ViewEditPane } from 'features/views/ViewEditPane'
import { ViewHeaderModularEditor } from 'features/views/ViewHeader/ViewHeaderModular/editors/ViewHeaderModularEditor'

import { getDefaultPageSize, getPageSizeOptions } from 'v2/ui/components/List/utils'

import { ViewState } from './viewStateType'

type ListViewEditPaneProps = {
    viewLayoutContext?: ViewLayoutContext
    saveConfig: () => Promise<void>
    setConfig: (patch: Partial<ViewDto>, shouldSave?: boolean) => void
    setViewData: (patch: Partial<ViewDto>) => void
    onDisplayChange?: (display: string) => void
    setAllowRedirect: (allowRedirect: boolean) => void
    setPageRoles: (pageRoles: string[]) => void
    viewState?: ViewState
    isConfigDirty?: boolean
    isRolesDirty?: boolean
    hideSaveButton?: boolean
    showDetailView?: boolean
    showCreate?: boolean
    object?: ObjectDto
    view?: ViewDto
    views?: ViewDto[]
    filteredRecords?: RecordDto[]
    pageRoles?: string[]
    detailView?: ViewDto
    columnConfig?: ListViewColumnConfig[]
    getPageSizeOptions?: (display?: string) => number[]
    getDefaultPageSize?: (display?: string) => number
}

const ListViewEditPane: React.FC<ListViewEditPaneProps> = ({
    viewLayoutContext,
    saveConfig,
    setConfig,
    setViewData,
    onDisplayChange,
    setAllowRedirect,
    setPageRoles,
    viewState,
    isConfigDirty,
    isRolesDirty,
    hideSaveButton,
    showDetailView,
    showCreate,
    object,
    views,
    view,
    filteredRecords,
    pageRoles,
    detailView,
    columnConfig,
    getPageSizeOptions: computePageSizeOptions = getPageSizeOptions,
    getDefaultPageSize: computeDefaultPageSize = getDefaultPageSize,
}) => {
    const showConfirmDeleteModal = useConfirmDeleteViewModal()

    const actions = useMemo(() => {
        // Only enable delete if there are other list views remaining for that object
        if (!views?.length || !view) return []

        const listViews = views.find(
            (v) => v.object_id === view.object_id && v._sid !== view._sid && v.type == 'list'
        )

        if (!listViews) return []

        return [
            {
                action: () => {
                    showConfirmDeleteModal({
                        view,
                        beforeRedirect: () => setAllowRedirect(true),
                    })
                },
                label: 'Delete Layout',
            },
        ]
    }, [setAllowRedirect, showConfirmDeleteModal, view, views])

    const showDuplicateViewModal = useDuplicateViewModal()
    const openDuplicateModal = () => {
        if (!view) return
        showDuplicateViewModal({ view })
    }

    if (!object) return null

    return (
        <ViewEditPane
            viewName={viewState?.view?.name}
            view={viewState?.view}
            saveView={saveConfig}
            setViewData={setViewData}
            isConfigDirty={isConfigDirty || isRolesDirty}
            actions={actions}
            additionalActions={
                <ListViewAdditionalActions openDuplicateModal={openDuplicateModal} />
            }
            hideSaveButton={hideSaveButton}
        >
            <ListViewControls
                onDisplayChange={onDisplayChange}
                object={object}
                columnConfig={columnConfig}
                config={viewState?.view?.options}
                setConfig={setConfig}
                detailView={showDetailView && detailView}
                records={filteredRecords}
                pageRoles={pageRoles}
                setPageRoles={setPageRoles}
                showCreate={showCreate}
                showPageSizeOptions={true}
                getPageSizeOptions={computePageSizeOptions}
                getDefaultPageSize={computeDefaultPageSize}
            />
            <ViewHeaderModularEditor
                viewLayoutContext={viewLayoutContext}
                listOptions={viewState?.view?.options}
                onChange={setConfig}
            />
        </ViewEditPane>
    )
}

export default ListViewEditPane

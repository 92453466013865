import { useMemo, useState } from 'react'

import { NavigationSpace } from 'features/navigation/types'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationAllAppsItemState() {
    const { spaces } = useNavigationContext()

    const [searchQuery, setSearchQuery] = useState('')
    const normalizedQuery = searchQuery.toLowerCase().trim()
    const filteredSpaces = useMemo(() => {
        return filterDropdownItems(spaces, normalizedQuery)
    }, [spaces, normalizedQuery])

    const isEmpty = !normalizedQuery && filteredSpaces.length === 0

    return useMemo(
        () => ({
            searchQuery,
            setSearchQuery,
            spaces: filteredSpaces,
            isEmpty,
        }),
        [searchQuery, filteredSpaces, isEmpty]
    )
}

function filterDropdownItems(spaces: NavigationSpace[], searchQuery: string) {
    return spaces.filter((space) => {
        if (space.apps.length < 1) return false

        return space.apps.some((app) => app.name.toLowerCase().includes(searchQuery))
    })
}

import React from 'react'

import { useListHeaderState } from './hooks/useListHeaderState'
import { ListHeaderDefault } from './ListHeaderDefault/ListHeaderDefault'
import { ListHeaderPortal } from './ListHeaderPortal/ListHeaderPortal'
import { ListHeaderContextProvider } from './ListHeaderContext'

type ListHeaderProps = React.ComponentPropsWithoutRef<typeof ListHeaderContextProvider> & {
    isSticky?: boolean
}

const ListHeaderInner: React.FC<ListHeaderProps> = React.memo(function ListHeaderInner({
    isSticky,
}) {
    const { headerType } = useListHeaderState()

    switch (headerType) {
        case 'default':
            return <ListHeaderDefault isSticky={isSticky} />
        case 'portal':
            return <ListHeaderPortal isSticky={isSticky} />
        default:
            return null
    }
})

export const ListHeader: React.FC<ListHeaderProps> = React.memo(function ListHeader({
    isSticky,
    ...props
}) {
    return (
        <ListHeaderContextProvider {...props}>
            <ListHeaderInner isSticky={isSticky} />
        </ListHeaderContextProvider>
    )
})

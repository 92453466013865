import { theme as chakraTheme } from '@chakra-ui/react'
import get from 'lodash/get'

import icons from 'v2/ui/theme/styles/icons'
import type { ColorConfig, Config } from 'v2/ui/theme/styles/types'
import { darken, lighten } from 'v2/ui/utils/colors'

import themeColors, { colorBaseConfig } from './colors'
import themeFontSizes from './fontSizes'
import themeFontWeights from './fontWeights'
import themeLineHeights from './lineHeights'
import themeRadii from './radii'
import themeShadows from './shadows'
import themeSizes from './sizes'
import themeSpace from './space'

// Base config
const config: Config = {
    widths: {
        sm: '280px',
        md: '480px',
        lg: '880px',
        xl: '1160px',
    },
    fonts: {
        heading:
            'Proxima Nova, apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        body: 'Proxima Nova, apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        button: 'Proxima Nova, apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
    },
    fontSizes: {
        xs: '0.75rem' /** 12px */,
        sm: '0.875rem' /** 14px */,
        smmd: '1rem' /** 16px */,
        md: '1.125rem' /** 18px */,
        mdlg: '1.25rem' /** 20px */,
        lg: '1.5rem' /** 24px */,
        xl: '2rem' /** 32px */,
        xxl: '2.5rem' /** 40px */,
        xxxl: '3rem' /** 48px */,

        // These use the newer font scale
        100: '0.75rem' /** 12px */,
        200: '0.875rem' /** 14px */,
        300: '1rem' /** 16px */,
        400: '1.125rem' /** 18px */,
        500: '1.25rem' /** 20px */,
        600: '1.5rem' /** 24px */,
        650: '1.75rem' /** 28px */,
        700: '2rem' /** 32px */,
        800: '2.5rem' /** 40px */,
        900: '3rem' /** 48px */,
    },
    fontWeights: {
        normal: 400,
        medium: 500,
        bold: 700,
    },
    lineHeights: {
        none: '100%',
        s: '105%',
        m: '115%',
        l: '120%',
        xl: '125%',
        xxl: '130%',
        xxxl: '135%',
        xxxxl: '140%',
    },
    colors: colorBaseConfig,
    iconSizes: {
        xxs: '8px',
        xs: '12px',
        sm: '16px',
        md: '20px',
        lg: '28px',
        xl: '44px',
        xxl: '56px',
        xxxl: '96px',
    },
    buttonSizes: {
        sm: '32px',
        md: '48px',
    },
    imageSizes: {
        xs: '24px',
        sm: '32px',
        md: '40px',
        lg: '50px',
        xlg: '60px',
    },
    weights: {
        regular: 300,
        bold: 600,
    },
}

// Add specific styles here
const theme = (
    stackTheme?: any
): {
    colors: ColorConfig
    [key: string]: any
} => {
    const { fontSizes, widths, colors, weights } = config

    colors.brand = get(stackTheme, 'brandColor') || colors.brand

    const brand = {
        100: lighten(colors.brand, 0.88),
        200: lighten(colors.brand, 0.18),
        300: colors.brand,
        400: darken(colors.brand, 0.18),
        500: darken(colors.brand, 0.4),
    }

    const getBrandAppColors = (color: string) => {
        const { appColors } = colorBaseConfig

        // Find the theme colour scheme that matches the brand colour
        const currentTheme = Object.values(appColors).find(
            (colorTheme) => colorTheme[500] === color
        )

        if (currentTheme) return currentTheme

        // Fallback if using a non-standard theme colour
        return {
            100: lighten(colors.brand, 0.8),
            200: lighten(colors.brand, 0.6),
            300: lighten(colors.brand, 0.4),
            400: lighten(colors.brand, 0.2),
            500: colors.brand,
            600: darken(colors.brand, 0.2),
            700: darken(colors.brand, 0.4),
            800: darken(colors.brand, 0.6),
            900: darken(colors.brand, 0.8),
            1000: darken(colors.brand, 0.9),
        }
    }

    const brandAppColors = getBrandAppColors(colors.brand)

    const allConfig = {
        ...config,
        brand,
        brandAppColors,
        chakraTheme,
    }

    type BreakpointsProp = Array<string> & {
        sm?: string
        md?: string
        lg?: string
        xl?: string
    }

    const breakpoints: BreakpointsProp = [widths.sm, widths.md, widths.lg, widths.xl]
    breakpoints.sm = widths.sm
    breakpoints.md = widths.md
    breakpoints.lg = widths.lg
    breakpoints.xl = widths.xl

    return {
        ...chakraTheme,
        ...config,
        breakpoints,
        fontSizes: {
            ...fontSizes,
            ...themeFontSizes(allConfig),
        },
        colors: {
            ...chakraTheme.colors,
            ...colors,
            ...themeColors(allConfig),
        },
        radii: {
            ...chakraTheme.radii,
            ...themeRadii(allConfig),
        },
        shadows: {
            ...chakraTheme.shadows,
            ...themeShadows(allConfig),
        },
        stackerIcons: icons,
        sizes: {
            ...chakraTheme.sizes,
            ...themeSizes(allConfig),
        },
        space: {
            ...chakraTheme.space,
            ...themeSpace(allConfig),
        },
        fontWeights: {
            ...weights,
            ...themeFontWeights(allConfig),
        },
        lineHeights: {
            ...themeLineHeights(allConfig),
        },
    }
}

export const colors = theme().colors

export default theme

import React, { useCallback, useMemo, useState } from 'react'

import { useListViewContext } from 'features/views/ListView/useListViewContext'

import { SearchContext } from './useSearchContext'

export type SearchContextProviderProps = {}

export const SearchContextProvider: React.FC<SearchContextProviderProps> = ({ children }) => {
    const [query, setQuery] = useState('')

    const handleSetQuery = useCallback((query: string) => {
        const newValue = query.trim().toLowerCase()

        setQuery(newValue)
    }, [])

    const { allowSearch } = useListViewContext()

    const value = useMemo(
        () => ({
            query,
            setQuery: handleSetQuery,
            allowSearch,
        }),
        [query, handleSetQuery, allowSearch]
    )

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
}

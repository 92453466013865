import React from 'react'

export type WorkflowManagerPane = 'list' | 'integrations' | 'editorCanvas' | 'editorRunHistory'
export type WorkflowManagerBreadcrumb = {
    pane: WorkflowManagerPane
}
export type WorkflowManagerContextValue = {
    currentPane: WorkflowManagerPane
    breadcrumbs: WorkflowManagerBreadcrumb[]
    navigateToPane: (pane: WorkflowManagerPane) => void
    navigateBack: () => void
}
export const WorkflowManagerContext = React.createContext<WorkflowManagerContextValue>(
    {} as WorkflowManagerContextValue
)

export function useWorkflowManagerContext() {
    return React.useContext(WorkflowManagerContext)
}

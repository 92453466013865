import type { FC } from 'react'

import { FilterDate } from './adminFilters/components/FilterDate'
import { FilterDaysCount } from './adminFilters/components/FilterDaysCount'
import { FilterMultiSelect } from './adminFilters/components/FilterMultiSelect'
import { FilterRecordFieldSelect } from './adminFilters/components/FilterRecordFieldSelect'
import { FilterText } from './adminFilters/components/FilterText'
import { FilterTextOrDropdown } from './adminFilters/components/FilterTextOrDropdown'
import { FilterUserFieldSelect } from './adminFilters/components/FilterUserFieldSelect'
import type { AdminFilterProps } from './adminFilters/components/types'
import { DateInput } from './inlineFilters/components/inputs/DateInput'
import { DayCounterInput } from './inlineFilters/components/inputs/DayCounterInput'
import { MultiSelectInput } from './inlineFilters/components/inputs/MultiSelectInput'
import { NumberInput } from './inlineFilters/components/inputs/NumberInput'
import { SingleValueDropdownInput } from './inlineFilters/components/inputs/SingleValueDropdownInput'
import { TextInput } from './inlineFilters/components/inputs/TextInput'
import { TextOrDropdownInput } from './inlineFilters/components/inputs/TextOrDropdownInput'
import { TextOrNumberInput } from './inlineFilters/components/inputs/TextOrNumberInput'
import { InlineFilterInputComponent } from './inlineFilters/components/inputs/types'

export const labelMap: { [key in FilterOperation]?: string } = {
    equals: '=',
    notEquals: '≠',
    lessThan: '<',
    equalsOrLessThan: '<=',
    greaterThan: '>',
    equalsOrGreaterThan: '>=',
    is: 'is',
    isnt: 'is not',
    listIs: 'is',
    listIsnt: 'is not',
    oneOf: 'is any of',
    noneOf: 'is none of',
    startsWith: 'starts with',
    endsWith: 'ends with',
    sameDay: 'is',
    beforeDate: 'is before',
    afterDate: 'is after',
    isCurrentUser: 'is current user',
    isNotCurrentUser: 'is not current user',
    isCurrentUserField: "is current user's...",
    isNotCurrentUserField: "is not current user's...",
    isEmpty: 'is empty',
    isNotEmpty: 'is not empty',
    containsAny: 'contains any of',
    containsNone: 'contains none of',
    containsCurrentUser: 'contains current user',
    doesNotContainCurrentUser: 'does not contain current user',
    containsCurrentUserField: "contains the user's...",
    doesNotContainCurrentUserField: "does not contain the user's...",
    withinNext: 'is within the next',
    withinLast: 'is within the last',
    isContextRecordField: "is current record's",
    isNotContextRecordField: "is not current record's",
    containsContextRecordField: "contains current record's",
    doesNotContainContextRecordField: "doesn't contain current record's",
}

export const stringOptions = [
    'is',
    'isnt',
    'contains',
    "doesn't contain",
    'startsWith',
    'endsWith',
    'isEmpty',
    'isNotEmpty',
]

export const FOREIGN_LINK_FILTER_OPTIONS: ('isEmpty' | 'isNotEmpty')[] = ['isEmpty', 'isNotEmpty']
export const filterOptionsForType = {
    autoid: ['is'],
    string: stringOptions,
    long_text: stringOptions,
    document: stringOptions,
    lookup: ['is', 'isnt', 'isEmpty', 'isNotEmpty', 'oneOf', 'noneOf'],
    user_role: ['is', 'isnt', 'oneOf', 'noneOf'],
    multi_lookup: ['listIs', 'listIsnt', 'isEmpty', 'isNotEmpty', 'containsAny', 'containsNone'],
    multi_select: ['listIs', 'listIsnt', 'isEmpty', 'isNotEmpty', 'containsAny', 'containsNone'],
    url: stringOptions,
    date: ['beforeDate', 'afterDate', 'sameDay', 'isEmpty', 'isNotEmpty'],
    image: stringOptions,
    checkbox: ['is', 'isnt'],
    dropdown: ['is', 'isnt', 'isEmpty', 'isNotEmpty', 'oneOf', 'noneOf'],
    number: [
        'equals',
        'lessThan',
        'equalsOrLessThan',
        'greaterThan',
        'equalsOrGreaterThan',
        'notEquals',
        'isEmpty',
        'isNotEmpty',
    ],
    datetime: ['beforeDate', 'afterDate', 'sameDay', 'isEmpty', 'isNotEmpty'],
    currency: [
        'equals',
        'lessThan',
        'equalsOrLessThan',
        'greaterThan',
        'equalsOrGreaterThan',
        'notEquals',
        'isEmpty',
        'isNotEmpty',
    ],
    percentage: [
        'equals',
        'lessThan',
        'equalsOrLessThan',
        'greaterThan',
        'equalsOrGreaterThan',
        'notEquals',
        'isEmpty',
        'isNotEmpty',
    ],
    multi_file: ['isEmpty', 'isNotEmpty'],
}

export const currentRecordFieldSingleOptions = ['isContextRecordField', 'isNotContextRecordField']
export const currentRecordFieldMultiOptions = [
    'containsContextRecordField',
    'doesNotContainContextRecordField',
]

export const filterableTypes = Object.keys(filterOptionsForType)

export const nextFilterOptions: Record<
    Exclude<
        FilterOperation,
        | 'isEmpty'
        | 'isNotEmpty'
        | 'isCurrentUser'
        | 'isNotCurrentUser'
        | 'containsCurrentUser'
        | 'doesNotContainCurrentUser'
        | 'containsCurrentUserProfile'
        | 'doesNotContainCurrentUserProfile'
        | 'appearsIn'
    >,
    FC<AdminFilterProps>
> = {
    is: FilterTextOrDropdown,
    isnt: FilterTextOrDropdown,
    listIs: FilterMultiSelect,
    listIsnt: FilterMultiSelect,
    contains: FilterText,
    "doesn't contain": FilterText,
    startsWith: FilterText,
    endsWith: FilterText,
    equals: FilterText,
    notEquals: FilterText,
    greaterThan: FilterText,
    lessThan: FilterText,
    equalsOrGreaterThan: FilterText,
    equalsOrLessThan: FilterText,
    beforeDate: FilterDate,
    afterDate: FilterDate,
    isInBetween: FilterDate,
    sameDay: FilterDate,
    withinNext: FilterDaysCount,
    withinLast: FilterDaysCount,
    oneOf: FilterMultiSelect,
    noneOf: FilterMultiSelect,
    containsAny: FilterMultiSelect,
    containsNone: FilterMultiSelect,
    isCurrentUserField: FilterUserFieldSelect,
    isNotCurrentUserField: FilterUserFieldSelect,
    containsCurrentUserField: FilterUserFieldSelect,
    doesNotContainCurrentUserField: FilterUserFieldSelect,
    isContextRecordField: FilterRecordFieldSelect,
    isNotContextRecordField: FilterRecordFieldSelect,
    containsContextRecordField: FilterRecordFieldSelect,
    doesNotContainContextRecordField: FilterRecordFieldSelect,
}

export type InlineFilterOperation = Exclude<
    FilterOperation,
    | 'isContextRecordField'
    | 'isNotContextRecordField'
    | 'containsContextRecordField'
    | 'doesNotContainContextRecordField'
>

export const inlineFilterLabelMap: Record<InlineFilterOperation, string> = {
    equals: 'is equal to',
    notEquals: 'is not equal to',
    lessThan: 'is less than',
    equalsOrLessThan: 'is less than or equal to',
    greaterThan: 'is more than',
    equalsOrGreaterThan: 'is more than or equal to',
    is: 'is',
    isnt: 'is not',
    listIs: 'is',
    listIsnt: 'is not',
    oneOf: 'is any of',
    noneOf: 'is none of',
    startsWith: 'starts with',
    endsWith: 'ends with',
    sameDay: 'is',
    beforeDate: 'is before',
    afterDate: 'is after',
    isInBetween: 'is in between',
    isCurrentUser: 'is current user',
    isNotCurrentUser: 'is not current user',
    isCurrentUserField: 'is my',
    isNotCurrentUserField: 'is not my',
    isEmpty: 'is empty',
    isNotEmpty: 'is not empty',
    containsAny: 'contains any of',
    containsNone: 'contains none of',
    containsCurrentUser: 'contains current user',
    doesNotContainCurrentUser: 'does not contain current user',
    containsCurrentUserField: "contains the user's",
    doesNotContainCurrentUserField: "does not contain the user's",
    withinNext: 'is within the next',
    withinLast: 'is within the last',
    contains: 'contains',
    "doesn't contain": "doesn't contain",
    containsCurrentUserProfile: 'contains current user',
    doesNotContainCurrentUserProfile: 'does not contain current user',
    appearsIn: 'appears in',
}

const inlineFilterStringOps: InlineFilterOperation[] = [
    'contains',
    "doesn't contain",
    'is',
    'isnt',
    'startsWith',
    'endsWith',
    'isEmpty',
    'isNotEmpty',
]

const inlineFilterNumberOps: InlineFilterOperation[] = [
    'equals',
    'notEquals',
    'lessThan',
    'equalsOrLessThan',
    'greaterThan',
    'equalsOrGreaterThan',
    'isEmpty',
    'isNotEmpty',
]

export const inlineFilterOpsForType: Record<FieldType, InlineFilterOperation[]> = {
    checkbox: ['is'],
    dropdown: ['oneOf', 'noneOf', 'is', 'isnt', 'isEmpty', 'isNotEmpty'],
    string: inlineFilterStringOps,
    long_text: inlineFilterStringOps,
    lookup: ['oneOf', 'noneOf', 'is', 'isnt', 'isEmpty', 'isNotEmpty'],
    user_role: [],
    multi_lookup: ['oneOf', 'noneOf', 'listIs', 'listIsnt', 'isEmpty', 'isNotEmpty'],
    multi_select: ['containsAny', 'containsNone', 'listIs', 'listIsnt', 'isEmpty', 'isNotEmpty'],
    url: inlineFilterStringOps,
    image: inlineFilterStringOps,
    number: inlineFilterNumberOps,
    currency: inlineFilterNumberOps,
    percentage: inlineFilterNumberOps,
    date: [
        'beforeDate',
        'afterDate',
        'sameDay',
        'isEmpty',
        'isNotEmpty',
        'withinNext',
        'withinLast',
        'isInBetween',
    ],
    datetime: [
        'beforeDate',
        'afterDate',
        'sameDay',
        'isEmpty',
        'isNotEmpty',
        'withinNext',
        'withinLast',
        'isInBetween',
    ],
    multi_file: ['isEmpty', 'isNotEmpty'],
    formula: [],
    password: [],
    user_ref: [],
    document: [],
}

export const inlineFilterOpInputMap: Partial<
    Record<FilterOperation, InlineFilterInputComponent<unknown>>
> = {
    equals: TextOrNumberInput,
    notEquals: TextOrNumberInput,
    is: TextOrDropdownInput,
    isnt: TextOrDropdownInput,
    oneOf: MultiSelectInput,
    noneOf: MultiSelectInput,
    listIs: MultiSelectInput,
    listIsnt: MultiSelectInput,
    contains: TextInput,
    "doesn't contain": TextInput,
    containsAny: MultiSelectInput,
    containsNone: MultiSelectInput,
    startsWith: TextInput,
    endsWith: TextInput,
    isCurrentUserField: SingleValueDropdownInput,
    isNotCurrentUserField: SingleValueDropdownInput,
    lessThan: NumberInput,
    greaterThan: NumberInput,
    equalsOrLessThan: NumberInput,
    equalsOrGreaterThan: NumberInput,
    withinNext: DayCounterInput,
    withinLast: DayCounterInput,
    beforeDate: DateInput,
    afterDate: DateInput,
    sameDay: DateInput,
    isInBetween: DateInput,
}

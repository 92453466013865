import { LABEL_GREY } from 'features/charts/recharts/constants'

export const NONE_VALUE_SECTION_COLOR = LABEL_GREY
export const NONE_VALUE_SECTION_LABEL_COLOR = '#204060'
export const NONE_VALUE_SECTION_LABEL = '(Empty)'

export const COLORS_ORDER = [
    'lightGrey',
    'blue',
    'purple',
    'ruby',
    'moss',
    'slateGrey',
    'raspberry',
    'teal',
    'mauve',
    'poppy',
    'pine',
    'gold',
    'indigo',
    'orange',
    'mulberry',
    'green',
]

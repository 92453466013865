import React, { useCallback, useState } from 'react'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownContent, DropdownTitle, DropdownTrigger } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { IconName } from 'ui/components/Icon/Icon'
import { IconPicker } from 'ui/components/IconPicker'
import { TriggerStyles } from 'ui/components/Select/Select.css'

export type IconValue = {
    type: 'lucide'
    name: IconName
}

type IconPickerDropdownProps = {
    value?: IconValue
    onChange?: (value?: IconValue) => void
    disabled?: boolean
    title?: string
    onOpenChange?: (open: boolean) => void
}

export const IconPickerDropdown: React.FC<IconPickerDropdownProps> = ({
    value,
    onChange,
    disabled,
    title = 'Icons',
    onOpenChange,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleChange = useCallback(
        (iconName?: IconName) => {
            if (iconName) {
                onChange?.({ type: 'lucide', name: iconName })
            } else {
                onChange?.(undefined)
            }
        },
        [onChange]
    )

    const handleOpenChange = useCallback(
        (open: boolean) => {
            setIsOpen(open)
            onOpenChange?.(open)
        },
        [onOpenChange]
    )

    return (
        <Dropdown open={isOpen} onOpenChange={handleOpenChange} modal>
            <DropdownTrigger
                className={TriggerStyles.styleFunction({ size: 'm' })}
                style={{
                    width: 'auto',
                }}
                disabled={disabled}
                aria-disabled={disabled}
                asChild
            >
                <Box flex center gap="xs" role="button">
                    {value ? (
                        <Icon name={value.name} size="m" />
                    ) : (
                        <Box height="xl" width="xl" rounded="s" background="surfaceStrongest" />
                    )}
                    <Icon size="m" name="ChevronDown" />
                </Box>
            </DropdownTrigger>
            <DropdownContent align="end">
                <DropdownTitle>{title}</DropdownTitle>
                <IconPickerWithDelay
                    value={value?.name ?? null}
                    onChange={handleChange}
                    isClearable
                />
            </DropdownContent>
        </Dropdown>
    )
}

const IconPickerWithDelay: React.FC<React.ComponentPropsWithoutRef<typeof IconPicker>> = (
    props
) => {
    const [isFirstRender, setIsFirstRender] = useState(true)

    // Delay the rendering of the icon picker to prevent
    // it from making the dropdown laggy.
    React.useEffect(() => {
        setTimeout(() => {
            setIsFirstRender(false)
        }, 0)
    }, [])

    return (
        <Box px="xl">
            {isFirstRender ? (
                <Box
                    style={{
                        width: '258px',
                        height: '250px',
                    }}
                />
            ) : (
                <IconPicker
                    variant="lucide"
                    style={{
                        width: '258px',
                        height: '250px',
                    }}
                    {...props}
                />
            )}
        </Box>
    )
}

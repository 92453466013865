import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { TiptapCollabProvider } from '@hocuspocus/provider'
import { Content, Editor } from '@tiptap/core'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import { AnyExtension } from '@tiptap/react'
import shortid from 'shortid'
import getInitials from 'v2/views/utils/getInitials'

import { TIP_TAP_COLLAB_APP_ID } from 'app/settings'
import { useAppUserContext } from 'app/useAppUserContext'

import { theme } from 'ui/styling/Theme.css'

type Props = {
    editor?: Editor | null
    content: Content
    collaborationId?: string
    collaborationToken?: string
}
export function useTipTapCollab({ editor, content, collaborationId, collaborationToken }: Props) {
    const { user } = useAppUserContext()
    const initialContent = useRef(content)
    const instance = useRef(shortid.generate())
    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState<'connected' | 'disconnected' | 'connecting'>(
        'disconnected'
    )
    const setContentFn = useRef(editor?.commands.setContent)
    setContentFn.current = editor?.commands.setContent

    const log = useCallback(function log(message: string, ...args: any[]) {
        console.log(
            `# tiptap (${instance.current} - ${new Date().toISOString()}): ${message}`,

            ...args
        )
    }, [])

    const provider = useMemo(() => {
        if (!collaborationToken || !collaborationId) {
            return undefined
        }

        log('create provider')
        setIsLoading(true)
        return new TiptapCollabProvider({
            appId: TIP_TAP_COLLAB_APP_ID,
            name: collaborationId,
            token: collaborationToken,
            onSynced() {
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        // @ts-ignore
                        log('onSynced', this.configuration)
                        setIsLoading(false)
                        // If the document from our server is not empty, but the document from the collab server is empty,
                        // it's the first time opening this document since turning on collab... so we need to push the content
                        // into the editor.
                        if (
                            initialContent.current &&
                            // @ts-ignore
                            !this.configuration.document.store.clients.size
                        ) {
                            log(
                                'set content to match our server copy',
                                // @ts-ignore
                                this.configuration
                            )
                            setContentFn.current?.(initialContent.current)
                        }
                    })
                })
            },
            onAuthenticated() {
                log('onAuthenticated')
            },
            onMessage(args) {
                log('onMessage', args)
            },
            onAuthenticationFailed(args) {
                log('onAuthenticationFailed', args)
                // for some reason auth failed, so as fallback, load our server's content
                setContentFn.current?.(initialContent.current)
            },
            onStatus({ status }) {
                log('status', status)
                setStatus(status)
            },
            onClose(args) {
                log('onClose', args)
            },
            onDisconnect(args) {
                log('onDisconnect', args)
            },

            onOpen(args) {
                log('onOpen', args)
            },
        })
    }, [collaborationId, collaborationToken, log])

    useEffect(() => {
        return () => {
            // @ts-ignore
            log('dispose provider')
            provider?.destroy()
        }
    }, [provider, log])
    const extensions = useMemo(() => {
        const extensions: AnyExtension[] = []

        if (provider) {
            extensions.push(
                Collaboration.configure({
                    document: provider.document,
                    field: 'default',
                })
            )
            extensions.push(
                CollaborationCursor.configure({
                    provider: provider,
                    user: {
                        name: user?.name || user?.email,
                        avatar: user?.avatar,
                        color: '#EADDCA',
                    },
                    render: (user) => {
                        const cursor = document.createElement('span')

                        cursor.classList.add('collaboration-cursor__caret')
                        cursor.setAttribute('style', `border-color: ${theme.color.theme300}`)
                        const container = document.createElement('div')
                        container.classList.add('collaboration-cursor__avatar')
                        container.setAttribute(
                            'style',
                            `background-color: ${theme.color.theme100};
                            color: ${theme.color.theme400};
                            border: 1px solid ${theme.color.theme200}`
                        )

                        if (user.avatar) {
                            const avatar = document.createElement('img')
                            avatar.src = user.avatar
                            container.insertBefore(avatar, null)
                        } else {
                            container.insertBefore(
                                document.createTextNode(getInitials(user.name) || 'U'),
                                null
                            )
                        }
                        cursor.insertBefore(container, null)

                        return cursor
                    },
                })
            )
        }
        return extensions
    }, [provider, user])

    return useMemo(
        () => ({ isLoading, status, extensions, provider }),
        [isLoading, status, extensions, provider]
    )
}

import { recordApi } from 'data/api/recordApi'
import { queryClient } from 'data/hooks/_helpers'

import { RECORD_QUERY_CONFIG } from './recordConstants'

/**
 * Fetches a record from the api and sets up the record query
 * @param {string} recordId
 * @param {any | undefined} options
 */
export const getRecords = async (
    objectId: string,
    filters: any,
    options: any = {}
): Promise<RecordDto[] | undefined> => {
    const queryKey = ['records', objectId, filters]
    // Note: calling prefetch query directly from queryClient won't use any of the default query config that the useQuery hook uses
    // This allows us to fetch data for a query outside of react components
    const queryOptions: any = { ...RECORD_QUERY_CONFIG }

    const existingData = queryClient.getQueryData(queryKey)
    if (existingData) {
        return Promise.resolve(existingData as RecordDto[])
    }

    // The query might not have been setup yet, so using prefetch query will create the query if not
    await queryClient.prefetchQuery(
        queryKey,
        () => {
            return recordApi
                .getObjectRecords(objectId, filters, {
                    ...options,
                })
                .then((response) => (response as { records: RecordDto[] })?.records)
        },
        queryOptions
    )

    return queryClient.getQueryData(queryKey)
}

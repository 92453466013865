import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { CollapsibleTrigger } from 'ui/components/Collapsible'

type ConnectionDetailsTriggerProps = {
    isOpen: boolean
}
export const ConnectionDetailsTrigger: React.FC<ConnectionDetailsTriggerProps> = ({ isOpen }) => {
    return (
        <Box flex column>
            <CollapsibleTrigger color="textWeaker" flex center justifyContent="flex-start" asChild>
                <Button
                    variant="secondary"
                    startIcon={{ name: isOpen ? 'ChevronUp' : 'ChevronDown' }}
                    aria-label={isOpen ? 'Hide details' : 'Show details'}
                    size="xs"
                />
            </CollapsibleTrigger>
        </Box>
    )
}

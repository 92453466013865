import React from 'react'

import { ListViewCommentCount } from 'features/views/ListView/ListViewCommentCount'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'

type BoardViewCommentCountProps = React.ComponentPropsWithoutRef<typeof Box> & {
    record: RecordDto
    isLoading?: boolean
}

export const BoardViewCommentCount: React.FC<BoardViewCommentCountProps> = ({
    record,
    isLoading,
    ...props
}) => {
    return (
        <Skeleton isLoading={isLoading}>
            <ListViewCommentCount record={record} color="icon" p="xs" {...props} />
        </Skeleton>
    )
}

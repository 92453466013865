import { QueryKey, useMutation, useQuery } from 'react-query'

import { queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { RelatedToType } from 'data/hooks/activityTypes'
import { buildUrl, fetchAndReturn } from 'data/utils/utils'

export enum FollowingStatus {
    NotFollowing = 0,
    Following = 1,
    Notify = 2,
    Muted = 3,
}
type FollowingData = {
    status: FollowingStatus
    originalStatus?: FollowingStatus
}
type FollowingProps = {
    activityId?: number
    relatedTo?: string
    relatedToType?: RelatedToType
    relatedToLocation?: string
    objectId?: string
    stackId?: string
}
export function useFollowingStatus(props: FollowingProps) {
    const queryKey = useQueryKeyBuilder(['followingStatus', props], { includeAuthKeys: true })
    const query = useQuery<FollowingData>(queryKey, async () => {
        const url = buildUrl(`following/`, props, true)
        return (await fetchAndReturn(url)) as FollowingData
    })

    const { mutate: updateStatus } = useSetFollowingStatus(queryKey, props)

    return { ...query, updateStatus }
}

export function invalidateFollowingStatus() {
    queryClient.invalidateQueries(['followingStatus'])
}
export function useSetFollowingStatus(queryKey: QueryKey, props: FollowingProps) {
    return useMutation(
        async (status: FollowingStatus) => {
            const results = (await fetchAndReturn(`following/`, {
                method: 'POST',
                body: JSON.stringify({
                    ...props,
                    status,
                }),
                headers: { 'Content-type': 'application/json' },
            })) as NotificationDto

            return results
        },
        {
            onMutate: (status: FollowingStatus) => {
                const queryData = {
                    ...(queryClient.getQueryData<FollowingData>(queryKey) ?? {}),
                }

                queryData.originalStatus = queryData.status
                queryData.status = status

                queryClient.setQueryData(queryKey, queryData)
            },
            onError: () => {
                const queryData = {
                    ...(queryClient.getQueryData<FollowingData>(queryKey) ?? {}),
                }

                if (queryData?.originalStatus) {
                    queryData.status = queryData.originalStatus
                    delete queryData.originalStatus
                }
                queryClient.setQueryData(queryKey, queryData)
            },
            onSettled: () => {
                const queryData = {
                    ...(queryClient.getQueryData<FollowingData>(queryKey) ?? {}),
                }

                if (queryData?.originalStatus) {
                    delete queryData.originalStatus
                }
                queryClient.setQueryData(queryKey, queryData)
            },
        }
    )
}

import { useCallback, useMemo, useState } from 'react'

import { NavigationSpace } from 'features/navigation/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useNavigationContext } from './useNavigationContext'

type UseNavigationSpacesItemStateProps = NavigationSpace & {
    isNavigationCollapsed?: boolean
}

export function useNavigationSpacesItemState({
    isNavigationCollapsed,
    ...space
}: UseNavigationSpacesItemStateProps) {
    const { permissions, showCreateAppModal } = useNavigationContext()

    const { apps, id } = space
    const pinnedApps = apps.filter(
        (app) => !!app.stack.options.pin_to_app_bar && !app.stack.options.is_archived
    )
    const appsMemo = useDeepEqualsMemoValue(pinnedApps)

    const [isCollapsed, setIsCollapsed] = useState(restoreCollapsedState(space))

    const toggleIsCollapsed = useCallback(() => {
        setIsCollapsed((prev) => {
            const newState = !prev
            persistCollapsedState(space, newState)

            return newState
        })
    }, [space])

    // If the navigation is collapsed, only show the apps if the space is not collapsed.
    let showApps = isNavigationCollapsed ? !isCollapsed : true
    if (!appsMemo.length) {
        showApps = false
    }

    const canCreateApp = permissions.canCreateApp
    const onCreateApp = useCallback(() => {
        showCreateAppModal(id)
    }, [id, showCreateAppModal])

    return useMemo(
        () => ({
            showApps,
            isCollapsed,
            apps: appsMemo,
            toggleIsCollapsed,
            canCreateApp,
            onCreateApp,
        }),
        [appsMemo, canCreateApp, isCollapsed, showApps, toggleIsCollapsed, onCreateApp]
    )
}

// Using the same key as the old navigation, which was storing the expanded state instead of the collapsed state.
function getCollapsedStateStorageKey(space: NavigationSpace) {
    return `${space.id}_Expanded`
}

function persistCollapsedState(space: NavigationSpace, isCollapsed: boolean) {
    const key = getCollapsedStateStorageKey(space)
    localStorage.setItem(key, (!isCollapsed).toString())
}

function restoreCollapsedState(space: NavigationSpace) {
    const key = getCollapsedStateStorageKey(space)
    const value = localStorage.getItem(key)

    return value ? value === 'false' : false
}

import React from 'react'

import { Rights } from 'app/accountUserContextConstants'
import { useAccountUserContext } from 'app/useAccountUserContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'

import { PortalGeneralSettings } from './PortalGeneralSettings'
import { WorkspaceGeneralSettings } from './WorkspaceGeneralSettings'

type WorkspaceModalGeneralSettingsProps = {
    formRef: any
    children?: React.ReactNode
}

type Portal = AccountZone & { type: 'Portal' }

export const WorkspaceModalGeneralSettings = ({
    formRef,
    children,
}: WorkspaceModalGeneralSettingsProps) => {
    const { workspaceZone, workspaceAccount } = useWorkspaceContext()
    const { hasRight } = useAccountUserContext()
    const readOnly = !hasRight(Rights.ManageSettings)

    if (!workspaceZone || !workspaceAccount) return null

    if (workspaceZone.type == 'Portal') {
        return (
            <PortalGeneralSettings
                formRef={formRef}
                readOnly={readOnly}
                portal={workspaceZone as Portal}
            >
                {children}
            </PortalGeneralSettings>
        )
    }

    return (
        <WorkspaceGeneralSettings
            formRef={formRef}
            readOnly={readOnly}
            workspaceAccount={workspaceAccount}
        >
            {children}
        </WorkspaceGeneralSettings>
    )
}

import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function usePortalLogoState() {
    const { workspaceZone } = useNavigationContext()

    const portalName = workspaceZone?.display_name ?? 'Stacker'
    const logoImageUrl = workspaceZone?.logo_url

    return useMemo(
        () => ({
            portalName,
            logoImageUrl,
        }),
        [portalName, logoImageUrl]
    )
}

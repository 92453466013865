import { useMemo } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/useTableViewContext'

export function useTableViewLoaderState() {
    const { pageSize } = useTableViewContext()

    return useMemo(
        () => ({
            placeholderRows: makePlaceholderRows(pageSize),
        }),
        [pageSize]
    )
}

function makePlaceholderRows(length: number) {
    return Array.from({ length }).map((_, index) => index)
}

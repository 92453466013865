import { useMemo } from 'react'

import { useUnreadNotificationCount } from 'data/hooks/activities/notifications'

export function useSystemNavigationItemNotificationsState() {
    const { data: count = 0 } = useUnreadNotificationCount()

    const isUnread = count > 0

    return useMemo(
        () => ({
            count,
            isUnread,
        }),
        [count, isUnread]
    )
}

// @ts-strict-ignore
import React, { useMemo } from 'react'

import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { isDisabled } from 'utils/fieldUtils'

import { Select, SelectOption } from 'ui/components/Select'

export const EmailFieldPicker = ({ objectId, objects, error, ...rest }) => {
    const object = objects.find((o: ObjectDto) => o._sid == objectId)
    const sortByFieldsOrder = useSortingByFieldsOrder(object)

    const fieldOptions = useMemo(() => {
        const fields = object?.fields?.filter(
            (field: FieldDto) => !isDisabled(field) && ['string'].includes(field.type)
        )
        return sortByFieldsOrder(fields).map((field) => ({
            label: field.label,
            value: field['api_name'],
        }))
    }, [object?.fields, sortByFieldsOrder])

    return (
        <Select
            label="Email field"
            startIcon={{ name: 'Mail' }}
            placeholder="Select email field..."
            isError={!!error}
            helperText={!!error ? error.message : null}
            isClearable
            {...rest}
        >
            {fieldOptions.map(({ label, value }) => (
                <SelectOption key={value} value={value} label={label} />
            ))}
        </Select>
    )
}

import React, { useCallback, useRef, useState } from 'react'

import { Input } from 'ui/components/Input/Input'

import { DropdownHeadBase } from './DropdownHeadBase'
import { useKeyDownFromHead } from './useKeyDownFromHead'

type DropdownHeadInputRef = HTMLDivElement

type InheritedInputProps = Pick<
    React.ComponentPropsWithoutRef<typeof Input>,
    'placeholder' | 'autoFocus' | 'value' | 'onChange'
>

type DropdownHeadInputProps = React.ComponentPropsWithoutRef<typeof DropdownHeadBase> &
    Partial<InheritedInputProps> & {}

export const DropdownHeadInput = React.forwardRef<DropdownHeadInputRef, DropdownHeadInputProps>(
    ({ placeholder, autoFocus = true, value, onChange, onFocus, ...props }, ref) => {
        const inputRef = useRef<HTMLInputElement>(null)

        const [headRef, setHeadRef] = useState<HTMLElement | null>(null)

        const handleOnFocus = useCallback(
            (e: React.FocusEvent<HTMLDivElement>) => {
                e.preventDefault()
                e.stopPropagation()

                // Only run this when the head is focused from another menu item.
                if (e.target !== e.currentTarget) return

                const input = inputRef.current
                if (!input) return

                input.focus()

                onFocus?.(e)
            },
            [onFocus]
        )

        const handleInputKeyDown = useKeyDownFromHead(headRef)

        return (
            <DropdownHeadBase
                onFocus={handleOnFocus}
                {...props}
                ref={(element: HTMLDivElement) => {
                    setHeadRef(element)

                    return ref
                }}
            >
                <Input
                    ref={inputRef}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    onKeyDown={handleInputKeyDown}
                />
            </DropdownHeadBase>
        )
    }
)

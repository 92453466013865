import moment from 'moment-timezone'

export const getBucketDate = (value?: string, bucketBy?: Bucket) => {
    if (!value || !bucketBy) return null

    let date = moment(value)
    if (bucketBy === 'quarter') {
        const pieces = value?.split('Q')
        date = moment(pieces[0]).quarter(parseInt(pieces[1]))
    }
    if (!date.isValid()) return null
    return date
}

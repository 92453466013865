export type APIErrorData = {
    error?: string
    exception?: string
    details?: string
}

export class APIError extends Error {
    statusCode?: number
    exception?: string
    details?: string

    constructor(statusCode: number, { error, exception, details }: APIErrorData) {
        super(error || 'API Error')

        this.statusCode = statusCode
        this.exception = exception
        this.details = details
    }
}

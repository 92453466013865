import React from 'react'

import { Box, Icon, Text } from 'v2/ui'

export type InlineFilterOptionProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'color'> & {
    label: string
    isChecked?: boolean
    color?: string
    leftAdorner?: React.ReactNode
    rightAdorner?: React.ReactNode
}

export const InlineFilterOption = React.forwardRef<HTMLElement, InlineFilterOptionProps>(
    ({ label, isChecked, color, leftAdorner, rightAdorner, ...props }, ref) => {
        return (
            <Box
                ref={ref as React.Ref<HTMLDivElement>}
                pl={3}
                pr={2}
                py={2}
                bgColor={isChecked ? 'brand.100' : 'transparent'}
                _hover={{
                    bgColor: isChecked ? 'brand.100' : 'userInterface.neutral.200',
                }}
                _active={{
                    bgColor: isChecked ? 'brand.100' : 'userInterface.neutral.300',
                }}
                _focusVisible={{
                    bgColor: isChecked ? 'brand.100' : 'userInterface.neutral.200',
                }}
                rounded="lg"
                transition="background-color 0.1s ease-out"
                aria-checked={isChecked}
                display="flex"
                alignItems="center"
                userSelect="none"
                textDecoration="none"
                width="100%"
                textAlign="start"
                flex="0 0 auto"
                outline="none"
                {...props}
            >
                {leftAdorner && <Box mr={2}>{leftAdorner}</Box>}

                {color && (
                    <Box
                        aria-hidden="true"
                        bgColor={color}
                        height={1.5}
                        width={1.5}
                        rounded="full"
                        mr={1.5}
                    />
                )}

                <Text
                    flex={1}
                    fontWeight="bold"
                    color="userInterface.neutral.1000"
                    lineHeight={1}
                    maxWidth="100%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                >
                    {label}
                </Text>

                {rightAdorner && <Box ml={2}>{rightAdorner}</Box>}

                {!rightAdorner && (
                    <Icon
                        icon="checkmark"
                        size="1rem"
                        color="brand.500"
                        transform={isChecked ? 'scale(1)' : 'scale(0)'}
                        opacity={isChecked ? 1 : 0}
                        mr={0.5}
                        ml={2}
                        transition="0.15s ease-out"
                    />
                )}
            </Box>
        )
    }
)

import React, { useCallback, useMemo, useState } from 'react'

import { BreadcrumbsMode } from './BreadcrumbsMode'
import { HeaderContext } from './HeaderContext'

export const HeaderContextProvider: React.FC = ({ children }) => {
    const [colorMode, setColorMode] = useState<null | 'dark' | 'light'>(null)
    const [headerImageVisible, setHeaderImageVisible] = useState(false)
    const [showBreadcrumbs, setShowBreadcrumbs] = useState(false)
    const [breadcrumbsMode, setBreadcrumbsMode] = useState(BreadcrumbsMode.Full)

    const reset = useCallback(() => {
        setColorMode(null)
        setHeaderImageVisible(false)
        setBreadcrumbsMode(BreadcrumbsMode.Full)
    }, [])

    const state = useMemo(
        () => ({
            colorMode,
            setColorMode,
            headerImageVisible,
            setHeaderImageVisible,
            reset,
            showBreadcrumbs,
            setShowBreadcrumbs,
            breadcrumbsMode,
            setBreadcrumbsMode,
        }),
        [breadcrumbsMode, colorMode, headerImageVisible, reset, showBreadcrumbs]
    )

    return <HeaderContext.Provider value={state}>{children}</HeaderContext.Provider>
}

import React, { useMemo } from 'react'

import { Select, SelectOption } from 'ui/components/Select'
import { Body } from 'ui/components/Text'

import * as Parts from './Pagination.parts'
import { formatCurrentCount } from './utils'

import { PerPageSelectStyle } from './Pagination.css'

type PaginationControlsProps = {
    pageIndex: number
    pageSize: number
    pageSizeOptions: number[]
    totalItemsCount: number
    showPerPage?: boolean
    onChangePageSize: (pageSize: number) => void
}

export const PaginationControls: React.FC<PaginationControlsProps> = ({
    pageIndex,
    pageSize,
    pageSizeOptions,
    showPerPage,
    totalItemsCount,
    onChangePageSize,
}) => {
    return (
        <Parts.Controls>
            <Count pageIndex={pageIndex} pageSize={pageSize} totalItemsCount={totalItemsCount} />
            {showPerPage && (
                <PerPage
                    pageSize={pageSize}
                    pageSizeOptions={pageSizeOptions}
                    onChangePageSize={onChangePageSize}
                />
            )}
        </Parts.Controls>
    )
}

type CountProps = {
    pageIndex: number
    pageSize: number
    totalItemsCount: number
}

const Count: React.FC<CountProps> = ({ pageIndex, pageSize, totalItemsCount }) => {
    const currentCount = useMemo(
        () => formatCurrentCount(pageIndex, pageSize, totalItemsCount),
        [pageIndex, pageSize, totalItemsCount]
    )

    const hasCount = currentCount && totalItemsCount > 0
    if (!hasCount) return null

    return (
        <Parts.Count>
            {currentCount && (
                <Body size="m" weight="bold">
                    {currentCount}
                </Body>
            )}
            {totalItemsCount > 0 && (
                <>
                    <Body size="m">of</Body>
                    <Body size="m" weight="bold">
                        {totalItemsCount}
                    </Body>
                </>
            )}
        </Parts.Count>
    )
}

type PerPageProps = {
    pageSize: number
    pageSizeOptions: number[]
    onChangePageSize: (pageSize: number) => void
}

const PerPage: React.FC<PerPageProps> = ({ pageSize, pageSizeOptions, onChangePageSize }) => {
    const handleChangePageSize = (newValue: string) => onChangePageSize(parseInt(newValue))

    return (
        <Parts.PerPage>
            <Body size="m">Show per page:</Body>
            <Select
                value={pageSize.toString()}
                isClearable={false}
                onChange={handleChangePageSize}
                className={PerPageSelectStyle}
            >
                {pageSizeOptions.map((opt) => (
                    <SelectOption key={opt} value={opt.toString()} label={opt.toString()} />
                ))}
            </Select>
        </Parts.PerPage>
    )
}

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useTheme } from 'ui/styling/themes/useTheme'

export function useDefaultedListHeader(viewOptions: ListViewOptions): ListViewHeader {
    const { colorScheme } = useTheme()

    let value: ListViewHeader

    if (viewOptions.listHeader?.type === 'portal') {
        value = {
            type: 'portal',
            style: 'boxed',
            eyebrow: '',
            title: '',
            subtitle: '',
            backgroundColor: colorScheme,
            coverImageUrl: '',
            textAlignment: 'left',
            searchPlacement: 'toolbar',
            ...(viewOptions.listHeader as Partial<ListViewHeaderPortal>),
        }
    } else {
        value = {
            type: 'default',
            title: '',
            subtitle: '',
            ...viewOptions.listHeader,
        }
    }

    return useDeepEqualsMemoValue(value)
}

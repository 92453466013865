// @ts-strict-ignore
import { formatFilters } from 'features/records/components/RecordFilters'

import { PermissionRuleFormData } from './permissionRuleForm.types'

/**
 *
 * used by create and edit forms
 *
 * @param { {  data: any, object_id: string } } props
 * @returns any
 */
export const buildPermissionRuleRequestDataFromFormData = (
    data: PermissionRuleFormData,
    object: ObjectDto
) => {
    const enabledFields = object.fields.filter((field) => !field.connection_options?.is_disabled)

    // The record_permissions and create_permission fields of the rule used to be set directly in
    // the modal UI but are now set implicitly based on the state of the rest of the form. If any of
    // the fields have a given action checked then enable the corresponding rule level permission.
    const hasCheckedField = { read: false, update: false, create: false }
    for (const field in data.fieldPermissions) {
        for (const permission in data.fieldPermissions[field]) {
            // When you untick a read checkbox, we disable the update and create checkboxes but keep
            // their state, just hidden. This is so that if you later tick read again then we can
            // restore your previous state. When we save, however, we actually do want to disable
            // update and create if read is disabled
            if (permission !== 'read' && !data.fieldPermissions[field]['read']) {
                data.fieldPermissions[field][permission] = false
            }

            const fieldObj = enabledFields.find((f) => f.api_name === field)

            // Only count the field as being checked if it is a currently enabled field
            // and it's not read only or it's not a write permission we're evaluating
            if (
                data.fieldPermissions[field][permission] &&
                fieldObj &&
                (!fieldObj.is_read_only || permission === 'read')
            ) {
                hasCheckedField[permission] = true
            }
        }
    }

    const reqData: PermissionRuleDTO = {
        object_id: object._sid,
        label: data.name,
        granted_by_roles: data.roles,
        create_permission: hasCheckedField.create,
        record_permissions: {
            read: hasCheckedField.read,
            update: hasCheckedField.update,
            delete: data.recordPermissionDelete,
        },
        field_permissions: data.fieldPermissions,
        record_filter: data.allRecords ? [] : formatFilters(data.recordFilter),
        options: {
            filters_unformatted: data.allRecords ? [] : data.recordFilter,
        },
        all_roles: data.allRoles,
        ownership: null,
    }

    // Set record permissions according to type
    if (
        data.allRecords ||
        !data.ownershipFilter.active ||
        !data.ownershipFilter.objectFieldSelected ||
        !data.ownershipFilter.userFieldSelected
    ) {
        // IMPORTANT
        //
        // these metadata fields must be in this shape for backwards compat with old UI
        // and with permission rules created there - do not change!
        reqData.options.permissionSelected = 'global'
        reqData.options.objectFieldSelected = undefined
        reqData.options.userFieldSelected = undefined
    } else if (data.ownershipFilter.active) {
        reqData.ownership = {
            field: data.ownershipFilter.objectFieldSelected,
            user_field: data.ownershipFilter.userFieldSelected,
        }

        // IMPORTANT
        //
        // these metadata fields must be in this shape for backwards compat with old UI
        // and with permission rules created there - do not change!
        reqData.options.permissionSelected = 'private'
        reqData.options.objectFieldSelected = data.ownershipFilter.objectFieldSelected
        reqData.options.userFieldSelected = data.ownershipFilter.userFieldSelected
    }

    return reqData
}

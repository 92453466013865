import React from 'react'

import { useExtDataIntegrations } from 'data/hooks/dataConnections/useExtDataIntegrations'
import { TRANSLATIONS } from 'features/DataConnections/constants'
import { getNangoClient } from 'features/DataConnections/getNangoClient'
import { useNewDataConnectionModal } from 'features/DataConnections/useNewDataConnectionModal'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

type DataConnectionGroupOptionsProps = {
    nangoConnectionId: string
}
export const DataConnectionGroupOptions: React.FC<DataConnectionGroupOptionsProps> = ({
    nangoConnectionId,
}) => {
    const nango = getNangoClient()

    const { data: integrationsData, isLoading: _isLoading } = useExtDataIntegrations()
    const { show: showNewDataConnectionModal } = useNewDataConnectionModal()
    const hmacForConnection = integrationsData?.connections.find(
        (nangoConnection) => nangoConnection.id === nangoConnectionId
    )?.hmac

    return (
        <Box
            style={{
                paddingRight: theme.space.xs,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Dropdown>
                <DropdownButton
                    size="s"
                    aria-label="More actions"
                    variant="ghost"
                    startIcon={{ name: 'MoreHorizontal' }}
                />
                <DropdownContent
                    onClick={stopPropagation}
                    align="end"
                    style={{ minWidth: '250px' }}
                >
                    <DropdownItem
                        startIcon={{ name: 'Key' }}
                        label="Re-authenticate"
                        onClick={async () => {
                            // TODO-nango: pass in the providerConfigKey from somewhere
                            await nango.auth('airtable', nangoConnectionId, {
                                params: {},
                                hmac: hmacForConnection,
                            })
                        }}
                    />
                    <DropdownItem
                        startIcon={{ name: 'Plus' }}
                        label={`Sync another ${TRANSLATIONS['airtable'].database} or ${TRANSLATIONS['airtable'].table}`}
                        // TODO: open up connection modal and prefill with context about this one
                        onClick={() =>
                            showNewDataConnectionModal({
                                initialSelectedExternalAccountId: nangoConnectionId,
                            })
                        }
                    />
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}

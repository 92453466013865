import { queryClient } from 'data/hooks/_helpers'
import { includeFieldsToRelatedFieldSuffix } from 'data/hooks/recordQueryKeySuffix'
/**
 * Gets the record from the query cache
 * @param {string} recordId
 */

export const getCachedRecord = (
    recordId: string,
    requiredFields?: string[]
): RecordDto | undefined => {
    const suffix = includeFieldsToRelatedFieldSuffix(requiredFields)
    return queryClient.getQueryData(['record', recordId, suffix])
}

import React, { useState } from 'react'

import { ExcalidrawElement, NonDeleted } from '@excalidraw/excalidraw/types/element/types'
import { AppState, BinaryFiles } from '@excalidraw/excalidraw/types/types'
import {
    mergeAttributes,
    Node,
    NodeViewProps,
    NodeViewWrapper,
    ReactNodeViewRenderer,
} from '@tiptap/react'

import { FloatingNodeControls } from 'features/tiptap/FloatingNodeControls'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownTitle,
} from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { theme } from 'ui/styling/Theme.css'

import { ExcalidrawImage } from './ExcalidrawImage'
import { ExcalidrawModal } from './ExcalidrawModal'

declare module '@tiptap/core' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Commands<ReturnType> {
        excalidraw: {
            openExcalidrawModal: () => ReturnType
        }
    }
}

const PreviewSize = {
    small: {
        width: '200px',
        label: 'Small',
    },
    medium: {
        width: '400px',
        label: 'Medium',
    },
    large: {
        width: '600px',
        label: 'Large',
    },
    full: {
        width: '100%',
        label: 'Full',
    },
}

const DEFAULT_SIZE = 'large'

// see excalidraw docs
// https://docs.excalidraw.com/docs/@excalidraw/excalidraw/api/props/initialdata
export const ExcalidrawExtension = Node.create({
    name: 'excalidraw',

    group: 'block',
    draggable: true,
    atom: true,
    selectable: true,

    addAttributes() {
        return {
            files: {
                default: {},
                parseHTML: (element) => {
                    return JSON.parse(element.getAttribute('data-files') || '{}')
                },
                renderHTML: (attributes) => ({
                    'data-files': JSON.stringify(attributes.files),
                }),
            },
            elements: {
                default: [],
                parseHTML: (element) => {
                    return JSON.parse(element.getAttribute('data-elements') || '{}')
                },
                renderHTML: (attributes) => ({
                    'data-elements': JSON.stringify(attributes.elements),
                }),
            },
            size: {
                default: DEFAULT_SIZE,
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'div[data-type="excalidraw"]',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'excalidraw' })]
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component)
    },

    addCommands() {
        return {
            openExcalidrawModal: () => {
                return ({ view, tr }) => {
                    const pos = tr.selection.$from.pos
                    const element = view.nodeDOM(pos) as HTMLElement | null
                    if (!element) return false

                    const triggerElement = element.querySelector('[data-excalidraw-trigger]')
                    if (!triggerElement) return false

                    // Trigger a double click event to open the modal.
                    const evt = new MouseEvent('dblclick', {
                        view: window,
                        bubbles: true,
                        cancelable: true,
                    })

                    triggerElement.dispatchEvent(evt)

                    return true
                }
            },
        }
    },
})

const Component = (props: NodeViewProps) => {
    const { node, editor, selected } = props
    const {
        elements = [],
        files = {},
        size = DEFAULT_SIZE,
    } = node.attrs as {
        elements?: NonDeleted<ExcalidrawElement>[]
        files?: BinaryFiles
        size?: string
    }
    const [isOpen, setIsOpen] = useState(false)
    const [imageKey, setImageKey] = useState(0)

    const handleChange = (
        elements: readonly ExcalidrawElement[],
        appState: AppState,
        files: BinaryFiles
    ) =>
        queueMicrotask(() => {
            props.updateAttributes({
                elements,
                files,
            })
        })

    const handleClose = () => {
        setIsOpen(false)
        setImageKey((v) => v + 1)
    }

    const isEmpty = !elements?.find((element) => !element.isDeleted)
    const isEditable = editor.isEditable

    const handleEnterEditMode = () => {
        if (!isEditable) return

        setIsOpen(true)
    }

    const handleClick = () => {
        if (!isEmpty) return

        handleEnterEditMode()
    }

    const handleChangeSize = (newSize: string) => {
        queueMicrotask(() => {
            props.updateAttributes({
                size: newSize,
            })
        })
    }

    const { width } = PreviewSize[size as keyof typeof PreviewSize]
    const effectiveWidth = isEmpty ? '100%' : width

    const [isOptionsOpen, setIsOptionsOpen] = useState(false)

    return (
        <NodeViewWrapper as="div" className="excalidraw" data-type="excalidraw" {...props}>
            <>
                {isOpen && (
                    <ExcalidrawModal
                        elements={elements}
                        files={files}
                        onChange={handleChange}
                        onClose={handleClose}
                    />
                )}
                <Container
                    p="2xl"
                    variant={isEmpty ? 'neutral' : 'transparent'}
                    cursor={isEmpty && isEditable ? 'pointer' : undefined}
                    maxWidth="full"
                    onDoubleClick={handleEnterEditMode}
                    onClick={handleClick}
                    data-drag-handle
                    data-excalidraw-trigger
                    style={{
                        width: effectiveWidth,
                        borderColor: selected ? theme.color.theme300 : 'transparent',
                    }}
                    borderRadius="s"
                    borderWidth={2}
                    overflow="hidden"
                >
                    {!isEmpty ? (
                        <FloatingNodeControls
                            editor={editor}
                            forceVisible={isOptionsOpen}
                            controls={
                                <Box flex center gap="xs">
                                    <Button
                                        startIcon={{ name: 'Pencil' }}
                                        variant="secondary"
                                        type="button"
                                        onClick={handleEnterEditMode}
                                        size="s"
                                    >
                                        Edit
                                    </Button>
                                    <ExcalidrawNodeOptions
                                        open={isOptionsOpen}
                                        onOpenChange={setIsOptionsOpen}
                                        currentSize={size}
                                        onChangeSize={handleChangeSize}
                                    />
                                </Box>
                            }
                        >
                            <ExcalidrawImage key={imageKey} elements={elements} files={files} />
                        </FloatingNodeControls>
                    ) : (
                        <>
                            <Icon name="PencilRuler" mr="m" /> Empty drawing.
                            {isEditable && ' Click to edit.'}
                        </>
                    )}
                </Container>
            </>
        </NodeViewWrapper>
    )
}

type ExcalidrawNodeOptionsProps = {
    open: boolean
    onOpenChange: (open: boolean) => void
    currentSize: string
    onChangeSize: (size: string) => void
}

const ExcalidrawNodeOptions: React.FC<ExcalidrawNodeOptionsProps> = ({
    open,
    onOpenChange,
    currentSize,
    onChangeSize,
}) => {
    return (
        <Dropdown open={open} onOpenChange={onOpenChange}>
            <DropdownButton variant="secondary" size="s" startIcon={{ name: 'MoreVertical' }} />
            <DropdownContent align="end">
                <DropdownTitle>Size</DropdownTitle>
                {Object.entries(PreviewSize).map(([key, value]) => (
                    <DropdownItem
                        multiSelect
                        key={key}
                        label={value.label}
                        checked={currentSize === key}
                        onCheckedChange={() => onChangeSize(key)}
                    />
                ))}
            </DropdownContent>
        </Dropdown>
    )
}

export function getRecordActionDisplay(view: ViewDto): ListViewOptions['actionDisplay'] {
    if (
        (view.options.display === 'boardV2' || view.options.display === 'cardV2') &&
        view.options.actionDisplay === 'buttons'
    ) {
        return 'dropdown'
    }

    return view.options.actionDisplay
}

export function getIncludedFieldsFromActionButtons(
    actionButtons: ActionButton[] = [],
    actions: ActionDto[] = []
): string[] {
    // Extract all fields from the action button conditions.
    const filterFieldApiNames = actionButtons.reduce((acc, button) => {
        const conditions = button.conditions ?? []
        for (const condition of conditions) {
            if (condition.field?.api_name && condition.field.type !== 'user_role') {
                acc.push(condition.field.api_name)
            }
        }
        const action = actions.find((action) => action._sid === button.id)
        if (action) {
            for (const step of action.options.steps.filter(
                (step) => step.type === 'updateRecord'
            )) {
                for (const stepField of step.fields) {
                    acc.push(stepField.fieldName)
                }
            }
        }

        return acc
    }, [] as string[])

    // Make them unique.
    return Array.from(new Set(filterFieldApiNames))
}

const EDITABLE_TYPES = new Set<FieldType>([
    'checkbox',
    'dropdown',
    'multi_select',
    'percentage',
    'date',
    'datetime',
    'lookup',
    'multi_lookup',
])

export function isFieldEditable(field: FieldDto): boolean {
    return !field.is_read_only && EDITABLE_TYPES.has(field.type)
}

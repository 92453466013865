import React, { useCallback, useRef } from 'react'

import { PickerResponse } from 'filestack-js'
// @ts-ignore - Filestack v3.1.0 is untyped
import Filestack from 'filestack-react'

import settings from 'app/settings'
import { useAppContext } from 'app/useAppContext'

// NB if upgrading to filestack-react 4.x.x - customRender and componentDisplayMode
// are deprecated. https://github.com/filestack/filestack-react#migration-from-3xx-and-4xx
type ReactFilestackProps = {
    options?: ReactFilestackOptions
    onSuccess?: (data: PickerResponse) => void
    customRender?: (options: { onPick: () => void }) => React.ReactNode
    componentDisplayMode?: {}
}

type ReactFilestackOptions = {
    fromSources?: string[]
    maxFiles?: number
    maxSize?: number
    accept?: string
    onCancel?: () => void
}

const ReactFilestackDefaultOptions: ReactFilestackOptions = {
    fromSources: [
        'local_file_system',
        'url',
        'webcam',
        'imagesearch',
        'unsplash',
        'facebook',
        'instagram',
        'googledrive',
        'dropbox',
    ],
    maxFiles: 1,
}

const ReactFilestack: React.FC<ReactFilestackProps> = ({
    options: optionsOverrides,
    onSuccess,
    customRender,
    componentDisplayMode,
}) => {
    const { selectedStack, workspaceAccount } = useAppContext()

    const observerRef = useRef<MutationObserver>()

    const onOpen = useCallback(() => {
        // Some of the modals use a focus lock and this will disable it so that users
        // can search for images within the filestack modal.

        // We continuously set these attributes because they will get reset if the filestack modal is rerendered.
        observerRef.current = new MutationObserver(() => {
            const filePicker = document.getElementById('__filestack-picker')
            if (!filePicker) return

            filePicker.setAttribute('data-no-focus-lock', 'true')

            if (!filePicker.classList.contains('click-outside-ignore')) {
                filePicker.classList.add('click-outside-ignore')
            }

            if (!filePicker.classList.contains('ag-custom-component-popup')) {
                filePicker.classList.add('ag-custom-component-popup')
            }
        })

        observerRef.current.observe(document.body, { childList: true, subtree: true })
    }, [])

    const onClose = useCallback(() => {
        observerRef.current?.disconnect()
        observerRef.current = undefined
    }, [])

    if (!workspaceAccount) {
        return null
    }

    const filestackPath = selectedStack
        ? `/astra/${workspaceAccount._sid}/${selectedStack._sid}/`
        : `/astra/${workspaceAccount._sid}/`

    const clientOptions = {
        cname: settings.FILESTACK.cname,
    }

    const actionOptions = {
        ...ReactFilestackDefaultOptions,
        ...optionsOverrides,
        onOpen,
        onClose,
        storeTo: {
            location: settings.FILESTACK.location,
            path: filestackPath,
        },
    }

    return (
        <Filestack
            apikey={settings.FILESTACK.key}
            clientOptions={clientOptions}
            actionOptions={actionOptions}
            onSuccess={onSuccess}
            customRender={customRender}
            componentDisplayMode={componentDisplayMode}
        />
    )
}

export default ReactFilestack

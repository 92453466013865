import React, { useMemo, useState } from 'react'

import { DateFilterContext } from './DateFilterContext'

type DateFilterContextProviderProps = React.PropsWithChildren<{}>
export const DateFilterContextProvider: React.FC<DateFilterContextProviderProps> = ({
    children,
}) => {
    const [specificDate, setSpecificDate] = useState<string | string[] | undefined>(undefined)

    const value = useMemo(
        () => ({
            specificDate,
            setSpecificDate,
        }),
        [specificDate, setSpecificDate]
    )

    return <DateFilterContext.Provider value={value}>{children}</DateFilterContext.Provider>
}

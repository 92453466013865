import React from 'react'

import { useStacks } from 'data/hooks/stacks'
import { StackIconBadge } from 'features/core/StackIconBadge'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'

import { Activity } from './Activity'

export type FeedItemProps = {
    activity: ActivityDto
    showAppIcon?: boolean
    showLocationPreview?: boolean
}

export function FeedItem({ activity, showAppIcon, showLocationPreview }: FeedItemProps) {
    return (
        <FeedItemContainer activity={activity} showAppIcon={showAppIcon}>
            <Activity
                activity={activity}
                startCollapsed
                collapsedHeight="8rem"
                showLocationPreview={showLocationPreview}
            />
        </FeedItemContainer>
    )
}

function FeedItemContainer({
    children,
    activity,
    showAppIcon,
}: {
    activity: ActivityDto
    children: React.ReactNode
    showAppIcon?: boolean
}) {
    const { data: stacks } = useStacks()
    const stack = stacks?.find((s) => s._sid === activity.stack_id)
    return (
        <Box width="full" mb="m" flex>
            {showAppIcon && stack && <StackIconBadge stack={stack} size={32} mr="l" />}

            <Container card flex column grow shrink overflow="hidden" pt="m">
                {children}
            </Container>
        </Box>
    )
}

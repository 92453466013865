import { BaseVariablesTheme } from 'ui/styling/baseVariables/BaseVariablesTheme.css'
import makeDefaultThemeVariables from 'ui/styling/themes/default'

function makeThemeVariables() {
    const s = BaseVariablesTheme

    const baseThemeOverrides = {
        color: {
            ...BaseVariablesTheme.color,
            theme50: s.color.admin50,
            theme100: s.color.admin100,
            theme200: s.color.admin200,
            theme300: s.color.admin300,
            theme400: s.color.admin400,
            theme500: s.color.admin500,
            theme600: s.color.admin600,
            theme700: s.color.admin700,
            theme800: s.color.admin800,
            theme900: s.color.admin900,
            theme950: s.color.admin950,
        },
    }

    return {
        ...makeDefaultThemeVariables(baseThemeOverrides),
    }
}

export default makeThemeVariables

import React, { useCallback } from 'react'

import { Text } from 'v2/ui'

import { Select, SelectOption } from 'ui/components/Select'

import { getSupportedCalculationTypes } from './utils'

type TableViewCalculationsControlsProps = {
    field: FieldDto
    calculationType?: ListViewColumnConfig['calculationType']
    onCalculationTypeChange?: (calculationType: ListViewColumnConfig['calculationType']) => void
}

export const TableViewCalculationsControls: React.FC<TableViewCalculationsControlsProps> = ({
    field,
    calculationType,
    onCalculationTypeChange,
}) => {
    const handleCalculationTypeChange = useCallback(
        (type: string) => {
            let newType = type as ListViewColumnConfig['calculationType']
            if (type === 'none') {
                newType = undefined
            }

            onCalculationTypeChange?.(newType)
        },
        [onCalculationTypeChange]
    )

    const supportedTypes = getSupportedCalculationTypes(field)

    return (
        <>
            <Text variant="paletteSectionLabel" mt={0} htmlFor="calculationType">
                Calculation
            </Text>
            <Select
                id="calculationType"
                size="m"
                value={calculationType || 'none'}
                onChange={handleCalculationTypeChange}
                mb="l"
            >
                <SelectOption value="none" label="None" />
                {supportedTypes.map((type) => (
                    <SelectOption key={type} value={type} label={getLabelForType(type)} />
                ))}
            </Select>
        </>
    )
}

function getLabelForType(type: ListViewColumnConfig['calculationType']): string {
    switch (type) {
        case 'count':
            return 'Count not empty'
        case 'countempty':
            return 'Count empty'
        case 'countall':
            return 'Count all'
        case 'sum':
            return 'Sum'
        case 'avg':
            return 'Average'
        case 'min':
            return 'Min'
        case 'max':
            return 'Max'
        case 'range':
            return 'Range'
        case 'median':
            return 'Median'
        default:
            return ''
    }
}

import { getWorkspaceAccount } from 'app/GlobalStaticState'
import publicAsset from 'utils/publicAsset'

export const getLogoUrl = (
    stack: StackDto,
    useIconIfNoLogo = false,
    defaultLogo: string | null = null
) => {
    if (defaultLogo === null) {
        defaultLogo = publicAsset('/static/media/stacker-logo-s-only.png')
    }
    const workspaceAccount = getWorkspaceAccount()
    const { options } = stack ?? {}

    let logo = options?.theme?.logo
    if (!logo && workspaceAccount) {
        if (workspaceAccount.options?.theme?.branding === 'logo') {
            logo = workspaceAccount.options?.theme?.logo
        } else if (workspaceAccount.options?.theme?.branding === 'logo_icon') {
            logo = workspaceAccount.options?.theme?.logo_icon
        }
    }

    if (!logo && useIconIfNoLogo) {
        if (!options.theme?.icon?.startsWith('fa') || options.theme?.icon?.includes('.')) {
            logo = options?.theme?.icon
        }
    }

    return logo || defaultLogo
}

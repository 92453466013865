import React, { useContext } from 'react'

import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import LayoutObjectEditor from 'features/utils/LayoutObjectEditor'

import DocumentRolesEditor from './DocumentRolesEditor'

const ROLES_EDITOR_TITLE = 'Roles'

type DocumenteViewEditorPanesProps = {
    onChange: (patch: Partial<ViewDto>, onCommitChange?: () => void) => void
    view?: ViewDto
    pageRoles: PageRole[]
    setPageRoles: (role: PageRole[]) => void
}

const DocumenteViewEditorPanes: React.FC<DocumenteViewEditorPanesProps> = ({
    pageRoles,
    setPageRoles,
}) => {
    const context = useContext(LayoutEditorContext)

    const isRolesSettings = context.activeEditor?.title === ROLES_EDITOR_TITLE

    return (
        <>
            <LayoutObjectEditor isOpen={isRolesSettings} title={ROLES_EDITOR_TITLE}>
                <DocumentRolesEditor pageRoles={pageRoles} setPageRoles={setPageRoles} />
            </LayoutObjectEditor>
        </>
    )
}

export default DocumenteViewEditorPanes

import { useCallback, useMemo, useState } from 'react'

import shortid from 'shortid'

import { Attachment } from './types'
import { UploadingAttachmentType } from './UploadingAttachment'

type UseAttachmentsResult = {
    attachments: Attachment[]
    uploadingAttachments: UploadingAttachmentType[]
    uploadAttachment: (blob: Blob) => void
    removeAttachment: (url: string) => Attachment[]
    clearAttachments: () => void
    onAttachmentUploaded: (id: string, attachment: Attachment) => Attachment[]
    setAttachments: (attachments: Attachment[]) => void
}

export function useAttachments(existing: Attachment[] = []): UseAttachmentsResult {
    const [attachments, setAttachments] = useState<Attachment[]>(existing)
    const [uploadingAttachments, setUploadingAttachments] = useState<UploadingAttachmentType[]>([])

    const uploadAttachment = useCallback((blob: Blob) => {
        const id = shortid.generate()
        setUploadingAttachments((existing) => [...existing, { id, blob }])
    }, [])

    const removeAttachment = useCallback(
        (url: string) => {
            const newAttachments = attachments.filter((a) => a.url !== url)

            setAttachments(newAttachments)
            setUploadingAttachments((existing) => existing.filter((a) => a.id !== url))

            return newAttachments
        },
        [attachments]
    )

    const onAttachmentUploaded = useCallback((id: string, attachment: Attachment) => {
        let newAttachments: Attachment[] = []

        setUploadingAttachments((existing) => existing.filter((x) => x.id !== id))
        setAttachments((existing) => {
            newAttachments = [...existing, attachment]

            return newAttachments
        })

        return newAttachments
    }, [])

    const clearAttachments = useCallback(() => {
        setAttachments([])
        setUploadingAttachments([])
    }, [])

    return useMemo(
        () => ({
            attachments,
            uploadingAttachments,
            uploadAttachment,
            removeAttachment,
            clearAttachments,
            onAttachmentUploaded,
            setAttachments,
        }),
        [
            attachments,
            uploadingAttachments,
            uploadAttachment,
            removeAttachment,
            clearAttachments,
            onAttachmentUploaded,
        ]
    )
}

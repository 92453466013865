// @ts-strict-ignore
import React, { useCallback, useState } from 'react'

import { startImportCsvTableJob } from 'data/api/csvApi'
import { prettifyCsvFileName } from 'features/ImportData/utils'
import ReactFilestack from 'utils/lazyComponents/ReactFilestack'

import PromptTableNameModal from './PromptTableNameModal'
import { useImportProgressModal } from './useImportProgressModal'

const MAX_CSV_SIZE_BYTES = 25 * 1024 * 1024 // 25 MiB

export type ImportCsvTableModalProps = {
    isOpen: boolean
    onSuccess: (objectId: string) => void
    onClose: () => void
    onError?: (errorDetails: any) => void
    onFileSelected?: () => void
    onTableNameSaved?: () => void
    // optionally pass in the tableName which skips asking for table name separately
    inputTableName?: string
}
type ImportStep = 'promptFile' | 'promptTableName' | 'import'

export const ImportCsvTableModal = (props: ImportCsvTableModalProps) => {
    const {
        isOpen,
        onClose,
        onSuccess,
        onError,
        onFileSelected,
        onTableNameSaved,
        inputTableName,
    } = props
    const [currentStep, setStep] = useState<ImportStep>('promptFile')
    const [uploadedFileUrl, setUploadedFileUrl] = useState<string>()
    const [tableName, setTableName] = useState<string>()
    const [tableNameError, setTableNameError] = useState<string>()
    const { show: showImportProgressModal } = useImportProgressModal()

    const startImport = useCallback(
        (tableName: string, fileUrl: string) => {
            startImportCsvTableJob(fileUrl, tableName).then((r) => {
                setStep('import')
                showImportProgressModal({
                    jobId: r.job,
                    onImportCompleted: (objectId) => {
                        setStep('promptFile')
                        onSuccess(objectId)
                    },
                    onImportError: (errorDetails: any) => {
                        setStep('promptFile')
                        onError?.(errorDetails)
                    },
                })
            })
        },
        [showImportProgressModal, onSuccess, onError]
    )

    const handleTableNameSubmit = useCallback(() => {
        if (!tableName) {
            setTableNameError('Please enter a table name')
            return
        }
        uploadedFileUrl && startImport(tableName, uploadedFileUrl)

        onTableNameSaved?.()
    }, [tableName, uploadedFileUrl, startImport, onTableNameSaved])

    if (!isOpen) {
        return null
    }

    return (
        <>
            {currentStep === 'promptFile' && (
                <ReactFilestack
                    componentDisplayMode={{
                        type: 'immediate',
                    }}
                    options={{
                        fromSources: ['local_file_system', 'url', 'googledrive', 'dropbox'],
                        accept: '.csv',
                        maxSize: MAX_CSV_SIZE_BYTES,
                        onCancel: onClose,
                    }}
                    onSuccess={(value) => {
                        const uploadedFile = value.filesUploaded[0]
                        setUploadedFileUrl(uploadedFile.url)
                        onFileSelected?.()
                        if (inputTableName) {
                            // if a table name is provided, skip asking for table name.
                            startImport(inputTableName, uploadedFile.url)
                        } else {
                            setTableName(prettifyCsvFileName(uploadedFile.filename ?? ''))
                            setStep('promptTableName')
                        }
                    }}
                />
            )}
            <PromptTableNameModal
                isOpen={currentStep === 'promptTableName'}
                value={tableName}
                onChange={setTableName}
                onSubmit={handleTableNameSubmit}
                error={tableNameError}
            />
        </>
    )
}

import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query'

import { buildQueryKey, queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import handleErrorResponse from 'data/utils/handleErrorResponse'
import { fetchAndReturn } from 'data/utils/utils'

const LIST_NAME = 'workflows/integrations'

function buildQuery() {
    return buildQueryKey([LIST_NAME], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

function useQueryKey() {
    return useQueryKeyBuilder([LIST_NAME], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

type WorkflowIntegrationData = {
    connections: WorkflowIntegrationConnectionDto[]
    providers: WorkflowIntegrationProviderDto[]
    connection_id: string
}

export function useWorkflowIntegrations(options: UseQueryOptions<WorkflowIntegrationData> = {}) {
    const queryKey = useQueryKey()

    return useQuery<WorkflowIntegrationData>(
        queryKey,
        async () => {
            return fetchAndReturn(`flows/integrations/`) as Promise<WorkflowIntegrationData>
        },
        options
    )
}

export function invalidateWorkflowIntegrations() {
    return queryClient.invalidateQueries(LIST_NAME)
}

export async function addNewConnectionToCache(connection: WorkflowIntegrationConnectionDto) {
    const queryKey = buildQuery()
    await queryClient.cancelQueries({ queryKey })

    const previousPayload = queryClient.getQueryData<WorkflowIntegrationData>(queryKey) ?? {
        connections: [],
        providers: [],
        connection_id: '',
    }

    const existingConnections = previousPayload.connections.filter((c) => c.id !== connection.id)

    const updatedPayload: WorkflowIntegrationData = {
        ...previousPayload,
        connections: [...existingConnections, connection],
    }
    queryClient.setQueryData(queryKey, updatedPayload)
}

export type DeleteIntegrationPayload = {
    connectionId: string
    providerId: string
}

export function useDeleteIntegration(
    options: UseMutationOptions<unknown, unknown, DeleteIntegrationPayload> = {}
) {
    const queryKey = useQueryKey()

    return useMutation(
        async (payload: DeleteIntegrationPayload) => {
            const { connectionId, providerId } = payload

            const res = await fetchWithAuth(
                `flows/integrations/${providerId}/${encodeURIComponent(connectionId)}`,
                {
                    method: 'DELETE',
                }
            )

            if (res.status >= 400) {
                handleErrorResponse(res)
                return Promise.reject(res)
            }

            return Promise.resolve()
        },
        {
            ...options,
            onMutate: async (payload: DeleteIntegrationPayload) => {
                await queryClient.cancelQueries({ queryKey })

                const previousPayload = queryClient.getQueryData<WorkflowIntegrationData>(queryKey)
                if (previousPayload) {
                    const updatedPayload: WorkflowIntegrationData = {
                        ...previousPayload,
                        connections: previousPayload.connections.filter(
                            (c) => c.id !== payload.connectionId
                        ),
                    }
                    queryClient.setQueryData(queryKey, updatedPayload)
                }

                return { previousPayload }
            },
            onError: (err, payload, context?: { previousPayload?: WorkflowIntegrationData }) => {
                queryClient.setQueryData(queryKey, context?.previousPayload)

                options?.onError?.(err, payload, context)
            },
            onSettled: () => {
                queryClient.invalidateQueries([LIST_NAME])
            },
        }
    )
}

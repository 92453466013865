import React from 'react'

import { ContextGroup, ContextSchema } from 'features/expressions/types'

import { ConditionGroupInput } from './ConditionGroupInput'
import { ConditionGroup } from './types'

type ConditionInputProps = {
    contextSchema: ContextSchema
    value?: ConditionGroup
    onChange: (value: ConditionGroup) => void
    renderGroupTitle: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
    disabled?: boolean
}

export const ConditionInput: React.FC<ConditionInputProps> = ({
    contextSchema,
    value,
    onChange,
    renderGroupTitle,
    disabled,
}) => {
    return (
        <ConditionGroupInput
            contextSchema={contextSchema}
            value={value}
            onChange={onChange}
            renderGroupTitle={renderGroupTitle}
            disabled={disabled}
        />
    )
}

import React from 'react'

export type DropdownContextValue = {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}
export const DropdownContext = React.createContext<DropdownContextValue | null>(null)

export function useDropdownContext() {
    return React.useContext(DropdownContext)
}

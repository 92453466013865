import React from 'react'

import { getUrl } from 'app/UrlService'
import { useStacks } from 'data/hooks/stacks'

import { LinkButton } from 'ui/components/LinkButton'

type Props = React.ComponentPropsWithoutRef<typeof LinkButton> & {
    document: Partial<DocumentDto>
}

export function DocumentLink({ document, ...props }: Props) {
    const { data: stacks } = useStacks()
    const stack = stacks?.find((stack) => stack._sid === document.stack_id)

    if (!stack) return null

    return (
        <LinkButton
            to={getUrl(`/docs/${document.auto_id}`, stack)}
            variant="link"
            size="2xs"
            title={document.title}
            startIcon={{ name: 'Notebook' }}
            {...props}
        >
            {document.title || 'Untitled'}
        </LinkButton>
    )
}

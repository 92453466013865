import React, { useState } from 'react'

import { CommentCount } from 'features/Activity/CommentCount'
import { useIsCommentCountVisible } from 'features/Activity/useIsCommentCountVisible'
import { RecordTaskCount } from 'features/tasks/RecordTaskCount'
import { useTasksEnabled } from 'features/tasks/useTasksEnabled'

import { Box } from 'ui/components/Box'

type RecordStatsIconsProps = {
    record: RecordDto
    object?: ObjectDto
}

export function RecordStatsIcons({ record, object }: RecordStatsIconsProps) {
    const [commentsActive, setCommentsActive] = useState(false)
    const [tasksActive, setTasksActive] = useState(false)
    const tasksEnabled = useTasksEnabled()
    const alwaysShow = commentsActive || tasksActive

    const isCommentCountVisible = useIsCommentCountVisible({ object, record })

    if (!isCommentCountVisible && !tasksEnabled) return null

    return (
        <Box flex center justifyContent="flex-end" style={{ height: '18px', width: '48px' }}>
            {isCommentCountVisible && (
                <CommentCount
                    record={record}
                    alwaysShow={alwaysShow}
                    setIsActive={setCommentsActive}
                />
            )}
            {tasksEnabled && (
                <RecordTaskCount
                    record={record}
                    ml="xs"
                    alwaysShow={alwaysShow}
                    setIsActive={setTasksActive}
                />
            )}
        </Box>
    )
}

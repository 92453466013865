import React, { useEffect, useMemo } from 'react'

import { useRecords } from 'data/hooks/records'
import { InlineFilter } from 'features/records/components/RecordFilters/inlineFilters/InlineFilter'
import { InlineFilterMultiValueOption } from 'features/records/components/RecordFilters/inlineFilters/types'
import { useInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/useInlineFilter'
import { getInlineFilterValueLabels } from 'features/records/components/RecordFilters/inlineFilters/valueUtils'
import { recordsToOptions } from 'features/records/components/recordsToOptions'

import { LookupFilterContextProvider } from './LookupFilterContextProvider'
import type { InlineFilterComponent } from './types'
import { useLookupFilter } from './useLookupFilter'

const LookupInlineFilterInternal: InlineFilterComponent<{}> = ({ field, columns }) => {
    const { searchString, setIsFetching } = useLookupFilter()

    const fetchOptions = {
        includeFields: ['_primary'],
        pageSize: 50,
        forcePartials: true,
    }

    const filters = useMemo((): Filter[] | undefined => {
        if (searchString) {
            return [
                {
                    field: { api_name: '_search' },
                    options: { value: searchString, operator: 'AND' },
                },
            ]
        }

        return undefined
    }, [searchString])

    const { filter, ...inlineFilterProps } = useInlineFilter({ field })

    const { data: { records = [] } = {}, isFetching } = useRecords({
        objectSid: field.link_target_object_id ?? '',
        filters,
        fetchOptions,
        options: { refetchOnMount: true }, // only remount if the data is stale
    })
    const options: InlineFilterMultiValueOption[] = useMemo(
        () => recordsToOptions(records),
        [records]
    )

    const valueLabels = useMemo(
        () => getInlineFilterValueLabels(filter, options),
        [filter, options]
    )

    useEffect(() => {
        setIsFetching(isFetching)
    }, [isFetching, setIsFetching])

    return (
        <InlineFilter
            field={field}
            columns={columns}
            valueLabels={valueLabels}
            options={options}
            {...inlineFilterProps}
        />
    )
}

export const LookupInlineFilter: typeof LookupInlineFilterInternal = (props) => {
    return (
        <LookupFilterContextProvider>
            <LookupInlineFilterInternal {...props} />
        </LookupFilterContextProvider>
    )
}

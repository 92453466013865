import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Editor, Node, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { MentionElement } from 'features/collaboration/MentionElement'
import { Portal } from 'features/collaboration/MentionsPortal'

import { Button } from 'v2/ui'

export const useMentions = (users) => {
    const ref = useRef()
    const [target, setTarget] = useState()
    const [index, setIndex] = useState(0)
    const [search, setSearch] = useState('')
    const [editor, setEditor] = useState()

    const options = users
        .filter((u) => {
            if (!u.email) return false
            return (
                String(u.name || u.email)
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) >= 0
            )
        })
        .slice(0, 10)

    useEffect(() => {
        if (target && options.length > 0) {
            const el = ref.current
            const domRange = ReactEditor.toDOMRange(editor, target)
            const rect = domRange.getBoundingClientRect()
            el.style.top = `${rect.top + window.pageYOffset + 24}px`
            el.style.left = `${rect.left + window.pageXOffset}px`
        }
    }, [options.length, editor, index, search, target])

    const initialize = useCallback(
        (editor) => {
            setEditor(editor)
            const { isInline, isVoid } = editor

            editor.isInline = (element) => {
                return element.type === 'mention' ? true : isInline(element)
            }

            editor.isVoid = (element) => {
                return element.type === 'mention' ? true : isVoid(element)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editor]
    )
    const handleChange = () => {
        const { selection } = editor

        if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection)
            const wordBefore = Editor.before(editor, start, { unit: 'word' })
            const before = wordBefore && Editor.before(editor, wordBefore)
            const beforeRange = before && Editor.range(editor, before, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
            const after = Editor.after(editor, start)
            const afterRange = Editor.range(editor, start, after)
            const afterText = Editor.string(editor, afterRange)
            const afterMatch = afterText.match(/^(\s|$)/)

            if (beforeMatch && afterMatch) {
                setTarget(beforeRange)
                setSearch(beforeMatch[1])
                setIndex(0)
                return
            }
        }

        setTarget(null)
    }

    const insertMention = (user) => {
        Transforms.select(editor, target)
        const mention = {
            type: 'mention',
            id: user._sid,
            name: user.name || user.email,
            children: [{ text: `@${user.name || user.email} ` }],
        }
        Transforms.insertNodes(editor, mention)
        Transforms.move(editor)
    }

    const handleOnKeyDown = (event) => {
        if (!target) return
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault()
                const prevIndex = index >= options.length - 1 ? 0 : index + 1
                setIndex(prevIndex)
                break
            case 'ArrowUp':
                event.preventDefault()
                const nextIndex = index <= 0 ? options.length - 1 : index - 1
                setIndex(nextIndex)
                break
            case 'Tab':
            case 'Enter':
                event.preventDefault()
                event.stopPropagation()
                insertMention(options[index])
                setTarget(null)
                break
            case 'Escape':
                event.preventDefault()
                setTarget(null)
                break
        }
    }

    const components = target && options.length > 0 && (
        <Portal>
            <div
                ref={ref}
                style={{
                    top: '-9999px',
                    left: '-9999px',
                    position: 'absolute',
                    zIndex: 10000,
                    padding: '3px',
                    background: 'white',
                    borderRadius: '4px',
                    boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                }}
            >
                {options.map((option, i) => (
                    <Button
                        display="block"
                        variant="clear"
                        size="sm"
                        key={option.id}
                        onClick={(e) => {
                            insertMention(option)
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        style={{
                            padding: '1px 3px',
                            borderRadius: '3px',
                            background: i === index ? '#B4D5FF' : 'transparent',
                        }}
                    >
                        {option.name || option.email}
                    </Button>
                ))}
            </div>
        </Portal>
    )

    const renderElement = (props) => {
        const { element } = props
        switch (element.type) {
            case 'mention':
                return <MentionElement {...props} />
            default:
                return null
        }
    }

    const getMentions = useCallback(() => {
        if (!editor) return []
        const result = Array.from(Node.descendants(editor))
            .map((x) => x[0])
            .filter((node) => node.type === 'mention')

        return result
    }, [editor])

    return {
        onKeyDown: handleOnKeyDown,
        onChange: handleChange,
        components,
        initialize,
        renderElement,
        getMentions,
    }
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Input } from 'ui/components/Input'
import { theme } from 'ui/styling/Theme.css'

const MAX_LENGTH = 100

type AppUsersSearchBarProps = React.ComponentPropsWithoutRef<typeof Input> & {}

export const AppUsersSearchBar: React.FC<AppUsersSearchBarProps> = ({
    style,
    disabled,
    ...props
}) => {
    return (
        <Box flex center gap="m" minWidth={0}>
            <Icon name="Search" size="m" noShrink color={disabled ? 'iconDisabled' : 'icon'} />
            <Input
                size="m"
                grow
                variant="borderless"
                placeholder="Search for users..."
                minWidth={0}
                style={{
                    ...style,
                    lineHeight: theme.lineHeights.bodyM,
                    backgroundColor: 'transparent',
                    minWidth: 0,
                }}
                disabled={disabled}
                maxLength={MAX_LENGTH}
                {...props}
            />
        </Box>
    )
}

import React from 'react'

import { Body } from 'ui/components/Text'

import { useAdvancedFilterValuePercentageState } from './hooks/useAdvancedFilterValuePercentageState'
import { AdvancedFilterInput } from './AdvancedFilterInput'
import { AdvancedFilter } from './types'

const MAX_LENGTH = 30

type AdvancedFilterValuePercentageProps = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
    className?: string
}

export const AdvancedFilterValuePercentage: React.FC<AdvancedFilterValuePercentageProps> = ({
    filter,
    onChange,
    className,
    onRemoveFilter,
}) => {
    const { onInputChange, onInputBlur, onInputKeyDown, value, inputRef } =
        useAdvancedFilterValuePercentageState({
            filter,
            onChange,
            onRemoveFilter,
        })

    const inputValue = value[0]

    return (
        <AdvancedFilterInput
            ref={inputRef}
            value={inputValue}
            onChange={onInputChange}
            onBlur={onInputBlur}
            onKeyDown={onInputKeyDown}
            maxLength={MAX_LENGTH}
            className={className}
            suffix={
                !!inputValue && (
                    <Body size={{ mobile: 's', tablet: 'm' }} weight="medium">
                        %
                    </Body>
                )
            }
            autoFocus={filter.isDraft}
        />
    )
}

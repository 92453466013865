import React from 'react'

import { useAppContext } from 'app/useAppContext'
import { useObject } from 'data/hooks/objects'
import { useGetRecord } from 'data/hooks/records'

import { layouts as layoutSettings } from 'v2/ui/utils/attributeSettings'

import { getLinkComponentFromUrl } from './link_utils'

export const RecordLinkDisplay = ({
    recordId,
    record,
    noLink = false,
    linkText = '',
    layout,
}: {
    recordId: string
    record?: RecordDto
    noLink?: boolean
    linkText?: string
    layout?: keyof typeof layoutSettings
}): React.ReactElement | null => {
    const { data: recordToLoad } = useGetRecord({
        recordId,
        includeFields: [],
        useQueryOptions: { enabled: !record },
    })
    const { selectedStack } = useAppContext()
    const recordToUse = record ?? recordToLoad
    const { object } = useObject(recordToUse?._object_id)

    // By convention record detail page will be whatever app we're in /object.api_name/{id}
    const url = `${object?.url}/view/${record?._sid}`

    if (!record) {
        return null
    }

    return getLinkComponentFromUrl({
        display: linkText || record._primary,
        url,
        layout,
        stack: selectedStack ?? undefined,
        // if object is empty but we're still rendering this component, then this is data on
        // a shared data with the target object not imported, so we don't want the links to be clickable
        noLink: noLink || !object,
    })
}

import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Rights } from 'app/accountUserContextConstants'
import { getWorkspaceUrl, Urls } from 'app/UrlService'
import { useAccountUserContext } from 'app/useAccountUserContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import useLDFlags from 'data/hooks/useLDFlags'
import { useWorkspaceZones } from 'data/hooks/zones'
import { useOmnibarContext } from 'features/Omnibar/OmnibarContext'
import { TasksQuickCreatePopoverButton } from 'features/tasks/TasksQuickCreatePopoverButton'
import { useTasksEnabled } from 'features/tasks/useTasksEnabled'
import { ZoneLogo } from 'features/workspace/zones/ZoneLogo'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Icon } from 'ui/components/Icon'
import { LinkButton, LinkButtonProps } from 'ui/components/LinkButton'
import { FadeInOnHoverStyle } from 'ui/styles/hoverUtils.css'
import { theme } from 'ui/styling/Theme.css'

import { CreateZoneModal } from './ZoneMenu/CreateZoneModal'
import { ZoneDropdown } from './ZoneMenu/ZoneDropdown'
import NotificationButton from './NotificationButton'

export const WorkspaceSidebarTop = () => {
    const { setIsOpen: setIsOmnibarOpen } = useOmnibarContext()
    const { flags } = useLDFlags()
    const { workspaceZone } = useWorkspaceContext()
    const tasksEnabled = useTasksEnabled()
    const hideNotifications = !flags.notifications || workspaceZone?.type === 'Portal'

    const { data: zones = [] } = useWorkspaceZones()
    const internalZone = useMemo(() => zones.find((zone) => zone.type === 'Internal'), [zones])
    const portals = useMemo(() => zones.filter((zone) => zone.type === 'Portal'), [zones])

    const { hasRight } = useAccountUserContext()
    const canCreateZones = hasRight(Rights.ManageSettings)
    const canViewSettings = hasRight(Rights.ViewSettings)
    const showZoneSwitcher = zones.length > 1 || canCreateZones

    return (
        <>
            <Box
                pl="m"
                flex
                center
                color="text"
                pt="s"
                pb={flags.search || tasksEnabled ? 's' : undefined}
            >
                <Box grow shrink as={Link} to={getWorkspaceUrl(Urls.Home)} color="text">
                    {workspaceZone && (
                        <ZoneLogo
                            zone={workspaceZone}
                            style={{
                                maxHeight: '32px',
                                maxWidth: theme.space['10xl'],
                                margin: '0px',
                            }}
                            isSidebar
                        />
                    )}
                </Box>
                {showZoneSwitcher && (
                    <>
                        <ZoneDropdown
                            internalZone={internalZone}
                            portals={portals}
                            canCreateZones={canCreateZones}
                            canViewSettings={canViewSettings}
                        />
                        <CreateZoneModal />
                    </>
                )}
                {!hideNotifications && (
                    <NotificationButton noShrink mr="xs" size="xs" style={{ zIndex: 10 }} />
                )}
            </Box>
            {flags.search && (
                <SidebarButton
                    startIcon={{ name: 'Search' }}
                    onClick={() => setIsOmnibarOpen(true)}
                >
                    Search
                </SidebarButton>
            )}

            {tasksEnabled && (
                <SidebarButton
                    startIcon={{ name: 'CalendarCheck' }}
                    to={getWorkspaceUrl(Urls.Tasks)}
                    containerProps={{
                        flex: true,
                        center: true,
                        shrink: false,
                        width: 'full',
                        justifyContent: 'space-between',
                    }}
                    wrapperProps={{
                        width: 'full',
                    }}
                >
                    <Box as="span" display="block">
                        Tasks
                    </Box>
                    <TasksQuickCreatePopoverButton
                        buttonProps={{
                            style: {
                                width: theme.space.xl,
                                height: theme.space.xl,
                                zIndex: 10,
                            },
                            variant: 'ghost',
                            startIcon: { name: 'Plus' },
                            className: FadeInOnHoverStyle,
                        }}
                        label="Add new task"
                    />
                </SidebarButton>
            )}
        </>
    )
}

type SideBarButtonProps = LinkButtonProps & {
    containerProps?: BoxProps
    wrapperProps?: BoxProps
}

function SidebarButton({
    startIcon,
    containerProps,
    wrapperProps,
    style,
    ...props
}: SideBarButtonProps) {
    return (
        <LinkButton
            variant="ghost"
            size="s"
            maxWidth="full"
            style={{
                borderRadius: 0,
                justifyContent: 'flex-start',
                ...style,
            }}
            {...props}
        >
            <Box flex center maxWidth="full" {...wrapperProps}>
                <Box flex column center width="2xl" mr="xs" noShrink>
                    {startIcon && <Icon style={{ height: 14, width: 14 }} {...startIcon} />}
                </Box>
                <Box shrink trim style={{ fontSize: 15 }} {...containerProps}>
                    {props.children}
                </Box>
            </Box>
        </LinkButton>
    )
}

import React, { useContext } from 'react'

import styled from '@emotion/styled'

import { useAppContext } from 'app/useAppContext'
import { BreadcrumbsMode } from 'features/utils/BreadcrumbsMode'
import { HeaderContext } from 'features/utils/HeaderContext'

import { Box, Flex, Link } from 'v2/ui'
import { BackArrow, TriangleRight } from 'v2/ui/svgs'

const StyledLink = styled(Link)<{ isLastItem?: boolean }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${(props) => (props.isLastItem ? '100%' : 'calc(100% - 22px)')};
    padding: 5px 0 0;
    margin: 0 0 5px;
    word-break: break-word;
`
export type BreadcrumbsItem = {
    key: string
    state?: {
        objectId?: string
        prev?: any
        title?: string
        type?: string
    }
    disabled?: boolean
    pathname?: string
}
export type BreadcrumbsProps = {
    items: BreadcrumbsItem[]
    showBackButton?: boolean
    linkVariant: 'breadcrumb'
    arrowColor?: string
    backArrowColor?: string
    [key: string]: any
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    items,
    showBackButton,
    linkVariant = 'breadcrumb',
    ...props
}) => {
    const context = useContext(HeaderContext)
    const colorMode = context?.colorMode || 'normal'
    const arrowColor = props.arrowColor || `breadcrumbs.${colorMode}.arrowColor`
    const backArrowColor = props.backArrowColor || `breadcrumbs.${colorMode}.linkColor`
    const { selectedStack } = useAppContext()
    const hideBreadcrumbs =
        selectedStack?.options?.showBreadcrumbs === false ||
        context?.breadcrumbsMode === BreadcrumbsMode.Hidden

    if (hideBreadcrumbs || !items.length) return null

    const breadcrumbsMode = context?.breadcrumbsMode ?? BreadcrumbsMode.Full
    const shouldDisplayBackButton =
        showBackButton &&
        (breadcrumbsMode === BreadcrumbsMode.Full ||
            breadcrumbsMode === BreadcrumbsMode.BackButtonOnly)
    const shouldDisplayItems = breadcrumbsMode === BreadcrumbsMode.Full && !shouldDisplayBackButton

    return (
        <Flex {...props}>
            {shouldDisplayBackButton && (
                <>
                    <Box mr={2} stroke={backArrowColor} mt="-2px" h="10px" as={BackArrow} />
                    <StyledLink colorMode={colorMode} variant={linkVariant} to={items[0]}>
                        Back to {items[0]?.state?.title}
                    </StyledLink>
                </>
            )}

            {shouldDisplayItems &&
                items.map((item, index) => {
                    const { key, state = {}, disabled, pathname } = item
                    const isLastItem = index === items.length - 1
                    return (
                        <React.Fragment key={`${key}-breadcrumb`}>
                            <StyledLink
                                isLastItem={isLastItem}
                                colorMode={colorMode}
                                variant={linkVariant}
                                to={item}
                                key={key}
                                disabled={disabled || !pathname}
                            >
                                {state?.title}
                            </StyledLink>
                            {index < items.length - 1 && (
                                <Box
                                    // @ts-expect-error
                                    colorMode={colorMode}
                                    mx={2}
                                    fill={arrowColor}
                                    size="6px"
                                    as={TriangleRight}
                                />
                            )}
                        </React.Fragment>
                    )
                })}
        </Flex>
    )
}

export default Breadcrumbs

import React from 'react'
import ReactTooltip from 'react-tooltip'

import PropTypes from 'prop-types'

import { Span } from './Span'

export const Tooltip = ({ id, children, tooltip, align, textAlign }) => {
    return (
        <>
            <ReactTooltip id={id} effect="solid" place={align}>
                <div
                    style={{
                        maxWidth: '200px',
                        textAlign: { textAlign },
                    }}
                >
                    <Span size="fontS" padding="none" margin="none" style={{ color: 'white' }}>
                        {tooltip}
                    </Span>
                </div>
            </ReactTooltip>
            <span data-tip data-for={id}>
                {children}
            </span>
        </>
    )
}

Tooltip.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    tooltip: PropTypes.string.isRequired,
    textAlign: PropTypes.string,
    align: PropTypes.string,
}

Tooltip.defaultProps = {
    textAlign: 'left',
    align: 'left',
}

import React, { useContext, useEffect, useMemo } from 'react'

import styled from '@emotion/styled'

import { getStackTheme } from 'app/Branding'
import { useAppContext } from 'app/useAppContext'
import { HeaderContext } from 'features/utils/HeaderContext'
import { getHeaderContextColorMode } from 'features/views/ViewHeader/viewHeaderUtils'
import { ThemeConfig } from 'features/workspace/types'

import { Box, Heading, RichTextEditor, Text } from 'v2/ui'

import ViewHeaderWrapper from './ViewHeaderWrapper'

const StyledViewHeaderWrapper = styled(ViewHeaderWrapper as any)`
    z-index: 2;
    position: relative;
    text-align: center;
`

const Overlay = styled.div<{
    bgColor: string
    overlayColor: string
    bgPos?: string
    bgImage?: string
}>`
    position: absolute;
    top: -1px;
    height: 100%;
    width: 100%;
    background-color: ${({ bgColor }) => bgColor};
    ${({ bgImage }) => bgImage && `background-image: url(${bgImage})`};
    ${({ bgPos }) => bgPos && `background-position: ${bgPos}`};
    background-size: cover;
    z-index: 0;
    transition: background-position 0.75s cubic-bezier(0.67, 0.21, 0.46, 1.03);

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: ${({ overlayColor }) => overlayColor};
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
    }
`

const Title = styled(Heading)`
    max-width: 100%;
`

const Subtitle = styled(Text)`
    max-width: 800px;
    margin: 0 auto;
`

const height = {
    small: ['150px', null, null, '200px'],
}

function getTitleColor(headerOptions: PageHeaderImage) {
    if (!headerOptions.src && !headerOptions.showBackgroundColor) {
        return 'neutral.1100'
    }

    if (headerOptions.colorMode === 'dark') return 'neutral.0'

    return 'neutral.1000'
}

function getSubtitleColor(headerOptions: PageHeaderImage) {
    if (!headerOptions.src && !headerOptions.showBackgroundColor) {
        return 'neutral.1100'
    }

    if (headerOptions.colorMode === 'dark') return 'neutral.150'

    return 'neutral.950'
}

function getBackgroundColor(headerOptions: PageHeaderImage, theme: ThemeConfig) {
    if (headerOptions.showBackgroundColor) return theme.brandColor

    return 'transparent'
}

function getOverlayColor(headerOptions: PageHeaderImage) {
    if (headerOptions.showBackgroundColor) return 'rgba(0, 0, 0, 0.25)'

    if (headerOptions.src) {
        if (!headerOptions.showOverlay) return 'transparent'

        if (headerOptions.colorMode === 'dark') return 'rgba(0, 0, 0, 0.6)'

        return 'rgba(255, 255, 255, 0.6)'
    }

    return 'transparent'
}

type ViewHeaderImageProps = {
    headerOptions: PageHeaderImage
}

const ViewHeaderImage: React.FC<ViewHeaderImageProps> = ({ headerOptions }) => {
    const { selectedStack } = useAppContext()
    const theme = useMemo(() => getStackTheme(selectedStack), [selectedStack])

    const { title, subtitle, src, position, size, colorMode, showBackgroundColor } = headerOptions

    const headerHeight = height[size!] ?? height.small
    const headerTitleColor = getTitleColor(headerOptions)
    const headerSubtitleColor = getSubtitleColor(headerOptions)

    const backgroundColor = getBackgroundColor(headerOptions, theme)
    const overlayColor = getOverlayColor(headerOptions)

    const headerContext = useContext(HeaderContext)

    const desiredHeaderContextMode = getHeaderContextColorMode(headerOptions)
    useEffect(() => {
        if (!headerContext) return

        headerContext.setColorMode(desiredHeaderContextMode)
        headerContext.setHeaderImageVisible(Boolean(src))
    }, [headerContext, desiredHeaderContextMode, src])

    useEffect(() => {
        return () => {
            if (!headerContext) return

            headerContext.reset()
        }
    }, [headerContext])

    return (
        <Box
            pt={[16, null, null, 24]}
            pb={[12, null, null, 20]}
            height={headerHeight}
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Overlay
                bgColor={backgroundColor}
                overlayColor={overlayColor}
                bgPos={position}
                bgImage={showBackgroundColor ? undefined : src}
                id={colorMode === 'dark' ? 'headerDarkMode' : 'headerLightMode'}
            />
            <StyledViewHeaderWrapper>
                {title && (
                    <Title
                        as="h1"
                        fontSize={[650, null, null, 800]}
                        color={headerTitleColor}
                        value={title}
                        mt={0}
                        mb={subtitle ? 4 : 0}
                    />
                )}
                {subtitle && (
                    <Subtitle fontSize={[300, null, null, 400]} color={headerSubtitleColor}>
                        <RichTextEditor containerHeadingSize={false}>{subtitle}</RichTextEditor>
                    </Subtitle>
                )}
            </StyledViewHeaderWrapper>
        </Box>
    )
}

export default ViewHeaderImage

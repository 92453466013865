import React from 'react'
export type AuthContextData = {
    user?: AuthedUserDto
    setUser: (user: AuthedUserDto) => void
    logOut: () => void
    tenantId?: string
    isEmailVerified: boolean
}

export const AuthContext = React.createContext<AuthContextData>({} as AuthContextData)

export const useAuthContext = () => React.useContext(AuthContext)

import React, { useCallback, useMemo } from 'react'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

import { getLocale } from 'utils/date/dateUtils'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import * as Parts from './DatePicker.parts'
import { SegmentSelector } from './SegmentSelector'
import { constrainDateValue, getAllMonths, getAllYears } from './utils'

type CalendarHeaderProps = ReactDatePickerCustomHeaderProps & {
    minDate?: Date
    maxDate?: Date
}

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    minDate,
    maxDate,
    monthDate,
}) => {
    const locale = getLocale()

    const monthOptions = useMemo(() => {
        const currentYear = monthDate.getFullYear()
        const allMonths = getAllMonths(locale, currentYear, minDate, maxDate)

        return allMonths.map(([value, label]) => ({
            value,
            label,
        }))
    }, [monthDate, locale, minDate, maxDate])

    const currentMonth = useMemo(
        () => monthOptions.find((y) => y.value === (monthDate.getMonth() + 1).toString()),
        [monthOptions, monthDate]
    )

    const onMonthChange = useCallback(
        (month: string) => {
            const newMonth = monthOptions.find((m) => m.value === month)
            if (!newMonth) return

            changeMonth(parseInt(newMonth.value) - 1)
        },
        [monthOptions, changeMonth]
    )

    const yearOptions = useMemo(() => {
        const allYears = getAllYears(locale, minDate, maxDate)

        return allYears.map((y) => ({
            value: y,
            label: y,
        }))
    }, [locale, maxDate, minDate])

    const currentYear = useMemo(
        () => yearOptions.find((y) => y.value === monthDate.getFullYear().toString()),
        [monthDate, yearOptions]
    )

    const onYearChange = useCallback(
        (year: string) => {
            const newYear = yearOptions.findIndex((y) => y.value === year)
            if (newYear < 0) return

            let constrainedNewDate = new Date(monthDate)
            constrainedNewDate.setFullYear(parseInt(year))
            constrainedNewDate = constrainDateValue(constrainedNewDate, minDate, maxDate)

            changeYear(constrainedNewDate.getFullYear())
            // Make sure the new month is within the min/max date range.
            if (constrainedNewDate.getMonth() !== monthDate.getMonth()) {
                changeMonth(constrainedNewDate.getMonth())
            }
        },
        [changeMonth, changeYear, maxDate, minDate, monthDate, yearOptions]
    )

    return (
        <Parts.CalendarHeader>
            <Button
                variant="ghost"
                size="s"
                startIcon={{ name: 'ChevronLeft' }}
                disabled={prevMonthButtonDisabled}
                onClick={decreaseMonth}
            />
            <Box flex center>
                <SegmentSelector
                    value={currentMonth?.value}
                    options={monthOptions}
                    onChange={onMonthChange}
                />
                <SegmentSelector
                    value={currentYear?.value}
                    options={yearOptions}
                    onChange={onYearChange}
                />
            </Box>
            <Button
                variant="ghost"
                size="s"
                startIcon={{ name: 'ChevronRight' }}
                disabled={nextMonthButtonDisabled}
                onClick={increaseMonth}
            />
        </Parts.CalendarHeader>
    )
}

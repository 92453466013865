/* Code Quality: Not audited */

import { Component } from 'react'

class PropertyStore extends Component {
    /*

        The property store acts as central state store for the page (or sub-region of the page).

        It allows blocks to expose properties and actions that can be accessed by other blocks
        somewhere else on the page.

        For example, an input might expose its value, and a modal might expose the "open" action.

        For performance, when the store is updated, we don't re-render down the tree, as would
        happen with a normal state-carrying component.

        Instead, each Block subscribes to this store when they mount, announcing which global
        properties they use. Then, when updates occur, these subscriptions are used to notify
        these blocks to re-render.

        The store is stored keyed by each block, and then by properties and actions:
        {
            input_0: {
                 actions: {"clear input": <Function>},
                 property: {value: "Hello world"}
                }
        }

        The subscriptions are stored keyed by what property has been accessed:
        {
            "input_0.property.value": [
                {id: "string_0", callback: <Function>},
                {id: "heading_10", callback: <Function>}
            ]
        }

    */
    store = {}
    subscriptions = {}

    updateProperty = (id, property, value) => {
        // console.log(
        //     `[PROPERTY STORE]: Updating subscription for ${id}.property.${property}`,
        //     this.props.isOuter
        // )

        this.store[id] = this.store[id] || { action: {}, property: {} }
        this.store[id].property[property] = value

        // Now go and notify any relevant subscriptions
        const subsToNotify = this.subscriptions[`${id}.property.${property}`] || []
        subsToNotify &&
            subsToNotify.forEach((sub) => {
                try {
                    // console.log("[PROPERTY STORE]: Found a sub for ", `${id}.property.${property}`)
                    sub.callback()
                } catch {
                    // So this block has probably rudely gone away without
                    // cancelling its subscription...   ¯\_(ツ)_/¯
                }
            })
    }

    updateAction = (id, name, action) => {
        this.store[id] = this.store[id] || { action: {}, property: {} }
        this.store[id].action[name] = action

        // We don't call the callbacks in this case
    }

    subscribe = (id, subscriptions, callback) => {
        // Add a new set of subscriptions for a given ID
        subscriptions &&
            subscriptions.forEach((sub) => {
                // console.log(
                //     `[PROPERTY STORE]: Registered subscription for ${sub} for ${id}`,
                //     this.props.isOuter
                // )
                this.subscriptions[sub] = this.subscriptions[sub] || []
                this.subscriptions[sub].push({ id, callback })
            })
        // Callback right now in case the order of register/update is backwards
        callback()
    }

    unsubscribe = (id) => {
        // Remove all the previous subscriptions
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key] = this.subscriptions[key].filter((sub) => sub.id !== id)
        })
    }

    render() {
        // console.log(`[PROPERTY STORE]: Rendered`, this.props.isOuter)
        return this.props.children({
            store: this.store,
            updateProperty: this.updateProperty,
            updateAction: this.updateAction,
            subscribe: this.subscribe,
            unsubscribe: this.unsubscribe,
        })
    }
}

export default PropertyStore

import { useNavigationWidth } from 'features/navigation/hooks/useNavigationWidth'

import { APP_SIDE_NAV_WIDTH, WORKSPACE_BAR_WIDTH } from './frameConstants'

export function useLeftOffset({
    isNavigationV2 = false,
    showAppBar = false,
    showSideNav = false,
    showSidebar = false,
}: {
    isNavigationV2?: boolean
    showAppBar?: boolean
    showSideNav?: boolean
    showSidebar?: boolean
}) {
    const v2NavWidth = useNavigationWidth()

    if (isNavigationV2) {
        return v2NavWidth
    } else if (showSidebar || showSideNav) {
        return APP_SIDE_NAV_WIDTH + (showAppBar ? WORKSPACE_BAR_WIDTH : 0)
    } else if (showAppBar) {
        return WORKSPACE_BAR_WIDTH
    }

    return 0
}

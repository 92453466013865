import { makeAppVariables } from './app'

export function makeAvatarGroupVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        gap: {
            xs: '-4px',
            s: '-8px',
            m: '-12px',
        },
        fontSizes: {
            xs: s.fontSizes.bodyXs,
            s: s.fontSizes.bodyXs,
            m: s.fontSizes.bodyM,
        },
    }
}

import React from 'react'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useLayoutEditorContext } from './useLayoutEditorContext'
import {
    LayoutEditorWidgetAreaContext,
    useLayoutEditorWidgetAreaContext,
} from './useLayoutEditorWidgetAreaContext'
import { useLayoutEditorWidgetContext } from './useLayoutEditorWidgetContext'
import { getWidgetAreaAtPath } from './utils'

type LayoutEditorWidgetAreaContextProviderProps = {
    id: string
}

export const LayoutEditorWidgetAreaContextProvider: React.FC<
    LayoutEditorWidgetAreaContextProviderProps
> = ({ id, children }) => {
    const { schema, view, isEditing } = useLayoutEditorContext()
    const { widget } = useLayoutEditorWidgetContext() ?? {}
    const { path = [] } = useLayoutEditorWidgetAreaContext() || {}

    // Widget area contexts can be nested, to calculate the parent ID path.
    const newPath = widget ? [...path, widget.id, id] : [...path, id]

    const childrenWidgets = getWidgetAreaAtPath(newPath, view?.layout) ?? []

    const value = useDeepEqualsMemoValue({
        path: newPath,
        children: childrenWidgets,
        editorSchema: schema,
        isEditing,
    })

    return (
        <LayoutEditorWidgetAreaContext.Provider value={value}>
            {children}
        </LayoutEditorWidgetAreaContext.Provider>
    )
}

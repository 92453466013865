import React, { useEffect, useRef } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { FilterButton } from 'features/records/components/InlineFilterItem'
import { getIsUserRefField } from 'utils/fieldUtils'

import { Box, Button, Divider, Dropdown, Flex, Icon, PopoverButton, Text } from 'v2/ui'

import { getUserFieldOptions } from './getUserFieldOptions'
import { OwnershipFilterFields } from './permissionRuleForm.types'
import { UserFieldsDropdown } from './UserFieldsDropdown'

type RecordPermissionFieldsProps = {
    value: OwnershipFilterFields
    onChange: (fieldData: OwnershipFilterFields) => {}
    objects: ObjectDto[]
    object: ObjectDto
    userObject: any
}
export const RecordPermissionFields: React.FC<RecordPermissionFieldsProps> = ({
    value,
    onChange,
    objects,
    object,
    userObject,
}): React.ReactElement => {
    const { isOpen, onToggle, onClose } = useDisclosure()
    // @ts-expect-error
    const dropdownRef = useRef<Dropdown>(null)

    useEffect(() => {
        if (isOpen && dropdownRef && dropdownRef.current) {
            dropdownRef.current.setState({ menuIsOpen: true })
            dropdownRef.current.select.focus()
        }
    }, [isOpen])

    // Make sure both link_target_object_ids on permission Access match
    let matchingObjectType: string | undefined = object._sid
    const selectedField: FieldDto = object.fields.find(
        (f) => f.api_name === value.objectFieldSelected
    )
    if (value.objectFieldSelected !== '_sid') {
        matchingObjectType = selectedField?.link_target_object_id
    }

    // If object link_target_object_id points to userObject sid, then we show a static user option
    let userRecord: { label: string; value: string }[] = []
    if (
        matchingObjectType === userObject?._sid ||
        (selectedField && getIsUserRefField(selectedField))
    ) {
        userRecord = [
            {
                label: '👤 User',
                value: '_sid',
            },
        ]
    }

    const objectFieldDropdownOptions = [
        { label: 'The record', value: '_sid' },
        ...object.fields
            .filter((field) => !field.is_foreign)
            .filter((field) => {
                return (
                    field.type === 'lookup' ||
                    field.type === 'multi_lookup' ||
                    getIsUserRefField(field)
                )
            })
            .filter((field) => {
                // Remove disabled fields... unless they're selected!
                return (
                    !(field.connection_options && field.connection_options.is_disabled) ||
                    field.api_name === value.objectFieldSelected
                )
            })
            .map((field) => ({
                label: `The record → ${field.label}`,
                value: field.api_name,
            })),
    ]

    const userFieldDropdownOptions: { label: string; value: string }[] =
        selectedField && getIsUserRefField(selectedField)
            ? userRecord
            : getUserFieldOptions(
                  userRecord,
                  userObject,
                  objects,
                  ['lookup', 'multi_lookup'],
                  matchingObjectType,
                  value,
                  false
              )

    const objectFieldLabel =
        objectFieldDropdownOptions.find((x) => x.value === value.objectFieldSelected)?.label ||
        '...'
    const userFieldLabel =
        userFieldDropdownOptions.find((x) => x.value === value.userFieldSelected)?.label || '...'
    const filterLabel = (
        <>
            <strong>{objectFieldLabel}</strong> matches the user&apos;s{' '}
            <strong>{userFieldLabel}</strong>
        </>
    )

    const ruleError = objectFieldLabel === '...' || userFieldLabel === '...' ? true : false

    if (value.active) {
        return (
            <>
                <PopoverButton
                    // @ts-expect-error
                    ButtonComponent={FilterButton}
                    label={filterLabel}
                    defaultIsOpen={value.isNew ?? false}
                    color="white"
                    onRemove={() =>
                        onChange({
                            objectFieldSelected: null,
                            userFieldSelected: null,
                            active: false,
                        })
                    }
                >
                    {({
                        onToggle,
                        buttonRef,
                    }: {
                        onToggle: () => void
                        buttonRef: React.MutableRefObject<HTMLElement>
                    }) => (
                        <Flex
                            column
                            wrap="nowrap"
                            align="stretch"
                            boxShadow="popup"
                            borderRadius="3px"
                            background="white"
                            padding={2}
                            fontSize="sm"
                            marginTop={`${-buttonRef.current?.offsetHeight}px`} // Offsets to have the popup cover the button
                            width={`${Math.max(165, buttonRef.current?.offsetWidth)}px`} // and match its width
                        >
                            <strong>
                                {
                                    objectFieldDropdownOptions.find(
                                        (x) => x.value === value.objectFieldSelected
                                    )?.label
                                }
                            </strong>
                            <div
                                style={{
                                    borderRadius: '6px',
                                    fontWeight: 600,
                                    padding: '0.5rem',
                                    marginTop: '0.5rem',
                                    marginBottom: '0.5rem',
                                    paddingLeft: '1rem',
                                    paddingRight: '1rem',
                                    color: '#737684',
                                    backgroundColor: '#F4F5F8',
                                }}
                            >
                                matches the user&apos;s
                            </div>
                            <UserFieldsDropdown
                                onChange={(userFieldSelected) => {
                                    onChange({ ...value, userFieldSelected })
                                }}
                                value={value}
                                usePortal={false}
                                userFieldDropdownOptions={userFieldDropdownOptions}
                            />
                            <Divider my={2} />
                            <Button variant="adminTertiary" buttonSize="sm" onClick={onToggle}>
                                Done
                            </Button>
                        </Flex>
                    )}
                </PopoverButton>
                {!value.isNew && ruleError && (
                    <Box
                        fontSize="sm"
                        d="flex"
                        alignItems="center"
                        mt={3}
                        flexGrow={1}
                        backgroundColor="#ffe4c7"
                        p={2}
                        borderRadius="5px"
                    >
                        <Icon
                            style={{
                                display: 'flex',
                                marginRight: '10px',
                            }}
                            icon="warning"
                            size="15px"
                            color="#FF0000"
                        />{' '}
                        {objectFieldLabel === '...' ? (
                            <strong>
                                The selected object field is no longer valid. Please update or
                                replace this rule.
                            </strong>
                        ) : (
                            <strong>
                                The selected user field is no longer valid. Please update or replace
                                this rule.
                            </strong>
                        )}
                    </Box>
                )}
            </>
        )
    }

    if (isOpen) {
        return (
            <Dropdown
                options={objectFieldDropdownOptions}
                ref={dropdownRef}
                onChange={(value: string) => {
                    onChange({
                        objectFieldSelected: value,
                        userFieldSelected: null,
                        active: true,
                        isNew: true,
                    })
                    onClose()
                }}
                placeholder={<Text color="gray.400">Select...</Text>}
                onBlur={() => {
                    onClose()
                }}
                variant="settings"
            />
        )
    }

    return (
        <Button
            icon="add"
            variant="adminSecondary"
            py={1}
            px={2}
            onClick={() => {
                onToggle()
            }}
            fontSize="sm"
        >
            Add Ownership Filter
        </Button>
    )
}

import { useQuery } from 'react-query'

import { fetchAndReturn } from 'data/utils/utils'

import { useQueryKeyBuilder } from './_helpers'

const LIST_ENDPOINT = '/objects'

export type SharedObjectUsageResponse = {
    stacks: StackDto[]
    count: number
}

export function useSharedObjectUsage(objectSid: string, options?: object) {
    const singleRecordQueryKey = useQueryKeyBuilder([LIST_ENDPOINT, objectSid], {
        includeAuthKeys: true,
    })

    const queryResult = useQuery<SharedObjectUsageResponse>(
        singleRecordQueryKey,
        async () => {
            const url = `objects/${objectSid}/usage/`
            const results = (await fetchAndReturn(url)) as SharedObjectUsageResponse

            return results
        },
        { refetchOnMount: 'always', ...options }
    )

    return queryResult
}

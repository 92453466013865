// @ts-strict-ignore
import React, { forwardRef } from 'react'

import { Input } from './Input'
import { InputEndActionNumber } from './InputEndActionNumber'

type NumberInputRef = HTMLInputElement

type NumberInputProps = Omit<React.ComponentPropsWithoutRef<typeof Input>, 'type' | 'onChange'> & {
    value: number
    onChange: (value: number) => void
}

export const NumberInput = forwardRef<NumberInputRef, NumberInputProps>(
    ({ onChange, ...props }, ref) => {
        return (
            <Input
                endAction={InputEndActionNumber}
                {...props}
                ref={ref}
                type="number"
                onChange={(e) => onChange(parseFloat(e.target.value))}
            />
        )
    }
)

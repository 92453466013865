import React from 'react'

import { Button } from 'ui/components/Button'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useListHeaderAddNewButtonState } from './hooks/useListHeaderAddNewButtonState'

type ListHeaderAddNewButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {}

export const ListHeaderAddNewButton: React.FC<ListHeaderAddNewButtonProps> = ({
    children = 'Add',
    ...props
}) => {
    const { onClick } = useListHeaderAddNewButtonState(props)

    const showLabel = useResponsiveValue({
        mobile: false,
        tablet: true,
    })

    return (
        <Button
            size="s"
            startIcon={{ name: 'Plus' }}
            aria-label="Add new record"
            {...props}
            onClick={onClick}
        >
            {showLabel && children}
        </Button>
    )
}

// @ts-strict-ignore
import React from 'react'

import { HELP_LINKS } from 'app/helpLinks'
import { useObject } from 'data/hooks/objects'
import {
    PermissionTable,
    PermissionTableRows,
} from 'features/AppSettings/Permissions/PermissionTable'
import { FormHeader } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box, Button, Divider, Flex } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

const learnMore = (
    <Button variant="adminSecondary" buttonSize="sm" href={HELP_LINKS.PERMISSIONS}>
        Learn more
    </Button>
)

export const AppSettingsModalDataPermissions = ({ stack, stackId, objectId, noPadding }) => {
    const { object } = useObject(objectId)

    if (!object) return null

    return (
        <Box width="100%">
            <Flex
                p={!noPadding ? '20px' : undefined}
                flexDirection="column"
                alignItems="stretch"
                minHeight="100%"
                className={ONBOARDING_CLASSES.APP_SETTINGS_PERMISSIONS_LIST}
            >
                <FormHeader
                    title="Permissions"
                    subtitle="Choose what kind of data access you want to give your users"
                    rightSideContent={learnMore}
                />

                <Divider my={1} />

                {/* <Banner icon="info">
                    {rolesWithAllAccess.map((role, idx) => (
                        <>
                            {idx > 0 && idx < rolesWithAllAccess.length - 1 && ', '}
                            {idx > 0 && idx === rolesWithAllAccess.length - 1 && ' and '}
                            <strong>{role.label}</strong>
                        </>
                    ))}{' '}
                    users can view and modify all app data regardless of permission settings
                </Banner> */}

                <PermissionTable includeTableName={false}>
                    <PermissionTableRows stack={stack} stackId={stackId} objectId={objectId} />
                </PermissionTable>
            </Flex>
        </Box>
    )
}

import React from 'react'
import type ReactQuill from 'react-quill'

import styled from '@emotion/styled'

import DropdownAttribute from 'features/pages/blocks/settings/attributes/items/form/Dropdown'
import SingleImageUploadAttribute from 'features/pages/blocks/settings/attributes/items/form/SingleImageUpload'
import { usePatchedData } from 'utils/usePatchedData'

import { Box, Flex, Input } from 'v2/ui'
import LongTextAttribute from 'v2/ui/components/Attribute/LongTextAttribute'
import stackerTheme from 'v2/ui/theme/styles/default'
import { modes } from 'v2/ui/utils/attributeSettings'
import useDebounce from 'v2/ui/utils/useDebounce'

import { getDefaultedPageHeader } from './viewHeaderDefaults'
import ViewHeaderDisplayInput from './ViewHeaderDisplayInput'
import { ViewHeaderEditorImageAdvancedSettings } from './ViewHeaderEditorImageAdvancedSettings'
import { ViewHeaderEditorInputGroup } from './ViewHeaderEditorInputGroup'
import { ViewHeaderEditorLabel } from './ViewHeaderEditorLabel'
import ViewHeaderPositionInput from './ViewHeaderPositionInput'

const { space } = stackerTheme()

const ImageLabelWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${space[3]};
`

const backgroundTypeOptions = [
    { label: 'Image', value: 'image' },
    { label: 'Color', value: 'color' },
]

type ViewHeaderEditorProps = {
    onChange: (patch: Partial<PageHeader>) => void
    pageHeader?: PageHeader
    isBlankPage?: boolean
}

const ViewHeaderEditor: React.FC<ViewHeaderEditorProps> = ({
    pageHeader,
    isBlankPage,
    onChange,
}) => {
    const { patchedData, applyPatch } = usePatchedData<PageHeader>(
        getDefaultedPageHeader(pageHeader)
    )

    const debouncedOnChange = useDebounce(onChange, 300, [])

    const patchHeader = (patch: Partial<PageHeader>) => {
        applyPatch(patch)
        debouncedOnChange({
            ...patchedData,
            ...patch,
        })
    }

    const onChangeHeaderDisplay = (displayValue: PageHeader['type']) => {
        patchHeader({ type: displayValue })
    }

    const { type, title, subtitle, src, position, showBackgroundColor } =
        patchedData as PageHeaderImage

    const hasImageBackground = type === 'image' && showBackgroundColor === false

    return (
        <Flex
            as="form"
            w="100%"
            alignSelf="flex-start"
            direction="column"
            alignItems="flex-start"
            p={3}
        >
            <ViewHeaderEditorInputGroup role="group">
                <ViewHeaderEditorLabel htmlFor="page-display" mb={3}>
                    Display
                </ViewHeaderEditorLabel>
                <ViewHeaderDisplayInput
                    id="page-display"
                    value={type}
                    onChange={onChangeHeaderDisplay}
                    isBlankPage={isBlankPage}
                />
            </ViewHeaderEditorInputGroup>
            <ViewHeaderEditorInputGroup role="group">
                <ViewHeaderEditorLabel htmlFor="page-title" mb={2}>
                    Title
                </ViewHeaderEditorLabel>
                <Input
                    w="100%"
                    value={title}
                    placeholder="Title"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        patchHeader({ title: e.target.value })
                    }
                    id="page-title"
                />
                <ViewHeaderEditorLabel htmlFor="page-subtitle" mt={4} mb={2}>
                    Subtitle
                </ViewHeaderEditorLabel>
                {/* @ts-expect-error */}
                <LongTextAttribute
                    showRichTextEditor={true}
                    enableMarkdown={true}
                    mode={modes.editing}
                    convertToMarkdown={false}
                    height="100px"
                    onChange={(value: ReactQuill.Value) => patchHeader({ subtitle: value })}
                >
                    {subtitle}
                </LongTextAttribute>
            </ViewHeaderEditorInputGroup>
            <ViewHeaderEditorInputGroup role="group">
                <ViewHeaderEditorLabel htmlFor="page-background-type" mb={2}>
                    Background
                </ViewHeaderEditorLabel>
                {/* @ts-expect-error */}
                <DropdownAttribute
                    id="page-background-type"
                    options={backgroundTypeOptions}
                    value={showBackgroundColor ? 'color' : 'image'}
                    menuPlacement="auto"
                    onChange={(newValue) =>
                        patchHeader({ showBackgroundColor: newValue === 'color' })
                    }
                    isClearable={false}
                />
                {hasImageBackground && (
                    <>
                        <ImageLabelWrapper mt={4}>
                            <ViewHeaderEditorLabel htmlFor="page-image">
                                Image
                            </ViewHeaderEditorLabel>
                            {src && (
                                <ViewHeaderPositionInput
                                    aria-label="Background image position"
                                    value={position}
                                    onChange={(value) => patchHeader({ position: value })}
                                />
                            )}
                        </ImageLabelWrapper>
                        <SingleImageUploadAttribute
                            id="page-image"
                            /* @ts-expect-error */
                            onSuccess={(value) => {
                                const patch: Partial<PageHeader> = {
                                    src: value.filesUploaded[0]?.url ?? '',
                                }

                                patchHeader(patch)
                            }}
                            image={src!}
                            canDelete={true}
                            onDelete={() => {
                                patchHeader({ src: '' })
                            }}
                        />
                        <ViewHeaderEditorImageAdvancedSettings
                            mt={6}
                            pageHeader={patchedData as PageHeaderImage}
                            updatePageHeader={patchHeader}
                        />
                    </>
                )}
            </ViewHeaderEditorInputGroup>
        </Flex>
    )
}

export default ViewHeaderEditor

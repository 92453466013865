import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'

import { useWorkspaceIconState } from './hooks/useWorkspaceIconState'

import { WorkspaceLogoIconStyles } from './Navigation.css'

type WorkspaceIconProps = React.ComponentPropsWithoutRef<typeof Box> & {
    workspaceAccount: Account
}

export const WorkspaceIcon: React.FC<WorkspaceIconProps> = ({ workspaceAccount, className }) => {
    const { imageUrl, nameInitials } = useWorkspaceIconState({ workspaceAccount })

    return (
        <Box
            className={classNames(
                WorkspaceLogoIconStyles.styleFunction({
                    hasImage: !!imageUrl,
                }),
                className
            )}
        >
            {imageUrl ? (
                <Box
                    as="img"
                    height="full"
                    width="full"
                    src={imageUrl}
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                    }}
                />
            ) : (
                <Box>{nameInitials}</Box>
            )}
        </Box>
    )
}

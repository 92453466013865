import { useCallback, useMemo, useState } from 'react'

import { AppGroup, AppRole } from 'features/users/AppUsers/types'
import { useAppUsersContext } from 'features/users/AppUsers/useAppUsersContext'

import useDebounce from 'v2/ui/utils/useDebounce'

import { IconName } from 'ui/components/Icon/Icon'

import { useAppUsers } from './useAppUsers'

const SEARCH_DEBOUNCE_RATE = 500 // ms

type UseAppUsersGroupListRowStateOptions = {
    group: AppGroup
    roles: AppRole[]
    pageSize: number
    isLoading?: boolean
}

export function useAppUsersGroupListRowState(options: UseAppUsersGroupListRowStateOptions) {
    const { group, roles, pageSize, isLoading: providedIsLoading } = options

    const { setPane, setSelectedTableGroup } = useAppUsersContext()

    const [isOpen, setIsOpen] = useState(false)

    const collapsibleIcon: IconName = isOpen ? 'ChevronUp' : 'ChevronDown'
    const collapsibleLabel = isOpen ? 'Collapse group' : 'Expand group'

    const [searchQuery, setSearchQuery] = useState('')
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')

    const setDebouncedQuery = useDebounce(setDebouncedSearchQuery, SEARCH_DEBOUNCE_RATE)

    const onChangeSearchInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setSearchQuery(value)
            setDebouncedQuery(value)
        },
        [setDebouncedQuery]
    )

    const goToTableGroupSettings = useCallback(() => {
        setSelectedTableGroup(group)
        setPane('updateTableGroup')
    }, [group, setPane, setSelectedTableGroup])

    const {
        users,
        totalUserCount = 0,
        isError,
        isFetchingSlow,
        pageIndex,
        setPageIndex,
        hasNoUsers,
        hasNoUsersMatchingSearch,
    } = useAppUsers({
        pageSize,
        searchQuery: debouncedSearchQuery,
        grantType: group.isWorkspaceGroup ? 'group' : 'table',
        grantSid: group.id,
        enabled: isOpen,
    })

    const supportedRoles = useMemo(() => {
        // We don't allow changing roles for non-workspace groups right now.
        if (!group.isWorkspaceGroup) {
            return roles.filter((role) => role.id === group.role)
        }

        return roles
    }, [group.isWorkspaceGroup, group.role, roles])

    const isLoading = providedIsLoading || isFetchingSlow

    return {
        isOpen,
        onOpenChange: setIsOpen,
        collapsibleIcon,
        collapsibleLabel,
        users,
        searchQuery,
        onChangeSearchInput,
        supportedRoles,
        totalUserCount,
        isError,
        isLoading,
        pageIndex,
        setPageIndex,
        hasNoUsers,
        hasNoUsersMatchingSearch,
        goToTableGroupSettings,
    }
}

import { useAppUsers, useWorkspaceUsers } from 'data/hooks/users/main'

export function useMentionUsersForStack(stackId?: string) {
    const { data: workspaceUsers } = useWorkspaceUsers()
    const { data: stackUsers } = useAppUsers(undefined, { stackId })

    const users = stackId ? stackUsers : workspaceUsers

    return users || []
}

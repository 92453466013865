import { useMemo } from 'react'

import { useFiltersContext } from 'features/views/ListView/Filters/useFiltersContext'

export function useCardViewFilters() {
    const { inlineFilters, inlineFilterType, availableInlineFilterFields, clearFilters } =
        useFiltersContext()
    const hasFilters = inlineFilters.length > 0

    return useMemo(
        () => ({
            hasFilters,
            inlineFilterType,
            availableInlineFilterFields,
            clearFilters,
        }),
        [hasFilters, inlineFilterType, availableInlineFilterFields, clearFilters]
    )
}

// @ts-strict-ignore
import React, { useCallback, useMemo, useState } from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'

import { useAppErrors } from 'data/hooks/appErrors'
import { fieldHasError, isSyntheticLink } from 'data/utils/error_utils'
import { withObjects } from 'data/wrappers/withObjects'
import { getEditableFieldTypeDefinition } from 'features/admin/fields/definitions/editableFieldTypeDefinitions'
import { toFieldTypeComponentData } from 'features/admin/fields/definitions/fieldTypeComponents'
import { FieldEditorPopoverButton } from 'features/admin/fields/FieldEditorPopoverButton'
import {
    getCanDeleteField,
    getIsFieldConfigurable,
} from 'features/admin/fields/logic/availableFieldOperationUtils'
import { RenderIcon } from 'features/admin/fields/RenderIcon'
import { getShortFieldName } from 'features/admin/utils'
import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { stringifyStackerAST } from 'features/formulas/parser/formulaParsingFunctions'
import { FormLabel, Icon, Toggle } from 'legacy/v1/ui'
import { isFormulaField } from 'utils/fieldUtils'

import { Box, Flex, Icon as Alert, Input, ScrollBox, Text, Tooltip } from 'v2/ui'
import VirtualizedList from 'v2/ui/components/VirtualizedList'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import stackerTheme from 'v2/ui/theme/styles/default'

import { DeleteFieldButton } from './DeleteFieldButton'
import PrimaryFieldButton from './PrimaryFieldButton'
import { useConfirmDeleteFieldModal } from './useConfirmDeleteFieldModal'
import { getTruncatedFieldDeveloperName, getTruncatedFieldLabel, isFieldShadowed } from './utils'

/**
 * This is a copy of FieldConfigurationEditor.js with the new redesign
 * to fit in the settings modal.
 */

const colors = stackerTheme().colors

const FieldLabel = styled.span`
    padding-right: 5px;
    font-weight: 900;
    color: ${colors.neutral[1000]};
    display: block;
    font-size: 14px;
`

type FieldRowProps = {
    field: FieldDto
    object: ObjectDto
    onChange: (data: any) => void
    onDelete: (data: any) => void
    showDeveloperDetails: boolean
    // whether the field enable/disable toggle is disabled (not to be confused whether the field
    // itself is disabled)
    disabled: boolean
    hasError: boolean
    showConfirmDeleteForField: (field: FieldDto) => unknown
}

const FieldRow = ({
    field,
    object,
    onChange,
    showDeveloperDetails,
    disabled,
    hasError = false,
    showConfirmDeleteForField,
}: FieldRowProps) => {
    const rawFieldType = getEditableFieldTypeDefinition(field)
    const fieldType = rawFieldType ? toFieldTypeComponentData(rawFieldType) : undefined
    const isEnabled = !field.connection_options.is_disabled
    const shortFieldName = getShortFieldName(field)

    const isConfigurable = getIsFieldConfigurable({
        field,
    })

    const formula = useMemo(() => {
        if (isFormulaField(field)) {
            return stringifyStackerAST(field.connection_options.formula_parsed, object)
        }
        return null
    }, [field, object])

    const shouldShowDeleteField = getCanDeleteField({ field, object })

    const label = useMemo(() => getTruncatedFieldLabel(field), [field])
    const isLabelTruncated = label !== field.label

    const developerLabel = useMemo(() => getTruncatedFieldDeveloperName(field), [field])
    const isDeveloperLabelTruncated = developerLabel !== shortFieldName

    return useMemo(
        () => (
            <FieldContainer data-testid="fieldContainer" className={STYLE_CLASSES.DATA_BLOCK}>
                <Flex width="40%">
                    <Flex width="100%" flexWrap="nowrap">
                        <Box marginLeft="0.25rem" width="calc(100% - 25px)">
                            <Tooltip
                                placement="top"
                                label={formula ?? undefined}
                                disabled={!formula}
                                showDelay={200}
                                maxWidth="500px"
                            >
                                <>
                                    <Tooltip label={field.label} disabled={!isLabelTruncated}>
                                        <FieldLabel>{label}</FieldLabel>
                                    </Tooltip>
                                    <Tooltip
                                        label={shortFieldName}
                                        disabled={!isDeveloperLabelTruncated}
                                    >
                                        <Flex width="calc(100% - 25px)">
                                            {showDeveloperDetails && (
                                                <Text
                                                    whiteSpace="nowrap"
                                                    textOverflow="ellipsis"
                                                    overflow="hidden"
                                                    size="13px"
                                                >
                                                    {developerLabel}
                                                </Text>
                                            )}
                                        </Flex>
                                    </Tooltip>
                                </>
                            </Tooltip>
                        </Box>
                    </Flex>
                </Flex>

                <Flex width="30%" wrap="nowrap">
                    {fieldType?.iconComponent && (
                        <RenderIcon
                            Icon={fieldType?.iconComponent}
                            color={colors.neutral[800]}
                            size="20px"
                        />
                    )}
                    <Box
                        m={0}
                        mt="auto"
                        mr={3}
                        p={0}
                        ml={1}
                        color={colors.neutral[800]}
                        wordBreak="break-word"
                        fontSize={14}
                    >
                        <Text display="inline-block" verticalAlign="middle">
                            {fieldType?.label}
                        </Text>
                    </Box>
                </Flex>

                <Flex justifyContent="flex-end" ml="auto" width="30%">
                    {hasError && (
                        <Alert
                            style={{ marginLeft: '2px' }}
                            icon="warning"
                            size="15px"
                            color="#FF0000"
                        />
                    )}
                    {/* Settings Button */}
                    {isConfigurable && (
                        <FieldEditorPopoverButton
                            data-testid="fieldConfigButton"
                            objectId={object?._sid}
                            icon="cog"
                            field={field}
                            variant="Tertiary"
                            pr={0}
                            fontSize={17}
                            color={colors.neutral[600]}
                            style={{
                                backgroundColor: 'transparent',
                            }}
                        />
                    )}
                    {/*Delete Icon*/}
                    {shouldShowDeleteField && (
                        <DeleteFieldButton
                            handleClick={() => showConfirmDeleteForField(field)}
                            icon="trash"
                            label="Delete this field"
                            buttonSize="sm"
                            _hover={{ color: 'black' }}
                            color={colors.neutral[600]}
                            style={{
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                marginLeft: '10px',
                                backgroundColor: 'transparent',
                            }}
                        />
                    )}
                    {/*Toggle*/}
                    {field.is_primary ? (
                        <PrimaryFieldButton
                            icon="key"
                            label={`You cannot disable ${field.label}, as it is the primary field for this object`}
                            buttonSize="sm"
                            _hover={{ color: 'black' }}
                            color={colors.neutral[1000]}
                            labelPlacement="left"
                            style={{
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                marginLeft: '10px',
                                backgroundColor: 'transparent',
                            }}
                        />
                    ) : (
                        <Flex pl="5px" mr="-5px">
                            {/* @ts-expect-error */}
                            <FormLabel hidden for={`${field._sid}_Toggle`}>
                                {field.label} Enable
                            </FormLabel>

                            <Toggle
                                small
                                theme={{ toggleBackgroundColor: '#465DD8' }}
                                id={`${field._sid}_Toggle`}
                                value={isEnabled}
                                disabled={disabled}
                                onChange={() =>
                                    onChange({
                                        connection_options: {
                                            ...field.connection_options,
                                            is_disabled: isEnabled,
                                        },
                                    })
                                }
                            />
                        </Flex>
                    )}
                </Flex>
            </FieldContainer>
        ),
        [
            developerLabel,
            disabled,
            field,
            fieldType?.iconComponent,
            fieldType?.label,
            formula,
            hasError,
            isConfigurable,
            isDeveloperLabelTruncated,
            isEnabled,
            isLabelTruncated,
            label,
            object?._sid,
            onChange,
            shortFieldName,
            shouldShowDeleteField,
            showConfirmDeleteForField,
            showDeveloperDetails,
        ]
    )
}

type NewFieldConfigurationEditorProps = {
    object: ObjectDto
    changeField: (sid: string, data: any) => Promise<void>
    objectDataConnection: any
    objects: ObjectDto[]
    deleteField: (sid: string) => void
    showDeveloperDetails: boolean
    bulkChangeFields: (changes: any[]) => Promise<void>
}

/**
 *
 * @param param0
 * @returns
 */
const InnerNewFieldConfigurationEditor: React.FC<NewFieldConfigurationEditorProps> = ({
    object,
    changeField,
    objects,
    deleteField,
    showDeveloperDetails,
    bulkChangeFields,
}: NewFieldConfigurationEditorProps) => {
    const { fields } = object

    const [filter, setFilter] = useState<string>()
    const { data } = useAppErrors()
    const errors = data?.synthetic_field_errors

    const sortByFieldsOrder = useSortingByFieldsOrder(object)

    const filteredFields = useMemo(
        () =>
            sortByFieldsOrder(fields).filter(
                (field) =>
                    !get(field, 'connection_options.is_stacker_id') &&
                    (!filter || field.label.toLowerCase().includes(filter.toLowerCase()))
            ),
        [fields, filter, sortByFieldsOrder]
    )

    const { showConfirmDeleteForField } = useConfirmDeleteFieldModal(object)

    const renderRow = useCallback(
        ({ item: field }) => {
            let hasError = false
            if (isSyntheticLink(field)) {
                hasError = fieldHasError(field, errors) || false
            }

            const handleChange = (data) => {
                changeField(field?._sid, data)
            }

            return (
                <FieldRow
                    key={field._sid}
                    field={field}
                    object={object}
                    onChange={handleChange}
                    onDelete={() => deleteField(field._sid)}
                    showDeveloperDetails={showDeveloperDetails}
                    disabled={isFieldLocked(field, objects)}
                    hasError={hasError}
                    showConfirmDeleteForField={showConfirmDeleteForField}
                />
            )
        },
        [
            objects,
            object,
            showDeveloperDetails,
            errors,
            changeField,
            deleteField,
            showConfirmDeleteForField,
        ]
    )

    const setAll = useCallback(
        async (value) => {
            const changes: any[] = []
            filteredFields
                .filter((field) => !isFieldLocked(field, objects)) // Never toggle the primary, please
                .forEach((field) =>
                    changes.push({
                        _sid: field._sid,
                        connection_options: {
                            ...field.connection_options,
                            is_disabled: value,
                        },
                    })
                )

            await bulkChangeFields(changes)
        },
        [filteredFields, bulkChangeFields, objects]
    )

    return useMemo(
        () => (
            <>
                <StyledSearchInput
                    style={{ paddingLeft: '2rem' }}
                    placeholder="Search"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    leftAdorner={
                        <Icon
                            icon="search"
                            style={{ fontSize: '10px', color: colors.neutral[600] }}
                        />
                    }
                />

                <FieldTitlesContainer>
                    <FieldText style={{ width: '40%' }}>Field name</FieldText>

                    <FieldText style={{ width: '30%' }}>Data type</FieldText>

                    <Flex width="30%" textAlign="right" ml="auto" justifyContent="end">
                        <FieldLinkText onClick={() => setAll(false)}>Enable all</FieldLinkText>
                        <FieldLinkText style={{ marginLeft: '10px' }} onClick={() => setAll(true)}>
                            Disable all
                        </FieldLinkText>
                    </Flex>
                </FieldTitlesContainer>
                <ScrollBox
                    id="inner"
                    maxHeight="100%"
                    flexGrow={1}
                    overflowY="auto"
                    overflowX="hidden"
                >
                    <VirtualizedList
                        estimatedItemSize={44}
                        items={filteredFields}
                        // The virtualized list seems to sometimes glitch when the number of items
                        // changes, so we're just recreating completely in that case
                        key={filteredFields.length}
                        renderItem={renderRow}
                    />
                </ScrollBox>
            </>
        ),
        [filter, filteredFields, renderRow, setAll]
    )
}

export const NewFieldConfigurationEditor = withObjects(InnerNewFieldConfigurationEditor)

/**
 * For single/multi links, if the target object is explicitly disabled, returns true.
 *
 * If the target object is missing, it's not considered disabled as this is a possible valid state from table sharing
 */
function isLookupTableDisabled(field: FieldDto, objects: ObjectDto[]) {
    if (field.type === 'lookup' || field.type === 'multi_lookup') {
        const lookupTable = field.link_target_object_id

        const table = objects.find((object) => object._sid === lookupTable)

        if (table?.connection_options.data_mapping_disabled) {
            return true
        }
    }

    return false
}

/**
 * Returns whether the field should be locked and can't be toggled between enable/disabled
 */
const isFieldLocked = (field: FieldDto, objects: ObjectDto[]): boolean => {
    const isShadowed = isFieldShadowed(field)
    return field.is_primary || isLookupTableDisabled(field, objects) || isShadowed
}

const FieldTitlesContainer = styled(Flex)`
    border-bottom: 1px solid ${colors.neutral[500]};
    padding-bottom: 5px;
    padding-left: 10px;
`

const FieldContainer = styled(FieldTitlesContainer)`
    padding-top: 5px;
    min-height: 44px;
`

const FieldText = styled.span`
    color: ${colors.neutral[900]};
`
const FieldLinkText = styled(FieldText)`
    text-decoration: underline;
    cursor: pointer;
    font-weight: 900;
`

const StyledSearchInput = styled(Input)`
    margin-bottom: 20px;

    &:focus {
        outline: none;
    }
`

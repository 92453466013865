import { fetchWithAuth } from './fetchWithAuth'

/**
 *
 * @param {*} requestUrl Requested URL slug by user
 * @param {*} zone Current account zone (null if called from the create form)
 * @returns
 */

export enum CustomDomainCheckStatus {
    OK = 'OK',
    IS_NOT_UNIQUE = 'IS_NOT_UNIQUE',
    INVALID_CHARACTERS = 'INVALID_CHARACTERS',
    RESERVED_DOMAIN = 'RESERVED_DOMAIN',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    CONTAINS_PREFIX = 'CONTAINS_PREFIX',
}

export type CustomDomainCheckResponse =
    | {
          status: CustomDomainCheckStatus.OK
      }
    | {
          status:
              | CustomDomainCheckStatus.IS_NOT_UNIQUE
              | CustomDomainCheckStatus.INVALID_CHARACTERS
              | CustomDomainCheckStatus.RESERVED_DOMAIN
              | CustomDomainCheckStatus.VALIDATION_ERROR
              | CustomDomainCheckStatus.CONTAINS_PREFIX

          message: string
      }

export const validateUrl = async (
    requestUrl: string,
    zone?: AccountZone | null
): Promise<CustomDomainCheckResponse> => {
    const requestedUrlLower = requestUrl.toLowerCase()

    if (zone?.custom_domain?.toLowerCase() === requestedUrlLower) {
        return { status: CustomDomainCheckStatus.OK }
    }

    try {
        const url = `zone/check-domain?url=${encodeURIComponent(requestedUrlLower)}`
        return (await fetchWithAuth(url)).json()
    } catch {
        return {
            status: CustomDomainCheckStatus.VALIDATION_ERROR,
            message: 'An error occurred while validating the custom domain.',
        }
    }
}

import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import { makeComponent } from 'ui/helpers/recipes'

import { ContentStyles, TooltipStyles, TriggerStyles } from './Tooltip.css'

export const Provider = TooltipPrimitive.Provider
export const Root = makeComponent(TooltipPrimitive.Root, TooltipStyles)
export const Trigger = makeComponent(TooltipPrimitive.Trigger, TriggerStyles)
export const Portal = TooltipPrimitive.Portal
export const Content = makeComponent(TooltipPrimitive.Content, ContentStyles)

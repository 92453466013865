import React from 'react'

import { isEqual } from 'lodash'

import { TableViewColumn } from 'features/views/ListView/TableView/types'

import { Skeleton } from 'ui/components/Skeleton'

import { AttachmentsCell } from './AttachmentsCell'
import { BaseCell } from './BaseCell'
import { CheckboxCell } from './CheckboxCell'
import { CurrencyCell } from './CurrencyCell'
import { DateCell } from './DateCell'
import { DropdownCell } from './DropdownCell'
import { LongTextCell } from './LongTextCell'
import { NumberCell } from './NumberCell'
import { PercentageCell } from './PercentageCell'
import { RecordLinksCell } from './RecordLinksCell'
import { RichTextCell } from './RichTextCell'
import { TextCell } from './TextCell'
import { UrlCell } from './UrlCell'
import { UserCell } from './UserCell'

type CellProps = {
    column: TableViewColumn
    value: any
    isLoading?: boolean
}

export const Cell: React.FC<CellProps> = React.memo(function Cell(props) {
    switch (props.column.field.type) {
        case 'string':
            return <TextCell key={props.column.id} {...props} />

        case 'long_text':
            return <LongTextCell key={props.column.id} {...props} />

        case 'document':
            return <RichTextCell key={props.column.id} {...props} />

        case 'url':
            return <UrlCell key={props.column.id} {...props} />

        case 'checkbox':
            return <CheckboxCell key={props.column.id} {...props} />

        case 'number':
            return <NumberCell key={props.column.id} {...props} />

        case 'currency':
            return <CurrencyCell key={props.column.id} {...props} />

        case 'percentage':
            return <PercentageCell key={props.column.id} {...props} />

        case 'date':
        case 'datetime':
            return <DateCell key={props.column.id} {...props} />

        case 'user_ref':
            return <UserCell key={props.column.id} {...props} />

        case 'multi_file':
            return <AttachmentsCell key={props.column.id} {...props} />

        case 'multi_lookup':
        case 'lookup':
            return <RecordLinksCell key={props.column.id} {...props} />

        case 'dropdown':
        case 'multi_select':
            return <DropdownCell key={props.column.id} {...props} />

        default:
            return (
                <BaseCell key={props.column.id} column={props.column}>
                    <Skeleton isLoading={props.isLoading}>
                        {props.value && String(props.value)}
                    </Skeleton>
                </BaseCell>
            )
    }
}, isEqual)

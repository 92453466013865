import React from 'react'

import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

type AddNewRecordPanelProps = React.ComponentPropsWithoutRef<typeof Box> & {
    addNewEmptyRecord: React.MouseEventHandler<HTMLButtonElement>
    totalRecordCount?: number
}

export const AddNewRecordPanel: React.FC<AddNewRecordPanelProps> = ({
    totalRecordCount = 0,
    addNewEmptyRecord,
    ...props
}) => {
    const { pageSize } = useDataGridContext()

    // If pagination is enabled, don't show the add new record panel.
    if (totalRecordCount > pageSize) return null

    return (
        <Box background="surfaceStrong" width="full" flex center {...props}>
            <Button
                variant="ghost"
                size="s"
                type="button"
                startIcon={{ name: 'Plus' }}
                color={{ default: 'textWeak', hover: 'text' }}
                onClick={addNewEmptyRecord}
            >
                To add a new record, click here or press Shift+Enter
            </Button>
        </Box>
    )
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'

import QueryString from 'qs'
import {} from 'supertokens-auth-react/recipe/passwordless'
import { createCode } from 'supertokens-web-js/recipe/passwordless'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { GlobalStaticState } from 'app/GlobalStaticState'
import { getUrl, Urls } from 'app/UrlService'

import { LoadingSplash } from 'v2/ui'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'

import { WorkspaceLoginFrame } from './WorkspaceLoginFrame'

type LoginFormData = {
    email: string
}
export function PortalLoginPage({ account, zone }: { account: Account; zone: AccountZone }) {
    const { user } = useAuthContext()
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormData>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: { email: query.email?.toString() ?? '' },
    })

    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const sendingEmail = useRef(false)
    const onSubmit = useCallback(
        async function onSubmit({ email }: LoginFormData) {
            try {
                setErrorMessage('')
                setIsLoading(true)
                sendingEmail.current = true
                const response = await createCode({ email, userContext: { zone_sid: zone._sid } })

                if (response.status !== 'OK') {
                    console.log(response)
                    setErrorMessage(response.reason)
                } else {
                    setEmailSent(true)
                }
            } catch (ex) {
                console.error(ex)
                setErrorMessage('Sending login link failed. Please try again.')
            } finally {
                setIsLoading(false)
                sendingEmail.current = false
            }
        },
        [zone._sid]
    )

    useEffect(() => {
        if (query.continue && query.email && !emailSent && !sendingEmail.current) {
            onSubmit({ email: query.email?.toString() ?? '' })
        }
    }, [emailSent, onSubmit, query.continue, query.email])
    GlobalStaticState.setSuperTokensTenant(account._sid)

    const title = zone.name

    if (user) {
        return <Redirect to={getUrl(Urls.Root)} />
    }

    if (!emailSent && query.continue) {
        return <LoadingSplash />
    }
    return (
        <WorkspaceLoginFrame
            zone={zone}
            title={zone.branding && zone.branding !== 'Name' ? title : null}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Box as="form" flex column gap="l" onSubmit={handleSubmit(onSubmit)}>
                {!emailSent && !query.continue && (
                    <>
                        <Banner
                            show={query.failed !== undefined}
                            icon={{ name: 'AlertCircle' }}
                            // title="Check your email"
                            type="warning"
                            helper="That link is no longer valid. Please enter your email address to receive a new one."
                        />
                        <Body textAlign="center">Enter your email below to access the portal.</Body>
                        <Input
                            placeholder="Email address"
                            {...register('email', { required: true })}
                            isError={!!errors?.email}
                            helperText={errors?.email && 'Email is required'}
                            autoFocus
                        />
                        {errorMessage && (
                            <Box color="textError" fontSize="bodyM" alignSelf="center" mb="m">
                                {errorMessage}
                            </Box>
                        )}
                        <Button variant="primary" type="submit" isLoading={isLoading}>
                            Continue
                        </Button>
                    </>
                )}
                {emailSent && (
                    <>
                        <Banner
                            show
                            icon={{ name: 'Mail' }}
                            title="Check your email"
                            type="success"
                            helper="If you are registered with this site, you'll receive an email with a link to log&nbsp;in."
                        />
                        <Button
                            alignSelf="center"
                            variant="link"
                            mt="m"
                            onClick={() => setEmailSent(false)}
                        >
                            Send another email
                        </Button>
                    </>
                )}
            </Box>
        </WorkspaceLoginFrame>
    )
}

import React, { useEffect, useMemo, useRef } from 'react'

import { JSONContent } from '@tiptap/react'

import { TaskDescription } from 'data/hooks/tasks/types'
import { SimpleBlockTypes } from 'features/tiptap/BlockTypes'
import { useKeyboardShortcutsExtension } from 'features/tiptap/Extensions/KeyboardShortcutExtension'
import { TipTapEditor, TipTapEditorHandle } from 'features/tiptap/TipTapEditor'

import { Box } from 'ui/components/Box'

import { getDescriptionEditorValue } from './descriptionUtils'

import { TasksDescriptionEditorStyle } from './TasksDescriptionEditor.css'

type TasksDescriptionEditorProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: TaskDescription
    onChange: (value: TaskDescription) => void
    isLoading?: boolean
}

export const TasksDescriptionEditor: React.FC<TasksDescriptionEditorProps> = ({
    value,
    onChange,
    isLoading,
    ...props
}) => {
    const editor = useRef<TipTapEditorHandle>(null)

    const handleChange = (newContent: JSONContent) => {
        const textValue = editor.current?.editor?.getText() ?? ''
        onChange({
            format: 'tiptap',
            content: newContent,
            plainTextContent: textValue,
        })
    }

    const keyboardShortcuts = useKeyboardShortcutsExtension({
        name: 'shortcuts',
        shortcuts: {
            'Mod-Enter': () => {
                return true
            },
        },
    })
    const editorExtensions = useMemo(() => [keyboardShortcuts], [keyboardShortcuts])

    const editorValue = useMemo(() => {
        return getDescriptionEditorValue(value)
    }, [value])

    useEffect(() => {
        if (!value || typeof value === 'string') return
        if (Boolean(value.plainTextContent)) return

        editor.current?.editor?.commands.setContent(value.content ?? null)
    }, [value])

    return (
        <Box {...props}>
            <TipTapEditor
                ref={editor}
                additionalExtensions={editorExtensions}
                content={editorValue}
                onChange={handleChange}
                readOnly={isLoading}
                placeholder="add some notes"
                allowedBlockTypes={SimpleBlockTypes}
                className={TasksDescriptionEditorStyle}
                allowMentions={false}
                allowPastingImages={false}
                allowDraggingImages={false}
            />
        </Box>
    )
}

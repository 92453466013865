import React from 'react'

import { useAppUserContext } from 'app/useAppUserContext'
import {
    addNewConnectionToCache,
    invalidateWorkflowIntegrations,
    useWorkflowIntegrations,
} from 'data/hooks/workflows/integrations'

import { Modal } from 'v2/ui'
import { useToast } from 'v2/ui/components/useToast'
import useModalToggle from 'v2/ui/utils/useModalToggle'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { ADD_NEW_CONNECTION_MODAL_KEY } from './addNewConnectionModalConstants'
import { IntegrationProviderIcon } from './IntegrationProviderIcon'
import { AuthStrategy, useConnectionAuthStrategy } from './useConnectionAuthStrategy'

type AddNewConnectionModalProps = {}

export const AddNewConnectionModal: React.FC<AddNewConnectionModalProps> = () => {
    const modal = useModalToggle<{
        onSuccessfulAuth?: (connection: WorkflowIntegrationConnectionDto) => void
    }>(ADD_NEW_CONNECTION_MODAL_KEY)
    const { isOpen, toggle, data: modalParams } = modal

    const handleClose = () => {
        toggle()
    }

    const onSuccessfulAuth = (connection: WorkflowIntegrationConnectionDto) => {
        handleClose()
        modalParams?.onSuccessfulAuth?.(connection)
    }

    return (
        <Modal
            isOpen={isOpen}
            zIndex={9999}
            onClose={handleClose}
            noDividers
            size="300px"
            title="Pick a provider"
            body={<AddNewConnection onSuccessfulAuth={onSuccessfulAuth} />}
        />
    )
}

type AddNewConnectionProps = {
    onSuccessfulAuth?: (connection: WorkflowIntegrationConnectionDto) => void
}

const AddNewConnection: React.FC<AddNewConnectionProps> = ({ onSuccessfulAuth }) => {
    const { data: integrationsData } = useWorkflowIntegrations()

    const providers = integrationsData?.providers ?? []
    const connectionId = integrationsData?.connection_id ?? ''

    const { user } = useAppUserContext()

    return (
        <Box>
            {providers.map((provider) => (
                <ConnectionAuthenticator
                    key={provider.id}
                    provider={provider}
                    connectionId={connectionId}
                    userId={user?._sid}
                    onSuccessfulAuth={onSuccessfulAuth}
                />
            ))}
        </Box>
    )
}

type ConnectionAuthenticatorProps = {
    provider: WorkflowIntegrationProviderDto
    connectionId: string
    userId?: string
    onSuccessfulAuth?: (connection: WorkflowIntegrationConnectionDto) => void
}

const ConnectionAuthenticator: React.FC<ConnectionAuthenticatorProps> = ({
    provider,
    connectionId,
    userId,
    onSuccessfulAuth,
}) => {
    const authStrategy = useConnectionAuthStrategy(provider.auth_strategy as AuthStrategy)

    const toast = useToast()

    const handleAuth = async () => {
        try {
            if (!connectionId) {
                throw new Error('Connection ID not set for provider.')
            }

            await authStrategy.auth({
                providerId: provider.id,
                connectionId,
                authScopes: provider.auth_scopes,
                hmac: provider.hmac,
            })

            toast({ title: 'Connection added successfully.', status: 'success' })

            const newConnection: WorkflowIntegrationConnectionDto = {
                id: connectionId,
                provider_id: provider.id,
                user_id: userId ?? '',
                created_at: new Date().toISOString(),
            }
            await addNewConnectionToCache(newConnection)
            onSuccessfulAuth?.(newConnection)

            invalidateWorkflowIntegrations()
        } catch (err) {
            console.log('# auth error:', err)

            toast({
                title: 'There was a problem authenticating your connection. Please try again later.',
                status: 'error',
            })
        }
    }

    return (
        <Button type="button" variant="secondary" size="m" center onClick={handleAuth}>
            <IntegrationProviderIcon fontSize="bodyXl" providerId={provider.id} />
            <Box as="span" fontSize="bodyM">
                {provider.name}
            </Box>
        </Button>
    )
}

import { useCallback, useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useMainNavigationState() {
    const { mainNavState, selectedApp, isPortal, toggleMobileMainNavigation, showTrialBanner } =
        useNavigationContext()

    const isNavHidden =
        mainNavState === 'collapsed' ||
        mainNavState === 'mobile-collapsed' ||
        (!selectedApp && !isPortal)

    const showMobileControls = mainNavState === 'mobile-expanded'

    const onBackClick = useCallback(() => {
        toggleMobileMainNavigation(false)
    }, [toggleMobileMainNavigation])

    return useMemo(
        () => ({
            isNavHidden,
            isPortal,
            showMobileControls,
            onBackClick,
            showTrialBanner,
        }),
        [isNavHidden, isPortal, onBackClick, showMobileControls, showTrialBanner]
    )
}

import React from 'react'

import { ButtonProps } from '@chakra-ui/react'
import InlineCreate from 'v2/views/Create/InlineCreate'
import { getCreateViewUrl } from 'v2/views/utils/getCreateViewUrl'

import { useObject } from 'data/hooks/objects'
import { WithPages } from 'data/wrappers/WithPagesComponent'
import { WithViewsComponent } from 'data/wrappers/WithViewsComponent'

import { Button } from 'v2/ui'

type CreateButtonProps = {
    objectId: string
    queryParams?: string
    text?: string
    buttonVariant?: string
    buttonSize?: string
    size?: string
} & Omit<ButtonProps, 'children'>

const CreateButton = ({
    objectId,
    queryParams,
    size,
    buttonSize = 'medium',
    buttonVariant = 'App',
    ...props
}: CreateButtonProps) => {
    const inlineAdd = true
    const { object } = useObject(objectId)

    return (
        <WithViewsComponent allStacks={false}>
            {({ views }: { views: ViewDto[] }) => {
                const createView = views.find(
                    (view) => view.object_id === objectId && view.type === 'create'
                )
                if (createView) {
                    return (
                        <WithPages allStacks={false}>
                            {({ pages }: { pages: PageDto[] }) => {
                                const createViewUrl = getCreateViewUrl(
                                    createView,
                                    pages,
                                    object,
                                    queryParams
                                )
                                const viewPage = pages.find(
                                    (page) => page.options.view_id === createView._sid
                                )
                                if (!object || !viewPage) return null
                                return (
                                    <>
                                        {inlineAdd ? (
                                            <InlineCreate
                                                objectId={objectId}
                                                queryParams={queryParams}
                                                buttonProps={{
                                                    variant: buttonVariant,
                                                    buttonSize,
                                                    size,
                                                    ml: 1,
                                                    iconProps: {
                                                        pr: props.text === '' ? 0 : undefined,
                                                    },
                                                    style: {
                                                        paddingRight:
                                                            props.text === '' ? 11 : undefined,
                                                        paddingLeft:
                                                            props.text === '' ? 11 : undefined,
                                                    },
                                                    className: 'stk-add-new-button',
                                                }}
                                                ButtonText={props.text ?? 'Add New'}
                                            />
                                        ) : (
                                            <Button
                                                variant={buttonVariant}
                                                buttonSize={buttonSize}
                                                icon="add"
                                                ml={1}
                                                href={createViewUrl}
                                                {...props}
                                                iconProps={{
                                                    pr: props.text === '' ? 0 : undefined,
                                                }}
                                                style={{
                                                    paddingRight:
                                                        props.text === '' ? 11 : undefined,
                                                    paddingLeft: props.text === '' ? 11 : undefined,
                                                }}
                                                className="stk-add-new-button"
                                                size={size}
                                            >
                                                {props.text ?? 'Add New'}
                                            </Button>
                                        )}
                                    </>
                                )
                            }}
                        </WithPages>
                    )
                }

                return null
            }}
        </WithViewsComponent>
    )
}

export default CreateButton

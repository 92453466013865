import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { StandardComponentProps } from 'ui/helpers/styles'

import { FadeInOnContainerHoverStyle, ItemContainerStyle } from './FeedFilterStyle.css'

type FeedFilterProps<T, V> = StandardComponentProps & {
    items: T[]
    idField: string
    selectedIds: V[]
    setSelectedIds: (ids: V[]) => void
    renderItem: (item: T) => React.ReactNode
    renderContent: (selectedItems: T[]) => React.ReactNode
}
export function FeedFilter<T extends Record<string, unknown>, V>({
    items,
    idField,
    selectedIds,
    setSelectedIds,
    renderItem,
    renderContent,
    ...props
}: FeedFilterProps<T, V>) {
    const selectedItems = items.filter((item) => selectedIds.includes(item[idField] as V))
    const toggleItem = (id: V, value: boolean) => {
        if (value) {
            setSelectedIds([...selectedIds, id])
        } else {
            setSelectedIds(selectedIds.filter((i) => i !== id))
        }
    }

    return (
        <Container
            variant={selectedIds.length !== items.length ? 'neutral' : 'default'}
            flex
            center
            justifyContent="space-between"
            {...props}
        >
            <Dropdown>
                <DropdownButton
                    variant="ghost"
                    size="2xs"
                    style={{ height: '100%', borderRadius: 0 }}
                >
                    {renderContent(selectedItems)}
                </DropdownButton>
                <DropdownContent align="start">
                    {items?.map((item) => (
                        <DropdownItem
                            multiSelect
                            className={ItemContainerStyle}
                            key={item[idField] as string}
                            checked={selectedIds.includes(item[idField] as V)}
                            onCheckedChange={(value) => toggleItem(item[idField] as V, value)}
                            onSelect={(e) => e.preventDefault()}
                            label={
                                <Box flex center width="full">
                                    {renderItem(item)}
                                    <Box grow flex center justifyContent="flex-end" ml="m">
                                        <Box
                                            className={FadeInOnContainerHoverStyle}
                                            textDecoration={{ hover: 'underline' }}
                                            onClick={(e: React.MouseEvent) => {
                                                setSelectedIds([item[idField] as V])
                                                e.stopPropagation()
                                                e.preventDefault()
                                            }}
                                        >
                                            only
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        />
                    ))}
                </DropdownContent>
            </Dropdown>
            {selectedIds.length !== items.length && (
                <Button
                    variant="ghost"
                    size="xs"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={() => {
                        setSelectedIds([])
                    }}
                    mr="xs"
                    startIcon={{ name: 'X' }}
                />
            )}
        </Container>
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'

import { WorkflowLoopNode } from './FlowControl/WorkflowLoop'
import { FlowLine } from './FlowLine'
import { WorkflowActionNode } from './WorkflowAction'
import { WorkflowChainContextProvider } from './WorkflowChainContext'
import { WorkflowFinishedNode } from './WorkflowFinishedNode'
import { WorkflowTriggerNode } from './WorkflowTrigger'

type WorkflowChainProps = React.ComponentPropsWithoutRef<typeof Box> & {
    items: WorkflowItem[]
    onlyShowTrigger?: boolean
    showFinishedNode?: boolean
    parentId?: string
    compact?: boolean
}

export const WorkflowChain: React.FC<WorkflowChainProps> = ({
    items,
    onlyShowTrigger = false,
    showFinishedNode = true,
    parentId,
    children,
    compact,
    ...props
}) => {
    return (
        <WorkflowChainContextProvider parentId={parentId}>
            <Box {...props}>
                {items.map((item, idx, itemList) => {
                    if (onlyShowTrigger && item.kind !== 'trigger') return null

                    const nextItem = itemList[idx + 1]
                    const line: WorkflowLine = {
                        id: `${item.id}->${nextItem?.id}`,
                        fromItemId: item.id,
                        toItemId: nextItem?.id,
                    }

                    return (
                        <React.Fragment key={item.id}>
                            <RenderNode item={item} />

                            {!onlyShowTrigger && <FlowLine line={line} compact={compact} />}
                        </React.Fragment>
                    )
                })}
                {!onlyShowTrigger && showFinishedNode && <WorkflowFinishedNode />}
                {children}
            </Box>
        </WorkflowChainContextProvider>
    )
}

function RenderNode({ item }: { item: WorkflowItem }) {
    switch (item.kind) {
        case 'trigger':
            return <WorkflowTriggerNode trigger={item as WorkflowTriggerConfig} />

        case 'action':
            return <WorkflowActionNode action={item as WorkflowActionConfig} />

        case 'loop':
            return <WorkflowLoopNode node={item as WorkflowLoopConfig} />

        default:
            return null
    }
}

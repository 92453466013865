import React, { useCallback, useEffect, useState } from 'react'

import { useDropdownContext } from 'ui/components/Dropdown/dropdownContext'

import { SelectContext } from './selectContext'
import { SelectOptionItem } from './types'

export type SelectContextProviderProps = {
    children: React.ReactNode
    value?: string[]
    defaultValue?: string[]
    onChange?: (value: string[]) => void
    multiSelect?: boolean
    options?: SelectOptionItem[]
    isClearable?: boolean
}

export const SelectContextProvider: React.FC<SelectContextProviderProps> = ({
    children,
    value: providedValue,
    defaultValue,
    onChange,
    multiSelect = false,
    options = [],
    isClearable,
}) => {
    const dropdownContext = useDropdownContext()

    const isControlled = typeof providedValue !== 'undefined'
    const [value, setValue] = useState<string[]>(providedValue || defaultValue || [])
    useEffect(() => {
        setValue(providedValue || defaultValue || [])
    }, [providedValue, defaultValue])

    const handleChange = useCallback(
        (newValue: string[]) => {
            if (!isControlled) {
                setValue(newValue)
            }
            onChange?.(newValue)
        },
        [onChange, isControlled]
    )

    const setOptionState = useCallback(
        (optionValue: string, checked: boolean) => {
            let newValue: string[] = []
            if (checked) {
                if (multiSelect) {
                    newValue = [...value, optionValue]
                } else {
                    newValue = [optionValue]
                }
            } else {
                // Prevent deselecting the last value if the input is not clearable.
                const isLastValue = value.length === 1
                if (isLastValue && !isClearable) return

                newValue = value.filter((v) => v !== optionValue)
            }

            handleChange(newValue)
        },
        [handleChange, isClearable, multiSelect, value]
    )

    const clearValue = useCallback(() => {
        handleChange([])
    }, [handleChange])

    const close = useCallback(() => {
        dropdownContext?.setIsOpen(false)
    }, [dropdownContext])

    useEffect(() => {
        // When disabling multiSelect, make sure to only keep the first value.
        if (!multiSelect) {
            if (value.length > 1) {
                handleChange([value[0]])
            }
        }
    }, [handleChange, multiSelect, value])

    return (
        <SelectContext.Provider
            value={{
                isOpen: dropdownContext?.isOpen || false,
                close,
                value,
                setValue: handleChange,
                setOptionState,
                clearValue,
                multiSelect,
                options,
                isClearable: !!isClearable,
            }}
        >
            {children}
        </SelectContext.Provider>
    )
}

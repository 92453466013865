import React, { forwardRef, useCallback, useEffect, useState } from 'react'

import * as Parts from './Pagination.parts'
import { PaginationControls } from './PaginationControls'
import { PaginationNavigation } from './PaginationNavigation'

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50]

type PaginationRef = HTMLDivElement

type PaginationProps = React.ComponentPropsWithoutRef<typeof Parts.Root> & {
    showPerPage?: boolean
    pageSize?: number
    pageSizeOptions?: number[]
    totalItemsCount?: number
    onChangePageSize?: (pageSize: number) => void

    pageIndex?: number
    onChangePageIndex?: (pageIndex: number) => void
}

export const Pagination = forwardRef<PaginationRef, PaginationProps>(
    (
        {
            pageSize: providedPageSize,
            pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
            showPerPage,
            totalItemsCount = 0,
            onChangePageSize,
            pageIndex: providedPageIndex,
            onChangePageIndex,
            ...props
        },
        ref
    ) => {
        const isPageIndexControlled = typeof providedPageIndex !== 'undefined'
        const [pageIndex, setPageIndex] = useState(providedPageIndex ?? 0)
        useEffect(() => {
            setPageIndex(providedPageIndex ?? 0)
        }, [providedPageIndex])

        const handlePageIndexChange = useCallback(
            (newIdx: number) => {
                if (!isPageIndexControlled) {
                    setPageIndex(newIdx)
                }

                onChangePageIndex?.(newIdx)
            },
            [isPageIndexControlled, onChangePageIndex]
        )

        const isPageSizeControlled = typeof providedPageSize !== 'undefined'
        const [pageSize, setPageSize] = useState(providedPageSize ?? DEFAULT_PAGE_SIZE)
        useEffect(() => {
            setPageSize(providedPageSize ?? DEFAULT_PAGE_SIZE)
        }, [providedPageSize])

        const handlePageSizeChange = useCallback(
            (newSize: number) => {
                if (!isPageSizeControlled) {
                    setPageSize(newSize)
                }

                onChangePageSize?.(newSize)

                // If the current page is out of bounds, reset it to the first page.
                const newPageCount = Math.ceil(totalItemsCount / newSize)
                if (pageIndex >= newPageCount) {
                    handlePageIndexChange(0)
                }
            },
            [
                handlePageIndexChange,
                isPageSizeControlled,
                onChangePageSize,
                pageIndex,
                totalItemsCount,
            ]
        )

        const pageCount = Math.ceil(totalItemsCount / pageSize)

        return (
            <Parts.Root {...props} ref={ref}>
                <PaginationControls
                    pageSize={pageSize}
                    pageSizeOptions={pageSizeOptions}
                    showPerPage={showPerPage}
                    totalItemsCount={totalItemsCount}
                    onChangePageSize={handlePageSizeChange}
                    pageIndex={pageIndex}
                />
                <PaginationNavigation
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    onChangePageIndex={handlePageIndexChange}
                />
            </Parts.Root>
        )
    }
)

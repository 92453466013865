import React from 'react'

import { Icon } from 'v2/ui/index'

export const PERCENTAGE_FIELD_OPTIONS = [
    {
        value: 'percentNumber',
        label: 'Number',
        icon: <Icon icon="fa-3" size="20px" color="userInterface.accent.1000" />,
    },
    {
        value: 'percentBar',
        label: 'Bar',
        icon: <Icon icon="faBarsProgress" size="20px" color="userInterface.accent.1000" />,
    },
    {
        value: 'percentRing',
        label: 'Ring',
        icon: <Icon icon="faSpinnerThird" size="20px" color="userInterface.accent.1000" />,
    },
]

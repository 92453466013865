import React from 'react'

import { useAdvancedFilterValueNumberState } from './hooks/useAdvancedFilterValueNumberState'
import { AdvancedFilterInput } from './AdvancedFilterInput'
import { AdvancedFilter } from './types'

const MAX_LENGTH = 30

type AdvancedFilterValueNumberProps = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
    className?: string
}

export const AdvancedFilterValueNumber: React.FC<AdvancedFilterValueNumberProps> = ({
    filter,
    onChange,
    className,
    onRemoveFilter,
}) => {
    const { onInputChange, onInputBlur, onInputKeyDown, value, inputRef } =
        useAdvancedFilterValueNumberState({
            filter,
            onChange,
            onRemoveFilter,
        })

    return (
        <AdvancedFilterInput
            ref={inputRef}
            value={value[0]}
            onChange={onInputChange}
            onBlur={onInputBlur}
            onKeyDown={onInputKeyDown}
            maxLength={MAX_LENGTH}
            className={className}
            autoFocus={filter.isDraft}
        />
    )
}

import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'

import { Content, Editor, Extensions } from '@tiptap/core'
import { LinkOptions } from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { Node } from '@tiptap/pm/model'
import { AnyExtension, EditorContent, EditorOptions, JSONContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import classNames from 'classnames'
import { debounce } from 'lodash'
import shortid from 'shortid'

import { LoadingScreen } from 'v2/ui'
import useDebounce from 'v2/ui/utils/useDebounce'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { Container } from 'ui/components/Container'

import { createCodeBlockExtension } from './Extensions/CodeBlockExtension'
import { createCodeExtension } from './Extensions/CodeExtension'
import { createLinkExtension } from './Extensions/LinkExtension'
import { createListKeymapExtension } from './Extensions/ListKeymapExtension'
import { createPasteExtension } from './Extensions/PasteExtension'
import { createVersionedDocumentExtension } from './Extensions/VersionedDocumentExtension'
import { BlockType, HeadingTypes } from './BlockTypes'
import { BubbleToolbar } from './BubbleToolbar'
import { useEditor } from './useEditor'
import { usePreprocessContentForPreview } from './utilities'

import { TipTapEditorBaseStyles } from './TipTapEditorBase.css'

export type TipTapEditorBaseHandle = {
    editor: Editor | null
    container: HTMLDivElement | undefined
}

export type TipTapEditorBaseProps = React.ComponentPropsWithoutRef<typeof Container> & {
    content?: Content
    placeholder?: string
    showPlaceholderWhenReadOnly?: boolean
    onChange?: (content: Content) => void
    extensions?: Extensions
    readOnly?: boolean
    allowedBlockTypes?: BlockType[]
    renderContent?: boolean

    disableHistory?: boolean
    allowComments?: boolean
    autoFocus?: boolean

    isLoading?: boolean

    schemaVersion?: number
    disableBaseExtensions?: boolean
    disableToolbar?: boolean
    tiptapProps?: EditorOptions

    linkOptions?: Partial<LinkOptions>
}

export const TipTapEditorBase = React.forwardRef<TipTapEditorBaseHandle, TipTapEditorBaseProps>(
    (
        {
            content,
            placeholder,
            onChange,
            extensions,
            readOnly,
            variant = 'transparent',
            className,
            allowedBlockTypes,
            disableHistory,
            allowComments,
            autoFocus,
            children,
            isLoading,
            schemaVersion,
            disableBaseExtensions,
            disableToolbar,
            tiptapProps,
            renderContent = true,
            showPlaceholderWhenReadOnly = false,
            linkOptions,
            ...props
        },
        ref
    ) => {
        const editorRef = useRef<Editor | null>(null)

        // Prevent attempting to render empty objects, which results in an error.
        let controlledContent = !content || Object.keys(content).length < 1 ? null : content
        controlledContent = usePreprocessContentForPreview(
            editorRef.current,
            controlledContent as JSONContent,
            readOnly
        )

        const previousSuppliedContent = useRef<Content | undefined>(controlledContent)
        const pendingContentUpdate = useRef<Content | null>(null)
        const instance = useRef(shortid.generate())
        const [containerRef, setContainerRef] = useState<HTMLDivElement | undefined>()
        const onChangeRef = useRef(onChange)
        onChangeRef.current = onChange

        // Debounce this change handler so we don't get laggy typing due to getting json every time
        const changeHandler = useRef(
            debounce((editor: Editor) => {
                const data = editor.getJSON()

                pendingContentUpdate.current = data
                onChangeRef.current?.(data)
            }, 200)
        )

        const editorExtensions = useMemo(() => {
            const editorExtensions: AnyExtension[] = [
                createLinkExtension(linkOptions),
                createVersionedDocumentExtension(schemaVersion),
                createPasteExtension(),
            ]

            if (!disableBaseExtensions) {
                editorExtensions.push(
                    StarterKit.configure({
                        history: disableHistory ? false : undefined,
                        blockquote: { HTMLAttributes: { class: 'blockquote' } },
                        heading: {
                            // @ts-expect-error
                            levels: allowedBlockTypes
                                ? HeadingTypes.filter((h) =>
                                      allowedBlockTypes?.includes(h.type)
                                  ).map((h) => h.level)
                                : [1, 2, 3],
                        },
                        document: false,
                        code: false,
                    }),
                    createListKeymapExtension(),
                    createCodeBlockExtension(),
                    createCodeExtension()
                )
            }

            if (placeholder) {
                editorExtensions.push(
                    Placeholder.configure({
                        placeholder,
                        showOnlyWhenEditable: !showPlaceholderWhenReadOnly,
                    })
                )
            }

            if (extensions) {
                editorExtensions.push(...extensions)
            }

            return editorExtensions
        }, [
            allowedBlockTypes,
            disableBaseExtensions,
            disableHistory,
            extensions,
            placeholder,
            schemaVersion,
            showPlaceholderWhenReadOnly,
            linkOptions,
        ])

        const editor = useEditor({
            extensions: editorExtensions,
            onUpdate: ({ editor }) => {
                changeHandler.current(editor)
            },
            content: controlledContent,
            editable: !readOnly,
            autofocus: autoFocus && !readOnly ? true : false,
            ...tiptapProps,
        })
        editorRef.current = editor

        const isContentEqual = useCallback((a: Content | undefined, b: Content | undefined) => {
            const schema = editorRef.current?.schema

            // if the values are literally the same (as in the case when both are undefined, for instance)
            // return true
            if (a === b) return true

            if (!schema || !a || !b) return false

            const nodeA = Node.fromJSON(schema, a)
            const nodeB = Node.fromJSON(schema, b)
            return nodeA.eq(nodeB)
        }, [])

        useEffectOnlyOnUpdate(() => {
            if (autoFocus) {
                editorRef.current?.commands.focus()
            }
        }, [autoFocus])

        const onSuppliedContentChange = useDebounce(
            useCallback(
                (content: Content) => {
                    // If we've actually received new content,
                    // update the editor
                    if (!isContentEqual(content, previousSuppliedContent.current)) {
                        console.log(
                            '# resetting content',
                            instance.current,
                            content,
                            previousSuppliedContent.current
                        )
                        editorRef?.current?.commands.setContent(content)
                    }

                    previousSuppliedContent.current = content
                },
                [isContentEqual]
            ),
            300
        )

        useEffect(() => {
            // If the supplied content coming in matches the most recent
            // updated content posted to the parent, then we clear out the
            // pendingContentUpdate flag.
            // NOTE: only do this if the controlledContent is non-null
            if (
                controlledContent &&
                isContentEqual(controlledContent, pendingContentUpdate.current)
            ) {
                pendingContentUpdate.current = null
                previousSuppliedContent.current = controlledContent
            } else if (!pendingContentUpdate.current) {
                // If we don't have any pending content update that hasn't
                // been processed by the parent, then we can safely update
                // the editor with the new content being supplied by the parent.
                //
                // We need this pending content update check becaues the parent may be
                // debouncing changes and thus may supply us with content that doesn't (yet) contain
                // the latest changes the user has made.
                onSuppliedContentChange(controlledContent)
            }
        }, [controlledContent, isContentEqual, onSuppliedContentChange])

        useImperativeHandle(
            ref,
            () => ({
                editor,
                container: containerRef,
            }),
            [editor, containerRef]
        )

        useEffect(() => {
            editor?.setEditable(!readOnly, false)
        }, [editor, readOnly])
        if (!editor) return null
        editorRef.current = editor

        const handleKeyDown = (e: React.KeyboardEvent) => {
            props.onKeyDown?.(e)

            // Prevent tabbing out of list items.
            if (e.key === 'Tab') {
                let focusedNode = window.getSelection()?.focusNode as HTMLElement | null
                if (focusedNode?.TEXT_NODE) {
                    focusedNode = focusedNode.parentElement
                }

                const isInList = focusedNode?.closest('li')
                if (isInList) {
                    e.preventDefault()
                }
            }
        }

        if (!renderContent) return null

        if (isLoading) {
            return (
                <Container variant={variant} {...props} height="100px">
                    <LoadingScreen isLoading />
                </Container>
            )
        }
        return (
            <Container
                ref={setContainerRef}
                variant={variant}
                position="relative"
                {...props}
                onKeyDown={handleKeyDown}
                className={classNames('EditorContainer', className, TipTapEditorBaseStyles)}
            >
                <EditorContent editor={editor} />

                {editor && !disableToolbar && (
                    // Not sure why, but without this wrapping div, react sometimes throws an error about failing
                    // to removeChild from a parent. Fix mentioned in this issue: https://github.com/ueberdosis/tiptap/issues/3784
                    <div>
                        <BubbleToolbar
                            editor={editor}
                            allowedBlockTypes={allowedBlockTypes}
                            allowComments={allowComments}
                        />
                    </div>
                )}
                {children}
            </Container>
        )
    }
)

import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { History } from 'history'
import { isEqual } from 'lodash'
import { getFiltersFromQuery, getQueryFromFilters } from 'v2/views/utils/urlFilterConverters'

const PAGINATION_URL_PARAM = 'page_num'
const SORT_URL_PARAM = '__sort'
const SEARCH_URL_PARAM = 'search'

type UrlParams = ReturnType<typeof parseParams>

export type ListViewUrlParams = ReturnType<typeof useListViewUrlParams>

type UseListViewUrlParamsOptions = {
    fields: FieldDto[]
    disabled?: boolean
}

export function useListViewUrlParams(options: UseListViewUrlParamsOptions) {
    const { disabled, fields } = options

    const fieldsRef = useRef(fields)
    fieldsRef.current = fields

    const disabledRef = useRef(disabled)
    disabledRef.current = disabled

    const history = useHistory() as History
    const location = useLocation()
    const searchRef = useRef(location.search)
    searchRef.current = location.search

    const [params, setParams] = useState<UrlParams>(parseParams(location.search, fields))
    const paramsRef = useRef(params)
    paramsRef.current = params

    useLayoutEffect(() => {
        if (disabled) return

        const fields = fieldsRef.current
        setParams(parseParams(location.search, fields))
    }, [disabled, location.search])

    const handleSetParams = useCallback(
        (params: Partial<UrlParams>) => {
            requestAnimationFrame(() => {
                if (disabledRef.current) return

                const currParams = paramsRef.current
                const newValue = {
                    ...currParams,
                    ...params,
                }

                // If the params are the same, we don't need to do anything.
                const isChanged = !isEqual(currParams, newValue)
                if (!isChanged) return

                const existingSearch = searchRef.current
                const fields = fieldsRef.current

                const encodedSearch = encodeParams(newValue, fields, existingSearch)
                history.replace({
                    search: encodedSearch,
                })

                paramsRef.current = newValue
            })
        },
        [history]
    )

    return useMemo(
        () => ({
            ...params,
            setParams: handleSetParams,
        }),
        [params, handleSetParams]
    )
}

function parseParams(search: string, fields: FieldDto[]) {
    const searchParams = new URLSearchParams(search)

    const pageNum = searchParams.get(PAGINATION_URL_PARAM) ?? undefined
    const pageIndex = pageNum ? parseInt(pageNum, 10) - 1 : undefined

    const sort = searchParams.get(SORT_URL_PARAM) ?? undefined
    const isSortDesc = sort?.startsWith('-') ?? false
    const sortDirection = isSortDesc ? 'desc' : 'asc'

    let sortField: string | undefined = sort ?? undefined
    if (isSortDesc) {
        sortField = sort?.slice(1)
    }

    const searchQuery = searchParams.get(SEARCH_URL_PARAM) ?? undefined

    const query = Object.fromEntries(searchParams.entries())
    const filters: Filter[] = getFiltersFromQuery(query, fields)

    return {
        pageIndex,
        sortField,
        sortDirection,
        searchQuery,
        filters,
    }
}

function encodeParams(params: UrlParams, fields: FieldDto[], existingSearch?: string) {
    let searchParams = new URLSearchParams(existingSearch)

    if (params.pageIndex) {
        searchParams.set(PAGINATION_URL_PARAM, String(params.pageIndex + 1))
    } else {
        searchParams.delete(PAGINATION_URL_PARAM)
    }

    if (params.sortField) {
        const isDesc = params.sortDirection === 'desc'
        const sort = isDesc ? `-${params.sortField}` : params.sortField
        searchParams.set(SORT_URL_PARAM, sort)
    } else {
        searchParams.delete(SORT_URL_PARAM)
    }

    if (params.searchQuery) {
        searchParams.set(SEARCH_URL_PARAM, params.searchQuery)
    } else {
        searchParams.delete(SEARCH_URL_PARAM)
    }

    if (params.filters) {
        const existingSearchWithParams = searchParams.toString()
        const filterParams = getQueryFromFilters(
            params.filters,
            fields,
            existingSearchWithParams,
            true
        ) as Record<string, string | string[]>

        searchParams = new URLSearchParams()
        for (const [key, value] of Object.entries(filterParams)) {
            if (Array.isArray(value)) {
                value.forEach((val) => searchParams.append(key, val))
            } else {
                searchParams.set(key, value)
            }
        }
    }

    return searchParams.toString()
}

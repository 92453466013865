import React from 'react'
import { useHistory } from 'react-router-dom'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { Urls } from 'app/UrlService'
import { buildUrl } from 'data/utils/utils'
import { getRedirectParam } from 'utils/utils'

import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'

import { AuthFrame } from './AuthFrame'

export function MustLoginToAccessWorkspacePage({
    workspaceAccount,
}: {
    workspaceAccount: Account
}) {
    const { logOut } = useAuthContext()
    const history = useHistory()

    const redirectTo = getRedirectParam()

    const handleClick = async () => {
        await logOut()
        history.push(buildUrl(Urls.Login, { r: redirectTo }))
    }
    return (
        <AuthFrame title="Log in">
            <Body textAlign="center">
                You must log in again to access the {workspaceAccount?.name} workspace.
            </Body>
            <Button variant="secondary" mt="2xl" onClick={handleClick}>
                Continue
            </Button>
        </AuthFrame>
    )
}

import { useMemo } from 'react'
import { UseQueryOptions } from 'react-query'

import { useTasks } from 'data/hooks/tasks/tasks'
import {
    GetTasksParams,
    GetTasksPayload,
    TaskDescription,
    TaskPayload,
} from 'data/hooks/tasks/types'

import { CategorizedTasks } from './types'

export function useCategorizedTasks(
    categorizer: (tasks: TaskPayload[]) => CategorizedTasks,
    params: GetTasksParams & { searchValue?: string } = {},
    options: UseQueryOptions = {}
) {
    const { searchValue, ...finalParams } = params
    const { data, ...query } = useTasks(finalParams, options as UseQueryOptions<GetTasksPayload>)

    const categorizedTasks = useMemo(() => {
        const search = searchValue?.toLowerCase()?.trim() ?? ''
        const filtered =
            data?.tasks.filter(
                (task) =>
                    !searchValue ||
                    task.title.toLowerCase().includes(search) ||
                    searchDescription(task.description, search)
            ) ?? []
        return categorizer(filtered)
    }, [categorizer, data?.tasks, searchValue])

    return { ...query, data: categorizedTasks && data ? { categorizedTasks, ...data } : undefined }
}

function searchDescription(desc: TaskDescription | undefined, query: string) {
    if (typeof desc === 'string') {
        return desc.toLowerCase().includes(query)
    }

    return desc?.plainTextContent?.toLowerCase().includes(query)
}

// @ts-strict-ignore
import React from 'react'

import { useConfirmModal } from 'app/useConfirmModal'

import { Text } from 'v2/ui'

const useShowConfirmPasting = () => {
    const { show } = useConfirmModal()

    const showConfirm = (
        numberRecordsPasted: number,
        numberRecordsCreated: number,
        onConfirm: () => void,
        onCancel: () => void
    ) => {
        const pastedRecordsLabel = formatRecordCount(numberRecordsPasted)
        const createdRecordsLabel = formatRecordCount(numberRecordsCreated)

        show({
            title: 'Pasting Raw Data',
            message: (
                <>
                    <Text>
                        You are about to overwrite
                        <br />
                        {`${pastedRecordsLabel}${
                            numberRecordsCreated > 0 ? ` and add ${createdRecordsLabel}` : ''
                        }.`}
                        <br />
                        Are you sure you want to paste?
                    </Text>
                    <Text mt={4} fontWeight="bold">
                        This action cannot be undone.
                    </Text>
                </>
            ),
            confirmVariant: 'PrimaryError',
            onConfirm: (modal) => {
                onConfirm()
                modal.toggle()
            },
            onCancel: (modal) => {
                onCancel()
                modal.toggle()
            },
        })
    }

    return showConfirm
}

export default useShowConfirmPasting

function formatRecordCount(count: number) {
    if (count === 1) {
        return '1 record'
    }

    return `${count} records`
}

import React from 'react'

import classNames from 'classnames'

import { CommentCountDisplay } from 'features/Activity/CommentCount'

import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { DocumentCommentFeedButtonStyle } from './DocumentCommentFeed.css'

type DocumentCommentFeedButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {
    isExpanded?: boolean
    count?: number
}

export const DocumentCommentFeedButton: React.FC<DocumentCommentFeedButtonProps> = ({
    isExpanded,
    count = 0,
    ...props
}) => {
    const isMobile = useIsMobile()
    if (isMobile) return null

    return (
        <Box display="inline-flex">
            <Button
                type="button"
                size="xs"
                variant="ghost"
                iconOnly={true}
                {...props}
                className={classNames(
                    DocumentCommentFeedButtonStyle({ isExpanded }),
                    props.className
                )}
            >
                <CommentCountDisplay
                    count={count}
                    alwaysShow={true}
                    iconProps={{
                        size: 'l',
                    }}
                    textProps={{
                        top: '2xs',
                    }}
                />
            </Button>
        </Box>
    )
}

import React, { useEffect, useState } from 'react'

import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react'
import shortid from 'shortid'

import { keywordMap } from 'features/formulas/constants/formulaEditorTextConstants'
import { FunctionArgument, FunctionDisplayTemplatePart } from 'features/formulas/formulaTypes'

import { Box } from 'ui/components/Box'
import { Combobox } from 'ui/components/Combobox'

import { ExpressionInput } from './ExpressionInput'
import { ContextGroup, ContextSchema } from './types'

type FormulaFunctionComponentProps = NodeViewRendererProps & {
    className?: string
    provideContextSchema: () => ContextSchema
    updateAttributes: (attrs: any) => void
    renderGroupTitle: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
    returnType?: FieldType
    returnTypeOptions?: FieldOptions
}
export const FormulaFunctionComponent: React.FC<FormulaFunctionComponentProps> = ({
    node,
    className,
    provideContextSchema,
    updateAttributes,
    renderGroupTitle,
    returnType,
    returnTypeOptions,
}) => {
    const [container, setContainer] = useState<HTMLElement | null>(null)

    const { id } = node.attrs
    const args = node.attrs.args || {}
    const functionDef = keywordMap[id as keyof typeof keywordMap]

    useEffect(() => {
        if (!container) return

        function handleFocus(e: FocusEvent) {
            e.stopPropagation()
            e.preventDefault()
        }

        container.addEventListener('focusin', handleFocus)

        return () => {
            container.removeEventListener('focusin', handleFocus)
        }
    }, [container])

    if (!functionDef) return null

    return (
        <NodeViewWrapper as="span" className={className}>
            <Box ref={setContainer} as="span" display="inline-flex" center flexWrap="wrap" py="xs">
                {!functionDef.displayTemplate && <Box>{id}</Box>}
                {renderDisplayTemplate({
                    parts: functionDef.displayTemplate,
                    args,
                    provideContextSchema,
                    updateAttributes,
                    renderGroupTitle,
                    returnType,
                    returnTypeOptions,
                })}
            </Box>
        </NodeViewWrapper>
    )
}

function renderDisplayTemplate({
    parts,
    ...props
}: {
    parts: FunctionDisplayTemplatePart[] | undefined
    args: any
    provideContextSchema: () => ContextSchema
    updateAttributes: (attrs: any) => void
    returnType?: FieldType
    returnTypeOptions?: FieldOptions
    renderGroupTitle: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
}) {
    const {
        args = {},
        provideContextSchema,
        updateAttributes,
        renderGroupTitle,
        returnType,
        returnTypeOptions,
    } = props

    function onStringValueChange(name: string, value: any) {
        queueMicrotask(() =>
            updateAttributes({ args: { ...args, [name]: { function: 'STRING', value } } })
        )
    }

    function onExpressionArgumentChange(name: string, value: any) {
        // only want the first item in the array, as arguments of functions can only
        // ever be single values
        queueMicrotask(() => updateAttributes({ args: { ...args, [name]: value } }))
    }

    return parts?.map((arg, index) => {
        if (typeof arg === 'string') {
            return (
                <Box key={index} mr="xs">
                    {arg}
                </Box>
            )
            // An array in the template groups some arguments together in a box to help keep them from wrapping
            // unnecessarily
        } else if (Array.isArray(arg)) {
            return (
                <Box key={index} flex center maxWidth="full" flexWrap="wrap">
                    {renderDisplayTemplate({ parts: arg, ...props })}
                </Box>
            )
            // If the isArray flag is set, it means this is a single argument that expects an array of values
            // we render that as a list of expression boxes which can be added to
        } else if (arg.isArray) {
            return (
                <RenderArrayArgument
                    key={arg.name}
                    list={args[arg.name] || []}
                    arg={arg}
                    onChange={(value) => {
                        onExpressionArgumentChange(arg.name, value)
                    }}
                    {...props}
                />
            )
        } else if (arg.allowFormula) {
            // expression input expects an array, not a single item
            const value = args[arg.name]
            return (
                <ExpressionInput
                    key={arg.name}
                    mr="xs"
                    value={value}
                    onChange={(value) => {
                        onExpressionArgumentChange(arg.name, value)
                    }}
                    contextSchema={provideContextSchema()}
                    borderWidth={0}
                    // args have the option of using the outer return type. for instance
                    // the ifTrue/ifFalse arguments of the if function should use the return type
                    // of the outer context.
                    returnType={arg.useOuterReturnType ? returnType : arg.type}
                    returnTypeOptions={arg.useOuterReturnType ? returnTypeOptions : arg.typeOptions}
                    placeholder={arg.placeholder || arg.name}
                    renderGroupTitle={renderGroupTitle}
                    style={getArgumentStyles(arg)}
                />
            )
        } else if (arg.type === 'dropdown') {
            return (
                <Combobox
                    key={arg.name}
                    mr="xs"
                    items={arg.typeOptions?.options ?? []}
                    labelField="label"
                    placeholder={arg.name}
                    showDropdownIndicator
                    selectedItem={arg.typeOptions?.options?.find(
                        (x) => x.id === args[arg.name]?.value
                    )}
                    onSelectedItemChange={(e) => {
                        onStringValueChange(arg.name, e.selectedItem?.id ?? null)
                    }}
                    style={getArgumentStyles(arg)}
                />
            )
        }
    })
}

function RenderArrayArgument({
    list = [],
    arg,
    onChange,
    provideContextSchema,
    renderGroupTitle,
}: {
    list?: any[]
    arg: FunctionArgument
    onChange: (value: any[]) => void
    provideContextSchema: () => ContextSchema
    renderGroupTitle: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
}) {
    const [itemIds, setItemIds] = useState(list.map(() => shortid.generate()))
    const [newItemId, setNewItemId] = useState(shortid.generate())
    const [focusedIndex, setFocusedIndex] = useState<number | undefined>()

    return (
        <>
            {list?.map((value: any, index: number) => (
                <ExpressionInput
                    key={itemIds[index]}
                    autoFocus={index === focusedIndex}
                    mr={1}
                    value={value}
                    onChange={(value) => {
                        if (!value?.input_content?.content?.length) {
                            onChange([...list.slice(0, index), ...list.slice(index + 1)])
                            setItemIds((ids) => ids.slice(0, index).concat(ids.slice(index + 1)))
                            setFocusedIndex(index)
                            return
                        }
                        onChange([...list.slice(0, index), value, ...list.slice(index + 1)])
                    }}
                    contextSchema={provideContextSchema()}
                    borderWidth={0}
                    returnType={arg.type}
                    returnTypeOptions={arg.typeOptions}
                    placeholder={arg.placeholder || arg.name}
                    renderGroupTitle={renderGroupTitle}
                    style={getArgumentStyles(arg)}
                    data-key={itemIds[index]}
                />
            ))}
            <ExpressionInput
                autoFocus={list.length === focusedIndex}
                key={newItemId}
                data-key={newItemId}
                mr={1}
                onChange={(value) => {
                    if (value?.input_content?.content?.length) {
                        onChange([...list, value])
                        setItemIds((ids) => [...ids, newItemId])
                        setNewItemId(shortid.generate())
                        setFocusedIndex(list.length + 1)
                    }
                }}
                contextSchema={provideContextSchema()}
                borderWidth={0}
                returnType={arg.type}
                returnTypeOptions={arg.typeOptions}
                placeholder={arg.placeholder || arg.name}
                renderGroupTitle={renderGroupTitle}
                style={getArgumentStyles(arg)}
            />
        </>
    )
}

const defaultWidths: { [key in FieldType]?: string } = {
    number: 'auto',
}
const defaultMinWidths: { [key in FieldType]?: string } = {
    number: '3rem',
}

function getArgumentStyles(arg: FunctionArgument) {
    const type = arg.type ?? ('' as FieldType)

    const width = arg.width || defaultWidths[type] || 'full'
    const minWidth = arg.minWidth || defaultMinWidths[type] || '5rem'
    return { minWidth, width }
}

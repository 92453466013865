import { useMemo, useRef } from 'react'

import { useAppContext } from 'app/useAppContext'
import { useStacks } from 'data/hooks/stacks'

import { createRecordLinkExtension } from './RecordLinkExtension'

export function useRecordLinkExtension(records?: RecordDto[]) {
    const { workspaceAccount } = useAppContext()
    const workspaceAccountRef = useRef(workspaceAccount)

    const { data: stacks } = useStacks()
    const stacksRef = useRef(stacks)

    const recordsRef = useRef(records)
    recordsRef.current = records
    return useMemo(() => {
        const options = {
            fetchStacksFn: () => stacksRef.current ?? [],
            fetchWorkspaceAccountFn: () => workspaceAccountRef.current,
            fetchRecordsFn: () => recordsRef.current ?? [],
        }

        return createRecordLinkExtension(options)
    }, [])
}

import { useEffect, useRef, useState } from 'react'

import moment from 'moment-timezone'

const useDisplayTimeFrom = (dateValue) => {
    const valueInput = useRef()
    const [displayValue, setDisplayValue] = useState()

    const updateDisplayValue = () => {
        setDisplayValue(moment.utc(valueInput.current).fromNow())
    }
    useEffect(() => {
        const timer = setInterval(updateDisplayValue, 60000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        valueInput.current = dateValue
        updateDisplayValue()
    }, [dateValue])

    return displayValue
}

export default useDisplayTimeFrom

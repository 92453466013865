import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/useDetailViewContext'

export function useDetailViewContentState() {
    const { title, recordManager } = useDetailViewContext()

    const isLoading = recordManager.isFetchingSlow

    return useMemo(
        () => ({
            title,
            isLoading,
        }),
        [isLoading, title]
    )
}

import React from 'react'

import { Editor } from '@tiptap/core'
import classNames from 'classnames'

import { Box } from 'ui/components/Box'

import { FadeInOnContainerHoverStyle, FloatingNodeControlsStyle } from './FloatingNodeControls.css'

type FloatingNodeControlsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    editor: Editor
    controls: React.ReactNode
    forceVisible?: boolean
}

export const FloatingNodeControls: React.FC<FloatingNodeControlsProps> = ({
    editor,
    children,
    controls,
    forceVisible,
    ...props
}) => {
    const isEditable = editor.isEditable

    return (
        <Box
            position="relative"
            {...props}
            className={classNames(FloatingNodeControlsStyle, props.className)}
        >
            {isEditable && (
                <Box
                    position="absolute"
                    top="xs"
                    right="xs"
                    className={forceVisible ? undefined : FadeInOnContainerHoverStyle}
                >
                    {controls}
                </Box>
            )}
            {children}
        </Box>
    )
}

import { FUNCTION_NAME_REGEX } from 'features/formulas/constants/formulaRegexConstants'

import {
    FormulaEditorFieldOrFunctionSelection,
    FormulaEditorFunctionSelection,
    FormulaEditorSelection,
} from './formulaSelectionTypes'

export function determineFunctionAndKeywordSelection({
    editor,
    shouldConsiderAfterCursor,
}: {
    editor: HTMLTextAreaElement
    shouldConsiderAfterCursor: boolean
}): FormulaEditorSelection {
    return determineSelectionFromTextAndPosition({
        text: editor.value,
        position: editor.selectionEnd,
        shouldConsiderAfterCursor,
    })
}

type StackEntry = {
    character: string
    position: number
}

const functionNameCharacterRegex = new RegExp('^' + FUNCTION_NAME_REGEX.source)

export function determineSelectionFromTextAndPosition({
    text,
    position,
    shouldConsiderAfterCursor,
}: {
    text: string
    position: number
    shouldConsiderAfterCursor: boolean
}): FormulaEditorSelection {
    const stack = buildBracketAndStringStackAtPosition(text, position)
    const lastStackEntry: StackEntry | undefined = stack[stack.length - 1]
    const openCurlyBracketEntry: StackEntry | undefined = stack.find(
        (entry) => entry.character === '{'
    )

    const closestOpeningParenthesisInStack = stack
        .reverse()
        .find((entry) => entry.character === '(')

    const closestWrappingFunction = closestOpeningParenthesisInStack
        ? buildFunctionSelectionAtPosition({
              text,
              position: closestOpeningParenthesisInStack.position,
              shouldConsiderAfterCursor: false,
          })
        : undefined

    if (isLastStackEntryQuotationMark(lastStackEntry, !!openCurlyBracketEntry)) {
        return {
            currentSelection: undefined,
            closestFunction: closestWrappingFunction,
        }
    }

    if (openCurlyBracketEntry) {
        return {
            currentSelection: buildFieldSelection({
                text,
                curlyBracketPosition: openCurlyBracketEntry.position,
                cursorPosition: position,
                shouldConsiderAfterCursor,
            }),
            closestFunction: closestWrappingFunction,
        }
    }

    /// workaround for "IF(|" - we want to treat IF as the selected function to autocomplete
    const currentSelection =
        closestOpeningParenthesisInStack?.position === position - 1
            ? closestWrappingFunction
            : buildFunctionSelectionAtPosition({
                  text,
                  position,
                  shouldConsiderAfterCursor,
              })
    return {
        currentSelection,
        closestFunction: currentSelection ?? closestWrappingFunction,
    }
}

function isLastStackEntryQuotationMark(lastEntry: StackEntry, inCurlyBrackets: boolean): boolean {
    return (!inCurlyBrackets && lastEntry?.character === '"') || lastEntry?.character === "'"
}

function buildFieldSelection({
    text,
    curlyBracketPosition,
    cursorPosition,
    shouldConsiderAfterCursor,
}: {
    text: string
    curlyBracketPosition: number
    cursorPosition: number
    shouldConsiderAfterCursor: boolean
}): FormulaEditorFieldOrFunctionSelection {
    let endPosition = cursorPosition
    if (shouldConsiderAfterCursor) {
        const firstClosingCurlyBracketIndex = text.slice(curlyBracketPosition + 1).indexOf('}')
        const firstOpeningCurlyBracketIndex = text.slice(curlyBracketPosition + 1).indexOf('{')
        const isThereAClosingCurlyBracket = firstClosingCurlyBracketIndex > -1
        const isClosingCurlyBracketBeforeOpeningCurlyBracket =
            firstOpeningCurlyBracketIndex === -1 ||
            firstClosingCurlyBracketIndex < firstOpeningCurlyBracketIndex
        if (isThereAClosingCurlyBracket && isClosingCurlyBracketBeforeOpeningCurlyBracket) {
            endPosition = curlyBracketPosition + firstClosingCurlyBracketIndex + 1
        }
    }
    return {
        name: text.slice(curlyBracketPosition + 1, endPosition),
        type: 'field',
    }
}

function buildBracketAndStringStackAtPosition(text: string, position: number): StackEntry[] {
    // Look at open brackets, curly brackets and strings to figure out
    // in which part we are
    const stack: StackEntry[] = []
    for (let i = 0; i < position && i < text.length; i++) {
        const isCurlyBracketsInStack = stack.find((entry) => entry.character === '{')
        const lastStackEntry = stack.length && stack[stack.length - 1].character

        const isLastStackEntryDoubleQuotes = lastStackEntry === '"'
        const isLastStackEntrySingleQuote = lastStackEntry === "'"

        const isLastStackEntryQuote = isLastStackEntryDoubleQuotes || isLastStackEntrySingleQuote

        const currentChar = text.charAt(i)
        if (isLastStackEntryQuote) {
            if (isLastStackEntryDoubleQuotes && currentChar === '"') {
                stack.pop()
            }
            if (isLastStackEntrySingleQuote && currentChar === "'") {
                stack.pop()
            }
        } else {
            if (
                currentChar == '{' ||
                (['(', '"', "'"].includes(currentChar) && !isCurlyBracketsInStack)
            ) {
                stack.push({
                    character: currentChar,
                    position: i,
                })
            } else if (currentChar === ')' || currentChar === '}') {
                // Note that this implementation allows stuff like ({(}, where the closing
                // curly bracket is considered to close the opening curly bracket.
                // Let's disregard that as that's never really going to lead to meaningful
                // formulas anyway.
                const openingBracket = currentChar === ')' ? '(' : '{'
                const allOpeningBracketEntries = Object.entries(stack).filter(
                    ([, value]) => value.character === openingBracket
                )
                const lastOpeningBracketEntry =
                    allOpeningBracketEntries[allOpeningBracketEntries.length - 1]
                if (lastOpeningBracketEntry) {
                    stack.splice(parseInt(lastOpeningBracketEntry[0], 10), 1)
                }
            }
        }
    }
    return stack
}

function findFunctionNameEndingAtPosition(text: string, position: number): string | undefined {
    const textUpToPosition = text.slice(0, position)
    const reversedText = textUpToPosition.split('').reverse().join('')
    const reversedRegexResult = functionNameCharacterRegex.exec(reversedText) ?? ['']
    const functionName = reversedRegexResult[0].split('').reverse().join('')
    return functionName ?? undefined
}

function buildFunctionSelectionAtPosition({
    text,
    position,
    shouldConsiderAfterCursor,
}: {
    text: string
    position: number
    shouldConsiderAfterCursor: boolean
}): FormulaEditorFunctionSelection | undefined {
    const partialFunctionName = findFunctionNameEndingAtPosition(text, position) ?? ''
    const functionNameSuffix = shouldConsiderAfterCursor
        ? (functionNameCharacterRegex.exec(text.slice(position)) ?? [''])[0]
        : ''
    const functionName = shouldConsiderAfterCursor
        ? partialFunctionName + functionNameSuffix
        : partialFunctionName
    if (functionName) {
        return {
            name: functionName,
            type: 'function',
        }
    }
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { LinkButton } from 'ui/components/LinkButton'
import { Progress } from 'ui/components/Progress'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { useNavigationTrialBannerState } from './hooks/useNavigationTrialBannerState'

type NavigationTrialBannerProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const NavigationTrialBanner: React.FC<NavigationTrialBannerProps> = (props) => {
    const { progressValue, daysLeftLabel, contactExpertUrl } = useNavigationTrialBannerState()

    return (
        <ThemeProvider theme="admin" flex column gap="2xl" {...props}>
            <Box flex column gap="l">
                <Box flex center justifyContent="space-between">
                    <Body size="m" weight="bold">
                        Free trial
                    </Body>
                    <Tag
                        size="2xs"
                        shade="light"
                        shape="pill"
                        type="solid"
                        color="Rose"
                        pointerEvents="none"
                    >
                        {daysLeftLabel}
                    </Tag>
                </Box>
                <Box>
                    <Progress value={progressValue} />
                </Box>
            </Box>
            <Box flex center justifyContent="center">
                <LinkButton
                    variant="primary"
                    startIcon={{ name: 'Sparkles' }}
                    size="s"
                    href={contactExpertUrl}
                    target="_blank"
                >
                    Talk to an expert
                </LinkButton>
            </Box>
        </ThemeProvider>
    )
}

// @ts-strict-ignore
import React, { useCallback, useMemo } from 'react'

import { useConfirmModal } from 'app/useConfirmModal'
import { deleteObject, invalidateObjects } from 'data/hooks/objects/objectOperations'
import { getDeleteTableModalData } from 'features/admin/settings/object/utils'
import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'

import { OrderableListSelector } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

export type TableOrderableListProps = {
    closeDropdown: () => void
    tables: ObjectDto[]
    selectedTableId: string | undefined
    onSelectTable: (table: ObjectDto) => void
}

export const TableOrderableList = ({
    closeDropdown,
    tables,
    selectedTableId,
    onSelectTable,
}: TableOrderableListProps): React.ReactElement => {
    const provideTableKey = useCallback((table: ObjectDto & { id: string }) => table._sid, [])
    const provideTableLabel = useCallback(
        (table: ObjectDto & { id: string; label: string }) => table.name,
        []
    )

    const selectTable = useCallback(
        (table: ObjectDto & { id: string; label: string }) => {
            if (!table.connection_options.data_mapping_disabled) {
                closeDropdown()
                onSelectTable(table)
            }
        },
        [closeDropdown, onSelectTable]
    )

    const { show: showConfirm, setData } = useConfirmModal()
    const allTableItems = useMemo(
        () =>
            tables.map((table) => {
                const isUsersTable = getIsStackerUserObject(table)
                const isSharedTable = !!table.connection_options?.shared_object_id
                const data = getDeleteTableModalData(table)

                const onConfirm = (modal) => {
                    setData({ ...data, isSaving: true })
                    deleteObject(table?._sid)
                        .then(() => {
                            invalidateObjects()
                        })
                        .then(() => {
                            setData({ ...data, isSaving: false })
                            modal.toggle()
                        })
                }

                const item = {
                    ...table,
                    id: table._sid,
                    isSelected: selectedTableId === table._sid,
                    labelColor:
                        selectedTableId === table._sid
                            ? colors.userInterface.accent[1000]
                            : undefined,
                    props: {
                        onClick: !table.connection_options.data_mapping_disabled
                            ? // @ts-ignore
                              () => selectTable(table)
                            : undefined,
                        hideBorder: true,
                        hoverBackgroundColor: colors.userInterface.neutral[150],
                        ignoreHoverStates: true,
                        style: {
                            borderRadius: '5px',
                            margin: 0,
                        },
                        // do not show the toggle for disabling/enabling tables
                        showToggle: false,
                    },
                }

                if (!isUsersTable && !table.is_shared_original) {
                    // show the delete icon unless this is the users table or the original copy of a shared table
                    item['actions'] = [
                        {
                            icon: isSharedTable ? 'fa-circle-xmark' : 'trash',
                            onClick: () => {
                                showConfirm({ ...data, onConfirm })
                            },
                            title: isSharedTable ? 'Remove shared table' : 'Delete table',
                            color: 'colors.neutral[600]',
                            style: {
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                marginLeft: '10px',
                                backgroundColor: 'transparent',
                                fontSize: '14px',
                            },
                        },
                    ]
                }

                return item
            }),
        [tables, selectedTableId, setData, selectTable, showConfirm]
    )

    const selectedTablesItems = useMemo(
        () => allTableItems.filter((table) => !table.connection_options.data_mapping_disabled),
        [allTableItems]
    )

    return (
        <OrderableListSelector
            maxHeight="calc(60vh - 100px)"
            items={allTableItems}
            selectedItems={selectedTablesItems}
            searchInputProps={{
                placeholder: 'Search tables',
            }}
            emptySearch={{
                label: (filterValue: string) =>
                    `No tables match the search '${filterValue}'. Please try again.`,
            }}
            provideKey={provideTableKey}
            provideLabel={provideTableLabel}
            onUpdate={() => undefined}
            onKeyboardItemSelected={selectTable}
            autofocus
            disableReorder
        />
    )
}

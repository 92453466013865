// @ts-strict-ignore
import React, { useRef } from 'react'
// Using react-portal instead of the Chakra Portal because
// our version of Chakra doesn't support specifying the container node
import { Portal } from 'react-portal'

import { Menu, MenuButton, MenuButtonProps, MenuList } from '@chakra-ui/react'
import { Placement } from '@popperjs/core'

import Button from './Button'
import Divider from './Divider'
import MenuItem from './MenuItem'
import Text from './Text'

export type ActionListItem = {
    label?: string | React.ReactElement
    icon?: any
    iconColor?: string
    action?: React.KeyboardEventHandler<HTMLElement>
    disabled?: boolean
    divider?: boolean
    className?: string
    header?: boolean
}

type ActionListProps = MenuButtonProps & {
    actions: ActionListItem[]
    icon?: string | null
    accessibilityLabel?: string
    labelPlacement?: any
    dataTestId?: string
    actionProps?: any
    variant?: string
    buttonSize?: string
    ButtonComponent?: React.ComponentType
    menuOpenStateChanged?: (isOpen: boolean) => void
    menuPlacement?: Placement
}
const ActionList = ({
    actions,
    icon = 'dotsV',
    accessibilityLabel,
    labelPlacement,
    dataTestId,
    actionProps,
    ButtonComponent = Button,
    menuOpenStateChanged,
    menuPlacement = 'bottom-end',
    ...props
}: ActionListProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null)
    const openRef = useRef<boolean>()
    return (
        <Menu placement={menuPlacement}>
            {({ isOpen, onClose }) => {
                if (openRef.current !== isOpen && menuOpenStateChanged) {
                    menuOpenStateChanged(isOpen || false)
                }
                openRef.current = isOpen
                return (
                    <>
                        <MenuButton
                            // @ts-expect-error
                            as={ButtonComponent}
                            // TODO: fix iconProps type
                            // @ts-expect-error
                            iconProps={{ pr: 0 }}
                            size="sm"
                            icon={icon}
                            variant="clear"
                            label={!isOpen && accessibilityLabel}
                            labelPlacement={labelPlacement}
                            data-testid={dataTestId}
                            ref={buttonRef}
                            {...props}
                        />
                        {/** We need to specify the portal container as a node
                         * that appears at the top of the document vs the default
                         * portal insertion point at the end of the document to avoid
                         * some visual jitter that seems to be caused by some of our
                         * elements being sized 100dvh.
                         */}
                        <Portal node={document && document.getElementById('chakraMenuPortal')}>
                            <MenuList minW={2} p={0} zIndex={2000} maxH="50vh" overflowY="auto">
                                {actions.map(
                                    (
                                        {
                                            label,
                                            action,
                                            disabled,
                                            divider,
                                            header,
                                            className,
                                            ...other
                                        },
                                        index
                                    ) => {
                                        // Chakra annoyingly selects the button after clicking on an item
                                        // this will unfocus the blue and then run the action
                                        const onItemClick = async (e) => {
                                            if (onClose) onClose()
                                            await new Promise((r) => setTimeout(r, 100))
                                            if (buttonRef.current) buttonRef.current.blur()
                                            if (action) action(e)
                                        }
                                        if (divider) {
                                            return <Divider key={index} />
                                        }
                                        if (header) {
                                            return (
                                                <Text
                                                    key={index}
                                                    size="xs"
                                                    color="userInterface.neutral.800"
                                                    px={3}
                                                    mt={2}
                                                    mb={1}
                                                    fontWeight="600"
                                                >
                                                    {label}
                                                </Text>
                                            )
                                        }
                                        return (
                                            <MenuItem
                                                key={index}
                                                isDisabled={disabled}
                                                onClick={onItemClick}
                                                fontSize="sm"
                                                fontWeight="500"
                                                className={className}
                                                {...actionProps}
                                                {...other}
                                            >
                                                {label}
                                            </MenuItem>
                                        )
                                    }
                                )}
                            </MenuList>
                        </Portal>
                    </>
                )
            }}
        </Menu>
    )
}

export default React.memo(ActionList)

import React, { forwardRef } from 'react'

import * as Parts from './Popup.parts'

type PopupInnerRef = HTMLDivElement

type PopupInnerProps = React.ComponentPropsWithoutRef<typeof Parts.Content> & {}

export const PopupInner = forwardRef<PopupInnerRef, PopupInnerProps>((props, ref) => {
    return <Parts.Content ref={ref} {...props} />
})

import { useCallback } from 'react'

import { useAppUserContext } from 'app/useAppUserContext'
import { useUserRecord } from 'data/hooks/users/main'
import { processFilterMemoized } from 'features/records/components/logic/recordLogic'

// This hook returns a processFilter() function which will be updated
// any time the current user record or role changes. This ensures that
// the filter will be re-evaluated when these current user pieces change.
export function useProcessFilter() {
    const { data: userRecord } = useUserRecord()
    const { role } = useAppUserContext()
    return useCallback(
        (data: RecordDto[], filters: Filter[], lookupRecord?: RecordDto) =>
            processFilterMemoized(data, filters, lookupRecord),
        // ignore this lint rule because we want to generate a new callback when the
        // current user record or role changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userRecord, role]
    )
}

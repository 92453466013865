function isQuickLinksBlockItemLinkEmpty(link?: QuickLinksBlockItemLink): boolean {
    if (!link) return true

    switch (link.type) {
        case 'external':
            return !link.label && !link.url
        case 'internal':
            return !link.viewId && !link.label
        case 'field':
            return !link.label && !link.fieldId
        default:
            return false
    }
}

export function isQuickLinksBlockItemEmpty(item: QuickLinksBlockItem): boolean {
    const { title, subtitle, link } = item
    return !title && !subtitle && isQuickLinksBlockItemLinkEmpty(link)
}

// we need to check in current user can see the internal link QL block item.
// If it's a list view, it's enough to have a corresponding view available from BE
// If it's a create view, we also need to check if corresponding object is not read only and have permissions to create
export function isQuickLinksBlockItemAllowed(
    item: QuickLinksBlockItem,
    views: ViewDto[],
    objects: ObjectDto[]
): boolean {
    switch (item.link?.type) {
        case 'external':
        case 'field':
            return true

        case 'internal': {
            if (!item.link.viewId) return true

            const linkView = views.find(
                (view) => view._sid === (item.link as QuickLinksBlockItemInternalLink).viewId
            )
            if (linkView?.type === 'create') {
                const linkObject = objects?.find((object) => object._sid === linkView?.object_id)
                return (
                    linkObject?._permissions?.may_create &&
                    !linkObject?.connection_options?.read_only
                )
            } else {
                return !!linkView
            }
        }

        default:
            return true
    }
}

export function getVisibleQuickLinksBlocks(
    items: QuickLinksBlockItem[],
    views: ViewDto[],
    objects: ObjectDto[]
): QuickLinksBlockItem[] {
    return sortQuickLinksBlocks(
        items.filter(
            (i) => !isQuickLinksBlockItemEmpty(i) && isQuickLinksBlockItemAllowed(i, views, objects)
        )
    )
}

export function sortQuickLinksBlocks(items: QuickLinksBlockItem[]): QuickLinksBlockItem[] {
    return items.sort((a, b) => a.order - b.order)
}

export function filterQuickLinksBlockEditorFields(field: FieldDto): boolean {
    if (!field || field.connection_options.is_disabled) return false

    return field.type === 'url' || field.type === 'string'
}

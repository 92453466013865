import React, { useMemo } from 'react'

import { matchesSearchTerms } from 'features/Search/helpers'

import { CommandsProvider } from './CommandsProvider'
import { InternalLinkCommands } from './internalLinkCommands'
import { InternalLinkCommand, InternalLinkCommandProviderProps } from './internalLinkCommandTypes'
import { RenderCommand } from './RenderCommand'

// Adds the "mention a user" and "link a record" commands. Might add more in the future.
export const useInternalLinkCommandProvider = ({
    allowMentions,
    props,
}: InternalLinkCommandProviderProps): CommandsProvider<InternalLinkCommand> => {
    return useMemo(() => {
        const availableCommands = InternalLinkCommands.filter(
            (u) => allowMentions || u.id !== 'mention_user'
        )

        return {
            id: 'internal_links',
            title: 'Link and tag things',
            initialItems: availableCommands,
            getItems({ queryTerms }) {
                return Promise.resolve({
                    items: availableCommands.filter((u) => matchesSearchTerms(u.label, queryTerms)),
                })
            },
            renderItem({ item, queryTerms }) {
                return <RenderCommand item={item} queryTerms={queryTerms} />
            },
            onSelect: ({
                editor,
                range,
                item,
                setDisplayedComponent,
                setDisplayedComponentProps,
            }) => {
                editor.chain().focus().insertContentAt(range, '/').run()
                setDisplayedComponent(item.component)
                setDisplayedComponentProps(props)
            },
        } as CommandsProvider<InternalLinkCommand>
    }, [allowMentions, props])
}

import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/useBoardViewContext'

export function useBoardViewDataState() {
    const {
        isFetchingSlow,
        inlineFilterType,
        availableInlineFilterFields,
        header,
        records,
        hasError,
        isEmbedded,
        retryFetchRecords,
    } = useBoardViewContext()

    const hasRecords = !!records?.length

    const isError = !hasRecords && hasError

    const showTopPadding =
        (header.type === 'portal' && header.style === 'full') ||
        (header.type === 'default' &&
            (inlineFilterType !== 'quick' ||
                (inlineFilterType === 'quick' && availableInlineFilterFields.length < 1)))

    return useMemo(
        () => ({
            showTopPadding,
            isFetchingSlow,
            isError,
            isEmbedded,
            retryFetchRecords,
        }),
        [showTopPadding, isFetchingSlow, isError, isEmbedded, retryFetchRecords]
    )
}

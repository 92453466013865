import { useCallback } from 'react'

import { CellEditRequestEvent } from 'ag-grid-community'

import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'
import { RowData } from 'features/datagrid/types'
import {
    createRecordFromCellEvent,
    isNewRecordPlaceholder,
    rawImportRecordWithCellEvent,
    updateRecordWithCellEvent,
} from 'features/datagrid/utils'

type UseHandleCellEditRequestProps = {
    addRecord: (record?: Partial<RecordDto>) => Promise<RecordDto>
    editRecord: (record: RecordDto, patch: Partial<RecordDto>) => Promise<void>
    importRawRecords?: (
        updates: { _sid: string | null; data: Record<string, string> }[]
    ) => Promise<void>
}

export function useHandleCellEditRequest({
    addRecord,
    editRecord,
    importRawRecords,
}: UseHandleCellEditRequestProps) {
    const { canAddRecords, canEditRecords } = useDataGridContext()

    return useCallback(
        async (params: CellEditRequestEvent<RowData>) => {
            const isNewRecord = isNewRecordPlaceholder(params.data)
            if (isNewRecord) {
                if (!canAddRecords) return Promise.resolve()

                return createRecordFromCellEvent(params, addRecord)
            }

            if (!canEditRecords) return Promise.resolve()

            if (params.source === 'rangeService' && importRawRecords) {
                // When using range fill, use the backend to update the records.
                return rawImportRecordWithCellEvent(params, importRawRecords)
            }
            return updateRecordWithCellEvent(params, editRecord)
        },
        [addRecord, canAddRecords, canEditRecords, editRecord, importRawRecords]
    )
}

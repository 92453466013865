import { useLayoutEffect, useRef } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'

type UseBoardViewCardWrapperStateOptions = {
    measureFn: (el: HTMLElement | null) => void
}

export function useBoardViewCardWrapperState(options: UseBoardViewCardWrapperStateOptions) {
    const { measureFn } = options

    const elementRef = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        const el = elementRef.current
        if (!el) return

        const mutationObserver = new MutationObserver(() => {
            measureFn(el)
        })
        mutationObserver.observe(el, { childList: true, subtree: true })

        return () => mutationObserver?.disconnect()
    }, [measureFn])

    const ref = useComposedRefs<HTMLDivElement>(elementRef, measureFn)

    return { ref }
}

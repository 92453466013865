// Using an interface instead of a type alias so we could extend it from other files.

import React, { useContext } from 'react'

import * as Y from 'yjs'

import { LayoutEditorSchema, Widget } from './types'

export type LayoutEditorCommands = {
    initEditor: (options: { viewSid: string }) => void
    closeEditor: () => void
    saveViewChanges: () => Promise<void>
    discardViewChanges: () => void
    updateViewName: (newName: string) => void
    insertWidgetAtPath: (widgetType: string, path: string[]) => void
    selectWidgetAtPath: (widgetId: string, path: string[]) => void
    deselectWidget: () => void
    removeSelectedWidget: () => void
    duplicateSelectedWidget: () => void
    undo: () => void
    redo: () => void
    transaction: (
        tr: (data: Y.Map<any>) => void,
        options?: {
            skipHistory?: boolean
        }
    ) => void
}

export type LayoutEditorContextValue = {
    isInitialized: boolean
    isEditing: boolean
    view?: ViewDto
    commands: LayoutEditorCommands
    isViewDirty: boolean
    schema: LayoutEditorSchema
    isSchemaOutdated: boolean
    selectedWidget?: Widget
    onChangeSelectedWidgetAttrs: (tr: (widget: Y.Map<any>) => void) => void
}
export const LayoutEditorContext = React.createContext<LayoutEditorContextValue | null>(null)

export function useLayoutEditorContext(): LayoutEditorContextValue {
    const context = useContext(LayoutEditorContext)

    if (!context) {
        throw new Error('useLayoutEditorContext must be used within a LayoutEditorContextProvider')
    }

    return context
}

import React from 'react'

import { EmbeddedNoRecordsEmptyState } from 'features/views/ListView/EmptyState/EmbeddedNoRecordsEmptyState'
import { EmbeddedNoRecordsFiltersEmptyState } from 'features/views/ListView/EmptyState/EmbeddedNoRecordsFiltersEmptyState'
import { EmbeddedServerErrorEmptyState } from 'features/views/ListView/EmptyState/EmbeddedServerErrorEmptyState'
import { NoRecordsEmptyState } from 'features/views/ListView/EmptyState/NoRecordsEmptyState'
import { NoRecordsFiltersEmptyState } from 'features/views/ListView/EmptyState/NoRecordsFiltersEmptyState'
import { ServerErrorEmptyState } from 'features/views/ListView/EmptyState/ServerErrorEmptyState'

import { Box } from 'ui/components/Box'

import { useCardViewDataState } from './hooks/useCardViewDataState'
import { CardViewPagination } from './Pagination/CardViewPagination'
import { CardViewContent } from './CardViewContent'
import { CardViewGrid } from './CardViewGrid'
import { CardViewLoader } from './CardViewLoader'

type CardViewDataProps = {}

export const CardViewData: React.FC<CardViewDataProps> = React.memo(function CardViewData() {
    const {
        isEmpty,
        isEmptyWithFilters,
        clearFilters,
        showTable,
        isError,
        retryFetchRecords,
        isFetchingSlow,
        isEmbedded,
        showTopPadding,
    } = useCardViewDataState()

    return (
        <Box
            px={{ tablet: '4xl' }}
            pt={{ tablet: showTopPadding ? 'xl' : undefined }}
            pb={{ tablet: '3xl' }}
            width="full"
            overflow="hidden"
            flex
            center
            flexDirection="column"
            background="surface"
        >
            {isEmpty && isEmbedded && <EmbeddedNoRecordsEmptyState />}
            {isEmpty && !isEmbedded && <NoRecordsEmptyState />}
            {isEmptyWithFilters && isEmbedded && <EmbeddedNoRecordsFiltersEmptyState />}
            {isEmptyWithFilters && !isEmbedded && (
                <NoRecordsFiltersEmptyState onClearFilters={clearFilters} />
            )}
            {isError && isEmbedded && <EmbeddedServerErrorEmptyState />}
            {isError && !isEmbedded && <ServerErrorEmptyState onRetry={retryFetchRecords} />}
            {showTable && (
                <>
                    <CardViewGrid>
                        {isFetchingSlow ? <CardViewLoader /> : <CardViewContent />}
                    </CardViewGrid>
                    {!isFetchingSlow && <CardViewPagination />}
                </>
            )}
        </Box>
    )
})

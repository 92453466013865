import { useMemo } from 'react'
import { UseQueryOptions } from 'react-query'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { useAppContext } from 'app/useAppContext'
import {
    ACCOUNT_QUERY_RETRY_OPTIONS,
    queryClient,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useUpdateItem,
} from 'data/hooks/_helpers'

const ENDPOINT = 'zones/'
const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useZones'
const GET_ACCOUNT_AND_ZONE_KEY = 'getAccountAndZone'

export const useZones = (options: UseQueryOptions<AccountZone[]> = {}) => {
    const { user, isEmailVerified } = useAuthContext()

    return useQuery<AccountZone[]>(
        LIST_NAME,
        ENDPOINT,
        { ...options, ...ACCOUNT_QUERY_RETRY_OPTIONS, enabled: !!user && isEmailVerified },
        {
            bypassMatchingStackCheck: true,
            bypassPreviewAs: true,
        }
    )
}

export const useWorkspaceZones = () => {
    const { workspaceAccount } = useAppContext()
    const queryResult = useZones()
    const zones = queryResult.data

    return useMemo(() => {
        const data = zones?.filter((zone) => zone.account_sid === workspaceAccount?._sid)

        return { ...queryResult, data }
    }, [queryResult, zones, workspaceAccount?._sid])
}

export const useCreateZone = () => {
    return useCreateItem(LIST_NAME, ENDPOINT)
}
export const useUpdateZone = () => {
    return useUpdateItem(LIST_NAME, ENDPOINT)
}
export const useDeleteZone = () => {
    return useDeleteItem(LIST_NAME, ENDPOINT)
}

export const invalidateZones = () => {
    return queryClient.invalidateQueries(LIST_NAME)
}

export const useGetAccountAndZone = (slug?: string) => {
    const query = useQuery<{
        zone: AccountZone
        account: Account
    }>(
        GET_ACCOUNT_AND_ZONE_KEY + (slug || ''),
        slug ? `get_account_and_zone?slug=${slug}` : `get_account_and_zone/`,
        { ...ACCOUNT_QUERY_RETRY_OPTIONS, keepPreviousData: false },
        {
            bypassMatchingStackCheck: true,
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
            bypassQueryDeps: true,
        }
    )

    return { ...query, zone: query?.data?.zone, account: query?.data?.account }
}

export const replaceCachedZone = (zone: AccountZone) => {
    queryClient.setQueryData(LIST_NAME, (zones: AccountZone[] | undefined = []) => {
        return zones.map((acc) => {
            if (acc._sid === zone._sid) {
                return zone
            }
            return acc
        })
    })
}

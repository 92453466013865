import React from 'react'
import { Redirect } from 'react-router-dom'

import { sortBy } from 'lodash'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getUrl, Urls } from 'app/UrlService'
import { useAccounts } from 'data/hooks/accounts'

import { LoadingSplash } from 'v2/ui'

export function HomePage() {
    const { data: accounts, isLoading } = useAccounts()
    const { tenantId } = useAuthContext()
    const queryString = window.location.search

    // The default account to redirect to will be:
    // - If the session is for a specific account (as in the case of SS0), select that account
    // - Otherwise, select the first account that does not require SSO
    const defaultAccount =
        accounts?.find((account) => account._sid === tenantId) ||
        sortBy(accounts, 'sso_required')[0]

    if (defaultAccount) {
        return <Redirect to={`${defaultAccount.slug}${queryString}`} />
    }

    if (isLoading) {
        return <LoadingSplash />
    }

    if (!sessionStorage.getItem('onboardingCompanyName')) {
        return <Redirect to={getUrl(Urls.OnboardingWhatAreYouBuilding)} />
    }

    return <Redirect to={getUrl(Urls.OnboardingStart)} />
}

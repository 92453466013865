import React, { useCallback, useMemo, useState } from 'react'

import useDebounce from 'v2/ui/utils/useDebounce'

import { AppUsersContext, AppUsersPane } from './AppUsersContext'
import { AppGroup } from './types'

export type AppUsersContextProviderProps = {}
export const AppUsersContextProvider: React.FC<AppUsersContextProviderProps> = ({ children }) => {
    const [currentPane, setCurrentPane] = useState<AppUsersPane>('default')
    const [selectedTableGroup, setSelectedTableGroup] = useState<AppGroup | undefined>(undefined)

    const resetPane = useCallback(() => {
        setCurrentPane('default')
        setSelectedTableGroup(undefined)
    }, [])

    const [searchQuery, setSearchQuery] = useState('')

    const debouncedSetSearchQuery = useDebounce(setSearchQuery, SEARCH_DEBOUNCE_RATE)

    const value = useMemo(
        () => ({
            pane: currentPane,
            setPane: setCurrentPane,
            resetPane,
            searchQuery,
            setSearchQuery: debouncedSetSearchQuery,
            selectedTableGroup,
            setSelectedTableGroup,
        }),
        [currentPane, debouncedSetSearchQuery, resetPane, searchQuery, selectedTableGroup]
    )

    return <AppUsersContext.Provider value={value}>{children}</AppUsersContext.Provider>
}
const SEARCH_DEBOUNCE_RATE = 300 // ms

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

export function AiGenerationFailed({ onContinue }: { onContinue: () => void }) {
    return (
        <Box grow flex column center justifyContent="center" gap="3xl">
            <Icon name="AlertTriangle" size="4xl" opacity={0.5} />
            <Body size="l" maxWidth="400px">
                We couldn&apos;t automatically create an app from those requirements, but click{' '}
                <strong>Continue</strong> to get started with a sample app instead.
            </Body>
            <Button onClick={onContinue}>Continue</Button>
        </Box>
    )
}

import React from 'react'

import { PreviewingBar } from './PreviewingBar'
import { useIsBannerShowing } from './useIsBannerShowing'

/**
 * Consolidates all app banners.
 */
export default function AppBannerService(props) {
    const [, setIsBannerShowing] = useIsBannerShowing()

    return (
        <>
            <PreviewingBar {...props} setIsBannerShowing={setIsBannerShowing} />
        </>
    )
}

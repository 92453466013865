import styled from '@emotion/styled'

import { Container } from 'v2/ui'

export const Wrapper = styled(Container)<{
    height: number
    numberOfMetrics: number
    isEditing: boolean
    isActive: boolean
    isOnListView?: boolean
}>`
    border-radius: 0.75rem;
    container-type: inline-size;

    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: ${({ numberOfMetrics }) => 100 / numberOfMetrics}%;
    height: ${({ height }) => height}px;
    margin: 0;

    @media (max-width: ${({ isEditing }) => (isEditing ? 1350 : 1100)}px) {
        width: 100%;
        max-width: ${({ numberOfMetrics }) => 100 / numberOfMetrics}%;
        min-width: min(${({ isEditing }) => (isEditing ? 300 : 400)}px, calc(50% - 0.376rem));
    }

    @media (max-width: ${({ isEditing }) => (isEditing ? 1100 : 800)}px) {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    transition:
        box-shadow 0.25s ease-out,
        border-color 0.25s ease-out;

    border-style: solid;
    border-width: ${({ isOnListView }) => (isOnListView ? 1 : 4)}px;
    border-color: ${({ isActive, numberOfMetrics, isOnListView, theme }) => {
        if (isActive && numberOfMetrics > 1) {
            return theme.colors.userInterface.accent[200]
        }

        return isOnListView
            ? theme.colors.userInterface.neutral[500]
            : theme.colors.userInterface.neutral[0]
    }};

    box-shadow: ${({ isActive, numberOfMetrics, isOnListView }) => {
        if (isActive && numberOfMetrics > 1) {
            return '0px 34px 54px -26px rgba(89, 94, 106, 0.15), 0px 9px 24px -8px rgba(89, 94, 106, 0.18)'
        }

        return isOnListView
            ? 'none'
            : '0px 2px 4px rgba(89, 94, 106, 0.1), 0px 0px 1px rgba(89, 94, 106, 0.05)'
    }};
`

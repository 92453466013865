import { formatNumberValue } from './utils/utils'

export const renderNumberValueAsText = ({
    value,
    display,
    config,
    metrics,
}: {
    value: number
    display: ChartParamsDisplay
    config: ChartParamsConfig
    metrics: MetricsQueryResponse
}) => {
    let { value: formattedValue, abbreviation } = formatNumberValue({
        value,
        display,
        config,
        metrics,
    })

    const fieldType = metrics.field_display_meta?.field_type

    if (fieldType === 'currency') {
        // currency_symbol should always be present on field_display_meta,
        // for currency type fields, but just default to no symbol in case not
        const currencySymbol = metrics.field_display_meta?.currency_symbol ?? ''

        formattedValue = currencySymbol + formattedValue
    }

    if (abbreviation) {
        formattedValue += abbreviation
    }

    return formattedValue
}

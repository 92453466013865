import React from 'react'
import { Link } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { useStacks } from 'data/hooks/stacks'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

import { useActivityFeedContext } from './useActivityFeedContext'

type DocumentLinkProps = {
    documentId: number
    location?: string
    stackId?: string
}

export function DocumentLink({ documentId, stackId, location }: DocumentLinkProps) {
    const { data: stacks } = useStacks()
    const { documents } = useActivityFeedContext()

    const document = documents?.find((d) => d.auto_id === documentId)
    const stack = stacks?.find((s) => s._sid === stackId)
    if (!document || !stack) return null

    let url = getUrl(`/docs/${document.auto_id}`, stack)
    if (location) {
        url += `#${encodeURIComponent(location)}`
    }

    return (
        <Link to={url}>
            <Icon name="StickyNote" mr="xs" />
            <Box as="span">{document.title}</Box>
        </Link>
    )
}

import React from 'react'
import { useHistory } from 'react-router-dom'

import { useTheme } from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import classNames from 'classnames'
import get from 'lodash/get'

import { EditableBlockWrapper } from 'features/admin/edit-mode/Common/EditableBlockWrapper'
import EditableFieldWrapper from 'features/admin/edit-mode/ListView/EditableListFieldWrapper'
import { useEditModeListContext } from 'features/admin/edit-mode/ListView/useEditModeListContext'
import { RecordStatsIcons } from 'features/records/components/RecordStatsIcons'
import isRichTextField from 'utils/isRichTextField'

import STYLE_CLASSES, { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import useHover from 'v2/ui/utils/useHover'

import { HoverContainerStyle } from 'ui/styles/hoverUtils.css'

import { Icon } from '..'

import BackgroundImage from './BackgroundImage'
import Box from './Box'
import ConditionalWrapper from './ConditionalWrapper'
import Container from './Container'
import Flex from './Flex'
import Text from './Text'

const gridCss = `
display: grid;
grid-template-columns: 1fr;
`

const rowStyle = ({ theme, link, borderStyle }) => {
    if (!link) return null

    if (borderStyle === 'solid') {
        return css`
            &:hover {
                background-color: ${theme.colors.rows.backgroundHover};
            }
        `
    }

    return css`
        &:hover {
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        }
    `
}

const ResponsiveTable = styled('table')`
    ${gridCss}
    width: 100%;

    @media all and (min-width: ${(props) => props.tabletBreak}) {
        display: table;
        border-collapse: collapse;
    }
`

const THead = styled('thead')`
    display: none;
    border: 1px solid ${(props) => props.borderColor};
    th {
        text-align: left;
    }

    @media all and (min-width: ${(props) => props.tabletBreak}) {
        display: table-header-group;
        border-width: 0 0 1px 0;

        tr {
            display: table-row;
        }

        th {
            padding: ${(props) => props.headerPadding};
        }

        th:first-child {
            padding-left: ${(props) => props.tablePadding};
        }

        th:last-child {
            padding-right: ${(props) => props.tablePaddingRight};
        }
    }
`

const TBody = styled('tbody')`
    text-align: left;
    border: 1px solid ${(props) => props.borderColor};
    border-width: 1px 0 0;

    tr {
        ${gridCss}
        align-items: center;
        border-bottom: 1px solid ${(props) => props.borderColor};
        padding: ${(props) => props.tablePadding};
        outline: 0;
    }

    td:first-child {
        padding-left: ${(props) => props.tablePadding};
    }

    td:last-child {
        padding-right: ${(props) => props.tablePaddingRight};
    }

    @media all and (min-width: ${(props) => props.tabletBreak}) {
        display: table-body-group;
        border-width: 0;

        tr {
            display: table-row;
            border-bottom: 1px solid ${(props) => props.borderColor};
        }

        td {
            padding: ${(props) => props.rowLgPadding};
        }

        td:first-child {
            padding-left: ${(props) => props.tablePadding};
        }

        td:last-child {
            padding-right: ${(props) => props.tablePaddingRight};
        }
    }
`

const StyledTr = styled('tr')`
    ${(props) => props.link && 'cursor: pointer;'}
    ${(props) => props.maxRowHeight && `max-height: ${(props) => props.maxRowHeight};`}

    ${rowStyle};
`

const Table = ({
    title,
    header = [],
    data = [],
    caption,
    emptyContent,
    pagination,
    titleIsEditable,
    buttons,
    topFilters,
    rowLink,
    search,
    onTitleChange,
    maxRowHeight,
    additionalListContainerContent,
    viewOptions,
    setConfig,
    object,
    onOrderByChange,
    orderBy,
    isEditMode,
    tablePadding: defaultTablePadding,
    tablePaddingRight: defaultTablePaddingRight,
    headerPadding: defaultHeaderPadding,
    rowLgPadding: defaultRowLgPadding,
    borderColor: defaultBorderColor,
    headerTextColor,
    rowBorderStyle = 'shadow',
    useClassNames = true,
    openRecord,
    ...props
}) => {
    const getClassName = (className) => {
        if (!useClassNames) return undefined

        return className
    }

    const theme = useTheme()
    const history = useHistory()
    const [isHovered, hoverHandlers] = useHover({ delay: 50 })
    const { activeEditorFieldId } = useEditModeListContext()
    const { optimizedLayout } = viewOptions ?? {
        optimizedLayout: false,
    }
    if (!data.length) {
        return (
            <Container
                id="table-container"
                title={title}
                buttons={buttons}
                topFilters={topFilters}
                search={search}
                onChange={onTitleChange}
                className={getClassName(ONBOARDING_CLASSES.TABLE_CONTAINER)}
                {...props}
            >
                {emptyContent}
            </Container>
        )
    }

    const tabletBreak = get(theme, 'widths.md')
    const tablePadding = defaultTablePadding ?? get(theme, 'space.table.outer')
    const tablePaddingRight = defaultTablePaddingRight ?? get(theme, 'space.table.outerRight')
    const headerPadding = defaultHeaderPadding ?? get(theme, 'space.table.header')
    const rowLgPadding = defaultRowLgPadding ?? get(theme, 'space.table.rowLg')
    const borderColor = defaultBorderColor ?? get(theme, 'colors.table.borderColor')

    const minWidthMap = (field = {}) => {
        const { type } = field
        switch (type) {
            case 'checkbox':
                return '20px'
            case 'string':
                return isRichTextField(field) ? '300px' : '100px'
            case 'document':
                return '300px'
            default:
                return 'unset'
        }
    }

    const handleOnHeaderClick = (id) => {
        if (orderBy?.id === id && orderBy?.desc) {
            //reset sorting if from desc
            onOrderByChange(undefined)
        } else {
            onOrderByChange({
                id: id,
                desc: orderBy?.id === id ? !orderBy?.desc : false,
            })
        }
    }

    const getSortIcon = (id) => {
        if (orderBy?.id === id) {
            return orderBy.desc ? 'sortDown' : 'sortUp'
        } else {
            return 'sort'
        }
    }
    // If the mouse is over the header bar, and we're not in edit layout mode,
    // then we want all the editable field wrappers to be highlighted, if the tactile
    // editing conrols are set to be available out of edit mode.
    const showAllEditableFieldWrappers = isHovered && !isEditMode

    return (
        <Container
            id="table-container"
            titleIsEditable={titleIsEditable}
            title={title}
            buttons={buttons}
            search={search}
            onChange={onTitleChange}
            className={getClassName(ONBOARDING_CLASSES.TABLE_CONTAINER)}
            position="relative"
            data-testid="table-view.table"
            {...props}
        >
            {additionalListContainerContent}

            <EditableBlockWrapper highlight={!!activeEditorFieldId}>
                <Flex align="stretch" wrap="nowrap">
                    <Box
                        overflowX="auto"
                        pb={1}
                        flexGrow={1}
                        className={optimizedLayout ? 'visible-scrollbar' : ''}
                    >
                        <ResponsiveTable
                            className={getClassName(STYLE_CLASSES.TABLE)}
                            tabletBreak={tabletBreak}
                        >
                            {caption && <caption>{caption}</caption>}

                            <THead
                                className={getClassName(STYLE_CLASSES.TABLE_HEADER)}
                                tabletBreak={tabletBreak}
                                borderColor={borderColor}
                                headerPadding={headerPadding}
                                tablePadding={tablePadding}
                                tablePaddingRight={tablePaddingRight}
                            >
                                <tr
                                    className={getClassName(STYLE_CLASSES.TABLE_ROW)}
                                    {...hoverHandlers}
                                >
                                    {header.map(
                                        ({ label, props = {}, id, renderOptions, canSort }) => (
                                            <th
                                                key={id}
                                                className={getClassName(
                                                    `${STYLE_CLASSES.TABLE_HEADER_CELL} ${STYLE_CLASSES.TABLE_HEADER_CELL}-${id}`
                                                )}
                                                {...props}
                                            >
                                                <EditableFieldWrapper
                                                    position="horizontal"
                                                    fieldId={renderOptions?.fieldId}
                                                    objectId={object?._sid}
                                                    viewOptions={viewOptions}
                                                    setConfig={setConfig}
                                                    showControls={
                                                        showAllEditableFieldWrappers
                                                            ? 'always'
                                                            : undefined
                                                    }
                                                >
                                                    <Flex
                                                        direction="row"
                                                        wrap="noWrap"
                                                        style={{
                                                            cursor: canSort ? 'pointer' : 'default',
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            if (canSort) {
                                                                handleOnHeaderClick(id)
                                                            }
                                                        }}
                                                    >
                                                        {!renderOptions?.hideLabel && (
                                                            <Text
                                                                variant="tableLabel"
                                                                color={
                                                                    headerTextColor ?? 'table.label'
                                                                }
                                                            >
                                                                {label}
                                                            </Text>
                                                        )}
                                                        {canSort && (
                                                            <Icon
                                                                size="10px"
                                                                color={
                                                                    headerTextColor ?? 'grey.300'
                                                                }
                                                                icon={getSortIcon(id)}
                                                                ml="5px"
                                                            />
                                                        )}
                                                    </Flex>
                                                </EditableFieldWrapper>
                                            </th>
                                        )
                                    )}
                                    {data?.find((row) => row.actionButtons) && <th />}
                                </tr>
                            </THead>

                            <TBody
                                className={getClassName(STYLE_CLASSES.TABLE_BODY)}
                                tabletBreak={tabletBreak}
                                borderColor={borderColor}
                                headerPadding={headerPadding}
                                tablePadding={tablePadding}
                                tablePaddingRight={tablePaddingRight}
                                rowLgPadding={rowLgPadding}
                            >
                                {data.map(
                                    (
                                        {
                                            cells = [],
                                            props = {},
                                            coverImage = {},
                                            row,
                                            actionButtons,
                                        },
                                        rowIndex
                                    ) => {
                                        const link = rowLink ? rowLink(row) : ''
                                        let rowProps = {}
                                        if (link) {
                                            rowProps = {
                                                tabIndex: 0,
                                                role: 'button',
                                            }
                                        }

                                        return (
                                            <StyledTr
                                                key={rowIndex}
                                                link={link}
                                                maxRowHeight={maxRowHeight}
                                                className={classNames([
                                                    getClassName(
                                                        `${
                                                            rowIndex === 0
                                                                ? ONBOARDING_CLASSES.TABLE_ITEM
                                                                : ''
                                                        } ${STYLE_CLASSES.TABLE_ROW}`
                                                    ),
                                                    HoverContainerStyle,
                                                ])}
                                                onClick={(e) => {
                                                    if (!link) return

                                                    if (e.ctrlKey || e.metaKey) {
                                                        window.open(link.pathname, '_blank')
                                                    } else if (openRecord) {
                                                        openRecord(row.original._sid)
                                                    } else {
                                                        history.push(link)
                                                    }

                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }}
                                                borderStyle={rowBorderStyle}
                                                data-recordid={row.original._sid}
                                                {...props}
                                                {...rowProps}
                                            >
                                                {cells.map(({ value, props, column }, colIndex) => {
                                                    const optimizedMinWidth = minWidthMap(
                                                        column.field
                                                    )
                                                    return (
                                                        <td
                                                            key={colIndex}
                                                            className={getClassName(
                                                                `${STYLE_CLASSES.TABLE_CELL} ${
                                                                    STYLE_CLASSES.TABLE_CELL
                                                                }-${get(header, `${colIndex}.id`)}`
                                                            )}
                                                            style={{
                                                                // When `optimizedLayout`, we apply a minWidth based on fields type
                                                                // Otherwise, no min-width
                                                                minWidth: optimizedLayout
                                                                    ? optimizedMinWidth
                                                                    : 'unset',
                                                            }}
                                                            {...props}
                                                        >
                                                            <ConditionalWrapper
                                                                condition={
                                                                    colIndex === 0 && coverImage
                                                                }
                                                                wrapper={(children) => (
                                                                    <Flex wrap="nowrap">
                                                                        {children}
                                                                    </Flex>
                                                                )}
                                                            >
                                                                {colIndex === 0 && coverImage && (
                                                                    <BackgroundImage
                                                                        objectFit={
                                                                            coverImage.fitImage
                                                                        }
                                                                        src={get(coverImage, 'url')}
                                                                        size={[
                                                                            'table.coverImage',
                                                                            null,
                                                                            null,
                                                                            'table.coverImageLg',
                                                                        ]}
                                                                        borderRadius="full"
                                                                        flexShrink={0}
                                                                        mr="table.rowLg"
                                                                    />
                                                                )}
                                                                <div
                                                                    className="table-field"
                                                                    style={{
                                                                        fontWeight:
                                                                            colIndex === 0
                                                                                ? 'bold'
                                                                                : null,
                                                                        flexGrow:
                                                                            colIndex === 0 &&
                                                                            coverImage
                                                                                ? 1
                                                                                : null,
                                                                    }}
                                                                >
                                                                    {colIndex === 0 ? (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent:
                                                                                    'space-between',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            {value}
                                                                            <RecordStatsIcons
                                                                                record={
                                                                                    row.original
                                                                                }
                                                                                object={object}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </div>
                                                            </ConditionalWrapper>
                                                        </td>
                                                    )
                                                })}
                                                {actionButtons && (
                                                    <td>
                                                        <Flex wrap="nowrap">{actionButtons}</Flex>
                                                    </td>
                                                )}
                                            </StyledTr>
                                        )
                                    }
                                )}
                            </TBody>
                        </ResponsiveTable>
                    </Box>
                </Flex>
                {pagination}
            </EditableBlockWrapper>
        </Container>
    )
}

export default React.memo(Table)

import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

type UseOverflowXOptions = {
    leftClassName?: string
    rightClassName?: string
}

export function useOverflowX(options: UseOverflowXOptions) {
    const { leftClassName, rightClassName } = options

    const parentRef = useRef<HTMLDivElement | null>(null)
    const [scrollAreaRef, setScrollAreaRef] = useState<HTMLDivElement | null>(null)

    const prevOverflow = useRef<{ left: boolean; right: boolean } | undefined>()
    const checkOverflow = useCallback(() => {
        const el = scrollAreaRef
        if (!el) return

        const isLeftOverflowing = Math.ceil(el.scrollLeft) > 0
        const isRightOverflowing =
            Math.ceil(el.scrollLeft + el.clientWidth) < Math.ceil(el.scrollWidth)

        if (
            isLeftOverflowing !== prevOverflow.current?.left ||
            isRightOverflowing !== prevOverflow.current?.right
        ) {
            const parent = parentRef.current
            if (!parent) return

            // Add or remove the overflowing classes based on the current state.
            if (leftClassName) {
                parent.classList.toggle(leftClassName, isLeftOverflowing)
            }
            if (rightClassName) {
                parent.classList.toggle(rightClassName, isRightOverflowing)
            }
        }

        prevOverflow.current = {
            left: isLeftOverflowing,
            right: isRightOverflowing,
        }
    }, [leftClassName, rightClassName, scrollAreaRef])

    useLayoutEffect(() => {
        const el = scrollAreaRef
        if (!el) return

        checkOverflow()

        el.addEventListener('scroll', checkOverflow)

        return () => el.removeEventListener('scroll', checkOverflow)
    }, [checkOverflow, scrollAreaRef])

    // Make sure to check overflow when the element's contents change.
    const mutationObseverRef = useRef<MutationObserver | null>(null)
    useLayoutEffect(() => {
        const el = scrollAreaRef
        if (!el) return

        mutationObseverRef.current = new MutationObserver(() => {
            checkOverflow()
        })
        mutationObseverRef.current.observe(el, { childList: true, subtree: true })

        return () => mutationObseverRef.current?.disconnect()
    }, [checkOverflow, scrollAreaRef])

    // Make sure to check overflow when the element's size changes.
    const resizeObseverRef = useRef<ResizeObserver | null>(null)
    useLayoutEffect(() => {
        const el = scrollAreaRef
        if (!el) return

        resizeObseverRef.current = new ResizeObserver(() => {
            checkOverflow()
        })
        resizeObseverRef.current.observe(el)

        return () => resizeObseverRef.current?.disconnect()
    }, [checkOverflow, scrollAreaRef])

    return useMemo(
        () => ({
            targetRef: parentRef,
            scrollAreaRef: setScrollAreaRef,
        }),
        []
    )
}

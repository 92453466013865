import React, { ReactNode, useCallback, useRef, useState } from 'react'

import shortid from 'shortid'

import { Body } from 'ui/components/Text'

import { generateBasicSchema } from './aiPrompts/generateBasicShema'
import { generateBasicRecords, generateRecords } from './aiPrompts/generateRecords'
import { generateTableNames } from './aiPrompts/generateTableNames'
import { Bundle } from './bundles/bundleBuilder'
import { AiAppBuilderContext } from './AiAppBuilderContext'
import { TableNameDisplay } from './TableNameDisplay'

export function AiAppBuilderProvider({ children }: { children: ReactNode }) {
    const [clearbitContext] = useState<string>('')
    const [bundle, setBundle] = useState<Bundle | null>(null)
    const [generationFailed, setGenerationFailed] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [requirements, setRequirements] = useState<string[]>([])
    const [progress, setProgress] = useState<{ progress: number; status: React.ReactNode }>({
        progress: 0,
        status: '',
    })
    const [isGenerating, setIsGenerating] = useState<boolean>(false)
    const [currentStep, setCurrentStep] = useState<'describe' | 'requirements'>('describe')

    const currentPreviewRequestId = useRef<string>('')
    const requirementsRef = useRef<string[]>(requirements)
    requirementsRef.current = requirements

    // const bundle = useRef<Bundle | null>(null)

    const doGenerateRecords = useCallback(
        async function (bundle: Bundle) {
            const requestId = currentPreviewRequestId.current

            setProgress({
                progress: 50,
                status: <span>preparing sample records</span>,
            })

            let newBundle = await generateBasicRecords(bundle, currentPreviewRequestId.current)
            // if a new request has started, then just bail
            if (requestId !== currentPreviewRequestId.current) {
                return
            }

            setBundle(newBundle)
            let objectIndex = 0
            for (const obj of newBundle.apps[0].objects) {
                setProgress({
                    progress: 60 + (30 * (objectIndex + 1)) / bundle.apps[0].objects.length,
                    status: (
                        <>
                            <Body mr="m" size="l">
                                preparing sample records for
                            </Body>
                            <TableNameDisplay>
                                {bundle.apps[0].objects[objectIndex].name}
                            </TableNameDisplay>
                        </>
                    ),
                })
                newBundle = await generateRecords(
                    newBundle,
                    obj.id,
                    currentPreviewRequestId.current
                )

                setBundle(newBundle)
                objectIndex++
            }
            setIsGenerating(false)
        },
        [setBundle, setIsGenerating, setProgress]
    )

    const generateSchema = useCallback(
        async function generateSchema(bundle: Bundle) {
            const requestId = currentPreviewRequestId.current

            setProgress({
                progress: 10,
                status: (
                    <>
                        <Body mr="m" size="l">
                            preparing table for
                        </Body>
                        <TableNameDisplay>{bundle.apps[0].objects[0].name}</TableNameDisplay>
                    </>
                ),
            })

            function updateProgressForObject(objectIndex: number) {
                if (objectIndex >= bundle.apps[0].objects.length) {
                    return
                }

                // if a new request has started, then just bail
                if (requestId !== currentPreviewRequestId.current) {
                    return
                }

                setProgress({
                    progress: 10 + (40 * (objectIndex + 1)) / bundle.apps[0].objects.length,
                    status: (
                        <>
                            <Body mr="m" size="l">
                                preparing table for
                            </Body>
                            <TableNameDisplay>
                                {bundle.apps[0].objects[objectIndex].name}
                            </TableNameDisplay>
                        </>
                    ),
                })
                timeout = setTimeout(() => updateProgressForObject(objectIndex + 1), 2000)
            }

            let timeout = setTimeout(() => updateProgressForObject(0), 2000)

            const newBundle = await generateBasicSchema(bundle, currentPreviewRequestId.current)

            // if a new request has started, then just bail
            if (requestId !== currentPreviewRequestId.current) {
                return
            }

            clearTimeout(timeout)
            setBundle(newBundle)

            await doGenerateRecords(newBundle)

            setBundle(newBundle)
        },
        [doGenerateRecords, setBundle, setProgress]
    )

    const generateBundle = useCallback(
        async function () {
            setIsGenerating(true)
            setGenerationFailed(false)

            const requestId = shortid.generate()
            currentPreviewRequestId.current = requestId
            try {
                setProgress({ progress: 0, status: <span>analyzing requirements</span> })
                const newBundle = await generateTableNames(requirementsRef.current)

                // if a new request has started, then just bail
                if (requestId !== currentPreviewRequestId.current) {
                    return
                }

                setBundle(newBundle)
                await generateSchema(newBundle)
            } catch (e) {
                if (requestId === currentPreviewRequestId.current) {
                    console.error(e)
                    setGenerationFailed(true)
                }
            }
        },
        [generateSchema]
    )

    const cancelGeneration = useCallback(() => {
        currentPreviewRequestId.current = ''
        setIsGenerating(false)
    }, [setIsGenerating])

    const value = {
        clearbitContext,
        bundle,
        generationFailed,
        description,
        requirements,
        progress,
        isGenerating,
        currentStep,
        setBundle,
        setGenerationFailed,
        setDescription,
        setRequirements,
        setProgress,
        setIsGenerating,
        setCurrentStep,
        generateBundle,
        cancelGeneration,
    }

    return <AiAppBuilderContext.Provider value={value}>{children}</AiAppBuilderContext.Provider>
}

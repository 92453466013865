import React, { useCallback, useState } from 'react'

import { useAppContext } from 'app/useAppContext'
import { UpdateActivityArgs } from 'data/hooks/activities/activities'
import { isEmptyDeep } from 'utils/utils'

import { LoadingScreen } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { StandardComponentProps } from 'ui/helpers/styles'

import { ActivityFeedContextProvider } from './ActivityFeedContextProvider'
import { ActivityFeedFilters } from './ActivityFeedFilters'
import { CreateComment } from './CreateComment'
import { FeedControls } from './FeedControls'
import { FeedItem } from './FeedItem'
import { FeedItemRelationshipInfo, FeedParameters } from './types'
import { useActivityFeed } from './useActivityFeed'
import { checkActivityIsReadOnly, makeDraftId, useValidActivities } from './utils'

type ActivityFeedProps = StandardComponentProps & {
    contextKey: string
    params?: FeedParameters
    target?: FeedItemRelationshipInfo
    feedWrapperProps?: Partial<React.ComponentPropsWithoutRef<typeof LoadingScreen>>
    showFilters?: boolean
    showPlaceholder?: boolean
    showControls?: boolean
    showLocationPreview?: boolean
    commentEditorProps?: Partial<React.ComponentPropsWithoutRef<typeof CreateComment>>
    onBeforeCreate?: (activity: Partial<ActivityDto>) => void
    onDelete?: (activityId: number) => void
    showAppIcons?: boolean
    showCommentEditor?: boolean
    onActivityCreationDeletion?: () => void
}
export const ActivityFeed = React.memo(function ActivityFeed({
    contextKey,
    params,
    target,
    feedWrapperProps,
    showFilters = true,
    showPlaceholder = true,
    showControls = true,
    showLocationPreview = false,
    commentEditorProps,
    onBeforeCreate,
    onDelete,
    showAppIcons = true,
    showCommentEditor = true,
    onActivityCreationDeletion,
    ...props
}: ActivityFeedProps) {
    const { selectedStack } = useAppContext()
    const { relatedTo } = target ?? {}
    const [filters, setFilters] = useState<FeedParameters>(
        JSON.parse(
            localStorage.getItem(`${contextKey}_ActivitiesFilter`) || JSON.stringify(params || {})
        )
    )

    const handleFiltersChange = (filters: FeedParameters) => {
        setFilters(filters)
        localStorage.setItem(`${contextKey}_ActivitiesFilter`, JSON.stringify(filters))
    }

    const targetStackId = target?.stackId
    const feedParams = {
        relatedTo: target?.relatedTo,
        relatedToLocation: target?.relatedToLocation,
        stackIds: targetStackId ? [targetStackId] : undefined,
        onActivityCreationDeletion,
        ...params,
        ...filters,
    }

    const {
        activities,
        relatedActivities,
        relatedData,
        isLoading,
        createActivity,
        retryFailedActivity,
        deleteActivity,
        updateActivity,
    } = useActivityFeed(feedParams)

    const handleCreateActivity = useCallback(
        (activity: Partial<ActivityDto>) => {
            if (checkActivityIsReadOnly()) return Promise.reject()

            activity.related_to = activity.related_to || target?.relatedTo
            activity.related_to_type = activity.related_to_type || target?.relatedToType
            activity.related_to_location = activity.related_to_location || target?.relatedToLocation
            activity.object_id = activity.object_id || target?.objectId
            activity.stack_id = activity.stack_id || target?.stackId || selectedStack?._sid

            onBeforeCreate?.(activity)

            return createActivity(activity)
        },
        [
            createActivity,
            selectedStack?._sid,
            target?.objectId,
            target?.relatedTo,
            target?.relatedToType,
            target?.relatedToLocation,
            target?.stackId,
            onBeforeCreate,
        ]
    )

    const handleDeleteActivity = useCallback(
        async (id: number) => {
            if (checkActivityIsReadOnly()) return Promise.reject()

            await deleteActivity(id)

            onDelete?.(id)
        },
        [deleteActivity, onDelete]
    )

    const handleUpdateActivity = useCallback(
        (args: UpdateActivityArgs) => {
            if (checkActivityIsReadOnly()) return Promise.reject()
            return updateActivity(args)
        },
        [updateActivity]
    )

    const showAppIcon = showAppIcons && !relatedTo

    const draftId = makeDraftId(target || {})

    const validActivities = useValidActivities(activities, relatedData)
    const validRelatedActivities = useValidActivities(relatedActivities, relatedData)

    return (
        <Box flex stretch column width="full" {...props}>
            <ActivityFeedContextProvider
                value={{
                    activities: [...(validActivities ?? []), ...(validRelatedActivities ?? [])],
                    createActivity: handleCreateActivity,
                    deleteActivity: handleDeleteActivity,
                    updateActivity: handleUpdateActivity,
                    retryFailedActivity,
                    target,
                    ...relatedData,
                }}
            >
                <Box
                    flex
                    stretch
                    pl="m"
                    width={showFilters ? undefined : 'full'}
                    justifyContent={showFilters ? undefined : 'flex-end'}
                >
                    {showFilters && (
                        <>
                            <Box
                                mr="l"
                                flex
                                center
                                color="text"
                                opacity={0.8}
                                noShrink
                                fontSize="bodyS"
                            >
                                <Icon name="Filter" mr="m" opacity={0.8} /> Filter{' '}
                            </Box>
                            <ActivityFeedFilters
                                contextKey={contextKey}
                                onFiltersChange={handleFiltersChange}
                                defaultFilters={filters}
                                target={target}
                                grow
                            />
                        </>
                    )}
                    {target?.relatedTo && showControls && (
                        <FeedControls
                            ml="l"
                            noShrink
                            relatedTo={target?.relatedTo}
                            relatedToType={target?.relatedToType}
                            relatedToLocation={target?.relatedToLocation}
                            objectId={target?.objectId}
                            stackId={target?.stackId}
                            alignSelf="center"
                        />
                    )}
                </Box>
                {!relatedTo && <Divider mb="l" mt="xs" />}
                {showCommentEditor && relatedTo && (
                    <Container variant="base" border mt="l" mb="m" pl="l">
                        <CreateComment
                            {...commentEditorProps}
                            inputContainerProps={{ p: 'm' }}
                            stackId={target?.stackId}
                            autoSaveDraft={true}
                            draftId={draftId}
                        />
                    </Container>
                )}
                <LoadingScreen
                    isLoading={isLoading}
                    minHeight="50px"
                    {...feedWrapperProps}
                    loadingBackgroundProps={{
                        bg: 'transparent',
                        ...feedWrapperProps?.loadingBackgroundProps,
                    }}
                >
                    {validActivities?.map((a) => (
                        <FeedItem
                            key={a.auto_id}
                            activity={a}
                            showAppIcon={showAppIcon}
                            showLocationPreview={showLocationPreview}
                        />
                    ))}
                    {validActivities?.length === 0 && showPlaceholder && (
                        <Box textAlign="center" fontSize="bodyL" mt="m">
                            No activities {!isEmptyDeep(filters) ? 'match these filters' : 'yet'}.
                        </Box>
                    )}
                </LoadingScreen>
            </ActivityFeedContextProvider>
        </Box>
    )
})

import React from 'react'

import { Box } from 'ui/components/Box'
import { Body, Headline } from 'ui/components/Text'

type BaseEmptyStateProps = React.ComponentPropsWithoutRef<typeof Box> & {
    image?: React.ReactNode
    title?: string
    description?: string
    footer?: React.ReactNode
}

export const BaseEmptyState: React.FC<BaseEmptyStateProps> = ({
    image,
    title,
    description,
    footer,
    ...rest
}) => {
    return (
        <Box flex center flexDirection="column" gap="3xl" py="4xl" {...rest}>
            {image}
            {(title || description) && (
                <Box
                    flex
                    center
                    flexDirection="column"
                    textAlign="center"
                    justifyContent="center"
                    gap="xl"
                    px="xl"
                >
                    {title && (
                        <Box>
                            <Headline size="l" color="text">
                                {title}
                            </Headline>
                        </Box>
                    )}
                    {description && (
                        <Box maxWidth="400px">
                            <Body size="m" color="textWeaker">
                                {description}
                            </Body>
                        </Box>
                    )}
                </Box>
            )}
            {footer && (
                <Box flex center justifyContent="center">
                    {footer}
                </Box>
            )}
        </Box>
    )
}

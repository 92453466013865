import React from 'react'

import { useAppUsersContentState } from './hooks/useAppUsersContentState'
import { AppUsersCreateTableGroupPane } from './AppUsersCreateTableGroupPane'
import { AppUsersDefaultPane } from './AppUsersDefaultPane'
import { AppUsersUpdateTableGroupPane } from './AppUsersUpdateTableGroupPane'

type AppUsersContentProps = {}

export const AppUsersContent: React.FC<AppUsersContentProps> = () => {
    const { pane } = useAppUsersContentState()

    switch (pane) {
        case 'default':
            return <AppUsersDefaultPane />
        case 'createTableGroup':
            return <AppUsersCreateTableGroupPane />
        case 'updateTableGroup':
            return <AppUsersUpdateTableGroupPane />
        default:
            return null
    }
}

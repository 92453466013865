import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { useAppContext } from 'app/useAppContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { BuiltInAccountRoleNames } from 'data/hooks/accountRoleTypes'
import { SharingSettingsPatch, useUpdateStackSharingSettings } from 'data/hooks/stacks'
import { refetchAppUsersForAdmin, useAddWorkspaceUser } from 'data/hooks/users/main'

import { useToast } from 'ui/components/Toast'

export type InviteUserModalHandle = {
    open: (prefillEmail?: string) => void
    close: () => void
}

type UseAppUsersInviteUserModalStateOptions = {
    ref: React.ForwardedRef<InviteUserModalHandle>
}

export function useAppUsersInviteUserModalState(options: UseAppUsersInviteUserModalStateOptions) {
    const { ref } = options

    const [isOpen, setIsOpen] = useState(false)

    const [email, setEmail] = useState('')
    const emailRef = useRef(email)
    emailRef.current = email
    const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }, [])

    const [selectedRole, setSelectedRole] = useState('')
    const selectedRoleRef = useRef(selectedRole)
    selectedRoleRef.current = selectedRole

    const onOpenChange = useCallback((open: boolean) => {
        if (!open) {
            setEmail('')
            setSelectedRole('')
        }

        setIsOpen(open)
    }, [])

    const { mutateAsync: addUser } = useAddWorkspaceUser()
    const { selectedStack } = useAppContext()
    const stackRef = useRef(selectedStack)
    stackRef.current = selectedStack
    const { workspaceZone } = useWorkspaceContext()
    const workspaceZoneRef = useRef(workspaceZone)
    workspaceZoneRef.current = workspaceZone

    const updateStackSharingSettings = useUpdateStackSharingSettings()
    const toast = useToast()

    const onConfirm = useCallback(async () => {
        const stack = stackRef.current
        if (!stack) return Promise.resolve()

        const skipEmail = stack.options?.disable_invite_emails ?? false

        const email = emailRef.current
        const role = selectedRoleRef.current

        if (!email || !role) {
            return Promise.resolve()
        }

        const workspaceZone = workspaceZoneRef.current
        const isPortal = workspaceZone?.type === 'Portal'
        const invitingAsAdmin = role === 'internal_admin'

        let workspaceRole = BuiltInAccountRoleNames.PORTAL_USER
        if (invitingAsAdmin || !isPortal) {
            workspaceRole = BuiltInAccountRoleNames.MEMBER
        }

        try {
            // Create the workspace user.
            const user = await addUser({
                email,
                options: {
                    role: workspaceRole,
                },
                send_email: !skipEmail,
            })

            const patch: SharingSettingsPatch = {
                [user._sid]: {
                    role,
                },
            }

            // Add the user to the stack sharing settings.
            await updateStackSharingSettings(stack, patch)
            await refetchAppUsersForAdmin()

            toast({
                type: 'success',
                title: 'Invitation sent',
                startIcon: { name: 'CheckCircle2' },
            })

            onOpenChange(false)
        } catch {
            toast({
                type: 'error',
                title: 'Failed to send invitation',
                startIcon: { name: 'AlertCircle' },
                helperText: 'Please try again later. If the issue persists, contact support.',
            })
        }
    }, [addUser, onOpenChange, toast, updateStackSharingSettings])

    useImperativeHandle(
        ref,
        () =>
            ({
                open: (prefillEmail?: string) => {
                    if (prefillEmail) {
                        setEmail(prefillEmail)
                    }
                    setIsOpen(true)
                },
                close: () => {
                    setIsOpen(false)
                },
            }) as InviteUserModalHandle,
        []
    )

    const emailInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (isOpen) {
            requestAnimationFrame(() => {
                emailInputRef.current?.focus()
            })
        }
    }, [isOpen])

    const isSubmitDisabled = !email || !selectedRole

    return {
        onConfirm,
        email,
        onEmailChange,
        isOpen,
        onOpenChange,
        selectedRole,
        setSelectedRole,
        emailInputRef,
        isSubmitDisabled,
    }
}

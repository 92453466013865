import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useVirtual, VirtualItem } from 'react-virtual'

import { BoxProps } from '@chakra-ui/react'

import { getScrollableParent } from 'v2/ui/utils/utils'

import Box from './Box'

type Props<T> = BoxProps & {
    estimatedItemSize: number
    items?: T[]
    renderItem: (props: { item: T }) => React.ReactNode
    scrollContainer?: HTMLElement
}

export const OldVirtualizedList = <ItemType,>(props: Props<ItemType>): React.ReactElement => {
    const { estimatedItemSize, items, renderItem, scrollContainer, ...boxProps } = props
    const parentRef = useRef(scrollContainer)
    const listRef = useRef<HTMLElement>()

    // Tells the virtualized list how much to offset based on the scroll position
    // of the scrollable parent. This has some enhanced logic in here to allow
    // virtualizing a list that is not positioned at the top of the scroll container.
    const getScrollOffset = () => {
        if (!parentRef.current || !listRef.current) return 0
        // How much has the scrolling parent scrolled?
        const scrollTop = parentRef.current.scrollTop
        // How far is the list container offset from the top of the scroll parent
        // when not scrolled at all?
        const parentRect = parentRef.current.getBoundingClientRect()
        const listRect = listRef.current.getBoundingClientRect()
        const listOffset = listRect.top - parentRect.top + scrollTop
        // If we haven't scrolled enough so the top of the list is off screen,
        // then return 0, otherwise return the amount of scroll minus the offset to the
        // top of the list.
        return scrollTop < listOffset ? 0 : scrollTop - listOffset
    }
    const estimateSize = useCallback(() => estimatedItemSize, [estimatedItemSize])

    const virtualizer = useVirtual({
        size: items?.length ?? 0,
        estimateSize,
        parentRef: parentRef,
        scrollOffsetFn: getScrollOffset,
        overscan: 2,
    })

    // When the container component registers, if we weren't supplied
    // specific scroll parent element, find the first scrollable ancestor
    // and use that by default.
    const setListContainerNode = (element: HTMLElement | null) => {
        if (element && !parentRef.current) {
            parentRef.current = scrollContainer || getScrollableParent(element)
            listRef.current = element
        }
    }
    return (
        <Box {...boxProps}>
            <div
                ref={setListContainerNode}
                style={{
                    height: `${virtualizer.totalSize}px`,
                    width: '100%',
                    position: 'relative',
                }}
            >
                {items &&
                    virtualizer.virtualItems.map((virtualRow) => (
                        <VirtualRow
                            key={virtualRow.index}
                            row={virtualRow}
                            renderItem={renderItem}
                            items={items}
                            defaultHeight={estimatedItemSize}
                        />
                    ))}
            </div>
        </Box>
    )
}

const VirtualRow = <ItemType,>({
    defaultHeight,
    items,
    renderItem,
    row,
}: {
    defaultHeight: number
    items: ItemType[]
    renderItem: Props<ItemType>['renderItem']
    row: VirtualItem
}): React.ReactElement => {
    const [show, setShow] = useState(false)
    const timeout = useRef<NodeJS.Timeout | undefined>()

    // to help eliminate janky scrolling, we use a setTimeout
    // to render the child so it doesn't block the UI thread while scrolling.
    // Only once scrolling stops do we actually render.
    useEffect(() => {
        timeout.current = setTimeout(() => setShow(true), 0)
        return () => (timeout.current ? clearTimeout(timeout.current) : undefined)
    }, [])

    return (
        <ItemMeasurer
            measure={row.measureRef}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                // use the default height until actually rendered,
                // then we let the ItemMeasurer calculate the actual rendered height.
                height: !show ? defaultHeight : undefined,
                transform: `translateY(${row.start}px)`,
            }}
        >
            {show && renderItem({ item: items[row.index] })}
        </ItemMeasurer>
    )
}

type ItemMeasurerProps = React.HTMLAttributes<any> & {
    as?: React.ElementType
    measure: (elm: HTMLElement | null) => void
}
const ItemMeasurer = ({
    children,
    measure,
    as = 'div',
    ...restProps
}: PropsWithChildren<ItemMeasurerProps>) => {
    const roRef = React.useRef<ResizeObserver | undefined>()
    const elRef = React.useRef<HTMLElement | null>(null)

    const measureFn = React.useRef(measure)
    measureFn.current = measure

    // When the wrapping element gets rendred, we register to observe its size.
    const refSetter = React.useCallback((el: HTMLElement) => {
        const ro = roRef.current

        if (ro && elRef.current) {
            ro.unobserve(elRef.current)
        }

        elRef.current = el

        if (ro && elRef.current) {
            ro.observe(elRef.current)
        }
    }, [])

    React.useLayoutEffect(() => {
        // when our size changes, we call the measure function
        // so react-virtual recalcs the height of our row.
        const update = () => {
            measureFn.current(elRef.current)
        }

        update()
        // Set up a resize observer that updates calls our
        const ro = roRef.current ? roRef.current : new ResizeObserver(update)
        const el = elRef.current
        if (el) {
            ro.observe(el)
        }
        roRef.current = ro

        return () => {
            ro.disconnect()
        }
    }, [])

    const Tag = as

    return (
        <Tag ref={refSetter} {...restProps}>
            {children}
        </Tag>
    )
}

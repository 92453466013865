import React, { forwardRef, useEffect, useRef } from 'react'

import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'
import { useColumnWidth } from 'features/datagrid/hooks/useColumnWidth'
import { useEditorValue } from 'features/datagrid/hooks/useEditorValue'
import { useTypeInCellToEdit } from 'features/datagrid/hooks/useTypeInCellToEdit'

import MultiRecordLinkAttribute from 'v2/ui/components/Attribute/MultiRecordLinkAttribute'
import { RecordLinkDisplay } from 'v2/ui/components/Attribute/RecordLinkDisplay'
import SingleRecordLinkAttribute from 'v2/ui/components/Attribute/SingleRecordLinkAttribute'
import TagList from 'v2/ui/components/Attribute/TagList'
import { layouts, modes } from 'v2/ui/utils/attributeSettings'

import { Box } from 'ui/components/Box'

import { PillValue } from './PillValue'
import { DataGridCellEditorComponent, DataGridCellRendererComponent } from './types'

import { RecordLinkCellStyle } from './RecordLinkCell.css'

type RecordLinkCellRendererProps = {}
export const RecordLinkCellRenderer: DataGridCellRendererComponent<RecordLinkCellRendererProps> = ({
    value,
    context,
}) => {
    const objectId = context.object._sid

    const { cellDataProvider } = useDataGridContext()

    if (!value) return null

    const values = Array.isArray(value) ? value : [value]

    return (
        <Box height="full" width="full">
            <TagList
                items={values.map((v) => (
                    <PillValue key={v}>
                        <RecordLinkDisplay
                            record={cellDataProvider.getRecord(objectId, v)}
                            recordId={v}
                            noLink={true}
                            layout={layouts.inline}
                        />
                    </PillValue>
                ))}
                singleLine={true}
                useOverflowList={false}
                shrinkTags={false}
                itemStyle={{ padding: 0, background: 'none', display: 'inline' }}
            />
        </Box>
    )
}
type RecordLinkCellEditorProps = {}
export const RecordLinkCellEditor: DataGridCellEditorComponent<RecordLinkCellEditorProps> =
    forwardRef(({ node, value, field, stopEditing, column }, ref) => {
        const Component =
            field.type === 'multi_lookup' ? MultiRecordLinkAttribute : SingleRecordLinkAttribute

        const [newValue, setNewValue] = useEditorValue<string | string[]>(ref, value)

        useEffect(() => {
            const handler = (e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    queueMicrotask(() => {
                        stopEditing()
                    })
                }
            }
            document.addEventListener('keydown', handler)
            return () => document.removeEventListener('keydown', handler)
        }, [stopEditing])

        const inputElementRef = useRef<HTMLInputElement | null>(null)

        useTypeInCellToEdit({
            getInputElement: () => inputElementRef.current,
        })

        const columnWidth = useColumnWidth(column)

        return (
            <Box
                ref={(el: HTMLDivElement | null) => {
                    inputElementRef.current = el?.querySelector('input[type="text"]') ?? null
                }}
                lineHeight="bodyS"
                height="full"
                width="full"
                flex
                center
            >
                {/* @ts-expect-error */}
                <Component
                    field={field}
                    contextRecord={node.data}
                    mode={modes.editing}
                    usePortal
                    onChange={setNewValue}
                    inDataGrid
                    controlStyle={{
                        border: '1px solid white',
                        '&:hover': {
                            border: '1px solid white',
                        },
                        height: '100%',
                    }}
                    onRecordsSelected={() => {}}
                    className={RecordLinkCellStyle}
                    hasFocus={true}
                    valueContainerStyle={{
                        height: '100%',
                        overflowY: 'auto',
                    }}
                    style={{
                        height: '100%',
                        minWidth: `${columnWidth}px`,
                    }}
                >
                    {newValue}
                </Component>
            </Box>
        )
    })

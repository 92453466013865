import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

export const defaultMessages = {
    required: 'This field is required',
}

export const dirtyValues = (dirtyFields, allValues) => {
    // NOTE: Recursive function. Each propert and value gets passed in here.
    // This function was copied from here: https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784

    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues
    }

    // Here, we have an object.
    return Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
    )
}

export const decodeFieldKey = (id) => decodeURIComponent(id)
export const encodeFieldKey = (id) => encodeURIComponent(id).replace(/\./g, '%2E')

// the form context becomes available from the modal
export function SyncRefWithForm({ formRef }) {
    const context = useFormContext()

    useEffect(() => {
        const hasChanges = Object.keys(context.formState.dirtyFields).length > 0

        // we compute hasChanges once instead of doing it everywhere
        formRef.current = {
            ...context,
            hasChanges,
        }

        return () => (formRef.current = {})
    }, [context, formRef])

    return null
}

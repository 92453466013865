import React, { CSSProperties, ReactNode } from 'react'
import { Position, Tooltip as TippyTooltip, TooltipProps as TippyTooltipProps } from 'react-tippy'

import Box from './Box'

import 'react-tippy/dist/tippy.css'

export type ToolipProps = TippyTooltipProps & {
    children: ReactNode
    label?: React.ReactNode
    labelStyle?: CSSProperties
    wrapperStyle?: CSSProperties
    placement?: Position
    textAlign?: any
    display?: string
    showDelay?: number
    isOpen?: boolean
    hasArrow?: boolean
    maxWidth?: string | number
}

const Tooltip: React.FC<ToolipProps> = ({
    children,
    label,
    labelStyle,
    wrapperStyle,
    placement = 'left',
    textAlign = 'left',
    display = 'block',
    showDelay = 400,
    maxWidth = '200px',
    hasArrow = true,
    ...props
}) => (
    <TippyTooltip
        trigger="mouseenter"
        // @ts-ignore
        zIndex={9999}
        arrow={hasArrow}
        html={
            <Box style={{ maxWidth, textAlign, overflowWrap: 'break-word', ...labelStyle }}>
                {label}
            </Box>
        }
        position={placement}
        delay={showDelay}
        style={{ display: display, ...wrapperStyle }}
        {...props}
    >
        {children}
    </TippyTooltip>
)

export default React.memo(Tooltip)

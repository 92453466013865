import React, { FC, useMemo, useState } from 'react'

import { Spinner } from '@chakra-ui/react'
import shortid from 'shortid'

import { useDeleteAction } from 'data/hooks/actions'

import { ActionList, Box, Button, Flex, Link } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import ActionEditor from './ActionEditor'
import { useActionsContext } from './actionHooks'
import { getNewAction } from './getNewAction'
import { Spacer, Title } from './ui'

const { colors } = stackerTheme()

type Props = {
    noPadding: boolean
}

const ActionsList: FC<Props> = ({ noPadding }) => {
    const { actions, status, objectId } = useActionsContext()
    const [editActionIdInModal, setEditActionIdInModal] = useState<
        (Omit<ActionDto, '_sid' | 'stack_id' | 'name'> & { _sid: string | null }) | null
    >(null)
    const { mutateAsync: deleteAction } = useDeleteAction()

    const selectedAction = useMemo(() => {
        if (!editActionIdInModal) return null
        if (!editActionIdInModal._sid) return editActionIdInModal
        return actions?.find(({ _sid }) => _sid === editActionIdInModal._sid)
    }, [actions, editActionIdInModal])

    const onDelete = (actionId: string) => {
        if (window.confirm('Are you sure you want to delete this action?'))
            return deleteAction({
                id: actionId,
            })
    }

    const onDuplicate = (action: ActionDto) => {
        const newAction = {
            ...action,
            _sid: null,
            _new: true,
            name: action.name + ' (2)',
            options: {
                ...action.options,
                steps: action.options.steps.map((step) => ({
                    ...step,
                    id: 'action_step_' + shortid.generate(),
                })),
            },
        }

        setEditActionIdInModal(newAction)
    }

    if (status === 'loading') {
        return (
            <Flex justify="center" height="100px" bg="white" width="100%">
                <Spinner />
            </Flex>
        )
    }
    if (status !== 'success') {
        return <div>Can&apos;t display actions: {status}</div>
    }

    return (
        <>
            <Flex
                column
                alignItems="flexStart"
                padding={!noPadding ? 12 : undefined}
                style={{ width: '100%', maxWidth: !noPadding ? 600 : undefined }}
            >
                <Title mt={noPadding ? 0 : undefined}>
                    <Flex>
                        <div style={{ flex: 1 }}>All Actions</div>
                        <Button
                            variant="adminPrimary"
                            buttonSize="sm"
                            icon="add"
                            onClick={() => setEditActionIdInModal(getNewAction(objectId))}
                        >
                            Add
                        </Button>
                    </Flex>
                </Title>
                <Spacer />
                {actions?.map((action) => (
                    <Box key={action._sid} mb={2} width="100%">
                        <ActionItem
                            item={action}
                            onSelect={() => setEditActionIdInModal(action)}
                            onDelete={() => onDelete(action._sid)}
                            onDuplicate={() => onDuplicate(action)}
                        />
                    </Box>
                ))}
                {actions?.length === 0 && (
                    <Box
                        padding={4}
                        background={colors.grey[100]}
                        color={colors.grey[400]}
                        textAlign="center"
                        borderRadius={4}
                    >
                        {'No actions created'}
                    </Box>
                )}
            </Flex>
            {editActionIdInModal && (
                <ActionEditor
                    item={selectedAction as ActionDto}
                    onRequestClose={() => setEditActionIdInModal(null)}
                />
            )}
        </>
    )
}

export default ActionsList

const ActionItem: FC<{
    item: ActionDto
    onSelect: () => void
    onDelete: () => void
    onDuplicate: () => void
}> = ({ item, onSelect, onDelete, onDuplicate }) => {
    const actions = useMemo(
        () => [
            { label: 'Edit', action: onSelect },
            { label: 'Delete', action: onDelete },
            { label: 'Duplicate', action: onDuplicate },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [item]
    )
    return (
        <Flex
            border={`1px solid ${colors.grey[200]}`}
            style={{ padding: '8px 16px' }}
            borderRadius={4}
        >
            <Link
                flex={1}
                mr={16}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                cursor="pointer"
                onClick={onSelect}
            >
                {item.name}
            </Link>
            <ActionList actions={actions} dataTestId="view-actions" />
        </Flex>
    )
}

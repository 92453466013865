import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'

type AggregationBlockContextType = {
    editingWidgetOrder: number | undefined
    setEditingWidgetOrder: (val: number | undefined) => void
    onStopEditingWidget: () => void
    onStartEditingWidget: (widget: AggregateBlockAttributes) => void
}

type AggregationContextProviderProps = {
    blockId: string
    setVisibleAttributes: (attrs: string[] | undefined) => void
    setLayoutEditorCloseOverride: (removeLayoutEditorCloseOverride: () => void) => void
    removeLayoutEditorCloseOverride: () => void
}

export const AggregationBlockContext = React.createContext<AggregationBlockContextType>(
    {} as AggregationBlockContextType
)

export const AggregationBlockContextProvider: React.FC<AggregationContextProviderProps> = ({
    children,
    blockId,
    setVisibleAttributes,
    setLayoutEditorCloseOverride,
    removeLayoutEditorCloseOverride,
}) => {
    const isMounted = useRef(false)
    const [editingWidgetOrder, setEditingWidgetOrder] = useState<number | undefined>(undefined)

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    const layoutEditorContext = useContext(LayoutEditorContext)

    const onStopEditingWidget = useCallback(() => {
        if (!isMounted.current) return
        setEditingWidgetOrder(undefined)
        setVisibleAttributes(undefined)
        removeLayoutEditorCloseOverride()
    }, [removeLayoutEditorCloseOverride, setVisibleAttributes])

    const onStartEditingWidget = useCallback(
        (widget: AggregateBlockAttributes) => {
            setEditingWidgetOrder(widget.order)
            // only shows the metrics attribute
            setVisibleAttributes(['metrics'])
            layoutEditorContext.setSelectedWidget(`${blockId}-${widget.order}`)
            setLayoutEditorCloseOverride(onStopEditingWidget)
        },
        [
            blockId,
            layoutEditorContext,
            onStopEditingWidget,
            setLayoutEditorCloseOverride,
            setVisibleAttributes,
        ]
    )

    const state = useMemo(
        () => ({
            editingWidgetOrder,
            setEditingWidgetOrder,
            onStopEditingWidget,
            onStartEditingWidget,
            blockId,
        }),
        [blockId, editingWidgetOrder, onStartEditingWidget, onStopEditingWidget]
    )

    return (
        <AggregationBlockContext.Provider value={state}>
            {children}
        </AggregationBlockContext.Provider>
    )
}

export const colors = {
    '#E11D48': 'Rose',
    '#DB2777': 'Pink',
    '#C026D3': 'Fuchsia',
    '#9333EA': 'Purple',
    '#7C3AED': 'Violet',
    '#4F46E5': 'Indigo',
    '#2563EB': 'Blue',
    '#037CC2': 'Sky',
    '#0A80A8': 'Cyan',
    '#0F857C': 'Teal',
    '#078766': 'Emerald',
    '#51841B': 'Lime',
    '#D6430A': 'Orange',
}
/**  @type {string[]}*/
export const colorSwatch = Object.keys(colors)
export const defaultColor = colorSwatch[6]

import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { useStacks } from 'data/hooks/stacks'
import { StackIconBadge } from 'features/core/StackIconBadge'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

import { SearchAuxiliaryData, SearchResultDocument, SearchResultRecord } from './types'

export function RenderRecordSearchResult({
    item,
    data,
    blockLinkClick,
}: {
    item: SearchResultRecord
    data: SearchAuxiliaryData
    blockLinkClick?: boolean
}) {
    const { data: stacks } = useStacks()

    const stack = stacks?.find((s) => s._sid === item._stack_id)
    const object = data.objects?.[item._stack_id ?? '']?.[item._object_id ?? '']
    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            // If the caller doesn't want a click to activate the link,
            // we prevent default here--unless ctrl or meta is pressed, then
            // we want to let the user click on the link for opening in a new tab
            if (blockLinkClick && !(e.ctrlKey || e.metaKey)) {
                e.preventDefault()
            } else {
                // @ts-ignore
                e.nativeEvent.preventDownshiftDefault = true
            }
        },
        [blockLinkClick]
    )

    if (!stack || !object) return null

    return (
        <Box
            as={Link}
            to={item.url}
            flex
            maxWidth="full"
            width="full"
            alignItems="stretch"
            color="text"
            py="m"
            onClick={handleClick}
            tabIndex={-1}
        >
            <StackIconBadge stack={stack} size={28} mr="m" opacity={0.3} />
            <Box flex column grow shrink justifyContent="center">
                <Box flex center>
                    <Box
                        fontWeight="bodyBold"
                        dangerouslySetInnerHTML={{
                            __html: item._highlightResult?._primary?.value || item._primary,
                        }}
                        mr="l"
                        trim
                        grow
                    />
                    <Box flex center fontSize="bodyS">
                        <Box color="textWeak">{stack.name}</Box>
                        <Icon color="textWeakest" noShrink name="ChevronRight" size="xs" mx="3xs" />
                        <Box color="textWeak">{object.name}</Box>
                    </Box>
                </Box>
                {item._snippetResult?._description?.matchLevel === 'none' && (
                    <Box fontSize="bodyS" color="textWeak" trim mt="xs">
                        {item._description}
                    </Box>
                )}

                {Object.entries(item._snippetResult ?? {}).map(
                    ([key, value]) =>
                        value.matchLevel !== 'none' &&
                        key !== '_primary' && (
                            <Box
                                key={key}
                                fontSize="bodyS"
                                color="textWeak"
                                dangerouslySetInnerHTML={{ __html: value.value }}
                                mt="xs"
                            />
                        )
                )}
            </Box>
        </Box>
    )
}

export function RenderDocumentSearchResult({
    item,
    blockLinkClick,
}: {
    item: SearchResultDocument
    blockLinkClick?: boolean
}) {
    const { data: stacks } = useStacks()

    const stack = stacks?.find((s) => s._sid === item._stack_id)
    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            // If the caller doesn't want a click to activate the link,
            // we prevent default here--unless ctrl or meta is pressed, then
            // we want to let the user click on the link for opening in a new tab
            if (blockLinkClick && !(e.ctrlKey || e.metaKey)) {
                e.preventDefault()
            } else {
                // @ts-ignore
                e.nativeEvent.preventDownshiftDefault = true
            }
        },
        [blockLinkClick]
    )

    if (!stack) return null

    return (
        <Box
            as={Link}
            to={item.url}
            flex
            maxWidth="full"
            width="full"
            alignItems="stretch"
            color="text"
            py="m"
            onClick={handleClick}
            tabIndex={-1}
        >
            <StackIconBadge stack={stack} size={28} mr="m" opacity={0.3} />
            <Box flex column grow shrink justifyContent="center">
                <Box flex center>
                    <Box
                        fontWeight="bodyBold"
                        dangerouslySetInnerHTML={{
                            __html: item._highlightResult?._primary?.value || item._primary,
                        }}
                        mr="l"
                        trim
                        grow
                    />
                    <Box flex center fontSize="bodyS">
                        <Box color="textWeak">{stack.name}</Box>
                        <Icon color="textWeakest" size="xs" noShrink name="ChevronRight" mx="3xs" />

                        <Box color="textWeak">Notes</Box>
                    </Box>
                </Box>
                {item._snippetResult?._description?.matchLevel === 'none' && (
                    <Box fontSize="bodyS" color="textWeak" trim mt="xs">
                        {item._description}
                    </Box>
                )}

                {Object.entries(item._snippetResult ?? {}).map(
                    ([key, value]) =>
                        value.matchLevel !== 'none' &&
                        key !== '_primary' && (
                            <Box
                                key={key}
                                fontSize="bodyS"
                                color="textWeak"
                                dangerouslySetInnerHTML={{ __html: value.value }}
                                mt="xs"
                            />
                        )
                )}
            </Box>
        </Box>
    )
}

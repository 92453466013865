import React from 'react'

import type { ContextMenuSubTriggerProps as BaseProps } from '@radix-ui/react-context-menu'

import { Icon } from 'ui/components/Icon'
import { IconName } from 'ui/components/Icon/Icon'
import { StandardComponentProps } from 'ui/helpers/styles'

import { LeftSlot, MenuIcon, RightSlot, SubTrigger } from './ContextMenu.parts'

type ContextMenuSubTriggerProps = Omit<BaseProps, 'asChild'> &
    StandardComponentProps & {
        icon?: IconName
    }

export const ContextMenuSubTrigger: React.FC<ContextMenuSubTriggerProps> = ({
    icon,
    children,
    ...props
}) => {
    return (
        <SubTrigger {...props}>
            {icon && (
                <LeftSlot>
                    <MenuIcon name={icon} />
                </LeftSlot>
            )}
            {children}
            <RightSlot>
                <Icon name="ChevronRight" />
            </RightSlot>
        </SubTrigger>
    )
}

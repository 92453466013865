import React, { useEffect } from 'react'

import { PostHogProvider, usePostHog } from 'posthog-js/react'

import settings from 'app/settings'
import { useLDFlags, useLDFlagsWithReadyStatus } from 'data/hooks/useLDFlags'

import { useAuthContext } from './AuthContext/AuthContext'
import { useAppContext } from './useAppContext'

export const PostHog = (): React.ReactElement | null => {
    const { flags } = useLDFlags()
    return (
        <PostHogProvider
            apiKey={settings.POSTHOG.key}
            options={{
                api_host: settings.POSTHOG.host,
                disable_session_recording: flags.postHogDisableSessionRecording,
                session_recording: {
                    maskAllInputs: false,
                    maskInputOptions: {
                        password: true,
                    },
                },
            }}
        >
            <PostHogTracker />
        </PostHogProvider>
    )
}

export const PostHogTracker = (): null => {
    const postHog = usePostHog()
    const { user } = useAuthContext()
    const { workspaceAccount } = useAppContext()
    const { flags, isClientReady } = useLDFlagsWithReadyStatus()

    useEffect(() => {
        if (user?.email) {
            postHog.identify(user.email, { email: user.email })
        }
    }, [user?.email, user?.name, postHog])

    useEffect(() => {
        if (workspaceAccount?._sid) {
            postHog.group('workspace', workspaceAccount._sid, {
                name: workspaceAccount?.name,
            })
        }
    }, [workspaceAccount?._sid, workspaceAccount?.name, postHog])

    useEffect(() => {
        if (!isClientReady) {
            return
        } else if (flags.postHogDisableSessionRecording && postHog.sessionRecordingStarted()) {
            postHog.stopSessionRecording()
        }
    }, [isClientReady, flags.postHogDisableSessionRecording, workspaceAccount?._sid, postHog])

    return null
}

import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

import { subscribers } from 'features/profile/openProfileSettingsModal'
import { UnsavedChangesModal } from 'features/workspace/UnsavedChangesModal'

import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import { Flex } from 'ui/deprecated/atoms/Flex'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import GeneralSettings from './ProfileSettingsModalGeneralSettings'
import { ProfileSettingsModalIntegrationSettings } from './ProfileSettingsModalIntegrationSettings'
import NotificationSettings from './ProfileSettingsModalNotificationSettings'
import { Sidebar } from './ProfileSettingsModalSidebar'
import ProfileSupportLoginAccess from './ProfileSupportLoginAccess'

const GeneralSettingsMemo = React.memo(GeneralSettings)

// handle the open / closed state of the modal
export default function ProfileSettingsModal() {
    const [isOpen, setIsOpen] = useState()

    useEffect(() => {
        subscribers.push(setIsOpen)

        return () =>
            subscribers.splice(
                0,
                subscribers.length,
                ...subscribers.filter((fn) => fn !== setIsOpen)
            )
    }, [])

    return isOpen ? (
        <ProfileSettingsContent
            onRequestClose={() => {
                setIsOpen(false)
            }}
        />
    ) : null
}

const pageComponents = {
    general: GeneralSettingsMemo,
    notifications: NotificationSettings,
    supportLogin: ProfileSupportLoginAccess,
    integration: ProfileSettingsModalIntegrationSettings,
}

/**
 *
 * @param {{onRequestClose: () => void, defaultState: {page: ProfileSettingsPage}}} param0
 */
function ProfileSettingsContent({ onRequestClose, defaultState = { page: 'general' } }) {
    const formRef = useRef({})
    const [displayChangesModal, setDisplayChangesModal] = useState(false)

    const [page, setPage] = useState(defaultState.page)

    // similar to onChangePage()
    // Can we close the modal yet?
    function onClose() {
        // wait until any ongoing request finishes before closing
        if (formRef.current.formState?.isSubmitting) {
            return setDisplayChangesModal({
                type: 'submitting',
                onProceed: onRequestClose, // function called when the function finishes
                onCancel: () => setDisplayChangesModal(false), // function called when clicking on Cancel
            })
        }

        // there are changes, don't close yet
        if (formRef.current.hasChanges) {
            return setDisplayChangesModal({
                type: 'changes',
                onProceed: onRequestClose,
                onCancel: () => setDisplayChangesModal(false),
            })
        }

        onRequestClose()
    }

    // similar to onClose
    // If there has changes, do not change pages
    function onChangePage(name) {
        // wait until any ongoing request finishes before closing
        if (formRef.current.formState?.isSubmitting) {
            return setDisplayChangesModal({
                type: 'submitting',
                onProceed: () => {
                    // called after the save query // or when clicking on Discard and continue
                    setPage(name)
                    setDisplayChangesModal(false)
                },
                onCancel: () => setDisplayChangesModal(false),
            })
        }

        // there are unsaved changes, don't close yet
        if (formRef.current.hasChanges) {
            return setDisplayChangesModal({
                type: 'changes',
                onProceed: () => {
                    setPage(name)
                    setDisplayChangesModal(false)
                },
                onCancel: () => setDisplayChangesModal(false),
            })
        }

        setPage(name)
    }

    const Component = pageComponents[page] ?? GeneralSettingsMemo

    const modalWidth = useResponsiveValue({
        mobile: '100%',
        tablet: '900px',
    })
    const wrapperHeight = useResponsiveValue({
        mobile: 'auto',
        tablet: '100%',
    })
    const wrapperWrap = useResponsiveValue({
        mobile: 'wrap',
        tablet: 'nowrap',
    })

    return (
        <SimpleModalCompat
            isOpen
            size={modalWidth}
            height={800}
            noPadding
            onClose={onClose}
            title="Account settings"
        >
            <Flex column style={{ height: wrapperHeight }}>
                <Flex
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        minHeight: 0,
                        overflow: 'hidden',
                        flexWrap: wrapperWrap,
                    }}
                >
                    <Sidebar onChange={onChangePage} active={page} onClose={onClose} />

                    {Component ? (
                        <Component formRef={formRef}>
                            {displayChangesModal && (
                                <UnsavedChangesModal {...displayChangesModal} />
                            )}
                        </Component>
                    ) : (
                        "can't render " + page
                    )}
                </Flex>
            </Flex>
        </SimpleModalCompat>
    )
}

import React from 'react'

import { Button } from 'ui/components/Button'

import { useAppUsersCreateTableGroupButtonState } from './hooks/useAppUsersCreateTableGroupButtonState'

type AppUsersCreateTableGroupButtonProps = {}

export const AppUsersCreateTableGroupButton: React.FC<AppUsersCreateTableGroupButtonProps> = () => {
    const { onClick } = useAppUsersCreateTableGroupButtonState()

    return (
        <Button size="s" variant="secondary" startIcon={{ name: 'Table' }} onClick={onClick}>
            Create table group
        </Button>
    )
}

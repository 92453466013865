import { TableRow } from '@tiptap/extension-table-row'

import { TableRowStyle } from './TableExtension.css'

export function createTableRowExtension() {
    return TableRow.configure({
        HTMLAttributes: {
            class: TableRowStyle,
        },
    })
}

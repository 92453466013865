// @ts-strict-ignore
import React from 'react'

import {
    DropdownEndIndicatorIcon,
    DropdownItem,
    DropdownSub,
    DropdownSubContent,
    DropdownSubTrigger,
} from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useActionMenuEditCheckboxState } from './hooks/useActionMenuEditCheckboxState'
import { ActionMenuEditComponent } from './types'

type ActionMenuEditCheckboxProps = {}

export const ActionMenuEditCheckbox: ActionMenuEditComponent<boolean, ActionMenuEditCheckboxProps> =
    React.memo(function ActionMenuEditCheckbox(props) {
        const { fieldLabel, editOptions, onEditValue, fieldIcon } =
            useActionMenuEditCheckboxState(props)

        return (
            <DropdownSub>
                <DropdownSubTrigger
                    startIcon={fieldIcon?.type === 'lucide' ? { name: fieldIcon.name } : undefined}
                    label={fieldLabel}
                />
                <DropdownSubContent onClick={stopPropagation}>
                    {editOptions.map((option) => {
                        const isChecked = props.value === option.value

                        return (
                            <DropdownItem
                                key={option.value.toString()}
                                label={option.label}
                                multiSelect
                                checked={isChecked}
                                onCheckedChange={(checked) => {
                                    onEditValue(option.value, checked)
                                }}
                                closeOnSelect={true}
                                hideCheckbox={option.isRecent && !isChecked}
                                rightSlotContent={(props) => {
                                    if (option.isRecent && !isChecked) {
                                        return <DropdownEndIndicatorIcon name="Clock" {...props} />
                                    }

                                    return null
                                }}
                            />
                        )
                    })}
                </DropdownSubContent>
            </DropdownSub>
        )
    })

import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'

import { useNavigationAppsItemState } from './hooks/useNavigationAppsItemState'
import { AppIcon } from './AppIcon'
import { NavigationItem } from './NavigationItem'
import { NavigationApp } from './types'

import {
    AppIconWrapperStyle,
    WorkspaceNavigationAppItemLabelStyles,
    WorkspaceNavigationAppItemStyles,
} from './Navigation.css'

type NavigationAppsItemProps = React.ComponentPropsWithoutRef<typeof NavigationItem> & {
    app: NavigationApp
    isCollapsed?: boolean
    isActive?: boolean
    weight?: 'medium' | 'bold'
}

export const NavigationAppsItem: React.FC<NavigationAppsItemProps> = ({
    app,
    isCollapsed,
    className,
    isActive: overrideIsActive,
    weight = 'medium',
    ...props
}) => {
    const { name, colorScheme, isExternalUrl, url } = app
    const { isActive } = useNavigationAppsItemState({ app })

    const effectiveIsActive = overrideIsActive ?? isActive

    const iconContent = (
        <Box className={AppIconWrapperStyle} noShrink>
            {/* We only show the active state of the icon when the navigation is expanded. */}
            <AppIcon app={app} isActive={effectiveIsActive && !isCollapsed} />
        </Box>
    )

    return (
        <NavigationItem
            to={isExternalUrl ? undefined : url}
            href={isExternalUrl ? url : undefined}
            aria-label={name}
            label={
                <Body
                    size="m"
                    weight={weight}
                    trim
                    className={WorkspaceNavigationAppItemLabelStyles.styleFunction({
                        isActive: effectiveIsActive,
                        colorScheme,
                    })}
                >
                    {name}
                </Body>
            }
            leftSlotContent={
                isCollapsed ? (
                    <Tooltip
                        content={app.name}
                        asChild
                        zIndex={200}
                        align="start"
                        side="right"
                        sideOffset={16}
                        noShrink
                    >
                        {iconContent}
                    </Tooltip>
                ) : (
                    iconContent
                )
            }
            isCollapsed={isCollapsed}
            className={classNames(
                WorkspaceNavigationAppItemStyles.styleFunction({
                    isCollapsed,
                    isActive: effectiveIsActive,
                    colorScheme,
                }),
                className
            )}
            {...props}
        />
    )
}

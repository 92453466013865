import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

export type LayoutEditorWidgetContextMenuHandle = {
    openAt: (x: number, y: number) => void
    close: () => void
}

export function useLayoutEditorWidgetContextMenuState() {
    const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
    const [isOpen, setIsOpen] = useState(false)

    const openAt = useCallback((x: number, y: number) => {
        setCurrentPos({
            x,
            y,
        })
        setIsOpen(true)
    }, [])
    const close = useCallback(() => {
        setIsOpen(false)
    }, [])

    useLayoutEffect(() => {
        const handleResize = () => {
            close()
        }

        // Close the context menu when the window is resized.
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [close])

    return useMemo(
        () => ({
            isOpen,
            setIsOpen,
            openAt,
            close,
            posX: currentPos.x,
            posY: currentPos.y,
        }),
        [isOpen, openAt, close, currentPos.x, currentPos.y]
    )
}

import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { UserRecordLinkAvatar } from './useUserRecordLinksAvatars'

export type UserItem = Pick<UserRecordLinkAvatar, 'firstName' | 'lastName'> &
    Partial<Pick<UserRecordLinkAvatar, 'imageUrl' | 'type'>> & {
        hideAvatar?: boolean
        to?: React.ComponentPropsWithoutRef<typeof Link>['to']
    }

type UseUsersStateOptions = {
    value?: UserItem[]
}

export function useUsersState(options: UseUsersStateOptions) {
    const { value } = options

    const isSingle = value?.length === 1
    const isEmpty = !value?.length

    const valueWithDefaults = useMemo(() => {
        if (!value) return []

        return value.map((item) => ({
            ...item,
            type: item.type ?? 'initial',
            fullName: `${item.firstName} ${item.lastName}`.trim(),
        }))
    }, [value])

    return useMemo(
        () => ({
            isEmpty,
            isSingle,
            valueWithDefaults,
        }),
        [isEmpty, isSingle, valueWithDefaults]
    )
}

import React from 'react'

import { DateAttributeDisplay } from 'features/views/attributes/DateAttributeDisplay'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_LINES = 1

type DateCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const DateCell: TableViewCellComponent<string, DateCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { isPrimary, field } = column

    const isEmpty = !isLoading && !value

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <DateAttributeDisplay
                weight={isPrimary ? 'bold' : undefined}
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                value={value}
                field={field}
                maxLines={MAX_LINES}
                isLoading={isLoading}
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseCell>
    )
}

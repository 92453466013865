import { useCallback, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useInlineCreateViewModal } from 'v2/views/Create/useInlineCreateViewModal'

import { getUrl } from 'app/UrlService'
import { useObject } from 'data/hooks/objects'
import { useCreateView, useViews } from 'data/hooks/views'
import { canCreateRecords } from 'data/utils/getObjectRecordRestrictions'
import { NavigationAppMenuItem } from 'features/navigation/types'

import { useAppNavigationContext } from './useAppNavigationContext'
import { useNavigationContext } from './useNavigationContext'

type UseAppNavigationTreeAddNewDropdownStateProps = {
    item: NavigationAppMenuItem
}

export function useAppNavigationTreeAddNewDropdownState({
    item,
}: UseAppNavigationTreeAddNewDropdownStateProps) {
    const { permissions } = useNavigationContext()

    const { app } = useAppNavigationContext()
    const appRef = useRef(app)
    appRef.current = app

    const { objectSid } = item
    const { object } = useObject(objectSid, { stackId: item.stackSid })

    const history = useHistory()
    const { mutateAsync: createNewView } = useCreateView({ stackId: item.stackSid })
    const [isCreatingView, setIsCreatingView] = useState(false)
    const createView = useCallback(
        async (display: ListViewDisplay) => {
            setIsCreatingView(true)

            const viewName = object?.name

            const viewTemplate: Partial<ViewDto> = {
                type: 'list',
                name: viewName,
                label: viewName,
                title: viewName,
                object_id: objectSid,
                options: {
                    title: viewName,
                    display,
                    columns: [],
                    blocks: [],
                    showAllFields: true,
                    defaultFieldsSelected: true,
                },
            } as unknown as ViewDto
            try {
                const newView = (await createNewView(viewTemplate)) as ViewDto

                requestAnimationFrame(() => {
                    const app = appRef.current
                    history.push(getUrl(newView.url, app.stack))
                })
            } finally {
                setIsCreatingView(false)
            }
        },
        [object?.name, objectSid, createNewView, history]
    )

    const canCreateView = permissions.canCreateView

    const { toggle: toggleCreateModal } = useInlineCreateViewModal({
        objectId: objectSid ?? '',
        queryParams: {},
    })
    const canCreateRecord = object ? canCreateRecords(object) : false
    const createRecord = useCallback(() => {
        if (!object?._sid) return

        toggleCreateModal()
    }, [object?._sid, toggleCreateModal])

    const { data: views } = useViews({ stackId: item.stackSid })
    const view = views?.find((v) => v.url === item.path)
    const viewRef = useRef(view)
    viewRef.current = view

    const showButton = (!view || view?.type === 'list') && (canCreateView || canCreateRecord)
    const isLoading = isCreatingView

    return useMemo(
        () => ({
            canCreateView,
            createView,
            showButton,
            canCreateRecord,
            createRecord,
            isLoading,
        }),
        [canCreateView, createView, showButton, canCreateRecord, createRecord, isLoading]
    )
}

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAccounts } from 'data/hooks/accounts'
import useLDIdentify from 'data/hooks/useLDIdentify'
import { useWorkspaceUser } from 'data/hooks/workspaceUser'
import { useGetAccountAndZone, useZones } from 'data/hooks/zones'

import { LoadingSplash } from 'v2/ui'

import { useAuthContext } from './AuthContext/AuthContext'
import { FailedPage } from './FailedPage'
import { GlobalStaticState } from './GlobalStaticState'
import { getWorkspaceSlugFromLocation, ZONE_PREFIX } from './UrlService'
import { isOnStudioDomain } from './urlUtils'
import { WorkspaceContext } from './WorkspaceContext'

function getZoneFromPath(
    pathname: string,
    account?: Account,
    zones?: AccountZone[]
): AccountZone | null {
    if (!account || !zones) {
        return null
    }

    // If we're on the studio domain, the path will be /:workspace_slug/p/:zone_slug
    // otherwise, we're on a custom domain and the path will be /p/:zone_slug
    const pathParts = isOnStudioDomain() ? pathname.split('/').slice(1) : pathname.split('/')
    let zone: AccountZone | null = null
    if (account) {
        // If we're in a zone, find the zone that matches the slug
        // zone URLs are: /:workspace_slug/p/:zone_slug
        if (pathParts?.[1] === ZONE_PREFIX) {
            zone = zones.find((zone) => zone.slug === pathParts?.[2]) || null
        } else {
            // otherwise use the default zone
            zone =
                zones.find((zone) => zone.account_sid === account._sid && zone.is_default) || null
        }
    }

    return zone
}

function useWorkspaceAccountAndZone() {
    const { user } = useAuthContext()
    // Get all accounts and zones
    const { data: accounts } = useAccounts({ enabled: !!user })
    const { data: zones } = useZones({ enabled: !!user })

    // Get workspace slug (if any) if we're on the studio domain
    const slug = getWorkspaceSlugFromLocation()

    // fetch the account and zone based on the slug (or custom damain).
    // This fetch doesn't need auth.
    const {
        account: initialWorkspaceAccount,
        zone: initialWorkspaceZone,
        isLoading,
        isError: accountAndZoneByUrlError,
    } = useGetAccountAndZone(slug)

    // only show the failure page when we have an error AND we've not yet fetched the required data
    const accountAndZoneFailed = accountAndZoneByUrlError && !initialWorkspaceAccount

    const { pathname } = useLocation()

    // selected workspaceAccount object should be the full account object if it's been loaded
    // (as it has more info in it once the user is authed)
    const workspaceAccount =
        accounts?.find((account) => account._sid === initialWorkspaceAccount?._sid) ||
        initialWorkspaceAccount

    const isOnPortalDomain = initialWorkspaceZone?.type === 'Portal'

    // If we're not on a portal domain, we want to see if we're on a portal sub path like
    // /:workspace_slug/p/:zone_slug
    const zoneFromPath = !isOnPortalDomain
        ? getZoneFromPath(pathname, workspaceAccount, zones)
        : undefined
    const workspaceZone = zoneFromPath || initialWorkspaceZone

    const failed = accountAndZoneFailed

    return {
        isLoading,
        failed,
        workspaceAccount,
        workspaceZone,
        isOnPortalDomain,
    }
}

export function WorkspaceContextProvider({ children }: { children: React.ReactNode }) {
    const { user, isEmailVerified } = useAuthContext()
    const {
        isLoading: accountAndZoneLoading,
        failed: accountAndZoneFailed,
        workspaceAccount,
        workspaceZone,
        isOnPortalDomain,
    } = useWorkspaceAccountAndZone()

    const ldIdentify = useLDIdentify()

    // Keep the global static stae object up to date
    // so these ids are accessible to non-react code such as APIs etc
    GlobalStaticState.setWorkspaceAccount(workspaceAccount)
    GlobalStaticState.setWorkspaceZone(workspaceZone)
    GlobalStaticState.setIsOnPortalDomain(isOnPortalDomain)

    const {
        data: workspaceUser,
        isError: hasWorkspaceUserError,
        error: workspaceUserError,
    } = useWorkspaceUser(workspaceAccount?._sid)

    useEffect(() => {
        if (workspaceAccount) {
            ldIdentify(workspaceAccount)
        }
    }, [workspaceAccount, ldIdentify])

    const userDeniedAccessToWorkspace =
        // @ts-expect-error
        workspaceUserError?.exception === 'NotAuthenticatedForWorkspaceError'

    const workspaceUserFailed =
        hasWorkspaceUserError && !workspaceUser && !userDeniedAccessToWorkspace
    if (accountAndZoneFailed || workspaceUserFailed) {
        return <FailedPage />
    }

    const workspaceUserLoading =
        !!user &&
        !!workspaceAccount &&
        !workspaceUser &&
        !userDeniedAccessToWorkspace &&
        isEmailVerified // we don't try to load the workspace user if the email isn't verified

    if (accountAndZoneLoading || workspaceUserLoading) {
        return <LoadingSplash />
    }

    return (
        <WorkspaceContext.Provider
            value={{
                workspaceAccount: workspaceAccount || null,
                workspaceZone,
                workspaceUser: user ? workspaceUser : undefined,
                isOnPortalDomain,
            }}
        >
            {children}
        </WorkspaceContext.Provider>
    )
}

import React from 'react'

import { isEqual } from 'lodash'

import { QuickFilterCheckbox } from './QuickFilterCheckbox'
import { QuickFilterDate } from './QuickFilterDate'
import { QuickFilterDropdown } from './QuickFilterDropdown'
import { QuickFilterPercentage } from './QuickFilterPercentage'
import { QuickFilterRecordLinks } from './QuickFilterRecordLinks'
import { QuickFilterUser } from './QuickFilterUser'

type QuickFiltersItemProps = {
    field: FieldDto
}

export const QuickFiltersItem: React.FC<QuickFiltersItemProps> = React.memo(
    function QuickFiltersItem(props) {
        switch (props.field.type) {
            case 'dropdown':
            case 'multi_select':
                return <QuickFilterDropdown {...props} />
            case 'checkbox':
                return <QuickFilterCheckbox {...props} />
            case 'percentage':
                return <QuickFilterPercentage {...props} />
            case 'lookup':
            case 'multi_lookup':
                return <QuickFilterRecordLinks {...props} />
            case 'user_ref':
                return <QuickFilterUser {...props} />
            case 'date':
            case 'datetime':
                return <QuickFilterDate {...props} />
            default:
                return null
        }
    },
    isEqual
)

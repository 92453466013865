import React from 'react'

export type WorkflowEditorContextValue = {
    selectedItem: WorkflowItem | undefined
    setSelectedItem: (item: WorkflowItem | undefined, chainPath?: string[]) => void
    workflow: WorkflowDto
    contextSchema: WorkflowSchemaStateItem[]
    addNode: (nodeType: WorkflowSchemaNodeType) => void
    deleteNode: (nodeId: string, chainPath?: string[]) => void
    selectedLine: WorkflowLine | undefined
    setSelectedLine: (line: WorkflowLine | undefined, chainPath?: string[]) => void
    closeEditor: () => void
    selectedItemChainPath: string[]
}
export const WorkflowEditorContext = React.createContext<WorkflowEditorContextValue>(
    {} as WorkflowEditorContextValue
)

export function useWorkflowEditorContext() {
    return React.useContext(WorkflowEditorContext)
}

import { useMemo } from 'react'

import Bowser from 'bowser'
const useShiftKey = () => {
    return useMemo(() => {
        const browser = Bowser.parse(window.navigator.userAgent)
        return browser.os.name === 'macOS' ? '⇧' : 'shift'
    }, [])
}
export default useShiftKey

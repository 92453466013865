import Bowser from 'bowser'

const browserInfo = Bowser.parse(window.navigator.userAgent)

export function isCmdOrCtrlPressed(e: KeyboardEvent): boolean {
    const isMac = browserInfo.os.name === 'macOS'

    if (isMac) return e.metaKey

    return e.ctrlKey
}

import React, { useRef } from 'react'

import { SortableContext } from '@dnd-kit/sortable'
import { useComposedRefs } from '@radix-ui/react-compose-refs'
import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { ScrollArea } from 'ui/components/ScrollArea'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'

import { useBoardViewCardWrapperState } from './hooks/useBoardViewCardWrapperState'
import { useBoardViewColumnContentState } from './hooks/useBoardViewColumnContentState'
import { useBoardViewColumnHeaderState } from './hooks/useBoardViewColumnHeaderState'
import {
    BoardViewCardValue,
    useBoardViewColumnRendererState,
} from './hooks/useBoardViewColumnRendererState'
import * as Parts from './BoardView.parts'
import { BoardViewAddNewCard } from './BoardViewAddNewCard'
import { BoardViewDnDCard } from './BoardViewCard'
import { BoardViewColumn } from './types'

import {
    BoardViewAddNewCardButtonStyle,
    ColumnBottomGutterStyle,
    ColumnHeaderTitleStyles,
    ColumnHideOnHoverStyle,
    ColumnScrollViewportStyle,
    ColumnShowOnHoverStyle,
    ColumnStyles,
    ColumnWrapperStyle,
} from './BoardView.css'

const TITLE_MAX_LENGTH = 20

type BoardViewColumnRendererProps = React.ComponentPropsWithoutRef<typeof Parts.Column> & {
    column: BoardViewColumn
    records?: RecordDto[]
}

export const BoardViewColumnRenderer: React.FC<BoardViewColumnRendererProps> = React.memo(
    function BoardViewColumnRenderer({ column, records: providedRecords, isLoading, ...props }) {
        const { title, colorScheme, id } = column

        const {
            valueCount,
            recordSids,
            records,
            columnRef,
            scrollAreaRef,
            values,
            sortStrategy,
            isDraggingOver,
            showAddNew,
            onAddNewCancel,
            onAddNewToggle,
            onAddNewRecord,
            isEmbedded,
            onCollapseColumnClick,
            isCollapsed,
            removeRecordNotMatchingFilters,
        } = useBoardViewColumnRendererState({
            column,
            records: providedRecords,
        })

        const hasCards = (valueCount > 0 || !!showAddNew) && !isCollapsed
        const isEmbeddedWithContent = isEmbedded && hasCards

        return (
            <Box
                noShrink
                height={isEmbeddedWithContent ? '600px' : 'full'}
                flex
                flexDirection="column"
                gap="m"
                className={ColumnWrapperStyle}
            >
                <Parts.Column
                    ref={columnRef}
                    colorScheme={colorScheme}
                    isDraggingOver={isDraggingOver}
                    isLoading={isLoading}
                    isCollapsed={isCollapsed}
                    {...props}
                >
                    <Skeleton
                        className={ColumnStyles.styleFunction({ isCollapsed })}
                        isLoading={isLoading}
                        showChildren
                    >
                        <ColumnHeader
                            title={title}
                            colorScheme={colorScheme}
                            valueCount={valueCount}
                            isLoading={isLoading}
                            pb={hasCards ? 'xs' : 'm'}
                            onCollapseColumnClick={onCollapseColumnClick}
                            isCollapsed={isCollapsed}
                            onAddNewClick={() => onAddNewToggle('top')}
                        />
                        {hasCards && (
                            <SortableContext items={recordSids} id={id} strategy={sortStrategy}>
                                <ColumnContent
                                    ref={scrollAreaRef}
                                    records={records}
                                    column={column}
                                    values={values}
                                    colorScheme={colorScheme}
                                    isLoading={isLoading}
                                    showChildrenFirst={showAddNew === 'top'}
                                    removeRecordNotMatchingFilters={removeRecordNotMatchingFilters}
                                >
                                    {!!showAddNew && (
                                        <BoardViewAddNewCard
                                            onCancel={onAddNewCancel}
                                            column={column}
                                            onAddNewRecord={onAddNewRecord}
                                            isFirstInColumn={showAddNew === 'top'}
                                            fistRecordLocalDisplayOrder={
                                                records?.[0]?._local_display_order
                                            }
                                        />
                                    )}
                                </ColumnContent>
                            </SortableContext>
                        )}
                    </Skeleton>
                </Parts.Column>
                <Box
                    noShrink
                    className={ColumnBottomGutterStyle}
                    hidden={!!showAddNew || isLoading || isCollapsed}
                >
                    <Tooltip content="Add new record" side="bottom" asChild>
                        <Button
                            size="xs"
                            variant="ghost"
                            startIcon={{ name: 'Plus' }}
                            onClick={() => onAddNewToggle('bottom')}
                            className={BoardViewAddNewCardButtonStyle}
                        >
                            Add new
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
        )
    },
    isEqual
)

type ColumnHeaderProps = React.ComponentPropsWithoutRef<typeof Parts.ColumnHeader> & {
    title: string
    onAddNewClick: () => void
    onCollapseColumnClick: () => void
    valueCount?: number
    isCollapsed?: boolean
}

const ColumnHeader: React.FC<ColumnHeaderProps> = React.memo(function ColumnHeader({
    title,
    valueCount,
    onAddNewClick,
    onCollapseColumnClick,
    isCollapsed,
    ...props
}) {
    const { formattedValueCount } = useBoardViewColumnHeaderState({ valueCount, isCollapsed })

    return (
        <Parts.ColumnHeader
            flex
            center
            gap={isCollapsed ? 'l' : 'm'}
            justifyContent="space-between"
            pt="m"
            alignSelf="stretch"
            px={isCollapsed ? 0 : 'xl'}
            pb={isCollapsed ? 'm' : 0}
            flexDirection={isCollapsed ? 'column' : 'row'}
            role={isCollapsed ? 'button' : undefined}
            onClick={isCollapsed ? onCollapseColumnClick : undefined}
            aria-label={isCollapsed ? 'Expand column' : undefined}
            {...props}
        >
            <Tooltip
                content={title}
                side="bottom"
                className={ColumnHeaderTitleStyles.styleFunction({ isCollapsed })}
            >
                <Body
                    size="m"
                    weight="bold"
                    style={{ color: 'inherit' }}
                    maxLength={TITLE_MAX_LENGTH}
                >
                    {title}
                </Body>
            </Tooltip>
            <Box flex center position="relative">
                <Box
                    position={isCollapsed ? 'static' : 'absolute'}
                    flex
                    center
                    justifyContent="flex-end"
                    textAlign="right"
                    top={0}
                    bottom={0}
                    right={0}
                    grow
                    noShrink
                    style={{
                        width: 'max-content',
                        margin: 'auto 0',
                    }}
                    className={isCollapsed ? undefined : ColumnHideOnHoverStyle}
                >
                    <Body size="s" weight="medium" color="textWeaker">
                        {formattedValueCount}
                    </Body>
                </Box>
                {!isCollapsed && (
                    <Box flex center gap="xs" className={ColumnShowOnHoverStyle}>
                        <Tooltip content="Add new record" side="bottom" asChild>
                            <Button
                                size="xs"
                                variant="ghost"
                                aria-label="Add new record"
                                startIcon={{ name: 'Plus' }}
                                onClick={onAddNewClick}
                                className={BoardViewAddNewCardButtonStyle}
                            />
                        </Tooltip>
                        <Tooltip content="Collapse column" side="bottom" asChild>
                            <Button
                                size="xs"
                                variant="ghost"
                                aria-label="Collapse column"
                                startIcon={{ name: 'ChevronsRightLeft' }}
                                onClick={onCollapseColumnClick}
                            />
                        </Tooltip>
                    </Box>
                )}
            </Box>
        </Parts.ColumnHeader>
    )
})

type ColumnContentProps = React.PropsWithChildren<{
    values: BoardViewCardValue[]
    records: RecordDto[]
    column: BoardViewColumn
    colorScheme?: React.ComponentPropsWithoutRef<typeof Parts.Column>['colorScheme']
    isLoading?: boolean
    showChildrenFirst?: boolean
    removeRecordNotMatchingFilters: (recordSid: string) => void
}>

const ColumnContentInner = React.forwardRef<HTMLDivElement, ColumnContentProps>(
    function ColumnContentInner(
        {
            values,
            records,
            colorScheme,
            isLoading,
            children,
            showChildrenFirst,
            removeRecordNotMatchingFilters,
        },
        ref
    ) {
        // These won't cause re-renders in the child components.
        const siblingRecords = useRef(records)
        siblingRecords.current = records

        const { scrollAreaRef, items, totalSize, startSize } = useBoardViewColumnContentState({
            values,
        })

        const composedRef = useComposedRefs(scrollAreaRef, ref)

        const content = children && (
            <Box
                mb={showChildrenFirst && !!items.length ? 's' : undefined}
                mt={!showChildrenFirst && !!items.length ? 's' : undefined}
            >
                {children}
            </Box>
        )

        return (
            <ScrollArea
                ref={composedRef}
                direction="vertical"
                height="full"
                width="full"
                className={ColumnScrollViewportStyle}
                rootProps={{
                    width: 'full',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                asChild
            >
                <Box pt={0} p="m">
                    {showChildrenFirst && content}
                    <div
                        style={{
                            height: totalSize,
                            position: 'relative',
                            width: '100%',
                        }}
                    >
                        <Box
                            flex
                            flexDirection="column"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                transform: `translateY(${startSize})`,
                            }}
                        >
                            {items.map((virtualRow) => {
                                const idx = virtualRow.index
                                const v = values[idx]

                                return (
                                    <CardWrapper
                                        key={idx}
                                        index={idx}
                                        measureFn={virtualRow.measureRef}
                                    >
                                        <BoardViewDnDCard
                                            record={v.record}
                                            colorScheme={colorScheme}
                                            siblingRecords={siblingRecords.current}
                                            isLoading={isLoading}
                                            isNotMatchingFilters={v.isNotMatchingFilters}
                                            removeRecordNotMatchingFilters={
                                                removeRecordNotMatchingFilters
                                            }
                                        />
                                    </CardWrapper>
                                )
                            })}
                        </Box>
                    </div>
                    {!showChildrenFirst && content}
                </Box>
            </ScrollArea>
        )
    }
)

const ColumnContent = React.memo(ColumnContentInner, isEqual)

type CardWrapperProps = {
    index: number
    measureFn: (el: HTMLElement | null) => void
}

export const CardWrapper: React.FC<CardWrapperProps> = React.memo(function CardWrapper({
    index,
    measureFn,
    children,
}) {
    const { ref } = useBoardViewCardWrapperState({ measureFn })

    return (
        <Box
            ref={ref}
            pt={index === 0 ? 0 : 's'}
            data-index={index}
            style={{
                boxSizing: 'border-box',
            }}
        >
            {children}
        </Box>
    )
})

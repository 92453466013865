import React, { FC } from 'react'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import debounce from 'lodash/debounce'

import { useObject } from 'data/hooks/objects'
import { Icon } from 'legacy/v1/ui'

import { Button, Dropdown, Flex, Text } from 'v2/ui'

import { getFilterOptionsForType } from './utils/utils'
import { labelMap, nextFilterOptions } from './constants'

const SMALL_FILTER_BREAKPOINT = '750px'

const FilterWrapper = styled(Flex)`
    width: 400px;
    margin-top: 10px;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    margin-bottom: 1rem;

    @media only screen and (min-width: ${SMALL_FILTER_BREAKPOINT}) {
        width: 100%;
        align-items: center;
        flex-direction: row;
        margin-bottom: 10px;
    }

    .small-filters & {
        width: 590px;
        flex-wrap: nowrap;
    }
`

const FilterItem = styled(Flex)`
    padding-bottom: 0.5rem;
    width: 100%;

    @media only screen and (min-width: ${SMALL_FILTER_BREAKPOINT}) {
        padding-right: 1rem;
        flex-shrink: 0;
        width: auto;
        padding-bottom: 0;
    }

    .small-filters & {
        flex-shrink: 1;
    }
`

const FilterButtomWrapper = styled(FilterItem)`
    @media only screen and (min-width: ${SMALL_FILTER_BREAKPOINT}) {
        flex-basis: 0;
    }
`

type Props = {
    id: string
    field: FieldDto
    filterOptions: {
        option: keyof typeof nextFilterOptions
        value: any
    }
    hideCurrentUserOption: boolean
    roles: RoleDto[]
    showLogicalOperator: boolean
    showRelativeDateFilters: boolean
    userObject: ObjectDto
    className: string
    onChange: (options: any[]) => void
    delete: () => void
    contextRecordObject?: ObjectDto
}

class FieldFilterInner extends React.Component<Props> {
    state = {
        option: this.props.filterOptions.option,
        value: this.props.filterOptions.value,
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.filterOptions.option !== prevProps.filterOptions.option ||
            this.props.filterOptions.value !== prevProps.filterOptions.value
        ) {
            this.setState({
                option: this.props.filterOptions.option,
                value: this.props.filterOptions.value,
            })
        }
    }

    debounceUpdate = debounce((options) => this.props.onChange(options), 300)

    invalidFilter = () => {
        // To show in place of a row where the existing filter is invalid.
        // Allows the invalid filter to be deleted.
        return (
            <div
                className="display-flex"
                style={{ minWidth: 600, marginTop: 10, alignItems: 'center' }}
            >
                <FilterItem>
                    <Text variant="adminFieldLabel">(Invalid filter)</Text>
                </FilterItem>
                <FilterButtomWrapper>
                    <Button onClick={this.props.delete} variant="adminSecondary" buttonSize="sm">
                        Delete
                    </Button>
                </FilterButtomWrapper>
            </div>
        )
    }

    render() {
        const { field } = this.props
        if (!field) return this.invalidFilter()
        const options = getFilterOptionsForType(
            field,
            this.props.hideCurrentUserOption,
            this.props.userObject,
            [],
            this.props.showRelativeDateFilters ?? false,
            this.props.contextRecordObject
        )
        if (!options) {
            Sentry.withScope((scope) => {
                scope.setExtra('field', field)
                scope.setLevel('error')
                Sentry.captureMessage(`Filter type ${field.type} doesn't exist`)
            })
            return ''
        }
        const selectedOption = this.props.filterOptions.option
        const FilterComponent = selectedOption ? nextFilterOptions[selectedOption] : null
        return (
            <FilterWrapper>
                <FilterItem>
                    {this.props.showLogicalOperator ? (
                        <Text variant="menuText" paddingRight={2}>
                            and
                        </Text>
                    ) : null}
                    <Text variant="menuText" fontWeight="bold">
                        {this.props.field.type === 'user_role' && <Icon icon="user-lock" />}
                        {this.props.field.label}
                    </Text>
                </FilterItem>
                <FilterItem>
                    {options.length === -1 ? (
                        <Dropdown
                            options={options.map((value) => ({
                                label: labelMap[value] || value,
                                value,
                            }))}
                            value={options[0]}
                            disabled
                            style={{ flex: 1 }}
                        />
                    ) : (
                        <Dropdown
                            options={options.map((value) => ({
                                label: labelMap[value] || value,
                                value,
                            }))}
                            value={this.state.option}
                            onChange={(option: {
                                label: string
                                value: keyof typeof nextFilterOptions
                            }) => {
                                this.setState({ option })
                                this.debounceUpdate({
                                    id: this.props.id,
                                    options: { ...this.props.filterOptions, option },
                                })
                            }}
                            style={{ flex: 1 }}
                        />
                    )}
                </FilterItem>
                {FilterComponent && (
                    <FilterItem maxWidth={'100%'}>
                        <FilterComponent
                            contextRecordObject={this.props.contextRecordObject}
                            roles={this.props.roles}
                            field={this.props.field}
                            value={this.state.value}
                            userObject={this.props.userObject}
                            onChange={(value) => {
                                this.setState({ value: value })
                                this.debounceUpdate({
                                    id: this.props.id,
                                    options: { ...this.props.filterOptions, value },
                                })
                            }}
                        />
                    </FilterItem>
                )}
                <FilterItem>
                    <Button onClick={this.props.delete} variant="adminSecondary" buttonSize="sm">
                        Delete
                    </Button>
                </FilterItem>
            </FilterWrapper>
        )
    }
}

export const FieldFilter: FC<
    Omit<Props, 'userObject'> & {
        userObjectId: string
    }
> = (props) => {
    const { object } = useObject(props.userObjectId)
    return (
        <FieldFilterInner userObject={object as ObjectDto} {...props} className="small-filters" />
    )
}

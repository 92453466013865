import React, { useContext } from 'react'

import { LayoutEditorSchema, Widget } from './types'

export type LayoutEditorWidgetAreaContextValue = {
    // The parent widget area IDs.
    path: string[]
    children: Widget[]
    editorSchema: LayoutEditorSchema
    isEditing: boolean
}
export const LayoutEditorWidgetAreaContext =
    React.createContext<LayoutEditorWidgetAreaContextValue | null>(null)

export function useLayoutEditorWidgetAreaContext(): LayoutEditorWidgetAreaContextValue | null {
    return useContext(LayoutEditorWidgetAreaContext)
}

import React, { useMemo } from 'react'

import { useWorkspaceUsers } from 'data/hooks/users/main'

import { ItemProvider } from 'ui/components/Combobox/useComboboxProviders'

import { UserSearchResult } from './UserSearchResult'
import { UserSearchItem } from './userSearchTypes'

type useUserSearchProviderProps = {
    showOnEmptySearch?: boolean
    showGroupTitle?: boolean
    users?: UserDto[]
}
function toUserSearchItem(user: UserDto): UserSearchItem {
    return {
        ...user,
        label: user.name || user.email,
        avatar: user.avatar,
    }
}

function getUserList(users: UserDto[]) {
    return users?.map(toUserSearchItem).sort((a, b) => a.label.localeCompare(b.label)) ?? []
}

export function searchUsers(users: UserDto[], searchText?: string, showOnEmptySearch?: boolean) {
    return getUserList(users).filter(
        (u) =>
            (!!searchText || showOnEmptySearch) &&
            u.label?.toLowerCase()?.includes(searchText || '')
    )
}
export const useUserSearchProvider = ({
    showOnEmptySearch,
    showGroupTitle,
    users: suppliedUsers,
}: useUserSearchProviderProps): ItemProvider<UserSearchItem> => {
    const { data: users } = useWorkspaceUsers()
    const usersList = suppliedUsers || users
    return useMemo(
        () =>
            ({
                id: 'users',
                title: showGroupTitle ? 'Workspace Users' : undefined,
                initialItems: showOnEmptySearch ? getUserList(usersList) : [],
                getItems({ query }) {
                    const searchText = query?.toLowerCase()
                    return Promise.resolve({
                        items: searchUsers(usersList, searchText, showOnEmptySearch),
                    })
                },
                renderItem({ item, queryTerms }) {
                    return <UserSearchResult item={item} queryTerms={queryTerms} />
                },
            }) as ItemProvider<UserSearchItem>,
        [showGroupTitle, showOnEmptySearch, usersList]
    )
}

import { useMemo } from 'react'

import { AttachmentValue } from 'features/views/attributes/types'
import { CardViewAttribute } from 'features/views/ListView/CardView/types'
import { useCardViewContext } from 'features/views/ListView/CardView/useCardViewContext'

import { ResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

type UseAttachmentsAttributeStateOptions = {
    attribute: CardViewAttribute
    value?: AttachmentValue[]
    isLoading?: boolean
}

const titleSizeMapping = {
    small: { mobile: 'm', tablet: 's' },
    medium: 'm',
    large: 'm',
}

export function useAttachmentsAttributeState(options: UseAttachmentsAttributeStateOptions) {
    const { value, isLoading, attribute } = options
    const { rawOptions, type } = attribute

    const isFooter = type === 'footer'

    const isEmpty = !isLoading && (!value || value.length < 1)

    const isSingle = !Array.isArray(value) || value.length === 1

    const displayAsImage = rawOptions.displayAsImage as boolean
    const displayAsTag = isFooter && !displayAsImage

    const { titleSize } = useCardViewContext()
    const size = useMemo(() => {
        switch (type) {
            case 'title':
                return titleSizeMapping[titleSize || 'medium'] as ResponsiveValue<'s' | 'm'>
            case 'eyebrow':
                return 's'
            default:
                return displayAsImage ? 's' : 'm'
        }
    }, [type, titleSize, displayAsImage])

    return useMemo(
        () => ({
            isEmpty,
            isSingle,
            displayAsTag,
            displayAsImage,
            size,
        }),
        [displayAsImage, displayAsTag, isEmpty, isSingle, size]
    )
}

import React from 'react'

import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { ExecutionHistoryStatusIcon } from './ExecutionHistory/ExecutionHistoryStatusIcon'

type WorkflowLastExecutionProps = React.ComponentPropsWithoutRef<typeof Button> & {
    workflow: WorkflowDto
}

export const WorkflowLastExecution: React.FC<WorkflowLastExecutionProps> = ({
    workflow,
    ...props
}) => {
    const lastExecution = workflow.latest_execution

    const startedAtDisplay = useDisplayTimeFrom(lastExecution?.started_at ?? 0)

    if (!lastExecution) return null

    return (
        <Button flex center gap="s" size="s" variant="link" {...props}>
            <ExecutionHistoryStatusIcon status={lastExecution.status} />
            <Box as="span">{startedAtDisplay}</Box>
        </Button>
    )
}

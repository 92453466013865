import React, { useEffect, useState } from 'react'

import { useToast as useChakraToast } from '@chakra-ui/react'

import Alert from './Alert'

// Creates an element that uses the hook so that we can
// use this in class components too
export const Toast = ({
    show = false,
    status = 'success',
    onCloseComplete,
    isClosable = false,
    variant = 'solid',
    ...props
}) => {
    const toast = useChakraToast()
    const [showToast, setShowToast] = useState(show)

    useEffect(() => {
        setShowToast(show)
    }, [show])

    useEffect(() => {
        if (showToast) {
            toast({
                status: 'success',
                render: ({ onClose }) => (
                    <Alert
                        variant={variant}
                        onClose={onClose}
                        isClosable={isClosable}
                        {...props}
                        status={status}
                    />
                ),
                duration: 2000,
                ...props,
            })

            if (onCloseComplete) onCloseComplete()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showToast])

    return null
}

import React from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import ReactTooltip from 'react-tooltip'

import { css } from 'emotion'

import { Icon, Label, Toggle } from 'legacy/v1/ui'

const SortableItem = SortableElement(
    ({ item, toggle, checked, ItemWrapper, isHome, isInSettingsModal }) => (
        <ItemWrapper checked={checked}>
            {!isInSettingsModal ? (
                <>
                    <Handle />
                    <Toggle small onChange={toggle} value={checked} />
                </>
            ) : null}
            {item}

            {isHome && (
                <>
                    <div
                        data-tip
                        data-for="home-item"
                        style={{ marginTop: '15px', float: 'right', color: 'gray' }}
                    >
                        <Icon icon="home" size={48} />
                    </div>

                    <ReactTooltip id="home-item" effect="solid">
                        <Label style={{ color: 'white' }}>
                            The first item in your navigation menu will be your homepage, even if it
                            isn&apos;t enabled.
                        </Label>
                    </ReactTooltip>
                </>
            )}
        </ItemWrapper>
    )
)
const Handle = SortableHandle(() => (
    <Icon icon="grip-vertical" style={{ cursor: 'pointer', color: '#c1cee2' }} />
))
export const SortableList = SortableContainer(
    ({
        items,
        ItemWrapper,
        itemToId,
        renderItem,
        toggle,
        isSelected,
        isMenu,
        isInSettingsModal,
    }) => {
        return (
            <div
                className={css`
                    scrollbar-width: thin;
                    scrollbar-height: thin;
                    scrollbar-color: rgba(0, 0, 0, 0.5);
                    ::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                    }
                    ::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    ::-webkit-scrollbar-thumb {
                        background-color: rgba(0, 0, 0, 0.5);
                        border-radius: 20px;
                    }
                `}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden scroll',
                    paddingBottom: '5px',
                }}
            >
                {items.map((item, index) => {
                    // For settings modal.
                    const checked = isSelected(item)
                    // For settings modal.
                    const _toggle = (
                        <Toggle
                            small
                            theme={{
                                toggleBackgroundColor: isInSettingsModal ? '#465DD8' : 'initial',
                            }}
                            onChange={() => toggle(index, !item.isSelected)}
                            value={checked}
                        />
                    )

                    return (
                        <SortableItem
                            key={itemToId(item)}
                            index={index}
                            item={renderItem(item, _toggle)}
                            toggle={() => toggle(index, !item.isSelected)}
                            checked={checked}
                            ItemWrapper={ItemWrapper}
                            isHome={isMenu && items.indexOf(item) === 0}
                            isInSettingsModal={isInSettingsModal}
                        />
                    )
                })}
            </div>
        )
    }
)

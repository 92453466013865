import React from 'react'

import { Thumbnail } from 'features/views/attributes/Thumbnail'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'

import { useCardViewProfileImageState } from './hooks/useCardViewProfileImageState'

import { CardProfileImageStyles, CardViewProfileBoxStyles } from './CardView.css'

type CardViewProfileImageProps = React.ComponentPropsWithoutRef<typeof Box> & {
    record: RecordDto
    isLoading?: boolean
    hasCoverImage?: boolean
}

export const CardViewProfileImage: React.FC<CardViewProfileImageProps> = ({
    record,
    isLoading,
    hasCoverImage,
    ...props
}) => {
    const { hasThumbnail, imageSrc } = useCardViewProfileImageState({ record })

    return (
        <Box
            className={CardViewProfileBoxStyles.styleFunction({ hasCoverImage })}
            noShrink
            {...props}
        >
            <Skeleton
                isLoading={isLoading}
                className={CardProfileImageStyles.styleFunction({ hasCoverImage })}
                display="block"
            >
                <Thumbnail
                    src={hasThumbnail ? imageSrc : undefined}
                    size="manual"
                    alt="Record profile image"
                    className={CardProfileImageStyles.styleFunction({ hasCoverImage })}
                />
            </Skeleton>
        </Box>
    )
}

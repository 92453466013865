// @ts-strict-ignore
import { keyframes } from '@emotion/react'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'

import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'

import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

/**
 * list of sidebar items
 * @type {{page: WorkspaceSettingsPage, name: string, icon: string, Component?: React.ReactElement, disabled?: (stack: any, workspaceAccount: any) => boolean }[]}
 */
export const appSettingsMenuItems = [
    {
        name: 'Navigation',
        page: 'navigation',
        icon: 'list',
    },
    {
        name: 'Permissions',
        page: 'permissions',
        icon: 'lock',
        class: ONBOARDING_CLASSES.APP_SETTINGS_PERMISSIONS_MENU_ITEM,
    },
    {
        name: 'General',
        page: 'general',
        icon: 'cog',
    },
    {
        name: 'Roles',
        page: 'roles',
        icon: 'userLock',
    },
    {
        name: 'Appearance',
        page: 'appearance',
        icon: 'brush',
        // only show appearance option if feature flag is enabled (we want to hide this section by default for now but
        // this feature flag can be used to show it to allow us to access setting custom css feature)
        disabled: (_stack, _workspaceAccount, ldflags) => {
            return ldflags?.showAppearanceSettings === false
        },
    },
]
export const BORDER_COLOR = V4DesignSystem.colors.gray[100]
export const fadeInAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`
export const slideInLeftAnimation = keyframes`
    0% {
        transform: translateX(-250px)
    }
    100% {
        transform: translateX(0px)
    }
`
type SecondaryMenuItems = {
    name: string
    section: string
    icon: string
    shouldShow?: (params: { object: ObjectDto; ldFlags?: LDFlagSet }) => boolean
}
/**
 * list of sidebar items
 */
const dataSourcesSecondaryMenuItems: SecondaryMenuItems[] = [
    {
        name: 'Data',
        section: 'data',
        icon: 'table',
        shouldShow: ({ ldFlags }) => ldFlags?.dataGridEnabled,
    },
    {
        name: 'Fields',
        section: 'fields',
        icon: 'table',
    },
    {
        name: 'Settings',
        section: 'settings',
        icon: 'cog',
        shouldShow: ({ object }) => !getIsStackerUserObject(object),
    },
    {
        name: 'Permissions',
        section: 'permissions',
        icon: 'lock',
    },
    {
        name: 'Layouts',
        section: 'layouts',
        icon: 'columns',
    },
    {
        name: 'Actions',
        section: 'actions',
        icon: 'bolt',
    },
]
export const getAvailableSecondarySources = (
    object: ObjectDto,
    ldFlags?: LDFlagSet
): SecondaryMenuItems[] => {
    return dataSourcesSecondaryMenuItems.filter((item) =>
        item.shouldShow ? item.shouldShow({ object, ldFlags }) : true
    )
}

export const cleanDataSourcesMenuState = (
    state: { object?: any; dataConnection?: any; section?: any } = {},
    ldFlags?: LDFlagSet
) => ({
    selectedDataConnection: state.dataConnection,
    selectedObject: state.object,
    secondaryMenuSectionState: {
        selected: state.section,
        available: getAvailableSecondarySources(state.object, ldFlags),
    },
})

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { useObjects } from 'data/hooks/objects'
import { useCreateView } from 'data/hooks/views'

type TableOption = {
    label: string
    value: string
}

export function useNavigationCreateViewModalState() {
    const [isOpen, setIsOpen] = useState(false)
    const viewRef = useRef<Partial<ViewDto>>({})

    const createNewLabel = formatCreateNewLabel(viewRef.current.options?.display)

    const { data: objects = [] } = useObjects()
    const tables = useMemo(() => {
        return objects.reduce((agg, curr) => {
            if (!curr.connection_options?.is_hidden) {
                agg.push({ label: curr.name, value: curr._sid })
            }

            return agg
        }, [] as TableOption[])
    }, [objects])

    const hasNoTables = tables.length < 1

    const [table, setTable] = useState('')
    const selectedTable = tables.find((t) => t.value === table)

    useEffect(() => {
        if (!selectedTable) return

        const viewName = selectedTable.label

        const prevView = viewRef.current
        viewRef.current = {
            ...prevView,
            name: viewName,
            label: viewName,
            title: viewName,
            object_id: selectedTable.value,
            options: {
                ...prevView.options,
                title: viewName,
            } as unknown as ViewDto['options'],
        }
    }, [selectedTable])

    // Clear the table when the modal is closed.
    useEffect(() => {
        if (!isOpen) {
            setTable('')
        }
    }, [isOpen])

    const isSubmitDisabled = table.length < 1

    const open = useCallback(
        (display: ListViewDisplay) => {
            viewRef.current = {
                type: 'list',
                options: {
                    display,
                    columns: [],
                    blocks: [],
                    showAllFields: true,
                    defaultFieldsSelected: true,
                } as unknown as ViewDto['options'],
            }
            setIsOpen(true)
        },
        [setIsOpen]
    )

    const close = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    const history = useHistory()
    const { mutateAsync: createNewView } = useCreateView()
    const createView = useCallback(async () => {
        const newView = (await createNewView(viewRef.current)) as ViewDto

        close()
        history.push(getUrl(newView.url))
    }, [createNewView, history, close])

    const handleKeyDown = useCallback(
        async (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (isSubmitDisabled) return

            if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
                await createView()
                close()
            }
        },
        [isSubmitDisabled, createView, close]
    )

    return useMemo(
        () => ({
            isOpen,
            setIsOpen,
            open,
            close,
            createNewLabel,
            tables,
            hasNoTables,
            createView,
            table,
            setTable,
            isSubmitDisabled,
            handleKeyDown,
        }),
        [
            isOpen,
            open,
            close,
            createNewLabel,
            tables,
            hasNoTables,
            createView,
            table,
            setTable,
            isSubmitDisabled,
            handleKeyDown,
        ]
    )
}

function formatCreateNewLabel(display?: ListViewDisplay) {
    let formattedDisplay: string = display ?? ''
    switch (display) {
        case 'tableV2':
            formattedDisplay = 'Table view'
            break
        case 'boardV2':
            formattedDisplay = 'Board view'
            break
        case 'cardV2':
            formattedDisplay = 'Card view'
            break
    }

    return `Create new ${formattedDisplay}`
}

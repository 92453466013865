import { useEffect, useRef, VFC } from 'react'

import { useTrackEvent } from 'utils/useTrackEvent'

import { useWorkspaceContext } from './WorkspaceContext'

const INTERVAL_MS = 60000

const EVENTS_TO_TRACK: (keyof DocumentEventMap)[] = [
    'keydown',
    'mousemove',
    'click',
    'dblclick',
    'touchmove',
    'touchstart',
    'touchend',
]

const UserActivityTracker: VFC = () => {
    const isUserActiveRef = useRef(false)
    const trackEvent = useTrackEvent()
    const { workspaceUser: user } = useWorkspaceContext()

    useEffect(() => {
        const onUserAction = () => {
            isUserActiveRef.current = true
        }

        EVENTS_TO_TRACK.forEach((eventName) => document.addEventListener(eventName, onUserAction))

        const intervalId = setInterval(() => {
            if (!isUserActiveRef.current) {
                return
            }

            // Reset as soon as possible the ref to prevent sending wrong user activity
            // in case a call takes longer than INTERVAL_MS
            isUserActiveRef.current = false

            // Nothing to tracker if no user is authenticated
            if (!user) {
                return
            }

            trackEvent('frontend_user_active')
        }, INTERVAL_MS)

        return () => {
            clearInterval(intervalId)

            EVENTS_TO_TRACK.forEach((eventName) =>
                document.removeEventListener(eventName, onUserAction)
            )
        }
    }, [trackEvent, user])

    return null
}

export default UserActivityTracker

import React, { useCallback } from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { EndActionComponent } from './types'

import { InputEndActionNumberStyle } from './Input.css'

type InputEndActionNumberProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const InputEndActionNumber: EndActionComponent<InputEndActionNumberProps> = ({
    disabled,
    readOnly,
    className,
    inputType,
    inputElement,
    ...props
}) => {
    const isCompatible = inputType === 'number'
    const isDisabled = disabled || readOnly || !isCompatible

    const increment = useCallback(() => {
        inputElement?.stepUp()
        // Dispatch input event to trigger input onChange event.
        inputElement?.dispatchEvent(new Event('input', { bubbles: true }))
    }, [inputElement])

    const decrement = useCallback(() => {
        inputElement?.stepDown()
        // Dispatch input event to trigger input onChange event.
        inputElement?.dispatchEvent(new Event('input', { bubbles: true }))
    }, [inputElement])

    return (
        <Box className={classNames(InputEndActionNumberStyle, className)} {...props}>
            <Button
                size="xs"
                variant="ghost"
                aria-label="Decrement"
                startIcon={{ name: 'Minus' }}
                disabled={isDisabled}
                onClick={decrement}
            />
            <Button
                size="xs"
                variant="ghost"
                aria-label="Increment"
                startIcon={{ name: 'Plus' }}
                disabled={isDisabled}
                onClick={increment}
            />
        </Box>
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { DatePicker } from 'ui/components/DatePicker'
import { DropdownSub, DropdownSubContent, DropdownSubTrigger } from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useActionMenuEditDateState } from './hooks/useActionMenuEditDateState'
import { ActionMenuEditComponent } from './types'

import { ActionMenuEditDateStyle } from './ActionMenuEditDate.css'

type ActionMenuEditDateProps = {}

export const ActionMenuEditDate: ActionMenuEditComponent<string, ActionMenuEditDateProps> =
    React.memo(function ActionMenuEditDate(props) {
        const { value, fieldLabel, onEditValue, showTime, fieldIcon, onClear } =
            useActionMenuEditDateState(props)

        const isDateTime = props.field.type === 'datetime'

        return (
            <DropdownSub>
                <DropdownSubTrigger
                    startIcon={fieldIcon?.type === 'lucide' ? { name: fieldIcon.name } : undefined}
                    label={fieldLabel}
                />
                <DropdownSubContent
                    className={ActionMenuEditDateStyle}
                    style={{ minWidth: 0, maxHeight: 'auto', padding: 0 }}
                    onClick={stopPropagation}
                >
                    <DatePicker showTime={showTime} value={value} onChange={onEditValue} />
                    {!!value && (
                        <Box
                            flex
                            center
                            noShrink
                            px="xl"
                            pt={isDateTime ? 'l' : 0}
                            style={{
                                marginTop: '-5px',
                            }}
                            borderTopWidth={isDateTime ? 1 : 0}
                            borderWidth={0}
                            borderColor="borderWeak"
                            borderStyle="base"
                            pb="l"
                        >
                            <Button variant="secondary" size="s" width="full" onClick={onClear}>
                                Clear
                            </Button>
                        </Box>
                    )}
                </DropdownSubContent>
            </DropdownSub>
        )
    })

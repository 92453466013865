import { Moment } from 'moment-timezone'

export const getBucketLabel = (date: Moment, bucketBy: Bucket) => {
    switch (bucketBy) {
        case 'day':
        case 'week':
            return date.format('MMM D YYYY')
        case 'month':
            return date.format('MMM YYYY')
        case 'year':
            return date.format('YYYY')
        case 'quarter':
            return `Q${date.quarter()} ${date.format('YYYY')}`
        default:
            return date.toISOString()
    }
}

import React from 'react'

import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

type ObjectOptionsProps = {
    object: ObjectDto
}
export const ObjectOptions: React.FC<ObjectOptionsProps> = ({ object }) => {
    const { showManageData } = useSlidingPane()

    return (
        <Box
            style={{
                paddingRight: theme.space['6xl'],
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Dropdown>
                <DropdownButton
                    size="s"
                    aria-label="More actions"
                    variant="ghost"
                    startIcon={{ name: 'MoreHorizontal' }}
                />
                <DropdownContent
                    onClick={stopPropagation}
                    align="end"
                    style={{ minWidth: '250px' }}
                >
                    <DropdownItem
                        startIcon={{ name: 'Table' }}
                        label="Manage Table"
                        onClick={() =>
                            showManageData({
                                objectId: object._sid,
                            })
                        }
                    />
                    {/* TODO-Nango implement "delete object" - from discussions so far we want this to be the same as
                            unselecting the object for import and re-running schema sync*/}
                    {/*<DropdownSeparator />*/}
                    {/*<DropdownItem*/}
                    {/*    variant="destructive"*/}
                    {/*    startIcon={{ name: 'Trash' }}*/}
                    {/*    label="Remove"*/}
                    {/*    onClick={() => console.log('Remove object called')}*/}
                    {/*/>*/}
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}

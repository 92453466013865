import React, { FC, useEffect, useRef } from 'react'

import { ButtonProps, useDisclosure } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAppContext } from 'app/useAppContext'
import { useObject } from 'data/hooks/objects'
import { getFilterFieldFromId } from 'features/utils/filterToField'

import { Button, Dropdown, Icon, Text } from 'v2/ui'

import { useRecordFilters } from './provider/useRecordFilters'
import { getFilterOptionsForType, getFilterSourceOptions } from './utils/utils'

const Separator = styled.div`
    height: 8px;
`

type Props = ButtonProps & {
    object: ObjectDto | undefined
    fields: FieldDto[]
    isCreate?: boolean
    hideCurrentUserOption?: boolean
    showRelativeDateFilters?: boolean
    hideTheRecordFilter?: boolean
    showRoleFilter?: boolean
    usePortal?: boolean
    addButtonVariant?: string
    label?: string
    contextRecordObject?: ObjectDto
    customRender?: (props: { onClick: React.MouseEventHandler }) => React.ReactNode
}

export const AddFilterButton: FC<Props> = ({
    object,
    fields,
    hideCurrentUserOption,
    showRelativeDateFilters = false,
    contextRecordObject,
    hideTheRecordFilter,
    showRoleFilter,
    addButtonVariant,
    label = 'Add Filter',
    usePortal,
    customRender,
    ...props
}) => {
    const {
        state: { filters },
        onAddFilter,
    } = useRecordFilters()
    const { selectedStack } = useAppContext()

    // @ts-expect-error
    const dropdownRef = useRef<Dropdown>()
    const { object: userObject } = useObject(selectedStack?.options?.data_mapping?.user_object)
    const { isOpen, onToggle, onClose } = useDisclosure()
    const options = getFilterSourceOptions(fields, {
        hideTheRecordFilter: Boolean(hideTheRecordFilter),
        showRoleFilter: !!showRoleFilter,
    })

    const handleSelectFilter = (value: (typeof options)[0]) => {
        // Reuse this in InlineFilterItem?
        // @ts-expect-error
        const field = getFilterFieldFromId(object, value)
        const options = getFilterOptionsForType(
            field,
            Boolean(hideCurrentUserOption),
            userObject as ObjectDto,
            [],
            showRelativeDateFilters,
            contextRecordObject
        )

        onAddFilter({
            field,
            field_sid: field?._sid,
            options: { operator: 'AND', option: options?.[0] },
            isNew: true,
        })
        onClose()
    }

    useEffect(() => {
        if (isOpen) {
            dropdownRef.current?.setState({ menuIsOpen: true })
            dropdownRef.current?.select.focus()
        }
    }, [isOpen])

    if (!isOpen) {
        return (
            <>
                {filters.length > 0 && <Separator />}
                {customRender ? (
                    customRender({ onClick: onToggle })
                ) : (
                    <Button
                        icon="add"
                        variant={addButtonVariant}
                        buttonSize="unset"
                        onClick={onToggle}
                        py={1}
                        px={2}
                        color="gray.400"
                        fontSize="sm"
                        w="100%"
                        {...props}
                    >
                        {label}
                    </Button>
                )}
            </>
        )
    }

    return (
        <>
            {filters.length > 0 && <Separator />}
            <Dropdown
                options={options}
                ref={dropdownRef}
                onChange={handleSelectFilter}
                placeholder={
                    <Text color="gray.400">
                        <Icon icon="search" size="xs" display="inline" mr={1} color="gray.400" />
                        Search
                    </Text>
                }
                onBlur={() => {
                    onClose()
                }}
                variant="settings"
                usePortal={usePortal}
            />
        </>
    )
}

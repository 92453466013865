import React from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { assertIsDefined } from 'data/utils/ts_utils'
import { FlowLine } from 'features/workflows/FlowLine'
import { ItemIcon } from 'features/workflows/ItemIcon'
import { WorkflowChain } from 'features/workflows/WorkflowChain'
import { WorkflowItemWrapper } from 'features/workflows/WorkflowItemWrapper'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { Icon } from 'ui/components/Icon'

import { getIterableValueLabel } from './utils'

type WorkflowLoopProps = {
    node: WorkflowLoopConfig
    showType?: boolean
}

export function WorkflowLoopNode({ node }: WorkflowLoopProps) {
    return (
        <Box noShrink>
            <WorkflowItemWrapper item={node} flex center>
                <WorkflowLoop node={node} />
            </WorkflowItemWrapper>
            <Box height="l" position="relative" flex center justifyContent="center">
                <Box
                    position="absolute"
                    width="3xs"
                    height="full"
                    borderColor="border"
                    borderStyle="dashed"
                    borderWidth={1}
                    style={{
                        margin: 'auto',
                        top: 0,
                        bottom: 0,
                    }}
                />
            </Box>
            <WorkflowLoopChain node={node} />
        </Box>
    )
}

export const WorkflowLoop: React.FC<WorkflowLoopProps> = ({ node, showType }) => {
    const { data: schema } = useWorkflowSchema()

    const nodeType = schema?.nodes?.find((node) => node.id === node.kind)
    assertIsDefined(nodeType)

    const iterableValueLabel = node.iterable_value
        ? getIterableValueLabel(node.iterable_value)
        : null

    const label = iterableValueLabel ? 'Loop over' : nodeType.name

    return (
        <>
            <ItemIcon icon={nodeType.icon!} iconStyle={nodeType.icon_style} mr="m" />

            <Box flex column alignItems="flex-start" fontWeight="bodyRegular">
                {showType && (
                    <Box fontSize="bodyXs" color="textWeakest">
                        Loop
                    </Box>
                )}
                <Box flex center>
                    {label}
                    {iterableValueLabel && (
                        <Container
                            as="span"
                            ml="s"
                            px="xs"
                            py="2xs"
                            borderRadius="s"
                            variant="accentMuted"
                        >
                            {iterableValueLabel}
                        </Container>
                    )}
                </Box>
            </Box>
        </>
    )
}

type WorkflowLoopChainProps = React.ComponentPropsWithoutRef<typeof Box> & {
    node: WorkflowLoopConfig
}

const WorkflowLoopChain: React.FC<WorkflowLoopChainProps> = ({ node, ...props }) => {
    const items = node.chain ?? []

    return (
        <Box
            borderWidth={2}
            borderColor="border"
            borderStyle="dashed"
            position="relative"
            px="m"
            pb="m"
            pt="l"
            borderRadius="l"
            mx="m"
            {...props}
        >
            <Box
                flex
                center
                justifyContent="center"
                position="absolute"
                width="full"
                style={{ zIndex: 1, top: '-8px', left: 0, right: 0 }}
            >
                <Box
                    p="2xs"
                    background="surfaceStrong"
                    borderColor="textWeakest"
                    borderRadius="s"
                    borderWidth={1}
                >
                    <Icon name="RotateCw" color="textWeak" size="xs" />
                </Box>
            </Box>
            <WorkflowChain items={items} showFinishedNode={false} parentId={node.id} compact={true}>
                {items.length < 1 && (
                    <Box flex center justifyContent="center">
                        <FlowLine
                            line={{
                                id: `${node.id}->placeholder`,
                                fromItemId: node.id,
                            }}
                            showArrows={false}
                        />
                    </Box>
                )}
            </WorkflowChain>
        </Box>
    )
}

import React from 'react'

import { ThemeProvider } from 'emotion-theming'

import { WithBlocks } from 'data/wrappers/WithBlocks'
import smallDarkTheme from 'features/pages/editor/ui/components/smallDarkTheme'

export class BlocksList extends React.Component {
    render() {
        return (
            <WithBlocks>
                {() => {
                    return (
                        <ThemeProvider theme={smallDarkTheme}>
                            <>{this.props.children({ blocks: [] })}</>
                        </ThemeProvider>
                    )
                }}
            </WithBlocks>
        )
    }
}

import React, { useMemo } from 'react'

import { ContextGroup, ContextSchema } from 'features/expressions/types'
import { matchesSearchTerms } from 'features/Search/helpers'

import { ItemProvider } from 'ui/components/Combobox/useComboboxProviders'

import { RenderContextGroup } from './RenderContextGroup'

type ContextGroupCommandOptions = {
    schema: ContextSchema
    renderGroupTitle?: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
}

export const useContextGroupsProvider = ({
    schema,
    renderGroupTitle,
}: ContextGroupCommandOptions): ItemProvider<ContextGroup> => {
    return useMemo(() => {
        return {
            id: 'context_groups',
            initialItems: schema,
            getItems({ queryTerms }) {
                return Promise.resolve({
                    items: schema.filter((item) => matchesSearchTerms(item.name, queryTerms)),
                })
            },
            renderItem({ item, queryTerms }) {
                return (
                    <RenderContextGroup
                        item={item as ContextGroup}
                        queryTerms={queryTerms}
                        renderItem={renderGroupTitle}
                    />
                )
            },
        } as ItemProvider<ContextGroup>
    }, [renderGroupTitle, schema])
}

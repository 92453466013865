import React from 'react'

import { useViewFromId } from 'data/hooks/views'
import {
    TrayButtonBase,
    TrayButtonBaseProps,
} from 'features/workspace/AdminSideTray/common/TrayButtonBase'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import useTrack from 'utils/useTrack'

type DataGridButtonProps = TrayButtonBaseProps & {}

export const DataGridButton: React.VFC<DataGridButtonProps> = ({}) => {
    const { track } = useTrack()

    const { state, showManageData, close } = useSlidingPane()

    const contextView = useViewFromId(state.contextInfo?.viewId ?? '')

    const handleClick = () => {
        track('WIP - Frontend - Admin Side Tray - Data Grid - Clicked')
        if (state.key !== 'data-grid') {
            // Older blank page views have objects associated with them.
            // Trying to open the data grid with one those objects
            // would result in an empty grid.
            let objectId: string | undefined = state.contextInfo?.objectId
            if (contextView?.type === 'blankpage') {
                objectId = undefined
            }

            showManageData({ objectId })
        } else {
            close()
        }
    }

    return (
        <TrayButtonBase
            icon="table"
            tooltip="Manage fields and data"
            onClick={handleClick}
            isActive={state.key === 'data-grid'}
            data-testid="admin-side-tray.data-grid-button"
        />
    )
}

import React from 'react'

import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'

import { useNumberAttributeDisplayState } from './hooks/useNumberAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type NumberAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    isLoading?: boolean
    showIcon?: boolean
}

export const NumberAttributeTag: AttributeDisplayComponent<number, NumberAttributeTagProps> =
    React.memo(function NumberAttributeTag({
        field,
        value,
        isLoading = false,
        style,
        showIcon,
        ...props
    }) {
        const { content, icon } = useNumberAttributeDisplayState({
            field,
            value,
            isLoading,
            showIcon,
        })

        return (
            <Skeleton isLoading={isLoading}>
                <Tag
                    size="2xs"
                    type="outline"
                    weight="regular"
                    style={{ wordBreak: 'break-word', ...style }}
                    pointerEvents="none"
                    noShrink
                    startIcon={icon}
                    {...props}
                >
                    {content}
                </Tag>
            </Skeleton>
        )
    })

import React from 'react'

import classNames from 'classnames'

import { Icon as V2Icon } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { useAppIconState } from './hooks/useAppIconState'
import { NavigationApp } from './types'

import { AppIconStyles } from './Navigation.css'

type AppIconProps = React.ComponentPropsWithoutRef<typeof Box> & {
    app: NavigationApp
    isActive?: boolean
}

export const AppIcon: React.FC<AppIconProps> = ({ className, app, isActive, ...props }) => {
    const { initial } = useAppIconState({ app })

    return (
        <Box
            {...props}
            className={classNames(
                className,
                AppIconStyles.styleFunction({ colorScheme: app.colorScheme, isActive })
            )}
        >
            {app.icon?.family === 'fa' && (
                <V2Icon icon={app.icon.name} color="inherit" fontSize={theme.icon.size.xs} />
            )}
            {!app.icon && initial && (
                <Body size="m" weight="bold" style={{ color: 'inherit' }}>
                    {initial}
                </Body>
            )}
        </Box>
    )
}

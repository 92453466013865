import React, { Component } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { datadogRum } from '@datadog/browser-rum'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import PropTypes from 'prop-types'
import { SuperTokensWrapper } from 'supertokens-auth-react'

import { _ScrollToTop, ScrollToTop } from 'app/_ScrollToTop'
import { AccountUserContextProvider } from 'app/AccountUserContextProvider'
import { AppContextProvider } from 'app/AppContextProvider'
import { AppUserContextProvider } from 'app/AppUserContextProvider'
import { CustomCss } from 'app/CustomCss'
import { CustomScripts } from 'app/CustomScripts'
import { catchAppErrors } from 'app/errorBoundaryFunctions'
import { getCurrentStackId } from 'app/GlobalStaticState'
import MetadataLoading from 'app/MetadataLoading'
import PreserveQuery from 'app/PreserveQuery'
import settings, { DD_RUM_APP_ID, DD_RUM_TOKEN, GOOGLE_OAUTH_CLIENT_ID } from 'app/settings'
import UserMonitoring from 'app/UserMonitoring'
import { WorkspaceDomainRedirector } from 'app/WorkspaceDomainRedirector'
import queryClient from 'data/reactQueryCache'
import SessionExpiredModal from 'features/auth/stacker-auth/SessionExpiredModal'
import { initializeSupertokens } from 'features/auth/supertokens'
import { PreviewAsModal } from 'features/core/PreviewingBar'
import { LoadActionObserver } from 'features/loadActions'
import { NavigationContextProvider } from 'features/navigation/NavigationContextProvider'
import {
    NotificationPane,
    NotificationPaneContextProvider,
} from 'features/Notifications/NotificationPane'
import { Omnibar } from 'features/Omnibar/Omnibar'
import { PreviewServiceContextProvider } from 'features/PreviewService/PreviewServiceContextProvider'
import ProfileSettingsModal from 'features/profile/ProfileSettingsModal'
import { AlgoliaInsights } from 'features/Search/InsightsEvents'
import { LayoutEditorContextProvider as SlowLayoutEditorContextProvider } from 'features/utils/LayoutEditorContext'
import { NavContextProvider } from 'features/utils/NavContext'
import { LayoutEditorContextProvider } from 'features/views/LayoutEditor/LayoutEditorContext'
import { LayoutEditorDnDContext } from 'features/views/LayoutEditor/LayoutEditorDnDContext'
import AdminSideTray from 'features/workspace/AdminSideTray'
import WorkspaceSettingsModal from 'features/workspace/WorkspaceSettingsModal'
// Analytics instance
import AnalyticsIdentifier from 'utils/AnalyticsIdentifier'
import PerformanceMonitor from 'utils/PerformanceMonitor'
import { UserflowIdentifier } from 'utils/UserflowIdentifier'

import { ToastProvider } from 'ui/components/Toast'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { AuthContextProvider } from './AuthContext/AuthContextProvider'
import GlobalModals from './GlobalModals'
import { GlobalStyle } from './GlobalStyle'
import LDFlagsWatcher from './LDFlagsWatcher'
import MetaDataRefresher from './MetaDataRefresher'
import { PostHog } from './PostHog'
import { Routes } from './Routes'
import UserActivityTracker from './UserActivityTracker'
import { WorkspaceContextProvider } from './WorkspaceContextProvider'

import 'ui/helpers/reset.css'
import 'ui/styling/GlobalStyles.css'
import '@fortawesome/fontawesome-pro/css/all.css'

// We need to import these globally to use for the icon pickers
library.add(fad, far)
const getUserConfirmation = (message, callback) => {
    console.warn('Confirming')
    window.confirm(callback)
}

initializeSupertokens()

export class Root extends Component {
    state = {}

    currentInterval = null

    UNSAFE_componentWillMount() {
        // Wipe everything out from local storage whenever we log in as support to avoid issues with
        // old stored tokens and such.
        if (window.location.href.match(/admin\/support-login\?api_token=[A-Za-z0-9.]+$/)) {
            localStorage.clear()
        }

        // Set up Datadog RUM, unless when running locally
        if (!settings.IS_LOCAL_INSTANCE) {
            datadogRum.init({
                applicationId: DD_RUM_APP_ID,
                clientToken: DD_RUM_TOKEN,
                site: 'datadoghq.com',
                service: 'stacker-frontend',
                env: settings.DOMAIN,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 0,
                trackResources: true,
                trackLongTasks: true,
                trackUserInteractions: true,
                trackSessionAcrossSubdomains: true,
                useSecureSessionCookie: true, // Disable this to test on local instances
                allowedTracingUrls: [
                    { match: settings.BACKEND_ENDPOINT, propagatorTypes: ['tracecontext'] },
                ],
            })
        }

        const whitelistUrls = settings.ASSETS_URL ? [new RegExp(settings.ASSETS_URL)] : undefined

        // Set up sentry
        Sentry.init({
            dsn: 'https://f0abda62c499f4a5d724853040a09d64@us.sentry.io/4506700214042624',
            integrations: [new BrowserTracing()],
            environment: settings.DOMAIN,
            release: settings.VERSION,
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'UnhandledRejection',
                'Record fetch failed',
                'Error updating permission rule undefined',
            ],
            whitelistUrls,
            beforeSend(event, hint) {
                // Don't report on local instances.
                if (settings.DEBUG) {
                    console.log('SENTRY (suppressed on local instances)', event, hint)
                    return null
                }
                return event
            },
        })
        Sentry.configureScope((scope) => {
            scope.setTag('domain', settings.DOMAIN)
            scope.setTag('stack_sid', getCurrentStackId())
            scope.setTag('stack_url', window.location.hostname)
        })
    }

    componentWillUnmount() {
        if (this.currentInterval) {
            clearInterval(this.currentInterval)
        }
    }

    render() {
        const { store } = this.props

        const routes = catchAppErrors(<Routes />)

        return (
            <SuperTokensWrapper>
                <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                    <QueryClientProvider client={queryClient}>
                        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                        <DndProvider backend={HTML5Backend}>
                            <Provider store={store}>
                                <ToastContainer />
                                <ToastProvider>
                                    <BrowserRouter getUserConfirmation={getUserConfirmation}>
                                        <PerformanceMonitor />
                                        <LDFlagsWatcher />
                                        <AuthContextProvider>
                                            <WorkspaceContextProvider>
                                                <AppContextProvider>
                                                    <MetadataLoading>
                                                        <AccountUserContextProvider>
                                                            <PreviewServiceContextProvider>
                                                                <AppUserContextProvider>
                                                                    <WorkspaceDomainRedirector />
                                                                    <UserActivityTracker />
                                                                    <AnalyticsIdentifier />
                                                                    <MetaDataRefresher />
                                                                    <UserflowIdentifier />
                                                                    <PostHog />
                                                                    <GlobalStyle>
                                                                        <SlowLayoutEditorContextProvider>
                                                                            <LayoutEditorContextProvider>
                                                                                <LayoutEditorDnDContext>
                                                                                    <ThemeProvider theme="admin">
                                                                                        <GlobalModals>
                                                                                            <NotificationPaneContextProvider>
                                                                                                <NavContextProvider>
                                                                                                    <NavigationContextProvider>
                                                                                                        <Omnibar>
                                                                                                            <AlgoliaInsights />
                                                                                                            <AdminSideTray />
                                                                                                            <PreserveQuery />
                                                                                                            <CustomScripts />
                                                                                                            <CustomCss />
                                                                                                            <WorkspaceSettingsModal />
                                                                                                            <ProfileSettingsModal />
                                                                                                            <PreviewAsModal />
                                                                                                            <ThemeProvider theme="default">
                                                                                                                <ScrollToTop>
                                                                                                                    {
                                                                                                                        routes
                                                                                                                    }
                                                                                                                </ScrollToTop>
                                                                                                            </ThemeProvider>
                                                                                                            <LoadActionObserver />
                                                                                                            <UserMonitoring />
                                                                                                            <SessionExpiredModal />
                                                                                                            <NotificationPane />
                                                                                                        </Omnibar>
                                                                                                    </NavigationContextProvider>
                                                                                                </NavContextProvider>
                                                                                            </NotificationPaneContextProvider>
                                                                                        </GlobalModals>
                                                                                    </ThemeProvider>
                                                                                </LayoutEditorDnDContext>
                                                                            </LayoutEditorContextProvider>
                                                                        </SlowLayoutEditorContextProvider>
                                                                    </GlobalStyle>
                                                                </AppUserContextProvider>
                                                            </PreviewServiceContextProvider>
                                                        </AccountUserContextProvider>
                                                    </MetadataLoading>
                                                </AppContextProvider>
                                            </WorkspaceContextProvider>
                                        </AuthContextProvider>
                                    </BrowserRouter>
                                </ToastProvider>
                            </Provider>
                        </DndProvider>
                    </QueryClientProvider>
                </GoogleOAuthProvider>
            </SuperTokensWrapper>
        )
    }
}

Root.propTypes = {
    store: PropTypes.object.isRequired,
}

_ScrollToTop.propTypes = {
    children: PropTypes.element.isRequired,
    location: PropTypes.object.isRequired, // From withRouter
}

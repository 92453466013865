import { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { EditorState, Transaction } from '@tiptap/pm/state'
import { JSONContent } from '@tiptap/react'
import { v4 as uuid } from 'uuid'

import { isMultiLineText, LIST_TYPES } from 'data/utils/fieldDefinitions'
import { assertIsDefined } from 'data/utils/ts_utils'
import { DATE_TYPES, NUMERIC_TYPES, STRING_TYPES, TEXT_TYPES } from 'features/admin/fields/common'
import { StackerDocumentFunction } from 'features/formulas/parser/formulaParserTypes'
import {
    parseStackerFormula,
    stringifyStackerAST,
} from 'features/formulas/parser/formulaParsingFunctions'

import {
    ContextEntry,
    ContextEntryWithPath,
    ContextGroup,
    ContextGroupWithPath,
    ContextItem,
    ContextSchema,
    ContextSchemaWithPaths,
    DynamicContextGroup,
    ExpressionResult,
    ExpressionValue,
} from './types'

export function getSchemaItemFromPath(path: string, contextSchema: ContextSchema) {
    // expr.api_name is a dot-separated string, e.g. "user.name"
    // We need to split it into an array of strings, e.g. ["user", "name"]
    const apiNameParts = path.split('.')
    let schemaItem: ContextEntry | undefined

    // Then we need to traverse the contextSchema hierarchy to find the item
    let currentLevel: ContextEntry[] = contextSchema
    let currentGroup: ContextGroup | undefined = undefined
    let parent: ContextGroup | undefined = undefined
    for (const apiNamePart of apiNameParts) {
        parent = currentGroup
        schemaItem = currentLevel?.find((item) => item.id === apiNamePart)

        // couldn't find the item... but return the last group that matched the path thus far
        if (!schemaItem) {
            return { item: undefined, group: parent }
        }
        currentGroup = schemaItem as ContextGroup
        currentLevel = (schemaItem as ContextGroup).items
    }

    return { item: schemaItem, group: parent }
}

export function hasChildren(item: ContextGroup): boolean {
    return (
        item.items?.filter((child) => child.type !== 'group' || hasChildren(child as ContextGroup))
            .length > 0
    )
}

type ExpressionType = {
    type?: FieldType
    extraOptions?: WorkflowExtraOptions
}

export function determineExpressionType(
    expValue: ExpressionValue | undefined,
    schema: ContextSchemaWithPaths
): ExpressionType {
    if (!expValue) {
        return {}
    }

    const result = getValueType(expValue, schema)
    if (result) return result

    return {
        type: 'string',
        extraOptions: {},
    }
}

const TYPES_ALLOWING_FORMULAS: FieldType[] = [...NUMERIC_TYPES, ...DATE_TYPES, 'checkbox']
/**
 * The expression evaluator expects a single value for each input,
 * so depending on the expected return type, we use different functions to handle
 * multiple values
 * @param value
 */
export function makeFinalValue(
    value: ExpressionValue[] | undefined,
    returnType?: FieldType,
    allowFormulas: boolean = true
): ExpressionValue | undefined {
    if (!value || value.length < 1) return

    if ((!returnType || TYPES_ALLOWING_FORMULAS.includes(returnType)) && allowFormulas) {
        return processAsFormula(value)
    } else if (returnType && LIST_TYPES.includes(returnType)) {
        return {
            function: 'ARRAY',
            arguments: value,
        }
    } else if (value.length > 1 && returnType) {
        return {
            function: 'CONCAT',
            arguments: value,
        }
    }

    return value[0]
}

function processAsFormula(value: ExpressionValue[]): ExpressionValue | undefined {
    const processed = value.map((x) => {
        if (x.function === 'STRING' || x.function === 'STRING_VALUE') {
            return x.value
        }
        return x
    })
    const text = processed.map((x) => (typeof x !== 'string' ? stringifyStackerAST(x) : x)).join('')

    const parsed = parseStackerFormula({ text })
    if (parsed.isValid) return parsed.output

    return undefined
}

function getValueType(
    value: ExpressionValue,
    schema: ContextSchemaWithPaths
): ExpressionType | undefined {
    switch (value.function) {
        case 'FIELD': {
            const { item } = getSchemaItemFromPath(value.api_name, schema)
            if (!item) break

            return {
                type: item.type as FieldType,
                extraOptions: (item as ContextItem).extra_options ?? {},
            }
        }

        case 'STRING_VALUE': {
            const { token_id } = value
            if (!token_id) break

            const { item } = getSchemaItemFromPath(token_id, schema)
            if (!item) break

            return {
                type: item.type as FieldType,
                extraOptions: (item as ContextItem).extra_options ?? {},
            }
        }

        default:
            return undefined
    }
}

export function buildContextSchemaWithPaths({
    contextSchema,
    returnTypeGroup,
    additionalGroups,
}: {
    contextSchema: ContextEntry[]
    returnTypeGroup?: ContextGroup | DynamicContextGroup
    additionalGroups?: (ContextGroup | DynamicContextGroup)[]
}): ContextSchemaWithPaths {
    const filteredContext = contextSchema.filter(
        (item) => item.type !== 'group' || hasChildren(item as ContextGroup)
    ) as ContextSchema

    function addPath(item: ContextEntry, path?: string) {
        const result: ContextEntryWithPath = {
            ...item,
            path: path ? `${path}.${item.id}` : item.id,
        }
        if ('items' in item) {
            const group = item as ContextGroup
            ;(result as ContextGroupWithPath).items = group.items.map((item) =>
                addPath(item, result.path)
            )
            return result
        }

        return result
    }

    if (returnTypeGroup) {
        filteredContext.unshift(returnTypeGroup)
    }

    if (additionalGroups) {
        filteredContext.push(...additionalGroups)
    }
    // now add the paths to each item
    const result = filteredContext.map((item) => addPath(item)) as ContextSchemaWithPaths

    return result
}

export function extractExpressionsFromContent(
    content: JSONContent,
    returnType: FieldType | undefined,
    contextSchema: ContextSchemaWithPaths,
    editorState: EditorState
): ExpressionValue[] {
    const expr: ExpressionValue[] = []

    if (returnType === 'document') {
        const documentExpr = extractExpressionFromDocument(content, contextSchema, editorState)
        expr.push(documentExpr)
    } else {
        editorState.doc.descendants((node) => {
            const nodeJson = node.toJSON()
            const nodeExpr = extractExpressionFromNode(nodeJson, returnType, contextSchema)
            if (nodeExpr) expr.push(nodeExpr)
            if (nodeJson.type === 'paragraph') {
                expr.push({
                    function: 'STRING',
                    value: '\n',
                })
            }
        })
    }

    return expr
}

function extractExpressionFromNode(
    node: JSONContent,
    returnType: FieldType | undefined,
    contextSchema: ContextSchemaWithPaths
): ExpressionValue | null {
    if (node.type === 'contextItem') {
        // if this is a literal token value and not a dynamic one, it is stored as a STRING_VALUE function
        if (node.attrs?.tag === 'literal') {
            const { group } = getSchemaItemFromPath(node.attrs.id, contextSchema)
            assertIsDefined(group)
            const value = node.attrs.id.substr(group?.id?.length + 1)
            return {
                function: 'STRING_VALUE',
                value: value ?? '', // This is the literal value
                token_id: node.attrs.id, // this is the path to the schema item so we can look it up later
            }
        } else {
            return {
                function: 'FIELD',
                api_name: node.attrs?.id,
                preserve_arrays: !returnType || LIST_TYPES.includes(returnType),
            }
        }
    } else if (node.type === 'expressionFunction') {
        const argsDict = Object.entries(node.attrs?.args ?? {})
            .filter(([k]) => k !== 'id')
            .reduce((result, [key, item]) => {
                let value = item

                if (Array.isArray(item)) {
                    value = item.map((x) => (x?.input_content ? x.value : x))
                } else {
                    value = (item as ExpressionResult)?.input_content
                        ? (item as ExpressionResult).value
                        : item
                }
                return {
                    ...result,
                    [key]: value,
                }
            }, {})
        return { function: node.attrs?.id, ...argsDict }
    } else if (node.text) {
        return { function: 'STRING', value: node.text ?? '' }
    }

    return null
}

/**
 * For documents, we extract the expressions from the document and replace the expression nodes with text nodes,
 * with tokens (i.e `$${{token_id}}`) as values. This allows us to preserve the original document structure,
 * and evaluate all formulas on the server-side, and then replace the tokens with the evaluated values.
 * We also preserve the original value of the document, so that we can use it in the editor.
 * @param content
 * @param contextSchema
 * @param editorState
 */
function extractExpressionFromDocument(
    content: JSONContent,
    contextSchema: ContextSchemaWithPaths,
    editorState: EditorState
): StackerDocumentFunction {
    // Create a copy of the current editor state.
    let state = editorState.reconfigure({})

    const { tr, subformulas } = getDocumentPlaceholderTransaction(state, contextSchema)
    state = state.apply(tr)

    const contentWithPlaceholders = state.doc.toJSON()

    return {
        function: 'DOCUMENT',
        value: contentWithPlaceholders,
        subformulas,
    }
}

function encodeExpressionIdentifier(id: string): string {
    return `\$\${{${id}}}`
}

function getDocumentPlaceholderTransaction(
    state: EditorState,
    contextSchema: ContextSchemaWithPaths
): {
    tr: Transaction
    subformulas: Record<string, ExpressionValue>
} {
    const subformulas: Record<string, ExpressionValue> = {}

    const changes: {
        placeholderNode: ProseMirrorNode
        nodeRange: { from: number; to: number }
    }[] = []
    // Walk the document, extract expressions and replace expression nodes with text.
    state.doc.descendants((node, pos) => {
        const nodeJson = node.toJSON()

        let nodeExpr: ExpressionValue | null = null
        // Deal with literal tokens separately, since we don't have them in the context schema.
        if (nodeJson.type === 'contextItem' && nodeJson.attrs?.tag === 'literal') {
            nodeExpr = extractExpressionFromLiteral(nodeJson)
        } else {
            nodeExpr = extractExpressionFromNode(nodeJson, 'document', contextSchema)
        }
        if (!nodeExpr) return true
        if (nodeExpr.function === 'STRING') return false

        const id = uuid()
        subformulas[id] = nodeExpr

        const placeholderNode = getPlaceholderNode(id, node, nodeJson, state)
        const nodeRange = { from: pos, to: pos + node.nodeSize }

        changes.push({
            placeholderNode,
            nodeRange,
        })

        return false
    })

    const { tr } = state
    // Apply changes in reverse order to avoid position invalidation.
    for (let i = changes.length - 1; i >= 0; i--) {
        const { nodeRange, placeholderNode } = changes[i]
        tr.replaceWith(nodeRange.from, nodeRange.to, placeholderNode)
    }

    return {
        tr,
        subformulas,
    }
}

function getPlaceholderNode(
    id: string,
    node: ProseMirrorNode,
    nodeJson: JSONContent,
    state: EditorState
): ProseMirrorNode {
    const encodedId = encodeExpressionIdentifier(id)
    if (nodeJson.type === 'contextItem' && nodeJson.attrs?.isMention) {
        return state.schema.nodes.mention.create(
            {
                id: encodedId,
                label: encodedId,
            },
            undefined,
            node.marks
        )
    }

    return state.schema.text(encodedId, node.marks)
}

function extractExpressionFromLiteral(nodeJson: JSONContent): ExpressionValue {
    const valuePrefix = 'value.'

    let value = nodeJson.attrs?.id
    if (nodeJson.attrs?.id.startsWith(valuePrefix)) {
        value = nodeJson.attrs?.id.substr(valuePrefix.length)
    }

    return {
        function: 'STRING_VALUE',
        value: value ?? '',
    }
}

export function checkTypeCompatibility(
    typeToCheck?: FieldType,
    typeToCheckOptions?: WorkflowExtraOptions,
    returnType?: FieldType,
    extraOptions?: WorkflowExtraOptions
) {
    if (!typeToCheck || !returnType) return true

    // Multi line text fields can accept any values
    if (isMultiLineText(returnType, extraOptions)) return true
    // single line text fields can accept everythign except multi line text
    if (TEXT_TYPES.includes(returnType)) return !isMultiLineText(typeToCheck, extraOptions)
    if (NUMERIC_TYPES.includes(typeToCheck) && NUMERIC_TYPES.includes(returnType)) return true
    if (DATE_TYPES.includes(typeToCheck) && DATE_TYPES.includes(returnType)) return true
    if (STRING_TYPES.includes(typeToCheck) && STRING_TYPES.includes(returnType)) return true
    if (typeToCheck === returnType) return true

    return false
}

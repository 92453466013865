import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { stopPropagation } from 'ui/helpers/utilities'

import { useNavigationPortalsState } from './hooks/useNavigationPortalsState'
import { NavigationPortalsItem } from './NavigationPortalsItem'

import {
    WorkspaceNavigationPortalsLabelHoverStyle,
    WorkspaceNavigationPortalsLabelStyle,
} from './Navigation.css'

type NavigationPortalsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    isCollapsed?: boolean
}

export const NavigationPortals: React.FC<NavigationPortalsProps> = ({ isCollapsed, ...props }) => {
    const { portals, canCreatePortal, createPortal } = useNavigationPortalsState()

    return (
        <Box px={isCollapsed ? 0 : 'xs'} {...props}>
            {!isCollapsed && (
                <Box
                    py="xs"
                    px="s"
                    height="3xl"
                    flex
                    center
                    justifyContent="space-between"
                    className={WorkspaceNavigationPortalsLabelStyle}
                >
                    <Body display="inline-block" size="s" weight="medium" color="textWeak">
                        Portals
                    </Body>

                    {canCreatePortal && (
                        <Box
                            className={WorkspaceNavigationPortalsLabelHoverStyle}
                            onClick={stopPropagation}
                            noShrink
                        >
                            <Tooltip
                                content="Create new portal"
                                asChild
                                zIndex={200}
                                side="bottom"
                                align="center"
                            >
                                <Button
                                    size="2xs"
                                    variant="ghost"
                                    startIcon={{ name: 'Plus' }}
                                    aria-label="Create new portal"
                                    onClick={createPortal}
                                />
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            )}
            <Box flex column gap="2xs">
                {!isCollapsed && !portals.length && (
                    <Box p="xs" height="3xl">
                        <Body size="s" weight="medium" color="textWeakest">
                            No portals
                        </Body>
                    </Box>
                )}
                {portals.map((portal) => (
                    <NavigationPortalsItem
                        key={portal.id}
                        portal={portal}
                        isCollapsed={isCollapsed}
                    />
                ))}
            </Box>
        </Box>
    )
}

import React from 'react'

import { Spinner } from '@chakra-ui/react'

import { useAppContext } from 'app/useAppContext'
import { ActivityType, RelatedToType } from 'data/hooks/activityTypes'
import { useStacks } from 'data/hooks/stacks'
import { StackIconBadge } from 'features/core/StackIconBadge'

import Text from 'v2/ui/components/Text'

import { Box } from 'ui/components/Box'

import { ActivityControls } from './ActivityControls'
import { ActivityTimestamp } from './ActivityTimestamp'
import { DocumentLink } from './DocumentLink'
import { FeedUserDisplay } from './FeedUserDisplay'
import { RecordLink } from './RecordLink'
import { TaskLink } from './TaskLink'
import { useActivityFeedContext } from './useActivityFeedContext'

type ActivityTitleProps = {
    activity: ActivityDto
    controls?: React.ReactNode
    compact?: boolean
    showActivityControls?: boolean
}
export function ActivityTitle({
    activity,
    controls,
    compact,
    showActivityControls = true,
}: ActivityTitleProps) {
    const { objects, retryFailedActivity, target } = useActivityFeedContext()
    const { selectedStack } = useAppContext()
    const { data: stacks } = useStacks()

    const activityStack = stacks?.find((stack) => stack._sid === activity.stack_id)
    const object = objects?.[activity.stack_id]?.[activity.object_id]

    const isSavePending =
        (!!activity.pendingCreateId || activity.originalContent) && !activity.saveFailed

    const retryPost = () => {
        retryFailedActivity(activity)
    }
    let actionText = ''

    switch (activity.type) {
        case ActivityType.RecordCreated:
            actionText = 'created'
            break
        case ActivityType.RecordUpdated:
            actionText = 'updated'
            break
        case ActivityType.RecordDeleted:
            actionText = 'deleted'
            break
        case ActivityType.TaskCompleted:
            actionText = 'completed'
            break
        case ActivityType.TaskDueDateChanged:
            actionText = 'updated'
            break
        case ActivityType.TaskAssigneesChanged:
            actionText = 'updated'
            break
        case ActivityType.RecordRestored:
            actionText = 'restored'
            break
        default:
            // If we're on the target feed itself, then we don't need to show the "on" text
            // as we're not showing the target name/link
            if (!target?.relatedTo) actionText = 'on'
    }

    return (
        <Box flex alignItems="center" flexWrap={compact ? 'nowrap' : 'wrap'} fontSize="bodyM">
            <Box flexShrink={0} mr="xs">
                <FeedUserDisplay userId={activity.created_by} showAvatar={!!compact} />
            </Box>
            {actionText && <Box mr="xs">{actionText}</Box>}
            {!target?.relatedTo && (
                <>
                    <Box mr="xs" fontWeight="bodyBold" shrink trim>
                        {activity.related_to_type === RelatedToType.Record && (
                            <RecordLink
                                recordId={activity.related_to}
                                objectId={activity.object_id}
                                stackId={activity.stack_id}
                                location={activity.related_to_location}
                            />
                        )}
                        {activity.related_to_type === RelatedToType.Task && (
                            <TaskLink taskId={Number(activity.related_to)} />
                        )}
                        {activity.related_to_type === RelatedToType.Document && (
                            <DocumentLink
                                documentId={Number(activity.related_to)}
                                location={activity.related_to_location}
                                stackId={activity.stack_id}
                            />
                        )}
                    </Box>
                    {!compact && activity.related_to_type === RelatedToType.Record && (
                        <>
                            in
                            <Box mx="xs" fontWeight="bodyBold" color="textWeakest">
                                {object?.name}
                            </Box>
                        </>
                    )}
                </>
            )}
            {target?.relatedTo &&
                selectedStack &&
                activityStack &&
                activityStack?._sid !== selectedStack?._sid && (
                    <>
                        {' '}
                        in{' '}
                        <Box flex center mx="xs" fontWeight="bodyBold" color="textWeakest">
                            <StackIconBadge stack={activityStack} size={20} mr="xs" />
                            {activityStack?.name}
                        </Box>
                    </>
                )}
            {isSavePending && <Spinner size="sm" color="userInterface.neutral.800" />}
            {activity.saveFailed && (
                <Box>
                    <Text variant="error" display="inline" mr={2}>
                        Failed to post.
                    </Text>
                    <a href="#" onClick={retryPost}>
                        Try again
                    </a>
                </Box>
            )}
            {!isSavePending && !activity.saveFailed && (
                <ActivityTimestamp activity={activity} noShrink ml="xs" />
            )}
            <Box grow />
            {controls}
            {!isSavePending && !activity.saveFailed && showActivityControls && (
                <ActivityControls activity={activity} />
            )}
        </Box>
    )
    // }

    return null
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { NoItemsFoundStyle } from 'ui/components/Menu/Menu.css'
import { PopupInner } from 'ui/components/Popup'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import {
    FavoriteItem,
    useSystemNavigationItemFavoritesState,
} from './hooks/useSystemNavigationItemFavoritesState'
import { AppIcon } from './AppIcon'
import { NavigationItem } from './NavigationItem'
import { NavigationMenuItemIcon } from './NavigationMenuItemIcon'
import { SystemNavigationItem } from './SystemNavigationItem'
import { SystemNavigationPopupItem } from './SystemNavigationPopupItem'

import {
    NavigationFavoritesItemStyles,
    NavigationItemHoverNoFocusStyle,
    SystemNavigationFavoritesPopupStyle,
} from './Navigation.css'

type SystemNavigationItemFavoritesProps = React.ComponentPropsWithoutRef<
    typeof SystemNavigationItem
> & {}

export const SystemNavigationItemFavorites: React.FC<SystemNavigationItemFavoritesProps> = (
    props
) => {
    const { items, removeFavorite } = useSystemNavigationItemFavoritesState()

    return (
        <SystemNavigationPopupItem
            iconName="star"
            label="Favorites"
            aria-label="Favorites"
            contentProps={{
                className: SystemNavigationFavoritesPopupStyle,
            }}
            {...props}
        >
            <PopupInner overflowY="auto">
                {items.map((item) => (
                    <FavoriteItemRenderer
                        key={item.id}
                        item={item}
                        onRemove={() => removeFavorite(item.id)}
                    />
                ))}
                {!items.length && (
                    <Box className={NoItemsFoundStyle}>
                        <Body
                            size="m"
                            weight="regular"
                            color="textHelper"
                            style={{ lineHeight: 1 }}
                        >
                            No favorites to display
                        </Body>
                    </Box>
                )}
            </PopupInner>
        </SystemNavigationPopupItem>
    )
}

type FavoriteItemRendererProps = {
    item: FavoriteItem
    onRemove: () => void
}

const FavoriteItemRenderer: React.FC<FavoriteItemRendererProps> = ({ item, onRemove }) => {
    const { url, isExternalUrl, label, app, navigationItem } = item

    return (
        <NavigationItem
            to={isExternalUrl ? undefined : url}
            href={isExternalUrl ? url : undefined}
            aria-label={label}
            className={NavigationFavoritesItemStyles.styleFunction({})}
            label={
                <Box flex column alignSelf="stretch" trim>
                    <Body size="m" weight="medium" trim color="text">
                        {label}
                    </Body>
                </Box>
            }
            leftSlotContent={
                <Box flex center noShrink gap="xs">
                    <AppIcon app={app} />
                    {navigationItem && (
                        <NavigationMenuItemIcon menuItem={navigationItem} color="icon" />
                    )}
                </Box>
            }
            rightSlotContent={
                <Box className={NavigationItemHoverNoFocusStyle} noShrink>
                    <Dropdown>
                        <DropdownButton
                            aria-label="Options"
                            startIcon={{
                                name: 'MoreHorizontal',
                            }}
                            variant="ghost"
                            size="2xs"
                        />
                        <DropdownContent onClick={stopPropagation}>
                            <DropdownItem
                                label="Remove from favorites"
                                startIcon={{ name: 'StarOff' }}
                                onClick={onRemove}
                            />
                        </DropdownContent>
                    </Dropdown>
                </Box>
            }
            isCollapsed={false}
        />
    )
}

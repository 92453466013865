import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeProgressVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surface: s.color.gray200,
            surfaceActive: s.color.theme600,
            surfaceSuccess: s.color.success600,
            surfaceSuccessOverflow: s.color.success700,
            surfaceDivider: s.color.gray900,
            helperTextHorizontal: s.color.text,
        },
        size: {
            minWidth: pxToRem(250),
            barHeight: pxToRem(5),
            dividerHeight: pxToRem(12),
            dividerWidth: pxToRem(2),
            helperTextHorizontal: pxToRem(45),
        },
        fontWeights: {
            label: s.fontWeights.bodyBold,
        },
        gap: {
            label: s.space.m,
            helperText: s.space.xs,
        },
    }
}

import React from 'react'

import { useAppContext } from 'app/useAppContext'
import { useObject } from 'data/hooks/objects'
import { ObjectPicker } from 'features/studio/ui/ObjectPicker'
import { DEFAULT_TRIGGER_ICON } from 'features/workflows/common'
import { ItemIcon } from 'features/workflows/ItemIcon'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'

import { TriggerCondition } from './TriggerCondition'
import { WorkflowTriggerEditorComponent, WorkflowTriggerNodeRendererComponent } from './types'

export const ActionButtonTriggerConfig: WorkflowTriggerEditorComponent = ({
    trigger,
    onChange,
}) => {
    const { selectedStack } = useAppContext()

    return (
        <>
            <ConfigurationLabel>Table</ConfigurationLabel>
            <ObjectPicker
                value={trigger.settings?.object_id}
                onChange={(item: { value: string }) => {
                    onChange({
                        settings: {
                            ...trigger.settings,
                            object_id: item.value,
                            stack_id: selectedStack?._sid,
                        },
                    })
                }}
                returnObject
                isClearable={false}
            />
        </>
    )
}

export const ActionButtonTriggerNode: WorkflowTriggerNodeRendererComponent = ({
    trigger,
    triggerType,
    showCondition,
    showType,
}) => {
    const { object } = useObject(trigger.settings?.object_id as string | undefined)

    return (
        <Box>
            <Box flex center>
                <ItemIcon
                    icon={triggerType?.icon || DEFAULT_TRIGGER_ICON}
                    iconStyle={triggerType?.icon_style}
                    mr="m"
                />
                <Box flex column alignItems="flex-start" fontWeight="bodyRegular">
                    {showType && (
                        <Box fontSize="bodyXs" color="textWeakest">
                            Trigger
                        </Box>
                    )}
                    <Box>
                        {trigger.name}
                        {object && (
                            <>
                                {' '}
                                in
                                <Container
                                    as="span"
                                    ml="s"
                                    p="xs"
                                    borderRadius="s"
                                    variant="accentMuted"
                                >
                                    {object?.name}
                                </Container>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            {showCondition && <TriggerCondition trigger={trigger} />}
        </Box>
    )
}

import React from 'react'
// @ts-ignore
import { components } from 'react-select'

import { useTheme } from '@chakra-ui/react'

import { InlineFilterOperation } from 'features/records/components/RecordFilters/constants'

import { Dropdown, Text } from 'v2/ui'

import { InlineFilterOption } from './InlineFilterOption'

type DropdownState = {
    isFocused: boolean
}

type InlineFilterOpDropdownProps = {
    options: { value: string; label?: string }[]
    value?: string
    onChange?: (newOperation: InlineFilterOperation) => void
}

export const InlineFilterOpDropdown: React.FC<InlineFilterOpDropdownProps> = (props) => {
    const theme = useTheme()

    return (
        <Dropdown
            isSearchable={false}
            isClearable={false}
            optionComponent={DropdownOption}
            menuProps={{
                padding: theme.space[2],
                boxShadow:
                    '0px 34px 54px -26px rgba(89, 94, 106, 0.15), 0px 9px 24px -8px rgba(89, 94, 106, 0.18)',
                margin: '0.3rem 0 0',
                borderColor: theme.colors.userInterface.neutral[400],
                borderRadius: 8,
                background: theme.colors.userInterface.neutral[0],
                zIndex: 50,
            }}
            optionProps={{
                '&:hover': {},
                backgroundColor: theme.colors.userInterface.neutral[0],
                cursor: 'pointer',
            }}
            controlStyle={(state: DropdownState) => ({
                border: state.isFocused
                    ? `1px solid ${theme.colors.brand[500]}`
                    : `1px solid ${theme.colors.userInterface.neutral[400]}`,
                boxShadow: state.isFocused
                    ? `0px 0px 0px 2px ${theme.colors.brand[200]}`
                    : '0px 3px 4px -5px rgba(24, 24, 28, 0.13), 0px 1px 2px rgba(24, 24, 28, 0.14)',

                padding: `${theme.space[1]} ${theme.space[2]} ${theme.space[1]} ${theme.space[1]}`,
                borderRadius: theme.radii.lg,
                backgroundColor: theme.colors.userInterface.neutral[0],
                '&:hover': {
                    backgroundColor: state.isFocused
                        ? undefined
                        : theme.colors.userInterface.neutral[100],
                    borderColor: state.isFocused
                        ? theme.colors.brand[500]
                        : theme.colors.userInterface.neutral[500],
                },
            })}
            dropdownIndicatorProps={{
                padding: 0,
            }}
            singleValueComponent={SingleValue}
            padding={0}
            usePortal={false}
            {...props}
        />
    )
}

type DropdownOptionProps = React.ComponentPropsWithoutRef<typeof components.Option> & {
    children: string
}

const DropdownOption: React.FC<DropdownOptionProps> = ({
    children,
    isSelected,
    innerProps,
    ...props
}) => {
    // Dropping these two handlers for perf reasons.
    // We handle the highlighting via CSS anyway.
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps

    return (
        <InlineFilterOption
            cursor="pointer"
            label={children}
            isChecked={isSelected}
            {...otherInnerProps}
            {...props}
        />
    )
}

type SingleValueProps = React.PropsWithChildren<{}>

const SingleValue: React.FC<SingleValueProps> = (props) => {
    return <Text fontWeight="bold" {...props} />
}

import { useCallback } from 'react'

import { useLocalStorageState } from 'utils/useLocalStorageState'

export function useLastFilters(view?: ViewDto, relatedListRecord?: RecordDto) {
    const lastFiltersPrefix = 'InlineFilters'

    // Persist user filter state to local storage, keyed by view + context.
    const [lastFilters, setLastFilters] = useLocalStorageState(
        `${lastFiltersPrefix}_${view?.stack_id}_${view?._sid}${
            relatedListRecord ? `_${relatedListRecord?._sid}` : ''
        }`,
        { defaultValue: [] }
    )

    const handleSetLastFilters = useCallback(
        (lastFilters) => {
            const filters: Filter[] = lastFilters.map((filter: Filter) => ({
                ...filter,
                field: {
                    _sid: filter.field?._sid,
                    api_name: filter.field?.api_name,
                },
            }))

            setLastFilters(filters)
        },
        [setLastFilters]
    )

    let normalizedLastFilters = lastFilters
    if (!Array.isArray(lastFilters)) {
        normalizedLastFilters = []
    }

    return [normalizedLastFilters, handleSetLastFilters]
}

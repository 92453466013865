import moment from 'moment-timezone'

import { TaskPayload } from 'data/hooks/tasks/types'
import { parseTaskDueDate } from 'features/tasks/categoryUtils'
import { CategorizedTasksGeneric } from 'features/tasks/types'

type TaskCategory = 'others' | 'me' | 'unassigned'
type TasksByAssigneeCategory = CategorizedTasksGeneric<TaskCategory>

export function groupByAssigneeCategory(
    tasks: TaskPayload[] = [],
    currentUser?: UserDto
): TasksByAssigneeCategory {
    const categorizedTasks = tasks.reduce<TasksByAssigneeCategory>(
        (agg, task) => {
            if (!task.assignees?.length) {
                agg.unassigned.tasks.push(task)
            } else if (currentUser?._sid && !task.assignees?.includes(currentUser._sid)) {
                agg.others.tasks.push(task)
            } else {
                agg.me.tasks.push(task)
            }

            return agg
        },
        {
            others: { label: 'Assigned to others', tasks: [], defaultExpanded: true },
            unassigned: { label: 'Unassigned', tasks: [], defaultExpanded: false },
            me: { label: 'Assigned to me', tasks: [], defaultExpanded: false },
        }
    )

    for (const category of Object.keys(categorizedTasks)) {
        categorizedTasks[category as TaskCategory].tasks = sortTasks(
            category as TaskCategory,
            categorizedTasks[category as TaskCategory].tasks
        )
    }

    return categorizedTasks
}

function sortTasks(category: TaskCategory, tasks: TaskPayload[]): TaskPayload[] {
    return tasks.sort((a, b) => {
        const aDueDate = parseTaskDueDate(a)
        const bDueDate = parseTaskDueDate(b)

        if (!aDueDate || !bDueDate) {
            const aCreationDate = moment.utc(a.created_at)
            const bCreationDate = moment.utc(b.created_at)

            return aCreationDate.isBefore(bCreationDate) ? -1 : 1
        }

        return aDueDate.isBefore(bDueDate) ? -1 : 1
    })
}

import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Editor } from '@tiptap/core'
import { isEqual } from 'lodash'

import { ActivityType } from 'data/hooks/activityTypes'
import { useDocument } from 'data/hooks/documents'
import { useGetRecord } from 'data/hooks/records'
import { ActivityFeedPopover } from 'features/Activity/ActivityFeedPopover'
import { useActivityFeedConfig } from 'features/Activity/useActivityFeedConfig'
import {
    ActivityLocationParameters,
    serializeActivityLocationParameters,
} from 'features/Activity/utils'

import usePrevious from 'v2/ui/hooks/usePrevious'

import { ActivityBubblePlugin } from './ActivityBubblePlugin'

type ActivityBubbleProps = {
    documentId?: number
    editor?: Editor
    recordId?: string
    fieldId?: string
}

/**
 * This component is responsible for rendering the activity popover.
 * It is coordinated by [ActivityMarkExtension](ActivityMarkExtension.ts) through [ActivityBubblePlugin](ActivityBubblePlugin.ts).
 */
export const ActivityBubble: React.FC<ActivityBubbleProps> = ({
    documentId,
    editor,
    recordId,
    fieldId,
}) => {
    const { data } = useDocument(documentId ?? -1, undefined, {
        enabled: Boolean(documentId),
        refetchOnMount: false,
    })
    const document = data?.document

    const fieldIdRef = useRef(fieldId)
    fieldIdRef.current = fieldId

    const { data: record } = useGetRecord({
        recordId: recordId ?? '',
        includeFields: [],
        useQueryOptions: { enabled: Boolean(recordId) },
    })

    const targetRef = useRef<HTMLElement | null>()

    const [isOpen, setIsOpen] = useState(false)

    const [targetLocation, setTargetLocation] = useState<string | undefined>()
    const targetLocationRef = useRef(targetLocation)
    targetLocationRef.current = targetLocation

    const keepTargetMarkRef = useRef(false)

    const [locationPreview, setLocationPreview] = useState<string | undefined>()

    const pluginRef = useRef<ActivityBubblePlugin>()

    const setTargetElement = useCallback((el: HTMLElement | null) => {
        targetRef.current = el
    }, [])

    const setTargetMarkId = useCallback((markId: string | undefined) => {
        const fieldId = fieldIdRef.current

        if (!markId && !fieldId) {
            setTargetLocation(undefined)
            return
        }

        const parameters: ActivityLocationParameters = {
            mark: markId!,
        }

        if (fieldId) {
            parameters.field = fieldId
        }

        const serializedLocation = serializeActivityLocationParameters(parameters)
        setTargetLocation(serializedLocation)
    }, [])

    const setLocationPreviewContent = useCallback((content: string | undefined) => {
        setLocationPreview(content)
    }, [])

    const open = useCallback(() => {
        setIsOpen(true)
    }, [])

    const close = useCallback(() => {
        const activityCount = commentCountRef.current
        const targetLocation = targetLocationRef.current!
        const keepTargetMark = keepTargetMarkRef.current

        setIsOpen(false)
        targetRef.current = null
        keepTargetMarkRef.current = false
        setTargetLocation(undefined)
        setLocationPreview(undefined)

        pluginRef.current?.emit('close', {
            activityCount,
            targetLocation,
            editor,
            keepTargetMark,
        })
    }, [editor])

    useEffect(() => {
        if (!editor || editor.isDestroyed) {
            return
        }

        const plugin = new ActivityBubblePlugin({
            setTargetElement,
            setTargetMarkId,
            setLocationPreviewContent,
            open,
            close,
        })
        pluginRef.current = plugin

        editor.registerPlugin(plugin)
        return () => {
            editor.unregisterPlugin(plugin.spec.key!)
        }
    }, [close, editor, open, setTargetElement, setTargetMarkId, setLocationPreviewContent])

    const {
        commentCount = 0,
        contextKey,
        target,
        commentsByLocation,
    } = useActivityFeedConfig({
        document,
        record,
        location: targetLocation,
    })
    const commentCountRef = useRef(commentCount)
    commentCountRef.current = commentCount

    const shouldShowControls = commentCount > 0

    const handleBeforeActivityCreate = useCallback(() => {
        keepTargetMarkRef.current = true
    }, [])

    const handleActivityDelete = useCallback(() => {
        keepTargetMarkRef.current = false
    }, [])

    const prevCommentsByLocation = usePrevious(commentsByLocation)

    useEffect(() => {
        if (!isEqual(commentsByLocation, prevCommentsByLocation)) {
            pluginRef.current?.emit('changeCommentCounts', {
                commentsByLocation: commentsByLocation,
                editor,
            })
        }
    }, [commentsByLocation, prevCommentsByLocation, editor])

    return (
        <ActivityFeedPopover
            open={isOpen}
            targetElement={targetRef.current ?? undefined}
            onClose={close}
            placement="bottom-start"
            contextKey={contextKey}
            target={target}
            params={{ types: [ActivityType.Comment] }}
            activityFeedProps={{
                showFilters: false,
                showControls: shouldShowControls,
                showPlaceholder: false,
                commentEditorProps: {
                    locationPreview,
                    autoFocus: true,
                },
                feedWrapperProps: {
                    maxHeight: '30vh',
                    overflowY: 'auto',
                    marginLeft: '-5px',
                    marginRight: '-5px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    width: 'auto',
                    minHeight: shouldShowControls ? '50px' : 'auto',
                },
                onBeforeCreate: handleBeforeActivityCreate,
                onDelete: handleActivityDelete,
            }}
        />
    )
}

import React, { useCallback, useImperativeHandle, useState } from 'react'

import { SuggestionProps } from '@tiptap/suggestion'
import classNames from 'classnames'

import { SuggestionComponentHandle } from 'features/tiptap/Extensions/extensionHelpers'

import { Box } from 'ui/components/Box'
import { ComboboxContext } from 'ui/components/Combobox'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'
import { LoadingIndicator } from 'ui/components/LoadingIndicator'
import { MenuBaseStyle } from 'ui/components/Menu/Menu.css'

import { CommandsProvider } from './CommandsProvider'

type ListProps = SuggestionProps<any> & {
    providersFn: () => CommandsProvider<any>[]
    setDisplayedComponent?: (component: React.ComponentType | undefined) => void
    setDisplayedComponentProps?: (props: any) => void
}
export const CommandExtensionView = React.forwardRef<SuggestionComponentHandle, ListProps>(
    (props, ref) => {
        const [displayedComponent, setDisplayedComponent] = useState<
            React.ComponentType | undefined
        >()
        const [displayedComponentProps, setDisplayedComponentProps] = useState<any>({})

        // Doing it like this because a component is a function and so if we just pass
        // the state setter setDisplayedComponent through to the children, and they try to call it
        // like setDisplayedComponent(SomeComponent), the state setter will actually execute the supplied
        // function rather than taking it as the new state value.
        const doSetDisplayedComponent = useCallback(
            (component) => setDisplayedComponent(() => component),
            [setDisplayedComponent]
        )
        //
        // If a command provider has specified custom content to display, we render that instead
        // of the root command list.
        const ContentComponent = displayedComponent
        return (
            <Box
                minWidth="400px"
                maxWidth="100vw"
                maxHeight="66vh"
                overflowY="auto"
                className={classNames(MenuBaseStyle, 'ag-custom-component-popup')}
            >
                {ContentComponent ? (
                    <ContentComponent
                        ref={ref}
                        {...props}
                        setDisplayedComponent={doSetDisplayedComponent}
                        setDisplayedComponentProps={setDisplayedComponentProps}
                        {...displayedComponentProps}
                    />
                ) : (
                    <RootCommandsList
                        ref={ref}
                        {...props}
                        setDisplayedComponent={doSetDisplayedComponent}
                        setDisplayedComponentProps={setDisplayedComponentProps}
                    />
                )}
            </Box>
        )
    }
)
const RootCommandsList = React.forwardRef<SuggestionComponentHandle, ListProps>(
    (
        { query, providersFn, setDisplayedComponent, setDisplayedComponentProps, editor, range },
        ref
    ) => {
        const onItemSelected = useCallback(
            (item, provider) => {
                provider.onSelect({
                    editor,
                    range,
                    item,
                    setDisplayedComponent,
                    setDisplayedComponentProps,
                })
            },
            [editor, range, setDisplayedComponent, setDisplayedComponentProps]
        )
        const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
            isOpen: true,
            inputValue: query,
            onItemSelected,
            providers: providersFn(),
            itemToString: () => '',
            debounceDelay: 10,
        })

        const { getInputProps } = comboboxState

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }) => {
                getInputProps(undefined, { suppressRefError: true }).onKeyDown(event)
                return event.defaultPrevented
            },
        }))

        const { isLoading, collections, showMore, items } = itemsState

        // Prevent deselecting the active option after the menu closes.
        const { onMouseLeave: _onMouseLeave, ...menuProps } = comboboxState.getMenuProps(
            undefined,
            {
                suppressRefError: true,
            }
        )

        return (
            <ComboboxContext.Provider value={comboboxState}>
                {query && !isLoading && !items.length && (
                    <Box py="l" px="xl">
                        No results found
                    </Box>
                )}
                <Box {...menuProps}>
                    <ComboboxList
                        collections={collections}
                        queryTerms={queryTerms}
                        showMore={showMore}
                        isLoading={isLoading}
                    />
                </Box>
                {isLoading && (
                    <Box py="l" px="xl" flex center color="textWeakest">
                        <LoadingIndicator />
                    </Box>
                )}
            </ComboboxContext.Provider>
        )
    }
)

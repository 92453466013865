import React, { useMemo } from 'react'
import { Cell, CellValue, Row } from 'react-table'

import { useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ViewCellBaseProps, ViewColumn } from 'v2/views/List/types'

import EditableFieldWrapper from 'features/admin/edit-mode/ListView/EditableListFieldWrapper'
import { RecordStatsIcons } from 'features/records/components/RecordStatsIcons'

import { Box, Flex, Text } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

const StyledBox = styled(Box)`
    .${STYLE_CLASSES.TAG_LIST} {
        margin-bottom: -0.25rem;
    }
`

type RowsCellProps = {
    index?: number
    dataIndex?: number
    showEmptyValues?: boolean
    hideFieldLabels?: boolean
    isInbox?: boolean
    object?: ObjectDto
    useClassNames?: boolean
    viewOptions?: ListViewOptions
    setConfig?: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    header?: string
    cells?: ViewCellBaseProps[]
    column?: ViewColumn
    cell?: Cell<RecordDto>
    value?: React.ReactNode
    label?: React.ReactNode
    originalValue?: CellValue
    row: Row<RecordDto>
}

export const RowsCell: React.FC<RowsCellProps> = ({
    header,
    label,
    value,
    originalValue,
    column,
    cells,
    index = 0,
    showEmptyValues,
    hideFieldLabels,
    isInbox,
    viewOptions,
    setConfig,
    object,
    useClassNames,
    row,
}) => {
    const getClassName = (className: string) => {
        if (!useClassNames) return undefined

        return className
    }

    // Only render this value if it's the first value (ie., the title),
    // or it has a value, or we're instructed to show empty values
    const renderValue = index === 0 || originalValue || showEmptyValues

    // Only render the separator dot if there are subsequent fields in the list
    // that have values (or we're supposed to render empty values)
    const hasMoreValues = useMemo(() => {
        if (index + 1 === cells?.length) return false

        if (showEmptyValues) return true

        const subsequentFields = cells?.slice(index + 1)
        return subsequentFields?.some((x) => !!x.originalValue)
    }, [cells, index, showEmptyValues])

    const { colors } = useTheme()

    return (
        <>
            <StyledBox
                paddingLeft={isInbox ? '8px' : undefined}
                className={getClassName(
                    `${STYLE_CLASSES.CARD_CELL} ${STYLE_CLASSES.CARD_CELL}-${header}`
                )}
                key={index}
                w={index === 0 ? '100%' : 'auto'}
                mb={1}
                maxWidth="100%"
                alignSelf={isInbox ? 'flex-start' : undefined}
            >
                {/* don't render this field if we have no value, unless it's the first
                field or the edit controls should be shown  */}
                {(index === 0 || renderValue) && (
                    <EditableFieldWrapper
                        position="horizontal"
                        fieldId={column?.renderOptions?.fieldId}
                        objectId={object?._sid}
                        viewOptions={viewOptions}
                        setConfig={setConfig}
                        hideLabelOptions
                        showControls="never"
                    >
                        {index === 0 && (
                            <Text
                                as={isInbox ? 'h3' : 'h2'}
                                variant="recordLabelRow"
                                style={{
                                    fontSize: isInbox ? '16px' : undefined,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {value}

                                {row && object && (
                                    <RecordStatsIcons record={row.original} object={object} />
                                )}
                            </Text>
                        )}
                        {index > 0 && renderValue && (
                            <Flex>
                                {!hideFieldLabels && (
                                    <Text
                                        variant="cardLabel"
                                        as="h3"
                                        style={{
                                            color: isInbox ? '#454e55' : undefined,
                                            fontSize: isInbox ? '14px' : undefined,
                                        }}
                                    >
                                        {label}
                                    </Text>
                                )}
                                {renderValue ? (
                                    <Text
                                        variant="rowFieldText"
                                        maxWidth="100%"
                                        style={{
                                            color: isInbox ? '#454e55' : undefined,
                                            fontSize: isInbox ? '12px' : undefined,
                                        }}
                                    >
                                        {value}
                                    </Text>
                                ) : (
                                    <Text
                                        variant="rowFieldText"
                                        maxWidth="100%"
                                        style={{
                                            color: colors.userInterface.neutral[800],
                                            fontSize: isInbox ? '12px' : undefined,
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        {column?.renderOptions?.label || column?.field?.label}
                                    </Text>
                                )}
                            </Flex>
                        )}
                    </EditableFieldWrapper>
                )}
            </StyledBox>
            {!isInbox && index > 0 && renderValue && hasMoreValues && (
                <Text
                    variant="rowFieldText"
                    className={getClassName(STYLE_CLASSES.CARD_CELLS_DIVIDER)}
                    mx={2}
                    mb={1}
                >
                    •
                </Text>
            )}
        </>
    )
}

import { DEFAULT_QUERY_CONFIG } from 'data/reactQueryCache'

export const RECORD_LIST_QUERY_CONFIG = {
    ...DEFAULT_QUERY_CONFIG,
    cacheTime: 1000 * 60 * 5,
    structuralSharing: false,
    keepPreviousData: true, // Stops flashing when loading new data
}

export const RECORD_QUERY_CONFIG = {
    ...DEFAULT_QUERY_CONFIG,
    cacheTime: 1000 * 60 * 10,
    structuralSharing: false,
    keepPreviousData: true, // Stops flashing when loading new data
}

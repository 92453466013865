import isValidAggregation from 'v2/blocks/blockTypes/view/aggregationBlocks/utils/isValidAggregation'

const findFieldApiName = (sid: string | undefined, fields: FieldDto[]) =>
    fields.find((f) => f._sid === sid)?.api_name

export default function isValidChart(chart: ChartBlockAttributes, object?: ObjectDto) {
    if (!isValidAggregation(chart, object)) return false

    const validGroupByField = chart.groupByFieldId
        ? findFieldApiName(chart.groupByFieldId, object?.fields ?? [])
        : true

    const validAdditionalGroupByField = chart.additionalGroupFieldId
        ? findFieldApiName(chart.additionalGroupFieldId, object?.fields ?? [])
        : true

    if (!object || !validGroupByField || !validAdditionalGroupByField) {
        return false
    }

    return true
}

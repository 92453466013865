import React, { useCallback } from 'react'

import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { useShouldDisplayAddNewTableButton } from './useShouldDisplayAddNewTableButton'

export const AddNewTableButton: React.FC = () => {
    const { showManageData } = useSlidingPane()

    const handleAddNewTable = useCallback(() => {
        showManageData({ shouldShowNewTableModal: true })
    }, [showManageData])

    if (!useShouldDisplayAddNewTableButton()) return null
    return (
        <Box mt="3xl" mb="xl" mx="l">
            <Button
                variant="secondary"
                startIcon={{ name: 'Plus' }}
                size="m"
                style={{ width: '100%' }}
                onClick={handleAddNewTable}
            >
                Add new table
            </Button>
        </Box>
    )
}

import React, { MouseEvent, useMemo } from 'react'
import ReactFocusLock from 'react-focus-lock'

import type { Placement } from '@popperjs/core'

import Popper from 'v2/ui/components/Popper'
import { autoSizeModifier } from 'v2/ui/components/popperModifiers/autoSizeModifier'

import { Container } from 'ui/components/Container'

import { ActivityFeed } from './ActivityFeed'
import { FeedItemRelationshipInfo, FeedParameters } from './types'

type ActivityFeedPopoverProps = {
    open?: boolean
    placement?: Placement
    targetElement?: HTMLElement
    container?: HTMLElement
    contextKey: string
    params?: FeedParameters
    target?: FeedItemRelationshipInfo
    onClose: () => void
    activityFeedProps?: Partial<React.ComponentPropsWithoutRef<typeof ActivityFeed>>
}
export const ActivityFeedPopover: React.FC<ActivityFeedPopoverProps> = ({
    open,
    placement,
    targetElement,
    container,
    contextKey,
    params,
    target,
    onClose,
    activityFeedProps,
}) => {
    // Find the first descendant of the document.body which contains
    // either the container element or the target element (if container
    // isn't specified). We pass this through to the Popper component
    // so it knows which branch of the DOM to consider for "outside actions."
    const rootNode = useMemo(() => {
        let result: Node | undefined = undefined
        for (var value of Array.from(document.body.childNodes.values())) {
            if (
                (container && value.contains(container)) ||
                (!container && targetElement && value.contains(targetElement))
            ) {
                result = value
            }
        }

        return result
    }, [container, targetElement])

    if (!open || !targetElement) return null
    // keeps clicks in the popover from bubbling up and clicking on other thigns
    // in the hierarchy like record cards/rows, etc.
    const handleClick = (e: MouseEvent) => {
        e.stopPropagation()
    }

    return (
        <Popper
            placement={placement}
            referenceElement={targetElement}
            onClose={onClose}
            // Only close on outer actions on descendents of the main app node,
            // not elements in portaled components such as confirm modals, dropdowns, etc.
            limitOuterActionsToDescendentsOf={rootNode}
            modifiers={[
                autoSizeModifier,
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: true,
                        padding: 10,
                    },
                },
            ]}
        >
            <ReactFocusLock>
                <Container
                    variant="neutralMuted"
                    border
                    popover
                    width="600px"
                    maxHeight="66vh"
                    p="l"
                    onClick={handleClick}
                    overflowY="auto"
                >
                    <ActivityFeed
                        contextKey={contextKey}
                        params={params}
                        target={target}
                        {...activityFeedProps}
                    />
                </Container>
            </ReactFocusLock>
        </Popper>
    )
}

import styled from '@emotion/styled'

export const SettingsWrapper = styled.div`
    max-width: 1000px;
`

export const SettingsContent = styled.div`
    background: white;
    border-radius: 5px;
    display: inline-flex;
    width: 100%;
    margin-bottom: 30px;
`

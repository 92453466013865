const READ_ONLY_SYNTHETIC_FIELDS_STACKER_DATA = new Set<SyntheticFieldType>([
    'created_at',
    'updated_at',
    'created_by',
    'updated_by',
    'record_id',
])

export const isReadOnlyField = (field: FieldDto): boolean => {
    const connectionOptions = field.connection_options
    if (!connectionOptions) return false

    const stackerDataType = field.synthetic_field_type
    if (stackerDataType && READ_ONLY_SYNTHETIC_FIELDS_STACKER_DATA.has(stackerDataType)) {
        return true
    }

    // Check if field is marked read only (i.e. Stacker data fields)
    return field.is_read_only
}

import { UseQueryOptions } from 'react-query'

import { useQuery } from 'data/hooks/_helpers'

export const EXTERNAL_OBJECTS_KEY = 'EXTERNAL_OBJECTS_KEY'

type ExternalObject = {
    id: string
    name: string
}

type useExternalObjectsInput = {
    integrationId: string
    nangoConnectionId: string
    externalDatabaseId: string
}
export function useExternalObjects(
    { nangoConnectionId, integrationId, externalDatabaseId }: useExternalObjectsInput,
    options: UseQueryOptions<ExternalObject[]>
) {
    return useQuery<ExternalObject[]>(
        [EXTERNAL_OBJECTS_KEY, integrationId, nangoConnectionId, externalDatabaseId],
        `data-connections/external-objects/?nango_connection_id=${nangoConnectionId}&integration_id=${integrationId}&external_database_id=${externalDatabaseId}`,
        options,
        {
            bypassQueryDeps: true,
        }
    )
}

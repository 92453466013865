import React, { useEffect, useState } from 'react'

import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react'
import { DocumentLink } from 'v2/views/Document/DocumentLink'

import { useCachedDocument, useUpdateDocument } from 'data/hooks/documents'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

import { EditableInputStyle } from './DocumentLinkExtensionStyles.css'

type DocumentLinkComponentProps = NodeViewRendererProps & {}
export const DocumentLinkComponent: React.FC<DocumentLinkComponentProps> = ({
    node,
    extension,
    editor,
}) => {
    const { id } = node.attrs
    const documents = extension.options.fetchDocumentsFn?.() as DocumentDto[]
    const cachedDocument = useCachedDocument(id)
    const { mutateAsync: updateDocument } = useUpdateDocument(id)
    const document = cachedDocument || documents?.find((document) => document.auto_id === id)
    const [editableElm, setEditableElm] = React.useState<HTMLElement | null>(null)
    const [editedTitle, setEditedTitle] = useState('')
    const shouldFocusInput = extension.storage.lastAddedDocument === id
    useEffect(() => {
        if (editableElm && shouldFocusInput) {
            editableElm.focus()
            extension.storage.lastAddedDocument = null
        }
    }, [editableElm, extension.storage, shouldFocusInput])
    const handleTitleChange = (event: React.ChangeEvent<HTMLSpanElement>) => {
        setEditedTitle(event.target.textContent || '')
    }

    const saveTitle = () => {
        if (!editedTitle) return

        updateDocument({ title: editedTitle })
        editor.commands.focus()
    }
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
            saveTitle()
        }
    }

    if (!document) return null

    return (
        <NodeViewWrapper as="span" className="document-link">
            {document.title ? (
                <DocumentLink
                    document={document}
                    fontWeight="bodyBold"
                    maxWidth="full"
                    style={{
                        userSelect: 'text',
                    }}
                    iconProps={{ mr: 'xs' }}
                    data-drag-handle
                    draggable="true"
                />
            ) : (
                <Box as="span" display="inline-flex" center>
                    <Icon name="NotebookPen" mr="xs" />
                    <Box
                        as="span"
                        ref={setEditableElm}
                        className={EditableInputStyle}
                        contentEditable
                        data-placeholder="enter page title"
                        minWidth={!editedTitle ? '100px' : undefined}
                        onInput={handleTitleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={saveTitle}
                    />
                </Box>
            )}
        </NodeViewWrapper>
    )
}

import moment from 'moment-timezone'

import { getCurrentStackId, getWorkspaceAccount, GlobalStaticState } from 'app/GlobalStaticState'
import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { hasSession } from 'features/auth/supertokens'
import { getCustomBackendConfig } from 'utils/getCustomBackendConfig'

type FetchWithAuthOptions = {
    bypassMatchingStackCheck?: boolean
    bypassPreviewAs?: boolean
    stackId?: string
    accountId?: string
}

export function fetchWithAuth(
    url: string,
    params?: Record<string, any>,
    options: FetchWithAuthOptions = {}
): Promise<Response> {
    if (!params) {
        params = {
            method: 'GET',
        }
    }
    const user = GlobalStaticState.getUser()
    // configure headers, then submit the request.
    return configureHeaders(params, options, user).then((headers) => {
        params = { ...params, headers }

        // We send through X-Ignore-Stack to let the backend know that we want a list for the whole workspace
        if (options?.bypassMatchingStackCheck) {
            params.headers = { ...params.headers, 'X-Ignore-Stack': true }
        }

        return fetch(settings.API_ROOT + url, params).then((response) => {
            const currentStackId = getCurrentStackId()
            if (
                !options?.stackId &&
                !options?.bypassMatchingStackCheck &&
                currentStackId &&
                response.headers.get('X-Stack-Id') !== currentStackId
            ) {
                return Promise.reject(null)
            } else {
                return response
            }
        })
    })
}

function configureHeaders(
    params: Record<string, any>,
    options: FetchWithAuthOptions,
    user?: AuthedUserDto
): Promise<Record<string, string>> {
    const apiToken = user?.api_token
    return new Promise(async (resolve) => {
        // url = url.includes("?") ? url + "&" : url + "?"
        const stackId = options?.stackId || getCurrentStackId()
        const accountId = options?.accountId || getWorkspaceAccount()?._sid

        let optional_headers: Record<string, any> = {}

        // If an admin is already logged in before going to the support login then
        // this can cause the admin activity date to incorrectly be updated
        const isSupportUrl = window.location.pathname.indexOf(getUrl(Urls.AdminSupportLogin)) !== -1

        if (localStorage.getItem('support_login') || isSupportUrl) {
            optional_headers['X-Support-Login'] = true
        }

        const tz = moment.tz.guess()
        const headers = {
            ...params.headers,
            ...getCustomBackendConfig(),
            ...optional_headers,
            'X-Timezone': tz,
        }

        const previewingUser = GlobalStaticState.getPreviewingAsUser()
        if (previewingUser && !options?.bypassPreviewAs) {
            headers['X-Previewing-As-User'] = previewingUser._sid
        }

        const hasSupertokensSession = await hasSession()
        // Only include api-token if session exists.
        if (apiToken && !hasSupertokensSession) {
            headers['X-Api-Token'] = apiToken
        }
        if (GlobalStaticState.getPreviewingAsRole() && !options?.bypassPreviewAs) {
            headers['X-Previewing-As-Role'] = GlobalStaticState.getPreviewingAsRole()
        }

        if (stackId) {
            headers['X-Stack-Id'] = stackId
        }
        if (accountId) {
            headers['X-Account-Id'] = accountId
        }

        resolve(headers)
    })
}

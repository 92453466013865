import React, { FC, useRef } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Button, DragAndDropList, Icon, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import FieldOption from './FieldOption'
import type { DropdownOption } from './types'
import useDropdownEditorHandler from './useDropdownEditorHandler'

const { colors } = stackerTheme()

const ScrollableWrapper = styled.div`
    width: 100%;
    max-height: min(500px, calc(50vh - 250px));

    overflow-y: scroll;
`

const AddOptionButton = styled(Button)<{ isEmptyState: boolean }>`
    // There is a terribly weird bug in the Button component: the first line
    // of styled is always ignored. There are several instances of such
    // ugly hack at difference places.
    noop;

    ${(props) =>
        props.isEmptyState &&
        css`
            width: 100%;
            justify-content: flex-start;

            border: 1px;
            border-style: dashed;
            border-color: ${colors.userInterface.neutral[500]};

            padding: 0.5rem;
        `}
`

type Props = {
    value: DropdownOption[]
    disabled: boolean
    onChange: (value: DropdownOption[]) => void
}

const DropdownFieldOptionsEditor: FC<Props> = ({ value, disabled, onChange }) => {
    const scrollableRef = useRef<HTMLDivElement | null>(null)
    const optionSelectionMapRef = useRef<
        Record<string, { selectionStart: number | null; selectionEnd: number | null }>
    >({})

    const {
        allowDropdownColors,
        options,
        focusedOption,
        focusOption,
        blurOption,
        provideKey,
        reorder,
        addEmptyOption,
        changeOption,
        deleteOption,
    } = useDropdownEditorHandler(value, onChange)

    const onAddOption = () => {
        addEmptyOption()
        // Wait for the next render to trigger a scroll: the new option is not mounted yet
        // in the current render.
        requestAnimationFrame(() => {
            scrollableRef.current?.scroll({ top: scrollableRef.current?.scrollHeight })
        })
    }

    const onFocusNext = () => {
        if (focusedOption === null) {
            focusOption(0)
            return
        }

        if (focusedOption < options.length - 1) {
            focusOption(focusedOption + 1)
            return
        }

        onAddOption()
    }

    return (
        <>
            <ScrollableWrapper ref={scrollableRef}>
                {/* @ts-expect-error */}
                <DragAndDropList
                    provideKey={provideKey}
                    dragDisabled={disabled}
                    onReorder={reorder}
                >
                    {options.map((option, index) => (
                        // TS expect error due to the extra props draggableProps and dragHandleProps
                        // passed by DragAndDropList that clones the children and add these props
                        <FieldOption
                            key={option.id}
                            allowDropdownColors={allowDropdownColors}
                            option={option}
                            placeholder={`Option ${index + 1}`}
                            isFocused={focusedOption === index}
                            disabled={disabled}
                            onFocus={() => focusOption(index)}
                            onBlur={blurOption}
                            onChange={(newOption) => changeOption(index, newOption)}
                            onDelete={() => deleteOption(index)}
                            onFocusNext={onFocusNext}
                            // @ts-expect-error
                            item={{ props: { cannotBeDragged: option.isEmpty } }}
                            optionSelectionMap={optionSelectionMapRef.current}
                        />
                    ))}
                </DragAndDropList>
            </ScrollableWrapper>
            <AddOptionButton
                variant="Tertiary"
                size="xs"
                mt={2}
                p={1}
                disabled={disabled}
                isEmptyState={options.length === 0}
                onClick={onAddOption}
            >
                <Icon icon="add" color={colors.userInterface.accent[1000]} mr={1} />
                <Text color={colors.userInterface.accent[1000]}>Add option</Text>
            </AddOptionButton>
        </>
    )
}

export default DropdownFieldOptionsEditor

import type { Color } from 'v2/ui/theme/styles/types/colorBase'

import { IconName } from 'ui/components/Icon/Icon'
import { palette } from 'ui/styling/baseVariables/colors/colorPalette'

export const FIELD_TYPES = [
    'checkbox',
    'currency',
    'date',
    'datetime',
    'dropdown',
    'formula',
    'image',
    'long_text',
    'document',
    'lookup',
    'multi_file',
    'multi_lookup',
    'multi_select',
    'number',
    'password',
    'percentage',
    'string',
    'url',
    'user_ref',
    'user_role',
] as const

export type FieldType = (typeof FIELD_TYPES)[number]

export const IMAGE_FIELD_TYPES: FieldType[] = ['image', 'multi_file']

export const LINK_TYPES: FieldType[] = ['lookup', 'multi_lookup']

export const DROPDOWN_TYPES: FieldType[] = ['dropdown', 'multi_select']

export const LIST_TYPES: FieldType[] = ['multi_select', 'multi_file', 'multi_lookup']

export const NUMBER_TYPES = ['number', 'percentage', 'currency']

export const DROPDOWN_COLORS: { [keyof: string]: Color } = {
    Rose: palette.rose200,
    Pink: palette.pink200,
    Fuchsia: palette.fuchsia200,
    Purple: palette.purple200,
    Violet: palette.violet200,
    Indigo: palette.indigo200,
    Blue: palette.blue200,
    Sky: palette.sky200,
    Cyan: palette.cyan200,
    Teal: palette.teal200,
    Emerald: palette.emerald200,
    Lime: palette.lime200,
    Orange: palette.orange200,
    Neutral: palette.gray200,
}

export const TEXT_COLORS: { [keyof: string]: Color } = {
    Rose: palette.rose900,
    Pink: palette.pink900,
    Fuchsia: palette.fuchsia900,
    Purple: palette.purple900,
    Violet: palette.violet900,
    Indigo: palette.indigo900,
    Blue: palette.blue900,
    Sky: palette.sky900,
    Cyan: palette.cyan900,
    Teal: palette.teal900,
    Emerald: palette.emerald900,
    Lime: palette.lime900,
    Orange: palette.orange900,
    Neutral: palette.gray900,
}

export const AUGMENTED_DATA_FIELD_TYPES: FieldType[] = [
    'checkbox',
    'currency',
    'date',
    'datetime',
    'dropdown',
    'long_text',
    'document',
    'lookup',
    'multi_file',
    'multi_lookup',
    'multi_select',
    'number',
    'percentage',
    'string',
    'url',
]
export const MAGIC_SYNTHETIC_FIELD_TYPES = [
    'created_at',
    'updated_at',
    'created_by',
    'updated_by',
    'record_id',
    'autonumber',
] as const

export const SYNTHETIC_FIELD_TYPES = [
    'relationship',
    'symmetric_relationship',
    'lookup',
    'rollup',
    'formula',
    ...MAGIC_SYNTHETIC_FIELD_TYPES,
] as const

export type SyntheticFieldType = (typeof SYNTHETIC_FIELD_TYPES)[number]

export function isMultiLineText(returnType: FieldType, returnTypeOptions?: WorkflowExtraOptions) {
    return (
        returnType === 'long_text' ||
        returnType === 'document' ||
        (returnType === 'string' && returnTypeOptions?.render_variant === 'richText')
    )
}

export enum FormulaType {
    String = 'string',
    Number = 'number',
    Bool = 'bool',
    Date = 'date',
    DateTime = 'datetime',
    Array = 'array',
}

export const StackerFieldToFormulaType: { [key in FieldType]?: FormulaType } = {
    string: FormulaType.String,
    number: FormulaType.Number,
    checkbox: FormulaType.Bool,
    date: FormulaType.Date,
    datetime: FormulaType.DateTime,
    multi_select: FormulaType.Array,
}

export type FieldIconLucide = {
    type: 'lucide'
    name: IconName
}

export type FieldIcon = FieldIconLucide

import React from 'react'

import { BoxProps } from '@chakra-ui/react'

import { useObject } from 'data/hooks/objects'

import { Box } from 'v2/ui'

type Props = BoxProps & {
    objectId: string
    iconSize?: string
}
const ObjectLabel: React.FC<Props> = ({ objectId, ...props }) => {
    const { object } = useObject(objectId)

    return (
        <Box display="inline" {...props}>
            <span style={{ verticalAlign: 'middle' }}>{object?.name}</span>
        </Box>
    )
}

export default ObjectLabel

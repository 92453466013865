import React from 'react'

import { FieldIcon } from 'features/admin/fields/icons/FieldIcon'
import * as Parts from 'features/views/ListView/BoardView/BoardView.parts'
import { BoardViewAttribute } from 'features/views/ListView/BoardView/types'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { useBoardViewBaseAttributeState } from './hooks/useBoardViewBaseAttributeState'

const LABEL_MAX_LENGTH = 40
const LABEL_MAX_LINES = 1

type BaseAttributeProps = React.ComponentPropsWithoutRef<typeof Parts.Attribute> & {
    attribute: BoardViewAttribute
    isEmpty?: boolean
}

export const BaseAttribute: React.FC<BaseAttributeProps> = React.memo(function BaseAttribute({
    children,
    isEmpty,
    attribute,
    ...props
}) {
    const { showLabel, titleTextSize, textSize, showIcon } = useBoardViewBaseAttributeState({
        attribute,
    })

    const { type } = attribute
    const isTitle = type === 'title'
    const isFooter = type === 'footer'

    if (isEmpty && !isTitle) return null

    return (
        <Parts.Attribute
            fontSize={{
                mobile: isTitle ? titleTextSize : 'bodyS',
                tablet: isTitle ? titleTextSize : textSize,
            }}
            lineHeight={{
                mobile: isTitle ? titleTextSize : 'bodyS',
                tablet: isTitle ? titleTextSize : textSize,
            }}
            letterSpacing={{
                mobile: isTitle ? titleTextSize : 'bodyS',
                tablet: isTitle ? titleTextSize : textSize,
            }}
            fontWeight={isTitle ? 'bodyBold' : 'bodyRegular'}
            {...props}
        >
            {showLabel && (
                <Box mb="3xs">
                    <Body
                        maxLength={LABEL_MAX_LENGTH}
                        maxLines={LABEL_MAX_LINES}
                        trim
                        size="s"
                        weight="medium"
                        color="gray900"
                    >
                        {attribute.title}
                    </Body>
                </Box>
            )}

            <Box
                minWidth={0}
                maxWidth="full"
                flex={showIcon || isFooter}
                gap={showIcon ? 'm' : 0}
                center={showIcon || isFooter}
            >
                {showIcon && <FieldIcon value={attribute.icon!} size="s" color="icon" noShrink />}
                {isEmpty ? (
                    <Body
                        size="m"
                        weight="bold"
                        color="textWeakest"
                        style={{
                            fontSize: 'inherit',
                            lineHeight: 'inherit',
                            letterSpacing: 'inherit',
                            fontWeight: 'inherit',
                            fontStyle: 'italic',
                            maxWidth: '100%',
                        }}
                        noShrink
                        trim
                        display="block"
                    >
                        None
                    </Body>
                ) : (
                    children
                )}
            </Box>
        </Parts.Attribute>
    )
})

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeRadioVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        size: {
            indicator: pxToRem(16),
            icon: pxToRem(8),
        },
        radius: {
            indicator: s.radius.pill,
            card: s.radius.xs,
        },
        borderWidth: s.borderWidths[1],
        borderStyle: 'solid',
        color: {
            surfaceUnchecked: s.color.surfaceSelectorOff,
            surfaceUncheckedDisabled: s.color.surfaceSelectorOffDisabled,
            surfaceChecked: s.color.surfaceSelectorOn,
            surfaceCheckedDisabled: s.color.surfaceSelectorOnDisabled,
            surfaceCheckedError: s.color.surfaceSelectorOnError,
            surfaceCheckedHover: s.color.surfaceSelectorOnHover,

            borderUnchecked: s.color.borderSelectorOff,
            borderUncheckedError: s.color.borderSelectorOffError,
            borderUncheckedHover: s.color.borderSelectorOffHover,
            borderChecked: s.color.borderSelectorOn,
            borderCheckedError: s.color.borderSelectorOnError,
            borderCheckedHover: s.color.borderSelectorOnHover,

            iconChecked: s.color.iconSelector,
            iconUncheckedHover: s.color.iconSelectorHover,

            cardSurface: s.color.white00,

            cardBorderUnchecked: s.color.gray200,
            cardBorderChecked: s.color.theme600,

            cardIconUnchecked: s.color.gray400,
            cardIconChecked: s.color.theme600,
        },
        shadows: {
            focus: s.shadows.focus,
        },
        gap: {
            label: s.space.m,
            betweenButtons: s.space.m,
            betweenCards: s.space.m,
            cardLabel: s.space.m,
        },
        padding: {
            cardIconWrapper: s.space.l,
        },
        fontSizes: {
            cardLabel: s.fontSizes.bodyS,
        },
        fontWeights: {
            cardLabelUnchecked: s.fontWeights.bodyRegular,
            cardLabelChecked: s.fontWeights.bodyBold,
        },
        lineHeights: {
            cardLabel: s.lineHeights.bodyS,
        },
    }
}

import React from 'react'

import { Text } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'

const TAB_COUNT_OPTIONS = [6, 8, 10, 12, 14]

type DetailViewVisibleTabCountInputProps = Omit<
    React.ComponentPropsWithoutRef<typeof Box>,
    'value' | 'onChange'
> & {
    value?: number
    onChange: (value: number) => void
}

export const DetailViewVisibleTabCountInput: React.FC<DetailViewVisibleTabCountInputProps> = ({
    value,
    onChange,
    ...props
}) => {
    return (
        <Box flex center {...props}>
            <Text variant="paletteOptionLabel" flexGrow={1}>
                Maximum number of visible tabs
            </Text>
            <Dropdown>
                <DropdownButton variant="secondary" size="xs">
                    {value ?? 'Pick a value'}
                    <Icon name="ChevronDown" ml="xs" size="xs" />
                </DropdownButton>
                <DropdownContent align="end">
                    {TAB_COUNT_OPTIONS.map((option) => (
                        <DropdownItem
                            key={option}
                            label={option}
                            multiSelect
                            checked={option === value}
                            onCheckedChange={(checked) => {
                                if (checked) onChange(option)
                            }}
                            closeOnSelect={true}
                        />
                    ))}
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}

import { formatValue } from 'data/utils/utils'
import { renderNumberValueAsText } from 'features/charts/NumberValue'
import { COLORS_ORDER, NONE_VALUE_SECTION_LABEL } from 'features/charts/recharts/Pie/constants'
import { getBucketDate } from 'features/charts/utils/getBucketDate'
import { getBucketLabel } from 'features/charts/utils/getBucketLabel'

import { ThemeConfig } from 'v2/ui/theme/styles/types'
import { AppColors } from 'v2/ui/theme/styles/types/colorBase'

type Parameters = {
    metrics: MetricsQueryResponse
    maxItems: number
    percentages: number[]
    display: ChartParamsDisplay
    config: ChartParamsConfig
    fields: FieldDto[] | undefined
}

export default function prepareData(
    data: MetricsQueryResponse['data'],
    { maxItems, percentages, display, metrics, config, fields }: Parameters,
    theme: ThemeConfig
) {
    const groupByField = fields?.find(({ _sid }) => _sid === config.group.field_sid)

    const chartData = data.slice(0, maxItems).map(({ key, value, others }, index) => ({
        name: key === null ? NONE_VALUE_SECTION_LABEL : transformKey(key, config, groupByField),
        value,
        formatted: renderNumberValueAsText({ value: value ?? 0, metrics, display, config }),
        _isOther: others,
        percentage: percentages[index],
        // this will hide the label if there are two consecutive small items
        showInLegend: percentages[index] < 4 && percentages[index - 1] < 5,
        color: getSectionColor(groupByField, key ?? NONE_VALUE_SECTION_LABEL, theme, index),
        key,
    }))

    return chartData.sort((a, b) => (a.name < b.name ? 1 : -1))
}

function getSectionColor(
    groupByField: FieldDto | undefined,
    name: string,
    theme: ThemeConfig,
    index: number
) {
    if (groupByField?.type !== 'dropdown' && groupByField?.type !== 'multi_select') {
        return getAppColor(theme, COLORS_ORDER[index % COLORS_ORDER.length] as keyof AppColors)
    }

    const fieldOption = groupByField.options?.options?.find(({ value }) => value === name)

    if (fieldOption?.color) {
        return getAppColor(theme, fieldOption.color as keyof AppColors)
    }

    return getAppColor(theme, COLORS_ORDER[index % COLORS_ORDER.length] as keyof AppColors)
}

function getAppColor(theme: ThemeConfig, colorName: keyof AppColors) {
    const colorMap = theme.colors.appColors[colorName] ?? theme.colors.appColors.blue
    return colorMap[500]
}

function transformKey(key: string, config: ChartParamsConfig, field?: FieldDto) {
    const formattedKey = (key && field && formatValue(field, key)) ?? key
    const bucketDate = getBucketDate(key, config?.group?.bucketBy)
    return config?.group?.bucketBy && bucketDate
        ? getBucketLabel(bucketDate, config?.group?.bucketBy)
        : String(formattedKey)
}

import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Redirect, useHistory } from 'react-router-dom'

import { Urls } from 'app/UrlService'
import { useAccounts } from 'data/hooks/accounts'
import { assertIsDefined } from 'data/utils/ts_utils'
import publicAsset from 'utils/publicAsset'
import { useTrackEvent } from 'utils/useTrackEvent'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { HelperText } from 'ui/components/Field/Field.parts'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useUpdateCrmContact } from './crmIntegration'
import { OnboardingFrame } from './OnboardingFrame'
import { OnboardingProgress } from './OnboardingProgress'

import {
    OnboardingButtonHeadingStyle,
    OnboardingButtonImageStyle,
    OnboardingButtonSelectedStyle,
    OnboardingButtonStyle,
    OnboardingButtonSubheadingStyle,
    OnboardingButtonTextContainerStyle,
    OnboardingInternalContainerStyle,
} from './OnboardingButtonStyles.css'

const generateLabel = (usecase: Usecase) => {
    switch (usecase) {
        case 'personal':
            return 'Project name'
        case 'startup':
            return 'Startup name'
        case 'client':
            return 'Agency or expert name'
        case 'business':
        default:
            return 'Company name'
    }
}

type Usecase = 'personal' | 'startup' | 'business' | 'client'
export function OnboardingWhatAreYouBuilding() {
    const { data: accounts } = useAccounts()
    const history = useHistory()
    const buttonSize = useResponsiveValue({ mobile: 'm', tabletLarge: 'l' }) as 'm' | 'l'
    const updateCrmContact = useUpdateCrmContact()
    const trackEvent = useTrackEvent()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        watch,
        control,
    } = useForm({
        defaultValues: {
            name: '',
            usecase: undefined,
        },
    })

    useEffect(() => {
        if (!sessionStorage.getItem('tracked_onboarding_start')) {
            trackEvent('onboarding_start')
            sessionStorage.setItem('tracked_onboarding_start', 'true')
        }
    }, [trackEvent])

    const [usecase] = watch(['usecase'])

    const onSubmit = async ({ name }: { name: string }) => {
        sessionStorage.setItem('onboardingCompanyName', name)
        assertIsDefined(usecase)
        await updateCrmContact({
            project_type: usecase,
            company_name: name,
        })
        history.push(Urls.OnboardingStart)
    }

    const buttonsContext = [
        {
            image: publicAsset('/static/media/stickynote.svg'),
            heading: 'Personal Project',
            subheading: 'eg. personal apps, clubs and societies',
            useCase: 'personal' as Usecase,
        },
        {
            image: publicAsset('/static/media/lamp-charge.svg'),
            heading: 'Creating a new startup',
            subheading: 'e.g. a new online marketplace, a new software product',
            useCase: 'startup' as Usecase,
        },
        {
            image: publicAsset('/static/media/briefcase.svg'),
            heading: 'Business app for my work',
            subheading: 'e.g. internal tool, CRM, customer portal, work tracker',
            useCase: 'business' as Usecase,
        },
        {
            image: publicAsset('/static/media/activity.svg'),
            heading: 'Building for a client',
            subheading: 'e.g. an agency or expert building for a client',
            useCase: 'client' as Usecase,
        },
    ]

    if (accounts?.length ?? 0 > 0) {
        return <Redirect to={Urls.Root} />
    }

    return (
        <OnboardingFrame
            headline="What are you building?"
            title="Get started with Stacker"
            footerContents={<OnboardingProgress steps={3} currentStep={1} />}
            previewContents={
                <Box flex column center gap="4xl">
                    <Body textAlign="center" weight="medium" size="l">
                        High-performing teams at <br />
                        companies worldwide build with Stacker
                    </Body>
                    <img
                        src={publicAsset('/static/media/client-logo-strip.png')}
                        alt="Client logos"
                    />
                </Box>
            }
        >
            <Box as="form" onSubmit={handleSubmit(onSubmit)} flex column gap="5xl">
                <Body size="l">
                    Select the option that best describes what you&apos;re building
                </Body>
                <Box flex column gap="m">
                    <Controller
                        name="usecase"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                            <>
                                {buttonsContext.map((button, index) => (
                                    <Button
                                        key={index}
                                        className={
                                            value === button.useCase
                                                ? OnboardingButtonSelectedStyle
                                                : OnboardingButtonStyle
                                        }
                                        onClick={() => onChange(button.useCase)}
                                    >
                                        <Box className={OnboardingInternalContainerStyle}>
                                            <Box className={OnboardingButtonImageStyle}>
                                                <img src={button.image} alt={button.heading} />
                                            </Box>
                                            <Box className={OnboardingButtonTextContainerStyle}>
                                                <Box className={OnboardingButtonHeadingStyle}>
                                                    {button.heading}
                                                </Box>
                                                {button.subheading && (
                                                    <Box
                                                        className={OnboardingButtonSubheadingStyle}
                                                    >
                                                        {button.subheading}
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    </Button>
                                ))}
                            </>
                        )}
                    />
                    {errors.usecase && <HelperText isError>Please select an option</HelperText>}
                </Box>
                {usecase && (
                    <Input
                        autoFocus
                        {...register('name', { required: true })}
                        isError={!!errors.name}
                        helperText={
                            errors.name
                                ? usecase === 'personal'
                                    ? 'Please enter a project name'
                                    : 'Please enter a company name'
                                : ''
                        }
                        label={generateLabel(usecase)}
                    />
                )}
                <Button
                    size={buttonSize}
                    type="submit"
                    alignSelf="stretch"
                    isLoading={isSubmitting}
                >
                    Continue
                </Button>
            </Box>
        </OnboardingFrame>
    )
}

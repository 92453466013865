import { useMemo, useRef } from 'react'

import { Mention } from '@tiptap/extension-mention'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { SuggestionOptions } from '@tiptap/suggestion'

import { renderSuggestionComponent } from './extensionHelpers'
import { MentionComponent } from './MentionComponent'
import { MentionsList } from './MentionsExtension'

import { MentionsItemStyles } from './MentionsExtension.css'

export type MentionsOptions = {
    fetchUsersFn: () => UserDto[]
}

export function createMentionsExtension(options: MentionsOptions) {
    return Mention.extend({
        addStorage() {
            return {
                mentions: () => [],
            }
        },
        onBeforeCreate() {
            this.storage.mentions = () => {
                const users = new Set<string>()

                this.editor.state.doc.descendants((node) => {
                    if (node.type.name === this.type.name) {
                        users.add(node.attrs.id)
                    }
                })

                return Array.from(users)
            }
        },
        addNodeView() {
            return ReactNodeViewRenderer(MentionComponent, {
                as: 'span',
            })
        },
        addOptions() {
            return {
                ...this.parent?.(),
                fetchUsersFn: options.fetchUsersFn,
            }
        },
    }).configure({
        HTMLAttributes: {
            class: MentionsItemStyles,
        },
        suggestion: {
            items: options.fetchUsersFn,
            render: () => {
                return renderSuggestionComponent(MentionsList)
            },
        } as Omit<SuggestionOptions<UserDto>, 'editor'>,
    })
}

export function useMentionsExtension(users: UserDto[]) {
    // The extension needs to only be created once, but the users can change.
    const usersRef = useRef(users)
    usersRef.current = users

    return useMemo(() => {
        return createMentionsExtension({
            fetchUsersFn: () => usersRef.current,
        })
    }, [])
}

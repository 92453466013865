export const getFaIconName = (name?: string) => {
    if (!name) return ''

    // if the icon name is already in the correct 'fa-icon-name' format;
    // replace the 'fa-' with '' to give -> ' icon-name'
    if (name.startsWith('fa-')) return name.replace('fa-', '')

    // takes icons in the format of 'faIconName' and converts them to ' icon-name'
    return name
        .replace('fa', '')
        .replace(/([a-zA-Z0-9_])(?=[A-Z])/g, '$1-')
        .toLowerCase()
}

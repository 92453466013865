// @ts-strict-ignore
import React from 'react'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'

import { ConfigurationLabel } from './WorkflowUI'

type StepIconInputProps = React.ComponentPropsWithoutRef<typeof Box> & {
    action: WorkflowActionConfig
    onChange: (action: Omit<Partial<WorkflowActionConfig>, 'id'>) => void
}

export const StepIconInput: React.FC<StepIconInputProps> = ({ action, onChange, ...props }) => {
    return (
        <Box {...props}>
            <ConfigurationLabel>Custom step icon URL</ConfigurationLabel>
            <Input
                value={action.icon?.url ?? ''}
                onChange={(e) => onChange({ icon: { url: e.target.value } })}
            />
        </Box>
    )
}

import React from 'react'

import publicAsset from 'utils/publicAsset'

export const LoadingSpinner = () => (
    <img
        alt=""
        src={publicAsset('/static/media/Spinner.svg')}
        style={{
            marginLeft: 0,
            marginRight: 8,
            height: 20,
        }}
    />
)

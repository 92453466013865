import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeToastVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surface: s.color.surfaceInverse,
            surfaceError: s.color.surfaceErrorStrong,
            surfaceSuccess: s.color.surfaceSuccessStrong,
            closeIcon: s.color.iconInverse,
            startIcon: s.color.borderInverse,
            title: s.color.textInverse,
            helper: s.color.lightAlpha700,
        },
        opacity: {
            dismissIcon: '0.5',
            dismissIconHover: '1',
            dismissIconFocus: '1',
        },
        sizes: {
            toastWidth: pxToRem(450),
        },
        gap: {
            vertical: s.space.m,
            horizontal: s.space.m,
            fromScreenEdge: s.space.xl,
            betweenToasts: s.space.l,
            dissmissIconFromEdge: `-${pxToRem(4)}`,
            betweenParts: s.space.l,
        },
        radius: {
            toast: s.radius.xl,
        },
        shadows: {
            toast: s.shadows.m,
        },
    }
}

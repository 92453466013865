import React from 'react'

import { DropdownContent, DropdownItem, DropdownSeparator } from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useActionMenuContentState } from './hooks/useActionMenuContentState'
import { ActionMenuItemActionButton } from './ActionMenuItemActionButton'
import { ActionMenuItemFavorite } from './ActionMenuItemFavorite'
import { ActionMenuSectionEdit } from './ActionMenuSectionEdit'

type ActionMenuContentProps = React.ComponentPropsWithoutRef<typeof DropdownContent> & {
    record: RecordDto
    actionButtons?: ActionDto[]
    additionalEditFields?: FieldDto[]
    includeFields: string[]
}

export const ActionMenuContent: React.FC<ActionMenuContentProps> = React.memo(
    function ActionMenuContent({
        record,
        actionButtons,
        additionalEditFields,
        includeFields,
        ...props
    }) {
        const {
            canEditRecord,
            userItems,
            navigationItems,
            managementItems,
            destructiveItems,
            editableFields,
        } = useActionMenuContentState({ record, actionButtons, additionalEditFields })

        return (
            <DropdownContent
                onKeyDown={stopPropagation}
                usePortal
                onPointerDown={stopPropagation}
                onClick={stopPropagation}
                style={{
                    minWidth: '295px',
                }}
                {...props}
            >
                {navigationItems.map((item) => (
                    <DropdownItem
                        key={item._sid}
                        startIcon={item.icon ? { name: item.icon } : undefined}
                        label={item.name}
                        onClick={item.onClick}
                    />
                ))}

                {!!userItems?.length && (
                    <>
                        {!!navigationItems.length && <DropdownSeparator />}

                        {userItems.map((action) => (
                            <ActionMenuItemActionButton
                                key={action._sid}
                                action={action}
                                record={record}
                                includeFields={includeFields}
                            />
                        ))}
                    </>
                )}

                {canEditRecord && (
                    <>
                        {(!!navigationItems.length || !!actionButtons?.length) && (
                            <DropdownSeparator />
                        )}
                        <ActionMenuSectionEdit record={record} editableFields={editableFields} />
                    </>
                )}

                {!!managementItems.length && (
                    <>
                        {(!!navigationItems?.length ||
                            !!actionButtons?.length ||
                            canEditRecord) && <DropdownSeparator />}

                        {managementItems.map((item, index) => {
                            if (item._sid === 'add_favorite') {
                                return <ActionMenuItemFavorite key={index} record={record} />
                            }

                            return (
                                <DropdownItem
                                    key={item._sid}
                                    startIcon={item.icon ? { name: item.icon } : undefined}
                                    label={item.name}
                                    onClick={item.onClick}
                                />
                            )
                        })}
                    </>
                )}

                {!!destructiveItems.length && (
                    <>
                        {(!!navigationItems?.length ||
                            !!actionButtons?.length ||
                            canEditRecord ||
                            !!managementItems.length) && <DropdownSeparator />}
                        {destructiveItems.map((item) => (
                            <DropdownItem
                                key={item._sid}
                                startIcon={item.icon ? { name: item.icon } : undefined}
                                label={item.name}
                                onClick={item.onClick}
                                variant="destructive"
                            />
                        ))}
                    </>
                )}
            </DropdownContent>
        )
    }
)

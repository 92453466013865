import React from 'react'

import classNames from 'classnames'

import { Icon as V2Icon } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { theme } from 'ui/styling/Theme.css'

import { NavigationAppMenuItem } from './types'

import { AppNavigationItemIconStyles } from './Navigation.css'

type NavigationMenuItemIconProps = React.ComponentPropsWithoutRef<typeof Box> & {
    menuItem: NavigationAppMenuItem
    isActive?: boolean
}

export const NavigationMenuItemIcon: React.FC<NavigationMenuItemIconProps> = ({
    className,
    menuItem,
    isActive,
    ...props
}) => {
    return (
        <Box
            {...props}
            className={classNames(
                className,
                AppNavigationItemIconStyles.styleFunction({ isActive })
            )}
        >
            {menuItem.icon?.family === 'legacy' && (
                <V2Icon
                    iconPack="far"
                    icon={menuItem.icon.name}
                    color="inherit"
                    fontSize={theme.icon.size.xs}
                />
            )}
        </Box>
    )
}

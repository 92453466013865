import React, { useCallback, useMemo } from 'react'

import { Moment } from 'moment-timezone'

import DateAttribute from 'v2/ui/components/Attribute/DateAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

import { formatDueDate, getDueDateValue } from './dueDateUtils'

type TasksDueDateEditorProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: string
    onChange: (value: string) => void
    isLoading?: boolean
    variant?: 'secondary' | 'ghost'
    isClearable?: boolean
    onBlur?: () => void
    onOpen?: () => void
    onClear?: () => void
    clearButtonProps?: Partial<React.ComponentPropsWithoutRef<typeof Icon>>
}

export const TasksDueDateEditor: React.FC<TasksDueDateEditorProps> = ({
    value,
    onChange,
    isLoading,
    variant = 'secondary',
    isClearable = true,
    onBlur,
    onOpen,
    onClear,
    clearButtonProps,
    ...props
}) => {
    const formattedDate = useMemo(() => formatDueDate(value), [value])
    const dateValue = useMemo(() => getDueDateValue(value), [value])

    const handleChange = useCallback(
        (value: string | Moment) => {
            let dateString = value
            if (typeof value !== 'string') {
                dateString = value.toISOString()
            }

            onChange(dateString as string)
        },
        [onChange]
    )

    const handleClear = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        handleChange('')
        onClear?.()
    }

    return (
        <Box onClick={stopPropagation} {...props}>
            {/* @ts-expect-error */}
            <DateAttribute
                mode={modes.editing}
                showTime={false}
                onChange={handleChange}
                disabled={isLoading}
                onBlur={onBlur}
                onOpen={onOpen}
                ignoreOnChangeFormatting={true}
                customInput={
                    <Button
                        type="button"
                        variant={variant}
                        size="s"
                        flex
                        center
                        disabled={isLoading}
                        style={{
                            fontWeight: theme.fontWeights.bodyRegular as any,
                        }}
                    >
                        <Icon
                            name="Calendar"
                            mr="2xs"
                            color={formattedDate ? 'text' : 'textWeak'}
                        />
                        {formattedDate ? (
                            <>
                                <Box as="span" trim fontSize="bodyS">
                                    {formattedDate}
                                </Box>
                                {isClearable && (
                                    <Icon
                                        name="X"
                                        ml="2xs"
                                        role="button"
                                        color="textWeak"
                                        onClick={handleClear}
                                        {...clearButtonProps}
                                    />
                                )}
                            </>
                        ) : (
                            <Box as="span" color="textWeaker" fontSize="bodyS">
                                set due date
                            </Box>
                        )}
                    </Button>
                }
            >
                {dateValue}
            </DateAttribute>
        </Box>
    )
}

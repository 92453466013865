import React, { useContext } from 'react'

import { Widget } from './types'

export type LayoutEditorWidgetContextValue = {
    widget: Widget
    isDragging: boolean
}
export const LayoutEditorWidgetContext = React.createContext<LayoutEditorWidgetContextValue | null>(
    null
)

export function useLayoutEditorWidgetContext(): LayoutEditorWidgetContextValue | null {
    return useContext(LayoutEditorWidgetContext)
}

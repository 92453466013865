import { useCallback } from 'react'

import { fetchWithAuth } from 'data/utils/fetchWithAuth'

const ENDPOINT = 'track-events/'

export type EventName = 'frontend_user_active' | 'onboarding_start' | 'onboarding_finish'
export const useTrackEvent = () => {
    return useCallback((eventName: EventName, additionalPayload = {}) => {
        fetchWithAuth(ENDPOINT, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ event_name: eventName, properties: additionalPayload }),
        })
    }, [])
}

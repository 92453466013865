import { useCallback, useMemo, useRef, useState } from 'react'

import { getRecommendedFieldSids } from 'features/views/ListView/Filters/utils'
import {
    getDefaultOperation,
    getDefaultValueForOperation,
    operationRequiresUserInput,
} from 'features/views/ListView/ListHeader/Filters/Advanced/operations'
import { AdvancedFilter } from 'features/views/ListView/ListHeader/Filters/Advanced/types'
import { useListHeaderContext } from 'features/views/ListView/ListHeader/useListHeaderContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type RecommendedField = {
    label: string
    field: FieldDto
}

export function useAdvancedFiltersState() {
    const {
        clearInlineFilters,
        inlineFilters,
        updateInlineFilter,
        availableInlineFilterFields,
        addInlineFilter,
        removeInlineFilter,
        view,
    } = useListHeaderContext()

    const isActive = inlineFilters.length > 0

    const [filterDraft, setFilterDraft] = useState<AdvancedFilter | null>(null)
    const currentDraftRef = useRef(filterDraft)
    currentDraftRef.current = filterDraft

    const onCommitDraft = useCallback(() => {
        const currentDraft = currentDraftRef.current
        if (!currentDraft) return

        addInlineFilter(currentDraft)
        setFilterDraft(null)
    }, [addInlineFilter])

    const onAddNewFilter = useCallback(
        (field: FieldDto) => {
            const defaultOperation = getDefaultOperation(field)
            if (!defaultOperation) return

            const defaultValue = getDefaultValueForOperation(field, defaultOperation)

            const newFilter: AdvancedFilter = {
                _id: Math.random(),
                field,
                field_sid: field._sid,
                options: {
                    operator: 'AND',
                    option: defaultOperation,
                    value: defaultValue,
                },
                isDraft: true,
            }

            const requiresValue = operationRequiresUserInput(field, defaultOperation)
            if (requiresValue) {
                setFilterDraft(newFilter)
            } else {
                // We can just commit this right away if it doesn't require a value.
                addInlineFilter(newFilter)
            }
        },
        [addInlineFilter]
    )

    const onUpdateFilter = useCallback(
        (filter: AdvancedFilter) => {
            if (filter.isDraft) {
                setFilterDraft(filter)
            } else {
                updateInlineFilter(filter)
            }
        },
        [updateInlineFilter]
    )

    const onRemoveFilter = useCallback(
        (id: number) => {
            const currentDraft = currentDraftRef.current
            if (currentDraft && currentDraft._id === id) {
                setFilterDraft(null)
            } else {
                removeInlineFilter(id)
            }
        },
        [removeInlineFilter]
    )

    const onClearFilters = useCallback(() => {
        clearInlineFilters()
        setFilterDraft(null)
    }, [clearInlineFilters])

    const fieldsBySid = useMemo(() => {
        return availableInlineFilterFields.reduce(
            (acc, field) => {
                acc[field._sid] = field
                return acc
            },
            {} as Record<string, FieldDto>
        )
    }, [availableInlineFilterFields])

    const existingAdvancedFilters: AdvancedFilter[] = inlineFilters.reduce((acc, filter) => {
        const field = fieldsBySid[filter.field_sid!]
        if (field) {
            acc.push({
                ...filter,
                field, // This may be missing if filters are restored from local storage.
                isDraft: false,
            })
        }

        return acc
    }, [] as AdvancedFilter[])

    const filters: AdvancedFilter[] = filterDraft
        ? [...existingAdvancedFilters, filterDraft]
        : existingAdvancedFilters
    const filtersMemo = useDeepEqualsMemoValue(filters)

    const recommendedFields: RecommendedField[] = useMemo(() => {
        const fieldsUsedInFilters = filtersMemo.reduce((acc, filter) => {
            if (!filter.field_sid) return acc

            return acc.add(filter.field_sid)
        }, new Set<string>())
        const recommendedFieldsSidsSet = new Set(getRecommendedFieldSids(view.options))

        return availableInlineFilterFields.reduce((acc, field) => {
            const isRecommended = recommendedFieldsSidsSet.has(field._sid)
            if (!isRecommended) return acc

            // Don't recommend fields that are already in use.
            if (fieldsUsedInFilters.has(field._sid)) {
                return acc
            }

            const column = view.options.columns?.find((column) => column.fieldId === field._sid)
            const label = column?.label || field.label

            acc.push({
                label,
                field,
            })

            return acc
        }, [] as RecommendedField[])
    }, [availableInlineFilterFields, filtersMemo, view.options])
    const recommendedFieldsMemo = useDeepEqualsMemoValue(recommendedFields)

    return useMemo(
        () => ({
            clearFilters: onClearFilters,
            isActive,
            onAddNewFilter,
            onUpdateFilter,
            onRemoveFilter,
            filters: filtersMemo,
            onCommitDraft,
            recommendedFields: recommendedFieldsMemo,
        }),
        [
            onClearFilters,
            isActive,
            onAddNewFilter,
            onUpdateFilter,
            onRemoveFilter,
            filtersMemo,
            onCommitDraft,
            recommendedFieldsMemo,
        ]
    )
}

import { useCallback, useMemo, useRef } from 'react'

import { makeQuickFilterValues } from 'features/views/ListView/Filters/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

import { useQuickFilter } from './useQuickFilter'

const MAX_ITEM_LENGTH = 25
const MAX_ITEMS = 2

type FilterOption = {
    label: string
    value: string
}

type UseQuickFilterDropdownStateOptions = {
    field: FieldDto
}

export function useQuickFilterDropdownState(options: UseQuickFilterDropdownStateOptions) {
    const { field } = options

    const { value: filters, setFiltersValue, isActive, label: defaultLabel } = useQuickFilter(field)

    const filterValue = filters[0]
    const filterValueRef = useRef(filterValue)
    filterValueRef.current = filterValue

    const onFilterRemove = useCallback(() => {
        setFiltersValue([])
    }, [setFiltersValue])

    const onSetFilterValue = useCallback(
        (value: string, isEnabled: boolean) => {
            const filterValue = filterValueRef.current
            const existingValue = (filterValue?.options.value as string[]) ?? []

            let newValue: string[] = []
            if (isEnabled) {
                newValue = [...existingValue, value]
            } else {
                newValue = existingValue.filter((v) => v !== value)
            }

            // Clear the filter if no values are selected.
            if (newValue.length < 1) {
                setFiltersValue([])
                return
            }

            setFiltersValue(makeQuickFilterValues(field, newValue))
        },
        [field, setFiltersValue]
    )

    const filterOptions = makeFilterOptions(field)

    const internalValue = useDeepEqualsMemoValue((filterValue?.options.value as string[]) ?? [])
    const value = useMemo(() => new Set(internalValue), [internalValue])

    const label = formatLabel(value, filterOptions, defaultLabel)

    return useMemo(
        () => ({ value, isActive, label, onFilterRemove, onSetFilterValue, filterOptions }),
        [value, isActive, label, onFilterRemove, onSetFilterValue, filterOptions]
    )
}

function makeFilterOptions(field: FieldDto): FilterOption[] {
    if (!field.options?.options) return []

    return field.options.options?.map((option) => ({
        label: truncateText(option.label ?? option.value ?? '', MAX_ITEM_LENGTH),
        value: option.value ?? '',
    }))
}

function formatLabel(value: Set<string>, filterOptions: FilterOption[], defaultLabel: string) {
    if (value.size < 1) {
        return defaultLabel
    }

    const activeOptions = filterOptions.filter((option) => value.has(option.value))

    const overflowingItemCount = activeOptions.length - MAX_ITEMS
    const truncatedOptions = activeOptions.slice(0, MAX_ITEMS)

    const formattedItems = truncatedOptions.map((option) => option.label).join(', ')

    if (overflowingItemCount > 0) {
        return `${formattedItems} +${overflowingItemCount}`
    }

    return formattedItems
}

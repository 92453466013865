import React, { useState } from 'react'

import { useActionInputConfigManager } from 'features/workflows/useActionInputConfigManager'
import { WorkflowExpressionInput } from 'features/workflows/WorkflowExpressionInput'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownTitle,
} from 'ui/components/Dropdown'
import { MenuIcon } from 'ui/components/Dropdown/Dropdown.parts'
import { Icon } from 'ui/components/Icon'
import { IconName } from 'ui/components/Icon/Icon'
import { Tooltip } from 'ui/components/Tooltip'

type ActionInputProps = {
    input: WorkflowSchemaNodeInput
    action: WorkflowActionConfig
    contextSchema: WorkflowSchemaStateItem[]
    onChange: (action: Omit<Partial<WorkflowActionConfig>, 'id'>) => void
    path?: string[]
}
export function ActionInput({ path, input, ...props }: ActionInputProps) {
    const { action, contextSchema, onChange } = props

    const { setConfigAtPath, getConfigAtPath } = useActionInputConfigManager(action, onChange)
    const id = input.id
    const effectivePath = path ? [...path, id] : [id]
    const config = getConfigAtPath(effectivePath)
    const inputValue = config?.value

    return (
        <>
            <ConfigurationLabel>
                <Box flex alignItems="flex-end" justifyContent="space-between">
                    {input.name}
                    <InputModeSelector
                        input={input}
                        mode={config?.mode}
                        onChange={(mode) => setConfigAtPath(effectivePath, undefined, { mode })}
                    />
                </Box>
            </ConfigurationLabel>
            <WorkflowExpressionInput
                contextSchema={contextSchema}
                value={inputValue}
                label={input.name}
                returnType={input.type}
                returnExtraOptions={input.extra_options}
                onChange={(value: WorkflowExpression | undefined) =>
                    setConfigAtPath(effectivePath, value, undefined)
                }
                placeholder={input.placeholder}
            />
        </>
    )
}

function InputModeSelector({
    mode,
    onChange,
    input,
}: {
    mode?: WorkflowActionConfigInput['mode']
    onChange: (mode: WorkflowActionConfigInput['mode'] | undefined) => void
    input: WorkflowSchemaNodeInput
}) {
    const [isOpen, setIsOpen] = useState(false)

    const modes = getModes(input)

    const selectedMode = modes?.find((m) => m.id === mode) || modes?.[0]
    if (!modes?.length) return null
    return (
        <Box flex center>
            <Box color="textWeakest" fontSize="bodyXs" mr="xs">
                Mode:
            </Box>
            <Dropdown open={isOpen} onOpenChange={setIsOpen}>
                <Tooltip content={!isOpen ? selectedMode?.description : undefined} asChild>
                    <DropdownButton variant="ghost" size="xs" iconOnly>
                        {selectedMode?.icon && <Icon name={selectedMode?.icon} />}
                    </DropdownButton>
                </Tooltip>
                <DropdownContent align="end">
                    <DropdownTitle>Select mode</DropdownTitle>
                    {modes.map(({ id, label, icon, description }) => (
                        <DropdownItem
                            key={id}
                            background={id === mode ? 'surfaceStronger' : undefined}
                            onClick={() => onChange(id as MultiValueInputMode)}
                            aria-checked={id === mode}
                            label={
                                <Box flex column>
                                    <Box flex center>
                                        <MenuIcon name={icon} mr="m" />

                                        {label}
                                    </Box>

                                    <Box flex center>
                                        <Icon name={icon} mr="m" opacity={0} />
                                        <Box fontSize="bodyXs" color="textWeaker">
                                            {description}
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        />
                    ))}
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}

type ModeDefinition = {
    id: string
    icon: IconName
    label: string
    description: JSX.Element
    // ability to filter modes based on the action type
    applicable_action_types?: string[]
}
function getModes(input: WorkflowSchemaNodeInput): ModeDefinition[] | null {
    switch (input.extra_options?.allowed_input_modes) {
        case 'multi_value_modes':
            return [
                {
                    id: 'set',
                    icon: 'Equal',
                    label: 'Set items',
                    description: (
                        <>
                            <Box fontWeight="bodyBold" display="inline">
                                {input.name}
                            </Box>{' '}
                            will be set to the selected items
                        </>
                    ),
                },
                {
                    id: 'add',
                    icon: 'PlusSquare',
                    label: 'Add items',
                    description: (
                        <>
                            The selected items will be added to{' '}
                            <Box fontWeight="bodyBold" display="inline">
                                {input.name}
                            </Box>
                        </>
                    ),
                },
                {
                    id: 'remove',
                    icon: 'MinusSquare',
                    label: 'Remove items',
                    description: (
                        <>
                            The selected items will be removed from{' '}
                            <Box fontWeight="bodyBold" display="inline">
                                {input.name}
                            </Box>
                        </>
                    ),
                },
            ]
        default:
            return null
    }
}

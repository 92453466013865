import React, { useContext } from 'react'

import { useRecordEditManagerContext } from 'features/records/useRecordEditManagerContext'
import { ListViewSort } from 'features/views/ListView/Sort/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { TableViewCalculations } from './hooks/useTableViewCalculations'
import { TableViewColumn } from './types'

type TableViewInternalContextValue = {
    columns: TableViewColumn[]
    records?: RecordDto[]
    dereferencedRecords?: RecordDto[]
    recordCount: number
    isLoading: boolean
    isFetchingSlow: boolean
    retryFetchRecords: () => Promise<void>
    hasError: boolean
    object: ObjectDto
    stack: StackDto
    view: ViewDto
    currentPageIndex: number
    setCurrentPageIndex: (pageIndex: number) => void
    pageSize: number
    sortBy?: ListViewSort
    setSortBy: (sortBy?: ListViewSort) => void
    defaultSortBy?: ListViewSort
    clearFilters: () => void
    requestFilters: Filter[]
    requestIncludedFields: string[]
    hasFilters: boolean
    isEmbedded: boolean
    hasResizableColumns: boolean
    isRecordActionsColumnPinned: boolean
    onRecordClick: Required<ListViewOptions['onRecordClick']>
    thumbnailImageField?: FieldDto
    thumbnailImageAspectRatio?: '1:1' | '4:3'
    calculations?: TableViewCalculations
    isCalculationsFetchingSlow: boolean
    isCalculationsError: boolean
    header: ListViewHeader
    showColumnIcons: boolean
}
export type TableViewContextValue = TableViewInternalContextValue & {
    pendingRecords: RecordDto[]
}
export const TableViewContext = React.createContext<TableViewInternalContextValue | null>(null)

export function useTableViewContext(): TableViewContextValue {
    const context = useContext(TableViewContext)

    if (!context) {
        throw new Error('useTableViewContext must be used within a TableViewContextProvider')
    }

    const { records, pendingNewRecords } = useRecordEditManagerContext()

    let isLoading = context.isLoading
    // On the initial record load, it may take a few frames
    // until the record manager picks up the first batch of records.
    if (records.length < 1 && !!context.records?.length) {
        isLoading = true
    }

    const pendingRecords = pendingNewRecords.map((record) => record.record)

    return useDeepEqualsMemoValue({
        ...context,
        isLoading,
        pendingRecords,
    })
}

// @ts-strict-ignore
import React, { useEffect, useMemo, useState } from 'react'

import { PickerResponse } from 'filestack-js'
import isEqual from 'lodash/isEqual'

import { useAppUserContext } from 'app/useAppUserContext'
import { ActivityType } from 'data/hooks/activityTypes'
import { TaskDescription, TaskPayload, UpdateTaskPayload } from 'data/hooks/tasks/types'
import { ActivityFeed } from 'features/Activity/ActivityFeed'
import { FeedControls } from 'features/Activity/FeedControls'
import { useActivityFeedConfig } from 'features/Activity/useActivityFeedConfig'
import { AttachmentGallery } from 'features/attachments/AttachmentGallery'
import { useAttachments } from 'features/attachments/useAttachments'

import { useToast } from 'v2/ui/components/useToast'
import ReactFilestack from 'v2/ui/ReactFilestack'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Input } from 'ui/components/Input'

import { getBlobFromClipboardEvent } from './attachmentsUtils'
import { validateNewTask } from './quickCreateUtils'
import { TaskRelatedToDisplay } from './TaskRelatedToDisplay'
import { TasksAssigneesEditor } from './TasksAssigneesEditor'
import { TasksCheckboxEditor } from './TasksCheckboxEditor'
import { TasksDescriptionEditor } from './TasksDescriptionEditor'
import { TasksDueDateEditor } from './TasksDueDateEditor'
import { TaskAssignee } from './types'
import { useTaskListContext } from './useTaskListContext'

export type TasksEditModeProps = React.ComponentPropsWithoutRef<typeof Container> & {
    task: TaskPayload
    onSave?: () => void
    onClose?: () => void
}

export const TasksEditMode: React.FC<TasksEditModeProps> = ({
    task,
    onSave,
    onClose,
    ...props
}) => {
    const { user: currentUser } = useAppUserContext()
    const { updateTask, _object_id } = useTaskListContext()
    const currentUserId = currentUser?._sid

    const [title, setTitle] = useState(task.title)
    const [description, setDescription] = useState<TaskDescription>(task.description)
    const [dueDate, setDueDate] = useState(task.due_at ?? '')
    const [assignees, setAssignees] = useState<TaskAssignee[]>(task.assignees)
    const [isCompleted, setIsCompleted] = useState(task.is_completed)

    const [validationMessage, setValidationMessage] = useState('')
    const { related_to_stack } = task
    const toast = useToast()

    const {
        attachments,
        uploadAttachment,
        uploadingAttachments,
        removeAttachment,
        onAttachmentUploaded,
    } = useAttachments(task.attachments ?? [])

    const handleFilestackUpload = (data: PickerResponse) => {
        for (const file of data.filesUploaded) {
            onAttachmentUploaded(file.url, {
                filename: file.filename,
                mimetype: file.mimetype,
                size: file.size,
                url: file.url,
            })
        }
    }

    const hasChanges = useMemo(() => {
        return (
            title !== task.title ||
            description !== task.description ||
            dueDate !== (task.due_at ?? '') ||
            isCompleted !== task.is_completed ||
            !isEqual(assignees, task.assignees) ||
            !isEqual(attachments, task.attachments ?? [])
        )
    }, [
        title,
        description,
        dueDate,
        isCompleted,
        assignees,
        attachments,
        task.title,
        task.description,
        task.due_at,
        task.is_completed,
        task.assignees,
        task.attachments,
    ])

    const handleSubmit = async () => {
        if (!currentUserId) return Promise.resolve()

        const validationMessage = validateNewTask({
            title,
            description,
            dueDate,
            assignees,
            related_to: task.related_to,
        })
        setValidationMessage(validationMessage)

        if (validationMessage.length > 0) {
            return Promise.resolve()
        }

        try {
            if (hasChanges) {
                const payload: UpdateTaskPayload = {
                    taskId: task.auto_id,
                    title,
                    description: description as string,
                    due_at: dueDate || null,
                    assignees,
                    _object_id,
                    attachments,
                }

                if (isCompleted !== task.is_completed) {
                    payload.is_completed = isCompleted
                }

                await updateTask(payload)

                toast({ title: 'Task updated successfully.', status: 'success' })
            }

            onSave?.()
        } catch {
            toast({
                title: 'There was a problem updating the task. Please try again later.',
                status: 'error',
            })
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            handleSubmit()
        }
    }

    const handlePaste = (e: React.ClipboardEvent) => {
        const blob = getBlobFromClipboardEvent(e)
        if (blob) uploadAttachment(blob)
    }

    const isSubmitButtonDisabled = uploadingAttachments.length > 0 || !hasChanges

    const { contextKey, target, commentCount } = useActivityFeedConfig({ task })

    const [showFeed, setShowFeed] = useState(false)
    useEffect(() => {
        if (commentCount !== 0) setShowFeed(true)
    }, [commentCount])

    const onClickAddComment = (e: React.MouseEvent) => {
        e.stopPropagation()
        setShowFeed(true)
    }

    return (
        <Container
            width="full"
            {...props}
            style={{
                padding: 0,
            }}
        >
            <Box
                as="form"
                px={props.px ?? 'l'}
                py="l"
                onSubmit={(e: React.FormEvent) => {
                    e.preventDefault()
                    handleSubmit()
                }}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
            >
                <Box flex center mb="m">
                    <TasksCheckboxEditor value={isCompleted} onChange={setIsCompleted} mr="m" />
                    <Input
                        width="full"
                        variant="borderless"
                        placeholder="Task name"
                        size="l"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        autoFocus
                    />
                    {target?.relatedTo && (
                        <FeedControls
                            ml="l"
                            noShrink
                            relatedTo={target?.relatedTo}
                            objectId={target?.objectId}
                            stackId={target?.stackId}
                            alignSelf="center"
                        />
                    )}
                </Box>
                <TasksDescriptionEditor value={description} onChange={setDescription} grow />
                <AttachmentGallery
                    attachments={attachments}
                    onDeleteAttachment={removeAttachment}
                    uploadingAttachments={uploadingAttachments}
                    onUploaded={onAttachmentUploaded}
                    size="md"
                    mt="l"
                />
                {task.related_to && (
                    <TaskRelatedToDisplay
                        related_to={task.related_to}
                        related_to_type={task.related_to_type}
                        related_to_stack={related_to_stack}
                        my="xs"
                        maxWidth="75%"
                    />
                )}
                <Box flex justifyContent="space-between" mt="xs">
                    <Box flex center gap="m">
                        <TasksAssigneesEditor
                            compact
                            value={assignees}
                            onChange={setAssignees}
                            currentUserId={currentUserId}
                            related_to_stack={related_to_stack ?? undefined}
                        />
                        <TasksDueDateEditor value={dueDate} onChange={setDueDate} />
                        <Box>
                            <ReactFilestack
                                options={{
                                    maxFiles: 5,
                                }}
                                onSuccess={handleFilestackUpload}
                                customRender={({ onPick }) => {
                                    return (
                                        <Button
                                            variant="secondary"
                                            size="s"
                                            startIcon={{ name: 'Paperclip' }}
                                            onClick={onPick}
                                            noShrink
                                            type="button"
                                        />
                                    )
                                }}
                            />
                        </Box>
                        {!showFeed && (
                            <Button
                                variant="secondary"
                                size="s"
                                startIcon={{ name: 'MessagesSquare' }}
                                noShrink
                                type="button"
                                onClick={onClickAddComment}
                            >
                                add comment
                            </Button>
                        )}
                    </Box>
                    <Box flex gap="s">
                        <Button variant="ghost" onClick={onClose} type="button" size="s">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitButtonDisabled} size="s">
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>

            {validationMessage.length > 0 && (
                <Banner
                    icon={{
                        name: 'AlertTriangle',
                    }}
                    mt="xl"
                    title={validationMessage}
                    type="warning"
                />
            )}

            {showFeed && (
                <Box background="surfaceStrong" pt="m" pb="m" px={props.px ?? 3}>
                    <ActivityFeed
                        contextKey={contextKey}
                        target={target}
                        params={{ types: [ActivityType.Comment] }}
                        feedWrapperProps={{
                            maxHeight: '30vh',
                            overflowY: 'auto',
                            marginLeft: '-5px',
                            marginRight: '-5px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            width: 'auto',
                            minHeight: 'auto',
                        }}
                        showFilters={true}
                        showPlaceholder={false}
                        showControls={false}
                    />
                </Box>
            )}
        </Container>
    )
}

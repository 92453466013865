import React from 'react'

import { StackIcon } from 'features/AppBar/StackIcon'

import { Box, BoxProps } from 'ui/components/Box/Box'
type StackIconBadgeProps = BoxProps & { stack: StackDto; size: number }
export function StackIconBadge({ stack, size, style, ...props }: StackIconBadgeProps) {
    return (
        <Box
            flex
            column
            center
            justifyContent="center"
            style={{
                ...style,
                backgroundColor: stack.options.theme.brandColor,
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: `20%`,
            }}
            noShrink
            {...props}
        >
            <StackIcon stack={stack} isSelected theme={{ theme: 'light' }} size={`${size / 2}px`} />
        </Box>
    )
}

import React, { useMemo } from 'react'
import Linkify from 'react-linkify'

import { TaskDescription } from 'data/hooks/tasks/types'

import { Box } from 'ui/components/Box'

import { getDescriptionPreview } from './descriptionUtils'
type TasksDescriptionProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: TaskDescription
}

export const TasksDescription: React.FC<TasksDescriptionProps> = ({ value, ...props }) => {
    const textContent = typeof value === 'string' ? value : (value.plainTextContent ?? '')

    const descriptionPreview = useMemo(() => getDescriptionPreview(value), [value])

    return (
        <Box color="textWeaker" title={textContent} {...props}>
            <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a
                        target="_blank"
                        href={decoratedHref}
                        key={key}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {decoratedText}
                    </a>
                )}
            >
                {descriptionPreview}
            </Linkify>
        </Box>
    )
}

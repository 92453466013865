import React from 'react'

import { ContextGroup, ContextSchema } from 'features/expressions/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { ConditionGroupItemInput } from './ConditionGroupItemInput'
import { ConditionGroupOperatorInput } from './ConditionGroupOperatorInput'
import {
    ConditionClause,
    ConditionGroup,
    ConditionGroupOperator,
    ConditionGroupOperatorOptions,
} from './types'

const DEFAULT_GROUP: ConditionGroup = {
    clauses: [],
    operator: ConditionGroupOperatorOptions.And.value,
}

type ConditionGroupInputProps = {
    contextSchema: ContextSchema
    value?: ConditionGroup
    onChange: (value: ConditionGroup) => void
    renderGroupTitle: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
    disabled?: boolean
}

export const ConditionGroupInput: React.FC<ConditionGroupInputProps> = ({
    contextSchema,
    value,
    onChange,
    renderGroupTitle,
    disabled,
}) => {
    const patchClause = (idx: number, clause: ConditionClause) => {
        const newClauses = value?.clauses.slice() ?? []
        newClauses[idx] = clause
        onChange({
            ...DEFAULT_GROUP,
            ...value,
            clauses: newClauses,
        })
    }

    const addClause = () => {
        const newClauses = value?.clauses.slice() ?? []
        newClauses.push({
            left: null,
            comparison: 'equals',
            right: null,
        })

        onChange({
            ...DEFAULT_GROUP,
            ...value,
            clauses: newClauses,
        })
    }

    const deleteClause = (idx: number) => {
        const newClauses = value?.clauses.slice() ?? []
        newClauses.splice(idx, 1)

        onChange({
            ...DEFAULT_GROUP,
            ...value,
            clauses: newClauses,
        })
    }

    const setOperator = (operator: ConditionGroupOperator) => {
        onChange({
            ...DEFAULT_GROUP,
            ...value,
            operator,
        })
    }

    return (
        <Box flex column gap="m">
            {value?.clauses.map((item, idx, clausesList) => {
                const clausesLen = clausesList.length
                const hasOtherConditions = clausesLen > 1 && idx < clausesLen - 1

                return (
                    <React.Fragment key={idx}>
                        <ConditionGroupItemInput
                            idx={idx}
                            contextSchema={contextSchema}
                            value={item}
                            onChange={(clause) => patchClause(idx, clause)}
                            onDelete={() => deleteClause(idx)}
                            renderGroupTitle={renderGroupTitle}
                            disabled={disabled}
                        />
                        {hasOtherConditions && (
                            <ConditionGroupOperatorInput
                                value={value?.operator}
                                onChange={setOperator}
                                // readOnly={idx > 0}
                                readOnly={true}
                                pl="xs"
                                disabled={disabled}
                            />
                        )}
                    </React.Fragment>
                )
            })}
            <div>
                <Button
                    type="button"
                    variant="secondary"
                    startIcon={{ name: 'Plus' }}
                    onClick={addClause}
                    disabled={disabled}
                    size="s"
                >
                    Add condition
                </Button>
            </div>
        </Box>
    )
}

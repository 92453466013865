import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query'

import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'

import { queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import handleErrorResponse from 'data/utils/handleErrorResponse'
import { buildUrl, fetchAndReturn } from 'data/utils/utils'

const LIST_NAME = 'workflows'

type WorkflowsData = WorkflowDto[]
export type WorkflowSchema = {
    version: number
    nodes: WorkflowSchemaNodeType[]
    triggers: WorkflowSchemaTriggerType[]
}

function useQueryKey() {
    return useQueryKeyBuilder([LIST_NAME], { includeAuthKeys: true, includeStackId: true })
}

export function useWorkflows(options: UseQueryOptions<WorkflowsData> = {}) {
    const queryKey = useQueryKey()
    const queryResult = useQuery<WorkflowsData>(
        queryKey,
        async () => {
            const results = (await fetchAndReturn(`flows/`)) as WorkflowsData
            return results
        },
        options
    )

    return queryResult
}

export function useWorkflowSchema(options: UseQueryOptions<WorkflowSchema> = {}) {
    const queryKey = useQueryKeyBuilder(['workflow_schema'], { includeAuthKeys: true })
    const queryResult = useQuery<WorkflowSchema>(
        queryKey,
        async () => {
            return (await fetchAndReturn(`flows/schema/`)) as WorkflowSchema
        },
        options
    )

    return queryResult
}

export type WorkflowPatch = Omit<Partial<WorkflowDto>, '_sid' | '_object_id'>

export function useCreateWorkflow(
    options: UseMutationOptions<WorkflowDto, unknown, WorkflowPatch> = {}
) {
    const queryKey = useQueryKey()

    return useMutation(
        async (workflow: WorkflowPatch) => {
            return fetchAndReturn('flows/', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(workflow),
            }) as Promise<WorkflowDto>
        },
        {
            ...options,
            onSuccess: async (workflow, payload, context) => {
                await queryClient.cancelQueries({ queryKey })

                const previousPayload = queryClient.getQueryData<WorkflowsData>(queryKey) ?? []
                const updatedPayload: WorkflowsData = [...previousPayload, workflow]
                queryClient.setQueryData(queryKey, updatedPayload)

                options?.onSuccess?.(workflow, payload, context)
            },
            onSettled: () => {
                queryClient.invalidateQueries([LIST_NAME])
            },
        }
    )
}

export type UseUpdateWorkflowPayload = {
    id: string
    workflow: WorkflowPatch
}

export function useUpdateWorkflow(
    options: UseMutationOptions<WorkflowDto, unknown, UseUpdateWorkflowPayload> = {}
) {
    return useMutation(
        async (payload: UseUpdateWorkflowPayload) => {
            return fetchAndReturn(`flows/${payload.id}/`, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(payload.workflow),
            }) as Promise<WorkflowDto>
        },
        {
            ...options,
            onSettled: () => {
                queryClient.invalidateQueries([LIST_NAME])
            },
        }
    )
}

export function useDeleteWorkflow(options: UseMutationOptions<unknown, unknown, string> = {}) {
    const queryKey = useQueryKey()

    return useMutation(
        async (sid: string) => {
            const res = await fetchWithAuth(`flows/${sid}/`, {
                method: 'DELETE',
            })

            if (res.status >= 400) {
                handleErrorResponse(res)
                return Promise.reject(res)
            }

            return Promise.resolve()
        },
        {
            ...options,
            onMutate: async (sid: string) => {
                await queryClient.cancelQueries({ queryKey })

                const previousPayload = queryClient.getQueryData<WorkflowsData>(queryKey)
                if (previousPayload) {
                    const updatedPayload: WorkflowsData = previousPayload.filter(
                        (item) => item._sid !== sid
                    )
                    queryClient.setQueryData(queryKey, updatedPayload)
                }

                return { previousPayload }
            },
            onError: (err, payload, context?: { previousPayload?: WorkflowsData }) => {
                queryClient.setQueryData(queryKey, context?.previousPayload)

                options?.onError?.(err, payload, context)
            },
            onSettled: () => {
                queryClient.invalidateQueries([LIST_NAME])
            },
        }
    )
}

type WorkflowExecutionsData = WorkflowExecutionDto[]

export type UseWorkflowExecutionsFilters = {
    flow_id?: string
}

export function useWorkflowExecutions(
    filters: UseWorkflowExecutionsFilters = {},
    options: UseQueryOptions<WorkflowExecutionsData> = {}
) {
    const queryKey = useQueryKeyBuilder([LIST_NAME, filters, 'executions'], {
        includeAuthKeys: true,
        includeStackId: true,
    })

    const queryResult = useQuery<WorkflowExecutionsData>(
        queryKey,
        async () => {
            const url = buildUrl('flows/executions/', filters, true)
            return fetchAndReturn(url) as Promise<WorkflowExecutionsData>
        },
        { ...options, refetchOnMount: 'always' }
    )

    return queryResult
}

export type UseDuplicateWorkflowPayload = {
    name: string
    workflow: WorkflowDto
}

export function useDuplicateWorkflow(
    options: UseMutationOptions<WorkflowDto, unknown, unknown, WorkflowDto> = {}
) {
    const { mutateAsync: createWorkflow } = useCreateWorkflow()

    return useMutation(async (payload: UseDuplicateWorkflowPayload) => {
        const newWorkflow = cloneWorkflow(payload.name, payload.workflow)

        return createWorkflow(newWorkflow)
    }, options)
}

function cloneWorkflow(name: string, original: WorkflowDto): WorkflowPatch {
    const newWorkflow = cloneDeep(omit(original, ['_sid']))
    newWorkflow.name = name

    return newWorkflow
}

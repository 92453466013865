import { useCallback, useState } from 'react'

import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import useLDFlags from './useLDFlags'
const useLDIdentify = (bypassCache = false) => {
    const [currentAccountId, setCurrentAccountId] = useState<string | null>()
    const ldClient = useLDClient()
    const { flags: oldFlags } = useLDFlags()
    return useCallback(
        (workspaceAccount: Account | undefined): Promise<LDFlagSet> => {
            if (workspaceAccount && (bypassCache || workspaceAccount._sid != currentAccountId)) {
                if (ldClient) {
                    setCurrentAccountId(workspaceAccount._sid)
                    const context = {
                        key: workspaceAccount._sid,
                        kind: 'workspace',
                        slug: workspaceAccount.slug,
                        base_url: workspaceAccount.base_url,
                        name: workspaceAccount.name,
                        created_date: workspaceAccount.created_date,
                    }
                    return ldClient?.identify(context)
                } else {
                    return Promise.resolve(oldFlags)
                }
            } else {
                return Promise.resolve(oldFlags)
            }
        },
        [currentAccountId, oldFlags, ldClient, bypassCache]
    )
}
export default useLDIdentify

import { FieldIcon } from 'data/utils/fieldDefinitions'
import { getEditableFieldTypeDefinition } from 'features/admin/fields/definitions/editableFieldTypeDefinitions'

export function getFieldIcon(field?: FieldDto): FieldIcon | undefined {
    let icon = field?.options?.icon
    if (!icon) {
        return getDefaultFieldIcon(field)
    }

    return icon
}

export function getDefaultFieldIcon(field?: FieldDto): FieldIcon | undefined {
    const fieldTypeDef = field ? getEditableFieldTypeDefinition(field) : undefined

    switch (fieldTypeDef?.value) {
        case 'string':
            return { type: 'lucide', name: 'Baseline' }
        case 'long_text':
            return { type: 'lucide', name: 'Text' }
        case 'string_with_rich_text':
        case 'document':
            return { type: 'lucide', name: 'TextQuote' }
        case 'number':
        case 'autonumber':
            return { type: 'lucide', name: 'Calculator' }
        case 'lookup':
        case 'multi_lookup':
            return { type: 'lucide', name: 'Link' }
        case 'url':
            return { type: 'lucide', name: 'Globe' }
        case 'image':
            return { type: 'lucide', name: 'File' }
        case 'multi_file':
            return { type: 'lucide', name: 'Files' }
        case 'date':
        case 'created_at':
        case 'updated_at':
            return { type: 'lucide', name: 'Calendar' }
        case 'datetime':
            return { type: 'lucide', name: 'CalendarClock' }
        case 'checkbox':
            return { type: 'lucide', name: 'Check' }
        case 'dropdown':
            return { type: 'lucide', name: 'ChevronDownCircle' }
        case 'multi_select':
            return { type: 'lucide', name: 'ListChecks' }
        case 'currency':
            return { type: 'lucide', name: 'DollarSign' }
        case 'percentage':
            return { type: 'lucide', name: 'Percent' }
        case 'lookup_related_value':
            return { type: 'lucide', name: 'Search' }
        case 'rollup_related_records':
            return { type: 'lucide', name: 'Eye' }
        case 'formula':
            return { type: 'lucide', name: 'Sigma' }
        case 'updated_by':
        case 'created_by':
            return { type: 'lucide', name: 'User' }
        case 'record_id':
            return { type: 'lucide', name: 'Fingerprint' }
        default:
            return undefined
    }
}

// @ts-strict-ignore
import React, { ChangeEvent, FC, useState } from 'react'

import useLDFlags from 'data/hooks/useLDFlags'
import QuillRichText from 'features/pages/blocks/blockTypes/form/QuillRichText'

import { Box, Button, Divider, Flex, Input, ScrollBox, Text } from 'v2/ui'

import { usePatchableActionContext } from './hooks/usePatchableActionContext'
import { useActionsContext } from './actionHooks'
import { ActionStepsList } from './ActionStepsList'
import { addStep, buildStep } from './helpers'
import { Title } from './ui'

type Props = {
    onRequestClose: (arg?: string) => void
}

export const EditAction: FC<Props> = ({ onRequestClose }) => {
    const { updateAction, activeModal } = useActionsContext()
    const patchableActionContext = usePatchableActionContext()
    if (!patchableActionContext) {
        throw new Error('usePatchableActionContext must be used within a PatchableActionContext')
    }

    const { applyPatch, draftObject: action } = patchableActionContext

    const [name, setDisplayName] = useState(action.name)
    const [title, setTitle] = useState(action.options.title)
    const [description, setDescription] = useState(action.options.description)
    const [error, setError] = useState<{ [keyof: string]: string } | null>(null)

    const { flags } = useLDFlags()

    const onSaveAction = async () => {
        try {
            await updateAction(
                action._sid,
                Object.assign({}, action, {
                    name,
                    options: {
                        ...action.options,

                        title,
                        description,
                    },
                })
            )

            onRequestClose()
        } catch (error) {
            const errorJson = await error.json()
            setError(errorJson)
        }
    }

    if (activeModal !== 'editAction') {
        return null
    }

    return (
        <ScrollBox margin="-5px" padding="5px" height="100%">
            <Flex column wrap="nowrap" align="stretch" height="100%">
                <Text variant="formHelperText">
                    Update your content based on your users&apos; actions
                </Text>
                <Title>Button Name*</Title>
                <Input
                    value={name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setDisplayName(event.target.value)
                    }
                    placeholder="e.g. Approve"
                    maxLength={25}
                    isRequired
                />
                <Title>
                    <Flex>
                        <Box flex={1}>Choose your action</Box>
                    </Flex>
                </Title>
                <ActionStepsList steps={action.options.steps} />
                {flags?.multiStepActions && (
                    <Flex my={2}>
                        <Button
                            variant="adminPrimary"
                            buttonSize="sm"
                            icon="add"
                            mr={2}
                            onClick={() => {
                                applyPatch(
                                    addStep(
                                        action as ActionDto,
                                        buildStep('updateRecord', 'Update Record')
                                    )
                                )
                            }}
                        >
                            Add Step
                        </Button>
                        <Button
                            variant="adminSecondary"
                            buttonSize="sm"
                            onClick={() => {
                                applyPatch({
                                    ...action,
                                    options: { ...action.options, steps: [] },
                                })
                            }}
                        >
                            Clear All Steps
                        </Button>
                    </Flex>
                )}

                <Title>Title and Description</Title>
                <Input
                    value={title}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setTitle(event.target.value)
                    }
                    placeholder="Your Title"
                />
                <Divider my={2} />
                <QuillRichText
                    // @ts-expect-error
                    placeholder="Description of the action"
                    onChange={setDescription}
                    value={description}
                    convertToMarkdown={false}
                    height={'200px'}
                />
                <Divider my={4} />
                {error && (
                    <Flex>
                        <Text
                            variant="error"
                            flexGrow={1}
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            mb={4}
                        >
                            An error occurred.
                            {Object.entries(error).map(([field, error], index) => (
                                <div key={index}>
                                    {mapNameToLabel[field] || field}: {error}
                                </div>
                            ))}
                        </Text>
                    </Flex>
                )}
                <Flex style={{ gap: 8 }} justifyContent="flex-end">
                    <Button
                        variant="adminSecondary"
                        buttonSize="sm"
                        onClick={() => onRequestClose('check-for-unsaved-changes')}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="adminPrimary"
                        buttonSize="sm"
                        icon="check"
                        disabled={!name}
                        onClick={onSaveAction}
                    >
                        Save Button
                    </Button>
                </Flex>
            </Flex>
        </ScrollBox>
    )
}

const mapNameToLabel: { [keyof: string]: string } = {
    name: 'Display Name',
}

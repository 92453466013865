import React from 'react'

import { TableGrantForm } from 'features/users/app-users-modal/TableGrants/TableGrantForm'

import { Box } from 'ui/components/Box'

import { useAppUsersUpdateTableGroupState } from './hooks/useAppUsersUpdateTableGroupState'

type AppUsersUpdateTableGroupPaneProps = {}

export const AppUsersUpdateTableGroupPane: React.FC<AppUsersUpdateTableGroupPaneProps> = () => {
    const { onClose, selectedTableGrant } = useAppUsersUpdateTableGroupState()

    return (
        <Box p="3xl">
            <TableGrantForm onClose={onClose} tableGrant={selectedTableGrant} />
        </Box>
    )
}

import { useRef } from 'react'

import { useAuthContext } from 'app/AuthContext/AuthContext'

import analytics from './analytics'

export default function AnalyticsIdentifier() {
    const { user } = useAuthContext()
    const userId = user?._sid
    const identifiedUserId = useRef<string>()

    if (userId && userId !== identifiedUserId.current) {
        identifiedUserId.current = userId
        analytics.identify(userId)
    }
    return null
}

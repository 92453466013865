import React from 'react'

import { Icon } from 'ui/components/Icon'

type IntegrationsListItemProviderIconProps = Omit<
    React.ComponentPropsWithoutRef<typeof Icon>,
    'name' | 'variant'
> & {
    providerId: string
}

export const IntegrationProviderIcon: React.FC<IntegrationsListItemProviderIconProps> = ({
    providerId,
    ...props
}) => {
    switch (providerId) {
        case 'slack':
            return <Icon name="Slack" {...props} />

        default:
            return null
    }
}

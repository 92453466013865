import * as Parts from './Modal.parts'

export { Modal } from './Modal'
export { ModalContent } from './ModalContent'
export { ModalFooter } from './ModalFooter'
export { ModalHeader } from './ModalHeader'

export const ModalFooterButtonGroup = Parts.FooterButtonGroup
export const ModalContentInner = Parts.InnerContent
export const ModalTrigger = Parts.Trigger
export const ModalCloseTrigger = Parts.Close

export const ModalTabs = Parts.ModalTabs
export const ModalTabsTrigger = Parts.ModalTabsTrigger
export const ModalTabsList = Parts.ModalTabsList
export const ModalTabsContent = Parts.ModalTabsContent

export const ModalParts = Parts

import { useAppUsersContext } from 'features/users/AppUsers/useAppUsersContext'

export function useAppUsersUpdateTableGroupState() {
    const { resetPane, selectedTableGroup } = useAppUsersContext()

    return {
        onClose: resetPane,
        selectedTableGrant: selectedTableGroup?.tableGrant,
    }
}

import * as Primitives from '@radix-ui/react-progress'

import { HelperText as HelperTextComponent, LabelText } from 'ui/components/Field'
import { makeComponent } from 'ui/helpers/recipes'

import {
    ContentWrapperStyles,
    HelperTextStyles,
    IndicatorStyles,
    LabelStyles,
    OverflowDividerStyles,
    OverflowIndicatorStyles,
    ProgressStyles,
    ProgressWrapperStyles,
    RootStyles,
} from './Progress.css'

export const Root = makeComponent('div', RootStyles)
export const Label = makeComponent(LabelText, LabelStyles)
export const Progress = makeComponent(Primitives.Root, ProgressStyles)
export const ProgressWrapper = makeComponent('div', ProgressWrapperStyles)
export const Indicator = makeComponent(Primitives.Indicator, IndicatorStyles)
export const OverflowIndicator = makeComponent(Primitives.Indicator, OverflowIndicatorStyles)
export const OverflowDivider = makeComponent('div', OverflowDividerStyles)
export const ContentWrapper = makeComponent('div', ContentWrapperStyles)
export const HelperText = makeComponent(HelperTextComponent, HelperTextStyles)

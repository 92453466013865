import { Box } from 'ui/components/Box'
import { makeComponent } from 'ui/helpers/recipes'

import {
    TableCalculationItemStyles,
    TableCellStyles,
    TableHeaderStyles,
    TableRowStyles,
} from './TableView.css'

export const Row = makeComponent(Box, TableRowStyles)
export const Cell = makeComponent(Box, TableCellStyles)
export const Header = makeComponent(Box, TableHeaderStyles)

export const CalculationItem = makeComponent(Box, TableCalculationItemStyles)

import React from 'react'
import type {
    PolymorphicForwardRefExoticComponent,
    PolymorphicPropsWithoutRef,
    PolymorphicPropsWithRef,
} from 'react-polymorphic-types'

import { StandardComponentProps, transformStandardProps } from 'ui/helpers/styles'

declare type BoxOwnProps = StandardComponentProps & {}

export type BoxProps<T extends React.ElementType = 'div'> = PolymorphicPropsWithRef<BoxOwnProps, T>

export const Box: PolymorphicForwardRefExoticComponent<BoxOwnProps, 'div'> = React.forwardRef(
    function Box<T extends React.ElementType = 'div'>(
        { as, ...props }: PolymorphicPropsWithoutRef<BoxOwnProps, T>,
        ref: React.ForwardedRef<Element>
    ) {
        const Element: React.ElementType = as || 'div'
        const finalProps = transformStandardProps(props)
        return <Element ref={ref} {...finalProps} />
    }
)

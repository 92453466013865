import { max, min } from 'date-fns'

export function getAllMonths(
    locale: string,
    year: number,
    minValue?: Date,
    maxValue?: Date
): string[][] {
    const startMonth = minValue?.getFullYear() === year ? minValue.getMonth() : 0
    const endMonth = maxValue?.getFullYear() === year ? maxValue.getMonth() : 12

    const numericFormatter = new Intl.DateTimeFormat(locale, { month: 'numeric' })
    const formatter = new Intl.DateTimeFormat(locale, { month: 'long' })

    if (startMonth === endMonth) {
        return [
            [
                numericFormatter.format(new Date(year, startMonth)),
                formatter.format(new Date(year, startMonth)),
            ],
        ]
    }

    return Array.from({ length: endMonth - startMonth }, (_, i) => {
        const date = new Date(year, startMonth + i)
        return [numericFormatter.format(date), formatter.format(date)]
    })
}

export function constrainDateValue(date: Date, minValue?: Date, maxValue?: Date) {
    if (minValue) {
        date = max([date, minValue])
    }

    if (maxValue) {
        date = min([date, maxValue])
    }

    return date
}

const START_YEAR = 1900
const END_YEAR = 2100

export function getAllYears(locale: string, minValue?: Date, maxValue?: Date): string[] {
    const startYear = minValue?.getFullYear() ?? START_YEAR
    const endYear = maxValue?.getFullYear() ?? END_YEAR

    const formatter = new Intl.DateTimeFormat(locale, { year: 'numeric' })

    return Array.from({ length: endYear - startYear + 1 }, (_, i) => {
        const date = new Date(startYear + i, 0)
        return formatter.format(date)
    })
}

export function filterTime(date: Date, minValue?: Date, maxValue?: Date): boolean {
    if (minValue && date.getTime() < minValue.getTime()) {
        return false
    }
    if (maxValue && date.getTime() > maxValue.getTime()) {
        return false
    }

    return true
}

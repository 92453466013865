import React, { useEffect, useMemo, useState } from 'react'

import { RelatedToType } from 'data/hooks/activityTypes'

import usePrevious from 'v2/ui/hooks/usePrevious'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'
import { StandardComponentProps } from 'ui/helpers/styles'

import { ActivityTypesFilter } from './ActivityTypesFilter'
import { getActivityTypesForTarget } from './getActivityTypesForTarget'
import { StacksFilter } from './StacksFilter'
import { FeedItemRelationshipInfo, FeedParameters } from './types'
import { UsersFilter } from './UsersFilter'

type ActivityFeedFiltersProps = StandardComponentProps & {
    contextKey: string
    defaultFilters?: FeedParameters
    onFiltersChange: (filters: FeedParameters) => void
    target?: FeedItemRelationshipInfo
}

export function ActivityFeedFilters({
    contextKey,
    onFiltersChange,
    defaultFilters = {},
    target,
    ...props
}: ActivityFeedFiltersProps) {
    const [filters, setFilters] = useState<FeedParameters>(defaultFilters)
    const previousFilters = usePrevious(filters)

    const setSelectedStacks = (stackIds: string[]) => {
        if (!showStacks) return
        setFilters((value) => ({ ...value, stackIds }))
    }
    const setSelectedUsers = (userIds: string[]) => {
        setFilters((value) => ({ ...value, userIds }))
    }
    const setSelectedTypes = (types: number[]) => {
        setFilters((value) => ({ ...value, types }))
    }
    useEffect(() => {
        if (previousFilters !== filters) {
            onFiltersChange(filters)
        }
    }, [filters, onFiltersChange, previousFilters, contextKey])

    const showStacks = !target?.stackId && target?.relatedToType !== RelatedToType.Task

    const activityTypes = useMemo(() => getActivityTypesForTarget(target), [target])

    return (
        <Box flex stretch wrap {...props}>
            {/* <Divider orientation="vertical" mx="xs" my="m" /> */}
            {showStacks && (
                <>
                    <StacksFilter
                        selectedStackIds={filters.stackIds}
                        setSelectedStackIds={setSelectedStacks}
                    />

                    <Divider type="vertical" my="m" />
                </>
            )}
            <UsersFilter selectedUserIds={filters.userIds} setSelectedUserIds={setSelectedUsers} />
            <Divider type="vertical" my="m" />
            <ActivityTypesFilter
                activityTypes={activityTypes}
                selectedTypes={filters.types}
                setSelectedTypes={setSelectedTypes}
            />
        </Box>
    )
}

import React from 'react'

import { SelectOptionItem } from './types'

export type SelectContextValue = {
    isOpen: boolean
    value: string[]
    setValue: (value: string[]) => void
    setOptionState(value: string, checked: boolean): void
    clearValue(): void
    multiSelect: boolean
    options: SelectOptionItem[]
    close(): void
    isClearable: boolean
}
export const SelectContext = React.createContext<SelectContextValue>({
    isOpen: false,
    value: [],
    setValue: () => {},
    setOptionState: () => {},
    clearValue: () => {},
    multiSelect: false,
    options: [],
    close: () => {},
    isClearable: false,
})

export function useSelectContext() {
    return React.useContext(SelectContext)
}

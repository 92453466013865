/** @jsxRuntime classic */
/** @jsx jsx */
/* Code Quality: Not audited */

import React from 'react'

import { jsx } from '@emotion/react'
import get from 'lodash/get'
import { MAX_HIGHLIGHT_ITEMS } from 'v2/blocks/blockTypes/view/FieldContainerEditor/FieldHighlightsEditor'

import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'
import { useProcessFilter } from 'features/records/components/RecordFilters'

import { Collapse, ConfigureWidgetPlaceholder, Container, FixedGrid } from 'v2/ui'
import { AttributeDisplay } from 'v2/ui/components/Attribute/AttributeDisplay'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import * as SVGIcons from 'v2/ui/svgs'

import FieldHighlightsEditor from './FieldContainerEditor/FieldHighlightsEditor'

const FieldHighlightsBlock = (props) => {
    const { attrs, context } = props
    let fields = get(props, 'attrs.contents', [])
    const isEditing = get(props, 'context.editor.isEditing')
    const processFilter = useProcessFilter()

    const fieldConditionalVisibilityFilters = attrs?.fieldConditionalVisibilityFilters

    fields = fields.filter(({ fieldId, type }) => {
        if (type !== 'field') return false

        const passesVisibilityFilters =
            isEditing ||
            !fieldConditionalVisibilityFilters?.[fieldId] ||
            processFilter([context.record], fieldConditionalVisibilityFilters[fieldId])?.length > 0

        // Don't include fields that no longer exist or are disabled.
        const field = context.object?.fields?.find((field) => field._sid === fieldId)
        return (
            field && passesVisibilityFilters && !get(field, 'connection_options.is_disabled', false)
        )
    })

    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    const hidden = !isEditing && passesFilters
    // If there are no visible fields at all, then show an edit control instead in edit mode.
    if (isEditing && fields.length == 0) {
        return (
            <ConfigureWidgetPlaceholder
                message="Highlights"
                subtitle="Highlight certain fields"
                Icon={SVGIcons.Highlights}
                onClick={props.showAttributePopover}
                className={ONBOARDING_CLASSES.EDIT_LAYOUT_HIGHLIGHTS}
            />
        )
    }

    if (fields.length === 0) return ''

    const maxCols = fields.length > MAX_HIGHLIGHT_ITEMS ? MAX_HIGHLIGHT_ITEMS : fields.length
    const smallCards = fields.length >= 4

    const children = fields.map((item) => {
        const { objectId, fieldId, required, fullWidth, label, description, ...otherOptions } = item

        return (
            <Container
                key={fieldId}
                p={[
                    'container.padding',
                    null,
                    null,
                    smallCards ? 'container.padding' : 'container.paddingLg',
                ]}
                m={0}
                variant="highlighted"
            >
                <AttributeDisplay
                    objectId={objectId}
                    fieldId={fieldId}
                    record={props.context.record}
                    required={required}
                    fullWidth={fullWidth}
                    readOnly={true}
                    editing={props.context.view.editing}
                    showErrors={props.context.view.showErrors}
                    setValue={props.context.view.actions.setValue}
                    setValid={props.context.view.actions.setValid}
                    valid={props.context.view.valid}
                    isLoading={props.context.view?.isLoading}
                    labelOverride={label}
                    editDescription={description}
                    variant={smallCards ? 'highlightedSmall' : 'highlighted'}
                    layout="highlighted"
                    {...otherOptions}
                />
            </Container>
        )
    })

    return (
        <Collapse isOpen={!hidden} duration={0.4}>
            <FixedGrid columns={[1, 1, 4, maxCols]} spacing="10px" maxWidth="100%" mb={4}>
                {children}
            </FixedGrid>
        </Collapse>
    )
}

FieldHighlightsBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    configuratorWrapper({
        field: 'content',
        Element: FieldHighlightsEditor,
        simple: true,
        fullHeight: true,
    }),
]

export default FieldHighlightsBlock

import { selectPaletteThemeColorsByTone } from 'ui/styling/helpers/selectPaletteThemeColorsByTone'

import { makeAppVariables } from './app'

// Specific component color values
export function makeAvatarVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            // gives us an entry for each color scheme with the 100 tone for that color
            // e.g., surfaceIndigo: paletteTheme.indigo100
            ...selectPaletteThemeColorsByTone(s.color, '100', 'surface'),

            // gives us an entry for each color scheme with the 100 tone for that color
            // e.g., textIndigo: paletteTheme.indigo600
            ...selectPaletteThemeColorsByTone(s.color, '600', 'text'),
        },
        size: {
            '2xs': '16px',
            xs: '20px',
            s: '24px',
            m: '36px',
            l: '48px',
            xl: '72px',
            '2xl': '108px',
            xsIcon: 'xs',
            sIcon: 's',
            mIcon: 'l',
            lIcon: '2xl',
            xlIcon: '3xl',
            '2xlIcon': '4xl',
        },
        fontFamily: s.fonts.headline,
        fontSizes: {
            '2xs': s.fontSizes.bodyXs,
            xs: s.fontSizes.bodyS,
            s: s.fontSizes.bodyS,
            m: s.fontSizes.bodyM,
            l: s.fontSizes.headlineS,
            xl: s.fontSizes.headlineM,
            '2xl': s.fontSizes.headlineXl,
        },
        lineHeights: {
            '2xs': s.lineHeights.bodyXs,
            xs: s.lineHeights.bodyS,
            s: s.lineHeights.bodyS,
            m: s.lineHeights.bodyM,
            l: s.lineHeights.headlineS,
            xl: s.lineHeights.headlineM,
            '2xl': s.lineHeights.headlineXl,
        },
        letterSpacing: {
            '2xs': s.letterSpacing.bodyXs,
            xs: s.letterSpacing.bodyS,
            s: s.letterSpacing.bodyS,
            m: s.letterSpacing.bodyM,
            l: s.letterSpacing.headlineS,
            xl: s.letterSpacing.headlineM,
            '2xl': s.letterSpacing.headlineXl,
        },
        fontWeights: {
            initial2XS: s.fontWeights.bodyBold,
            initialXS: s.fontWeights.bodyBold,
            initialS: s.fontWeights.bodyBold,
            initialM: s.fontWeights.bodyBold,
            initialL: s.fontWeights.headline,
            initialXL: s.fontWeights.headline,
            initial2XL: s.fontWeights.headline,
        },
        radius: {
            square2XS: s.radius.xs,
            squareXS: s.radius.s,
            squareS: s.radius.m,
            squareM: s.radius['xl'],
            squareL: s.radius['2xl'],
            squareXL: s.radius['3xl'],
            square2XL: s.radius['4xl'],
        },
        onlineIndicatorColor: s.color.surfaceSuccess,
        onlineIndicatorSize: {
            '2xs': '6px',
            xs: '6px',
            s: '6px',
            m: '10px',
            l: '12px',
            xl: '14px',
            '2xl': '18px',
        },
        onlineIndicatorBorder: {
            '2xs': `${s.borderWidths[1]} ${s.borderStyles.base} ${s.color.borderInverse}`,
            xs: `${s.borderWidths[1]} ${s.borderStyles.base} ${s.color.borderInverse}`,
            s: `${s.borderWidths[1]} ${s.borderStyles.base} ${s.color.borderInverse}`,
            m: `${s.borderWidths[2]} ${s.borderStyles.base} ${s.color.borderInverse}`,
            l: `${s.borderWidths[2]} ${s.borderStyles.base} ${s.color.borderInverse}`,
            xl: `${s.borderWidths[2]} ${s.borderStyles.base} ${s.color.borderInverse}`,
            '2xl': `${s.borderWidths[2]} ${s.borderStyles.base} ${s.color.borderInverse}`,
        },
        onlineIndicatorOffset: {
            '2xs': '-8%',
            xs: '-8%',
            s: '0',
            m: '0',
            l: '1.5%',
            xl: '3.5%',
            '2xl': '5.5%',
        },
    }
}

import React from 'react'

import styled from '@emotion/styled'

import { useAppContext } from 'app/useAppContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'

const PoweredLink = styled('a')`
    text-align: center;
    color: ${(props) => props.theme.fontColor};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 0.8em;
`

const PoweredWrapper = styled('div')`
    flex: 0;
    width: 100%;
    text-align: center;
    flex-basis: 100%;
    margin: 20px 0;
    align-self: flex-end;
`

export const PoweredBy = (props) => {
    const { workspaceZone } = useWorkspaceContext()
    const { selectedStack } = useAppContext()
    if (workspaceZone?.type === 'Portal') {
        return null
    }
    const combined_optional_features = selectedStack?.combined_optional_features

    //stops the powered by flashing up before we have the stack data
    if (!selectedStack?._sid) return null

    let whitelabel = false

    if (combined_optional_features) whitelabel = combined_optional_features.whitelabel

    if (whitelabel != true) {
        const poweredByLink =
            'https://www.stackerhq.com/?utm_source=stacker_footer&utm_medium=powered&utm_content=' +
            window.location.hostname
        return (
            <PoweredWrapper className="powered-by" style={props.wrapperStyle}>
                <PoweredLink href={poweredByLink} target="_blank">
                    Powered By Stacker
                </PoweredLink>
            </PoweredWrapper>
        )
    }

    return null
}

import React, { useImperativeHandle, useMemo, useState } from 'react'

import classNames from 'classnames'

import { useMentionUsersForStack } from 'features/Activity/useMentionUsersForStack'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useBlockTypeCommandProvider } from './Commands/BlockTypeCommandProvider'
import { useCommandsExtension } from './Commands/CommandsExtension'
import { CommandsProvider } from './Commands/CommandsProvider'
import { useInternalLinkCommandProvider } from './Commands/useInternalLinksCommandProvider'
import { createCheckItemExtension } from './Extensions/CheckItemExtension'
import { createCheckListExtension } from './Extensions/CheckListExtension'
import { useMentionsExtension } from './Extensions/createMentionsExtension'
import { createDetailsExtension } from './Extensions/DetailsExtension'
import { createHorizontalRuleExtension } from './Extensions/HorizontalRuleExtension'
import { createImageExtension } from './Extensions/ImageExtension'
import { createImageManagerExtension } from './Extensions/ImageManagerExtension'
import { createSubscriptExtension } from './Extensions/SubscriptExtension'
import { createSuperscriptExtension } from './Extensions/SuperscriptExtension'
import { createTableCellExtension } from './Extensions/TableCellExtension'
import { createTableExtension } from './Extensions/TableExtension'
import { createTableHeaderExtension } from './Extensions/TableHeaderExtension'
import { createTableRowExtension } from './Extensions/TableRowExtension'
import { createUnderlineExtension } from './Extensions/UnderlineExtension'
import { useRecordLinkExtension } from './Extensions/useRecordLinkExtension'
import { TipTapEditorBase, TipTapEditorBaseHandle, TipTapEditorBaseProps } from './TipTapEditorBase'

import { SimpleRichTextStyle } from './SimpleRichTextStyle.css'

type TipTapEditorProps = Omit<TipTapEditorBaseProps, 'extensions'> & {
    stackId?: string
    additionalExtensions?: any[]
    dereferencedRecords?: RecordDto[]
    allowMentions?: boolean
    allowPastingImages?: boolean
    allowDraggingImages?: boolean
    documentStyle?: boolean
}

export type TipTapEditorHandle = TipTapEditorBaseHandle & {
    getMentions: () => string[]
    getRecordLinks: () => {
        id: string
        stack_id: string
    }[]
}

export const TipTapEditor = React.forwardRef<TipTapEditorHandle, TipTapEditorProps>(
    function TipTapEditor(
        {
            stackId,
            additionalExtensions: suppliedExtensions = [],
            dereferencedRecords,
            allowMentions = true,
            allowPastingImages = true,
            allowDraggingImages = true,
            className,
            documentStyle,
            ...props
        }: TipTapEditorProps,
        ref
    ) {
        const { allowedBlockTypes } = props
        const [editorRef, setEditorRef] = useState<TipTapEditorBaseHandle | null>(null)
        const blockTypes = useBlockTypeCommandProvider({
            allowedBlockTypes,
            editor: editorRef?.editor ?? null,
        })
        const internalLinks = useInternalLinkCommandProvider({ stackId, allowMentions })
        const users = useMentionUsersForStack(stackId)
        const mentions = useMentionsExtension(users)

        const commandProviders = useMemo(() => {
            const result: CommandsProvider<any>[] = [blockTypes, internalLinks]

            return result
        }, [blockTypes, internalLinks])
        const commands = useCommandsExtension({
            providers: commandProviders,
        })
        const additionalExtensions = useDeepEqualsMemoValue(suppliedExtensions)
        const recordLinkExtension = useRecordLinkExtension(dereferencedRecords)

        const editorExtensions = useMemo(() => {
            const extensions = [
                createCheckListExtension(),
                createCheckItemExtension(),
                createImageExtension(),
                createUnderlineExtension(),
                createSubscriptExtension(),
                createSuperscriptExtension(),
                createHorizontalRuleExtension(),
                createTableExtension(),
                createTableHeaderExtension(),
                createTableCellExtension(),
                createTableRowExtension(),
                createDetailsExtension(),
                commands,
                recordLinkExtension,
                ...additionalExtensions,
            ]

            if (allowMentions) {
                extensions.push(mentions)
            }

            if (allowPastingImages || allowDraggingImages) {
                extensions.push(
                    createImageManagerExtension({
                        allowPaste: allowPastingImages,
                        allowDrop: allowDraggingImages,
                    })
                )
            }

            return extensions
        }, [
            commands,
            recordLinkExtension,
            additionalExtensions,
            allowMentions,
            allowPastingImages,
            allowDraggingImages,
            mentions,
        ])

        useImperativeHandle(
            ref,
            () => ({
                ...(editorRef || { editor: null, container: undefined }),
                getMentions: () => mentions.storage.mentions(),
                getRecordLinks: () => {
                    return recordLinkExtension.storage.records()
                },
            }),
            [editorRef, mentions, recordLinkExtension]
        )
        return (
            <TipTapEditorBase
                ref={setEditorRef}
                extensions={editorExtensions}
                {...props}
                className={classNames(className, { [SimpleRichTextStyle]: !documentStyle })}
            />
        )
    }
)

import React from 'react'

import { useAppUserContext } from 'app/useAppUserContext'
import { assertIsDefined } from 'data/utils/ts_utils'
import { UserDisplay, UserDisplayProps } from 'features/users/shared/UserDisplay'
import { isWorkflowSid } from 'features/workflows/common'

import { useActivityFeedContext } from './useActivityFeedContext'
import { WorkflowUserDisplay } from './WorkflowUserDisplay'

type FeedUserDisplayProps = Omit<UserDisplayProps, 'user'> & {
    userId: string
}

export function FeedUserDisplay({ userId, ...props }: FeedUserDisplayProps) {
    const { user: currentUser } = useAppUserContext()
    const { users } = useActivityFeedContext()
    assertIsDefined(currentUser)

    if (isWorkflowSid(userId)) {
        return <WorkflowUserDisplay workflowId={userId} {...props} />
    }

    const user = [currentUser, ...users].find((u) => u._sid === userId)
    if (!user) return null
    return <UserDisplay user={user} {...props} />
}

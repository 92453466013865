import React, { useEffect, useState } from 'react'

import isEmpty from 'lodash/isEmpty'

import { getErrorMessage } from 'features/datagrid/getDataGridErrorMessage'
import { RecordEditManagerResult } from 'features/datagrid/hooks/useRecordEditManager'

import { Banner, SlideInBox } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

type ErrorMessageProps = {
    failedRecords?: RecordEditManagerResult['failedRecords']
    retryFailures?: () => void
    zIndex?: number
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
    failedRecords = {},
    retryFailures,
    zIndex,
}) => {
    {
        const [errorMessage, setErrorMessage] = useState('')

        useEffect(() => {
            if (isEmpty(failedRecords)) return

            const message = getErrorMessage(failedRecords)
            setErrorMessage(message)
        }, [failedRecords])

        const failedRecordsCount = Object.keys(failedRecords).length
        if (!failedRecordsCount) return null

        return (
            <SlideInBox
                slideIn={failedRecordsCount > 0}
                slideOut={failedRecordsCount === 0}
                zIndex={zIndex}
            >
                <Box ml="l" mb="l">
                    <Banner icon="info" variant="Error" justifyContent="center">
                        {errorMessage}
                        <Button ml="m" variant="secondary" size="s" onClick={retryFailures}>
                            Try again
                        </Button>
                    </Banner>
                </Box>
            </SlideInBox>
        )
    }
}

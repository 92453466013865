import React, { useState } from 'react'

import { useDocuments } from 'data/hooks/documents'
import { useAppUsers } from 'data/hooks/users/main'
import { UserDisplay } from 'features/users/shared/UserDisplay'

import { LoadingScreen } from 'v2/ui'
import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Collapsible, CollapsibleContent } from 'ui/components/Collapsible'
import { Container } from 'ui/components/Container'
import { Divider } from 'ui/components/Divider'
import { theme } from 'ui/styling/Theme.css'

import { DocumentLink } from './DocumentLink'
import { NewDocumentPlaceholder } from './NewDocumentPlaceholder'

import { ListItemStyle } from './DocumentListStyles.css'

type DocumentListProps = { recordId?: string }
export function DocumentList({ recordId }: DocumentListProps) {
    const { data: documentsResponse, isLoading } = useDocuments({ record_id: recordId })
    const documents = documentsResponse?.documents ?? []

    if (isLoading)
        return (
            <Box flex center justifyContent="center" minHeight="100px">
                <LoadingScreen isLoading />
            </Box>
        )

    const rootLevelDocs = documents.filter((doc) => !doc.parent_document_id)
    return (
        <>
            {rootLevelDocs.map((document, index) => (
                <>
                    <DocumentListItem
                        key={document.auto_id}
                        document={document}
                        documents={documents}
                    />
                    {index < rootLevelDocs.length && <Divider />}
                </>
            ))}
            <NewDocumentPlaceholder
                parent_record_id={recordId}
                style={{ paddingLeft: theme.sizes.squareButtonWidth }}
            />
        </>
    )
}

type DocumentListItemProps = { documents: DocumentDto[]; document: DocumentDto }
export function DocumentListItem({ documents, document }: DocumentListItemProps) {
    const [isOpen, setIsOpen] = useState(false)
    const { data: users } = useAppUsers()
    const children = documents.filter((doc) => doc.parent_document_id === document.auto_id)

    const timeDisplay = useDisplayTimeFrom(document.created_at)
    const user = users?.find((user: UserDto) => user._sid === document.created_by)
    const hasChildren = children.length > 0
    return (
        <Collapsible open={isOpen} onOpenChange={setIsOpen} disabled={!hasChildren}>
            <Box flex column grow width="full" className={ListItemStyle} py="xs">
                <Box
                    flex
                    center
                    width="full"
                    onDoubleClick={hasChildren ? () => setIsOpen((open) => !open) : undefined}
                    onClick={hasChildren ? () => setIsOpen((open) => !open) : undefined}
                    cursor={hasChildren ? 'pointer' : 'default'}
                    pl="m"
                >
                    <Button
                        startIcon={{ name: isOpen ? 'ChevronDown' : 'ChevronRight' }}
                        variant="ghost"
                        size="2xs"
                        opacity={children.length > 0 ? 1 : 0}
                        pointerEvents={children.length > 0 ? 'auto' : 'none'}
                    />
                    <DocumentLink document={document} trim shrink />

                    {hasChildren && (
                        <Container ml="m" px="xs" variant="neutral" fontSize="bodyXs">
                            +{children.length}
                        </Container>
                    )}
                    <Box grow />
                    <Box color="textWeaker" fontSize="bodyS" mr="xl" noShrink>
                        {timeDisplay}
                    </Box>
                    {user && <UserDisplay user={user} showName={false} avatarSize="xs" mr="xl" />}
                </Box>
                {document.searchable_content && (
                    <Box
                        style={{ marginLeft: theme.sizes.squareButtonWidth, maxHeight: '2.5em' }}
                        mb="xs"
                        fontSize="bodyS"
                        color="textWeaker"
                        overflow="hidden"
                        title={document.searchable_content}
                        textOverflow="ellipsis"
                        whiteSpace="pre-wrap"
                        mr="xl"
                    >
                        {document.searchable_content}
                    </Box>
                )}
            </Box>
            <CollapsibleContent style={{ marginLeft: theme.sizes.squareButtonWidth }}>
                {children.map((doc) => (
                    <DocumentListItem key={doc.auto_id} document={doc} documents={documents} />
                ))}
            </CollapsibleContent>
        </Collapsible>
    )
}

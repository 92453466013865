import React from 'react'

import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

type AppUsersListEmptyValueProps = {
    isLoading?: boolean
}

export const AppUsersListEmptyValue: React.FC<AppUsersListEmptyValueProps> = ({ isLoading }) => {
    const content = (
        <Body color="textWeakest" weight="regular" size="m">
            None
        </Body>
    )

    if (isLoading) {
        return (
            <Skeleton isLoading={true} isInline>
                {content}
            </Skeleton>
        )
    }

    return content
}

import React from 'react'

export type SaveGuardContextType = {
    canSave: boolean
    registerBlock: (message: string) => () => void
}

const defaultContext: SaveGuardContextType = {
    canSave: true,
    registerBlock: () => () => {},
}

export const SaveGuardContext = React.createContext(defaultContext)

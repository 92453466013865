import { useWorkspaceContext } from 'app/WorkspaceContext'
import useLDFlags from 'data/hooks/useLDFlags'

export function useNotesEnabled() {
    const { flags } = useLDFlags()

    const { workspaceZone } = useWorkspaceContext()
    const isPortal = workspaceZone?.type === 'Portal'

    return flags.notes && !isPortal
}

import React from 'react'

import classNames from 'classnames'

import { Box, BoxProps } from 'ui/components/Box'

import { DotStyle, LoadingIndicatorStyle } from './LoadingIndicatorStyles.css'
type LoadingIndicatorProps = BoxProps
export function LoadingIndicator({ className, ...props }: LoadingIndicatorProps) {
    return (
        <Box className={classNames(LoadingIndicatorStyle, className)} {...props}>
            <span className={DotStyle} />
            <span className={DotStyle} />
            <span className={DotStyle} />
        </Box>
    )
}

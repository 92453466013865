import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

export type AspectRatio = '1:1' | '4:3'

type UseThumbnailStateOptions = {
    src?: string
    aspectRatio?: AspectRatio
}

export function useThumbnailState(options: UseThumbnailStateOptions) {
    const { aspectRatio: providedAspectRatio = '1:1', src } = options

    const shouldDetermineAspectRatio = !providedAspectRatio
    const shouldDetermineAspectRatioRef = useRef(shouldDetermineAspectRatio)
    shouldDetermineAspectRatioRef.current = shouldDetermineAspectRatio

    const [aspectRatio, setAspectRatio] = useState<AspectRatio>(providedAspectRatio)
    const effectiveAspectRatio = shouldDetermineAspectRatio ? aspectRatio : providedAspectRatio

    const [isLoaded, setIsLoaded] = useState(false)

    const onLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
        const image = e.target as HTMLImageElement
        if (!image.complete) return

        const shouldDetermineAspectRatio = shouldDetermineAspectRatioRef.current

        if (shouldDetermineAspectRatio) {
            const imageAspectRatio = image.naturalWidth / image.naturalHeight
            if (imageAspectRatio > 1) {
                setAspectRatio('4:3')
            } else {
                setAspectRatio('1:1')
            }
        }

        setIsLoaded(true)
    }, [])

    const [isError, setIsError] = useState(false)

    const onError = useCallback(() => {
        setIsError(true)
    }, [])

    useLayoutEffect(() => {
        setIsLoaded(false)
        setIsError(false)
    }, [src])

    return useMemo(
        () => ({
            isLoaded,
            onLoad,
            aspectRatio: effectiveAspectRatio,
            isError,
            onError,
        }),
        [isLoaded, onLoad, effectiveAspectRatio, isError, onError]
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { useLayoutEditorSaveButtonState } from './hooks/useLayoutEditorSaveButtonState'

type LayoutEditorSaveButtonProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const LayoutEditorSaveButton: React.FC<LayoutEditorSaveButtonProps> = (props) => {
    const { isSaveable, label, onSaveClick } = useLayoutEditorSaveButtonState()

    return (
        <Box noShrink {...props}>
            <Button disabled={!isSaveable} size="m" width="full" onClick={onSaveClick}>
                {label}
            </Button>
        </Box>
    )
}

import React from 'react'

import { Frame } from 'features/core/Frame'

import { WorkflowManager } from './WorkflowManager'

type WorkflowPlaygroundProps = {}

export function WorkflowPlayground({}: WorkflowPlaygroundProps) {
    return (
        <Frame paddingOverride="0px" style={{ height: '100dvh' }} hidePoweredBy>
            {/* <WorkflowEditor workflow={workflow} /> */}
            <WorkflowManager />
        </Frame>
    )
}

import React from 'react'

import type { ContextMenuContentProps as BaseProps } from '@radix-ui/react-context-menu'

import { StandardComponentProps } from 'ui/helpers/styles'

import { Content, Portal } from './ContextMenu.parts'

type ContextMenuContentProps = Omit<BaseProps, 'asChild'> &
    StandardComponentProps & {
        usePortal?: boolean
    }

export const ContextMenuContent: React.FC<ContextMenuContentProps> = ({
    usePortal = true,
    ...props
}) => {
    return (
        <>
            {usePortal === true ? (
                <Portal>
                    <Content {...props} />
                </Portal>
            ) : (
                <Content {...props} />
            )}
        </>
    )
}

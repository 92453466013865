// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'

import { RenderIcon } from 'features/admin/fields/RenderIcon'

import { Flex } from 'v2/ui'
import { ShortText } from 'v2/ui/svgs/field-icons'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type FieldsPreviewProps = {
    fields: string[]
}
const FieldsPreview = (props: FieldsPreviewProps) => {
    return (
        <Flex direction="row" flexWrap="nowrap" width="100%" overflowX="auto">
            {props.fields.map((field) => (
                <Cell key={field}>
                    <RenderIcon color={colors.userInterface.neutral[800]} Icon={ShortText} />{' '}
                    {field}
                </Cell>
            ))}
        </Flex>
    )
}

const Cell = styled('div')`
    font-size: 14px;
    margin-bottom: 10px;
    background-color: ${colors.userInterface.neutral[200]};
    border: 1px solid ${colors.userInterface.neutral[400]};
    font-weight: bold;
    padding: 5px 10px;
    flex-shrink: 0;
`
export default FieldsPreview

import React from 'react'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import { getUrl, Urls } from 'app/UrlService'
import { ExpandSection, Modal, Section } from 'legacy/v1/ui'

import { Alert, Button, Input, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

type DeleteAppSettingProps = {
    selectedStack: StackDto
    stacks: StackDto[]
    deleteStack: any
    isStackSubscribed: boolean
}

export const DeleteAppSetting = ({
    selectedStack,
    stacks,
    deleteStack,
    isStackSubscribed,
}: DeleteAppSettingProps) => {
    const [deleteModal, setDeleteModalOpen] = React.useState<boolean>(false)
    const [urlMatch, setUrlMatch] = React.useState<string>('')
    const [processing, setProcessing] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)

    const history = useHistory()

    const deletePrompt = () => {
        setDeleteModalOpen((prevState) => !prevState)
    }

    const deleteModalComponent = (
        stacks: StackDto[],
        selectedStack: StackDto,
        deleteStack: (stack_sid: string) => Promise<any>,
        subscribed: boolean
    ) => {
        return (
            <Modal
                isOpen={deleteModal}
                style={{
                    width: 600,
                    height: 400,
                    padding: 24,
                    margin: 'auto',
                    background: '#ffffff',
                    overflow: 'hidden',
                }}
                overlayStyle={{
                    zIndex: 1401,
                }}
            >
                <ModalSection>
                    <Text variant="modalTitle" fontWeight="bold">
                        Are you sure you want to delete your app?
                    </Text>
                    <Text fontWeight="bold" mt={4}>
                        Deleting your app is irreversible.
                    </Text>
                    {subscribed && (
                        <Alert
                            status="warning"
                            description="Deleting your app will not cancel your subscription. Please contact
                                            us if you wish to cancel."
                            m={0}
                            mt={4}
                            fontSize="sm"
                            lineHeight="normal"
                        />
                    )}
                    <Text mt={4}>
                        Please type <b>{selectedStack.url_slug}</b> to confirm
                    </Text>
                    <Input
                        mt={4}
                        variant="admin"
                        data-testid="general-settings.modal.delete-app.input"
                        id="delete-app-input"
                        value={urlMatch}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setUrlMatch(e.target?.value)
                        }
                    />

                    <Button
                        data-testid="general-settings.modal.delete-app.button"
                        variant="PrimaryError"
                        buttonSize="md"
                        mt={4}
                        disabled={urlMatch !== selectedStack.url_slug || processing}
                        onClick={() => {
                            setProcessing(true)
                            setError(false)
                            deleteStack(selectedStack._sid)
                                .then(() => {
                                    history.push(getUrl(Urls.Home))
                                    setProcessing(false)
                                })
                                .catch(() => {
                                    setProcessing(false)
                                    setError(true)
                                })
                        }}
                    >
                        I understand. Delete my app.
                    </Button>
                    <Button
                        variant="Secondary"
                        buttonSize="md"
                        mt={4}
                        onClick={() => deletePrompt()}
                    >
                        Back to Safety
                    </Button>
                    {error && (
                        <Text style={{ color: '#e74c3c' }}>
                            Sorry, there was an error deleting the app. Please try again.
                        </Text>
                    )}
                </ModalSection>
            </Modal>
        )
    }
    return (
        <ExpandSection
            heading="Delete your app"
            helpUrl=""
            helpText=""
            text={null}
            classes={{ heading: ONBOARDING_CLASSES.APP_SETTINGS_GENERAL.DELETE_APP }}
            testId="general-settings.delete-app"
        >
            {/*@ts-expect-error*/}
            <>
                <Button
                    variant="PrimaryError"
                    buttonSize="sm"
                    width="100px"
                    disabled={!selectedStack._sid}
                    onClick={() => deletePrompt()}
                    data-testid="general-settings.delete-app.button"
                >
                    Delete
                </Button>
                {deleteModalComponent(stacks, selectedStack, deleteStack, isStackSubscribed)}
            </>
        </ExpandSection>
    )
}

const ModalSection = styled(Section)`
    text-align: center;
    overflow: auto;
`

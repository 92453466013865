import React, { useCallback } from 'react'

import { useActionButton } from 'features/actions/hooks/useActionButton'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

type RecordActionButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {
    action: ActionDto
    record: RecordDto
    includeFields: string[]
}

export const RecordActionButton: React.FC<RecordActionButtonProps> = React.memo(
    ({ action, record, includeFields, onClick, ...props }) => {
        const { executeAction } = useActionButton({
            action,
            recordId: record._sid,
            record,
            includeFields,
        })

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()

                executeAction()
                onClick?.(e)
            },
            [executeAction, onClick]
        )

        return (
            <Button onClick={handleClick} startIcon={{ name: 'Check' }} {...props}>
                <Box trim maxWidth="full">
                    {action.name}
                </Box>
            </Button>
        )
    }
)

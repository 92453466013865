import { useMemo } from 'react'

import { AppGroup } from 'features/users/AppUsers/types'

type UseAppUsersGroupListStateOptions = {
    groups: AppGroup[]
    isLoading?: boolean
}

export function useAppUsersGroupListState(options: UseAppUsersGroupListStateOptions) {
    const { groups } = options

    const rows = useMemo(() => {
        return groups.slice().sort((a, b) => {
            const aName = a.name.toLowerCase()
            const bName = b.name.toLowerCase()
            return aName.localeCompare(bName)
        })
    }, [groups])

    return { rows }
}

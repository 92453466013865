import React, { useCallback, useLayoutEffect, useRef } from 'react'

import { FieldIcon as FieldIconValue } from 'data/utils/fieldDefinitions'

import STYLE_CLASSES from 'v2/ui/styleClasses'

import { IconName } from 'ui/components/Icon/Icon'
import { IconPickerDropdown } from 'ui/components/IconPicker/IconPickerDropdown'

import { getDefaultFieldIcon } from './utils'

type FieldIconPickerProps = {
    value?: FieldIconValue
    field: FieldDto
    onChange?: (value?: FieldIconValue) => void
    disabled?: boolean
}

export const FieldIconPicker: React.FC<FieldIconPickerProps> = ({
    value,
    field,
    onChange,
    disabled,
}) => {
    const fieldRef = useRef(field)
    fieldRef.current = field

    const effectiveValue = value ?? getDefaultFieldIcon(field)

    const handleChange = useCallback(
        (value?: { type: 'lucide'; name: IconName }) => {
            if (value) {
                onChange?.(value)
            } else {
                const field = fieldRef.current
                const defaultValue = getDefaultFieldIcon(field)
                onChange?.(defaultValue)
            }
        },
        [onChange]
    )

    const updateAdminSideTrayState = useCallback((open: boolean) => {
        const adminTrayEl = document.querySelector(
            `.${STYLE_CLASSES.ADMIN_SIDE_TRAY_CONTENT}`
        ) as HTMLElement | null

        if (adminTrayEl) {
            adminTrayEl.style.pointerEvents = open ? 'none' : 'auto'
        }
    }, [])

    // Make sure we never leave the admin tray in a disabled state.
    useLayoutEffect(() => {
        return () => {
            updateAdminSideTrayState(false)
        }
    }, [updateAdminSideTrayState])

    return (
        <IconPickerDropdown
            value={effectiveValue}
            onChange={handleChange}
            disabled={disabled}
            onOpenChange={updateAdminSideTrayState}
        />
    )
}

import React from 'react'

import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

import Flex from 'v2/ui/components/Flex'

import NumberAttribute from './NumberAttribute'

type PercentRingProps = {
    progressColor: string
    percentValue: number
    progressColorTheme: string
    isEditing?: boolean
    layout?: 'highlighted' | 'inline' | 'block'
}

const PercentRing = ({
    progressColor,
    percentValue,
    progressColorTheme,
    isEditing,
    layout,
    ...props
}: PercentRingProps) => {
    const ringLabel = percentValue.toFixed(0)
    return (
        <Flex
            direction="row"
            wrap="noWrap"
            align="initial"
            width="100%"
            justifyContent={layout === 'highlighted' ? 'space-around' : ''}
        >
            <CircularProgress color={progressColor} value={percentValue} capIsRound>
                <CircularProgressLabel color={progressColorTheme} style={{ fontWeight: 600 }}>
                    {ringLabel}%
                </CircularProgressLabel>
            </CircularProgress>
            {isEditing && <NumberAttribute style={{ margin: 'auto auto auto 1rem' }} {...props} />}
        </Flex>
    )
}

export default PercentRing

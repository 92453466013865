const STACKER_GROUP_PREFIX = 'stacker.'

export function sortWorkflowItemsByGroup<T extends WorkflowSchemaItemBase>(items: T[]): T[] {
    return items.sort((a, b) => {
        const groupA = a.group ?? ''
        const groupB = b.group ?? ''

        // Show Stacker items at the top of the list.
        if (groupA.startsWith(STACKER_GROUP_PREFIX) && !groupB.startsWith(STACKER_GROUP_PREFIX)) {
            return -1
        }
        if (!groupA.startsWith(STACKER_GROUP_PREFIX) && groupB.startsWith(STACKER_GROUP_PREFIX)) {
            return 1
        }

        const groupComparison = groupA.localeCompare(groupB)
        if (groupComparison !== 0) return groupComparison

        const nameA = a.name ?? ''
        const nameB = b.name ?? ''
        return nameA.localeCompare(nameB)
    })
}

export type WorkflowNodeEditorComponentProps = {
    onChange: (patch: Omit<Partial<WorkflowItem>, 'id'>) => void
}

export type WorkflowNodeEditorComponent<T = {}> = React.FC<WorkflowNodeEditorComponentProps & T>

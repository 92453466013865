// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'

import { startUpsertCsvTableJob, startValidateCsvTableJob } from 'data/api/csvApi'
import { useJobPoller } from 'data/api/jobApi'
import { invalidateRecords } from 'data/hooks/records/recordOperations'
import ReactFilestack from 'utils/lazyComponents/ReactFilestack'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button/'
import { Spinner } from 'ui/components/Spinner'
import { Body } from 'ui/components/Text/'

type JobType = {
    job_info: {
        result: any
    }
    type: string
    task: string
}

type ImportCsvJobResult = {
    created_sids: string[]
    updated_sids: string[]
    pre_update_values: number
    formatting_errors: Array<Record<string, any>>
    translated_external_ids: Array<Record<string, string>>
}

type ParsedUpsertResult = {
    createdLength: number
    updatedLength: number
    formattingErrorsLength: number
}

type UpsertStage =
    | 'selectFile'
    | 'fileValidating'
    | 'fileReady'
    | 'importInProgress'
    | 'upsertComplete'
    | 'importFailed'

const MAX_CSV_SIZE_BYTES = 25 * 1024 * 1024 // 25 MiB

const renderLoadingState = (text: string) => (
    <Box flex flexDirection="column" alignItems="center">
        <Spinner size="3xl" />
        <Body mt="l">{text}</Body>
    </Box>
)

const renderJobResult = (jobResult: object) => (
    <Box>
        <Box p="l" mt="xl" mx="s" borderRadius="l" background="error200">
            <pre>
                {JSON.stringify(jobResult, null, 2)
                    .split('\n')
                    .map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
            </pre>
        </Box>
    </Box>
)

const AppSettingsModalDataImport = ({ objectId }) => {
    const [currentStage, setCurrentStage] = useState<UpsertStage>('selectFile')
    const [fileStackUrl, setFileStackUrl] = useState('')
    const [fileStackFileName, setFileStackFileName] = useState('')
    const [showFilestack, setShowFilestack] = useState(false)
    const [currentJobId, setCurrentJobId] = useState(null)

    const { isComplete, isSuccessful, job } = useJobPoller(currentJobId) as {
        isComplete: boolean
        isSuccessful: boolean
        job: JobType | undefined
    }

    useEffect(() => {
        if (isComplete) {
            if (isSuccessful) {
                if (job?.task === 'validate_csv_for_upsert') {
                    setCurrentStage('fileReady')
                } else {
                    setCurrentStage('upsertComplete')
                    invalidateRecords(objectId)
                }
            } else {
                setCurrentStage('importFailed')
                console.error('Import Failed', job)
            }
        }
    }, [isComplete, isSuccessful, job, objectId])

    const startValidate = useCallback(async (fileUrl: string, object_sid: string) => {
        try {
            const response = await startValidateCsvTableJob(fileUrl, object_sid)
            setCurrentJobId(response.job)
            setCurrentStage('fileValidating')
        } catch (error) {
            console.error('Error starting validate', error)
        }
    }, [])

    const onFileStackSuccess = useCallback(
        (value) => {
            const uploadedFile = value.filesUploaded[0]
            setFileStackUrl(uploadedFile.url)
            setFileStackFileName(uploadedFile.filename)
            startValidate(uploadedFile.url, objectId)
        },
        [objectId, startValidate]
    )

    const parseUpsertJob = useCallback((job: JobType): ParsedUpsertResult => {
        const rawResult: ImportCsvJobResult = job?.job_info?.result

        const { created_sids, updated_sids, formatting_errors } = rawResult || {}

        const createdLength = created_sids?.length || 0
        const updatedLength = updated_sids?.length || 0
        const formattingErrorsLength = formatting_errors?.length || 0

        return {
            createdLength,
            updatedLength,
            formattingErrorsLength,
        }
    }, [])

    const startUpsert = useCallback(async (fileUrl: string, object_sid: string) => {
        try {
            const response = await startUpsertCsvTableJob(fileUrl, object_sid)
            setCurrentJobId(response.job)
            setCurrentStage('importInProgress')
        } catch (error) {
            console.error('Error starting upsert', error)
        }
    }, [])

    return (
        <>
            {showFilestack && (
                <ReactFilestack
                    componentDisplayMode={{
                        type: 'immediate',
                    }}
                    options={{
                        fromSources: ['local_file_system', 'url', 'googledrive', 'dropbox'],
                        accept: '.csv',
                        maxSize: MAX_CSV_SIZE_BYTES,
                        onCancel: () => {
                            setShowFilestack(false)
                        },
                    }}
                    onSuccess={onFileStackSuccess}
                />
            )}
            {currentStage === 'selectFile' && (
                <Box textAlign={'right'}>
                    <Banner
                        icon={{
                            name: 'Info',
                        }}
                        textAlign={'left'}
                        link={{
                            label: 'Read the docs',
                            target: '_blank',
                            href: 'https://docs.stackerhq.com/stacker/getting-started/introduction-to-data/stacker-tables-import-a-csv',
                        }}
                        title="Before you import"
                        type="info"
                        helper="Importing a CSV may overwrite existing data. There is no way to undo this action. For further information, please refer to the feature documentation."
                    ></Banner>

                    <Button
                        isLoading={showFilestack}
                        variant="primary"
                        size="m"
                        my="l"
                        onClick={() => {
                            setShowFilestack(true)
                        }}
                    >
                        Begin import
                    </Button>
                </Box>
            )}
            {currentStage === 'fileValidating' && renderLoadingState('Validating file...')}

            {currentStage === 'fileReady' && (
                <Box>
                    <Box flex flexDirection="row">
                        <Box flex flexDirection="column" flexGrow={1}>
                            <Body>
                                <b>File details</b>
                                <br />
                                {fileStackFileName}
                                <br />
                                {job?.job_info?.result?.incoming_records || '0'} valid records
                            </Body>
                        </Box>
                        <Box flex flexDirection="column" flexGrow={1}>
                            <Body>
                                <b>Pending import</b>
                                <br />
                                {job?.job_info?.result?.pending_updates || '0'} updates
                                <br />
                                {job?.job_info?.result?.pending_creates || '0'} creates
                            </Body>
                        </Box>
                        <Box flex flexDirection="column" flexGrow={1}>
                            <Body>
                                <b>Validation info</b>
                                <br />
                                {job?.job_info?.result?.eid_translations || '0'} external IDs
                                matched
                                <br />
                                {job?.job_info?.result?.formatting_error_count || '0'} validation
                                errors
                            </Body>
                        </Box>
                    </Box>

                    {job?.job_info?.result?.formatting_errors?.length > 0 ? (
                        renderJobResult(job?.job_info?.result?.formatting_errors)
                    ) : (
                        <Box textAlign={'right'}>
                            <Button
                                variant="primary"
                                mt="l"
                                onClick={() => startUpsert(fileStackUrl, objectId)}
                            >
                                Start import
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
            {currentStage === 'importInProgress' && renderLoadingState('Importing data...')}
            {currentStage === 'upsertComplete' && (
                <>
                    <Box>
                        <Body>
                            <b>Import complete!</b>
                            <br />
                            Records created: {job ? parseUpsertJob(job).createdLength : 0}
                            <br />
                            Records updated: {job ? parseUpsertJob(job).updatedLength : 0}
                            <br />
                            Formatting errors:{' '}
                            {job ? parseUpsertJob(job).formattingErrorsLength : 0}
                        </Body>
                    </Box>
                </>
            )}
            {currentStage === 'importFailed' && (
                <Box>
                    <Body>Import failed. Please try again or contact Stacker for assistance.</Body>
                </Box>
            )}
        </>
    )
}

export default AppSettingsModalDataImport

import React from 'react'

import { DetailViewControls } from 'features/views/DetailView/DetailViewControls'

import { useLayoutEditorControlsState } from './hooks/useLayoutEditorControlsState'
import { LayoutEditorWidgetControls } from './LayoutEditorWidgetControls'

type LayoutEditorControlsProps = {}

export const LayoutEditorControls: React.FC<LayoutEditorControlsProps> = () => {
    const { viewType, hasWidgetSelected, isSchemaOutdated } = useLayoutEditorControlsState()

    if (isSchemaOutdated) return null

    if (hasWidgetSelected) return <LayoutEditorWidgetControls />

    if (viewType === 'detail') return <DetailViewControls />

    return null
}

import { useMemo } from 'react'

import { sortFieldsByOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'

export function useDisplayedFields(object: ObjectDto) {
    return useMemo(() => {
        let fields = object.fields ?? []
        // Sort fields by the order specified in the object.
        fields = sortFieldsByOrder(fields, object)

        // Hide disabled fields.
        let displayedFields = fields.filter(
            (field) => !field.connection_options?.is_disabled && !field.is_primary
        )

        const primaryField = fields.find(({ is_primary }) => is_primary) as FieldDto
        if (primaryField) {
            // Always show the primary field as the first column.
            displayedFields = [primaryField, ...displayedFields]
        }

        return displayedFields
    }, [object])
}

import { createContext } from 'react'

type LookupFilterContext = {
    searchString: string
    setSearchString: (newDate: string) => void
    isFetching: boolean
    setIsFetching: (isFetching: boolean) => void
}
export const LookupFilterContext = createContext<LookupFilterContext>({
    searchString: '',
    setSearchString: () => {},
    isFetching: false,
    setIsFetching: () => {},
})

import React from 'react'

import { FieldIcon as FieldIconValue } from 'data/utils/fieldDefinitions'

import { Icon } from 'ui/components/Icon'

type FieldIconProps = Omit<React.ComponentPropsWithoutRef<typeof Icon>, 'name'> & {
    value: FieldIconValue
}

export const FieldIcon: React.FC<FieldIconProps> = ({ value, ...props }) => {
    if (value.type === 'lucide') {
        return <Icon name={value.name} {...props} />
    }

    return null
}

/*
 Returns modalState which can be passed to the UnsavedChangesModal, and a
 saveChanges(fn) method which checks for changes/shows the modal, and if proceeding
 calls fn().  Use like this:


    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)

    // check for unsaved changes, then close the modal
    function onClose() {
        saveChanges(onRequestClose)
    }
    ....

    <UnsavedChangesModal {...displayChangesModal} />
*/
import { useCallback, useState } from 'react'

export const useUnsavedChangesModalCallback = (formRef: any) => {
    const [modalState, setModalState] = useState()
    // similar to onClose
    // If there has changes, do not change pages
    const saveChanges = useCallback(
        async (fn) => {
            // trigger a validation here to ensure that the inputs have all been validated
            await formRef.current?.trigger?.()

            return new Promise((resolve) => {
                formRef.current?.trigger?.()

                // wait until any ongoing request finishes before closing
                if (formRef.current.formState?.isSubmitting || formRef.current.hasChanges) {
                    return setModalState({
                        // @ts-expect-error
                        type: formRef.current.formState?.isSubmitting ? 'submitting' : 'changes',
                        onProceed: () => {
                            resolve(fn())
                            // @ts-expect-error
                            setModalState(null)
                        },
                        onCancel: () => {
                            // @ts-expect-error
                            setModalState(null)
                        },
                        onSave: () => {},
                    })
                }

                resolve(fn())
            })
        },
        [formRef]
    )

    return {
        modalState,
        saveChanges,
    }
}

// @ts-strict-ignore
import React, { useCallback, useState } from 'react'

import { useDeleteGrant } from 'data/hooks/accessGrants'
import { useRoleListOptions } from 'data/hooks/roles'
import GroupListItem, { GroupListItemProps } from 'features/users/shared/GroupListItem'

import { Button } from 'ui/components/Button'
import {
    Dropdown as NewDropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
} from 'ui/components/Dropdown'
import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import { useUserModal } from './useUserModal'

export const TableGroupComponent: React.FC<GroupListItemProps> = (props) => {
    const { group, UserComponent } = props

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const toggleConfirmationModal = useCallback(() => {
        setIsConfirmationModalOpen((prevState) => !prevState)
    }, [])

    const { data: options } = useRoleListOptions(undefined, true)
    const role = options.find((option) => option.value === group.role)
    const label = role?.label || 'No role'

    const { mutateAsync: deleteGrant } = useDeleteGrant()

    const [isError, setIsError] = useState(false)

    const revokeTableGroupAccess = useCallback(async () => {
        try {
            await deleteGrant(group._sid)
            setIsError(false)
            toggleConfirmationModal()
        } catch {
            setIsError(true)
        }
    }, [group, deleteGrant, toggleConfirmationModal])

    const { setMode } = useUserModal()

    const onEditGrant = useCallback(() => {
        if (group) {
            setMode('tableGrant', group)
        }
    }, [group, setMode])

    return (
        <>
            <GroupListItem
                {...props}
                rowContents={
                    <>
                        <div style={{ flexGrow: 1 }} />
                        <Tag
                            startIcon={{
                                name: 'Lock',
                            }}
                            size="xs"
                            type="solid"
                        >
                            {label}
                        </Tag>
                        <div style={{ width: '15px' }} />
                        <ActionMenu
                            onEdit={onEditGrant}
                            onRevokeAccessClick={toggleConfirmationModal}
                        />
                    </>
                }
                UserComponent={UserComponent}
            />
            <RevokeAccessConfirmationModal
                isOpen={isConfirmationModalOpen}
                groupName={group.name}
                onCancel={toggleConfirmationModal}
                onContinue={revokeTableGroupAccess}
                isError={isError}
            />
        </>
    )
}

const ActionMenu = ({ onEdit, onRevokeAccessClick }) => {
    return (
        <NewDropdown modal={false}>
            <DropdownButton
                size="xs"
                endIcon={{
                    name: 'ChevronDown',
                }}
                variant="secondary"
            />
            <DropdownContent onClick={stopPropagation}>
                <DropdownItem
                    label="Edit table group"
                    startIcon={{
                        name: 'Cog',
                    }}
                    onClick={onEdit}
                />
                <DropdownItem
                    label="Revoke access"
                    variant="destructive"
                    startIcon={{
                        name: 'ArrowRightFromLine',
                    }}
                    onClick={onRevokeAccessClick}
                />
            </DropdownContent>
        </NewDropdown>
    )
}

const RevokeAccessConfirmationModal = ({
    isOpen,
    groupName,
    onCancel,
    onContinue,
    isError,
}: {
    isOpen: boolean
    groupName: string
    onCancel: () => void
    onContinue: () => void
    isError: boolean
}) => {
    return (
        <Modal open={isOpen}>
            <ModalContent>
                <ModalHeader
                    subtitle={`Are you sure you want to revoke access for the ${groupName} table group?`}
                    title="Revoke table group access"
                    showCloseButton={false}
                />
                <ModalFooter flexDirection="column">
                    <ModalFooterButtonGroup layout="inline">
                        <Button size="m" variant="secondary" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button size="m" variant="destructive" onClick={onContinue}>
                            Revoke access
                        </Button>
                    </ModalFooterButtonGroup>
                    {isError && (
                        <div style={{ marginLeft: 'auto', marginTop: '12px' }}>
                            <Body size="m" color="textError">
                                Error revoking table group access. Please try again or contact
                                support.
                            </Body>
                        </div>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

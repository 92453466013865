import React from 'react'

import useHover from 'v2/ui/utils/useHover'

import { Box, BoxProps } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { useConfirmDeleteNodeModal } from './useConfirmDeleteNodeModal'
import { useWorkflowChainContext } from './useWorkflowChainContext'
import { useWorkflowEditorContext } from './useWorkflowEditorContext'

import { WorkflowItemActionsStyle } from './WorkflowItemWrapper.css'

type WorkflowItemWrapperProps = BoxProps & { item?: WorkflowItem; children: React.ReactNode }
export function WorkflowItemWrapper({ item, children, ...props }: WorkflowItemWrapperProps) {
    const { chainPath } = useWorkflowChainContext()

    const { selectedItem, setSelectedItem } = useWorkflowEditorContext()
    const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!item) return

        setSelectedItem(item, chainPath)
        e.stopPropagation()
    }
    const isSelected = item ? selectedItem?.id === item.id : false

    const [isHovered, hoverHandlers] = useHover()

    const isDeletable = item && item.kind !== 'trigger'
    const isDeleteVisible = isHovered

    const showConfirmDeleteNode = useConfirmDeleteNodeModal()

    const handleDeleteItem = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDeletable) return
        e.stopPropagation()

        showConfirmDeleteNode(item!.id)
    }

    return (
        <Box
            boxShadow={isSelected ? 'm' : undefined}
            background="surface"
            borderColor={isSelected ? 'admin500' : 'textWeakest'}
            borderRadius="l"
            borderWidth={1}
            py="m"
            px="l"
            onClick={handleSelect}
            role="button"
            position="relative"
            fontSize="bodyM"
            {...props}
            {...hoverHandlers}
        >
            {children}
            {isDeletable && (
                <Box
                    className={WorkflowItemActionsStyle({ isDeleteVisible })}
                    onClick={handleDeleteItem}
                >
                    <Button
                        startIcon={{ name: 'Trash' }}
                        variant="critical"
                        type="button"
                        size="xs"
                    >
                        delete
                    </Button>
                </Box>
            )}
        </Box>
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Tooltip } from 'ui/components/Tooltip'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { useWorkspaceNavigationState } from './hooks/useWorkspaceNavigationState'
import * as Parts from './Navigation.parts'
import { NavigationCurrentUserDropdown } from './NavigationCurrentUserDropdown'
import { NavigationPortals } from './NavigationPortals'
import { NavigationSpaces } from './NavigationSpaces'
import { NavigationTrialBanner } from './NavigationTrialBanner'
import { SystemNavigation } from './SystemNavigation'
import { WorkspaceLogo } from './WorkspaceLogo'
import { WorkspaceNavigationLinks } from './WorkspaceNavigationLinks'

import { WorkspaceNavigationCollapsedToggleStyle } from './Navigation.css'

type WorkspaceNavigationProps = {}

export const WorkspaceNavigation: React.FC<WorkspaceNavigationProps> = () => {
    const {
        isWorkspaceNavCollapsed,
        workspaceNavState,
        toggleWorkspaceNavCollapsed,
        tooltipText,
        canEditWorkspaceSettings,
        handleOpenWorkspaceSettings,
        hasAppSelected,
        showMainNavigation,
        showMainNavigationToggle,
        showCollapseToggle,
        isNavHidden,
        showTrialBanner,
    } = useWorkspaceNavigationState()

    if (isNavHidden) return null

    return (
        <ThemeProvider theme={hasAppSelected ? 'default' : 'admin'}>
            <Parts.WorkspaceNavigationWrapper state={workspaceNavState}>
                {showCollapseToggle && (
                    <Tooltip
                        asChild
                        side="bottom"
                        align="center"
                        content={tooltipText}
                        zIndex={201}
                    >
                        <Button
                            startIcon={{
                                name: isWorkspaceNavCollapsed ? 'ChevronRight' : 'ChevronLeft',
                            }}
                            size="s"
                            variant="secondary"
                            onClick={toggleWorkspaceNavCollapsed}
                            className={WorkspaceNavigationCollapsedToggleStyle}
                            aria-label={tooltipText}
                        />
                    </Tooltip>
                )}

                <Box flex column height="full">
                    <Box flex column height="full" grow shrink>
                        <Box
                            flex
                            center
                            justifyContent={isWorkspaceNavCollapsed ? 'center' : 'space-between'}
                            pt="l"
                            pl={isWorkspaceNavCollapsed ? 's' : 'l'}
                            pr={isWorkspaceNavCollapsed ? 's' : 's'}
                            mb="xl"
                        >
                            <WorkspaceLogo />
                            {!isWorkspaceNavCollapsed && canEditWorkspaceSettings && (
                                <Tooltip
                                    asChild
                                    side="bottom"
                                    align="center"
                                    content="Workspace settings"
                                    zIndex={201}
                                >
                                    <Button
                                        size="xs"
                                        variant="ghost"
                                        startIcon={{ name: 'Settings' }}
                                        aria-label="Workspace settings"
                                        onClick={handleOpenWorkspaceSettings}
                                    />
                                </Tooltip>
                            )}
                            {showMainNavigationToggle && (
                                <Button
                                    startIcon={{
                                        name: 'ArrowRight',
                                    }}
                                    size="s"
                                    variant="ghost"
                                    onClick={showMainNavigation}
                                    aria-label="Show app navigation"
                                />
                            )}
                        </Box>
                        <Box flex column grow shrink>
                            <SystemNavigation isCollapsed={isWorkspaceNavCollapsed} />
                            <WorkspaceNavigationLinks isCollapsed={isWorkspaceNavCollapsed}>
                                <NavigationSpaces isCollapsed={isWorkspaceNavCollapsed} />
                                <NavigationPortals isCollapsed={isWorkspaceNavCollapsed} />
                            </WorkspaceNavigationLinks>
                        </Box>
                    </Box>
                    {workspaceNavState === 'static' && showTrialBanner && (
                        <Box px="l" mb="m">
                            <NavigationTrialBanner background="gray50" p="xl" rounded="m" />
                        </Box>
                    )}
                    <NavigationCurrentUserDropdown isCollapsed={isWorkspaceNavCollapsed} />
                </Box>
            </Parts.WorkspaceNavigationWrapper>
        </ThemeProvider>
    )
}

import { Bundle } from 'features/AiAppBuilder/bundles/bundleBuilder'
import { AppSchemaNoFieldsNoRecords } from 'features/AiAppBuilder/bundles/zodSchema'
import { getChatCompletion } from 'features/AiAppBuilder/chatUtils/chatApi'
import { zodToOpenAiResponseFormat } from 'features/AiAppBuilder/chatUtils/zodUtils'

export async function generateTableNames(requirements: string[]): Promise<Bundle> {
    console.log('# Generating table names', { requirements })

    //     For objects which are like people, companies, product listings etc. you should always try to include an image field.
    // Some examples of this include: customer, leads, employees, companies, products, etc.

    // If you do add an image, make it be the second field.
    const result = await getChatCompletion(
        [
            {
                role: 'system',
                content: `You are an app building assistant. 
                                You must identify up to 4 main objects/tables needed for the requirements below.
                                Do not include a users table. Do not generate xref/junction tables.
                                Use plural, human friendly names.
                                Prefer simpler schema with fewer objects.
                                App name should be a short, human friendly name.
                                `,
            },
            { role: 'user', content: requirements.join('\n- ') },
        ],
        undefined,
        zodToOpenAiResponseFormat(AppSchemaNoFieldsNoRecords, 'appSchema')
    )

    const app = JSON.parse(result.content ?? '')

    return { apps: [app] }
}

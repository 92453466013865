import React, { useEffect, useState } from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { ColorScheme } from 'ui/styling/baseVariables/colors/colorPalette'
import { colorSchemeVar } from 'ui/styling/GlobalStyles.css'
import { Breakpoint, breakpoints } from 'ui/styling/helpers/breakpoints'
import { adminTheme, darkTheme, lightTheme, onboardingTheme } from 'ui/styling/Theme.css'

import {
    mapThemeNameToRawVariables,
    ThemeName,
    ThemeProviderProps,
    ThemeProviderRef,
} from './themeTypes'
import { ThemeContext } from './useTheme'

export const ThemeProvider = React.forwardRef<ThemeProviderRef, ThemeProviderProps>(
    ({ children, className, theme = 'default', ...props }, ref) => {
        const themeClassName = mapThemeNameToClassName(theme)
        const rawVariables = mapThemeNameToRawVariables(theme)

        const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>('mobile')

        useEffect(() => {
            const handleResize = () => {
                const width = window.innerWidth
                const breakpoint = Object.entries(breakpoints).find(
                    ([_, { start, end }]) => width >= start && (end === undefined || width < end)
                )
                if (breakpoint) {
                    setCurrentBreakpoint(breakpoint[0] as Breakpoint)
                }
            }

            handleResize()
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }, [])

        const colorSchemeVariableName = colorSchemeVar.replace('var(', '').replace(')', '')

        const colorScheme =
            (window
                .getComputedStyle(document.querySelector(':root')!)
                .getPropertyValue(colorSchemeVariableName)
                .trim() as ColorScheme) || 'Indigo'

        return (
            <ThemeContext.Provider
                value={{
                    theme,
                    themeClassName,
                    themeRawVariables: rawVariables,
                    breakpoint: currentBreakpoint,
                    colorScheme,
                }}
            >
                <Box {...props} className={classNames(className, themeClassName)} ref={ref}>
                    {children}
                </Box>
            </ThemeContext.Provider>
        )
    }
)

function mapThemeNameToClassName(themeName: ThemeName): string | undefined {
    switch (themeName) {
        case 'dark':
            return darkTheme
        case 'admin':
            return adminTheme
        case 'onboarding':
            return onboardingTheme
        default:
            return lightTheme
    }
}

import React from 'react'

import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Input } from 'ui/components/Input'

import { WorkflowTriggerEditorComponent } from './types'

export const SlackTriggerConfig: WorkflowTriggerEditorComponent = ({ trigger, onChange }) => {
    return (
        <>
            <ConfigurationLabel>Channel ID</ConfigurationLabel>
            <Input
                value={(trigger.settings?.channel_id as string | undefined) ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value

                    onChange({
                        settings: {
                            ...trigger.settings,
                            channel_id: value,
                        },
                    })
                }}
            />
            <ConfigurationLabel mt="m">
                Please note that the <i>Stacker Workspace Slack App</i> <strong>must be</strong>{' '}
                invited to the provided channel to be able to trigger this workflow.
            </ConfigurationLabel>
        </>
    )
}

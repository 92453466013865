import React from 'react'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownSeparator,
} from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useWorkspaceNavigationAppAddNewDropdownState } from './hooks/useWorkspaceNavigationAppAddNewDropdownState'
import { NavigationApp } from './types'

type WorkspaceNavigationAppAddNewDropdownProps = React.ComponentPropsWithoutRef<
    typeof DropdownButton
> & {
    app: NavigationApp
}

export const WorkspaceNavigationAppAddNewDropdown: React.FC<
    WorkspaceNavigationAppAddNewDropdownProps
> = ({ app, ...props }) => {
    const {
        canCreateTable,
        createTable,
        showButton,
        canCreateCustomPage,
        createCustomPage,
        canCreateView,
        createView,
    } = useWorkspaceNavigationAppAddNewDropdownState({ app })

    if (!showButton) return null

    return (
        <Dropdown modal={false}>
            <DropdownButton
                variant="ghost"
                startIcon={{
                    name: 'Plus',
                }}
                noShrink
                {...props}
            />
            <DropdownContent style={{ width: '230px' }} onClick={stopPropagation}>
                {canCreateTable && (
                    <DropdownItem
                        startIcon={{ name: 'Sheet' }}
                        label="Data table"
                        onClick={createTable}
                    />
                )}
                {canCreateView && (
                    <>
                        {canCreateTable && <DropdownSeparator />}
                        <DropdownItem
                            startIcon={{ name: 'TableProperties' }}
                            label="Table view"
                            onClick={() => createView('tableV2')}
                        />
                        <DropdownItem
                            startIcon={{ name: 'KanbanSquare' }}
                            label="Board view"
                            onClick={() => createView('boardV2')}
                        />
                        <DropdownItem
                            startIcon={{ name: 'LayoutGrid' }}
                            label="Card view"
                            onClick={() => createView('cardV2')}
                        />
                    </>
                )}
                {canCreateCustomPage && (
                    <>
                        {(canCreateTable || canCreateView) && <DropdownSeparator />}
                        <DropdownItem
                            startIcon={{ name: 'MousePointerSquareDashed' }}
                            label="Custom page"
                            onClick={createCustomPage}
                        />
                    </>
                )}
            </DropdownContent>
        </Dropdown>
    )
}

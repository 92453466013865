// @ts-strict-ignore
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import translateEndUserFilters from 'v2/views/utils/translateEndUserFilters'

import { useDataConnection } from 'data/hooks/dataConnections/useDataConnection'
import { useObject } from 'data/hooks/objects'
import { useRecordCount, useRecords } from 'data/hooks/records'
import {
    createRecord,
    deleteRecords,
    importRawRecords,
    restoreRecords,
    updateRecord,
} from 'data/hooks/records/recordOperations'
import { RECORD_REMOVED } from 'data/utils/constants'
import { canCreateRecords, canDeleteRecords } from 'data/utils/getObjectRecordRestrictions'
import { getAreObjectRecordsEditable } from 'features/admin/fields/logic/availableFieldOperationUtils'
import { getIsStackerNativeObject } from 'features/admin/stackerNativeObjectUtils'
import { DataGrid } from 'features/datagrid/components/DataGrid'
import type { DataGridCellProvider, DataGridEditorHandle } from 'features/datagrid/types'
import { DataGridContext } from 'features/workspace/AdminSideTray/manage-data/DataGridContext'
import useTrack from 'utils/useTrack'

import { Box, Flex, LoadingScreen } from 'v2/ui'
import { colors } from 'v2/ui/theme/styles/default'
import useDebounce from 'v2/ui/utils/useDebounce'

import ErrorState from './ErrorState'
import {
    ObjectDataGridToolbar,
    ObjectDataGridToolbarHandle,
    ObjectDataGridToolbarProps,
} from './ObjectDataGridToolbar'
import SelectedRecordsToolbar from './SelectedRecordsToolbar'

type Props = {
    objectId: string
    hideLabel?: ObjectDataGridToolbarProps['hideLabel']
    setIsDirty?: (dirty: boolean) => void
    isActive: boolean
    showOnlySyncButtons?: boolean
}

class CellProvider implements DataGridCellProvider {
    records: { [keyof: string]: RecordDto } = {}
    setRecords(records: RecordDto[]) {
        // put records into a dictionary keyed by record ID for more
        // performant lookups. We merge the incoming records
        // with what we already have on hand because we may have records
        // cached from the user selecting related records in record links
        this.records = {
            ...this.records,
            ...records.reduce((result, record) => {
                if (record) {
                    result[record._sid] = record
                }
                return result
            }, {}),
        }
    }
    getRecord = (objectId, recordId) => this.records[recordId]
    // When the user selectds records in related record fields we
    // want to add those selected records into our store (if they don't exist)
    onRecordsSelected = (records: RecordDto[]) => {
        records
            .filter((x) => !!x)
            .forEach((record) => (this.records[record._sid] = this.records[record._sid] || record))
    }
}

const ObjectDataGrid: React.VFC<Props> = ({
    objectId,
    hideLabel,
    setIsDirty,
    isActive,
    showOnlySyncButtons,
}) => {
    const { object, changeObject: updateObject } = useObject(objectId)
    const { track } = useTrack()
    const { data: dataConnection } = useDataConnection(object?.data_connection)
    const containerRef = useRef<HTMLElement | undefined>()
    // ORDERING
    const [apiOrderBy, setAPIOrderBy] = useState<{ id: string; desc?: boolean }>()

    // MODALS and Dropdowns
    const { setReadOnly } = useContext(DataGridContext)

    const [trashMode, setTrashMode] = useState<boolean>(false)

    const [pageIndex, setPageIndex] = useState(0)
    const pageSize = object?.options?.records_per_page || 1000
    const fetchOptions = useMemo(() => {
        if (!object) return undefined

        return {
            dereference: true,
            dereferenceMultiFields: '"*"',
            pageSize,
            pageIndex: pageIndex,
            orderBy: apiOrderBy,
            showingDeleted: trashMode,
            disablePartials: true,
        }
    }, [apiOrderBy, object, pageIndex, pageSize, trashMode])

    const countOptions = useMemo(() => {
        if (!object) return undefined

        return {
            showingDeleted: trashMode,
        }
    }, [object, trashMode])

    const [filters, setFilters] = useState<any[]>([])
    const {
        data: { records: allRecords = [], dereferencedRecords } = {},
        refetch: refetchRecords,
        isLoading: isLoadingRecords,
        isError: isErrorRecords,
    } = useRecords({
        objectSid: objectId,
        filters,
        fetchOptions,
    })

    const { data: totalResults, refetch: refetchRecordCount } = useRecordCount({
        objectSid: objectId,
        filters,
        fetchOptions: countOptions,
    })

    const cellDataProvider = useMemo(() => new CellProvider(), [])
    useEffect(() => {
        cellDataProvider.setRecords(allRecords.concat(dereferencedRecords ?? []))
    }, [allRecords, cellDataProvider, dereferencedRecords])

    const dispatch = useDispatch()
    const isReadOnly = object?.connection_options?.read_only
    const canRecordsBeEdited = !!object && getAreObjectRecordsEditable(object)
    const allowDelete = object && canDeleteRecords(object)
    const allowCreate = object && canCreateRecords(object)

    const dataGridRef = useRef<DataGridEditorHandle | null>(null)
    const objectDataGridToolbarRef = useRef<ObjectDataGridToolbarHandle | undefined>()

    const [selectedRecords, setSelectedRecords] = useState<RecordDto[]>([])

    useEffect(() => track('WIP - Frontend - DataGrid - Viewed'), [track])

    useEffect(() => {
        if (isActive) {
            refetchRecords()
            refetchRecordCount()
        }
    }, [isActive, refetchRecords, refetchRecordCount])

    // allRecords comes with the dereferenced linked records as well,
    // so we need to filter to just include records from this object.
    // NOTE: we're not currently supporting filters, but when we do, we'll need to
    // apply those filters locally too as we may have linked records of this
    // object type included in the list of all records, but which aren't included in the
    // filtered result set.
    const records = useMemo(
        () => allRecords.filter((record) => record._object_id === objectId),
        [allRecords, objectId]
    )

    const [search, setSearch] = useState<string | undefined>()

    const applySearch = (search?: string) => setFilters(translateEndUserFilters({}, [], search))
    const debounceApplySearch = useDebounce(applySearch)
    const onSearch = React.useCallback(
        (newVal?: string) => {
            setSearch(newVal)
            debounceApplySearch(newVal)
        },
        [debounceApplySearch]
    )

    useEffect(() => {
        // Reset the page index when the search changes.
        setPageIndex(0)
    }, [search])

    /**
     * Trigger a loading state while we wait for the new records after mode switch
     */
    const [isGridModeChangeLoading, setIsGridModeChangeLoading] = useState<boolean>(false)
    useEffect(() => {
        setIsGridModeChangeLoading(true)
    }, [trashMode])

    useEffect(() => {
        // Trigger a loading state while we wait for the new records after page change.
        setIsGridModeChangeLoading(true)
    }, [pageIndex])

    useEffect(() => {
        setIsGridModeChangeLoading(false)
    }, [allRecords])

    const handleUpdateRecord = async (id, patch) => {
        await updateRecord(id, patch, [], {
            deferStoreUpdate: true,
        })
    }

    const handleAddRecord = async (record: RecordDto): Promise<RecordDto> => {
        const newRecord = (await createRecord(
            { ...record, object_id: objectId },
            undefined,
            // we don't use any of the additional fields returned from create record request so can skip returning them
            true
        )) as RecordDto
        await Promise.all([refetchRecords(), refetchRecordCount()])
        return newRecord
    }

    const handleImportRawRecords = async (
        changes: {
            _sid: string | null
            data: { [keyof: string]: string }
        }[]
    ): Promise<void> => {
        await importRawRecords(objectId, changes)
        await Promise.all([refetchRecords(), refetchRecordCount()])
    }

    const handleDeleteRecords = async (recordIds: string[]) => {
        track('WIP - Frontend - DataGrid - Records - Deleted', { records: recordIds.length })
        if (object?._sid) await deleteRecords(object._sid, recordIds)
        // Remove records from redux
        recordIds.forEach((id) => dispatch({ type: RECORD_REMOVED, id }))
        await Promise.all([refetchRecords(), refetchRecordCount()])
    }

    const handleRestoreRecords = async (recordIds: string[]) => {
        if (object?._sid) await restoreRecords(object._sid, recordIds)
        await Promise.all([refetchRecords(), refetchRecordCount()])
    }

    const handleFieldOrderChange = async (fieldsOrder: string[]) => {
        if (!object) return

        await updateObject(
            {
                options: {
                    ...object.options,
                    fields_order: fieldsOrder,
                },
            },
            {
                allowOptimisticUpdates: true,
            }
        )
    }

    useEffect(() => {
        if (apiOrderBy?.id) {
            track('WIP - Frontend - DataGrid - Column Header - Clicked - Sorting', {
                direction: apiOrderBy?.desc ? 'desc' : 'asc',
                field: apiOrderBy?.id,
            })
        }
    }, [apiOrderBy, track])

    if (!object) return null
    return (
        <Flex
            ref={containerRef}
            column
            wrap="nowrap"
            width="100%"
            height="100%"
            borderBottomRightRadius={8}
            align="stretch"
            data-dataconnectiontype={dataConnection?.type}
        >
            {selectedRecords.length === 0 && (
                <ObjectDataGridToolbar
                    ref={objectDataGridToolbarRef}
                    dataConnection={dataConnection}
                    hideLabel={hideLabel}
                    showOnlySyncButtons={showOnlySyncButtons}
                    object={object}
                    search={search}
                    onSearch={onSearch}
                    onModalToggled={setReadOnly}
                    trashMode={trashMode}
                    setTrashMode={setTrashMode}
                />
            )}

            {selectedRecords.length > 0 && (
                <SelectedRecordsToolbar
                    onDeleteClick={
                        allowDelete ? () => dataGridRef.current?.deleteSelectedRecords() : undefined
                    }
                    onRestoreClick={() => dataGridRef.current?.restoreSelectedRecords()}
                    recordCount={selectedRecords.length}
                    trashMode={trashMode}
                />
            )}

            <Box position="relative" flexGrow={1} px={2} pb={2}>
                <Box
                    width="100%"
                    height="100%"
                    overflow="hidden"
                    border={`1px solid ${colors.userInterface.neutral[500]} `}
                    borderRadius="5px"
                >
                    <LoadingScreen isLoading={isLoadingRecords || isGridModeChangeLoading}>
                        {isErrorRecords ? (
                            <ErrorState
                                showSettings={() =>
                                    objectDataGridToolbarRef.current?.showSettings()
                                }
                                showFields={() => objectDataGridToolbarRef.current?.showFields()}
                            />
                        ) : (
                            <DataGrid
                                ref={dataGridRef}
                                object={object}
                                records={records}
                                updateRecord={canRecordsBeEdited ? handleUpdateRecord : undefined}
                                addRecord={allowCreate ? handleAddRecord : undefined}
                                deleteRecords={allowDelete ? handleDeleteRecords : undefined}
                                restoreRecords={handleRestoreRecords}
                                setIsDirty={setIsDirty}
                                onRecordsSelected={setSelectedRecords}
                                refetchRecords={refetchRecords as () => Promise<any>}
                                onColumnsOrderChanged={handleFieldOrderChange}
                                cellDataProvider={cellDataProvider}
                                dataSourceSupportsPasting={getIsStackerNativeObject(object)}
                                dataSourceLabel={dataConnection?.label || ''}
                                importRawRecords={!isReadOnly ? handleImportRawRecords : undefined}
                                orderBy={apiOrderBy}
                                setOrderBy={setAPIOrderBy}
                                trashMode={trashMode}
                                setPageIndex={setPageIndex}
                                pageIndex={pageIndex}
                                pageSize={fetchOptions?.pageSize}
                                totalRecordCount={totalResults}
                            />
                        )}
                    </LoadingScreen>
                </Box>
            </Box>
        </Flex>
    )
}

export default ObjectDataGrid

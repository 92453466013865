import React from 'react'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'

import { WorkflowLastExecution } from './WorkflowLastExecution'
import { WorkflowTrigger } from './WorkflowTrigger'

type WorkflowListProps = {
    workflows: WorkflowDto[]
    onSelect: (workflow: WorkflowDto) => void
    openFlowExecutionHistory: (workflow: WorkflowDto) => void
}
export function WorkflowList({ workflows, onSelect, openFlowExecutionHistory }: WorkflowListProps) {
    return (
        <>
            <Container variant="neutralMuted" flex p="m" px="l" color="textWeak" fontSize="bodyM">
                <Box style={{ flexBasis: '25%' }}>Name</Box>
                <Box style={{ flexBasis: '25%' }}>Trigger</Box>
                <Box style={{ flexBasis: '25%' }}>Status</Box>
                <Box style={{ flexBasis: '25%' }}>Last run</Box>
            </Container>
            <Divider mb="m" />
            <Box grow fontSize="bodyM">
                {workflows?.length === 0 && (
                    <Box flex center px="l" py="m" color="textWeak">
                        Create a workflow to get started.
                    </Box>
                )}
                {workflows?.map((workflow) => (
                    <WorkflowListItem
                        key={workflow._sid}
                        workflow={workflow}
                        onSelect={() => onSelect(workflow)}
                        openFlowExecutionHistory={openFlowExecutionHistory}
                    />
                ))}
            </Box>
        </>
    )
}

type WorkflowListItemProps = {
    workflow: WorkflowDto
    onSelect: () => void
    openFlowExecutionHistory: (workflow: WorkflowDto) => void
}
export function WorkflowListItem({
    workflow,
    onSelect,
    openFlowExecutionHistory,
}: WorkflowListItemProps) {
    const handleExecutionHistoryClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        openFlowExecutionHistory(workflow)
    }

    return (
        <Box
            flex
            role="listitem"
            onClick={onSelect}
            cursor="pointer"
            background={{ hover: 'surfaceStronger' }}
            p="m"
            px="l"
        >
            <Box style={{ flexBasis: '25%' }} flex center>
                {workflow.name}
            </Box>
            <Box style={{ flexBasis: '25%' }} flex center>
                <WorkflowTrigger trigger={workflow.trigger} />
            </Box>
            <Box
                style={{ flexBasis: '25%' }}
                flex
                center
                color={workflow.is_enabled ? 'text' : 'textWeakest'}
            >
                <Icon
                    name={workflow.is_enabled ? 'Check' : 'X'}
                    mr="xs"
                    style={{ width: '1rem' }}
                    textAlign="center"
                />
                {workflow.is_enabled ? 'Enabled' : 'Disabled'}
            </Box>
            <Box style={{ flexBasis: '25%' }} flex center>
                <WorkflowLastExecution workflow={workflow} onClick={handleExecutionHistoryClick} />
            </Box>
        </Box>
    )
}

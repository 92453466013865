import { SerializedCodeNode } from '@lexical/code'
import { SerializedLinkNode } from '@lexical/link'
import { SerializedListItemNode, SerializedListNode } from '@lexical/list'
import { SerializedHeadingNode, SerializedQuoteNode } from '@lexical/rich-text'
import { JSONContent } from '@tiptap/react'
import {
    SerializedLexicalNode,
    SerializedParagraphNode,
    SerializedRootNode,
    SerializedTextNode,
} from 'lexical'
import { SerializedEditorState } from 'lexical/LexicalEditorState'

import { getUrl } from 'app/UrlService'

export type ConverterOptions = {
    objectResolver?: (id: string) => Promise<ObjectDto | null>
}

export async function convertLexicalFormatToTipTap(
    lexicalFormat: SerializedEditorState,
    options: ConverterOptions = {}
): Promise<JSONContent | null> {
    if (!lexicalFormat.root) return null

    const rootNode = await convertLexicalNodeToTipTap(lexicalFormat.root, options)
    if (!rootNode || rootNode.length < 1) return null

    return rootNode[0]
}

async function convertLexicalNodeToTipTap(
    node: any,
    options: ConverterOptions = {}
): Promise<JSONContent[] | null> {
    if (node.version !== 1) return null

    switch (node.type) {
        case 'root': {
            const typedNode = node as SerializedRootNode

            return [
                {
                    type: 'doc',
                    content: await convertLexicalChildrenToTipTap(typedNode.children, options),
                },
            ]
        }

        case 'text': {
            const typedNode = node as SerializedTextNode
            if (typedNode.mode !== 'normal') return null

            return [
                {
                    type: 'text',
                    text: typedNode.text,
                    marks: convertTextFormatToMarks(typedNode.format),
                },
            ]
        }

        case 'paragraph': {
            const typedNode = node as SerializedParagraphNode

            return [
                {
                    type: 'paragraph',
                    content: await convertLexicalChildrenToTipTap(typedNode.children, options),
                },
            ]
        }

        case 'heading': {
            const typedNode = node as SerializedHeadingNode

            return [
                {
                    type: 'heading',
                    content: await convertLexicalChildrenToTipTap(typedNode.children, options),
                    attrs: {
                        level: convertHeadingTagToLevel(typedNode.tag),
                    },
                },
            ]
        }

        case 'linebreak': {
            return [
                {
                    type: 'hardBreak',
                },
            ]
        }

        case 'quote': {
            const typedNode = node as SerializedQuoteNode

            return [
                {
                    type: 'blockquote',
                    content: [
                        {
                            type: 'paragraph',
                            content: await convertLexicalChildrenToTipTap(
                                typedNode.children,
                                options
                            ),
                        },
                    ],
                },
            ]
        }

        case 'list': {
            const typedNode = node as SerializedListNode

            switch (typedNode.listType) {
                case 'bullet':
                    return [
                        {
                            type: 'bulletList',
                            content: await convertLexicalChildrenToTipTap(
                                typedNode.children,
                                options
                            ),
                        },
                    ]

                case 'number':
                    return [
                        {
                            type: 'orderedList',
                            content: await convertLexicalChildrenToTipTap(
                                typedNode.children,
                                options
                            ),
                            attrs: {
                                start: typedNode.start,
                            },
                        },
                    ]

                case 'check':
                    return [
                        {
                            type: 'taskList',
                            content: (
                                await convertLexicalChildrenToTipTap(typedNode.children, options)
                            ).map((item) => ({
                                ...item,
                                type: item.type === 'listItem' ? 'taskItem' : item.type,
                            })),
                        },
                    ]
            }
        }

        case 'listitem': {
            const typedNode = node as SerializedListItemNode

            const isNestedList =
                typedNode.children.length === 1 && typedNode.children[0].type === 'list'
            if (isNestedList) {
                return convertLexicalNodeToTipTap(typedNode.children[0], options)
            }

            return [
                {
                    type: 'listItem',
                    content: [
                        {
                            type: 'paragraph',
                            content: await convertLexicalChildrenToTipTap(
                                typedNode.children,
                                options
                            ),
                        },
                    ],
                    attrs: {
                        checked: typedNode.checked ?? false,
                    },
                },
            ]
        }

        case 'link': {
            const typedNode = node as SerializedLinkNode

            const convertedChildren = await convertLexicalChildrenToTipTap(
                typedNode.children,
                options
            )

            return convertedChildren.map((child) => ({
                ...child,
                marks: [
                    ...(child.marks ?? []),
                    {
                        type: 'link',
                        attrs: {
                            href: typedNode.url,
                            target: typedNode.target,
                            class: null,
                        },
                    },
                ],
            }))
        }

        case 'image': {
            return [
                {
                    type: 'image',
                    attrs: {
                        src: node.src,
                        alt: node.altText,
                        title: node.caption,
                    },
                },
            ]
        }

        case 'horizontalrule':
        case 'divider': {
            return [
                {
                    type: 'horizontalRule',
                },
            ]
        }

        case 'Record': {
            const typedNode = node

            if (!typedNode.recordId || !typedNode.objectId || !options.objectResolver) {
                return null
            }

            const object = await options.objectResolver(typedNode.objectId)
            if (!object) return null

            const url = getUrl(`${object.url}/view/${typedNode.recordId}`)

            return [
                {
                    type: 'paragraph',
                    content: [
                        {
                            type: 'recordLink',
                            attrs: {
                                id: typedNode.recordId,
                                stack_id: object.stack_id,
                                url,
                            },
                        },
                    ],
                },
            ]
        }

        case 'tablesheet': {
            const typedNode = node

            return [
                {
                    type: 'table',
                    content: await convertLexicalTableRowsToTipTap(typedNode.rows),
                },
            ]
        }

        case 'collapsible-container': {
            const typedNode = node

            return [
                {
                    type: 'details',
                    content: await convertLexicalChildrenToTipTap(typedNode.children, options),
                    attrs: {
                        open: typedNode.open,
                    },
                },
            ]
        }

        case 'collapsible-title': {
            const typedNode = node

            return [
                {
                    type: 'detailsSummary',
                    content: await convertLexicalChildrenToTipTap(typedNode.children, options),
                },
            ]
        }

        case 'collapsible-content': {
            const typedNode = node

            return [
                {
                    type: 'detailsContent',
                    content: await convertLexicalChildrenToTipTap(typedNode.children, options),
                },
            ]
        }

        case 'code': {
            const typedNode = node as SerializedCodeNode

            return [
                {
                    type: 'paragraph',
                    content: await convertLexicalChildrenToTipTap(typedNode.children, options),
                },
            ]
        }

        case 'code-highlight': {
            const typedNode = node as SerializedTextNode
            if (typedNode.mode !== 'normal') return null

            return [
                {
                    type: 'text',
                    text: typedNode.text,
                    marks: [{ type: 'code' }],
                },
            ]
        }

        default:
            return null
    }
}

async function convertLexicalChildrenToTipTap(
    nodes: SerializedLexicalNode[],
    options: ConverterOptions = {}
): Promise<JSONContent[]> {
    const converted = await Promise.all(
        nodes.map((node) => convertLexicalNodeToTipTap(node, options))
    )

    return converted.reduce<JSONContent[]>((acc, node) => {
        if (node) {
            const validSubNodes = node.filter(Boolean)
            if (validSubNodes.length > 0) {
                acc.push(...validSubNodes)
            }
        }

        return acc
    }, [])
}

function convertTextFormatToMarks(textFormat: number): { type: string }[] {
    const lexicalFormats = {
        bold: 1,
        italic: 1 << 1,
        strikethrough: 1 << 2,
        underline: 1 << 3,
        code: 1 << 4,
        subscript: 1 << 5,
        superscript: 1 << 6,
        highlight: 1 << 7,
    }

    const marks: { type: string }[] = []
    for (const [type, flag] of Object.entries(lexicalFormats)) {
        const hasFormat = textFormat & flag
        if (!hasFormat) continue

        switch (type) {
            case 'bold':
                marks.push({ type: 'bold' })
                break
            case 'italic':
                marks.push({ type: 'italic' })
                break
            case 'strikethrough':
                marks.push({ type: 'strike' })
                break
            case 'code':
                marks.push({ type: 'code' })
                break
            case 'underline':
                marks.push({ type: 'underline' })
                break
            case 'subscript':
                marks.push({ type: 'subscript' })
                break
            case 'superscript':
                marks.push({ type: 'superscript' })
                break
        }
    }

    return marks
}

function convertHeadingTagToLevel(tag: string): number {
    switch (tag) {
        case 'h1':
            return 1
        case 'h2':
            return 2
        case 'h3':
            return 3
        case 'h4':
            return 4
        case 'h5':
            return 5
        case 'h6':
            return 6
        default:
            return 1
    }
}

async function convertLexicalTableRowsToTipTap(rows: any[]): Promise<JSONContent[]> {
    return Promise.all(
        rows.map(async (r) => {
            return {
                type: 'tableRow',
                content: await convertLexicalTableCellsToTipTap(r.cells),
            }
        })
    )
}

async function convertLexicalTableCellsToTipTap(cells: any[]): Promise<JSONContent[]> {
    return Promise.all(
        cells.map(async (c) => {
            const type = c.type === 'header' ? 'tableHeader' : 'tableCell'

            let content: JSONContent[] = []
            try {
                const contentJson = JSON.parse(c.json)
                content = await convertLexicalChildrenToTipTap(contentJson.root.children)
            } catch {}

            return {
                type,
                content,
                attrs: {
                    colwidth: c.width,
                    colspan: c.colSpan,
                },
            }
        })
    )
}

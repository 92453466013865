import React, { useCallback, useRef } from 'react'

import { useGrabToResizeElement } from 'utils/useGrabToResizeElement'

import * as Parts from './Textarea.parts'
import { TextareaResizeHandle } from './TextareaResizeHandle'

type TextareaWrapperProps = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.Wrapper>,
    'isDisabled' | 'isReadOnly'
> & {
    readOnly?: boolean
    disabled?: boolean
    isResizable?: boolean
    autoSize?: boolean
}

export const TextareaWrapper: React.FC<TextareaWrapperProps> = ({
    children,
    disabled,
    readOnly,
    isResizable,
    size,
    ...props
}) => {
    const wrapperRef = useRef<HTMLElement>(null)

    const handleResize = useCallback((height: number) => {
        if (wrapperRef.current) {
            wrapperRef.current.style.height = `${height}px`
        }
    }, [])

    const { handleMouseDown: handleHandleMouseDown } = useGrabToResizeElement(
        wrapperRef.current,
        handleResize
    )

    return (
        <Parts.Wrapper
            ref={wrapperRef}
            isDisabled={disabled}
            isReadOnly={readOnly}
            size={size}
            {...props}
        >
            {children}
            {isResizable && (
                <TextareaResizeHandle
                    size={size}
                    isDisabled={disabled}
                    isReadOnly={readOnly}
                    onMouseDown={handleHandleMouseDown}
                    role="presentation"
                />
            )}
        </Parts.Wrapper>
    )
}

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Rights } from 'app/accountUserContextConstants'
import { useAuthContext } from 'app/AuthContext/AuthContext'
import { useAccountUserContext } from 'app/useAccountUserContext'
import { useAppContext } from 'app/useAppContext'
import { useAppUserContext } from 'app/useAppUserContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { useAccounts } from 'data/hooks/accounts'
import { useGroupedWorkspaceStacks } from 'data/hooks/stacks'
import useLDFlags from 'data/hooks/useLDFlags'
import { useZones } from 'data/hooks/zones'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { NavigationContext, NavigationContextValue } from './NavigationContext'
import {
    NavigationCreateAppModal,
    NavigationCreateAppModalHandle,
} from './NavigationCreateAppModal'
import {
    NavigationCreateViewModal,
    NavigationCreateViewModalHandle,
} from './NavigationCreateViewModal'
import { NavigationApp } from './types'
import {
    getNavigationCurrentUserFromUser,
    mapGroupsToNavigationSpaces,
    mapPortalsToNavigationPortals,
    mapStackToNavigationApp,
} from './utils'

type NavigationContextProviderProps = {}

export const NavigationContextProvider: React.FC<NavigationContextProviderProps> = ({
    children,
}) => {
    const { value, createViewModalRef, createAppModalRef } = useContextValue()

    return (
        <NavigationContext.Provider value={value}>
            {children}
            {!!value.workspaceZone && !!value.workspaceAccount && (
                <>
                    <NavigationCreateViewModal ref={createViewModalRef} />
                    <NavigationCreateAppModal ref={createAppModalRef} />
                </>
            )}
        </NavigationContext.Provider>
    )
}

function useContextValue() {
    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })
    const isMobileRef = useRef(isMobile)
    isMobileRef.current = isMobile

    const [workspaceNavState, setWorkspaceNavState] =
        useState<NavigationContextValue['workspaceNavState']>('collapsed')

    let effectiveWorkspaceNavState = workspaceNavState
    if (isMobile && !workspaceNavState.startsWith('mobile-')) {
        effectiveWorkspaceNavState =
            workspaceNavState === 'collapsed' ? 'mobile-collapsed' : 'mobile-expanded'
    }

    const { workspaceZone, isOnPortalDomain, workspaceAccount } = useWorkspaceContext()
    const workspaceAccountMemo = useDeepEqualsMemoValue(workspaceAccount ?? undefined)
    const workspaceZoneMemo = useDeepEqualsMemoValue(workspaceZone ?? undefined)

    const { hasRight, role: workspaceAccountRole, role } = useAccountUserContext()
    const { isPreviewingAsUserOrRole } = usePreviewServiceContext()
    const canEditWorkspaceSettings = hasRight(Rights.ViewSettings)
    const canEditPortalSettings = hasRight(Rights.ViewSettings)
    const canCreatePortal = hasRight(Rights.ManageSettings)
    const canCreateApp = hasRight(Rights.CreateApps)
    const canEditAppUsers = hasRight(Rights.ManageUsers)

    const { isAdmin } = useAppUserContext()

    const { data: workspaceAccounts = [] } = useAccounts()
    const { data: workspaceZones = [] } = useZones()

    const internalZone = workspaceZones.find(
        (zone) => zone.account_sid === workspaceAccountMemo?._sid && zone.type === 'Internal'
    )

    const { selectedStack } = useAppContext()
    const selectedApp = selectedStack
        ? mapStackToNavigationApp({
              stack: selectedStack,
              account: workspaceAccountMemo,
              zone: internalZone,
              isOnPortalDomain,
          })
        : undefined
    const selectedAppMemo = useDeepEqualsMemoValue(selectedApp)
    const selectedAppRef = useRef(selectedApp)
    selectedAppRef.current = selectedApp

    const [mainNavState, setMainNavState] =
        useState<NavigationContextValue['mainNavState']>('collapsed')

    let effectiveMainNavState = mainNavState
    if (isMobile && !mainNavState.startsWith('mobile-')) {
        effectiveMainNavState =
            mainNavState === 'collapsed' ? 'mobile-collapsed' : 'mobile-expanded'
    }

    useLayoutEffect(() => {
        if (isMobile) {
            if (!selectedAppMemo) {
                // If mobile, just go back to the workspace navigation.
                setWorkspaceNavState('mobile-expanded')
                setMainNavState('mobile-collapsed')
            }

            return
        }

        if (selectedAppMemo) {
            setWorkspaceNavState('collapsed')
            setMainNavState('static')
        } else {
            // If there is no selected app, we want to expand the workspace nav and collapse the app nav.
            setWorkspaceNavState('static')
            setMainNavState('collapsed')
        }
    }, [selectedAppMemo, isMobile])

    const { data: groups } = useGroupedWorkspaceStacks({ filterByZoneSid: internalZone?._sid })
    const spaces = useDeepEqualsMemoValue(
        mapGroupsToNavigationSpaces({
            groups,
            account: workspaceAccountMemo,
            zone: internalZone,
            isOnPortalDomain,
        })
    )

    const { user } = useAuthContext()
    const currentUser = useDeepEqualsMemoValue(getNavigationCurrentUserFromUser(user))

    const createAppModalRef = useRef<NavigationCreateAppModalHandle>(null)

    const showCreateAppModal = useCallback(
        (spaceId?: string) => {
            createAppModalRef.current?.open(spaceId)
        },
        [createAppModalRef]
    )

    const isPortal = workspaceZoneMemo?.type === 'Portal'

    const portals = useDeepEqualsMemoValue(
        mapPortalsToNavigationPortals({
            zones: workspaceZones,
            account: workspaceAccountMemo,
            isOnPortalDomain,
        })
    )

    const openMobileNavigation = useCallback(() => {
        const selectedApp = selectedAppRef.current
        setMainNavState(selectedApp ? 'mobile-expanded' : 'mobile-collapsed')
        setWorkspaceNavState(selectedApp ? 'mobile-collapsed' : 'mobile-expanded')
    }, [])

    const closeMobileNavigation = useCallback(() => {
        setWorkspaceNavState('mobile-collapsed')
        setMainNavState('mobile-collapsed')
    }, [])

    const toggleMobileMainNavigation = useCallback((state: boolean) => {
        setWorkspaceNavState(state ? 'mobile-collapsed' : 'mobile-expanded')
        setMainNavState(state ? 'mobile-expanded' : 'mobile-collapsed')
    }, [])

    const tryCollapseWorkspaceNav = useCallback(() => {
        const isMobile = isMobileRef.current

        if (isMobile) {
            closeMobileNavigation()
        } else {
            setWorkspaceNavState((prev) => {
                if (prev === 'expanded') {
                    return 'collapsed'
                }

                return prev
            })
        }
    }, [closeMobileNavigation])

    const history = useHistory()
    const executeActionInApp = useCallback(
        (app: NavigationApp, action: () => void) => {
            tryCollapseWorkspaceNav()

            const selectedApp = selectedAppRef.current
            // Go to the app if we're not already on it.
            if (selectedApp?.id !== app.id) {
                history.push(app.url)
            }

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    action()
                })
            })
        },
        [tryCollapseWorkspaceNav, history]
    )

    const createViewModalRef = useRef<NavigationCreateViewModalHandle>(null)

    const showCreateViewModal = useCallback(
        (app: NavigationApp, display: ListViewDisplay) => {
            executeActionInApp(app, () => {
                createViewModalRef.current?.open(display)
            })
        },
        [executeActionInApp]
    )

    const location = useLocation()
    useEffect(() => {
        // Close mobile navigation on route change.
        const isMobile = isMobileRef.current
        if (isMobile) {
            closeMobileNavigation()
        }
    }, [location.pathname, closeMobileNavigation])

    const { flags } = useLDFlags()
    const canSeeTrialBanner = role?.api_name === 'owner' || !!isPreviewingAsUserOrRole
    const showTrialBanner = canSeeTrialBanner && !!flags.showTrialBanner

    const permissions: NavigationContextValue['permissions'] = useMemo(
        () => ({
            canEditWorkspaceSettings,
            canEditPortalSettings,
            canDeleteApp: isAdmin,
            canEditAppSettings: isAdmin,
            canEditAppUsers,
            canPinApp: isAdmin,
            canCreateTable: isAdmin,
            canCreateView: isAdmin,
            canCreateCustomPage: isAdmin,
            canDeleteLayout: isAdmin,
            canEditViewLayout: isAdmin,
            canDuplicateLayout: isAdmin,
            canArchiveApp: isAdmin,
            canChangeAppSpace: isAdmin,
            canCreateApp,
            canCreatePortal,
            canSeeTrialBanner,
        }),
        [
            canEditWorkspaceSettings,
            canEditPortalSettings,
            isAdmin,
            canEditAppUsers,
            canCreateApp,
            canCreatePortal,
            canSeeTrialBanner,
        ]
    )

    const value = useDeepEqualsMemoValue({
        workspaceNavState: effectiveWorkspaceNavState,
        setWorkspaceNavState,
        tryCollapseWorkspaceNav,
        executeActionInApp,
        mainNavState: effectiveMainNavState,
        workspaceZone: workspaceZoneMemo,
        permissions,
        selectedApp: selectedAppMemo,
        currentUser,
        spaces,
        isPortal,
        isOnPortalDomain,
        showCreateViewModal,
        workspaceAccountRole,
        workspaceAccounts,
        workspaceAccount: workspaceAccountMemo,
        workspaceZones,
        showCreateAppModal,
        portals,
        openMobileNavigation,
        closeMobileNavigation,
        toggleMobileMainNavigation,
        showTrialBanner,
    })

    return useMemo(() => ({ value, createViewModalRef, createAppModalRef }), [value])
}

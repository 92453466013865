import React from 'react'

import styled from '@emotion/styled'

export const Label = ({
    text,
    marginBottom,
}: {
    text: string
    marginBottom?: number | string
}): React.ReactElement => {
    return (
        <div
            style={{
                fontSize: '14px',
                color: 'rgb(31, 33, 35)',
                fontWeight: 600,
                marginBottom: marginBottom ?? '5px',
            }}
        >
            {text}
        </div>
    )
}

export const NonEditable = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.05);
    padding: 12px 16px;
    border-radius: 6px;

    user-select: all;

    display: flex;
    align-items: center;
`

import React from 'react'

import styled from '@emotion/styled'
import { ViewLayoutContext } from 'v2/blocks/types'

import { Text } from 'v2/ui'

const Wrapper = styled.div<{ minHeight?: number; fullHeight?: boolean; noMargin?: boolean }>`
    ${({ noMargin }) => !noMargin && `margin-bottom: 10px;`}
    padding: 0;
    width: 100%;
    max-height: 100%;
    min-height: ${({ minHeight }) => (minHeight ? minHeight : 'initial')};
    ${({ fullHeight }) => fullHeight && `height: 100%;`}

    & > label {
        color: #666;
        font-weight: bold;
    }

    .Dropdown-root {
        font-size: 1em;
        margin-left: 0;
        display: block;
        width: 100%;
    }
`

type Params = Record<string, unknown>

export type RenderRowAttributeRenderProps<
    P = Params,
    K extends keyof P = keyof P,
> = RenderRowAttributeProps<P> & {
    value: P[K]
    onChange: (value: P[K]) => void
}

type RenderRowAttributeProps<P = Params, K extends keyof P = keyof P> = {
    params: P
    field: K
    render: (props: RenderRowAttributeRenderProps<P, K>) => React.ReactElement | null
    setAttr: (field: K, value: P[K]) => void
    setAttrs: (value: Partial<P>) => void
    setOnlyVisible: (isVisible: boolean) => void
    context: ViewLayoutContext
    simpleOnly?: boolean
    simple?: boolean
    noLabel?: boolean
    fullHeight?: boolean
    minHeight?: number
    label?: string
    noMargin?: boolean
}

const RenderRowAttribute: React.FC<RenderRowAttributeProps> = (props) => {
    const renderProps: RenderRowAttributeRenderProps = {
        ...props,
        value: props.params[props.field],
        onChange: (value) => props.setAttr(props.field, value),
    }

    if (props.simpleOnly && !props.simple) return null

    return (
        <Wrapper
            fullHeight={props.fullHeight}
            minHeight={props.minHeight}
            noMargin={props.noMargin}
        >
            {!props.noLabel && <Text variant="paletteSectionLabel">{props.label}</Text>}
            {props.render(renderProps)}
        </Wrapper>
    )
}

export default RenderRowAttribute

import { faGoogle, faSlack } from '@fortawesome/free-brands-svg-icons'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import { faTable as faTableLight } from '@fortawesome/pro-light-svg-icons'
import {
    faAnalytics,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowDown,
    faArrowLeft,
    faArrowLeftLong,
    faArrowRight,
    faArrowsAltH,
    faArrowUp,
    faBoxOpen,
    faBrush,
    faBuilding,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCardsBlank,
    faChartBar,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faColumns,
    faCopy,
    faCreditCard,
    faDesktop,
    faDiagramNext,
    faEllipsisH,
    faEnvelope,
    faExchange,
    faExclamationCircle,
    faExternalLink,
    faFile,
    faFilePlus,
    faFolders,
    faGripLinesVertical,
    faHourglassHalf,
    faImage,
    faInfoCircle,
    faListAlt,
    faMinus,
    faMousePointer,
    faPencilPaintbrush,
    faPhone,
    faRocketLaunch,
    faSave,
    faSearch,
    faServer as faServerOutline,
    faSquare,
    faStars,
    faStickyNote,
    faTable,
    faThumbsUp,
    faUserCircle,
    faUserPlus,
    faWrench,
} from '@fortawesome/pro-regular-svg-icons'
import {
    faAddressBook,
    faAddressCard,
    faBars,
    faBell,
    faBolt,
    faBook,
    faBookBookmark,
    faBookOpen,
    faBoxesAlt,
    faBoxFull,
    faBrush as faBrushSolid,
    faCaretCircleDown,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCheckCircle as faCheckCircleSolid,
    faCog,
    faCogs,
    faComment,
    faComments,
    faCrown,
    faDatabase,
    faEdit,
    faEllipsisV,
    faEnvelope as faEnvelopeSolid,
    faExclamationCircle as faExclamationCircleSolid,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFileDownload,
    faGlobeAmericas,
    faGlobeEurope,
    faHome,
    faKey,
    faLightbulb,
    faLink,
    faList,
    faLock,
    faLockOpen,
    faMapSigns,
    faMegaphone,
    faMinusCircle,
    faPalette,
    faPen,
    faPlay,
    faPlus,
    faPlusCircle,
    faQuestion,
    faQuestionCircle,
    faQuoteLeft,
    faQuoteRight,
    faRss,
    faServer,
    faSignOut,
    faSort,
    faSortDown,
    faSortUp,
    faSync,
    faTag,
    faThumbsUp as faThumbsUpSolid,
    faTimes,
    faTrash,
    faUnlink,
    faUser,
    faUserCog,
    faUserFriends,
    faUserLock,
    faUserPlus as faUserPlusSolid,
    faUsers,
    faWrench as faWrenchSolid,
} from '@fortawesome/pro-solid-svg-icons'
export default {
    home: faHome,
    feed: faRss,
    checkmark: faCheck,
    check: faCheck,
    checkSquare: faCheckSquare,
    checkmarkCircle: faCheckCircle,
    checkmarkCircleSolid: faCheckCircleSolid,
    search: faSearch,
    arrowLeft: faAngleLeft,
    arrowRight: faAngleRight,
    arrowUp: faAngleUp,
    arrowDown: faAngleDown,
    longArrowUp: faArrowUp,
    longArrowDown: faArrowDown,
    arrowLeftLong: faArrowLeftLong,
    x: faTimes,
    avatar: faUser,
    user: faUser,
    email: faEnvelope,
    emailSolid: faEnvelopeSolid,
    phone: faPhone,
    date: faCalendar,
    file: faFile,
    edit: faPen,
    editBox: faEdit,
    add: faPlus,
    addCircle: faPlusCircle,
    minus: faMinus,
    dotsH: faEllipsisH,
    dotsV: faEllipsisV,
    checkBox: faSquare,
    trash: faTrash,
    save: faSave,
    box: faBoxOpen,
    cog: faCog,
    warning: faExclamationCircle,
    dragHandle: faGripLinesVertical,
    image: faImage,
    back: faArrowLeft,
    forward: faArrowRight,
    eye: faEye,
    eyeSlash: faEyeSlash,
    dropdown: faCaretCircleDown,
    multiSelect: faList,
    lookup: faLink,
    chain: faLink,
    noChain: faUnlink,
    bell: faBell,
    table: faTable,
    tableLight: faTableLight,
    caretRight: faCaretRight,
    caretDown: faCaretDown,
    caretLeft: faCaretLeft,
    caretUp: faCaretUp,
    columns: faColumns,
    note: faStickyNote,
    info: faInfoCircle,
    question: faQuestion,
    like: faThumbsUp,
    likeSolid: faThumbsUpSolid,
    quoteLeft: faQuoteLeft,
    quoteRight: faQuoteRight,
    refresh: faSync,
    database: faDatabase,
    copy: faCopy,
    profile: faUserCircle,
    addUser: faUserPlus,
    addUserSolid: faUserPlusSolid,
    google: faGoogle,
    lock: faLock,
    lockOpen: faLockOpen,
    desktop: faDesktop,
    list: faListAlt,
    alert: faExclamationTriangle,
    filePlus: faFilePlus,
    hourglass: faHourglassHalf,
    bars: faBars,
    signOut: faSignOut,
    palette: faPalette,
    userSettings: faUserCog,
    users: faUsers,
    tag: faTag,
    bolt: faBolt,
    cogs: faCogs,
    lightbulb: faLightbulb,
    link: faExternalLink,
    chart: faChartBar,
    analytics: faAnalytics,
    pie: faChartPie,
    tabs: faFolders,
    userLock: faUserLock,
    comments: faComments,
    exchange: faExchange,
    brush: faBrush,
    brushSolid: faBrushSolid,
    pencilPaintbrush: faPencilPaintbrush,
    play: faPlay,
    building: faBuilding,
    questionCircle: faQuestionCircle,
    spinner: faSpinnerThird,
    userFriends: faUserFriends,
    world: faGlobeEurope,
    userAddress: faAddressCard,
    globe: faGlobeAmericas,
    data: faServer,
    dataOutline: faServerOutline,
    fullBox: faBoxFull,
    sort: faSort,
    sortDown: faSortDown,
    sortUp: faSortUp,
    creditCard: faCreditCard,
    calendar: faCalendarAlt,
    calendarCheck: faCalendarCheck,
    boxes: faBoxesAlt,
    stars: faStars,
    wrench: faWrench,
    wrenchSolid: faWrenchSolid,
    doubleArrowsH: faArrowsAltH,
    crown: faCrown,
    key: faKey,
    pointer: faMousePointer,
    rocket: faRocketLaunch,
    bookOpen: faBookOpen,
    megaphone: faMegaphone,
    slack: faSlack,
    mapSigns: faMapSigns,
    comment: faComment,
    alertCircle: faExclamationCircleSolid,
    addressBook: faAddressBook,
    minusCircle: faMinusCircle,
    layout: faPencilPaintbrush,
    download: faFileDownload,
    book: faBook,
    bookmark: faBookBookmark,
    cardsBlank: faCardsBlank,
    workflow: faDiagramNext,
}

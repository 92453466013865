import React from 'react'

import { useObjects } from 'data/hooks/objects'
import { TRANSLATIONS } from 'features/DataConnections/constants'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { DataConnectionOptions } from './DataConnectionOptions'
import { ObjectRow } from './ObjectRow'

type DataConnectionSectionProps = {
    dataConnection: DataConnectionDto
}
export const DataConnectionSection: React.FC<DataConnectionSectionProps> = ({ dataConnection }) => {
    const { data: objects } = useObjects()
    const objsForDC = objects.filter((obj) => obj.data_connection == dataConnection._sid)

    return (
        <Box style={{ paddingTop: theme.space.m, paddingBottom: theme.space.m }}>
            <Box flex style={{ justifyContent: 'space-between' }}>
                <Body style={{ color: theme.color.textWeaker }}>{dataConnection.label}</Body>
                <DataConnectionOptions dataConnection={dataConnection} />
            </Box>
            <Box style={{ paddingBottom: theme.space.s }}>
                {!objsForDC.length && (
                    <Body size="s">No {TRANSLATIONS['airtable'].tables} added yet</Body>
                )}
                {objsForDC.map((object) => (
                    <ObjectRow key={object._sid} object={object} />
                ))}
            </Box>
        </Box>
    )
}

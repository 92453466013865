import React, { useEffect, useState } from 'react'

import { useCreateDataConnection } from 'data/hooks/dataConnections/useCreateDataConnection'
import { invalidateDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import {
    invalidateExtDataIntegrations,
    useExtDataIntegrations,
} from 'data/hooks/dataConnections/useExtDataIntegrations'
import { invalidateExternalDatabases } from 'data/hooks/dataConnections/useExternalDatabases'
import { usePopulateUserInfoForExtConnection } from 'data/hooks/dataConnections/usePopulateUserInfoForExtConnection'
import { NEW_DATA_CONNECTION_MODAL_KEY, TRANSLATIONS } from 'features/DataConnections/constants'
import { getNangoClient } from 'features/DataConnections/getNangoClient'

import useModalToggle from 'v2/ui/utils/useModalToggle'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'ui/components/Modal'
import { Body } from 'ui/components/Text'
import { useToast } from 'ui/components/Toast'

import { ExternalAccountSelect } from './ExternalAccountSelect'
import { ExternalDatabaseSelect, SelectedDatabaseType } from './ExternalDatabaseSelect'
import { ExternalObjectSelect } from './ExternalObjectSelect'

export const NewDataConnectionModal: React.FC = () => {
    const { isOpen, data, setIsOpen } = useModalToggle<{
        initialSelectedExternalAccountId: string
    }>(NEW_DATA_CONNECTION_MODAL_KEY)

    const nango = getNangoClient()

    // the external account to create new data connection for (the value will be the nango connection id)
    const [selectedExternalAccountId, setSelectedExternalAccountId] = useState<string>('')
    const [selectedDatabase, setSelectedDatabase] = useState<SelectedDatabaseType>({
        id: '',
        name: '',
    })
    const [selectedExternalObjectIds, setSelectedExternalObjectIds] = useState<Set<string>>(
        new Set()
    )

    useEffect(() => {
        if (data?.initialSelectedExternalAccountId && !selectedExternalAccountId) {
            setSelectedExternalAccountId(data?.initialSelectedExternalAccountId)
        }
    }, [data?.initialSelectedExternalAccountId, selectedExternalAccountId])

    const handleClose = () => {
        data.initialSelectedExternalAccountId = ''
        setSelectedExternalAccountId('')
        setSelectedDatabase({ id: '', name: '' })
        setSelectedExternalObjectIds(new Set())
        setIsOpen(false)
    }
    const handleOnOpenChange = (open: boolean) => {
        if (open) {
            setIsOpen(true)
        } else {
            handleClose()
        }
    }

    const toast = useToast()
    const { data: integrationsData, isLoading: _isLoading } = useExtDataIntegrations()
    const { mutateAsync: createDataConnection } = useCreateDataConnection({
        onError: () => {
            toast({
                title: 'There was a problem creating the data connection. Please try again later.',
                type: 'error',
            })
        },
    })

    const { mutateAsync: populateUserInfoInConnection } = usePopulateUserInfoForExtConnection({
        onError: () => {
            toast({
                // TODO-Nango: REMOVE THIS, only for dev
                title: 'There was a problem populating user info for the connection created',
                type: 'error',
            })
        },
    })

    const hmacForSelectedExtAccount = integrationsData?.connections.find(
        (nangoConnection) => nangoConnection.id === selectedExternalAccountId
    )?.hmac

    const addNewExternalAccount = async () => {
        try {
            if (!integrationsData?.nango_connection_id) {
                throw new Error('Connection ID not set for provider.')
            }

            const nango = getNangoClient()

            // TODO-Nango: replace airtable with integration.id or some other prop passed in
            await nango.auth('airtable', integrationsData.nango_connection_id, {
                params: {},
                // TODO-Nango: Remove assumption that airtable is the only and first integrations
                hmac: integrationsData.integrations[0].hmac,
            })
            await populateUserInfoInConnection({
                nangoConnectionId: integrationsData.nango_connection_id,
                integrationId: 'airtable',
            })
            await invalidateExtDataIntegrations()

            toast({ title: 'Connection added successfully.', type: 'success' })
        } catch (err) {
            console.warn('nango auth error:', err)

            toast({
                title: 'There was a problem authenticating your connection. Please try again later.',
                type: 'error',
            })
        }
    }

    return (
        <Modal open={isOpen} onOpenChange={handleOnOpenChange}>
            <ModalContent>
                <ModalHeader title="Add data source" showCloseButton={true} />
                <Box pb="xl" px="3xl">
                    <Box pb="s">
                        <Body size="m" weight="bold" paddingBottom="m">
                            Account
                        </Body>
                    </Box>
                    <ExternalAccountSelect
                        externalIntegrationId="airtable"
                        value={selectedExternalAccountId}
                        onChange={(newExternalAccountId: string) => {
                            setSelectedExternalAccountId(newExternalAccountId)
                            setSelectedDatabase({ id: '', name: '' })
                            setSelectedExternalObjectIds(new Set())
                        }}
                        onAddExternalAccount={addNewExternalAccount}
                    />
                </Box>

                <Box pb="xl" px="3xl">
                    <Box pb="s">
                        <Body size="m" weight="bold" paddingBottom="m">
                            {TRANSLATIONS['airtable'].Databases}
                        </Body>
                    </Box>
                    <ExternalDatabaseSelect
                        externalIntegrationId="airtable"
                        nangoConnectionId={selectedExternalAccountId}
                        isDisabled={!selectedExternalAccountId}
                        onAddExternalDatabase={async () => {
                            await nango.auth('airtable', selectedExternalAccountId, {
                                params: {},
                                hmac: hmacForSelectedExtAccount,
                            })
                            await invalidateExternalDatabases()
                        }}
                        selectedDatabase={selectedDatabase}
                        onSelectedDatabase={(newDb) => {
                            setSelectedExternalObjectIds(new Set())
                            setSelectedDatabase(newDb)
                        }}
                    />
                </Box>

                {!!selectedExternalAccountId && !!selectedDatabase.id && (
                    <Box pb="xl" px="3xl">
                        <ExternalObjectSelect
                            externalIntegrationId="airtable"
                            nangoConnectionId={selectedExternalAccountId}
                            externalDatabaseId={selectedDatabase.id}
                            selectedExternalObjectIds={selectedExternalObjectIds}
                            setSelectedExternalObjectIds={setSelectedExternalObjectIds}
                        />
                    </Box>
                )}

                <ModalFooter flex flexDirection="row" style={{ justifyContent: 'flex-end' }}>
                    <Button size="l" variant="ghost" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <Button
                        size="l"
                        variant="primary"
                        onClick={async () => {
                            await createDataConnection({
                                label: selectedDatabase.name,
                                type: 'nango_airtable',
                                nango_connection_id: selectedExternalAccountId,
                                external_database_id: selectedDatabase.id,
                                external_object_ids: Array.from(selectedExternalObjectIds),
                            })
                            invalidateDataConnections()
                            handleClose()
                        }}
                        disabled={
                            !selectedExternalAccountId ||
                            !selectedDatabase.id ||
                            !selectedExternalObjectIds.size
                        }
                    >
                        Continue
                    </Button>
                    {/*TODO-Nango capture and display errors */}
                    {/*{error && (*/}
                    {/*    <Body color="textError" size="s" mt="m" weight="medium">*/}
                    {/*        {error}*/}
                    {/*    </Body>*/}
                    {/*)}*/}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

import React from 'react'

import { MenuItem as ChakraMenuItem, MenuItemProps as ChakraMenuItemProps } from '@chakra-ui/react'

import Icon, { IconProps } from './Icon'

export type MenuItemProps = ChakraMenuItemProps & {
    icon?: any
    svg?: IconProps['svg']
    iconColor?: IconProps['color']
    iconProps?: IconProps
}

export const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
    ({ children, icon, svg, iconProps, iconColor, ...props }, ref): React.ReactElement => {
        const getIcon = () => {
            if (typeof icon === 'function') {
                return icon()
            } else if (icon && typeof icon === 'object') {
                return icon
            }
            return (
                <Icon
                    fontSize="14px"
                    icon={icon}
                    svg={svg}
                    size="14px"
                    display="inline"
                    color={iconColor || 'neutral.700'}
                    textAlign="center"
                    {...iconProps}
                />
            )
        }
        return (
            <ChakraMenuItem
                // @ts-expect-error
                ref={ref}
                color="neutral.1000"
                fontFamily="button"
                fontSize="smmd"
                border="unset"
                bg="transparent"
                cursor="pointer"
                px={3}
                py={2}
                {...props}
            >
                {(icon || svg) && (
                    <>
                        <div
                            style={{
                                width: 12,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {getIcon()}
                        </div>
                        <div style={{ width: 12 }} />
                    </>
                )}

                {children}
            </ChakraMenuItem>
        )
    }
)

export default MenuItem

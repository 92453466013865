import React from 'react'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'

type ConditionComparisonOpInputProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: FilterOperation
    onChange: (value: FilterOperation) => void
    operations: {
        label: string
        value: FilterOperation
    }[]
}

export const ConditionComparisonOpInput: React.FC<ConditionComparisonOpInputProps> = ({
    value,
    onChange,
    operations,
    disabled,
    ...props
}) => {
    const currentLabel = operations.find((op) => op.value === value)?.label ?? 'select a condition'

    return (
        <Box flex center {...props}>
            <Dropdown>
                <DropdownButton
                    variant="secondary"
                    width="full"
                    height="full"
                    style={{
                        justifyContent: 'space-between',
                    }}
                    trim
                    disabled={disabled}
                    endIcon={{
                        name: 'ChevronDown',
                    }}
                >
                    {currentLabel}
                </DropdownButton>
                <DropdownContent align="end">
                    {operations.map(({ label, value: operationValue }) => (
                        <DropdownItem
                            key={operationValue}
                            multiSelect
                            checked={value === operationValue}
                            onCheckedChange={(checked) => {
                                if (checked) onChange(operationValue)
                            }}
                            defaultChecked={operationValue === operations[0]?.value}
                            label={label}
                        />
                    ))}
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}

import React, { forwardRef, useCallback } from 'react'

import { Button } from 'ui/components/Button'

import * as Parts from './Modal.parts'

import { CoverImageLoaded } from './Modal.css'

type CoverImageProps = {
    src: string
    alt: string
}

type ModalHeaderRef = HTMLDivElement

type ModalHeaderProps = React.ComponentPropsWithoutRef<typeof Parts.HeaderWrapper> &
    ModalControlsProps & {
        title?: React.ReactNode
        subtitle?: React.ReactNode
        coverImage?: CoverImageProps
    }

export const ModalHeader = forwardRef<ModalHeaderRef, ModalHeaderProps>(
    (
        {
            title,
            subtitle,
            children,
            showCloseButton = true,
            showExpandButton = false,
            showBackButton,
            onExpandButtonClick,
            onBackButtonClick,
            coverImage,
            ...props
        },
        ref
    ) => {
        const titleComponent = title ? (
            <Parts.Title showBackButton={showBackButton} showCoverImage={!!coverImage}>
                {title}
            </Parts.Title>
        ) : undefined

        const handleCoverImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
            const img = e.target as HTMLImageElement
            img.classList.add(CoverImageLoaded)
        }, [])

        return (
            <Parts.HeaderWrapper ref={ref} {...props}>
                {coverImage && (
                    <Parts.CoverImageWrapper>
                        <ModalControls
                            showCloseButton={showCloseButton}
                            showExpandButton={showExpandButton}
                            showBackButton={showBackButton}
                            onExpandButtonClick={onExpandButtonClick}
                            onBackButtonClick={onBackButtonClick}
                        />
                        <Parts.CoverImage
                            src={coverImage.src}
                            alt={coverImage.alt}
                            onLoad={handleCoverImageLoad}
                        />
                    </Parts.CoverImageWrapper>
                )}
                <Parts.Header showBackButton={showBackButton} showCoverImage={!!coverImage}>
                    {coverImage ? (
                        titleComponent
                    ) : (
                        <ModalControls
                            showCloseButton={showCloseButton}
                            showExpandButton={showExpandButton}
                            showBackButton={showBackButton}
                            onExpandButtonClick={onExpandButtonClick}
                            onBackButtonClick={onBackButtonClick}
                        >
                            {titleComponent}
                        </ModalControls>
                    )}

                    {subtitle && <Parts.Subtitle>{subtitle}</Parts.Subtitle>}
                    {children}
                </Parts.Header>
            </Parts.HeaderWrapper>
        )
    }
)

type ModalControlsProps = {
    showBackButton?: boolean
    showCloseButton?: boolean
    showExpandButton?: boolean
    onExpandButtonClick?: () => void
    onBackButtonClick?: () => void
}

const ModalControls: React.FC<ModalControlsProps> = ({
    showBackButton,
    onBackButtonClick,
    showExpandButton,
    onExpandButtonClick,
    showCloseButton,
    children,
}) => {
    return (
        <Parts.Controls>
            {showBackButton && (
                <Button
                    variant="ghost"
                    size="s"
                    aria-label="Go back"
                    startIcon={{ name: 'ChevronLeft' }}
                    style={{ visibility: !!onBackButtonClick ? 'visible' : 'hidden' }}
                    onClick={onBackButtonClick}
                />
            )}
            {children}
            <Parts.ControlsActionContainer>
                {showExpandButton && (
                    <Button
                        variant="ghost"
                        size="s"
                        aria-label="Expand"
                        startIcon={{ name: 'Expand' }}
                        onClick={onExpandButtonClick}
                    />
                )}
                {showCloseButton && (
                    <Parts.Close asChild>
                        <Button
                            variant="ghost"
                            size="s"
                            aria-label="Close"
                            startIcon={{ name: 'X' }}
                        />
                    </Parts.Close>
                )}
            </Parts.ControlsActionContainer>
        </Parts.Controls>
    )
}

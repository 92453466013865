import { useCallback, useEffect, useState } from 'react'

import { Editor } from '@tiptap/core'

function useForceUpdate() {
    const [, setValue] = useState(0)

    return useCallback(() => setValue((value) => value + 1), [])
}

/**
 * This hook subscribes to the editor's transaction events
 * and forces a re-render when the editor's content changes.
 * @param editor
 */
export function useSubscribeToEditorUpdates(editor: Editor) {
    const forceUpdate = useForceUpdate()

    useEffect(() => {
        let isMounted = true

        editor.on('transaction', () => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    if (isMounted) {
                        forceUpdate()
                    }
                })
            })
        })

        return () => {
            isMounted = false
        }
    }, [editor, forceUpdate])
}

import React from 'react'

import { ScrollArea } from 'ui/components/ScrollArea'

import { AppNavigationTree } from './AppNavigationTree'

type AppNavigationMenuRef = HTMLDivElement

type AppNavigationMenuProps = {}

export const AppNavigationMenu = React.forwardRef<AppNavigationMenuRef, AppNavigationMenuProps>(
    (props, ref) => {
        return (
            <ScrollArea px="m" pb="xl" direction="vertical" ref={ref} {...props}>
                <AppNavigationTree />
            </ScrollArea>
        )
    }
)

import React from 'react'

import styled from '@emotion/styled'

export const FieldTypeLabel = styled('span')`
    text-decoration: underline;
    opacity: 0.6;
    font-weight: bold;
`
export const UnsupportedMessageComponent = (
    <>
        Converting to this field type will <strong>remove all existing data in this field</strong>.
    </>
)

import React, { useMemo } from 'react'

import { LayoutEditorBlock } from 'v2/blocks/types'

import { getStackTheme } from 'app/Branding'
import { useAppContext } from 'app/useAppContext'
import {
    conditionalVisibility,
    multiline,
    options,
    text,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'

import { ConfigureWidgetPlaceholder, Container, Flex, Heading, Text } from 'v2/ui'
import CalloutIcon from 'v2/ui/components/CalloutIcon'
import * as SVGIcons from 'v2/ui/svgs'

const CalloutBlock: LayoutEditorBlock<{}, CalloutBlock> = ({ attrs, context }) => {
    const { style, title, subtitle } = attrs
    const isEditing = context?.editor?.isEditing ?? false

    const { selectedStack } = useAppContext()
    const theme = getStackTheme(selectedStack)

    const styleDetails = useMemo(() => {
        switch (style) {
            case 'error':
                return {
                    svg: SVGIcons.CalloutCrossNoBg,
                    color: 'userInterface.error.600',
                }
            case 'warning':
                return {
                    svg: SVGIcons.CalloutExclamationNoBg,
                    color: 'userInterface.warning.1000',
                }
            case 'success':
                return {
                    svg: SVGIcons.CalloutCheckNoBg,
                    color: 'userInterface.success.500',
                }
            case 'brand':
                return {
                    color: theme.brandColor ?? 'purple.1000',
                    svg: SVGIcons.CalloutCommentNoBg,
                }
            case 'question':
                return {
                    svg: SVGIcons.CalloutQuestionNoBg,
                    color: 'userInterface.neutral.700',
                }
            default:
            case 'info':
                return {
                    svg: SVGIcons.CalloutInformationNoBg,
                    color: 'userInterface.accent.1000',
                }
        }
    }, [style, theme.brandColor])

    // Conditional Visibility
    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    if (passesFilters && !isEditing) return null

    if (!title && !subtitle) {
        if (!isEditing) {
            return null
        }
        return (
            <ConfigureWidgetPlaceholder
                message="Callout"
                subtitle="The Callout widget can be used to draw attention to organization updates,
                changes to the application or any other important information you wish to highlight to users
                 of an application."
                Icon={SVGIcons.Banner}
            />
        )
    }

    return (
        <Container
            mb="1rem"
            p={['container.padding', null, null, 'container.paddingLg']}
            border="solid 0.9px #eaecf0"
            borderRadius="4px"
            borderLeftWidth="6px"
            borderLeftColor={styleDetails.color}
        >
            <Flex flexWrap="nowrap" direction="row" alignItems="flex-start">
                <CalloutIcon mr={5} svg={styleDetails.svg} color={styleDetails.color} />
                <Flex direction="column" alignItems="flex-start">
                    <Heading as="h2" value={title} flexGrow={1} variant="widgetContainerTitle" />
                    <Text variant="widgetContainerDescription">{subtitle}</Text>
                </Flex>
            </Flex>
        </Container>
    )
}

CalloutBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    options({
        field: 'style',
        label: 'Style',
        //without it it won't render
        simple: true,
        options: [
            { label: 'Error', value: 'error' },
            { label: 'Warning', value: 'warning' },
            { label: 'Success', value: 'success' },
            { label: 'Information', value: 'info' },
            { label: 'Question', value: 'question' },
            { label: 'Brand', value: 'brand' },
        ],
    }),
    text({ field: 'title', label: 'Title', simple: true, fullWidth: true }),
    multiline({ field: 'subtitle', label: 'Subtitle', simple: true }),
]

export default CalloutBlock

import React, { useMemo } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { DEFAULT_TRIGGER_ICON } from 'features/workflows/common'
import { ItemIcon } from 'features/workflows/ItemIcon'
import { sortWorkflowItemsByGroup } from 'features/workflows/types'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { theme } from 'ui/styling/Theme.css'

type TriggerTypeDropdownProps = {
    type?: string
    onChange: (trigger: WorkflowSchemaTriggerType) => void
    buttonProps?: Partial<React.ComponentProps<typeof DropdownButton>>
}

export const TriggerTypeDropdown: React.FC<TriggerTypeDropdownProps> = ({
    type,
    onChange,
    buttonProps,
}) => {
    const { data: schema } = useWorkflowSchema()
    const triggerType = schema?.triggers.find((t) => t.id === type)

    const triggers = useMemo(
        () => sortWorkflowItemsByGroup(schema?.triggers ?? []),
        [schema?.triggers]
    )

    return (
        <Dropdown>
            <DropdownButton
                variant="secondary"
                size="l"
                style={{
                    justifyContent: 'flex-start',
                    fontSize: theme.fontSizes.bodyM,
                    fontWeight: theme.fontWeights.bodyRegular as any,
                    paddingLeft: theme.space.m,
                    paddingRight: theme.space.m,
                }}
                {...buttonProps}
            >
                {triggerType ? (
                    <>
                        <ItemIcon
                            icon={triggerType.icon || DEFAULT_TRIGGER_ICON}
                            iconStyle={triggerType.icon_style}
                        />
                        {triggerType.name}
                    </>
                ) : (
                    <>
                        <Box
                            width="4xl"
                            height="4xl"
                            background="surfaceStronger"
                            noShrink
                            rounded="m"
                        />
                        Pick a trigger
                    </>
                )}
            </DropdownButton>
            <DropdownContent align="end">
                {triggers.map((trigger) => (
                    <DropdownItem
                        key={trigger.id}
                        label={trigger.name}
                        multiSelect
                        checked={trigger.id === type}
                        onCheckedChange={(checked) => {
                            if (checked) onChange(trigger)
                        }}
                        closeOnSelect={true}
                    />
                ))}
            </DropdownContent>
        </Dropdown>
    )
}

import { useEffect, useRef } from 'react'

import { EventHandler, subscribeToEvent, unsubscribeToEvent } from './eventObservor'

export const useSubscribeToEvent = ({ event, handler }: EventHandler) => {
    const handlerRef = useRef(handler)

    handlerRef.current = handler

    useEffect(() => {
        function _handler(data: any) {
            handlerRef.current(data)
        }

        subscribeToEvent({ event, handler: _handler })

        return () => unsubscribeToEvent({ event, handler: _handler })
    }, [event])
}

import React, { useContext } from 'react'

import { ViewLayoutContext } from 'v2/blocks/types'

import { ListViewUrlParams } from './hooks/useListViewUrlParams'

export type ListViewContextValue = {
    view: ViewDto
    context?: ViewLayoutContext
    columnConfigs: ListViewColumnConfig[]
    updateView: (patch: Partial<ViewDto>) => void
    updateViewOptions: (patch: Partial<ListViewOptions>) => void
    isDeleteEnabled: boolean
    commitChanges: () => void
    discardChanges: () => void
    hasPendingChanges: boolean
    object: ObjectDto
    allFields: FieldDto[]
    visibleFields: FieldDto[]
    // api names of searchable fields from columns
    // (excl.additional card/board fields such as title, subtitle, footer)
    searchableColumnFields: string[]
    stack: StackDto
    pageRoles: PageRole[]
    updatePageRoles: (roles: PageRole[]) => void
    isEditMode: boolean
    useUrlParams: boolean
    isEmbedded: boolean
    embeddedByField?: FieldDto
    embeddedByFieldValue?: string
    embeddedByRecord?: RecordDto
    title: string
    allowSearch: boolean
    allowCreate: boolean
    allowDownload: boolean
    urlParams?: ListViewUrlParams
    additionalFilters?: Filter[]
    header: ListViewHeader
}
export const ListViewContext = React.createContext<ListViewContextValue | null>(null)

export function useListViewContext(): ListViewContextValue {
    const context = useContext(ListViewContext)

    if (!context) {
        throw new Error('useListViewContext must be used within a ListViewContextProvider')
    }

    return context
}

import { Box } from 'ui/components/Box'
import { Divider as DividerComponent } from 'ui/components/Divider'
import { PopoverClose, PopoverTrigger } from 'ui/components/Popover'
import { makeComponent } from 'ui/helpers/recipes'

import { ContentStyles, DividerStyles, FooterStyles, RootStyles, TitleStyles } from './Popup.css'

export const Root = makeComponent(Box, RootStyles)
export const Title = makeComponent(Box, TitleStyles)
export const Content = makeComponent(Box, ContentStyles)
export const Footer = makeComponent(Box, FooterStyles)
export const Divider = makeComponent(DividerComponent, DividerStyles)
export const Trigger = PopoverTrigger
export const Close = PopoverClose

import React, { useMemo } from 'react'

import { Editor } from '@tiptap/core'

import { matchesSearchTerms } from 'features/Search/helpers'
import { BlockType, BlockTypeCommand, BlockTypes } from 'features/tiptap/BlockTypes'

import { CommandsProvider } from './CommandsProvider'
import { RenderBlockType } from './RenderBlockType'

type BlockTypeCommandOptions = {
    allowedBlockTypes?: BlockType[]
    editor: Editor | null
}
export const useBlockTypeCommandProvider = ({
    allowedBlockTypes,
    editor,
}: BlockTypeCommandOptions): CommandsProvider<BlockTypeCommand> => {
    return useMemo(() => {
        let initialItems: BlockTypeCommand[] = []
        if (editor) {
            initialItems = Object.entries(BlockTypes)
                .filter(
                    ([key, blockType]) =>
                        (allowedBlockTypes?.includes(key) ?? true) &&
                        (blockType.isAvailable?.(editor) ?? true)
                )
                .map(([_key, u]) => u)
        }

        return {
            id: 'block_types',
            title: 'Blocks',
            editor,
            initialItems,
            getItems({ queryTerms }) {
                if (!editor) {
                    return Promise.resolve({ items: [] })
                }

                return Promise.resolve({
                    items: Object.entries(BlockTypes)
                        .filter(
                            ([key, blockType]) =>
                                matchesSearchTerms(blockType.label, queryTerms) &&
                                (allowedBlockTypes?.includes(key) ?? true) &&
                                (blockType.isAvailable?.(editor) ?? true)
                        )
                        .map(([_key, u]) => u),
                })
            },
            renderItem({ item, queryTerms }) {
                return <RenderBlockType item={item} queryTerms={queryTerms} />
            },
            onSelect: ({ editor, range, item }) => {
                queueMicrotask(() => {
                    editor.chain().focus().deleteRange(range).run()
                    item.command(editor)
                })
            },
        } as CommandsProvider<BlockTypeCommand>
    }, [allowedBlockTypes, editor])
}

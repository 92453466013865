import { Extension } from '@tiptap/react'

import { deserializeActivityLocationParameters } from 'features/Activity/utils'

declare module '@tiptap/core' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Commands<ReturnType> {
        positionNavigator: {
            restorePosition: (positionId: string, fieldId?: string) => ReturnType
        }
    }
}

export function createPositionNavigatorExtension() {
    return Extension.create({
        name: 'positionNavigator',
        addCommands() {
            return {
                restorePosition:
                    (positionId: string, fieldId?: string) =>
                    ({ state, commands }) => {
                        let handled = false

                        if (!positionId) return false
                        const { mark: markId, field } = getNodeFromPositionId(positionId)

                        if (!markId) return false
                        if (fieldId && field !== fieldId) return false

                        const activityMarkType = state.schema.marks.activity

                        state.doc.descendants((node, pos) => {
                            if (!node.isText) return

                            for (const mark of node.marks) {
                                const { attrs } = mark

                                if (attrs?.id === markId) {
                                    commands.focus(pos, { scrollIntoView: true })

                                    if (mark.type === activityMarkType) {
                                        commands.openActivityBubble()
                                    }

                                    handled = true

                                    return
                                }
                            }
                        })

                        return handled
                    },
            }
        },
    })
}

function getNodeFromPositionId(positionId: string) {
    // We assume this is an activity location.
    return deserializeActivityLocationParameters(positionId)
}

import React from 'react'

import styled from '@emotion/styled'
import { ViewLayoutContext } from 'v2/blocks/types'

import { DropZone } from 'features/pages/editor/dnd/DropZone'

import stackerTheme from 'v2/ui/theme/styles/default'

import BlankPageEmptyState from './BlankPageEmptyState'

const { colors, space } = stackerTheme()

const StyledDropZone = styled(DropZone)`
    padding: ${space[32]} 0;
    background: transparent;
    opacity: 1;
    transition:
        background 0.3s ease-out,
        opacity 0.1s ease-out;
    border-radius: 5px;

    &.is-over {
        background: ${colors.userInterface.neutral[400]};
        opacity: 0.6;
    }
`

type BlankPageViewEditorDropZoneProps = {
    dropZoneProps: React.PropsWithChildren<{
        payload: { id: string }
    }>
    block: Block
    context: ViewLayoutContext
}

const BlankPageViewEditorDropZone: React.FC<BlankPageViewEditorDropZoneProps> = ({
    dropZoneProps,
    block,
    context,
}) => {
    if (block.type === 'container') {
        return (
            // @ts-ignore
            <StyledDropZone {...dropZoneProps} isOverClassName="is-over">
                {context.editor.isEditing && <BlankPageEmptyState />}
            </StyledDropZone>
        )
    }
    // @ts-ignore
    return <DropZone {...dropZoneProps} />
}

export default BlankPageViewEditorDropZone

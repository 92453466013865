import React, { useState } from 'react'

import { ContextGroup, ContextSchema, ContextSchemaWithPaths } from 'features/expressions/types'
import { SuggestionComponentHandle } from 'features/tiptap/Extensions/extensionHelpers'

import { Container } from 'ui/components/Container'

import { ContextGroupItemsList } from './ContextGroupItemsList'
import { RootGroupsList } from './RootGroupsList'
import { BaseExtensionProps } from './types'

type ContextItemsViewProps = BaseExtensionProps & {
    provideSchema: () => ContextSchema
}

export const ContextItemsMenu = React.forwardRef<SuggestionComponentHandle, ContextItemsViewProps>(
    ({ provideSchema, ...props }, ref) => {
        const [selectedGroup, setSelectedGroup] = useState<ContextGroup | undefined>()
        const schema = provideSchema() as ContextSchemaWithPaths
        let content: React.ReactNode | null = null

        const singleGroupToShow =
            selectedGroup || (schema.length === 1 ? (schema[0] as ContextGroup) : undefined)

        if (singleGroupToShow) {
            content = (
                <ContextGroupItemsList
                    ref={ref}
                    group={singleGroupToShow}
                    onClose={schema.length > 1 ? () => setSelectedGroup(undefined) : undefined}
                    {...props}
                />
            )
        } else {
            content = (
                <RootGroupsList ref={ref} schema={schema} onSelect={setSelectedGroup} {...props} />
            )
        }

        return (
            <Container popover minWidth="300px" maxWidth="100vw" maxHeight="50vh" overflowY="auto">
                {content}
            </Container>
        )
    }
)

import { useCallback, useMemo } from 'react'

import { NavigationApp } from 'features/navigation/types'
import { useCreateEmptyBlankPageModal } from 'features/workspace/AddCustomPageButton/useCreateEmptyBlankPageModal'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useNavigationContext } from './useNavigationContext'

type UseWorkspaceNavigationAppAddNewDropdownStateProps = {
    app: NavigationApp
}

export function useWorkspaceNavigationAppAddNewDropdownState({
    app,
}: UseWorkspaceNavigationAppAddNewDropdownStateProps) {
    const { permissions, executeActionInApp, showCreateViewModal } = useNavigationContext()

    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    const { showManageData } = useSlidingPane()

    const canCreateTable = permissions.canCreateTable && !isMobile
    const createTable = useCallback(() => {
        executeActionInApp(app, () => {
            showManageData({ shouldShowNewTableModal: true })
        })
    }, [executeActionInApp, showManageData, app])

    const { show: handleAddPage } = useCreateEmptyBlankPageModal()

    const canCreateCustomPage = permissions.canCreateCustomPage
    const createCustomPage = useCallback(
        (e: React.MouseEvent) => {
            executeActionInApp(app, () => handleAddPage(e))
        },
        [executeActionInApp, handleAddPage, app]
    )

    const canCreateView = permissions.canCreateView
    const createView = useCallback(
        (display: ListViewDisplay) => {
            showCreateViewModal(app, display)
        },
        [showCreateViewModal, app]
    )

    const showButton = canCreateTable || canCreateCustomPage || canCreateView

    return useMemo(
        () => ({
            canCreateTable,
            createTable,
            showButton,
            canCreateCustomPage,
            createCustomPage,
            canCreateView,
            createView,
        }),
        [
            canCreateTable,
            createTable,
            showButton,
            canCreateCustomPage,
            createCustomPage,
            canCreateView,
            createView,
        ]
    )
}

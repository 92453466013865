import React from 'react'

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { useLayoutEditorWidgetAreaState } from './hooks/useLayoutEditorWidgetAreaState'
import { LayoutEditorDnDWidget } from './LayoutEditorWidget'
import { LayoutEditorWidgetAreaContextProvider } from './LayoutEditorWidgetAreaContext'

type LayoutEditorWidgetAreaProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'id'> & {
    id: string
    isDragging?: boolean
}

export const LayoutEditorWidgetArea: React.FC<LayoutEditorWidgetAreaProps> = React.memo(
    function LayoutEditorWidgetArea({ id, ...props }) {
        return (
            <LayoutEditorWidgetAreaContextProvider id={id}>
                <LayoutEditorWidgetAreaInner id={id} {...props} />
            </LayoutEditorWidgetAreaContextProvider>
        )
    }
)

const LayoutEditorWidgetAreaInner: React.FC<LayoutEditorWidgetAreaProps> = ({ id, ...props }) => {
    const { children, ref, sortableContextId, isDragging, isEditing } =
        useLayoutEditorWidgetAreaState({
            id,
        })

    // If we are dragging, we don't want to render nested widget areas, to improve performance.
    // Also, without this check we could drag a widget into its own child nested widget area, which would result in a memory leak.
    if (isDragging) return null

    return (
        <Box ref={ref} {...props}>
            <SortableContext
                items={children}
                id={sortableContextId}
                strategy={verticalListSortingStrategy}
            >
                {!!children.length &&
                    children.map((widget) => (
                        <LayoutEditorDnDWidget key={widget.id} widget={widget} />
                    ))}

                {!children.length && isEditing && <Placeholder />}
            </SortableContext>
        </Box>
    )
}

type PlaceholderProps = {}

const Placeholder: React.FC<PlaceholderProps> = () => {
    return (
        <Box
            width="full"
            py="3xl"
            px="m"
            flex
            center
            justifyContent="center"
            rounded="xl"
            background="gray100"
            textAlign="center"
        >
            <Body size="s" weight="bold" color="gray400">
                Replace with one or more widgets
            </Body>
        </Box>
    )
}

import React, { useMemo } from 'react'

import { CheckboxAttributeDisplay } from 'features/views/attributes/CheckboxAttributeDisplay'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

type CheckboxCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const CheckboxCell: TableViewCellComponent<boolean, CheckboxCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const {
        isPrimary,
        rawOptions: { checkboxDisplay },
        field,
    } = column

    const valueDisplay = useMemo(() => {
        switch (checkboxDisplay) {
            case 'default':
                return isPrimary ? 'big' : 'default'
            case 'big':
                return 'bigFilled'
            case 'filled':
                return isPrimary ? 'bigFilled' : 'filled'
        }
    }, [checkboxDisplay, isPrimary])

    const isEmpty = !isLoading && typeof value === 'undefined'

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <CheckboxAttributeDisplay
                value={value}
                valueDisplay={valueDisplay}
                field={field}
                isLoading={isLoading}
            />
        </BaseCell>
    )
}

import React from 'react'

import { RichTextAttributeDisplay } from 'features/views/attributes/RichTextAttributeDisplay'
import { RichTextValue } from 'features/views/attributes/types'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_LINES = 2
const MAX_OVERFLOW_LENGTH = 300

type RichTextCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const RichTextCell: TableViewCellComponent<RichTextValue, RichTextCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { isPrimary, field } = column

    const isEmpty = !isLoading && !value?.plainTextContent

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <RichTextAttributeDisplay
                value={value}
                whiteSpace="normal"
                weight={isPrimary ? 'bold' : 'regular'}
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                alwaysShowOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                field={field}
                maxLines={MAX_LINES}
                maxOverflowLength={MAX_OVERFLOW_LENGTH}
                isLoading={isLoading}
            />
        </BaseCell>
    )
}

import React from 'react'

import { useBoardViewLoaderState } from './hooks/useBoardViewLoaderState'
import { BoardViewColumnRenderer } from './BoardViewColumnRenderer'

type BoardViewLoaderProps = {}

export const BoardViewLoader: React.FC<BoardViewLoaderProps> = () => {
    const { columns, placeholderRows } = useBoardViewLoaderState()

    return (
        <>
            {columns?.map((c) => (
                <BoardViewColumnRenderer
                    key={c.id}
                    records={placeholderRows}
                    column={c}
                    isLoading
                />
            ))}
        </>
    )
}

import React, { FC, useMemo, useState } from 'react'

import { useCreateAction, useUpdateAction } from 'data/hooks/actions'

import { ActionsContext } from './actionHooks'
import { useActionsFromObjectId } from './helpers'
import type { ActionsContext as ActionsContextType, ModalType } from './types'

type Props = {
    object: ObjectDto
}

export const ActionsContextProvider: FC<Props> = ({ object, children }) => {
    const { data: actions, status } = useActionsFromObjectId(object?._sid)
    const { mutateAsync: doUpdateAction } = useUpdateAction()
    const { mutateAsync: createAction } = useCreateAction()
    const [modalStack, setModalStack] = useState<
        { name: ModalType; data?: { stepId?: string; fieldId?: string } }[]
    >([])

    const value = useMemo(() => {
        const context: ActionsContextType = {
            objectId: object?._sid,
            actions,
            status,
            updateAction,
            modalStack,
            activeModal: modalStack[modalStack.length - 1]?.name,
            activeModalData: modalStack[modalStack.length - 1]?.data,
            addModalToStack: (name, data) => setModalStack((list) => [...list, { name, data }]),
            removeModalFromStack: () => setModalStack((list) => [...list.slice(0, -1)]),
            openAddStep() {
                context.addModalToStack('AddAStep')
            },
            editStep(stepId) {
                context.addModalToStack('editStep', { stepId })
            },
            editField(fieldId) {
                context.addModalToStack('EditField', { fieldId })
            },
        }
        return context

        function updateAction(actionId?: string, patch: Partial<ActionDto> = {}) {
            if (actionId) {
                return doUpdateAction({
                    id: actionId,
                    patch,
                })
            }

            return createAction(patch)
        }
    }, [object, actions, status, modalStack, createAction, doUpdateAction])

    return <ActionsContext.Provider value={value}>{children}</ActionsContext.Provider>
}

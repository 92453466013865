import React from 'react'

import { Box } from 'ui/components/Box'

type PillValueProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const PillValue: React.FC<PillValueProps> = (props) => {
    return (
        <Box
            px="m"
            py="xs"
            rounded="xs"
            background="surfaceStrongest"
            display="inline"
            {...props}
        />
    )
}

import React from 'react'

import { isEqual } from 'lodash'

import { FieldIcon } from 'features/admin/fields/icons/FieldIcon'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { useTableViewHeaderState } from './hooks/useTableViewHeaderState'
import { TableViewHeaderSort } from './Sort/TableViewHeaderSort'
import * as Parts from './TableView.parts'
import { TableViewCellContent } from './TableViewCellContent'
import { TableViewColumn } from './types'

import { TableViewResizeHandleStyles } from './TableView.css'

type TableViewHeaderProps = React.ComponentPropsWithoutRef<typeof Parts.Header> & {
    column: TableViewColumn
    showIcon?: boolean
}

export const TableViewHeader: React.FC<TableViewHeaderProps> = React.memo(function TableViewHeader({
    column,
    showIcon,
    ...props
}) {
    const { title, isPinned, isPrimary, supportsSort, icon, isResizable } = column

    const { headerRef, onResizeHandleMouseDown, onResizeHandleTouchStart, isResizing } =
        useTableViewHeaderState({ column })

    return (
        <Parts.Header
            ref={headerRef}
            color={isPrimary ? 'text' : 'textWeak'}
            isPinned={isPinned}
            {...props}
        >
            <TableViewCellContent column={column} gap="xs">
                {showIcon && icon && (
                    <FieldIcon
                        size={{ mobile: 'xs', tablet: 's' }}
                        color={isPrimary ? 'text' : 'icon'}
                        noShrink
                        value={icon}
                        mr={{ mobile: 0, tablet: '3xs' }}
                    />
                )}
                <Box trim>
                    <Body
                        size={{ mobile: 's', tablet: 'm' }}
                        weight={isPrimary ? 'bold' : 'medium'}
                        style={{ color: 'inherit' }}
                    >
                        {title}
                    </Body>
                </Box>
                {supportsSort && <TableViewHeaderSort column={column} />}
                {isResizable && (
                    <TableViewColumnResizeHandle
                        column={column}
                        position="absolute"
                        right={0}
                        top={0}
                        onMouseDown={onResizeHandleMouseDown}
                        onTouchStart={onResizeHandleTouchStart}
                        isResizing={isResizing}
                    />
                )}
            </TableViewCellContent>
        </Parts.Header>
    )
}, isEqual)

type TableViewColumnResizeHandleProps = React.ComponentPropsWithoutRef<typeof Box> & {
    isResizing: boolean
}

const TableViewColumnResizeHandle: React.FC<TableViewColumnResizeHandleProps> = React.memo(
    function TableViewColumnResizeHandle({ isResizing, ...props }) {
        return (
            <Box
                height="full"
                aria-hidden="true"
                aria-label="Drag to resize column"
                className={TableViewResizeHandleStyles.styleFunction({ isResizing })}
                {...props}
            >
                <Box height="full" width="2xs" background="gray900" />
            </Box>
        )
    }
)

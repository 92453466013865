import React, { useEffect, useRef, useState } from 'react'

import { ActivityType } from 'data/hooks/activityTypes'
import { TaskPayload } from 'data/hooks/tasks/types'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Icon } from 'ui/components/Icon'
import { DisplayOnContainerHover } from 'ui/styles/hoverUtils.css'
import { theme } from 'ui/styling/Theme.css'

import { ActivityFeedPopover } from './ActivityFeedPopover'
import { useActivityFeedConfig } from './useActivityFeedConfig'

type CommentCountProps = {
    alwaysShow: boolean
    setIsActive: (isActive: boolean) => void
    record?: RecordDto
    task?: TaskPayload
}
export function CommentCount({ record, alwaysShow, setIsActive, task }: CommentCountProps) {
    const [isOpen, setIsOpen] = useState<boolean>()

    const onClose = () => {
        setIsOpen(false)
    }

    const targetRef = useRef<HTMLDivElement>(null)

    const handleClick = (e: React.MouseEvent) => {
        setIsOpen(!isOpen)
        e.preventDefault()
        e.stopPropagation()
    }

    const { commentCount = 0, contextKey, target } = useActivityFeedConfig({ record, task })

    useEffect(() => {
        setIsActive(isOpen || commentCount > 0)
    }, [isOpen, commentCount, setIsActive])

    return (
        <>
            <CommentCountDisplay
                ml="xs"
                count={commentCount}
                onClick={handleClick}
                ref={targetRef}
                alwaysShow={isOpen || alwaysShow}
            />
            <ActivityFeedPopover
                open={isOpen}
                targetElement={targetRef.current ?? undefined}
                onClose={onClose}
                placement="bottom-start"
                contextKey={contextKey}
                target={target}
                params={{ types: [ActivityType.Comment] }}
                activityFeedProps={{ showLocationPreview: true }}
            />
        </>
    )
}

export type CommentCountDisplayProps = BoxProps & {
    count: number
    alwaysShow?: boolean
    iconProps?: Partial<React.ComponentPropsWithoutRef<typeof Icon>>
    textProps?: Partial<React.ComponentPropsWithoutRef<typeof Box>>
}
export const CommentCountDisplay = React.forwardRef<HTMLDivElement, CommentCountDisplayProps>(
    function CommentCountDisplay(
        { count, onClick, alwaysShow, iconProps, textProps, ...props }: CommentCountDisplayProps,
        ref
    ) {
        const tooltipLabel = formatTooltipLabel(count)
        const formattedCount = formatCountLabel(count)

        return (
            <Tooltip label={tooltipLabel} placement="bottom">
                <Box
                    flex
                    center
                    position="relative"
                    className={!count && !alwaysShow ? DisplayOnContainerHover : undefined}
                    role={onClick ? 'button' : undefined}
                    onClick={onClick}
                    ref={ref}
                    {...props}
                >
                    <>
                        <Icon
                            name="MessageSquare"
                            color="border"
                            opacity={count ? 1 : 0.8}
                            size="l"
                            style={{
                                fill: count ? theme.color.surfaceStrongest : 'transparent',
                            }}
                            {...iconProps}
                        />
                        <Box
                            position="absolute"
                            color="textWeaker"
                            fontSize="bodyXs"
                            style={{
                                right: 0,
                                left: 0,
                                top: '3px',
                                whiteSpace: 'nowrap',
                                lineHeight: 1,
                            }}
                            fontWeight="bodyBold"
                            textAlign="center"
                            {...textProps}
                        >
                            {formattedCount}
                        </Box>
                    </>
                </Box>
            </Tooltip>
        )
    }
)

function formatTooltipLabel(count: number) {
    if (!count) return 'No comments'
    if (count === 1) return '1 comment'

    return `${count} comments`
}

function formatCountLabel(count: number) {
    if (!count) return ''
    if (count > 99) return '99+'

    return count.toString()
}

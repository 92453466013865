import { getDetailRequiredFieldApiNames } from 'v2/views/Detail/detailRequiredFields'

import { RichTextValue } from 'features/views/attributes/types'

export function isTurboView(display: string): boolean {
    return display === 'boardV2' || display === 'tableV2' || display === 'cardV2'
}

export function getSupportedThumbnailFields(fields: FieldDto[]): FieldDto[] {
    return fields.filter((field) => field.type === 'multi_file')
}

export function extractSrcFromCoverImageValue(record?: RecordDto, field?: FieldDto) {
    if (!record || !field) return undefined

    const value = record[field.api_name]

    if (['date', 'datetime', 'lookup', 'checkbox'].includes(field.type)) {
        return undefined
    }

    if (typeof value === 'string') {
        return value
    }

    // Extract the URL from the attachment value.
    if (field.type === 'multi_file') {
        if (Array.isArray(value)) {
            return value[0]?.url
        } else if (value && typeof value === 'object') {
            return (value as any).url
        }
    }

    if (field.type === 'document') {
        return (value as RichTextValue).plainTextContent
    }

    return undefined
}

export function getCreateViewForViewSid(viewSid: string, allViews: ViewDto[]): ViewDto | undefined {
    const currentView = allViews.find((v) => v._sid === viewSid)
    if (!currentView) return undefined

    return allViews.find((v) => v.object_id === currentView.object_id && v.type === 'create')
}

export function extractFieldApiNamesFromCreateView(
    listViewSid: string,
    allActions: ActionDto[],
    allViews: ViewDto[],
    allFields: FieldDto[],
    object: ObjectDto
): string[] {
    const createView = getCreateViewForViewSid(listViewSid, allViews)
    if (!createView) return []

    // This view is using a layout from another view (i.e. the detail view).
    const useLayoutFrom = createView.options?.use_layout_from
    if (useLayoutFrom) {
        const layoutView = allViews.find((v) => v._sid === useLayoutFrom)
        if (layoutView) {
            return (
                getDetailRequiredFieldApiNames({
                    actions: allActions,
                    fields: allFields,
                    obj: object,
                    view: createView,
                }) ?? []
            )
        }

        return []
    }

    return (
        getDetailRequiredFieldApiNames({
            actions: allActions,
            fields: allFields,
            obj: object,
            view: createView,
        }) ?? []
    )
}

import React from 'react'

import { Tag } from 'ui/components/Tag'
import { Tooltip } from 'ui/components/Tooltip'

import { useListHeaderDefaultObjectLabelState } from './hooks/useListHeaderDefaultObjectLabelState'

import { ListHeaderDefaultShowOnHoverStyle } from './ListHeaderDefault.css'

const MAX_LABEL_LENGTH = 25

type ListHeaderDefaultObjectLabelProps = {}

export const ListHeaderDefaultObjectLabel: React.FC<ListHeaderDefaultObjectLabelProps> = () => {
    const { objectLabel, onClick, shouldShow } = useListHeaderDefaultObjectLabelState()

    if (!shouldShow) return null

    return (
        <Tooltip asChild content={objectLabel} zIndex={200}>
            <Tag
                type="solid"
                shape="rectangle"
                shade="light"
                size="xs"
                color="Neutral"
                noShrink
                startIcon={{ name: 'Link2' }}
                role="button"
                onClick={onClick}
                maxLength={MAX_LABEL_LENGTH}
                className={ListHeaderDefaultShowOnHoverStyle}
            >
                {objectLabel}
            </Tag>
        </Tooltip>
    )
}

import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

import { useIsMobile } from 'v2/ui/utils/useIsMobile'

export function useActionContextMenuState() {
    const isDisabled = useIsMobile()

    const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
    const [isOpen, setIsOpen] = useState(false)

    const openAt = useCallback(
        (x: number, y: number) => {
            if (isDisabled) return

            setCurrentPos({
                x,
                y,
            })
            setIsOpen(true)
        },
        [isDisabled]
    )
    const close = useCallback(() => {
        setIsOpen(false)
    }, [])

    useLayoutEffect(() => {
        if (isDisabled) return

        const handleResize = () => {
            close()
        }

        // Close the context menu when the window is resized.
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [close, isDisabled])

    return useMemo(
        () => ({
            isOpen,
            setIsOpen,
            openAt,
            close,
            posX: currentPos.x,
            posY: currentPos.y,
            isDisabled,
        }),
        [close, isOpen, openAt, currentPos.x, currentPos.y, isDisabled]
    )
}

import React, { memo } from 'react'

import get from 'lodash/get'
import PropTypes from 'prop-types'

import { formatDate, getDateTimeFormats } from 'utils/date/dateUtils'

import { DatePicker, Editable } from 'v2/ui'
import { modes } from 'v2/ui/utils/attributeSettings'

import { AttributeValueWrapper } from './AttributeValueWrapper'
import { DisplayText } from './DisplayText'

const DateAttribute = memo(
    ({
        mode,
        showTime,
        onChange,
        ignoreOnChangeFormatting = false,
        placeholder,
        displayType,
        customInput,
        onBlur,
        onOpen,
        formStyle = {},
        hideBorder = false,
        renderInline,
        ...props
    }) => {
        const { dateValue, locale, isUTC } = formatDate(
            props.children,
            get(props, 'field.options.timezone'),
            showTime
        )

        // Formats for both the datapicker and the display (using Moment.js)
        // They have slightly different formats, so we use 2 variables for the time and date
        // Default to local time formats / no time format if only showing a date
        const { datepickerTimeFormat, displayFormat, datepickerFormat } = getDateTimeFormats(
            props.field,
            showTime
        )

        const form = (
            <div style={{ display: 'inline-block', alignSelf: 'flex-start', ...formStyle }}>
                <DatePicker
                    selectsRange={props.selectsRange}
                    showTimeSelect={showTime}
                    dateFormat={datepickerFormat}
                    timeFormat={datepickerTimeFormat}
                    autoFocus={mode === modes.editable}
                    selected={dateValue || null}
                    onChange={(item) => {
                        if (ignoreOnChangeFormatting) {
                            onChange(item)
                        } else {
                            if (showTime || typeof item === 'string') {
                                onChange(item)
                            } else {
                                if (Array.isArray(item)) {
                                    onChange(item.map((i) => i.format('YYYY-MM-DD')))
                                    return
                                }
                                onChange(item ? item.format('YYYY-MM-DD') : item)
                            }
                        }
                    }}
                    locale={locale}
                    isUTC={isUTC}
                    placeholder={placeholder}
                    customInput={customInput}
                    onCalendarClose={onBlur}
                    hideBorder={hideBorder}
                    onCalendarOpen={onOpen}
                    inline={renderInline}
                />
            </div>
        )

        const display = (
            <AttributeValueWrapper displayType={displayType} isEmpty={!props.children}>
                <DisplayText>
                    {(props.children && dateValue !== null && dateValue.format(displayFormat)) ||
                        '-'}
                </DisplayText>
            </AttributeValueWrapper>
        )

        if (mode === modes.editable) {
            return (
                <Editable
                    input={({ end }) =>
                        React.cloneElement(form, {
                            onCalendarClose: end,
                            shouldCloseOnSelect: true,
                        })
                    }
                    display={() => display}
                    onChange={onChange}
                />
            )
        }
        if (mode === modes.editing) {
            return form
        }

        return display
    }
)

DateAttribute.propTypes = {
    /** handles saving the changed value */
    onChange: PropTypes.func,
}
export default DateAttribute

import React from 'react'

import styled from '@emotion/styled'

import { DisplayTypes } from './attributeDisplayOptions'

const PillWrapper = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1px 8px;
    margin: 1px 2px 1px 0px;
    max-width: 100%;
    align-self: flex-start;
    width: fit-content;
`

type Props = React.PropsWithChildren<{
    displayType: DisplayTypes
    isEmpty?: any
}>
export function AttributeValueWrapper({ displayType, children, isEmpty }: Props) {
    let Wrapper: React.ComponentType | null = null
    switch (displayType) {
        case 'pill':
            Wrapper = !isEmpty ? PillWrapper : null
            break
        default:
            Wrapper = null
    }
    if (!Wrapper) {
        return <>{children}</>
    }

    return <Wrapper>{children}</Wrapper>
}

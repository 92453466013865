import React from 'react'

import { isEqual } from 'lodash'

import { ActionMenuEditCheckbox } from './ActionMenuEditCheckbox'
import { ActionMenuEditDate } from './ActionMenuEditDate'
import { ActionMenuEditDropdown } from './ActionMenuEditDropdown'
import { ActionMenuEditPercentage } from './ActionMenuEditPercentage'
import { ActionMenuEditRecordLinks } from './ActionMenuEditRecordLinks'
import { ActionMenuEditComponent } from './types'

type ActionMenuEditFieldProps = {
    label: string
}

export const ActionMenuEditField: ActionMenuEditComponent<any, ActionMenuEditFieldProps> =
    React.memo(function ActionMenuEditField(props) {
        switch (props.field.type) {
            case 'checkbox':
                return <ActionMenuEditCheckbox {...props} />
            case 'dropdown':
            case 'multi_select':
                return <ActionMenuEditDropdown {...props} />
            case 'percentage':
                return <ActionMenuEditPercentage {...props} />
            case 'date':
            case 'datetime':
                return <ActionMenuEditDate {...props} />
            case 'lookup':
            case 'multi_lookup':
                return <ActionMenuEditRecordLinks {...props} />
            default:
                return null
        }
    }, isEqual)

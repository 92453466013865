import React, { forwardRef, useMemo } from 'react'

import type { SwitchProps as RadixToggleProps } from '@radix-ui/react-switch'

import { extractStandardProps, StandardComponentProps } from 'ui/helpers/styles'
import { generateUniqueId } from 'ui/helpers/utilities'

import * as Parts from './Toggle.parts'

import { ToggleVariants } from './Toggle.css'

type ToggleRef = HTMLInputElement

type ToggleProps = Omit<RadixToggleProps, 'asChild'> &
    StandardComponentProps &
    ToggleVariants & {
        isError?: boolean
        helperText?: string
        infoText?: string
    }

export const Toggle = forwardRef<ToggleRef, ToggleProps>(
    ({ id, className, isError, helperText, infoText, ...props }, ref) => {
        // this is so the id property can be optional, but still use the
        // label htmlFor property to link the label to the input.
        const effectiveId = useMemo(() => id || generateUniqueId(), [id])
        const [standardProps, rootProps] = extractStandardProps(props)
        return (
            <Parts.Container ref={ref} className={className} {...standardProps}>
                <Parts.Root
                    id={effectiveId}
                    aria-invalid={isError || undefined}
                    aria-label={typeof props.children === 'string' ? props.children : undefined}
                    aria-disabled={rootProps.disabled || undefined}
                    {...rootProps}
                >
                    <Parts.Thumb aria-disabled={rootProps.disabled || undefined} />
                </Parts.Root>
                <Parts.Label
                    htmlFor={effectiveId}
                    disabled={rootProps.disabled}
                    label={props.children}
                    required={rootProps.required}
                    infoText={infoText}
                    helperText={helperText}
                    isError={isError}
                />
            </Parts.Container>
        )
    }
)

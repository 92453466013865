import { useCallback } from 'react'

import { getRecordActionDisplay } from 'features/views/ListView/Actions/utils'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { isSystemAction } from './useSystemActions'

const MAX_VISIBLE_ACTIONS = 2

type UseRecordActionsStateOptions = {
    record: RecordDto
    actionButtons?: ActionDto[]
    onClick?: (e: React.MouseEvent) => void
}

export function useRecordActionsState(options: UseRecordActionsStateOptions) {
    const { onClick, actionButtons = [] } = options

    const { view } = useListViewContext()

    let visibleActions: ActionDto[] = []
    let menuActions: ActionDto[] = []

    const actionDisplay = getRecordActionDisplay(view)
    if (actionDisplay === 'buttons') {
        for (const action of actionButtons) {
            if (!isSystemAction(action._sid) && visibleActions.length < MAX_VISIBLE_ACTIONS) {
                visibleActions.push(action)
                continue
            }

            menuActions.push(action)
        }
    } else {
        menuActions = actionButtons
    }

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation()
            e.preventDefault()

            onClick?.(e)
        },
        [onClick]
    )

    return useDeepEqualsMemoValue({
        visibleActions,
        menuActions,
        handleClick,
    })
}

import React from 'react'

import classNames from 'classnames'

import { Popup, PopupContent, PopupTitle, PopupTrigger } from 'ui/components/Popup'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useSystemNavigationPopupItemState } from './hooks/useSystemNavigationPopupItemState'
import { SystemNavigationItem } from './SystemNavigationItem'

import { SystemNavigationPopupStyle } from './Navigation.css'

type SystemNavigationPopupItemProps = React.ComponentPropsWithoutRef<
    typeof SystemNavigationItem
> & {
    contentProps?: React.ComponentPropsWithoutRef<typeof PopupContent>
}

export const SystemNavigationPopupItem: React.FC<SystemNavigationPopupItemProps> = ({
    children,
    contentProps,
    ...props
}) => {
    const { isOpen, setIsOpen, onInteractOutside, isFirstRender } =
        useSystemNavigationPopupItemState()

    const sideOffset = useResponsiveValue({
        mobile: 0,
        tablet: 16,
    })
    const side = useResponsiveValue({
        mobile: 'top' as const,
        tablet: 'right' as const,
    })

    return (
        <Popup open={isOpen} onOpenChange={setIsOpen}>
            <PopupTrigger asChild>
                <SystemNavigationItem {...props} />
            </PopupTrigger>
            <PopupContent
                sideOffset={sideOffset}
                align="start"
                side={side}
                onInteractOutside={onInteractOutside}
                {...contentProps}
                style={{
                    zIndex: 200,
                    ...contentProps?.style,
                }}
                className={classNames(contentProps?.className, SystemNavigationPopupStyle)}
            >
                <PopupTitle>{props.label}</PopupTitle>
                {isOpen && !isFirstRender && children}
            </PopupContent>
        </Popup>
    )
}

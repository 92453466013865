import React from 'react'

import { Box } from 'ui/components/Box'

import { useBoardViewWrapperState } from './hooks/useBoardViewWrapperState'

type BoardViewWrapperProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const BoardViewWrapper: React.FC<BoardViewWrapperProps> = React.memo(
    function BoardViewWrapper({ children, ...props }) {
        const { style } = useBoardViewWrapperState()

        return (
            <Box
                width="full"
                style={style}
                overflow="hidden"
                flex
                flexDirection="column"
                {...props}
            >
                {children}
            </Box>
        )
    }
)

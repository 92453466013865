import React, { useMemo } from 'react'

import { InlineFilter } from 'features/records/components/RecordFilters/inlineFilters/InlineFilter'
import { useInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/useInlineFilter'
import { getInlineFilterValue } from 'features/records/components/RecordFilters/inlineFilters/valueUtils'

import type { InlineFilterComponent } from './types'

export const PercentageInlineFilter: InlineFilterComponent<{}> = ({ field, columns }) => {
    const { filter, ...inlineFilterProps } = useInlineFilter({ field })

    const valueLabels = useMemo(() => {
        const value = getInlineFilterValue(filter)
        if (!value) {
            return []
        }

        return [`${value}%`]
    }, [filter])

    return (
        <InlineFilter
            field={field}
            columns={columns}
            closeOnSelect
            valueLabels={valueLabels}
            {...inlineFilterProps}
        />
    )
}

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeTabsVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            border: s.color.gray200,
            borderActive: s.color.theme600,
            textDisabled: s.color.textDisabled,
            textUnderlined: s.color.text,
            textUnderlinedActive: s.color.text,
            textCard: s.color.textWeak,
            textCardActive: s.color.text,
            surface: s.color.surface,
            surfaceHover: s.color.gray100,
            icon: s.color.icon,
            iconDisabled: s.color.iconDisabled,
        },
        size: {
            underlined: pxToRem(44),
            card: pxToRem(40),
            actionDivider: pxToRem(24),
            inner: pxToRem(28),
        },
        borderWidth: {
            default: s.borderWidths[1],
            active: s.borderWidths[2],
        },
        borderStyle: 'solid',
        radius: {
            card: s.radius.xl,
            inner: s.radius.m,
        },
        padding: {
            vertical: s.space.s,
            horizontal: s.space.m,
        },
        gap: {
            icon: s.space.s,
            content: s.space.s,
            action: s.space.m,
        },
        shadows: {
            focus: s.shadows.focus,
        },
    }
}

import React from 'react'

import { Editor } from '@tiptap/core'

import * as Parts from 'ui/components/Textarea/Textarea.parts'

type TextareaToolbarPanelProps = React.ComponentPropsWithoutRef<typeof Parts.ToolbarPanel> & {
    editor: Editor
}

export const TextareaToolbarPanel: React.FC<TextareaToolbarPanelProps> = (props) => {
    return <Parts.ToolbarPanel {...props} />
}

import { useCallback, useMemo } from 'react'

import { getCurrentStackId } from 'app/GlobalStaticState'
import { notifyEvent, ObservableEvents } from 'data/eventObservor/eventObservor'
import { fetchWithAuth } from 'data/utils/utils'

import { invalidateRecords } from './records/recordOperations'
import { updateRecordQuery } from './records/updateRecordQuery'
import { useCreateItem, useDeleteItem, useQuery, useUpdateItem } from './_helpers'
import { StepTypes } from './actionTypes'
import { useFields } from './fields'

/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'useActions'
const ENDPOINT = 'actions/'

export function isStepVisible(step) {
    switch (step.type) {
        case StepTypes.UpdateRecord:
            return Boolean(step.fields?.find((field) => field.promptUser))
        case StepTypes.AutomationTrigger:
            return false
        default:
            return false
    }
}

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useActions(options = {}) {
    const stackId = getCurrentStackId()
    return useQuery([LIST_NAME, stackId], ENDPOINT, options)
}

export function useCreateAction() {
    const stackId = getCurrentStackId()
    return useCreateItem([LIST_NAME, stackId], ENDPOINT)
}
export function useUpdateAction() {
    const stackId = getCurrentStackId()
    return useUpdateItem([LIST_NAME, stackId], ENDPOINT)
}
export function useDeleteAction() {
    const stackId = getCurrentStackId()
    return useDeleteItem([LIST_NAME, stackId], ENDPOINT)
}

export function useExecuteAction(objectId) {
    const { data: fields } = useFields({ objectId })
    const linksToOtherObjects = useMemo(
        () =>
            (fields ?? []).filter(
                (field) =>
                    field.object_id === objectId &&
                    (field.type === 'lookup' || field.type === 'multi_lookup')
            ),
        [fields, objectId]
    )

    return useCallback(
        (requestData) => {
            return fetchWithAuth('execute/', {
                method: 'POST',
                body: JSON.stringify(requestData),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.status >= 400) {
                        return Promise.reject(response)
                    }

                    return response.json()
                })
                .then((data) => {
                    // update redux cache with an update records
                    if (data.records) {
                        updateRecordQuery(data.records, true, requestData.include_fields, undefined)

                        data.records.forEach((record) =>
                            notifyEvent({
                                event: ObservableEvents.RECORD_UPDATED,
                                data: { recordSid: record['_sid'] },
                            })
                        )

                        const objectIdsToInvalidate = [
                            ...linksToOtherObjects
                                .map((field) => field.link_target_object_id)
                                .filter((target) => !!target)
                                .reduce((set, target) => set.add(target), new Set()),
                        ]
                        for (const idToInvalidate of objectIdsToInvalidate) {
                            invalidateRecords(idToInvalidate)
                        }
                    }
                    return data
                })
        },
        [linksToOtherObjects]
    )
}

import React, { forwardRef } from 'react'

import { Field } from './Field'

type SelectorLabelRef = React.ElementRef<typeof Field>

type SelectorLabelProps = React.ComponentPropsWithoutRef<typeof Field> & {}

export const SelectorLabel = forwardRef<SelectorLabelRef, SelectorLabelProps>((props, ref) => {
    return <Field ref={ref} {...props} />
})

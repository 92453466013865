import React from 'react'

import classNames from 'classnames'

import { RecordActions } from 'features/views/ListView/Actions/RecordActions'
import { TableViewOnRowHoverStyle } from 'features/views/ListView/TableView/TableView.css'
import * as Parts from 'features/views/ListView/TableView/TableView.parts'

import { Box } from 'ui/components/Box'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

import { useTableViewRecordActionsState } from './hooks/useTableViewRecordActionsState'

type TableViewRecordActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    actionButtons?: ActionDto[]
    includeFields: string[]
    rowTop: number
    rowHeight: number
}

export const TableViewRecordActions: React.FC<TableViewRecordActionsProps> = React.memo(
    function TableViewRecordActions({
        rowTop,
        rowHeight,
        style,
        className,
        actionButtons,
        includeFields,
        ...props
    }) {
        const { isOpen, setIsOpen, record, isPinned } = useTableViewRecordActionsState()

        if (!record && isPinned) return null
        if (!record || (!actionButtons?.length && !isPinned)) return <Parts.Cell />

        return (
            <Parts.Cell
                position={isPinned ? 'absolute' : undefined}
                flex
                center
                right={0}
                style={{
                    top: isPinned ? `${rowTop}px` : undefined,
                    height: isPinned ? `${rowHeight}px` : undefined,
                    background: isPinned
                        ? 'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0px, #FFF 20px)'
                        : undefined,
                    zIndex: 1,
                    opacity: isOpen ? 1 : undefined,
                    minWidth: 'max-content',
                    paddingLeft: isPinned ? theme.space.xl : undefined,
                    paddingRight: isPinned ? theme.space.m : undefined,
                    border: isPinned ? 'none' : undefined,
                    ...style,
                }}
                className={classNames(className, isPinned ? TableViewOnRowHoverStyle : undefined)}
                {...props}
            >
                <RecordActions
                    actionButtons={actionButtons}
                    includeFields={includeFields}
                    onOpenChange={setIsOpen}
                    onCloseAutoFocus={stopPropagation}
                    record={record}
                    minWidth={0}
                    justifyContent="flex-end"
                    grow
                />
            </Parts.Cell>
        )
    }
)

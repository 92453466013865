import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import { getStackTheme } from 'app/Branding'
import { useAppContext } from 'app/useAppContext'
import { RenderRowAttributeRenderProps } from 'features/pages/blocks/settings/attributes/RenderRowAttribute'

import stackerTheme from 'v2/ui/theme/styles/default'

import QuickLinksBlockEditorItem from './QuickLinksBlockEditorItem'
import { sortQuickLinksBlocks } from './utils'

const { space, colors } = stackerTheme()

const Wrapper = styled.form<{ paddingTop: string }>`
    padding-top: ${({ paddingTop }) => paddingTop};
`

const StyledQuickLinksBlockEditorItem = styled(QuickLinksBlockEditorItem)`
    // Adding a stylish border between items.
    & + & {
        margin-top: ${space[5]};
        border-top: 1px solid ${colors.neutral[400]};
        padding-top: ${space[5]};
    }
`

type QuickLinksBlockEditorProps = RenderRowAttributeRenderProps<
    QuickLinksBlock['config']['attributes'],
    'items'
> & {}

const QuickLinksBlockEditor: React.FC<QuickLinksBlockEditorProps> = ({
    value,
    onChange,
    context,
    ...props
}) => {
    const { selectedStack } = useAppContext()
    const theme = useMemo(() => getStackTheme(selectedStack), [selectedStack])

    const orderedItems = useMemo(() => sortQuickLinksBlocks(value), [value])

    const onUpdateItem =
        (idx: number) =>
        (newItem: QuickLinksBlockItem): void => {
            const newItems = orderedItems.slice()
            newItems[idx] = newItem

            onChange(newItems)
        }

    return (
        <Wrapper paddingTop={space[3]}>
            {orderedItems.map((item, idx) => (
                <StyledQuickLinksBlockEditorItem
                    key={item.order}
                    {...props}
                    index={idx}
                    onChange={onUpdateItem(idx)}
                    value={item}
                    context={context}
                    fullHeight={false}
                    noLabel={false}
                    brandColor={theme.brandColor}
                />
            ))}
        </Wrapper>
    )
}

export default QuickLinksBlockEditor

import React from 'react'

import { Icon } from 'ui/components/Icon'
import { IconName } from 'ui/components/Icon/Icon'

type ExecutionHistoryStatusIconProps = Omit<React.ComponentPropsWithoutRef<typeof Icon>, 'name'> & {
    status: WorkflowExecutionStatus
}

export const ExecutionHistoryStatusIcon: React.FC<ExecutionHistoryStatusIconProps> = ({
    status,
    color: setColor,
    ...props
}) => {
    const name = mapStatusToIconName(status)
    const color = mapStatusToColor(status)

    const shouldSpin = status === 'running'

    return (
        <Icon
            name={name}
            className={shouldSpin ? 'fa-spin' : undefined}
            color={setColor ?? color}
            noShrink
            {...props}
        />
    )
}

function mapStatusToIconName(status: WorkflowExecutionStatus): IconName {
    switch (status) {
        case 'failed':
            return 'AlertCircle'
        case 'running':
            return 'Loader2'
        case 'success':
            return 'CheckCircle2'
        case 'timed_out':
            return 'Clock'
        default:
            return 'HelpCircle'
    }
}

function mapStatusToColor(
    status: WorkflowExecutionStatus
): React.ComponentPropsWithoutRef<typeof Icon>['color'] {
    switch (status) {
        case 'failed':
            return 'error600'
        case 'success':
            return 'success600'
        case 'timed_out':
        case 'running':
            return 'textWeak'
        default:
            return undefined
    }
}

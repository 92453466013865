import { useEffect, useRef, useState } from 'react'

import { queryCache } from 'data/hooks/_helpers'

export function useCachedRecords(recordIds: string[]): Record<string, RecordDto> {
    const recordIdsInternal = useRef(recordIds)
    const [recordsFromCache, setRecordsFromCache] = useState<Record<string, RecordDto>>({})
    recordIdsInternal.current = recordIds
    useEffect(() => {
        // We want to subscribe to the react-query cache and whenever a record query is updated,
        // if it is one of the supplied record ids, we want to grab the upated record and add it to our
        // state, which we return to the client
        const unsubscribe = queryCache.subscribe((event) => {
            if (event && event.type === 'queryUpdated') {
                const { queryKey } = event.query
                if (queryKey?.[0] === 'record') {
                    const recordId = queryKey[1]
                    if (recordIdsInternal.current.includes(recordId)) {
                        // @ts-expect-error
                        const record = event.action.data
                        if (record) {
                            setRecordsFromCache((prev) => ({ ...prev, [recordId]: record }))
                        }
                    }
                }
            }
        })

        return unsubscribe
    }, [])

    return recordsFromCache
}

import { useContext, useMemo } from 'react'

import { DateFilterContext } from './DateFilterContext'

export const useDateFilter = () => {
    const { specificDate, setSpecificDate } = useContext(DateFilterContext)

    return useMemo(
        () => ({
            specificDate,
            setSpecificDate,
        }),
        [specificDate, setSpecificDate]
    )
}

import React, { forwardRef, useRef } from 'react'
import { createPortal } from 'react-dom'

import { useColumnWidth } from 'features/datagrid/hooks/useColumnWidth'
import { useEditorValue } from 'features/datagrid/hooks/useEditorValue'

import AttachmentsAttribute from 'v2/ui/components/Attribute/AttachmentsAttribute'
import { useThumbnail } from 'v2/ui/components/Attribute/attachmentUtils'
import { AttributeAttachment } from 'v2/ui/components/Attribute/types'
import { modes } from 'v2/ui/utils/attributeSettings'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { DataGridCellEditorComponent, DataGridCellRendererComponent } from './types'

import {
    AttachmentRendererItemPreviewStyle,
    AttachmentRendererItemStyle,
} from './AttachmentsCell.css'

type AddFileButtonProps = {
    anchorRef: React.MutableRefObject<HTMLDivElement | null>
    onClick: () => void
}
const AddFileButton: React.FC<AddFileButtonProps> = ({ anchorRef, onClick }) => {
    if (!anchorRef.current) {
        return null
    }

    return createPortal(
        <Button size="s" variant="secondary" startIcon={{ name: 'Plus' }} onClick={onClick}>
            Add file
        </Button>,
        anchorRef.current
    )
}
type AttachmentPreviewProps = {
    attachment: AttributeAttachment
}
const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({ attachment }) => {
    const { src, onError } = useThumbnail(attachment)

    return (
        <Box className={AttachmentRendererItemStyle} flexShrink={0}>
            <img
                className={AttachmentRendererItemPreviewStyle}
                src={src}
                alt={attachment.filename}
                onError={onError}
            />
        </Box>
    )
}
type AttachmentsCellRendererProps = {}
export const AttachmentsCellRenderer: DataGridCellRendererComponent<
    AttachmentsCellRendererProps
> = ({ value, field }) => {
    if (!value) return null

    const isSingle = field.type === 'image'

    return (
        <Box flex center gap="xs" height="full" width="full">
            {isSingle && <AttachmentPreview attachment={value as AttributeAttachment} />}

            {!isSingle &&
                Array.isArray(value) &&
                value.map((attachment: AttributeAttachment) => (
                    <AttachmentPreview key={attachment.url} attachment={attachment} />
                ))}
        </Box>
    )
}
type AttachmentsCellEditorProps = {}
export const AttachmentsCellEditor: DataGridCellEditorComponent<AttachmentsCellEditorProps> =
    forwardRef(({ value, field, column }, ref) => {
        const [newValue, setNewValue] = useEditorValue<AttributeAttachment | AttributeAttachment[]>(
            ref,
            value
        )

        const divRef = useRef<HTMLDivElement>(null)

        const isSingle = field.type === 'image'
        const isEmpty = !newValue || (Array.isArray(newValue) && newValue.length === 0)

        const columnWidth = useColumnWidth(column)

        return (
            <Box p="m" height="full" style={{ width: '365px', minWidth: `${columnWidth}px` }}>
                <div ref={divRef} />
                <Box mt={isEmpty ? undefined : 'm'} maxHeight="200px" overflowY="auto">
                    <AttachmentsAttribute
                        mode={modes.editing}
                        onChange={setNewValue}
                        FormButton={({ onClick }) => (
                            <AddFileButton anchorRef={divRef} onClick={onClick} />
                        )}
                        isSingle={isSingle}
                    >
                        {newValue as AttributeAttachment[]}
                    </AttachmentsAttribute>
                </Box>
            </Box>
        )
    })

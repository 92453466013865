import React from 'react'

import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
export type ActivityTimestampProps = BoxProps & {
    activity: ActivityDto
}
export function ActivityTimestamp({ activity, ...props }: ActivityTimestampProps) {
    const timeDisplay = useDisplayTimeFrom(activity.created_at)
    return (
        <Box as="span" color="textWeaker" fontSize="bodyS" {...props}>
            {timeDisplay}
        </Box>
    )
}

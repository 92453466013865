export function selectTextInElement(element: HTMLElement) {
    const range = document.createRange()
    range.selectNodeContents(element)

    const selection = window.getSelection()
    if (!selection) return

    selection.removeAllRanges()
    selection.addRange(range)
}

import React from 'react'

import { Frame } from 'features/core/Frame'

const PageFrame = ({ page, app, children, blockEntities, context, view }) => {
    const pageWithDefaultFrame = (
        <Frame context={context} page={page} view={view}>
            {children}
        </Frame>
    )

    const frameID = page.options.frame || app.options.frame
    // Deal with the case where we've disabled the default frame
    if (frameID == 'no_frame') return pageWithDefaultFrame

    // Check for any default frame via meta tags
    let blockList = Object.values(blockEntities || {})
    let defaultFrameByTags = blockList.find((block) => block.tags['stacker.defaultFrame'])

    if (!frameID && !defaultFrameByTags) return pageWithDefaultFrame

    const frame = blockEntities[frameID] || defaultFrameByTags
    if (!frame || !frame.blocks || !frame.blocks.length) return pageWithDefaultFrame

    return pageWithDefaultFrame
}
export default PageFrame

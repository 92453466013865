import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { useDuplicateView } from 'data/hooks/views'

import { Box, Button, Collapse, Icon, Input, Text } from 'v2/ui'
import { DuplicateLayout as DuplicateLayoutIcon } from 'v2/ui/svgs'

import {
    formatDuplicateViewErrorLabel,
    formatDuplicateViewLabel,
    formatDuplicateViewPlaceholderLabel,
    formatDuplicateViewValidationLabels,
} from './labels'

type DuplicateViewModalContentProps = {
    view: ViewDto
    onConfirm?: () => Promise<void>
    isOpen?: boolean
}

export const DuplicateViewModalContent: React.FC<DuplicateViewModalContentProps> = ({
    view,
    onConfirm,
    isOpen,
}) => {
    const [newViewName, setNewViewName] = useState('')
    const [validationError, setValidationError] = useState('')

    const {
        mutateAsync: duplicateView,
        isLoading,
        reset,
    } = useDuplicateView({
        document_id: view.options.document_id,
        onError: () => {
            setValidationError(formatDuplicateViewErrorLabel(view))
        },
    })
    const history = useHistory()

    const cleanup = useCallback(() => {
        setNewViewName('')
        setValidationError('')
        reset()
    }, [reset])

    const validateForm = () => {
        const labels = formatDuplicateViewValidationLabels(view)

        if (newViewName.length < 1) {
            return labels.nameTooShort
        }

        return ''
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        const validationMessage = validateForm()
        if (validationMessage) {
            setValidationError(validationMessage)

            return
        }

        const newView = (await duplicateView({
            name: newViewName,
            view,
        } as unknown as void)) as ViewDto

        history.push(getUrl(newView.url))

        await onConfirm?.()
        cleanup()
    }

    useEffect(() => {
        if (!isOpen) {
            cleanup()
        }
    }, [isOpen, cleanup])

    return (
        <Box as="form" onSubmit={onSubmit}>
            <Icon svg={<DuplicateLayoutIcon />} />
            <Box textAlign="center" mt={6}>
                <Text as="label" htmlFor="dup-view-input" variant="modalTitle" fontWeight="bold">
                    {formatDuplicateViewLabel(view)}
                </Text>
            </Box>

            <Input
                id="dup-view-input"
                mt={2}
                alignSelf="stretch"
                placeholder={formatDuplicateViewPlaceholderLabel(view)}
                value={newViewName}
                maxLength={30}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValidationError('')
                    setNewViewName(e.target.value)
                }}
                autoFocus={true}
                variant="admin"
                width="100%"
            />
            <Collapse isOpen={validationError.length > 0} mt={2} width="100%">
                <Text variant="error" size="sm" width="100%" textAlign="left">
                    {validationError}
                </Text>
            </Collapse>
            <Button
                variant="adminPrimary"
                buttonSize="md"
                mt={4}
                width="100%"
                type="submit"
                isLoading={isLoading}
            >
                Duplicate
            </Button>
        </Box>
    )
}

import React, { useMemo, useState } from 'react'

import { LookupFilterContext } from './lookupFilterContext'

type LookupFilterContextProviderProps = React.PropsWithChildren<{}>

export const LookupFilterContextProvider: React.FC<LookupFilterContextProviderProps> = ({
    children,
}) => {
    const [searchString, setSearchString] = useState('')
    const [isFetching, setIsFetching] = useState(false)

    const value = useMemo(
        () => ({
            searchString,
            setSearchString,
            isFetching,
            setIsFetching,
        }),
        [isFetching, searchString]
    )

    return <LookupFilterContext.Provider value={value}>{children}</LookupFilterContext.Provider>
}

import { useMemo } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

export function useLayoutEditorControlsState() {
    const { view, selectedWidget, isSchemaOutdated } = useLayoutEditorContext()

    const viewType = view?.type

    const hasWidgetSelected = !!selectedWidget

    return useMemo(
        () => ({ viewType, hasWidgetSelected, isSchemaOutdated }),
        [hasWidgetSelected, isSchemaOutdated, viewType]
    )
}

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

export const Span = styled('span')`
    a > & {
        color: inherit;
    }
    font-family: ${(props) => props.theme.fontFamily};
    font-size: ${(props) => props.theme[props.size || 'fontM']};
    padding: ${(props) => props.theme[props.padding || 'small']} 0px;
    margin: ${(props) => props.theme[props.margin || 'small']} 0px;
    color: ${(props) => props.theme.fontColor};
    ${(props) =>
        props.inline &&
        `
        display: inline-block;
    `};
    ${(props) =>
        props.light &&
        `
        color: ${props.theme.lightFontColor};
    `};

    ${(props) =>
        props.singleLine &&
        ` white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}

    ${(props) =>
        props.noOverflow &&
        `
    overflow: hidden;
    text-overflow: ellipsis;`}

    ${(props) =>
        props.centered &&
        `
    text-align: center`}
`

Span.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    margin: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
    size: PropTypes.oneOf(['fontS', 'fontM', 'fontL', 'fontXL']),
    inline: PropTypes.bool,
    light: PropTypes.bool,
    singleLine: PropTypes.bool,
    centered: PropTypes.bool,
}

Span.displayName = 'Span'

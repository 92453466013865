// @ts-strict-ignore
import { useCallback } from 'react'

import { useAccountUserContext } from 'app/useAccountUserContext'
import { useAppContext } from 'app/useAppContext'
import { useAppUserContext } from 'app/useAppUserContext'

import analytics from './analytics'

/**
 * @deprecated
 * Use useTrackEvent instead
 */
const useTrack = () => {
    const { workspaceAccount, selectedStack } = useAppContext()
    const { user, role: appRole } = useAppUserContext()
    const { role: accountRole } = useAccountUserContext()

    return {
        track: useCallback(
            (eventName, additionalPayload = {}) => {
                const basePayload = {
                    ...(workspaceAccount
                        ? {
                              workspace_id: workspaceAccount._sid,
                              subscription: workspaceAccount.plan_tier?.toLowerCase() ?? 'trial',
                          }
                        : {}),
                    ...(selectedStack ? { app_id: selectedStack._sid } : {}),
                    ...{ user_id: user?._sid },
                    ...(accountRole?.api_name
                        ? { account_role_api_name: accountRole?.api_name }
                        : {}),
                    ...(appRole?.api_name ? { app_role: appRole?.api_name } : {}),
                    is_support_login: localStorage.getItem('support_login') ?? false,
                }
                analytics.track(eventName, { ...basePayload, ...additionalPayload })
            },
            [workspaceAccount, selectedStack, user, accountRole, appRole]
        ),
    }
}
export default useTrack

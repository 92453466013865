import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makePopupVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        colors: {
            surface: s.color.surface,

            divider: s.color.gray200,

            borderContainer: s.color.borderWeak,
            text: s.color.text,
        },
        shadows: {
            container: s.shadows.l,
            focus: s.shadows.focus,
        },
        radius: {
            container: s.radius['2xl'],
        },
        borderWidths: {
            container: s.borderWidths[1],
        },
        borderStyles: {
            container: s.borderStyles.base,
        },
        padding: {
            containerVertical: s.space.m,
            footerVertical: s.space.m,
            footerHorizontal: s.space.xl,
            titleVertical: s.space.xs,
            titleHorizontal: s.space.xl,
            contentVertical: s.space.m,
            contentHorizontal: s.space.xl,
            dividerVertical: s.space.s,
        },
        gap: {
            footerContent: s.space.s,
            titleContent: s.space.xs,
        },
        fontSizes: {
            content: s.fontSizes.bodyS,
            title: s.fontSizes.bodyM,
        },
        fontWeights: {
            content: s.fontWeights.bodyRegular,
            title: s.fontWeights.bodyBold,
        },
        lineHeights: {
            content: s.lineHeights.bodyS,
            title: s.lineHeights.bodyM,
        },
        size: {
            container: pxToRem(650),
        },
    }
}

import React from 'react'

import { LayoutEditorWidgetArea } from 'features/views/LayoutEditor/LayoutEditorWidgetArea'

import { Box } from 'ui/components/Box'

type DetailViewSidebarLayoutProps = {}

export const DetailViewSidebarLayout: React.FC<DetailViewSidebarLayoutProps> = () => {
    return (
        <Box
            as="main"
            px="4xl"
            gap="l"
            display="grid"
            height="full"
            style={{
                gridTemplateColumns: '350px 1fr',
            }}
        >
            <Box as="aside">
                <LayoutEditorWidgetArea id="sidebar" />
            </Box>
            <Box as="section">
                <LayoutEditorWidgetArea id="content" />
            </Box>
        </Box>
    )
}

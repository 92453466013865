import React, { useRef } from 'react'

import { buildPermissionRuleRequestDataFromFormData } from './PermissionRuleForm/buildPermissionRuleRequestDataFromFormData'
import { PermissionsRuleForm } from './PermissionRuleForm'

const AppSettingsModalCreatePermissionModal = ({
    onClose,
    onSubmit,
    permissionRules,
    roles,
    object,
    userObject,
    objects,
}) => {
    const fieldPermissions = object.fields
        .filter((field) => {
            return !(field.connection_options && field.connection_options.is_disabled)
        })
        .reduce(
            (result, field) => {
                result[field.api_name] = { read: true, update: true, create: true }
                return result
            },
            { '*': { read: true, update: true, create: true } }
        )

    const formRef = useRef()

    const createPermissionRule = (data) => {
        // validate data
        const nameLowercase = data.name.toLowerCase()
        const isDuplicateRuleName = permissionRules.some((rule) => {
            return rule.label.toLowerCase() === nameLowercase
        })

        if (isDuplicateRuleName) {
            formRef.current &&
                formRef.current.setError('name', {
                    type: 'manual',
                    message: 'There is already a rule with that name',
                })
            return
        }

        const reqData = buildPermissionRuleRequestDataFromFormData(data, object)

        return onSubmit(reqData)
    }

    const ruleName = permissionRules.length
        ? `${object.name} permissions ${permissionRules.length + 1}`
        : `${object.name} permissions`

    return (
        <PermissionsRuleForm
            title="Add permission"
            onClose={onClose}
            formRef={formRef}
            defaultValues={{
                name: ruleName,
                roles: roles.map((role) => role._sid),
                allRoles: true,
                allRecords: true,
                permissionType: 'global',
                recordPermissionCreate: true,
                recordPermissionUpdate: true,
                recordPermissionDelete: false,
                recordFilter: [],
                fieldPermissions,
                ownershipFilter: {
                    active: false,
                    objectFieldSelected: null,
                    userFieldSelected: null,
                },
            }}
            onSubmit={createPermissionRule}
            object={object}
            objects={objects}
            userObject={userObject}
            roles={roles}
            enableUnsavedChangesModal={true}
            isNew={true}
        />
    )
}

export default AppSettingsModalCreatePermissionModal

import React from 'react'

import classNames from 'classnames'

import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'

import { AttributeDisplayComponent, CheckboxDisplay } from './types'

import { CheckboxAttributeCheckedTagStyles } from './CheckboxAttributeTag.css'

type CheckboxAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    valueDisplay?: CheckboxDisplay
    isLoading?: boolean
    showIcon?: boolean
}

export const CheckboxAttributeTag: AttributeDisplayComponent<boolean, CheckboxAttributeTagProps> =
    React.memo(function CheckboxAttributeTag({
        value,
        valueDisplay = 'default',
        isLoading,
        className,
        ...props
    }) {
        const isFilled = valueDisplay === 'bigFilled' || valueDisplay === 'filled'

        let iconName = value ? 'Check' : 'X'
        if (isFilled) {
            iconName = value ? 'CheckCircle2' : 'XCircle'
        }

        return (
            <Skeleton isLoading={isLoading}>
                <Tag
                    startIcon={{ name: iconName }}
                    type="outline"
                    size="2xs"
                    pointerEvents="none"
                    className={classNames(
                        className,
                        CheckboxAttributeCheckedTagStyles.styleFunction({ isChecked: !!value })
                    )}
                    aria-label={value ? 'Checked' : 'Unchecked'}
                    noShrink
                    {...props}
                />
            </Skeleton>
        )
    })

import React from 'react'

import { Toast } from './Toast'

export type ToastProps = Pick<
    React.ComponentPropsWithoutRef<typeof Toast>,
    'type' | 'title' | 'helperText' | 'startIcon' | 'showDismissButton' | 'onClick'
>
export type StateToast = ToastProps & { id: string }

export type ToastContextState = {
    toasts: StateToast[]
}
export const defaultContextState: ToastContextState = {
    toasts: [],
}

export type ToastContextType = ToastContextState & {
    showToast: (props: ToastProps) => void
}
const defaultContext: ToastContextType = {
    ...defaultContextState,
    showToast: () => {},
}
export const ToastContext = React.createContext(defaultContext)

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeIconPickerVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            container: s.color.surface,
            header: s.color.surface,
            iconWrapper: s.color.surface,
            iconWrapperActive: s.color.theme100,
            iconWrapperHover: s.color.gray100,
            icon: s.color.icon,
            iconActive: s.color.iconTheme,
        },
        padding: {
            pickerTop: s.space.xs,
            pickerBottom: s.space.m,
            iconWrapper: s.space.xs,
            clearContainerBottom: s.space.m,
        },
        gap: {
            icons: s.space['2xs'],
            header: s.space.m,
        },
        radius: {
            iconWrapper: s.radius.s,
        },
        size: {
            iconWrapper: s.space['3xl'],
            header: pxToRem(29),
        },
    }
}

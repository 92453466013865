import React from 'react'
import { Link } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { useStacks } from 'data/hooks/stacks'

import { useActivityFeedContext } from './useActivityFeedContext'

type RecordLinkProps = {
    objectId: string
    recordId: string
    stackId: string
    location?: string
}
export function RecordLink({ objectId, recordId, stackId, location }: RecordLinkProps) {
    const { data: stacks } = useStacks()
    const { records, objects } = useActivityFeedContext()

    const record = records?.find((r) => r._sid === recordId)
    const stack = stacks?.find((s) => s._sid === stackId)

    if (!record) return null
    if (!stack) return <>{record._primary}</>
    const object = objects[stackId]?.[objectId]
    if (!object) return <>{record._primary}</>

    let url = getUrl(`${object.url}/view/${record?._sid}`, stack)
    if (location) {
        url += `#${encodeURIComponent(location)}`
    }

    // @ts-ignore
    return <Link to={url}>{record._primary}</Link>
}

import React, { forwardRef } from 'react'

import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { TooltipProps } from 'ui/components/Tooltip/Tooltip'

import * as Parts from './Radio.parts'

type IconProps = IconNameVariantType

type RadioCardRef = HTMLButtonElement

type RadioCardProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.RadioCard>, 'asChild'> & {
    icon: IconProps | (() => React.ReactNode)
    cardIconWrapperProps?: React.ComponentPropsWithoutRef<typeof Parts.RadioCardIconWrapper>
}

export const RadioCard = forwardRef<
    RadioCardRef,
    RadioCardProps & {
        hasTooltip?: boolean
        tooltipContent?: TooltipProps['content']
        tooltipSide?: TooltipProps['side']
    }
>(
    (
        { children, icon, tooltipContent, tooltipSide, hasTooltip, cardIconWrapperProps, ...props },
        ref
    ) => {
        const IconWrapper =
            hasTooltip && tooltipContent ? (
                <Parts.RadioCardIconTooltip
                    content={tooltipContent}
                    side={tooltipSide}
                    zIndex={50000}
                >
                    {typeof icon === 'function' ? (
                        icon()
                    ) : (
                        <Parts.RadioCardIcon size="s" {...icon} />
                    )}
                </Parts.RadioCardIconTooltip>
            ) : (
                <Parts.RadioCardIconWrapper {...cardIconWrapperProps}>
                    {typeof icon === 'function' ? (
                        icon()
                    ) : (
                        <Parts.RadioCardIcon size="s" {...icon} />
                    )}
                </Parts.RadioCardIconWrapper>
            )

        return (
            <Parts.RadioCard {...props} asChild={false} ref={ref} hasTooltip={hasTooltip}>
                {IconWrapper}
                <div>
                    <Parts.RadioCardLabel>{children}</Parts.RadioCardLabel>
                </div>
            </Parts.RadioCard>
        )
    }
)

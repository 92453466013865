import { RecordsList } from 'features/tiptap/Extensions/RecordSearchExtension'

import { InternalLinkCommand } from './internalLinkCommandTypes'
import { SearchUsers } from './SearchUsers'

export const InternalLinkCommands: InternalLinkCommand[] = [
    {
        id: 'mention_user',
        label: 'Mention user...',
        icon: 'User',
        component: SearchUsers,
    },
    {
        id: 'link_record',
        label: 'Link to record...',
        icon: 'FileText',
        component: RecordsList,
    },
]

import React, { useContext } from 'react'

export type FiltersContextValue = {
    filters: Filter[]
    inlineFilters: Filter[]
    addFilter: (filter: Filter) => void
    removeFilter: (id: number) => void
    updateFilter: (filter: Filter) => void
    setFilters: (filters: Filter[]) => void
    clearFilters: () => void
    availableInlineFilterFields: FieldDto[]
    inlineFilterType?: ListViewInlineFilters
}
export const FiltersContext = React.createContext<FiltersContextValue | null>(null)

export function useFiltersContext(): FiltersContextValue {
    const context = useContext(FiltersContext)

    if (!context) {
        throw new Error('useFiltersContext must be used within a FiltersContextProvider')
    }

    return context
}

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

import { SelectorLabel } from 'ui/components/Field'
import { makeComponent } from 'ui/helpers/recipes'

import { CheckboxStyles, ContainerStyles, IndicatorStyles, LabelStyles } from './Checkbox.css'

export const Container = makeComponent('div', ContainerStyles)
export const Root = makeComponent(CheckboxPrimitive.Root, CheckboxStyles)
export const Indicator = makeComponent(CheckboxPrimitive.Indicator, IndicatorStyles)
export const Label = makeComponent(SelectorLabel, LabelStyles)

import { replaceCachedZone } from 'data/hooks/zones'

export const updateZoneUrl = async (
    onUpdateZone: any,
    zone: AccountZone,
    values: {
        name: string
        slug: string
        custom_domain?: string | null
        enable_permanent_login_links?: boolean
    }
): Promise<AccountZone> => {
    return onUpdateZone({
        id: zone._sid,
        patch: {
            name: values.name,
            custom_domain: values.custom_domain === '' ? null : values.custom_domain,
            slug: values.slug,
            enable_permanent_login_links: values.enable_permanent_login_links,
        },
    }).then((resp: AccountZone) => {
        replaceCachedZone(resp)
        return resp
    })
}

import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useAppUsers } from 'data/hooks/users/main'
import { UserItem } from 'features/views/attributes/hooks/useUsersState'
import { extractPartsFromUserName } from 'features/views/attributes/utils'
import { AdvancedFilter } from 'features/views/ListView/ListHeader/Filters/Advanced/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { truncateText } from 'ui/helpers/utilities'

const MAX_ITEMS = 2
const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

type FilterOption = {
    label: string
    value: string
    avatar: {
        imageUrl: string
        firstName: string
        lastName: string
        type: 'initial' | 'image'
    }
}

type UseAdvancedFilterValueUserStateOptions = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
    maxItemLength?: number
}

export function useAdvancedFilterValueUserState(options: UseAdvancedFilterValueUserStateOptions) {
    const { filter, onChange, maxItemLength, onRemoveFilter } = options

    const { data: users, isFetching, isError } = useAppUsers()
    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const internalValue = useDeepEqualsMemoValue(getValueFromFilter(filter, isFetchingSlow))
    const filterValueRef = useRef(internalValue)
    filterValueRef.current = internalValue

    const value = useMemo(() => new Set(internalValue), [internalValue])
    const valueRef = useRef(value)
    valueRef.current = value

    const supportsMultiValue = false
    const isSingle = !supportsMultiValue

    const onSetFilterValue = useCallback(
        (value: string, isEnabled: boolean) => {
            const defaultValue = supportsMultiValue ? [] : ''
            const filterValue = filterValueRef.current
            const existingValue = filterValue ?? defaultValue

            let newValue: AdvancedFilter['options']['value']
            if (isEnabled) {
                if (supportsMultiValue) {
                    newValue = [...existingValue, value]
                } else {
                    newValue = value
                }
            } else {
                if (supportsMultiValue) {
                    newValue = existingValue.filter((v) => v !== value)
                } else {
                    newValue = defaultValue
                }
            }

            // Remove filter when clearing the value.
            if (newValue.length < 1) {
                onRemoveFilter()
                return
            }

            onChange(newValue)
        },
        [onChange, onRemoveFilter, supportsMultiValue]
    )

    const filterOptions = isFetchingSlow
        ? PLACEHOLDER_OPTIONS
        : makeFilterOptions(users, maxItemLength)

    const valueOptions = getValueOptions(value, filterOptions)

    const hasValue = value.size > 0
    const isLabelLoading = hasValue && !users

    const [isOpen, setIsOpen] = useState(false)
    useLayoutEffect(() => {
        if (isOpen) {
            // Make sure we set the pointer events to none. This can be reset
            // if this dropdown is opened after another dropdown.
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = 'none'
            }, 0)

            return () => clearTimeout(timer)
        } else {
            document.body.style.pointerEvents = 'auto'
        }
    }, [isOpen])

    // Focus input when filter is created.
    useEffect(() => {
        if (filter.isDraft) {
            setIsOpen(true)
        }
    }, [filter.isDraft])

    // Focus input when operation is changed, and the filter is empty.
    useEffectOnlyOnUpdate(() => {
        const isEmpty = !valueRef.current.size
        if (!isEmpty) return

        setIsOpen(true)
    }, [filter.options.option])

    return useMemo(
        () => ({
            value,
            onSetFilterValue,
            filterOptions,
            isError,
            isFetchingSlow,
            isSingle,
            valueOptions,
            isLabelLoading,
            isOpen,
            setIsOpen,
        }),
        [
            value,
            onSetFilterValue,
            filterOptions,
            isError,
            isFetchingSlow,
            isSingle,
            valueOptions,
            isLabelLoading,
            isOpen,
            setIsOpen,
        ]
    )
}

function makeFilterOptions(users?: UserDto[], maxItemLength?: number): FilterOption[] {
    if (!users) {
        return []
    }

    const options = users.map((user) => {
        const label = user.name

        const imageUrl = user.avatar
        const { firstName, lastName } = extractPartsFromUserName(label)

        return {
            label: maxItemLength ? truncateText(label, maxItemLength) : label,
            value: user._sid,
            avatar: {
                imageUrl,
                firstName,
                lastName,
                type: imageUrl ? ('image' as const) : ('initial' as const),
            },
        }
    })

    options.sort((a, b) => {
        // Sort by label alphabetically.
        if (a.label.localeCompare(b.label) < 0) return -1
        if (a.label.localeCompare(b.label) > 0) return 1

        return 0
    })

    return options
}

function getValueFromFilter(filter: AdvancedFilter, isFetchingSlow?: boolean): string[] {
    if (isFetchingSlow) {
        return PLACEHOLDER_OPTIONS.map((option) => option.value)
    }

    const filterValue = filter.options?.value

    if (Array.isArray(filterValue)) {
        return filterValue
    }

    if (filterValue) {
        return [filterValue]
    }

    return []
}

function getValueOptions(value: Set<string>, filterOptions: FilterOption[]): UserItem[] {
    const activeOptions = filterOptions.reduce((acc, option) => {
        if (value.has(option.value)) {
            acc.push(option.avatar)
        }

        return acc
    }, [] as UserItem[])

    const truncatedOptions = activeOptions.slice(0, MAX_ITEMS)

    return truncatedOptions
}

const PLACEHOLDER_OPTIONS: FilterOption[] = [
    {
        label: 'Loading options...',
        value: 'loading1',
        avatar: {
            type: 'initial',
            firstName: 'Loading',
            lastName: 'Options',
            imageUrl: '',
        },
    },
    {
        label: 'Loading options...',
        value: 'loading2',
        avatar: {
            type: 'initial',
            firstName: 'Loading',
            lastName: 'Options',
            imageUrl: '',
        },
    },
    {
        label: 'Loading options...',
        value: 'loading3',
        avatar: {
            type: 'initial',
            firstName: 'Loading',
            lastName: 'Options',
            imageUrl: '',
        },
    },
    {
        label: 'Loading options...',
        value: 'loading4',
        avatar: {
            type: 'initial',
            firstName: 'Loading',
            lastName: 'Options',
            imageUrl: '',
        },
    },
    {
        label: 'Loading options...',
        value: 'loading5',
        avatar: {
            type: 'initial',
            firstName: 'Loading',
            lastName: 'Options',
            imageUrl: '',
        },
    },
    {
        label: 'Loading options...',
        value: 'loading6',
        avatar: {
            type: 'initial',
            firstName: 'Loading',
            lastName: 'Options',
            imageUrl: '',
        },
    },
]

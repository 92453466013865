// @ts-strict-ignore
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useActions } from 'data/hooks/actions'
import { useViews } from 'data/hooks/views'
import {
    BoardViewAddNewCardButtonStyle,
    BoardViewAddNewCardPopover,
} from 'features/views/ListView/BoardView/BoardView.css'
import { BoardViewColumn } from 'features/views/ListView/BoardView/types'
import { useBoardViewContext } from 'features/views/ListView/BoardView/useBoardViewContext'
import { extractFieldApiNamesFromCreateView } from 'features/views/ListView/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseBoardViewAddNewCardStateOptions = {
    column: BoardViewColumn
    onCancel?: () => void
    onAddNewRecord?: (record: RecordDto, isFirstInColumn: boolean) => void
    isFirstInColumn?: boolean
    fistRecordLocalDisplayOrder?: number
}

export function useBoardViewAddNewCardState(options: UseBoardViewAddNewCardStateOptions) {
    const { column, onCancel, onAddNewRecord, isFirstInColumn, fistRecordLocalDisplayOrder } =
        options

    const {
        createRecord,
        object,
        statusField,
        embeddedByField,
        embeddedByFieldValue,
        embeddedByRecord,
        view,
        allFields,
        allowReorder,
    } = useBoardViewContext()

    const scrollElementIntoView = useCallback(() => {
        const element = elementRef.current
        if (!element) return

        const elRect = element.getBoundingClientRect()
        const isInViewport = elRect.top >= 0 && elRect.bottom <= window.innerHeight

        if (!isInViewport) {
            element.scrollIntoView({
                block: 'end',
                inline: 'nearest',
            })
        }
    }, [])

    // Scroll to the bottom of the list when adding a new card.
    useLayoutEffect(() => {
        scrollElementIntoView()
    }, [scrollElementIntoView])

    const embeddedByFieldRef = useRef(embeddedByField)
    embeddedByFieldRef.current = embeddedByField

    const [record, setRecord] = useState<Partial<RecordDto>>({})

    const recordWithOverrides = useMemo(() => {
        const newRecord = {
            _object_id: object._sid,
            ...record,
        }

        // Make sure this is not overwritten.
        if (statusField) {
            newRecord[statusField.api_name] = column.value
        }

        // If this is a related list, pre-set the related record field.
        const embeddedByField = embeddedByFieldRef.current
        prefillEmbeddedViewData(newRecord, embeddedByField, embeddedByFieldValue)

        return newRecord
    }, [column.value, embeddedByFieldValue, object._sid, record, statusField])
    const recordRef = useRef(recordWithOverrides)
    recordRef.current = recordWithOverrides

    const updateRecord = useCallback(
        (record?: Partial<RecordDto>) => {
            let newRecord: Partial<RecordDto> = {}
            if (record) {
                newRecord = {
                    ...recordRef.current,
                    ...record,
                }
            }

            setRecord(newRecord)
            persistToSessionStorage(view, column, newRecord, embeddedByRecord)
        },
        [column, embeddedByRecord, view]
    )

    const cancelEditing = useCallback(() => {
        onCancel?.()
        persistToSessionStorage(view, column, {}, embeddedByRecord)
    }, [column, embeddedByRecord, onCancel, view])

    useLayoutEffect(() => {
        const existingRecord = restoreFromSessionStorage(view, column, embeddedByRecord)
        if (existingRecord) {
            setRecord(existingRecord)
        } else {
            setRecord({})
        }
    }, [column, embeddedByRecord, view])

    const primaryFieldApiName = allFields.find((field) => field.is_primary)?.api_name
    const setTitle = useCallback(
        (title: string) => {
            if (!primaryFieldApiName) return

            updateRecord({
                [primaryFieldApiName]: title,
                // This is so the title is displayed in the card while the record is being created.
                _primary: title,
            })
        },
        [primaryFieldApiName, updateRecord]
    )

    const title = record[primaryFieldApiName ?? ''] ?? ''
    const isSubmitDisabled = title.trim().length < 1

    const onSubmit = useCallback(
        (e?: React.FormEvent, clearFields: boolean = true) => {
            e?.preventDefault()
            e?.stopPropagation()

            if (isSubmitDisabled) return

            const record = recordRef.current
            const newRecord: Partial<RecordDto> = {
                ...record,
            }

            if (allowReorder && isFirstInColumn) {
                // Make sure this card is displayed first in the column,
                // while the record is created in the background.
                newRecord._local_display_order = (fistRecordLocalDisplayOrder ?? 0) - 1
            }

            createRecord(newRecord).then((record) =>
                onAddNewRecord?.(record, isFirstInColumn ?? false)
            )
            if (clearFields) {
                updateRecord()
            } else {
                // Clear the title field only.
                setTitle('')
            }

            // Make sure the new record is shown in the UI before scrolling to the bottom.
            queueMicrotask(() => {
                scrollElementIntoView()
            })
        },
        [
            isSubmitDisabled,
            allowReorder,
            isFirstInColumn,
            createRecord,
            fistRecordLocalDisplayOrder,
            onAddNewRecord,
            updateRecord,
            setTitle,
            scrollElementIntoView,
        ]
    )

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            switch (e.key) {
                case 'Enter':
                    const isMultiple = (e.ctrlKey || e.metaKey) && e.shiftKey
                    onSubmit(e, !isMultiple)
                    break
                case 'Escape': {
                    // If any of the value editors are open, don't cancel.
                    const isPopoverOpen = !!document.querySelector(`.${BoardViewAddNewCardPopover}`)
                    if (isPopoverOpen) return

                    cancelEditing()
                    break
                }
            }
        },
        [cancelEditing, onSubmit]
    )

    const elementRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        const handleOutsideClick = (e: MouseEvent) => {
            const element = elementRef.current
            if (!element) return

            // If any of the value editors are open, don't cancel.
            const isPopoverOpen = !!document.querySelector(`.${BoardViewAddNewCardPopover}`)
            if (isPopoverOpen) return

            const target = e.target as HTMLElement

            // If this is one of the add new card buttons, don't cancel.
            const isAddNewCardButton = target.closest(`.${BoardViewAddNewCardButtonStyle}`)
            if (isAddNewCardButton) return

            if (element.contains(target)) return

            const isTargetInteractive = target.closest('button, a, input, [role="button"]')
            if (isTargetInteractive) {
                // If you click on an interactive element, wait for the click event to finish before cancelling.
                setTimeout(() => {
                    onCancel?.()
                }, 150)
            } else {
                // We need to defer this to the next microtask so the click event is not swallowed by the input blur.
                queueMicrotask(() => {
                    onCancel?.()
                })
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)
        return () => document.removeEventListener('mousedown', handleOutsideClick)
    }, [onCancel])

    const { data: allViews = [] } = useViews()
    const { data: allActions } = useActions()
    const createViewFields = extractFieldApiNamesFromCreateView(
        view._sid,
        allActions,
        allViews,
        allFields,
        object
    )

    const editableFields = getEditableFields(
        column,
        allFields,
        createViewFields,
        statusField,
        embeddedByField
    )
    const editableFieldsMemo = useDeepEqualsMemoValue(editableFields)

    const onChangeFieldValue = useCallback(
        (field: FieldDto, value: unknown) => {
            updateRecord({
                [field.api_name]: value,
            })
        },
        [updateRecord]
    )

    const canCreateMultiple = shouldShowAddMultiple(
        editableFieldsMemo,
        recordWithOverrides,
        statusField,
        embeddedByField
    )

    const onCreateMultiple = useCallback(() => {
        if (!canCreateMultiple) return

        onSubmit(undefined, false)
    }, [canCreateMultiple, onSubmit])

    return useMemo(
        () => ({
            title,
            setTitle,
            isSubmitDisabled,
            onSubmit,
            onKeyDown,
            elementRef,
            cancelEditing,
            editableFields: editableFieldsMemo,
            record: recordWithOverrides,
            onChangeFieldValue,
            canCreateMultiple,
            onCreateMultiple,
        }),
        [
            title,
            setTitle,
            isSubmitDisabled,
            onSubmit,
            onKeyDown,
            cancelEditing,
            editableFieldsMemo,
            recordWithOverrides,
            onChangeFieldValue,
            canCreateMultiple,
            onCreateMultiple,
        ]
    )
}

function prefillEmbeddedViewData(
    record: Partial<RecordDto>,
    embeddedByField?: FieldDto,
    embeddedByFieldValue?: string
) {
    if (!embeddedByField || !embeddedByFieldValue) return

    if (embeddedByField.type === 'multi_lookup') {
        record[embeddedByField.api_name] = [embeddedByFieldValue]
    } else {
        record[embeddedByField.api_name] = embeddedByFieldValue
    }
}

function getSessionStorageKey(
    view: ViewDto,
    column: BoardViewColumn,
    embeddedByRecord?: RecordDto
) {
    let baseKey = `${view.stack_id}_${view._sid}_${column.id}_new_card`
    if (embeddedByRecord) {
        baseKey += `_${embeddedByRecord._sid}`
    }

    return baseKey
}

function persistToSessionStorage(
    view: ViewDto,
    column: BoardViewColumn,
    record: Partial<RecordDto>,
    embeddedByRecord?: RecordDto
) {
    const key = getSessionStorageKey(view, column, embeddedByRecord)
    sessionStorage.setItem(key, JSON.stringify(record))
}

function restoreFromSessionStorage(
    view: ViewDto,
    column: BoardViewColumn,
    embeddedByRecord?: RecordDto
): Partial<RecordDto> | undefined {
    const key = getSessionStorageKey(view, column, embeddedByRecord)
    const value = sessionStorage.getItem(key)
    if (!value) return

    return JSON.parse(value)
}

function isFieldEditable(field: FieldDto) {
    if (field.is_read_only) return false

    return ['lookup', 'multi_lookup', 'dropdown', 'multi_select'].includes(field.type)
}

function getEditableFields(
    column: BoardViewColumn,
    allFields: FieldDto[],
    createViewFieldApiNames: string[],
    statusField?: FieldDto,
    embeddedByField?: FieldDto
) {
    const visibleFieldApiNames = new Set(createViewFieldApiNames)
    if (statusField) {
        if (!!column.value) {
            // Make sure to always show the status field,
            // if this is not the `No category` column.
            visibleFieldApiNames.add(statusField.api_name)
        } else {
            // If this is the `No category` column,
            // don't show the status field.
            visibleFieldApiNames.delete(statusField.api_name)
        }
    }
    if (embeddedByField) {
        // Make sure to always show the embedded by field, if it exists.
        visibleFieldApiNames.add(embeddedByField.api_name)
    }

    return allFields
        .filter((f) => {
            // Only show fields that are in the create view.
            if (!visibleFieldApiNames.has(f.api_name)) return false

            if (!isFieldEditable(f)) return false

            return true
        })
        .sort((a, b) => {
            // Keep status field first.
            if (statusField && a._sid === statusField._sid) return -1
            if (statusField && b._sid === statusField._sid) return 1

            // Keep embedded by fields next.
            if (embeddedByField && a._sid === embeddedByField._sid) return -1
            if (embeddedByField && b._sid === embeddedByField._sid) return 1

            return 0
        })
}

function shouldShowAddMultiple(
    editableFields: FieldDto[],
    record: Partial<RecordDto>,
    statusField?: FieldDto,
    embeddedByField?: FieldDto
) {
    const statusFieldApiName = statusField?.api_name
    const embeddedByFieldApiName = embeddedByField?.api_name

    return editableFields.some((f) => {
        // We only show the add multiple option if any of the editable fields are changed (but not the title).
        if (f.api_name === statusFieldApiName) return false
        if (f.api_name === embeddedByFieldApiName) return false
        if (f.is_primary) return false

        return record.hasOwnProperty(f.api_name)
    })
}

import { useCallback } from 'react'

import { useObjects } from 'data/hooks/objects'
import { useWorkflowSchema } from 'data/hooks/workflows/workflows'

export function useFindRecordsActionFinalSchema() {
    const { data: objects } = useObjects()
    const { data: schema } = useWorkflowSchema()
    return useCallback(
        (config: WorkflowActionConfig): WorkflowSchemaNodeType | undefined => {
            const type = schema?.nodes.find(
                (t) => t.id === config.action_type
            ) as WorkflowSchemaActionType
            const object = objects?.find((o) => o._sid === config.settings?.object_id)

            if (!type || !object) {
                return undefined
            }

            return {
                ...type,
                state: [
                    ...type.state.map((o) => {
                        if (o.id === 'records') {
                            return {
                                ...o,
                                extra_options: {
                                    ...o.extra_options,
                                    link_target_object_id: object._sid,
                                },
                            } as WorkflowSchemaActionStateItem
                        }
                        return o
                    }),
                ],
            }
        },
        [objects, schema?.nodes]
    )
}

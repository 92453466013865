import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

import { Box } from 'ui/components/Box'
import { SelectorLabel } from 'ui/components/Field'
import { Icon } from 'ui/components/Icon'
import { Tooltip } from 'ui/components/Tooltip'
import { makeComponent } from 'ui/helpers/recipes'

import {
    ButtonStyles,
    ContainerStyles,
    IndicatorStyles,
    LabelStyles,
    RadioCardsIconStyles,
    RadioCardsIconWrapperStyles,
    RadioCardsLabelStyles,
    RadioCardsRootStyles,
    RadioCardStyles,
    RootStyles,
} from './Radio.css'

export const Root = makeComponent(RadioGroupPrimitive.Root, RootStyles)
export const Container = makeComponent('div', ContainerStyles)
export const Button = makeComponent(RadioGroupPrimitive.Item, ButtonStyles)
export const Indicator = makeComponent(RadioGroupPrimitive.Indicator, IndicatorStyles)
export const Label = makeComponent(SelectorLabel, LabelStyles)

export const RadioCardsRoot = makeComponent(RadioGroupPrimitive.Root, RadioCardsRootStyles)
export const RadioCard = makeComponent(RadioGroupPrimitive.Item, RadioCardStyles)
export const RadioCardIcon = makeComponent(Icon, RadioCardsIconStyles)
export const RadioCardIconTooltip = makeComponent(Tooltip, RadioCardsIconWrapperStyles)
export const RadioCardIconWrapper = makeComponent(Box, RadioCardsIconWrapperStyles)
export const RadioCardLabel = makeComponent(Box, RadioCardsLabelStyles)

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import {
    OnboardingButtonHeadingStyle,
    OnboardingButtonImageStyle,
    OnboardingButtonStyle,
    OnboardingButtonSubheadingStyle,
    OnboardingButtonTextContainerStyle,
    OnboardingInternalContainerStyle,
} from './OnboardingButtonStyles.css'

type ButtonContext = {
    image: string
    heading: string
    subheading?: string
    onClick: () => void
}

type OnboardingButtonsProps = {
    buttonsContext: ButtonContext[]
}

export function OnboardingButtons({ buttonsContext }: OnboardingButtonsProps) {
    return (
        <Box flex column gap="m">
            {buttonsContext.map((button, index) => (
                <Button key={index} className={OnboardingButtonStyle} onClick={button.onClick}>
                    <Box className={OnboardingInternalContainerStyle}>
                        <Box className={OnboardingButtonImageStyle}>
                            <img src={button.image} alt={button.heading} />
                        </Box>
                        <Box className={OnboardingButtonTextContainerStyle}>
                            <Box className={OnboardingButtonHeadingStyle}>{button.heading}</Box>
                            {button.subheading && (
                                <Box className={OnboardingButtonSubheadingStyle}>
                                    {button.subheading}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Button>
            ))}
        </Box>
    )
}

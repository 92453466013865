import React from 'react'

import { useStacks } from 'data/hooks/stacks'
import { StackIconBadge } from 'features/core/StackIconBadge'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { StandardComponentProps } from 'ui/helpers/styles'

import { FeedFilter } from './FeedFilter'

import { SelectedFilterItemIcon } from './SelectedFilterItemIcon.css'

type StacksFilterProps = StandardComponentProps & {
    selectedStackIds?: string[]
    setSelectedStackIds: (stackIds: string[]) => void
}

export function StacksFilter({
    selectedStackIds,
    setSelectedStackIds,
    ...props
}: StacksFilterProps) {
    const { data: stacks } = useStacks()

    const effectiveStackIds = !selectedStackIds?.length
        ? (stacks?.map((stack) => stack._sid) ?? [])
        : selectedStackIds

    if (!stacks) return null
    return (
        <FeedFilter
            items={stacks}
            idField="_sid"
            selectedIds={effectiveStackIds}
            setSelectedIds={setSelectedStackIds}
            renderItem={(stack) => (
                <>
                    <StackIconBadge stack={stack} size={24} mr="m" />
                    {stack.name}
                </>
            )}
            renderContent={(items) => (
                <>
                    <Box flex flexDirection="row-reverse" mr="xs">
                        {items
                            .slice(0, 10)
                            .reverse()
                            .map((stack, index) => (
                                <SelectedStackIcon
                                    key={stack._sid}
                                    stack={stack}
                                    className={index > 0 ? SelectedFilterItemIcon : undefined}
                                    style={{
                                        zIndex: index,
                                    }}
                                />
                            ))}
                    </Box>
                    {items.length === 1
                        ? items[0].name
                        : items.length === stacks.length
                          ? 'all apps'
                          : `${items.length} apps`}
                </>
            )}
            {...props}
        />
    )
}

type SelectedStackIconProps = BoxProps & {
    stack: StackDto
}

function SelectedStackIcon({ stack, ...props }: SelectedStackIconProps) {
    return <StackIconBadge stack={stack} size={24} {...props} />
}

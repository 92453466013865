import React from 'react'

import { useWorkflowManagerContext } from 'features/workflows/useWorkflowManagerContext'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { useAddNewConnectionModal } from './useAddNewConnectionModal'

type IntegrationsHeaderProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const IntegrationsHeader: React.FC<IntegrationsHeaderProps> = ({ ...props }) => {
    const { navigateToPane } = useWorkflowManagerContext()
    const addNewConnection = useAddNewConnectionModal()

    return (
        <Box height="full" flex center justifyContent="space-between" {...props}>
            <Button
                type="button"
                variant="ghost"
                startIcon={{ name: 'ArrowLeft' }}
                onClick={() => navigateToPane('list')}
                size="s"
            >
                Workflows
            </Button>
            <Box as="h4" fontSize="headlineXs" flex center>
                Connections
            </Box>
            <Button type="button" startIcon={{ name: 'Plus' }} size="s" onClick={addNewConnection}>
                New connection
            </Button>
        </Box>
    )
}

import React from 'react'

import { Thumbnail } from 'features/views/attributes/Thumbnail'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'

import { Attribute } from './attributes/Attribute'
import { CardViewAttribute } from './types'

import { CardCoverImageStyle } from './CardView.css'

type CardViewCoverImageProps = React.ComponentPropsWithoutRef<typeof Box> & {
    src?: string
    isLoading?: boolean
    placeholderAttribute?: CardViewAttribute
    placeholderValue?: unknown
}

export const CardViewCoverImage: React.FC<CardViewCoverImageProps> = ({
    src,
    isLoading,
    placeholderAttribute,
    placeholderValue,
    ...props
}) => {
    return (
        <Box height="full" width="full" {...props}>
            <Skeleton noShrink isLoading={isLoading} display="block" width="full" height="full">
                <Thumbnail
                    src={src}
                    placeholder={
                        placeholderAttribute ? (
                            <Attribute
                                attribute={placeholderAttribute}
                                isLoading={isLoading}
                                displayStyle="list"
                                value={placeholderValue}
                                style={{
                                    color: 'inherit',
                                }}
                            />
                        ) : (
                            placeholderValue
                        )
                    }
                    size="manual"
                    className={CardCoverImageStyle}
                    alt="Record cover image"
                />
            </Skeleton>
        </Box>
    )
}

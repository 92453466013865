import { useCallback } from 'react'

import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'

export function useRestoreSelectedRecords(restoreRecords?: (recordIds: string[]) => Promise<void>) {
    const { gridApi, canRestoreRecords } = useDataGridContext()

    return useCallback(() => {
        if (!gridApi || !canRestoreRecords) return

        const selectedRecords = gridApi.getSelectedRows()
        if (selectedRecords.length < 1) return

        const recordIds = selectedRecords.reduce(
            (ids, record) => ids.add(record._sid),
            new Set<string>()
        )

        return restoreRecords?.(Array.from(recordIds))
    }, [canRestoreRecords, restoreRecords, gridApi])
}

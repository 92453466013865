import React from 'react'

import { isEmpty } from 'lodash'
import memoize from 'lodash/memoize'

export var groupBy = function (xs, key) {
    if (typeof xs !== 'object') return {}
    if (typeof xs.reduce !== 'function') return {}
    return xs.reduce(function (rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
    }, {})
}
// => {3: ["one", "two"], 5: ["three"]}

export var unique = function (a) {
    return a.sort().filter(function (item, pos, ary) {
        return !pos || item !== ary[pos - 1]
    })
}

const map = new WeakMap()
export const memoizeTwoArgs = (fn) =>
    memoize(fn, (a, b) => {
        if (typeof a === 'undefined' || typeof b === 'undefined') {
            return Math.random() + 1
        }
        if (!map.has(a)) map.set(a, Math.random())
        if (!map.has(b)) map.set(b, Math.random())

        return map.get(a) + '_' + map.get(b)
    })

/** Reorder an array moving an item from its current position
 * (startIndex) to a new position (endIndex)
 */
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

/**A URL should be treated as internal if it matches one of the following:
 - //*hostname*
 - http://*hostname*
 - https://*hostname*
 - starts with / and not //

 See https://www.notion.so/stacker/Link-URL-Handling-Rules-412869fbcbe54de49edc9c1ff894c18b
 */

export const isExternal = (url) => {
    var host = window.location.hostname

    if (!url) return false

    return !(
        url.startsWith(`http://${host}`) ||
        url.startsWith(`https://${host}`) ||
        url.startsWith(`//${host}`) ||
        /^\/[^\/]/.test(url)
    )
}

/**
 * Builds the param for after-login redirect that you can use directly in URLs.
 */
export function getRedirectParam() {
    const { pathname, search, hash } = window.location
    const path = `${pathname}${search}${hash}`
    if (path === '/') return undefined
    return encodeURIComponent(path)
}

/** Checks whether a value is blank/empty. Usefull
 *  for checking required fields. The following values
 *  are considered blank:
 *
 * - undefined, null, false, '', Nan,
 * - arrays with no items
 */
export const isBlank = (checkedValue, field) => {
    let value = checkedValue
    if (field.type === 'document' && !!checkedValue) {
        value = checkedValue.plainTextContent
    }

    // the only falsey value that isn't counted as blank is 0
    return (!value && value !== 0) || (Array.isArray(value) && value.length === 0)
}

export const hash = (s) => s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)

/**
 * Decodes a utf8 string which has been encoded in hex format
 * @param {*} str1 a utf8 string encoded in hex
 * @returns the decoded string
 */
export function hexToString(str1) {
    return str1 ? decodeURIComponent(str1.replace(/\s+/g, '').replace(/[0-9a-f]{2}/g, '%$&')) : str1
}

/**
 * Wraps a component with span if it's of type string.
 * This method is used in preventing translation issues.
 * @param {*} component
 * @returns component
 */
export function wrapStringComponentWithSpan(component, props = {}) {
    if (typeof component === 'string' && component.length > 0) {
        component = (
            <span {...props} className="stk-button-text">
                {component}
            </span>
        )
    }
    return component
}

export class UserCancelledError extends Error {}

export function ensureArray(value) {
    return Array.isArray(value) ? value : value ? [value] : []
}

export const isFreeEmail = (email) => {
    const freeDomains = [
        'gmail',
        'yahoo',
        'aol',
        'hotmail',
        'outlook',
        'mail',
        'protonmail',
        'icloud',
        'live',
    ]
    return freeDomains.some((domain) => email.indexOf(`@${domain}.`) >= 0)
}

export class DeferredPromise {
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject
            this.resolve = resolve
        })
    }
}

export function isEmptyDeep(value) {
    return isEmpty(value) || (typeof value === 'object' && Object.values(value).every(isEmptyDeep))
}

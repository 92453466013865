// @ts-strict-ignore
import React, { useCallback, useRef } from 'react'

import ListViewAdditionalActions from 'v2/views/List/ListViewAdditionalActions'
import ListViewControlsV2 from 'v2/views/List/ListViewControls'

import { UnsavedChangesModal } from 'app/UnsavedChangesModal'
import { ViewEditPane } from 'features/views/ViewEditPane'

import { Box } from 'ui/components/Box'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { Body } from 'ui/components/Text'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { ActionControls } from './Actions/ActionControls'
import { BoardViewCardFooterControls } from './BoardView/BoardViewCardFooterControls'
import { BoardViewCardHeaderControls } from './BoardView/BoardViewCardHeaderControls'
import { CardViewCardFooterControls } from './CardView/CardViewCardFooterControls'
import { CardViewCardHeaderControls } from './CardView/CardViewCardHeaderControls'
import { InlineFiltersControls } from './Filters/InlineFiltersControls'
import { useListViewControlsState } from './hooks/useListViewControlsState'
import { ListHeaderControls } from './ListHeader/ListHeaderControls'
import { ListViewControlItem } from './ListViewControlItem'
import { getSupportedThumbnailFields } from './utils'

type ListViewControlsProps = {}

export const ListViewControls: React.FC<ListViewControlsProps> = () => {
    const {
        view,
        actions,
        commitChanges,
        discardChanges,
        hasPendingChanges,
        updateView,
        openDuplicateModal,
        object,
        columnConfigs,
        updateViewOptions,
        pageRoles,
        updatePageRoles,
        allFields,
        getPageSizeOptions,
        getDefaultPageSize,
        onDisplayChange,
    } = useListViewControlsState()

    return (
        <ThemeProvider theme="admin">
            <ViewEditPane
                viewName={view.name}
                view={view}
                saveView={commitChanges}
                setViewData={updateView}
                isConfigDirty={hasPendingChanges}
                actions={actions}
                additionalActions={
                    <ListViewAdditionalActions openDuplicateModal={openDuplicateModal} />
                }
            >
                <ListViewControlsV2
                    onDisplayChange={onDisplayChange}
                    object={object}
                    columnConfig={columnConfigs}
                    config={view.options}
                    setConfig={updateViewOptions}
                    pageRoles={pageRoles}
                    setPageRoles={updatePageRoles}
                    getPageSizeOptions={getPageSizeOptions}
                    getDefaultPageSize={getDefaultPageSize}
                    showPageSizeOptions={true}
                    additionalAppearanceToggles={
                        <AppearanceControls
                            config={view.options}
                            setConfig={updateViewOptions}
                            fields={allFields}
                            objectSid={object?._sid}
                        />
                    }
                    additionalFilteringToggles={
                        <FilteringAndSortingControls
                            setConfig={updateViewOptions}
                            config={view.options}
                        />
                    }
                    customActionsEditor={<ActionControls />}
                    hideHeader
                    hideCoverImageOptions
                    hideEndUserFiltersOptions
                />
            </ViewEditPane>
            <UnsavedChangesModal
                isDirty={hasPendingChanges}
                onSave={commitChanges}
                revertChanges={discardChanges}
            />
        </ThemeProvider>
    )
}

type FilteringAndSortingControlsProps = {
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
}

const FilteringAndSortingControls: React.FC<FilteringAndSortingControlsProps> = ({
    setConfig,
    config,
}) => {
    const inlineSortingValue = config.inlineSorting ?? ''
    const handleInlineSortingChange = useCallback(
        (value: string) => {
            const newValue = value === '' ? undefined : (value as ListViewInlineSorting)

            setConfig({
                inlineSorting: newValue,
            })
        },
        [setConfig]
    )

    return (
        <>
            <InlineFiltersControls config={config} setConfig={setConfig} />
            <ListViewControlItem label="Inline sorting" icon="ArrowUpWideNarrow">
                <Box maxWidth="full" minWidth="300px" flex flexDirection="column" gap="m">
                    <Box role="group">
                        <RadioCardGroup
                            value={inlineSortingValue}
                            onValueChange={handleInlineSortingChange}
                        >
                            <RadioCard value="" icon={{ name: 'EyeOff' }}>
                                Disabled
                            </RadioCard>
                            <RadioCard value="quick" icon={{ name: 'PanelTopOpen' }}>
                                Enabled
                            </RadioCard>
                        </RadioCardGroup>
                    </Box>
                </Box>
            </ListViewControlItem>
        </>
    )
}

type AppearanceControlsProps = {
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
    objectSid: string
}

const AppearanceControls: React.FC<AppearanceControlsProps> = ({
    setConfig,
    config,
    fields,
    objectSid,
}) => {
    return (
        <>
            <ListHeaderControls setConfig={setConfig} config={config} />
            {config.display === 'tableV2' && (
                <ThumbnailControls config={config} setConfig={setConfig} fields={fields} />
            )}
            {config.display === 'boardV2' && (
                <>
                    <BoardViewCardHeaderControls
                        objectSid={objectSid}
                        config={config}
                        setConfig={setConfig}
                        fields={fields}
                    />
                    <BoardViewCardFooterControls
                        objectSid={objectSid}
                        config={config}
                        setConfig={setConfig}
                        fields={fields}
                    />
                </>
            )}
            {config.display === 'cardV2' && (
                <>
                    <CardViewCardHeaderControls
                        objectSid={objectSid}
                        config={config}
                        setConfig={setConfig}
                        fields={fields}
                    />
                    <CardViewCardFooterControls
                        config={config}
                        setConfig={setConfig}
                        fields={fields}
                        objectSid={objectSid}
                    />
                </>
            )}
        </>
    )
}

type ThumbnailControlsProps = React.FC<Omit<AppearanceControlsProps, 'objectSid'>>

const ThumbnailControls: ThumbnailControlsProps = ({ config, setConfig, fields }) => {
    const supportedFields = getSupportedThumbnailFields(fields)

    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    let fieldApiName = config.coverImage?.id
    // If the field is not in the list of fields, reset the fieldApiName.
    if (!supportedFields.find((field) => field.api_name === fieldApiName)) {
        fieldApiName = undefined
    }

    const onChangeFieldApiName = useCallback(
        (value: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['coverImage'] = value
                ? {
                      ...existingConfig.coverImage,
                      id: value,
                  }
                : undefined

            setConfig({
                coverImage: newValue,
            })
        },
        [setConfig]
    )

    const aspectRatio = config.coverImage?.aspectRatio ?? '1:1'

    const onAspectRatioChange = useCallback(
        (value: string) => {
            const existingConfig = existingConfigRef.current

            setConfig({
                coverImage: {
                    ...existingConfig.coverImage,
                    aspectRatio: value,
                } as ListViewOptions['coverImage'],
            })
        },
        [setConfig]
    )

    return (
        <ListViewControlItem label="Thumbnail" icon="Image">
            <Box maxWidth="full" minWidth="300px" flex flexDirection="column" gap="l">
                <Select
                    placeholder="Select field..."
                    label="Image"
                    value={fieldApiName}
                    onChange={onChangeFieldApiName}
                    isClearable
                    isSearchable
                >
                    {supportedFields.map((field) => (
                        <SelectOption key={field._sid} value={field.api_name} label={field.label} />
                    ))}
                </Select>
                {fieldApiName && (
                    <Box role="group" flex column gap="m">
                        <Body size="m" weight="medium">
                            Aspect ratio
                        </Body>
                        <RadioCardGroup value={aspectRatio} onValueChange={onAspectRatioChange}>
                            <RadioCard value="1:1" icon={{ name: 'Square' }}>
                                1:1
                            </RadioCard>
                            <RadioCard value="4:3" icon={{ name: 'RectangleHorizontal' }}>
                                4:3
                            </RadioCard>
                        </RadioCardGroup>
                    </Box>
                )}
            </Box>
        </ListViewControlItem>
    )
}

import { pxToRem } from 'ui/helpers/converters'
import { BaseVariablesTheme } from 'ui/styling/baseVariables/BaseVariablesTheme.css'

// Setup the base values
export function makeAppVariables(baseVariablesOverrides?: Partial<typeof BaseVariablesTheme>) {
    const s: typeof BaseVariablesTheme = { ...BaseVariablesTheme, ...baseVariablesOverrides }

    return {
        ...s,
        color: {
            ...s.color,
            surface: s.color.white00,
            surfaceDisabled: s.color.gray100,
            surfaceDisabledStrong: s.color.gray300,
            surfaceDisabledStronger: s.color.gray500,
            surfaceStrong: s.color.gray50,
            surfaceStronger: s.color.gray100,
            surfaceStrongest: s.color.gray200,
            surfaceTransparent: s.color.transparent,
            surfaceError: s.color.error600,
            surfaceErrorStrong: s.color.error700,
            surfaceErrorStronger: s.color.error800,
            surfaceSuccess: s.color.success600,
            surfaceSuccessStrong: s.color.success700,
            surfaceSuccessStronger: s.color.success800,
            surfaceWarning: s.color.warning600,
            surfaceWarningStrong: s.color.warning700,
            surfaceWarningStronger: s.color.warning800,
            surfaceInverse: s.color.gray900,

            surfaceTheme: s.color.theme600,
            surfaceThemeStrong: s.color.theme700,
            surfaceThemeStronger: s.color.theme800,

            surfaceSelectorOn: s.color.theme600,
            surfaceSelectorOnHover: s.color.theme800,
            surfaceSelectorOnFocus: s.color.theme600,
            surfaceSelectorOnDisabled: s.color.gray300,
            surfaceSelectorOnError: s.color.error600,
            surfaceSelectorOff: s.color.white00,
            surfaceSelectorOffDisabled: s.color.gray100,

            text: s.color.gray900,
            textWeak: s.color.gray700,
            textWeaker: s.color.gray500,
            textWeakest: s.color.gray400,
            textInverse: s.color.white00,
            textDisabled: s.color.gray300,
            textError: s.color.error600,
            textInput: s.color.gray900,
            textLabel: s.color.gray700,
            textLabelDisabled: s.color.gray400,
            textHelper: s.color.gray500,
            textTheme: s.color.theme600,

            border: s.color.gray300,
            borderStrong: s.color.gray400,
            borderWeak: s.color.gray200,
            borderDisabled: s.color.gray200,
            borderFocus: s.color.theme600,
            borderError: s.color.error600,

            borderTheme: s.color.theme700,
            borderThemeStrong: s.color.theme800,
            borderThemeWeak: s.color.theme600,
            borderInverse: s.color.white00,

            borderSelectorOff: s.color.gray300,
            borderSelectorOffError: s.color.error600,
            borderSelectorOffHover: s.color.gray400,
            borderSelectorOn: s.color.theme600,
            borderSelectorOnError: s.color.error600,
            borderSelectorOnHover: s.color.theme800,

            icon: s.color.gray500,
            iconError: s.color.error600,
            iconTheme: s.color.theme600,
            iconThemeStrong: s.color.theme700,
            iconHover: s.color.gray900,
            iconActive: s.color.gray900,
            iconInverse: s.color.white00,
            iconSelectorHover: s.color.gray200,
            iconSelector: s.color.white00,
            iconDisabled: s.color.gray300,
            iconSuccess: s.color.success600,
            linkText: s.color.theme600,

            divider: s.color.gray300,
            dividerWeak: s.color.gray200,

            blanket: s.color.darkAlpha500,
        },
        // Shadows
        shadows: {
            0: 'none',
            xs: `${pxToRem(0)} ${pxToRem(1)} ${pxToRem(1)} ${s.color.darkAlpha100}, ${pxToRem(
                0
            )} ${pxToRem(0)} ${pxToRem(1)} ${s.color.darkAlpha50};`,
            s: `${pxToRem(0)} ${pxToRem(2)} ${pxToRem(4)} ${s.color.darkAlpha100}, ${pxToRem(
                0
            )} ${pxToRem(0)} ${pxToRem(1)} ${s.color.darkAlpha50};`,
            m: `${pxToRem(0)} ${pxToRem(8)} ${pxToRem(24)} ${s.color.darkAlpha100}, ${pxToRem(
                0
            )} ${pxToRem(1)} ${pxToRem(3)} ${s.color.darkAlpha100};`,
            l: `${pxToRem(0)} ${pxToRem(34)} ${pxToRem(54)} ${pxToRem(-26)} ${
                s.color.darkAlpha100
            }, ${pxToRem(0)} ${pxToRem(9)} ${pxToRem(24)} ${pxToRem(-8)} ${s.color.darkAlpha200};`,
            xl: `${pxToRem(0)} ${pxToRem(18)} ${pxToRem(36)} ${pxToRem(-5)} ${
                s.color.darkAlpha100
            }, ${pxToRem(0)} ${pxToRem(26)} ${pxToRem(41)} ${pxToRem(-23)} ${
                s.color.darkAlpha200
            };`,
            focus: `${pxToRem(0)} ${pxToRem(0)} ${pxToRem(0)} ${pxToRem(2)}  ${s.color.theme300}`,
        },
    }
}

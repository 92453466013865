import React from 'react'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'

import { IntegrationsHeader } from './Integrations/IntegrationsHeader'
import { IntegrationsList } from './Integrations/IntegrationsList'
import { useWorkflowManagerContext } from './useWorkflowManagerContext'
import { WorkflowList } from './WorkflowList'
import { WorkflowListHeader } from './WorkflowListHeader'

type WorkflowsViewProps = React.ComponentPropsWithoutRef<typeof Box> & {
    workflows: WorkflowDto[]
    onSelectWorkflow: (workflow: WorkflowDto) => void
    onCreateClick: () => void
    openFlowExecutionHistory: (workflow: WorkflowDto) => void
}

export const WorkflowsView: React.FC<WorkflowsViewProps> = ({
    workflows,
    onSelectWorkflow,
    onCreateClick,
    openFlowExecutionHistory,
    ...props
}) => {
    const { currentPane } = useWorkflowManagerContext()

    return (
        <Box {...props}>
            <Box flex column stretch>
                <Box width="full" p="m" style={{ height: '50px' }}>
                    {currentPane === 'list' && <WorkflowListHeader onCreateClick={onCreateClick} />}

                    {currentPane === 'integrations' && <IntegrationsHeader />}
                </Box>
                <Divider />
            </Box>
            <Box grow>
                {currentPane === 'list' && (
                    <WorkflowList
                        workflows={workflows}
                        onSelect={onSelectWorkflow}
                        openFlowExecutionHistory={openFlowExecutionHistory}
                    />
                )}
                {currentPane === 'integrations' && <IntegrationsList />}
            </Box>
        </Box>
    )
}

import { useMemo } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'

import { useActionButtonTriggerFinalSchema } from './useActionButtonTriggerFinalSchema'
import { useRecordChangeTriggerFinalSchema } from './useRecordChangeTriggerFinalSchema'
import { useWebhookTriggerFinalSchema } from './useWebhookTriggerFinalSchema'

export function usePreparedTriggerSchema(
    trigger: WorkflowTriggerConfig
): WorkflowSchemaTriggerType | undefined {
    const getRecordChangeTriggerSchema = useRecordChangeTriggerFinalSchema()
    const getWebhookTriggerSchema = useWebhookTriggerFinalSchema()
    const getActionButtonTriggerSchema = useActionButtonTriggerFinalSchema()
    const { data: schema } = useWorkflowSchema()
    return useMemo(() => {
        if (!schema) return undefined

        const triggerProcessors: Record<
            string,
            (trigger: WorkflowTriggerConfig) => WorkflowSchemaTriggerType | undefined
        > = {
            record_created: getRecordChangeTriggerSchema,
            record_updated: getRecordChangeTriggerSchema,
            record_created_or_updated: getRecordChangeTriggerSchema,
            webhook: getWebhookTriggerSchema,
            action_button_clicked: getActionButtonTriggerSchema,
        }

        const triggerSchema = schema.triggers.find((t) => t.id === trigger.trigger_type)
        const result = triggerProcessors[trigger.trigger_type]?.(trigger) ?? triggerSchema

        return result
    }, [
        schema,
        getRecordChangeTriggerSchema,
        getWebhookTriggerSchema,
        getActionButtonTriggerSchema,
        trigger,
    ])
}

import { Editor } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'

export type CloseEvent = {
    type: 'close'
    payload: {
        activityCount: number
        targetLocation: string
        editor?: Editor
        keepTargetMark?: boolean
    }
}

export type ChangeCommentCountsEvent = {
    type: 'changeCommentCounts'
    payload: {
        commentsByLocation: Record<string, number>
        editor?: Editor
    }
}

export type Events = CloseEvent | ChangeCommentCountsEvent

type EventPayloadMap = {
    [T in Events as T['type']]: T['payload']
}

export type ListenerCallback<T extends keyof EventPayloadMap> = (
    payload: EventPayloadMap[T]
) => void

type ListenersMap = {
    [T in keyof EventPayloadMap]: ListenerCallback<T>[]
}

export type ActivityBubblePluginProps = {
    setTargetElement: (element: HTMLElement | null) => void
    setTargetMarkId: (markId: string | undefined) => void
    setLocationPreviewContent: (content: string | undefined) => void
    open: () => void
    close: () => void
}

/**
 * This plugin acts as a bridge between the [ActivityMarkExtension](ActivityMarkExtension.ts) and the [ActivityBubble](ActivityBubble.tsx) component.
 * It allows the component to render what the mark extension decides, and it allows the mark extension to respond to component updates.
 *  */
export class ActivityBubblePlugin extends Plugin {
    public readonly viewProps: ActivityBubblePluginProps

    constructor(viewProps: ActivityBubblePluginProps) {
        super({
            key: new PluginKey('activityBubble'),
        })

        this.viewProps = viewProps
    }

    protected listeners: ListenersMap = {
        close: [],
        changeCommentCounts: [],
    }

    on<T extends keyof EventPayloadMap>(event: T, handler: ListenerCallback<T>) {
        const listeners = this.listeners[event] as ListenerCallback<T>[]
        listeners.push(handler)
    }

    off<T extends keyof EventPayloadMap>(event: T, handler: ListenerCallback<T>) {
        const listeners = this.listeners[event] as ListenerCallback<T>[]
        const newListeners = listeners.filter((listener) => listener !== handler)

        this.listeners[event] = newListeners as ListenerCallback<keyof EventPayloadMap>[]
    }

    emit<T extends keyof EventPayloadMap>(event: T, payload: EventPayloadMap[T]) {
        const listeners = this.listeners[event] as ListenerCallback<T>[]
        for (const listener of listeners) {
            queueMicrotask(() => {
                listener(payload)
            })
        }
    }
}

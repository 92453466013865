import * as React from 'react'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { invalidateAppUsersList } from 'data/hooks/users/invalidateAppUsersList'
import { useUpdateUser } from 'data/hooks/users/main'
import { Divider, FormFrame } from 'features/workspace/WorkspaceSettingsModalUi'

import { Avatar, Button, Flex } from 'v2/ui'
import ReactFilestack from 'v2/ui/ReactFilestack'

import Form from 'ui/deprecated/forms/Form'
import InputForm from 'ui/deprecated/forms/InputForm'

import { Label, NonEditable } from './ProfileSettingsCommonComponents'

// not part of the designs, just a placeholder to show the user's email - later we'll make it editable
const EmailDisplay = ({ user }) => {
    return <NonEditable>{user.email}</NonEditable>
}

const UploadAvatar = ({ image, onChange, ...props }) => {
    return (
        <Flex {...props}>
            <Avatar src={image} size="lg" mr={4} />
            <ReactFilestack
                options={{
                    accept: 'image/*',
                }}
                onSuccess={(value) => {
                    if (onChange) {
                        onChange(value.filesUploaded[0].url)
                    }
                }}
                customRender={({ onPick }) => (
                    <Button
                        variant="moderateSm"
                        padding="small"
                        icon={image ? 'edit' : 'add'}
                        onClick={onPick}
                    >
                        {image ? 'Change' : 'Upload'}
                    </Button>
                )}
            />

            {image && (
                <Button
                    ml={2}
                    variant="moderateSm"
                    padding="small"
                    icon="trash"
                    onClick={() => onChange(null)}
                >
                    Remove
                </Button>
            )}
        </Flex>
    )
}

export default function ProfileSettingsModalGeneralSettings({ formRef, children }) {
    const { user } = useAuthContext()
    const { mutateAsync: updateUser } = useUpdateUser()

    const onSubmit = (data) =>
        updateUser({
            id: user._sid,
            patch: {
                name: data.name,
            },
        })

    // avatar photo is not part of the form, it just saves inline when you change it
    const handleChangePhoto = (photo) => {
        updateUser({
            id: user._sid,
            patch: {
                options: {
                    ...user.options,
                    photo,
                },
            },
        })
    }

    // Refetch the user list when profile info is updated
    // Re-render activity with the correct name/avatar
    invalidateAppUsersList()

    const defaultValues = {
        name: user.name,
    }

    return (
        <Form
            onSubmit={onSubmit}
            options={{
                defaultValues,
            }}
            style={{ flex: 1 }}
            resetOnSuccess
        >
            <FormFrame title="General" formRef={formRef}>
                <Divider />
                <InputForm
                    name="name"
                    label="Name"
                    placeholder="Your Name"
                    variant="authentication"
                    required
                />
                <div style={{ height: 24 }} />

                <Label text="Email Address" />
                <EmailDisplay user={user} />

                <div style={{ height: 24 }} />

                <Label text="Avatar" marginBottom="8px" />
                <UploadAvatar image={user.avatar} onChange={handleChangePhoto} mb={4} />
            </FormFrame>
            {children}
        </Form>
    )
}

import React from 'react'

import { ZoneLogo } from 'features/workspace/zones/ZoneLogo'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'

type Props = {
    zone: AccountZone
    children: React.ReactNode
    title?: string | null
}
export function WorkspaceLoginFrame({ zone, children, title = 'Sign in' }: Props) {
    return (
        <Box fullWidth height="100dvh" flex column center justifyContent="center">
            <Box
                p={{ mobile: 'xl', tablet: '7xl' }}
                borderStyle="base"
                borderWidth={{ mobile: 0, tablet: 'base' }}
                boxShadow={{ tablet: 'xl' }}
                minWidth={{ mobile: 'full', tablet: '600px' }}
                width={{ mobile: 'full', tablet: '600px' }}
                rounded="4xl"
                flex
                column
                stretch
            >
                <ZoneLogo zone={zone} alignSelf="center" maxWidth="200px" maxHeight="100px" />
                {title ? (
                    <Headline size="l" textAlign="center" my="5xl">
                        {title}
                    </Headline>
                ) : (
                    <Box height="5xl" />
                )}
                {children}
            </Box>
        </Box>
    )
}

const attachmentKeys = ['filename', 'id', 'url']

export function isRecordAttachment(obj: any): obj is AttachmentDto {
    if (typeof obj !== 'object' || !obj) return false

    for (const k of attachmentKeys) {
        if (!Object.hasOwnProperty.call(obj, k)) return false
    }

    return true
}

export function isRecordAttachmentArray(obj: any): obj is AttachmentDto[] {
    if (!Array.isArray(obj)) return false
    if (obj.length < 1) return false

    return isRecordAttachment(obj[0])
}

export function isRecordAttachmentEqual(
    attachmentA: AttachmentDto,
    attachmentB: AttachmentDto
): boolean {
    return attachmentA.url === attachmentB.url
}

function attachmentsArrayToMap(attachments: AttachmentDto[]): Map<string, AttachmentDto> {
    return attachments.reduce((agg, curr) => {
        if (isRecordAttachment(curr)) {
            agg.set(curr.id, curr)
        }

        return agg
    }, new Map())
}

export function isRecordAttachmentArrayEqual(
    valueA: AttachmentDto[],
    valueB: AttachmentDto[]
): boolean {
    const attachmentsA = attachmentsArrayToMap(valueA)
    const attachmentsB = attachmentsArrayToMap(valueB)

    if (attachmentsA.size !== attachmentsB.size) return false

    for (const oldAttachment of valueA) {
        const newAttachment = attachmentsB.get(oldAttachment.id)
        if (!newAttachment || !isRecordAttachmentEqual(oldAttachment, newAttachment)) {
            return false
        }
    }

    return true
}

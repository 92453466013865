import { getCurrentStack, GlobalStaticState } from 'app/GlobalStaticState'
import { getCachedFields } from 'data/hooks/fields'
import { getCurrentUserRecord, getUserProfileRecord } from 'data/hooks/users/getUserRecords'
import { getLinksFromObject } from 'data/utils/getLinks'

/** Returns the current authenticated user id,
 * or if in a workspace app, the current stacker user's cache record id
 */

export function getCurrentUserRecordId(): string | undefined {
    const previewingAsUser = GlobalStaticState.getPreviewingAsUser()
    const user = GlobalStaticState.getUser()

    const currentUser = previewingAsUser || user

    return currentUser?._sid
}

export function getCurrentUserProfileRecordId(userLinkLookupTarget: string): any {
    let record_id = getCurrentUserRecordId()
    // If we don't have a record_id it means we are logged in as
    // and admin in V3
    if (!record_id) return null

    const stack = getCurrentStack()
    const userLinks = getLinksFromObject(
        getCachedFields(),
        stack?.options?.data_mapping?.user_object
    )

    // Iterate over the user links (user profiles) in search of target object of the field. The current user record
    // will contain the key-value pairs of the user profiles record entries
    // All the current user profiles records are cached in the store and can be retrieved at any time
    const field = userLinks?.find((rec) => rec.target.objectId === userLinkLookupTarget)

    const currentUserRecord = getCurrentUserRecord()
    return currentUserRecord?.[(field as any)?.apiName]
}

export function getCurrentUserRecordField(field: string): any {
    let record_id = getCurrentUserRecordId()
    // If we don't have a record_id it means we are logged in as
    // and admin in V3
    if (!record_id) return null

    const userRecord = getCurrentUserRecord()

    // If the field we are retrieving the record value for, is on the current user record, return
    // immediately
    if (userRecord?.[field]) return userRecord?.[field]

    let currentUserRecordField = null

    const stack = getCurrentStack()
    const userLinks = getLinksFromObject(
        getCachedFields(),
        stack?.options?.data_mapping?.user_object
    )
    // Iterate over the user links (user profiles) in search of the field. The current user record
    // will contain the key-value pairs of the user profiles record entries
    // All the current user profiles records are cached in the store and can be retrieved at any time
    userLinks?.forEach((rec) => {
        const record = userRecord?.[(rec as any).apiName]
            ? getUserProfileRecord(userRecord?.[(rec as any).apiName])
            : null
        if ((record as any)?.[field]) currentUserRecordField = (record as any)?.[field]
    })

    return currentUserRecordField
}

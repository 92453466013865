import React from 'react'

import { Thumbnail } from 'features/views/attributes/Thumbnail'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'

import { useTableViewThumbnailState } from './hooks/useTableViewThumbnailState'

type TableViewThumbnailProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const TableViewThumbnail: React.FC<TableViewThumbnailProps> = ({ ...props }) => {
    const { hasThumbnail, aspectRatio, imageSrc, isLoading } = useTableViewThumbnailState()

    if (!hasThumbnail) return null

    return (
        <Box {...props}>
            <Skeleton isLoading={isLoading} display="block">
                <Thumbnail
                    aspectRatio={aspectRatio}
                    src={imageSrc}
                    size={{ mobile: 's', tablet: 'm' }}
                    alt="Record thumbnail"
                />
            </Skeleton>
        </Box>
    )
}

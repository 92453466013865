import { useMemo } from 'react'

import { keywordMap } from 'features/formulas/constants/formulaEditorTextConstants'
import { Keyword } from 'features/formulas/formulaTypes'
import { getAllAvailableFunctionKeys } from 'features/formulas/parser/exclusiveFunctionFormulas'
import { matchesSearchTerms } from 'features/Search/helpers'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { ContextItemWithPath, DynamicContextGroup } from './types'
import { checkTypeCompatibility } from './utilities'

function functionToContextItem(func: Keyword & { id: string }): ContextItemWithPath {
    return {
        id: func.id,
        name: func.syntax,
        type: 'function',
        path: `functions.${func.name}`,
        description: func.helpText,
    }
}

function getFunctions() {
    return getAllAvailableFunctionKeys().map((key) => ({ ...keywordMap[key], id: key }))
}
export function useFormulaFunctionsGroup({
    returnType,
    returnTypeOptions: suppliedReturnTypeOptions,
}: {
    returnType?: FieldType
    returnTypeOptions?: FieldOptions
}): DynamicContextGroup {
    const returnTypeOptions = useDeepEqualsMemoValue(suppliedReturnTypeOptions)
    return useMemo(
        () => ({
            id: 'functions',
            name: `Formula function`,
            type: 'group',
            isDynamic: true,
            provideItems: ({ queryTerms }) =>
                Promise.resolve(
                    getFunctions()
                        .filter(
                            (x) =>
                                !x.recordFunction &&
                                checkTypeCompatibility(
                                    x.returnType,
                                    undefined,
                                    returnType,
                                    returnTypeOptions
                                ) &&
                                matchesSearchTerms(x.name, queryTerms ?? [])
                        )
                        .map(functionToContextItem)
                ),
            getItem: async (id: string) => {
                const func = getFunctions().find((x) => x.name === id)
                return Promise.resolve(func ? functionToContextItem(func) : undefined)
            },
        }),
        [returnType, returnTypeOptions]
    )
}

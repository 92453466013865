import { useMemo, useRef } from 'react'
import { useVirtual } from 'react-virtual'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { BoardViewCardValue } from './useBoardViewColumnRendererState'

type UseBoardViewColumnContentStateOptions = {
    values: BoardViewCardValue[]
}

export function useBoardViewColumnContentState(options: UseBoardViewColumnContentStateOptions) {
    const { values } = options

    const scrollAreaRef = useRef<HTMLDivElement>(null)

    const virtualizer = useVirtual({
        parentRef: scrollAreaRef,
        size: values.length,
        overscan: 10,
    })

    const items = virtualizer.virtualItems
    const itemsMemo = useDeepEqualsMemoValue(items)

    const totalSize = `${virtualizer.totalSize}px`
    const startSize = `${items[0]?.start ?? 0}px`

    return useMemo(
        () => ({ scrollAreaRef, items: itemsMemo, totalSize, startSize }),
        [itemsMemo, totalSize, startSize]
    )
}

import React from 'react'

import { RecordEditManagerContext } from './RecordEditManagerContext'

export function useRecordEditManagerContext() {
    const context = React.useContext(RecordEditManagerContext)
    if (!context) {
        throw new Error(
            'useRecordEditManagerContext must be used within a RecordEditManagerContextProvider'
        )
    }
    return context
}

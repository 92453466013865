import { useEffect } from 'react'

type UseCreateTaskModalStateOptions = {
    isOpen?: boolean
    onOpenChange?: (open: boolean) => void
}

export function useCreateTaskModalState(options: UseCreateTaskModalStateOptions) {
    const { isOpen, onOpenChange } = options

    useEffect(() => {
        if (isOpen) {
            // Prevent the modal from blocking pointer events on the body, so our nested dropdowns and modals still work.
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = ''
            }, 0)

            return () => clearTimeout(timer)
        } else {
            document.body.style.pointerEvents = 'auto'
        }
    }, [isOpen])

    const onInteractOutside = (e: Event) => {
        const el = e.target as HTMLElement | null
        if (!el) return

        if (el.closest('.ag-custom-component-popup')) {
            e.preventDefault()
        }
    }

    const onCreate = (shouldDraftNew: boolean) => {
        if (shouldDraftNew) return

        onOpenChange?.(false)
    }

    const onClose = () => {
        onOpenChange?.(false)
    }

    return { onInteractOutside, onCreate, onClose }
}

import React from 'react'

import { UnsavedChangesModal } from 'app/UnsavedChangesModal'
import { DetailView } from 'features/views/DetailView/DetailView'

import { Box } from 'ui/components/Box'

import { useLayoutEditorCanvasState } from './hooks/useLayoutEditorCanvasState'
import { LayoutEditorSchemaOutdated } from './LayoutEditorSchemaOutdated'

type LayoutEditorCanvasProps = {
    viewSid: string
}

/**
 * This component is responsible for rendering the editable contents of the view.
 */
export const LayoutEditorCanvas: React.FC<LayoutEditorCanvasProps> = React.memo(
    function LayoutEditorCanvas({ viewSid }) {
        const {
            isInitialized,
            viewType,
            onClick,
            isViewDirty,
            saveViewChanges,
            discardViewChanges,
            isSchemaOutdated,
        } = useLayoutEditorCanvasState({ viewSid })

        if (!isInitialized) return null
        if (isSchemaOutdated) return <LayoutEditorSchemaOutdated />

        let content: React.ReactNode = null
        if (viewType === 'detail') {
            content = <DetailView />
        }

        return (
            <>
                <Box width="full" height="full" onClick={onClick}>
                    {content}
                </Box>
                <UnsavedChangesModal
                    isDirty={isViewDirty}
                    onSave={saveViewChanges}
                    revertChanges={discardViewChanges}
                />
            </>
        )
    }
)

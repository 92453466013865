import React from 'react'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'

import { useAppUsers } from './hooks/useAppUsers'
import { useAppUsersDefaultPaneState } from './hooks/useAppUsersDefaultPaneState'
import { AppUsersDefaultPaneHeader } from './AppUsersDefaultPaneHeader'
import { AppUsersGroupList } from './AppUsersGroupList'
import { AppUsersUserList } from './AppUsersUserList'
import { AppRole } from './types'

type AppUsersDefaultPaneProps = {}

export const AppUsersDefaultPane: React.FC<AppUsersDefaultPaneProps> = () => {
    const { groups, isLoading, appRoles, noGroups, isError } = useAppUsersDefaultPaneState()

    return (
        <Box px="4xl" pb="3xl">
            <AppUsersDefaultPaneHeader mb="l" isLoading={isLoading} roles={appRoles} />
            <Box flex flexDirection="column" gap="4xl">
                <Box>
                    <SectionTitle
                        label="Groups"
                        count={groups.length}
                        isLoading={isLoading || isError}
                    />
                    {groups.length > 0 && !isError && (
                        <AppUsersGroupList groups={groups} isLoading={isLoading} roles={appRoles} />
                    )}
                    {noGroups && (
                        <Box py="m">
                            <Body color="textWeakest">There are no groups to display</Body>
                        </Box>
                    )}
                    {isError && (
                        <Box py="m">
                            <Body color="textError">There was a problem fetching groups</Body>
                        </Box>
                    )}
                </Box>
                <UsersSection roles={appRoles} />
            </Box>
        </Box>
    )
}

type SectionTitleProps = {
    label: string
    count?: number
    isLoading?: boolean
}

const SectionTitle: React.FC<SectionTitleProps> = ({ label, count = 0, isLoading }) => {
    return (
        <Box flex center gap="m" mb="xs">
            <Body size="l" weight="bold">
                {label}
            </Body>
            <Skeleton isLoading={isLoading}>
                <Tag size="2xs" type="outline" shade="light" color="Neutral" pointerEvents="none">
                    {count.toString()}
                </Tag>
            </Skeleton>
        </Box>
    )
}

const PAGE_SIZE = 24

type UsersSectionProps = {
    roles: AppRole[]
    isLoading?: boolean
}

const UsersSection: React.FC<UsersSectionProps> = ({ roles, isLoading }) => {
    const {
        users,
        totalUserCount = 0,
        isError,
        isFetchingSlow,
        pageIndex,
        setPageIndex,
        hasNoUsers,
        hasNoUsersMatchingSearch,
    } = useAppUsers({
        pageSize: PAGE_SIZE,
        grantType: 'individual',
    })

    const effectiveIsLoading = isLoading || isFetchingSlow

    return (
        <Box>
            <SectionTitle
                label="Users"
                count={totalUserCount}
                isLoading={effectiveIsLoading || isError}
            />
            {hasNoUsersMatchingSearch && (
                <Box py="m">
                    <Body color="textWeakest">No users found matching your search</Body>
                </Box>
            )}
            {hasNoUsers && (
                <Box py="m">
                    <Body color="textWeakest">There are no users to display</Body>
                </Box>
            )}
            {isError && (
                <Box py="m">
                    <Body color="textError">There was a problem fetching users</Body>
                </Box>
            )}
            {!hasNoUsersMatchingSearch && !hasNoUsers && !isError && !!users && (
                <AppUsersUserList
                    isLoading={effectiveIsLoading}
                    roles={roles}
                    showLastRowBorder={false}
                    pageSize={PAGE_SIZE}
                    users={users}
                    totalUserCount={totalUserCount}
                    pageIndex={pageIndex}
                    onChangePageIndex={setPageIndex}
                />
            )}
        </Box>
    )
}

import React from 'react'

import { Button, Flex, Text } from 'v2/ui'
import { EmptyBox } from 'v2/ui/svgs'

export const ManageDataEmptyState: React.FC<{
    hasAllTablesDeactivated: boolean
    showImportCsvTableButton: boolean
    showImportTableDataButton: boolean
    onAddTable: () => void
    onImportCsvTable: () => void
    onImportTableData: () => void
}> = ({
    hasAllTablesDeactivated,
    onAddTable,
    onImportCsvTable,
    onImportTableData,
    showImportCsvTableButton,
    showImportTableDataButton,
}) => (
    <Flex alignItems="center" justifyContent="center" column>
        <EmptyBox />
        <Text variant="heading" my={4}>
            {hasAllTablesDeactivated
                ? "You don't have any active tables"
                : "You don't have any tables yet"}
        </Text>
        <Flex direction="row">
            <Button
                mr="10px"
                width="200px"
                variant="Primary"
                onClick={onAddTable}
                p={2}
                fontSize="14px"
                data-testid="manage-data.add-table-button"
            >
                Add new table
            </Button>
            {showImportCsvTableButton && (
                <Button
                    width="200px"
                    variant="Secondary"
                    onClick={onImportCsvTable}
                    p={2}
                    mr="10px"
                    fontSize="14px"
                    data-testid="manage-data.import-csv-table-button"
                >
                    Import from CSV
                </Button>
            )}
            {showImportTableDataButton && (
                <Button
                    width="200px"
                    variant="Secondary"
                    onClick={onImportTableData}
                    p={2}
                    fontSize="14px"
                    data-testid="manage-data.import-table-data-button"
                >
                    Paste data from spreadsheet
                </Button>
            )}
        </Flex>
    </Flex>
)

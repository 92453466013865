import React from 'react'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { Widget } from './types'
import {
    LayoutEditorWidgetContext,
    useLayoutEditorWidgetContext,
} from './useLayoutEditorWidgetContext'

type LayoutEditorWidgetContextProviderProps = {
    widget: Widget
    isDragging?: boolean
}

export const LayoutEditorWidgetContextProvider: React.FC<
    LayoutEditorWidgetContextProviderProps
> = ({ widget, isDragging = false, children }) => {
    const { isDragging: parentIsDragging = false } = useLayoutEditorWidgetContext() || {}

    const effectiveIsDragging = isDragging || parentIsDragging

    const value = useDeepEqualsMemoValue({
        widget,
        isDragging: effectiveIsDragging,
    })

    return (
        <LayoutEditorWidgetContext.Provider value={value}>
            {children}
        </LayoutEditorWidgetContext.Provider>
    )
}

import { useCallback } from 'react'

import { useToast } from 'ui/components/Toast'

export function useCopyLink() {
    const toast = useToast()

    return useCallback(
        async (link: string) => {
            await navigator.clipboard.writeText(link)

            toast({
                startIcon: { name: 'CopyCheck' },
                title: 'Link copied to clipboard',
                type: 'default',
                showDismissButton: true,
            })
        },
        [toast]
    )
}

import React from 'react'

import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'
import { TextAttributeTag } from 'features/views/attributes/TextAttributeTag'

import { BaseAttribute } from './BaseAttribute'
import { MAX_LENGTH, MAX_LENGTH_TAG, MAX_LINES, MAX_OVERFLOW_LENGTH } from './constants'
import { CardViewAttributeComponent } from './types'

type TextAttributeProps = {}

export const TextAttribute: CardViewAttributeComponent<string, TextAttributeProps> = ({
    value,
    attribute,
    isLoading,
    displayStyle,
    ...props
}) => {
    const { field, type, rawOptions } = attribute

    const isEmpty = !isLoading && !value
    const isTitle = type === 'title'
    const isSubtitle = type === 'subtitle'
    const isHeader = isTitle || isSubtitle
    const isFooter = type === 'footer'

    let maxLength: number | undefined = MAX_LENGTH
    if (isHeader || isFooter) {
        maxLength = undefined
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {displayStyle === 'tags' ? (
                <TextAttributeTag
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    value={value}
                    field={field}
                    maxLines={MAX_LINES}
                    maxLength={MAX_LENGTH_TAG}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    treatAsUrl={rawOptions.treatAsUrl as boolean}
                    buttonTitle={rawOptions.buttonTitle as string}
                    displayAsImage={rawOptions.displayAsImage as boolean}
                    imageAltText={rawOptions.imageAltText as string}
                    openInNewTab={rawOptions.openInNewTab as boolean}
                />
            ) : (
                <TextAttributeDisplay
                    alwaysShowOverflowTooltip={isHeader}
                    style={{
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        letterSpacing: 'inherit',
                        fontWeight: 'inherit',
                        display: isFooter ? 'block' : undefined,
                        maxWidth: isFooter ? '100%' : undefined,
                    }}
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    value={value}
                    field={field}
                    maxLines={MAX_LINES}
                    maxLength={maxLength}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    trim={!isTitle && !isSubtitle}
                    treatAsUrl={rawOptions.treatAsUrl as boolean}
                    buttonTitle={rawOptions.buttonTitle as string}
                    displayAsImage={rawOptions.displayAsImage as boolean}
                    imageAltText={rawOptions.imageAltText as string}
                    openInNewTab={rawOptions.openInNewTab as boolean}
                />
            )}
        </BaseAttribute>
    )
}

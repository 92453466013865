import React, { forwardRef, useEffect, useRef } from 'react'

import { DropdownOption } from 'features/admin/fields/DropdownFieldConfiguration/types'
import { useColumnWidth } from 'features/datagrid/hooks/useColumnWidth'
import { useEditorValue } from 'features/datagrid/hooks/useEditorValue'
import { useTypeInCellToEdit } from 'features/datagrid/hooks/useTypeInCellToEdit'

import DropdownAttribute from 'v2/ui/components/Attribute/DropdownAttribute'
import { layouts, modes } from 'v2/ui/utils/attributeSettings'

import { Box } from 'ui/components/Box'

import { DataGridCellEditorComponent, DataGridCellRendererComponent } from './types'

import { DropdownCellStyle } from './DropdownCell.css'

type DropdownCellRendererProps = {}
export const DropdownCellRenderer: DataGridCellRendererComponent<DropdownCellRendererProps> = ({
    value,
    field,
}) => {
    if (!value) return null

    const isMulti = field.type === 'multi_select'

    return (
        <Box lineHeight="bodyS" height="full" flex center>
            <DropdownAttribute
                field={field}
                onChange={() => {}}
                mode={modes.readonly}
                isMulti={isMulti}
                controlStyle={{
                    border: '1px solid white',
                    '&:hover': {
                        border: '1px solid white',
                    },
                }}
                inDataGrid
                className={DropdownCellStyle}
                displaySingleValueAsTag={true}
                layout={layouts.inline}
                useOverflowList={false}
                shrinkTags={false}
                displayUnknownValues={true}
            >
                {value}
            </DropdownAttribute>
        </Box>
    )
}
type DropdownCellEditorProps = {}
export const DropdownCellEditor: DataGridCellEditorComponent<DropdownCellEditorProps> = forwardRef(
    ({ value, field, stopEditing, column }, ref) => {
        const isMulti = field.type === 'multi_select'

        const [newValue, setNewValue] = useEditorValue<DropdownOption | DropdownOption[]>(
            ref,
            value
        )

        useEffect(() => {
            const handler = (e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    queueMicrotask(() => {
                        stopEditing()
                    })
                }
            }
            document.addEventListener('keydown', handler)
            return () => document.removeEventListener('keydown', handler)
        }, [stopEditing])

        const inputElementRef = useRef<HTMLInputElement | null>(null)

        useTypeInCellToEdit({
            getInputElement: () => inputElementRef.current,
        })

        const columnWidth = useColumnWidth(column)

        return (
            <Box
                ref={(el: HTMLDivElement | null) => {
                    inputElementRef.current = el?.querySelector('input[type="text"]') ?? null
                }}
                lineHeight="bodyS"
                height="full"
                width="full"
                flex
                center
            >
                <DropdownAttribute
                    field={field}
                    onChange={setNewValue}
                    mode={modes.editing}
                    isMulti={isMulti}
                    controlStyle={{
                        border: '1px solid white',
                        '&:hover': {
                            border: '1px solid white',
                        },
                        height: '100%',
                    }}
                    inDataGrid
                    hasFocus={true}
                    className={DropdownCellStyle}
                    valueContainerStyle={{
                        height: '100%',
                        overflowY: 'auto',
                    }}
                    style={{
                        minWidth: `${columnWidth}px`,
                    }}
                >
                    {/* @ts-expect-error */}
                    {newValue}
                </DropdownAttribute>
            </Box>
        )
    }
)

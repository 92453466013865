import React from 'react'

import { TaskPayload } from 'data/hooks/tasks/types'

import { Box } from 'ui/components/Box'

import { TasksListItem } from './TasksListItem'

type TasksListProps = React.ComponentPropsWithoutRef<typeof Box> & {
    tasks: TaskPayload[]
}

export const TasksList: React.FC<TasksListProps> = ({ tasks, ...props }) => {
    return (
        <Box flex column gap="xs" {...props}>
            {tasks.map((task) => (
                <TasksListItem key={task.auto_id} task={task} showGutter />
            ))}
        </Box>
    )
}

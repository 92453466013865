import { useCallback } from 'react'

import { isMultiLineText } from 'data/utils/fieldDefinitions'
import { formatValue } from 'data/utils/utils'
import { DataGridCellProvider } from 'features/datagrid/types'
import { extractPlainTextFromValue } from 'features/datagrid/utils'
import { formatDate } from 'utils/date/dateUtils'
import { ensureArray } from 'utils/utils'

type UseValueFormatterProps = {
    cellDataProvider: DataGridCellProvider
}

/**
 * Format the value to be shown in the grid, or to be used in copy operations.
 */
export function useValueFormatter({ cellDataProvider }: UseValueFormatterProps) {
    return useCallback(
        (value: unknown, field: FieldDto, _object: ObjectDto): string => {
            if (isMultiLineText(field.type, field.options)) {
                const newValue = extractPlainTextFromValue(value, field)

                return newValue.split(/\r?\n/).join('  ')
            }

            switch (field.type) {
                case 'image':
                    if (!value) return ''
                    if (typeof value === 'string') return value
                    return (value as AttachmentDto).url

                case 'multi_file': {
                    const values = ensureArray(value)
                        .map(({ url }) => url)
                        .join(' ')

                    return values
                }

                case 'date':
                case 'datetime': {
                    if (!value) return ''

                    const timezone = field.options?.timezone
                    const showTime = field.type === 'datetime'
                    const { dateValue } = formatDate(value, timezone, showTime)

                    return dateValue?.format(showTime ? 'L LT Z z' : 'L') || ''
                }

                case 'user_ref':
                    return (value as UserRefDto)?.name ?? ''

                case 'lookup':
                case 'multi_lookup':
                    if (value) {
                        const values = ensureArray(value)

                        return (
                            values
                                .map((value) => {
                                    const linkedRecord = cellDataProvider.getRecord(
                                        field.link_target_object_id ?? '',
                                        value as string
                                    )
                                    return linkedRecord?._primary || ''
                                })
                                // Format as CSV - duplicate quotes and then wrap the values in CSV.
                                // For instance, ['a,b', 'c"d'] becomes '"a,b","c""d"'
                                .map((name) => `"${String(name).replace(/"/g, '""')}"`)
                                .join(',')
                        )
                    }

                    return ''

                case 'multi_select':
                case 'dropdown':
                    if (value) {
                        const values = ensureArray(value)

                        const optionsAsMap =
                            field.options?.options?.reduce((acc, option) => {
                                acc.set(option.value!, option)
                                return acc
                            }, new Map<string, FieldOptionsOptionItem>()) ??
                            new Map<string, FieldOptionsOptionItem>()

                        return values
                            .map((value) => {
                                const option = optionsAsMap.get(value!)
                                return option?.label ?? value
                            })
                            .join(',')
                    }

                    return ''

                case 'checkbox':
                    return String(value ?? false).toLowerCase()
            }

            return formatValue(field, value)?.toString() || ''
        },
        [cellDataProvider]
    )
}

import React from 'react'

import { ActionMenuContextProvider } from 'features/views/ListView/Actions/ActionMenuContext'
import { FiltersContextProvider } from 'features/views/ListView/Filters/FiltersContext'
import { PaginationContextProvider } from 'features/views/ListView/Pagination/PaginationContext'
import { SearchContextProvider } from 'features/views/ListView/Search/SearchContext'
import { SortContextProvider } from 'features/views/ListView/Sort/SortContext'

import { TableViewContextProvider } from './TableViewContext'
import { TableViewData } from './TableViewData'
import { TableViewListHeader } from './TableViewListHeader'

type TableViewProps = {}

export const TableView: React.FC<TableViewProps> = React.memo(function TableView() {
    return (
        <FiltersContextProvider>
            <SearchContextProvider>
                <SortContextProvider>
                    <PaginationContextProvider>
                        <TableViewContextProvider>
                            <ActionMenuContextProvider>
                                <TableViewListHeader />
                                <TableViewData />
                            </ActionMenuContextProvider>
                        </TableViewContextProvider>
                    </PaginationContextProvider>
                </SortContextProvider>
            </SearchContextProvider>
        </FiltersContextProvider>
    )
})

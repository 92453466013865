import { useCallback } from 'react'

import { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

import { AddNewConnectionModal } from './AddNewConnectionModal'
import { ADD_NEW_CONNECTION_MODAL_KEY } from './addNewConnectionModalConstants'

export const useAddNewConnectionModal = (
    onSuccessfulAuth?: (connection: WorkflowIntegrationConnectionDto) => void
) => {
    const { show } = useModalDeclaration(ADD_NEW_CONNECTION_MODAL_KEY, AddNewConnectionModal)

    return useCallback(
        () =>
            show({
                onSuccessfulAuth,
            }),
        [show, onSuccessfulAuth]
    )
}

// @ts-strict-ignore
import React, { useCallback, useMemo, useState } from 'react'

import { InlineCreateViewModal } from 'v2/views/Create/InlineCreateViewModal'

import { GlobalModalContext, GlobalModalProps } from './GlobalModalContext'
import { INLINE_CREATE_VIEW_MODAL } from './GlobalModalKeys'

function lookupComponent(key: string) {
    if (key.startsWith(INLINE_CREATE_VIEW_MODAL)) return InlineCreateViewModal
}

export default function GlobalModals({ children }) {
    const [modals, setModals] = useState<{
        [key: string]: React.ComponentType<GlobalModalProps>
    }>({})

    const registerModal = useCallback(
        (key: string, Component?: React.ComponentType<GlobalModalProps>) => {
            const ComponentToRegister = Component || lookupComponent(key)

            if (!ComponentToRegister) {
                return
            }

            setModals((modals) => ({
                ...modals,
                [key]: ComponentToRegister,
            }))
        },
        []
    )

    const unregisterModal = useCallback((key) => {
        setModals((modals) => {
            const newModals = { ...modals }
            delete newModals[key]
            return newModals
        })
    }, [])

    const context = useMemo(
        () => ({ registerModal, unregisterModal }),
        [registerModal, unregisterModal]
    )
    return (
        <GlobalModalContext.Provider value={context}>
            <>{children}</>

            {Object.entries(modals).map(([key, Component]) => (
                <Component key={key} modalKey={key} />
            ))}
        </GlobalModalContext.Provider>
    )
}

import { useCallback, useEffect, useState } from 'react'

import type { DismissableLayerProps } from '@radix-ui/react-dismissable-layer'

export function useSystemNavigationPopupItemState() {
    const [isOpen, setIsOpen] = useState(false)

    const onInteractOutside: DismissableLayerProps['onInteractOutside'] = useCallback((e) => {
        const isAgGridPopupOpen = document.querySelector('.ag-custom-component-popup')
        if (isAgGridPopupOpen) {
            e.preventDefault()
        }
    }, [])

    const [isFirstRender, setIsFirstRender] = useState(true)

    // Delay the rendering of the icon picker to prevent
    // it from making the dropdown laggy.
    useEffect(() => {
        requestAnimationFrame(() => {
            setIsFirstRender(false)
        })
    }, [])

    return { isOpen, setIsOpen, onInteractOutside, isFirstRender }
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { stopPropagation } from 'ui/helpers/utilities'

import { useNavigationSpacesItemState } from './hooks/useNavigationSpacesItemState'
import { NavigationApps } from './NavigationApps'
import { NavigationSpace as NavigationSpaceType } from './types'

import {
    WorkspaceNavigationSpaceItemHoverStyle,
    WorkspaceNavigationSpaceItemLabelStyle,
    WorkspaceNavigationSpaceItemStyle,
} from './Navigation.css'

type NavigationSpacesItemProps = React.ComponentPropsWithoutRef<typeof Box> &
    NavigationSpaceType & {
        isCollapsed?: boolean
        isLast?: boolean
    }

export const NavigationSpacesItem: React.FC<NavigationSpacesItemProps> = ({
    apps: appsProp,
    id,
    name,
    isCollapsed: isNavigationCollapsed,
    isLast,
    ...props
}) => {
    const { apps, isCollapsed, toggleIsCollapsed, showApps, canCreateApp, onCreateApp } =
        useNavigationSpacesItemState({
            isNavigationCollapsed,
            apps: appsProp,
            id,
            name,
        })

    if (!showApps) {
        return null
    }

    return (
        <Box as={Collapsible} open={!isCollapsed} onOpenChange={toggleIsCollapsed} trim {...props}>
            {!isNavigationCollapsed && (
                <CollapsibleTrigger asChild>
                    <Box
                        role="button"
                        py={isNavigationCollapsed ? 0 : '2xs'}
                        className={WorkspaceNavigationSpaceItemStyle}
                    >
                        <Box className={WorkspaceNavigationSpaceItemLabelStyle}>
                            <Icon
                                aria-label={isCollapsed ? 'Expand group' : 'Collapse group'}
                                size="s"
                                p="3xs"
                                family="hugeicons"
                                name={isCollapsed ? 'arrow-right-01' : 'arrow-down-01'}
                                color="icon"
                            />
                            <Body
                                size="s"
                                weight="medium"
                                color="textWeak"
                                trim
                                px="2xs"
                                style={{
                                    fontStyle: id === '' ? 'italic' : 'normal',
                                }}
                            >
                                {name}
                            </Body>
                        </Box>
                        {canCreateApp && (
                            <Box
                                className={WorkspaceNavigationSpaceItemHoverStyle}
                                onClick={stopPropagation}
                                noShrink
                            >
                                <Tooltip
                                    content="Create new app"
                                    asChild
                                    zIndex={200}
                                    side="bottom"
                                    align="center"
                                >
                                    <Button
                                        size="2xs"
                                        variant="ghost"
                                        startIcon={{ name: 'Plus' }}
                                        aria-label="Create new app"
                                        onClick={onCreateApp}
                                    />
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                </CollapsibleTrigger>
            )}
            <CollapsibleContent>
                <NavigationApps
                    apps={apps}
                    isCollapsed={isNavigationCollapsed}
                    pt="2xs"
                    pb={isLast || isNavigationCollapsed ? 0 : 'm'}
                />
            </CollapsibleContent>
        </Box>
    )
}

import { Mark as ProseMirrorMark, Node as ProseMirrorNode } from '@tiptap/pm/model'
import { getMarksBetween } from '@tiptap/react'

export function getActivityMark(
    fromPos: number,
    toPos: number,
    node: ProseMirrorNode
): ProseMirrorMark | null {
    const ranges = getMarksBetween(fromPos, toPos, node)

    const activityMarkRange = ranges.find((range) => {
        return range.mark.type.name === 'activity'
    })
    if (activityMarkRange) return activityMarkRange.mark

    return null
}

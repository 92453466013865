import React, { forwardRef } from 'react'

import { Button } from 'ui/components/Button'

import { Trigger } from './Dropdown.parts'

type DropdownButtonRef = HTMLButtonElement

type DropdownButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {}

export const DropdownButton = forwardRef<DropdownButtonRef, DropdownButtonProps>((props, ref) => {
    return (
        <Trigger asChild ref={ref}>
            <Button {...props} />
        </Trigger>
    )
})

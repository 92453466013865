import { UseQueryOptions } from 'react-query'

import { queryClient, useQuery } from 'data/hooks/_helpers'

export const EXTERNAL_DATABASES_KEY = 'EXTERNAL_DATABASES_KEY'

type ExternalDatabase = {
    id: string
    name: string
}

type useExternalDatabasesInput = {
    integrationId: string
    nangoConnectionId: string
}
export function useExternalDatabases(
    { nangoConnectionId, integrationId }: useExternalDatabasesInput,
    options: UseQueryOptions<ExternalDatabase[]>
) {
    return useQuery<ExternalDatabase[]>(
        [EXTERNAL_DATABASES_KEY, integrationId, nangoConnectionId],
        `data-connections/external-databases/?nango_connection_id=${nangoConnectionId}&integration_id=${integrationId}`,
        options,
        {
            bypassQueryDeps: true,
        }
    )
}

export function invalidateExternalDatabases() {
    return queryClient.invalidateQueries([EXTERNAL_DATABASES_KEY])
}

import React from 'react'

import { ActionEditor } from './Actions/ActionEditor'
import { LoopEditor } from './FlowControl/LoopEditor'
import { TriggerEditor } from './Triggers/TriggerEditor'

type WorkflowItemEditorProps = {
    workflow: WorkflowDto
    item: WorkflowItem
    onChange: (patch: Omit<Partial<WorkflowItem>, 'id'>) => void
}
export function WorkflowItemEditor({ workflow, item, onChange }: WorkflowItemEditorProps) {
    switch (item.kind) {
        case 'trigger':
            return <TriggerEditor workflow={workflow} trigger={item} onChange={onChange} />
        case 'action':
            return <ActionEditor action={item} onChange={onChange} />
        case 'loop':
            return <LoopEditor node={item} onChange={onChange} />
        default:
            return <>Edit {(item as WorkflowItem).kind}</>
    }
}

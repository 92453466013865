import React from 'react'

export type DropdownContentContextValue = {
    searchQuery: string
    setSearchQuery: (value: string | ((prevValue: string) => string)) => void
    isSearchQueryControlled: boolean
    isSearchManaged: boolean
    searchInputElement: HTMLInputElement | null
    setIsSearchManaged: (value: boolean, searchInput?: HTMLInputElement) => void
}
export const DropdownContentContext = React.createContext<DropdownContentContextValue>({
    searchQuery: '',
    setSearchQuery: () => {},
    isSearchQueryControlled: false,
    isSearchManaged: false,
    searchInputElement: null,
    setIsSearchManaged: () => {},
})

export function useDropdownContentContext() {
    return React.useContext(DropdownContentContext)
}

import styled from '@emotion/styled'

import { RichTextarea } from 'v2/ui'

export const ActivityRichTextarea = styled(RichTextarea)`
    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
`

import { useCallback } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import {
    buildContextSchemaWithPaths,
    determineExpressionType,
} from 'features/expressions/utilities'
import {
    StackerFormulaSyntaxTree,
    StackerNAryFunction,
} from 'features/formulas/parser/formulaParserTypes'

export function useLoopFinalSchema() {
    const { data: schema } = useWorkflowSchema()

    return useCallback(
        (
            config: WorkflowLoopConfig,
            contextSchema: WorkflowSchemaStateItem[]
        ): WorkflowSchemaLoopType | undefined => {
            const type = schema?.nodes.find((t) => t.id === config.kind) as
                | WorkflowSchemaLoopType
                | undefined

            if (!type) {
                return undefined
            }

            const state = type.state?.slice() ?? []

            const currentItemState = getStateItemFromIterable(config.iterable_value, contextSchema)
            if (currentItemState) state.push(currentItemState)

            return {
                ...type,
                state,
            }
        },
        [schema?.nodes]
    )
}

function getStateItemFromIterable(
    iterable: WorkflowLoopIterable | undefined,
    contextSchema: WorkflowSchemaStateItem[]
): WorkflowSchemaControlFlowStateItem | null {
    if (iterable?.type !== 'array_expr') return null

    const value = iterable.expr?.value
    if (!value) return null

    return getStateItemFromExpressionValue(value, contextSchema)
}

function getStateItemFromExpressionValue(
    value: StackerFormulaSyntaxTree,
    contextSchema: WorkflowSchemaStateItem[],
    consumeArrays: boolean = true
): WorkflowSchemaControlFlowStateItem | null {
    const fn = typeof value === 'object' ? value.function : null

    if (fn === 'ARRAY' && consumeArrays) {
        const fnArguments = (value as StackerNAryFunction).arguments ?? []
        const referenceItem = fnArguments[0]

        return getStateItemFromExpressionValue(referenceItem, contextSchema, false)
    }

    const contextWithPaths = buildContextSchemaWithPaths({ contextSchema })
    const { type, extraOptions } = determineExpressionType(value, contextWithPaths)
    if (!type) return null

    return getStateItemFromValueType(type, extraOptions)
}

function getStateItemFromValueType(
    type: FieldType,
    extraOptions?: WorkflowExtraOptions
): WorkflowSchemaControlFlowStateItem {
    let newType: FieldType = 'string'
    let newExtraOptions: WorkflowExtraOptions | undefined

    switch (type) {
        case 'multi_lookup':
            newType = 'lookup'
            newExtraOptions = extraOptions
            break
        case 'multi_file':
        case 'multi_select':
            newType = 'string'
            break
    }

    return {
        id: 'current_item',
        name: 'Current Item',
        type: newType,
        extra_options: newExtraOptions,
        scope: 'local',
    }
}

import React, { ReactNode, useMemo, useState } from 'react'

import { useListViewContext } from 'features/views/ListView/useListViewContext'

import { getDefaultPageSize } from 'v2/ui/components/List/utils'

import { PaginationContext } from './usePaginationContext'

export type PaginationContextProviderProps = { children: ReactNode; pageSize?: number }

export const PaginationContextProvider: React.FC<PaginationContextProviderProps> = ({
    children,
    pageSize,
}) => {
    const { view } = useListViewContext()

    const effectivePageSize =
        pageSize || view.options.pageSize || getDefaultPageSize(view.options.display)

    const [currentPageIndex, setCurrentPageIndex] = useState(0)

    const value = useMemo(
        () => ({
            currentPageIndex,
            setCurrentPageIndex,
            pageSize: effectivePageSize,
        }),
        [currentPageIndex, effectivePageSize]
    )

    return <PaginationContext.Provider value={value}>{children}</PaginationContext.Provider>
}

import React, { forwardRef } from 'react'

import { Button } from 'ui/components/Button'

type InputEndActionCalendarRef = HTMLButtonElement

type InputEndActionCalendarProps = React.ComponentPropsWithoutRef<typeof Button> & {
    readOnly?: boolean
}

export const InputEndActionCalendar = forwardRef<
    InputEndActionCalendarRef,
    InputEndActionCalendarProps
>(({ disabled, readOnly, ...props }, ref) => {
    return (
        <Button
            ref={ref}
            size="xs"
            variant="ghost"
            aria-label="Calendar"
            disabled={disabled || readOnly}
            startIcon={{ name: 'Calendar' }}
            {...props}
        />
    )
})

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { merge } from 'lodash'
import QueryString from 'qs'
import { signUp } from 'supertokens-auth-react/recipe/emailpassword'

import { Urls } from 'app/UrlService'
type SignupFormData = {
    name: string
    email: string
    password: string
}
export function useSignUpForm() {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    const history = useHistory()
    const companyName = query.company?.toString()
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<SignupFormData>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',

        defaultValues: { name: query.name?.toString() ?? '', email: query.email?.toString() ?? '' },
    })

    const [internalErrors, setInternalErrors] = useState<Record<string, { message: string }>>({})
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    async function onSubmit({ name, email, password }: SignupFormData) {
        try {
            setIsLoading(true)
            setInternalErrors({})
            sessionStorage.setItem('onboardingCompanyName', companyName ?? '')
            const response = await signUp({
                formFields: [
                    {
                        id: 'name',
                        value: name,
                    },
                    {
                        id: 'email',
                        value: email,
                    },
                    {
                        id: 'password',
                        value: password,
                    },
                ],
            })
            if (response.status !== 'OK') {
                if (response.status === 'FIELD_ERROR') {
                    setInternalErrors(
                        response.formFields.reduce(
                            (acc, f) => {
                                acc[f.id] = { message: f.error }
                                return acc
                            },
                            {} as Record<string, { message: string }>
                        )
                    )
                } else {
                    setErrorMessage('Sign up failed. Please try again.')
                }
            } else {
                history.push(Urls.Root)
            }
        } catch (ex) {
            console.error(ex)
            setErrorMessage('Sign up failed. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    return {
        register,
        onSubmit: handleSubmit(onSubmit),
        errors: merge(errors, internalErrors),
        errorMessage,
        isLoading,
        watch,
    }
}

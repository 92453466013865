import React, { useMemo } from 'react'

import { getUrl } from 'app/UrlService'
import { useStacks } from 'data/hooks/stacks'

import { Box } from 'ui/components/Box'
import { LinkButton } from 'ui/components/LinkButton'
import { stopPropagation } from 'ui/helpers/utilities'

import { useTaskListContext } from './useTaskListContext'

type RecordLinkProps = React.ComponentPropsWithoutRef<typeof Box> & {
    recordId: string
    stackId: string
    variant?: React.ComponentPropsWithoutRef<typeof LinkButton>['variant']
    buttonProps?: Partial<React.ComponentPropsWithoutRef<typeof LinkButton>>
}

export function RecordLink({
    recordId,
    stackId,
    variant = 'secondary',
    buttonProps,
    ...props
}: RecordLinkProps) {
    const { data: stacks } = useStacks()
    const { records, objects } = useTaskListContext()

    const record = records?.find((r) => r._sid === recordId)

    const linkHref = useMemo(() => {
        if (!record) return undefined

        const stack = stacks?.find((s) => s._sid === stackId)
        if (!stack || !objects) return undefined

        const object = objects[stackId]?.[record._object_id]
        if (!object) return undefined

        return getUrl(`${object.url}/view/${record._sid}`, stack)
    }, [objects, record, stackId, stacks])

    const isLink = variant === 'link'
    const isRecordHidden = recordId && !record

    if (!record && !isRecordHidden) return null

    return (
        <Box onClick={stopPropagation} {...props}>
            <LinkButton
                to={linkHref}
                variant={variant}
                size="2xs"
                width="full"
                fontWeight={isLink ? 'bodyBold' : undefined}
                aria-disabled={!linkHref}
                title={record?._primary?.toString()}
                startIcon={{
                    name: 'FileText',
                }}
                {...buttonProps}
            >
                {record ? (
                    <Box as="span" width="full" trim>
                        {record._primary}
                    </Box>
                ) : (
                    <Box as="i" width="full" trim pr="xs">
                        hidden record
                    </Box>
                )}
            </LinkButton>
        </Box>
    )
}

import { GlobalStaticState } from 'app/GlobalStaticState'
import { makeToast } from 'utils/toaster'

const EXCEPTION_TYPES_TO_TOAST = ['SalesforceConnectorError', 'SalesforceMappingError']
const EXCEPTION_TYPES_TO_TOAST_FOR_EVERYONE = []

const handleErrorResponse = (response) => {
    const user = GlobalStaticState.getUser()

    response
        .clone()
        .json()
        .then((result) => {
            console.warn(`Error from Stacker API (${response.status})`, result)

            if (
                result &&
                'error' in result &&
                'exception' in result &&
                ((user && EXCEPTION_TYPES_TO_TOAST.includes(result.exception)) ||
                    EXCEPTION_TYPES_TO_TOAST_FOR_EVERYONE.includes(result.exception))
            ) {
                const toastId = result.exception + result.error
                makeToast(toastId, result.error, 'error')
            }
        })
        .catch((err) => {
            // not a json response, happens with 500 server errors.
            console.warn(
                `Exception during handling error from Stacker API (${response.status}), response: ${response}`,
                err
            )
        })
}

export default handleErrorResponse

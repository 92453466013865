import React from 'react'

import { useAppContext } from 'app/useAppContext'
import { useGrants } from 'data/hooks/accessGrants'
import { useUpdateStackSharingSettings } from 'data/hooks/stacks'
import { refetchAppUsersForAdmin } from 'data/hooks/users/main'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { assertIsDefined } from 'data/utils/ts_utils'

import { Box, Flex, LoadingScreen } from 'v2/ui'

import AddUserDropdown from './AddUserDropdown'
import { ManualAppUserList } from './ManualAppUserList'

type Props = {
    formRef: any
    children?: React.ReactNode
}
export default function ManualAppUsers({ formRef, children }: Props) {
    const { selectedStack: stack } = useAppContext()
    const { data: users = [], isLoading } = useAppUsersForAdmin(
        true,
        { refetchOnMount: true },
        {
            includeUsersWithNoAccess: true,
        }
    )
    const { data: grants = [], isLoading: isLoadingGrants } = useGrants()

    const updateStackSharingSettings = useUpdateStackSharingSettings()

    const saveChanges = (patch: any) => {
        assertIsDefined(stack)

        const adminUsersAfterPatch = users.filter((user: UserDto) => {
            if (user.role !== 'internal_admin') return false

            const patchUser = patch[user._sid]
            if (!patchUser) return true

            if (patchUser.role !== 'internal_admin') return false
            if (patchUser.deleted) return false

            return true
        })

        if (adminUsersAfterPatch.length === 0) {
            throw new Error('There must be at least one admin user.')
        }

        return updateStackSharingSettings(stack, patch).then(refetchAppUsersForAdmin)
    }
    if (isLoading || isLoadingGrants) {
        return <LoadingScreen isLoading={true} width="100%" height="100%" />
    }
    return (
        <Flex
            column
            width="100%"
            height="100%"
            maxHeight="100%"
            wrap="nowrap"
            align="stretch"
            flexGrow={1}
            flexShrink={1}
            minHeight="0"
        >
            <AddUserDropdown showRoles={true} />
            <Box height="3" />
            <ManualAppUserList
                disabled={false}
                formRef={formRef}
                onSave={saveChanges}
                users={users}
                grants={grants}
            >
                {children}
            </ManualAppUserList>
        </Flex>
    )
}

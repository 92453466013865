import React, { useCallback } from 'react'

import { DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu'
import classNames from 'classnames'

import { HeadBaseStyle } from 'ui/components/Menu/Menu.css'
import { StandardComponentProps } from 'ui/helpers/styles'

import { Item } from './Dropdown.parts'

type DropdownHeadBaseRef = HTMLDivElement

type DropdownHeadBaseProps = Omit<DropdownMenuItemProps, 'asChild'> & StandardComponentProps & {}

export const DropdownHeadBase = React.forwardRef<DropdownHeadBaseRef, DropdownHeadBaseProps>(
    ({ className, onSelect, onPointerMove, ...props }, ref) => {
        const handleOnSelect = useCallback(
            (e: Event) => {
                e.preventDefault()

                onSelect?.(e)
            },
            [onSelect]
        )

        const handlePointerMove = useCallback(
            (e: React.PointerEvent<HTMLDivElement>) => {
                // Prevent this item from being highlighted.
                e.preventDefault()
                e.stopPropagation()

                onPointerMove?.(e)
            },
            [onPointerMove]
        )

        return (
            <Item
                onSelect={handleOnSelect}
                onPointerMove={handlePointerMove}
                className={classNames(className, HeadBaseStyle)}
                {...props}
                ref={ref}
            />
        )
    }
)

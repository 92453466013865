import { getCachedField } from 'data/hooks/fields'
import { FieldPatch } from 'features/admin/fields/common'

import { getLinkedFieldObjectName } from './getLinkedFieldObjectName'

export const getLookupDynamicFieldLabel = (field: FieldPatch): string | undefined => {
    const localLinkFieldSid = field?.connection_options?.lookup_relationship_field
    if (!localLinkFieldSid) return
    const localLinkField = getCachedField(localLinkFieldSid)
    if (!localLinkField) return

    const lookupFieldSid = field?.connection_options?.lookup_field
    if (!lookupFieldSid) return

    const linkedObjectName = getLinkedFieldObjectName(localLinkField)
    if (!linkedObjectName) return

    const lookupField = getCachedField(lookupFieldSid)
    if (!lookupField) return

    const lookupFieldLabel = lookupField.label
    // This should never happen, but just in case, it'll return an error
    if (!lookupFieldLabel) return
    return `${lookupFieldLabel} (from ${linkedObjectName})`
}

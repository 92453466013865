import React from 'react'

import { composeRefs } from '@radix-ui/react-compose-refs'

import { useOverflowY } from 'features/views/ListView/hooks/useOverflowY'

import { Box } from 'ui/components/Box'

import { AppUsersIsOverflowingTopStyle } from './AppUsers.css'

type AppUsersScrollAreaProps = {}

export const AppUsersScrollArea: React.FC<AppUsersScrollAreaProps> = ({ children }) => {
    const { targetRef, scrollAreaRef } = useOverflowY({
        topClassName: AppUsersIsOverflowingTopStyle,
    })

    return (
        <Box
            overflowY="auto"
            alignItems="stretch"
            grow
            ref={composeRefs<HTMLDivElement>(
                targetRef as React.MutableRefObject<HTMLDivElement>,
                scrollAreaRef
            )}
        >
            {children}
        </Box>
    )
}

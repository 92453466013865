import React from 'react'

import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'
import { TextAttributeTag } from 'features/views/attributes/TextAttributeTag'

import { BaseAttribute } from './BaseAttribute'
import { BoardViewAttributeComponent } from './types'

const MAX_LINES = 1
const MAX_LINES_TITLE = 3
const MAX_LINES_SUBTITLE = 2
const MAX_LENGTH = 40
const MAX_LENGTH_TAG = 20
const MAX_LENGTH_TITLE = 120
const MAX_LENGTH_SUBTITLE = 120
const MAX_OVERFLOW_LENGTH = 300

type TextAttributeProps = {}

export const TextAttribute: BoardViewAttributeComponent<string, TextAttributeProps> = ({
    value,
    attribute,
    isLoading,
    displayStyle,
    showIcon,
    ...props
}) => {
    const { field, type, rawOptions } = attribute

    const isEmpty = !isLoading && !value
    const isTitle = type === 'title'
    const isSubtitle = type === 'subtitle'
    const isFooter = type === 'footer'

    let maxLines = MAX_LINES
    if (isTitle) {
        maxLines = MAX_LINES_TITLE
    } else if (isSubtitle) {
        maxLines = MAX_LINES_SUBTITLE
    }

    let maxLength: number | undefined = MAX_LENGTH
    if (isTitle) {
        maxLength = MAX_LENGTH_TITLE
    } else if (isSubtitle) {
        maxLength = MAX_LENGTH_SUBTITLE
    } else if (isFooter) {
        maxLength = undefined
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} {...props}>
            {displayStyle === 'tags' ? (
                <TextAttributeTag
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    value={value}
                    field={field}
                    maxLines={MAX_LINES}
                    maxLength={MAX_LENGTH_TAG}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    treatAsUrl={rawOptions.treatAsUrl as boolean}
                    buttonTitle={rawOptions.buttonTitle as string}
                    displayAsImage={rawOptions.displayAsImage as boolean}
                    imageAltText={rawOptions.imageAltText as string}
                    openInNewTab={rawOptions.openInNewTab as boolean}
                    showIcon={showIcon}
                />
            ) : (
                <TextAttributeDisplay
                    style={{
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        letterSpacing: 'inherit',
                        fontWeight: 'inherit',
                        wordBreak: 'break-word',
                        display: isFooter ? 'block' : undefined,
                        maxWidth: isFooter ? '100%' : undefined,
                    }}
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    value={value}
                    field={field}
                    maxLines={maxLines}
                    maxLength={maxLength}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    trim={!isTitle && !isSubtitle}
                    treatAsUrl={rawOptions.treatAsUrl as boolean}
                    buttonTitle={rawOptions.buttonTitle as string}
                    displayAsImage={rawOptions.displayAsImage as boolean}
                    imageAltText={rawOptions.imageAltText as string}
                    openInNewTab={rawOptions.openInNewTab as boolean}
                />
            )}
        </BaseAttribute>
    )
}

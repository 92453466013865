import React from 'react'

import { WithObject } from './WithObjectComponent'

export const withObject =
    (Child: React.ComponentType<any>) =>
    ({ objectId, ...props }: { objectId: string; [prop: string]: any }) => (
        <WithObject objectId={objectId}>
            {(recProps: any) => <Child {...recProps} {...props} />}
        </WithObject>
    )

export const relativeFiltersLabelsByValue = {
    _today: 'Today',
    _yesterday: 'Yesterday',
    _tomorrow: 'Tomorrow',
    _this_week: 'This week',
    _last_week: 'Last week',
    _next_week: 'Next week',
    _this_quarter: 'This quarter',
    _last_quarter: 'Last quarter',
    _next_quarter: 'Next quarter',
    _customDate: 'Specific Date',
    _this_month: 'This month',
    _next_month: 'Next month',
    _last_month: 'Last month',
    _this_year: 'This year',
    _next_year: 'Next year',
    _last_year: 'Last year',
    _inBetweenDates: 'Between dates',
} as const

import { LINK_TYPES } from 'data/utils/fieldDefinitions'

const QUICK_FILTER_FIELD_TYPES = new Set<FieldType>([
    'checkbox',

    // 'number',
    // 'currency',
    'percentage',

    'date',
    'datetime',

    'dropdown',
    'multi_select',

    'user_ref',
    'lookup',
    'multi_lookup',
])

const ADVANCED_FILTER_FIELD_TYPES = new Set<FieldType>([
    'checkbox',
    'dropdown',
    'string',
    'long_text',
    'lookup',
    'multi_lookup',
    'multi_select',
    'url',
    'number',
    'currency',
    'percentage',
    'date',
    'datetime',
    'multi_file',
    'user_ref',
    'document',
])

export function filterSupportsField(field: FieldDto, type?: ListViewInlineFilters): boolean {
    if (LINK_TYPES.includes(field.type) && field.is_foreign) {
        // users are not allowed to fetch list of possible values for cross-app link fields because the current user
        // may not have access at all to the target stack. So we're excluding cross app link fields entirely for now.
        return false
    }

    if (type === 'quick') {
        return QUICK_FILTER_FIELD_TYPES.has(field.type)
    }

    return ADVANCED_FILTER_FIELD_TYPES.has(field.type)
}

export function filterSupportedFields(
    fields: FieldDto[],
    type?: ListViewInlineFilters
): FieldDto[] {
    return fields.filter((f) => filterSupportsField(f, type))
}

export function filterSelectedFields(
    selectedFieldSids: string[],
    fields: FieldDto[]
): {
    fieldId: string
    label: string
}[] {
    const fieldsById = fields.reduce((agg, f) => {
        return agg.set(f._sid, f)
    }, new Map<string, FieldDto>())

    return selectedFieldSids.reduce<ReturnType<typeof filterSelectedFields>>((agg, fieldSid) => {
        const field = fieldsById.get(fieldSid)
        if (field) {
            agg.push({
                fieldId: fieldSid,
                label: field.label,
            })
        }

        return agg
    }, [])
}

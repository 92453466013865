import React from 'react'

import { TaskListContextType } from './defaultTaskListContext'
import { TaskListContext } from './TaskListContext'

export function TaskListContextProvider({
    value,
    children,
}: {
    value: TaskListContextType
    children: React.ReactNode
}) {
    return <TaskListContext.Provider value={value}>{children}</TaskListContext.Provider>
}

function getKey(createUrl: string): string {
    return `${createUrl}_inline_create_draft`
}

export function persistRecord(createUrl: string, record: Partial<RecordDto>) {
    const key = getKey(createUrl)
    localStorage.setItem(key, JSON.stringify(record))
}

export function restoreRecord(createUrl: string): Partial<RecordDto> {
    const key = getKey(createUrl)
    const record = localStorage.getItem(key)

    return record ? JSON.parse(record) : {}
}

export function clearRecord(createUrl: string) {
    const key = getKey(createUrl)
    localStorage.removeItem(key)
}

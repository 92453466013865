import { useObjects } from 'data/hooks/objects'
import { getLinksFromObject } from 'data/utils/getLinks'

import { getCachedFields } from './fields'

export const useUserProfileObjects = (userObjectId?: string): ObjectDto[] => {
    const { data: allObjects = [] } = useObjects()

    if (!userObjectId) return []

    const userLinks = getLinksFromObject(getCachedFields(), userObjectId)

    const userProfilesObjectIds = userLinks
        .filter((link) => link.source.objectId === userObjectId)
        .map((link) => link.target.objectId)

    const userProfileObjects = allObjects.filter((object) =>
        userProfilesObjectIds.includes(object._sid)
    )

    return userProfileObjects
}

import React, { forwardRef } from 'react'

import { Box } from 'ui/components/Box'
import { Toggle } from 'ui/components/Toggle'
import { stopPropagation } from 'ui/helpers/utilities'

import { ItemIndicator } from './Dropdown.parts'
import { SlotContentProps } from './types'

type DropdownEndIndicatorToggleRef = HTMLDivElement

type DropdownEndIndicatorToggleProps = React.ComponentPropsWithoutRef<typeof Box> &
    SlotContentProps & {}

export const DropdownEndIndicatorToggle = forwardRef<
    DropdownEndIndicatorToggleRef,
    DropdownEndIndicatorToggleProps
>(({ checked, onCheckedChange, disabled, onClick, ...props }, ref) => {
    const handleOnClick = (e: React.MouseEvent) => {
        stopPropagation(e)
        onClick?.(e)
    }

    return (
        <Box onClick={handleOnClick} {...props} ref={ref}>
            <ItemIndicator forceMount>
                <Toggle checked={checked} onCheckedChange={onCheckedChange} disabled={disabled} />
            </ItemIndicator>
        </Box>
    )
})

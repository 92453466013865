import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { usePortalIconState } from './hooks/usePortalIconState'
import { NavigationPortal } from './types'

import { PortalIconInnerStyles, PortalIconStyles } from './Navigation.css'

const sizeMapping = {
    initials: {
        xs: 's',
        s: 'm',
        m: 'l',
    },
} as const

type PortalIconProps = React.ComponentPropsWithoutRef<typeof Box> & {
    portal: NavigationPortal
    isActive?: boolean
    size?: 'xs' | 's' | 'm'
}

export const PortalIcon: React.FC<PortalIconProps> = ({
    className,
    portal,
    isActive,
    size = 's',
    ...props
}) => {
    const { initials } = usePortalIconState({ portal })

    const bodySize = sizeMapping.initials[size]

    return (
        <Box
            {...props}
            className={classNames(className, PortalIconStyles.styleFunction({ isActive, size }))}
        >
            {!!portal.logoIconUrl && (
                <Box
                    as="img"
                    height="full"
                    width="full"
                    src={portal.logoIconUrl}
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                    }}
                    className={PortalIconInnerStyles.styleFunction({ size })}
                />
            )}
            {!portal.logoIconUrl && initials && (
                <Body
                    size={bodySize}
                    weight="bold"
                    style={{ color: 'inherit', lineHeight: 'normal', whiteSpace: 'nowrap' }}
                >
                    {initials}
                </Body>
            )}
        </Box>
    )
}

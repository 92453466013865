import React, { useState } from 'react'

import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { StandardComponentProps } from 'ui/helpers/styles'

import { TasksQuickCreate, TasksQuickCreateProps } from './TasksQuickCreate'

type TaskListNewTaskPlaceholderProps = StandardComponentProps &
    TasksQuickCreateProps & {
        defaultIsEditing?: boolean
    }
export function TaskListNewTaskPlaceholder({
    related_to,
    related_to_stack,
    related_to_type,
    _object_id,
    defaultIsEditing,
    ...props
}: TaskListNewTaskPlaceholderProps) {
    const [isEditing, setIsEditing] = useState(defaultIsEditing)

    if (isEditing) {
        return (
            <Container border card maxWidth="600px" {...props}>
                <TasksQuickCreate
                    related_to={related_to}
                    related_to_type={related_to_type}
                    related_to_stack={related_to_stack}
                    _object_id={_object_id}
                    onClose={() => setIsEditing(false)}
                />
            </Container>
        )
    }
    return (
        <Button
            variant="ghost"
            size="m"
            startIcon={{ name: 'Plus' }}
            onClick={() => setIsEditing(true)}
            {...(props as React.ComponentProps<typeof Button>)}
        >
            Add a new task
        </Button>
    )
}

import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { StackIconBadge } from 'features/core/StackIconBadge'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

import { Highlighter } from './Highlighter'
import { SearchableNavTreeItem } from './navigationSearchTypes'

export function RenderNavigationLink({
    item,
    queryTerms,
}: {
    item: SearchableNavTreeItem
    queryTerms?: string[]
}) {
    const handleClick = useCallback((e: React.MouseEvent) => {
        // @ts-ignore
        e.nativeEvent.preventDownshiftDefault = true
    }, [])
    return (
        <Box as={Link} to={item.url} flex center color="text" onClick={handleClick} tabIndex={-1}>
            <StackIconBadge stack={item.stack} size={20} mr="xs" />
            <Box>
                <Highlighter terms={queryTerms || []}>{item.stack.name}</Highlighter>
            </Box>
            {!item.isStack && (
                <Icon color="textWeakest" size="xs" noShrink name="ChevronRight" mx="3xs" />
            )}
            {item.parents.map((parent) => (
                <>
                    <Box key={parent._sid}>
                        <Highlighter terms={queryTerms || []}>{parent.label}</Highlighter>
                    </Box>

                    <Icon color="textWeakest" size="xs" noShrink name="ChevronRight" mx="3xs" />
                </>
            ))}
            <Box>
                <Highlighter terms={queryTerms || []}>{item.label}</Highlighter>
            </Box>
        </Box>
    )
}

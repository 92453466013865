// @ts-strict-ignore
import React, { useCallback, useImperativeHandle } from 'react'

import { SuggestionProps } from '@tiptap/suggestion'

import { useUserSearchProvider } from 'features/Search/UserSearchProvider'

import { Box } from 'ui/components/Box'
import { ComboboxContext } from 'ui/components/Combobox'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'

import { SuggestionComponentHandle } from './extensionHelpers'
import { insertMention } from './mentionFunctions'

import { MentionsListStyles } from './MentionsExtension.css'

type MentionsListProps = SuggestionProps<UserDto> & {}

export const MentionsList = React.forwardRef<SuggestionComponentHandle, MentionsListProps>(
    ({ query, editor, range, items: users }, ref) => {
        const usersProvider = useUserSearchProvider({ showOnEmptySearch: true, users })

        const onItemSelected = useCallback(
            (item) => insertMention(editor, range, item),
            [editor, range]
        )
        const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
            isOpen: true,
            inputValue: query,
            onItemSelected,
            providers: [usersProvider],
            itemToString: () => '',
            debounceDelay: 10,
        })

        const { getInputProps } = comboboxState

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }) => {
                getInputProps(undefined, { suppressRefError: true }).onKeyDown(event)
                return event.defaultPrevented
            },
        }))
        const { isLoading, collections, showMore, items } = itemsState

        if (!items.length) return null
        return (
            <ComboboxContext.Provider value={comboboxState}>
                <Box
                    as="ul"
                    role="listbox"
                    aria-label="Mention a user"
                    className={MentionsListStyles}
                >
                    <ComboboxList
                        collections={collections}
                        queryTerms={queryTerms}
                        showMore={showMore}
                        isLoading={isLoading}
                    />
                </Box>
            </ComboboxContext.Provider>
        )
    }
)

import React, { useContext } from 'react'

import { ListViewSort, ListViewSortType } from 'features/views/ListView/Sort/types'

export type ListHeaderContextValue = {
    viewTitle: string
    value: ListViewHeader
    object: ObjectDto
    stack: StackDto
    recordCount: number
    sortBy?: ListViewSort
    setSortBy: (sortBy?: ListViewSort) => void
    availableSortFields: FieldDto[]
    sortType: ListViewSortType
    view: ViewDto
    searchQuery: string
    setSearchQuery: (searchQuery: string) => void
    inlineFilters: Filter[]
    inlineFilterType?: ListViewInlineFilters
    addInlineFilter: (filter: Filter) => void
    removeInlineFilter: (id: number) => void
    updateInlineFilter: (filter: Filter) => void
    setFilters: (filters: Filter[]) => void
    clearInlineFilters: () => void
    availableInlineFilterFields: FieldDto[]
    isEmbedded: boolean
    embeddedByField?: FieldDto
    embeddedByFieldValue?: string
    allowSearch: boolean
    allowCreate: boolean
    allowDownload: boolean
    isEditMode: boolean
    requestIncludedFields: string[]
    requestFilters: Filter[]
}
export const ListHeaderContext = React.createContext<ListHeaderContextValue | null>(null)

export function useListHeaderContext(): ListHeaderContextValue {
    const context = useContext(ListHeaderContext)

    if (!context) {
        throw new Error('useListHeaderContext must be used within a ListHeaderContextProvider')
    }

    return context
}

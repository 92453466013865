import { useEffect, useRef } from 'react'

import { useAppContext } from 'app/useAppContext'
import { useWorkflowEditorContext } from 'features/workflows/useWorkflowEditorContext'

import { USE_CURRENT_USER_INPUT_ID } from './createTaskActionConstants'
import { WorkflowActionEditorComponent } from './types'
import { hasTriggerCurrentUser } from './useCreateTaskActionFinalSchema'

export const CreateTaskActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const { contextSchema } = useWorkflowEditorContext()
    const { selectedStack } = useAppContext()

    const relatedStackId = selectedStack?._sid

    const settingsRef = useRef(action.settings)
    settingsRef.current = action.settings

    useEffect(() => {
        onChange({
            settings: {
                ...settingsRef.current,
                stack_id: relatedStackId,
            },
        })
    }, [onChange, relatedStackId])

    const hasCurrentUser = hasTriggerCurrentUser(contextSchema)

    const inputsRef = useRef(action.inputs)
    inputsRef.current = action.inputs

    useEffect(() => {
        if (hasCurrentUser) return

        // If we change the trigger type and the new type
        // does not support having a current user, just reset
        // the input.
        const inputs = inputsRef.current?.filter((i) => i.id !== USE_CURRENT_USER_INPUT_ID)

        onChange({
            inputs,
        })
    }, [onChange, hasCurrentUser])

    return null
}

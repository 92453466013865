export type Breakpoint = keyof typeof breakpointsStart

const breakpointsStart = {
    mobile: 0,
    tablet: 481,
    tabletLarge: 769,
    desktop: 1025,
    desktopLarge: 1640,
}

export const breakpoints = Object.entries(breakpointsStart).reduce(
    (result, [key, value], idx, array) => {
        const start = value
        const end = idx < array.length - 1 ? array[idx + 1][1] : undefined
        return {
            ...result,
            [key]: {
                start,
                end,
                cssMin: `screen and (min-width: ${value}px)`,
                cssMax: end ? `screen and (max-width: ${end}px)` : undefined,
            },
        }
    },
    {} as Record<Breakpoint, { start: number; end?: number; cssMin: string; cssMax?: string }>
)

export const containerBreakpoints = Object.entries(breakpointsStart).reduce(
    (result, [key, value], idx, array) => {
        const start = value
        const end = idx < array.length - 1 ? array[idx + 1][1] : undefined
        return {
            ...result,
            [key]: {
                start,
                end,
                cssMin: `min-width: ${value}px`,
                cssMax: end ? `max-width: ${end}px` : undefined,
            },
        }
    },
    {} as Record<Breakpoint, { start: number; end?: number; cssMin: string; cssMax?: string }>
)

import React, { FC } from 'react'

import { ListWidgets } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/ListWidgets'

import { ChartAll } from 'v2/ui/svgs'

import { MAX_NUM_CHARTS, NEW_CHART_ATTRIBUTES } from './constants'

type ListChartsEditorProps = {
    charts: ChartBlockAttributes[]
    onStartEditingChart: (widget: ChartBlockAttributes) => void
    onAddChart: (widget: ChartBlockAttributes) => void
    onDeleteChart: (widgetOrder: number) => void
    onReorderCharts: (widgets: (ChartBlockAttributes & { previousOrder: number })[]) => void
}

export const ListChartsEditor: FC<ListChartsEditorProps> = ({
    charts,
    onStartEditingChart,
    onAddChart,
    onDeleteChart,
    onReorderCharts,
}) => (
    <ListWidgets
        widgets={charts}
        emptyTitle="Create your first chart"
        emptySubtitle="You can display up to three charts"
        EmptyIllustration={ChartAll}
        addButtonLabel="Add chart"
        maxWidgets={MAX_NUM_CHARTS}
        maxWidgetsReachedText={`Max ${MAX_NUM_CHARTS} charts per widget. Create a new widget to add more.`}
        newWidgetAttributes={NEW_CHART_ATTRIBUTES}
        onStartEditing={onStartEditingChart}
        onAddWidget={onAddChart}
        onDeleteWidget={onDeleteChart}
        onReorderWidgets={onReorderCharts}
        defaultLabelPrefix="Chart"
    />
)

import React from 'react'

import classNames from 'classnames'

import { useUnreadNotificationCount } from 'data/hooks/activities/notifications'
import { BadgeStyle } from 'features/Notifications/Notifications.css'

import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'

import {
    CollapseButtonContainerStyle,
    CollapseButtonStyle,
    CollapsedNotificationButtonStyle,
    HoverBarStyle,
    HoverIndicatorStyle,
} from './WorkspaceSidebarStyles.css'

type CollapseControlsProps = {
    isCollapsed: boolean
    toggleCollapsed: () => void
    isPoppedOut: boolean
}
export function CollapseControls({
    isCollapsed,
    toggleCollapsed,
    isPoppedOut,
}: CollapseControlsProps) {
    const { data: unreadNotifications = 0 } = useUnreadNotificationCount()
    const isMobile = useIsMobile()

    if (isMobile) return null

    return (
        <>
            {isCollapsed && <Box className={HoverBarStyle} />}
            {isCollapsed && !isPoppedOut && (
                <>
                    <Box className={HoverIndicatorStyle}>
                        {unreadNotifications > 0 && (
                            <Box
                                className={classNames(BadgeStyle, CollapsedNotificationButtonStyle)}
                            >
                                {unreadNotifications}
                            </Box>
                        )}
                    </Box>
                </>
            )}

            {(!isCollapsed || isPoppedOut) && (
                <Box className={CollapseButtonContainerStyle}>
                    <Button
                        className={CollapseButtonStyle}
                        onClick={toggleCollapsed}
                        variant="secondary"
                        aria-expanded={isCollapsed}
                    >
                        <Icon
                            name={!isCollapsed ? 'ChevronLeft' : 'ChevronRight'}
                            color="textWeakest"
                            size="xs"
                        />
                    </Button>
                </Box>
            )}
        </>
    )
}

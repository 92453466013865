import React from 'react'

import { NumberInput } from './NumberInput'
import type { InlineFilterInputComponent } from './types'

export const TextOrNumberInput: InlineFilterInputComponent<{}> = (props) => {
    switch (props.field.type) {
        case 'currency':
        case 'number':
        case 'percentage':
            return <NumberInput {...props} />
        default:
            return null
    }
}

import React, { useMemo } from 'react'

import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { TableViewColumn } from './types'

type TableViewCellContentProps = React.ComponentPropsWithoutRef<typeof Box> & {
    column: TableViewColumn
    isEmpty?: boolean
}

export const TableViewCellContent: React.FC<TableViewCellContentProps> = React.memo(
    function TableViewCellContent({ children, column, isEmpty, ...props }) {
        const { align } = column

        const justifyContent = useMemo(() => {
            switch (align) {
                case 'left':
                    return 'flex-start'
                case 'center':
                    return 'center'
                case 'right':
                    return 'flex-end'
            }
        }, [align])

        return (
            <Box flex grow center maxWidth="full" justifyContent={justifyContent} {...props}>
                {isEmpty ? (
                    <Body
                        color="textWeakest"
                        weight="regular"
                        style={{ fontSize: 'inherit', lineHeight: 'inherit' }}
                    >
                        None
                    </Body>
                ) : (
                    children
                )}
            </Box>
        )
    },
    isEqual
)

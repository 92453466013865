// @ts-strict-ignore
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { bindActionCreators } from 'redux'

import { userActions } from 'data/api/userActions'

function useEditMode(): {
    isOpen: boolean
    open: () => Promise<boolean>
    close: (options?: { leaveSlidingPaneOpen?: boolean }) => Promise<boolean>
} {
    const isOpen = useSelector((state) => state.user.isEditing)
    const dispatch = useDispatch()
    const boundUserActions = useMemo(() => bindActionCreators(userActions, dispatch), [dispatch])

    // @ts-ignore
    return useMemo(
        () => ({
            isOpen,
            open: boundUserActions.startEditing,
            close: boundUserActions.stopEditing,
        }),
        [isOpen, boundUserActions]
    )
}

export default useEditMode

import React, { forwardRef, useCallback } from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon, IconNameVariantType } from 'ui/components/Icon/Icon'
import { Body } from 'ui/components/Text'

import * as Parts from './Toast.parts'

import { StartIconStyle } from './Toast.css'

type IconProps = IconNameVariantType

type ToastRef = HTMLLIElement

type ToastProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Root>, 'asChild' | 'onClick'> & {
    title?: string
    startIcon?: IconProps
    helperText?: string
    showDismissButton?: boolean
    onClick?: () => void
}

export const Toast = forwardRef<ToastRef, ToastProps>(
    ({ helperText, title, showDismissButton = true, startIcon, onClick, ...props }, ref) => {
        const handleClick = useCallback(
            (e: React.MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()

                const target = e.target as HTMLElement

                // Allow clicking on the close button and other interactive elements.
                const isTargetInteractive = target.closest('button')
                if (isTargetInteractive) {
                    return
                }

                onClick?.()
            },
            [onClick]
        )

        return (
            <Parts.Root
                {...props}
                ref={ref}
                hasHelperText={Boolean(helperText)}
                role={!!onClick ? 'button' : undefined}
                onClick={handleClick}
            >
                {startIcon && <Icon size="xl" {...startIcon} className={StartIconStyle} />}
                <Box flex flexDirection="column" flexGrow={1}>
                    {title && (
                        <Parts.Title asChild>
                            <Body size="m" weight="bold">
                                {title}
                            </Body>
                        </Parts.Title>
                    )}
                    {helperText && (
                        <Parts.Description asChild>
                            <Body size="m">{helperText}</Body>
                        </Parts.Description>
                    )}
                </Box>
                {showDismissButton && (
                    <Parts.DismissButton asChild hasHelperText={Boolean(helperText)}>
                        <Button
                            size="xs"
                            type="button"
                            variant="ghost"
                            aria-label="Dismiss"
                            startIcon={{ name: 'X' }}
                            aria-expanded={false}
                        />
                    </Parts.DismissButton>
                )}
            </Parts.Root>
        )
    }
)

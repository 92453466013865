import get from 'lodash/get'
import queryString from 'qs'

import { isReadOnlyField } from 'features/admin/data-connector/utils'
import { getShortFieldName } from 'features/admin/utils'

const getAutoFillParams = (searchString) => {
    const autofill = Object.keys(searchString)
        .filter((key) => key.startsWith('autofill__'))
        .map((key) => {
            return {
                field: key.replace('autofill__', ''),
                value: searchString[key],
            }
        })

    return autofill
}

const processAutofill = (objects, objectId, search, autofillDone, onComplete) => {
    // If any 'autofill__*' parameters are provided, use them to pre-fill the form.
    // This is used for automatically linking recoreds created in a related list.
    // N.B. This is not full-featured filling yet.
    // For example it only suppoorts filling single string values (which will become singleton lists in list typed fields).

    const searchString = queryString.parse(search, {
        ignoreQueryPrefix: true,
    })

    if (!searchString) return

    const { previous } = searchString

    const objectToCreate = objects.find((o) => o._sid === objectId)
    const autofillFields = getAutoFillParams(searchString) || []
    const autoSave = searchString['autosave'] === '1'

    if (!objectToCreate || objectToCreate?.fields.length < 1) {
        return
    }

    if (!autofillFields || !autofillFields.length || autofillDone) return

    let autofillData = {}
    let relatedRecordIdsToRefresh = []
    for (const { field, field_sid, value } of autofillFields) {
        const fieldToFill = objectToCreate.fields.find((f) => {
            // Do not need to send through the full api name with the prefix, e.g.
            // users__email can be sent through as email
            const shortName = getShortFieldName(f)
            return f.api_name === field || f._sid === field_sid || shortName === field
        })
        if (fieldToFill) {
            // Don't fill fields the user is not permitted to create
            // Don'f fill fields which we know to be read-only
            const creatable_field_names = get(
                objectToCreate,
                '_permissions.may_create_fields',
                []
            ).concat(get(objectToCreate, '_permissions.maybe_may_create_fields', []))
            if (
                creatable_field_names.includes(fieldToFill.api_name) &&
                !isReadOnlyField(fieldToFill)
            ) {
                let valueToFill = value

                if (
                    ['multi_lookup', 'multi_select'].includes(fieldToFill.type) &&
                    !Array.isArray(value)
                ) {
                    valueToFill = [value]
                }

                if (fieldToFill.type == 'checkbox') {
                    valueToFill = stringToBoolean(value)
                }

                autofillData[fieldToFill.api_name] = valueToFill

                if (['lookup', 'multi_lookup'].includes(fieldToFill.type)) {
                    relatedRecordIdsToRefresh.push(value)
                }
            } else {
                console.log(`Autofill for ${fieldToFill.api_name} not applied - it is read-only.`)
            }
        } else {
            console.log(`Autofill for ${field} not applied - field not found.`)
        }
    }

    onComplete({
        autofillData,
        autoSave,
        previousPage: previous,
        autofillDone: true, // Only run processAutofill once, so we don't clobber any manual updates.
        relatedRecordIdsToRefresh,
    })
}

const stringToBoolean = (value) => {
    if (typeof value !== 'string') return false
    const trueValues = ['true', '1', 'yes']
    if (trueValues.includes(value.toLowerCase())) return true
    return false
}

export default processAutofill

import React, { useContext } from 'react'

import { RecordManager } from 'features/views/RecordManager/hooks/useRecordManagerState'

export type DetailViewContextValue = {
    view?: ViewDto
    recordManager: RecordManager
    title: string
}
export const DetailViewContext = React.createContext<DetailViewContextValue | null>(null)

export function useDetailViewContext(): DetailViewContextValue {
    const context = useContext(DetailViewContext)

    if (!context) {
        throw new Error('useDetailViewContext must be used within a DetailViewContextProvider')
    }

    return context
}

import { createContext } from 'react'

export type ModalType = 'users' | 'tableGrant'

export type UserModalContextValue = {
    mode: ModalType
    editData: any
    setMode: (mode: ModalType, grant?: AccessGrant | null) => void
}

export const UserModalContext = createContext<UserModalContextValue>({
    mode: 'users',
    editData: null,
    setMode: () => {},
})

import React from 'react'

import { useRadio } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Box, Button, Text } from 'v2/ui'

const IconWrapper = styled.div`
    border-radius: 5px;
    outline: 2px solid transparent;
    outline-offset: -1px;
    transition: outline-color 0.15s ease-out;
    height: 60px;
    width: 85px;
    overflow: hidden;
`

const StyledButton = styled(Button)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &:focus-visible,
    &.focus-visible {
        outline: none;
        box-shadow: none;
    }

    &,
    &:hover,
    &:active {
        background: none;
    }

    &:disabled {
        opacity: 0.4;
    }

    &[aria-checked='true'] .icon-wrapper,
    &:hover .icon-wrapper,
    &:focus-visible .icon-wrapper {
        outline-color: ${(props) => props.theme.colors?.userInterface.accent[1000]};
    }
`

const Label = styled(Text)`
    transition: opacity 0.15s ease-out;
`

type ViewHeaderDisplayRadioProps = React.ComponentPropsWithoutRef<typeof Button> & {
    label: string
    value: PageHeader['type']
    onChange: (newValue: PageHeader['type']) => void
    icon: React.ReactNode
    isChecked?: boolean
    isDisabled?: boolean
}

const ViewHeaderDisplayRadio = React.forwardRef<HTMLDivElement, ViewHeaderDisplayRadioProps>(
    ({ label, icon, onChange, value, ...props }, ref) => {
        const { getInputProps, state } = useRadio(props)
        const { isChecked, isDisabled } = state
        return (
            <label>
                <input type="radio" {...getInputProps()} hidden />
                <StyledButton
                    aria-checked={isChecked}
                    role="radio"
                    isDisabled={isDisabled}
                    ref={ref}
                    aria-label={label}
                    mr={2}
                    p={0}
                    {...props}
                    tabIndex={isChecked ? -1 : 0}
                    rounded={5}
                    as={Box}
                    onClick={() => onChange(value)}
                    value={value}
                >
                    <IconWrapper className="icon-wrapper">{icon}</IconWrapper>
                    <Label
                        as={Text}
                        size="sm"
                        mt={3}
                        opacity={isChecked ? 1 : 0.5}
                        color="neutral.900"
                    >
                        {label}
                    </Label>
                </StyledButton>
            </label>
        )
    }
)

export default ViewHeaderDisplayRadio

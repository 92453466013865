import React from 'react'

import { ActionMenuContextProvider } from 'features/views/ListView/Actions/ActionMenuContext'
import { FiltersContextProvider } from 'features/views/ListView/Filters/FiltersContext'
import { SearchContextProvider } from 'features/views/ListView/Search/SearchContext'
import { SortContextProvider } from 'features/views/ListView/Sort/SortContext'

import { BoardViewContextProvider } from './BoardViewContext'
import { BoardViewData } from './BoardViewData'
import { BoardViewListHeader } from './BoardViewListHeader'
import { BoardViewWrapper } from './BoardViewWrapper'

type BoardViewProps = {}

export const BoardView: React.FC<BoardViewProps> = React.memo(function BoardView() {
    return (
        <FiltersContextProvider>
            <SearchContextProvider>
                <SortContextProvider>
                    <BoardViewContextProvider>
                        <ActionMenuContextProvider>
                            <BoardViewWrapper>
                                <BoardViewListHeader />
                                <BoardViewData />
                            </BoardViewWrapper>
                        </ActionMenuContextProvider>
                    </BoardViewContextProvider>
                </SortContextProvider>
            </SearchContextProvider>
        </FiltersContextProvider>
    )
})

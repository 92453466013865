import React from 'react'

import { useWorkflowIntegrations } from 'data/hooks/workflows/integrations'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'

import { ConnectionAuthor } from './ConnectionAuthor'
import { IntegrationProviderIcon } from './IntegrationProviderIcon'
import { useAddNewConnectionModal } from './useAddNewConnectionModal'

type ConnectionDropdownProps = {
    value?: string
    onChange: (connection: WorkflowIntegrationConnectionDto) => void
    providerId: string
}

export const ConnectionDropdown: React.FC<ConnectionDropdownProps> = ({
    value,
    onChange,
    providerId,
}) => {
    const { data: integrationsData } = useWorkflowIntegrations()

    const providers = integrationsData?.providers ?? []

    const supportedConnections =
        integrationsData?.connections.filter((c) => c.provider_id === providerId) ?? []

    const selectedConnection = supportedConnections.find((c) => c.id === value)
    const selectedProvider = providers.find((p) => p.id === selectedConnection?.provider_id)

    const onAddNewConnection = (connection: WorkflowIntegrationConnectionDto) => {
        onChange(connection)
    }
    const addNewConnection = useAddNewConnectionModal(onAddNewConnection)

    return (
        <Dropdown>
            <DropdownButton variant="secondary" size="m" style={{ justifyContent: 'flex-start' }}>
                {selectedConnection ? (
                    <ConnectionDisplay
                        connection={selectedConnection}
                        provider={selectedProvider}
                    />
                ) : (
                    <>
                        <Box width="xl" height="xl" background="surfaceStronger" noShrink mr="m" />
                        Pick a connection
                    </>
                )}
            </DropdownButton>
            <DropdownContent align="end">
                {supportedConnections.map((connection) => {
                    const provider = providers.find((p) => p.id === connection.provider_id)
                    if (!provider) return null

                    return (
                        <DropdownItem
                            key={connection.id}
                            label={
                                <ConnectionDisplay connection={connection} provider={provider} />
                            }
                            multiSelect
                            checked={connection.id === selectedConnection?.id}
                            onCheckedChange={(checked) => {
                                if (checked) onChange(connection)
                            }}
                            closeOnSelect={true}
                        />
                    )
                })}
                <DropdownItem
                    key="new"
                    onClick={addNewConnection}
                    startIcon={{ name: 'Plus' }}
                    variant="action"
                    label="Add new connection"
                />
            </DropdownContent>
        </Dropdown>
    )
}

type ConnectionDisplayProps = {
    connection: WorkflowIntegrationConnectionDto
    provider?: WorkflowIntegrationProviderDto
}

const ConnectionDisplay: React.FC<ConnectionDisplayProps> = ({ connection, provider }) => {
    if (!provider) return null

    return (
        <Box
            fontWeight="bodyRegular"
            flex
            center
            gap="xs"
            py={!connection.user_id ? 's' : undefined}
        >
            <IntegrationProviderIcon providerId={provider.id} mr="2xs" />
            {provider.name}
            {connection.user_id && (
                <>
                    <Box as="span">by</Box>
                    <ConnectionAuthor userId={connection.user_id} fontSize="bodyM" />
                </>
            )}
        </Box>
    )
}

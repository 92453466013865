import React from 'react'

import { Input, Text } from 'v2/ui'

import { FormField } from 'ui/deprecated/forms/FormField'

import { ConfigurationComponentProps } from './common'

export const CurrencyFieldConfiguration: React.VFC<ConfigurationComponentProps> = ({}) => {
    return (
        <>
            <Text mb={2}>Currency symbol</Text>
            <FormField
                as={Input}
                name="options.currency_symbol"
                controlled
                // @ts-ignore
                controlledDefaultValue="$"
                variant="settings"
                required
                errorMessages={{ required: 'Enter a currency symbol' }}
            />
        </>
    )
}

// @ts-strict-ignore
import React, { useImperativeHandle } from 'react'

import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownContent,
    DropdownEndIndicatorShortcut,
    DropdownItem,
    DropdownTrigger,
} from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { stopPropagation } from 'ui/helpers/utilities'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { useLayoutEditorDnDWidgetState } from './hooks/useLayoutEditorDnDWidgetState'
import {
    LayoutEditorWidgetContextMenuHandle,
    useLayoutEditorWidgetContextMenuState,
} from './hooks/useLayoutEditorWidgetContextMenuState'
import { useLayoutEditorWidgetState } from './hooks/useLayoutEditorWidgetState'
import { LayoutEditorWidgetContextProvider } from './LayoutEditorWidgetContext'
import { Widget } from './types'

import { LayoutEditorWidgetControlsStyle, LayoutEditorWidgetStyles } from './LayoutEditor.css'

type LayoutEditorWidgetRef = HTMLDivElement

type LayoutEditorWidgetProps = React.ComponentPropsWithoutRef<typeof Box> & {
    widget: Widget
    isDragging?: boolean
    dragHandleProps?: React.ComponentPropsWithoutRef<typeof Box>
}

/**
 * This component is responsible for rendering widgets and their admin controls in the canvas.
 */
const LayoutEditorWidgetInner = React.forwardRef<LayoutEditorWidgetRef, LayoutEditorWidgetProps>(
    function LayoutEditorWidget({ widget, dragHandleProps, isDragging, ...props }, ref) {
        const {
            Component,
            isSelected,
            onClick,
            isSelectable,
            widgetLabel,
            isEditing,
            isDraggingAnotherWidget,
            onContextMenu,
            contextMenuRef,
            onRemoveWidget,
            onDuplicateWidget,
            onFocus,
        } = useLayoutEditorWidgetState({
            widget,
        })
        if (!Component) return null

        return (
            <ThemeProvider theme="admin">
                <LayoutEditorWidgetContextProvider widget={widget} isDragging={isDragging}>
                    <Box
                        ref={ref}
                        className={LayoutEditorWidgetStyles.styleFunction({
                            isSelected,
                            isSelectable,
                            isDragging,
                            isDraggingAnotherWidget,
                        })}
                        onClick={onClick}
                        onContextMenu={onContextMenu}
                        tabIndex={isSelectable ? 0 : undefined}
                        onFocus={onFocus}
                        {...props}
                    >
                        <ThemeProvider theme="default">
                            <Component
                                widget={widget}
                                isEditing={isEditing}
                                isSelected={isSelected}
                            />
                        </ThemeProvider>
                        {isSelected && !isDragging && (
                            <WidgetControls
                                widgetLabel={widgetLabel}
                                dragHandleProps={dragHandleProps}
                            />
                        )}
                    </Box>
                    <WidgetContextMenu
                        ref={contextMenuRef}
                        onRemoveWidget={onRemoveWidget}
                        onDuplicateWidget={onDuplicateWidget}
                    />
                </LayoutEditorWidgetContextProvider>
            </ThemeProvider>
        )
    }
)

export const LayoutEditorWidget = React.memo(LayoutEditorWidgetInner, isEqual)

type WidgetControlsProps = {
    widgetLabel: string
    dragHandleProps?: React.ComponentPropsWithoutRef<typeof Box>
}

const WidgetControls: React.FC<WidgetControlsProps> = ({ widgetLabel, dragHandleProps }) => {
    return (
        <Box className={LayoutEditorWidgetControlsStyle}>
            <Box background="theme500" flex center rounded="s" height="full" pr="xs">
                <Tooltip content="Move" asChild>
                    <Box
                        px="xs"
                        borderColor="theme400"
                        borderRightWidth="base"
                        height="full"
                        flex
                        center
                        justifyContent="center"
                        {...dragHandleProps}
                        style={{
                            ...dragHandleProps?.style,
                            cursor: 'grab',
                            touchAction: 'none',
                        }}
                        tabIndex={-1}
                    >
                        <Icon name="GripVertical" color="iconInverse" />
                    </Box>
                </Tooltip>
                <Box px="xs">
                    <Body size="s" color="textInverse">
                        {widgetLabel}
                    </Body>
                </Box>
            </Box>
        </Box>
    )
}

type LayoutEditorDnDWidgetProps = LayoutEditorWidgetProps & {}

export const LayoutEditorDnDWidget: React.FC<LayoutEditorDnDWidgetProps> = React.memo(
    function LayoutEditorDnDWidget(props) {
        const { attributes, listeners, setNodeRef, isDragging } = useLayoutEditorDnDWidgetState({
            widgetId: props.widget.id,
        })

        return (
            <LayoutEditorWidget
                ref={setNodeRef}
                isDragging={isDragging}
                dragHandleProps={{
                    ...listeners,
                    ...attributes,
                }}
                {...props}
            />
        )
    },
    isEqual
)

type WidgetContextMenuProps = {
    onRemoveWidget: () => void
    onDuplicateWidget: () => void
}

const WidgetContextMenu = React.forwardRef<
    LayoutEditorWidgetContextMenuHandle,
    WidgetContextMenuProps
>(function WidgetContextMenu({ onRemoveWidget, onDuplicateWidget }, ref) {
    const { openAt, close, isOpen, setIsOpen, posX, posY } = useLayoutEditorWidgetContextMenuState()

    useImperativeHandle(
        ref,
        () => ({
            openAt,
            close,
        }),
        [close, openAt]
    )

    if (!isOpen) return null

    return (
        <Dropdown open={isOpen} onOpenChange={setIsOpen}>
            <DropdownTrigger
                ref={(el) => {
                    if (!el) return

                    // This is a hack to make the dropdown trigger behave like a fixed element,
                    // even if the parent has transform set.
                    el.getBoundingClientRect = () => {
                        return {
                            width: 0,
                            height: 0,
                            x: posX,
                            left: posX,
                            bottom: posY,
                            y: posY,
                            right: posX,
                            top: posY,
                        } as DOMRect
                    }
                }}
                position="fixed"
                pointerEvents="none"
                display="none"
            />
            <DropdownContent
                onCloseAutoFocus={stopPropagation}
                onClick={stopPropagation}
                style={{
                    minWidth: '300px',
                }}
            >
                <DropdownItem
                    startIcon={{ name: 'Copy' }}
                    label="Duplicate widget"
                    onClick={onDuplicateWidget}
                    rightSlotContent={(slotProps) => (
                        <DropdownEndIndicatorShortcut
                            label={{
                                mac: '⌘D',
                                windows: 'Ctrl + D',
                            }}
                            {...slotProps}
                        />
                    )}
                />
                <DropdownItem
                    startIcon={{ name: 'Trash' }}
                    label="Remove widget"
                    variant="destructive"
                    onClick={onRemoveWidget}
                    rightSlotContent={(slotProps) => (
                        <DropdownEndIndicatorShortcut label="Delete" {...slotProps} />
                    )}
                />
            </DropdownContent>
        </Dropdown>
    )
})

import { useMemo } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { BoardViewAttribute } from 'features/views/ListView/BoardView/types'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseAttributesFromViewOptions = {
    titleFieldSid?: string
}

export function useAttributesFromView(options: UseAttributesFromViewOptions): BoardViewAttribute[] {
    const { titleFieldSid } = options

    const { view, visibleFields } = useListViewContext()

    const columns = useMemo(() => {
        if (!view.options.columns) return []

        const columnsByFieldSid = view.options.columns.reduce(
            (acc, column) => acc.set(column.fieldId, column),
            new Map<string, ListViewColumnConfig>()
        )

        const newAttributes = visibleFields.reduce((acc, field) => {
            // Don't show the title field in the card body when the `Show all fields` option is enabled.
            if (view.options.showAllFields && field._sid === titleFieldSid) {
                return acc
            }

            const column = columnsByFieldSid.get(field._sid)

            const title = column?.label ?? field.label

            const fieldIcon = getFieldIcon(field)

            const computedAttribute: BoardViewAttribute = {
                id: field._sid,
                title,
                field,
                rawOptions: column ?? {},
                type: 'content',
                icon: fieldIcon,
            }

            acc.push(computedAttribute)

            return acc
        }, [] as BoardViewAttribute[])

        return newAttributes
    }, [view.options.columns, view.options.showAllFields, visibleFields, titleFieldSid])

    return useDeepEqualsMemoValue(columns)
}

import React, { useContext } from 'react'

import { useRecordEditManagerContext } from 'features/records/useRecordEditManagerContext'
import { ListViewSort } from 'features/views/ListView/Sort/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { CardViewAttribute } from './types'

type CardViewInternalContextValue = {
    attributes: CardViewAttribute[]
    records?: RecordDto[]
    dereferencedRecords?: RecordDto[]
    recordCount: number
    isLoading: boolean
    isFetchingSlow: boolean
    retryFetchRecords: () => Promise<void>
    hasError: boolean
    object: ObjectDto
    stack: StackDto
    view: ViewDto
    currentPageIndex: number
    setCurrentPageIndex: (pageIndex: number) => void
    pageSize: number
    sortBy?: ListViewSort
    clearFilters?: () => void
    errorMessage?: string
    labelStyle?: ListViewOptions['cardLabelStyle']
    embeddedByField?: FieldDto
    embeddedByFieldValue?: string
    embeddedByRecord?: RecordDto
    hasFilters: boolean
    inlineFilterType?: ListViewInlineFilters
    availableInlineFilterFields: FieldDto[]
    isEmbedded: boolean
    allFields: FieldDto[]
    requestFilters: Filter[]
    requestIncludedFields: string[]
    coverImageField?: FieldDto
    profileImageField?: FieldDto
    titleSize: Required<ListViewOptions['cardTitleSize']>
    cardSize: Required<ListViewOptions['cardCardSize']>
    cardStyle: Required<ListViewOptions['cardCardStyle']>
    eyebrowField?: FieldDto
    titleField?: FieldDto
    subtitleField?: FieldDto
    header: ListViewHeader
    onRecordClick: Required<ListViewOptions['onRecordClick']>
}
export type CardViewContextValue = CardViewInternalContextValue & {
    pendingRecords: RecordDto[]
}
export const CardViewContext = React.createContext<CardViewInternalContextValue | null>(null)

export function useCardViewContext(): CardViewContextValue {
    const context = useContext(CardViewContext)

    if (!context) {
        throw new Error('useCardViewContext must be used within a CardViewContextProvider')
    }

    const { records, pendingNewRecords } = useRecordEditManagerContext()

    let isLoading = context.isLoading
    // On the initial record load, it may take a few frames
    // until the record manager picks up the first batch of records.
    if (records.length < 1 && !!context.records?.length) {
        isLoading = true
    }

    const pendingRecords = pendingNewRecords.map((record) => record.record)

    return useDeepEqualsMemoValue({
        ...context,
        isLoading,
        pendingRecords,
    })
}

import { useEffect, useMemo, useState } from 'react'

import { useRecords } from 'data/hooks/records'
import { extractPartsFromUserName } from 'features/views/attributes/utils'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export type UserRecordLinkAvatar = {
    imageUrl: string
    firstName: string
    lastName: string
    type: 'initial' | 'image'
}

const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

export function useUserRecordLinksAvatars(recordSids?: string[]) {
    const usersObject = useStackerUsersObject()

    const filters: Filter[] = [
        {
            field: { api_name: '_sid' }, // A fake field object for the SID "field".
            options: {
                value: recordSids,
                option: 'oneOf',
                operator: 'AND',
            },
        },
    ]

    const avatarApiNames = getAvatarFieldApiNames(usersObject)

    const { data, isError, isFetching, isLoading } = useRecords({
        objectSid: usersObject?._sid,
        filters,
        fetchOptions: {
            forcePartials: true,
            includeFields: avatarApiNames,
        },
        options: {
            enabled: !!usersObject && !!recordSids?.length,
        },
    })

    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const memoizedRecords = useDeepEqualsMemoValue(data?.records as RecordDto[] | undefined)

    const avatars = memoizedRecords?.map((r) => getAvatarFromUserRecord(r, usersObject))
    const memoizedAvatars = useDeepEqualsMemoValue(avatars)

    const effectiveIsLoading = isLoading || !memoizedRecords

    return useMemo(
        () => ({
            records: memoizedRecords,
            avatars: memoizedAvatars,
            isError,
            isFetchingSlow,
            isLoading: effectiveIsLoading,
        }),
        [memoizedRecords, memoizedAvatars, isError, isFetchingSlow, effectiveIsLoading]
    )
}

export function getAvatarFieldApiNames(usersObject?: ObjectDto) {
    if (!usersObject) return []

    return [`${usersObject?.api_name}__avatar`, `${usersObject?.api_name}__photo`]
}

export function isUsersRecord(record?: RecordDto, usersObject?: ObjectDto): boolean {
    return record?._object_id === usersObject?._sid
}

export function getAvatarFromUserRecord(
    record?: RecordDto,
    usersObject?: ObjectDto
): UserRecordLinkAvatar | undefined {
    const isUsersTable = isUsersRecord(record, usersObject)
    if (!isUsersTable) return undefined

    // If this is a user record, use the user's avatar.
    let imageUrl = ''
    const avatar =
        record?.[`${usersObject?.api_name}__avatar`] ?? record?.[`${usersObject?.api_name}__photo`]
    if (typeof avatar === 'string') {
        imageUrl = avatar
    } else if (Array.isArray(avatar)) {
        imageUrl = avatar[0].url
    }

    const recordPrimary = record?._primary || ''
    const label = typeof recordPrimary === 'string' ? recordPrimary : recordPrimary.toString()
    const { firstName, lastName } = extractPartsFromUserName(label)

    return {
        imageUrl,
        firstName,
        lastName,
        type: imageUrl ? 'image' : 'initial',
    }
}

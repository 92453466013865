import React, { forwardRef } from 'react'

import { Box } from 'ui/components/Box'
import { makeComponent } from 'ui/helpers/recipes'

import { SkeletonStyles } from './Skeleton.css'

const SkeletonBase = makeComponent(Box, SkeletonStyles)

type SkeletonRef = HTMLSpanElement

type SkeletonProps = React.ComponentPropsWithoutRef<typeof SkeletonBase> & {
    isLoading?: boolean
}

export const Skeleton = forwardRef<SkeletonRef, SkeletonProps>(
    ({ isLoading, children, ...props }, ref) => {
        if (!isLoading) return children as JSX.Element

        return (
            <SkeletonBase
                as="span"
                ref={ref}
                tabIndex={-1}
                aria-hidden
                isInline={!React.isValidElement(children)}
                {...props}
            >
                {children}
            </SkeletonBase>
        )
    }
)

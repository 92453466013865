import React from 'react'
import { useWatch } from 'react-hook-form'

import { NUMBER_TYPES } from 'data/utils/fieldDefinitions'
import { editableFieldTypeDefinitionMap } from 'features/admin/fields/definitions/editableFieldTypeDefinitions'
import { CreatableFieldTypeKey } from 'features/admin/fields/definitions/fieldTypeDefinitions'

import { Box } from 'ui/components/Box'
import { Dropdown } from 'ui/components/Dropdown'
import { Input } from 'ui/components/Input'
import { Select, SelectOption } from 'ui/components/Select'
import { FormField } from 'ui/deprecated/forms/FormField'

import { ConfigurationEditorProps } from './formulaConfigurationTypes'

export function NumberConfigurationEditor({ originalField }: ConfigurationEditorProps) {
    let selectedType: CreatableFieldTypeKey =
        useWatch({ name: 'type' }) || originalField?.type || 'number'

    if (!NUMBER_TYPES.includes(selectedType)) {
        selectedType = 'number'
    }

    return (
        <Box flex column>
            <Box flex center>
                <Box mr="m">Display as</Box>
                <FormField
                    as={Dropdown}
                    name="type"
                    controlled
                    controlledRender={({ field: formField }) => {
                        return (
                            <Select
                                value={selectedType}
                                onChange={formField.onChange}
                                onPointerDownOutside={(e) => {
                                    // Prevent other actions from firing when closing this in the data grid.
                                    e.detail.originalEvent.preventDefault()
                                    e.detail.originalEvent.stopPropagation()
                                }}
                            >
                                {NUMBER_TYPES.map((type) => (
                                    <SelectOption
                                        key={type}
                                        value={type}
                                        label={
                                            editableFieldTypeDefinitionMap[
                                                type as CreatableFieldTypeKey
                                            ].label
                                        }
                                    ></SelectOption>
                                ))}
                            </Select>
                        )
                    }}
                />
            </Box>

            {selectedType === 'currency' && (
                <Box flex center mt="m">
                    <Box mr="m">Currency symbol</Box>
                    <FormField
                        as={Input}
                        name="options.currency_symbol"
                        controlled
                        controlledDefaultValue="$"
                        required
                        errorMessages={{ required: 'Enter a currency symbol' }}
                        style={{ width: '60px' }}
                    />
                </Box>
            )}
        </Box>
    )
}

import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'
import { DotFilledIcon } from '@radix-ui/react-icons'

import { Icon } from 'ui/components/Icon'
import {
    ContentStyles,
    IconStyles,
    ItemIndicatorIconStyles,
    ItemIndicatorPlaceholderStyles,
    ItemIndicatorStyles,
    ItemStyles,
    LabelStyles,
    LeftSlotStyles,
    RightSlotStyles,
    SeparatorStyles,
    SubTriggerStyles,
} from 'ui/components/Menu/Menu.css'
import EmptyStyles from 'ui/helpers/emptyStyles.css'
import { makeComponent } from 'ui/helpers/recipes'

export const Root = ContextMenuPrimitive.Root
export const Trigger = makeComponent(ContextMenuPrimitive.Trigger, EmptyStyles)
export const Portal = ContextMenuPrimitive.Portal
export const Content = makeComponent(ContextMenuPrimitive.Content, ContentStyles)
export const Item = makeComponent(ContextMenuPrimitive.Item, ItemStyles)
export const LeftSlot = makeComponent('div', LeftSlotStyles)
export const RightSlot = makeComponent('div', RightSlotStyles)
export const MenuIcon = makeComponent(Icon, IconStyles)
export const CheckboxItem = makeComponent(ContextMenuPrimitive.CheckboxItem, ItemStyles)
export const CheckIcon = makeComponent(Icon, ItemIndicatorIconStyles)
export const RadioGroup = makeComponent(ContextMenuPrimitive.RadioGroup, EmptyStyles)
export const RadioButton = makeComponent(ContextMenuPrimitive.RadioItem, ItemStyles)
export const RadioIcon = makeComponent(DotFilledIcon, ItemIndicatorIconStyles)
export const ItemIndicator = makeComponent(ContextMenuPrimitive.ItemIndicator, ItemIndicatorStyles)
export const ItemIndicatorPlaceholder = makeComponent('div', ItemIndicatorPlaceholderStyles)
export const Label = makeComponent(ContextMenuPrimitive.Label, LabelStyles)
export const Separator = makeComponent(ContextMenuPrimitive.Separator, SeparatorStyles)
export const Sub = ContextMenuPrimitive.Sub
export const SubTrigger = makeComponent(ContextMenuPrimitive.SubTrigger, SubTriggerStyles)
export const SubContent = makeComponent(ContextMenuPrimitive.SubContent, ContentStyles)

import React, { useContext } from 'react'

export type SearchContextValue = {
    query: string
    setQuery: (query: string) => void
    allowSearch: boolean
}
export const SearchContext = React.createContext<SearchContextValue | null>(null)

export function useSearchContext(): SearchContextValue {
    const context = useContext(SearchContext)

    if (!context) {
        throw new Error('useSearchContext must be used within a SearchContextProvider')
    }

    return context
}

import { useContext } from 'react'

import { AccountUserContext } from 'app/AccountUserContext'

/**
 * @return {{ user: any, role: any, hasRight: function(string):boolean, isLoading:boolean }}
 */
export const useAccountUserContext = () => {
    return useContext(AccountUserContext)
}

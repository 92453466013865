import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { getLocale } from 'utils/date/dateUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseNumberAttributeDisplayStateProps = {
    field: FieldDto
    value?: number
    isLoading?: boolean
    showIcon?: boolean
}

export function useNumberAttributeDisplayState({
    field,
    value,
    isLoading,
    showIcon,
}: UseNumberAttributeDisplayStateProps) {
    const content = formatNumberValue(isLoading ? PLACEHOLDER_VALUE : value)

    const fieldIcon = getFieldIcon(field)
    const icon = useDeepEqualsMemoValue(
        showIcon && fieldIcon?.type === 'lucide' ? fieldIcon : undefined
    )

    return { content, icon }
}

const MAX_FRACTION_DIGITS = 5

function formatNumberValue(value?: number): string {
    if (typeof value !== 'number' || Number.isNaN(value)) return '0'

    const locale = getLocale()
    const formatter = new Intl.NumberFormat(locale, {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: MAX_FRACTION_DIGITS,
    })
    const formattedValue = formatter.format(value)

    return formattedValue
}

const PLACEHOLDER_VALUE = 123456.78901

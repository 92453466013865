import { Body } from 'ui/components/Text'
import { makeComponent } from 'ui/helpers/recipes'

import {
    ActionsContainerStyles,
    ContentContainerStyles,
    HelperStyles,
    IconContainerStyles,
    MainContainerStyles,
    TitleStyles,
    WrapperStyles,
} from './Banner.css'

export const Wrapper = makeComponent('div', WrapperStyles)
export const MainContainer = makeComponent('div', MainContainerStyles)
export const ContentContainer = makeComponent('div', ContentContainerStyles)
export const IconContainer = makeComponent('div', IconContainerStyles)
export const ActionsContainer = makeComponent('div', ActionsContainerStyles)
export const Title = makeComponent(Body, TitleStyles)
export const Helper = makeComponent(Body, HelperStyles)

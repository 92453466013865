// @ts-strict-ignore
import React, { useContext } from 'react'

import styled from '@emotion/styled'
import { isEmpty } from 'lodash'

import { DataGridContext } from 'features/workspace/AdminSideTray/manage-data/DataGridContext'

import { PopoverButton } from 'v2/ui'
import { SolidMenuHamburger } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { TableOrderableList, TableOrderableListProps } from './TableOrderableList'

const { colors } = stackerTheme()

const PopoverContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 400px;
    max-height: 60vh;

    border-radius: 6px;

    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    margin-top: 10px;

    background-color: ${colors.userInterface.neutral[0]};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`

const TablesListWrapper = styled.div`
    width: 100%;
    margin-top: 10px;
`

type ManageTablesDropdownProps = Omit<TableOrderableListProps, 'closeDropdown'>

const ManageTablesButton = React.forwardRef((props, ref) => (
    //@ts-expect-error
    <span ref={ref}>
        <SolidMenuHamburger {...props} />
    </span>
))

const ManageTablesDropdown = ({
    tables,
    selectedTableId,
    onSelectTable,
}: ManageTablesDropdownProps): React.ReactElement => {
    const { setReadOnly } = useContext(DataGridContext)
    return (
        <>
            {!isEmpty(tables) && (
                <Box ml="m" mr="m" flexShrink={0}>
                    <PopoverButton
                        style={{ cursor: 'pointer', paddingTop: '3px' }}
                        defaultIsOpen={false}
                        //@ts-expect-error
                        ButtonComponent={ManageTablesButton}
                        place="bottom-start"
                        onOpen={() => setReadOnly(true)}
                        onClose={() => setReadOnly(false)}
                    >
                        {({ close }) => (
                            <PopoverContentWrapper>
                                <Body weight="bold">Manage Tables</Body>
                                <TablesListWrapper>
                                    <TableOrderableList
                                        tables={tables}
                                        selectedTableId={selectedTableId}
                                        onSelectTable={onSelectTable}
                                        closeDropdown={close}
                                    />
                                </TablesListWrapper>
                            </PopoverContentWrapper>
                        )}
                    </PopoverButton>
                </Box>
            )}
        </>
    )
}

export default ManageTablesDropdown

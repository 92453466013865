import React, { forwardRef } from 'react'

import * as Parts from './Field.parts'

type RequiredMarkRef = HTMLDivElement

type RequiredMarkProps = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.RequiredMark>,
    'isDisabled'
> & {
    disabled?: boolean
}

export const RequiredMark = forwardRef<RequiredMarkRef, RequiredMarkProps>(
    ({ size = 'm', disabled, ...props }, ref) => {
        return (
            <Parts.RequiredMark
                ref={ref}
                isDisabled={disabled}
                size={size}
                weight="medium"
                {...props}
            >
                *
            </Parts.RequiredMark>
        )
    }
)

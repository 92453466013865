import React from 'react'

import { Box } from 'ui/components/Box'

import { useTableViewGridState } from './hooks/useTableViewGridState'
import { TableViewGridContextProvider } from './TableViewGridContext'

type TableViewGridProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const TableViewGrid: React.FC<TableViewGridProps> = React.memo(function TableViewGrid({
    children,
    ...props
}) {
    const { gridRef, onColumnResize } = useTableViewGridState()

    return (
        <Box
            ref={gridRef}
            display="grid"
            height="full"
            px={{ mobile: 'xl', tablet: 0 }}
            style={{
                minWidth: 'max-content',
            }}
            {...props}
        >
            <TableViewGridContextProvider onColumnResize={onColumnResize}>
                {children}
            </TableViewGridContextProvider>
        </Box>
    )
})

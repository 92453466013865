import { useMemo } from 'react'

import { GlobalStaticState } from 'app/GlobalStaticState'
import { DereferencedField, RelatedFeedData } from 'data/hooks/activities/types'
import { ActivityType, RelatedToType } from 'data/hooks/activityTypes'
import { LINK_TYPES } from 'data/utils/fieldDefinitions'
import { makeToast } from 'utils/toaster'
import { ensureArray } from 'utils/utils'

const ACTIVITY_LOCATION_ITEM_SEPARATOR = ';'
const ACTIVITY_LOCATION_VALUE_SEPARATOR = ':'

export type ActivityLocationParameters = Record<string, string | number>

export function serializeActivityLocationParameters(
    parameters: ActivityLocationParameters
): string {
    const result: string[] = []

    for (const [key, value] of Object.entries(parameters)) {
        result.push(`${key}${ACTIVITY_LOCATION_VALUE_SEPARATOR}${value}`)
    }

    result.sort()

    return result.join(ACTIVITY_LOCATION_ITEM_SEPARATOR)
}

export function deserializeActivityLocationParameters(
    location: string
): ActivityLocationParameters {
    const params = location.split(ACTIVITY_LOCATION_ITEM_SEPARATOR)

    return params.reduce<ActivityLocationParameters>((acc, param) => {
        const [key, value] = param.split(ACTIVITY_LOCATION_VALUE_SEPARATOR)
        if (!key || !value) return acc

        acc[key] = value

        return acc
    }, {})
}

export function makeDraftId(props: {
    relatedTo?: string
    relatedToType?: RelatedToType
    relatedToLocation?: string
    objectId?: string
    stackId?: string
    parentActivityId?: number
}): string {
    const { relatedTo, relatedToType, relatedToLocation, objectId, stackId, parentActivityId } =
        props

    const keyParts: string[] = []

    if (parentActivityId) keyParts.push(parentActivityId.toString())
    if (stackId) keyParts.push(stackId)
    if (objectId) keyParts.push(objectId)
    if (relatedTo) keyParts.push(relatedTo)
    if (relatedToType) keyParts.push(relatedToType.toString())
    if (relatedToLocation) keyParts.push(relatedToLocation)

    return keyParts.join('_')
}

/**
 * Validate activities against related data. We don't want to display
 * activities that have fields that we don't have access to, or that have broken links.
 * @param activities
 * @param relatedData
 */
export function useValidActivities(
    activities: ActivityDto[] | undefined,
    relatedData: RelatedFeedData
): ActivityDto[] | undefined {
    return useMemo(() => {
        if (!activities) return undefined

        const { fields = {}, records = [] } = relatedData

        const dereferencedRecords = groupRecordsBySid(records)

        return activities.reduce<ActivityDto[]>((acc, activity) => {
            switch (activity.type) {
                case ActivityType.RecordCreated:
                case ActivityType.RecordUpdated: {
                    const newActivity = removeActivityInvalidFields(
                        activity,
                        fields,
                        dereferencedRecords
                    )
                    if (newActivity) acc.push(newActivity)

                    break
                }
                default:
                    acc.push(activity)
            }

            return acc
        }, [])
    }, [activities, relatedData])
}

function removeActivityInvalidFields(
    activity: ActivityDto,
    fields: RelatedFeedData['fields'],
    records: Record<string, RecordDto>
): ActivityDto | null {
    const newContent: Record<string, unknown> = {}
    for (const [api_name, value] of Object.entries(activity.content)) {
        const field = fields[activity.stack_id]?.[activity.object_id]?.[api_name]
        if (!field) continue

        // Check if this is a link field, and if the link target is valid, and we can access it.
        const isLink = LINK_TYPES.includes(field.type)
        const isLinkValid = isRecordLinkValid(field, value, records)

        if (isLink && !isLinkValid) continue

        newContent[api_name] = value
    }

    if (Object.keys(newContent).length < 1) return null

    return {
        ...activity,
        content: newContent,
    }
}

function isRecordLinkValid(
    field: DereferencedField,
    value: any,
    dereferencedRecords: Record<string, RecordDto>
): boolean {
    // Don't let us display links if we don't have access to the target.

    const isLink = LINK_TYPES.includes(field.type)
    if (!isLink) return false

    if (field.type === 'lookup') {
        return !!dereferencedRecords[value]
    } else if (field.type === 'multi_lookup') {
        return ensureArray(value as string[]).every((v) => !!dereferencedRecords[v])
    }

    return false
}

export function groupRecordsBySid(records: RecordDto[]): Record<string, RecordDto> {
    return records.reduce<Record<string, RecordDto>>((acc, record) => {
        acc[record._sid] = record
        return acc
    }, {})
}

export const checkActivityIsReadOnly = () => {
    if (GlobalStaticState.getPreviewingAsUser()) {
        makeToast(
            'activityDisabled',
            'This feature is not available while previewing as another user.'
        )
        return true
    }
    return false
}

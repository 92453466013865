import React from 'react'

import { Avatar } from 'v2/ui'

import { Box, BoxProps } from 'ui/components/Box'

import { Highlighter } from './Highlighter'
import { UserSearchItem } from './userSearchTypes'

export function UserSearchResult({
    item,
    size = 'xs',
    queryTerms,
    ...props
}: BoxProps & { item: UserSearchItem; size?: 'xs' | 'sm'; queryTerms: string[] }) {
    return (
        <Box flex center py={size === 'xs' ? undefined : 'xs'} {...props}>
            <Avatar
                src={item.avatar}
                name={item.label}
                size={size === 'xs' ? '2xs' : 'xs'}
                mr={size === 'xs' ? 1.5 : 2}
                flexShrink={0}
            />
            <Box as="span" trim>
                <Highlighter terms={queryTerms}>{item.label}</Highlighter>
            </Box>
        </Box>
    )
}

import React from 'react'

import { Progress as ChakraProgress, ProgressProps as ChakraProgressProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { BaseColor } from 'v2/ui/theme/styles/types'

type ProgressProps = ChakraProgressProps & {
    barColor?: BaseColor
}

const Progress: React.FC<ProgressProps> = (props) => <StyledProgress {...props} />

// This lets us use a color scheme that doesn't require a .500 value like if we use
// the colorScheme prop
const StyledProgress = styled(ChakraProgress)<ProgressProps>`
    & > div {
        background: ${(props) =>
            !props.barColor ? '' : (props.theme.colors?.[props.barColor] ?? props.barColor)};
    }
`

export default Progress

// @ts-strict-ignore
import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import useWindowSize from 'v2/views/utils/useWindowSize'

import { useAppContext } from 'app/useAppContext'
import { useLDFlags } from 'data/hooks/useLDFlags'
import { withDataConnections } from 'data/wrappers/withDataConnections'

import { SidebarItem } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { Flex } from 'ui/deprecated/atoms/Flex'

import {
    appSettingsMenuItems,
    BORDER_COLOR,
    cleanDataSourcesMenuState,
    fadeInAnimation,
} from './appSettingsModalSidebarInner'

const Container = styled(Flex)`
    position: relative;

    & > div {
        position: relative;
    }

    .fadeIn {
        animation: ${fadeInAnimation} 0.2s linear;
    }

    ${
        '' /* .slideInLeft {
        animation: ${slideInLeftAnimation} 0.2s ease-out;
    } */
    }
`

export function DataSourcesSecondaryMenu({ dataSourcesMenuState, dataSourcesMenuStateFns }) {
    return (
        // @ts-expect-error
        <Flex
            column
            className="slideInLeft"
            style={{
                width: 160,
                borderRight: `1px solid ${BORDER_COLOR}`,
                overflowY: 'auto',
                padding: '20px 10px',
                flexShrink: 0,
                zIndex: 1,
            }}
        >
            {dataSourcesMenuState.secondaryMenuSectionState.available.map((menuItem) => (
                <>
                    <SidebarItem
                        key={menuItem.section}
                        icon={menuItem.icon}
                        active={
                            dataSourcesMenuState.secondaryMenuSectionState.selected ===
                            menuItem.section
                        }
                        onChange={() => {
                            dataSourcesMenuStateFns.setSelectedSecondaryMenuSection(
                                dataSourcesMenuState.selectedObject,
                                menuItem.section
                            )
                        }}
                        classes={
                            menuItem.section === 'permissions'
                                ? ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.PERMISSIONS_ITEM
                                : ''
                        }
                    >
                        {menuItem.name}
                    </SidebarItem>
                    <div style={{ height: 1 }} />
                </>
            ))}
        </Flex>
    )
}

/**
 *
 * @param {{onChangePage: (page: AppSettingsPage) => Promise<any>, [keyof: string]: any}} param0
 * @returns
 */
function InnerSidebar({ onChangePage, pageName, dataConnections, pageOptions = undefined }) {
    const { workspaceAccount, selectedStack } = useAppContext()
    const { flags: ldflags } = useLDFlags()
    const { width: windowWidth } = useWindowSize()

    const [dataSourcesMenuState, _setDataSourcesMenuState] = useState(
        cleanDataSourcesMenuState(pageOptions, ldflags)
    )

    // when page options change, we need to update our menu state
    // so the correct nav items are selected
    useEffect(() => {
        _setDataSourcesMenuState(cleanDataSourcesMenuState(pageOptions, ldflags))
    }, [pageOptions, workspaceAccount, selectedStack, ldflags])

    const dataSourcesMenuStateFns = {
        resetState() {
            _setDataSourcesMenuState(cleanDataSourcesMenuState(undefined, ldflags))
        },
        setSelectedSecondaryMenuSection(object, selectedSecondaryMenuSection) {
            const dataConnection = dataConnections.find((dc) => dc._sid === object.data_connection)
            onChangePage({
                name: 'object',
                meta: {
                    dataConnection,
                    object,
                    section: selectedSecondaryMenuSection,
                },
            }).then(() => {
                _setDataSourcesMenuState((state) => ({ ...state, selectedSecondaryMenuSection }))
            })
        },
    }

    return (
        // @ts-expect-error
        <Container>
            {/* @ts-expect-error */}
            <Flex
                column
                className="first-col"
                style={{
                    width: (windowWidth ?? 0) < 1366 ? 180 : 220,
                    borderRight: `1px solid ${BORDER_COLOR}`,
                    flexShrink: 0,
                    zIndex: 2,
                    background: 'white',
                    borderBottomLeftRadius: '0.375rem',
                    borderTopLeftRadius: '5px',
                }}
            >
                <div
                    style={{
                        padding: 16,
                        paddingTop: 12,
                        borderTop: 0,
                    }}
                >
                    {appSettingsMenuItems
                        .filter((menuItem) => {
                            if (typeof menuItem.disabled === 'function') {
                                return !menuItem.disabled(selectedStack, workspaceAccount, ldflags)
                            }
                            return true
                        })
                        .map((menuItem, index) => (
                            <React.Fragment key={index}>
                                <SidebarItem
                                    key={menuItem.page}
                                    icon={menuItem.icon}
                                    active={
                                        !dataSourcesMenuState.selectedObject &&
                                        pageName === menuItem.page
                                    }
                                    onChange={() => {
                                        // reset state in data sources menu when selecting a new top level page
                                        dataSourcesMenuStateFns.resetState()

                                        onChangePage({
                                            name: menuItem.page,
                                        })
                                    }}
                                    classes={menuItem.class || ''}
                                >
                                    {menuItem.name}
                                </SidebarItem>
                                <div style={{ height: 1 }} />
                            </React.Fragment>
                        ))}
                </div>
            </Flex>

            {!!dataSourcesMenuState.selectedObject && !ldflags?.dataGridSidePane && (
                <DataSourcesSecondaryMenu
                    dataSourcesMenuState={dataSourcesMenuState}
                    dataSourcesMenuStateFns={dataSourcesMenuStateFns}
                />
            )}
        </Container>
    )
}

export const Sidebar = withDataConnections(InnerSidebar)

import React, { useMemo } from 'react'

import { NodeViewRendererProps } from '@tiptap/core'
import { NodeViewWrapper } from '@tiptap/react'

import { MentionsItemStyles } from './MentionsExtension.css'

type MentionComponentProps = NodeViewRendererProps & {}
export const MentionComponent: React.FC<MentionComponentProps> = ({ node, extension }) => {
    const userId = node.attrs.id
    const users = extension.options.fetchUsersFn() as UserDto[]

    const user = useMemo(() => users.find((u) => u._sid === userId), [userId, users])
    const label = user?.name || user?.email || node.attrs.label

    return (
        <NodeViewWrapper as="span" className={MentionsItemStyles} contentEditable={false}>
            @{label}
        </NodeViewWrapper>
    )
}

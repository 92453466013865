import { makeAppVariables } from './app'

export function makeBreadcrumbsVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            textItem: s.color.textWeaker,
            textItemHover: s.color.textTheme,
            textDataset: s.color.text,
            textCurrent: s.color.text,

            separator: s.color.textWeakest,

            icon: s.color.icon,
            iconHover: s.color.iconTheme,
        },
        gap: {
            betweenItems: s.space.xs,
            betweenParts: s.space.xs,
        },
        fontSize: s.fontSizes.bodyM,
        lineHeight: s.lineHeights.bodyM,
        fontWeights: {
            item: s.fontWeights.bodyRegular,
            dataset: s.fontWeights.bodyRegular,
        },
        shadows: {
            focus: s.shadows.focus,
        },
    }
}

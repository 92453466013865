import { createContext, useContext, useMemo } from 'react'

import { useProcessFilter } from 'features/records/components/RecordFilters'

import { useActionsFromObjectId } from './helpers'
import type { ActionsContext as ActionsContextType } from './types'

export const ActionsContext = createContext<ActionsContextType | undefined>(undefined)

export function useActionsContext() {
    const context = useContext(ActionsContext)
    if (!context) {
        throw new Error(
            "Can't access actions context provider, is your component wrapped in <ActionsContextProvider/> ?"
        )
    }

    return context
}

export function useActionButtons(
    selectedItems: ActionButton[],
    record: RecordDto,
    object: ObjectDto,
    additionalActions: ActionDto[] = []
) {
    const { data: actions } = useActionsFromObjectId(object?._sid)
    const processFilter = useProcessFilter()

    // want to re-evaluate available buttons if the record changes
    // or the user's role changes.
    return useMemo(() => {
        if (!selectedItems || !actions || !record) return []

        return selectedItems
            .filter(({ conditions }) => {
                if (!conditions) return true

                const filteredData = processFilter([record], conditions)
                return filteredData.length > 0
            })
            .map((actionButton) => ({
                action: additionalActions
                    .concat(actions)
                    .find((action) => action._sid === actionButton.id) as ActionDto,
                config: actionButton,
            }))
            .filter(({ action }) => action)
    }, [selectedItems, actions, record, additionalActions, processFilter])
}

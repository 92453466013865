import React from 'react'

import isRichTextField from 'utils/isRichTextField'

import FieldContainerEditor from './FieldContainerEditor'

const HIGHLIGHTABLE_FIELDS = [
    'string',
    'url',
    'number',
    'lookup',
    'date',
    'datetime',
    'dropdown',
    'currency',
    'percentage',
    'checkbox',
]

export const MAX_HIGHLIGHT_ITEMS = 8

const filterField = (field) => {
    return HIGHLIGHTABLE_FIELDS.includes(field.type) && !isRichTextField(field)
}

const FieldHighlightsEditor = (props) => (
    <FieldContainerEditor
        {...props}
        filterField={filterField}
        disallowSections={true}
        hideFieldRequiredSetting={true}
        hideFieldFullWidthSetting={true}
        hideFieldDescriptionSetting={true}
        hideCreateButtonSetting={true}
        maxItemsSelected={MAX_HIGHLIGHT_ITEMS}
    />
)

export default FieldHighlightsEditor

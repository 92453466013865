// @ts-strict-ignore
import React, { useState } from 'react'

import { refetchObjects } from 'data/hooks/objects/refetchObjects'

import { Divider, Flex, Icon, Modal, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'
import useModalToggle from 'v2/ui/utils/useModalToggle'

import { IMPORT_PROGRESS_MODAL_KEY } from './importProgressModalConstants'
import ImportProgressUpdate from './ImportProgressUpdate'

const { colors } = stackerTheme()

export const ImportProgressModal = () => {
    const { isOpen, toggle, data } = useModalToggle<{
        jobId: string
        onImportCompleted: (string) => void
        onImportError: (errorDetails?: any) => void
    }>(IMPORT_PROGRESS_MODAL_KEY)
    const [error, setError] = useState<string | undefined>()

    const cleanup = () => {
        setError(undefined)
    }

    const onComplete = async (objectId, errorDetails): Promise<void> => {
        cleanup()
        await refetchObjects()
        toggle()
        if (!errorDetails) {
            data.onImportCompleted(objectId)
            return
        }

        data.onImportError?.()
    }

    return (
        <Modal
            isOpen={isOpen}
            size="370px"
            showCloseButton={false}
            onClose={() => null}
            errorMessage={error}
            isSecondLayer
            data-testid="import-progress-modal"
            noDividers
        >
            <Flex
                direction="row"
                alignItems="center"
                mb={1}
                justifyContent="center"
                style={{ width: '100%' }}
            >
                <Icon
                    icon="tableLight"
                    color={colors.userInterface.neutral[1000]}
                    size="16px"
                    mr={2}
                />
                <Text variant="modalTitle">Importing CSV</Text>
            </Flex>

            <Divider my={3} />

            <ImportProgressUpdate jobId={data?.jobId} onComplete={onComplete} />
        </Modal>
    )
}

import React from 'react'

import { IconStyle } from '@fortawesome/fontawesome-common-types'
import { IconName } from '@fortawesome/fontawesome-svg-core'

import { Image } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Icon } from 'ui/deprecated/components/Icon'

type ItemIconProps = BoxProps & {
    icon: IconName
    iconStyle?: IconStyle
    iconUrl?: string
}

export function ItemIcon({ icon, iconStyle, iconUrl, ...props }: ItemIconProps) {
    const variant = iconStyle || 'light'

    return (
        <Box
            width="4xl"
            height="4xl"
            fontSize="bodyM"
            background="surfaceStronger"
            color="textWeak"
            flex
            center
            justifyContent="center"
            noShrink
            rounded="m"
            p={iconUrl ? 'xs' : undefined}
            {...props}
        >
            {iconUrl ? <Image src={iconUrl} alt="" /> : <Icon name={icon} variant={variant} />}
        </Box>
    )
}

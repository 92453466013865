import React from 'react'

import { Editor } from '@tiptap/core'

import * as Parts from 'ui/components/Textarea/Textarea.parts'

import { TextareaToolbarItem } from './TextareaToolbarItem'

type TextareaToolbarHeadingFormatProps = React.ComponentPropsWithoutRef<
    typeof Parts.ToolbarGroup
> & {
    editor: Editor
    disabled?: boolean
}

export const TextareaToolbarHeadingFormat: React.FC<TextareaToolbarHeadingFormatProps> = ({
    editor,
    disabled,
    ...props
}) => {
    return (
        <Parts.ToolbarGroup aria-label="Heading format" {...props}>
            <TextareaToolbarItem
                icon={{ name: 'Heading1' }}
                label="Heading Level 1"
                isActive={editor.isActive('heading', { level: 1 })}
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                disabled={disabled || !editor.can().toggleHeading({ level: 1 })}
            />
            <TextareaToolbarItem
                icon={{ name: 'Heading2' }}
                label="Heading Level 2"
                isActive={editor.isActive('heading', { level: 2 })}
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                disabled={disabled || !editor.can().toggleHeading({ level: 2 })}
            />
            <TextareaToolbarItem
                icon={{ name: 'Heading3' }}
                label="Heading Level 3"
                isActive={editor.isActive('heading', { level: 3 })}
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                disabled={disabled || !editor.can().toggleHeading({ level: 3 })}
            />
        </Parts.ToolbarGroup>
    )
}

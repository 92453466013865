import React, { forwardRef } from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { NoItemsFoundStyle } from 'ui/components/Menu/Menu.css'
import { Body } from 'ui/components/Text'

type DropdownEmptySearchRef = HTMLDivElement

type DropdownEmptySearchProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const DropdownEmptySearch = forwardRef<DropdownEmptySearchRef, DropdownEmptySearchProps>(
    ({ className, ...props }, ref) => {
        return (
            <Box {...props} ref={ref} className={classNames(className, NoItemsFoundStyle)}>
                <Body size="m" weight="regular" color="textHelper" style={{ lineHeight: 1 }}>
                    No items found
                </Body>
            </Box>
        )
    }
)

import React from 'react'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'
import { Textarea } from 'ui/components/Textarea'
import { theme } from 'ui/styling/Theme.css'

import { useListHeaderDefaultControlsState } from './hooks/useListHeaderDefaultControlsState'

type ListHeaderDefaultControlsProps = {
    header: ListViewHeaderDefault
    updateHeader: (header: Partial<ListViewHeaderDefault>) => void
}

export const ListHeaderDefaultControls: React.FC<ListHeaderDefaultControlsProps> = ({
    updateHeader,
    header,
}) => {
    const { title, handleChangeTitle, titlePlaceholder, subtitle, handleChangeSubtitle } =
        useListHeaderDefaultControlsState({
            header,
            updateHeader,
        })

    return (
        <Box flex flexDirection="column" gap="l">
            <Input
                label="Title"
                value={title}
                onChange={handleChangeTitle}
                placeholder={titlePlaceholder}
            />
            <Textarea
                label="Subtitle"
                value={subtitle}
                onChange={handleChangeSubtitle}
                autoSize
                style={{
                    paddingTop: theme.input.padding.verticalM,
                    paddingBottom: theme.input.padding.verticalM,
                    width: '100%',
                    minHeight: theme.input.size.m,
                    alignItems: 'center',
                }}
                textareaProps={{
                    style: {
                        maxHeight: theme.textarea.size.m,
                    },
                }}
            />
        </Box>
    )
}

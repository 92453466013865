// @ts-strict-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { ListViewOrderBy } from 'v2/views/utils/orderBy/types'

import { useObject } from 'data/hooks/objects'
import { useRecords } from 'data/hooks/records'
import { createRecord, updateRecord } from 'data/hooks/records/recordOperations'

type WithRecordsReturnProps = {
    isLoading: boolean
    loadingFailed: boolean
    records: RecordDto[]
    object: ObjectDto | undefined
    primaryField?: string
    onChange: (id: string, data: object, options: any) => Promise<any>
    isServerLoading: boolean
    dereferencedRecords: RecordDto[]
    addRecord: (record: RecordDto) => Promise<RecordDto>
}

type WithRecordsProps = {
    objectSid: string
    filters?: Filter[]
    options?: {
        dereference?: boolean
        dereferenceMultiFields?: string | string[]
        includeFields?: string[]
        disablePartials?: boolean
        orderBy?: ListViewOrderBy
        viewId?: string
        pageSize?: number
        pageIndex?: number
        stackId?: string
        manualOrderKey?: string
    }
    onRecordsLoadStarted?: () => void
    ignoreLoading?: boolean
    onRecordsLoaded?: () => void
    onError?: () => void
    children: (props: WithRecordsReturnProps) => React.ReactNode
    loading?: any
    renderOnError?: boolean
    internalOptions?: {
        lookupObjects?: string[]
        disableRealtimeUpdates?: boolean
    }
}

const WithRecords = ({
    objectSid,
    filters,
    options,
    onRecordsLoadStarted,
    ignoreLoading,
    onRecordsLoaded,
    onError,
    children,
    loading,
    renderOnError,
    internalOptions,
}: WithRecordsProps) => {
    const { object, primaryField, schemaTimestamp } = useObject(objectSid)
    const {
        data: { records = [], dereferencedRecords = [] } = {},
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useRecords({
        objectSid,
        filters,
        fetchOptions: options,
        schemaTimestamp,
        internalOptions,
    })
    const [initialised, setInitialised] = useState(false)
    const showIsLoading = isLoading && !ignoreLoading
    const prevRecords = useRef<any>(records)
    const prevDereferencedRecords = useRef<any>(dereferencedRecords)
    const prevErrorState = useRef<boolean>(isError)

    useEffect(() => {
        if (!initialised) {
            if (showIsLoading && onRecordsLoadStarted) {
                onRecordsLoadStarted()
            }

            if (!showIsLoading && isSuccess) {
                if (onRecordsLoaded) onRecordsLoaded()
                setInitialised(true)
            }
        }
    }, [initialised, isLoading, onRecordsLoadStarted, onRecordsLoaded, showIsLoading, isSuccess])

    //Data loading error
    useEffect(() => {
        // We need to be careful here as onError might cause a re-render and we'd get a loop, so always check the previous state
        if (prevErrorState.current !== isError) {
            if (isError && onError) {
                onError()
            }
            prevErrorState.current = isError
        }
    }, [isError, onError])

    // Store the prev records - this is so that we do not show loading states when we are fetching new records
    useEffect(() => {
        if (records && records.length) {
            prevRecords.current = records
            prevDereferencedRecords.current = dereferencedRecords
        }
    }, [filters, options, records, dereferencedRecords])

    const addRecord = useCallback(
        async (record: RecordDto) => {
            const newRecord = await createRecord(
                {
                    ...record,
                    object_id: objectSid,
                } as RecordDtoForCreation,
                // all fields are returned when creating record via this method - this HoC is deprecated and
                // intentionally not optimised
                undefined
            )
            await refetch()
            return newRecord
        },
        [objectSid, refetch]
    )

    if (!isSuccess && !initialised && loading && (!isError || renderOnError === false))
        return loading

    if (!isSuccess && !initialised && !ignoreLoading && (!isError || renderOnError === false))
        return null

    return (
        <>
            {children({
                isLoading: showIsLoading && !initialised,
                loadingFailed: isError,
                // This will stop the glitching that we get when changing filters. We keep any prev records around until we've fetched
                // the new records
                records: isLoading && prevRecords.current.length ? prevRecords.current : records,
                dereferencedRecords:
                    isLoading && prevDereferencedRecords.current.length
                        ? prevDereferencedRecords.current
                        : dereferencedRecords,
                object,
                primaryField,
                onChange: updateRecord,
                addRecord,
                // we want to let the children know that we're waiting on results from the server
                // if we haven't yet received the initial payload, or we're refreshing due to changed
                // filter/sort params
                isServerLoading: isLoading,
            })}
        </>
    )
}

export default WithRecords

import React from 'react'

import { ActionInput } from './ActionInput'

type InputGroupProps = {
    action: WorkflowActionConfig
    title: string
    items: (WorkflowSchemaNodeInput | WorkflowSchemaNodeInputGroup)[]
    contextSchema: WorkflowSchemaStateItem[]
    onChange: (action: Omit<Partial<WorkflowActionConfig>, 'id'>) => void
    path?: string[]
}
export function ActionInputGroup({ items, path, ...props }: InputGroupProps) {
    return (
        <>
            {items?.map((input, index) => {
                const id = input.id
                const effectivePath = path ? [...path, id] : [id]

                if ('items' in input) {
                    return (
                        <ActionInputGroup
                            {...props}
                            key={index}
                            title={input.name}
                            items={input.items}
                            path={effectivePath}
                        />
                    )
                }

                return <ActionInput key={input.id} {...props} input={input} path={path} />
            })}
        </>
    )
}

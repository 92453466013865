// @ts-strict-ignore
import React, { useMemo } from 'react'

import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'

import { Select, SelectOption } from 'ui/components/Select'

export const TablePicker = ({ objects, error, ...rest }) => {
    const filteredObjects = useMemo(() => {
        return objects.filter(
            (obj: ObjectDto) => !obj.connection_options?.is_hidden && !getIsStackerUserObject(obj)
        )
    }, [objects])

    const noOptions = filteredObjects.length < 1

    return (
        <Select
            label="Table"
            startIcon={{ name: 'Table' }}
            placeholder={noOptions ? 'No available options' : 'Select table...'}
            disabled={noOptions}
            isError={!!error}
            helperText={!!error ? error.message : null}
            isClearable
            {...rest}
        >
            {filteredObjects.map(({ _sid, name }) => (
                <SelectOption key={_sid} value={_sid} label={name} />
            ))}
        </Select>
    )
}

import { fetchAndReturn } from 'data/utils/utils'
import { StackerFormulaSyntaxTree } from 'features/formulas/parser/formulaParserTypes'

export enum FormulaCheckStatus {
    OK = 'OK',
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    PARSE_ERROR = 'PARSE_ERROR',
}

export type FormulaCheckResponse = {
    status: FormulaCheckStatus.OK
} & {
    status: FormulaCheckStatus.INTERNAL_ERROR | FormulaCheckStatus.PARSE_ERROR
    message: string
}

export const checkFormula = (
    field: FieldDto | undefined,
    object: ObjectDto,
    formula: StackerFormulaSyntaxTree
): Promise<FormulaCheckResponse> => {
    return fetchAndReturn(
        `formula/check`,
        {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                object: object._sid,
                field: field?._sid,
                formula,
            }),
        },
        null,
        null,
        { bypassPreviewAs: true }
    ) as Promise<FormulaCheckResponse>
}

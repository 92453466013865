// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'

import { Box, Button, Collapse, Flex, Input, Modal, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const ErrorText = styled(Text)`
    font-size: 12px;
    color: ${colors.userInterface.error[600]};
    margin-bottom: 10px;
`

type PromptTableNameModalProps = {
    isOpen: boolean
    value?: string
    error?: string
    onSubmit: () => void
    onChange: (tableName: string) => void
}
const PromptTableNameModal = (props: PromptTableNameModalProps) => {
    const { value, onSubmit, isOpen, onChange, error } = props
    return (
        <Modal
            noDividers
            size="310px"
            isOpen={isOpen}
            showCloseButton={false}
            onClose={() => null}
            padding="30px"
        >
            <Flex
                direction={'column'}
                style={{
                    width: '100%',
                }}
            >
                <Text
                    fontSize="20px"
                    fontWeight="bold"
                    color="userInterface.neutral.1000"
                    mb="30px"
                >
                    Add new table
                </Text>
                <Text
                    style={{
                        alignSelf: 'flex-start',
                        color: colors.userInterface.neutral[1000],
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }}
                >
                    Table Name
                </Text>
                <Input
                    mt={2}
                    alignSelf="stretch"
                    placeholder="e.g. Customers, Products, Events…"
                    value={value}
                    maxLength={30}
                    onChange={(e) => onChange(e.target.value)}
                    autoFocus
                    variant="admin"
                    width="100%"
                    className={error ? 'invalid' : ''}
                />
                <Box alignSelf="flex-start">
                    <Collapse
                        isOpen={!!error}
                        mt={2}
                        width="100%"
                        style={{ alignSelf: 'flex-start' }}
                    >
                        <ErrorText>{error}</ErrorText>
                    </Collapse>
                </Box>
                <Button
                    variant="Primary"
                    buttonSize="large"
                    disabled={false}
                    aria-label="Import from CSV"
                    mt="20px"
                    width="250px"
                    onClick={onSubmit}
                >
                    Import
                </Button>
            </Flex>
        </Modal>
    )
}
export default PromptTableNameModal

import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { TableViewColumn } from 'features/views/ListView/TableView/types'
import { useTableViewGridContext } from 'features/views/ListView/TableView/useTableViewGridContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseTableViewHeaderStateProps = {
    column: TableViewColumn
}

export function useTableViewHeaderState({ column }: UseTableViewHeaderStateProps) {
    const { onColumnResize } = useTableViewGridContext()

    const [isResizing, setIsResizing] = useState(false)

    const headerRef = useRef<HTMLDivElement>(null)

    const startX = useRef(0)

    const handleResize = useCallback(
        (width: number) => {
            const minWidth = column.resizeMinWidth ?? 0
            const maxWidth = column.resizeMaxWidth ?? Infinity
            let newWidth = Math.max(minWidth, Math.min(maxWidth, width))
            newWidth = Math.ceil(newWidth)

            onColumnResize(column.id, newWidth)
        },
        [column.id, column.resizeMaxWidth, column.resizeMinWidth, onColumnResize]
    )

    const onResizeHandleMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault()

            const header = headerRef.current
            if (!header) return

            setIsResizing(true)

            // Save the initial pointer position.
            startX.current = e.clientX
            const initialWidth = header.offsetWidth

            // Set the cursor to the resize cursor.
            document.body.style.pointerEvents = 'none'
            document.documentElement.style.cursor = 'col-resize'

            const onMouseMove = (e: MouseEvent) => {
                const deltaX = e.clientX - startX.current
                const newWidth = initialWidth + deltaX

                handleResize(newWidth)
            }

            const onMouseUp = () => {
                document.body.style.pointerEvents = 'inherit'
                document.documentElement.style.cursor = 'inherit'

                setIsResizing(false)

                window.removeEventListener('mousemove', onMouseMove)
                window.removeEventListener('mouseup', onMouseUp)
            }

            window.addEventListener('mousemove', onMouseMove)
            window.addEventListener('mouseup', onMouseUp)
        },
        [handleResize]
    )

    const onResizeHandleTouchStart = useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            e.preventDefault()

            const header = headerRef.current
            if (!header) return

            setIsResizing(true)

            // Save the initial pointer position.
            startX.current = e.touches[0].clientX
            const initialWidth = header.offsetWidth

            document.body.style.pointerEvents = 'none'

            const onTouchMove = (e: TouchEvent) => {
                const deltaX = e.touches[0].clientX - startX.current
                const newWidth = initialWidth + deltaX

                handleResize(newWidth)
            }

            const onTouchEnd = () => {
                document.body.style.pointerEvents = 'inherit'

                setIsResizing(false)

                window.removeEventListener('touchmove', onTouchMove)
                window.removeEventListener('touchend', onTouchEnd)
            }

            window.addEventListener('touchmove', onTouchMove)
            window.addEventListener('touchend', onTouchEnd)
        },
        [handleResize]
    )

    const rawOptionsMemo = useDeepEqualsMemoValue(column.rawOptions)
    const isResizableRef = useRef(column.isResizable)
    isResizableRef.current = column.isResizable

    // If the column's display options change, we need to update the header's width.
    useLayoutEffect(() => {
        if (!isResizableRef.current) return

        const header = headerRef.current
        if (!header) return

        handleResize(header.offsetWidth)
    }, [handleResize, rawOptionsMemo])

    return useMemo(
        () => ({
            headerRef,
            onResizeHandleMouseDown,
            onResizeHandleTouchStart,
            isResizing,
        }),
        [onResizeHandleMouseDown, onResizeHandleTouchStart, isResizing]
    )
}

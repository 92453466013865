import { useEffect, useRef } from 'react'

import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'
import { simulateInputValueChange } from 'utils/simulateInputValueChange'

type UseTypeInCellToEditProps = {
    getInputElement?: () => HTMLInputElement | HTMLTextAreaElement | null
    onChange?: (content: string) => void
}

/**
 * This hook handles the logic for typing in a cell to edit it.
 * In AG Grid you can start typing into a cell to start edit mode, and the content you typed before
 * edit mode started should be in the cell editor.
 */
export function useTypeInCellToEdit({ getInputElement, onChange }: UseTypeInCellToEditProps) {
    const onChangeRef = useRef(onChange)
    onChangeRef.current = onChange

    const getInputElementRef = useRef(getInputElement)
    getInputElementRef.current = getInputElement

    const { editorOpenedWithEvent } = useDataGridContext()

    useEffect(() => {
        if (!editorOpenedWithEvent) return

        const inputElement: HTMLInputElement | HTMLTextAreaElement | null =
            getInputElementRef.current?.() ?? null
        inputElement?.focus()

        if (editorOpenedWithEvent?.type === 'keydown') {
            const key = (editorOpenedWithEvent as KeyboardEvent).key ?? ''
            if (key.length === 1) {
                // The user started typing in the cell to edit it.
                const onChange = onChangeRef.current
                if (onChange) {
                    onChange(key)
                } else {
                    // Default callback for when no onChange is provided.
                    const input = inputElement as HTMLInputElement
                    if (!input) return

                    simulateInputValueChange(input, key)
                }
            } else if (key === 'Enter') {
                // Opening the editor with `Enter` should select the whole text,
                // but opening it with `F2`, for example should not.
                inputElement?.select?.()
            }
        } else {
            // Opening the editor with a mouse click should select the whole text.
            inputElement?.select?.()
        }
    }, [editorOpenedWithEvent])
}

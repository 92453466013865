import { useRef } from 'react'

import { CONFIRM_MODAL_KEY, ConfirmModal } from 'app/ConfirmModal'
import { assertIsDefined } from 'data/utils/ts_utils'
import { UserCancelledError } from 'utils/utils'

import { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

export const useConfirmModal = (data) => {
    return useModalDeclaration(CONFIRM_MODAL_KEY, ConfirmModal, data)
}
// Shows a confirm modal using a simple show() method which returns
// a promise which resolves when the user clicks Confirm or is rejected
// if the user clicks Cancel.
export function useConfirmModalWithPromise(suppliedData) {
    const resolvePromise = useRef()
    const rejectPromise = useRef()
    const getFinalData = (preliminaryData) => ({
        ...preliminaryData,
        onConfirm: async ({ setIsOpen }) => {
            await preliminaryData?.onConfirm?.()
            assertIsDefined(resolvePromise.current)
            resolvePromise.current(undefined)
            setIsOpen(false)
        },
        onCancel: async ({ setIsOpen }) => {
            await preliminaryData?.onCancel?.()
            assertIsDefined(rejectPromise.current)
            rejectPromise.current(new UserCancelledError())
            setIsOpen(false)
        },
    })

    const show = (overwriteModalData) => {
        showConfirm(getFinalData({ ...suppliedData, ...overwriteModalData }))
        return new Promise((resolve, reject) => {
            resolvePromise.current = resolve
            rejectPromise.current = reject
        })
    }
    const { show: showConfirm } = useConfirmModal(getFinalData(suppliedData))

    return show
}

import React from 'react'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
    DropdownSeparator,
    DropdownSub,
    DropdownSubContent,
    DropdownSubTrigger,
    DropdownTitle,
} from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useWorkspaceNavigationAppDropdownState } from './hooks/useWorkspaceNavigationAppDropdownState'
import { NavigationApp } from './types'

type WorkspaceNavigationAppDropdownProps = React.ComponentPropsWithoutRef<typeof DropdownButton> & {
    app: NavigationApp
}

export const WorkspaceNavigationAppDropdown: React.FC<WorkspaceNavigationAppDropdownProps> = ({
    app,
    ...props
}) => {
    const {
        canShowAppSettings,
        showAppSettings,
        canShowAppUsers,
        showAppUsers,
        canDeleteApp,
        deleteApp,
        canTogglePin,
        togglePin,
        isPinned,
        copyLink,
        canFavoriteApp,
        toggleFavorite,
        isFavorite,
        canArchiveApp,
        toggleArchiveApp,
        isArchived,
        spaces,
        canMoveToSpace,
        moveToSpace,
    } = useWorkspaceNavigationAppDropdownState({ app })

    return (
        <Dropdown modal={false}>
            <DropdownButton
                variant="ghost"
                startIcon={{
                    name: 'MoreHorizontal',
                }}
                noShrink
                {...props}
            />
            <DropdownContent style={{ width: '230px' }} onClick={stopPropagation}>
                <DropdownItem startIcon={{ name: 'Link' }} label="Copy link" onClick={copyLink} />
                {canFavoriteApp && (
                    <DropdownItem
                        startIcon={{
                            name: isFavorite ? 'StarOff' : 'Star',
                        }}
                        label={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                        onClick={toggleFavorite}
                    />
                )}
                {(canShowAppSettings || canShowAppUsers) && <DropdownSeparator />}
                {canShowAppSettings && (
                    <DropdownItem
                        startIcon={{ name: 'Settings' }}
                        label="App settings"
                        onClick={showAppSettings}
                    />
                )}
                {canShowAppUsers && (
                    <DropdownItem
                        startIcon={{ name: 'Users' }}
                        label="Manage app users"
                        onClick={showAppUsers}
                    />
                )}
                {(canMoveToSpace || canTogglePin) && <DropdownSeparator />}
                {canMoveToSpace && (
                    <DropdownSub>
                        <DropdownSubTrigger
                            label="Move to Space..."
                            startIcon={{ name: 'ArrowRightFromLine' }}
                        />
                        <DropdownSubContent head={<DropdownHeadSearch autoFocus={false} />}>
                            <DropdownTitle>Spaces</DropdownTitle>
                            {spaces.map((s) => {
                                const isChecked = app.spaceId === s.id

                                return (
                                    <DropdownItem
                                        key={s.id}
                                        label={s.name}
                                        multiSelect
                                        checked={isChecked}
                                        onClick={() => moveToSpace(s.id)}
                                        style={{
                                            fontStyle: s.id === '' ? 'italic' : 'normal',
                                        }}
                                    />
                                )
                            })}
                        </DropdownSubContent>
                    </DropdownSub>
                )}
                {canTogglePin && (
                    <DropdownItem
                        startIcon={{ name: isPinned ? 'PinOff' : 'Pin' }}
                        label={isPinned ? 'Unpin from nav' : 'Pin to nav'}
                        onClick={togglePin}
                    />
                )}
                {(canArchiveApp || canDeleteApp) && <DropdownSeparator />}
                {canArchiveApp && (
                    <DropdownItem
                        startIcon={{ name: 'Archive' }}
                        label={isArchived ? 'Unarchive app' : 'Archive app'}
                        onClick={toggleArchiveApp}
                    />
                )}
                {canDeleteApp && (
                    <DropdownItem
                        variant="destructive"
                        startIcon={{ name: 'Trash2' }}
                        label="Delete app"
                        onClick={deleteApp}
                    />
                )}
            </DropdownContent>
        </Dropdown>
    )
}

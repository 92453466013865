import React, { useState } from 'react'

import ObjectFieldsFilter from 'features/records/components/RecordFilters'

import { Box, Button, Divider, Flex, Modal, Text } from 'v2/ui'

type Props = {
    object?: ObjectDto
    filters: Filter[]
    onUpdate: (filters: Filter[]) => void
    onClose: () => void
}

const ConditionalVisibilityModal: React.FC<Props> = ({ object, filters, onUpdate, onClose }) => {
    const [conditions, setConditions] = useState(filters)
    const save = () => {
        onUpdate(conditions)
    }

    return (
        <Modal isOpen title="Edit conditions" onClose={onClose} size="700px" isSecondLayer>
            <Flex column align="stretch">
                <Text>Select the conditions under which this button should be visible.</Text>
                <Divider my={2} />
                <Box flexGrow={1}>
                    {/*@ts-expect-error*/}
                    <ObjectFieldsFilter
                        object={object}
                        value={conditions}
                        showRoleFilter
                        fields={object?.fields.filter(
                            (field) => !field.connection_options.is_disabled
                        )}
                        onChange={setConditions}
                    />
                </Box>
                <Divider my={2} />
                <Flex justify="flex-end">
                    <Button variant="moderateSm" icon="x" onClick={onClose} mr={1}>
                        Cancel
                    </Button>
                    <Button buttonSize="sm" icon="checkmark" onClick={save}>
                        Save
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    )
}

export default ConditionalVisibilityModal

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeRangeVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surface: s.color.gray200,
            surfaceCircle: s.color.surface,
            surfaceCircleHover: s.color.surfaceStrong,
            surfaceCircleActive: s.color.surfaceStronger,
            surfaceActive: s.color.theme600,
            surfaceDisabled: s.color.surfaceDisabledStrong,

            count: s.color.textHelper,
            countDisabled: s.color.textLabelDisabled,
        },
        size: {
            minWidth: pxToRem(386),
            trackHeight: pxToRem(4),
            thumb: pxToRem(16),
        },
        radius: {
            thumb: s.radius.pill,
        },
        shadows: {
            thumb: s.shadows.s,
            focus: s.shadows.focus,
        },
        fontSizes: {
            count: s.fontSizes.bodyS,
        },
        fontWeights: {
            count: s.fontWeights.bodyRegular,
        },
        lineHeights: {
            count: s.lineHeights.bodyS,
        },
    }
}

import React from 'react'
import {
    Controller,
    ControllerFieldState,
    ControllerRenderProps,
    FieldPath,
    FieldValues,
    RegisterOptions,
    useFormContext,
    UseFormStateReturn,
    ValidationRule,
} from 'react-hook-form'

import { get } from 'lodash'

import { ErrorMessage } from './ui'

export type FormFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    as: React.ElementType
    name: TName
    errorMessages?: { [key: string]: string }
    required?: boolean
    pattern?: ValidationRule<RegExp>
    registerFormField?: boolean
    controlled?: boolean
    controlledDefaultValue?: any
    controlledRender?: ({
        field,
        fieldState,
        formState,
    }: {
        field: ControllerRenderProps<TFieldValues, TName>
        fieldState: ControllerFieldState
        formState: UseFormStateReturn<TFieldValues>
    }) => React.ReactElement
    registerOptions?: Partial<RegisterOptions<TFieldValues, TName>>
    errorMessageStyle?: React.CSSProperties
    containerStyle?: React.CSSProperties
    [propName: string]: any
}
export function FormField({
    as,
    name,
    errorMessages = undefined,
    required = false,
    pattern = undefined,
    registerFormField = true,
    controlled = false,
    controlledDefaultValue = undefined,
    controlledRender = undefined,
    registerOptions = undefined,
    errorMessageStyle = undefined,
    containerStyle = undefined,
    ...props
}: FormFieldProps) {
    const { register, formState, control } = useFormContext()
    const Component = as
    const { errors } = formState
    let content
    // If the component we're wrapping is a "controlled" component, then we need
    // to use the <Controller /> component from react-form-hooks to render it
    // and route changes back into the form.
    if (controlled) {
        const defaultControlledRender = ({ field }: { field: ControllerRenderProps<any, any> }) => (
            <Component {...field} isInvalid={Boolean(errors?.[name])} {...props} />
        )
        content = (
            <Controller
                control={control}
                name={name}
                defaultValue={controlledDefaultValue}
                rules={{ required, pattern, ...registerOptions }}
                render={controlledRender || defaultControlledRender}
            />
        )
    } else {
        content = (
            <Component
                {...(registerFormField
                    ? // @ts-expect-error
                      register(name, { required, pattern, ...registerOptions })
                    : null)}
                isInvalid={Boolean(errors?.[name])}
                {...props}
            />
        )
    }
    return (
        <div style={{ flexShrink: 1, ...containerStyle }}>
            {content}
            <ErrorMessage
                error={get(errors, name)}
                // @ts-expect-error
                errorMessages={errorMessages}
                errorMessageStyle={errorMessageStyle}
            />
        </div>
    )
}

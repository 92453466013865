import React from 'react'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Headline } from 'ui/components/Text'

import { useDetailViewContentState } from './hooks/useDetailViewContentState'
import { DetailViewLayout } from './layouts/DetailViewLayout'

type DetailViewContentProps = {}

export const DetailViewContent: React.FC<DetailViewContentProps> = () => {
    const { title, isLoading } = useDetailViewContentState()

    return (
        <Box background="surface" width="full" pt="3xl" flex column gap="2xl" grow>
            <Box as="header" px="4xl">
                <Skeleton isLoading={isLoading}>
                    <Headline size="xl">{title}</Headline>
                </Skeleton>
            </Box>
            {!isLoading && <DetailViewLayout />}
        </Box>
    )
}

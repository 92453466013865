import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import isEqual from 'lodash/isEqual'

import { ListViewOrderBy, SetOrderBy } from './types'
import { updateLocationWithOrderBy } from './updateLocationWithOrderBy'
import { updateOrderByFromQueryParams } from './updateOrderByFromQueryParams'

type useOrderByParamsProps = {
    adminOrderBy: ListViewOrderBy
    disableParams: boolean
}

export const useOrderByParams = ({
    adminOrderBy,
    disableParams = false,
}: useOrderByParamsProps) => {
    const location = useLocation()
    const history = useHistory()
    const isMounted = useRef(false)
    const currentOrderBy = useRef<ListViewOrderBy>()
    const prevAdminOrderBy = useRef<ListViewOrderBy>()
    const [orderBy, setOrderBy] = useState<ListViewOrderBy>()

    const setNewOrderBy = useCallback<SetOrderBy>(
        (newOrderBy, disableParamsForCallback = false) => {
            currentOrderBy.current = newOrderBy
            setOrderBy(newOrderBy)

            // If not using the params (can be turned off for the whole hook or just per call), then return here
            if (disableParams || disableParamsForCallback) return

            // Otherwise set the url params
            updateLocationWithOrderBy({ orderBy: newOrderBy, location, history })
        },
        [disableParams, history, location]
    )

    useEffect(() => {
        // update the orderBy state value when the orderBy query param changes
        if (location?.search && !disableParams) {
            updateOrderByFromQueryParams({
                orderBy: currentOrderBy.current,
                setOrderBy: setNewOrderBy,
                location,
            })
        }
    }, [disableParams, location, setNewOrderBy])

    useEffect(() => {
        // Only run this if we are getting a new adminOrderBy value
        const newAdminOrderByValue =
            adminOrderBy && !isEqual(prevAdminOrderBy.current, adminOrderBy)
        // If we're mounting and there is no url param set, then set the default admin order by
        const setDefaultAdminOrderByOnMount =
            newAdminOrderByValue && !isMounted.current && !currentOrderBy.current
        // If we've mounted but the admin order by has changed, then set to the new admin order by value
        const setNewAdminOrderBy = newAdminOrderByValue && isMounted.current

        // Do not set the default admin sort by in the params on first mount
        if (setDefaultAdminOrderByOnMount) setNewOrderBy(adminOrderBy, true)
        if (setNewAdminOrderBy) setNewOrderBy(adminOrderBy)

        prevAdminOrderBy.current = adminOrderBy
    }, [adminOrderBy, setNewOrderBy])

    useEffect(() => {
        isMounted.current = true
    }, [])

    if (!history || !location) return { orderBy, setOrderBy: () => {} }

    return { orderBy, setOrderBy: setNewOrderBy, orderByHasInitialised: isMounted.current }
}

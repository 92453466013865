import getObject from 'data/utils/getObject'

const getSymmetricField = (field) => {
    if (!field) return null
    const targetObject = getObject(field?.link_target_object_id)

    return (
        targetObject &&
        targetObject.fields.find((f) => {
            const simpleconn_match =
                f.connection_options.simpleconn_field &&
                f.connection_options.simpleconn_field ===
                    field.connection_options.simpleconn_symmetric_column_id

            // if the connection_options.relationship_target_lookup_field is set on the passed field,
            // see if that matches this field on the targetObject
            //
            // otherwise look for the same in the other direction
            const symmetric_match = field.connection_options.relationship_target_lookup_field
                ? f._sid === field.connection_options.relationship_target_lookup_field
                : f.connection_options.relationship_target_lookup_field === field._sid

            return simpleconn_match || symmetric_match
        })
    )
}

export default getSymmetricField

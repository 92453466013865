import React from 'react'

import styled from '@emotion/styled'

type SlideInBoxProps = {
    slideIn: boolean
    slideOut: boolean
    zIndex?: number
}

const SlideInBox: React.FC<SlideInBoxProps> = ({ children, slideIn, slideOut, ...props }) => {
    return (
        <AnimatedBox slideIn={slideIn} slideOut={slideOut} {...props}>
            {children}
        </AnimatedBox>
    )
}

const AnimatedBox = styled.div<{ slideIn: boolean; slideOut: boolean; zIndex?: number }>`
    position: absolute;
    bottom: 0;
    transform: ${(p) =>
        p.slideIn ? `translateY(0px)` : p.slideOut ? `translateY(1000px)` : 'none'};
    transition: transform 0.3s;
    z-index: ${({ zIndex }) => zIndex};
`

export default SlideInBox

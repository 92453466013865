// @ts-strict-ignore
import {
    ColorScheme,
    colorSchemeNames,
    ColorTone,
} from 'ui/styling/baseVariables/colors/colorPalette'
import type { makeAppVariables } from 'ui/styling/themes/default/app'

export function selectPaletteThemeColorsByTone<
    TPrefix extends string = '',
    TSuffix extends string = '',
>(
    colorPalette: ReturnType<typeof makeAppVariables>['color'],
    tone: ColorTone,
    prefix: TPrefix = '' as TPrefix,
    suffix: TSuffix = '' as TSuffix
) {
    return colorSchemeNames.reduce(
        (result, colorScheme) => ({
            ...result,
            [`${prefix}${colorScheme}${suffix}`]: colorPalette[colorScheme.toLowerCase() + tone],
        }),
        {}
    ) as { [K in `${TPrefix}${ColorScheme}${TSuffix}`]: string }
}

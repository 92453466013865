import { fetchAndReturn } from 'data/utils/utils'

export const startImportCsvTableJob = (url: string, tableName: any): Promise<any> => {
    return fetchAndReturn(`csv/import`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            url: url,
            name: tableName,
        }),
    }) as Promise<any>
}

export const startUpsertCsvTableJob = (url: string, objectSid: string): Promise<any> => {
    return fetchAndReturn(`csv/upsert`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            url: url,
            object_sid: objectSid,
        }),
    })
}

export const startValidateCsvTableJob = (url: string, objectSid: string): Promise<any> => {
    return fetchAndReturn(`csv/validate`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            url: url,
            object_sid: objectSid,
        }),
    })
}

import { queryClient } from 'data/hooks/_helpers'
import { reloadMetadataAfterObjectChange } from 'data/hooks/refreshData'

import { getCachedObjects, getQueryKey } from './objectOperations'
/**
 * Refetches objects and returns a promise that resolves when the query is done
 */
export const refetchObjects = async () => {
    await queryClient.refetchQueries(getQueryKey()).then(() => {
        reloadMetadataAfterObjectChange()
    })

    return getCachedObjects()
}

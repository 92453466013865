import TextareaAutosize from 'react-autosize-textarea'

import { TipTapEditor } from 'features/tiptap/TipTapEditor'

import { Box } from 'ui/components/Box'
import {
    ToolbarButton as ToolbarButtonBase,
    ToolbarRoot as ToolbarRootBase,
    ToolbarSeparator as ToolbarSeparatorBase,
} from 'ui/components/Toolbar'
import { makeComponent } from 'ui/helpers/recipes'

import {
    ResizeHandleStyles,
    RichTextEditorStyles,
    RichTextEditorWrapperStyles,
    TextareaStyles,
    ToolbarButtonStyles,
    ToolbarGroupStyles,
    ToolbarInputStyles,
    ToolbarPanelStyles,
    ToolbarRootStyles,
    ToolbarSeparatorStyles,
    WrapperStyles,
} from './Textarea.css'

export const Wrapper = makeComponent(Box, WrapperStyles)
export const TextareaBase = makeComponent('textarea', TextareaStyles)
export const TextareaAutosizable = makeComponent(TextareaAutosize, TextareaStyles)
export const ResizeHandle = makeComponent('svg', ResizeHandleStyles)
export const RichTextEditorWrapper = makeComponent(Box, RichTextEditorWrapperStyles)
export const RichTextEditor = makeComponent(TipTapEditor, RichTextEditorStyles)

export const ToolbarRoot = makeComponent(ToolbarRootBase, ToolbarRootStyles)
export const ToolbarGroup = makeComponent(Box, ToolbarGroupStyles)
export const ToolbarButton = makeComponent(ToolbarButtonBase, ToolbarButtonStyles)
export const ToolbarSeparator = makeComponent(ToolbarSeparatorBase, ToolbarSeparatorStyles)
export const ToolbarPanel = makeComponent(Box, ToolbarPanelStyles)
export const ToolbarInput = makeComponent('input', ToolbarInputStyles)

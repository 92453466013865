import * as Primitive from '@radix-ui/react-slider'

import { Field } from 'ui/components/Field'
import { makeComponent } from 'ui/helpers/recipes'

import {
    ContainerStyles,
    ControlStyles,
    CountStyles,
    RangeStyles,
    ThumbStyles,
    TrackStyles,
} from './Range.css'

export const Container = makeComponent(Field, ContainerStyles)
export const Control = makeComponent(Primitive.Root, ControlStyles)
export const Track = makeComponent(Primitive.Track, TrackStyles)
export const Range = makeComponent(Primitive.Range, RangeStyles)
export const Thumb = makeComponent(Primitive.Thumb, ThumbStyles)
export const Count = makeComponent('div', CountStyles)

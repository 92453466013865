import React from 'react'

export type PreviewServiceContextData = {
    stopPreviewing: () => void
    previewAsUser: (userId: string) => Promise<UserDto>
    previewAsRole: (roleApiName: string) => Promise<void>
    previewingAsUser?: UserDto
    previewingAsRoleApiName?: string
    isPreviewingAsUserOrRole: boolean
}

export const PreviewServiceContext = React.createContext<PreviewServiceContextData>(
    {} as PreviewServiceContextData
)

export const usePreviewServiceContext = () => React.useContext(PreviewServiceContext)

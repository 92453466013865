import React from 'react'

import { useWorkflows } from 'data/hooks/workflows/workflows'
import { UserDisplay } from 'features/users/shared/UserDisplay'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

type WorkflowUserDisplayProps = Omit<React.ComponentPropsWithoutRef<typeof UserDisplay>, 'user'> & {
    workflowId?: string
}

export const WorkflowUserDisplay: React.FC<WorkflowUserDisplayProps> = ({
    workflowId,
    ...props
}) => {
    const { data: workflows } = useWorkflows()
    const workflow = workflows?.find((w) => w._sid === workflowId)
    const workflowName = workflow?.name

    const user = { name: 'A workflow' } as UserDto

    return (
        <Box flex center>
            <Tooltip disabled={!workflowName} label={workflowName} placement="bottom">
                <UserDisplay
                    user={user}
                    avatarProps={{
                        icon: <Icon name="Zap" />,
                        name: undefined,
                    }}
                    {...props}
                />
            </Tooltip>
        </Box>
    )
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { formatNumber } from 'data/utils/utils'
import { AdvancedFilter } from 'features/views/ListView/ListHeader/Filters/Advanced/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

const NUMBER_REGEXP = /^-?\d*\.?\d*$/
const MAX_FRACTION_DIGITS = 2

type UseAdvancedFilterValuePercentageStateOptions = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
}

export function useAdvancedFilterValuePercentageState(
    options: UseAdvancedFilterValuePercentageStateOptions
) {
    const { filter, onChange, onRemoveFilter } = options

    const inputRef = useRef<HTMLInputElement>(null)

    const filterValue = getValueFromFilter(filter)
    const filterValueMemo = useDeepEqualsMemoValue(filterValue)

    const [value, setValue] = useState(filterValue)
    const valueRef = useRef(value)
    valueRef.current = value

    // Sync value with filter on update.
    useEffect(() => {
        setValue(filterValueMemo)
    }, [filterValueMemo])

    const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const isValid = NUMBER_REGEXP.test(value)
        if (!isValid) return

        // Cap fraction digits.
        if (value.includes('.')) {
            const [_, fraction] = value.split('.')
            if (fraction.length > MAX_FRACTION_DIGITS) return
        }

        setValue([value])
    }, [])

    const setFilterValue = useCallback(
        (value: string) => {
            const isEmpty = !value
            if (isEmpty && filter.isDraft) {
                return
            }

            let numberValue = parseFloat(value)
            numberValue = formatNumber(numberValue / 100, 0, 2 + MAX_FRACTION_DIGITS)
            onChange(Number.isNaN(numberValue) ? '' : numberValue.toString())
        },
        [filter.isDraft, onChange]
    )

    const onInputBlur = useCallback(() => {
        const value = valueRef.current
        setFilterValue(value[0])
    }, [setFilterValue])

    const onInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                onInputBlur()
            }

            if (e.key === 'Escape' && filter.isDraft) {
                e.preventDefault()
                onRemoveFilter()
            }
        },
        [filter.isDraft, onInputBlur, onRemoveFilter]
    )

    // Focus input when operation is changed, and the filter is empty.
    useEffectOnlyOnUpdate(() => {
        requestAnimationFrame(() => {
            const isEmpty = !valueRef.current[0]
            if (!isEmpty) return

            if (inputRef.current) {
                inputRef.current.focus()
            }
        })
    }, [filter.options.option])

    return useMemo(
        () => ({
            onInputChange,
            onInputBlur,
            onInputKeyDown,
            value,
            inputRef,
        }),
        [onInputChange, onInputBlur, onInputKeyDown, value]
    )
}

function getValueFromFilter(filter: AdvancedFilter): string[] {
    const filterValue = filter.options?.value

    if (Array.isArray(filterValue)) {
        return filterValue.map(getInputValue)
    }

    if (filterValue) {
        return [getInputValue(filterValue)]
    }

    return ['']
}

function getInputValue(value?: string): string {
    if (!value) return ''

    let numberValue = parseFloat(value)
    numberValue = formatNumber(numberValue * 100, 0, 2 + MAX_FRACTION_DIGITS)

    return numberValue.toString()
}

import React from 'react'

import { FieldIcon } from 'features/admin/fields/icons/FieldIcon'
import { AttributeValueStyles } from 'features/views/ListView/CardView/CardView.css'
import * as Parts from 'features/views/ListView/CardView/CardView.parts'
import { CardViewAttribute } from 'features/views/ListView/CardView/types'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

import { useCardViewBaseAttributeState } from './hooks/useCardViewBaseAttributeState'

const LABEL_MAX_LENGTH = 40
const LABEL_MAX_LINES = 1

type BaseAttributeProps = React.ComponentPropsWithoutRef<typeof Parts.Attribute> & {
    attribute: CardViewAttribute
    isEmpty?: boolean
    isLoading?: boolean
}

export const BaseAttribute: React.FC<BaseAttributeProps> = React.memo(function BaseAttribute({
    children,
    isEmpty,
    attribute,
    isLoading,
    ...props
}) {
    const { showLabel, titleTextSize, showIcon } = useCardViewBaseAttributeState({
        attribute,
    })

    const { type } = attribute
    const isTitle = type === 'title'

    return (
        <Parts.Attribute
            fontSize={{
                mobile: isTitle ? titleTextSize : 'bodyS',
                tablet: isTitle ? titleTextSize : 'bodyM',
            }}
            lineHeight={{
                mobile: isTitle ? titleTextSize : 'bodyS',
                tablet: isTitle ? titleTextSize : 'bodyM',
            }}
            letterSpacing={{
                mobile: isTitle ? titleTextSize : 'bodyS',
                tablet: isTitle ? titleTextSize : 'bodyM',
            }}
            fontWeight={isTitle ? 'bodyBold' : 'bodyRegular'}
            {...props}
        >
            {showLabel && (
                <Box mb="3xs">
                    <Skeleton isLoading={isLoading}>
                        <Body
                            maxLength={LABEL_MAX_LENGTH}
                            maxLines={LABEL_MAX_LINES}
                            trim
                            size="s"
                            weight="medium"
                            color="gray900"
                        >
                            {attribute.title}
                        </Body>
                    </Skeleton>
                </Box>
            )}
            <Box
                minWidth={0}
                maxWidth="full"
                className={AttributeValueStyles.styleFunction({ type })}
                flex={showIcon}
                gap={showIcon ? 'm' : 0}
                center={showIcon}
            >
                {showIcon && <FieldIcon value={attribute.icon!} size="s" color="icon" noShrink />}
                {isEmpty ? (
                    <Body
                        size="m"
                        weight="bold"
                        color="textWeakest"
                        style={{
                            fontSize: 'inherit',
                            lineHeight: 'inherit',
                            letterSpacing: 'inherit',
                            fontWeight: 'inherit',
                            maxWidth: '100%',
                        }}
                        pr="2xs"
                        trim
                        display="block"
                    >
                        {showLabel || !attribute.title ? 'None' : `No ${attribute.title}`}
                    </Body>
                ) : (
                    children
                )}
            </Box>
        </Parts.Attribute>
    )
})

import React from 'react'

import { Container } from 'ui/components/Container'
import { Icon } from 'ui/components/Icon'
type CommandListIconProps = React.ComponentPropsWithoutRef<typeof Icon<'lucide'>> & {}
export function CommandListIcon({ name, ...props }: CommandListIconProps) {
    return (
        <Container
            border
            flex
            center
            justifyContent="center"
            style={{
                width: '20px',
                height: '20px',
            }}
            {...props}
        >
            <Icon name={name} size="xs" />
        </Container>
    )
}

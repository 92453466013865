// @ts-strict-ignore
// @ts-check
import React, { forwardRef, MutableRefObject } from 'react'
import { FormProvider, useForm, UseFormProps, UseFormReturn } from 'react-hook-form'

import { dirtyValues } from './utils'

export type FormProps = {
    options?: UseFormProps
    schema?: any
    onSubmit: (data: any, event: any, formContext: any) => any
    onError?: (data: any) => any
    onSuccess?: (formContext: any) => void
    resetOnSuccess?: boolean
    onlySubmitChangedfields?: boolean
    children: any
    style?: React.CSSProperties
}

export const FormWithContext = forwardRef<any, FormProps>(({ options, ...props }, ref) => {
    const formContext = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        ...options,
    })

    return <FormWrapper ref={ref} {...props} formContext={formContext} />
})

export type FormPropsWithContext = FormProps & {
    formContext: UseFormReturn<any>
    formElementRef?: MutableRefObject<HTMLFormElement | null>
}
export const FormWrapper = forwardRef<any, FormPropsWithContext>(
    (
        {
            onSubmit,
            onError,
            onSuccess,
            resetOnSuccess,
            children,
            onlySubmitChangedfields = false,
            formContext,
            formElementRef,
            ...props
        },
        ref
    ) => {
        // get access to the context from the parent
        if (ref) {
            // @ts-ignore
            ref.current = formContext
        }

        return (
            <FormProvider {...formContext}>
                <form
                    ref={formElementRef}
                    onSubmit={(event) => {
                        let handler = (data, event) => onSubmit(data, event, formContext.formState)
                        // If the client only wants to have changed field sent to onSubmit,
                        // extract those changed fields now.
                        if (onlySubmitChangedfields) {
                            handler = (data, event) => {
                                return onSubmit(
                                    dirtyValues(formContext.formState.dirtyFields, data),
                                    event,
                                    formContext.formState
                                )
                            }
                        }
                        const promise = formContext.handleSubmit(handler, onError)(event)

                        if (onSuccess) {
                            if (promise && promise.then) {
                                promise.then(
                                    () => onSuccess(formContext),
                                    () => {}
                                )
                            } else {
                                onSuccess(formContext)
                            }
                        }

                        // set the default values to be the current values
                        if (resetOnSuccess) {
                            if (promise && promise.then) {
                                promise.then(
                                    () => {
                                        // Don't reset the form if there were errors as it doesn't actually count as success
                                        if (
                                            Object.keys(formContext.formState.errors).length === 0
                                        ) {
                                            formContext.reset(formContext.getValues())
                                        }
                                    },
                                    () => {}
                                )
                                // Don't reset the form if there were errors as it doesn't actually count as success
                            } else if (Object.keys(formContext.formState.errors).length === 0) {
                                formContext.reset(formContext.getValues())
                            }
                        }

                        event.stopPropagation()
                        event.preventDefault()
                    }}
                    {...props}
                >
                    {typeof children === 'function' ? children(formContext) : children}
                </form>
            </FormProvider>
        )
    }
)

export default FormWithContext

import React from 'react'

import { ExpressionInput } from 'features/expressions/ExpressionInput'
import { ContextGroup } from 'features/expressions/types'

import { Box } from 'ui/components/Box'

import { WorkflowLoop } from './FlowControl/WorkflowLoop'
import { findNodeInChain } from './common'
import { ItemIcon } from './ItemIcon'
import { useWorkflowEditorContext } from './useWorkflowEditorContext'
import { WorkflowAction } from './WorkflowAction'
import { WorkflowTrigger } from './WorkflowTrigger'

type WorkflowExpressionInputProps = Omit<
    React.ComponentPropsWithoutRef<typeof ExpressionInput>,
    'value' | 'onChange'
> & {
    value: WorkflowExpression | undefined
    onChange: (value: WorkflowExpression | undefined) => void
}
export const WorkflowExpressionInput: React.FC<WorkflowExpressionInputProps> = (props) => {
    // @ts-expect-error
    return <ExpressionInput {...props} renderGroupTitle={GroupTitle} />
}

type GroupTitleProps = { item: ContextGroup; queryTerms?: string[] }
export function GroupTitle({ item }: GroupTitleProps) {
    const { workflow } = useWorkflowEditorContext()

    if (item.id === 'trigger') {
        return (
            <Box grow flex center>
                <WorkflowTrigger trigger={workflow.trigger} showType />
            </Box>
        )
    }

    const node = findNodeInChain(item.id, workflow.chain, true)

    if (node?.kind === 'action') {
        return (
            <Box grow flex center>
                <WorkflowAction action={node} showType />
            </Box>
        )
    }

    if (node?.kind === 'loop') {
        return (
            <Box grow flex center>
                <WorkflowLoop node={node} showType />
            </Box>
        )
    }

    return (
        <Box grow flex center>
            <ItemIcon icon="mouse-pointer" mr="m" />
            {item.name}
        </Box>
    )
}

import React, { useState } from 'react'

import { useAppUsers } from 'data/hooks/users/main'
import { UserDisplay } from 'features/users/shared/UserDisplay'
import { isSupportLogin } from 'features/users/shared/utils'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Collapsible, CollapsibleContent } from 'ui/components/Collapsible'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'ui/components/Tabs'

import { ExecutionHistoryNodeList } from './ExecutionHistoryNodeList'
import { ExecutionHistoryState } from './ExecutionHistoryState'
import { ExecutionHistoryStatusLabel } from './ExecutionHistoryStatusLabel'
import { formatExecutionDate, getExecutionDuration, prettyPrintExecutionErrorTrace } from './utils'

type ExecutionHistoryDetailProps = React.ComponentPropsWithoutRef<typeof Box> & {
    selectedExecution?: WorkflowExecutionDto
}

export const ExecutionHistoryDetail: React.FC<ExecutionHistoryDetailProps> = ({
    selectedExecution,
    ...props
}) => {
    const [isErrorDetailsOpen, setIsErrorDetailsOpen] = useState(false)

    if (!selectedExecution) return null

    const startDate = formatExecutionDate(selectedExecution.started_at)
    const finishDate = selectedExecution.finished_at
        ? formatExecutionDate(selectedExecution.finished_at)
        : null
    const duration = getExecutionDuration(selectedExecution)

    const error = selectedExecution.result_data.error

    const hasState = Object.keys(selectedExecution.execution_data?.state_data ?? {}).length > 0

    const supportsShowDetails = isSupportLogin()

    return (
        <Box p="3xl" background="surfaceStrong" {...props}>
            <Box flex justifyContent="space-between" alignItems="flex-start" gap="m">
                <Box flex column gap="xs" grow style={{ minWidth: 0 }}>
                    <Box as="h2" fontSize="bodyM" fontWeight="bodyBold">
                        {startDate}
                    </Box>
                    <Box flex center fontSize="bodyS">
                        <ExecutionHistoryStatusLabel
                            status={selectedExecution.status}
                            fontSize="bodyS"
                        />
                        {duration && (
                            <Tooltip label={finishDate!}>
                                <Box color="textWeaker" ml="xs">
                                    in {duration}
                                </Box>
                            </Tooltip>
                        )}
                        {supportsShowDetails && error && (
                            <Button
                                size="xs"
                                variant="link"
                                color="textWeak"
                                onClick={() => setIsErrorDetailsOpen((prev) => !prev)}
                                ml="m"
                            >
                                {isErrorDetailsOpen ? 'hide details' : 'show details'}
                            </Button>
                        )}
                    </Box>
                    {error && <ExecutionHistoryError error={error} open={isErrorDetailsOpen} />}
                </Box>
                <ExecutionHistoryTriggeredBy userId={selectedExecution.triggered_by} noShrink />
            </Box>
            <Box mt="xl">
                <Tabs defaultValue="steps" width="full" flex column type="underlined">
                    <TabsList mb="m" width="full">
                        <TabsTrigger
                            style={{ backgroundColor: 'transparent' }}
                            value="steps"
                            label="Steps"
                        />
                        <TabsTrigger
                            style={{ backgroundColor: 'transparent' }}
                            disabled={!hasState}
                            value="state"
                            label="State"
                        />
                    </TabsList>
                    <TabsContent value="steps">
                        <ExecutionHistoryNodeList execution={selectedExecution} />
                    </TabsContent>
                    <TabsContent value="state">
                        <ExecutionHistoryState execution={selectedExecution} />
                    </TabsContent>
                </Tabs>
            </Box>
        </Box>
    )
}

type ExecutionHistoryTriggeredByProps = React.ComponentPropsWithoutRef<typeof Box> & {
    userId: string
}

const ExecutionHistoryTriggeredBy: React.FC<ExecutionHistoryTriggeredByProps> = ({
    userId,
    ...props
}) => {
    const { data: users } = useAppUsers()
    const triggeredBy = (users as UserDto[])?.find((u) => u._sid === userId)

    if (!triggeredBy) return null

    return (
        <Box flex center gap="xs" fontSize="bodyS" {...props}>
            <Box as="span" color="textWeaker">
                triggered by
            </Box>
            <UserDisplay user={triggeredBy} />
        </Box>
    )
}

type ExecutionHistoryErrorProps = React.ComponentPropsWithoutRef<typeof Collapsible> & {
    error: WorkflowExecutionError
}

const ExecutionHistoryError: React.FC<ExecutionHistoryErrorProps> = ({ error, ...props }) => {
    const errorMessage = error.message
    const trace = prettyPrintExecutionErrorTrace(error.trace)

    return (
        <Collapsible {...props}>
            <Box fontSize="bodyS" flex gap="xs" color="textError" alignItems="flex-start" trim>
                <strong>Error:</strong>
                <Box color="textError" trim>
                    {errorMessage}
                </Box>
            </Box>
            <CollapsibleContent>
                <Box fontSize="bodyS" whiteSpace="pre-wrap" mt="xs">
                    {trace}
                </Box>
            </CollapsibleContent>
        </Collapsible>
    )
}

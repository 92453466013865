import { useCallback } from 'react'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import settings from 'app/settings'
import { fetchWithCheckForServerErrors } from 'data/api/fetchWithCheckForServerErrors'

const apiRoot = settings.API_ROOT

async function fetchAndReturnUser(
    url: string,
    options: Omit<RequestInit, 'body'> & { body?: Object | string } = {}
) {
    const defaultHeaders: Record<string, string> = { Accept: 'application/json' }

    if (options.body) {
        defaultHeaders['Content-Type'] = 'application/json'
    }

    options.headers = { ...defaultHeaders, ...options.headers }

    let body: BodyInit | undefined
    if (options.body && !options.method) {
        options.method = 'POST'
    }

    if (options.body && typeof options.body !== 'string') {
        body = JSON.stringify(options.body)
    } else {
        body = options.body?.toString()
    }

    const response = await fetchWithCheckForServerErrors(url, { ...options, body })

    return (await response.json()) as AuthedUserDto
}

export function useLoginUser() {
    const { setUser } = useAuthContext()
    return useCallback(
        async (email: string, password: string, redirect: string) => {
            const user = await fetchAndReturnUser(`${apiRoot}login/`, {
                body: {
                    email,
                    password,
                    redirect,
                },
            })
            setUser(user)
            return user
        },
        [setUser]
    )
}

export function useLoginUserByTemporaryAuthToken() {
    const { setUser } = useAuthContext()
    return useCallback(
        async (token: string) => {
            const user = await fetchAndReturnUser(`${apiRoot}redeem-auth-token/`, {
                body: {
                    auth_token: token,
                },
            })
            setUser(user)
            return user
        },
        [setUser]
    )
}

type LoginWithProviderTokenData = {
    provider: 'google'
    jwt_token: string
    invitation_token?: string
}
export function useLoginUserWithProviderToken() {
    const { setUser } = useAuthContext()
    return useCallback(
        async (details: LoginWithProviderTokenData) => {
            const user = await fetchAndReturnUser(`${apiRoot}login-with-provider-token/`, {
                body: details,
            })
            setUser(user)
            return user
        },
        [setUser]
    )
}

export function useAuthenticateWithLoginCode() {
    const { setUser } = useAuthContext()
    return useCallback(
        async (email: string, code: string, isSignUp: boolean) => {
            const user = await fetchAndReturnUser(`${apiRoot}login/code/`, {
                body: { email, code, isSignUp },
            })
            setUser(user)
            return user
        },
        [setUser]
    )
}

export function useResetPassword() {
    const { setUser } = useAuthContext()
    return useCallback(
        async (token: string, newPassword: string): Promise<AuthedUserDto> => {
            const user = await fetchAndReturnUser(`${apiRoot}admin/reset-password/`, {
                body: { new_password: newPassword, token },
            })

            setUser(user as AuthedUserDto)

            return user
        },
        [setUser]
    )
}

type SignupData = {
    name: string
    email: string
    password: string
    invitationToken?: string
}

export function useSignup() {
    const { setUser } = useAuthContext()
    return useCallback(
        async (details: SignupData): Promise<AuthedUserDto> => {
            const user = await fetchAndReturnUser(`${apiRoot}register/`, {
                body: details,
            })

            setUser(user as AuthedUserDto)

            return user
        },
        [setUser]
    )
}

export function resetPasswordSendEmail(email: string): Promise<Response> {
    const url = `${apiRoot}admin/reset-password/request/?email=${encodeURIComponent(email)}`
    return fetchWithCheckForServerErrors(url) // Don't care about response
}

type SendLoginCodeEmailResponse = {
    error?: string
}

export async function sendLoginCodeEmail(
    email: string,
    isSignUp: boolean
): Promise<SendLoginCodeEmailResponse> {
    const body = {
        email,
        is_sign_up: isSignUp ? 1 : undefined,
    }
    const response = await fetchWithCheckForServerErrors(`${apiRoot}login/code/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    return (await response.json()) as SendLoginCodeEmailResponse
}

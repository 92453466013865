import { Box } from 'ui/components/Box'
import { makeComponent } from 'ui/helpers/recipes'

import {
    ControlsStyles,
    CountStyles,
    EllipsisStyles,
    NumberButtonStyles,
    PageListItemStyles,
    PageListStyles,
    PerPageStyles,
    RootStyles,
} from './Pagination.css'

export const Root = makeComponent(Box, RootStyles)
export const PageList = makeComponent('ul', PageListStyles)
export const PageListItem = makeComponent('li', PageListItemStyles)
export const Controls = makeComponent(Box, ControlsStyles)
export const Count = makeComponent(Box, CountStyles)
export const PerPage = makeComponent(Box, PerPageStyles)
export const NumberButton = makeComponent('button', NumberButtonStyles)
export const Ellipsis = makeComponent('span', EllipsisStyles)

import React from 'react'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

type EmbeddedNoRecordsFiltersEmptyStateProps = {}

export const EmbeddedNoRecordsFiltersEmptyState: React.FC<EmbeddedNoRecordsFiltersEmptyStateProps> =
    React.memo(function EmbeddedNoRecordsFiltersEmptyState() {
        return (
            <Box
                flex
                center
                flexDirection="column"
                textAlign="center"
                justifyContent="center"
                py="2xl"
            >
                <Body size="m" color="textWeaker">
                    No records found
                </Body>
            </Box>
        )
    })

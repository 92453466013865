import { useCallback, useMemo } from 'react'

import { makeQuickFilterValues } from 'features/views/ListView/Filters/utils'

import { useQuickFilter } from './useQuickFilter'

type FilterOption = {
    label: string
    value: string
}

type UseQuickFilterCheckboxStateOptions = {
    field: FieldDto
}

export function useQuickFilterCheckboxState(options: UseQuickFilterCheckboxStateOptions) {
    const { field } = options

    const { value: filters, setFiltersValue, isActive, label } = useQuickFilter(field)
    const filterValue = filters[0]

    const onFilterRemove = useCallback(() => {
        setFiltersValue([])
    }, [setFiltersValue])

    const onSetFilterValue = useCallback(
        (newValue: string, isEnabled: boolean) => {
            if (!isEnabled) {
                setFiltersValue([])
                return
            }

            setFiltersValue(makeQuickFilterValues(field, newValue))
        },
        [field, setFiltersValue]
    )

    const filterOptions = FILTER_OPTIONS

    const value = (filterValue?.options.value as string) ?? ''

    return useMemo(
        () => ({ value, isActive, label, onFilterRemove, onSetFilterValue, filterOptions }),
        [value, isActive, label, onFilterRemove, onSetFilterValue, filterOptions]
    )
}

const FILTER_OPTIONS: FilterOption[] = [
    {
        label: 'is checked',
        value: 'true',
    },
    {
        label: 'is unchecked',
        value: 'false',
    },
]

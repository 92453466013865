import { useMemo } from 'react'

import { useFavorite } from 'data/hooks/favorites/favorites'
import { FavoriteType } from 'data/hooks/favorites/types'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

type UseActionMenuItemFavoriteStateOptions = {
    record: RecordDto
}

export function useActionMenuItemFavoriteState(options: UseActionMenuItemFavoriteStateOptions) {
    const { record } = options
    const { stack, object } = useListViewContext()

    const { favorite, toggleFavorite } = useFavorite({
        targetType: FavoriteType.Record,
        stackId: stack?._sid,
        objectId: object?._sid,
        recordId: record?._sid,
    })

    const isFavorite = !!favorite

    const label = isFavorite ? 'Remove from favorites' : 'Add to favorites'

    return useMemo(
        () => ({ isFavorite, toggleFavorite, label }),
        [isFavorite, toggleFavorite, label]
    )
}

// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { getUrl, Urls } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { useDeleteRole, useStackRoles, useUpdateRole } from 'data/hooks/roles'
import { useUpdateStack } from 'data/hooks/stacks'
import { RolePages } from 'features/admin/settings/permissions/RolePages'
import { SettingsContent } from 'features/admin/settings/ui/SettingsFrame'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'
import { useAppSettings } from 'features/workspace/AdminSideTray/hooks/useAppSettings'
import {
    Container,
    Divider,
    FormLabel,
    Heading,
    Icon,
    Input,
    Section,
    Text,
} from 'legacy/v1/ui/index'

import { Button, ConfirmationModal, Icon as V2Icon } from 'v2/ui'
import usePrevious from 'v2/ui/hooks/usePrevious'

import { Checkbox } from 'ui/components/Checkbox'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

export const AppSettingsModalRolePage = ({
    resetActiveRole,
    role,
}: {
    resetActiveRole: () => void
    role: RoleDto
}): React.ReactElement | null => {
    const [error, setError] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [newRole, setNewRole] = useState<Partial<RoleDto>>({})
    const [newRoleLabel, setNewRoleLabel] = useState('')
    const [newRoleValue, setNewRoleValue] = useState('')
    const [newRoleDefault, setNewRoleDefault] = useState('')
    const [duplicateNameError, setDuplicateNameError] = useState(false)
    const [duplicateValueError, setDuplicateValueError] = useState(false)
    const [pagesSetup, setPagesSetup] = useState(false)
    const [newPermittedPages, setNewPermittedPages] = useState<any[]>([])
    const [newRights, setNewRights] = useState<string[] | null>(null)
    const [options, setOptions] = useState<Partial<RoleDto['options']>>({})
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [confirmModalProcessing, setConfirmModalProcessing] = useState(false)
    const [confirmModalError, setConfirmModalError] = useState(false)

    const { selectedStack } = useAppContext()
    const { mutateAsync: updateStack } = useUpdateStack()
    const { previewAsRole } = usePreviewServiceContext()
    const { open: openAppSettings, close: closeAppSettings } = useAppSettings()
    const { data: roles } = useStackRoles()
    const { mutateAsync: onChangeRole } = useUpdateRole()
    const { mutateAsync: removeRole } = useDeleteRole()
    const history = useHistory()

    const previousRole = usePrevious(role)
    const roleId = role?._sid
    const oldPermittedPages = (role?.permitted_pages ?? []).sort()
    const defaultId = selectedStack?.options?.roles__default_user_role

    useEffect(() => {
        if (role && role !== previousRole) {
            setNewRole(role)
            if (!newRoleLabel) {
                setNewRoleLabel(role.label)
            }
            if (!newRoleValue) {
                setNewRoleValue(role.type_value)
            }
            if (!newRoleDefault) {
                setNewRoleDefault(defaultId ?? '')
            }
            if (!newRights) {
                setNewRights(role.options.rights || [])
            }
            if (!pagesSetup && roles.length) {
                setPagesSetup(true)
                setNewPermittedPages(role?.permitted_pages ?? [])
            }
        }
    }, [
        defaultId,
        newRights,
        newRoleDefault,
        newRoleLabel,
        newRoleValue,
        pagesSetup,
        previousRole,
        role,
        roles,
    ])

    const isDisabled = useCallback(() => {
        if (!newRole) {
            return true
        }
        const noChange =
            role.label === newRoleLabel &&
            role.type_value === newRoleValue &&
            newRoleDefault === defaultId &&
            isEqual(oldPermittedPages, newPermittedPages.sort()) &&
            isEqual(newRights || [], role.options.rights || []) &&
            isEmpty(options)
        const hasValues = newRoleLabel && newRoleDefault
        const isDefault = newRoleDefault === roleId && newRoleLabel

        return noChange || !(hasValues || isDefault) || processing
    }, [
        defaultId,
        newPermittedPages,
        newRights,
        newRole,
        newRoleDefault,
        newRoleLabel,
        newRoleValue,
        options,
        oldPermittedPages,
        processing,
        role.label,
        role.options.rights,
        role.type_value,
        roleId,
    ])

    const allPages =
        options?.all_pages !== undefined ? options?.all_pages : role?.options?.all_pages

    const isNameDuplicate = (name: string) => {
        return roles.some((role: RoleDto) => role.label === name && role._sid !== roleId)
    }

    const isValueDuplicate = (value: string) => {
        return roles.some((role: RoleDto) => role.type_value === value && role._sid !== roleId)
    }

    const saveUpdates = async () => {
        setError(false)
        setDuplicateNameError(false)
        setDuplicateValueError(false)
        setProcessing(true)

        // updating role values
        if (
            newRole.label !== newRoleLabel ||
            newRole.type_value != newRoleValue ||
            !isEqual(oldPermittedPages, newPermittedPages.sort()) ||
            !isEqual(newRights || [], role?.options.rights || []) ||
            !isEmpty(options)
        ) {
            if (isNameDuplicate(newRoleLabel)) {
                setDuplicateNameError(true)
                setProcessing(false)
                return
            }
            if (newRoleValue && isValueDuplicate(newRoleValue)) {
                setDuplicateValueError(true)
                setProcessing(false)
                return
            }

            const newOptions = { ...options, rights: newRights }
            try {
                await onChangeRole({
                    id: role?._sid,
                    patch: {
                        label: newRoleLabel,
                        type_value: newRoleValue,
                        permitted_pages: newPermittedPages,
                        options: { ...role.options, ...newOptions },
                    } as any,
                }).then(invalidateObjects)
            } catch (e) {
                Sentry.captureMessage(`Error updating role ${get(e, 'message')}`)
                setError(true)
                setProcessing(false)
                return
            }
        }

        if (selectedStack && newRoleDefault !== defaultId) {
            try {
                await updateStack({
                    id: selectedStack._sid,
                    patch: {
                        options: {
                            ...selectedStack.options,
                            roles__default_user_role: newRoleDefault,
                        },
                    },
                })
            } catch (e) {
                Sentry.captureMessage(`Error updating role ${e?.message}`)
                setError(true)
                setProcessing(false)
                return
            }
        }

        setError(false)
        setProcessing(false)
    }

    const closeDeleteModal = () => {
        setDeleteModalOpen(false)
        setConfirmModalProcessing(false)
        setConfirmModalError(false)
    }

    const deleteConfirmModal = () => (
        <ConfirmationModal
            isOpen={deleteModalOpen}
            title="Confirm Delete"
            onClose={closeDeleteModal}
            onConfirm={async () => {
                setConfirmModalProcessing(true)
                try {
                    await removeRole(role?._sid)
                    resetActiveRole()
                } catch (e) {
                    Sentry.captureMessage(
                        `Error removing role ${get(role, '_sid')}. Error message: ${get(
                            e,
                            'message'
                        )}`
                    )
                    setConfirmModalError(true)
                    setConfirmModalProcessing(false)
                }
            }}
            inProgress={confirmModalProcessing}
            details="Are you sure that you wish to delete this role? This action cannot be undone"
            error={
                confirmModalError
                    ? 'Sorry, there was an error deleting this role. Please try again.'
                    : undefined
            }
        />
    )

    const showRolePages = () => (
        <RolePages>
            {({ pages }: { pages: PageDto[] }) => (
                <>
                    <Label>Pages</Label>
                    <div
                        style={{
                            margin: '5px -5px',
                            padding: '5px 10px',
                            display: 'flex',
                            alignItems: 'center',
                            background: V4DesignSystem.colors.gray[10],
                        }}
                    >
                        <V2Icon icon="world" />
                        <div style={{ width: 10 }} />
                        <div>
                            {pages.find((page) => !page.permitted_by_all_roles) ? (
                                <>
                                    These pages have role sharing enabled. <br />
                                    Enable role sharing on other pages
                                </>
                            ) : (
                                <>
                                    There are no pages with role sharing enabled. <br /> Enable role
                                    sharing for pages
                                </>
                            )}{' '}
                            <a
                                href=""
                                onClick={(event) => {
                                    openAppSettings({ page: { name: 'navigation' } })
                                    event.preventDefault()
                                }}
                            >
                                from the Navigation
                            </a>
                        </div>
                    </div>
                    <FlexWrapper style={{ flex: 1 }}>
                        <CheckboxItem style={{ minWidth: 250 }}>
                            <Checkbox
                                data-testid="role-page-checkbox"
                                checked={allPages}
                                id="all pages"
                                onCheckedChange={(checked) => {
                                    setOptions({ ...options, all_pages: checked === true })
                                }}
                            />
                            <FormLabel
                                style={{ marginLeft: '10px', textAlign: 'left' }}
                                htmlFor="all pages"
                            >
                                All Pages
                            </FormLabel>
                        </CheckboxItem>
                        {pages
                            .filter((page) => !page.permitted_by_all_roles)
                            .map((page) => (
                                <CheckboxItem style={{ minWidth: 250 }} key={page._sid}>
                                    <Checkbox
                                        data-testid="role-page-checkbox"
                                        disabled={allPages || page.permitted_by_all_roles}
                                        checked={
                                            allPages ||
                                            page.permitted_by_all_roles ||
                                            newPermittedPages.includes(page._sid)
                                        }
                                        id={`page-${page._sid}`}
                                        onCheckedChange={(checked) => {
                                            let permittedPagesToSet = newPermittedPages

                                            if (!checked) {
                                                permittedPagesToSet = newPermittedPages.filter(
                                                    (p) => p !== page._sid
                                                )
                                            } else {
                                                permittedPagesToSet = [
                                                    ...permittedPagesToSet,
                                                    page._sid,
                                                ]
                                            }

                                            setNewPermittedPages(permittedPagesToSet)
                                        }}
                                    />
                                    <FormLabel
                                        style={{ marginLeft: '10px', textAlign: 'left' }}
                                        htmlFor={`page-${page._sid}`}
                                    >
                                        {page.permitted_by_all_roles && (
                                            <V2Icon
                                                icon="lock"
                                                display="inline"
                                                labelPlacement="bottom"
                                                size="sm"
                                                mr={2}
                                                label="This page is visible to all roles"
                                            />
                                        )}
                                        {page.name}
                                    </FormLabel>
                                </CheckboxItem>
                            ))}
                    </FlexWrapper>
                </>
            )}
        </RolePages>
    )

    if (!newRole || !role) return null

    return (
        <>
            <Content>
                <StyledSection>
                    <HeadingContainer>
                        <StyledIcon icon="user-lock" />
                        <Heading style={{ paddingTop: 0, paddingBottom: 0 }} size="medium">
                            {newRole?.label}
                        </Heading>
                    </HeadingContainer>
                    <Button
                        variant="adminSecondary"
                        buttonSize="sm"
                        icon="play"
                        mr={2}
                        onClick={async () => {
                            await previewAsRole(newRole?.api_name ?? '')
                            history.push(getUrl(Urls.Home))
                            closeAppSettings()
                        }}
                    >
                        Test as {newRole?.label}
                    </Button>
                </StyledSection>
                <Section>
                    <FlexWrapper>
                        <Label htmlFor="role-name">Name</Label>
                        <Input
                            value={newRoleLabel}
                            disabled={role?.options?.system_role}
                            onChange={(event) => {
                                const target = event.target.value
                                setNewRoleLabel(target)
                            }}
                            id="role-name"
                            style={{
                                flex: 1,
                                background: role?.options?.system_role ? '#ddd' : undefined,
                            }}
                        />
                    </FlexWrapper>
                    <Divider />
                    {showRolePages()}
                    {/* Hide this checkbox for now - may be re-enabled or deleted fully in the future*/}
                    {/*{workspaceApp && (*/}
                    {/*    <>*/}
                    {/*        <Divider />*/}
                    {/*        <FlexWrapper>*/}
                    {/*            <Label htmlFor="all-data">Can read/modify all data</Label>*/}
                    {/*            <Checkbox*/}
                    {/*                id="all-data"*/}
                    {/*                checked={*/}
                    {/*                    !!(state.newRights || []).find(*/}
                    {/*                        (right) => right === Rights.AllDataAccess*/}
                    {/*                    )*/}
                    {/*                }*/}
                    {/*                onCheckedChange={(checked) => {*/}
                    {/*                    setState((state) => {*/}
                    {/*                        let newRights = (state.newRights || []).filter(*/}
                    {/*                            (right) => right !== Rights.AllDataAccess*/}
                    {/*                        )*/}
                    {/*                        if (checked) {*/}
                    {/*                            newRights.push(Rights.AllDataAccess)*/}
                    {/*                        }*/}
                    {/*                        return {*/}
                    {/*                            ...state,*/}
                    {/*                            newRights: newRights,*/}
                    {/*                        }*/}
                    {/*                    })*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </FlexWrapper>*/}
                    {/*    </>*/}
                    {/*)}*/}
                    <Divider />

                    <Button
                        variant="adminPrimary"
                        buttonSize="sm"
                        isDisabled={isDisabled()}
                        style={{ width: '200px', alignSelf: 'center' }}
                        onClick={saveUpdates}
                    >
                        Save Changes
                    </Button>
                    {!role?.options?.system_role && defaultId !== roleId && (
                        <Button
                            variant="link"
                            color="gray.400"
                            buttonSize="sm"
                            style={{
                                width: '200px',
                                alignSelf: 'center',
                                textDecoration: 'underline',
                                marginTop: '10px',
                            }}
                            onClick={() => {
                                setDeleteModalOpen(true)
                            }}
                        >
                            Delete Role
                        </Button>
                    )}

                    {error && (
                        <Text style={{ color: '#e74c3c' }}>
                            Sorry, there was an error updating this new role. Please try again.
                        </Text>
                    )}
                    {duplicateNameError && (
                        <Text style={{ color: '#e74c3c' }}>
                            A role already exists with this name. Please choose another name.
                        </Text>
                    )}
                    {duplicateValueError && (
                        <Text style={{ color: '#e74c3c' }}>
                            A role already exists with this value. Please choose another value.
                        </Text>
                    )}
                </Section>
                {deleteConfirmModal()}
            </Content>
            {/* <RolePermissions match={match} role={state.role} /> */}
        </>
    )
}

const Content = styled(SettingsContent)`
    flex-direction: column;
`

const StyledSection = styled(Section)`
    flex-direction: row;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    flex-wrap: wrap;
`

const HeadingContainer = styled(Container)`
    align-items: center;
    flex: 1;
`

const StyledIcon = styled(Icon)`
    font-weight: 400;
    font-size: 25px;
    margin-right: 15px;
`

const FlexWrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const Label = styled(FormLabel)`
    text-align: left;
    margin-right: 20px;
    width: 200px;
    font-weight: bold;

    @media (max-width: 420px) {
        width: 100%;
    }
`

const CheckboxItem = styled('div')`
    flex: 0 182px;
    display: flex;
    align-items: center;
`

import React from 'react'

import { useTableViewContentState } from './hooks/useTableViewContentState'
import { TableViewRow } from './TableViewRow'

type TableViewContentProps = {}

export const TableViewContent: React.FC<TableViewContentProps> = React.memo(
    function TableViewContent() {
        const { records } = useTableViewContentState()

        return <>{records?.map((record, idx) => <TableViewRow key={idx} record={record} />)}</>
    }
)

import { MAGIC_SYNTHETIC_FIELD_TYPES } from 'data/utils/fieldDefinitions'

import { CreatableFieldTypeKey, FieldTypeKey } from './definitions/fieldTypeDefinitions'

export const TEXT_TYPES: FieldType[] = ['long_text', 'string']
export const NUMERIC_TYPES: FieldType[] = ['number', 'currency', 'percentage']
export const LIST_TYPES: FieldType[] = ['multi_select', 'multi_lookup', 'multi_file']
export const LOOKUP_TYPES: FieldType[] = ['lookup', 'multi_lookup']
export const STRING_TYPES: FieldType[] = [
    'string',
    'lookup',
    'long_text',
    'url',
    'image',
    'dropdown',
]
export const DATE_TYPES: FieldType[] = ['date', 'datetime']

export const LOGICAL_FIELD_TYPE_GROUPS: { name: string; fieldTypes: FieldTypeKey[] }[] = [
    {
        name: 'Text',
        fieldTypes: ['string', 'long_text', 'document'],
    },
    { name: 'Numeric', fieldTypes: NUMERIC_TYPES },
    { name: 'Choices', fieldTypes: ['checkbox', 'dropdown', 'multi_select'] },
    { name: 'Dates', fieldTypes: ['date', 'datetime'] },
    { name: 'Relationships', fieldTypes: ['relationship', ...LOOKUP_TYPES] },
    {
        name: 'Values from related records',
        fieldTypes: ['lookup_related_value', 'rollup_related_records'],
    },
    {
        name: 'Others',
        fieldTypes: ['multi_file', 'formula', 'url', 'image', ...MAGIC_SYNTHETIC_FIELD_TYPES],
    },
]

export type ConfigurationComponentProps = {
    field: FieldDto
    object?: ObjectDto
    dataConnection?: DataConnectionDto | null
    canChangeFieldType: boolean
    previousConfigRef?: React.MutableRefObject<Record<string, Partial<FieldDto>>>
}

export type FieldPatch = Partial<FieldDto> & { selectedType?: CreatableFieldTypeKey }

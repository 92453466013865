import React from 'react'

import { ServerError } from 'v2/ui/svgs/list-view-empty-states/ServerError'

import { Button } from 'ui/components/Button'

import { BaseEmptyState } from './BaseEmptyState'

type ServerErrorEmptyStateProps = React.ComponentPropsWithoutRef<typeof BaseEmptyState> & {
    onRetry?: () => void
}

export const ServerErrorEmptyState: React.FC<ServerErrorEmptyStateProps> = React.memo(
    function ServerErrorEmptyState({ onRetry, ...props }) {
        return (
            <BaseEmptyState
                image={<ServerError />}
                title="Something's wrong"
                description="We were unable to connect to the service. Click Retry to try again."
                footer={
                    onRetry && (
                        <Button variant="primary" size="m" onClick={onRetry}>
                            Retry
                        </Button>
                    )
                }
                {...props}
            />
        )
    }
)

import React, { useCallback, useRef, useState } from 'react'

import { Input } from 'ui/components/Input/Input'
import { HeadDoubleInputWrapperStyle } from 'ui/components/Menu/Menu.css'
import { Select } from 'ui/components/Select'

import { DropdownHeadBase } from './DropdownHeadBase'
import { useKeyDownFromHead } from './useKeyDownFromHead'

type DropdownHeadSelectInputRef = HTMLDivElement

type DropdownHeadSelectInputProps = React.ComponentPropsWithoutRef<typeof DropdownHeadBase> & {
    input: React.ComponentPropsWithoutRef<typeof Input>
    select: React.ComponentPropsWithoutRef<typeof Select>
    autoFocus?: boolean
}

export const DropdownHeadSelectInput = React.forwardRef<
    DropdownHeadSelectInputRef,
    DropdownHeadSelectInputProps
>(({ input, select, onFocus, autoFocus = true, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const selectRef = useRef<HTMLButtonElement>(null)

    const [headRef, setHeadRef] = useState<HTMLElement | null>(null)

    const handleOnFocus = useCallback(
        (e: React.FocusEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()

            // Only run this when the head is focused from another menu item.
            if (e.target !== e.currentTarget) return

            const select = selectRef.current
            if (!select) return

            select.focus()

            onFocus?.(e)
        },
        [onFocus]
    )

    const handleSelectKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLButtonElement>) => {
            select?.onKeyDown?.(e)

            if (e.key === 'ArrowDown') {
                e.preventDefault()

                const input = inputRef.current
                if (!input) return

                input.focus()
            }
        },
        [select]
    )

    const handleKeyDownFromHead = useKeyDownFromHead(headRef)

    const handleInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'ArrowUp') {
                e.preventDefault()

                const select = selectRef.current
                if (!select) return

                select.focus()

                return
            }

            handleKeyDownFromHead(e)
        },
        [handleKeyDownFromHead]
    )

    return (
        <DropdownHeadBase
            onFocus={handleOnFocus}
            {...props}
            ref={(element: HTMLDivElement) => {
                setHeadRef(element)

                return ref
            }}
        >
            <div className={HeadDoubleInputWrapperStyle}>
                <Select
                    ref={selectRef}
                    {...select}
                    onKeyDown={handleSelectKeyDown}
                    autoFocus={autoFocus}
                />
                <Input ref={inputRef} {...input} onKeyDown={handleInputKeyDown} />
            </div>
        </DropdownHeadBase>
    )
})

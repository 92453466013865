import React from 'react'

import { ViewPagesList } from 'features/AppSettings/Data/AppSettingsModalDataLayouts/AppSettingsModalDataLayoutsInner'
import { Section } from 'legacy/v1/ui/index'

export class AppSettingsModalDataLayouts extends React.Component {
    state = {}

    render() {
        const { object, onCloseSettingsModal, noPadding } = this.props
        return (
            <Section style={{ width: '100%', marginBottom: 0, padding: noPadding ? 0 : undefined }}>
                <ViewPagesList object={object} onCloseSettingsModal={onCloseSettingsModal} />
            </Section>
        )
    }
}

import React, { forwardRef } from 'react'

import * as Parts from './Field.parts'

type HelperTextRef = HTMLDivElement

type HelperTextProps = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.HelperText>,
    'isDisabled'
> & {
    disabled?: boolean
}

export const HelperText = forwardRef<HelperTextRef, HelperTextProps>(
    ({ size = 's', disabled, children, ...props }, ref) => {
        return (
            <Parts.HelperText ref={ref} isDisabled={disabled} size={size} {...props}>
                {children}
            </Parts.HelperText>
        )
    }
)

import * as React from 'react'

import { Rights } from 'app/accountUserContextConstants'
import { useAccountUserContext } from 'app/useAccountUserContext'

import { SidebarItem } from 'v2/ui'

import { Flex } from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

/**
 * list of sidebar items
 * @type {{id: ProfileSettingsPage, name: string, icon: string}[]}
 */
const menu = [
    {
        name: 'General',
        id: 'general',
        icon: 'cog',
    },
    {
        name: 'Notifications',
        id: 'notifications',
        icon: 'emailSolid',
    },
    {
        name: 'Support Login',
        id: 'supportLogin',
        icon: 'users',
        rightRequired: Rights.ContactSupport,
    },
    {
        name: 'Integration',
        id: 'integration',
        icon: 'cog',
    },
]

const useMenuItems = (hasRight) => {
    return menu.filter(({ rightRequired }) => {
        if (!rightRequired) {
            return true
        }

        return hasRight(rightRequired)
    })
}

export function Sidebar({ onChange, active }) {
    const { hasRight } = useAccountUserContext()

    const menuItems = useMenuItems(hasRight)

    const sidebarWidth = useResponsiveValue({
        mobile: '100%',
        tablet: '250px',
    })

    return (
        <Flex
            column
            style={{
                width: sidebarWidth,
                borderRight: '1px solid ' + V4DesignSystem.colors.gray[100],
                overflowY: 'auto',
                padding: 20,
                flexShrink: 0,
            }}
        >
            <div style={{ ...V4DesignSystem.label, paddingLeft: 10 }}>Settings</div>
            <div style={{ height: 5 }} />

            {menuItems.map(({ icon, name, id }) => (
                <React.Fragment key={`profile-settings-menu-${id}`}>
                    <SidebarItem active={active === id} onChange={() => onChange(id)} icon={icon}>
                        {name}
                    </SidebarItem>
                    <div style={{ height: 1 }} />
                </React.Fragment>
            ))}
        </Flex>
    )
}

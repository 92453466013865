import { AddNewRecordButtonCellStyle } from 'features/datagrid/cells/AddNewRecordCell.css'
import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'

import { useIsGridElementInViewport } from './useIsGridElementInViewport'

export function useIsAddNewRowPanelVisible(wrapperElement: HTMLElement | null) {
    const { showEmptyRecordRow, isTrashMode, canAddRecords } = useDataGridContext()

    const isAddNewRowButtonVisible = useIsGridElementInViewport(
        () => {
            return (
                (wrapperElement?.querySelector(`.${AddNewRecordButtonCellStyle}`) as HTMLElement) ??
                null
            )
        },
        () => {
            return (wrapperElement?.querySelector('.ag-body-viewport') as HTMLElement) ?? null
        }
    )

    return !isAddNewRowButtonVisible && !showEmptyRecordRow && !isTrashMode && canAddRecords
}

import React, { useEffect, useMemo, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { FormulaType, StackerFieldToFormulaType } from 'data/utils/fieldDefinitions'
import { FormulaEditor } from 'features/formulas/FormulaEditor'
import { FormulaEditorUpdate, ValidFormulaEditorUpdate } from 'features/formulas/formulaEditorTypes'
import { stringifyStackerAST } from 'features/formulas/parser/formulaParsingFunctions'
import { isFormulaField } from 'utils/fieldUtils'

import usePrevious from 'v2/ui/hooks/usePrevious'

import { FormField } from 'ui/deprecated/forms/FormField'

import { CONFIGURATION_EDITOR_COMPONENTS } from './FormulaConfiguration/formulaConfigurationComponents'
import { ConfigurationComponentProps } from './common'

export const FormulaFieldConfiguration: React.VFC<ConfigurationComponentProps> = ({
    object,
    field,
    previousConfigRef,
}) => {
    const isInitialized = useRef(false)
    const formContext = useFormContext()
    const patchDestination = 'connection_options'

    const formulaType = useWatch({ name: 'connection_options.formula_type' })
    const previousFormulaType = usePrevious(formulaType)

    useEffect(() => {
        if (isInitialized.current) {
            return
        }
        isInitialized.current = true
        if (
            field?.connection_options['formula_parsed'] &&
            field?.connection_options['formula_string'] &&
            field?.connection_options['formula_type']
        ) {
            formContext.setValue(
                'connection_options.formula_parsed',
                field?.connection_options['formula_parsed']
            )
            formContext.setValue(
                'connection_options.formula_string',
                field?.connection_options['formula_string']
            )
            formContext.setValue(
                'connection_options.formula_type',
                field?.connection_options['formula_type']
            )
        }
    }, [field?.connection_options, formContext])

    useEffect(() => {
        // if the formula type is changing, reset the field data type
        if (previousFormulaType && formulaType !== previousFormulaType) {
            formContext.setValue('type', undefined)
        }
    }, [formulaType, previousFormulaType, formContext])

    if (!object) return null

    return (
        <>
            <FormField
                name={patchDestination}
                as={FormulaEditor}
                errorMessages={{ required: 'A valid formula is required' }}
                controlledDefaultValue={field?.connection_options}
                controlledRender={({ field: formField }) => {
                    return (
                        <FormulaEditorWrapper
                            object={object}
                            field={field}
                            onChange={(update: ValidFormulaEditorUpdate) => {
                                const isChanged =
                                    update.formulaString !== formField.value?.formula_string
                                if (!isChanged) return

                                formContext.clearErrors(patchDestination)
                                formField.onChange({
                                    formula_parsed: update.parsedFormula,
                                    formula_string: update.formulaString,
                                    formula_type: update.formulaType,
                                })
                            }}
                            onError={() => {
                                formContext.setValue(patchDestination, null, { shouldDirty: true })
                            }}
                            onValid={() => {
                                formContext.clearErrors(patchDestination)
                            }}
                            previousConfigRef={previousConfigRef}
                        />
                    )
                }}
                controlled
                required
            />
            <FormulaConfigurationEditorForm field={field} />
        </>
    )
}

type FormulaEditorWrapperProps = {
    field: FieldDto
    object: ObjectDto
    onChange: (update: ValidFormulaEditorUpdate) => void
    onError: () => void
    onValid: () => void
    previousConfigRef: React.MutableRefObject<Record<string, Partial<FieldDto>>> | undefined
}

const FormulaEditorWrapper: React.FC<FormulaEditorWrapperProps> = ({
    field,
    object,
    onChange,
    onError,
    onValid,
    previousConfigRef,
}) => {
    const previousFormulaString =
        previousConfigRef?.current.formula?.connection_options?.formula_string

    const initialFormulaString = useMemo(() => {
        if (isFormulaField(field)) {
            return stringifyStackerAST(field?.connection_options?.formula_parsed, object)
        }
        return previousFormulaString || ''
    }, [field, object, previousFormulaString])

    return (
        <FormulaEditor
            field={field}
            initialFormulaString={initialFormulaString}
            object={object}
            onChange={(update: FormulaEditorUpdate) => {
                if (update.isValid) {
                    onChange(update)
                    onValid()
                } else {
                    onError()
                }
            }}
        />
    )
}

function FormulaConfigurationEditorForm({ field }: { field: FieldDto }) {
    let formulaType: FormulaType | undefined = useWatch({
        name: 'connection_options.formula_type',
    })?.toString()

    const parsedFormula = useWatch({ name: 'connection_options.formula_parsed' })

    // If we don't have a formula type saved in connection options, then this
    // is an old field. We will default to the appropriate type based on the field type
    if (!formulaType && parsedFormula && field) {
        formulaType = StackerFieldToFormulaType[field.type]
    }

    // Only show the configuration editor if we have a formula type and a valid parsed formula
    if (!formulaType || !parsedFormula) return null

    const ConfigurationEditor = CONFIGURATION_EDITOR_COMPONENTS[formulaType]
    if (!ConfigurationEditor) return null

    return <ConfigurationEditor originalField={field} />
}

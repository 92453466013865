import React, { useMemo, useRef, useState } from 'react'

import { useWorkflows, useWorkflowSchema } from 'data/hooks/workflows/workflows'

import { LoadingScreen } from 'v2/ui'

import { Container } from 'ui/components/Container'

import { DRAFT_WORKFLOW_ID, makeDraftWorkflow } from './common'
import { WorkflowManagerBreadcrumb, WorkflowManagerPane } from './useWorkflowManagerContext'
import { WorkflowEditor } from './WorkflowEditor'
import { WorkflowManagerContextProvider } from './WorkflowManagerContext'
import { WorkflowsView } from './WorkflowsView'

const BREADCRUMBS_MAX_SIZE = 5

const BREADCRUMBS_PLACEHOLDER: WorkflowManagerBreadcrumb[] = [
    {
        pane: 'list',
    },
]

type WorkflowManagerProps = {}
export function WorkflowManager({}: WorkflowManagerProps) {
    const [currentPane, setCurrentPane] = useState<WorkflowManagerPane>('list')
    const [breadcrumbs, setBreadcrumbs] =
        useState<WorkflowManagerBreadcrumb[]>(BREADCRUMBS_PLACEHOLDER)

    const setCurrentPaneWithHistory = (pane: WorkflowManagerPane) => {
        setBreadcrumbs((prev) => {
            if (pane === 'list') {
                return BREADCRUMBS_PLACEHOLDER
            }
            return [
                ...prev,
                {
                    pane,
                },
            ].slice(-BREADCRUMBS_MAX_SIZE)
        })

        setCurrentPane(pane)
    }

    const goBackWithHistory = () => {
        const prevPane = breadcrumbs[breadcrumbs.length - 2]?.pane ?? 'list'

        if (prevPane === 'list') {
            setSelectedWorkflowSid(undefined)
        }

        setCurrentPane(prevPane)
        setBreadcrumbs((prev) => prev.slice(0, prev.length - 1))
    }

    const { data: workflows = [], isLoading } = useWorkflows()
    const { data: schema, isLoading: schemaIsLoading } = useWorkflowSchema()
    const [selectedWorkflowSid, setSelectedWorkflowSid] = useState<string | undefined>()

    const draftWorkflow = useRef<WorkflowDto | undefined>()
    const createDraftWorkflow = () => {
        if (!draftWorkflow.current) {
            draftWorkflow.current = makeDraftWorkflow(schema?.version)
        }

        setSelectedWorkflowSid(DRAFT_WORKFLOW_ID)
        setCurrentPaneWithHistory('editorCanvas')
    }

    const discardDraftWorkflow = () => {
        draftWorkflow.current = undefined
    }

    const selectedWorkflow = useMemo(() => {
        if (selectedWorkflowSid === DRAFT_WORKFLOW_ID) {
            return draftWorkflow.current
        }

        return workflows.find((workflow) => workflow._sid === selectedWorkflowSid)
    }, [selectedWorkflowSid, workflows])

    const handleCloseEditor = () => {
        setSelectedWorkflowSid(undefined)
        setCurrentPaneWithHistory('list')
        discardDraftWorkflow()
    }

    const openNewFlowInEditor = (workflow: WorkflowDto) => {
        setSelectedWorkflowSid(workflow._sid)
        setCurrentPaneWithHistory('editorCanvas')
        discardDraftWorkflow()
    }

    const selectWorkflow = (workflow: WorkflowDto) => {
        setSelectedWorkflowSid(workflow._sid)
        setCurrentPaneWithHistory('editorCanvas')
    }

    const openFlowExecutionHistory = (workflow: WorkflowDto) => {
        setSelectedWorkflowSid(workflow._sid)
        setCurrentPaneWithHistory('editorRunHistory')
    }

    return (
        <WorkflowManagerContextProvider
            currentPane={currentPane}
            breadcrumbs={breadcrumbs}
            navigateToPane={setCurrentPaneWithHistory}
            navigateBack={goBackWithHistory}
        >
            <Container variant="base" width="full" height="full">
                <LoadingScreen isLoading={isLoading || schemaIsLoading}>
                    {selectedWorkflow ? (
                        <WorkflowEditor
                            workflow={selectedWorkflow}
                            onClose={handleCloseEditor}
                            openNewFlowInEditor={openNewFlowInEditor}
                        />
                    ) : (
                        <WorkflowsView
                            workflows={workflows}
                            onSelectWorkflow={selectWorkflow}
                            onCreateClick={createDraftWorkflow}
                            openFlowExecutionHistory={openFlowExecutionHistory}
                        />
                    )}
                </LoadingScreen>
            </Container>
        </WorkflowManagerContextProvider>
    )
}

import React from 'react'

import { useCardViewLoaderState } from './hooks/useCardViewLoaderState'
import { CardViewCard } from './CardViewCard'

type CardViewLoaderProps = {}

export const CardViewLoader: React.FC<CardViewLoaderProps> = () => {
    const { placeholderCards } = useCardViewLoaderState()

    return (
        <>
            {placeholderCards.map((record, idx) => (
                <CardViewCard key={idx} record={record} isLoading />
            ))}
        </>
    )
}

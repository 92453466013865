import React, { useMemo } from 'react'

import { InlineFilter } from 'features/records/components/RecordFilters/inlineFilters/InlineFilter'
import { InlineFilterMultiValueOption } from 'features/records/components/RecordFilters/inlineFilters/types'
import { useInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/useInlineFilter'
import { getInlineFilterValueLabels } from 'features/records/components/RecordFilters/inlineFilters/valueUtils'

import { InlineFilterComponent } from './types'

const OPTIONS: InlineFilterMultiValueOption[] = [
    {
        label: 'is checked',
        value: 'true',
        color: 'userInterface.success.300',
    },
    {
        label: 'is unchecked',
        value: 'false',
        color: 'userInterface.error.300',
    },
]

type BooleanInlineFilterProps = {}

export const BooleanInlineFilter: InlineFilterComponent<BooleanInlineFilterProps> = ({
    field,
    columns,
}) => {
    const { filter, ...inlineFilterProps } = useInlineFilter({ field })

    const valueLabels = useMemo(() => getInlineFilterValueLabels(filter, OPTIONS), [filter])

    return (
        <InlineFilter
            closeOnSelect={true}
            valueLabels={valueLabels}
            field={field}
            columns={columns}
            options={OPTIONS}
            {...inlineFilterProps}
        />
    )
}

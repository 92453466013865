import React from 'react'

import { orderBy } from 'lodash'

import { useTasks } from 'data/hooks/tasks/tasks'
import { TaskListNewTaskPlaceholder } from 'features/tasks/TaskListNewItemPlaceholder'

import { LoadingScreen } from 'v2/ui'

import { TaskListContextProvider } from './TaskListContextProvider'
import { TasksList } from './TasksList'
import { TasksQuickCreateProps } from './TasksQuickCreate'

export type RelatedTaskListProps = TasksQuickCreateProps & {
    defaultAddNew?: boolean
}
export function RelatedTaskList({ defaultAddNew, ...props }: RelatedTaskListProps) {
    const { related_to, _object_id } = props
    const {
        data: taskData = { tasks: [] },
        isLoading,
        actions,
    } = useTasks({ related_to }, { enabled: !!related_to })

    const sortedTasks = React.useMemo(
        () => orderBy(taskData.tasks || [], ['due_at', 'created_at']),
        [taskData.tasks]
    )
    return (
        <LoadingScreen isLoading={isLoading} flexGrow={1}>
            <TaskListContextProvider
                value={{ ...taskData, ...actions, related_to, _object_id, showAssignees: 'always' }}
            >
                <TasksList tasks={sortedTasks} />

                <TaskListNewTaskPlaceholder
                    mx="l"
                    mt="m"
                    defaultIsEditing={defaultAddNew}
                    {...props}
                />
            </TaskListContextProvider>
        </LoadingScreen>
    )
}

import { useCallback, useContext, useMemo, useState } from 'react'

import { NavigationAppMenuItem } from 'features/navigation/types'
import { getActiveMenuItem } from 'features/navigation/utils'
import { PathContext } from 'features/utils/PathContext'

import { useAppNavigationContext } from './useAppNavigationContext'

type UseAppNavigationTreeItemStateProps = {
    item: NavigationAppMenuItem
    isTopLevel?: boolean
    isFirstItem?: boolean
}

export function useAppNavigationTreeItemState({
    item,
    isTopLevel = false,
    isFirstItem = false,
}: UseAppNavigationTreeItemStateProps) {
    const { menuItems } = useAppNavigationContext()

    const { pageUrl } = useContext(PathContext)
    const activeMenuItem = getActiveMenuItem(menuItems, pageUrl)

    const isActive = activeMenuItem?.id === item.id
    const hasChildren = !!item.children.length
    const hasIcon = !!item.icon && isTopLevel

    // Keep the first item open by default.
    const defaultExpandedState = restoreExpandedState(item) ?? isFirstItem
    const [isExpanded, setIsExpanded] = useState(defaultExpandedState)

    const toggleIsExpanded = useCallback(() => {
        setIsExpanded((prev) => {
            const newState = !prev
            persistExpandedState(item, newState)

            return newState
        })
    }, [item])

    return useMemo(
        () => ({
            isActive,
            hasChildren,
            hasIcon,
            isExpanded,
            setIsExpanded: toggleIsExpanded,
        }),
        [isActive, hasChildren, hasIcon, isExpanded, toggleIsExpanded]
    )
}

function getExpandedStateStorageKey(item: NavigationAppMenuItem) {
    return `Nav_item_${item.id}_Expanded`
}

function persistExpandedState(item: NavigationAppMenuItem, isExpanded: boolean) {
    const key = getExpandedStateStorageKey(item)
    localStorage.setItem(key, isExpanded.toString())
}

function restoreExpandedState(item: NavigationAppMenuItem) {
    const key = getExpandedStateStorageKey(item)
    const value = localStorage.getItem(key)

    if (!value) return undefined

    return value === 'true'
}

// @ts-strict-ignore
import React, { useLayoutEffect, useRef, useState } from 'react'

import { DefaultValue } from 'v2/views/utils/useDefaultValuesForView'

import { useRecordEditManagerContext } from 'features/records/useRecordEditManagerContext'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'

import { prefillRelatedRecordData } from './kanbanUtils'

import { KanbanNewItemPlacholderStyle } from './KanbanStyles.css'

type KanbanNewCardPlaceholderProps = {
    statusValue: string
    statusField: FieldDto
    object: ObjectDto
    relatedListField?: FieldDto
    relatedListFieldValue?: string
    defaultValues?: DefaultValue[]
}
export function KanbanNewCardPlaceholder({
    statusValue,
    statusField,
    object,
    relatedListField,
    relatedListFieldValue,
    defaultValues,
}: KanbanNewCardPlaceholderProps) {
    const { addRecord } = useRecordEditManagerContext()
    const [isEditing, setIsEditing] = useState(false)
    const [name, setName] = useState('')
    const primaryField = object?.fields?.find((f) => f.is_primary)

    const doAddRecord = () => {
        if (name) {
            const record: Partial<RecordDto> = {
                _object_id: object._sid,
            }

            if (defaultValues) {
                for (const defaultValue of defaultValues) {
                    record[defaultValue.fieldName] = defaultValue.defaultValue
                }
            }

            // Make sure these are not overriden by default values.
            record[primaryField.api_name] = name
            record[statusField.api_name] = statusValue

            // If this is a related list, pre-set the related record field.
            prefillRelatedRecordData(record, relatedListField, relatedListFieldValue)

            addRecord(record as RecordDto)
        }
        setName('')
        setIsEditing(false)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            doAddRecord()
            setIsEditing(true)
        } else if (e.key === 'Escape') {
            setName('')
            setIsEditing(false)
        }
    }

    const inputRef = useRef<HTMLInputElement>(null)

    const isEditingRef = useRef(isEditing)
    isEditingRef.current = isEditing
    const doAddRecordRef = useRef(doAddRecord)
    doAddRecordRef.current = doAddRecord

    useLayoutEffect(() => {
        // Implementing custom blur logic, so we won't trigger the blur event when switching tabs.
        const saveOnBlur = (e: MouseEvent) => {
            if (e.target === inputRef.current) return

            queueMicrotask(() => {
                doAddRecordRef.current()
            })
        }

        window.addEventListener('mousedown', saveOnBlur)

        return () => {
            window.removeEventListener('mousedown', saveOnBlur)
        }
    }, [])

    if (isEditing) {
        return (
            <Box flex center>
                <Input
                    ref={inputRef}
                    grow
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    mr="xs"
                    style={{ paddingRight: '20px' }}
                />

                <Button
                    startIcon={{ name: 'Check' }}
                    variant="ghost"
                    size="xs"
                    style={{ marginLeft: '-34px' }}
                />
            </Box>
        )
    }
    return (
        <Button
            variant="ghost"
            size="s"
            width="full"
            startIcon={{ name: 'Plus' }}
            className={KanbanNewItemPlacholderStyle}
            onClick={() => setIsEditing(true)}
        >
            Add item
        </Button>
    )
}

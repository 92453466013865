import { useMemo } from 'react'

import Color from 'color'
import sortBy from 'lodash/sortBy'

import { getCurrentStackId } from 'app/GlobalStaticState'
import { useAppUserContext } from 'app/useAppUserContext'
import { useNavigation } from 'data/hooks/navigation'
import { usePages } from 'data/hooks/pages'
import { useViews } from 'data/hooks/views'
import { viewHasAssociatedPage } from 'features/core/nav/viewHasAssociatedPage'
import publicAsset from 'utils/publicAsset'

import type { NavTree, NavTreeItem } from './types'

export const buildNavTree = (
    navigation: NavigationDto[],
    views: ViewDto[],
    pages: PageDto[],
    _role?: RoleDto | null, // Not actually used in this method, but it allows us to add role as a memo dependency
    suppliedStackId?: string
): NavTree => {
    /*
    This method will transform the array of navigations (with toplevel and secondlevel mixed together)
    into a sorted tree grouped by objects with toplevel folders and links nested as ".children"

    example:
    navigation = [{_sid:'1',object:'users', parent_id: null}, {_sid:'2': object:'users': parent_id:'1'}]
    will be translated to
    navTree = [{_sid:'1',object:'users', children:[{_sid:'2': object:'users'}]}]
    */

    const stackId = suppliedStackId || getCurrentStackId()

    const visibleViewsUrls = views
        ?.filter((v) => viewHasAssociatedPage(v, pages) && stackId === v.stack_id)
        ?.map((p) => p.url)

    const navigationByObject: { [key: string]: NavigationDto[] } = {}
    navigation
        .filter((n) => n.stack_id === stackId)
        .forEach((navItem) => {
            const objectId = navItem.object_id ?? null
            if (navigationByObject[objectId!]) {
                navigationByObject[objectId!].push(navItem)
            } else {
                navigationByObject[objectId!] = [navItem]
            }
        })

    let navTree: NavTree = []
    for (const objectId in navigationByObject) {
        if (navigationByObject.hasOwnProperty(objectId)) {
            const navItems = navigationByObject[objectId]

            let parents = navItems.filter((navItem) => navItem.parent_id === null)

            parents.forEach((parent) => {
                const parentNavItem: NavTreeItem = { ...parent }
                const children = navItems.filter(
                    (navItem) => navItem.parent_id && navItem.parent_id === parent._sid
                )
                parentNavItem.children = sortBy(children, ['display_order']).filter(
                    (child) => !visibleViewsUrls || visibleViewsUrls.includes(child.url)
                )

                if (parentNavItem.children.length) {
                    navTree.push(parentNavItem)
                }
            })
        }
    }

    navTree = sortBy(navTree, ['display_order'])

    return navTree
}

type NavTreeOptions = {
    stackId?: string
}

export const useNavTree = (options?: NavTreeOptions) => {
    const { data: navigation } = useNavigation(options)
    const { data: views } = useViews(options)
    const { data: pages } = usePages(options)
    const { role } = useAppUserContext()

    return useMemo(() => {
        if (!navigation || !views || !pages) return []
        return buildNavTree(navigation, views, pages, role, options?.stackId)
        // Note: we do need role, so that it correctly re-caclulates the nav tree
    }, [navigation, views, pages, role, options?.stackId])
}

export const navigationColor = (theme: any, isSecondaryNav: boolean, isNewColorScheme: boolean) => {
    const stackerColorLogo = publicAsset('/static/media/stacker-logo-colour.png')
    const stackerWhiteLogo = publicAsset('/static/media/stacker-logo-white.png')

    const isMobile = window.innerWidth < 768

    let height
    if (isMobile) {
        height = isSecondaryNav ? 25 : 40
    } else {
        height = isSecondaryNav ? 32 : 64
    }

    const lighten = (value: number) => {
        return Color(theme.brandColor).lighten(value).string()
    }
    const darken = (value: number) => {
        return Color(theme.brandColor).darken(value).string()
    }
    const fade = (color: any, value: number) => {
        return Color(color).fade(value).string()
    }

    const layoutItemHeight = height - 2 // keep a gap on the bottom border of the selected layout item
    switch (theme.navColor) {
        case 'dark':
            return {
                navColor: isNewColorScheme ? '#333C43' : '#343843',
                secondNavColor: isNewColorScheme ? '#454e55' : '#1B1E27',
                navBorderColor: fade('#000000', 0.5),
                stackerLogo: stackerWhiteLogo,
                textColor: fade('#ffffff', 0.2),
                textColorBright: '#ffffff',
                borderTop: `1px solid #343843`,
                height: layoutItemHeight + 'px',
                highlightColor: fade('#ffffff', 0.85),
                hoverColor: fade('#ffffff', 0.9),
                navFadeColor: fade('#ffffff', 0.95),
                selectedStyle: {
                    borderBottom: `3px solid ${theme.brandColor}`,
                },
                button: {
                    color: '#ffffff',
                    selectedColor: '#ffffff',
                    background: 'rgba(255,255,255,0.2)',
                },
            }
        case 'brand':
            let isDark = true

            try {
                isDark = Color(theme.brandColor).isDark()
            } catch {
                // if brand color is invalid, just assume dark
            }
            const color = isDark ? '#ffffff' : '#000000'

            return {
                navColor: theme.brandColor,
                secondNavColor: isNewColorScheme ? lighten(0.2) : darken(0.1),
                navFadeColor: fade(color, 0.93),
                navBorderColor: isDark ? darken(0.2) : 'rgba(0,0,0,.1)',
                textColor: '#ffffff',
                textColorBright: color,
                stackerLogo: isDark ? stackerWhiteLogo : stackerColorLogo,
                borderTop: `1px solid ${theme.brandColor}`,
                height: layoutItemHeight + 'px',
                highlightColor: fade(color, 0.8),
                hoverColor: fade(color, 0.9),
                selectedStyle: {
                    borderBottom: `3px solid #ffffff`,
                },
                button: {
                    color: color,
                    selectedColor: color,
                    background: isDark ? 'rgba(0,0,0,0.2)' : '#ffffff',
                },
            }
        default:
            // White or undefined
            return {
                navColor: '#ffffff',
                secondNavColor: '#ffffff',
                navFadeColor: fade('#000000', 0.93),
                navBorderColor: fade('#000000', 0.9),
                textColor: fade('#000000', 0.2),
                textColorBright: '#000000',
                stackerLogo: stackerColorLogo,
                borderTop: '1px solid #eee',
                height: layoutItemHeight + 'px',

                highlightColor: fade('#000000', 0.85),
                hoverColor: fade('#000000', 0.9),
                selectedStyle: {
                    borderBottom: `3px solid ${theme.brandColor}`,
                },
                button: {
                    color: '#B0B3B9',
                    selectedColor: '#000000',
                    background: '#F4F5F8',
                },
            }
    }
}

export const findFirstEnablednavItem = (navTree: NavTree) => {
    for (const navItem of navTree) {
        if (navItem.children && navItem.children?.length === 1) {
            if (!navItem.children[0].hidden) {
                return navItem.children[0]
            }
        }
        if (navItem.children && navItem.children?.length > 1) {
            if (navItem.children.find((child) => child.hidden === false)) {
                return navItem
            }
        }
    }
}

import React, { forwardRef } from 'react'

import * as Parts from './Popup.parts'

type PopupFooterRef = HTMLDivElement

type PopupFooterProps = React.ComponentPropsWithoutRef<typeof Parts.Footer> & {}

export const PopupFooter = forwardRef<PopupFooterRef, PopupFooterProps>((props, ref) => {
    return <Parts.Footer ref={ref} {...props} />
})

import { useRef } from 'react'

import isEqual from 'lodash/isEqual'

export function useDeepEqualsMemoValue<T>(value: T) {
    const ref = useRef(value)

    if (!isEqual(value, ref.current)) {
        ref.current = value
    }

    return ref.current
}

export default useDeepEqualsMemoValue

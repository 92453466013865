// eslint-disable-next-line unused-imports/no-unused-vars
const shadows = (config) => {
    return {
        sm: '0px 0px 2px rgba(0, 0, 0, 0.15), 1px 1px 5px -2px rgba(0,0,0, 0.15)',
        popup: '1px 1px 3px 2px rgb(0, 0, 0, 0.15)',
        admin: {
            input: 'inset 0px 1px 3px rgba(0, 0, 0, 0.12)',
            upgradeButton: {
                focus: '0 0 0 3px #009b75',
            },
        },
    }
}

export default shadows

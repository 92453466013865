import { useContext } from 'react'

import {
    AppNavigationContext,
    AppNavigationContextValue,
} from 'features/navigation/AppNavigationContext'

export function useAppNavigationContext(): AppNavigationContextValue {
    const context = useContext(AppNavigationContext)

    if (!context) {
        throw new Error(
            'useAppNavigationContext must be used within a AppNavigationContextProvider'
        )
    }

    return context
}

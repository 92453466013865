import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationSpacesState() {
    const { spaces } = useNavigationContext()

    return useMemo(
        () => ({
            spaces,
        }),
        [spaces]
    )
}

import React from 'react'

import { EmbeddedServerErrorEmptyState } from 'features/views/ListView/EmptyState/EmbeddedServerErrorEmptyState'
import { ServerErrorEmptyState } from 'features/views/ListView/EmptyState/ServerErrorEmptyState'

import { Box } from 'ui/components/Box'

import { useBoardViewDataState } from './hooks/useBoardViewDataState'
import { BoardViewContent } from './BoardViewContent'
import { BoardViewLoader } from './BoardViewLoader'
import { BoardViewScrollArea } from './BoardViewScrollArea'

type BoardViewDataProps = {}

export const BoardViewData: React.FC<BoardViewDataProps> = React.memo(function BoardViewData() {
    const { showTopPadding, isFetchingSlow, isError, isEmbedded, retryFetchRecords } =
        useBoardViewDataState()

    return (
        <Box
            px={{ tablet: '4xl' }}
            pt={{ tablet: showTopPadding ? 'xl' : undefined }}
            style={{
                /* Expand element to show nested focus shadows. */
                marginTop: showTopPadding ? undefined : '-5px',
                paddingTop: showTopPadding ? undefined : '5px',
            }}
            width="full"
            overflow="hidden"
            flex
            center
            flexDirection="column"
            background="surface"
            grow
        >
            {isError && isEmbedded && <EmbeddedServerErrorEmptyState />}
            {isError && !isEmbedded && <ServerErrorEmptyState onRetry={retryFetchRecords} />}
            {!isError && (
                <BoardViewScrollArea>
                    <Box
                        flex
                        alignItems="flex-start"
                        height="full"
                        width="full"
                        gap="m"
                        p={{ mobile: 'xl', tablet: 0 }}
                        pt={{ mobile: showTopPadding ? 'xl' : 0, tablet: 0 }}
                    >
                        {isFetchingSlow ? <BoardViewLoader /> : <BoardViewContent />}
                    </Box>
                </BoardViewScrollArea>
            )}
        </Box>
    )
})

import { useEffect, useState } from 'react'

import {
    Aggregation,
    AggregationGroup,
    AggregationType,
    useAggregates,
    useInvalidateObjectAggregates,
} from 'data/hooks/aggregates'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'
import { filtersToQueryDict } from 'features/utils/filtersToQueryDict'
import { ListViewSort } from 'features/views/ListView/Sort/types'
import { getSupportedCalculationTypes } from 'features/views/ListView/TableView/Calculations/utils'
import { TableViewColumn } from 'features/views/ListView/TableView/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

export type TableViewCalculationItem = {
    label: string
    type: AggregationType
    value: number
    isPrimary: boolean
}

export type TableViewCalculations = Record<
    string,
    Record<AggregationType, TableViewCalculationItem>
>

type UseTableViewCalculationsOptions = {
    stack: StackDto
    object: ObjectDto
    requestFilters: Filter[]
    requestIncludedFields: string[]
    requestSearchFields: string[]
    columns: TableViewColumn[]
    sortBy?: ListViewSort
}

export function useTableViewCalculations(options: UseTableViewCalculationsOptions) {
    const {
        stack,
        object,
        requestFilters,
        requestIncludedFields,
        requestSearchFields,
        sortBy,
        columns,
    } = options

    const aggregateFilters = useDeepEqualsMemoValue(mapFiltersToAggregateFilters(requestFilters))
    const aggregateConfigs = useDeepEqualsMemoValue(makeAggregationConfigs(columns))

    const shouldFetch = aggregateConfigs.length > 0

    const {
        data: aggregates,
        isFetching,
        isError,
    } = useAggregates({
        objectId: object._sid,
        aggregations: aggregateConfigs,
        filters: aggregateFilters,
        searchFields: requestSearchFields,
        includedFields: requestIncludedFields,
        orderBy: sortBy,
        options: {
            enabled: shouldFetch,
        },
    })
    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const isLoading = !aggregates
    const [isLoadingSlow, setIsLoadingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isLoading) {
            timer = window.setTimeout(() => {
                setIsLoadingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsLoadingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isLoading])

    // Invalidate aggregates when the object values change.
    const invalidateAggregates = useInvalidateObjectAggregates()
    useRealtimeObjectUpdates({
        stack,
        objectIds: [object._sid],
        handler: () => {
            invalidateAggregates(object._sid)
        },
        disabled: !shouldFetch,
    })

    return useDeepEqualsMemoValue({
        calculations: makeCalculationsFromAggregates(columns, aggregates),
        isFetchingSlow,
        isLoadingSlow,
        isError,
    })
}

function makeCalculationsFromAggregates(
    columns: TableViewColumn[],
    aggregates?: AggregationGroup[]
): TableViewCalculations | undefined {
    const columnsByFieldApiName = columns.reduce((acc, column) => {
        return acc.set(column.field.api_name, column)
    }, new Map<string, TableViewColumn>())

    return aggregates?.[0]?.aggregates.reduce((acc, aggregate) => {
        const fieldApiName = aggregate.fieldApiName
        if (!fieldApiName) return acc

        const column = columnsByFieldApiName.get(fieldApiName)
        if (!column) return acc

        const type = aggregate.type
        const value = aggregate.value
        const label = getLabelFromCalculation(type)
        const isPrimary = column.calculation?.type === type

        const newItem: TableViewCalculationItem = {
            label,
            type,
            value,
            isPrimary,
        }

        acc[fieldApiName] = {
            ...acc[fieldApiName],
            [type]: newItem,
        }

        return acc
    }, {} as TableViewCalculations)
}

function getLabelFromCalculation(type?: AggregationType): string {
    switch (type) {
        case 'count':
            return 'Not empty'
        case 'countempty':
            return 'Empty'
        case 'countall':
            return 'Count'
        case 'sum':
            return 'Sum'
        case 'avg':
            return 'Average'
        case 'min':
            return 'Min'
        case 'max':
            return 'Max'
        case 'range':
            return 'Range'
        case 'median':
            return 'Median'
        default:
            return ''
    }
}

function mapFiltersToAggregateFilters(filters: Filter[]): ChartFilter[] {
    return (filtersToQueryDict(filters).filters as ChartFilter[] | undefined) ?? []
}

function makeAggregationConfigs(columns: TableViewColumn[]): Aggregation[] {
    return columns.reduce((acc, column) => {
        if (column.calculation) {
            const field = column.field
            const supportedTypes = getSupportedCalculationTypes(field)
            for (const type of supportedTypes) {
                acc.push({
                    type,
                    fieldApiName: field.api_name,
                })
            }
        }

        return acc
    }, [] as Aggregation[])
}

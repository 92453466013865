// remove self props to pass down to dom element
export const cleanUpProps = ({
    /* eslint-disable unused-imports/no-unused-vars */
    context,
    params,
    styleContext = undefined,
    style = undefined,
    setAttr,
    setAttrs,
    fieldRef = undefined,
    field,
    simple = undefined,
    simpleOnly = undefined,
    setOnlyVisible = undefined,
    render,
    /* eslint-enable */
    ...otherProps
}: Record<string, any>): Record<string, any> => otherProps

import React from 'react'

import { ContextItem } from 'features/expressions/types'
import { Highlighter } from 'features/Search/Highlighter'

import { Box } from 'ui/components/Box'

export function RenderContextItem({
    item,
    queryTerms,
}: {
    item: ContextItem
    queryTerms?: string[]
}) {
    return (
        <Box flex center maxWidth="full" width="full" color="text" tabIndex={-1}>
            <Box flex column trim grow>
                <Highlighter terms={queryTerms || []}>{item.name}</Highlighter>
                <Box
                    as={Highlighter}
                    fontSize="bodyXs"
                    terms={queryTerms || []}
                    maxWidth="300px"
                    whiteSpace="break-spaces"
                >
                    {item.description}
                </Box>
            </Box>
        </Box>
    )
}

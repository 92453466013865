// @ts-strict-ignore
import React from 'react'
import { render } from 'react-dom'

import './utils/dev-tools'

import { RootWithLdProvider } from './app/RootWithLdProvider'
import store from './app/store'
import { consumeLoadAction } from './features/loadActions'

import 'v2/ui/theme/styles.scss'

function doRender(RootElem) {
    render(<RootElem store={store} />, document.getElementById('app'))
}

consumeLoadAction()
doRender(RootWithLdProvider)

import React, { VFC } from 'react'

import { WorkflowManager } from 'features/workflows/WorkflowManager'
import { SlidingPaneFrame } from 'features/workspace/AdminSideTray/common/SlidingPaneFrame'

type WorkflowsSidePaneProps = {}
export const WorkflowsSidePane: VFC<WorkflowsSidePaneProps> = () => (
    <SlidingPaneFrame
        title="Manage workflows"
        description="Build automations and custom workflows."
    >
        <WorkflowManager />
    </SlidingPaneFrame>
)

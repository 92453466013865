import React from 'react'

import { OutputSchemaEditor } from 'features/workflows/OutputSchema/OutputSchemaEditor'
import { RequestMethodDropdown } from 'features/workflows/RequestMethodDropdown'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Box } from 'ui/components/Box'

import { WorkflowActionEditorComponent } from './types'
import { getRequestMethod, supportsRequestMethodBody } from './useAPIRequestActionFinalSchema'

export const APIRequestActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const requestMethod = getRequestMethod(action)
    const responseSchema = action.settings?.response_schema as WorkflowOutputSchema | undefined

    const handleChangeSettings = (patch: Record<string, unknown>) => {
        onChange({
            settings: {
                ...action.settings,
                ...patch,
            },
        })
    }

    const handleChangeRequestMethod = (value: string) => {
        const patch: Record<string, unknown> = {
            settings: { ...action.settings, request_method: value },
        }

        const supportsBody = supportsRequestMethodBody(value)
        if (!supportsBody) {
            const newInputs: WorkflowActionConfigInput[] = action.inputs.map((input) => {
                if (input.id === 'body') {
                    return {
                        ...input,
                        value: {
                            input_content: null,
                            value: undefined,
                        },
                    } as WorkflowActionConfigInput
                }

                return input
            })
            patch.inputs = newInputs
        }

        onChange(patch)
    }

    return (
        <>
            <Box flex center gap="m">
                <Box style={{ flexBasis: '50%' }}>
                    <ConfigurationLabel>Request method</ConfigurationLabel>
                    <RequestMethodDropdown
                        value={requestMethod}
                        onChange={handleChangeRequestMethod}
                    />
                </Box>
                <Box style={{ flexBasis: '50%' }}>
                    <ConfigurationLabel>Response format</ConfigurationLabel>
                    JSON
                </Box>
            </Box>
            <ConfigurationLabel>Response attributes</ConfigurationLabel>
            <OutputSchemaEditor
                value={responseSchema}
                onChange={(value) => handleChangeSettings({ response_schema: value })}
                mb="l"
            />
        </>
    )
}

import { useMemo } from 'react'

import { useStacks } from 'data/hooks/stacks'
import { NavigationApp } from 'features/navigation/types'
import { mapStackToNavigationApp } from 'features/navigation/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useNavigationContext } from './useNavigationContext'

export function usePortalAppsState() {
    const { selectedApp, workspaceZone, isOnPortalDomain, workspaceAccount } =
        useNavigationContext()

    const { data: stacks = [] } = useStacks()

    const apps: NavigationApp[] = useMemo(() => {
        return stacks.map((stack) => {
            return mapStackToNavigationApp({
                stack,
                account: workspaceAccount,
                zone: workspaceZone,
                isOnPortalDomain,
            })
        })
    }, [stacks, workspaceAccount, workspaceZone, isOnPortalDomain])

    const appsMemo = useDeepEqualsMemoValue(apps)

    return useMemo(
        () => ({
            apps: appsMemo,
            selectedAppId: selectedApp?.id,
        }),
        [appsMemo, selectedApp]
    )
}

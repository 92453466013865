import { useDataConnections } from './useDataConnections'

export function useDataConnection(dataConnectionId: string | null | undefined = null): {
    data: DataConnectionDto | null
    isLoading: boolean
} {
    const { data: dataConnections, isLoading } = useDataConnections()

    const data =
        dataConnections?.find(({ _sid }) => !!dataConnectionId && _sid === dataConnectionId) || null

    return { isLoading, data }
}

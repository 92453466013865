// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import queryString from 'qs'
import CreateViewControls from 'v2/views/Create/CreateViewControls'
import processAutofill from 'v2/views/Create/processAutofill'
import { FieldLayoutEditor } from 'v2/views/FieldLayoutEditor'
import { determineIsBlockDisabled } from 'v2/views/utils/determineIsBlockDisabled'
import filterBlockTree from 'v2/views/utils/filterBlockTree'
import { findWidgetBlockById } from 'v2/views/utils/findWidgetBlockById'
import { scrollToInvalidField } from 'v2/views/utils/scrollToInvalidField'
import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'

import { UnsavedChangesModal } from 'app/UnsavedChangesModal'
import { getUrl } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import { FavoriteType } from 'data/hooks/favorites/types'
import { useRecordActions } from 'data/hooks/objects'
import { withObject } from 'data/wrappers/withObject'
import { withObjects } from 'data/wrappers/withObjects'
import { withViews } from 'data/wrappers/withViews'
import { useFavoritesEnabled } from 'features/favorites/useFavoritesEnabled'
import { FavoriteButton } from 'features/NewAppBar/FavoriteButton'
import BlockSelectorPortal from 'features/utils/BlockSelectorPortal'
import { LoadingState } from 'features/views/List/ui/utils'
import { ViewEditPane } from 'features/views/ViewEditPane'
import useTrack from 'utils/useTrack'

import { Box, Button, Collapse, ConditionalWrapper, ContainerLabel, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import useRunOnce from 'v2/ui/utils/useRunOnce'

import { Box as NewBox } from 'ui/components/Box'

import { clearRecord, restoreRecord } from './inlineCreateExpand'

type Props = {
    config: any
    record: any
    showControls: boolean
    onChange: (data: any) => void
    recordActions: any
    view: any
    doNotRedirect: boolean
    onCreate: (id: string, message?: string) => void
    hideTitle: boolean
    setModalActions: any
    inlineCreate: boolean
    queryParams: any
    fromDetailView: boolean
    onRecordChange?: (record: Partial<RecordDto>) => void
}

// props injected by HOC
type HocProps = {
    object: any
    objects: any
    views: any
}

const defaultFormState = {
    showErrors: false,
    isDirty: false,
    isSaving: false,
    valid: {},
    saveError: false,
    validationError: false,
    autofillDone: false,
    autoSave: false,
    triggeredSave: false,
}

const resetFieldValue = (value) => {
    if (Array.isArray(value)) {
        return []
    }
    return ''
}
const initRecordValues = (record) =>
    Object.entries(record).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: resetFieldValue(value) }),
        {}
    )

const isChanged = (before, after) =>
    Object.entries(after).some(([key, nextValue]) => {
        const originalValue = before[key]
        // The value is initially undefined for new records, but it becomes either a string or array once it
        // has been changed
        const fieldIsEmptyArray = Array.isArray(nextValue) && nextValue.length === 0
        const fieldIsEmptyString = nextValue === ''
        const fieldIsBlank = fieldIsEmptyArray || fieldIsEmptyString
        const valueIsBlankAndRecordIsNew = originalValue === undefined && fieldIsBlank
        return originalValue !== nextValue && !valueIsBlankAndRecordIsNew
    })

const InnerCreateView = ({
    config,
    record = {},
    showControls,
    onChange,
    doNotRedirect,
    onCreate,
    hideTitle = false,
    setModalActions,
    inlineCreate,
    queryParams,
    fromDetailView,
    onRecordChange,

    // injected by HOCs
    objects,
    object,
    views,
}: Props & HocProps) => {
    const objectId = object._sid

    const { selectedStack: stack } = useAppContext()
    const [recordState, setRecordState] = useState(defaultFormState)
    const [configState, setConfigState] = useState(defaultFormState)
    const recordActions = useRecordActions()
    const favoritesEnabled = useFavoritesEnabled()

    const loadedObjects = useRef(objects)
    const onChangeView = useRef(objects)

    const doSaveRecordRef = useRef<(props: { skipRedirect?: boolean }) => void>()
    const defaultData = {
        config: cloneDeep(config),
        record: initRecordValues(record),
        loadedConfig: config,
        loadedRecord: record,
    }

    const [data, setData] = useState(defaultData)

    const { track } = useTrack()

    const currentRecordRef = useRef(data.record)
    currentRecordRef.current = data.record

    const onRecordChangeRef = useRef(onRecordChange)
    onRecordChangeRef.current = onRecordChange

    const setRecordValues = useCallback(
        (patch: Partial<RecordDto>, options: { ignoreIsDirty?: boolean } = {}) => {
            const { ignoreIsDirty = false } = options

            const currentRecord = currentRecordRef.current
            const updatedRecord = { ...currentRecord, ...patch }

            const initialRecord = record
            const isDirty = isChanged(initialRecord, updatedRecord)
            setRecordState((state) => ({
                ...state,
                isDirty: ignoreIsDirty ? state.isDirty : isDirty,
            }))

            setData((data) => ({ ...data, record: { ...data.record, ...patch } }))

            onRecordChangeRef.current?.(updatedRecord)
        },
        [record]
    )

    const setRecordValue = useCallback(
        (key, nextValue, options = {}) => {
            setRecordValues({ [key]: nextValue }, options)
        },
        [setRecordValues]
    )
    const setValid = useCallback(
        (key, value) => {
            return setRecordState((state) => ({
                ...state,
                validationError: false,
                valid: { ...state.valid, [key]: value },
            }))
        },
        [setRecordState]
    )

    const onConfigChange = useCallback(
        (changes, blocks) => {
            changes?.forEach((changedBlock) => {
                const block = findWidgetBlockById(blocks, changedBlock.blockId)
                if (changedBlock.action === 'create') {
                    track('Frontend Widget Created', {
                        ...(block ? { widget_type: block.type } : {}),
                        location: 'create_view',
                    })
                } else if (changedBlock.action === 'updateConfig') {
                    track('Frontend Widget Modified', {
                        ...(block ? { widget_type: block.type } : {}),
                        location: 'create_view',
                    })
                }
            })

            setConfigState((state) => ({
                ...state,
                isDirty: true,
            }))
            setData((data) => ({
                ...data,
                config: { ...data.config, blocks },
            }))
        },
        [setConfigState, setData, track]
    )

    // Hide certain fields if they're disabled
    const isFieldDisabled = useCallback(
        (fieldId) => {
            const fields = objects.map((object) => object.fields).flat()
            const field = fields.find((f) => f._sid === fieldId)
            return field && field.connection_options.is_disabled === true
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEqual(loadedObjects.current, objects)]
    )

    const recordContext = useMemo(
        () => {
            return {
                record: data.record,
                view: {
                    actions: {
                        setValue: setRecordValue,
                        setValid,
                    },

                    valid: recordState.valid,
                    editing: true,
                    creating: true,
                    useNewCreateForm: true,
                    showErrors: recordState.showErrors,
                    isInlineCreate: inlineCreate,
                    isLoading: recordState.isSaving,
                },
                object,
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            data.record,
            object,
            recordState.valid,
            recordState.showErrors,
            setRecordValue,
            setValid,
            recordState.isSaving,
        ]
    )

    const history = useHistory()

    useEffect(() => {
        if (config && !isEqual(data.loadedConfig, config)) {
            setData((data) => ({ ...data, loadedConfig: config, config }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config])

    useEffect(() => {
        if (record && !isEqual(data.loadedRecord, record)) {
            setData((data) => ({ ...data, loadedRecord: record, record }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record])

    useEffect(() => {
        loadedObjects.current = objects
    }, [objects])

    useEffect(() => {
        onChangeView.current = onChange
    }, [onChange])

    const useLayoutFrom = get(data, 'config.use_layout_from')

    const buildBlockFromField = (field) => {
        return {
            type: 'field',
            fieldId: field?._sid,
            objectId: field?.object_id,
            fieldName: field?.api_name,
            required: field?.required,
            fullWidth: field?.type === 'long_text',
            isPrimary: field?.is_primary,
        }
    }

    const buildPrimaryFieldBlock = () => {
        const fields = objects.find((object) => object._sid === objectId)?.fields.flat()
        const primaryField = fields.find((f) => f.is_primary)

        const block = buildBlockFromField(primaryField)

        return block
    }

    const updateTreeWithBlock = (tree, block) => {
        if (!block?.fieldId) return tree
        const blocks = tree.childBlocks

        for (let i = 0; i < blocks.length; i++) {
            const currentBlock = blocks[i]
            if (!currentBlock?.childBlocks?.length && currentBlock.config?.attributes?.contents) {
                const hasPrimaryBlock = currentBlock.config?.attributes?.contents.find(
                    (b) => b.fieldId === block.fieldId
                )

                if (hasPrimaryBlock) return tree
                else {
                    currentBlock.config?.attributes?.contents?.splice(1, 0, block)
                    return tree
                }
            }
            updateTreeWithBlock(blocks[i], block)
        }
    }

    const tree = useMemo(() => {
        let createTree = cloneDeep(get(data, 'config.blocks'))

        if (useLayoutFrom) {
            const layoutFromView = cloneDeep(views.find((v) => v._sid === useLayoutFrom))
            if (layoutFromView) {
                createTree = get(layoutFromView, 'options.blocks', createTree)

                updateTreeWithBlock(
                    createTree.find((block) => block.id === 'page'),
                    buildPrimaryFieldBlock()
                )
            }
        }

        createTree = filterBlockTree(createTree, [
            'container',
            'attribute',
            'gridcard',
            'field_container',
            'banner',
            'text',
            'callout',
        ])

        return createTree
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useLayoutFrom, data, objects, views])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const autoFillOnComplete = ({ autofillData, ...rest }) => {
        setRecordState((recordState) => ({ ...recordState, ...rest }))
        setData((data) => ({ ...data, record: { ...data.record, ...autofillData } }))
    }

    const revertRecordChanges = () => {
        setData((data) => ({
            ...data,
            record: initRecordValues(data.record),
        }))
        setRecordState((prevState) => ({ ...prevState, isDirty: false, isSaving: false }))
    }

    const revertConfigChanges = () => {
        // We do not want to reset the end user form
        const { ...defaultDataWithoutRecord } = defaultData
        setData((prevState) => ({ ...prevState, ...defaultDataWithoutRecord }))
        setConfigState((prevState) => ({ ...prevState, isDirty: false }))
    }

    // Deserialize the draft record from the router state and autofill the form.
    useRunOnce(() => {
        if (inlineCreate) return

        const createUrl = history.location.pathname

        const recordDraft = restoreRecord(createUrl)
        if (!recordDraft) return

        // Clear the draft record from local storage.
        clearRecord(createUrl)

        queueMicrotask(() => {
            // Clear unsaved changes modal bypass state.
            history.replace({
                state: {
                    // @ts-expect-error
                    ...history.location.state,
                    bypassUnsavedChangesModal: false,
                },
            })

            setRecordValues(recordDraft)
        })
    }, !inlineCreate)

    useEffect(() => {
        processAutofill(
            objects,
            objectId,
            queryParams ?? window.location.search,
            recordState.autofillDone,
            autoFillOnComplete
        )
    }, [objects, objectId, queryParams, recordState.autofillDone, autoFillOnComplete])

    const isRecordValid = () => {
        const validity = recordState.valid
        let isValid = true
        Object.keys(validity).forEach((key) => {
            isValid = isValid && validity[key]
        })

        return isValid
    }

    const saveConfig = useCallback(() => {
        track('layout updated', {
            view: 'create view',
        })
        onChange(data.config)
        setConfigState((state) => ({ ...state, isDirty: false }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEqual(onChangeView.current, onChange), onChange, setConfigState, data.config])

    const handleAddRecordModalSubmit = () => {
        // When submitting the change modal, we want to follow
        // the requested url change and skip the default redirect logic
        return saveRecord({ skipRedirect: true })
    }

    const handleAddRecordFormSubmit = () => {
        saveRecord()
    }

    const onFormKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            const target = e.target as HTMLElement | null
            // Prevent saving when submitting the comment editor.
            if (target?.closest('.activity-comment-editor')) return

            e.preventDefault()
            // given a user can type changes and then hit ctrl+enter quite quickly,
            // we want to apply a delay to ensure that any debounced field updates are flushed
            saveRecord({ applyDelay: true })
        }
    }

    // Scroll to the first error field when showErrors is set to true
    useEffect(() => {
        if (recordState.showErrors) scrollToInvalidField()
    }, [recordState.showErrors])

    const saveRecord = ({ skipRedirect = false, applyDelay = false } = {}) => {
        // Don't actually save if we're in edit mode
        if (showControls) return setRecordState((state) => ({ ...state }))

        setRecordState((state) => ({ ...state, isSaving: true, saveError: false }))

        if (applyDelay) {
            // This is a hack to ensure that any debounced field updates are flushed
            // before we actually do the save. This will hopefully be unnecessary when
            // we rebuild the view architecture and can allow attributes to notify us when
            // an update is in progress.
            setTimeout(() => doSaveRecordRef.current?.({ skipRedirect }), 400)
        } else {
            doSaveRecordRef.current?.({ skipRedirect })
        }
    }

    // we use a ref here so that it pierces any closure and is always the latest version
    doSaveRecordRef.current = ({ skipRedirect = false } = {}) => {
        const shouldRedirect = !doNotRedirect && !skipRedirect

        // See if we're valid
        if (!isRecordValid()) {
            setRecordState((state) => ({
                ...state,
                showErrors: true,
                validationError: true,
                isSaving: false,
            }))
            // Note: we need this in both a useEffect and here so that it triggers both for the first time showErrors is set to true
            // and every time submit is clicked when the record is still not valid (showErrors is already true)
            scrollToInvalidField()
            return Promise.reject()
        }

        // include fields is set to empty list as we sometimes need the primary field of the created records, but never
        // access any other fields
        return recordActions
            .create({ ...data.record, object_id: objectId }, [])
            .then((persistedRecord) => {
                // don't flash up the unsaved changes modal when redirecting
                setRecordState((state) => ({ ...state, isDirty: false }))

                if (shouldRedirect) {
                    redirectAfterCreate(persistedRecord)
                }

                // ensures that the new record is shown on the parent
                if (onCreate) {
                    onCreate(persistedRecord._sid)
                }

                // Only revert the changes if we're not redirecting
                if (!shouldRedirect) {
                    setRecordState((state) => ({
                        ...state,
                        showErrors: false,
                        validationError: false,
                        isSaving: false,
                    }))

                    revertRecordChanges()
                }
            })
            .catch((e) => {
                Sentry.captureMessage(`Error creating record. Error message: ${get(e, 'message')}`)
                setRecordState((state) => ({ ...state, isSaving: false, saveError: true }))
            })
    }

    const redirectAfterCreate = (record) => {
        const searchString = queryString.parse(window.location?.search, {
            ignoreQueryPrefix: true,
        })
        if (searchString?.previous) {
            // Redirect, e.g. back to a related list
            // @ts-expect-error
            history.push(searchString.previous)
        } else if (recordState.autoSave && history.length) {
            // Go back from an auto-saving create link
            history.goBack()
        } else if (searchString?.redirectToInbox) {
            // Redirect to inbox with new row selected
            history.push(`${searchString.redirectToInbox}?row_id_base=${record._sid}`)
        } else {
            // Redirect to the newly created record
            history.push({
                pathname: getUrl(`${object.url}/view/${record._sid}`),
                state: { prev: history?.location },
            })
        }
    }

    let title = (record && record._primary) || 'Record title'

    // Sometimes, the _primary field could contain an object (eg: attachments), rather than a string or a number
    // if this happens, React throws an error 'Objects are not valid as a React child'
    // We don't know how we can get into this state, as we don't allow fields like attachments to be _primary,
    // but sometimes it happens.
    if (typeof title === 'object') title = get(title, 'id') || 'Record title'

    const setConfig = useCallback(
        (config) => {
            setConfigState((state) => ({
                ...state,
                isDirty: true,
            }))

            setData((data) => ({ ...data, config: { ...data.config, ...config } }))
        },
        [setConfigState, setData]
    )

    const detailView = useMemo(
        () => views.find((view) => view.object_id === objectId && view.type === 'detail'),
        [views, objectId]
    )

    const createControls = useMemo(
        () => (
            <>
                <CreateViewControls
                    mt={4}
                    setConfig={setConfig}
                    detailView={detailView && detailView._sid}
                    useLayoutFrom={useLayoutFrom}
                />

                <BlockSelectorPortal useLayoutFrom={useLayoutFrom} />
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data.config, setConfig, detailView && detailView._sid, useLayoutFrom]
    )

    // if there are tabs, we only use the first one
    const usefulTree = useMemo(() => {
        const activeTabs =
            detailView.options?.tabs?.filter(({ active, type }) => type !== 'activity' && active) ??
            []

        if (activeTabs.length > 1 && tree[activeTabs[0].treeIndex]) {
            return [tree[activeTabs[0].treeIndex]]
        }

        return tree
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tree, detailView.config?.tabs])

    const onChangeTitle = useCallback((title) => setConfig({ title }), [setConfig])

    const viewName = (obj) => `${obj.name} create`

    title = data.config.title || `${object.name}: add new`
    const ignoreHistoryBreadcrumb = inlineCreate || fromDetailView

    useHistoryBreadcrumb({ title, type: 'create', objectId }, ignoreHistoryBreadcrumb)

    const readOnlyPortal = stack?.options?.read_only_data

    const saveButton = useMemo(() => {
        let props: any = {}
        props.w = '100%'
        if (setModalActions && inlineCreate) {
            setModalActions([
                {
                    label: 'Save',
                    onClick: handleAddRecordModalSubmit,
                    icon: 'checkmark',
                    buttonSize: 'sm',
                    isDisabled: recordState.isSaving || readOnlyPortal,
                    isLoading: recordState.isSaving,
                },
            ])

            return null
        }

        return (
            <>
                <Button
                    variant="sm"
                    icon="checkmark"
                    onClick={handleAddRecordFormSubmit}
                    isDisabled={recordState.isSaving || readOnlyPortal}
                    label={readOnlyPortal && 'Saving changes is disabled on this app'}
                    className={ONBOARDING_CLASSES.SAVE_RECORD_BUTTON}
                    isLoading={recordState.isSaving}
                    {...props}
                >
                    Save
                </Button>
            </>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, recordState, showControls])

    const errorMessages = (
        <>
            <Collapse isOpen={recordState.saveError}>
                <Text variant="error" mb={3} textAlign={'center'}>
                    {stack?.options?.is_demo
                        ? `Sorry, you can't create new records in a demo app!`
                        : 'Sorry, there was an error saving your record. Please try again.'}
                </Text>
            </Collapse>
            <Collapse isOpen={recordState.validationError} mb={3} textAlign={'center'}>
                <Text variant="error">Please fill out the required fields above.</Text>
            </Collapse>
        </>
    )

    if (recordState.autoSave) {
        if (!recordState.saveError && !recordState.triggeredSave) {
            setRecordState((state) => ({ ...state, triggeredSave: true }))
            saveRecord()
        }

        if (recordState.saveError) {
            return (
                <Text variant="error">
                    Sorry, there was an error saving your record. Please {/*@ts-expect-error*/}
                    <a href={history.goBack()}>go back</a>.
                </Text>
            )
        }

        return (
            <>
                <ContainerLabel
                    borderStyle="noBorder"
                    variant="pageHeading"
                    value="Saving record"
                    textAlign="center"
                />

                {/* @ts-expect-error */}
                <LoadingState />
            </>
        )
    }

    return (
        <>
            {showControls && (
                <ViewEditPane
                    isConfigDirty={configState.isDirty}
                    viewName={viewName(object)}
                    saveView={saveConfig}
                >
                    {createControls}
                </ViewEditPane>
            )}
            <>
                <ConditionalWrapper
                    wrapper={(children) => (
                        <Box w="100%" maxWidth="500px" m="0 auto">
                            {children}
                        </Box>
                    )}
                    condition={!inlineCreate}
                >
                    <div onKeyDownCapture={onFormKeyDown}>
                        {!hideTitle && (
                            <ContainerLabel
                                isEditable={showControls && !useLayoutFrom}
                                onChange={onChangeTitle}
                                borderStyle="noBorder"
                                variant="pageHeading"
                                value={title}
                                valueDecorator={
                                    favoritesEnabled ? (
                                        <NewBox
                                            display="inline-flex"
                                            verticalAlign="middle"
                                            pb="xs"
                                            ml="xs"
                                        >
                                            <FavoriteButton
                                                targetType={FavoriteType.CreateForm}
                                                stack_id={stack?._sid}
                                                object_id={object._sid}
                                                size="m"
                                            />
                                        </NewBox>
                                    ) : undefined
                                }
                                textAlign={'center'}
                            />
                        )}
                        <FieldLayoutEditor
                            tree={usefulTree}
                            context={recordContext}
                            // @ts-expect-error
                            treeIndex={0}
                            object={object}
                            isCreate={true}
                            onChange={onConfigChange}
                            config={data.config}
                            showControls={showControls && !useLayoutFrom}
                            isFieldDisabled={isFieldDisabled}
                            recordPermissions={get(data, 'record._permissions')}
                            newCreateForm={true}
                            hideFields={true}
                            determineIsBlockDisabled={determineIsBlockDisabled}
                            showBlockSelector={true}
                        />
                        {errorMessages}
                        {saveButton}
                    </div>
                </ConditionalWrapper>
                <UnsavedChangesModal
                    endUserThemed={true}
                    isDirty={recordState.isDirty}
                    onSave={handleAddRecordModalSubmit}
                    revertChanges={revertRecordChanges}
                />

                <UnsavedChangesModal
                    isDirty={configState.isDirty}
                    onSave={saveConfig}
                    revertChanges={revertConfigChanges}
                />
            </>
        </>
    )
}

export const CreateView = withObjects(withViews(withObject(InnerCreateView)))

import React, { FC } from 'react'

import { ListWidgets } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/ListWidgets'

import { EmptyMetrics } from 'v2/ui/svgs'

import { MAX_NUM_METRICS, NEW_METRIC_ATTRIBUTES } from './constants'

type ListMetricsEditorProps = {
    metrics: MetricsBlockAttributes[]
    onStartEditingMetric: (metric: MetricsBlockAttributes) => void
    onAddMetric: (metric: MetricsBlockAttributes) => void
    onDeleteMetric: (metricOrder: number) => void
    onReorderMetrics: (metrics: (MetricsBlockAttributes & { previousOrder: number })[]) => void
}

export const ListMetricsEditor: FC<ListMetricsEditorProps> = ({
    metrics,
    onStartEditingMetric,
    onAddMetric,
    onDeleteMetric,
    onReorderMetrics,
}) => (
    <ListWidgets
        widgets={metrics}
        emptyTitle="Create your first big number"
        emptySubtitle="You can display up to four numbers per widget."
        EmptyIllustration={EmptyMetrics}
        addButtonLabel="Add big number"
        maxWidgets={MAX_NUM_METRICS}
        maxWidgetsReachedText={`Max ${MAX_NUM_METRICS} numbers per widget. Create a new widget to add more.`}
        newWidgetAttributes={NEW_METRIC_ATTRIBUTES}
        onStartEditing={onStartEditingMetric}
        onAddWidget={onAddMetric}
        onDeleteWidget={onDeleteMetric}
        onReorderWidgets={onReorderMetrics}
        defaultLabelPrefix="Big number"
    />
)

import { Dispatch } from 'react'

import { DataGridCellProvider } from 'features/datagrid/types'

import { TipTapValue } from 'v2/ui/components/Attribute/DocumentAttribute'

import {
    DataGridContextState,
    DataGridStateForContextAction,
    defaultState,
} from './dataGridContextReducer'

export type DataGridContext = DataGridContextState & {
    cellDataProvider: DataGridCellProvider
    canAddRecords: boolean
    canDeleteRecords: boolean
    canEditRecords: boolean
    canRestoreRecords: boolean
    isTrashMode: boolean
    dispatch: Dispatch<DataGridStateForContextAction>
    getDocumentFieldHTMLContent: (content: TipTapValue) => string
    getDocumentFieldContentFromHTML: (content: string) => TipTapValue
    pageIndex: number
    setPageIndex: (pageIndex: number) => void
    pageSize: number
}

export const defaultContext: DataGridContext = {
    ...defaultState,
    canAddRecords: false,
    canDeleteRecords: false,
    canEditRecords: false,
    canRestoreRecords: false,
    cellDataProvider: {} as DataGridCellProvider,
    dispatch: () => {},
    getDocumentFieldHTMLContent: () => '',
    getDocumentFieldContentFromHTML: () => ({
        content: null,
        format: 'tiptap',
        plainTextContent: '',
    }),
    isTrashMode: false,
    pageIndex: 0,
    setPageIndex: () => {},
    pageSize: 0,
}

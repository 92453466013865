import React from 'react'

import { FrameContext } from 'features/core/frameContext'

export function DocumentPageFrame({ children }: { children: React.ReactNode }) {
    const frameContext = React.useContext(FrameContext)

    return (
        <div
            style={{
                minHeight: `calc(100dvh - ${frameContext.headerHeight}px)`,
                height: `calc(100dvh - ${frameContext.headerHeight}px)`,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
            }}
        >
            <style>
                {`
            .stk-content {
                background-color: white;
                padding: 0px !important;
            }
            .stk-content > div {
                padding: 0px !important;
            }

            .powered-by {
                display: none;
            }
            `}
            </style>
            {children}
        </div>
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { theme } from 'ui/styling/Theme.css'

type LayoutEditorFrameProps = {}

export const LayoutEditorFrame: React.FC<LayoutEditorFrameProps> = ({ children }) => {
    return (
        <Box
            flex
            flexDirection="column"
            alignItems="flex-start"
            style={{
                minHeight: '100dvh',
                height: '100dvh',
            }}
        >
            <style>
                {`
            .stk-page {
                overflow: visible!important;
            }
            .stk-page.device-mobile {
                max-width: 100%;
            }
            .stk-block-loader__overlay {
                display: none;
            }
            .stk-content {
                background-color: ${theme.color.surface};
                padding: 0!important;
                overflow: visible!important;
            }
            .stk-content > div {
                padding: 0!important;
            }
            .powered-by {
                display: none;
            }
            @media (max-width: 480px) {
                .stk-header {
                    display: none;
                }
                .BackdropStyled {
                    padding-top: 0;
                }
            }
            `}
            </style>
            {children}
        </Box>
    )
}

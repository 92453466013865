import React, { forwardRef, useMemo } from 'react'

import { makeComponent } from 'ui/helpers/recipes'
import { StandardComponentProps } from 'ui/helpers/styles'
import { makeLineTruncationStyle, truncateText } from 'ui/helpers/utilities'
import {
    PropsWithResponsiveValues,
    ResponsiveValue,
    useTransformResponsiveProps,
} from 'ui/styling/helpers/useResponsiveValue'

import { body } from './Body.css'

const BodyBase = makeComponent('span', body)

type BodyRef = HTMLSpanElement

export type BodyProps = PropsWithResponsiveValues<
    React.ComponentPropsWithoutRef<typeof BodyBase>,
    'size' | 'weight'
> &
    StandardComponentProps & {
        maxLength?: ResponsiveValue<number>
        maxLines?: ResponsiveValue<number>
    }

export const Body = forwardRef<BodyRef, BodyProps>(
    ({ maxLength, maxLines, style, children, size, weight, ...props }, ref) => {
        const {
            size: effectiveSize,
            weight: effectiveWeight,
            maxLength: effectiveMaxLength,
            maxLines: effectiveMaxLines,
        } = useTransformResponsiveProps({
            size,
            weight,
            maxLength,
            maxLines,
        })

        const computedStyle = useMemo(() => {
            return {
                ...makeLineTruncationStyle(effectiveMaxLines),
                ...style,
            }
        }, [style, effectiveMaxLines])

        const content = useMemo(() => {
            if (typeof children === 'string' && effectiveMaxLength) {
                return truncateText(children, effectiveMaxLength)
            }

            return children
        }, [children, effectiveMaxLength])

        return (
            <BodyBase
                ref={ref}
                style={computedStyle}
                size={effectiveSize}
                weight={effectiveWeight}
                {...props}
            >
                {content}
            </BodyBase>
        )
    }
)

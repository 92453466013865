export type ConditionClause = {
    left: WorkflowExpression | null
    comparison: FilterOperation
    right: WorkflowExpression | null
}

export const ConditionGroupOperatorOptions = {
    And: {
        label: 'and',
        value: 'AND',
    },
} as const

export type ConditionGroupOperator =
    (typeof ConditionGroupOperatorOptions)[keyof typeof ConditionGroupOperatorOptions]['value']

export type ConditionGroup = {
    clauses: ConditionClause[]
    operator: ConditionGroupOperator
}

export const CONDITION_GROUP_OPERATORS = Object.values(ConditionGroupOperatorOptions)

export function getLabelForGroupOperator(operator?: ConditionGroupOperator): string | undefined {
    return Object.values(ConditionGroupOperatorOptions).find((op) => op.value === operator)?.label
}

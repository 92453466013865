import React from 'react'

import { useCardViewContentState } from './hooks/useCardViewContentState'
import { CardViewCard } from './CardViewCard'

type CardViewContentProps = {}

export const CardViewContent: React.FC<CardViewContentProps> = React.memo(
    function CardViewContent() {
        const { records } = useCardViewContentState()

        return (
            <>
                {records?.map((record, idx) => (
                    <CardViewCard key={idx} record={record} siblingRecords={records} />
                ))}
            </>
        )
    }
)

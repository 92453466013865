import React from 'react'
import ReactToggle from 'react-toggle'

import { css } from 'emotion'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import 'react-toggle/style.css'

const StackerToggle = (props) => {
    const toggleColor = props.noColor ? '#4D4D4D' : props.theme.toggleBackgroundColor || '#19AB27'
    const toggleStyle = css`
        margin: 0px ${props.theme[props.margin || 'small']};

        &.react-toggle--checked .react-toggle-track {
            background-color: ${toggleColor} !important;
        }

        &.react-toggle--checked .react-toggle-thumb {
            border: ${toggleColor} !important;
        }

        ${props.small &&
        `
            transform: scale(0.5);
            margin: -7px 0px -7px -7px;
            `}
    `

    return (
        <ReactToggle
            id={props.id}
            disabled={props.disabled}
            className={toggleStyle}
            checked={props.value}
            onChange={(e) => props.onChange && props.onChange(e.target.checked)}
            icons={false}
        />
    )
}
export const Toggle = React.memo(withTheme(StackerToggle))

import React, { useContext } from 'react'

import { RecordManager } from './hooks/useRecordManagerState'

export type RecordManagerContextValue = RecordManager
export const RecordManagerContext = React.createContext<RecordManagerContextValue | null>(null)

export function useRecordManagerContext(): RecordManagerContextValue {
    const context = useContext(RecordManagerContext)

    if (!context) {
        throw new Error(
            'useRecordManagerContext must be used within a RecordManagerContextProvider'
        )
    }

    return context
}

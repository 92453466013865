import { AggregationGroup } from 'data/hooks/aggregates/types'

type groupAggregateDataProps = {
    data: AggregationGroup[] | undefined
    categories?: MetricsQueryRequest['categories']
    aggregation: MetricsQueryRequest['aggr']
    fields: FieldDto[]
}

export const NONE_VALUE_SECTION_LABEL = '(Empty)'
// This returns aggregation data with one main group and a possible secondary grouping
// e.g. [key: "20-25", value: 300, values: {"Male": 120, "Female": 110, "Other": 70} ]

const getAggrFieldDisplayMeta = (aggr: MetricsQueryRequest['aggr'], fields: FieldDto[]) => {
    const field: FieldDto | undefined =
        aggr.type != 'count' ? fields.find((f) => f._sid === aggr.field_sid) : undefined
    if (!field) return undefined
    return { field_type: field?.type, currency_symbol: field?.options?.currency_symbol ?? '' }
}

export const groupAggregateData = ({
    data,
    categories,
    aggregation,
    fields,
}: groupAggregateDataProps): MetricsQueryResponse | undefined => {
    if (!data) {
        return data
    }

    let uniqueCategories: GroupCategories = {}
    const mappedData: MetricsQueryResponse['data'] = data.reduce(
        (acc, item) => {
            const key = item.others
                ? `Others (${item.otherGroupsCount})`
                : (item.groupKey?.value ?? NONE_VALUE_SECTION_LABEL)

            const value = item.aggregates[0].value
            const values = item.secondaryGroups?.reduce(
                (valuesAcc, secondaryGroup) => {
                    const secondaryGroupKey =
                        secondaryGroup.groupKey?.value ?? NONE_VALUE_SECTION_LABEL
                    valuesAcc[secondaryGroupKey] = secondaryGroup.aggregates[0].value
                    if (!(secondaryGroupKey in uniqueCategories)) {
                        uniqueCategories[secondaryGroupKey] = {
                            color: categories?.[secondaryGroupKey]?.color,
                        }
                    }
                    return valuesAcc
                },
                {} as { [value: string]: number }
            )
            const categoryData = {
                key,
                value,
                values,
                fieldApiName: item.groupKey?.fieldApiName,
                ...(item.others ? { others: item.others } : {}),
            }
            acc.push(categoryData)

            // Store a list of category values and their colours
            // TODO: the colours are not yet set anywhere
            // if there are secondary groups, we use secondary group keys, otherwise primary group keys for colors

            if ((item.secondaryGroups ?? []).length === 0) {
                if (item.others) {
                    uniqueCategories['Others'] = { color: categories?.['Others']?.color }
                } else if (!(key in uniqueCategories)) {
                    uniqueCategories[key] = { color: categories?.[key]?.color }
                }
            }

            return acc
        },
        [] as MetricsQueryResponse['data']
    )

    return {
        data: mappedData,
        categories: uniqueCategories,
        field_display_meta: getAggrFieldDisplayMeta(aggregation, fields),
    }
}

/**
 * This file is a modified version of the useEditor hook from the tiptap library.
 * Please remove once this bug was fixed: {https://github.com/ueberdosis/tiptap/pull/4000}
 * @source: {https://github.com/ueberdosis/tiptap/blob/75f0418f03fb040dc4c12c104dfe457342b4e4b4/packages/react/src/useEditor.ts}
 */

import { useCallback, useEffect, useRef, useState } from 'react'

import { EditorOptions } from '@tiptap/core'
import { Editor } from '@tiptap/react'

function useForceUpdate() {
    const [, setValue] = useState(0)

    return useCallback(() => setValue((value) => value + 1), [])
}

export const useEditor = (options: Partial<EditorOptions> = {}) => {
    const [editor, setEditor] = useState<Editor | null>(null)

    const forceUpdate = useForceUpdate()

    const {
        onBeforeCreate,
        onBlur,
        onCreate,
        onDestroy,
        onFocus,
        onSelectionUpdate,
        onTransaction,
        onUpdate,
    } = options

    const onBeforeCreateRef = useRef(onBeforeCreate)
    const onBlurRef = useRef(onBlur)
    const onCreateRef = useRef(onCreate)
    const onDestroyRef = useRef(onDestroy)
    const onFocusRef = useRef(onFocus)
    const onSelectionUpdateRef = useRef(onSelectionUpdate)
    const onTransactionRef = useRef(onTransaction)
    const onUpdateRef = useRef(onUpdate)

    // This effect will handle updating the editor instance
    // when the event handlers change.
    useEffect(() => {
        if (!editor) {
            return
        }

        if (onBeforeCreate) {
            editor.off('beforeCreate', onBeforeCreateRef.current)
            editor.on('beforeCreate', onBeforeCreate)
            onBeforeCreateRef.current = onBeforeCreate
        }

        if (onBlur) {
            editor.off('blur', onBlurRef.current)
            editor.on('blur', onBlur)
            onBlurRef.current = onBlur
        }

        if (onCreate) {
            editor.off('create', onCreateRef.current)
            editor.on('create', onCreate)
            onCreateRef.current = onCreate
        }

        if (onDestroy) {
            editor.off('destroy', onDestroyRef.current)
            editor.on('destroy', onDestroy)
            onDestroyRef.current = onDestroy
        }

        if (onFocus) {
            editor.off('focus', onFocusRef.current)
            editor.on('focus', onFocus)
            onFocusRef.current = onFocus
        }

        if (onSelectionUpdate) {
            editor.off('selectionUpdate', onSelectionUpdateRef.current)
            editor.on('selectionUpdate', onSelectionUpdate)
            onSelectionUpdateRef.current = onSelectionUpdate
        }

        if (onTransaction) {
            editor.off('transaction', onTransactionRef.current)
            editor.on('transaction', onTransaction)
            onTransactionRef.current = onTransaction
        }

        if (onUpdate) {
            editor.off('update', onUpdateRef.current)
            editor.on('update', onUpdate)
            onUpdateRef.current = onUpdate
        }
    }, [
        onBeforeCreate,
        onBlur,
        onCreate,
        onDestroy,
        onFocus,
        onSelectionUpdate,
        onTransaction,
        onUpdate,
        editor,
    ])

    const optionsRef = useRef(options)
    optionsRef.current = options

    useEffect(() => {
        let isMounted = true

        const instance = new Editor(optionsRef.current)

        setEditor(instance)

        instance.on('transaction', () => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    if (isMounted) {
                        forceUpdate()
                    }
                })
            })
        })

        return () => {
            isMounted = false
            queueMicrotask(() => {
                instance.destroy()
            })
        }
    }, [forceUpdate])

    return editor
}

import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

const CONTACT_EXPERT_URL = 'https://calendly.com/stacker-app/create-your-first-stacker-app'

const TRIAL_DAYS_TOTAL = 14

export function useNavigationTrialBannerState() {
    const { workspaceAccount } = useNavigationContext()

    const daysRemaining = Math.min(Math.max(workspaceAccount?.trial_days_remaining ?? 0, 0), 14)
    const daysTotal = TRIAL_DAYS_TOTAL

    const progressValue = (daysRemaining / daysTotal) * 100
    const daysLeftLabel = daysRemaining === 1 ? 'day left' : `${daysRemaining} days left`

    return useMemo(
        () => ({
            contactExpertUrl: CONTACT_EXPERT_URL,
            daysLeftLabel,
            progressValue,
        }),
        [daysLeftLabel, progressValue]
    )
}

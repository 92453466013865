import React from 'react'

import styled from '@emotion/styled'

import { Flex } from 'v2/ui'

import { ToolbarButton } from './ToolbarButton'

type SelectedRecordsToolbarProps = {
    onDeleteClick?: () => void
    onRestoreClick: () => void
    recordCount?: number
    trashMode?: boolean
}
const SelectedRecordsToolbar: React.FC<SelectedRecordsToolbarProps> = ({
    onDeleteClick,
    onRestoreClick,
    recordCount,
    trashMode = false,
}) => {
    return (
        <Wrapper alignItems="center" wrap="nowrap" id="datagrid_buttonbar" pr={2}>
            {trashMode ? (
                <ToolbarButton
                    icon="refresh"
                    onClick={onRestoreClick}
                    data-testid="datagrid.restore.button"
                    color="userInterface.accent.800"
                    iconColor="userInterface.accent.800"
                >
                    {recordCount
                        ? `Restore ${formatRecordCount(recordCount)}`
                        : 'Restore selected records'}
                </ToolbarButton>
            ) : onDeleteClick ? (
                <ToolbarButton
                    icon="trash"
                    onClick={onDeleteClick}
                    data-testid="datagrid.delete.button"
                    color="userInterface.error.600"
                    iconColor="userInterface.error.600"
                >
                    {recordCount
                        ? `Delete ${formatRecordCount(recordCount)}`
                        : 'Delete selected records'}
                </ToolbarButton>
            ) : undefined}
        </Wrapper>
    )
}
export default SelectedRecordsToolbar

const Wrapper = styled(Flex)`
    justify-content: flex-end;
    flex-direction: row;
    height: 44px;
`

function formatRecordCount(count: number) {
    if (count === 1) {
        return '1 record'
    }
    return `${count} records`
}

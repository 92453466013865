import { BackendFilter, RecordSearchQueryDict } from 'data/api/recordApiTypes'
import {
    getCurrentUserProfileRecordId,
    getCurrentUserRecordField,
    getCurrentUserRecordId,
} from 'data/wrappers/withUserUtils'

import { encodeCommas } from './escapeCommas'

export const currentUserOptions: readonly FilterOperation[] = [
    'isCurrentUser',
    'isNotCurrentUser',
    'containsCurrentUser',
    'doesNotContainCurrentUser',
]

export const userFieldOptions: readonly FilterOperation[] = [
    'isCurrentUserField',
    'isNotCurrentUserField',
    'containsCurrentUserField',
    'doesNotContainCurrentUserField',
]

export const queryStringSuffixMap: Record<FilterOperation, string> = {
    equals: '',
    notEquals: '__neq',
    lessThan: '__lt',
    equalsOrLessThan: '__lte',
    greaterThan: '__gt',
    equalsOrGreaterThan: '__gte',
    is: '',
    isnt: '__neq',
    listIs: '__listeq',
    listIsnt: '__listneq',
    oneOf: '__in',
    noneOf: '__notin',
    startsWith: '__startswith',
    endsWith: '__endswith',
    contains: '__contains',
    containsAny: '__containsany',
    containsNone: '__containsnone',
    "doesn't contain": '__notcontains',
    isEmpty: '__isempty',
    isNotEmpty: '__isnotempty',
    sameDay: '',
    beforeDate: '__lt',
    afterDate: '__gt',
    isInBetween: '',
    withinNext: '__withinnext',
    withinLast: '__withinlast',
    isCurrentUser: '',
    isNotCurrentUser: '__neq',
    containsCurrentUser: '__containsany',
    doesNotContainCurrentUser: '__containsnone',
    containsCurrentUserProfile: '__containsany',
    doesNotContainCurrentUserProfile: '__containsnone',
    isCurrentUserField: '',
    isNotCurrentUserField: '__neq',
    containsCurrentUserField: '__containsany',
    doesNotContainCurrentUserField: '__containsnone',
    isContextRecordField: '',
    isNotContextRecordField: '__neq',
    containsContextRecordField: '__containsany',
    doesNotContainContextRecordField: '__containsnone',
    appearsIn: '__appearsin',
}

const emptyValueOptions = new Set<FilterOperation>(['isEmpty', 'isNotEmpty'])

function isArrayOpValue(operation: FilterOperation) {
    if (!operation) {
        return false
    }

    const op = queryStringSuffixMap[operation]

    return op === '__containsany' || op === '__containsnone'
}

export const preprocessFilterValue = (
    objectId: string | undefined,
    operation?: FilterOperation,
    value: Filter['options']['value'] = ''
) => {
    let newValue = value

    // For the current user filters, send through the current user
    // sid and the corresponding modifier
    if (operation !== undefined && currentUserOptions.includes(operation)) {
        newValue =
            (!!objectId && getCurrentUserProfileRecordId(objectId)) || getCurrentUserRecordId()
    }

    // For the current user filters, send through the current user
    // sid and the corresponding modifier
    if (
        operation !== undefined &&
        userFieldOptions.includes(operation) &&
        !Array.isArray(newValue)
    ) {
        newValue = getCurrentUserRecordField(newValue)
    }

    if (operation !== undefined && isArrayOpValue(operation) && !Array.isArray(newValue)) {
        newValue = [newValue]
    }

    return newValue
}

export const filtersToQueryDict = (filters: Filter[] = []): RecordSearchQueryDict => {
    const flattenedFilters = filters.reduce<Filter[]>((agg, filter) => {
        if (filter.options?.option === 'isInBetween') {
            agg.push({
                ...filter,
                options: {
                    ...filter.options,
                    option: 'afterDate',
                    value: filter.options?.value?.[0],
                },
            })
            agg.push({
                ...filter,
                options: {
                    ...filter.options,
                    option: 'beforeDate',
                    value: filter.options?.value?.[1],
                },
            })
        } else {
            agg.push(filter)
        }
        return agg
    }, [])

    const processedFilters = flattenedFilters.map((filter): BackendFilter => {
        let value = preprocessFilterValue(
            filter.field?.link_target_object_id,
            filter.options?.option,
            filter.options?.value
        )

        const rawOperation: FilterOperation | undefined = filter.options?.option

        // some filters don't require a value, so just set these to true so that
        // they are still sent
        if (rawOperation && emptyValueOptions.has(rawOperation)) {
            value = 'true'
        }

        if (!filter.field?.api_name?.startsWith('_')) {
            value = encodeCommas(value)
        }

        const operationSuffix = rawOperation && queryStringSuffixMap[rawOperation]
        const operation = operationSuffix?.startsWith('__')
            ? operationSuffix.replace('__', '')
            : !!operationSuffix
              ? rawOperation
              : null

        return {
            target: filter.field?.api_name ?? '',
            operation,
            value,
        }
    })

    return { filters: processedFilters }
}

// @ts-strict-ignore
import { useContext, useMemo, useRef } from 'react'
import {
    QueryKey,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery as _useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'

import { AppContext } from 'app/AppContext'
import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getCurrentStackId, GlobalStaticState } from 'app/GlobalStaticState'
import { WorkspaceContext } from 'app/WorkspaceContext'
import { UseUpdateRecordInternalOptions } from 'data/api/recordApiTypes'
import _queryClient from 'data/reactQueryCache'
import { buildUrl, fetchWithAuth } from 'data/utils/utils'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'

import { logQueryFailure, resetQueryFailures, shouldDisableDueToFailures } from './queryFailureLog'

// for critical account-level queries that will stop the loading of the app,
// we want to retry a few times before giving up, to account for transient failures
export const ACCOUNT_QUERY_RETRY_OPTIONS = {
    retry: 3,
    retryDelay: 1000,
}

// Checks if we have the necessary elements loaded
// to run queries for workspace-level data
export function useCanRunWorkspaceScopedQueries() {
    const { isEmailVerified } = useAuthContext()
    const { workspaceAccount, workspaceUser } = useContext(WorkspaceContext)

    return !!workspaceAccount && !!workspaceUser && isEmailVerified
}

// Checks if we have the necessary elements loaded
// to run queries for stack-level data
export function useCanRunStackScopedQueries() {
    const workspaceQueriesEnabled = useCanRunWorkspaceScopedQueries()
    const { selectedStack } = useContext(AppContext)
    return workspaceQueriesEnabled && !!selectedStack
}

// Checks if we have the necessary elements loaded
// to run queries for stack-level data and we are not previewing as another user
// and thus can run admin queries
export function useCanRunStackScopedAdminQueries() {
    const workspaceQueriesEnabled = useCanRunWorkspaceScopedQueries()
    const { isPreviewingAsUserOrRole } = usePreviewServiceContext()
    const { selectedStack } = useContext(AppContext)

    return workspaceQueriesEnabled && !!selectedStack && !isPreviewingAsUserOrRole
}

// Returns a list of  values concerning the current user's
// auth state (previewing as another user, as another role, etc)
export function useAuthStateDependencies() {
    const { user } = useAuthContext()
    const { previewingAsUser, previewingAsRoleApiName } = usePreviewServiceContext()
    const userId = previewingAsUser?._sid || user?._sid
    return useMemo(() => [userId, previewingAsRoleApiName], [userId, previewingAsRoleApiName])
}

export function getAuthStateDependencies(): [string] | [string, string] {
    const user = GlobalStaticState.getUser()
    const previewingAsUser = GlobalStaticState.getPreviewingAsUser()
    const userId = previewingAsUser?._sid || user?._sid || ''
    const previewingAsRole = GlobalStaticState.getPreviewingAsRole()
    if (previewingAsRole) {
        return [userId, previewingAsRole]
    }
    return [userId]
}

export function buildQueryKey(
    queryNameOrDeps: QueryKey,
    options: {
        includeAuthKeys?: boolean
        includeStackId?: boolean
        stackId?: string
    }
): QueryKey {
    const { includeAuthKeys, includeStackId, stackId: providedStackId } = options

    const authDeps = getAuthStateDependencies()
    let value: QueryKey = queryNameOrDeps || []
    // include stack id first in the list of dependencies after
    // the list name so that we can clear all the queries for a given
    // stack
    if (includeStackId) {
        const stackId = providedStackId ?? getCurrentStackId()
        if (stackId) {
            value = ([] as unknown[]).concat(value, stackId)
        }
    }
    // If the includeAuthKeys option is set, then we want to add
    // auth related dependencies to the dependencies list. This will ensure
    // the query gets refetched when any auth state change happens
    if (includeAuthKeys && authDeps) {
        value = ([] as unknown[]).concat(value, authDeps)
    }
    return value
}

export function useQueryKeyBuilder(queryNameOrDeps: QueryKey, options = {}) {
    // Even though the hook value isn't used, we still include it to ensure
    // a rerender when it changes
    useAuthStateDependencies()

    return buildQueryKey(queryNameOrDeps, options)
}

export type QueryOptions<T> = UseQueryOptions<T, Error> & {
    bypassMatchingStackCheck?: boolean
    bypassPreviewAs?: boolean
    disabled?: boolean
    disabledValue?: any
    disableOptimisticUpdates?: boolean
    disabledFn?: () => boolean
    afterFetch?: (data: any) => Promise<void>
    customMapper?: (data: any) => any
    provideFetchOptions?: () => RequestInit | undefined
    queryParams?: { [key: string]: boolean | string | undefined }
    ignore40x?: boolean
    bypassQueryDeps?: boolean
    stackId?: string
}

function doFetch<T>(
    endpoint: string,
    params?: RequestInit | undefined,
    options?: QueryOptions<T>
): Promise<Response> {
    return fetchWithAuth(buildUrl(endpoint, options?.queryParams ?? {}), params, options)
}

export function useQuery<T>(
    queryNameOrDeps: REACT_QUERY_DEPS_NAME | QueryKey,
    url: string,
    options?: UseQueryOptions<T>,
    internalOptions: QueryOptions<T> = {}
): UseQueryResult<T> & { url: string } {
    //please be careful with disabled. As it uses useRef to store previous value and detect invalidation,
    // you can only re-enable it from the same component.
    // Otherwise it will consider it initially enabled and won't trigger disabled query invalidation
    //use options = { enabled: false } instead
    const disabled =
        internalOptions.disabled || (internalOptions.disabledFn && internalOptions.disabledFn())

    const disabledRef = useRef(disabled)
    if (disabled !== disabledRef.current) {
        disabledRef.current = disabled
        queryClient.invalidateQueries(queryNameOrDeps)
    }

    const afterFetch = internalOptions.afterFetch
    if (internalOptions.afterFetch) delete internalOptions.afterFetch

    const customMapper = internalOptions.customMapper
    if (internalOptions.customMapper) delete internalOptions.customMapper

    if (url[0] === '/') {
        url = url.slice(1)
    }

    const disabledForFailures = shouldDisableDueToFailures(queryNameOrDeps)

    const query = _useQuery<T, Error>(
        queryNameOrDeps,
        async () => {
            // This option allows us to disable this query completely
            if (disabledRef.current) {
                return Promise.resolve(
                    internalOptions.disabledValue !== undefined ? internalOptions.disabledValue : []
                )
            }

            try {
                const response = await doFetch(
                    internalOptions?.bypassQueryDeps
                        ? url
                        : `${url}?query=${formatQueryDepsToString(queryNameOrDeps)}`,
                    internalOptions?.provideFetchOptions?.(),
                    internalOptions
                )

                if (response.status >= 400) {
                    logQueryFailure(queryNameOrDeps)

                    // if we've been instructed to ignore 40x errors, then
                    // just return null for this query
                    if (internalOptions?.ignore40x) {
                        return Promise.resolve(null)
                    }

                    return response.json().then((data) => Promise.reject(data))
                }

                // got a successful query, so reset any failure count
                resetQueryFailures(queryNameOrDeps)

                let result = await response.json()
                if (customMapper) {
                    result = customMapper(result)
                }
                if (afterFetch) {
                    // So that we can wait for something else such as a redux state update
                    await afterFetch(result)
                }
                return result
            } catch (ex) {
                // Make sure to log this query failure so we can disable it if
                // we've gotten too many failures in a short period of time
                logQueryFailure(queryNameOrDeps)
                throw ex
            }
        },
        {
            ...options,
            enabled: (options?.enabled ?? true) && !disabledForFailures,
        }
    )

    return { ...query, url }
}

type MutationOptions<T> = UseMutationOptions<
    T,
    unknown,
    { id?: string; patch?: Partial<T>; stack_id?: string }
>

export function useUpdateItem<T extends { _sid: string; stack_id?: string }>(
    listName: QueryKey,
    endpoint: string,
    config?: MutationOptions<T>,
    internalOptions: UseUpdateRecordInternalOptions = {},
    allowOptimisticUpdates: boolean = true
): UseMutationResult<T, unknown, { id: string; patch: Partial<T> }> {
    const queryClient = useQueryClient()
    const currentStackId = internalOptions.stackSid ?? getCurrentStackId()

    return useMutation<T, unknown, { id: string; patch: Partial<T> }>(
        async ({ id, patch }) => {
            patch = transformFromLocal(patch)
            const additionalFetchOptions = internalOptions?.provideFetchOptions?.({ id, patch })

            const response = await doFetch<T>(
                `${endpoint}${id}/`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(patch),
                    headers: {
                        'Content-Type': 'application/json',
                        ...additionalFetchOptions?.headers,
                    },
                },
                {
                    stackId: internalOptions.stackSid,
                    ...additionalFetchOptions,
                    bypassPreviewAs: internalOptions?.bypassPreviewAs,
                }
            )

            if (response.status >= 400) {
                return Promise.reject(response)
            }

            const data: T = await response.json()
            return data
        },
        {
            onMutate: ({ id, patch }): (() => T[]) => {
                const previousList = queryClient.getQueryData<T[]>(listName) || []
                const item = previousList.find(
                    ({ _sid, stack_id }) =>
                        _sid === id && (!stack_id || stack_id === currentStackId)
                )

                if (item && allowOptimisticUpdates) {
                    const newList = previousList.map((x) => (x === item ? { ...x, ...patch } : x))
                    queryClient.setQueryData(listName, newList)
                }

                if (typeof config?.onMutate === 'function') {
                    config.onMutate({ id, patch, stack_id: currentStackId })
                }

                return () => queryClient.setQueryData(listName, previousList)
            },
            onSuccess: (data, { id, patch }) => {
                const previousList = queryClient.getQueryData<T[]>(listName) || []
                const item = previousList.find(
                    ({ _sid, stack_id }) =>
                        _sid === id && (!stack_id || stack_id === currentStackId)
                )
                if (item) {
                    const newList = previousList.map((x) => (x === item ? data : x))
                    queryClient.setQueryData(listName, newList)
                } else {
                    queryClient.invalidateQueries(listName)
                }
                if (typeof config?.onSuccess === 'function') {
                    return config.onSuccess(data, { id, patch, stack_id: currentStackId }, null)
                }

                return Promise.resolve(data)
            },
            onSettled: (...args) => {
                if (typeof config?.onSettled === 'function') {
                    return config.onSettled(...args)
                }
            },
        }
    )
}

/**
 * Passes the supplied data to the server and expects a list of changed records
 * in response.
 */
export function useBatchUpdate(
    listName: REACT_QUERY_DEPS_NAME,
    endpoint: string,
    compareRecordsFn: (record1: RecordDto, record2: RecordDto) => boolean,
    config?: MutationOptions<RecordDto[]>,
    internalOptions = {}
): UseMutationResult<RecordDto[]> {
    const queryClient = useQueryClient()
    const currentStackId = getCurrentStackId()

    return useMutation<RecordDto[], unknown, { id: string; patch: RecordDto[] }>(
        async (data) => {
            const response = await doFetch<RecordDto[]>(
                `${endpoint}/`,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
                internalOptions
            )
            if (response.status >= 400) {
                return Promise.reject(response)
            }

            const records: RecordDto[] = await response.json()
            return records
        },
        {
            onMutate: (data) => {
                const previousList = queryClient.getQueryData(listName) || []

                if (typeof config?.onMutate === 'function') {
                    config.onMutate({ ...data, stack_id: currentStackId })
                }

                return () => queryClient.setQueryData(listName, previousList)
            },
            onSuccess: async (data) => {
                const previousList = queryClient.getQueryData<RecordDto[]>(listName) || []

                // Replaces the existing records with the updated ones coming
                // back from the server
                const newList = previousList.map(
                    (x) => data.find((record) => compareRecordsFn(record, x)) || x
                )

                queryClient.setQueryData(listName, newList)
                if (typeof config?.onSuccess === 'function') {
                    return config.onSuccess(data, { stack_id: currentStackId }, null)
                }
                return data
            },
            onSettled: (...args) => {
                if (typeof config?.onSettled === 'function') {
                    return config.onSettled(...args)
                }
            },
        }
    )
}

export function useCreateItem<T extends { _sid: string; id?: string; stack_id?: string }>(
    listName: QueryKey,
    endpoint: string,
    config?: MutationOptions<T>,
    internalOptions?: QueryOptions<T>,
    allowOptimisticUpdates: boolean = true
): UseMutationResult<T> {
    const queryClient = useQueryClient()
    const currentStackId = getCurrentStackId()

    return useMutation<T, unknown, T>(
        async (data) => {
            const response = await doFetch<T>(
                endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
                internalOptions
            )

            if (response.status >= 400) {
                return Promise.reject(response)
            }

            return await response.json()
        },
        {
            onMutate: (object) => {
                if (typeof config?.onMutate === 'function') {
                    config.onMutate(object)
                }
                if (allowOptimisticUpdates && !internalOptions?.disableOptimisticUpdates) {
                    queryClient.cancelQueries(listName)
                    const previous = queryClient.getQueryData(listName)
                    queryClient.setQueryData(listName, (old: T[] = []) => [...old, object])

                    // rollback function
                    return () => queryClient.setQueryData(listName, previous)
                }
            },
            onError: (_, __, rollback) => {
                if (rollback) {
                    // @ts-ignore
                    rollback()
                }
            },
            onSuccess: (data, { id }) => {
                const previousList = queryClient.getQueryData<T[]>(listName) || []
                const item = previousList.find(
                    ({ _sid, stack_id }) =>
                        _sid === id && (!stack_id || stack_id === currentStackId)
                )
                if (item) {
                    const newList = previousList.map((x) => (x === item ? data : x))
                    queryClient.setQueryData(listName, newList)
                } else {
                    queryClient.invalidateQueries(listName)
                }
                if (typeof config?.onSuccess === 'function') {
                    return config.onSuccess(data, { id, stack_id: currentStackId }, null)
                }

                return Promise.resolve(data)
            },
            onSettled: (...args) => {
                if (typeof config?.onSettled === 'function') {
                    return config.onSettled(...args)
                }
            },
        }
    )
}

export function useDeleteItem<T extends { _sid: string; stack_id?: string }>(
    listName: QueryKey,
    endpoint: string,
    config?: MutationOptions<T>,
    internalOptions?: QueryOptions<T>
): UseMutationResult<T> {
    const queryClient = useQueryClient()
    const currentStackId = getCurrentStackId()

    return useMutation<T, unknown, { id: string } | string>(
        // @ts-ignore
        async (params) => {
            // Accept id as either a prop on an object, or as the params value itself
            const id = typeof params === 'string' ? params : params.id
            const response = await doFetch<T>(
                `${endpoint}${id}/`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
                internalOptions
            )

            if (response.status >= 400) {
                return Promise.reject(response)
            }

            return response
        },
        {
            onSuccess: (data, params) => {
                // Accept id as either a prop on an object, or as the params value itself
                const id = typeof params === 'string' ? params : params.id
                const previousList = queryClient.getQueryData<T[]>(listName) || []
                const item = previousList.find(
                    ({ _sid, stack_id }) =>
                        _sid === id && (!stack_id || stack_id === currentStackId)
                )
                if (item) {
                    const newList = previousList.filter(({ _sid }) => _sid !== item._sid)

                    queryClient.setQueryData(listName, newList)
                }
                if (typeof config?.onSuccess === 'function') {
                    return config.onSuccess(data, { id, stack_id: currentStackId }, null)
                }
            },
            onSettled: (...args) => {
                if (typeof config?.onSettled === 'function') {
                    // @ts-ignore
                    return config.onSettled(...args)
                }
            },
        }
    )
}

export const queryClient = _queryClient
export const queryCache = _queryClient.getQueryCache()

function transformFromLocal(data) {
    if (!data) return data
    const keys = Object.keys(data)
    const out = {}
    keys.forEach((key) => {
        if (key.includes('options.')) {
            const keyName = key.split('.')[1]
            // @ts-ignore
            out.options = { ...out.options, [keyName]: data[key] }
        } else {
            out[key] = data[key]
        }
    })
    return out
}

function formatQueryDepsToString(deps: QueryKey) {
    if (Array.isArray(deps)) {
        if (deps.length === 0) {
            return ''
        }
        const firstDep = deps[0]
        return typeof firstDep === 'string' ? firstDep : JSON.stringify(firstDep)
    } else {
        return deps
    }
}

export const removeStackSpecificQueries = () => {
    const queryKeys = ['useMetrics', 'record', 'records']
    // We want to totally remove any record queries when changing the app
    queryKeys.forEach((key) => {
        queryClient.resetQueries(key)
    })
}

// @ts-strict-ignore
import { useMemo } from 'react'

import { AggregationType } from 'data/hooks/aggregates'
import { TableViewCalculationItem } from 'features/views/ListView/TableView/hooks/useTableViewCalculations'
import { TableViewColumn } from 'features/views/ListView/TableView/types'
import { useTableViewContext } from 'features/views/ListView/TableView/useTableViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

const PLACEHOLDER_VALUE = 333333333

const CALCULATION_ORDER: Partial<Record<AggregationType, number>> = {
    countall: 0,
    countempty: 1,
    count: 2,
    sum: 3,
    avg: 4,
    median: 5,
    min: 6,
    max: 7,
    range: 8,
}

export type CalculationItem = {
    label: string
    type: AggregationType
    value: number
}

type UseTableViewCalculationItemStateOptions = {
    column: TableViewColumn
}

export function useTableViewCalculationItemState(options: UseTableViewCalculationItemStateOptions) {
    const { column } = options
    const { field, calculation } = column

    const { calculations, isCalculationsError, isCalculationsFetchingSlow } = useTableViewContext()

    const calculationsForField = calculations?.[field.api_name] ?? ({} as TableViewCalculationItem)
    const allCalculations = Object.values(calculationsForField).sort((a, b) => {
        return CALCULATION_ORDER[a.type]! - CALCULATION_ORDER[b.type]!
    })
    const calculationItems = useDeepEqualsMemoValue(allCalculations)

    const primaryCalculationType = calculation?.type
    const primaryCalculation = primaryCalculationType
        ? calculationsForField[primaryCalculationType]
        : undefined

    const label = primaryCalculation?.label ?? ''
    const value =
        isCalculationsFetchingSlow || !primaryCalculation
            ? PLACEHOLDER_VALUE
            : primaryCalculation.value

    const shouldRender = !!calculation && !!primaryCalculation

    return useMemo(
        () => ({
            label,
            value,
            isFetchingSlow: isCalculationsFetchingSlow,
            calculations: calculationItems,
            shouldRender,
            isError: isCalculationsError,
            calculationType: primaryCalculationType,
        }),
        [
            label,
            value,
            isCalculationsFetchingSlow,
            calculationItems,
            shouldRender,
            isCalculationsError,
            primaryCalculationType,
        ]
    )
}

import React from 'react'

import CreateButton from 'v2/views/List/CreateButton'
import { shouldAddViaRelatedList, useCreateQueryParams } from 'v2/views/utils/useCreateQueryParams'

import { canCreateRecords } from 'data/utils/getObjectRecordRestrictions'
import useTrack from 'utils/useTrack'

import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { ViewState } from './viewStateType'

type ListViewButtonsProps = {
    object?: ObjectDto
    text?: string
    viewState?: ViewState
    isRecordList?: boolean
    relatedFieldMayCreateNewRecords?: boolean
    hideEditControls?: boolean
    isRecordListOnCreate?: boolean
    listEditControls?: React.ComponentType
    relatedListSymmetricColumnName?: string
    relatedListType?: string
    autoFilledRelatedListRecord?: string
}

const ListViewButtons: React.VFC<ListViewButtonsProps> = ({
    object,
    text,
    viewState,
    isRecordList,
    relatedFieldMayCreateNewRecords,
    hideEditControls,
    isRecordListOnCreate,
    listEditControls,
    relatedListSymmetricColumnName,
    relatedListType,
    autoFilledRelatedListRecord,
}) => {
    const { track } = useTrack()

    const redirectToList = viewState?.view?.options?.redirectToList
    const display = viewState?.view?.options?.display
    const isInbox = display === 'inbox'

    const createQueryParamsConfig = {
        isInbox,
        redirectToList,
        relatedFieldMayCreateNewRecords,
        isRecordListOnCreate,
        relatedListSymmetricColumnName,
        relatedListType,
        autoFilledRelatedListRecord,
        isRecordList,
    }

    const queryParams = useCreateQueryParams(object, createQueryParamsConfig)
    const addViaRelatedList = shouldAddViaRelatedList(object, createQueryParamsConfig)
    if (hideEditControls) return null
    if (listEditControls) return <>{listEditControls}</>

    if (
        object?._permissions?.may_create &&
        canCreateRecords(object) &&
        (addViaRelatedList || !isRecordList)
    ) {
        return (
            <CreateButton
                onClick={() => {
                    if (display === 'calendar') {
                        track('Calendar view - clicked on add new button')
                    }
                }}
                text={text}
                objectId={object?._sid}
                queryParams={queryParams}
                className={ONBOARDING_CLASSES.ADD_NEW_RECORD_BUTTON}
            />
        )
    }

    return null
}

export default ListViewButtons

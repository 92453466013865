import * as React from 'react'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { useUpdateUser } from 'data/hooks/users/main'
import { Divider, FormFrame } from 'features/workspace/WorkspaceSettingsModalUi'

import { Icon } from 'v2/ui'

import CheckboxForm from 'ui/deprecated/forms/CheckboxForm'
import Form from 'ui/deprecated/forms/Form'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const Content = ({ user, updateUser, formRef, children }) => {
    const notificationsSettings = user.options.notifications ?? {}

    const onSubmit = (data) => {
        updateUser({
            id: user._sid,
            patch: {
                options: {
                    ...user.options,
                    notifications: {
                        // merge with existing values in case the names of values change, to not overwrite old ones
                        ...notificationsSettings,

                        // pass in each value explicitly to reduce bug surface area
                        // note this means each time we add a value in the form we
                        // also have to add it here to have it saved
                        email_feed_update: !!data.email_feed_update,
                        email_mention: !!data.email_mention,
                    },
                },
                name: data.name,
            },
        })
    }

    const defaultValues = {
        email_feed_update: notificationsSettings.email_feed_update ?? true, // default true if not set
        email_mention: notificationsSettings.email_mention ?? true, // default true if not set...notificationsSettings,
    }

    return (
        <Form
            onSubmit={onSubmit}
            options={{
                defaultValues,
            }}
            style={{ flex: 1 }}
            resetOnSuccess
        >
            <FormFrame title="Notifications" formRef={formRef}>
                <Divider />
                <div style={{ height: 16 }} />
                <div
                    style={{
                        marginBottom: 20,
                        fontSize: 14,
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Icon icon="emailSolid" color={V4DesignSystem.colors.gray[400]} />
                    <div style={{ marginLeft: 12 }}>Send me an email notification when...</div>
                </div>

                <CheckboxForm
                    name="email_feed_update"
                    label="A new comment is posted to a thread I'm following"
                />

                <div style={{ height: 16 }} />

                <CheckboxForm
                    name="email_mention"
                    label="I'm mentioned in a comment in any thread"
                />
            </FormFrame>
            {children}
        </Form>
    )
}

export default function ProfileSettingsModalGeneralSettings({ formRef, children }) {
    const { user } = useAuthContext()
    const { mutateAsync: updateUser } = useUpdateUser()

    if (!user) {
        return // loading
    }

    return (
        <Content user={user} updateUser={updateUser} formRef={formRef}>
            {children}
        </Content>
    )
}

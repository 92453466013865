// @ts-strict-ignore
import React, { useState } from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'

import { useAppContext } from 'app/useAppContext'
import { useConfirmModal } from 'app/useConfirmModal'
import { useRecordActions } from 'data/hooks/objects'
import { withObject } from 'data/wrappers/withObject'
import QuillRichText from 'features/pages/blocks/blockTypes/form/QuillRichText'
import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'
import { ColorPickerDropdown } from 'features/workspace/forms/ColorPicker'

import {
    Box,
    Checkbox,
    ConfigureWidgetPlaceholder,
    Dropdown,
    Flex,
    Input,
    OrderableListSelector,
    RichTextEditor,
    Text,
} from 'v2/ui'
import * as SVGIcons from 'v2/ui/svgs'

//@ts-ignore
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const StyledQuillRichText = styled(QuillRichText)`
    flex-basis: auto;
`

const getThemedChevron = (themeColor) => styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;

    &::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 4px;
        right: 4px;
        bottom: calc(50% - 1px);
        background: ${V4DesignSystem.colors.gray[200]};
        transform: skew(25deg, 0);
        border-radius: 2px;
    }

    &::after {
        content: ' ';
        position: absolute;
        top: calc(50% - 1px);
        left: 4px;
        right: 4px;
        bottom: 0;
        background: ${V4DesignSystem.colors.gray[200]};
        transform: skew(-25deg, 0);
        border-radius: 2px;
    }

    &.current {
        &::before,
        &::after {
            background: ${themeColor};
        }
    }
`

const ChevronContainer = styled.div`
    overflow: hidden;
    width: 110px;
    flex-grow: 1;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 0.5rem;

    &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        div:before,
        div:after {
            left: -20px;
        }
    }

    &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        div:before,
        div:after {
            right: -20px;
        }
    }
`

const getDropdownFieldOptionsFromObject = ({ fields }) => {
    if (!fields) return []

    return fields
        .filter(
            (field) => field.type === 'dropdown' && !get(field, 'connection_options.is_disabled')
        )
        .map((record) => ({ label: record.label, value: record._sid }))
}

const PipelineBlockEditor = withObject(({ object, params, setAttr }) => {
    const fieldId = params.fieldId
    const field = object.fields.find((f) => f._sid === fieldId)

    const [previousFieldId, setPreviousFieldId] = useState(fieldId)
    const { selectedStack } = useAppContext()

    if (previousFieldId !== fieldId) {
        setAttr('stages', [])
        setPreviousFieldId(fieldId)
    }

    const fieldChosen = field && !get(field, 'connection_options.is_disabled', false)

    const values = field?.options?.options || []
    const draggableItems = values.map((v) => ({ id: v.value, label: v.label }))
    const stages = params.stages || []
    const selected = stages.map((s) => ({ id: s }))

    return (
        <Flex flexWrap="nowrap" flexDirection="column" alignItems="stretch" height="100%">
            <Text variant="paletteSectionLabel">Field</Text>
            <Box mb={3}>
                <Dropdown
                    value={params.fieldId}
                    options={getDropdownFieldOptionsFromObject(object)}
                    placeholder="Select a dropdown field..."
                    onChange={(fieldId) => {
                        setAttr('fieldId', fieldId)
                    }}
                />
            </Box>

            {fieldChosen && (
                <Box maxWidth="100%" minHeight={0} display="flex" flexDirection="column">
                    <Text variant="paletteSectionLabel">Title</Text>
                    <Input
                        variant="admin_white"
                        id="title"
                        placeholder="Enter a title"
                        value={params.title ?? ''}
                        onChange={(e) => {
                            setAttr('title', e.target?.value)
                        }}
                    />

                    <Text variant="paletteSectionLabel">Description</Text>
                    <StyledQuillRichText
                        // @ts-expect-error as it's integration with untyped js
                        fontSize="14px"
                        placeholder="Add a description"
                        onChange={(value) => setAttr('description', value)}
                        value={params.description ?? ''}
                        convertToMarkdown={false}
                        height="55px"
                    />

                    <Checkbox
                        mt="1rem"
                        id="readOnly"
                        isChecked={params.readOnly}
                        onChange={(event) => {
                            setAttr('readOnly', event.target.checked)
                        }}
                        variant="admin"
                        mb={2}
                    >
                        Make read only
                    </Checkbox>
                    <Text variant="paletteSectionLabel">Stages</Text>
                    <Box flexGrow={1} overflow="auto" mb={2} maxHeight="247px">
                        <OrderableListSelector
                            items={draggableItems}
                            onUpdate={(items) =>
                                setAttr(
                                    'stages',
                                    items?.map((i) => i.id)
                                )
                            }
                            onAdd={(item) => setAttr('stages', [...stages, item.id])}
                            selectedItems={selected}
                        />
                    </Box>
                    <Flex
                        direction="row"
                        style={{ position: 'relative' }}
                        justifyContent="space-between"
                    >
                        <Text variant="paletteSectionLabel">Color</Text>
                        <ColorPickerDropdown
                            usePortal={false}
                            value={params.color ?? selectedStack?.options?.theme?.brandColor}
                            onChange={(value) => {
                                setAttr('color', value)
                            }}
                            place="top"
                        />
                    </Flex>
                </Box>
            )}
        </Flex>
    )
})

const PipelineBlock = ({ attrs, context }) => {
    const actions = useRecordActions()

    const { selectedStack } = useAppContext()

    const { fieldId, readOnly: readOnlyToggle, title, description, color } = attrs
    const field = context.object?.fields?.find((f) => f._sid === fieldId)
    const readOnly =
        readOnlyToggle ||
        !context.record ||
        context.object?.connection_options?.read_only ||
        !context.record?._permissions?.may_update ||
        !context.record?._permissions?.may_update_fields.includes(field?.api_name) ||
        field?.is_read_only

    // The modal contents are dynamic, so pass the config object to showUpdateConfirm instead of useConfirmModal
    const { show: showUpdateConfirm } = useConfirmModal()

    const chevronClick = (stage) => {
        if (isEditing) {
            return
        }

        if (context.view.editing || context.view.creating) {
            context.view.actions.setValue(field.api_name, stage.value)
            return
        }

        showUpdateConfirm({
            title: `Update ${field?.label}?`,
            endUser: true,
            message: () => (
                <Text>
                    Are you sure you want to update <b>{field.label}</b> on this record to{' '}
                    <b>{stage.label}</b>?
                </Text>
            ),
            onConfirm: (modal) =>
                actions
                    .update(context.record._sid, { [field.api_name]: stage.value }, undefined)
                    .then(modal.toggle),
        })
    }

    const isEditing = get(context, 'editor.isEditing')
    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    const hidden = !isEditing && passesFilters

    if (hidden) {
        return <></>
    }

    const placeholderIfEditing = isEditing ? (
        <ConfigureWidgetPlaceholder
            message="Pipeline"
            subtitle="Display a pipeline with stages"
            Icon={SVGIcons.Pipeline}
        />
    ) : (
        <></>
    )

    if (!fieldId || !field || get(field, 'connection_options.is_disabled', false)) {
        return placeholderIfEditing
    }

    const fieldOptions = field.options?.options
    const stages = (attrs.stages || [])
        .map((s) => fieldOptions.find((o) => o.value === s))
        .filter((s) => !!s)

    if (!stages || !stages.length) {
        return placeholderIfEditing
    }

    const currentStage = context.record?.[field.api_name]
    const Chevron = getThemedChevron(color ?? selectedStack?.options.theme.brandColor)

    let reachedActiveStage = false
    return (
        <Box mb={2}>
            <Text mb="1rem" fontWeight="bold" fontSize="16px">
                {title}
            </Text>
            <Text fontSize="14px" mb="0.5rem">
                <RichTextEditor>{description}</RichTextEditor>
            </Text>
            <Flex justifyContent="stretch">
                {stages
                    .reverse()
                    .map((s, index) => {
                        if (s.value === currentStage) {
                            reachedActiveStage = true
                        }

                        return (
                            <ChevronContainer
                                key={index}
                                style={isEditing || readOnly ? {} : { cursor: 'pointer' }}
                                onClick={readOnly ? undefined : () => chevronClick(s)}
                            >
                                <Chevron className={reachedActiveStage ? 'current' : ''} />
                                <Text
                                    color={
                                        reachedActiveStage
                                            ? 'white'
                                            : V4DesignSystem.colors.gray[800]
                                    }
                                    pl={4}
                                    pr={3}
                                    py={2}
                                    size="sm"
                                    fontWeight="800"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {s.label}
                                </Text>
                            </ChevronContainer>
                        )
                    })
                    .reverse()}
            </Flex>
        </Box>
    )
}

PipelineBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    configuratorWrapper({
        field: 'stages',
        Element: PipelineBlockEditor,
        simple: true,
        fullHeight: true,
    }),
]

export default PipelineBlock

import React, {
    ComponentType,
    FC,
    MutableRefObject,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import { ButtonProps, useDisclosure } from '@chakra-ui/react'
import type { Placement } from '@popperjs/core'

import Button from './Button'
import Popper from './Popper'

export type PopoverButtonChildrenProps = {
    isOpen: boolean
    onToggle: () => void
    buttonRef: MutableRefObject<HTMLElement>
    close: () => void
}

export type Props = ButtonProps & {
    defaultIsOpen: boolean
    label?: string | ReactNode
    ButtonComponent?: ComponentType
    onOpen?: () => void
    onClose?: () => void
    place?: Placement
    hideOnScroll?: boolean
    closeOnOuterAction?: boolean
    disabled?: boolean
    icon?: string | Element
    usePortal?: boolean
}

const PopoverButton: FC<Props> = ({
    defaultIsOpen,
    children,
    ButtonComponent = Button,
    label,
    onOpen,
    onClose,
    place = 'bottom-start',
    hideOnScroll = false,
    closeOnOuterAction,
    usePortal = true,
    disabled,
    ...props
}) => {
    const { isOpen, onToggle, onClose: close, onOpen: open } = useDisclosure()
    const lastOpenValue = useRef(false)
    const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null)

    // want to limit the outer actions to the root node of the button, so that
    // clicks in portaled elements like dropdowns inside the popover doen't close it
    const rootNode = useMemo(() => {
        for (const value of Array.from(document.body.childNodes.values())) {
            if (value.contains(buttonRef)) {
                return value
            }
        }

        return
    }, [buttonRef])

    // if we're supposed to default to open, set a timeout and
    // open after the component has had a chance to mount,  otherwise
    // the popover will appear in the upper left of the screen
    useEffect(() => {
        if (defaultIsOpen) {
            setTimeout(() => open(), 0)
        }
    }, [defaultIsOpen, open])

    const isPopoverOpen = isOpen && !disabled

    const content = useMemo(
        () =>
            typeof children === 'function'
                ? children({ isOpen, onToggle, buttonRef: { current: buttonRef }, close })
                : children,
        [buttonRef, children, close, isOpen, onToggle]
    )

    const handleClick = (event: MouseEvent) => {
        if (!disabled) {
            onToggle()
        }

        event.preventDefault()
        event.stopPropagation()
    }

    const handleClosePopover = useCallback(() => {
        close()
        onClose?.()
    }, [close, onClose])

    useEffect(() => {
        if (isOpen === lastOpenValue.current) {
            return
        }

        lastOpenValue.current = isOpen
        if (isOpen) {
            onOpen?.()
        } else {
            onClose?.()
        }
    }, [isOpen, onOpen, onClose])

    useEffect(() => {
        if (isPopoverOpen && hideOnScroll) {
            window.addEventListener('scroll', handleClosePopover, true)
        }

        return () => {
            window.removeEventListener('scroll', handleClosePopover, true)
        }
    }, [handleClosePopover, hideOnScroll, isPopoverOpen])

    return (
        <>
            <ButtonComponent
                disabled={disabled}
                onClick={handleClick}
                ref={setButtonRef}
                {...props}
            >
                {label}
            </ButtonComponent>
            {isOpen && (
                <Popper
                    referenceElement={buttonRef}
                    placement={place}
                    closeOnOuterAction={closeOnOuterAction}
                    onClose={handleClosePopover}
                    limitOuterActionsToDescendentsOf={rootNode}
                    usePortal={usePortal}
                >
                    {content}
                </Popper>
            )}
        </>
    )
}
export default PopoverButton

import React from 'react'

import { AppUsersContent } from './AppUsersContent'
import { AppUsersContextProvider } from './AppUsersContextProvider'
import { AppUsersScrollArea } from './AppUsersScrollArea'

type AppUsersProps = {}

export const AppUsers: React.FC<AppUsersProps> = () => {
    return (
        <AppUsersContextProvider>
            <AppUsersScrollArea>
                <AppUsersContent />
            </AppUsersScrollArea>
        </AppUsersContextProvider>
    )
}

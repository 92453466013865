import React from 'react'

import { ConditionInput } from 'features/conditions/ConditionInput'

import { GroupTitle } from './WorkflowExpressionInput'

type WorkflowConditionInputProps = Omit<
    React.ComponentPropsWithoutRef<typeof ConditionInput>,
    'renderGroupTitle'
> & {}

export const WorkflowConditionInput: React.FC<WorkflowConditionInputProps> = (props) => {
    return <ConditionInput {...props} renderGroupTitle={GroupTitle} />
}

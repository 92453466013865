export function canDeleteRecords(object: ObjectDto) {
    return !!object && !!object.can_delete_records && !object?.connection_options?.read_only
}

export function canCreateRecords(object: ObjectDto) {
    if (!object) return false
    const readOnly = !!object?.connection_options?.read_only

    return !readOnly && !!object?._permissions?.may_create && !!object.can_create_records
}

// @ts-strict-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react'
import FocusLock, { AutoFocusInside } from 'react-focus-lock'
import { Portal } from 'react-portal'

import classNames from 'classnames'

import { Button as V2Button, ConditionalWrapper, Flex } from 'v2/ui'
import useLockBodyScroll from 'v2/ui/hooks/useLockBodyScroll'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import stackerTheme from 'v2/ui/theme/styles/default'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import { useTheme } from 'ui/styling/themes/useTheme'

import Box from './Box'
import CloseButton from './CloseButton'

const colors = stackerTheme().colors

// Allows us to still focus on things outside of the react app
// e.g. intercom
const lockWhitelist = (node: HTMLElement) =>
    document?.getElementById('app')?.contains(node) ?? false

type Props = {
    height?: string
    width?: string
    padding?: string
    title?: React.ReactNode
    showCloseButton?: boolean
    renderFooter?: () => React.ReactElement
    onClose?: () => void
    open?: boolean
    disableFocusLock?: boolean
    zIndex?: number
    closeOnEsc?: boolean
    scrollBarCss?: string
    usePortal?: boolean
    textAlign?: string
    actions?: React.ReactNode
    actionsBorder?: boolean
    footer?: React.ReactNode
    autofocus?: boolean
    style?: React.CSSProperties
    hideTitleBottomBorder?: boolean
    'data-testid'?: string
}
/**
 * @param any param0
 */
const SimpleModal: React.FC<Props> = ({
    children,
    height = '90vh',
    width = '1024px',
    padding = '20px',
    title,
    showCloseButton = false,
    renderFooter,
    onClose,
    open,
    disableFocusLock = false,
    zIndex = 1400,
    closeOnEsc = false,
    scrollBarCss = undefined,
    usePortal = true,
    textAlign = 'inherit',
    actions = null,
    actionsBorder = false,
    footer = undefined,
    autofocus = true,
    style = {},
    hideTitleBottomBorder,
    'data-testid': dataTestId = undefined,
}) => {
    const [isOpen, setIsOpen] = useState(open)
    const [focusLockDisabled, setFocusLockDisabled] = useState(disableFocusLock)

    useLockBodyScroll(isOpen)

    const onModalClose = useCallback(() => {
        if (onClose) onClose()
    }, [onClose])

    const closeButtonRef = useRef<HTMLElement | undefined>()

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    useEffect(() => {
        setFocusLockDisabled(disableFocusLock)
    }, [disableFocusLock])

    const handleKeyDown = (e) => {
        if (e.keyCode === 27) {
            if (closeOnEsc) {
                onClose?.()
                // don't want a parent modal handling this Esc key event as well
                e.stopPropagation()
                e.preventDefault()
            } else {
                if (closeButtonRef.current) {
                    closeButtonRef.current.focus()
                }
            }
        }
    }

    const { themeClassName } = useTheme()

    if (!isOpen) return null

    return (
        <ConditionalWrapper
            condition={usePortal}
            wrapper={(children) => (
                // If we don't open this in a portal, then when opening this modal,
                // inside another modal, the overlay below does not cover the whole screen,
                // but only the parent modal. (Surprising, because I thought fixed was always
                // relative to the viewport, but apparently inside another fixed + absolute hierarchy
                // like you get when inside another modal, it does not work that way.)
                <Portal>{children}</Portal>
            )}
        >
            <div
                data-testid={dataTestId}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    zIndex,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                }}
                className={classNames(
                    ONBOARDING_CLASSES.MODAL_OVERLAY,
                    themeClassName,
                    'ag-custom-component-popup'
                )}
            >
                <FocusLock disabled={focusLockDisabled} whiteList={lockWhitelist}>
                    <ConditionalWrapper
                        condition={autofocus}
                        wrapper={(children) => <AutoFocusInside>{children}</AutoFocusInside>}
                    >
                        <Flex
                            style={{
                                height,
                                maxHeight: '100dvh',
                                width,
                                maxWidth: '100%',
                                textAlign,
                                backgroundColor: 'white',
                                borderRadius: '0.375rem',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                flexDirection: 'column',
                                ...style,
                            }}
                            align="stretch"
                            wrap="nowrap"
                            role="dialog"
                            data-selector="simple-modal"
                            aria-label={title}
                            onKeyDown={handleKeyDown}
                        >
                            {(title || showCloseButton) && (
                                <div
                                    style={
                                        hideTitleBottomBorder
                                            ? {}
                                            : {
                                                  borderBottom: '1px solid ' + colors.grey[100],
                                              }
                                    }
                                >
                                    <Flex
                                        style={{
                                            padding: '1px 10px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: 'center',
                                                ...V4DesignSystem.h3,
                                                padding: showCloseButton
                                                    ? '10px 10px 10px 42px'
                                                    : '10px',
                                            }}
                                        >
                                            {title}
                                        </div>
                                        {showCloseButton && (
                                            <CloseButton
                                                ref={closeButtonRef}
                                                onClick={onModalClose}
                                                aria-label="Close Modal"
                                                className={ONBOARDING_CLASSES.MODAL_CLOSE}
                                            />
                                        )}
                                    </Flex>
                                </div>
                            )}
                            <Flex
                                column
                                style={{
                                    width: '100%',
                                    overflowY: 'auto',
                                    padding,
                                    flexGrow: 1,
                                }}
                                align="stretch"
                                wrap="nowrap"
                                className={scrollBarCss}
                            >
                                {children}
                            </Flex>
                            {actions && (
                                <Box
                                    padding={padding}
                                    borderTop={actionsBorder ? `1px solid ${colors.grey[100]}` : ''}
                                >
                                    {actions}
                                </Box>
                            )}
                            {footer}
                        </Flex>
                    </ConditionalWrapper>
                </FocusLock>
            </div>
            {renderFooter && renderFooter()}
        </ConditionalWrapper>
    )
}

export default SimpleModal

export type CompatProps = {
    title?: string
    body?: React.ReactNode
    actions?: any[]
    actionsBorder?: boolean
    isOpen?: boolean
    onClose?: () => void
    size?: string
    showCloseButton?: boolean
    textAlign?: string
    noPadding?: boolean
    children?: React.ReactNode
    isCentered?: boolean
    bodyStyle?: React.CSSProperties
    actionsLayout?: string
    height?: string
    errorMessage?: string
    isHidden?: boolean
    isSecondLayer?: boolean
    noDividers?: boolean
    variant?: string
    padding?: string
    disableFocusLock?: boolean // new
    closeOnEsc?: boolean // new
    usePortal?: boolean
    zIndex?: number
    footer?: React.ReactNode
    autofocus?: boolean
    style?: React.CSSProperties
    hideTitleBottomBorder?: boolean
    'data-testid'?: string
}
export function SimpleModalCompat({
    title,
    body = undefined,
    actions = [],
    actionsBorder = true,
    isOpen,
    onClose,
    size = '370px',
    showCloseButton = true,
    textAlign = 'inherit',
    noPadding = false,
    children = undefined,
    height,
    padding = undefined,
    disableFocusLock = undefined, // new
    closeOnEsc = true, // new
    usePortal = true,
    zIndex = 1400,
    footer = <></>,
    autofocus = true,
    style = {},
    hideTitleBottomBorder = false,
    'data-testid': dataTestId = undefined,
}: CompatProps) {
    const modalChildren = (
        <>
            {body}
            {children}
        </>
    )

    const actionsJSX = actions && actions.length > 0 && (
        <Box mb={-3}>
            {actions.map(
                (
                    {
                        label,
                        onClick,
                        variant,
                        buttonSize = 'sm',
                        Component = V2Button,
                        display,
                        margin,
                        ...props
                    },
                    index
                ) => (
                    <Component
                        key={index}
                        variant={variant}
                        buttonSize={buttonSize}
                        onClick={onClick}
                        width={props.width ? props.width : '100%'}
                        mb={3}
                        style={{ display: display, margin: margin }}
                        {...props}
                    >
                        {label}
                    </Component>
                )
            )}
        </Box>
    )

    return (
        <SimpleModal
            open={isOpen}
            title={title}
            padding={noPadding ? '0' : padding}
            width={size}
            height={height}
            onClose={onClose}
            showCloseButton={showCloseButton}
            disableFocusLock={disableFocusLock}
            closeOnEsc={closeOnEsc}
            usePortal={usePortal}
            textAlign={textAlign}
            actions={actionsJSX}
            actionsBorder={actionsBorder}
            zIndex={zIndex}
            footer={footer}
            autofocus={autofocus}
            style={style}
            hideTitleBottomBorder={hideTitleBottomBorder}
            data-testid={dataTestId}
        >
            {modalChildren}
        </SimpleModal>
    )
}

import shortid from 'shortid'

export function getNewAction(objectId: string): ActionDto {
    return {
        object_id: objectId,
        options: {
            steps: [
                {
                    id: 'action_step_' + shortid.generate(),
                    displayName: 'Update Record',
                    type: 'updateRecord',
                    fields: [],
                },
            ],
        },
    } as unknown as ActionDto
}

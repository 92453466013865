import React, { FC } from 'react'

import { isFieldSupportedForBucketing } from 'v2/blocks/blockTypes/view/aggregationBlocks/utils/isFieldSupportedForBucketing'

import FieldPicker from 'features/studio/ui/FieldPicker'

import type { DataPanelProps } from './types'

type Props = Pick<DataPanelProps, 'chart' | 'onChange'> & {
    id: string
    fields: FieldDto[] | undefined
    placeholder: string
    filter: (field: FieldDto) => boolean
}

export const GroupByDropdown: FC<Props> = ({
    id,
    chart,
    fields,
    placeholder,
    filter,
    onChange,
}) => {
    const onChangeGroupByField = (newGroupByFieldId: string) => {
        const newGroupByField = fields?.find(({ _sid }) => _sid === newGroupByFieldId)
        if (!newGroupByField) {
            return
        }

        // we need to clean bucketBy if new group field is no longer relevant
        if (!isFieldSupportedForBucketing(newGroupByField)) {
            const { bucketBy: _, ...chartWithoutBucket } = chart
            onChange({ ...chartWithoutBucket, groupByFieldId: newGroupByFieldId })
            return
        }

        onChange({
            ...chart,
            groupByFieldId: newGroupByFieldId,
            bucketBy: chart.bucketBy || 'day',
        })
    }

    return (
        <FieldPicker
            id={id}
            variant="admin"
            objectId={chart.objectId}
            value={chart.groupByFieldId}
            onChange={onChangeGroupByField}
            placeholder={placeholder}
            filter={filter}
            isDisabled={false}
        />
    )
}

import React, { useImperativeHandle, useMemo, useState } from 'react'

export function useEditorValue<T>(ref: React.ForwardedRef<unknown>, initialValue: T) {
    const [value, setValue] = useState<T>(initialValue)

    // This method is accessed by AG Grid to get the value of the cell editor.
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value
            },
        }
    }, [value])

    return useMemo(() => [value, setValue] as const, [value, setValue])
}

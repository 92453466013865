import React from 'react'

import * as Parts from 'features/views/ListView/TableView/TableView.parts'

import { useTableViewCalculationsState } from './hooks/useTableViewCalculationsState'
import { TableViewCalculationItem } from './TableViewCalculationItem'

type TableViewCalculationsProps = React.ComponentPropsWithoutRef<typeof Parts.Row> & {}

export const TableViewCalculations: React.FC<TableViewCalculationsProps> = React.memo(
    function TableViewCalculations(props) {
        const { columns, isRecordActionsColumnPinned, shouldRender } =
            useTableViewCalculationsState()

        if (!shouldRender) return null

        return (
            <Parts.Row {...props}>
                {columns.map((column) => (
                    <TableViewCalculationItem key={column.id} column={column} />
                ))}
                {!isRecordActionsColumnPinned && <Parts.CalculationItem />}
            </Parts.Row>
        )
    }
)

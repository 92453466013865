// @ts-strict-ignore
import { useMemo, useRef } from 'react'

import { mergeAttributes, Node } from '@tiptap/core'
import { CommandProps, JSONContent, ReactNodeViewRenderer } from '@tiptap/react'

import { getAbsoluteWorkspaceRootUrl, getUrl } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import { SingleDocumentResponse, useCreateDocument } from 'data/hooks/documents'
import { useStacks } from 'data/hooks/stacks'

import { DocumentLinkComponent } from './DocumentLinkComponent'

declare module '@tiptap/core' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Commands<ReturnType> {
        documentLink: {
            setDocumentLink: (attrs: DocumentLinkAttrs) => ReturnType
        }
    }
}

export type DocumentLinkExtensionOptions = {
    stack_id: string
    fetchDocumentsFn?: () => DocumentDto[]
    fetchStacksFn?: () => StackDto[]
    fetchWorkspaceAccountFn?: () => Account | null
    createDocument: (params: {
        title?: string
        body?: JSONContent
    }) => Promise<SingleDocumentResponse>
}

type DocumentLinkAttrs = {
    id: number
    stack_id: string
}

export function createDocumentLinkExtension(options: DocumentLinkExtensionOptions) {
    return Node.create<DocumentLinkExtensionOptions>({
        name: 'documentLink',
        addOptions() {
            return options
        },
        inline: true,
        group: 'inline',
        defining: true,
        draggable: true,
        selectable: false,
        atom: true,
        addAttributes() {
            return {
                id: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-id'),
                    renderHTML: (attributes) => ({
                        'data-id': attributes.id,
                    }),
                },
                stack_id: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-stack-id'),
                    renderHTML: (attributes) => ({
                        'data-stack-id': attributes.stack_id,
                    }),
                },
            }
        },
        parseHTML() {
            return [
                {
                    tag: `document-link`,
                },
            ]
        },
        renderHTML({ HTMLAttributes }) {
            return ['document-link', mergeAttributes(HTMLAttributes)]
        },
        renderText({ node }) {
            const workspaceAccount = this.options.fetchWorkspaceAccountFn?.()
            const stacks = this.options.fetchStacksFn?.()
            const stack = stacks?.find((stack) => stack._sid === node.attrs.stack_id)

            if (!stack || !workspaceAccount) {
                return ''
            }

            const workspaceUrl = getAbsoluteWorkspaceRootUrl(workspaceAccount)

            return `${workspaceUrl}${getUrl(`/docs/${node.attrs.id}`, stack)}`
        },
        addNodeView() {
            return ReactNodeViewRenderer(DocumentLinkComponent)
        },
        addStorage() {
            return {
                lastAddedDocument: null,
            }
        },
        // @ts-ignore
        addCommands() {
            return {
                setDocumentLink:
                    (attrs: DocumentLinkAttrs) =>
                    ({ editor, chain }: CommandProps) => {
                        this.storage.lastAddedDocument = attrs.id
                        const range = editor.state.selection

                        const currentChain = chain()

                        // Replace heading nodes with paragraphs.
                        const { schema } = editor
                        const currentNode = editor.state.selection.$from.node()
                        if (currentNode?.type === schema.nodes.heading) {
                            currentChain.setNode(editor.schema.nodes.paragraph)
                        }

                        return currentChain
                            .insertContentAt(range, [
                                {
                                    type: this.name,
                                    attrs,
                                },
                                {
                                    type: 'text',
                                    text: ' ',
                                },
                            ])
                            .run()
                    },
            }
        },
    })
}

export function useDocumentLinkExtension({
    documentId,
    recordId,
    documents,
}: {
    documentId?: number
    recordId?: string
    documents?: DocumentDto[]
}) {
    const { workspaceAccount, selectedStack } = useAppContext()
    const workspaceAccountRef = useRef(workspaceAccount)
    const { mutateAsync: createDocument } = useCreateDocument()
    const { data: stacks } = useStacks()
    const stacksRef = useRef(stacks)

    const documentsRef = useRef(documents)
    documentsRef.current = documents
    return useMemo(() => {
        const options: DocumentLinkExtensionOptions = {
            fetchStacksFn: () => stacksRef.current ?? [],
            fetchWorkspaceAccountFn: () => workspaceAccountRef.current,
            fetchDocumentsFn: () => documentsRef.current ?? [],
            stack_id: selectedStack?._sid ?? '',
            createDocument: ({ title, body }: { title?: string; body?: JSONContent }) =>
                createDocument({
                    parent_document_id: documentId,
                    parent_record_id: recordId,
                    title: title || '',
                    content: { type: 'doc', content: body },
                }),
        }

        return createDocumentLinkExtension(options)
    }, [createDocument, documentId, recordId, selectedStack?._sid])
}

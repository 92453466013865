export const getIsStackerNativeObject = (object?: ObjectDto): boolean => {
    return !!(object && !object.data_connection)
}

export const getIsStackerSharedObject = (object?: ObjectDto): boolean => {
    return getIsStackerNativeObject(object) && !!object!.connection_options?.shared_object_id
}

export const getIsStackerSharedObjectOriginal = (object?: ObjectDto): boolean => {
    return getIsStackerSharedObject(object) && object!.is_shared_original
}

export const getIsStackerSharedObjectCopy = (object?: ObjectDto): boolean => {
    return getIsStackerSharedObject(object) && !object!.is_shared_original
}

import React, { useCallback, useMemo } from 'react'

import {
    deleteRecords as originalDeleteRecords,
    updateRecord as originalUpdateRecord,
} from 'data/hooks/records/recordOperations'
import { RecordEditManagerContextProvider } from 'features/records/RecordEditManagerContextProvider'
import { useListViewRecords } from 'features/views/ListView/hooks/useListViewRecords'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useCardViewFilters } from './Filters/hooks/useCardViewFilters'
import { useAttributesFromView } from './hooks/useAttributesFromView'
import { useCardViewPagination } from './Pagination/hooks/useCardViewPagination'
import { useCardViewSearch } from './Search/hooks/useCardViewSearch'
import { useCardViewSort } from './Sort/hooks/useCardViewSort'
import { CardViewContext } from './useCardViewContext'

export type CardViewContextProviderProps = {}

export const CardViewContextProvider: React.FC<CardViewContextProviderProps> = ({ children }) => {
    const {
        object,
        stack,
        view,
        embeddedByField,
        embeddedByFieldValue,
        embeddedByRecord,
        isEmbedded,
        allFields,
        header,
    } = useListViewContext()

    const {
        hasFilters: hasInlineFilters,
        clearFilters: clearInlineFilters,
        inlineFilterType,
        availableInlineFilterFields,
    } = useCardViewFilters()

    const coverImageFieldApiName = view.options.coverImage?.id
    const coverImageField = allFields.find((field) => field.api_name === coverImageFieldApiName)

    const profileImageFieldApiName = view.options.profileImage?.fieldApiName
    const profileImageField = allFields.find((field) => field.api_name === profileImageFieldApiName)

    const eyebrowFieldSid = view.options.cardCardEyebrow?.fieldSid
    const eyebrowField = allFields.find((field) => field._sid === eyebrowFieldSid)
    const eyebrowFieldApiName = eyebrowField?.api_name

    const titleFieldSid = view.options.cardCardTitle?.fieldSid
    const titleField = allFields.find((field) => field._sid === titleFieldSid)
    const titleFieldApiName = titleField?.api_name

    const subtitleFieldSid = view.options.cardCardSubtitle?.fieldSid
    const subtitleField = allFields.find((field) => field._sid === subtitleFieldSid)
    const subtitleFieldApiName = subtitleField?.api_name

    const attributes = useAttributesFromView({ titleFieldSid })

    const additionalSearchFieldApiNames = useMemo(() => {
        const apiNames = new Set<string>()

        // Include card footer fields.
        const footerLeftFieldSid = view.options.cardFooter?.leftFieldSid
        if (footerLeftFieldSid && footerLeftFieldSid !== '_record_stats') {
            const field = allFields.find((field) => field._sid === footerLeftFieldSid)
            if (field) {
                apiNames.add(field.api_name)
            }
        }

        const footerRightFieldSid = view.options.cardFooter?.rightFieldSid
        if (footerRightFieldSid && footerRightFieldSid !== '_record_stats') {
            const field = allFields.find((field) => field._sid === footerRightFieldSid)
            if (field) {
                apiNames.add(field.api_name)
            }
        }

        // Include eyebrow field.
        if (eyebrowFieldApiName) {
            apiNames.add(eyebrowFieldApiName)
        }

        // Include title field.
        if (titleFieldApiName) {
            apiNames.add(titleFieldApiName)
        }

        // Include subtitle field.
        if (subtitleFieldApiName) {
            apiNames.add(subtitleFieldApiName)
        }

        return Array.from(apiNames)
    }, [
        allFields,
        view.options.cardFooter?.leftFieldSid,
        view.options.cardFooter?.rightFieldSid,
        titleFieldApiName,
        eyebrowFieldApiName,
        subtitleFieldApiName,
    ])

    const additionalFieldApiNames = useMemo(() => {
        const apiNames = new Set<string>(additionalSearchFieldApiNames)

        // Include cover image field.
        if (coverImageFieldApiName) {
            apiNames.add(coverImageFieldApiName)
        }

        // Include profile image field.
        if (profileImageFieldApiName) {
            apiNames.add(profileImageFieldApiName)
        }

        return Array.from(apiNames)
    }, [additionalSearchFieldApiNames, coverImageFieldApiName, profileImageFieldApiName])

    const {
        records,
        dereferencedRecords,
        recordCount = 0,
        retryFetchRecords,
        hasError,
        isLoading,
        isLoadingSlow: isFetchingSlow,
        effectiveFilters,
        includedFieldsApiNames,
    } = useListViewRecords({
        additionalFieldApiNames,
        additionalSearchFieldApiNames,
    })

    const { sortBy } = useCardViewSort()
    const { query, setQuery } = useCardViewSearch()

    const hasFilters = !!query || hasInlineFilters

    const requestFilters = useDeepEqualsMemoValue(effectiveFilters)
    const requestIncludedFields = useDeepEqualsMemoValue(includedFieldsApiNames)

    const clearFilters = useCallback(() => {
        setQuery('')
        clearInlineFilters()
    }, [setQuery, clearInlineFilters])

    // empty addRecord callback as card views don't need this
    const addRecord = useCallback(async (record: RecordDto) => record, [])

    const updateRecord = useCallback(
        async (recordId: string, patch: Partial<RecordDto>) => {
            await originalUpdateRecord(recordId, patch, requestIncludedFields, {
                deferStoreUpdate: true,
            })
        },
        [requestIncludedFields]
    )

    const deleteRecords = useCallback(
        async (recordIds: string[]) => {
            await originalDeleteRecords(object._sid, recordIds)
            await retryFetchRecords()
        },
        [object._sid, retryFetchRecords]
    )

    const labelStyle = view.options.cardLabelStyle

    const { pageIndex, pageSize, setPageIndex } = useCardViewPagination({
        recordCount,
        isLoading,
    })

    const titleSize = view.options.cardTitleSize ?? 'medium'
    const cardSize = view.options.cardCardSize ?? 'medium'

    const cardStyle = (coverImageField && view.options.cardCardStyle) || 'border'

    const onRecordClick = view.options.onRecordClick || 'preview'

    const value = useMemo(
        () => ({
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            currentPageIndex: pageIndex,
            pageSize,
            setCurrentPageIndex: setPageIndex,
            isLoading,
            isFetchingSlow,
            retryFetchRecords,
            hasError,
            sortBy,
            attributes,
            labelStyle,
            embeddedByField,
            embeddedByFieldValue,
            embeddedByRecord,
            view,
            hasFilters,
            inlineFilterType,
            availableInlineFilterFields,
            isEmbedded,
            allFields,
            requestFilters,
            requestIncludedFields,
            clearFilters,
            coverImageField,
            profileImageField,
            titleSize,
            titleField,
            eyebrowField,
            subtitleField,
            cardSize,
            cardStyle,
            header,
            onRecordClick,
        }),
        [
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            pageIndex,
            pageSize,
            setPageIndex,
            isLoading,
            isFetchingSlow,
            retryFetchRecords,
            hasError,
            sortBy,
            attributes,
            labelStyle,
            embeddedByField,
            embeddedByFieldValue,
            embeddedByRecord,
            view,
            hasFilters,
            inlineFilterType,
            availableInlineFilterFields,
            isEmbedded,
            allFields,
            requestFilters,
            requestIncludedFields,
            clearFilters,
            coverImageField,
            profileImageField,
            titleSize,
            titleField,
            eyebrowField,
            subtitleField,
            cardSize,
            cardStyle,
            header,
            onRecordClick,
        ]
    )

    return (
        <CardViewContext.Provider value={value}>
            <RecordEditManagerContextProvider
                records={records ?? []}
                addRecord={addRecord}
                updateRecord={updateRecord}
                deleteRecords={deleteRecords}
            >
                {children}
            </RecordEditManagerContextProvider>
        </CardViewContext.Provider>
    )
}

import React from 'react'

export type Panel = 'link' | 'image'
export type TextareaToolbarContextValue = {
    currentPanel: Panel | null
    setCurrentPanel: (value: Panel | null) => void
}
export const TextareaToolbarContext = React.createContext<TextareaToolbarContextValue>({
    currentPanel: null,
    setCurrentPanel: () => {},
})

export function useTextareaToolbarContext() {
    return React.useContext(TextareaToolbarContext)
}

import React, { forwardRef } from 'react'

import { Body } from 'ui/components/Text'

import * as Parts from './Divider.parts'

import { TextStyle } from './Divider.css'

type DividerRef = HTMLDivElement

type DividerProps = React.ComponentPropsWithoutRef<typeof Parts.Root>

export const Divider = forwardRef<DividerRef, DividerProps>(
    ({ type = 'default', variant = 'strong', ...props }, ref) => {
        return (
            <Parts.Root variant={variant} type={type} {...props} ref={ref}>
                <Parts.Separator
                    as={type === 'vertical' ? 'div' : 'hr'}
                    type={type}
                    variant={variant}
                />
                {type === 'or' && (
                    <>
                        <Body size="m" weight="medium" className={TextStyle}>
                            or
                        </Body>
                        <Parts.Separator as="hr" type={type} variant={variant} />
                    </>
                )}
            </Parts.Root>
        )
    }
)

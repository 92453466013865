export function getStackTheme(stack) {
    const { options, account_theme } = stack || {}

    const theme = options?.theme || {}

    theme.navColor = theme.navColor ?? account_theme?.navColor ?? 'dark'
    theme.brandColor = options?.theme?.brandColor || account_theme?.brandColor
    if (!theme.logo_icon) {
        theme.logo_icon = account_theme?.logo_icon
    }
    return theme
}

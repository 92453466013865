import React from 'react'

import { ListViewControlFieldInput } from 'features/views/ListView/ListViewControlFieldInput'
import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'

import { Box } from 'ui/components/Box'

type CardViewCardFooterControlsProps = {
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
    objectSid: string
}

export const CardViewCardFooterControls: React.FC<CardViewCardFooterControlsProps> = ({
    fields,
    config,
    setConfig,
    objectSid,
}) => {
    return (
        <ListViewControlItem label="Card footer" icon="PanelBottom">
            <Box maxWidth="full" minWidth="300px" flex flexDirection="column" gap="xl">
                <ListViewControlFieldInput
                    objectSid={objectSid}
                    label="Left area"
                    fields={fields}
                    value={config.cardFooter?.leftFieldSid}
                    displayOptions={config.cardFooter?.leftFieldDisplayOptions}
                    onChange={(value) => {
                        setConfig({
                            cardFooter: {
                                ...config.cardFooter,
                                leftFieldSid: value,
                            },
                        })
                    }}
                    onDisplayOptionsChange={(value) => {
                        setConfig({
                            cardFooter: {
                                ...config.cardFooter,
                                leftFieldDisplayOptions: value,
                            },
                        })
                    }}
                />
                <ListViewControlFieldInput
                    objectSid={objectSid}
                    label="Right area"
                    fields={fields}
                    value={config.cardFooter?.rightFieldSid}
                    displayOptions={config.cardFooter?.rightFieldDisplayOptions}
                    onChange={(value) => {
                        setConfig({
                            cardFooter: {
                                ...config.cardFooter,
                                rightFieldSid: value,
                            },
                        })
                    }}
                    onDisplayOptionsChange={(value) => {
                        setConfig({
                            cardFooter: {
                                ...config.cardFooter,
                                rightFieldDisplayOptions: value,
                            },
                        })
                    }}
                />
            </Box>
        </ListViewControlItem>
    )
}

import React, { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { css } from 'emotion'

import usePrevious from 'v2/ui/hooks/usePrevious'

import Box from './Box'
import Flex from './Flex'

// use this class to hide the overflow indicator
// as we still want to measure it
const transparentClass = css`
    visibility: hidden !important;
`

const Indicator = css`
    padding-right: 2px;
    padding-left: 4px;
    font-size: 0.75rem;
    color: #737684;
    flex-shrink: 0;
    flex-grow: 1;
`

export const OverflowList = memo(function OverflowList({
    style,
    children,
    maxVisible,
    renderIndicator,
    ...props
}) {
    const [visibleCount, setVisibleCount] = useState(children.length)
    const containerNode = useRef()
    const indicatorNode = useRef()
    const { ref: outerContainerNode, inView: isContainerShowing } = useInView()
    const previousChildren = usePrevious(children)
    const observer = useRef()
    const childrenNodes = useRef([])
    const initialized = useRef()

    // Called by the IntersectionObserver when the visibility of
    // any children has changed
    const visibleChanged = (entries) => {
        let overflowedAtIdx

        for (const entry of entries) {
            // If the child isn't intersecting with our container element
            // then it is overflowing. Note the earliest index of an overflown
            // child, and that gives us the number of visible items in the list.
            const idx = childrenNodes.current.indexOf(entry.target)
            if (!entry.isIntersecting) {
                if (!overflowedAtIdx || idx < overflowedAtIdx) {
                    overflowedAtIdx = idx
                }
                // Hide the item if it's not completely visible (we always want the first item visible)
                if (idx !== 0 && !!childrenNodes.current[idx]?.style) {
                    childrenNodes.current[idx].style.display = 'none'
                    childrenNodes.current[idx].style.visibility = 'hidden'
                }
            } else if (!!childrenNodes.current[idx]?.style) {
                childrenNodes.current[idx].style.display = ''
                childrenNodes.current[idx].style.visibility = ''
            }
        }
        setVisibleCount(overflowedAtIdx || childrenNodes.current.length)
    }

    const hiddenCount = children.length - visibleCount

    const childrenToRender = useMemo(() => {
        return maxVisible ? children.slice(0, maxVisible) : children
    }, [maxVisible, children])

    const childrenHaveChanged = previousChildren !== children
    useLayoutEffect(() => {
        // If we aren't even showing the container yet,
        // or we've aliready initialized and the children haven't changed
        // then we don't need to do anything
        if (!isContainerShowing || (initialized.current && !childrenHaveChanged)) return

        initialized.current = true
        setVisibleCount(children.length)
        // The threshold 1 here means that the item has to be completely visible
        observer.current = new IntersectionObserver(visibleChanged, {
            root: containerNode.current,
            threshold: 1,
        })
        childrenNodes.current.forEach((child, index) => {
            // This stops it from flashing too much when the list changes.
            // Only the first item will be shown initially and then it'll add in others if they fit
            if (!!child?.style) {
                if (index > 0) {
                    child.style.visibility = 'hidden'
                }

                child.style.display = ''
            }
        })
        childrenNodes.current.forEach((child) => {
            if (child) observer.current.observe(child)
        })
        return () => {
            observer?.current?.disconnect()
        }
    }, [childrenToRender, isContainerShowing, children.length, childrenHaveChanged])

    // Set the reference list to the right number of items when the childrenToRender list changes
    useEffect(() => {
        return () => {
            childrenNodes.current = childrenNodes.current.slice(0, childrenToRender.length)
        }
    }, [childrenToRender.length])

    useEffect(() => {
        return () => {
            observer?.current?.disconnect()
        }
    }, [])

    const defaultIndicator = <span>+{hiddenCount}</span>
    // We only render the specified max number of children. This is an optmization.
    // If the parent knows we will only ever be able to display max 5 items, but there are
    // 63 items in the list, no sense rendering all those extra ones.
    return (
        <Flex ref={outerContainerNode} wrap="nowrap" maxWidth="100%" style={style} {...props}>
            <Box
                ref={containerNode}
                display="flex"
                whiteSpace="nowrap"
                maxWidth="100%"
                maxHeight="100%"
                overflow="hidden"
                minWidth={0}
                flexShrink={1}
            >
                {childrenToRender.map((child, i) => (
                    <span
                        style={{ visibility: i === 0 ? '' : 'hidden' }}
                        key={i}
                        ref={(el) => (childrenNodes.current[i] = el)}
                    >
                        {/* This little span is our "visbility" checker and we insert one
                        around each child. When this indicater is visible in our container
                        then we know the child is visible  */}
                        {child}
                    </span>
                ))}
            </Box>
            <div
                className={` ${hiddenCount > 0 || transparentClass} ${Indicator}`}
                ref={indicatorNode}
            >
                {renderIndicator !== undefined ? renderIndicator(hiddenCount) : defaultIndicator}
            </div>
        </Flex>
    )
})

export default OverflowList

import React from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl, Urls } from 'app/UrlService'
import { refetchRoles } from 'data/hooks/roles'
import { refetchStacks } from 'data/hooks/stacks/stackOperations'
import { assertIsDefined } from 'data/utils/ts_utils'
import { importBundle } from 'features/bundles/bundlesApi'
import { OnboardingAppPreview } from 'features/Onboarding/OnboardingAppPreview'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import {
    Modal,
    ModalContent,
    ModalContentInner,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Body, Headline } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { bundleBuilder } from './bundles/bundleBuilder'
import { AiAppBuilderProvider } from './AiAppBuilderProvider'
import { RequirementsStep } from './RequirementsStep'
import { Step1Describe } from './Step1Describe'
import { useAiAppBuilder } from './useAiAppBuilder'

export function AiAppBuilderModal({
    isOpen,
    onOpenChange,
    stackOptions,
}: {
    isOpen: boolean
    onOpenChange: (open: boolean) => void
    stackOptions?: Partial<StackDto['options']>
}) {
    const showMobileBlock = useResponsiveValue({ mobile: true, desktop: false })
    if (!isOpen) {
        return null
    }

    if (showMobileBlock) {
        return (
            <Modal open onOpenChange={onOpenChange}>
                <ModalContent>
                    <ModalHeader
                        title="AI Builder"
                        subtitle="To use the AI builder, please log in on a tablet or computer."
                        showCloseButton={false}
                    />
                    <ModalFooter>
                        <ModalFooterButtonGroup>
                            <Button onClick={() => onOpenChange(false)}>OK</Button>
                        </ModalFooterButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }

    return (
        <Modal open onOpenChange={onOpenChange}>
            <AiAppBuilderProvider>
                <ModalContent
                    flex
                    column
                    style={{
                        width: '90%',
                        maxWidth: '1440px',
                        height: '90%',
                        border: 'none',
                        maxHeight: '800px',
                    }}
                >
                    <Contents onClose={() => onOpenChange(false)} stackOptions={stackOptions} />
                </ModalContent>
            </AiAppBuilderProvider>
        </Modal>
    )
}

function Contents({
    onClose,
    stackOptions,
}: {
    onClose: () => void
    stackOptions?: Partial<StackDto['options']>
}) {
    const { currentStep, bundle, setCurrentStep, generationFailed } = useAiAppBuilder()
    const history = useHistory()
    const headline =
        currentStep === 'describe' ? 'What are you building?' : 'What are you trying to track?'

    const stepNumber = currentStep === 'describe' ? 1 : 2
    const handleFinish = async () => {
        assertIsDefined(bundle)
        const preppedBundle = bundleBuilder(bundle)
        const result = (await importBundle({ bundle: preppedBundle, stackOptions })) as StackDto
        console.log('# Import result', result)
        await Promise.all([refetchStacks(), refetchRoles()])
        history.push(`${getUrl(Urls.Home, result)}`)
        onClose()
    }

    return (
        <>
            <ModalHeader
                title={'Create new app'}
                showCloseButton
                showBackButton
                onBackButtonClick={stepNumber > 1 ? () => setCurrentStep('describe') : undefined}
            />
            <ModalContentInner grow shrink>
                <Box flex height="full" pb="2xl">
                    <Box
                        flex
                        column
                        style={{ flexBasis: '40%' }}
                        height="full"
                        px="5xl"
                        justifyContent="space-between"
                        alignItems="stretch"
                        overflow="auto"
                    >
                        <Box flex column gap="3xl">
                            <Headline>{headline}</Headline>
                            {currentStep === 'describe' && <Step1Describe />}
                            {currentStep === 'requirements' && (
                                <RequirementsStep onMoveNext={handleFinish} />
                            )}
                        </Box>
                        <Progress steps={2} currentStep={stepNumber} />
                    </Box>
                    <Box
                        flex
                        column
                        center
                        justifyContent="center"
                        background="gray100"
                        shrink
                        style={{ flexBasis: '60%' }}
                        roundedRight="2xl"
                        overflow="hidden"
                    >
                        {generationFailed ? <GenerationFailed /> : <OnboardingAppPreview />}
                    </Box>
                </Box>
            </ModalContentInner>
        </>
    )
}

function GenerationFailed() {
    const { generateBundle } = useAiAppBuilder()
    return (
        <Box flex column gap="3xl" center justifyContent="center">
            <Icon name="AlertTriangle" size="4xl" opacity={0.5} />
            <Body size="l" maxWidth="400px">
                We couldn&apos;t automatically create an app from those requirements. Please try
                again.
            </Body>
            <Button onClick={generateBundle}>Try again</Button>
        </Box>
    )
}

function Progress({ steps, currentStep }: { steps: number; currentStep: number }) {
    return (
        <Box flex gap="m" center>
            <Box flex grow gap="xs">
                {Array.from({ length: steps }).map((_, i) => (
                    <Box
                        key={i}
                        height="2xs"
                        style={{ flexBasis: 100 / steps + '%' }}
                        background={i < currentStep ? 'theme950' : 'gray200'}
                    />
                ))}
            </Box>
            <Body size="s" color="textWeaker">
                {currentStep}/{steps}
            </Body>
        </Box>
    )
}

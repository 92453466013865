import React from 'react'

import { SimpleBundlePreview } from 'features/AiAppBuilder/components/SimpleBundlePreview/SimpleBundlePreview'
import { useAiAppBuilder } from 'features/AiAppBuilder/useAiAppBuilder'
import publicAsset from 'utils/publicAsset'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Progress } from 'ui/components/Progress'
import { Body } from 'ui/components/Text'

export function OnboardingAppPreview() {
    const { bundle, generationFailed, isGenerating, progress, currentStep } = useAiAppBuilder()

    const showPreview = currentStep !== 'describe' && !generationFailed
    const showProgress = isGenerating && currentStep !== 'describe'
    return (
        <Box
            flex
            column
            style={{
                flexBasis: '60%',
                backgroundColor: '#986EFF',
                backgroundImage: `url(${publicAsset('/static/media/app-preview-background.svg')})`,
                backgroundSize: 'contain',
                backgroundPosition: 'left',
            }}
            shrink
            width="full"
            maxWidth="full"
            height="full"
            grow
            justifyContent="center"
        >
            <Box grow flex column style={{ maxHeight: '700px' }} shrink>
                <Box
                    flex
                    column
                    width="full"
                    alignSelf="center"
                    justifyContent="center"
                    alignItems="center"
                    mb="xl"
                    p="l"
                    roundedLeft="m"
                    style={{
                        visibility: showProgress ? 'visible' : 'hidden',
                    }}
                    color="white00"
                >
                    <Icon name="Loader" size="3xl" mb="l" className="spin" />
                    <Body size="l" weight="bold" mb="s">
                        Generating preview:
                    </Body>
                    <Body size="l">{progress.status}</Body>
                    <Progress
                        mt="l"
                        value={progress.progress}
                        color="purple900"
                        width="full"
                        style={{ width: '100%', maxWidth: '50%' }}
                        rounded="s"
                    ></Progress>
                </Box>

                <Box pl="8xl" pb="2xl" flex column gap="l" justifyContent="center" shrink grow>
                    <Box height="full" maxHeight="600px">
                        {(!bundle || currentStep === 'describe') && (
                            <Box
                                style={{ maxHeight: '100%', width: '100%', height: '100%' }}
                                overflow="hidden"
                                position="relative"
                                boxShadow="l"
                                background="white00"
                                roundedLeft="xl"
                            >
                                <Box
                                    as="img"
                                    style={{
                                        maxHeight: '100%',
                                        height: '100%',
                                        marginRight: '-30px',
                                        objectFit: 'contain',
                                        maxWidth: 'none',
                                    }}
                                    src={publicAsset('/static/media/app-preview-skeleton.svg')}
                                />
                            </Box>
                        )}
                        {bundle && showPreview && <SimpleBundlePreview bundle={bundle} />}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

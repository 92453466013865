import getObject from 'data/utils/getObject'
import { FieldPatch } from 'features/admin/fields/common'

// Get the linked object name from a field
export const getLinkedFieldObjectName = (field: FieldPatch): string | undefined => {
    // Get the linked object _sid
    const lookupTarget = field?.link_target_object_id
    if (!lookupTarget) return

    // Get the linked object
    const linkedObject = getObject(lookupTarget)
    if (linkedObject) {
        // Return the linked object name
        return linkedObject.name
    }
}

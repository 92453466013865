import React, { useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Link, useLocation } from 'react-router-dom'

import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { getUrl } from 'app/UrlService'
import { DereferencedField } from 'data/hooks/activities/types'
import { ActivityType, RelatedToType } from 'data/hooks/activityTypes'
import { useStacks } from 'data/hooks/stacks'
import { AttachmentGallery } from 'features/attachments/AttachmentGallery'
import { useMentions } from 'features/collaboration/Mentions'
import { formatDueDate } from 'features/tasks/dueDateUtils'
import { TipTapEditor } from 'features/tiptap/TipTapEditor'
import { UserDisplay } from 'features/users/shared/UserDisplay'
import isRichTextField from 'utils/isRichTextField'
import { ensureArray } from 'utils/utils'

import { RichTextEditor, Tag } from 'v2/ui'
import Attribute from 'v2/ui/components/Attribute/Attribute'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Button } from 'ui/components/Button'

import { ActivityRichTextarea } from './ActivityRichTextarea'
import { RecordLink } from './RecordLink'
import { CommentContent } from './types'
import { useActivityFeedContext } from './useActivityFeedContext'
import { useMentionUsersForStack } from './useMentionUsersForStack'

import { CollapsedContainerStyle, ExpandButtonStyle } from './ActivityContentStyles.css'

type ActivityContentProps = {
    activity: ActivityDto
    startCollapsed?: boolean
    collapsedHeight?: string
    compact?: boolean
    showLocationPreview?: boolean
}
export function ActivityContent({
    activity,
    startCollapsed,
    collapsedHeight,
    compact,
    showLocationPreview,
}: ActivityContentProps) {
    const { users: feedUsers, fields, records } = useActivityFeedContext()
    const mentionUsers = useMentionUsersForStack(activity.stack_id)
    const allUsers = [...mentionUsers, ...feedUsers]

    const mentions = useMentions(allUsers)
    const plugins = useMemo(() => [mentions], [mentions])

    switch (activity.type) {
        case ActivityType.RecordCreated:
        case ActivityType.RecordUpdated:
            return (
                <ContentContainer
                    my="m"
                    flex
                    column
                    stretch
                    startCollapsed={startCollapsed}
                    collapsedHeight={collapsedHeight}
                >
                    <Box display="table">
                        {Object.entries(activity.content).map(([api_name, value]) => {
                            const field =
                                fields?.[activity.stack_id]?.[activity.object_id]?.[api_name]
                            if (!field || field.type === 'user_ref') return null
                            return (
                                <Box key={api_name} display="table-row">
                                    <Box
                                        display="table-cell"
                                        pr="l"
                                        color="textWeaker"
                                        py="xs"
                                        width="xs"
                                        whiteSpace="nowrap"
                                    >
                                        {field.label}
                                    </Box>
                                    <Box display="table-cell" py="xs">
                                        <ChangedFieldValue
                                            field={field}
                                            value={value}
                                            stackId={activity.stack_id}
                                        />
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </ContentContainer>
            )
        case ActivityType.Comment:
            const message = (activity.content || {}) as CommentContent
            const formatUnknown = message.format !== 'slate' && message.format !== 'tiptap'

            return (
                <>
                    {message.format === 'slate' && !isEmpty(message.message) && (
                        <ContentContainer
                            startCollapsed={startCollapsed}
                            collapsedHeight={collapsedHeight}
                        >
                            <ActivityRichTextarea
                                // @ts-ignore
                                readOnly
                                plugins={plugins}
                                value={message.message}
                                placeholder=""
                                linkify
                            />
                        </ContentContainer>
                    )}

                    {message.format === 'tiptap' && !isEmpty(message.message) && (
                        <>
                            {showLocationPreview && message.locationPreview && (
                                <LocationPreview
                                    locationPreview={message.locationPreview}
                                    activity={activity}
                                    mt="m"
                                />
                            )}
                            <ContentContainer
                                startCollapsed={startCollapsed}
                                collapsedHeight={collapsedHeight}
                            >
                                <TipTapEditor
                                    readOnly
                                    content={message.message ?? null}
                                    dereferencedRecords={records}
                                />
                            </ContentContainer>
                        </>
                    )}

                    {formatUnknown && (
                        <ContentContainer
                            style={{ whiteSpace: 'pre-wrap' }}
                            startCollapsed={startCollapsed}
                            collapsedHeight={collapsedHeight}
                        >
                            {activity.content?.text ?? activity.content?.plainTextMessage ?? null}
                        </ContentContainer>
                    )}

                    {message.attachments && message.attachments.length > 0 && (
                        <>
                            <AttachmentGallery
                                size={compact ? 'md' : 'lg'}
                                attachments={message.attachments}
                                my="m"
                            />
                        </>
                    )}
                </>
            )

        case ActivityType.TaskDueDateChanged: {
            const formattedDate = activity.content?.due_at
                ? formatDueDate(activity.content.due_at)
                : undefined

            return (
                <ContentContainer
                    my="m"
                    flex
                    column
                    stretch
                    startCollapsed={startCollapsed}
                    collapsedHeight={collapsedHeight}
                >
                    <Box display="table">
                        <Box display="table-row">
                            <Box
                                display="table-cell"
                                pr="l"
                                color="textWeaker"
                                py="xs"
                                width="xs"
                                whiteSpace="nowrap"
                            >
                                Due date
                            </Box>
                            <Box display="table-cell" py="xs">
                                <Box as="span" color={formattedDate ? 'text' : 'textWeaker'}>
                                    {formattedDate ? formattedDate : 'none'}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ContentContainer>
            )
        }

        case ActivityType.TaskAssigneesChanged: {
            const noAssignees =
                Array.isArray(activity.content?.assignees) && !activity.content?.assignees.length

            const assignee = activity.content?.assignees?.[0]
            const user = assignee ? allUsers.find((user) => user._sid === assignee) : undefined

            return (
                <ContentContainer
                    my="m"
                    flex
                    column
                    stretch
                    startCollapsed={startCollapsed}
                    collapsedHeight={collapsedHeight}
                >
                    <Box display="table">
                        <Box display="table-row">
                            <Box
                                display="table-cell"
                                pr="l"
                                color="textWeaker"
                                py="xs"
                                width="xs"
                                whiteSpace="nowrap"
                            >
                                Assignee
                            </Box>
                            <Box display="table-cell" verticalAlign="middle">
                                {user ? (
                                    <UserDisplay user={user} />
                                ) : (
                                    <Box as="span" color="textWeaker">
                                        {noAssignees ? 'none' : 'unknown user'}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </ContentContainer>
            )
        }

        default:
            return null
    }
}

function ContentContainer({
    startCollapsed,
    collapsedHeight = '2.5rem',
    children,
    ...props
}: BoxProps & {
    children: React.ReactNode
    startCollapsed?: boolean
    collapsedHeight?: any
}) {
    const [isCollapsed, setIsCollapsed] = useState(startCollapsed)
    const { ref: visibleRef, inView } = useInView()

    const isContentCutoff = !inView && isCollapsed
    return (
        <Box
            my="m"
            {...props}
            position="relative"
            overflow="hidden"
            className={classNames({
                [CollapsedContainerStyle]: isContentCutoff,
            })}
            style={{ maxHeight: isCollapsed ? collapsedHeight : undefined }}
            fontSize="bodyM"
        >
            {children}
            <div ref={visibleRef}></div>
            {isContentCutoff && (
                <Button
                    variant="ghost"
                    className={ExpandButtonStyle}
                    onClick={() => setIsCollapsed((v) => !v)}
                    size="xs"
                >
                    show more
                </Button>
            )}
        </Box>
    )
}

type ChangedFieldValueProps = {
    field: DereferencedField
    value: any
    stackId: string
}
function ChangedFieldValue({ field, value, stackId }: ChangedFieldValueProps) {
    const [hideLongText, setHideLongText] = useState(true)

    switch (field.type) {
        case 'lookup':
            return (
                <Tag>
                    <RecordLink
                        recordId={value}
                        objectId={field.link_target_object_id ?? ''}
                        stackId={stackId}
                    />
                </Tag>
            )
        case 'multi_lookup':
            return (
                <>
                    {ensureArray(value as string[]).map((v) => (
                        <Tag key={v}>
                            <RecordLink
                                recordId={v}
                                objectId={field.link_target_object_id ?? ''}
                                stackId={stackId}
                            />
                        </Tag>
                    ))}
                </>
            )
        case 'string':
            if (isRichTextField(field as FieldDto)) {
                // hide long rich text values for perf reasons.
                if (value?.length > 500 && hideLongText) {
                    return (
                        <Button variant="ghost" size="xs" onClick={() => setHideLongText(false)}>
                            Show value
                        </Button>
                    )
                } else {
                    return (
                        <RichTextEditor
                            showRichTextEditor={value !== '-'}
                            background="transparent"
                            // convertToMarkdown={convertToMarkdown}
                        >
                            {value}
                        </RichTextEditor>
                    )
                }
            }
            return <Attribute field={field}>{value}</Attribute>

        default:
            return <Attribute field={field}>{value}</Attribute>
    }
}

type LocationPreviewProps = React.ComponentPropsWithoutRef<typeof Box> & {
    locationPreview: string
    activity: ActivityDto
}

const LocationPreview: React.FC<LocationPreviewProps> = ({
    locationPreview,
    activity,
    ...props
}) => {
    const location = useLocation()

    const previewUrl = useLocationPreviewUrl(activity)
    const isOnSamePage = isPathOnSamePage(previewUrl, location.pathname)

    return (
        <Box
            as={previewUrl ? Link : undefined}
            to={previewUrl}
            trim
            color="textWeaker"
            borderLeftWidth={3}
            borderStyle="base"
            borderColor="admin300"
            pl="m"
            py="xs"
            replace={isOnSamePage}
            {...props}
        >
            {locationPreview}
        </Box>
    )
}

function useLocationPreviewUrl(activity: ActivityDto): string {
    const { data: stacks } = useStacks()
    const { documents, records, objects } = useActivityFeedContext()

    switch (activity.related_to_type) {
        case RelatedToType.Record: {
            const recordId = activity.related_to
            const stackId = activity.stack_id
            const objectId = activity.object_id
            const location = activity.related_to_location

            const record = records?.find((r) => r._sid === recordId)
            const stack = stacks?.find((s) => s._sid === stackId)
            const object = objects[stackId]?.[objectId]

            if (!record || !stack || !object) return ''

            let url = getUrl(`${object.url}/view/${record?._sid}`, stack)
            if (location) {
                url += `#${encodeURIComponent(location)}`
            }

            return url
        }

        case RelatedToType.Document: {
            const documentId = Number(activity.related_to)
            const stackId = activity.stack_id
            const location = activity.related_to_location

            const document = documents?.find((d) => d.auto_id === documentId)
            const stack = stacks?.find((s) => s._sid === stackId)
            if (!document || !stack) return ''

            let url = getUrl(`/docs/${document.auto_id}`, stack)
            if (location) {
                url += `#${encodeURIComponent(location)}`
            }

            return url
        }
    }

    return ''
}

function isPathOnSamePage(path: string, currentPath: string): boolean {
    if (!path) return false
    if (!path.startsWith(currentPath)) return false

    const extra = path.slice(currentPath.length)
    if (extra.startsWith('#')) return true
    if (extra.startsWith('?')) return true

    return false
}

import React from 'react'

import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useAppUsersRoleDropdownState } from './hooks/useAppUsersRoleDropdownState'
import { AppUsersRevokeAccessModal } from './AppUsersRevokeAccessModal'
import { AppGroup, AppRole, AppUser } from './types'

import { AppUsersRoleAdminOptionStyle } from './AppUsers.css'

type AppUsersRoleDropdownProps = {
    roles: AppRole[]
    group?: AppGroup
    user?: AppUser
}

export const AppUsersRoleDropdown: React.FC<AppUsersRoleDropdownProps> = ({
    roles,
    group,
    user,
}) => {
    const {
        roleOptions,
        label,
        isCurrentRoleAdmin,
        value,
        onChange,
        isRevokeAccessModalOpen,
        onRevokeAccessModalOpenChange,
        handleRevokeAccess,
        isDropdownOpen,
        onDropdownOpenChange,
    } = useAppUsersRoleDropdownState({
        roles,
        group,
        user,
    })

    return (
        <>
            <Dropdown modal={false} open={isDropdownOpen} onOpenChange={onDropdownOpenChange}>
                <DropdownButton
                    variant="ghost"
                    size="xs"
                    endIcon={{ name: 'ChevronDown' }}
                    startIcon={isCurrentRoleAdmin ? { name: 'Crown' } : undefined}
                    className={isCurrentRoleAdmin ? AppUsersRoleAdminOptionStyle : undefined}
                >
                    {label}
                </DropdownButton>
                <DropdownContent align="end" onClick={stopPropagation}>
                    {roleOptions.map((option) => (
                        <DropdownItem
                            key={option.value}
                            label={option.label}
                            multiSelect
                            checked={value === option.value}
                            onCheckedChange={(checked) => {
                                if (checked) onChange(option.value)
                            }}
                            startIcon={{ name: option.icon }}
                            variant={option.variant}
                            closeOnSelect={true}
                            disabled={roleOptions.length === 1}
                        />
                    ))}
                    <DropdownItem
                        label="Revoke access"
                        variant="destructive"
                        startIcon={{ name: 'LogOut' }}
                        onClick={() => queueMicrotask(() => onRevokeAccessModalOpenChange(true))}
                    />
                </DropdownContent>
            </Dropdown>
            <AppUsersRevokeAccessModal
                isOpen={isRevokeAccessModalOpen}
                onOpenChange={onRevokeAccessModalOpenChange}
                group={group}
                user={user}
                onConfirm={handleRevokeAccess}
            />
        </>
    )
}

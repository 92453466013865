import React from 'react'

import { Button } from 'ui/components/Button'

import { useListHeaderAdvancedFilterControlState } from './hooks/useListHeaderAdvancedFilterControlState'

type ListHeaderAdvancedFilterControlProps = React.ComponentPropsWithoutRef<typeof Button> & {
    isActive?: boolean
}

export const ListHeaderAdvancedFilterControl: React.FC<ListHeaderAdvancedFilterControlProps> = ({
    isActive,
    ...props
}) => {
    const { hasFilters, label } = useListHeaderAdvancedFilterControlState()

    return (
        <Button
            variant={hasFilters && !isActive ? 'activated' : 'ghost'}
            data-state={isActive ? 'active' : 'inactive'}
            size="s"
            aria-label="Filter table by column"
            startIcon={{
                name: 'ListFilter',
            }}
            {...props}
        >
            {label}
        </Button>
    )
}

/* eslint-disable simple-import-sort/imports -- Disabled to avoid circular dependencies, remove when file is removed from .freezircularDeps */

import React, { Component, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import cx from 'classnames'
import RenderBlockTypeAttributes from 'features/pages/blocks/settings/attributes/RenderBlockTypeAttributes'
import { withRouter } from 'react-router-dom'
import { ConditionalWrapper, Dropdown, Flex, Icon as IconV2, Modal } from 'v2/ui'
import { DraggableAndDroppable } from 'features/pages/editor/dnd/DraggableAndDroppable'
import { DropZone } from 'features/pages/editor/dnd/DropZone'
import { getBlockByType } from 'v2/blocks/blockTypes'
import BlockVisibility from 'features/pages/blocks/display/BlockVisibility'
import { evaluateFormulas } from 'features/pages/blocks/settings/formulas'
import { getInnerStyle, getOuterStyle } from 'features/pages/blocks/styles/splitStyles'
import { LayoutObjectEditor } from 'features/utils/LayoutObjectEditor'
import get from 'lodash/get'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { WithObject } from 'data/wrappers/WithObjectComponent'
import Button from 'ui/deprecated/atoms/Button'
import { isEqual } from 'lodash'
import { BlockLoader } from 'v2/blocks/BlockLoader'

import BlockLabelTooltip from './BlockLabelTooltip'

class UnRoutedBlock extends Component {
    /*
        Block takes the following props:
            - Block definition, the JSON of the block
            - Block context
                - Static context (i.e. stuff from the page, theme)
                - Shared block state (i.e. other blocks' properties/methods)
                - Callback for setting its own properties/methods, and for registering for updates to new ones
            - Editor functionality (e.g. methods for selecting, moving block etc.)
        It then renders in two separate bits
            +------WRAPPER-----+
            |                  |
            |  BLOCK CONTENTS  |
            |                  |
            +------------------+
        The wrapper handles
            - Creating the div the block sits in
            - Taking the "outer styles" that apply to that div and applying them
            - Allowing that to be selectable/draggable/droppable
            - Displaying the block's name + controls when selected
        The block contents are rendered based on the block's type, and are passed:
            - The original block definition
            - The block's context
            - Editor functionality
            - Utility methods from this file, including
                - Evaluate formuals
                - Run actions
        * * *
        As well as that, the block inspects the attributes and styles from the block's definition,
        and subscribes to the shared block state for any shared block state in merge tags
        It also renders the block's style, attribute and actions update panels, rendered into
        a portal in the editor
    */

    state = {
        visibleAttributes: undefined,
        layoutEditorCloseOverride: undefined,
    }

    static contextType = LayoutEditorContext

    render() {
        // console.count("[BLOCK] Render")
        const block = this.renderBlock()
        const BlockContextProvider = this.getBlockContextProvider()
        return this.renderWrapper(block, BlockContextProvider)
    }

    updateAttributes = (key, value) =>
        this.props.context.editor.actions.updateAttributes(this.props.block.id, key, value)

    renderDropZone = ({ children }) => {
        const { block, context, renderDropZone } = this.props

        const dropZoneProps = {
            payload: { id: block.id },
            children,
        }

        if (renderDropZone) {
            return renderDropZone({
                dropZoneProps,
                context,
                block,
            })
        }

        return <DropZone {...dropZoneProps} />
    }

    getBlockContextProvider = () => {
        const blockType = this.getBlockType()
        return blockType.contextProvider
    }

    renderBlock = () => {
        // Here we get the block's element by its type,
        // build out the utility props we will pass down to it
        // and  render it
        const { block, context } = this.props
        const blockType = this.getBlockType()
        const BlockTypeClass = blockType.blockElement
        const { innerStyles, outerStyles } = this.getStyles()

        let computedStyle = innerStyles
        // If this is a modal-type block, the block itself handles its
        // inner and outer styles
        if (BlockTypeClass.isModalType) {
            computedStyle = { ...innerStyles, ...outerStyles }
        }

        // Build the utility props
        const extraProps = {
            attrs: block.config.attributes,
            computedStyle,
            evaluateFormulas: evaluateFormulas(this.props.context),
            ChildBlocks: this.ChildBlocks,
            updateAttributes: this.updateAttributes,
            selected: this.isSelected(),
            selectBlock: () => context.editor.actions.selectBlock(block.id),
            DropZone: this.renderDropZone,
        }

        return <BlockTypeClass block={block} context={context} {...extraProps} />
    }

    renderWrapper = (children, BlockContextProvider) => {
        /*
           This wrapper is the part that handles laying out the block on the page,
           and selecting/dragging and dropping etc. when editing
           It also conditionally doesn't display a block based on the block visibility
        */
        const { block, context } = this.props
        const { innerStyles, outerStyles } = this.getStyles()
        const blockTypeClass = this.getBlockType().blockElement

        // We want to ignore the "locked" flag on "gridcard_0" as that used to be our
        // primary container that was locked. However, in the new layout system
        // users should be able to remove that card if they want.
        const isBlockLocked = block.config.locked && block.id !== 'gridcard_0'

        let styles = outerStyles
        if (block.type === 'container') {
            // Seems to be a necessary hack to keep compatibility with the old engine
            const direction = this.getContentsDirection()
            styles = {
                flexDirection: direction,
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                display: 'flex',
                ...outerStyles,
                ...innerStyles,
                maxWidth: '100%',
            }
        }
        if (block.type === 'gridcard') {
            // This allows the card to grow within its wrapper, even if there's only a min-height set.
            styles = {
                flexDirection: 'column',
                display: 'flex',
                ...outerStyles,
                ...innerStyles,
            }
        }
        if (block.type === 'view') {
            styles = {
                flexDirection: 'column',
                display: 'flex',
                ...outerStyles,
                ...innerStyles,
            }
        }

        if (block.type === 'attribute') {
            // Under certain circumstances (e.g. disbled fields) we don't want to show
            // fields on the layout
            let fieldId = block.config.attributes.fieldId

            if (context.isFieldDisabled && context.isFieldDisabled(fieldId)) {
                styles = {
                    display: 'none',
                    ...outerStyles,
                    ...innerStyles,
                }
            }
        }

        // If it's a modal type block then we only render a small placeholder most of the time
        if (blockTypeClass.isModalType) styles = {}

        const removeLayoutEditorCloseOverride = () =>
            this.setState((s) => ({ ...s, layoutEditorCloseOverride: undefined }))

        const setLayoutEditorCloseOverride = (layoutEditorCloseOverride) =>
            this.setState((s) => ({ ...s, layoutEditorCloseOverride }))

        const selectBlock = (e) => {
            if (isBlockLocked) return

            // Close the config editor in the menu if the selected block has no attributes
            if (get(this.context, 'activeEditor') && !this.componentHasAttributes(block)) {
                this.context.closeEditor()
            }
            context.editor.actions.selectBlock(block.id)

            e?.stopPropagation()
        }

        const isSelected = this.isSelected()

        // If the block has a field id attached to it, then add this into the class name
        const getFieldClass = () => {
            let fieldId =
                block?.config?.attributes?.field?.fieldId || block?.config?.attributes?.fieldId
            if (!fieldId || typeof fieldId !== 'string') return ''
            return `${block.type}-block-for-${fieldId.split('.').pop()}`
        }

        const isDisabled =
            this.props.determineIsBlockDisabled?.(block, context) ?? !context.editor.isEditing

        const isLoading = context.view?.isLoading

        return (
            <>
                <BlockVisibility block={block} context={context}>
                    <BlockLoader block={block} isLoading={isLoading}>
                        <DraggableAndDroppable
                            disabled={isDisabled}
                            disableDrag={isBlockLocked}
                            direction={context.direction}
                            onDrop={this.props.context.editor.actions.onDrop}
                            payload={{
                                id: block.id,
                                position: this.props.position,
                                direction: context.direction,
                            }}
                        >
                            {({ isDragging, isOver }) => (
                                <div
                                    className={cx(
                                        'block',
                                        `block-${block.id}`,
                                        `${block.type}-block`,
                                        getFieldClass(),
                                        {
                                            isSelected,
                                            isOver,
                                            isDragging,
                                            inRow: context.direction === 'row',
                                            inColumn: context.direction === 'column',
                                        }
                                    )}
                                    style={styles}
                                    onClick={context.editor.isEditing ? selectBlock : null}
                                    onMouseOver={($event) => {
                                        if (context.editor.isEditing && !isBlockLocked) {
                                            context.editor.actions.onHover(block.id)
                                            return $event.stopPropagation()
                                        }
                                    }}
                                    aria-live="polite"
                                    aria-busy={isLoading}
                                >
                                    <ConditionalWrapper
                                        condition={!!BlockContextProvider}
                                        wrapper={(children) => (
                                            <BlockContextProvider
                                                blockId={block.id}
                                                setLayoutEditorCloseOverride={
                                                    setLayoutEditorCloseOverride
                                                }
                                                removeLayoutEditorCloseOverride={
                                                    removeLayoutEditorCloseOverride
                                                }
                                                setVisibleAttributes={(attrs) =>
                                                    this.setState({ visibleAttributes: attrs })
                                                }
                                            >
                                                {children}
                                            </BlockContextProvider>
                                        )}
                                    >
                                        {context.editor.isEditing && this.getBlockControls()}
                                        {children}
                                        {this.getModal()}
                                    </ConditionalWrapper>
                                </div>
                            )}
                        </DraggableAndDroppable>
                    </BlockLoader>
                </BlockVisibility>
            </>
        )
    }

    // <Draggable
    //                     key={childBlock.id || index}
    //                     id={childBlock.id}
    //                     position={index}
    //                     onDrop={this.props.context.editor.actions.onDrop}
    //                 >
    //                     {({ getRootProps, isDragging }) => (

    isSelected = () => {
        return this.props.context.editor.selected === this.props.block.id
    }

    getBlockType = () => {
        const { block } = this.props
        return getBlockByType(block.type)
    }

    onEditorClosed = () => {
        if (this.isSelected()) {
            this.props.context.editor.actions.deselectBlock()
        }
    }

    getModal = () => {
        const type = this.getBlockType()
        const { block } = this.props
        const context = { ...this.props.context, block: block }

        const renderBlockTypeAttributes = (title) => (
            <LayoutObjectEditor
                context={context}
                title={title || type.label}
                isOpen={this.componentHasAttributes(this.props.block) && this.isSelected()}
                onClose={this.onEditorClosed}
                onCloseOverride={this.state.layoutEditorCloseOverride}
                getBlockByType={getBlockByType}
                editorId={block.id}
            >
                <RenderBlockTypeAttributes
                    block={block}
                    onlyVisibleAttributes={this.state.visibleAttributes}
                    context={context}
                    getBlockByType={getBlockByType}
                    simpleOnly
                />
            </LayoutObjectEditor>
        )

        // show the field name as the title rather than 'attribute'
        if (block.type === 'attribute') {
            const attrs = get(block, 'config.attributes')

            if (attrs) {
                return (
                    <WithObject objectId={attrs.objectId}>
                        {({ object }) => {
                            const field = object.fields.find(
                                (field) => field._sid === attrs.fieldId
                            )
                            return renderBlockTypeAttributes(get(field, 'label'))
                        }}
                    </WithObject>
                )
            }
        }

        return renderBlockTypeAttributes()
    }

    ChildBlocks = ({ blocks, extraContext }) => {
        /*
            This is a utility component that lets a block render its children in the right place
        */
        extraContext = extraContext || {}
        const direction = this.getContentsDirection()
        const childBlocks = blocks || this.props.block.childBlocks

        if (!childBlocks || !childBlocks.length) {
            return this.renderDropZone({ children: this.props.children })
        }

        return (
            <>
                {(childBlocks || []).map((childBlock, index) => (
                    <Block
                        position={index}
                        key={childBlock.id}
                        block={childBlock}
                        determineIsBlockDisabled={this.props.determineIsBlockDisabled}
                        renderDropZone={this.props.renderDropZone}
                        context={{
                            ...this.props.context,
                            direction,
                            ...extraContext,
                        }}
                    />
                ))}
            </>
        )
    }

    getStyles = () => {
        /*
            Compute the inner and outer styles for use by the block content and wrapper, respectively
        */
        const { block, context } = this.props
        const evalFormulas = evaluateFormulas(context)
        const blockType = this.getBlockType()
        const innerStyles = getInnerStyle(
            // Work out the style that can be used within the block
            block.config.style.extraStyles,
            block.config.style,
            context.deviceType,
            block.config.attributes.direction || context.direction,
            blockType.blockElement.childStyles
        )
        const outerStyles = getOuterStyle(
            // Work out which styles are used for the wrapper
            block.config.style.extraStyles,
            block.config.style,
            context.deviceType,
            block.config.attributes.direction || context.direction,
            blockType.blockElement.childStyles
        )

        // Allow a block to set its own grid width
        if (block.config.attributes.fullWidth) {
            outerStyles.gridColumn = '1 / -1'
        }

        return {
            innerStyles: evalFormulas(innerStyles),
            outerStyles: evalFormulas(outerStyles),
        }
    }

    getContentsDirection = () => {
        const { context, block } = this.props
        const { innerStyles } = this.getStyles()
        return innerStyles.flexDirection || block.config.attributes.direction || context.direction
    }

    subscribeToPropertyStore = () => {
        // Find any time we use a global property and subscribe to the store with it
        const vars = JSON.stringify(this.props.block.config)
        const re = /block\.[a-z0-9_]+\.property\.[a-z0-9_]+/g
        const matches = vars.match(re)
        if (matches) {
            const subscriptions = matches.map((match) => match.slice(6)) // Remove "block."
            this.props.context.propertyStore.subscribe(this.props.block.id, subscriptions, () => {
                // console.count(`[BLOCK] ${this.props.block.id} Updated because of subscriptions`)
                // console.log("[BLOCK] Property store right now", this.props.context.propertyStore)
                this.forceUpdate()
            })
        }
    }

    getBlockControls = () => {
        const { block, context } = this.props
        return (
            <BlockControls className="block-controls">
                {!this.props.context.hide_block_labels && (
                    <BlockLabel className="block-label block-name">
                        {block.config.attributes.label || block.id}
                    </BlockLabel>
                )}
                <BlockLabelTooltip
                    icon="longArrowUp"
                    tooltip="Move Up"
                    onClick={() => context.editor.actions.moveBlockUp(block.id)}
                />
                <BlockLabelTooltip
                    icon="longArrowDown"
                    tooltip="Move Down"
                    onClick={() => context.editor.actions.moveBlockDown(block.id)}
                />
                <BlockLabelTooltip
                    icon="faClone"
                    tooltip="Duplicate"
                    onClick={() => {
                        context.editor.actions.copySelected()
                        context.editor.actions.pasteAtSelected()
                    }}
                />
                <BlockLabelTooltip
                    icon="trash"
                    tooltip="Delete"
                    onClick={() => {
                        this.context.closeEditor()
                        context.editor.actions.removeBlock(block.id)
                    }}
                    isDelete
                />
                <BlockLabelMoveBlock
                    context={context}
                    block={block}
                    stackOptions={this.props.stackOptions}
                />
            </BlockControls>
        )
    }

    componentHasAttributes = (block) => {
        return !['string:v2', 'container', 'gridcard'].includes(block.type)
    }

    unsubscribeFromPropertyStore = () => {
        // Find any time we use a global property and subscribe to the store with it

        this.props.context.propertyStore.unsubscribe(this.props.block.id)
    }

    UNSAFE_componentWillReceiveProps = () => {
        // console.count(
        //     `[BLOCK] ${this.props.block.id} Updating because of parent ${
        //         this.props.isOuter ? "Outer" : "x"
        //     }`
        // )
    }

    componentDidMount() {
        // console.count(
        //     `[BLOCK] ${this.props.block.id} Initial render ${this.props.isOuter ? "Outer" : "x"}`
        // )
        this.subscribeToPropertyStore()
    }

    componentWillUnmount() {
        if (this.componentHasAttributes(this.props.block) && this.isSelected()) {
            this.context.closeEditor()
        }
        this.unsubscribeFromPropertyStore()
    }

    shouldComponentUpdate(nextProps) {
        // console.count('Block should component update')
        // If we're editing, then we're very happy to render
        if (nextProps.context.editor.isEditing) return true
        // Only update on meaningful changes. Sound expensive, but it prevents a lot of recursive checking!
        // We want to compare the contexts without the property store
        // eslint-disable-next-line unused-imports/no-unused-vars
        let { propertyStore: junk0, ...newContext } = nextProps.context
        // eslint-disable-next-line unused-imports/no-unused-vars
        let { propertyStore: junk1, ...oldContext } = this.props.context
        return !isEqual(nextProps.block, this.props.block) || !isEqual(newContext, oldContext)
    }
}

function BlockLabelMoveBlock({ context }) {
    const [isModalOpen, setModalOpen] = useState()
    const [selectedTab, selectTab] = useState()

    // available on DetailView
    const { viewConfig, viewType } = context

    const activeTabs = useMemo(() => {
        return (
            viewConfig?.tabs?.filter(
                (tab) =>
                    tab.active && tab.type !== 'activity' && '#' + tab.id !== window.location.hash
            ) ?? []
        )
    }, [viewConfig?.tabs])

    if (activeTabs.length === 0) return null
    if (viewType !== 'detail') return null

    return (
        <>
            <BlockLabel
                className="block-label"
                onClick={() => {
                    setModalOpen(true)
                }}
            >
                <IconV2 icon="exchange" margin="none" />
            </BlockLabel>
            {isModalOpen && (
                <Modal isOpen onClose={() => setModalOpen(false)} title="Move to another tab">
                    <Dropdown
                        options={activeTabs.map(({ name, id }) => ({ id, label: name }))}
                        onChange={selectTab}
                        value={selectedTab}
                        returnObject
                    />

                    <Flex mt={5} style={{ justifyContent: 'flex-end' }}>
                        <Button type="secondary" onClick={() => setModalOpen(false)}>
                            Cancel
                        </Button>
                        <div style={{ width: 15 }} />
                        <Button
                            disabled={!selectedTab}
                            icon="check"
                            onClick={() => {
                                context.editor.actions.moveBlockToAnotherParentTree(
                                    selectedTab.id === 'details'
                                        ? 'center_content'
                                        : 'center_content' + selectedTab.id
                                )
                                context.treeInstance.notifyChanges()
                            }}
                        >
                            Move {selectedTab && 'to ' + selectedTab.label}
                        </Button>
                    </Flex>
                </Modal>
            )}
        </>
    )
}

export const Block = withRouter(UnRoutedBlock)

const BlockControls = styled('div')`
    position: absolute;
    left: 6px;

    bottom: 100%;
    display: none;
    flex-direction: row;
`

const BlockLabel = styled('div')`
    font-weight: bold;
    font-size: 12px;
    padding: 1px 3px;
    margin-right: 3px;
`

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { isArray } from 'lodash'

import { Dropdown } from 'v2/ui'

import type { AdminFilterProps } from './types'

const StyledValueError = styled.div`
    color: ${(props) => props.theme.colors.userInterface.error[600]};
    display: inline-block;
`

export const FilterDropdown: FC<AdminFilterProps> = ({
    value,
    field,
    multi,
    editMode = true,
    ...props
}) => {
    // if we're not in edit mode, then just display the selected values
    // not the input control
    const fieldOptions =
        field?.options?.options && field?.options?.options.map((option) => option.value)
    if (!editMode) {
        if ((field?.type === 'multi_select' || multi) && isArray(value)) {
            return (
                <>
                    {value?.map((value, idx) => {
                        return fieldOptions?.includes(value) ? (
                            <div style={{ display: 'inline-block' }} key={value}>
                                {idx > 0 ? ', ' : ''} {value}
                            </div>
                        ) : (
                            <StyledValueError key={value}>
                                {idx > 0 ? ', ' : ''} {value}
                            </StyledValueError>
                        )
                    })}
                </>
            )
        }

        if (!field?.options?.options?.find((x) => x.value === value)) {
            return <StyledValueError>{value}</StyledValueError>
        }
        return <>{field?.options?.options?.find((x) => x.value === value)?.label}</>
    }

    return (
        <Dropdown
            options={field?.options?.options}
            value={value}
            multi={multi}
            {...props}
            isMulti={field?.type === 'multi_select' || multi}
            style={{ flex: 1 }}
        />
    )
}

import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useWorkspaceLogoState() {
    const { workspaceNavState, workspaceAccount } = useNavigationContext()

    const isWorkspaceNavCollapsed = workspaceNavState === 'collapsed'

    const workspaceName = workspaceAccount?.name ?? 'Stacker'
    const logoImageUrl = workspaceAccount?.options?.theme?.logo

    return useMemo(
        () => ({
            workspaceName,
            showIconOnly: isWorkspaceNavCollapsed,
            workspaceAccount,
            logoImageUrl,
        }),
        [workspaceName, isWorkspaceNavCollapsed, workspaceAccount, logoImageUrl]
    )
}

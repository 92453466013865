import { useCallback } from 'react'

import { useAppUsersContext } from 'features/users/AppUsers/useAppUsersContext'

export function useAppUsersCreateTableGroupButtonState() {
    const { setPane } = useAppUsersContext()

    const onClick = useCallback(() => {
        setPane('createTableGroup')
    }, [setPane])

    return {
        onClick,
    }
}

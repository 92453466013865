// @ts-strict-ignore
import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { Rights } from 'app/accountUserContextConstants'
import { useAccountUserContext } from 'app/useAccountUserContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import useLDFlags from 'data/hooks/useLDFlags'
import { useUpdateZone } from 'data/hooks/zones'
import analytics from 'utils/analytics'

import { PreviewBackground, Text } from 'v2/ui'
import { LogoPreviewImg } from 'v2/ui/components/LogoPreview'
import { getNavigationColor } from 'v2/ui/hooks/useNavigationColor'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'
import Form from 'ui/deprecated/forms/Form'
import { SelectForm } from 'ui/deprecated/forms/SelectForm'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { defaultColor } from './forms/colorConstants'
import { ColorPickerForm } from './forms/ColorPicker'
import { UploadIcon } from './forms/UploadIcon'
import { FormFrame } from './WorkspaceSettingsModalUi'

const Spacer = ({ style = {} }) => {
    return <div style={{ flexBasis: 15, flexShrink: 0, ...style }} />
}
const Flex = ({ children, ...props }) => {
    return (
        <Box flex {...props}>
            {children}
        </Box>
    )
}

type FormData = Pick<
    AccountZone,
    '_sid' | 'brand_color' | 'branding' | 'logo_url' | 'logo_icon_url'
> & {
    nav_color: string
}

export const WorkspaceModalAppearanceSettings = ({ formRef, children }) => {
    const { workspaceAccount, workspaceZone } = useWorkspaceContext()
    const { hasRight } = useAccountUserContext()
    const readOnly = !hasRight(Rights.ManageSettings)

    const { mutateAsync: updateZone } = useUpdateZone()

    if (!workspaceZone || !workspaceAccount) return null

    const onSubmit = (data: FormData) => updateZone({ id: workspaceZone._sid, patch: data })

    const defaultValues = {
        brand_color: workspaceZone.brand_color || defaultColor,
        branding: workspaceZone.branding || 'Name',
        logo_url: workspaceZone.logo_url,
        logo_icon_url: workspaceZone.logo_icon_url,
        nav_color: workspaceAccount.options?.theme?.navColor || 'dark',
    }

    const isPortal = workspaceZone.type == 'Portal'

    return (
        <Form
            onSubmit={onSubmit}
            options={{ defaultValues }}
            style={{ flex: 1 }}
            resetOnSuccess
            onSuccess={(formContext) => {
                if (!!formContext.formState.dirtyFields.logo_url) {
                    analytics.track('WIP - Frontend - Workspace Settings - Logo - Updated', {})
                }
            }}
        >
            <FormFrame title="Appearance" formRef={formRef}>
                <WorkspaceModalAppearanceSettingsContent
                    name={workspaceZone.name}
                    readOnly={readOnly}
                    isPortal={isPortal}
                />
            </FormFrame>
            {children}
        </Form>
    )
}

const WorkspaceModalAppearanceSettingsContent = ({
    name,
    readOnly = false,
    isPortal = false,
}: {
    name: string
    readOnly?: boolean
    isPortal?: boolean
}) => {
    const formContext = useFormContext()
    const { logo_url, logo_icon_url, nav_color, brand_color } = formContext.watch()

    const isMobile = window.innerWidth < 768

    const { flags } = useLDFlags()

    return (
        <>
            <UploadIcon
                disabled={readOnly}
                logoFieldName="logo_url"
                iconFieldName="logo_icon_url"
                brandColorFieldName="brand_color"
            />
            <div style={{ height: 4 }} />
            <Body size="l" weight="medium">
                App
            </Body>
            <Flex style={{ flexWrap: isMobile ? 'wrap' : 'nowrap', alignItems: 'flex-start' }}>
                <Flex column style={{ flex: 1 }}>
                    <Flex>
                        <ColorPickerForm
                            name="brand_color"
                            required
                            label="Default color"
                            disabled={readOnly}
                        />
                    </Flex>
                    {!flags.navigationV2 && (
                        <>
                            <Spacer />
                            <SelectForm
                                name="branding"
                                label="Branding"
                                options={[
                                    {
                                        value: 'Name',
                                        label: `${isPortal ? 'Portal' : 'Workspace'} name`,
                                    },
                                    { value: 'Logo', label: 'Logo', disabled: !logo_url },
                                    {
                                        value: 'Icon',
                                        label: 'Logo icon',
                                        disabled: !logo_icon_url,
                                    },
                                ]}
                                disabled={readOnly}
                            />
                        </>
                    )}
                </Flex>
                <Spacer />
                <Spacer />
                <PreviewBackground
                    navColor={nav_color}
                    brandColor={brand_color}
                    style={{ marginTop: isMobile ? 30 : 0 }}
                >
                    <PreviewSelectedHeader name={name} />
                    <PreviewNav />
                </PreviewBackground>
            </Flex>
        </>
    )
}

// Show workspace name or logo or logo_icon based on selection and what is available
const PreviewSelectedHeader = ({ name }: { name: string }) => {
    const formContext = useFormContext()
    const watchedValues = formContext.watch()
    const [navColor, brandColor] = formContext.watch(['nav_color', 'brand_color'])
    const navTheme = getNavigationColor({ navColor, brandColor })

    const url =
        watchedValues.branding === 'Logo'
            ? watchedValues.logo_url
            : watchedValues.branding === 'Icon'
              ? watchedValues.logo_icon_url
              : null

    if (!url) {
        return <div style={{ ...V4DesignSystem.h1, color: navTheme.textBright }}>{name}</div>
    }

    return <LogoPreviewImg url={url} />
}

// try to simulate how the nav would look like
const PreviewNav = () => {
    const formContext = useFormContext()
    const [navColor, brandColor] = formContext.watch(['nav_color', 'brand_color'])
    const navTheme = getNavigationColor({ navColor, brandColor })

    return (
        <div style={{ textAlign: 'left', width: '100%' }}>
            <div style={{ background: `#AAAAAA33`, height: 1, margin: '15px -10px' }} />
            <div style={{ padding: '5px 8px', borderRadius: 3 }}>
                <Text
                    variant="internalNavLabel"
                    style={{ ...V4DesignSystem.h2, color: navTheme.textBright }}
                >
                    App
                </Text>
            </div>
            <div style={{ height: 10 }} />
            <div style={{ padding: '5px 8px', borderRadius: 3 }}>
                <Text variant="internalChildNavLabel" color={navTheme.text}>
                    One
                </Text>
            </div>
            <div
                style={{
                    padding: '5px 8px',
                    borderRadius: 3,
                    background: navTheme.highlightBg,
                }}
            >
                <Text variant="internalChildNavLabelActive" color={navTheme.highlightColor}>
                    Two
                </Text>
            </div>
            <div style={{ padding: '5px 8px', borderRadius: 3 }}>
                <Text variant="internalChildNavLabel" color={navTheme.text}>
                    Three
                </Text>
            </div>
        </div>
    )
}

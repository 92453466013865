import { useCallback } from 'react'

import { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

import { DuplicateWorkflowModal } from './DuplicateWorkflowModal'
import { USE_DUPLICATE_WORKFLOW_MODAY_KEY } from './duplicateWorkflowModalConstants'

export const useDuplicateWorkflowModal = (onSuccess?: (workflow: WorkflowDto) => void) => {
    const { show } = useModalDeclaration(USE_DUPLICATE_WORKFLOW_MODAY_KEY, DuplicateWorkflowModal)

    return useCallback((workflow: WorkflowDto) => show({ workflow, onSuccess }), [show, onSuccess])
}

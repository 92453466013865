import { v4 as uuid } from 'uuid'
import * as Y from 'yjs'

import { toYType } from 'features/utils/useYjsState'

import { Layout, LayoutEditorSchema, Widget } from './types'

export function getWidgetAreaAtPath(path: string[], layout?: Layout): Widget[] | undefined {
    if (path.length < 1 || !layout) return

    if (path.length === 1) {
        const id = path[0]

        return layout.children?.[id] ?? []
    }

    const pathLen = path.length

    let currentLevelIdx = 0
    let id = path[currentLevelIdx]
    let widgetArea = id ? layout?.children?.[id] : undefined
    while (!!widgetArea && currentLevelIdx < pathLen) {
        id = path[currentLevelIdx]
        if (!id) break

        for (const widget of widgetArea) {
            if (widget.id !== id) continue

            currentLevelIdx++
            id = path[currentLevelIdx]

            widgetArea = widget.children[id]

            if (currentLevelIdx === pathLen - 1) {
                return widgetArea
            }

            break
        }

        currentLevelIdx++
    }

    return widgetArea
}

export function getWidgetAtPath(
    path: string[],
    widgetId?: string,
    layout?: Layout
): Widget | undefined {
    const widgetArea = getWidgetAreaAtPath(path, layout)
    if (!widgetArea) return

    return widgetArea.find((w) => w.id === widgetId)
}

export function getYWidgetArea(path: string[], view?: Y.Map<any>): Y.Array<any> | undefined {
    if (path.length < 1 || !view) return

    if (path.length === 1) {
        const id = path[0]

        let layout = view.get('layout')
        if (!layout) {
            layout = view.set('layout', new Y.Map())
        }

        let children = layout.get('children')
        if (!children) {
            children = layout.set('children', new Y.Map())
        }

        let widgetArea = children.get(id)
        if (!widgetArea) {
            widgetArea = children.set(id, new Y.Array())
        }

        return widgetArea
    }

    const pathLen = path.length

    let currentLevelIdx = 0
    let id = path[currentLevelIdx]
    let widgetArea = id ? view.get('layout')?.get('children')?.get(id) : undefined
    while (!!widgetArea && currentLevelIdx < pathLen) {
        id = path[currentLevelIdx]
        if (!id) break

        for (const widget of widgetArea) {
            if (widget.get('id') !== id) continue

            let children = widget.get('children')
            if (!children) {
                children = widget.set('children', new Y.Map())
            }

            currentLevelIdx++
            id = path[currentLevelIdx]

            widgetArea = children.get(id)
            if (!widgetArea) {
                widgetArea = children.set(id, new Y.Array())
            }

            if (currentLevelIdx === pathLen - 1) {
                return widgetArea
            }

            break
        }

        currentLevelIdx++
    }

    return widgetArea
}

export function getYWidgetAtPath(
    path: string[],
    widgetId?: string,
    view?: Y.Map<any>
): { widget: Y.Map<any> | undefined; idx: number } {
    const widgetArea = getYWidgetArea(path, view)
    if (widgetArea) {
        let idx = -1
        for (const widget of widgetArea) {
            idx++

            if (widget.get('id') !== widgetId) continue

            return { widget, idx }
        }
    }

    return { widget: undefined, idx: -1 }
}

export function insertYWidgetAtPath(
    path: string[],
    schema: LayoutEditorSchema,
    widgetType: string,
    view?: Y.Map<any>,
    targetIdx?: number,
    widgetId?: string
) {
    if (!view) return

    const widgetArea = getYWidgetArea(path, view)
    if (!widgetArea) return

    const newWidget = createWidget(widgetType, schema)
    if (!newWidget) return

    if (widgetId) {
        newWidget.id = widgetId
    }

    const newYWidget = toYType(newWidget)
    if (typeof targetIdx === 'number') {
        widgetArea.insert(targetIdx, [newYWidget])
    } else {
        widgetArea.push([newYWidget])
    }
}

export function generateWidgetId() {
    return uuid()
}

export function createWidget(widgetType: string, schema: LayoutEditorSchema): Widget | undefined {
    const widgetSchema = schema.widgets[widgetType]
    if (!widgetSchema) {
        return undefined
    }

    const widgetId = generateWidgetId()

    const defaultedAttrs =
        (typeof widgetSchema.defaultAttrs === 'function'
            ? widgetSchema.defaultAttrs?.()
            : widgetSchema.defaultAttrs) ?? {}

    return {
        type: widgetType,
        id: widgetId,
        children: {},
        attrs: defaultedAttrs,
    }
}

export function duplicateYWidgetAtPath(path: string[], widgetId?: string, view?: Y.Map<any>) {
    const { widget, idx } = getYWidgetAtPath(path, widgetId, view)
    if (!widget) return

    const newWidgetId = generateWidgetId()
    const newIdx = idx + 1

    const clonedWidget = deepCopyYType(widget) as Y.Map<any>
    clonedWidget.set('id', newWidgetId)

    const widgetArea = widget.parent as Y.Array<any> | undefined
    widgetArea?.insert(newIdx, [clonedWidget])

    return newWidgetId
}

export function deepCopyYType(yType: Y.AbstractType<any>) {
    if (yType instanceof Y.Map) {
        const newMap = new Y.Map()
        yType.forEach((value, key) => {
            newMap.set(key, deepCopyYType(value))
        })
        return newMap
    } else if (yType instanceof Y.Array) {
        const newArray = new Y.Array()
        yType.forEach((item) => {
            newArray.push([deepCopyYType(item)])
        })
        return newArray
    } else if (yType instanceof Y.Text) {
        const newText = new Y.Text()
        newText.insert(0, yType.toString()) // Copy text content.
        return newText
    } else {
        // For primitive types (string, number, boolean, etc.), return directly
        return yType
    }
}

export function moveWidgetToPath(
    widgetId: string,
    initialPath: string[],
    destinationPath: string[],
    destinationIdx: number,
    data: Y.Map<any>
) {
    const { widget: existingWidget, idx: existingWidgetIdx } = getYWidgetAtPath(
        initialPath,
        widgetId,
        data
    )
    if (!existingWidget) return

    const existingWidgetArea = existingWidget.parent as Y.Array<any> | undefined
    const newWidgetArea = getYWidgetArea(destinationPath, data)
    if (!newWidgetArea) return

    // Clone the widget, since we can't use the same Yjs object in multiple places.
    const existingWidgetClone = deepCopyYType(existingWidget)

    // Remove widget from existing widget area.
    existingWidgetArea?.delete(existingWidgetIdx)
    // Insert widget in new widget area.
    newWidgetArea?.insert(destinationIdx, [existingWidgetClone])
}

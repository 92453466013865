export const Rights = {
    Admin: {
        All: 'admin',
        Any: 'admin.*',
        DataConnections: {
            All: 'admin.dataconnections',
            Any: 'admin.dataconnections.*',
            View: 'admin.dataconnections.view',
        },
    },
    AllDataAccess: 'all_data_access',
}

import * as React from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import Collapse from 'v2/ui/components/Collapse'
import Flex from 'v2/ui/components/Flex'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { defaultMessages } from './utils'

export const Spacer = () => <div style={{ height: 15 }} />
export const ErrorMessage = ({ error, errorMessages = defaultMessages, errorMessageStyle }) => {
    const message =
        error && (error?.message || errorMessages?.[error.type] || defaultMessages?.[error.type])
    return (
        <Collapse isOpen={message}>
            <Box style={{ flexBasis: 5, flexShrink: 0 }}>
                <Body color="textError" size="s" style={errorMessageStyle}>
                    {message}
                </Body>
            </Box>
        </Collapse>
    )
}

const FieldLabelText = styled.div`
    && {
        i {
            display: block;
            margin-top: 8px;
            font-weight: 400;
        }
    }
`

export const Label = ({ children, htmlFor, style, ...props }) => (
    <label htmlFor={htmlFor}>
        <FieldLabelText
            style={{ ...V4DesignSystem.label, color: V4DesignSystem.colors.text, ...style }}
            {...props}
        >
            {children}
        </FieldLabelText>
    </label>
)

export const RenderInlineElement = ({
    label,
    subtitle = '',
    children,
    error,
    errorMessages,
    row = false,
    style,
    innerStyle,
    customSubtitleRender,
    onClick,
    ...props
}) => {
    return (
        <ControlContainer
            column={!row}
            style={row ? { alignItems: 'center', ...style } : { alignItems: 'stretch', ...style }}
            margin={props.margin}
            onClick={onClick}
        >
            {label && (
                <Label
                    className="label"
                    style={{ marginBottom: 5, ...(row ? { minWidth: 200 } : {}) }}
                >
                    {label}
                    {subtitle && <i>{subtitle}</i>}
                </Label>
            )}
            {customSubtitleRender}
            {row && <div style={{ minWidth: 5, flex: 1 }} />}
            <div style={row ? { flexBasis: '60%', ...innerStyle } : { flex: 1, ...innerStyle }}>
                {children}
            </div>
            <ErrorMessage error={error} errorMessages={errorMessages} />
        </ControlContainer>
    )
}

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`
const scaleInAnimation = keyframes`
  0% { height: 0; transform: scaleY(0); transform-origin: top; }
  100% { height: auto; transform: scaleY(1) }
`

const ControlContainer = styled(Flex)`
    ${(p) => (p.hidden ? ' display: none;' : '')}

    margin-top: ${(props) => props.margin || '16px'};
    align-items: center;
    animation: ${fadeInAnimation} 0.2s linear; /*, ${scaleInAnimation} 0.15s ease-out;*/

    .label {
        min-width: 80px;
        padding-right: 8px;
        font-size: 14px;
    }

    .chart-control {
        flex-grow: 1;
    }
`

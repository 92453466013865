import React, { useState } from 'react'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import isEmpty from 'lodash/isEmpty'

import { useSignup } from 'data/hooks/auth/auth'
import validateEmail from 'features/utils/validateEmail'
import analytics from 'utils/analytics'

import { Collapse } from 'v2/ui'

import { Button, Input, Text } from './AuthUIElements'

const RegisterForm = ({ onSuccess, onRejected, email = null, name = null, invitation = null }) => {
    const signup = useSignup()
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({
        name: name,
        email: email || invitation?.email,
        errors: {},
    })
    const [error, setError] = useState(false)

    const handleRegister = (e) => {
        e.preventDefault()
        const errors = {}
        if (!state.name && !invitation) {
            errors.name = 'Please enter your full name'
        }

        if (!invitation && (!state.email || !validateEmail(state.email))) {
            errors.email = 'Please enter a valid email address'
        }
        if (!state.password) {
            errors.password = 'Please enter a password'
        } else if (state.password.length < 8) {
            errors.password = 'Password must be at least 8 characters'
        }

        setState((state) => ({ ...state, errors }))
        if (!isEmpty(errors)) {
            return
        }
        setIsLoading(true)

        analytics.track('user registration submitted', {
            event_category: 'user',
            event_description: 'New user registration was submitted',
            referred_user_id: invitation?.sender?._sid,
            authentication_method: 'email',
        })

        const data = {
            ...state,
            invitation_token: invitation?.token,
        }
        setError(false)
        signup(data)
            .then((response) => {
                onSuccess(response)
            })
            .catch((ex) => {
                Sentry.captureException(ex)
                setError(true)

                onRejected(ex, state.email)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChange = (e) => {
        const field = e.target.id
        const value = e.target.value
        setState((state) => ({ ...state, [field]: value }))
    }

    return (
        <form onSubmit={handleRegister} style={{ display: 'flex', flexDirection: 'column' }}>
            {!invitation && (
                <>
                    <StyledLabel htmlFor="name">Full name</StyledLabel>
                    <InputElement
                        id="name"
                        state={state}
                        onChange={handleChange}
                        placeholder="enter full name"
                        aria-label="Full name"
                    />
                </>
            )}

            {!invitation && (
                <>
                    <StyledLabel htmlFor="email">Email</StyledLabel>
                    <InputElement
                        id="email"
                        type="email"
                        state={state}
                        onChange={handleChange}
                        placeholder="enter email"
                        aria-label="Email"
                    />
                </>
            )}
            <StyledLabel htmlFor="password">Password</StyledLabel>
            <InputElement
                id="password"
                type="password"
                state={state}
                onChange={handleChange}
                placeholder={invitation ? 'create password' : 'enter password'}
            />
            <Collapse isOpen={error}>
                <Text variant="error" mb={2}>
                    Sorry, an error occurred trying to register.
                </Text>
            </Collapse>
            <Button
                width="100%"
                buttonSize="sm"
                mt={1}
                mb={4}
                aria-label="Password"
                isLoading={isLoading}
                type="submit"
                isDisabled={isLoading}
            >
                Sign up
            </Button>
        </form>
    )
}

const StyledLabel = styled.label`
    margin-top: 1rem;
    padding-bottom: 0.2rem;
    font-weight: bold;
`

const InputElement = ({ id, state, onChange, ...props }) => (
    <>
        <Input
            variant="authentication"
            id={id}
            width="100%"
            mb={2}
            value={state[id]}
            onChange={onChange}
            {...props}
        />
        <Collapse isOpen={!!(state.errors && state.errors[id])}>
            <Text variant="error" mt="-0.5rem" mb={3}>
                {state.errors[id]}
            </Text>
        </Collapse>
    </>
)

export default RegisterForm

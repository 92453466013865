import React, { useCallback } from 'react'

import { openWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'
import { ZoneLogo } from 'features/workspace/zones/ZoneLogo'

import useHover from 'v2/ui/utils/useHover'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { DropdownItemComplex } from 'ui/components/Dropdown'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { determineColorScheme } from 'ui/styling/helpers/ThemeInitializer'
import { theme } from 'ui/styling/Theme.css'

export const ZoneItem = ({
    zone,
    isCurrent,
    onClick,
    isInternal = false,
    canViewSettings = false,
    ...rest
}: {
    zone: AccountZone
    isCurrent: boolean
    onClick: () => void
    isInternal?: boolean
    canViewSettings?: boolean
}) => {
    const [isHovered, hoverHandlers] = useHover()

    const onOpenSettings = useCallback(() => {
        onClick()
        openWorkspaceSettingsModal({ page: 'general' })
    }, [onClick])

    const getRightSlotContent = (slotProps: any) => {
        return (
            <Box flex>
                {canViewSettings && isHovered ? (
                    <Button
                        startIcon={{ name: 'Settings' }}
                        variant="ghost"
                        onClick={onOpenSettings}
                        {...slotProps}
                    />
                ) : isCurrent ? (
                    <Button startIcon={{ name: 'Check' }} variant="ghost" {...slotProps} />
                ) : null}
            </Box>
        )
    }

    if (isInternal) {
        const label = (
            <ZoneLogo
                zone={zone}
                isSidebar
                style={{
                    maxHeight: '32px',
                    maxWidth: theme.space['10xl'],
                }}
            />
        )

        const subtitle = (
            <Box
                flex
                style={{
                    marginTop: '2px',
                    marginBottom: '5px',
                    flexWrap: 'wrap',
                    maxWidth: '70%',
                }}
            >
                <Body mr="xs">{zone.display_name} Workspace</Body>
                <Tag size="2xs" color="Blue">
                    Internal
                </Tag>
            </Box>
        )

        return (
            <DropdownItemComplex
                label={label}
                subtitle={subtitle}
                rightSlotContent={getRightSlotContent}
                onClick={onClick}
                {...hoverHandlers}
                {...rest}
            />
        )
    }
    return (
        <DropdownItemComplex
            label={zone.display_name}
            startAvatar={getAvatarProps(zone)}
            rightSlotContent={getRightSlotContent}
            onClick={onClick}
            {...hoverHandlers}
            {...rest}
        />
    )
}

const getAvatarProps = (zone: AccountZone) => {
    if (zone.logo_icon_url) {
        return { imageUrl: zone.logo_icon_url, type: 'image' }
    }
    return {
        firstName: zone.display_name,
        colorScheme: zone.brand_color ? determineColorScheme(zone.brand_color) : 'Blue',
        shape: 'square',
        size: 'm',
        type: 'initial',
    }
}

import React from 'react'

import classNames from 'classnames'

import { Users } from 'features/views/attributes/Users'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
} from 'ui/components/Dropdown'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAdvancedFilterValueUserState } from './hooks/useAdvancedFilterValueUserState'
import { AdvancedFilter } from './types'

import { AdvancedFiltersButtonStyle } from './AdvancedFilters.css'

const MAX_ITEM_LENGTH = 25

type AdvancedFilterValueUserProps = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
    className?: string
}

export const AdvancedFilterValueUser: React.FC<AdvancedFilterValueUserProps> = ({
    filter,
    onChange,
    onRemoveFilter,
    className,
}) => {
    const {
        value,
        onSetFilterValue,
        filterOptions,
        isFetchingSlow,
        isError,
        isSingle,
        valueOptions,
        isLabelLoading,
        isOpen,
        setIsOpen,
    } = useAdvancedFilterValueUserState({
        filter,
        onChange,
        onRemoveFilter,
        maxItemLength: MAX_ITEM_LENGTH,
    })

    const size = useResponsiveValue<React.ComponentPropsWithoutRef<typeof DropdownButton>['size']>({
        mobile: 'xs',
        tablet: 's',
    })

    return (
        <Dropdown open={isOpen} onOpenChange={setIsOpen} modal={false}>
            <DropdownButton
                size={size}
                variant="ghost"
                aria-label="Select value"
                className={classNames(className, AdvancedFiltersButtonStyle)}
            >
                <Users
                    isLoading={isLabelLoading}
                    value={valueOptions}
                    size={{ mobile: 's', tablet: 'm' }}
                    avatarSize={{ mobile: '2xs', tablet: 'xs' }}
                    overflow
                    showTooltips={false}
                    maxLength={MAX_ITEM_LENGTH}
                />
            </DropdownButton>
            <DropdownContent
                align="end"
                onClick={stopPropagation}
                head={!isFetchingSlow && <DropdownHeadSearch />}
                style={{ pointerEvents: 'auto' }}
            >
                {isError && (
                    <DropdownItem
                        label={
                            <Body size={{ mobile: 's', tablet: 'm' }} color="textError">
                                There was a problem displaying options
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError && filterOptions.length < 1 && (
                    <DropdownItem
                        label={
                            <Body size={{ mobile: 's', tablet: 'm' }} color="textHelper">
                                No items found
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError &&
                    filterOptions.map((item) => (
                        <DropdownItem
                            key={item.value}
                            multiSelect={!isFetchingSlow}
                            label={
                                isFetchingSlow ? (
                                    <Skeleton isLoading={isFetchingSlow}>{item.label}</Skeleton>
                                ) : (
                                    item.label
                                )
                            }
                            checked={value.has(item.value)}
                            onCheckedChange={(checked) => {
                                onSetFilterValue(item.value, checked)
                            }}
                            disabled={isFetchingSlow}
                            startAvatar={item.avatar}
                            closeOnSelect={isSingle}
                        />
                    ))}
            </DropdownContent>
        </Dropdown>
    )
}

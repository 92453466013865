import React from 'react'

import { Box } from 'ui/components/Box'

import { Attribute } from './attributes/Attribute'
import { useCardViewSubtitleState } from './attributes/hooks/useCardViewSubtitleState'

import { CardViewContentSubtitleStyles } from './CardView.css'

type CardViewSubtitleProps = {
    record: RecordDto
    isLoading?: boolean
}

export const CardViewSubtitle: React.FC<CardViewSubtitleProps> = ({ record, isLoading }) => {
    const { attribute, value } = useCardViewSubtitleState({ record })

    if (!attribute) return null

    return (
        <Box mt="2xs">
            <Attribute
                attribute={attribute}
                isLoading={isLoading}
                displayStyle="list"
                value={value}
                className={CardViewContentSubtitleStyles}
            />
        </Box>
    )
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAppUsers } from 'data/hooks/users/main'
import { extractPartsFromUserName } from 'features/views/attributes/utils'
import { makeQuickFilterValues } from 'features/views/ListView/Filters/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

import { useQuickFilter } from './useQuickFilter'

const MAX_LABEL_LENGTH = 25
const MAX_ITEMS = 2
const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

type FilterOption = {
    label: string
    value: string
    avatar?: {
        imageUrl: string
        firstName: string
        lastName: string
        type: 'initial' | 'image'
    }
}

type UseQuickFilterUserStateOptions = {
    field: FieldDto
}

export function useQuickFilterUserState(options: UseQuickFilterUserStateOptions) {
    const { field } = options

    const { value: filters, setFiltersValue, isActive } = useQuickFilter(field)

    const filterValue = filters[0]
    const filterValueRef = useRef(filterValue)
    filterValueRef.current = filterValue

    const onFilterRemove = useCallback(() => {
        setFiltersValue([])
    }, [setFiltersValue])

    const onSetFilterValue = useCallback(
        (value: string, isEnabled: boolean) => {
            const filterValue = filterValueRef.current
            const existingValue = (filterValue?.options.value as string[]) ?? []

            let newValue: string[] = []
            if (isEnabled) {
                newValue = [...existingValue, value]
            } else {
                newValue = existingValue.filter((v) => v !== value)
            }

            // Clear the filter if no values are selected.
            if (newValue.length < 1) {
                setFiltersValue([])
                return
            }

            setFiltersValue(makeQuickFilterValues(field, newValue))
        },
        [field, setFiltersValue]
    )

    const { data: users, isFetching, isError } = useAppUsers()
    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const filterOptions = isFetchingSlow ? PLACEHOLDER_OPTIONS : makeFilterOptions(users)

    const internalValue = useDeepEqualsMemoValue((filterValue?.options.value as string[]) ?? [])
    const value = useMemo(() => new Set(internalValue), [internalValue])

    const hasValue = value.size > 0
    const isButtonLoading = hasValue && !users
    const label = isButtonLoading ? 'Loading...' : formatLabel(field, value, filterOptions)

    return useMemo(
        () => ({
            value,
            isActive,
            label,
            onFilterRemove,
            onSetFilterValue,
            filterOptions,
            isError,
            isFetchingSlow,
            isButtonLoading,
        }),
        [
            value,
            isActive,
            label,
            onFilterRemove,
            onSetFilterValue,
            filterOptions,
            isError,
            isFetchingSlow,
            isButtonLoading,
        ]
    )
}

function makeFilterOptions(users?: UserDto[]): FilterOption[] {
    if (!users) {
        return []
    }

    return users.map((user) => {
        const label = user.name

        const imageUrl = user.avatar
        const { firstName, lastName } = extractPartsFromUserName(label)

        return {
            label: truncateText(label, MAX_LABEL_LENGTH),
            value: user._sid,
            avatar: {
                imageUrl,
                firstName,
                lastName,
                type: imageUrl ? 'image' : 'initial',
            },
        }
    })
}

function formatLabel(field: FieldDto, value: Set<string>, filterOptions: FilterOption[]) {
    if (value.size < 1) {
        return truncateText(field.label, MAX_LABEL_LENGTH)
    }

    const activeOptions = filterOptions.filter((option) => value.has(option.value))

    const overflowingItemCount = activeOptions.length - MAX_ITEMS
    const truncatedOptions = activeOptions.slice(0, MAX_ITEMS)

    const formattedItems = truncatedOptions.map((option) => option.label).join(', ')

    if (overflowingItemCount > 0) {
        return `${formattedItems} +${overflowingItemCount}`
    }

    return formattedItems
}

const PLACEHOLDER_OPTIONS: FilterOption[] = [
    { label: 'Loading all filter options...', value: 'loading1' },
    { label: 'Loading all filter options...', value: 'loading2' },
    { label: 'Loading all filter options...', value: 'loading3' },
    { label: 'Loading all filter options...', value: 'loading4' },
    { label: 'Loading all filter options...', value: 'loading5' },
    { label: 'Loading all filter options...', value: 'loading6' },
]

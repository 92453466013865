import { isMultiLineText } from 'data/utils/fieldDefinitions'

function getDefaultFieldConfig(f: FieldDto) {
    return {
        fieldId: f._sid,
        fieldName: f.api_name,
        // Matching the logic from View._get_default_view_contents_with_field_container_widget in backend Ì
        fullWidth: isMultiLineText(f.type, f.options),
        objectId: f.object_id,
        readOnly: false,
        required: false,
        type: 'field',
    }
}

// Generates the list of fields to use for a given field container when in All Fields mode.
export function generateAllFields(
    fields: FieldDto[],
    configuredItems: any[],
    includePrimaryField: boolean
) {
    const allFields = fields
        .filter((f) => !f.is_primary || includePrimaryField)
        .map((f) => {
            const existingOptions = configuredItems.find((x) => x.fieldId === f._sid) || {}

            return {
                ...getDefaultFieldConfig(f),
                // Override with potential existing display options
                ...existingOptions,
            }
        })

    // If the first item in the list a is a section, then we want to preserve that
    // and prepend it to the list of all fields.
    let firstSection
    if (configuredItems.length > 0 && configuredItems[0].type === 'section') {
        firstSection = configuredItems[0]
    }

    return [...(firstSection ? [firstSection] : []), ...allFields]
}

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'
import { makeComponent } from 'ui/helpers/recipes'

import {
    HelperTextStyles,
    InfoTextMarkStyles,
    LabelStyles,
    LabelWrapperStyles,
    LeftSlotContentStyles,
    OptionalMarkStyles,
    RequiredMarkStyles,
    RightSlotContentStyles,
    WrapperStyles,
} from './Field.css'

export const Wrapper = makeComponent(Box, WrapperStyles)
export const LabelWrapper = makeComponent('div', LabelWrapperStyles)
export const Label = makeComponent('label', LabelStyles)
export const HelperText = makeComponent(Body, HelperTextStyles)
export const InfoMark = makeComponent('div', InfoTextMarkStyles)
export const RequiredMark = makeComponent(Body, RequiredMarkStyles)
export const OptionalMark = makeComponent(Body, OptionalMarkStyles)
export const LeftSlotContent = makeComponent(Box, LeftSlotContentStyles)
export const RightSlotContent = makeComponent('div', RightSlotContentStyles)

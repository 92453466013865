import React, { useContext } from 'react'

import { ListViewSort, ListViewSortType } from './types'

export type SortContextValue = {
    sortBy?: ListViewSort
    setSortBy: (sortBy?: ListViewSort) => void
    defaultSortBy?: ListViewSort
    availableFields: FieldDto[]
    sortType: ListViewSortType
    manualSortKey?: string
}
export const SortContext = React.createContext<SortContextValue | null>(null)

export function useSortContext(): SortContextValue {
    const context = useContext(SortContext)

    if (!context) {
        throw new Error('useSortContext must be used within a SortContextProvider')
    }

    return context
}

import { GridApi } from 'ag-grid-enterprise'

import { RowData } from 'features/datagrid/types'

type OpenEditorAction = {
    type: 'openEditor'
    editorOpenedWithEvent?: Event
}

type CloseEditorAction = {
    type: 'closeEditor'
}

type SetGridReadyAction = {
    type: 'setGridReady'
    gridApi: GridApi<RowData>
}

type DestroyGridAction = {
    type: 'destroyGrid'
}

type ShowEmptyRecordRowAction = {
    type: 'showEmptyRecordRow'
}

type HideEmptyRecordRowAction = {
    type: 'hideEmptyRecordRow'
}

type AddNewRecordAction = {
    type: 'addNewRecord'
    editorOpenedWithEvent: Event
}

export type DataGridStateForContextAction =
    | OpenEditorAction
    | CloseEditorAction
    | SetGridReadyAction
    | DestroyGridAction
    | ShowEmptyRecordRowAction
    | HideEmptyRecordRowAction
    | AddNewRecordAction
export type DataGridContextState = {
    isEditorOpen: boolean
    editorOpenedWithEvent: Event | null
    isGridReady: boolean
    gridApi: GridApi<RowData> | null
    showEmptyRecordRow: boolean
}

export const defaultState: DataGridContextState = {
    isEditorOpen: false,
    editorOpenedWithEvent: null,
    isGridReady: false,
    gridApi: null,
    showEmptyRecordRow: false,
}

export function dataGridStateForContextReducer(
    state: DataGridContextState,
    action: DataGridStateForContextAction
): DataGridContextState {
    switch (action.type) {
        case 'openEditor':
            return {
                ...state,
                editorOpenedWithEvent: action.editorOpenedWithEvent ?? null,
                isEditorOpen: true,
            }
        case 'closeEditor':
            return {
                ...state,
                editorOpenedWithEvent: null,
                isEditorOpen: false,
            }
        case 'setGridReady':
            return {
                ...state,
                isGridReady: true,
                gridApi: action.gridApi,
            }
        case 'destroyGrid':
            return {
                ...state,
                isGridReady: false,
                gridApi: null,
            }
        case 'showEmptyRecordRow':
            return {
                ...state,
                showEmptyRecordRow: true,
            }
        case 'hideEmptyRecordRow':
            return {
                ...state,
                showEmptyRecordRow: false,
            }
        case 'addNewRecord':
            return {
                ...state,
                showEmptyRecordRow: true,
                editorOpenedWithEvent: action.editorOpenedWithEvent,
            }
        default:
            return state
    }
}

import React, { forwardRef } from 'react'

import * as Parts from './Progress.parts'
import { formatPercentageValue } from './utils'

type ProgressRef = HTMLDivElement

type ProgressProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Root>, 'asChild'> & {
    showHelperText?: boolean
    value?: number
}

export const Progress = forwardRef<ProgressRef, ProgressProps>(
    ({ showHelperText, value = 0, children, layout = 'vertical', rounded, ...props }, ref) => {
        const visibleValue = Math.max(0, Math.min(100, value))
        const maxValue = 100

        const isOverflowing = value > maxValue
        const overflowingPerc = Math.ceil(((value - maxValue) / value) * 100)

        const formattedValue = formatPercentageValue(value)

        return (
            <Parts.Root ref={ref} {...props}>
                {children && <Parts.Label>{children}</Parts.Label>}
                <Parts.ContentWrapper layout={layout}>
                    <Parts.ProgressWrapper>
                        <Parts.Progress
                            value={value}
                            max={maxValue}
                            aria-valuenow={typeof value === 'number' ? value : undefined}
                            aria-valuetext={typeof value === 'number' ? `${value}%` : undefined}
                            data-state={getProgressState(value, maxValue)}
                            rounded={rounded}
                        >
                            <Parts.Indicator
                                data-state={getProgressState(value, maxValue)}
                                style={{
                                    transform: `translate3d(calc(-${
                                        100 - visibleValue
                                    }% - 1px), 0, 0)`,
                                }}
                            />
                            {isOverflowing && (
                                <Parts.OverflowIndicator
                                    style={{
                                        transform: `translate3d(calc(${
                                            100 - overflowingPerc
                                        }% - 1px), 0, 0)`,
                                    }}
                                />
                            )}
                        </Parts.Progress>
                        {isOverflowing && (
                            <Parts.OverflowDivider
                                style={{
                                    transform: `translate3d(${100 - overflowingPerc}%, 0, 0)`,
                                }}
                            />
                        )}
                    </Parts.ProgressWrapper>
                    {showHelperText && (
                        <Parts.HelperText layout={layout}>{formattedValue}</Parts.HelperText>
                    )}
                </Parts.ContentWrapper>
            </Parts.Root>
        )
    }
)

function getProgressState(value: number | null, maxValue: number): string {
    if (value === null) {
        return 'indeterminate'
    }

    return value >= maxValue ? 'complete' : 'incomplete'
}

import { useCallback, useEffect, useMemo, useState } from 'react'

import { formatNumber } from 'data/utils/utils'
import { makeQuickFilterValues } from 'features/views/ListView/Filters/utils'

import { useQuickFilter } from './useQuickFilter'

type UseQuickFilterPercentageStateOptions = {
    field: FieldDto
}

export function useQuickFilterPercentageState(options: UseQuickFilterPercentageStateOptions) {
    const { field } = options

    const { value: filterValue, setFiltersValue, isActive, label } = useQuickFilter(field)

    const onFilterRemove = useCallback(() => {
        setFiltersValue([])
    }, [setFiltersValue])

    const [currentValue, setCurrentValue] = useState<[number, number]>(
        getValueFromFilters(filterValue)
    )
    useEffect(() => {
        // Reset the value if the filter is cleared.
        const isCleared = filterValue.length < 1
        if (isCleared) {
            setCurrentValue([0, 100])
        }
    }, [filterValue.length])

    const applyFilters = useCallback(
        (newValue: [number, number]) => {
            const filterValue = convertInputValueToFilterValue(newValue)

            setFiltersValue(makeQuickFilterValues(field, filterValue))
        },
        [field, setFiltersValue]
    )

    const onSetFilterValue = useCallback(
        (newValue: [number, number]) => {
            setCurrentValue(newValue)
            applyFilters(newValue)
        },
        [applyFilters]
    )

    return useMemo(
        () => ({ value: currentValue, isActive, label, onFilterRemove, onSetFilterValue }),
        [currentValue, isActive, label, onFilterRemove, onSetFilterValue]
    )
}

function getValueFromFilters(filters: Filter[]): [number, number] {
    // This value is formatted for the range input, so it should be between 0 and 100.
    let lhs = 0
    let rhs = 100

    for (const filter of filters) {
        if (filter.options.option === 'equalsOrGreaterThan') {
            lhs = Math.floor(parseFloat(filter.options.value as string) * 100)
        } else if (filter.options.option === 'equalsOrLessThan') {
            rhs = Math.floor(parseFloat(filter.options.value as string) * 100)
        }
    }

    return [lhs, rhs]
}

function convertInputValueToFilterValue(value: [number, number]): [string, string] {
    const lhs = formatNumber(value[0] / 100, 0, 3)
    const rhs = formatNumber(value[1] / 100, 0, 3)

    return [lhs.toString(), rhs.toString()]
}

import { LIST_TYPES } from 'data/utils/fieldDefinitions'
import { ContextEntry, ContextGroup } from 'features/expressions/types'

export function filterIterableContextItems(entries: ContextEntry[]): ContextEntry[] {
    // Only include list context items.
    return entries.reduce<ContextEntry[]>((acc, entry) => {
        if (entry.type === 'group') {
            const group = entry as ContextGroup
            const filteredItems = group.items ? filterIterableContextItems(group.items) : undefined

            acc.push({
                ...group,
                items: filteredItems,
            } as ContextGroup)
        }

        if (LIST_TYPES.includes(entry.type as FieldType)) {
            acc.push(entry)
        }

        return acc
    }, [])
}

export function getIterableValueLabel(iterable: WorkflowLoopIterable): string | null {
    const node = iterable.expr?.input_content?.content?.find((node) => node.type === 'contextItem')
    if (!node) return null

    return node.attrs?.label ?? null
}

/*
    This store holds values that are global to the application and may need to be access
    from non-react code such as APIs etc.

    Do not use these values directly in React components, Instead use
    the appropriate hooks as exposed via context providers such as AppContextProvider, etc.

    This is an interim replacement for the places we have been reading values directly out of redux
    as we are moving away from redux.
*/

type InternalStore = {
    selectedStack: StackDto | undefined
    workspaceAccount: Account | undefined | null
    workspaceZone: AccountZone | undefined | null
    previewingAsUser: UserDto | undefined
    previewingAsRoleApiName: string | undefined
    user: AuthedUserDto | undefined
    appUserRole: RoleDto | undefined
    superTokensTenant: string | undefined
    isOnPortalDomain: boolean
}
const _globalStaticState: InternalStore = {
    selectedStack: undefined,
    workspaceAccount: undefined,
    workspaceZone: undefined,
    previewingAsUser: undefined,
    previewingAsRoleApiName: undefined,
    user: undefined,
    appUserRole: undefined,
    superTokensTenant: undefined,
    isOnPortalDomain: false,
}

export const getCurrentStackId = (): string | undefined => {
    return _globalStaticState.selectedStack?._sid
}
export const getCurrentStack = (): StackDto | undefined => {
    return _globalStaticState.selectedStack
}
export const setCurrentStack = (stack: StackDto | undefined) => {
    _globalStaticState.selectedStack = stack
}
export const getWorkspaceAccount = (): Account | undefined | null => {
    return _globalStaticState.workspaceAccount
}
export const setWorkspaceAccount = (account: Account | undefined | null) => {
    _globalStaticState.workspaceAccount = account
}
export const getPreviewingAsUser = (): UserDto | undefined => {
    return _globalStaticState.previewingAsUser
}
export const setPreviewingAsUser = (user: UserDto | undefined) => {
    _globalStaticState.previewingAsUser = user
}
export const getPreviewingAsRole = (): string | undefined => {
    return _globalStaticState.previewingAsRoleApiName
}
export const setPreviewingAsRole = (roleId: string | undefined) => {
    _globalStaticState.previewingAsRoleApiName = roleId
}

const getUser = (): AuthedUserDto | undefined => {
    return _globalStaticState.user
}
const setUser = (user: AuthedUserDto | undefined) => {
    _globalStaticState.user = user
}

const getAppUserRole = (): RoleDto | undefined => {
    return _globalStaticState.appUserRole
}
const setAppUserRole = (role: RoleDto | undefined) => {
    _globalStaticState.appUserRole = role
}

const getWorkspaceZone = (): AccountZone | undefined | null => {
    return _globalStaticState.workspaceZone
}

const setWorkspaceZone = (zone: AccountZone | undefined | null) => {
    _globalStaticState.workspaceZone = zone
}

// Gets the supertokens tenant id to be used with any Auth requests
const getSuperTokensTenant = (): string | undefined => {
    return _globalStaticState.superTokensTenant
}
const setSuperTokensTenant = (tenant: string | undefined) => {
    _globalStaticState.superTokensTenant = tenant
}

const getIsOnPortalDomain = (): boolean => {
    return _globalStaticState.isOnPortalDomain
}

const setIsOnPortalDomain = (isOnPortalDomain: boolean) => {
    _globalStaticState.isOnPortalDomain = isOnPortalDomain
}

export const GlobalStaticState = {
    getCurrentStackId,
    getCurrentStack,
    setCurrentStack,
    getWorkspaceAccount,
    setWorkspaceAccount,
    getWorkspaceZone,
    setWorkspaceZone,
    getPreviewingAsUser,
    setPreviewingAsUser,
    getPreviewingAsRole,
    setPreviewingAsRole,
    getUser,
    setUser,
    getAppUserRole,
    setAppUserRole,
    getSuperTokensTenant,
    setSuperTokensTenant,
    getIsOnPortalDomain,
    setIsOnPortalDomain,
}

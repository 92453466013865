import { useMemo } from 'react'

import { RelatedToType } from 'data/hooks/activityTypes'
import { TaskPayload } from 'data/hooks/tasks/types'

import { FeedItemRelationshipInfo } from './types'

export type UseActivityFeedParams = {
    record?: RecordDto
    task?: TaskPayload
    document?: DocumentDto
    location?: string
}

export type UseActivityFeedConfigResult = {
    contextKey: string
    target: FeedItemRelationshipInfo
    commentCount?: number
    commentsByLocation: Record<string, number>
}

export function useActivityFeedConfig({
    record,
    task,
    document,
    location,
}: UseActivityFeedParams): UseActivityFeedConfigResult {
    let contextKey = ''
    if (record) contextKey = record._object_id
    if (task) contextKey = String(task.auto_id)
    if (document) contextKey = String(document.auto_id)

    let relatedTo = ''
    if (record) relatedTo = record._sid
    if (task) relatedTo = String(task.auto_id)
    if (document) relatedTo = String(document.auto_id)

    let relatedToType = RelatedToType.Record
    if (task) relatedToType = RelatedToType.Task
    if (document) relatedToType = RelatedToType.Document

    const objectId = record ? record._object_id : undefined

    let commentCount = record?._comment_count || task?._comment_count || document?._comment_count
    if (location) {
        commentCount = record?._comment_counts?.[location] || document?._comment_counts?.[location]
    }

    const commentsByLocation = useMemo(
        () => record?._comment_counts || document?._comment_counts || {},
        [document?._comment_counts, record?._comment_counts]
    )

    const stackId = task?.related_to_stack ? task.related_to_stack : undefined

    return useMemo(() => {
        return {
            contextKey,
            target: {
                relatedTo,
                relatedToType,
                relatedToLocation: location,
                objectId,
                stackId,
            },
            commentCount,
            commentsByLocation,
        }
    }, [
        contextKey,
        relatedTo,
        relatedToType,
        location,
        objectId,
        commentCount,
        stackId,
        commentsByLocation,
    ])
}

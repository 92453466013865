import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeIconVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            stroke: s.color.icon,
            strokeHover: s.color.gray900,
            strokeActive: s.color.gray900,
            strokeInverse: s.color.white00,
            strokeDisabled: s.color.gray300,
            strokeError: s.color.error600,
            strokeSuccess: s.color.success600,
            strokeWarning: s.color.warning600,
            strokeTheme: s.color.theme600,
            strokeThemeStrong: s.color.theme700,
        },
        size: {
            xs: pxToRem(12),
            s: pxToRem(14),
            m: pxToRem(16),
            l: pxToRem(18),
            xl: pxToRem(20),
            '2xl': pxToRem(24),
            '3xl': pxToRem(32),
            '4xl': pxToRem(40),
        },
        strokeWidth: {
            xs: pxToRem(1.2),
            s: pxToRem(1.2),
            m: pxToRem(1.3),
            l: pxToRem(1.3),
            xl: pxToRem(1.4),
            '2xl': pxToRem(1.6),
            '3xl': pxToRem(2),
            '4xl': pxToRem(2.4),
        },
    }
}

export const Rights = {
    CreateApps: 'create_apps',
    InviteUsers: 'invite_users',
    ManageUsers: 'manage_users',
    ManageBilling: 'manage_billing',
    ContactSupport: 'contact_support',
    ViewSettings: 'view_settings',
    ManageSettings: 'manage_settings',
    CustomScripts: 'custom_scripts',
    DeleteWorkspace: 'delete_workspace',
} as const

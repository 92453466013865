import React from 'react'

import classNames from 'classnames'

import { useTableViewHeadingState } from './hooks/useTableViewHeadingState'
import * as Parts from './TableView.parts'
import { TableViewHeader } from './TableViewHeader'

import { TableHeadingStyles } from './TableView.css'

type TableViewHeadingProps = React.ComponentPropsWithoutRef<typeof Parts.Row> & {}

export const TableViewHeading: React.FC<TableViewHeadingProps> = React.memo(
    function TableViewHeading({ className, ...props }) {
        const { columns, isRecordActionsColumnPinned, showColumnIcons } = useTableViewHeadingState()

        return (
            <Parts.Row
                className={classNames(className, TableHeadingStyles.styleFunction())}
                {...props}
            >
                {columns.map((column) => (
                    <TableViewHeader key={column.id} column={column} showIcon={showColumnIcons} />
                ))}
                {!isRecordActionsColumnPinned && <Parts.Header />}
            </Parts.Row>
        )
    }
)

import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import shortid from 'shortid'

import { reorder } from 'utils/utils'

import { COLORS_ORDER } from './constants'
import type { DropdownOption, EditorDropdownOption } from './types'

function getInitialOptions(
    value: Array<DropdownOption | EditorDropdownOption> | undefined
): EditorDropdownOption[] {
    if (!value) {
        return [
            {
                label: '',
                value: 'empty-0',
                color: COLORS_ORDER[0],
                isEmpty: true,
                hasError: false,
                id: shortid(),
            },
        ]
    }

    return value.map((option, index) => {
        option = { ...option, id: (option as EditorDropdownOption).id ?? shortid() }

        return {
            ...option,
            color: option.color || COLORS_ORDER[index % COLORS_ORDER.length],
        }
    })
}

function useDropdownEditorHandler(
    value: DropdownOption[],
    onChange: (value: DropdownOption[]) => void
) {
    const { clearErrors, formState, setError, watch } = useFormContext()
    const allowDropdownColors = watch('options.allow_dropdown_colors')
    const formOptions = watch('options.options')

    const [focusedOption, setFocusedOption] = useState<number | null>(null)
    const [options, setOptions] = useState<EditorDropdownOption[]>(getInitialOptions(value))

    if (
        allowDropdownColors &&
        formState.dirtyFields['options']?.allow_dropdown_colors &&
        options !== formOptions
    ) {
        onChange(options)
    }

    const provideKey = (index: number) => `${options[index].value}-${index}`

    const onReorder = (start: number, end: number) => {
        if (end > 0 && options[end].isEmpty) {
            return
        }

        const newOptions = reorder(options, start, end)
        setOptions(newOptions)
        setFocusedOption(null)
        onChange(newOptions)
    }

    const addEmptyOption = () => {
        setOptions((prevOptions) => {
            const prevOptionColorIndex = COLORS_ORDER.findIndex(
                (color) => color === prevOptions[prevOptions.length - 1]?.color
            )

            return [
                ...prevOptions,
                {
                    label: '',
                    value: `empty-${prevOptions.length}`,
                    color:
                        prevOptionColorIndex > -1
                            ? // if the previous option color has been found, take the next one in the
                              // color palette
                              COLORS_ORDER[(prevOptionColorIndex + 1) % COLORS_ORDER.length]
                            : // otherwise, take the color corresponding to the position of the option
                              // in the list, preventing having the same colors pattern when unknown
                              // colors are in the options
                              COLORS_ORDER[prevOptions.length % COLORS_ORDER.length],
                    isEmpty: true,
                    hasError: false,
                    id: shortid(),
                },
            ]
        })

        setFocusedOption(options.length)
    }

    const changeOption = (index: number, newOption: EditorDropdownOption) => {
        const newOptions = options.map((option, currentIndex) =>
            currentIndex === index ? { ...newOption, isEmpty: false } : option
        )
        setOptions(newOptions)

        if (newOptions.find(({ isEmpty, value }) => !isEmpty && value.length === 0)) {
            setError('options.options', {
                type: 'validate',
                message: 'Provide a value for each option',
            })
            return
        }

        // If an other option has the same value than the one being edited,
        // set a validation error that will disable the form submission
        // and display the error message.
        if (
            options.find(
                ({ value }, currentIndex) => value === newOption.value && currentIndex !== index
            )
        ) {
            setError('options.options', {
                type: 'validate',
                message: 'You cannot have 2 options with the same value',
            })
            return
        }

        clearErrors('options.options')
        onChange(newOptions.filter(({ isEmpty }) => !isEmpty))
    }

    const deleteOption = (index: number) => {
        const newOptions = [...options.slice(0, index), ...options.slice(index + 1)]
        setOptions(newOptions)
        onChange(newOptions.filter(({ isEmpty }) => !isEmpty))
    }

    const focusOption = (index: number) => {
        setFocusedOption(index)
    }

    const blurOption = () => {
        if (!focusedOption) {
            return
        }

        // If the option has the same value than an other option,
        // set the flag hasError to true in order to have the visual
        // indication on this option.
        // The form has already the error, set when editing.
        const option = options[focusedOption]
        if (options.find(({ value }, index) => index !== focusedOption && value === option.value)) {
            setOptions((prevOptions) => [
                ...prevOptions.slice(0, focusedOption),
                { ...option, hasError: true },
                ...prevOptions.slice(focusedOption + 1),
            ])
        }

        setFocusedOption(null)
    }

    return {
        allowDropdownColors,
        options,
        focusedOption,
        focusOption,
        blurOption,
        provideKey,
        reorder: onReorder,
        addEmptyOption,
        changeOption,
        deleteOption,
    }
}

export default useDropdownEditorHandler

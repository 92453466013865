import React, { useContext } from 'react'

export type TableViewRowContextValue = {
    record?: RecordDto
}
export const TableViewRowContext = React.createContext<TableViewRowContextValue | null>(null)

export function useTableViewRowContext(): TableViewRowContextValue {
    const context = useContext(TableViewRowContext)

    if (!context) {
        throw new Error('useTableViewRowContext must be used within a TableViewRowContextProvider')
    }

    return context
}

import * as Parts from './Popup.parts'

export { Popup } from './Popup'
export { PopupContent } from './PopupContent'
export { PopupFooter } from './PopupFooter'
export { PopupInner } from './PopupInner'
export { PopupTitle } from './PopupTitle'

export const PopupTrigger = Parts.Trigger
export const PopupCloseTrigger = Parts.Close

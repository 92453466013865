import { AppGroup, AppUser } from 'features/users/AppUsers/types'

type UseAppUsersRevokeAccessModalStateOptions = {
    user?: AppUser
    group?: AppGroup
}

export function useAppUsersRevokeAccessModalState(
    options: UseAppUsersRevokeAccessModalStateOptions
) {
    const { user, group } = options

    const isGroup = !!group

    const userName = `${user?.firstName} ${user?.lastName}`.trim()

    const title = isGroup ? 'Revoke group access' : 'Revoke user access'
    const groupName = group?.name ?? 'Unknown'

    return {
        title,
        groupName,
        userName,
        isGroup,
    }
}

import React from 'react'

import {
    WorkflowManagerBreadcrumb,
    WorkflowManagerContext,
    WorkflowManagerPane,
} from './useWorkflowManagerContext'

export type WorkflowManagerProviderProps = {
    children: React.ReactNode
    currentPane: WorkflowManagerPane
    breadcrumbs: WorkflowManagerBreadcrumb[]
    navigateToPane: (pane: WorkflowManagerPane) => void
    navigateBack: () => void
}

export function WorkflowManagerContextProvider({
    children,
    currentPane,
    breadcrumbs,
    navigateToPane,
    navigateBack,
}: WorkflowManagerProviderProps) {
    return (
        <WorkflowManagerContext.Provider
            value={{
                currentPane,
                breadcrumbs,
                navigateToPane,
                navigateBack,
            }}
        >
            {children}
        </WorkflowManagerContext.Provider>
    )
}

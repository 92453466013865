import { UseQueryOptions } from 'react-query'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import {
    ACCOUNT_QUERY_RETRY_OPTIONS,
    buildQueryKey,
    queryClient,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from 'data/hooks/_helpers'

import { invalidateObjects } from './objects/objectOperations'
import { invalidateAppUsersList } from './users/invalidateAppUsersList'
import { invalidateWorkspaceUsersList } from './users/main'
import { invalidateFields } from './fields'

const ENDPOINT = 'access-grants/'
const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useGrants'

const useQueryKey = () => {
    return useQueryKeyBuilder([LIST_NAME], { includeAuthKeys: false, includeStackId: true })
}

const getQueryKey = () => {
    return buildQueryKey([LIST_NAME], { includeAuthKeys: false, includeStackId: true })
}

export const useGrants = (options: UseQueryOptions<AccessGrant[]> = {}) => {
    const { user } = useAuthContext()

    return useQuery<AccessGrant[]>(
        useQueryKey(),
        ENDPOINT,
        { ...options, ...ACCOUNT_QUERY_RETRY_OPTIONS, enabled: !!user },
        {
            bypassMatchingStackCheck: true,
            bypassPreviewAs: true,
        }
    )
}

export const useCreateGrant = () => {
    return useCreateItem(getQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateAppUsersList()
            invalidateWorkspaceUsersList()
            invalidateFields()
            invalidateGrants()
            invalidateObjects()
        },
    })
}
export const useUpdateGrant = () => {
    return useUpdateItem(getQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateAppUsersList()
            invalidateWorkspaceUsersList()
            invalidateFields()
            invalidateGrants()
            invalidateObjects()
        },
    })
}
export const useDeleteGrant = () => {
    return useDeleteItem(getQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateAppUsersList()
            invalidateWorkspaceUsersList()
            invalidateFields()
            invalidateGrants()
            invalidateObjects()
        },
    })
}

export const invalidateGrants = () => {
    return queryClient.invalidateQueries(getQueryKey())
}

import React from 'react'

import { useRadioGroup } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Box } from 'v2/ui'
import { BackgroundAlignBottom, BackgroundAlignMiddle, BackgroundAlignTop } from 'v2/ui/svgs'

import ViewHeaderPositionRadio from './ViewHeaderPositionRadio'

const StyledRadioButtonGroup = styled(Box)`
    display: flex;
    align-items: center;
`

type Value = PageHeaderImage['position']

type RadioOption = {
    label: string
    value: Value
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

const options: RadioOption[] = [
    {
        label: 'Top',
        value: 'top',
        icon: ({ fill }) => <BackgroundAlignTop fill={fill} />,
    },
    {
        label: 'Middle',
        value: 'center',
        icon: ({ fill }) => <BackgroundAlignMiddle fill={fill} />,
    },
    {
        label: 'Bottom',
        value: 'bottom',
        icon: ({ fill }) => <BackgroundAlignBottom fill={fill} />,
    },
]

type ViewHeaderPositionInputProps = Omit<
    React.ComponentPropsWithoutRef<typeof Box>,
    'value' | 'onChange'
> & {
    value?: Value
    onChange: (newValue: Value) => void
}

const ViewHeaderPositionInput: React.FC<ViewHeaderPositionInputProps> = ({
    value,
    onChange,
    ...props
}) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
        value: value,
        onChange: (value) => onChange(value as Value),
    })
    const group = getRootProps()
    return (
        <StyledRadioButtonGroup gap={2} {...props} {...group}>
            {options.map(({ label, value: optionValue, icon }) => {
                const radio = getRadioProps({ value: optionValue })
                return (
                    <ViewHeaderPositionRadio
                        {...radio}
                        key={optionValue}
                        label={label}
                        icon={icon}
                    />
                )
            })}
        </StyledRadioButtonGroup>
    )
}

export default ViewHeaderPositionInput

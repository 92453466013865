import React from 'react'

import { TableGrantForm } from 'features/users/app-users-modal/TableGrants/TableGrantForm'

import { Box } from 'ui/components/Box'

import { useAppUsersCreateTableGroupState } from './hooks/useAppUsersCreateTableGroupState'

type AppUsersCreateTableGroupPaneProps = {}

export const AppUsersCreateTableGroupPane: React.FC<AppUsersCreateTableGroupPaneProps> = () => {
    const { onClose } = useAppUsersCreateTableGroupState()

    return (
        <Box p="3xl">
            <TableGrantForm onClose={onClose} />
        </Box>
    )
}

import React, { useState } from 'react'

import classnames from 'classnames'

import { useAppUserContext } from 'app/useAppUserContext'
import { useWorkspaceUsers } from 'data/hooks/users/main'
import { UserDisplay } from 'features/users/shared/UserDisplay'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Icon } from 'ui/components/Icon'

import { getUserDisplayName } from './assigneeUtils'
import { TasksAssigneesEditor } from './TasksAssigneesEditor'
import { TaskAssignee } from './types'

import { EditorStyles, SelectedUserStyles, WrapperStyles } from './TaskAssigneesDisplayStyles.css'

type AssigneedsDisplayProps = BoxProps & {
    assignees: TaskAssignee[]
    onChangeAssignees: (assignees: TaskAssignee[]) => void
    displayPlaceholder?: boolean
}
export function AssigneesDisplay({
    assignees,
    onChangeAssignees,
    displayPlaceholder,
    ...props
}: AssigneedsDisplayProps) {
    const { user: currentUser } = useAppUserContext()
    const currentUserId = currentUser?._sid
    const { data: users } = useWorkspaceUsers()
    const selectedUser = (users as UserDto[] | undefined)?.find((u) => u._sid === assignees?.[0])
    const [isOpen, setIsOpen] = useState(false)
    console.log('# is open', isOpen)
    return (
        <Box {...props} className={classnames([WrapperStyles, props.className])}>
            <Box position="relative" fontSize="bodyS" style={{ width: '100px', height: '24px' }}>
                <TasksAssigneesEditor
                    placeholder={
                        <Box
                            rounded="pill"
                            background="surfaceStrong"
                            width="3xl"
                            height="3xl"
                            flex
                            center
                            justifyContent="center"
                        >
                            <Icon name="User" />
                        </Box>
                    }
                    className={EditorStyles({ showEditor: isOpen || displayPlaceholder })}
                    position="absolute"
                    variant="tertiary"
                    value={assignees}
                    onChange={onChangeAssignees}
                    currentUserId={currentUserId}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    style={{ minWidth: '60px', top: '-2px' }}
                />
                {selectedUser && (
                    <UserDisplay
                        className={SelectedUserStyles({ showEditor: isOpen })}
                        // position="absolute"
                        style={{
                            marginLeft: '9px',
                        }}
                        showName={false}
                        user={{
                            ...selectedUser,
                            name: getUserDisplayName(selectedUser, currentUserId),
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

import React, { useContext } from 'react'

export type TableViewGridContextValue = {
    onColumnResize: (columnId: string, width: number) => void
}
export const TableViewGridContext = React.createContext<TableViewGridContextValue | null>(null)

export function useTableViewGridContext(): TableViewGridContextValue {
    const context = useContext(TableViewGridContext)

    if (!context) {
        throw new Error(
            'useTableViewGridContext must be used within a TableViewGridContextProvider'
        )
    }

    return context
}

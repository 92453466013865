import { useCallback } from 'react'

type UseValueParserProps = {}

/**
 * Parse the value to be inserted into the grid.
 * This is currently used after pasting into the grid.
 */
export function useValueParser({}: UseValueParserProps) {
    return useCallback((newValue: string, field: FieldDto, _object: ObjectDto): string => {
        switch (field.type) {
            case 'checkbox':
                return newValue.toLowerCase() === 'true' ? 'true' : 'false'

            case 'currency': {
                const symbol = field.options?.currency_symbol || ''
                const value = newValue.replace(symbol, '').replaceAll(',', '').trim()

                return value
            }
            case 'percentage':
                const percentage = newValue.replace('%', '').replaceAll(',', '').trim()
                const value = parseFloat(percentage) / 100

                return value.toString()
            default:
                return newValue
        }
    }, [])
}

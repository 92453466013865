import { getDefaultedPageHeader } from './viewHeaderDefaults'

export function patchListViewHeader(
    options?: ListViewOptions,
    pageHeaderPatch?: Partial<PageHeader>
): ListViewOptions {
    const existingOptions = options ?? {}
    const optionsPatch = getListViewOptionsFromHeaderOptions(pageHeaderPatch, existingOptions)

    return { ...options, ...optionsPatch } as ListViewOptions
}

export function getModularHeaderOptionsFromListViewOptions(
    options: Partial<ListViewOptions> | undefined
): PageHeaderModular {
    return getDefaultedPageHeader({
        type: 'modular',
        title: options?.headerTitle,
        subtitle: options?.headerDesc,
        collapsibleWidgetsType: options?.collapsibleWidgetsType,
        collapsibleWidgets: options?.collapsibleWidgets || [],
    }) as PageHeaderModular
}

export function getHeaderOptionsFromListViewOptions(
    options?: Partial<ListViewOptions>
): PageHeader {
    if (!options) return getDefaultedPageHeader()

    const determinedOptions = getDefaultedPageHeader({
        type: 'image',
        showOverlay: true,
        size: 'small',
        showBackgroundColor: false,
        colorMode: 'dark',
    }) as PageHeaderImage

    const {
        headerType,
        headerTitle,
        headerDesc,
        headerImage,
        headerImageMode,
        headerImagePosition,
        headerImageOverlay,
        headerImageSize,
        headerShowBackgroundColor,
    } = options

    if (isSet(headerType)) determinedOptions.type = headerType as (typeof determinedOptions)['type']
    if (isSet(headerTitle)) determinedOptions.title = headerTitle
    if (isSet(headerDesc)) determinedOptions.subtitle = headerDesc
    if (isSet(headerImage)) determinedOptions.src = headerImage
    if (isSet(headerImageMode)) determinedOptions.colorMode = headerImageMode
    if (isSet(headerImagePosition)) determinedOptions.position = headerImagePosition
    if (isSet(headerImageOverlay)) determinedOptions.showOverlay = headerImageOverlay
    if (isSet(headerImageSize)) determinedOptions.size = headerImageSize
    if (isSet(headerShowBackgroundColor)) {
        determinedOptions.showBackgroundColor = headerShowBackgroundColor
    }

    return determinedOptions
}

const listOptionsDefaults: Partial<ListViewOptions> = {
    headerType: 'image',
    headerTitle: '',
    headerDesc: '',
    headerImage: '',
    headerImageMode: 'dark',
    headerImageOverlay: true,
    headerImagePosition: 'center',
    headerImageSize: 'small',
    headerShowBackgroundColor: false,
}

export function getListViewOptionsFromHeaderOptions(
    headerOptions: Partial<PageHeader> | undefined,
    defaults: Partial<ListViewOptions> = listOptionsDefaults
): Partial<ListViewOptions> {
    const listOptions = { ...defaults }

    if (!headerOptions) return listOptions

    const {
        type,
        title,
        subtitle,
        src,
        colorMode,
        position,
        showOverlay,
        size,
        showBackgroundColor,
    } = headerOptions as PageHeaderImage

    if (isSet(type)) listOptions.headerType = type
    if (isSet(title)) listOptions.headerTitle = title
    if (isSet(subtitle)) listOptions.headerDesc = subtitle

    if (type === 'image') {
        if (isSet(src)) listOptions.headerImage = src
        if (isSet(colorMode)) listOptions.headerImageMode = colorMode
        if (isSet(position)) listOptions.headerImagePosition = position
        if (isSet(size)) listOptions.headerImageSize = size
        if (isSet(showOverlay)) listOptions.headerImageOverlay = showOverlay
        if (isSet(showBackgroundColor)) listOptions.headerShowBackgroundColor = showBackgroundColor
    }

    return listOptions
}

function isSet(property: unknown): boolean {
    return typeof property !== 'undefined' && property !== null
}

import React from 'react'

import { AggregationBlock } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/AggregationBlock'
import type { LayoutEditorBlock } from 'v2/blocks/types'

import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'

import isValidChart from './utils/isValidChart'
import { ChartBlock } from './ChartBlock'
import { ChartsBlockEditor } from './ChartsBlockEditor'
import { ChartsBlockEmptyState } from './ChartsBlockEmptyState'
import { InvalidChartBlock } from './InvalidChartBlock'

type ChartsBlockProps = {
    isOnListView?: boolean
}

const ChartsBlock: LayoutEditorBlock<ChartsBlockProps, ChartsBlock> = ({
    attrs,
    block,
    context,
    isOnListView,
}) => (
    <AggregationBlock
        attrs={attrs}
        blockId={block?.id}
        context={context}
        isOnListView={isOnListView}
        Block={ChartBlock}
        BlockEmptyState={ChartsBlockEmptyState}
        BlockInvalidState={InvalidChartBlock}
        isValid={isValidChart}
    />
)

ChartsBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    configuratorWrapper({
        Element: ChartsBlockEditor,
        field: 'metrics',
        simple: true,
        fullWidth: true,
        hideLabel: true,
        requireObject: false,
    }),
]

export default ChartsBlock

import { createContext, useContext } from 'react'

import { UseComboboxReturnValue } from 'downshift'

export type ComboboxContextReturn = UseComboboxReturnValue<any>

export const ComboboxContext = createContext<ComboboxContextReturn>({} as ComboboxContextReturn)
export const useComboboxContext = () => useContext(ComboboxContext)

export type Item = Record<string, unknown>

import { useCallback, useMemo, useState } from 'react'

import { useListViewContext } from 'features/views/ListView/useListViewContext'

import useDebounce from 'v2/ui/utils/useDebounce'

import { ColorScheme, colorSchemeNames } from 'ui/styling/baseVariables/colors/colorPalette'
import { useTheme } from 'ui/styling/themes/useTheme'

const DEBOUNCE_RATE = 300

type UseListHeaderPortalControlsStateOptions = {
    header: ListViewHeaderPortal
    updateHeader: (header: Partial<ListViewHeaderPortal>) => void
}

export function useListHeaderPortalControlsState(options: UseListHeaderPortalControlsStateOptions) {
    const { header, updateHeader } = options

    const { title: titlePlaceholder } = useListViewContext()

    const debouncedUpdateHeader = useDebounce(updateHeader, DEBOUNCE_RATE, [updateHeader])

    const style = header.style

    const handleChangeStyle = useCallback(
        (value: string) => {
            updateHeader({
                style: value as ListViewHeaderPortal['style'],
            })
        },
        [updateHeader]
    )

    const backgroundColorOptions = useMemo(() => {
        return colorSchemeNames.reduce(
            (acc, color) => {
                if (color !== 'Theme') {
                    acc.push({
                        label: color,
                        value: color,
                        color: `${color.toLowerCase()}600`,
                    })
                }

                return acc
            },
            [] as { label: string; value: string; color: string }[]
        )
    }, [])

    const backgroundColor = header.backgroundColor
    const { colorScheme } = useTheme()

    const handleChangeBackgroundColor = useCallback(
        (value?: string) => {
            updateHeader({
                backgroundColor: (value as ColorScheme | undefined) || colorScheme,
            })
        },
        [colorScheme, updateHeader]
    )

    const backgroundColorPreview = `${backgroundColor.toLowerCase()}600`

    const [eyebrow, setEyebrow] = useState(header.eyebrow)

    const handleChangeEyebrow = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setEyebrow(value)

            debouncedUpdateHeader({
                eyebrow: value.trim(),
            })
        },
        [debouncedUpdateHeader]
    )

    const [title, setTitle] = useState(header.title)

    const handleChangeTitle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setTitle(value)

            debouncedUpdateHeader({
                title: value.trim(),
            })
        },
        [debouncedUpdateHeader]
    )

    const [subtitle, setSubtitle] = useState(header.subtitle)

    const handleChangeSubtitle = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const value = e.target.value

            setSubtitle(value)

            debouncedUpdateHeader({
                subtitle: value.trim(),
            })
        },
        [debouncedUpdateHeader]
    )

    const coverImage = header.coverImageUrl

    const handleChangeCoverImage = useCallback(
        (value: string) => {
            updateHeader({
                coverImageUrl: value,
            })
        },
        [updateHeader]
    )

    const searchPlacement = header.searchPlacement

    const handleChangeSearchPlacement = useCallback(
        (value: string) => {
            updateHeader({
                searchPlacement: value as ListViewHeaderPortal['searchPlacement'],
            })
        },
        [updateHeader]
    )

    const textAlignment = header.textAlignment

    const handleChangeTextAlignment = useCallback(
        (value: string) => {
            updateHeader({
                textAlignment: value as ListViewHeaderPortal['textAlignment'],
            })
        },
        [updateHeader]
    )

    return useMemo(
        () => ({
            style,
            handleChangeStyle,
            backgroundColorOptions,
            backgroundColor,
            backgroundColorPreview,
            handleChangeBackgroundColor,
            title,
            handleChangeTitle,
            titlePlaceholder,
            subtitle,
            handleChangeSubtitle,
            eyebrow,
            handleChangeEyebrow,
            coverImage,
            handleChangeCoverImage,
            searchPlacement,
            handleChangeSearchPlacement,
            textAlignment,
            handleChangeTextAlignment,
        }),
        [
            backgroundColor,
            backgroundColorOptions,
            backgroundColorPreview,
            coverImage,
            eyebrow,
            handleChangeBackgroundColor,
            handleChangeCoverImage,
            handleChangeEyebrow,
            handleChangeSearchPlacement,
            handleChangeStyle,
            handleChangeSubtitle,
            handleChangeTextAlignment,
            handleChangeTitle,
            searchPlacement,
            style,
            subtitle,
            textAlignment,
            title,
            titlePlaceholder,
        ]
    )
}

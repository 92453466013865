import React from 'react'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

type EmbeddedNoRecordsEmptyStateProps = {}

export const EmbeddedNoRecordsEmptyState: React.FC<EmbeddedNoRecordsEmptyStateProps> = React.memo(
    function EmbeddedNoRecordsEmptyState() {
        return (
            <Box
                flex
                center
                flexDirection="column"
                textAlign="center"
                justifyContent="center"
                py="2xl"
            >
                <Body size="m" color="textWeaker">
                    This list is empty
                </Body>
            </Box>
        )
    }
)

import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button as NewButton } from 'ui/components/Button'
import Button from 'ui/deprecated/atoms/Button'

export const DiscardButton = (props: {
    hideByDefault?: boolean
    children?: any
    style?: any
    v4?: boolean
}) => {
    const { formState, reset } = useFormContext()

    const hasChanges = useMemo(() => {
        return Object.keys(formState.dirtyFields).length > 0
    }, [formState])

    if (props.hideByDefault && !hasChanges) return null

    return (
        <Button
            as={NewButton}
            type="secondary"
            disabled={!hasChanges || formState.isSubmitting}
            //@ts-ignore
            onClick={() => reset()}
            {...props}
        />
    )
}

import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { useNavigationPortalsItemState } from './hooks/useNavigationPortalsItemState'
import { NavigationItem } from './NavigationItem'
import { PortalIcon } from './PortalIcon'
import { NavigationPortal } from './types'

import {
    WorkspaceNavigationPortalsItemIconWrapperStyles,
    WorkspaceNavigationPortalsItemLabelStyles,
    WorkspaceNavigationPortalsItemStyles,
} from './Navigation.css'

type NavigationPortalsItemProps = React.ComponentPropsWithoutRef<typeof NavigationItem> & {
    portal: NavigationPortal
    isCollapsed?: boolean
}

export const NavigationPortalsItem: React.FC<NavigationPortalsItemProps> = ({
    portal,
    isCollapsed,
    className,
    ...props
}) => {
    const { name, domain, url, isExternalUrl } = portal
    const { isActive } = useNavigationPortalsItemState({ portal })

    return (
        <NavigationItem
            to={isExternalUrl ? undefined : url}
            href={isExternalUrl ? url : undefined}
            aria-label={name}
            label={
                <Box flex column alignSelf="stretch" trim>
                    <Body
                        size="m"
                        weight="medium"
                        trim
                        className={WorkspaceNavigationPortalsItemLabelStyles.styleFunction({
                            isActive,
                        })}
                    >
                        {name}
                    </Body>
                    <Body
                        size="xs"
                        weight="medium"
                        color="textWeak"
                        style={{ marginTop: '-2px' }}
                        trim
                    >
                        {domain}
                    </Body>
                </Box>
            }
            leftSlotContent={
                <Box
                    className={WorkspaceNavigationPortalsItemIconWrapperStyles.styleFunction({
                        isCollapsed,
                    })}
                    noShrink
                >
                    <PortalIcon
                        portal={portal}
                        isActive={isActive}
                        size={isCollapsed ? 'xs' : 'm'}
                    />
                </Box>
            }
            isCollapsed={isCollapsed}
            className={classNames(
                WorkspaceNavigationPortalsItemStyles.styleFunction({
                    isActive,
                    isCollapsed,
                }),
                className
            )}
            {...props}
        />
    )
}

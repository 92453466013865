import { useMemo } from 'react'

import { NavigationPortal } from 'features/navigation/types'

type UsePortalIconStateProps = {
    portal: NavigationPortal
}

export function usePortalIconState({ portal }: UsePortalIconStateProps) {
    const { name } = portal

    const initials = name.slice(0, 2)

    return useMemo(
        () => ({
            initials,
        }),
        [initials]
    )
}

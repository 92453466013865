import { ViewColumn } from 'v2/views/List/types'

const SUPPORTED_FIELD_TYPES = new Set([
    'string',
    'long_text',
    'url',
    'image',
    'checkbox',
    'dropdown',
    'multi_select',
    'lookup',
    'multi_lookup',
    'number',
    'multi_file',
    'percentage',
    'currency',
    'date',
    'datetime',
])

function isFieldCompatible(field: FieldDto): boolean {
    return SUPPORTED_FIELD_TYPES.has(field.type)
}

export function filterCompatibleFields(fields: FieldDto[]): FieldDto[] {
    return fields.filter((field) => isFieldCompatible(field))
}

export function getNumOfFilters(options: ListViewOptions, allFields: FieldDto[]): number {
    const { enableEndUserFilters, enableSpecificEndUserFilters, specificEndUserFilters, columns } =
        options
    if (!enableEndUserFilters) return 0
    if (enableSpecificEndUserFilters && specificEndUserFilters) {
        return specificEndUserFilters.length
    }

    const compatibleFields = allFields.reduce((agg, f) => {
        if (!isFieldCompatible(f)) return agg

        return agg.add(f._sid)
    }, new Set())

    const filtersVisibleForColumns = columns.filter((column) => {
        return compatibleFields.has(column.fieldId)
    })
    return filtersVisibleForColumns.length
}

export type SelectedItem = {
    fieldId: string
    label: string
}

export function filterSelectedFields(
    selectedFieldSids: string[],
    fields: FieldDto[]
): SelectedItem[] {
    const fieldsById = fields.reduce((agg, f) => {
        return agg.set(f._sid, f)
    }, new Map<string, FieldDto>())

    return selectedFieldSids.reduce<SelectedItem[]>((agg, fieldSid) => {
        const field = fieldsById.get(fieldSid)
        if (field) {
            agg.push({
                fieldId: fieldSid,
                label: field.label,
            })
        }

        return agg
    }, [])
}

export function filterColumnsBySelectedFields<T extends ViewColumn>(
    columns: T[],
    selectedFieldSids: string[]
): T[] {
    const columnsByFieldId = columns.reduce((agg, c) => {
        const field = c.field
        if (field) agg.set(field._sid, c)

        return agg
    }, new Map<string, T>())

    return selectedFieldSids.reduce<T[]>((agg, fieldSid) => {
        const column = columnsByFieldId.get(fieldSid)
        if (column) agg.push(column)

        return agg
    }, [])
}

import React from 'react'

import { isEqual } from 'lodash'

import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'

import { useDateAttributeDisplayState } from './hooks/useDateAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type DateAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    isLoading?: boolean
    showIcon?: boolean
}

export const DateAttributeTag: AttributeDisplayComponent<string, DateAttributeTagProps> =
    React.memo(function DateAttributeTag({ value, field, isLoading, showIcon, ...props }) {
        const { content, icon } = useDateAttributeDisplayState({
            value,
            field,
            isLoading,
            showIcon,
        })

        return (
            <Skeleton isLoading={isLoading}>
                <Tag
                    size="2xs"
                    type="outline"
                    weight="regular"
                    pointerEvents="none"
                    noShrink
                    startIcon={icon}
                    {...props}
                >
                    {content}
                </Tag>
            </Skeleton>
        )
    }, isEqual)

import { useCallback } from 'react'

import { useAppContext } from 'app/useAppContext'
import { LINK_TYPES } from 'data/utils/fieldDefinitions'

import { ContextEntry, ContextGroup, ContextItem } from './types'
import { checkTypeCompatibility } from './utilities'

export function useCheckContextItemType(
    returnType?: FieldType,
    returnExtraOptions?: WorkflowExtraOptions
) {
    const { selectedStack } = useAppContext()

    const checkContextItemType = useCallback(
        (suppliedItem: ContextEntry) => {
            if (!returnType) return true

            if (suppliedItem.type === 'group') {
                return true
            }
            const item: ContextItem = suppliedItem as ContextItem

            if (item.type === 'function') {
                return true
            }

            // If these are two link types and either they match exactly,
            // or the returnType is a multi and we thus want to allow single lookups to match.
            if (LINK_TYPES.includes(returnType)) {
                const isMultiLookup = returnType === 'multi_lookup'
                const itemTargetStackId = selectedStack?._sid
                const returnTypeTargetStackId = selectedStack?._sid

                return (
                    LINK_TYPES.includes(item.type) &&
                    (isMultiLookup || suppliedItem.type === returnType) &&
                    ((item.extra_options?.link_target_object_id ===
                        returnExtraOptions?.link_target_object_id &&
                        itemTargetStackId === returnTypeTargetStackId) ||
                        returnExtraOptions?.link_target_object_id === undefined)
                )
            }

            // Be able to use strings as multi_file field values.
            if (suppliedItem.type === 'string' && returnType === 'multi_file') return true

            return checkTypeCompatibility(
                item.type,
                item.extra_options,
                returnType,
                returnExtraOptions
            )
        },
        [returnType, returnExtraOptions, selectedStack?._sid]
    )

    const applyTypeCheckToItems = useCallback(
        (items: ContextEntry[]): ContextEntry[] => {
            return items
                .map((item) => {
                    if (item.type === 'group') {
                        const group = item as ContextGroup
                        return {
                            ...group,
                            items: group.items ? applyTypeCheckToItems(group.items) : undefined,
                        } as ContextGroup
                    } else {
                        return checkContextItemType(item) ? item : undefined
                    }
                })
                .filter((item) => item !== undefined) as ContextEntry[]
        },
        [checkContextItemType]
    )

    return { checkContextItemType, applyTypeCheckToItems }
}

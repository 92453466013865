import React from 'react'

import { Box } from 'ui/components/Box'

import { PillValue } from './PillValue'
import { DataGridCellRendererComponent } from './types'

type MagicUserFieldCellRendererProps = {}
export const MagicUserFieldCellRenderer: DataGridCellRendererComponent<
    MagicUserFieldCellRendererProps
> = ({ value }) => {
    if (!value) return null

    const userRefValue: UserRefDto = value

    return (
        <Box height="full" width="full">
            <PillValue>{userRefValue.name}</PillValue>
        </Box>
    )
}

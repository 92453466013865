import React, { forwardRef } from 'react'

import { PopoverContent } from 'ui/components/Popover'
import { extractStandardProps, StandardComponentProps } from 'ui/helpers/styles'

import * as Parts from './Popup.parts'

type PopupContentRef = HTMLDivElement

type PopupContentProps = React.ComponentPropsWithoutRef<typeof PopoverContent> &
    StandardComponentProps & {}

export const PopupContent = forwardRef<PopupContentRef, PopupContentProps>(
    ({ sideOffset = 4, align = 'start', children, ...props }, ref) => {
        const [standardProps, rootProps] = extractStandardProps(props)

        return (
            <PopoverContent asChild sideOffset={sideOffset} align={align} {...rootProps}>
                <InlinePopupContent ref={ref} {...standardProps}>
                    {children}
                </InlinePopupContent>
            </PopoverContent>
        )
    }
)

type InlinePopupContentProps = React.PropsWithChildren<StandardComponentProps>

export const InlinePopupContent = forwardRef<PopupContentRef, InlinePopupContentProps>(
    (props, ref) => {
        return <Parts.Root ref={ref} {...props} />
    }
)

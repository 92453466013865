import React, { useCallback, useMemo, useRef, useState } from 'react'

import { FieldsEditorContextImpl } from './fieldsEditorContext'
import { Item } from './types'

type FieldsEditorContextProviderProps = React.PropsWithChildren<{}>

export const FieldsEditorContextProvider: React.FC<FieldsEditorContextProviderProps> = ({
    children,
}) => {
    const [editingFieldItem, setEditingFieldItem] = useState<Item | undefined>()
    const [isEditorOpen, setIsEditorOpen] = useState(false)
    const [openEditorInPortal, setOpenEditorInPortal] = useState(false)

    const previousEditorTitle = useRef<string | undefined>()
    const setPreviousEditorTitle = useCallback((title: string | undefined) => {
        previousEditorTitle.current = title
    }, [])

    const context = useMemo(
        () => ({
            editingFieldItem,
            setEditingFieldItem,
            isEditorOpen,
            setIsEditorOpen,
            openEditorInPortal,
            setOpenEditorInPortal,
            previousEditorTitle: previousEditorTitle.current,
            setPreviousEditorTitle,
        }),
        [editingFieldItem, isEditorOpen, openEditorInPortal, setPreviousEditorTitle]
    )

    return (
        <FieldsEditorContextImpl.Provider value={context}>
            {children}
        </FieldsEditorContextImpl.Provider>
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { ColorScheme } from 'ui/styling/baseVariables/colors/colorPalette'
import { Breakpoint } from 'ui/styling/helpers/breakpoints'

import makeAdminThemeVariables from './admin'
import makeDefaultThemeVariables from './default'

export type ThemeName = 'default' | 'dark' | 'admin' | 'onboarding'
export type ThemeProviderRef = HTMLDivElement
export type ThemeProviderProps = React.ComponentPropsWithoutRef<typeof Box> & {
    theme?: ThemeName
}
export type ThemeContextValue = {
    theme: ThemeName
    themeClassName: string | undefined
    themeRawVariables: ReturnType<typeof mapThemeNameToRawVariables>
    breakpoint: Breakpoint
    colorScheme: ColorScheme
}

export function mapThemeNameToRawVariables(themeName: ThemeName) {
    switch (themeName) {
        case 'admin':
            return makeAdminThemeVariables()
        case 'onboarding':
            return makeAdminThemeVariables()
        default:
            return makeDefaultThemeVariables()
    }
}

import React from 'react'

import { Box } from 'ui/components/Box'

import { useAppNavigationState } from './hooks/useAppNavigationState'
import { AppNavigationContextProvider } from './AppNavigationContextProvider'
import { AppNavigationHeader } from './AppNavigationHeader'
import { AppNavigationMenu } from './AppNavigationMenu'

import { NavigationScrollAreaStyle } from './Navigation.css'

type AppNavigationProps = {}

export const AppNavigation: React.FC<AppNavigationProps> = () => {
    const { selectedApp, wrapperRef, scrollAreaRef } = useAppNavigationState()

    if (!selectedApp) return null

    return (
        <AppNavigationContextProvider app={selectedApp}>
            <Box
                flex
                column
                gap="xl"
                minHeight={0}
                grow
                shrink
                ref={wrapperRef as React.RefObject<HTMLDivElement>}
                className={NavigationScrollAreaStyle}
            >
                <AppNavigationHeader />
                <AppNavigationMenu ref={scrollAreaRef} />
            </Box>
        </AppNavigationContextProvider>
    )
}

import * as React from 'react'

import { getNavigationColor } from 'v2/ui/hooks/useNavigationColor'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import Flex from './Flex'

export type PreviewBackgroundProps = {
    style?: any
    navColor: 'light' | 'dark' | 'brand'
    brandColor: string
}

const PreviewBackground: React.FC<PreviewBackgroundProps> = ({
    children,
    style = {},
    navColor,
    brandColor,
}) => {
    const navTheme = getNavigationColor({ navColor, brandColor })

    return (
        <Flex
            column
            style={{
                background: navTheme.bgScdNav,
                padding: 10,
                borderRadius: 5,
                width: 224,
                alignItems: 'center',
                position: 'relative',
                border:
                    navColor === 'light' ? '1px solid ' + V4DesignSystem.colors.gray[100] : 'none',
                ...style,
            }}
        >
            {children}
        </Flex>
    )
}

export default PreviewBackground

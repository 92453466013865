import { getCurrentStackId } from 'app/GlobalStaticState'

import { queryClient, useQuery } from './_helpers'
/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'app-errors'
const ENDPOINT = 'app-errors/'

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useAppErrors(options = {}) {
    const stackId = getCurrentStackId()
    return useQuery([LIST_NAME, stackId], ENDPOINT, options)
}

export function invalidateAppErrors() {
    const stackId = getCurrentStackId()
    return queryClient.invalidateQueries([LIST_NAME, stackId])
}

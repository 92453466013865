import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AdvancedFilter } from 'features/views/ListView/ListHeader/Filters/Advanced/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

type UseAdvancedFilterValueTextStateOptions = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
}

export function useAdvancedFilterValueTextState(options: UseAdvancedFilterValueTextStateOptions) {
    const { filter, onChange, onRemoveFilter } = options

    const inputRef = useRef<HTMLInputElement>(null)

    const filterValue = getValueFromFilter(filter)
    const filterValueMemo = useDeepEqualsMemoValue(filterValue)

    const [value, setValue] = useState(filterValue)
    const valueRef = useRef(value)
    valueRef.current = value

    // Sync value with filter on update.
    useEffect(() => {
        setValue(filterValueMemo)
    }, [filterValueMemo])

    const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue([e.target.value])
    }, [])

    const onInputBlur = useCallback(() => {
        const value = valueRef.current
        const textValue = value[0]

        const isEmpty = !textValue
        if (isEmpty && filter.isDraft) {
            return
        }

        onChange(value[0])
    }, [filter.isDraft, onChange])

    const onInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                onInputBlur()
            }

            if (e.key === 'Escape' && filter.isDraft) {
                e.preventDefault()
                onRemoveFilter()
            }
        },
        [filter.isDraft, onInputBlur, onRemoveFilter]
    )

    // Focus input when operation is changed, and the filter is empty.
    useEffectOnlyOnUpdate(() => {
        requestAnimationFrame(() => {
            const isEmpty = !valueRef.current[0]
            if (!isEmpty) return

            if (inputRef.current) {
                inputRef.current.focus()
            }
        })
    }, [filter.options.option])

    return useMemo(
        () => ({
            onInputChange,
            onInputBlur,
            onInputKeyDown,
            value,
            inputRef,
        }),
        [onInputChange, onInputBlur, onInputKeyDown, value]
    )
}

function getValueFromFilter(filter: AdvancedFilter): string[] {
    const filterValue = filter.options?.value

    if (Array.isArray(filterValue)) {
        return filterValue
    }

    if (filterValue) {
        return [filterValue]
    }

    return ['']
}

import { useContext } from 'react'

import { AppUsersContext, AppUsersContextValue } from './AppUsersContext'

export function useAppUsersContext(): AppUsersContextValue {
    const context = useContext(AppUsersContext)

    if (!context) {
        throw new Error('useAppUsersContext must be used within a AppUsersContextProvider')
    }

    return context
}

export const evaluateThemeFormulas = (theme) => {
    const evaluatedTheme = {}
    Object.keys(theme).forEach(
        (key) => (evaluatedTheme[key] = evaluateThemeFormula(theme[key], theme))
    )
    return evaluatedTheme
}

export const evaluateThemeFormula = (themeFormula) => {
    if (!themeFormula || typeof themeFormula !== 'string') return themeFormula
    let result = themeFormula
    const re = /({(theme).*?})/g
    const matches = themeFormula.match(re)
    if (matches && matches.length) {
        matches.forEach((match) => {
            try {
                const formula = match.slice(1, match.length - 1)
                const evaluated = eval(formula)
                result = result.replace(match, evaluated)
            } finally {
            }
        })
    }
    return result
}

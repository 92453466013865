import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { ListHeaderDefaultStickyStyle } from 'features/views/ListView/ListHeader/ListHeaderDefault/ListHeaderDefault.css'
import { useListHeaderContext } from 'features/views/ListView/ListHeader/useListHeaderContext'

export function useListHeaderDefaultState() {
    const {
        value,
        viewTitle,
        recordCount,
        view,
        isEmbedded,
        allowCreate,
        inlineFilterType,
        availableInlineFilterFields,
        clearInlineFilters,
    } = useListHeaderContext()

    const formattedRecordCount = formatRecordCount(recordCount)

    const supportsInlineSorting = view.options.inlineSorting ?? false
    const supportsQuickFilters =
        inlineFilterType === 'quick' && !!availableInlineFilterFields.length

    const supportsAdvancedFilters = inlineFilterType === 'advanced'
    const [advancedFiltersVisible, setAdvancedFiltersVisible] = useState(false)
    const toggleAdvancedFilters = useCallback(() => {
        setAdvancedFiltersVisible((prev) => !prev)
    }, [])

    const [showRecordCount, setShowRecordCount] = useState(true)

    // Add a classname to the header when it's sticky.
    const headerRef = useRef<HTMLElement>(null)
    useLayoutEffect(() => {
        if (isEmbedded) return

        const scrollParent = document.querySelector('#contentWrapper')
        if (!scrollParent) return

        const scrollHandler = () => {
            const headerEl = headerRef.current
            if (!headerEl) return

            if (scrollParent.scrollTop > 0) {
                headerEl.classList.add(ListHeaderDefaultStickyStyle)
            } else {
                headerEl.classList.remove(ListHeaderDefaultStickyStyle)
            }
        }

        scrollParent.addEventListener('scroll', scrollHandler)
        return () => {
            scrollParent.removeEventListener('scroll', scrollHandler)
        }
    }, [isEmbedded])

    // Clear filters when switching between inline filter types.
    useLayoutEffect(() => {
        setAdvancedFiltersVisible(false)
        clearInlineFilters()
    }, [clearInlineFilters, inlineFilterType])

    const title = value.title || viewTitle

    const subtitle = value.subtitle

    const [isSubtitleVisible, setIsSubtitleVisible] = useState(false)

    const toggleIsSubtitleVisible = useCallback(() => {
        setIsSubtitleVisible((prev) => !prev)
    }, [])

    return useMemo(
        () => ({
            headerRef,
            title,
            subtitle,
            recordCount: formattedRecordCount,
            supportsInlineSorting,
            showRecordCount,
            setShowRecordCount,
            supportsQuickFilters,
            supportsAdvancedFilters,
            isEmbedded,
            allowCreate,
            advancedFiltersVisible,
            toggleAdvancedFilters,
            isSubtitleVisible,
            toggleIsSubtitleVisible,
        }),
        [
            advancedFiltersVisible,
            allowCreate,
            formattedRecordCount,
            isEmbedded,
            isSubtitleVisible,
            showRecordCount,
            subtitle,
            supportsAdvancedFilters,
            supportsInlineSorting,
            supportsQuickFilters,
            title,
            toggleAdvancedFilters,
            toggleIsSubtitleVisible,
        ]
    )
}

function formatRecordCount(count: number) {
    if (count === 0) return ''

    if (count === 1) {
        return `${count} record`
    }

    return `${count} records`
}

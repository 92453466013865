import React from 'react'

import { Button, Icon } from 'v2/ui'
import { IconProps } from 'v2/ui/components/Icon'
import { SolidCog } from 'v2/ui/svgs'

type ListViewMenuToggleTriggerProps = {
    label: React.ReactNode
    icon?: string
    svg?: IconProps['svg']
    onClick: React.MouseEventHandler
    onKeyDown?: React.KeyboardEventHandler
    showCog?: boolean
    style?: React.CSSProperties
}

const ListViewMenuToggleTrigger = React.forwardRef(
    (
        {
            onClick,
            onKeyDown,
            svg,
            icon,
            label,
            style = {},
            showCog = false,
        }: ListViewMenuToggleTriggerProps,
        ref
    ) => {
        return (
            <Button
                ref={ref}
                as="span"
                role="button"
                variant="menuToggle"
                px={2}
                onClick={onClick}
                onKeyDown={onKeyDown}
                svgIcon={showCog ? <SolidCog /> : undefined}
                iconAlign="right"
                iconColor="grey.300"
                tabIndex={0}
                display="inline-flex"
                flexDirection="row"
                style={style}
            >
                {(!!svg || !!icon) && (
                    <Icon
                        svg={svg}
                        icon={icon}
                        mr={2}
                        color="admin.menuToggle.icon"
                        fontSize="admin.menuToggle.icon"
                    />
                )}
                {label}
            </Button>
        )
    }
)
export default ListViewMenuToggleTrigger

import { useCallback } from 'react'

import { getUrl, Urls } from 'app/UrlService'
import { useFavorites } from 'data/hooks/favorites/favorites'
import { FavoriteType } from 'data/hooks/favorites/types'
import { NavigationApp, NavigationAppMenuItem } from 'features/navigation/types'
import { mapNavTreeToAppNavigationMenuItems } from 'features/navigation/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useNavigationContext } from './useNavigationContext'

export type FavoriteItem = {
    id: string
    label: string
    url: string
    isExternalUrl: boolean
    app: NavigationApp
    navigationItem?: NavigationAppMenuItem
}

export function useSystemNavigationItemFavoritesState() {
    const { favorites = [], data, deleteFavorite } = useFavorites()
    const { spaces } = useNavigationContext()

    const appsById = spaces.reduce((acc, space) => {
        for (const app of space.apps) {
            acc.set(app.id, app)
        }
        return acc
    }, new Map<string, NavigationApp>())

    const objectsBySid = data?.objects?.reduce((acc, obj) => {
        return acc.set(obj._sid!, obj as ObjectDto)
    }, new Map<string, ObjectDto>())

    const viewsBySid = data?.views?.reduce((acc, view) => {
        return acc.set(view._sid!, view as ViewDto)
    }, new Map<string, ViewDto>())

    const recordsBySid = data?.records?.reduce((acc, record) => {
        return acc.set(record._sid!, record as RecordDto)
    }, new Map<string, RecordDto>())

    const documentsById = data?.documents?.reduce((acc, document) => {
        return acc.set(document.auto_id!, document as DocumentDto)
    }, new Map<number, DocumentDto>())

    const navItemsById = data?.navigation_items?.reduce((acc, navItem) => {
        return acc.set(navItem._sid!, navItem as NavigationDto)
    }, new Map<string, NavigationDto>())

    const objectUrls = data?.object_urls ?? {}

    const favoriteItems = favorites.reduce((acc, favorite) => {
        const app = appsById.get(favorite.stack_id)
        if (app) {
            const id = favorite.auto_id?.toString() ?? Math.random().toString()

            let label: string = ''
            let url = ''
            let navigationItem: NavigationAppMenuItem | undefined
            switch (favorite.type) {
                case FavoriteType.App:
                    label = app.name
                    url = getUrl(Urls.Home, app.stack)
                    break
                case FavoriteType.NavigationItem:
                    const navItem = navItemsById?.get(favorite.navigation_id!)
                    if (!navItem) {
                        return acc
                    }
                    label = navItem.label
                    url = getUrl(navItem.url, app.stack)
                    navigationItem = mapNavTreeToAppNavigationMenuItems([navItem])[0]
                    break
                case FavoriteType.CreateForm: {
                    const obj = objectsBySid?.get(favorite.object_id!)
                    const view = viewsBySid?.get(favorite.view_id!)
                    if (!obj || !view) {
                        return acc
                    }

                    label = view.options?.title || `${obj.name}: add new`
                    url = getUrl(view.url ?? '/', app.stack)
                    break
                }
                case FavoriteType.Record: {
                    const record = recordsBySid?.get(favorite.record_id!)
                    const obj = objectsBySid?.get(favorite.object_id!)
                    if (!obj || !record) {
                        return acc
                    }

                    label = record._primary?.toString() ?? 'Unknown'
                    url = getUrl(`${objectUrls[obj._sid!]}/view/${record._sid}`, app.stack)
                    break
                }
                case FavoriteType.Document:
                    const document = documentsById?.get(favorite.document_id!)
                    if (!document) {
                        return acc
                    }

                    label = document?.title || 'Untitled'
                    url = getUrl(`/docs/${document?.auto_id}`, app.stack)
                    break
            }

            const isExternalUrl = false

            acc.push({
                id,
                label,
                url,
                isExternalUrl,
                app,
                navigationItem,
            })
        }
        return acc
    }, [] as FavoriteItem[])

    const removeFavorite = useCallback(
        (id: string) => {
            deleteFavorite(Number(id))
        },
        [deleteFavorite]
    )

    return useDeepEqualsMemoValue({
        items: favoriteItems,
        removeFavorite,
    })
}

import { keywordMap } from 'features/formulas/constants/formulaEditorTextConstants'
import { Keyword } from 'features/formulas/formulaTypes'

export const getAllAvailableFunctionKeys = (): (keyof typeof keywordMap)[] => {
    return Object.keys(keywordMap) as (keyof typeof keywordMap)[]
}

export const getAllAvailableFunctions = (): Keyword[] => {
    return getAllAvailableFunctionKeys().map((func) => keywordMap[func])
}

import React from 'react'

import { useWorkflowIntegrations } from 'data/hooks/workflows/integrations'

import { LoadingScreen } from 'v2/ui'
import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Divider } from 'ui/components/Divider'
import { HoverContainerStyle, OpacityOnContainerHover } from 'ui/styles/hoverUtils.css'

import { ConnectionAuthor } from './ConnectionAuthor'
import { IntegrationProviderIcon } from './IntegrationProviderIcon'
import { useConfirmDeleteConnectionModal } from './useConfirmDeleteConnectionModal'

type IntegrationsListProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const IntegrationsList: React.FC<IntegrationsListProps> = (props) => {
    const { data: integrationsData, isLoading } = useWorkflowIntegrations({
        refetchOnMount: true,
    })

    return (
        <Box overflowY="auto" fontSize="bodyM" {...props}>
            <LoadingScreen isLoading={isLoading} minHeight="50px">
                <Container variant="neutralMuted" flex p="m" px="l" color="textWeak">
                    <Box style={{ flexBasis: '30%' }}>Provider</Box>
                    <Box style={{ flexBasis: '30%' }}>Author</Box>
                    <Box style={{ flexBasis: '30%' }}>Created</Box>
                </Container>
                <Divider mb="m" />
                <Box grow>
                    {integrationsData?.connections?.length === 0 && (
                        <Box flex center px="l" py="m" color="textWeak">
                            Set up a connection to get started.
                        </Box>
                    )}
                    {integrationsData?.connections?.map((connection) => {
                        return <IntegrationsListItem key={connection.id} connection={connection} />
                    })}
                </Box>
            </LoadingScreen>
        </Box>
    )
}

type IntegrationsListItemProps = {
    connection: WorkflowIntegrationConnectionDto
}

const IntegrationsListItem: React.FC<IntegrationsListItemProps> = ({ connection }) => {
    return (
        <Box
            flex
            role="listitem"
            background={{ hover: 'surfaceStronger' }}
            p="m"
            px="l"
            className={HoverContainerStyle}
        >
            <Box style={{ flexBasis: '30%' }} flex center>
                <IntegrationsListItemProvider providerId={connection.provider_id} />
            </Box>

            <Box style={{ flexBasis: '30%' }} flex center>
                <ConnectionAuthor userId={connection.user_id} />
            </Box>

            <Box style={{ flexBasis: '30%' }} flex center>
                <ConnectionCreatedAt createdAt={connection.created_at} />
            </Box>

            <Box style={{ marginLeft: 'auto' }} noShrink className={OpacityOnContainerHover}>
                <ConnectionDeleteButton
                    connectionId={connection.id}
                    providerId={connection.provider_id}
                />
            </Box>
        </Box>
    )
}

type IntegrationsListItemProviderProps = React.ComponentPropsWithoutRef<typeof Box> & {
    providerId: string
}

const IntegrationsListItemProvider: React.FC<IntegrationsListItemProviderProps> = ({
    providerId,
    ...props
}) => {
    const { data: integrationsData } = useWorkflowIntegrations()
    const providers = integrationsData?.providers ?? []

    const provider = providers.find((p) => p.id === providerId)
    if (!provider) return null

    return (
        <Box flex center gap="xs" {...props}>
            <IntegrationProviderIcon providerId={providerId} />
            {provider.name}
        </Box>
    )
}

type ConnectionCreatedAtProps = React.ComponentPropsWithoutRef<typeof Box> & {
    createdAt: String
}

const ConnectionCreatedAt: React.FC<ConnectionCreatedAtProps> = ({ createdAt, ...props }) => {
    const timeDisplay = useDisplayTimeFrom(createdAt)

    return (
        <Box fontSize="bodyS" {...props}>
            {timeDisplay}
        </Box>
    )
}

type ConnectionDeleteButtonProps = {
    connectionId: string
    providerId: string
}

const ConnectionDeleteButton: React.FC<ConnectionDeleteButtonProps> = ({
    connectionId,
    providerId,
}) => {
    const showConfirmDeleteModal = useConfirmDeleteConnectionModal()

    return (
        <Button
            variant="ghost"
            size="xs"
            startIcon={{ name: 'X' }}
            onClick={() =>
                showConfirmDeleteModal({
                    connectionId,
                    providerId,
                })
            }
        />
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'

type TriggerConditionProps = React.ComponentPropsWithoutRef<typeof Box> & {
    trigger: WorkflowTriggerConfig
}

export const TriggerCondition: React.FC<TriggerConditionProps> = ({ trigger, ...props }) => {
    const clauseCount = trigger.condition?.clauses?.length || 0
    if (!clauseCount) return null

    const conditionLabel = clauseCount === 1 ? 'condition' : 'conditions'

    return (
        <Box fontSize="bodyXs" color="textWeak" textAlign="right" {...props}>
            on {clauseCount} {conditionLabel}
        </Box>
    )
}

import React, { ComponentPropsWithoutRef } from 'react'

import { Input } from 'ui/components/Input'

import { useComboboxContext } from './ComboboxContext'
type ComboboxInputProps = ComponentPropsWithoutRef<typeof Input>
export function ComboboxInput({ onKeyDown, ...props }: ComboboxInputProps) {
    const context = useComboboxContext()
    return <Input {...context.getInputProps({ onKeyDown })} {...props} />
}

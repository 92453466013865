const resolvedOperations: { [key: string]: FilterOperation } = {
    isContextRecordField: 'is',
    isNotContextRecordField: 'isnt',
    containsContextRecordField: 'containsAny',
    doesNotContainContextRecordField: 'containsNone',
}

const noValueResolvedOperations: { [key: string]: FilterOperation } = {
    isContextRecordField: 'isEmpty',
    isNotContextRecordField: 'isNotEmpty',
    containsContextRecordField: 'isEmpty',
    doesNotContainContextRecordField: 'isNotEmpty',
}
//TODO discuss with PO if falling back to isEmpty is ok behavior and what to do with contains operations
export const resolveContextRecordFilters = (
    filters: Filter[],
    contextRecord?: RecordDto
): Filter[] => {
    return filters.reduce((accumulator, rawFilter) => {
        if (Object.keys(resolvedOperations).includes(rawFilter.options.option!)) {
            // only apply the filter if field value is resolved to something meaningfull
            const resolvedValue = contextRecord?.[rawFilter.options.value as string]
            if (resolvedValue) {
                accumulator.push({
                    ...rawFilter,
                    options: {
                        ...rawFilter.options,
                        option: resolvedValue
                            ? resolvedOperations[rawFilter.options.option!]
                            : noValueResolvedOperations[rawFilter.options.option!],
                        value: resolvedValue,
                    },
                })
            }
        } else {
            accumulator.push(rawFilter)
        }
        return accumulator
    }, [] as Filter[])
}

import { ThemeConfig } from './types'

export const wsNavThemeToV3 = (navTheme: ThemeConfig) => {
    return {
        ...navTheme,
        navBorderColor: 'transparent',
        navFadeColor: 'pink', // not consumed
        textColorBright: navTheme.textBright,
        textColor: navTheme.text,
        hoverColor: navTheme.highlightBg,
        navColor: navTheme.bgNav,
        secondNavColor: navTheme.bgScdNav,
    }
}

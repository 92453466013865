import { isTurboView } from 'features/views/ListView/utils'

export function supportsSearchBar(options: ListViewOptions): boolean {
    return (
        options.display === 'table' ||
        options.display === 'rows' ||
        options.display === 'card' ||
        isTurboView(options.display)
    )
}

import { Modifier } from 'react-popper'

export const overlayModifier: Modifier<'overlay', {}> = {
    name: 'overlay',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
        state.styles.popper.top = `-${state.rects.reference.height}px`
    },
    effect: ({ state, instance }) => {
        const referenceElementHeight = state.elements.reference.getBoundingClientRect().height
        state.elements.popper.style.top = `-${referenceElementHeight}px`

        const observer = new ResizeObserver(() => {
            const height = state.elements.reference.getBoundingClientRect().height
            state.elements.popper.style.top = `-${height}px`

            instance.forceUpdate()
        })
        observer.observe(state.elements.reference as Element)
        return () => {
            observer.disconnect()
        }
    },
}

import { createContext } from 'react'

type DateFilterContext = {
    specificDate: string | string[] | undefined
    setSpecificDate: (newDate: string | string[] | undefined) => void
}

export const DateFilterContext = createContext<DateFilterContext>({
    specificDate: '',
    setSpecificDate: () => {},
})

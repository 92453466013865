export function getInlineFilterType(config: ListViewOptions): ListViewInlineFilters | undefined {
    return config.enableEndUserFilters ? (config.inlineFilters ?? 'quick') : undefined
}

export function removeUnsupportedFilters(
    filters: Filter[],
    fields: FieldDto[],
    filterType?: ListViewInlineFilters
) {
    const fieldsBySid = fields.reduce((acc, f) => acc.set(f._sid, f), new Map<string, FieldDto>())

    return filters.filter((f) => {
        const field = fieldsBySid.get(f.field_sid ?? '')
        if (!field) return false

        if (filterType === 'quick') {
            switch (field.type) {
                case 'dropdown':
                case 'multi_select':
                    return f.options.option === 'oneOf' && Array.isArray(f.options.value)
                case 'datetime':
                case 'date':
                    return f.options.option === 'sameDay' && f.options.value
                case 'checkbox':
                    return f.options.option === 'is' && f.options.value
                case 'percentage':
                    return (
                        ['equalsOrGreaterThan', 'equalsOrLessThan'].includes(f.options.option!) &&
                        f.options.value
                    )
                case 'lookup':
                case 'multi_lookup':
                    return f.options.option === 'oneOf' && Array.isArray(f.options.value)
                case 'user_ref':
                    return f.options.option === 'oneOf' && Array.isArray(f.options.value)
            }
        }

        return true
    })
}

export function makeQuickFilterValues(field: FieldDto, value: string | string[]): Filter[] {
    switch (field.type) {
        case 'dropdown':
        case 'multi_select':
            return [
                {
                    field_sid: field._sid,
                    field,
                    options: {
                        option: 'oneOf',
                        value: Array.isArray(value) ? value : [value],
                    },
                },
            ]

        case 'datetime':
        case 'date':
            return [
                {
                    field_sid: field._sid,
                    field,
                    options: {
                        option: 'sameDay',
                        value,
                    },
                },
            ]

        case 'checkbox':
            return [
                {
                    field_sid: field._sid,
                    field,
                    options: {
                        option: 'is',
                        value,
                    },
                },
            ]

        case 'percentage':
            return [
                {
                    field_sid: field._sid,
                    field,
                    options: {
                        option: 'equalsOrGreaterThan',
                        value: value[0],
                    },
                },
                {
                    field_sid: field._sid,
                    field,
                    options: {
                        option: 'equalsOrLessThan',
                        value: value[1],
                    },
                },
            ]

        case 'lookup':
        case 'multi_lookup':
            return [
                {
                    field_sid: field._sid,
                    field,
                    options: {
                        option: 'oneOf',
                        value: Array.isArray(value) ? value : [value],
                    },
                },
            ]

        case 'user_ref':
            return [
                {
                    field_sid: field._sid,
                    field,
                    options: {
                        option: 'oneOf',
                        value: Array.isArray(value) ? value : [value],
                    },
                },
            ]
        default:
            return []
    }
}

export function mergeFilters(
    filters: Filter[],
    newFilters: Filter[],
    filterType?: ListViewInlineFilters
) {
    const filtersByFieldAndOption = [...filters, ...newFilters].reduce((acc, f) => {
        const key = `${f.field_sid}:${f.options.option}`
        const existing = acc.get(key)
        if (existing) {
            return acc.set(key, existing.concat(f))
        }

        return acc.set(key, [f])
    }, new Map<string, Filter[]>())

    // Flatten the map into an array.
    const allFilters: Filter[] = []
    for (const filters of filtersByFieldAndOption.values()) {
        if (filters.length < 1) continue

        if (filterType === 'quick') {
            // If we're using quick filters, just reduce everything into a single filter.
            const filter = filters.reduce((acc, f) => {
                if (Array.isArray(f.options.value)) {
                    const newValue = f.options.value

                    return {
                        ...acc,
                        options: {
                            ...acc.options,
                            value: newValue,
                        },
                    } as Filter
                }

                return {
                    ...acc,
                    options: {
                        ...acc.options,
                        value: f.options.value,
                    },
                } as Filter
            }, filters[0])

            allFilters.push(filter)
            continue
        }

        // On advanced filters, merge all array values together.
        const hasArrayValue = filters.some((f) => Array.isArray(f.options.value))
        if (hasArrayValue) {
            const newFilter = filters.reduce((acc, f) => {
                const existingValue = Array.isArray(acc.options.value)
                    ? acc.options.value
                    : [acc.options.value]
                const currentValue = Array.isArray(f.options.value)
                    ? f.options.value
                    : [f.options.value]
                const newValue = new Set([...existingValue, ...currentValue])

                return {
                    ...acc,
                    options: {
                        ...acc.options,
                        value: Array.from(newValue),
                    },
                } as Filter
            }, filters[0])

            allFilters.push(newFilter)
        } else {
            // Just dedupe the filter values.
            const values = new Set<string | undefined>()
            for (const filter of filters) {
                const value = filter.options.value as string | undefined

                // If the value was already added, we don't need to add it again.
                if (values.has(value)) continue

                values.add(value)
                allFilters.push(filter)
            }
        }
    }

    return allFilters
}

export function getRecommendedFieldSids(viewOptions: ListViewOptions): string[] {
    const recommendedFields = viewOptions.inlineFiltersRecommendedFields ?? []

    const useSpecificFilters = viewOptions.enableSpecificEndUserFilters
    // If we're using specific filters, we should only show the fields that are selected.
    if (useSpecificFilters) {
        const specificFields = new Set(viewOptions.specificEndUserFilters ?? [])

        return recommendedFields.filter((sid) => specificFields.has(sid))
    }

    return recommendedFields
}

import React from 'react'

import classNames from 'classnames'

import { ListViewCommentCount } from 'features/views/ListView/ListViewCommentCount'

import { Box } from 'ui/components/Box'

import { useTableViewCommentCountState } from './hooks/useTableViewCommentCountState'

import { TableViewOnRowHoverStyle } from './TableView.css'

type TableViewCommentCountProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const TableViewCommentCount: React.FC<TableViewCommentCountProps> = ({
    className,
    ...props
}) => {
    const { record } = useTableViewCommentCountState()

    return (
        <ListViewCommentCount
            record={record}
            className={classNames(className, TableViewOnRowHoverStyle)}
            {...props}
        />
    )
}

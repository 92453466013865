import React from 'react'

import { useOverflowX } from 'features/views/ListView/hooks/useOverflowX'

import { Box } from 'ui/components/Box'

import {
    TableViewScrollAreaIsOverflowingLeftStyle,
    TableViewScrollAreaIsOverflowingRightStyle,
    TableViewScrollAreaRightShadowStyle,
} from './TableView.css'

export type TableViewScrollAreaProps = {}

export const TableViewScrollArea: React.FC<TableViewScrollAreaProps> = ({ children }) => {
    const { targetRef, scrollAreaRef } = useOverflowX({
        leftClassName: TableViewScrollAreaIsOverflowingLeftStyle,
        rightClassName: TableViewScrollAreaIsOverflowingRightStyle,
    })

    return (
        <Box
            width="full"
            height="full"
            position="relative"
            overflow="hidden"
            alignSelf="stretch"
            ref={targetRef}
            grow
        >
            <Box position="relative" width="full" height="full">
                <Box
                    width="full"
                    height="full"
                    overflowX="auto"
                    overflowY="hidden"
                    ref={scrollAreaRef}
                    style={{
                        WebkitOverflowScrolling: 'touch',
                    }}
                >
                    <Box minWidth="full" display="table">
                        {children}
                    </Box>
                </Box>
            </Box>
            <div className={TableViewScrollAreaRightShadowStyle} />
        </Box>
    )
}

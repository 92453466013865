import React, { forwardRef } from 'react'

import { ItemTagStyle } from 'ui/components/Menu/Menu.css'
import { Tag } from 'ui/components/Tag'

import { DropdownCheckboxBase } from './DropdownCheckboxBase'
import { DropdownItemBase } from './DropdownItemBase'

type DropdownItemTagRef = HTMLDivElement

type InheritedTagProps = Pick<
    React.ComponentPropsWithoutRef<typeof Tag>,
    'color' | 'startIcon' | 'shade' | 'type' | 'shape' | 'size'
>

type BaseProps = React.ComponentPropsWithoutRef<typeof DropdownItemBase> &
    React.ComponentPropsWithoutRef<typeof DropdownCheckboxBase>

type DropdownItemTagProps = Omit<BaseProps, 'children' | 'color' | 'variant' | 'leftSlotContent'> &
    Partial<InheritedTagProps> & {
        label?: string
        multiSelect?: boolean
    }

export const DropdownItemTag = forwardRef<DropdownItemTagRef, DropdownItemTagProps>(
    (
        {
            color = 'Theme',
            startIcon,
            shade = 'light',
            type = 'solid',
            label,
            shape = 'rectangle',
            size = '2xs',
            multiSelect,
            ...props
        },
        ref
    ) => {
        const BaseComponent = multiSelect ? DropdownCheckboxBase : DropdownItemBase

        return (
            <BaseComponent {...props} ref={ref}>
                <Tag
                    color={color}
                    startIcon={startIcon}
                    shade={shade}
                    type={type}
                    shape={shape}
                    size={size}
                    disabled={props.disabled}
                    className={ItemTagStyle}
                >
                    {label}
                </Tag>
            </BaseComponent>
        )
    }
)

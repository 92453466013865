import React from 'react'

import styled from '@emotion/styled'

import BlockSelectorPortal from 'features/utils/BlockSelectorPortal'

import { Box, Button, Divider, Flex, Icon, Text } from 'v2/ui'
import { Display, Header as HeaderIcon, SolidCog, SolidLock } from 'v2/ui/svgs'

type BlankPageViewEditorBlockPaneProps = {
    onOpenHeaderSettings?: () => void
    onOpenRolesSettings?: () => void
    view: ViewDto | undefined
}

const BlankPageViewEditorBlockPane: React.FC<BlankPageViewEditorBlockPaneProps> = ({
    onOpenHeaderSettings,
    onOpenRolesSettings,
    view,
}) => {
    if (!view) return null

    return (
        <>
            <Flex direction="row" alignItems="center" justifyContent="flex-start" mt={2}>
                <Icon icon="palette" mr="0.5rem" />
                <Text variant="adminFieldLabel">
                    Appearance{onOpenRolesSettings ? ' & Visibility' : ''}
                </Text>
            </Flex>
            <Box mr="-8px" ml="-8px">
                <StyledButton
                    variant="menuToggle"
                    onClick={onOpenHeaderSettings}
                    svgIcon={<SolidCog />}
                    iconAlign="right"
                    iconColor="grey.300"
                >
                    <Icon
                        svg={<HeaderIcon />}
                        mr={2}
                        color="admin.menuToggle.icon"
                        fontSize="admin.menuToggle.icon"
                    />
                    Header
                </StyledButton>
            </Box>
            {onOpenRolesSettings && (
                <Box mr="-8px" ml="-8px">
                    <StyledButton
                        variant="menuToggle"
                        onClick={onOpenRolesSettings}
                        svgIcon={<SolidCog />}
                        iconAlign="right"
                        iconColor="grey.300"
                        mt="-1px"
                    >
                        <Icon
                            svg={<SolidLock />}
                            mr={2}
                            color="admin.menuToggle.icon"
                            fontSize="admin.menuToggle.icon"
                        />
                        Roles
                    </StyledButton>
                </Box>
            )}
            <Divider my={4} />
            <Flex direction="row" alignItems="center" justifyContent="flex-start">
                <Icon svg={<Display />} mr="0.5rem" />
                <Text variant="adminFieldLabel">Widgets</Text>
            </Flex>
            <Text fontSize="12px" color="gray.500" mb={1}>
                Drag to add to your layout
            </Text>
            <BlockSelectorPortal />
        </>
    )
}

export default BlankPageViewEditorBlockPane

export const StyledButton = styled(Button)`
    padding-left: 8px;
    padding-right: 8px;
`

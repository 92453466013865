import { useMemo } from 'react'

import { BoardViewColumn } from 'features/views/ListView/BoardView/types'
import { useFiltersContext } from 'features/views/ListView/Filters/useFiltersContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseBoardViewFiltersOptions = {
    statusField?: FieldDto
    columns: BoardViewColumn[]
}

export function useBoardViewFilters(options: UseBoardViewFiltersOptions) {
    const { columns, statusField } = options

    const defaultFilters = makeDefaultFilters(columns, statusField)
    const defaultFiltersMemo = useDeepEqualsMemoValue(defaultFilters)

    const { inlineFilters, inlineFilterType, availableInlineFilterFields } = useFiltersContext()
    const hasFilters = inlineFilters.length > 0

    return useMemo(
        () => ({
            defaultFilters: defaultFiltersMemo,
            hasFilters,
            inlineFilterType,
            availableInlineFilterFields,
        }),
        [defaultFiltersMemo, hasFilters, inlineFilterType, availableInlineFilterFields]
    )
}

function makeDefaultFilters(columns: BoardViewColumn[], statusField?: FieldDto): Filter[] {
    if (columns.length < 1 || !statusField) {
        return []
    }

    const includedStatuses = new Set(columns.map((column) => column.value))

    let filterValues: string[] = []

    const hasNoCategory = includedStatuses.has(null)
    if (hasNoCategory) {
        const options = statusField.options?.options ?? []
        filterValues = options.reduce((acc, option) => {
            // Only return the values that are not included in the columns.
            if (!includedStatuses.has(option.value!)) {
                acc.push(option.value!)
            }

            return acc
        }, [] as string[])
    } else {
        filterValues = Array.from(includedStatuses as Set<string>)
    }

    if (filterValues.length === 0) {
        return []
    }

    return [
        {
            field: statusField,
            field_sid: statusField._sid,
            options: {
                value: filterValues,
                option: hasNoCategory ? 'noneOf' : 'oneOf',
            },
        },
    ]
}

import React from 'react'

import classNames from 'classnames'

import { RecordActions } from 'features/views/ListView/Actions/RecordActions'
import { CardOnHoverStyle } from 'features/views/ListView/BoardView/BoardView.css'

import { Box } from 'ui/components/Box'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { useBoardViewRecordActionsState } from './hooks/useBoardViewRecordActionsState'

type BoardViewRecordActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    includeFields: string[]
    actionButtons?: ActionDto[]
    additionalEditFields?: FieldDto[]
}

export const BoardViewRecordActions: React.FC<BoardViewRecordActionsProps> = React.memo(
    function BoardViewRecordActions({
        record,
        className,
        style,
        additionalEditFields,
        actionButtons,
        includeFields,
        ...props
    }) {
        const { isOpen, setIsOpen } = useBoardViewRecordActionsState()

        const opacity = useResponsiveValue({
            mobile: 1,
            tabletLarge: isOpen ? 1 : undefined,
        })

        if (!record || !actionButtons?.length) return null

        return (
            <Box
                position="absolute"
                flex
                center
                style={{
                    top: theme.space.l,
                    right: theme.space.l,
                    zIndex: 1,
                    opacity,
                    ...style,
                }}
                className={classNames(className, CardOnHoverStyle)}
                {...props}
            >
                <RecordActions
                    actionButtons={actionButtons}
                    onOpenChange={setIsOpen}
                    onCloseAutoFocus={stopPropagation}
                    record={record}
                    additionalEditFields={additionalEditFields}
                    includeFields={includeFields}
                />
            </Box>
        )
    }
)

import React from 'react'

import { DeleteViewConfirmModal } from 'features/views/ListView/DeleteViewConfirmModal'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownSeparator,
} from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useListHeaderMoreActionsState } from './hooks/useListHeaderMoreActionsState'

type ListHeaderMoreActionsProps = {}

export const ListHeaderMoreActions: React.FC<ListHeaderMoreActionsProps> = () => {
    const {
        allowDownload,
        onDownload,
        isLoading,
        onCopyLink,
        allowDelete,
        onDelete,
        deleteModalViewSid,
        isDeleteModalOpen,
        onDeleteModalOpenChange,
    } = useListHeaderMoreActionsState()

    return (
        <>
            <Dropdown>
                <DropdownButton
                    size="s"
                    aria-label="More actions"
                    variant="ghost"
                    startIcon={{ name: 'MoreHorizontal' }}
                    isLoading={isLoading}
                />
                <DropdownContent
                    onClick={stopPropagation}
                    align="end"
                    style={{ minWidth: '250px' }}
                >
                    <DropdownItem
                        startIcon={{ name: 'Link' }}
                        label="Copy link"
                        onClick={onCopyLink}
                    />

                    {(allowDownload || allowDelete) && <DropdownSeparator />}

                    {allowDownload && (
                        <DropdownItem
                            startIcon={{ name: 'Download' }}
                            label="Export as .CSV"
                            onClick={onDownload}
                        />
                    )}
                    {allowDelete && (
                        <DropdownItem
                            variant="destructive"
                            startIcon={{ name: 'Trash' }}
                            label="Delete layout"
                            onClick={onDelete}
                        />
                    )}
                </DropdownContent>
            </Dropdown>
            <DeleteViewConfirmModal
                viewSid={deleteModalViewSid}
                isOpen={isDeleteModalOpen}
                onOpenChange={onDeleteModalOpenChange}
            />
        </>
    )
}

import React from 'react'
import { Redirect } from 'react-router-dom'

import queryString from 'qs'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getWorkspaceUrl, Urls } from 'app/UrlService'
import { useInvitation } from 'data/hooks/useInvitation'
import { useGlobalUser } from 'data/hooks/users/main'
import { AuthFrame } from 'features/auth/new-stacker-auth/AuthFrame'

import { Icon, LoadingSplash } from 'v2/ui'

import { Button, Text } from './AuthUIElements'

const AcceptInviteFlow = () => {
    const { logOut } = useAuthContext()
    const { data: user, isLoading: isUserLoading } = useGlobalUser()
    const query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })

    const { isLoading: invitationLoading, data: invitation } = useInvitation(query.invite)

    const isLoading = invitationLoading || isUserLoading

    if (isLoading) {
        return <LoadingSplash />
    }

    const isLoggedInUser =
        invitation && invitation.user && user && invitation.user._sid === user._sid

    if (user && !isLoggedInUser) {
        return <LoggedInWrongAccount user={user} invitation={invitation} onLogout={logOut} />
    } else if (isLoggedInUser) {
        return <Redirect to={getWorkspaceUrl(Urls.Home, invitation.workspace)} />
    } else if (invitation?.workspace.sso_required) {
        return <Redirect to={getWorkspaceUrl(Urls.Login, invitation.workspace)} />
    } else if (invitation?.user?.is_new_user) {
        return (
            <Redirect
                to={`${getWorkspaceUrl(Urls.SignUp)}?email=${encodeURIComponent(
                    invitation?.user.email
                )}`}
            />
        )
    } else {
        return (
            <Redirect
                to={`${getWorkspaceUrl(Urls.Login)}?email=${encodeURIComponent(
                    invitation?.user.email
                )}`}
            />
        )
    }
}

const LoggedInWrongAccount = ({ user, invitation, onLogout }) => {
    return (
        <AuthFrame title="Accept invitation">
            <Icon icon="info" size="xxxl" color="grey.200" />
            <Text variant="authenticationText" my={6} textAlign="center">
                You are already logged in as <strong>{user?.email}</strong>.
            </Text>
            <Text variant="authenticationText" mb={6} fontSize="sm" textAlign="center">
                You must log in as <strong>{invitation?.user.email}</strong> to proceed.
            </Text>
            <Button variant="adminPrimary" onClick={onLogout} buttonSize="sm">
                Continue
            </Button>
        </AuthFrame>
    )
}

export default AcceptInviteFlow

import { Box } from 'ui/components/Box'
import { makeComponent } from 'ui/helpers/recipes'

import {
    MainNavigationStyles,
    NavigationStyles,
    SystemNavigationStyles,
    WorkspaceNavigationStyles,
} from './Navigation.css'

export const NavigationWrapper = makeComponent(Box, NavigationStyles)
export const WorkspaceNavigationWrapper = makeComponent(Box, WorkspaceNavigationStyles)
export const MainNavigationWrapper = makeComponent(Box, MainNavigationStyles)
export const SystemNavigationWrapper = makeComponent(Box, SystemNavigationStyles)

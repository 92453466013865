import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'
import { pxToRem } from 'ui/helpers/converters'

import { useAppUsersSearchBarState } from './hooks/useAppUsersSearchBarState'
import { AppUsersAddUsersButton } from './AppUsersAddUsersButton'
import { AppUsersCreateTableGroupButton } from './AppUsersCreateTableGroupButton'
import { AppUsersSearchBar } from './AppUsersSearchBar'
import { AppRole } from './types'

import { AppUsersHeaderStyle } from './AppUsers.css'

type AppUsersDefaultPaneHeaderProps = React.ComponentPropsWithoutRef<typeof Box> & {
    roles: AppRole[]
    isLoading?: boolean
}

export const AppUsersDefaultPaneHeader: React.FC<AppUsersDefaultPaneHeaderProps> = ({
    style,
    className,
    isLoading,
    roles,
    ...props
}) => {
    return (
        <Box
            flex
            center
            gap="m"
            justifyContent="space-between"
            position="sticky"
            top={0}
            width="full"
            background="surface"
            pt="3xl"
            pb="l"
            style={{ ...style, zIndex: 99 }}
            className={classNames(AppUsersHeaderStyle, className)}
            {...props}
        >
            <Box>
                <Headline size="s">App users</Headline>
            </Box>
            <Box flex center gap="m">
                <SearchBar disabled={isLoading} />
                <AppUsersCreateTableGroupButton />
                <AppUsersAddUsersButton roles={roles} />
            </Box>
        </Box>
    )
}

const SEARCH_BAR_WIDTH = pxToRem(160)

type SearchBarProps = {
    disabled?: boolean
}

const SearchBar: React.FC<SearchBarProps> = ({ disabled }) => {
    const { searchQuery, onInputChange } = useAppUsersSearchBarState()

    return (
        <Box
            flex
            center
            style={{
                width: SEARCH_BAR_WIDTH,
            }}
            noShrink
        >
            <AppUsersSearchBar onChange={onInputChange} value={searchQuery} disabled={disabled} />
        </Box>
    )
}

import { useMemo } from 'react'

import { useObjects } from 'data/hooks/objects'
import { useStackViews } from 'data/hooks/views'
import { useNavTree } from 'features/admin/settings/navigation/NavigationUtils'
import type { NavTreeItem } from 'features/admin/settings/navigation/types'

export type AccessibleView = {
    view: ViewDto
    navItem?: NavTreeItem
}

export type AccessibleViewList = Readonly<Record<ViewDto['type'], AccessibleView[]>>

export function useAccessibleViews(): AccessibleViewList {
    const { data: objects = [] } = useObjects()
    const views = useStackViews() as ViewDto[]
    const navTree = useNavTree()

    return useMemo(() => {
        const flattenedNavTreeByUrl = flattenNavTreeByUrl(navTree)
        const objectsById = getObjectsById(objects)

        const viewsByUrl = getViewsByUrl(views)

        return categorizeViews({
            navItemsByUrl: flattenedNavTreeByUrl,
            viewsByUrl,
            objectsById,
        })
    }, [navTree, objects, views])
}

function getViewsByUrl(views: ViewDto[] = []): Map<string, ViewDto> {
    return views.reduce((agg, curr) => agg.set(curr.url, curr), new Map<string, ViewDto>())
}

function getObjectsById(objects: ObjectDto[] = []): Map<string, ObjectDto> {
    return objects.reduce((agg, curr) => agg.set(curr._sid, curr), new Map<string, ObjectDto>())
}

function categorizeViews(params: {
    navItemsByUrl: Map<string, NavTreeItem>
    viewsByUrl: Map<string, ViewDto>
    objectsById: Map<string, ObjectDto>
}): AccessibleViewList {
    const { navItemsByUrl, viewsByUrl, objectsById } = params

    const agg: AccessibleViewList = {
        list: [],
        blankpage: [],
        create: [],
        detail: [],
        document: [],
    }

    for (const view of viewsByUrl.values()) {
        const { type } = view
        if (type === 'create') {
            const object = view.object_id ? objectsById?.get(view.object_id) : undefined
            if (!object?.connection_options?.read_only) {
                agg.create.push({ view })
            }

            continue
        }

        const navItem = navItemsByUrl.get(view.url)

        agg[type].push({ view, navItem })
    }

    return agg
}

function flattenNavTreeByUrl(navTree: NavTreeItem[]): Map<string, NavTreeItem> {
    return navTree.reduce((agg, curr) => {
        if (!curr.children) {
            return agg.set(curr.url, curr)
        }

        if (curr.children.length === 1) {
            const item = curr.children[0]
            return agg.set(item.url, item)
        }

        for (const item of curr.children) {
            agg.set(item.url, item)
        }
        return agg
    }, new Map<string, NavTreeItem>())
}

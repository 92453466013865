import React, { useState } from 'react'

import { AttachmentGallery } from 'features/attachments/AttachmentGallery'
import { Attachment } from 'features/attachments/types'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { formatCounterLabel } from './attachmentsUtils'

type TaskAttachmentCounterProps = React.ComponentPropsWithoutRef<typeof Box> & {
    attachments: Attachment[] | null
    allowPreview?: boolean
}

export const TaskAttachmentCounter: React.FC<TaskAttachmentCounterProps> = ({
    attachments,
    allowPreview = true,
    ...props
}) => {
    const [isLightboxOpen, setIsLightboxOpen] = useState(false)

    const onLightboxClose = () => {
        setIsLightboxOpen(false)
    }

    const handleClick = (e: React.MouseEvent) => {
        setIsLightboxOpen(true)
        e.preventDefault()
        e.stopPropagation()
    }

    const count = attachments?.length ?? 0
    if (!count) return null

    const tooltipLabel = formatCounterLabel(count)

    return (
        <>
            <Tooltip label={tooltipLabel} placement="bottom">
                <Button
                    onClick={handleClick}
                    aria-label="Number of attachments"
                    variant="secondary"
                    size="xs"
                    startIcon={{ name: 'Paperclip' }}
                    {...props}
                >
                    {count}
                </Button>
            </Tooltip>
            {allowPreview && (
                <AttachmentGallery
                    size="md"
                    attachments={attachments ?? []}
                    style={{ display: 'none' }}
                    isLightboxOpen={isLightboxOpen}
                    onLightboxClose={onLightboxClose}
                />
            )}
        </>
    )
}

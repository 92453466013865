import { useMemo } from 'react'

import { getRecord } from 'data/hooks/records/getRecord'

import { ContextGroup, DynamicContextGroup } from './types'
import { useRecordItemsProvider } from './useRecordItemsProvider'

type ReturnTypeValuesProps = {
    type?: FieldType
    extraOptions?: WorkflowExtraOptions
    inputLabel?: string
}

// Returns a special ContextGroup with literal values for the given field type.
export function useReturnTypeGroup({
    type,
    extraOptions,
    inputLabel = 'value',
}: ReturnTypeValuesProps): ContextGroup | DynamicContextGroup | undefined {
    const provideRecords = useRecordItemsProvider()
    return useMemo(() => {
        switch (type) {
            case 'dropdown':
            case 'multi_select':
                return {
                    id: 'value',
                    name: `Select ${inputLabel}`,
                    type: 'group',
                    items:
                        extraOptions?.options?.map((option) => ({
                            id: option.value,
                            name: option.label ?? option.value,
                            type,
                            isLiteral: true,
                        })) ?? [],
                } as ContextGroup
            case 'checkbox':
                return {
                    id: 'value',
                    name: `Select ${inputLabel}`,
                    type: 'group',
                    items: [
                        {
                            id: 'true',
                            name: 'Yes',
                            type,
                            isLiteral: true,
                        },
                        {
                            id: 'false',
                            name: 'No',
                            type,
                            isLiteral: true,
                        },
                    ],
                } as ContextGroup
            case 'lookup':
            case 'multi_lookup':
                const targetObjectId = extraOptions?.link_target_object_id
                if (!targetObjectId) {
                    return undefined
                }

                return {
                    id: 'value',
                    name: `Select  ${inputLabel}`,
                    type: 'group',
                    isDynamic: true,
                    provideItems: ({ query }) => provideRecords(targetObjectId, query),
                    getItem: async (id: string) => {
                        // fetch the record from the server
                        const record = await getRecord(id, [], {
                            checkCache: true,
                        })
                        return {
                            id: id,
                            name: record?._primary ?? id,
                            path: `value.${id}`,
                            type: 'string',
                            isLiteral: true,
                        }
                    },
                } as DynamicContextGroup

            default:
                return undefined
        }
    }, [
        type,
        inputLabel,
        extraOptions?.options,
        extraOptions?.link_target_object_id,
        provideRecords,
    ])
}

import React from 'react'

import { convertFromBytesToKB, convertFromBytesToMB } from 'features/ImportData/utils'

import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type CounterProps = {
    count: number
    total: number
}

const Counter = ({ count = 0, total }: CounterProps) => {
    const getConvertedCount = () => {
        return count < 100000 ? convertFromBytesToKB(count) : convertFromBytesToMB(count)
    }

    const getScale = () => {
        return count < 100000 ? 'KB' : 'MB'
    }

    const getCounterColor = () => {
        const dataBytesToMB = convertFromBytesToMB(count)

        if (dataBytesToMB < total) {
            return colors.userInterface.neutral[800]
        } else if (dataBytesToMB > total) {
            return colors.userInterface.error[600]
        } else return colors.userInterface.success[500]
    }

    return (
        <div data-testid="import-table-data-counter">
            <p style={{ color: `${getCounterColor()}`, fontSize: '14px' }}>
                {getConvertedCount()} {getScale()} / {total} MB
            </p>
        </div>
    )
}

export default Counter

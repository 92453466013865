import React from 'react'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'
import { theme } from 'ui/styling/Theme.css'

type TasksPageLoadingAnimationProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const TasksPageLoadingAnimation: React.FC<TasksPageLoadingAnimationProps> = (props) => {
    return (
        <Box {...props}>
            <Banner
                title="Loading tasks…"
                type="info"
                style={{
                    backgroundColor: theme.color.surfaceStronger,
                }}
            />
        </Box>
    )
}

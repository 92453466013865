import { useMemo } from 'react'

import { useWorkflowSchema, WorkflowSchema } from 'data/hooks/workflows/workflows'
import { assertIsDefined } from 'data/utils/ts_utils'

import { useAPIRequestActionFinalSchema } from './Actions/useAPIRequestActionFinalSchema'
import { useCreateTaskActionFinalSchema } from './Actions/useCreateTaskActionFinalSchema'
import { useFindRecordsActionFinalSchema } from './Actions/useFindRecordsActionFinalSchema'
import { useRecordChangeActionFinalSchema } from './Actions/useRecordChangeActionFinalSchema'
import { useLoopFinalSchema } from './FlowControl/useLoopFinalSchema'
import { useActionButtonTriggerFinalSchema } from './Triggers/useActionButtonTriggerFinalSchema'
import { useRecordChangeTriggerFinalSchema } from './Triggers/useRecordChangeTriggerFinalSchema'
import { useWebhookTriggerFinalSchema } from './Triggers/useWebhookTriggerFinalSchema'

export type PreparedWorkflowSchema = WorkflowSchemaStateItem[]

export function useFinalWorkflowSchema(
    flow: WorkflowDto,
    forNodeAtPath?: string[]
): PreparedWorkflowSchema {
    const getRecordChangeTriggerSchema = useRecordChangeTriggerFinalSchema()
    const getWebhookTriggerSchema = useWebhookTriggerFinalSchema()
    const getActionButtonTriggerSchema = useActionButtonTriggerFinalSchema()
    const getRecordChangeActionSchema = useRecordChangeActionFinalSchema()
    const getFindRecordsActionSchema = useFindRecordsActionFinalSchema()
    const getLoopSchema = useLoopFinalSchema()
    const getCreateTaskActionSchema = useCreateTaskActionFinalSchema()
    const getAPIRequestActionSchema = useAPIRequestActionFinalSchema()
    const { data: schema } = useWorkflowSchema()

    return useMemo(() => {
        const triggerProcessors: Record<
            string,
            (trigger: WorkflowTriggerConfig) => WorkflowSchemaTriggerType | undefined
        > = {
            record_created: getRecordChangeTriggerSchema,
            record_updated: getRecordChangeTriggerSchema,
            record_created_or_updated: getRecordChangeTriggerSchema,
            webhook: getWebhookTriggerSchema,
            action_button_clicked: getActionButtonTriggerSchema,
        }

        const nodeProcessors: Record<
            string,
            (node: WorkflowNode) => WorkflowSchemaNodeType | undefined
        > = {
            create_record: getRecordChangeActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            update_record: getRecordChangeActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            create_task: getCreateTaskActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            find_records: getFindRecordsActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            api_request: getAPIRequestActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
        }
        const result: WorkflowSchemaStateItem[] = []

        if (flow.trigger.trigger_type) {
            const triggerSchema =
                triggerProcessors[flow.trigger.trigger_type]?.(flow.trigger) ??
                schema?.triggers.find((trigger) => trigger.id === flow.trigger.trigger_type)

            assertIsDefined(triggerSchema)

            result.push({
                id: 'trigger',
                name: flow.trigger.name,
                type: 'group',
                items: triggerSchema.outputs,
            } as WorkflowSchemaStateItem)
        }

        if (forNodeAtPath) {
            const stateItems = getAccessibleStateItems({
                schema,
                nodeProcessors,
                getLoopSchema,
                existingSchema: result,
                workflow: flow,
                nodePath: forNodeAtPath,
            })
            result.push(...stateItems)
        } else {
            // If there's no node id provided, just handle all the root nodes.
            flow.chain?.forEach((item) => {
                const stateItem = extractStateItemFromNode({
                    node: item,
                    schema,
                    nodeProcessors,
                    getLoopSchema,
                    existingSchema: result,
                })

                result.push(stateItem)
            })
        }

        return result
    }, [
        getRecordChangeTriggerSchema,
        getWebhookTriggerSchema,
        getActionButtonTriggerSchema,
        getRecordChangeActionSchema,
        getCreateTaskActionSchema,
        getFindRecordsActionSchema,
        getAPIRequestActionSchema,
        flow,
        forNodeAtPath,
        schema,
        getLoopSchema,
    ])
}

// Only get the state items that can be used by the current node.
// This means state items from all the previous nodes (both in the parent chains and in the same chain).
function getAccessibleStateItems(options: {
    schema?: WorkflowSchema
    nodeProcessors: Record<string, (node: WorkflowNode) => WorkflowSchemaNodeType | undefined>
    getLoopSchema: (
        config: WorkflowLoopConfig,
        existingSchema: WorkflowSchemaStateItem[]
    ) => WorkflowSchemaLoopType | undefined
    existingSchema: WorkflowSchemaStateItem[]
    workflow: WorkflowDto
    nodePath: string[]
}): WorkflowSchemaStateItemGroup[] {
    const { schema, nodeProcessors, getLoopSchema, existingSchema, workflow, nodePath } = options
    if (nodePath.length < 1) return []

    const stateItems: WorkflowSchemaStateItemGroup[] = []

    const [nodeId, ...parentPath] = nodePath
    const parents = new Set(parentPath)

    function findNodeAndExtractState(
        nodeId: string,
        chain: WorkflowNode[]
    ): WorkflowNode | undefined {
        for (const item of chain) {
            if (item.id === nodeId) {
                return item
            } else {
                // We only include the state items for the nodes that are within the scope.
                const isItemWithinScope = parents.has(item.id)

                const stateGroup = extractStateItemFromNode({
                    node: item,
                    schema,
                    nodeProcessors,
                    getLoopSchema,
                    existingSchema,
                    keepLocalState: isItemWithinScope,
                    existingState: stateItems,
                })
                stateItems.push(stateGroup)
            }

            if (item.kind === 'loop') {
                const node = findNodeAndExtractState(nodeId, item.chain)
                if (node) return node
            }
        }
    }

    const node = findNodeAndExtractState(nodeId, workflow.chain)
    if (!node) return []

    return stateItems
}

function extractStateItemFromNode(options: {
    node: WorkflowNode
    schema?: WorkflowSchema
    nodeProcessors: Record<string, (node: WorkflowNode) => WorkflowSchemaNodeType | undefined>
    getLoopSchema: (
        config: WorkflowLoopConfig,
        existingSchema: WorkflowSchemaStateItem[]
    ) => WorkflowSchemaLoopType | undefined
    existingSchema: WorkflowSchemaStateItem[]
    keepLocalState?: boolean
    existingState?: WorkflowSchemaStateItem[]
}): WorkflowSchemaStateItemGroup {
    const { node, schema, nodeProcessors, getLoopSchema, existingState, keepLocalState } = options

    let nodeSchema: WorkflowSchemaItemBase | undefined
    let stateItems: WorkflowSchemaStateItem[] | undefined
    switch (node.kind) {
        case 'action':
            nodeSchema =
                nodeProcessors[node.action_type]?.(node) ??
                schema?.nodes.find((n) => n.id === node.action_type)
            stateItems = (nodeSchema as WorkflowSchemaActionType)?.state ?? []
            break

        case 'loop':
            nodeSchema = getLoopSchema(node, existingState ?? [])
            stateItems = (nodeSchema as WorkflowSchemaLoopType)?.state ?? []
            if (!keepLocalState) {
                // Remove local state items.
                stateItems = stateItems.filter(
                    (item) => (item as WorkflowSchemaControlFlowStateItem).scope === 'global'
                )
            }
            break
    }

    assertIsDefined(nodeSchema)

    return {
        id: node.id,
        name: (node as WorkflowActionConfig).name ?? nodeSchema.name,
        type: 'group',
        items: stateItems,
    } as WorkflowSchemaStateItemGroup
}

import React from 'react'

import { WorkflowEditorContext } from './useWorkflowEditorContext'

export type WorkflowEditorProviderProps = {
    children: React.ReactNode
    workflow: WorkflowDto
    contextSchema: WorkflowSchemaStateItem[]
    selectedItem: WorkflowItem | undefined
    setSelectedItem: (item: WorkflowItem | undefined, chainPath?: string[]) => void
    addNode: (nodeType: WorkflowSchemaNodeType) => void
    deleteNode: (nodeId: string, chainPath?: string[]) => void
    selectedLine: WorkflowLine | undefined
    setSelectedLine: (line: WorkflowLine | undefined, chainPath?: string[]) => void
    closeEditor: () => void
    selectedItemChainPath: string[]
}

export function WorkflowEditorContextProvider({
    children,
    workflow,
    contextSchema,
    selectedItem,
    setSelectedItem,
    selectedLine,
    setSelectedLine,
    addNode,
    deleteNode,
    closeEditor,
    selectedItemChainPath,
}: WorkflowEditorProviderProps) {
    return (
        <WorkflowEditorContext.Provider
            value={{
                workflow,
                contextSchema,
                selectedItem,
                setSelectedItem,
                addNode,
                deleteNode,
                selectedLine,
                setSelectedLine,
                closeEditor,
                selectedItemChainPath,
            }}
        >
            {children}
        </WorkflowEditorContext.Provider>
    )
}

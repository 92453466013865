import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { OutputSchemaEditorNode } from './OutputSchemaEditorNode'
import { OutputSchemaEditorNodeDivider } from './OutputSchemaEditorNodeDivider'
import { generateEmptyNode } from './utils'

type OutputSchemaEditorNodeListProps = React.ComponentPropsWithoutRef<typeof Box> & {
    nodes: WorkflowOutputSchemaNode[]
    onChange: (nodes: WorkflowOutputSchemaNode[]) => void
    showTopDivider?: boolean
    focusedNodeId: string | null
    setFocusedNode: (nodeId: string | null) => void
}

export const OutputSchemaEditorNodeList: React.FC<OutputSchemaEditorNodeListProps> = ({
    nodes,
    onChange,
    focusedNodeId,
    setFocusedNode,
    ...props
}) => {
    const addNode = (idx?: number) => {
        const updatedNodes = nodes.slice()
        const newNode = generateEmptyNode(updatedNodes)

        if (idx !== undefined && idx < updatedNodes.length) {
            updatedNodes.splice(idx, 0, newNode)
        } else {
            updatedNodes.push(newNode)
        }

        onChange(updatedNodes)
        setFocusedNode(newNode.id)
    }

    return (
        <Box {...props}>
            {nodes.length > 0 && (
                <Box flex column gap="xs" mb="xs">
                    {nodes.map((node, idx) => {
                        const nextNodeId = nodes[idx + 1]?.id ?? null

                        return (
                            <Box key={node.id} width="full" position="relative">
                                <OutputSchemaEditorNodeDivider
                                    position="absolute"
                                    style={{
                                        zIndex: 1,
                                        top: '-12px',
                                    }}
                                    onAddClick={() => addNode(idx)}
                                />
                                <OutputSchemaEditorNode
                                    value={node}
                                    onChange={(updatedNode) => {
                                        const updatedNodes = nodes.map((n) =>
                                            n.id === node.id ? updatedNode : n
                                        )

                                        onChange(updatedNodes)
                                    }}
                                    onDelete={() => {
                                        const updatedNodes = nodes.filter((n) => n.id !== node.id)

                                        onChange(updatedNodes)
                                    }}
                                    siblingNodes={nodes.filter((n) => n.id !== node.id)}
                                    focusedNodeId={focusedNodeId}
                                    setFocusedNode={setFocusedNode}
                                    nextNodeId={nextNodeId}
                                />
                            </Box>
                        )
                    })}
                </Box>
            )}
            <Box flex center>
                <Button
                    type="button"
                    variant="secondary"
                    size="xs"
                    onClick={() => addNode()}
                    startIcon={{ name: 'Plus' }}
                >
                    add attribute
                </Button>
            </Box>
        </Box>
    )
}

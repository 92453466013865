import { useEffect, useMemo } from 'react'

import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'
import { isTargetEditable } from 'features/datagrid/utils'

import { TipTapValue } from 'v2/ui/components/Attribute/DocumentAttribute'

type UseHandlePasteProps = {
    fields: FieldDto[]
    importRawRecords?: (
        updates: { _sid: string | null; data: Record<string, string> }[]
    ) => Promise<void>
}

export function useHandlePaste({ fields, importRawRecords }: UseHandlePasteProps) {
    const { isGridReady, gridApi, getDocumentFieldContentFromHTML } = useDataGridContext()

    const fieldsByApiName = useMemo(() => {
        return fields.reduce<Map<string, FieldDto>>(
            (agg, field) => agg.set(field.api_name, field),
            new Map<string, FieldDto>()
        )
    }, [fields])

    useEffect(() => {
        if (!gridApi || !isGridReady) return

        const handler = (e: ClipboardEvent) => {
            if (isTargetEditable(e.target as HTMLElement | null)) {
                // Use default behavior for events with editable targets
                // i.e. when editing field configuration such as formulas
                return
            }

            e.preventDefault()
            e.stopPropagation()

            const cellRanges = gridApi.getCellRanges() ?? []

            const range = cellRanges?.[0]
            const startIndex = range?.startRow?.rowIndex ?? 0
            const endIndex = range?.endRow?.rowIndex ?? 0

            // If a single cell is selected.
            if (range?.columns.length === 1 && startIndex - endIndex === 0) {
                const column = range.columns[0]
                const row = gridApi.getDisplayedRowAtIndex(startIndex)!
                const field = fieldsByApiName.get(column.getColId())

                // Paste document fields with their formatting.
                if (field?.type === 'document') {
                    let newContent: TipTapValue | string =
                        e.clipboardData?.getData('text/plain') ?? ''

                    const htmlData = e.clipboardData?.getData('text/html')
                    if (htmlData) {
                        newContent = getDocumentFieldContentFromHTML(htmlData)
                    }

                    importRawRecords?.([
                        { _sid: row.data!._sid, data: { [field.api_name]: newContent as any } },
                    ])

                    return
                }
            }

            gridApi.pasteFromClipboard()
        }

        document.addEventListener('paste', handler)
        return () => document.removeEventListener('paste', handler)
    }, [fieldsByApiName, getDocumentFieldContentFromHTML, gridApi, importRawRecords, isGridReady])
}

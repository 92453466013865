import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useMobileNavigationTriggerState() {
    const { openMobileNavigation, closeMobileNavigation } = useNavigationContext()

    return useMemo(
        () => ({
            openMobileNavigation,
            closeMobileNavigation,
        }),
        [openMobileNavigation, closeMobileNavigation]
    )
}

import { groupBy, isEmpty, keys, pickBy } from 'lodash'
import { assign } from 'xstate'

import { sortFieldsByOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import {
    getCaretPositionAfterInsertingKeyword,
    setCaretAtPositionAndFocus,
} from 'features/formulas/autocomplete/formulaAutocompleteFocusFunctions'
import { insertAutocompleteText } from 'features/formulas/autocomplete/formulaAutocompleteInsertionFunctions'
import { Keyword } from 'features/formulas/formulaTypes'
import { getAllAvailableFunctions } from 'features/formulas/parser/exclusiveFunctionFormulas'
import { parseStackerFormula } from 'features/formulas/parser/formulaParsingFunctions'
import {
    FormulaEditorFieldOrFunctionSelection,
    FormulaEditorSelection,
} from 'features/formulas/selection/formulaSelectionTypes'
import { isDisabled, isFieldParseable, isFormulaSupportedField } from 'utils/fieldUtils'

import {
    FormulaEditorContext,
    FormulaEditorMachineInitializeEvent,
} from './formulaEditorMachineTypes'

export const assignToContextFromInitializeEvent = assign(
    (
        _,
        {
            editorRef,
            field,
            initialFormulaString,
            object,
            onChange,
            track,
        }: FormulaEditorMachineInitializeEvent
    ) => {
        const parseResult = parseStackerFormula({
            object,
            text: initialFormulaString,
        })
        return {
            editorRef,
            field,
            formulaString: initialFormulaString,
            object,
            onChange,
            parsedFormula: parseResult.isValid ? parseResult.output : undefined,
            track,
        }
    }
)

export const setFocusAtEndOfEditor = (textarea: HTMLTextAreaElement): void => {
    textarea.focus()
    textarea.selectionStart = textarea.value.length
    textarea.selectionEnd = textarea.value.length
}

export const insertAutocompleteTextAndSetSelection = (
    params: {
        selection: FormulaEditorSelection
        textarea: HTMLTextAreaElement
    } & (
        | {
              field: FieldDto & { insertText: string }
              type: 'field'
          }
        | {
              keyword: Keyword
              type: 'keyword'
          }
    )
): string => {
    const { insertedTextStart, insertedTextEnd, result } = insertAutocompleteText({
        editor: params.textarea,
        selection: params.selection.currentSelection,
        newText:
            params.type === 'field' ? `{${params.field.insertText}}` : params.keyword.insertText,
    })

    setCaretAtPositionAndFocus({
        editor: params.textarea,
        position:
            params.type === 'field'
                ? insertedTextEnd
                : getCaretPositionAfterInsertingKeyword({
                      keyword: params.keyword,
                      start: insertedTextStart,
                  }),
    })

    return result
}

export const updateSuggestionsAndHelperValues = assign(
    ({
        field,
        formulaString,
        object,
        selection,
        canAutocomplete: initialCanAutoComplete,
    }: FormulaEditorContext) => {
        return getSuggestionsAndHelperValues({
            field,
            formulaString,
            initialCanAutoComplete,
            object,
            selection,
        })
    }
)

export function getSuggestionsAndHelperValues({
    field,
    formulaString,
    initialCanAutoComplete,
    object,
    selection,
}: {
    field?: FieldDto
    formulaString: string
    initialCanAutoComplete: boolean
    object?: ObjectDto
    selection: FormulaEditorSelection
}) {
    if (!object) {
        throw new Error('object not set')
    }

    const isBlank = /^\s+$/.test(formulaString) || isEmpty(formulaString)
    const canAutocomplete = isBlank ? true : initialCanAutoComplete

    const duplicatedFieldNames = keys(
        pickBy(
            groupBy(object.fields, (f) => f.label.toLowerCase()),
            (x) => x.length > 1
        )
    ).map((n) => n.toLowerCase())

    const suggestedFields = sortFieldsByOrder(object.fields, object)
        .filter((f) => !isDisabled(f))
        .filter((f) => isFieldParseable(f))
        .filter((f) => isFormulaSupportedField(f))
        .filter((f) => f.api_name !== field?.api_name)
        .filter((f) => {
            if (isBlank) {
                return true
            }
            return f.label
                .toLowerCase()
                .includes(selection.currentSelection?.name.toLowerCase() as string)
        })
        .map((f) => ({
            ...f,
            insertText: `${f.label}${
                duplicatedFieldNames.includes(f.label.toLowerCase()) ? ` [${f.api_name}]` : ''
            }`,
        }))

    // Identifies the last word typed or `{`
    const regex = new RegExp(/\s*(\w+|\{)\s*(?=$|\)|,)/, 'gi')
    const match = formulaString.match(regex)
    const shouldOnlyShowFields = !!match && match[0].trim() === '{'

    return {
        canAutocomplete,
        functionForHelpPanel: selectFunctionForHelpPanel({ selection }),
        isBlank,
        shouldOnlyShowFields,
        suggestedFields,
        suggestedKeywords: getSuggestedKeywords({
            selection,
            availableKeywords: getAllAvailableFunctions(),
            isBlank,
        }),
    }
}

const selectFunctionForHelpPanel = ({
    selection,
}: {
    selection: FormulaEditorSelection
}): Keyword | undefined => {
    const closestFunction = selection.closestFunction
    if (!closestFunction) {
        return undefined
    }
    return getSortedKeywords({
        availableKeywords: getAllAvailableFunctions(),
        functionSelection: closestFunction,
    })[0]
}

const getSuggestedKeywords = ({
    availableKeywords,
    isBlank,
    selection,
}: {
    availableKeywords: Keyword[]
    isBlank: boolean
    selection: FormulaEditorSelection
}): Keyword[] => {
    return isBlank
        ? availableKeywords
        : selection.currentSelection?.type === 'function'
          ? getSortedKeywords({ availableKeywords, functionSelection: selection.currentSelection })
          : []
}

const getSortedKeywords = ({
    availableKeywords,
    functionSelection,
}: {
    availableKeywords: Keyword[]
    functionSelection: FormulaEditorFieldOrFunctionSelection
}): Keyword[] => {
    const prefixedKeywords: Keyword[] = []
    const nonPrefixedKeywords: Keyword[] = []
    for (const keyword of availableKeywords) {
        if (keywordStartsWithSelection(keyword, functionSelection)) {
            prefixedKeywords.push(keyword)
        } else if (keywordContainsSelection(keyword, functionSelection)) {
            nonPrefixedKeywords.push(keyword)
        }
    }
    return [...prefixedKeywords, ...nonPrefixedKeywords]
}

const keywordStartsWithSelection = (
    keyword: Keyword,
    functionSelection: FormulaEditorFieldOrFunctionSelection
): boolean => keyword.insertText.toLowerCase().startsWith(functionSelection.name.toLowerCase())

const keywordContainsSelection = (
    keyword: Keyword,
    functionSelection: FormulaEditorFieldOrFunctionSelection
): boolean => keyword.insertText.toLowerCase().includes(functionSelection.name.toLowerCase())

import React from 'react'

import { LayoutEditorViewPane } from 'features/views/LayoutEditor/controls/LayoutEditorViewPane'
import { LayoutEditorWidgetPicker } from 'features/views/LayoutEditor/controls/LayoutEditorWidgetPicker'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'

import { useDetailViewControlsState } from './hooks/useDetailViewControlsState'

type DetailViewControlsProps = {}

export const DetailViewControls: React.FC<DetailViewControlsProps> = () => {
    const { title, viewName, onChangeViewName, onClickWidget } = useDetailViewControlsState()

    return (
        <LayoutEditorViewPane title={title}>
            <Box flex column gap="l">
                <Input
                    label="Name"
                    placeholder="View name"
                    value={viewName}
                    onChange={onChangeViewName}
                />
                <LayoutEditorWidgetPicker onClickWidget={onClickWidget} />
            </Box>
        </LayoutEditorViewPane>
    )
}

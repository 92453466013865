import React from 'react'

import { Excalidraw } from '@excalidraw/excalidraw'
import { ExcalidrawElement, NonDeleted } from '@excalidraw/excalidraw/types/element/types'
import { AppState, BinaryFiles } from '@excalidraw/excalidraw/types/types'

import SimpleModal from 'v2/ui/components/SimpleModal'

import { ExcalidrawModalStyles } from './ExcalidrawModal.css'
type ExcalidrawModalProps = {
    elements: NonDeleted<ExcalidrawElement>[]
    files: BinaryFiles
    onChange: (
        elements: readonly ExcalidrawElement[],
        appState: AppState,
        files: BinaryFiles
    ) => void
    onClose?: () => void
}
export function ExcalidrawModal({ elements, files, onChange, onClose }: ExcalidrawModalProps) {
    return (
        <SimpleModal
            open
            onClose={onClose}
            closeOnEsc
            showCloseButton
            usePortal
            width="98%"
            padding="0"
        >
            <div className={ExcalidrawModalStyles}>
                <Excalidraw initialData={{ elements, files }} onChange={onChange} />
            </div>
        </SimpleModal>
    )
}

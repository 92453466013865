import React from 'react'

import useRecordEditManager, {
    RecordEditManagerArgs,
} from 'features/datagrid/hooks/useRecordEditManager'

import { RecordEditManagerContext } from './RecordEditManagerContext'

export function RecordEditManagerContextProvider({
    children,
    ...props
}: RecordEditManagerArgs & { children: React.ReactNode }) {
    const recordEditManager = useRecordEditManager(props)

    return (
        <RecordEditManagerContext.Provider value={recordEditManager}>
            {children}
        </RecordEditManagerContext.Provider>
    )
}

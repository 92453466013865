import React, { forwardRef } from 'react'

import { useTheme } from 'ui/styling/themes/useTheme'

import * as Parts from './Modal.parts'

type ModalContentRef = HTMLDivElement

type ModalContentProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Content>, 'asChild'> & {
    zIndex?: number
}

export const ModalContent = forwardRef<ModalContentRef, ModalContentProps>(
    ({ zIndex, ...props }, ref) => {
        const { themeClassName } = useTheme()

        return (
            <Parts.Portal>
                <Parts.Overlay className={themeClassName} style={{ zIndex }}>
                    <Parts.Content ref={ref} {...props} />
                </Parts.Overlay>
            </Parts.Portal>
        )
    }
)

import { useMemo } from 'react'

import { useViews } from 'data/hooks/views'

export type DefaultValue = {
    fieldId: string
    fieldName: string
    defaultValue: string
}

export function useDefaultValuesForView(viewId: string): DefaultValue[] {
    const { data: views = [] } = useViews()

    return useMemo(() => {
        if (!viewId) return []

        const createView = getCreateViewForViewId(viewId, views)
        if (!createView) return []

        return extractDefaultValuesFromView(createView)
    }, [viewId, views])
}

function getCreateViewForViewId(viewId: string, views: ViewDto[]): ViewDto | undefined {
    const currentView = views.find((v) => v._sid === viewId)
    if (!currentView) return undefined

    const createView = views.find(
        (v) => v.object_id === currentView.object_id && v.type === 'create'
    )

    return createView
}

function extractDefaultValuesFromView(view: ViewDto): DefaultValue[] {
    const viewBlocks = view.options.blocks?.[0]?.childBlocks?.[0]
        ?.childBlocks as BlockFieldContainer[]
    if (!viewBlocks) return []

    return viewBlocks.reduce<DefaultValue[]>((agg, curr) => {
        if (curr.type !== 'field_container') return agg

        const contents = curr.config.attributes.contents
        for (const field of contents) {
            if (field.type !== 'field') continue

            const defaultValue = field.defaultValue
            if (typeof defaultValue === 'undefined') continue

            agg.push({
                fieldId: field.fieldId,
                fieldName: field.fieldName,
                defaultValue,
            })
        }

        return agg
    }, [])
}

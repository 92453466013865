import React from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { Urls } from 'app/UrlService'
import publicAsset from 'utils/publicAsset'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { OnboardingButtons } from './OnboardingButtons'
import { OnboardingFrame } from './OnboardingFrame'

export function OnboardingProductSplit() {
    const { user } = useAuthContext()
    const location = useLocation()
    const history = useHistory()

    const redirectToClassicProduct = () => {
        const currentSearchParams = new URLSearchParams(location.search).toString()
        window.location.href = `https://studio.stackerhq.com/register${
            currentSearchParams ? `?${currentSearchParams}` : ''
        }`
    }

    const redirectToSignUp = () => {
        history.push(Urls.OnboardingSignup)
    }

    const buttonsContext = [
        {
            image: publicAsset('/static/media/integration_gallery/airtable.svg'),
            heading: 'Airtable',
            onClick: redirectToClassicProduct,
        },
        {
            image: publicAsset('/static/media/integration_gallery/gsheets.svg'),
            heading: 'Google Sheets',
            onClick: redirectToSignUp,
        },
        {
            image: publicAsset('/static/media/more-square.svg'),
            heading: 'Other',
            onClick: redirectToSignUp,
        },
        {
            image: publicAsset('/static/media/edit.svg'),
            heading: 'Start from scratch',
            onClick: redirectToSignUp,
        },
    ]

    if (user) {
        return <Redirect to={Urls.Root} />
    }

    return (
        <OnboardingFrame
            headline="Select data source"
            title="Get started with Stacker"
            previewContents={
                <Box flex column center gap="4xl">
                    <Body textAlign="center" weight="medium" size="l">
                        High-performing teams at <br />
                        companies worldwide build with Stacker
                    </Body>
                    <img
                        src={publicAsset('/static/media/client-logo-strip.png')}
                        alt="Client logos"
                    />
                </Box>
            }
        >
            <Box flex column gap="xl" my="2xl">
                <Body size="l" paddingBottom="4xl">
                    Choose the data source you&apos;d like to use to build your first app
                </Body>
                <OnboardingButtons buttonsContext={buttonsContext} />
            </Box>
        </OnboardingFrame>
    )
}

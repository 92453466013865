// @ts-strict-ignore
import React, { useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import { useRoleListOptions } from 'data/hooks/roles'
import { SharingSettingsPatch } from 'data/hooks/stacks'
import { GroupActions, SaveChangesBar, UserActions } from 'features/users/shared/AppUsersUI'
import GroupListItem, { GroupListItemProps } from 'features/users/shared/GroupListItem'
import UserList from 'features/users/shared/UserList'
import UserListItem, { UserListItemProps } from 'features/users/shared/UserListItem'

import { Dropdown } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { FormWrapper } from 'ui/deprecated/forms/Form'
import { FormField } from 'ui/deprecated/forms/FormField'
import { decodeFieldKey, encodeFieldKey, SyncRefWithForm } from 'ui/deprecated/forms/utils'

import { TableGroupComponent } from './TableGroupComponent'

const colors = stackerTheme().colors
type Props = {
    formRef: any
    children: any
    users: any[]
    grants: AccessGrant[]
    disabled: boolean
    onSave: any
}

export function ManualAppUserList(props: Props) {
    const { formRef, children, users, grants, onSave } = props
    const [listResetKey, setListResetKey] = useState(0)
    const [error, setError] = useState<string | undefined>()

    const formContext = useForm<SharingSettingsPatch>({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const { data: roleOptions } = useRoleListOptions()

    const cancelEdit = () => {
        formContext.reset()
        setListResetKey((val) => val + 1)
        setError(undefined)
    }

    const saveChanges = (patch) => {
        // decode the userIds from the patch before sending to the caller
        const translatedPatch = Object.keys(patch).reduce((result, key) => {
            result[decodeFieldKey(key)] = patch[key]
            return result
        }, {})

        setError(undefined)

        try {
            return onSave(translatedPatch)
                .then(() => {
                    setError(undefined)
                    setListResetKey((val) => val + 1)
                })
                .catch((ex) => {
                    console.error(ex)
                    // If the promise was rejected, it was a server error
                    // so show a generic message
                    setError('An error occurred. You may not have permission to do this.')
                    throw ex
                })
        } catch (ex) {
            // If an error was thrown during the processing of the save data
            // it's the caller rejecting the data and so we should show the message
            setError(ex.message)
            throw ex
        }
    }

    return (
        <FormWrapper
            formContext={formContext}
            onSubmit={saveChanges}
            // @ts-ignore
            style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: 0,
                maxHeight: '100%',
            }}
            onlySubmitChangedfields
            resetOnSuccess
        >
            {formRef && <SyncRefWithForm formRef={formRef} />}
            <UserList
                // react-hook-form can't just "reset" the form state to default values
                // unless those default values are specified up front in the useForm call.
                // We're using inline default values at the field level, so that doesn't work.
                // The solution is just to recreate the list when resetting form state.
                key={listResetKey}
                inEditMode
                users={users}
                grants={grants}
                roleOptions={roleOptions}
                GroupComponent={GroupComponent}
                UserComponent={UserComponent}
            />

            <SaveChangesBar cancelEdit={cancelEdit} error={error} />
            {children}
        </FormWrapper>
    )
}

const GroupComponent: React.FC<GroupListItemProps> = (props) => {
    const { group } = props
    const { watch, setValue, register } = useFormContext()
    const { data: options } = useRoleListOptions(undefined, true)
    const key = encodeFieldKey(props.group._sid)
    const deletedkey = `${key}.deleted`
    const deleted = watch(deletedkey)
    const remove = () => {
        setValue(deletedkey, true, { shouldDirty: true })
    }
    if (group.type === 'table') {
        return <TableGroupComponent UserComponent={GroupedUserComponent} {...props} />
    }

    return (
        <GroupListItem
            {...props}
            rowContainerStyle={{
                textDecoration: deleted ? 'line-through' : 'none',
            }}
            rowContents={
                <>
                    <div style={{ flexGrow: 1 }} />
                    <input type="hidden" {...register(deletedkey)} />
                    {!deleted && (
                        <FormField
                            maxWidth="150px"
                            width="150px"
                            as={Dropdown}
                            controlledDefaultValue={group.role}
                            name={`${key}.role`}
                            options={options}
                            variant="settings"
                            isClearable={false}
                            isSearchable={false}
                            controlled
                        />
                    )}
                    {!deleted && <GroupActions group={props.group} onRemove={remove} />}
                </>
            }
            UserComponent={GroupedUserComponent}
        />
    )
}

const UserComponent: React.FC<UserListItemProps> = (props) => {
    const { watch, setValue, register } = useFormContext()
    const { data: options } = useRoleListOptions(undefined, true)
    const userKey = encodeFieldKey(props.user._sid)
    const deletedkey = `${userKey}.deleted`
    const deleted = watch(deletedkey)
    const removeUser = () => {
        setValue(deletedkey, true, { shouldDirty: true })
    }
    const hasAccess = props.user.role

    return (
        <UserListItem
            {...props}
            limitWidth={true}
            rowContainerStyle={{
                color: !hasAccess ? colors.neutral[600] : undefined,
                textDecoration: deleted ? 'line-through' : undefined,
            }}
            rowContents={
                <>
                    <input type="hidden" {...register(deletedkey)} />

                    <div style={{ flexGrow: 1 }} />
                    {!deleted && (
                        <FormField
                            maxWidth="150px"
                            width="150px"
                            as={Dropdown}
                            controlledDefaultValue={props.user.role}
                            name={`${userKey}.role`}
                            options={options}
                            variant="settings"
                            isClearable={false}
                            isSearchable={false}
                            controlled
                        />
                    )}
                </>
            }
            rowActions={!deleted && <UserActions user={props.user} onRemove={removeUser} />}
        />
    )
}

const GroupedUserComponent: React.FC<UserListItemProps> = (props) => {
    const hasAccess = props.user.role
    const { watch } = useFormContext()
    const key = encodeFieldKey(props.user.group)
    const deletedkey = `${key}.deleted`
    const deleted = watch(deletedkey)
    return (
        <UserListItem
            {...props}
            rowContainerStyle={{
                color: !hasAccess ? colors.neutral[600] : undefined,

                textDecoration: deleted ? 'line-through' : undefined,
            }}
            rowContents={
                <>
                    <div style={{ flexGrow: 1 }} />
                </>
            }
            rowActions={!deleted && <UserActions user={props.user} />}
        />
    )
}

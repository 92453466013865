import React from 'react'

import styled from '@emotion/styled'

import { withTheme } from 'v2/ui/theme/components/withTheme'

const InnerIcon8 = ({
    icon = 'icon',
    iconStyle = 'material',
    large = false,
    size,
    displaySize,
    color = '000000',
    noMargin = false,
    fallbackIcon,
    ...otherProps
}: Partial<{
    icon: string
    iconStyle: string
    large: boolean
    size: string
    displaySize: string
    color: string
    noMargin: boolean
    fallbackIcon: string
}> &
    Record<string, any>) => {
    let sizeToDisplay = displaySize
    if (!displaySize) {
        sizeToDisplay = large ? '40' : '20'
    }
    const src = `https://img.icons8.com/${iconStyle}/${size || sizeToDisplay}/${color}/${icon}`
    const fallbackSrc = `https://img.icons8.com/${iconStyle}/${
        size || sizeToDisplay
    }/${color}/${fallbackIcon}`

    const Image = styled('img')`
        vertical-align: middle;
        margin-right: ${() => (noMargin ? '0px' : '8px')};
        height: ${() => `${sizeToDisplay}px`};
    `

    return (
        <Image
            {...otherProps}
            src={src}
            onError={(e) => {
                if (fallbackIcon) {
                    ;(e.target as HTMLImageElement).src = fallbackSrc
                }
            }}
        />
    )
}

export const Icon8 = withTheme(InnerIcon8)

import React, { useCallback } from 'react'

import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItemLink } from 'ui/components/Dropdown'
import { LinkButton } from 'ui/components/LinkButton'
import { Skeleton } from 'ui/components/Skeleton'
import { stopPropagation } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useRecordLinksAttributeTagState } from './hooks/useRecordLinksAttributeTagState'
import { AttributeDisplayComponent } from './types'

const sizeMapping = {
    button: {
        s: '2xs',
        m: 'xs',
    },
} as const

type RecordLinksAttributeTagProps = React.ComponentPropsWithoutRef<typeof Box> & {
    size?: ResponsiveValue<'s' | 'm'>
    maxItemLength?: number
    dereferencedRecords?: RecordDto[]
    isLoading?: boolean
    showIcon?: boolean
}

export const RecordLinksAttributeTag: AttributeDisplayComponent<
    string | string[],
    RecordLinksAttributeTagProps
> = React.memo(function RecordLinksAttributeTag({
    value,
    dereferencedRecords,
    maxItemLength,
    isLoading,
    field,
    style,
    size = 'm',
    showIcon,
    ...props
}) {
    const effectiveSize = useResponsiveValue(size)
    const buttonSize = sizeMapping.button[effectiveSize]

    const {
        records,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelCloseAutoFocus,
        label,
        icon,
    } = useRecordLinksAttributeTagState({
        field,
        value,
        dereferencedRecords,
        maxItemLength,
        isLoading,
        showIcon,
    })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    if (records.length === 0) {
        return null
    }

    const isSingle = records.length === 1

    return (
        <Box flex center {...props}>
            <Skeleton isLoading={isLoading}>
                {isSingle ? (
                    <LinkButton
                        variant="secondary"
                        size={buttonSize}
                        to={records[0].url}
                        onClick={handleClick}
                        style={{
                            wordBreak: 'break-word',
                            maxWidth: '100%',
                            minWidth: 0,
                            ...style,
                        }}
                        noShrink
                        startIcon={icon}
                    >
                        <Box trim maxWidth="full" py="3xs">
                            {records[0].name}
                        </Box>
                    </LinkButton>
                ) : (
                    <Dropdown open={isOverflowPopupOpen} onOpenChange={onOverflowPopupOpenChange}>
                        <DropdownButton
                            variant="secondary"
                            size={buttonSize}
                            noShrink
                            onClick={stopPropagation}
                            onMouseEnter={onOverflowLabelMouseEnter}
                            onMouseLeave={onOverflowLabelMouseLeave}
                            style={{
                                maxWidth: '100%',
                                minWidth: 0,
                            }}
                            startIcon={icon}
                        >
                            <Box trim maxWidth="full" py="3xs">
                                {label}
                            </Box>
                        </DropdownButton>
                        <DropdownContent
                            sideOffset={0}
                            side="bottom"
                            align="end"
                            onCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                            onMouseEnter={onOverflowLabelMouseEnter}
                            onMouseLeave={onOverflowLabelMouseLeave}
                        >
                            {records.map((record) => (
                                <DropdownItemLink
                                    key={record.url}
                                    to={record.url}
                                    label={record.name}
                                    onClick={handleClick}
                                />
                            ))}
                        </DropdownContent>
                    </Dropdown>
                )}
            </Skeleton>
        </Box>
    )
}, isEqual)

import { TableHeader } from '@tiptap/extension-table-header'

import { TableHeaderStyle } from './TableExtension.css'

export function createTableHeaderExtension() {
    return TableHeader.configure({
        HTMLAttributes: {
            class: TableHeaderStyle,
        },
    })
}

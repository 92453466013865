import React from 'react'

import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

import { Box } from 'ui/components/Box'

type NotificationTimestampProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: string
}

export const NotificationTimestamp: React.FC<NotificationTimestampProps> = ({
    value,
    ...props
}) => {
    const timeDisplay = useDisplayTimeFrom(value)

    return (
        <Box as="span" color="textWeak" fontSize="bodyS" {...props}>
            {timeDisplay}
        </Box>
    )
}

import React from 'react'
import { Redirect } from 'react-router-dom'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getUrl, Urls } from 'app/UrlService'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Divider } from 'ui/components/Divider'
import { Input } from 'ui/components/Input'
import { Link } from 'ui/components/Link'
import { Body } from 'ui/components/Text'

import { AuthFrame } from './AuthFrame'
import { GoogleLoginButton } from './GoogleLoginButton'
import { useSignUpForm as useSignUpForm } from './useSignUpForm'

export function SignUpPage() {
    const { user } = useAuthContext()

    const { register, onSubmit, errors, errorMessage, isLoading } = useSignUpForm()

    if (user) {
        return <Redirect to={getUrl(Urls.Root)} />
    }
    return (
        <AuthFrame title="Sign up to Stacker">
            <GoogleLoginButton />
            <Box flex gap="m" center my="2xl" color="textWeakest">
                <Divider />
                <Box>or</Box>
                <Divider />
            </Box>
            <Box as="form" flex column gap="m" onSubmit={onSubmit}>
                <Input
                    placeholder="Full name"
                    {...register('name', { required: true })}
                    isError={!!errors?.name}
                    helperText={errors?.name && 'Name is required'}
                    autoFocus
                />
                <Input
                    placeholder="Email address"
                    {...register('email', { required: true })}
                    isError={!!errors?.email}
                    helperText={errors?.email && 'Email is required'}
                />
                <Input
                    placeholder="Password"
                    type="password"
                    {...register('password', { required: true })}
                    isError={!!errors?.password}
                    helperText={errors?.password && 'Password is required'}
                />
                {errorMessage && (
                    <Box color="textError" fontSize="bodyM" alignSelf="center" mb="m">
                        {errorMessage}
                    </Box>
                )}
                <Button variant="primary" mt="m" type="submit" isLoading={isLoading}>
                    Sign up
                </Button>
                <Body size="s" mt="m" alignSelf="center">
                    Already have an account?{' '}
                    <Link size="s" to={getUrl(Urls.Login) + window.location.search}>
                        Log in
                    </Link>
                </Body>
            </Box>
        </AuthFrame>
    )
}

import React, { useMemo, useState } from 'react'

import { EditModeListContext } from './EditModeListContext'

export const EditModeListContextProvider: React.FC<{ disableEditControls?: boolean }> = ({
    disableEditControls,
    children,
}) => {
    const [lastHoveredRecordId, setLastHoveredRecordId] = useState<string | undefined>()
    const [hoveredEditorFieldId, setHoveredEditorFieldId] = useState<string | undefined>()
    const [activeEditorFieldId, setActiveEditorFieldId] = useState<string | undefined>()

    const contextState = useMemo(
        () => ({
            disableEditControls,
            lastHoveredRecordId,
            setLastHoveredRecordId,
            hoveredEditorFieldId,
            setHoveredEditorFieldId,
            activeEditorFieldId,
            setActiveEditorFieldId,
        }),
        [
            disableEditControls,
            lastHoveredRecordId,
            setLastHoveredRecordId,
            hoveredEditorFieldId,
            setHoveredEditorFieldId,
            activeEditorFieldId,
            setActiveEditorFieldId,
        ]
    )

    return (
        <EditModeListContext.Provider value={contextState}>{children}</EditModeListContext.Provider>
    )
}

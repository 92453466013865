import React, { forwardRef, useMemo } from 'react'

import { makeComponent } from 'ui/helpers/recipes'
import { StandardComponentProps } from 'ui/helpers/styles'
import { makeLineTruncationStyle, truncateText } from 'ui/helpers/utilities'
import {
    PropsWithResponsiveValues,
    ResponsiveValue,
    useTransformResponsiveProps,
} from 'ui/styling/helpers/useResponsiveValue'

import { headline } from './Headline.css'

const HeadlineBase = makeComponent('span', headline)

type HeadlineRef = HTMLSpanElement

export type HeadlineProps = PropsWithResponsiveValues<
    React.ComponentPropsWithoutRef<typeof HeadlineBase>,
    'size'
> &
    StandardComponentProps & {
        maxLength?: ResponsiveValue<number>
        maxLines?: ResponsiveValue<number>
    }

export const Headline = forwardRef<HeadlineRef, HeadlineProps>(
    ({ children, size, maxLength, maxLines, style, ...props }, ref) => {
        const {
            size: effectiveSize,
            maxLength: effectiveMaxLength,
            maxLines: effectiveMaxLines,
        } = useTransformResponsiveProps({
            size,
            maxLength,
            maxLines,
        })

        const computedStyle = useMemo(() => {
            return {
                ...makeLineTruncationStyle(effectiveMaxLines),
                ...style,
            }
        }, [style, effectiveMaxLines])

        const content = useMemo(() => {
            if (typeof children === 'string' && effectiveMaxLength) {
                return truncateText(children, effectiveMaxLength)
            }

            return children
        }, [children, effectiveMaxLength])

        return (
            <HeadlineBase ref={ref} style={computedStyle} size={effectiveSize} {...props}>
                {content}
            </HeadlineBase>
        )
    }
)

import React, { forwardRef, useMemo } from 'react'

import type { RadioGroupItemProps as RadixRadioButtonProps } from '@radix-ui/react-radio-group'

import { extractStandardProps, StandardComponentProps } from 'ui/helpers/styles'
import { generateUniqueId } from 'ui/helpers/utilities'

import * as Parts from './Radio.parts'

import { RadioButtonVariants } from './Radio.css'

type RadioButtonRef = HTMLInputElement

type RadioButtonProps = Omit<RadixRadioButtonProps, 'asChild'> &
    RadioButtonVariants &
    StandardComponentProps & {
        isError?: boolean
        helperText?: string
        infoText?: string
    }

export const RadioButton = forwardRef<RadioButtonRef, RadioButtonProps>(
    ({ id, className, isError, helperText, infoText, ...props }, ref) => {
        // this is so the id property can be optional, but still use the
        // label htmlFor property to link the label to the input.
        const effectiveId = useMemo(() => id || generateUniqueId(), [id])
        const [standardProps, rootProps] = extractStandardProps(props)

        const hasHelperText = Boolean(helperText)

        return (
            <Parts.Container
                ref={ref}
                className={className}
                hasHelperText={hasHelperText}
                {...standardProps}
            >
                <Parts.Button
                    id={effectiveId}
                    aria-invalid={isError || undefined}
                    aria-label={typeof props.children === 'string' ? props.children : undefined}
                    {...rootProps}
                >
                    <Parts.Indicator forceMount />
                </Parts.Button>
                <Parts.Label
                    htmlFor={effectiveId}
                    disabled={rootProps.disabled}
                    label={props.children}
                    required={rootProps.required}
                    infoText={infoText}
                    helperText={helperText}
                    isError={isError}
                />
            </Parts.Container>
        )
    }
)

import React, { forwardRef } from 'react'

import { Box } from 'ui/components/Box'
import { IconNameVariantType } from 'ui/components/Icon/Icon'

import { MenuIcon } from './Dropdown.parts'
import { SlotContentProps } from './types'

type IconProps = IconNameVariantType

type DropdownEndIndicatorIconRef = HTMLDivElement

type DropdownEndIndicatorIconProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'name'> &
    IconProps &
    SlotContentProps & {}

export const DropdownEndIndicatorIcon = forwardRef<
    DropdownEndIndicatorIconRef,
    DropdownEndIndicatorIconProps
>(({ name, ...props }, ref) => {
    return (
        <Box {...props} ref={ref}>
            <MenuIcon size="m" name={name} />
        </Box>
    )
})

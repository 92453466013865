import React, { forwardRef } from 'react'

import * as Parts from './Field.parts'

type LabelTextRef = HTMLLabelElement

type LabelTextProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Label>, 'isDisabled'> & {
    disabled?: boolean
}

export const LabelText = forwardRef<LabelTextRef, LabelTextProps>(
    ({ disabled, children, ...props }, ref) => {
        return (
            <Parts.Label ref={ref} isDisabled={disabled} {...props}>
                {children}
            </Parts.Label>
        )
    }
)

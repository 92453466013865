import React from 'react'

import type { ContextMenuSubContentProps as BaseProps } from '@radix-ui/react-context-menu'

import { StandardComponentProps } from 'ui/helpers/styles'

import { Portal, SubContent } from './ContextMenu.parts'

type ContextMenuSubContentProps = Omit<BaseProps, 'asChild'> &
    StandardComponentProps & {
        usePortal?: boolean
    }

export const ContextMenuSubContent: React.FC<ContextMenuSubContentProps> = ({
    usePortal = true,
    ...props
}) => {
    return (
        <>
            {usePortal === true ? (
                <Portal>
                    <SubContent {...props} />
                </Portal>
            ) : (
                <SubContent {...props} />
            )}
        </>
    )
}

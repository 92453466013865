import React, { useMemo, useState } from 'react'

import { useListViewContext } from 'features/views/ListView/useListViewContext'

import { useSortAvailableFields } from './hooks/useSortAvailableFields'
import { ListViewSort } from './types'
import { SortContext } from './useSortContext'

export type SortContextProviderProps = {}

export const SortContextProvider: React.FC<SortContextProviderProps> = ({ children }) => {
    const { view } = useListViewContext()

    const sortBy = view.options.order as ListViewSort | undefined
    const [sortByOverride, setSortByOverride] = useState<ListViewSort | undefined>(undefined)

    let effectiveSortBy = sortByOverride ?? sortBy
    if (!effectiveSortBy?.id) effectiveSortBy = undefined

    const availableFields = useSortAvailableFields()

    const sortType = view.options.orderType
    const manualSortKey = sortType === 'manual' ? `${view.stack_id}_${view._sid}` : undefined

    const value = useMemo(
        () => ({
            sortBy: effectiveSortBy,
            setSortBy: setSortByOverride,
            availableFields,
            sortType,
            manualSortKey,
            defaultSortBy: sortBy,
        }),
        [effectiveSortBy, availableFields, sortType, manualSortKey, sortBy]
    )

    return <SortContext.Provider value={value}>{children}</SortContext.Provider>
}

import { TaskDescription } from 'data/hooks/tasks/types'

import { TaskAssignee } from './types'

export type NewTaskParams = {
    title: string
    description: TaskDescription
    dueDate: string
    assignees: TaskAssignee[]
    related_to: string | null
}

export function validateNewTask({ title, assignees, related_to }: NewTaskParams): string {
    if (!title) return 'Please provide a task title.'
    if (assignees.length < 1 && !related_to) return 'Please assign the task to at least one person.'

    return ''
}

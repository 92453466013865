import { useMemo } from 'react'

import { useObject } from 'data/hooks/objects'
import { useUserProfileObjects } from 'data/hooks/userProfiles'
import { useUserRecord } from 'data/hooks/users/main'

import { getUserFieldOptions } from './utils'

export const useUserFieldOptions = (field: FieldDto) => {
    const { data: userRecord } = useUserRecord()
    const userObjectId = userRecord?.[0]?._object_id
    const { object: userObject } = useObject(userObjectId)
    const userProfileObjects = useUserProfileObjects(userObjectId)

    const options = useMemo(
        () => getUserFieldOptions(field, userObject, userProfileObjects),
        [field, userObject, userProfileObjects]
    )
    return options
}

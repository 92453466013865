import React, { useMemo } from 'react'

import type { ViewColumn } from 'v2/views/List/types'

import {
    inlineFilterLabelMap,
    InlineFilterOperation,
} from 'features/records/components/RecordFilters/constants'

import { Box } from 'v2/ui'

import { InlineFilterValueInput } from './components/inputs/InlineFilterValueInput'
import { InlineFilterDescriptor } from './InlineFilterDescriptor'
import { InlineFilterMenu } from './InlineFilterMenu'
import { InlineFilterOpDropdown } from './InlineFilterOpDropdown'
import { InlineFilterMultiValueOption } from './types'

type OpOptions = React.ComponentPropsWithoutRef<typeof InlineFilterOpDropdown>['options']

type InlineFilterProps = Omit<
    React.ComponentPropsWithoutRef<typeof InlineFilterMenu>,
    'label' | 'onClear' | 'children'
> & {
    field: FieldDto
    columns?: ViewColumn[]
    options?: InlineFilterMultiValueOption[]
    isActive: boolean
    value: FilterOptions['value']
    valueLabels: string[]
    onValueChange: (value: FilterOptions['value']) => void
    operation: InlineFilterOperation | undefined
    onOperationChange: (operation: InlineFilterOperation) => void
    operations: InlineFilterOperation[]
    onClear: () => void
}

export const InlineFilter = React.forwardRef<HTMLDivElement, InlineFilterProps>(
    (
        {
            field,
            columns,
            isActive,
            value,
            valueLabels,
            onValueChange,
            operation,
            onOperationChange,
            operations,
            onClear,
            options = [],
            buttonProps,
            ...props
        },
        ref
    ) => {
        const opOptions: OpOptions | undefined = useMemo(() => {
            if (!operations || operations.length < 1) return undefined

            return operations.map((op) => ({
                value: op,
                label: inlineFilterLabelMap[op] ?? op,
            }))
        }, [operations])

        const showOpOptions = opOptions && opOptions.length > 1

        const activeOpOption = useMemo(() => {
            if (!showOpOptions) return undefined

            return opOptions?.find((option) => option.value === operation)
        }, [opOptions, operation, showOpOptions])

        const label = useMemo(
            () =>
                columns?.find(({ field: colField }) => colField?._sid === field._sid)?.renderOptions
                    ?.label || field.label,
            [columns, field._sid, field.label]
        )

        return (
            <InlineFilterMenu
                ref={ref}
                label={
                    <InlineFilterDescriptor
                        label={label}
                        opLabel={activeOpOption?.label}
                        values={valueLabels}
                        showValue={isActive}
                    />
                }
                showClearButton={isActive}
                isActive={isActive}
                onClear={onClear}
                buttonProps={{
                    'aria-label': label,
                    ...buttonProps,
                }}
                {...props}
            >
                {showOpOptions && (
                    <InlineFilterOpDropdown
                        options={opOptions!}
                        value={operation}
                        onChange={onOperationChange}
                    />
                )}

                <Box mt={showOpOptions ? 2 : 0} _empty={{ mt: 0 }}>
                    <InlineFilterValueInput
                        operation={operation}
                        field={field}
                        value={value}
                        onChange={onValueChange}
                        options={options}
                    />
                </Box>
            </InlineFilterMenu>
        )
    }
)

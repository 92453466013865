import React from 'react'

import classNames from 'classnames'

import { Button } from 'ui/components/Button'
import { DatePicker } from 'ui/components/DatePicker'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAdvancedFilterValueDateState } from './hooks/useAdvancedFilterValueDateState'
import { AdvancedFilter } from './types'

import {
    AdvancedFiltersButtonStyle,
    AdvancedFiltersDatePickerCalendarStyle,
    AdvancedFiltersDatePickerStyle,
} from './AdvancedFilters.css'

type AdvancedFilterValueDateProps = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
    className?: string
}

export const AdvancedFilterValueDate: React.FC<AdvancedFilterValueDateProps> = ({
    filter,
    onChange,
    className,
}) => {
    const { onInputChange, value, showTime, dateFormat, calendarRef } =
        useAdvancedFilterValueDateState({
            filter,
            onChange,
        })

    return (
        <DatePicker
            ref={calendarRef}
            inline={false}
            value={value}
            showTime={showTime}
            onChange={onInputChange}
            dateFormat={dateFormat}
            customInput={<CustomInput className={className} />}
            wrapperClassName={AdvancedFiltersDatePickerStyle}
            calendarClassName={AdvancedFiltersDatePickerCalendarStyle}
        />
    )
}

type CustomInputRef = HTMLButtonElement

type CustomInputProps = {
    value?: string
    onClick?: () => void
    className?: string
}

const CustomInput = React.forwardRef<CustomInputRef, CustomInputProps>(
    ({ value, onClick, className }, ref) => {
        const size = useResponsiveValue<React.ComponentPropsWithoutRef<typeof Button>['size']>({
            mobile: '2xs',
            tablet: 's',
        })

        return (
            <Button
                ref={ref}
                size={size}
                variant="ghost"
                onClick={onClick}
                className={classNames(className, AdvancedFiltersButtonStyle)}
                iconOnly={false}
            >
                {value}
            </Button>
        )
    }
)

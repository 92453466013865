import React from 'react'

import { Item } from './types'

export type FieldsEditorContext = {
    editingFieldItem: Item | undefined
    setEditingFieldItem: (field: Item | undefined) => void

    isEditorOpen: boolean
    setIsEditorOpen: (isOpen: boolean) => void

    openEditorInPortal: boolean
    setOpenEditorInPortal: (inPortal: boolean) => void

    previousEditorTitle: string | undefined
    setPreviousEditorTitle: (title: string | undefined) => void
}

export const defaultContext: FieldsEditorContext = {
    editingFieldItem: undefined,
    setEditingFieldItem: () => {},
    isEditorOpen: false,
    setIsEditorOpen: () => {},
    openEditorInPortal: false,
    setOpenEditorInPortal: () => {},
    previousEditorTitle: undefined,
    setPreviousEditorTitle: () => {},
}

export const FieldsEditorContextImpl = React.createContext<FieldsEditorContext>(defaultContext)

import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import { OpenRecordFunction, RowLinkFunction, ViewHeader, ViewRow } from 'v2/views/List/types'

import ActionButtonsList from 'features/actions/ActionButtonsList'

import { Flex } from 'v2/ui'
import Rows from 'v2/ui/components/Rows'

const StyledFlex = styled(Flex)`
    margin: 0;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.colors.userInterface.neutral[200]};
    padding: 0.5rem 1rem;

    &:hover {
        background-color: ${({ theme }) => theme.colors.rows.backgroundHover};
    }
`

type RowsRendererListProps = {
    object?: ObjectDto
    data?: ViewRow[]
    headers?: ViewHeader[]
    rowLinkFunction?: RowLinkFunction
    additionalContainerContent?: React.ReactNode
    viewOptions?: ListViewOptions
    setViewOptions?: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    isEditing?: boolean
    openRecord?: OpenRecordFunction
}

export const RowsRendererList: React.FC<RowsRendererListProps> = ({
    object,
    data,
    headers,
    rowLinkFunction,
    additionalContainerContent,
    viewOptions,
    setViewOptions,
    isEditing = false,
    openRecord,
}) => {
    // Style each row's wrapper.
    const modifiedData = useMemo(() => {
        return data?.map((d) => {
            const newData = { ...d }
            const props = newData.props || {}

            props.variant = undefined
            props.as = StyledFlex

            if (newData.coverImage) {
                props.paddingLeft = '0.2rem'
            }

            newData.props = props

            // Override the action buttons' isEditing state.
            if (
                React.isValidElement<React.ComponentPropsWithoutRef<typeof ActionButtonsList>>(
                    newData.actionButtons
                )
            ) {
                newData.actionButtons = React.cloneElement(newData.actionButtons, {
                    showControls: isEditing,
                })
            }

            return newData
        })
    }, [data, isEditing])

    return (
        <Rows
            header={headers}
            data={modifiedData}
            object={object}
            rowLink={rowLinkFunction}
            additionalListContainerContent={additionalContainerContent}
            viewOptions={viewOptions}
            setConfig={setViewOptions}
            mb={0}
            useClassNames={false}
            boxShadow="unset"
            backgroundColor="transparent"
            spacing={0}
            openRecord={openRecord}
        />
    )
}

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeDatePickerVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            text: s.color.text,
            textDisabled: s.color.textDisabled,
            textDaysOfWeek: s.color.textWeaker,
            textDaySelected: s.color.textInverse,
            textTimeSelected: s.color.textInverse,

            surfaceContainer: s.color.surfaceTransparent,
            surfaceDay: s.color.surfaceTransparent,
            surfaceDaySelected: s.color.theme600,
            surfaceDayHover: s.color.surfaceStronger,
            surfaceRange: s.color.theme100,
            surfaceTime: s.color.surfaceTransparent,
            surfaceTimeSelected: s.color.theme600,
            surfaceTimeHover: s.color.surfaceStronger,
            surfaceSelector: s.color.surfaceTransparent,
            surfaceSelectorHover: s.color.surfaceStronger,
            surfaceSelectorActive: s.color.surfaceStronger,

            border: s.color.borderWeak,
        },
        padding: {
            container: s.space.xl,
            day: s.space['2xs'],
            selectorVertical: s.space['2xs'],
            selectorHorizontal: s.space.s,
            timeSelectorTitleVertical: s.space.l,
            timeSelectorItemVertical: s.space['2xs'],
            timeSelectorItemHorizontal: s.space.xl,
        },
        gap: {
            daysOfWeek: s.space.m,
            betweenDaysOfWeek: s.space['3xs'],
            grid: s.space.xl,
            betweenTimes: s.space.xs,
            timeSelectorTitle: s.space.s,
        },
        radius: {
            container: s.radius['2xl'],
            day: s.radius.m,
            selector: s.radius.s,
        },
        size: {
            day: pxToRem(28),
            calendar: pxToRem(256),
            timeSelector: pxToRem(72),
        },
        borderWidths: {
            container: s.borderWidths[1],
            timeSelector: s.borderWidths[1],
        },
        borderStyles: {
            container: s.borderStyles.base,
            timeSelector: s.borderStyles.base,
        },
        shadows: {
            focus: s.shadows.focus,
            popup: s.shadows.m,
        },
        fontSizes: {
            daysOfWeek: s.fontSizes.bodyM,
            timeSelectorTitle: s.fontSizes.bodyM,
            timeItem: s.fontSizes.bodyM,
        },
        fontWeights: {
            daysOfWeek: s.fontWeights.bodyBold,
            timeSelectorTitle: s.fontWeights.bodyMedium,
            timeItem: s.fontWeights.bodyRegular,
        },
        lineHeights: {
            daysOfWeek: s.lineHeights.bodyM,
            timeSelectorTitle: s.lineHeights.bodyM,
            timeItem: s.lineHeights.bodyM,
        },
    }
}

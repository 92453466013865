import React from 'react'

import { DropdownContext } from './dropdownContext'

export type DropdownContextProviderProps = {
    children: React.ReactNode
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}

export const DropdownContextProvider: React.FC<DropdownContextProviderProps> = ({
    children,
    isOpen,
    setIsOpen,
}) => {
    return (
        <DropdownContext.Provider
            value={{
                isOpen,
                setIsOpen,
            }}
        >
            {children}
        </DropdownContext.Provider>
    )
}

import React, { useMemo } from 'react'

import { TaskSource } from 'data/hooks/tasks/types'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

import { formatCreatedByLabel } from './sourceUtils'
import { useSourceLabel } from './useSourceLabel'

type TaskSourceDisplayProps = React.ComponentPropsWithoutRef<typeof Box> & {
    source: string
    source_type: TaskSource
    created_at: string
}

export const TaskSourceDisplay: React.FC<TaskSourceDisplayProps> = ({
    source,
    source_type,
    created_at,
    ...props
}) => {
    const sourceLabel = useSourceLabel(source, source_type)
    const createdByLabel = useMemo(
        () => formatCreatedByLabel(sourceLabel, created_at),
        [sourceLabel, created_at]
    )

    if (!sourceLabel) return null

    return (
        <Tooltip label={createdByLabel}>
            <Box fontSize="bodyS" color="textWeak" flex center {...props}>
                <Icon name="Inbox" size="s" mr="m" />
                from {sourceLabel}
            </Box>
        </Tooltip>
    )
}

import React from 'react'

import { Activity } from 'features/Activity/Activity'

import useHover from 'v2/ui/utils/useHover'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'

import { NotificationControls } from './NotificationControls'
import { RelatedNotificationData } from './types'

type Props = {
    notifications: NotificationDto[]
    targetActivityId: number
    relatedData: RelatedNotificationData

    markAsRead: (options: { notificationIds: number[]; isRead: boolean }) => void
    markAsArchived: (optionn: { notificationIds: number[]; isArchived: boolean }) => void
}
export function ReplyNotification({
    targetActivityId,
    notifications,
    relatedData,
    markAsRead,
    markAsArchived,
}: Props) {
    // const { user } = useAppUserContext()
    const [isHovered, hoverHandlers] = useHover()

    const parentActivity = relatedData.activities.find((a) => a.auto_id === targetActivityId)

    if (!parentActivity) return null

    // let titleContent: React.ReactNode = 'Some people commented on this post'

    const unreadNotifications = notifications.filter((n) => !n.is_read)
    const unreadActivitiyIds = unreadNotifications.map((n) => n.activity_id)
    // const activities = unreadNotifications
    //     .map((n) => relatedData.activities.find((a) => a.auto_id === n.activity_id))
    //     .filter(Boolean)

    // if (notifications.length === 1) {
    //     let verb = ''

    //     switch (notifications[0].type) {
    //         case NotificationType.Mention:
    //             verb = 'mentioned you in reply to this post'
    //             break
    //         case NotificationType.Reply:
    //             verb = 'replied to this post'
    //             break
    //     }
    //     titleContent = (
    //         <>
    //             <FeedUserDisplay userId={activities?.[0]?.created_by} mr={1} /> {verb}:
    //         </>
    //     )
    // } else {
    //     const users = Array.from(new Set(activities.map((a) => a.created_by)))

    //     if (users.length > 1) {
    //         titleContent = (
    //             <Box py={2} flex center>
    //                 <Icon name="comment" variant="regular" mr={2} />{' '}
    //                 {`${users.length} people commented on this post:`}
    //             </Box>
    //         )
    //     } else {
    //         titleContent = (
    //             <>
    //                 <FeedUserDisplay userId={users?.[0]} mr={1} /> commented on this post:
    //             </>
    //         )
    //     }
    // }
    const rootActivityIsUnread = !!notifications.find(
        (n) => !n.is_read && n.activity_id === targetActivityId
    )
    return (
        <Box flex column {...hoverHandlers}>
            {/* <Box flex center px={3} mb={2}>
                {titleContent}
                <Box grow />

            </Box> */}
            {parentActivity && (
                <Box
                    px="xs"
                    borderLeftWidth={1}
                    borderStyle="base"
                    borderRadius="l"
                    borderColor="border"
                >
                    <Activity
                        key={rootActivityIsUnread.toString()}
                        activity={parentActivity}
                        compact
                        startCollapsed={!rootActivityIsUnread}
                        controls={
                            <NotificationControls
                                showUnreadIndicator={rootActivityIsUnread}
                                notifications={notifications}
                                isHovered={isHovered}
                                markAsRead={markAsRead}
                                markAsArchived={markAsArchived}
                            />
                        }
                        unreadActivities={unreadActivitiyIds}
                        showActivityControls={false}
                        showLocationPreview={true}
                    />
                </Box>
            )}
            <Divider my="m" />
        </Box>
    )
}

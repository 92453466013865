import { useMemo } from 'react'

import { useListHeaderContext } from 'features/views/ListView/ListHeader/useListHeaderContext'

export function useQuickFiltersState() {
    const { availableInlineFilterFields, clearInlineFilters, inlineFilters } =
        useListHeaderContext()

    const isActive = inlineFilters.length > 0

    return useMemo(
        () => ({ fields: availableInlineFilterFields, clearFilters: clearInlineFilters, isActive }),
        [availableInlineFilterFields, clearInlineFilters, isActive]
    )
}

import { useObjects } from 'data/hooks/objects'
import { assertIsDefined } from 'data/utils/ts_utils'

/**
 * Checks if the object is the Stacker User object
 * @returns True if the object is the Stacker User object
 */
export const getIsStackerUserObject = (o?: ObjectDto): boolean => {
    return !!(o && o.system_object_type === 'users')
}

export function useStackerUsersObject(): ObjectDto {
    const { data: objects } = useObjects()
    const stackerUsersObject = objects?.find(getIsStackerUserObject)
    assertIsDefined(stackerUsersObject)
    return stackerUsersObject
}

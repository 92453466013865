// @ts-strict-ignore
import { getCurrentStack } from 'app/GlobalStaticState'

const getLastPreviewingRoleApiName = (): string | undefined => {
    const stack = getCurrentStack()

    if (!stack) return

    const id = sessionStorage.getItem(`${stack._sid}_previous_previewing_as_role`)
    if (id && id !== 'null') {
        return id
    }

    return
}

const getPreviewingRoleApiName = (): string | undefined => {
    const stack = getCurrentStack()

    if (!stack) return
    const id = localStorage.getItem(`${stack._sid}_previewing_as_role`)
    if (id && id !== 'null') {
        return id
    }

    return
}

const getLastPreviewingUser = (): UserDto | undefined => {
    const stack = getCurrentStack()

    if (!stack) return

    const value = sessionStorage.getItem(`${stack._sid}_previous_previewing_as_user`)
    if (value) return JSON.parse(value)

    return
}

const getPreviewingUser = (): UserDto | undefined => {
    const stack = getCurrentStack()

    if (!stack) return
    const value = localStorage.getItem(`${stack._sid}_previewing_as_user`)
    if (value) return JSON.parse(value)

    return
}

const setLastPreviewingUser = (user) => {
    const stack = getCurrentStack()

    if (!stack) return

    const value = user ? JSON.stringify(user) : ''
    sessionStorage.setItem(`${stack._sid}_previous_previewing_as_user`, value)
}

const setPreviewingUser = (user) => {
    const stack = getCurrentStack()

    if (!stack) return

    const value = user ? JSON.stringify(user) : ''

    localStorage.setItem(`${stack._sid}_previewing_as_user`, value)
}

const setLastPreviewingRoleApiName = (apiName) => {
    const stack = getCurrentStack()

    if (!stack) return
    sessionStorage.setItem(`${stack._sid}_previous_previewing_as_role`, apiName || '')
}

const setPreviewingRoleApiName = (apiName) => {
    const stack = getCurrentStack()

    if (!stack) return
    localStorage.setItem(`${stack._sid}_previewing_as_role`, apiName || '')
}

const isPreviewing = (): boolean => {
    return !!getPreviewingUser() || !!getPreviewingRoleApiName()
}

export const PreviewServiceStorage = {
    getLastPreviewingRoleApiName,
    getPreviewingRoleApiName,
    getLastPreviewingUser,
    getPreviewingUser,
    setLastPreviewingUser,
    setPreviewingUser,
    setLastPreviewingRoleApiName,
    setPreviewingRoleApiName,
    isPreviewing,
}

import React, { forwardRef } from 'react'

import * as Parts from 'ui/components/Textarea/Textarea.parts'

type TextareaToolbarInputRef = HTMLInputElement

type TextareaToolbarInputProps = React.ComponentPropsWithoutRef<typeof Parts.ToolbarInput> & {}

export const TextareaToolbarInput = forwardRef<TextareaToolbarInputRef, TextareaToolbarInputProps>(
    (props, ref) => {
        return <Parts.ToolbarInput ref={ref} {...props} />
    }
)

import React, { useContext } from 'react'

export type ActionMenuContextValue = {
    deleteRecord: (recordSid: string) => void
    createTaskForRecord: (recordSid: string) => void
}
export const ActionMenuContext = React.createContext<ActionMenuContextValue | null>(null)

export function useActionMenuContext(): ActionMenuContextValue {
    const context = useContext(ActionMenuContext)

    if (!context) {
        throw new Error('useActionMenuContext must be used within a ActionMenuContextProvider')
    }

    return context
}

import settings from 'app/settings'
import {
    getAbsoluteWorkspaceRootUrl,
    getPortalDomain,
    getRootPath,
    getUrl,
    getWorkspaceUrl,
    Urls,
} from 'app/UrlService'
import { NavTreeItem } from 'features/admin/settings/navigation/types'
import { extractPartsFromUserName } from 'features/views/attributes/utils'
import { colors, defaultColor } from 'features/workspace/forms/colorConstants'

import { ColorScheme } from 'ui/styling/baseVariables/colors/colorPalette'
import { determineColorScheme } from 'ui/styling/helpers/ThemeInitializer'

import {
    NavigationApp,
    NavigationAppIcon,
    NavigationAppMenuItem,
    NavigationAppMenuItemIcon,
    NavigationCurrentUser,
    NavigationPortal,
    NavigationSpace,
} from './types'

const faIconRegexp = /^fa/

type MapGroupsToNavigationSpacesProps = {
    groups: { name: string; stacks: StackDto[] }[]
    account?: Account
    zone?: AccountZone
    isOnPortalDomain?: boolean
}

export function mapGroupsToNavigationSpaces({
    groups,
    account,
    zone,
    isOnPortalDomain,
}: MapGroupsToNavigationSpacesProps): NavigationSpace[] {
    const spaces = groups.reduce((acc, group) => {
        const unarchivedApps = group.stacks.reduce((acc, stack) => {
            if (!stack.options.is_archived) {
                acc.push(mapStackToNavigationApp({ stack, account, zone, isOnPortalDomain }))
            }

            return acc
        }, [] as NavigationApp[])

        if (unarchivedApps.length) {
            acc.push({
                id: group.name,
                name: group.name || 'Unassigned',
                apps: unarchivedApps,
            })
        }

        return acc
    }, [] as NavigationSpace[])

    return spaces.sort((a, b) => {
        // Move ungrouped apps to the end.
        if (!a.id) return 1
        if (!b.id) return -1

        return 0
    })
}

type MapStackToNavigationAppProps = {
    stack: StackDto
    account?: Account
    zone?: AccountZone
    isOnPortalDomain?: boolean
}

export function mapStackToNavigationApp({
    stack,
    account,
    zone,
    isOnPortalDomain,
}: MapStackToNavigationAppProps): NavigationApp {
    const stackIcon = stack.options.theme?.icon

    let appIcon: NavigationAppIcon | undefined
    if (stackIcon?.match(faIconRegexp)) {
        appIcon = {
            family: 'fa',
            name: stackIcon,
        }
    } else if (stackIcon) {
        appIcon = {
            family: 'legacy',
            name: stackIcon,
        }
    }

    const brandColor = stack.options.theme?.brandColor ?? defaultColor
    const colorScheme =
        determineColorScheme(brandColor) ??
        (colors[defaultColor as keyof typeof colors] as ColorScheme)

    const spaceId = stack.options.group

    const isExternalUrl = !!isOnPortalDomain
    const stackRootPath = `${getRootPath(stack, account, zone)}/`
    const workspaceDomain = account ? getAbsoluteWorkspaceRootUrl(account) : ''

    const url = isExternalUrl ? `${workspaceDomain}${stack.url_slug}/` : stackRootPath

    return {
        id: stack._sid,
        name: stack.name,
        icon: appIcon,
        url,
        colorScheme,
        stack,
        spaceId,
        isExternalUrl,
    }
}

export function getNavigationCurrentUserFromUser(user?: AuthedUserDto): NavigationCurrentUser {
    const fullName = user?.name ?? 'Unknown User'
    const { firstName, lastName } = extractPartsFromUserName(fullName)
    const avatarUrl = user?.avatar
    const email = user?.email ?? ''

    return {
        fullName,
        firstName,
        lastName,
        avatarUrl,
        email,
    }
}

export function mapNavTreeToAppNavigationMenuItems(
    navTree: NavTreeItem[],
    parent?: NavigationAppMenuItem,
    stack?: StackDto
): NavigationAppMenuItem[] {
    return navTree.reduce((acc, item) => {
        if (item.hidden) return acc

        // If we only have one child, we can just return that as a top level item.
        const allChildren = item.children ?? []
        const children = allChildren.filter((child) => !child.hidden)
        // If all children are hidden, we don't need to include this item.
        if (allChildren.length !== children.length && !children.length) {
            return acc
        }

        if (children.length === 1) {
            const newItem = mapNavTreeToAppNavigationMenuItems(children, undefined, stack)[0]
            if (!newItem) return acc

            if (!newItem.url) {
                newItem.url = item.url
            }

            return [...acc, newItem]
        }

        const path = item.url
        const url = getUrl(path, stack)

        const icon: NavigationAppMenuItemIcon | undefined = !!item.options?.icon
            ? {
                  family: 'legacy',
                  name: item.options.icon,
              }
            : undefined

        const appMenuItem: NavigationAppMenuItem = {
            id: item._sid,
            name: item.label,
            url,
            path,
            parent,
            children: [],
            objectSid: item.object_id ?? undefined,
            icon,
            stackSid: item.stack_id,
        }
        appMenuItem.children = mapNavTreeToAppNavigationMenuItems(children, appMenuItem, stack)

        return [...acc, appMenuItem]
    }, [] as NavigationAppMenuItem[])
}

type MapPortalsToNavigationPortalsProps = {
    zones: AccountZone[]
    account?: Account
    isOnPortalDomain?: boolean
}

export function mapPortalsToNavigationPortals({
    zones,
    account,
    isOnPortalDomain,
}: MapPortalsToNavigationPortalsProps): NavigationPortal[] {
    return zones.reduce((acc, zone) => {
        if (zone.type !== 'Portal') return acc
        if (!!account && zone.account_sid !== account?._sid) return acc

        let url: string
        if (isOnPortalDomain) {
            url = account
                ? getPortalDomain(zone, account, Urls.Root)
                : `https://${settings.STUDIO_DOMAIN}`
        } else {
            url = getWorkspaceUrl(Urls.Root, account, zone)
        }

        const isExternalUrl = !!isOnPortalDomain

        let domain = account ? getPortalDomain(zone, account, Urls.Root) : settings.STUDIO_DOMAIN
        domain = domain.replace('https://', '').replace('http://', '').replace('/', '')

        acc.push({
            id: zone._sid,
            name: zone.display_name,
            logoIconUrl: zone.logo_icon_url,
            url,
            isExternalUrl,
            zone,
            domain,
        })

        return acc
    }, [] as NavigationPortal[])
}

export function getActiveMenuItem(menuItems: NavigationAppMenuItem[], currentPath?: string) {
    if (!currentPath) return undefined

    let queue = [...menuItems]
    let i = 0
    while (i < queue.length) {
        const item = queue[i++]

        if (currentPath === item.path) {
            return item
        }

        if (item.children.length) {
            queue.push(...item.children)
        }
    }

    return undefined
}

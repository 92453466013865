import React, { FC } from 'react'

import { useRecordFilters } from 'features/records/components/RecordFilters/provider/useRecordFilters'

type Props = {
    message: string | undefined
    shouldShowHeader: boolean | null
}

export const SecurityInfoMessage: FC<Props> = ({ message, shouldShowHeader }) => {
    const {
        state: { filters },
    } = useRecordFilters()

    if (!message) {
        return null
    }

    return (
        <div
            style={{
                color: '#454e55', // could not find this color in V4DesignSystem
                fontSize: 13,
                marginBottom: filters.length > 0 ? 24 : 12, // bit more margin before showing the filters if there are any
                marginTop: shouldShowHeader ? 6 : 0, // some margin above if we are showing the advanced menu toggle above the message
            }}
        >
            <div>
                <span>{message}</span>
                <span>{' This only changes the appearance of the app, and '}</span>
                <span style={{ fontWeight: 600 }}>{'is not a security feature. '}</span>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                    target="_blank"
                    href="https://support.stackerhq.com/hc/en-us/articles/4416415857171-List-Filter"
                    style={{ textDecoration: 'underline' }}
                >
                    Learn more.
                </a>
            </div>
        </div>
    )
}

import { InputRuleMatch } from '@tiptap/react'

import { getAbsoluteWorkspaceRootUrl } from 'app/UrlService'

export const whitespaceRegexp = /\s/

export function parseDetailViewInputURL(
    content: string,
    fetchWorkspaceAccountFn?: () => Account | null,
    fetchStacksFn?: () => StackDto[],
    includeTrailingSpace = true
): InputRuleMatch | null {
    const workspaceAccount = fetchWorkspaceAccountFn?.()
    const stacks = fetchStacksFn?.()

    if (!workspaceAccount || !stacks) return null

    const regexpPatternParts: string[] = [
        // Optional space at the beginning.
        '(?:^|\\s)(',
        // https://www.
        '(https?:\\/\\/)(?:www\\.)?',
    ]

    // <workspace-domain + <slug> (if not custom domain).
    const workspaceDomain = getAbsoluteWorkspaceRootUrl(workspaceAccount)
    const encodedWorkspaceDomain = encodeUrl(workspaceDomain)
    regexpPatternParts.push(encodedWorkspaceDomain)

    // /<stack-name>/<feature-url>/view/<record-id>
    regexpPatternParts.push(
        '\\/([^\\/\\s]+)\\/([^\\/\\s]+)(?:\\/view\\/)([^\\s\\/#?]+)([#?&\\/][^\\s]+)?'
    )
    if (includeTrailingSpace) {
        // Optional space at the end.
        regexpPatternParts.push('(?:\\s)')
    }
    regexpPatternParts.push(')$')

    const regexp = new RegExp(regexpPatternParts.join(''))
    const detailViewUrlMatch = regexp.exec(content)
    if (!detailViewUrlMatch) return null

    const [text, , , stackSlug, , recordId] = detailViewUrlMatch

    const stack = stacks.find((stack) => stack.url_slug === stackSlug)
    if (!stack) return null

    let relativeUrl = text.trim().split(workspaceDomain)[1]
    // Include the workspace slug in the URL if we're on the studio domain.
    if (!workspaceAccount.sso_required) {
        relativeUrl = `/${workspaceAccount.slug}${relativeUrl}`
    }

    return {
        index: detailViewUrlMatch.index + 1,
        text: text,
        data: {
            id: recordId,
            url: relativeUrl,
            stack_id: stack._sid,
        },
    }
}

function encodeUrl(url: string) {
    return url.replace(/(https?:\/\/)(?:www\.)?/, '').replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'

import {
    CONDITION_GROUP_OPERATORS,
    ConditionGroupOperator,
    getLabelForGroupOperator,
} from './types'

type ConditionGroupOperatorInputProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value?: ConditionGroupOperator
    onChange: (value: ConditionGroupOperator) => void
    readOnly?: boolean
}

export const ConditionGroupOperatorInput: React.FC<ConditionGroupOperatorInputProps> = ({
    value,
    onChange,
    readOnly,
    disabled,
    ...props
}) => {
    const currentLabel = getLabelForGroupOperator(value) ?? 'Pick an operator'

    return (
        <Box flex center {...props}>
            {readOnly ? (
                <Box color="textWeak" fontSize="bodyS">
                    {currentLabel}
                </Box>
            ) : (
                <Dropdown>
                    <DropdownButton
                        variant="secondary"
                        trim
                        size="xs"
                        style={{
                            justifyContent: 'space-between',
                        }}
                        disabled={disabled}
                        endIcon={{
                            name: 'ChevronDown',
                        }}
                    >
                        {currentLabel}
                    </DropdownButton>
                    <DropdownContent>
                        {CONDITION_GROUP_OPERATORS.map(({ label, value: conditionValue }) => (
                            <DropdownItem
                                key={conditionValue}
                                multiSelect
                                checked={value === conditionValue}
                                onCheckedChange={() => onChange(conditionValue)}
                                closeOnSelect
                                label={label}
                            />
                        ))}
                    </DropdownContent>
                </Dropdown>
            )}
        </Box>
    )
}

import { useMemo } from 'react'

import { AccessibleView, useAccessibleViews } from 'v2/views/utils/useAccessibleViews'

import { useObjects } from 'data/hooks/objects'
import { NavTreeItem } from 'features/admin/settings/navigation/types'
import { isBlankPageObject } from 'features/blank-pages/utils'
import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'

export const useInternalLinks = () => {
    const { list, blankpage, create } = useAccessibleViews()
    const { data: objects = [] } = useObjects()

    return useMemo(() => {
        const views = [...list, ...blankpage, ...create]

        const objectsById = objects.reduce(
            (agg, curr) => agg.set(curr._sid, curr),
            new Map<string, ObjectDto>()
        )
        const viewsByObjectId = getViewsByObjectId(views)

        const links = views.reduce<QuickLinksBlockItemInternalLink[]>((agg, curr) => {
            const { navItem, view } = curr
            const { type } = view

            if (type === 'blankpage') {
                agg.push({
                    type: 'internal',
                    label: navItem?.label ?? view.name!,
                    objectId: view.object_id ?? '',
                    viewId: view._sid,
                    viewType: view.type,
                })
            }

            if (type === 'list' || type === 'create') {
                const isNavItemValid = validateNavItem(navItem)
                if (!isNavItemValid) return agg

                const object = view.object_id ? objectsById.get(view.object_id) : undefined
                const viewsForObject = viewsByObjectId.get(view.object_id ?? null)
                const isObjectValid = validateObject(object, viewsForObject)
                if (!isObjectValid) return agg

                const parentName = object?.name ?? navItem?.label

                let label = ''
                if (type === 'list') {
                    label = getLabelWithParent(view.name ?? '', parentName)
                } else {
                    label = getLabelWithParent('Create new', parentName)
                }

                agg.push({
                    type: 'internal',
                    label,
                    viewId: view._sid,
                    objectId: view.object_id ?? '',
                    viewType: view.type,
                })
            }

            return agg
        }, [])

        sortLinks(links)

        return links
    }, [blankpage, create, list, objects])
}

const viewOrder: Record<ViewDto['type'], number> = {
    blankpage: 0,
    list: 1,
    create: 2,
    detail: 3,
    document: 4,
}

function sortLinks(links: QuickLinksBlockItemInternalLink[]) {
    // Sort views by object id and by view type.
    links.sort((a, b) => {
        const viewTypeA = a.viewType
        const viewTypeB = b.viewType

        const objIdA = a.objectId
        const objIdB = b.objectId

        if (objIdA === objIdB && Boolean(objIdA)) {
            const viewOrderA = viewOrder[viewTypeA]
            const viewOrderB = viewOrder[viewTypeB]

            if (viewOrderA !== viewOrderB) {
                return viewOrderA - viewOrderB
            }
        }

        return a.label.localeCompare(b.label)
    })
}

function validateObject(object?: ObjectDto, viewsForObject: ViewDto[] = []) {
    if (!object) return true

    // don't include the nav item for any objects which have been hidden
    // and don't include the stacker_users object, which should be hidden
    // but due to some bugs, may not be in some cases.
    if (object.connection_options?.is_hidden || getIsStackerUserObject(object)) {
        return false
    }

    if (isBlankPageObject(object, viewsForObject)) {
        return true
    }

    return true
}

function validateNavItem(navItem?: NavTreeItem): boolean {
    if (!navItem) return true

    return navItem.enabled !== false && !navItem.hidden
}

function getViewsByObjectId(views: AccessibleView[]): Map<string | null, ViewDto[]> {
    return views.reduce((agg, { view }) => {
        const objectId = view.object_id ?? null

        const viewsForObject = agg.get(objectId) ?? []
        viewsForObject.push(view)

        agg.set(objectId, viewsForObject)

        return agg
    }, new Map<string | null, ViewDto[]>())
}

function getLabelWithParent(label: string, parent?: string): string {
    if (parent) {
        if (label) return `${parent} > ${label}`

        return parent
    }

    return label
}

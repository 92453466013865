// @ts-strict-ignore
import * as React from 'react'

import styled from '@emotion/styled'
import classNames from 'classnames'

import stackerTheme from 'v2/ui/theme/styles/default'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import Flex from './Flex'
import Icon from './Icon'

const colors = stackerTheme().colors

const StyledSidebarItem = styled(Flex)`
    font-size: ${() => V4DesignSystem.h3.fontSize}px;
    font-weight: ${() => V4DesignSystem.h3.fontWeight};
    background: ${(p) => (p.active ? V4DesignSystem.colors.highlight : 'transparent')};
    color: ${(p) => (p.active ? colors.white : V4DesignSystem.colors.text)};

    &:hover {
        ${(p) => !p.active && 'background: #eff1f2;'};
    }
`

type SidebarItemProps = {
    onChange: () => void
    active?: boolean
    icon?: string
    style?: any
    classes?: any
}

const SidebarItem: React.FC<SidebarItemProps> = ({
    onChange,
    active,
    icon,
    children,
    style = {},
    classes = {},
}) => {
    return (
        <StyledSidebarItem
            onClick={onChange}
            active={active}
            style={{
                padding: '5px 10px',
                minHeight: 26,
                borderRadius: 5,
                cursor: active ? 'default' : 'pointer',
                alignItems: 'center',
                ...V4DesignSystem.tabItem,
                ...style,
            }}
            className={classNames(classes, { isActive: active })}
        >
            {icon && (
                <div style={{ width: 22, flexShrink: 0 }}>
                    <Icon
                        className="icon"
                        icon={icon}
                        color={active ? 'white' : V4DesignSystem.colors.gray[400]}
                    />
                </div>
            )}

            <div
                style={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {children}
            </div>
        </StyledSidebarItem>
    )
}

export default SidebarItem

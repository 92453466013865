import React from 'react'

import { Box } from 'ui/components/Box'

export function TableNameDisplay({ children }: { children: React.ReactNode }) {
    return (
        <Box rounded="s" style={{ background: '#FB6513' }} display="inline" px="s" py="xs">
            {children}
        </Box>
    )
}

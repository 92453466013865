import React from 'react'

import styled from '@emotion/styled'

import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { ObjectOptions } from './ObjectOptions'

const ObjectRowContainer = styled.div`
    padding-top: ${theme.space.m};
    padding-bottom: ${theme.space.m};
    padding-left: ${theme.space.s};
    display: flex;
    justify-content: space-between;
    border-radius: ${theme.radius.s};

    &:hover {
        background-color: ${theme.color.surfaceStrong};
    }
`

export type ObjectRowProps = {
    object: ObjectDto
}
export const ObjectRow: React.FC<ObjectRowProps> = ({ object }) => {
    return (
        <ObjectRowContainer>
            <Body weight="medium">{object.name}</Body>
            <ObjectOptions object={object} />
        </ObjectRowContainer>
    )
}

import { getUrl } from 'app/UrlService'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'

import { SearchAuxiliaryData, SearchResult } from './types'

export function globalSearch(query: string, stacks: StackDto[], allowedHitTypes?: string[]) {
    return fetchWithAuth(`search/?q=${query}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    })
        .then((res) => res.json())
        .then((res) => {
            let { hits, ...data } = res

            if (allowedHitTypes) {
                const allowedHitTypesSet = new Set(allowedHitTypes)
                hits = hits.filter((hit: SearchResult) => allowedHitTypesSet.has(hit._type))
            }

            const items = hits
                .map((hit: SearchResult) => {
                    let url: string | undefined

                    if (hit._type === 'record' && hit._stack_id && hit._object_id) {
                        const stack = stacks?.find((s) => s._sid === hit._stack_id)
                        if (!stack) return undefined

                        const object = data.objects[hit._stack_id]?.[hit._object_id]
                        if (!object) return undefined

                        url = getUrl(`${object.url}/view/${hit._record_id}`, stack)
                    } else if (hit._type === 'document' && hit._stack_id && hit._document_id) {
                        const stack = stacks?.find((s) => s._sid === hit._stack_id)
                        if (!stack) return undefined

                        url = getUrl(`/docs/${hit._document_id}`, stack)
                    }

                    return {
                        ...hit,
                        url,
                        _sid: hit.objectID,
                    }
                })
                .filter(Boolean)
            return { items, data: data as SearchAuxiliaryData }
        })
}

import { useMemo } from 'react'

import { useListHeaderContext } from 'features/views/ListView/ListHeader/useListHeaderContext'

export function useListHeaderState() {
    const { value, isEmbedded } = useListHeaderContext()

    const headerType = isEmbedded ? 'default' : value.type

    return useMemo(() => ({ headerType }), [headerType])
}

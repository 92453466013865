import React from 'react'

import { Box } from 'ui/components/Box'

import { ExecutionHistoryJSONPreview } from './ExecutionHistoryJSONPreview'

type ExecutionHistoryStateProps = React.ComponentPropsWithoutRef<typeof Box> & {
    execution: WorkflowExecutionDto
}

export const ExecutionHistoryState: React.FC<ExecutionHistoryStateProps> = ({
    execution,
    ...props
}) => {
    return (
        <Box {...props}>
            {execution.execution_data && (
                <ExecutionHistoryJSONPreview value={execution.execution_data.state_data} />
            )}
        </Box>
    )
}

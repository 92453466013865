import React from 'react'

import { Box } from 'ui/components/Box'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownSeparator,
} from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import { useAppNavigationTreeAddNewDropdownState } from './hooks/useAppNavigationTreeAddNewDropdownState'
import { useAppNavigationTreeItemState } from './hooks/useAppNavigationTreeItemState'
import { useAppNavigationTreeMenuItemDropdownState } from './hooks/useAppNavigationTreeMenuItemDropdownState'
import { useAppNavigationTreeState } from './hooks/useAppNavigationTreeState'
import { NavigationItem } from './NavigationItem'
import { NavigationMenuItemIcon } from './NavigationMenuItemIcon'
import { NavigationAppMenuItem } from './types'

import {
    AppNavigationChildrenContainerStyle,
    AppNavigationChildrenStyle,
    AppNavigationItemIconWrapperStyle,
    AppNavigationItemLabelStyles,
    AppNavigationItemStyles,
    NavigationItemHideOnHoverStyle,
    NavigationItemHoverStyle,
} from './Navigation.css'

type AppNavigationTreeProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const AppNavigationTree: React.FC<AppNavigationTreeProps> = React.memo(
    function AppNavigationTree(props) {
        const { menuItems } = useAppNavigationTreeState()

        if (menuItems.length < 1) return null

        return (
            <Box flex column gap="2xs" {...props}>
                {menuItems.map((item, index) => (
                    <AppNavigationTreeItem
                        key={item.id}
                        item={item}
                        isTopLevel
                        isFirstItem={index === 0}
                    />
                ))}
            </Box>
        )
    }
)

type AppNavigationTreeItemProps = {
    item: NavigationAppMenuItem
    isTopLevel?: boolean
    isFirstItem?: boolean
}

const AppNavigationTreeItem: React.FC<AppNavigationTreeItemProps> = React.memo(
    function AppNavigationTreeItem({ item, isTopLevel, isFirstItem }) {
        const { isActive, hasChildren, hasIcon, isExpanded, setIsExpanded } =
            useAppNavigationTreeItemState({
                item,
                isTopLevel,
                isFirstItem,
            })

        const content = (
            <NavigationItem
                as={hasChildren ? 'div' : undefined}
                role={hasChildren ? 'button' : undefined}
                to={item.url}
                className={AppNavigationItemStyles.styleFunction({
                    isActive,
                    hasChildren,
                    hasIcon,
                })}
                trim
                label={
                    <Box flex center gap="s" trim>
                        {hasIcon && (
                            <Box position="relative" className={AppNavigationItemIconWrapperStyle}>
                                {hasChildren && (
                                    <Box
                                        width="full"
                                        height="full"
                                        top={0}
                                        left={0}
                                        position="absolute"
                                        flex
                                        center
                                        justifyContent="center"
                                        className={NavigationItemHoverStyle}
                                        overflow="hidden"
                                    >
                                        <Icon
                                            family="hugeicons"
                                            name={isExpanded ? 'arrow-down-01' : 'arrow-right-01'}
                                            size="s"
                                            display="block"
                                            color="gray900"
                                        />
                                    </Box>
                                )}
                                <NavigationMenuItemIcon
                                    menuItem={item}
                                    isActive={isActive}
                                    className={
                                        hasChildren ? NavigationItemHideOnHoverStyle : undefined
                                    }
                                />
                            </Box>
                        )}
                        <Body
                            size="m"
                            weight={isActive ? 'bold' : 'medium'}
                            pl={hasIcon ? 0 : 'xs'}
                            trim
                            className={AppNavigationItemLabelStyles.styleFunction({
                                isActive,
                            })}
                        >
                            {item.name}
                        </Body>
                    </Box>
                }
                rightSlotContent={
                    <Box
                        className={NavigationItemHoverStyle}
                        onClick={stopPropagation}
                        flex
                        center
                        gap="2xs"
                        noShrink
                    >
                        {!hasChildren && <AppNavigationTreeMenuItemDropdown item={item} />}
                        {isTopLevel && <AppNavigationTreeAddNewDropdown item={item} />}
                    </Box>
                }
            />
        )

        return (
            <Box
                as={hasChildren ? Collapsible : 'div'}
                flex
                column
                width="full"
                // @ts-expect-error: Box is not inheriting the open prop.
                open={isExpanded}
                onOpenChange={setIsExpanded}
            >
                {hasChildren ? (
                    <>
                        <CollapsibleTrigger asChild>{content}</CollapsibleTrigger>
                        <Box
                            as={CollapsibleContent}
                            className={AppNavigationChildrenContainerStyle}
                        >
                            <Box flex column gap="2xs" className={AppNavigationChildrenStyle}>
                                {item.children.map((child) => (
                                    <AppNavigationTreeItem key={child.id} item={child} />
                                ))}
                            </Box>
                        </Box>
                    </>
                ) : (
                    content
                )}
            </Box>
        )
    }
)

type AppNavigationTreeAddNewDropdownProps = {
    item: NavigationAppMenuItem
}

const AppNavigationTreeAddNewDropdown: React.FC<AppNavigationTreeAddNewDropdownProps> = ({
    item,
}) => {
    const { canCreateView, createView, showButton, canCreateRecord, createRecord, isLoading } =
        useAppNavigationTreeAddNewDropdownState({
            item,
        })

    if (!showButton) return null

    return (
        <Dropdown modal={false}>
            <DropdownButton
                size="2xs"
                variant="ghost"
                startIcon={{
                    name: 'Plus',
                }}
                noShrink
                isLoading={isLoading}
            />
            <DropdownContent style={{ width: '230px' }} onClick={stopPropagation}>
                {canCreateRecord && (
                    <DropdownItem
                        startIcon={{ name: 'Pencil' }}
                        label="Record"
                        onClick={createRecord}
                    />
                )}
                {canCreateView && (
                    <>
                        {canCreateRecord && <DropdownSeparator />}
                        <DropdownItem
                            startIcon={{ name: 'TableProperties' }}
                            label="Table view"
                            onClick={() => createView('tableV2')}
                        />
                        <DropdownItem
                            startIcon={{ name: 'KanbanSquare' }}
                            label="Board view"
                            onClick={() => createView('boardV2')}
                        />
                        <DropdownItem
                            startIcon={{ name: 'LayoutGrid' }}
                            label="Card view"
                            onClick={() => createView('cardV2')}
                        />
                    </>
                )}
            </DropdownContent>
        </Dropdown>
    )
}

type AppNavigationTreeMenuItemDropdownProps = React.ComponentPropsWithoutRef<
    typeof DropdownButton
> & {
    item: NavigationAppMenuItem
}

export const AppNavigationTreeMenuItemDropdown: React.FC<
    AppNavigationTreeMenuItemDropdownProps
> = ({ item, ...props }) => {
    const {
        canFavorite,
        toggleFavorite,
        isFavorite,
        copyLink,
        canEditLayout,
        editLayout,
        canDuplicateLayout,
        duplicateLayout,
        canDeleteLayout,
        deleteLayout,
        deleteLabel,
    } = useAppNavigationTreeMenuItemDropdownState({
        item,
    })

    return (
        <Dropdown modal={false}>
            <DropdownButton
                size="2xs"
                variant="ghost"
                startIcon={{
                    name: 'MoreHorizontal',
                }}
                noShrink
                {...props}
            />
            <DropdownContent style={{ width: '230px' }} onClick={stopPropagation}>
                <DropdownItem startIcon={{ name: 'Link' }} label="Copy link" onClick={copyLink} />
                {canFavorite && (
                    <DropdownItem
                        startIcon={{
                            name: isFavorite ? 'StarOff' : 'Star',
                        }}
                        label={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                        onClick={toggleFavorite}
                    />
                )}
                {canEditLayout && (
                    <>
                        <DropdownSeparator />
                        <DropdownItem
                            startIcon={{ name: 'PencilLine' }}
                            label="Edit layout"
                            onClick={editLayout}
                        />
                    </>
                )}
                {(canDuplicateLayout || canDeleteLayout) && <DropdownSeparator />}
                {canDuplicateLayout && (
                    <DropdownItem
                        startIcon={{ name: 'Copy' }}
                        label="Duplicate"
                        onClick={duplicateLayout}
                    />
                )}
                {canDeleteLayout && (
                    <DropdownItem
                        variant="destructive"
                        startIcon={{ name: 'Trash2' }}
                        label={deleteLabel}
                        onClick={deleteLayout}
                    />
                )}
            </DropdownContent>
        </Dropdown>
    )
}

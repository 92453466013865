import orderBy from 'lodash/orderBy'

export function getUserDisplayName(user: UserDto, currentUserId?: string): string {
    if (currentUserId && user._sid === currentUserId) return 'me'

    return user.name || user.email
}

export function orderUsers(users: UserDto[] = []): UserDto[] {
    return orderBy(users, (user) => getUserDisplayName(user), ['asc'])
}

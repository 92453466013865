import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { ExpressionInput } from 'features/expressions/ExpressionInput'
import { ContextGroup, ContextSchema, ExpressionResult } from 'features/expressions/types'
import {
    buildContextSchemaWithPaths,
    determineExpressionType,
} from 'features/expressions/utilities'
import { labelMap } from 'features/records/components/RecordFilters/constants'
import { getFilterOptionsForType } from 'features/records/components/RecordFilters/utils/utils'

import usePrevious from 'v2/ui/hooks/usePrevious'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { ConditionComparisonOpInput } from './ConditionComparisonOpInput'
import { ConditionClause } from './types'
import { filterSupportedContextItems, getConditionSupportedRHSType } from './utils'

type ConditionGroupItemInputProps = React.ComponentPropsWithoutRef<typeof Box> & {
    contextSchema: ContextSchema
    value: ConditionClause
    onChange: (value: ConditionClause) => void
    onDelete: () => void
    idx: number
    renderGroupTitle: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
}

export const ConditionGroupItemInput: React.FC<ConditionGroupItemInputProps> = ({
    contextSchema,
    value,
    onChange,
    onDelete,
    idx,
    renderGroupTitle,
    disabled,
    ...props
}) => {
    const valueRef = useRef(value)
    valueRef.current = value

    const onChangeRef = useRef(onChange)
    onChangeRef.current = onChange

    const patchClause = useCallback((patch: Partial<ConditionClause>) => {
        onChangeRef.current({ ...valueRef.current, ...patch })
    }, [])

    const leftValue = value.left
    const rightValue = value.right

    const { expressionType, expressionExtraOptions } = useMemo(() => {
        const supportedSchemaItems = filterSupportedContextItems(contextSchema)
        const contextWithPaths = buildContextSchemaWithPaths({
            contextSchema: supportedSchemaItems,
        })
        const { type, extraOptions } = determineExpressionType(leftValue?.value, contextWithPaths)

        return {
            expressionType: type,
            expressionExtraOptions: extraOptions,
        }
    }, [contextSchema, leftValue])

    const supportedOperations = useMemo(() => {
        if (!expressionType) return []

        const fakeField: Partial<FieldDto> = {
            type: expressionType,
            ...expressionExtraOptions,
        }

        const options = getFilterOptionsForType(fakeField, true, {} as ObjectDto, [], true)

        return options.map((option) => ({
            label: labelMap[option] ?? option,
            value: option,
        }))
    }, [expressionExtraOptions, expressionType])

    const { type: rhsType, typeOptions: rhsTypeOptions } = useMemo(
        () =>
            getConditionSupportedRHSType(
                expressionType,
                expressionExtraOptions?.options,
                value.comparison
            ),
        [expressionType, expressionExtraOptions, value.comparison]
    )

    // If the selected operation is not supported for the current type, reset it to the first supported operation.
    useEffect(() => {
        const isCurrentOpSupported = supportedOperations.some((op) => op.value === value.comparison)
        if (!isCurrentOpSupported) {
            queueMicrotask(() => {
                patchClause({
                    comparison: supportedOperations[0]?.value,
                })
            })
        }
    }, [patchClause, supportedOperations, value.comparison])

    const prevRhsType = usePrevious(rhsType)

    // Reset the right value if the right type changes.
    useEffect(() => {
        if (!prevRhsType || rhsType === prevRhsType) return

        patchClause({
            right: null,
        })
    }, [patchClause, rhsType, prevRhsType])

    return (
        <Box role="group" flex center gap="xs" {...props}>
            <Box
                grow
                borderWidth={1}
                borderColor="border"
                background="surfaceStrong"
                borderRadius="xs"
                p="xs"
                gap="xs"
                flex
                column
                width="full"
            >
                <Box flex center gap="xs" justifyContent="space-between">
                    <Box fontSize="bodyS" color="textWeakest">
                        #{idx + 1}
                    </Box>
                    <Box flex center>
                        <Button
                            onClick={onDelete}
                            startIcon={{ name: 'X' }}
                            variant="ghost"
                            size="xs"
                            justifyContent="center"
                        />
                    </Box>
                </Box>
                <ExpressionInput
                    contextSchema={contextSchema}
                    value={leftValue as ExpressionResult}
                    onChange={(value) => {
                        patchClause({
                            left: value as WorkflowExpression,
                        })
                    }}
                    renderGroupTitle={renderGroupTitle}
                    placeholder="select a value"
                    disabled={disabled}
                    // the left hand side can only be a single value. Makes type detection much easier
                    // and I can't think of a usecase where you need to combine multiple values on the left.
                    singleValue
                    allowFormulaFunctions={false}
                />
                <Box flex gap="xs" maxWidth="full" alignItems="stretch">
                    <ConditionComparisonOpInput
                        value={value.comparison}
                        onChange={(op) => patchClause({ comparison: op })}
                        operations={supportedOperations}
                        noShrink
                        grow={rhsType ? 0 : 1}
                        disabled={disabled || !expressionType}
                    />
                    {rhsType && (
                        <ExpressionInput
                            contextSchema={contextSchema}
                            returnType={rhsType}
                            returnTypeOptions={rhsTypeOptions}
                            value={rightValue as ExpressionResult}
                            onChange={(value) =>
                                patchClause({
                                    right: value as WorkflowExpression,
                                })
                            }
                            renderGroupTitle={renderGroupTitle}
                            minWidth={0}
                            placeholder="select a value"
                            disabled={disabled || !value.comparison || !expressionType}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

import { useEffect, useState } from 'react'

import { Column } from 'ag-grid-enterprise'

export function useColumnWidth(column: Column): number {
    const [width, setWidth] = useState<number>(column.getActualWidth())

    useEffect(() => {
        const onWidthChanged = () => setWidth(column.getActualWidth())
        column.addEventListener('widthChanged', onWidthChanged)
        return () => column.removeEventListener('widthChanged', onWidthChanged)
    }, [column])

    return width
}

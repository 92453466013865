// @ts-strict-ignore
import React, { useEffect, useRef, useState, VFC } from 'react'

import { isEqual } from 'lodash'
import { StringDiff } from 'slate-react/dist/utils/diff-text'

import {
    filtersToValue,
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'

import { Checkbox, Collapse, Text } from 'v2/ui'

type Props = {
    object: ObjectDto | undefined
    value: any
    onChange: (filters: any) => void
    contextRecordObject: ObjectDto
    checkboxLabel: StringDiff
}

const FiltersConfiguration: VFC<Props> = ({
    object,
    value,
    onChange,
    contextRecordObject,
    checkboxLabel = 'Filters',
}) => {
    const lastUpdatedValue = useRef<ReturnType<typeof formatFilters>>()

    const [showFilters, setShowFilters] = useState(!!value && value.length > 0)

    const [filters, setFilters] = useState(!!object && value ? filtersToValue(value, object) : [])

    useEffect(() => {
        if (isEqual(!lastUpdatedValue.current, value)) {
            setFilters(object ? filtersToValue(value, object) : [])
        }
    }, [object, value])

    const updateFilters = (rawFilters) => {
        const formattedFilters = formatFilters(rawFilters)
        lastUpdatedValue.current = formattedFilters
        onChange(formattedFilters)
    }

    const handleToggleShowFilters = () => {
        if (showFilters) {
            onChange([])
        } else {
            updateFilters(filters)
        }
        setShowFilters((prev) => !prev)
    }

    const fields = object?.fields ?? []

    return (
        <>
            <Checkbox
                style={{ marginTop: '8px' }}
                isDisabled={!object}
                isChecked={showFilters}
                onChange={handleToggleShowFilters}
            >
                <Text fontSize="13px">{checkboxLabel}</Text>
            </Checkbox>
            <Collapse isOpen={showFilters} marginTop={2}>
                <Filters
                    object={object}
                    fields={fields}
                    value={filters}
                    onChange={updateFilters}
                    addButtonVariant="Secondary"
                    contextRecordObject={contextRecordObject}
                />
            </Collapse>
        </>
    )
}

export default FiltersConfiguration

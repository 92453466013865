import React from 'react'

import { Thumbnail } from 'features/views/attributes/Thumbnail'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'

import { CardCoverImageStyle } from './BoardView.css'

type BoardViewCoverImageProps = React.ComponentPropsWithoutRef<typeof Box> & {
    src: string
    isLoading?: boolean
}

export const BoardViewCoverImage: React.FC<BoardViewCoverImageProps> = ({
    src,
    isLoading,
    ...props
}) => {
    return (
        <Box pb="s" {...props}>
            <Skeleton isLoading={isLoading} display="block">
                <Thumbnail
                    src={src}
                    size="manual"
                    className={CardCoverImageStyle}
                    alt="Record cover image"
                />
            </Skeleton>
        </Box>
    )
}

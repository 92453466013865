import React, { useCallback, useMemo, useState } from 'react'

import shortid from 'shortid'

import { SaveGuardContext } from './SaveGuardContext'

export const SaveGuardContextProvider: React.FC = ({ children }) => {
    const [blocks, setBlocks] = useState<{ [key: string]: string }>({})

    const registerBlock = useCallback((message: string) => {
        const id = shortid.generate()
        setBlocks((prev) => ({ ...prev, [id]: message }))
        return () => {
            setBlocks((prev) => {
                const { [id]: _, ...rest } = prev
                return rest
            })
        }
    }, [])

    const result = useMemo(
        () => ({
            canSave: Object.keys(blocks).length === 0,
            reasons: new Set(Object.values(blocks)),
            registerBlock,
        }),
        [blocks, registerBlock]
    )

    return <SaveGuardContext.Provider value={result}>{children}</SaveGuardContext.Provider>
}

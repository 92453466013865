import * as TabsPrimitive from '@radix-ui/react-tabs'

import { Box } from 'ui/components/Box'
import { makeComponent } from 'ui/helpers/recipes'

import {
    ActionsContainerStyles,
    TabsContentStyles,
    TabsListStyles,
    TabsListWrapperStyles,
    TabsStyles,
    TabsTriggerInnerStyles,
    TabsTriggerStyles,
} from './Tabs.css'

export const Root = makeComponent(TabsPrimitive.Root, TabsStyles)
export const ListWrapper = makeComponent(Box, TabsListWrapperStyles)
export const List = makeComponent(TabsPrimitive.List, TabsListStyles)
export const Trigger = makeComponent(TabsPrimitive.Trigger, TabsTriggerStyles, {
    ['data-focus-visible-added']: true,
} as never)
export const TriggerInner = makeComponent('div', TabsTriggerInnerStyles)
export const Content = makeComponent(TabsPrimitive.Content, TabsContentStyles)
export const ActionsContainer = makeComponent('div', ActionsContainerStyles)

import React from 'react'

import { Switch } from '@chakra-ui/react'
import AdvancedOptions from 'v2/views/List/AdvancedOptions'

import DropdownAttribute from 'features/pages/blocks/settings/attributes/items/form/Dropdown'

import { Box } from 'v2/ui'

import { ViewHeaderEditorLabel } from './ViewHeaderEditorLabel'

// const sizeOptions = [{ label: 'Small', value: 'small' }]

const colorModeOptions = [
    { label: 'Light', value: 'light' },
    { label: 'Dark', value: 'dark' },
]

type ViewHeaderEditorImageAdvancedSettingsProps = Omit<
    React.ComponentPropsWithoutRef<typeof AdvancedOptions>,
    'children'
> & {
    pageHeader: PageHeaderImage
    updatePageHeader: (patch: Partial<PageHeaderImage>) => void
}

export const ViewHeaderEditorImageAdvancedSettings: React.FC<
    ViewHeaderEditorImageAdvancedSettingsProps
> = ({ pageHeader, updatePageHeader, ...props }) => {
    const { colorMode, showOverlay, showBackgroundColor, src } = pageHeader

    const hasImage = Boolean(src)

    return (
        <AdvancedOptions
            title={<ViewHeaderEditorLabel as="div">Advanced settings</ViewHeaderEditorLabel>}
            width="100%"
            {...props}
        >
            <Box mt={4}>
                {/* <ViewHeaderEditorLabel htmlFor="page-header-size" mb={2}>
                    Size
                </ViewHeaderEditorLabel>
                <DropdownAttribute
                    id="page-header-size"
                    options={sizeOptions}
                    value={size}
                    menuPlacement="auto"
                    onChange={(newValue) =>
                        updatePageHeader({ size: newValue as PageHeaderImage['size'] })
                    }
                    isClearable={false}
                /> */}

                {hasImage && (
                    <>
                        <ViewHeaderEditorLabel htmlFor="page-header-color-mode" mt={4} mb={2}>
                            Color mode
                        </ViewHeaderEditorLabel>
                        {/* @ts-expect-error */}
                        <DropdownAttribute
                            id="page-header-color-mode"
                            options={colorModeOptions}
                            value={colorMode}
                            menuPlacement="auto"
                            onChange={(newValue) =>
                                updatePageHeader({
                                    colorMode: newValue as PageHeaderImage['colorMode'],
                                })
                            }
                            isClearable={false}
                        />
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mt={4}
                        >
                            <ViewHeaderEditorLabel htmlFor="page-header-overlay">
                                Show overlay
                            </ViewHeaderEditorLabel>
                            <Switch
                                id="page-header-overlay"
                                isChecked={showOverlay}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updatePageHeader({ showOverlay: e.target.checked })
                                }
                            />
                        </Box>
                    </>
                )}

                {!hasImage && (
                    <Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
                        <ViewHeaderEditorLabel htmlFor="page-header-color">
                            Show background color
                        </ViewHeaderEditorLabel>
                        <Switch
                            id="page-header-color"
                            isChecked={showBackgroundColor}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                updatePageHeader({ showBackgroundColor: e.target.checked })
                            }
                        />
                    </Box>
                )}
            </Box>
        </AdvancedOptions>
    )
}

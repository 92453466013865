import React, { memo } from 'react'

import { Switch } from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Button from 'v2/ui/components/Button'
import ConditionalWrapper from 'v2/ui/components/ConditionalWrapper'
import Flex from 'v2/ui/components/Flex'
import Icon from 'v2/ui/components/Icon'
import Text from 'v2/ui/components/Text'
import Tooltip from 'v2/ui/components/Tooltip'
import stackerTheme from 'v2/ui/theme/styles/default'

import { OrderableItemContentProps } from './types'

const { colors } = stackerTheme()

export const OrderableItemContent = memo<OrderableItemContentProps>((props) => {
    const {
        allowReorder,
        label,
        icon,
        onEdit,
        item,
        isChecked,
        onCheckedChanged,
        autoHideEditButton,
        classes,
        showActionsOnDisabled = false,
    } = props
    const showToggle =
        props.showToggle !== undefined ? props.showToggle : !item.props?.cannotBeDragged

    const allowDelete = item.props?.allowDelete ?? true

    const testIdSuffix = props['data-testid'] ? `-${props['data-testid']}` : ''
    return (
        <>
            {allowReorder && !item.isDisabled && !Boolean(item.props?.cannotBeDragged) && (
                <Icon my={2} mr={2} icon="dragHandle" color="gray.400" />
            )}
            {item?.iconOrderableItem}
            <ItemLabel
                data-testid={`orderable-item-label${testIdSuffix}`}
                size="sm"
                isSelected={item.isSelected}
            >
                {icon} {label}
            </ItemLabel>

            {onEdit && (
                <ButtonContainer hiddenUntilFocus={autoHideEditButton}>
                    <EditButton
                        variant="clear"
                        onClick={onEdit}
                        className={classes ? classes.editButton : ''}
                        aria-label="Edit"
                    >
                        <Icon icon="edit" color="gray.400" />
                    </EditButton>
                </ButtonContainer>
            )}

            {item.actions && (showActionsOnDisabled || !item.isDisabled) && (
                <Flex style={{ flexWrap: 'nowrap' }}>
                    {item.actions.map(({ onClick, title, icon, ...props }) => (
                        <ActionButton
                            key={title}
                            variant="clear"
                            onClick={() => onClick(item)}
                            title={title}
                        >
                            {typeof icon === 'string' ? <Icon icon={icon} {...props} /> : icon}
                        </ActionButton>
                    ))}
                </Flex>
            )}

            {!showToggle ? (
                item.props?.allowEdit && allowDelete ? (
                    <EditButton variant="clear" onClick={onCheckedChanged}>
                        <Icon icon="x" color="gray.400" />
                    </EditButton>
                ) : null
            ) : (
                <div onClick={(e) => e.stopPropagation()}>
                    <ConditionalWrapper
                        condition={!!item?.props?.tooltip}
                        wrapper={(children) => (
                            <Tooltip label={item?.props?.tooltip}>{children}</Tooltip>
                        )}
                    >
                        <Switch
                            data-testId={`orderable-item-toggle${testIdSuffix}`}
                            colorScheme="adminSwitch"
                            size="sm"
                            isChecked={isChecked}
                            isDisabled={item.isDisabled}
                            my={2}
                            mx={1}
                            // @ts-ignore
                            onChange={onCheckedChanged}
                        />
                    </ConditionalWrapper>
                </div>
            )}
        </>
    )
})

const ItemLabel = styled(Text)<{ hiddenUntilFocus?: boolean; isSelected?: boolean }>`
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    padding: 8px 0;
    ${(props) =>
        props.isSelected &&
        css`
            color: ${colors.userInterface.accent[1000]};
            font-weight: bold;
        `};
`
const ButtonContainer = styled('div')<{ hiddenUntilFocus?: boolean; isSelected?: boolean }>`
    transition: all 0.2s ease-in;
    transform: ${(props) => {
        return !props.hiddenUntilFocus ? 'scaleX(1)' : 'scaleX(0)'
    }};
    transform-origin: right;
    max-width: ${(props) => {
        return !props.hiddenUntilFocus ? 'unset' : '0px'
    }};
    overflow: hidden;
    display: flex;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    :focus-within {
        transform: scaleX(1);
        max-width: unset;
    }
`
const EditButton = styled(Button)`
    background-color: transparent;
    border-radius: 0;
    padding: 8px;
    :focus {
        background-color: ${colors.userInterface.neutral[200]};
    }
`

const ActionButton = styled(Button)`
    background-color: transparent;
    border-radius: 0;
    padding: 4px;
    :focus {
        background-color: ${colors.userInterface.neutral[200]};
    }
`

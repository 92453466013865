import React from 'react'

import { Box } from 'ui/components/Box'

import { Attribute } from './attributes/Attribute'
import { useCardViewEyebrowState } from './hooks/useCardViewEyebrowState'

import { CardViewEyebrowAttributeStyle } from './CardView.css'

type CardViewEyebrowProps = {
    record: RecordDto
    isLoading?: boolean
    hasCoverImage?: boolean
}

export const CardViewEyebrow: React.FC<CardViewEyebrowProps> = ({
    record,
    isLoading,
    hasCoverImage,
}) => {
    const { attribute, value } = useCardViewEyebrowState({ record })

    if (!attribute || !value) return null

    return (
        <Box className={CardViewEyebrowAttributeStyle.styleFunction({ hasCoverImage })}>
            <Attribute
                attribute={attribute}
                isLoading={isLoading}
                displayStyle="tags"
                value={value}
                style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
            />
        </Box>
    )
}

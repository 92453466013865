import { getFieldIcon } from 'features/admin/fields/icons/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseLongTextAttributeTagStateProps = {
    field: FieldDto
    showIcon?: boolean
}

export function useLongTextAttributeTagState({
    field,
    showIcon,
}: UseLongTextAttributeTagStateProps) {
    const fieldIcon = getFieldIcon(field)
    const icon = useDeepEqualsMemoValue(
        showIcon && fieldIcon?.type === 'lucide' ? fieldIcon : undefined
    )

    return { icon }
}

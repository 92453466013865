import { useEffect } from 'react'

import settings from 'app/settings'
import { getAbsoluteWorkspaceRootUrl, getWorkspaceSlugFromLocation } from 'app/UrlService'
import { useAccounts } from 'data/hooks/accounts'

export function WorkspaceDomainRedirector() {
    const { data: accounts } = useAccounts()
    const slug = getWorkspaceSlugFromLocation()
    useEffect(() => {
        const host = settings.HOST
        if (!settings.SUPPORTED_STUDIO_DOMAINS.includes(host)) {
            const account = accounts?.find((account) => (account.base_url === host) === host)
            // If this account doesn't require SSO, then it's not eligible to be served at a domain other than a studio domain.
            if (account && !account.sso_required) {
                window.location.assign(
                    `${getAbsoluteWorkspaceRootUrl(account)}${window.location.pathname}${
                        window.location.search
                    }${window.location.hash}`
                )
            }
            // Otherwise if we're on the studio domain and we have a workspace slug for an account
            // and that account has SSO required, then we want to redirect to the account domain.
        } else if (slug) {
            const account = accounts?.find((account) => account.slug === slug)

            if (account && account.sso_required) {
                const path = window.location.pathname.replace(`/${slug}`, '')
                window.location.assign(
                    `${getAbsoluteWorkspaceRootUrl(account)}${path}${window.location.search}${
                        window.location.hash
                    }`
                )
            }
        }
    }, [accounts, slug])

    return null
}

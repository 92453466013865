import React, { MouseEvent, useCallback, useMemo, useRef } from 'react'
import ReactFocusLock from 'react-focus-lock'

import styled from '@emotion/styled'
import type { Placement } from '@popperjs/core'

import FieldEditor, { FieldEditorHandle } from 'features/admin/fields/FieldEditor'

import { Box } from 'v2/ui'
import Popper from 'v2/ui/components/Popper'
import { autoSizeModifier } from 'v2/ui/components/popperModifiers/autoSizeModifier'
import { colors } from 'v2/ui/theme/styles/default'

type FieldEditorPopoverProps = {
    open?: boolean
    placement?: Placement
    target?: HTMLElement
    container?: HTMLElement
    objectId?: string
    field?: FieldDto | null
    onSuccess: (field: FieldDto) => void
    onCreate?: (field: FieldDto) => void
    onCancel: () => void
    onClose: () => void
    onFieldDeleted?: (field?: FieldDto | null) => void
    adaptivePlacement?: boolean
}
export const FieldEditorPopover: React.FC<FieldEditorPopoverProps> = ({
    open,
    placement: defaultPlacement,
    target,
    container,
    objectId,
    field,
    onSuccess,
    onCreate,
    onCancel,
    onClose,
    adaptivePlacement = true,
}) => {
    const editorRef = useRef<FieldEditorHandle | null>(null)

    // Find the first descendant of the document.body which contains
    // either the container element or the target element (if container
    // isn't specified). We pass this through to the Popper component
    // so it knows which branch of the DOM to consider for "outside actions."
    const rootNode = useMemo(() => {
        let result: Node | undefined = undefined
        for (var value of Array.from(document.body.childNodes.values())) {
            if (
                (container && value.contains(container)) ||
                (!container && target && value.contains(target))
            ) {
                result = value
            }
        }

        return result
    }, [container, target])
    const tryClose = useCallback(() => {
        editorRef.current?.tryClose(onClose)
    }, [onClose])
    // keeps clicks in the popover from bubbling up and clicking on other thigns
    // in the hierarchy like record cards/rows, etc.
    const handleClick = (e: MouseEvent) => {
        e.stopPropagation()
    }

    const shouldIgnoreAction = (): boolean => {
        // If there is a Radix popup open, don't close the popover before that popup is closed.
        const isRadixPopperOpen = document.querySelector('[data-radix-popper-content-wrapper]')

        return !!isRadixPopperOpen
    }

    return useMemo(() => {
        if (!open || !target) return null

        // hack to make the popover not appear out-of-bounds less frequently for automatic record links
        const targetY = target.getBoundingClientRect()?.y
        const screenHeight = screen.availHeight

        let placement = defaultPlacement
        if (adaptivePlacement) {
            if (targetY / screenHeight < 0.1) {
                placement = 'top-start'
            } else if (targetY / screenHeight > 0.5) {
                placement = 'top-start'
            } else if (targetY / screenHeight > 0.75) {
                placement = 'bottom-start'
            }
        }

        return (
            <Popper
                placement={placement}
                referenceElement={target}
                onClose={tryClose}
                // Only close on outer actions on descendents of the main app node,
                // not elements in portaled components such as confirm modals, dropdowns, etc.
                limitOuterActionsToDescendentsOf={rootNode}
                shouldIgnoreAction={shouldIgnoreAction}
                modifiers={[autoSizeModifier]}
            >
                <ReactFocusLock>
                    <PopperContentWrapper
                        width={500}
                        background={colors.userInterface.neutral[0]}
                        p={4}
                        onClick={handleClick}
                    >
                        <FieldEditor
                            ref={editorRef}
                            objectId={objectId}
                            onSuccess={onSuccess}
                            onCreate={onCreate}
                            onDeleteSuccess={() => {
                                onCancel()
                            }}
                            onCancel={onCancel}
                            usePortal={false}
                            field={field}
                        />
                    </PopperContentWrapper>
                </ReactFocusLock>
            </Popper>
        )
    }, [
        adaptivePlacement,
        defaultPlacement,
        field,
        objectId,
        onCancel,
        onCreate,
        onSuccess,
        open,
        rootNode,
        target,
        tryClose,
    ])
}

const PopperContentWrapper = styled(Box)`
    border-radius: 6px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
`

import { useCallback } from 'react'

import { orderBy } from 'lodash'

import { useActivities } from 'data/hooks/activities/activities'
import type { ActivitiesData } from 'data/hooks/activities/types'
import { useRealtimeUpdates } from 'data/realtime/realtimeUpdates'

import type { FeedParameters } from './types'

const DEFAULT_DATA: ActivitiesData = {
    activities: [],
    deletedActivities: [],
    records: [],
    users: [],
    fields: {},
    objects: {},
    tasks: [],
    documents: [],
    views: [],
}
export function useActivityFeed({
    onActivityCreationDeletion,
    ...filters
}: FeedParameters & { onActivityCreationDeletion?: () => void } = {}) {
    const {
        data: data,
        refetch,
        isLoadingError,
        ...query
    } = useActivities(
        filters,
        { refetchOnMount: 'always', keepPreviousData: false },
        onActivityCreationDeletion
    )
    const sortByLastReply = !filters?.relatedTo
    useRealtimeUpdates({ channel: 'activities', handler: refetch })

    const { activities, ...relatedData } = { ...DEFAULT_DATA, ...data }
    const topLevelActivities = activities?.filter((a) => !a.parent_activity_id)
    const sortedActivities = sortByLastReply
        ? orderBy(topLevelActivities, [(a) => a.last_reply_at || a.updated_at], ['desc'])
        : orderBy(topLevelActivities, ['updated_at'], ['desc'])

    const relatedActivities = orderBy(
        activities?.filter((a) => a.parent_activity_id && !a.is_deleted),
        ['created_at'],
        ['asc']
    )
    const refresh = useCallback(async () => {
        await refetch()
    }, [refetch])

    return {
        activities: sortedActivities ?? [],
        relatedActivities,
        relatedData,
        refresh,
        loadingFailed: isLoadingError,
        ...query,
    }
}

import * as React from 'react'

import styled from '@emotion/styled'

import NewNavigationEditor from 'features/admin/settings/navigation/NewNavigationEditor/NewNavigationEditor'

export default function AppModalNavigation({ onCloseSettingsModal }) {
    return (
        <>
            <StyledNavigationEditor>
                <NewNavigationEditor
                    onCloseSettingsModal={onCloseSettingsModal}
                    canChangeNavigationPlacement={false}
                />
            </StyledNavigationEditor>
        </>
    )
}

const StyledNavigationEditor = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 8px 30px 15px;
    overflow-y: hidden;
`

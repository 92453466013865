import { AnyExtension } from '@tiptap/core'
import { Code } from '@tiptap/extension-code'

const ALLOWED_MARKS = new Set(['activity'])
const ADDITIONAL_EXCLUDED_MARKS = new Set(['bold', 'code', 'italic', 'strike'])

export function createCodeExtension() {
    return Code.extend({
        excludes() {
            const extensions = this.editor?.options.extensions as AnyExtension[]
            if (!extensions) return undefined

            const marks = extensions.reduce((acc, e) => {
                if (e.type === 'mark' && !ALLOWED_MARKS.has(e.name)) {
                    acc.add(e.name)
                }

                return acc
            }, new Set<string>())

            // Add aditional marks to exclude.
            for (const mark of ADDITIONAL_EXCLUDED_MARKS) {
                marks.add(mark)
            }

            if (marks.size < 1) {
                return undefined
            }

            return Array.from(marks).join(' ')
        },
    })
}

import React, { useCallback, useMemo } from 'react'

import { isEqual } from 'lodash'

import { DownloadAttachmentsButton } from 'v2/ui/components/Attribute/DownloadAttachmentsButton'
import Gallery from 'v2/ui/components/Gallery'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { LinkButton } from 'ui/components/LinkButton'
import { Popup, PopupContent, PopupInner, PopupTrigger } from 'ui/components/Popup'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { makeLineTruncationStyle, stopPropagation } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAttachmentsAttributeDisplayState } from './hooks/useAttachmentsAttributeDisplayState'
import { Thumbnail } from './Thumbnail'
import { AttachmentValue, AttributeDisplayComponent } from './types'

const sizeMapping = {
    button: {
        s: '2xs',
        m: 'xs',
    },
    thumbnail: {
        s: 's',
        m: 'm',
    },
    tag: {
        s: '2xs',
        m: 'xs',
    },
} as const

type AttachmentsAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Box> & {
    maxAttachments?: number
    displayAsImage?: boolean
    imageAltText?: string
    maxItemLength?: number
    maxLines?: number
    size?: ResponsiveValue<'s' | 'm'>
    isLoading?: boolean
    showOnlyCount?: boolean
}

export const AttachmentsAttributeDisplay: AttributeDisplayComponent<
    AttachmentValue[],
    AttachmentsAttributeDisplayProps
> = React.memo(function AttachmentsAttributeDisplay({
    value,
    maxAttachments,
    maxItemLength,
    maxLines,
    displayAsImage,
    imageAltText,
    size = 'm',
    isLoading: providedIsLoading,
    showOnlyCount = false,
    ...props
}) {
    const effectiveSize = useResponsiveValue(size)
    const buttonSize = sizeMapping.button[effectiveSize]
    const thumbnailSize = sizeMapping.thumbnail[effectiveSize]
    const tagSize = sizeMapping.tag[effectiveSize]

    const {
        attachments,
        isLoading,
        overflowingAttachmentsCountLabel,
        overflowingAttachments,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelCloseAutoFocus,
        onOverflowLabelFocus,
        onOverflowLabelBlur,
        onOverflowLabelClick,
        wrapperRef,
        gridTemplateColumns,
    } = useAttachmentsAttributeDisplayState({
        value,
        maxAttachments,
        maxItemLength,
        isLoading: providedIsLoading,
        displayAsImage,
        showOnlyCount,
    })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
    }, [])

    const galleryStyle = useMemo(() => {
        return {
            ...makeLineTruncationStyle(maxLines),
            gridTemplateColumns,
        }
    }, [gridTemplateColumns, maxLines])

    return (
        <Box ref={wrapperRef} flex center height="full" {...props}>
            <Gallery
                center={!displayAsImage}
                display="grid"
                grow
                gap="xs"
                style={galleryStyle}
                useThumbnailsFromChildren={false}
                height="full"
                selector="a"
                toolbarChildren={
                    <DownloadAttachmentsButton
                        attachments={attachments ?? []}
                        fieldName={props.field.label}
                    />
                }
            >
                {displayAsImage && (
                    <>
                        {attachments?.map((attachment) => (
                            <Skeleton
                                isLoading={isLoading}
                                key={attachment.url}
                                // Hide the element, but still keep it in the gallery.
                                display={attachment.exceedsLimit ? 'none' : undefined}
                            >
                                <Thumbnail
                                    as="a"
                                    size={thumbnailSize}
                                    href={attachment.url}
                                    src={attachment.url}
                                    alt={imageAltText}
                                    maxWidth="full"
                                    maxHeight="full"
                                    onClick={handleClick}
                                    target="_blank"
                                    noShrink
                                    style={{
                                        // Hide the element, but still keep it in the gallery.
                                        display: attachment.exceedsLimit ? 'none' : undefined,
                                    }}
                                    {...attachment.attributes}
                                />
                            </Skeleton>
                        ))}
                        {overflowingAttachmentsCountLabel && (
                            <Skeleton isLoading={isLoading} height="full">
                                <Box noShrink onClick={stopPropagation} height="full">
                                    <Popup
                                        open={isOverflowPopupOpen}
                                        onOpenChange={onOverflowPopupOpenChange}
                                    >
                                        <PopupTrigger
                                            asChild
                                            onMouseEnter={onOverflowLabelMouseEnter}
                                            onFocus={onOverflowLabelFocus}
                                            onMouseLeave={onOverflowLabelMouseLeave}
                                            onBlur={onOverflowLabelBlur}
                                            onTouchStart={stopPropagation}
                                        >
                                            <Tag
                                                type="solid"
                                                color="Neutral"
                                                size={tagSize}
                                                style={{ height: '100%' }}
                                            >
                                                {overflowingAttachmentsCountLabel}
                                            </Tag>
                                        </PopupTrigger>
                                        <PopupContent
                                            sideOffset={0}
                                            onOpenAutoFocus={stopPropagation}
                                            onCloseAutoFocus={stopPropagation}
                                            onMouseEnter={onOverflowLabelMouseEnter}
                                            onMouseLeave={onOverflowLabelMouseLeave}
                                            side="bottom"
                                            align="end"
                                            style={{
                                                width: 'auto',
                                                maxWidth: '300px',
                                            }}
                                        >
                                            <PopupInner flex center gap="xs" wrap>
                                                {overflowingAttachments!.map((attachment) => (
                                                    <Thumbnail
                                                        key={attachment.url}
                                                        as="a"
                                                        size={thumbnailSize}
                                                        href={attachment.url}
                                                        src={attachment.url}
                                                        alt={imageAltText}
                                                        maxWidth="full"
                                                        maxHeight="full"
                                                        onClick={onOverflowLabelClick}
                                                        target="_blank"
                                                        noShrink
                                                        {...attachment.attributes}
                                                    />
                                                ))}
                                            </PopupInner>
                                        </PopupContent>
                                    </Popup>
                                </Box>
                            </Skeleton>
                        )}
                    </>
                )}
                {!displayAsImage && (
                    <>
                        {attachments?.map((attachment) => (
                            <Skeleton
                                isLoading={isLoading}
                                key={attachment.url}
                                // Hide the element, but still keep it in the gallery.
                                display={attachment.exceedsLimit ? 'none' : undefined}
                                style={{
                                    maxWidth: '100%',
                                    minWidth: '50px',
                                }}
                            >
                                <LinkButton
                                    variant="secondary"
                                    startIcon={{ name: 'Paperclip' }}
                                    size={buttonSize}
                                    href={attachment.url}
                                    onClick={handleClick}
                                    noShrink
                                    style={{
                                        // Hide the element, but still keep it in the gallery.
                                        display: attachment.exceedsLimit ? 'none' : undefined,
                                        maxWidth: '100%',
                                        minWidth: '50px',
                                    }}
                                    {...attachment.attributes}
                                >
                                    <Box trim maxWidth="full" py="3xs">
                                        {attachment.name}
                                    </Box>
                                </LinkButton>
                            </Skeleton>
                        ))}
                        {overflowingAttachmentsCountLabel && (
                            <Skeleton isLoading={isLoading}>
                                <Dropdown
                                    open={isOverflowPopupOpen}
                                    onOpenChange={onOverflowPopupOpenChange}
                                    modal={false}
                                >
                                    <DropdownButton
                                        variant="secondary"
                                        size={buttonSize}
                                        noShrink
                                        onClick={stopPropagation}
                                        onMouseEnter={onOverflowLabelMouseEnter}
                                        onMouseLeave={onOverflowLabelMouseLeave}
                                    >
                                        {overflowingAttachmentsCountLabel}
                                    </DropdownButton>
                                    <DropdownContent
                                        sideOffset={0}
                                        side="bottom"
                                        align="end"
                                        onCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                                        onMouseEnter={onOverflowLabelMouseEnter}
                                        onMouseLeave={onOverflowLabelMouseLeave}
                                    >
                                        {overflowingAttachments!.map((attachment) => (
                                            <DropdownItem
                                                key={attachment.url}
                                                label={attachment.name}
                                                onClick={onOverflowLabelClick}
                                                {...attachment.attributes}
                                            />
                                        ))}
                                    </DropdownContent>
                                </Dropdown>
                            </Skeleton>
                        )}
                    </>
                )}
            </Gallery>
        </Box>
    )
}, isEqual)

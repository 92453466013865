import React, { memo, useMemo } from 'react'

import { injectGlobal } from 'emotion'
import { ThemeProvider } from 'emotion-theming'

import { useAppContext } from 'app/useAppContext'
import { evaluateThemeFormulas } from 'features/studio/theme/utils'
import stackerTheme from 'legacy/v1/ui/styleHelpers/stackerTheme'

const ThemeWrapperInner = memo(({ stackTheme, children }) => {
    const siteTheme = useMemo(
        () =>
            evaluateThemeFormulas({
                ...stackerTheme,
                ...stackTheme,
            }),
        [stackTheme]
    )

    return (
        <ThemeProvider theme={siteTheme}>
            {children({ theme: siteTheme })}
            {injectGlobal`
                page > a {
                    color: ${siteTheme.linkTextColor};
                }
            `}
        </ThemeProvider>
    )
})

export const ThemeWrapper = (props) => {
    // When not authenticated as a studio user, the theme
    // is accessed via App (the backend copies it there from Stack)
    // This is so that a non-studio user doesn't need to access Stack
    const { selectedStack } = useAppContext()

    if (props.loadFromStack) {
        const stackTheme =
            (selectedStack && selectedStack.options && selectedStack.options.theme) || {}

        return <ThemeWrapperInner stackTheme={stackTheme}>{props.children}</ThemeWrapperInner>
    }
    return <ThemeWrapperInner stackTheme={props.app.theme}>{props.children}</ThemeWrapperInner>
}

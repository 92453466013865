import { useCallback, useMemo, useState } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationCreateAppModalState() {
    const { spaces } = useNavigationContext()

    const [isOpen, setIsOpen] = useState(false)

    const [spaceId, setSpaceId] = useState<string | undefined>(undefined)

    const selectedSpace = useMemo(() => {
        return spaces.find((s) => s.id === spaceId)
    }, [spaces, spaceId])

    const open = useCallback(
        (spaceId?: string) => {
            setSpaceId(spaceId)
            setIsOpen(true)
        },
        [setIsOpen]
    )

    const close = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    const onBeforeCreateStack = useCallback(
        (stack: StackDto) => {
            stack.options.group = spaceId
            stack.options.pin_to_app_bar = true
        },
        [spaceId]
    )

    return useMemo(
        () => ({
            isOpen,
            setIsOpen,
            open,
            close,
            selectedSpace,
            onBeforeCreateStack,
        }),
        [isOpen, open, close, selectedSpace, onBeforeCreateStack]
    )
}

import React from 'react'

import { useAccountUserContext } from 'app/useAccountUserContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import useLDFlags from 'data/hooks/useLDFlags'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { Progress } from 'ui/components/Progress'
import { Body } from 'ui/components/Text'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { ResourceBoxStyle } from './TrialBannerStyles.css'

export function TrialBanner() {
    const { workspaceAccount } = useWorkspaceContext()
    const { role } = useAccountUserContext()
    const { flags } = useLDFlags()
    const daysRemaining = Math.max(workspaceAccount?.trial_days_remaining ?? 0, 0)

    const openBookingWindow = () => {
        const url = 'https://calendly.com/stacker-app/create-your-first-stacker-app'
        window.open(url, '_blank')
    }

    const { isPreviewingAsUserOrRole } = usePreviewServiceContext()

    if (!flags.showTrialBanner || role?.api_name !== 'owner' || isPreviewingAsUserOrRole)
        return null

    const quickStartGuides = [
        {
            label: 'Managing data',
            url: 'https://docs.stackerhq.com/stacker/getting-started/introduction-to-data',
        },
        {
            label: 'Design your app',
            url: 'https://docs.stackerhq.com/stacker/getting-started/introduction-to-layouts',
        },
        {
            label: 'Users and permissions',
            url: 'https://docs.stackerhq.com/stacker/getting-started/introduction-to-permissions',
        },
    ]

    return (
        <ThemeProvider theme="admin">
            <Box
                flex
                stretch
                column
                gap="l"
                width="full"
                px="l"
                py="l"
                alignContent="center"
                justifyContent="center"
            >
                <Divider />
                <Box flex column gap="s">
                    <Box flex gap="s" alignItems="center">
                        <Icon name="Info" size="l" />
                        <Body size="m" weight="bold">
                            Quick start guides
                        </Body>
                    </Box>

                    <Box flex column>
                        {quickStartGuides.map((guide, index) => (
                            <Box
                                key={index}
                                flex
                                justifyContent="space-between"
                                alignItems="center"
                                px="m"
                                py="s"
                                cursor="pointer"
                                onClick={() => window.open(guide.url, '_blank')}
                                className={ResourceBoxStyle}
                            >
                                <Box flex gap="m" alignItems="center">
                                    <Icon name="Book" />
                                    <Body size="m">{guide.label}</Body>
                                </Box>
                                <Icon name="ChevronRight" />
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Button size="s" variant="secondary" onClick={openBookingWindow}>
                    Talk to a Stacker expert
                </Button>
                <Progress value={(daysRemaining / 14) * 100} />

                {daysRemaining > 0 ? (
                    <Body size="s" textAlign="center">
                        <strong>{daysRemaining} days</strong> left in your Stacker free trial
                    </Body>
                ) : (
                    <Body textAlign="center">
                        Your stacker free <strong>trial has ended</strong>.
                    </Body>
                )}
            </Box>
        </ThemeProvider>
    )
}

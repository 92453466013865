import React, { FC } from 'react'

import ActionButtonList from './ActionButtonList'
import { useActionButtons } from './actionHooks'

type ActionButtonsListProps = {
    buttons: ActionButton[]
    record: RecordDto
    object: ObjectDto
    style: string
    showControls: boolean
    includeFields: string[]
}

const ActionButtonsList: FC<ActionButtonsListProps> = ({
    buttons,
    record,
    object,
    style = '1pa',
    showControls,
    includeFields,
}) => {
    const availableButtons = useActionButtons(buttons, record, object, [])
    const maxVisible = 2

    const getStyle = (index: number) => {
        // {} will convert in primary buttons
        if (style === 'primary') return {}

        // first item is primary, others are secondary
        if (style === '1pa' && index === 0) return {}

        // useful if there is a dropdown
        if (index >= maxVisible) return {}

        return {
            variant: 'adminSecondary',
            buttonSize: 'sm',
        }
    }

    return (
        <ActionButtonList
            includeFields={includeFields}
            record={record}
            maxVisible={maxVisible}
            getActionProps={({ index }) => getStyle(index)}
            actions={availableButtons.map((item) => {
                return { ...item, isDisabled: showControls }
            })}
        />
    )
}

export default ActionButtonsList

import React from 'react'

import { useDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import { useNewDataConnectionModal } from 'features/DataConnections/useNewDataConnectionModal'

import { LoadingScreen } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Headline } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import {
    DataConnectionGroupHeading,
    DataConnectionGroupRow,
} from './DataConnectionGroupHeadingAndRow'
import { groupDataConnectionsByTypeAndNangoConnectionId } from './groupDataConnections'

export const DataConnectionsManager: React.FC = () => {
    const { data: dataConnections, isLoading } = useDataConnections()
    const dataConnectionsGroupedByTypeAndEmail =
        groupDataConnectionsByTypeAndNangoConnectionId(dataConnections)
    const { show: showNewDataConnectionModal } = useNewDataConnectionModal()

    return (
        <>
            <Box
                flex
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                pt="2xl"
            >
                <LoadingScreen isLoading={isLoading}>
                    <Box flex column stretch style={{ margin: '30px 20px' }}>
                        <Box flex justifyContent="space-between">
                            <Headline size="s">Data sources</Headline>

                            <Button
                                type="button"
                                startIcon={{ name: 'Plus' }}
                                size="s"
                                // TODO-Nango: Add new modal that takes user to data source selector first (or maybe that should be part
                                //   of the logic of NewDataConnectionModal - if integrationId is provider use that, otherwise show
                                //   screen to choose integration)
                                onClick={() =>
                                    showNewDataConnectionModal({
                                        initialSelectedExternalAccountId: '',
                                    })
                                }
                            >
                                Add data source
                            </Button>
                        </Box>
                        <Box
                            style={{
                                paddingTop: theme.space['3xl'],
                                paddingBottom: theme.space['10xl'],
                                overflowX: 'scroll',
                                height: '90vh',
                            }}
                        >
                            <DataConnectionGroupHeading />
                            {dataConnectionsGroupedByTypeAndEmail?.map((group) => (
                                <DataConnectionGroupRow
                                    key={group.key}
                                    dataConnections={group.dataConnections}
                                />
                            ))}
                        </Box>
                    </Box>
                </LoadingScreen>
            </Box>
        </>
    )
}

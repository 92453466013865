import React, { useCallback, useRef, useState } from 'react'

import { HeadDoubleInputWrapperStyle } from 'ui/components/Menu/Menu.css'
import { Select } from 'ui/components/Select'

import { DropdownHeadBase } from './DropdownHeadBase'
import { useKeyDownFromHead } from './useKeyDownFromHead'

type DropdownHeadDoubleSelectRef = HTMLDivElement

type DropdownHeadDoubleSelectProps = React.ComponentPropsWithoutRef<typeof DropdownHeadBase> & {
    selectA: React.ComponentPropsWithoutRef<typeof Select>
    selectB: React.ComponentPropsWithoutRef<typeof Select>
    autoFocus?: boolean
}

export const DropdownHeadDoubleSelect = React.forwardRef<
    DropdownHeadDoubleSelectRef,
    DropdownHeadDoubleSelectProps
>(({ selectA, selectB, onFocus, autoFocus = true, ...props }, ref) => {
    const selectARef = useRef<HTMLButtonElement>(null)
    const selectBRef = useRef<HTMLButtonElement>(null)

    const [headRef, setHeadRef] = useState<HTMLElement | null>(null)

    const handleOnFocus = useCallback(
        (e: React.FocusEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()

            // Only run this when the head is focused from another menu item.
            if (e.target !== e.currentTarget) return

            const selectA = selectARef.current
            if (!selectA) return

            selectA.focus()

            onFocus?.(e)
        },
        [onFocus]
    )

    const handleSelectAKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLButtonElement>) => {
            selectA?.onKeyDown?.(e)

            if (e.key === 'ArrowDown') {
                e.preventDefault()

                const selectB = selectBRef.current
                if (!selectB) return

                selectB.focus()
            }
        },
        [selectA]
    )

    const handleKeyDownFromHead = useKeyDownFromHead(headRef)

    const handleSelectBKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'ArrowUp') {
                e.preventDefault()

                const selectA = selectARef.current
                if (!selectA) return

                selectA.focus()

                return
            }

            handleKeyDownFromHead(e)
        },
        [handleKeyDownFromHead]
    )

    return (
        <DropdownHeadBase
            onFocus={handleOnFocus}
            {...props}
            ref={(element: HTMLDivElement) => {
                setHeadRef(element)

                return ref
            }}
        >
            <div className={HeadDoubleInputWrapperStyle}>
                <Select
                    ref={selectARef}
                    {...selectA}
                    onKeyDown={handleSelectAKeyDown}
                    autoFocus={autoFocus}
                />

                <Select ref={selectBRef} {...selectB} onKeyDown={handleSelectBKeyDown} />
            </div>
        </DropdownHeadBase>
    )
})

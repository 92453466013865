import { useActivityFeedConfig } from 'features/Activity/useActivityFeedConfig'

type UseDocumentCommentFeedCountParams = {
    document?: DocumentDto
    record?: RecordDto
}

export function useDocumentCommentFeedCount({
    document,
    record,
}: UseDocumentCommentFeedCountParams) {
    const { commentCount = 0 } = useActivityFeedConfig({
        document,
        record,
    })

    return commentCount
}

// @ts-strict-ignore
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useCreateRole, useStackRoles } from 'data/hooks/roles'

export const useCreateRoleModalState = ({
    setIsOpen,
    onRoleCreated,
}: {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onRoleCreated: (role: RoleDto) => void
}) => {
    const toggleModal = useCallback(() => setIsOpen((open) => !open), [setIsOpen])

    const { mutateAsync: createRole } = useCreateRole()
    const { data: roles } = useStackRoles()

    const isRoleNameUnique = useCallback(
        (roleLabel: string): boolean => !roles.some(({ label }) => label === roleLabel),
        [roles]
    )

    const [error, setError] = useState<string>()

    const formContext = useForm({})

    const onModalClose = useCallback(() => {
        toggleModal()
        formContext.reset({ label: '' })
    }, [toggleModal, formContext])

    const onSuccess = useCallback(
        (role: RoleDto) => {
            onRoleCreated?.(role)
            onModalClose()
        },
        [onModalClose, onRoleCreated]
    )

    const onCreateRole = useCallback(
        async (data: FormData) => {
            setError(undefined)
            try {
                const role = await createRole(data)
                onSuccess(role as RoleDto)
            } catch {
                setError(
                    'An error occurred while creating the new role. Please contact support if the issue persists.'
                )
            }
        },
        [createRole, onSuccess]
    )

    const onSubmit = useCallback(
        () => formContext.handleSubmit(onCreateRole)(),
        [formContext, onCreateRole]
    )

    return useMemo(
        () => ({
            createRole,
            isRoleNameUnique,
            onCreateRole,
            error,
            formContext,
            onModalClose,
            onSubmit,
        }),
        [createRole, isRoleNameUnique, onCreateRole, error, formContext, onModalClose, onSubmit]
    )
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import get from 'lodash/get'
import getInitials from 'v2/views/utils/getInitials'

import { Icon } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

const IconSize = 23

const AppIcon = styled('img')<{ isSelected?: boolean }>`
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isSelected ? 1 : 0.8)};
    border-radius: 8px;
    :hover {
        opacity: 1;
    }
`

type Props = {
    stack?: any
    isSelected?: boolean
    theme?: any
    size?: number | string
}

export const StackIcon: FC<Props> = ({ stack, isSelected, theme, size = IconSize }) => {
    const stackIcon = get(stack.options, 'theme.icon')
    const initials = getInitials(stack.name)

    const color = theme.theme === 'light' ? '#9099A0' : 'rgba(255,255,255,0.7)'
    if (stackIcon?.match(/^fa/)) {
        return (
            <Icon
                height="100%"
                color={isSelected ? 'white' : color}
                fontSize={size}
                pb="2px"
                icon={stackIcon}
            />
        )
    } else if (!stackIcon) {
        return (
            <Box flex center justifyContent="center">
                <Body color="textInverse" style={{ fontSize: size }}>
                    {initials}
                </Body>
            </Box>
        )
    } else {
        return <AppIcon src={stackIcon} isSelected={isSelected} />
    }
}

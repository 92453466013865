import React from 'react'

import { Panel, TextareaToolbarContext } from './textareaToolbarContext'

export type TextareaToolbarContextProviderProps = {
    children: React.ReactNode
    currentPanel: Panel | null
    setCurrentPanel: (value: Panel | null) => void
}

export const TextareaToolbarContextProvider: React.FC<TextareaToolbarContextProviderProps> = ({
    children,
    currentPanel,
    setCurrentPanel,
}) => {
    return (
        <TextareaToolbarContext.Provider
            value={{
                currentPanel,
                setCurrentPanel,
            }}
        >
            {children}
        </TextareaToolbarContext.Provider>
    )
}

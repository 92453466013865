import { useMemo } from 'react'

import { useDroppable } from '@dnd-kit/core'

import { useLayoutEditorWidgetAreaContext } from 'features/views/LayoutEditor/useLayoutEditorWidgetAreaContext'
import { useLayoutEditorWidgetContext } from 'features/views/LayoutEditor/useLayoutEditorWidgetContext'

type UseLayoutEditorWidgetAreaStateOptions = {
    id: string
}

export function useLayoutEditorWidgetAreaState(options: UseLayoutEditorWidgetAreaStateOptions) {
    const { id } = options

    const { children, path, isEditing } = useLayoutEditorWidgetAreaContext()!
    const { isDragging = false, widget } = useLayoutEditorWidgetContext() ?? {}

    const sortableContextId = widget ? `${widget.id}.${id}` : id

    const { setNodeRef: ref, over } = useDroppable({
        id: sortableContextId,
        data: {
            id,
            type: 'widgetArea',
            path,
        },
        disabled: isDragging,
    })

    let overColumnId: string | undefined
    if (over?.data.current?.type === 'widgetArea') {
        // We are dragging over an empty column.
        overColumnId = over.data.current.id
    } else {
        // We are dragging over a record in a column.
        overColumnId = over?.data.current?.sortable?.containerId
    }

    const isDraggingOver = id === overColumnId

    return useMemo(
        () => ({
            children,
            ref,
            isDraggingOver,
            sortableContextId,
            isDragging,
            isEditing,
        }),
        [children, isDragging, isDraggingOver, isEditing, ref, sortableContextId]
    )
}

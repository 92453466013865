import React from 'react'

import { TaskRelatedTo } from 'data/hooks/tasks/types'

import { Box } from 'ui/components/Box'

import { RecordLink } from './RecordLink'

type TaskRelatedToDisplayProps = React.ComponentPropsWithoutRef<typeof Box> & {
    related_to: string | null
    related_to_type: TaskRelatedTo | null
    related_to_stack: string | null
}

export const TaskRelatedToDisplay: React.FC<TaskRelatedToDisplayProps> = ({
    related_to,
    related_to_type,
    related_to_stack,
    ...props
}) => {
    if (!related_to || related_to_type !== TaskRelatedTo.Record || !related_to_stack) return null

    return (
        <Box fontSize="bodyS" color="textWeak" flex center gap="2xs" {...props}>
            <Box as="span" noShrink>
                created on
            </Box>
            <RecordLink
                recordId={related_to}
                stackId={related_to_stack}
                variant={'link'}
                buttonProps={{
                    style: {
                        padding: 0,
                        height: 'auto',
                    },
                }}
                trim
            />
        </Box>
    )
}

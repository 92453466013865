import { Editor } from '@tiptap/core'
import { Node as ProseMirrorNode, NodeType, ResolvedPos } from '@tiptap/pm/model'
import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state'
import { NodeView as ProseMirrorNodeView } from '@tiptap/pm/view'
import { Extension, findChildren, isActive } from '@tiptap/react'
import { Details } from '@tiptap-pro/extension-details'
import { DetailsContent } from '@tiptap-pro/extension-details-content'
import { DetailsSummary } from '@tiptap-pro/extension-details-summary'

import { DetailsStyle, SummaryStyle } from './DetailsExtension.css'

export function createDetailsExtension() {
    return Extension.create({
        name: 'details',
        addExtensions() {
            return [
                DetailsSummary.configure({
                    HTMLAttributes: {
                        class: SummaryStyle,
                    },
                }),
                DetailsContent.configure({}),
                Details.extend({
                    addNodeView() {
                        const existing = this.parent!()

                        return (props) => {
                            const view = existing(props) as ProseMirrorNodeView

                            const button = (view.dom as HTMLElement).querySelector('button')
                            if (button) {
                                button.contentEditable = 'false'
                            }

                            return {
                                ...view,
                                dom: view.dom,
                                contentDOM: view.contentDOM,
                            }
                        }
                    },
                    addProseMirrorPlugins() {
                        return [
                            new Plugin({
                                key: new PluginKey('detailsSelection'),
                                // Modify transaction to prevent selection from being placed inside details when it's closed.
                                appendTransaction: (transactions, oldState, newState) => {
                                    const { editor, type: nodeType } = this

                                    const isSelectionUpdated = transactions.some(
                                        (t) => t.selectionSet
                                    )
                                    if (
                                        !isSelectionUpdated ||
                                        !oldState.selection.empty ||
                                        !newState.selection.empty
                                    ) {
                                        // Selection was not updated, bounce.
                                        return
                                    }

                                    if (!isActive(newState, nodeType.name)) return

                                    const { $from } = newState.selection

                                    const hasParent = hasOffsetParent($from.pos, editor)
                                    if (hasParent) return

                                    const currentNodePos = getCurrentNodePos(
                                        $from,
                                        nodeType,
                                        editor
                                    )
                                    if (!currentNodePos) return

                                    const summaryChildren = findChildren(
                                        currentNodePos.node,
                                        (t) => t.type === newState.schema.nodes.detailsSummary
                                    )
                                    if (!summaryChildren.length) return
                                    // The summary node, defined by the `DetailsSummary` extension.
                                    const summaryNode = summaryChildren[0]

                                    // The direction that the cursor moved in.
                                    const direction =
                                        oldState.selection.from < newState.selection.from
                                            ? 'forward'
                                            : 'backward'

                                    const anchorPos =
                                        direction === 'forward'
                                            ? // If the cursor moved forwards, we move the selection to the next node.
                                              currentNodePos.start + currentNodePos.node.nodeSize
                                            : // If the cursor moved backwards, we move the selection to the previous node.
                                              currentNodePos.pos +
                                              summaryNode.pos +
                                              summaryNode.node.nodeSize

                                    const newSelection = TextSelection.create(
                                        newState.doc,
                                        anchorPos
                                    )

                                    return newState.tr.setSelection(newSelection)
                                },
                            }),
                        ]
                    },
                }).configure({
                    persist: true,
                    HTMLAttributes: {
                        class: DetailsStyle,
                    },
                }),
            ]
        },
    })
}

function hasOffsetParent(pos: number, editor: Editor): boolean {
    const parentOffset = (editor.view.domAtPos(pos).node as HTMLElement).offsetParent
    return parentOffset !== null
}

function getCurrentNodePos(
    from: ResolvedPos,
    nodeType: NodeType,
    editor: Editor
):
    | {
          pos: number
          start: number
          depth: number
          node: ProseMirrorNode
      }
    | undefined {
    for (let depth = from.depth; depth > 0; depth -= 1) {
        const node = from.node(depth)
        const isNodeType = node.type === nodeType
        const hasParent = hasOffsetParent(from.start(depth), editor)

        if (isNodeType && hasParent)
            return {
                pos: depth > 0 ? from.before(depth) : 0,
                start: from.start(depth),
                depth,
                node,
            }
    }

    return undefined
}

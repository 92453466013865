import React from 'react'

import { UpdateActivityArgs } from 'data/hooks/activities/activities'
import { RelatedFeedData } from 'data/hooks/activities/types'

import { FeedItemRelationshipInfo } from './types'

export type ActivityFeedContext = RelatedFeedData & {
    target?: FeedItemRelationshipInfo
    activities: ActivityDto[]
    createActivity: (activity: Partial<ActivityDto>) => Promise<ActivityDto>
    deleteActivity: (id: number) => Promise<void>
    updateActivity: (args: UpdateActivityArgs) => Promise<ActivityDto>
    retryFailedActivity: (activity: Partial<ActivityDto>) => Promise<ActivityDto>
}

const DefaultContext: ActivityFeedContext = {
    activities: [],
    deleteActivity: () => Promise.reject(),
    createActivity: () => Promise.reject(),
    updateActivity: () => Promise.reject(),
    retryFailedActivity: () => Promise.reject(),
    fields: {},
    objects: {},
    records: [],
    users: [],
    tasks: [],
    documents: [],
    views: [],
}

export const ActivityFeedContext = React.createContext<ActivityFeedContext>(DefaultContext)

import React from 'react'

import { Box } from 'ui/components/Box'

import { usePortalNavigationState } from './hooks/usePortalNavigationState'
import { PortalApps } from './PortalApps'
import { PortalNavigationHeader } from './PortalNavigationHeader'

import { NavigationScrollAreaStyle } from './Navigation.css'

type PortalNavigationProps = {}

export const PortalNavigation: React.FC<PortalNavigationProps> = () => {
    const { wrapperRef, scrollAreaRef } = usePortalNavigationState()

    return (
        <Box
            flex
            column
            minHeight={0}
            grow
            shrink
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className={NavigationScrollAreaStyle}
        >
            <PortalNavigationHeader />
            <PortalApps ref={scrollAreaRef} />
        </Box>
    )
}

import React, { memo } from 'react'

import { ListView } from 'v2/views/List/ListView'

type ListViewWrapperProps = React.ComponentPropsWithoutRef<typeof ListView> & {}

// @ts-expect-error
const ListViewWrapper: React.FC<ListViewWrapperProps> = memo(({ titleText, ...props }) => {
    // @ts-expect-error
    return <ListView title={titleText} {...props} />
})

export default ListViewWrapper

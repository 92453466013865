import { queryCache, queryClient } from 'data/hooks/_helpers'

import { getMergedRecord } from './getMergedRecord'
import { RECORD_LIST_QUERY_CONFIG } from './recordConstants'

/**
 * Updates records in the object query cache
 *
 * @param {Object} record
 * @param {bool} updateObjectQueries
 */

export const updateObjectRecordQuery = (
    record: RecordDto,
    onMergeRecord?: (cachedRecord: RecordDto, newRecord: RecordDto) => RecordDto
): void => {
    if (!record?._object_id) return
    const key = ['records', record?._object_id]
    // There may be many object queries for different list views, so we go through all of them and
    // update the cache with the updated record (if it exists)
    const objectQueries = queryCache.findAll(key)
    objectQueries.forEach((query) => {
        const { queryKey } = query
        queryClient.setQueryData(
            queryKey,
            (old: any) => {
                const oldRecords: RecordDto[] = old?.records || []
                const oldRecordIndex = oldRecords.findIndex((rec) => rec._sid === record?._sid)
                const oldRecord = oldRecordIndex >= 0 && oldRecords[oldRecordIndex]
                var newRecords = [...oldRecords]
                // This record exists, so update the record in the cache
                if (oldRecord) {
                    let newRecord = onMergeRecord ? onMergeRecord(oldRecord, record) : record
                    newRecord = getMergedRecord(newRecord, oldRecord)
                    newRecords[oldRecordIndex] = newRecord
                }
                return { ...old, records: newRecords }
            },
            RECORD_LIST_QUERY_CONFIG as any
        )
    })
}

export function preloadImage(src: string, fallbackSrc?: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const preloadImage = new Image()
        preloadImage.src = src
        preloadImage.onload = () => {
            resolve(preloadImage)
        }

        preloadImage.onerror = () => {
            if (fallbackSrc) {
                preloadImage.src = fallbackSrc
                preloadImage.onerror = () => {}

                return
            }

            reject()
        }
    })
}

export function extractPartsFromUserName(name?: string) {
    if (!name) {
        return { firstName: '', lastName: '' }
    }

    const parts = name.split(' ')

    return {
        firstName: parts.slice(0, -1).join(' '),
        lastName: parts.at(-1) ?? '',
    }
}

import { useEffect, useRef } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useComposedRefs } from '@radix-ui/react-compose-refs'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseBoardViewDnDCardStateOptions = {
    recordSid: string
    isNotMatchingFilters?: boolean
}

export function useBoardViewDnDCardState(options: UseBoardViewDnDCardStateOptions) {
    const { recordSid, isNotMatchingFilters } = options

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: recordSid,
        data: {
            type: 'record',
        },
        disabled: isNotMatchingFilters,
    })

    const cardRef = useRef<HTMLDivElement | null>(null)
    const composedRef = useComposedRefs<HTMLDivElement>(cardRef, setNodeRef)

    const transformStr = CSS.Transform.toString(transform)
    useEffect(() => {
        const cardEl = cardRef.current
        if (!cardEl) return

        cardEl.style.transition = transition ?? ''
        cardEl.style.transform = transformStr ?? ''
    }, [transformStr, transition])

    const listenersRef = useRef(listeners)
    listenersRef.current = listeners

    return useDeepEqualsMemoValue({
        attributes,
        listeners: listenersRef.current,
        setNodeRef: composedRef,
        isDragging,
    })
}

import { useEffect } from 'react'

import aa from 'search-insights'

import { ALGOLIA_API_KEY, ALGOLIA_APP_ID } from 'app/settings'
import { useAppContext } from 'app/useAppContext'
import { useAppUserContext } from 'app/useAppUserContext'

import { getAlgoliaUserToken } from './insightEventsFunctions'

export function AlgoliaInsights() {
    const { workspaceAccount } = useAppContext()
    const { user } = useAppUserContext()

    useEffect(() => {
        if (workspaceAccount && user) {
            aa('init', {
                appId: ALGOLIA_APP_ID,
                apiKey: ALGOLIA_API_KEY,
                userToken: getAlgoliaUserToken(user._sid),
            })
        }
    }, [workspaceAccount, user])

    return null
}

import getFieldPerms from 'v2/views/utils/getFieldPerms'

export const getFieldsWithPerms = (
    record: RecordDto,
    object: ObjectDto | null | undefined,
    fields: ActionStepField[]
): ActionStepField[] => {
    const permissions = record._permissions
    const objectPermissions = object?._permissions ?? {}

    const [, allowedFieldNamesToUpdate] = getFieldPerms(permissions, objectPermissions)

    return fields.filter(({ fieldId, fieldName }) => {
        const field = object?.fields.find((x) => x._sid === fieldId)
        return allowedFieldNamesToUpdate.includes(fieldName) && field && !field.is_read_only
    })
}

import { mergeAttributes, Node } from '@tiptap/core'
import { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { CommandProps, Range } from '@tiptap/react'

export type TokenOptions = {
    HTMLAttributes: Record<string, any>
    renderLabel: (props: { options: TokenOptions; node: ProseMirrorNode }) => string
}

export type TokenExtensionSettings = {
    className?: string
    clearContent?: boolean
}

export const createTokenExtension = ({
    name,
    getSettings,
}: {
    name: string
    getSettings: () => TokenExtensionSettings
}) =>
    Node.create<TokenOptions>({
        name,

        addOptions() {
            const { className } = getSettings()
            return {
                HTMLAttributes: { class: className },
                renderLabel({ node }) {
                    return `${node.attrs.label ?? node.attrs.id}`
                },
            }
        },

        group: 'inline',

        inline: true,

        selectable: false,

        atom: true,

        addAttributes() {
            return {
                id: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-id'),
                    renderHTML: (attributes) => {
                        if (!attributes.id) {
                            return {}
                        }

                        return {
                            'data-id': attributes.id,
                        }
                    },
                },

                label: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-label'),
                    renderHTML: (attributes) => {
                        if (!attributes.label) {
                            return {}
                        }

                        return {
                            'data-label': attributes.label,
                        }
                    },
                },
                tag: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-tag'),
                    renderHTML: (attributes) => {
                        if (!attributes.tag) {
                            return {}
                        }

                        return {
                            'data-tag': attributes.tag,
                        }
                    },
                },
            }
        },

        parseHTML() {
            return [
                {
                    tag: `span[data-type="${this.name}"]`,
                },
            ]
        },

        renderHTML({ node, HTMLAttributes }) {
            return [
                'span',
                mergeAttributes(
                    { 'data-type': this.name },
                    this.options.HTMLAttributes,
                    HTMLAttributes
                ),
                this.options.renderLabel({
                    options: this.options,
                    node,
                }),
            ]
        },

        renderText({ node }) {
            return this.options.renderLabel({
                options: this.options,
                node,
            })
        },

        addKeyboardShortcuts() {
            return {
                Backspace: () =>
                    this.editor.commands.command(({ tr, state }) => {
                        let isToken = false
                        const { selection } = state
                        const { empty, anchor } = selection

                        if (!empty) {
                            return false
                        }

                        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                            if (node.type.name === this.name) {
                                isToken = true
                                tr.insertText('', pos, pos + node.nodeSize)

                                return false
                            }
                        })

                        return isToken
                    }),
            }
        },
        // @ts-ignore
        addCommands() {
            return {
                insertToken:
                    (attrs: { id: string; label: string }, range: Range) =>
                    ({ commands }: CommandProps) => {
                        const content = [
                            {
                                type: this.name,
                                attrs,
                            },
                        ]

                        const { clearContent } = getSettings()
                        if (clearContent) {
                            return commands.setContent(content, true)
                        }
                        return commands.insertContentAt(range, content)
                    },
            }
        },
    })

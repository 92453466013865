import * as DialogPrimitive from '@radix-ui/react-dialog'

import { Box } from 'ui/components/Box'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'ui/components/Tabs'
import { makeComponent } from 'ui/helpers/recipes'

import {
    ControlsActionContainerStyles,
    ControlsStyles,
    CoverImageStyles,
    CoverImageWrapperStyles,
    FooterButtonGroupStyles,
    FooterStyles,
    HeaderStyles,
    HeaderWrapperStyles,
    InnerContentStyles,
    MainContentStyles,
    OverlayStyles,
    SubtitleStyles,
    TabsContentStyles,
    TabsListStyles,
    TabsStyles,
    TitleStyles,
} from './Modal.css'

export const Root = DialogPrimitive.Root
export const Content = makeComponent(DialogPrimitive.Content, MainContentStyles)
export const Overlay = makeComponent(DialogPrimitive.Overlay, OverlayStyles)
export const Trigger = DialogPrimitive.Trigger
export const Close = DialogPrimitive.Close
export const Portal = DialogPrimitive.Portal
export const HeaderWrapper = makeComponent(Box, HeaderWrapperStyles)
export const Header = makeComponent(Box, HeaderStyles)
export const Title = makeComponent(DialogPrimitive.Title, TitleStyles)
export const Controls = makeComponent(Box, ControlsStyles)
export const ControlsActionContainer = makeComponent(Box, ControlsActionContainerStyles)
export const Subtitle = makeComponent(DialogPrimitive.Description, SubtitleStyles)
export const Footer = makeComponent(Box, FooterStyles)
export const FooterButtonGroup = makeComponent(Box, FooterButtonGroupStyles)
export const CoverImageWrapper = makeComponent(Box, CoverImageWrapperStyles)
export const CoverImage = makeComponent('img', CoverImageStyles)
export const InnerContent = makeComponent(Box, InnerContentStyles)

export const ModalTabs = makeComponent(Tabs, TabsStyles)
export const ModalTabsTrigger = TabsTrigger
export const ModalTabsList = makeComponent(TabsList, TabsListStyles)
export const ModalTabsContent = makeComponent(TabsContent, TabsContentStyles)

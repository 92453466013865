import React from 'react'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'

import { LayoutEditorSaveButton } from './LayoutEditorSaveButton'

type LayoutEditorViewPaneProps = {
    title: string
}

export const LayoutEditorViewPane: React.FC<LayoutEditorViewPaneProps> = ({ title, children }) => {
    return (
        <Box py="l" alignSelf="stretch" height="full">
            <Box flex flexDirection="column" px="l" alignSelf="stretch" height="full">
                <Box grow>
                    <Box mb="3xl">
                        <Headline size="2xs">{title}</Headline>
                    </Box>
                    {children}
                </Box>
                <LayoutEditorSaveButton />
            </Box>
        </Box>
    )
}

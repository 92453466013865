import React, { VFC } from 'react'

import useLDFlags from 'data/hooks/useLDFlags'
import { AppUsersModalContent } from 'features/users/app-users-modal/AppUsersModal'
import { UserModalContextProvider } from 'features/users/app-users-modal/UserModalContextProvider'
import { AppUsers } from 'features/users/AppUsers/AppUsers'
import { SlidingPaneFrame } from 'features/workspace/AdminSideTray/common/SlidingPaneFrame'

import { Flex } from 'v2/ui'

type ManageUsersSidePaneProps = {}
export const ManageUsersSidePane: VFC<ManageUsersSidePaneProps> = () => {
    const { flags } = useLDFlags()

    return (
        <SlidingPaneFrame title="Manage users" description="Manage who has access to this app.">
            {flags.appUsersV2 ? (
                <AppUsers />
            ) : (
                <Flex
                    p={4}
                    flexGrow={1}
                    overflowY="auto"
                    alignItems="stretch"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Flex width="100%" overflowY="auto" flex={1} mb={4}>
                        <UserModalContextProvider>
                            <AppUsersModalContent />
                        </UserModalContextProvider>
                    </Flex>
                </Flex>
            )}
        </SlidingPaneFrame>
    )
}

import { refetchAccounts } from 'data/hooks/accounts'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { Bundle } from 'features/AiAppBuilder/bundles/bundleBuilder'

export async function initializeAccount({
    bundle,
    bundleUrl,
}: {
    bundle?: Bundle
    bundleUrl?: string
}) {
    // If running this inside an existing workspace we'll hit the bundle import endpoint
    // and also have to refetchRoles(), refetchStacks() instead of accounts at the end
    const response = await fetchWithAuth('accounts/initialize-from-onboarding/', {
        method: 'POST',

        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            company_name: sessionStorage.getItem('onboardingCompanyName'),
            bundle: bundle ? JSON.stringify(bundle) : undefined,
            bundle_url: bundleUrl,
        }),
    })

    if (response.status !== 200) return

    const data = await response.json()
    console.log(data)
    await Promise.all([refetchAccounts()])

    return data
}

import React, { forwardRef } from 'react'

import type { DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu'

import { ItemVariants } from 'ui/components/Menu/Menu.css'
import { StandardComponentProps } from 'ui/helpers/styles'

import { Item, LeftSlot, RightSlot } from './Dropdown.parts'

export type SlotContentProps = {
    disabled?: boolean
}

type DropdownItemBaseRef = HTMLDivElement

type DropdownItemBaseProps = Omit<DropdownMenuItemProps, 'asChild'> &
    StandardComponentProps &
    ItemVariants & {
        leftSlotContent?: React.ElementType<SlotContentProps>
        rightSlotContent?: React.ElementType<SlotContentProps>
    }

export const DropdownItemBase = forwardRef<DropdownItemBaseRef, DropdownItemBaseProps>(
    (
        {
            leftSlotContent: LeftSlotContent,
            rightSlotContent: RightSlotContent,
            children,
            ...props
        },
        ref
    ) => {
        const slotProps = { disabled: props.disabled }

        return (
            <Item {...props} ref={ref}>
                {LeftSlotContent && (
                    <LeftSlot>
                        <LeftSlotContent {...slotProps} />
                    </LeftSlot>
                )}
                {children}
                {RightSlotContent && (
                    <RightSlot>
                        <RightSlotContent {...slotProps} />
                    </RightSlot>
                )}
            </Item>
        )
    }
)

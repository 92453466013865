type LinkedField = {
    label: string
    value: string
    objectId: string
    fieldId: string
    isExternalField: boolean
}

const filterLocalField = (field: FieldDto, targetObjectId?: string) => {
    return (
        field.type === 'multi_lookup' &&
        field.link_target_object_id === targetObjectId &&
        !field?.connection_options?.is_disabled
    )
}

const fieldToOption = (field: FieldDto, objectName: string, isExternalField: boolean) => ({
    label: `${objectName} → ${field.label}`,
    value: field._sid,
    objectId: field.object_id,
    fieldId: field._sid,
    isExternalField,
})

const filterForeignField = (field: FieldDto, localLinkedFields: LinkedField[], object: ObjectDto) =>
    ['lookup', 'multi_lookup'].includes(field.type) &&
    !field.connection_options?.simpleconn_symmetric_column_id &&
    field.link_target_object_id === object?._sid &&
    !localLinkedFields.find((local) => local.value === field._sid)

// This hook builds the list of available relationshp fields between our current object
// and the selected target object
export const useGetLinkedFields = (
    currentObject: ObjectDto,
    targetObject?: ObjectDto,
    filter?: (field: FieldDto) => boolean
): LinkedField[][] => {
    let localFields = currentObject?.fields ?? []
    if (filter) {
        localFields = localFields.filter(filter)
    }
    const localLinkedFields = localFields
        .filter((field) => filterLocalField(field, targetObject?._sid))
        .map((field) => fieldToOption(field, currentObject.name, false))

    let foreignFields = targetObject?.fields ?? []
    if (filter) {
        foreignFields = foreignFields.filter(filter)
    }
    const foreignLinkedFields = foreignFields
        .filter((field) => filterForeignField(field, localLinkedFields, currentObject))
        .map((field) => fieldToOption(field, targetObject?.name ?? '', true))
    return [localLinkedFields, foreignLinkedFields]
}

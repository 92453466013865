// @ts-strict-ignore
import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { InputEndActionSearch } from 'ui/components/Input/InputEndActionSearch'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useListHeaderSearchControlState } from './hooks/useListHeaderSearchControlState'
import { ListHeaderSearchInput } from './ListHeaderSearchInput'

type ListHeaderSearchControlProps = React.ComponentPropsWithoutRef<typeof Box> & {
    setShowRecordCount?: (showRecordCount: boolean) => void
    showLabel?: boolean
    variant?: 'default' | 'expanded'
}

export const ListHeaderSearchControl: React.FC<ListHeaderSearchControlProps> = ({
    setShowRecordCount,
    showLabel: providedShowLabel = true,
    variant = 'default',
    ...props
}) => {
    const {
        onSearchButtonClick,
        showInput,
        inputSearchQuery,
        setInputSearchQuery,
        onInputBlur,
        onInputFocus,
        onInputClear,
        allowSearch,
        inputRef,
    } = useListHeaderSearchControlState({ setShowRecordCount })

    const showLabel = useResponsiveValue({
        mobile: false,
        tablet: providedShowLabel,
    })

    if (!allowSearch) return null

    if (variant === 'expanded') {
        return (
            <Box {...props}>
                <Input
                    ref={inputRef}
                    size="m"
                    placeholder="Search..."
                    onChange={(e) => setInputSearchQuery(e.target.value)}
                    endAction={(endActionProps) => (
                        <InputEndActionSearch {...endActionProps} pointerEvents="none" />
                    )}
                />
            </Box>
        )
    }

    if (variant === 'default') {
        return (
            <Box {...props}>
                {showInput ? (
                    <ListHeaderSearchInput
                        autoFocus
                        value={inputSearchQuery}
                        onChange={setInputSearchQuery}
                        onBlur={onInputBlur}
                        onFocus={onInputFocus}
                        onClear={onInputClear}
                    />
                ) : (
                    <Button
                        variant="ghost"
                        size="s"
                        aria-label="Search records"
                        startIcon={{
                            name: 'Search',
                        }}
                        onClick={onSearchButtonClick}
                    >
                        {showLabel && 'Search'}
                    </Button>
                )}
            </Box>
        )
    }

    return null
}

import React from 'react'

import { Modal as ModalComponent } from 'v2/ui'
import { useModalToggle } from 'v2/ui/utils/useModalToggle'

import { CreateEmptyBlankPageModalContent } from './CreateEmptyBlankPageModalContent'
import { MODAL_KEY } from './emptyBlankPageModalConstants'

export const CreateEmptyBlankPageModal: React.FC<{}> = () => {
    const modal = useModalToggle(MODAL_KEY)
    const { isOpen, toggle } = modal

    return (
        <ModalComponent
            isOpen={isOpen}
            onClose={toggle}
            body={
                <CreateEmptyBlankPageModalContent
                    onConfirm={toggle}
                    isOpen={isOpen}
                    onClose={toggle}
                />
            }
            showCloseButton={false}
            headerVariant="smallCentered"
            bodyStyle={{ p: 0 }}
            size="28rem"
        />
    )
}

import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { FieldEditorPopover } from 'features/admin/fields/FieldEditorPopover'
import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'

import { DataGridHeaderComponent } from './types'

type AddNewHeaderProps = {
    onFieldCreated: (field: FieldDto) => void
}

export const AddNewHeader: DataGridHeaderComponent<AddNewHeaderProps> = ({
    context,
    onFieldCreated,
    eGridHeader,
}) => {
    const { gridApi } = useDataGridContext()

    const containerRef = useRef<HTMLDivElement>(null)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const [isFieldEditorOpen, setIsFieldEditorOpen] = useState(false)
    const onHeaderClick = useCallback(() => {
        setIsFieldEditorOpen((prev) => !prev)
    }, [])

    useEffect(() => {
        const hideFieldEditor = () => {
            setIsFieldEditorOpen(false)
        }

        gridApi?.addEventListener('bodyScroll', hideFieldEditor)
        gridApi?.addEventListener('gridSizeChanged', hideFieldEditor)

        return () => {
            if (gridApi?.isDestroyed()) return
            gridApi?.removeEventListener('bodyScroll', hideFieldEditor)
            gridApi?.removeEventListener('gridSizeChanged', hideFieldEditor)
        }
    })

    useLayoutEffect(() => {
        const handler = (e: KeyboardEvent) => {
            // Handle keyboard interaction with the column header.
            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                e.stopPropagation()
                onHeaderClick()
            }
        }

        eGridHeader.addEventListener('keydown', handler)

        return () => eGridHeader.removeEventListener('keydown', handler)
    }, [eGridHeader, onHeaderClick])

    return (
        <Box ref={containerRef} height="full" width="full" flex center>
            <Button
                ref={anchorRef}
                type="button"
                variant="ghost"
                aria-label="Add new column"
                width="full"
                height="full"
                color="textWeak"
                flex
                center
                justifyContent="center"
                style={{ borderRadius: 0 }}
                onClick={onHeaderClick}
            >
                <Icon name="Plus" size="s" />
            </Button>
            <FieldEditorPopover
                open={isFieldEditorOpen}
                placement="bottom-start"
                container={containerRef.current as HTMLElement}
                target={anchorRef.current as HTMLElement}
                objectId={context.object._sid}
                onSuccess={() => {}}
                onCreate={(newField) => {
                    setIsFieldEditorOpen(false)
                    onFieldCreated(newField)
                }}
                onCancel={() => {
                    setIsFieldEditorOpen(false)
                }}
                onClose={() => {
                    setIsFieldEditorOpen(false)
                }}
                adaptivePlacement={false}
            />
        </Box>
    )
}

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeTooltipVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surface: s.color.gray900,
            text: s.color.textInverse,
        },
        gap: {
            vertical: s.space.xs,
            horizontal: s.space.m,
        },
        radius: {
            content: s.radius.m,
        },
        size: {
            contentMaxWidth: pxToRem(350),
        },
        fontSizes: {
            content: s.fontSizes.bodyS,
        },
        fontWeights: {
            content: s.fontWeights.bodyMedium,
        },
        lineHeights: {
            content: s.lineHeights.bodyS,
        },
    }
}

import React from 'react'

import { useObject } from 'data/hooks/objects'
import { DEFAULT_ACTION_ICON } from 'features/workflows/common'
import { ItemIcon } from 'features/workflows/ItemIcon'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'

import { WorkflowActionNodeRendererComponent } from './types'

export const RecordChangeActionNode: WorkflowActionNodeRendererComponent = ({
    action,
    actionType,
    showType,
}) => {
    const { object } = useObject(action.settings?.object_id as string | undefined)

    return (
        <>
            <ItemIcon
                icon={actionType.icon || DEFAULT_ACTION_ICON}
                iconStyle={actionType.icon_style}
                iconUrl={action.icon?.url}
                mr="m"
            />

            <Box flex column alignItems="flex-start" fontWeight="bodyRegular">
                {showType && (
                    <Box fontSize="bodyXs" color="textWeakest">
                        Action
                    </Box>
                )}

                <Box flex center>
                    {action.name}
                    {object && (
                        <>
                            {' '}
                            in
                            <Container
                                as="span"
                                ml="s"
                                px="xs"
                                py="2xs"
                                borderRadius="s"
                                variant="accentMuted"
                            >
                                {object?.name}
                            </Container>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}

import React, { Component } from 'react'
import { DragSource } from 'react-dnd'

import types from './types'

const blockSource = {
    beginDrag(props) {
        console.log('[ADD BLOCK DRAGGABLE] Begin drag' + props)
        // Return the data describing the dragged item
        const payload = props.payload
        if (props.onDragStart) props.onDragStart(payload)
        return payload
    },

    endDrag(props, monitor) {
        console.log('[ADD BLOCK DRAGGABLE] End drag ')
        if (!monitor.didDrop()) {
            return
        }

        if (props.onDragEnd) props.onDragEnd()

        // When dropped on a compatible target, do something
        const item = monitor.getItem()
        const dropResult = monitor.getDropResult()
        console.log('DROP', item, props.payload, dropResult)
        props.onDrop &&
            dropResult &&
            props.onDrop({ dragPayload: props.payload, dropPayload: dropResult })
    },
}

/**
 * Specifies which props to inject into your component.
 */
function collectDrag(connect, monitor) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        // You can ask the monitor about the current drag state:
        isDragging: monitor.isDragging(),
    }
}

class InnerAddBlockDraggable extends Component {
    render() {
        const props = this.props
        // These two props are injected by React DnD,
        // as defined by your `collect` function above:
        const { isDragging, connectDragSource } = props
        // const img = getEmptyImage()
        // connectDragPreview(img)
        const payload = {
            isDragging: isDragging,
        }
        let children = props.children(payload)
        children = typeof children == 'function' ? <div>Hello</div> : children
        return connectDragSource(children)
    }
}

export const AddBlockDraggable = DragSource(
    types.BLOCK,
    blockSource,
    collectDrag
)(InnerAddBlockDraggable)

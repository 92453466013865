import React from 'react'

import { invalidateDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import { useDeleteDataConnection } from 'data/hooks/dataConnections/useDeleteDataConnection'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownSeparator,
} from 'ui/components/Dropdown'
import { useToast } from 'ui/components/Toast'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

type DataConnectionOptionsProps = {
    dataConnection: DataConnectionDto
}
export const DataConnectionOptions: React.FC<DataConnectionOptionsProps> = ({ dataConnection }) => {
    const toast = useToast()

    const { mutateAsync: deleteDataConnection } = useDeleteDataConnection({
        onError: () => {
            toast({
                title: 'There was a problem deleting the data connection. Please try again later.',
                type: 'error',
            })
        },
    })

    return (
        <Box
            style={{
                paddingRight: theme.space['6xl'],
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Dropdown>
                <DropdownButton
                    size="s"
                    aria-label="More actions"
                    variant="ghost"
                    startIcon={{ name: 'MoreHorizontal' }}
                />
                <DropdownContent
                    onClick={stopPropagation}
                    align="end"
                    style={{ minWidth: '250px' }}
                >
                    <DropdownItem
                        startIcon={{ name: 'RefreshCcw' }}
                        label="Force full re-sync"
                        // TODO-Nango implement full resync
                        onClick={() => console.log('full re-sync triggered')}
                    />
                    <DropdownSeparator />
                    <DropdownItem
                        variant="destructive"
                        startIcon={{ name: 'Trash' }}
                        label="Remove"
                        onClick={async () => {
                            await deleteDataConnection(dataConnection._sid)
                            invalidateDataConnections()
                        }}
                    />
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}

import EmptyStyles from 'ui/helpers/emptyStyles.css'
import { makeComponent } from 'ui/helpers/recipes'

export { Root } from './Primitives'
import { Input as PInput, Item as PItem, Menu as PMenu } from './Primitives'

import { GroupTitleStyle, ItemStyle, MenuStyle } from './ComboboxStyles.css'

export const Input = makeComponent(PInput, EmptyStyles)
export const Menu = makeComponent(PMenu, MenuStyle)
export const Item = makeComponent(PItem, ItemStyle)
export const GroupTitle = makeComponent('div', GroupTitleStyle)

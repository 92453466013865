import React from 'react'

import { useTheme } from '@chakra-ui/react'

import { useExtDataIntegrations } from 'data/hooks/dataConnections/useExtDataIntegrations'
import { INTEGRATION_ID_TO_NAME } from 'features/DataConnections/constants'

import { Icon } from 'v2/ui'
import { Airtable } from 'v2/ui/svgs'

import { Box } from 'ui/components/Box'
import { DropdownItem } from 'ui/components/Dropdown'
import { Select, SelectOptionComplex } from 'ui/components/Select'

type ExternalAccountSelectProps = React.ComponentPropsWithoutRef<typeof Select> & {
    externalIntegrationId: ExternalIntegrationId
    onAddExternalAccount: () => void
}
export const ExternalAccountSelect: React.FC<ExternalAccountSelectProps> = ({
    externalIntegrationId,
    value,
    onChange,
    onAddExternalAccount,
    ...props
}) => {
    const theme = useTheme()

    const { data: integrationsData, isLoading: _isLoading } = useExtDataIntegrations()
    const extConnections = integrationsData?.connections.filter(
        (connection) => connection.provider_id === externalIntegrationId
    )

    const IntegrationIcon = () => (
        <Box
            style={{
                backgroundColor: theme.colors.grey[100],
                padding: '8px',
                borderRadius: '6px',
            }}
        >
            <Icon height="16px" width="16px" style={{}} svg={<Airtable />} />
        </Box>
    )
    return (
        <Select
            placeholder="Select or connect account"
            onChange={onChange}
            value={value}
            {...props}
            isLoading={true}
        >
            {extConnections?.map((extConnection) => (
                <SelectOptionComplex
                    key={extConnection.id}
                    label={INTEGRATION_ID_TO_NAME[externalIntegrationId]}
                    leftSlotContent={() => <IntegrationIcon />}
                    value={extConnection.id}
                    subtitle={extConnection.external_user_email}
                />
            ))}
            <DropdownItem
                label={`Connect ${INTEGRATION_ID_TO_NAME[externalIntegrationId]} account`}
                startIcon={{ name: 'Plus' }}
                onClick={onAddExternalAccount}
                variant="action"
            />
        </Select>
    )
}

import React from 'react'

import { Button, ButtonProps } from 'ui/components/Button'
import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

export type ConfirmationModalProps = {
    isOpen: boolean
    title: string
    subtitle: React.ReactNode
    onConfirm: () => void
    onCancel: () => void
    customModalContent?: React.ReactNode
    confirmButtonText?: string
    cancelButtonText?: string
    confirmButtonProps?: ButtonProps
    cancelButtonProps?: ButtonProps
    errorMessage?: string
}

export const ConfirmationModal = ({
    isOpen,
    title,
    subtitle,
    onConfirm,
    onCancel,
    customModalContent,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    confirmButtonProps = {},
    cancelButtonProps = {},
    errorMessage,
}: ConfirmationModalProps) => {
    return (
        <Modal open={isOpen}>
            <ModalContent>
                <ModalHeader title={title} subtitle={subtitle} showCloseButton={false} />
                {customModalContent}
                <ModalFooter>
                    <ModalFooterButtonGroup>
                        <Button variant="primary" onClick={onConfirm} {...confirmButtonProps}>
                            {confirmButtonText}
                        </Button>
                        <Button variant="secondary" onClick={onCancel} {...cancelButtonProps}>
                            {cancelButtonText}
                        </Button>
                    </ModalFooterButtonGroup>
                    {errorMessage && (
                        <Body color="textError" size="m">
                            {errorMessage}
                        </Body>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'

import { ButtonStyles, ButtonVariants } from 'ui/components/Button/Button.css'
import { Icon } from 'ui/components/Icon'
import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { makeComponent } from 'ui/helpers/recipes'
import { StandardComponentProps } from 'ui/helpers/styles'

type IconProps = IconNameVariantType

export type LinkButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
    StandardComponentProps &
    ButtonVariants & {
        startIcon?: IconProps
        to?: React.ComponentPropsWithoutRef<typeof Link>['to']
        href?: string
        iconProps?: Partial<React.ComponentPropsWithoutRef<typeof Icon>>
        disabled?: boolean
        replace?: boolean
    }
export const AnchorBase = makeComponent('a', ButtonStyles)
export const LinkBase = makeComponent(Link, ButtonStyles)

export const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(function Button(
    { children, startIcon, to, href, iconProps, ...props },
    ref
) {
    const Component = (to ? LinkBase : AnchorBase) as React.ElementType<
        | React.ComponentPropsWithRef<typeof LinkBase>
        | React.ComponentPropsWithRef<typeof AnchorBase>
    >
    return (
        <Component to={to!} href={href} {...props} ref={ref}>
            {startIcon && <Icon {...startIcon} noShrink {...iconProps} />}
            {children}
        </Component>
    )
})

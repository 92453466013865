/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable simple-import-sort/imports -- Disabled to avoid circular dependencies, remove when file is removed from .freezircularDeps */

import React, { Component } from 'react'
import { jsx } from '@emotion/react'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { DropZone } from 'features/pages/editor/dnd/DropZone'
import BlockVisibility from './display/BlockVisibility'
import { evaluateFormulas } from './settings/formulas'
import { getInnerStyle, getOuterStyle } from './styles/splitStyles'
import { getBlockByType } from 'v2/blocks/blockTypes'
import { BlockLoader } from 'v2/blocks/BlockLoader'

class UnRoutedBlock extends Component {
    /*

        Block takes the following props:

            - Block definition, the JSON of the block
            - Block context
                - Static context (i.e. stuff from the page, theme)
                - Shared block state (i.e. other blocks' properties/methods)
                - Callback for setting its own properties/methods, and for registering for updates to new ones
            - Editor functionality (e.g. methods for selecting, moving block etc.)

        It then renders in two separate bits

            +------WRAPPER-----+
            |                  |
            |  BLOCK CONTENTS  |
            |                  |
            +------------------+


        The wrapper handles

            - Creating the div the block sits in
            - Taking the "outer styles" that apply to that div and applying them
            - Allowing that to be selectable/draggable/droppable
            - Displaying the block's name + controls when selected

        The block contents are rendered based on the block's type, and are passed:

            - The original block definition
            - The block's context
            - Editor functionality
            - Utility methods from this file, including
                - Evaluate formuals
                - Run actions

        * * *

        As well as that, the block inspects the attributes and styles from the block's definition,
        and subscribes to the shared block state for any shared block state in merge tags

        It also renders the block's style, attribute and actions update panels, rendered into
        a portal in the editor


    */

    state = {}

    render() {
        // console.count("[BLOCK] Render")
        const block = this.renderBlock()
        return this.renderWrapper(block)
    }

    renderBlock = () => {
        // Here we get the block's element by its type,
        // build out the utility props we will pass down to it
        // and  render it
        const { block, context } = this.props
        const blockType = this.getBlockType()
        const BlockTypeClass = blockType.blockElement
        const { innerStyles, outerStyles } = this.getStyles()
        const updateAttributes = (key, value) =>
            context.editor.actions.updateAttributes(block.id, key, value)

        let computedStyle = innerStyles
        // If this is a modal-type block, the block itself handles its
        // inner and outer styles
        if (BlockTypeClass.isModalType) {
            computedStyle = { ...innerStyles, ...outerStyles }
        }

        // Build the utility props
        const extraProps = {
            attrs: block.config.attributes,
            computedStyle,
            evaluateFormulas: evaluateFormulas(this.props.context),
            ChildBlocks: this.ChildBlocks,
            updateAttributes,
            selected: this.isSelected(),
            selectBlock: () => context.editor.actions.selectBlock(block.id),
            DropZone: ({ children }) => (
                <DropZone payload={{ id: this.props.block.id }}>{children}</DropZone>
            ),
        }

        return <BlockTypeClass block={block} context={context} {...extraProps} />
    }

    renderWrapper = (children) => {
        /*
           This wrapper is the part that handles laying out the block on the page,
           and selecting/dragging and dropping etc. when editing
           It also conditionally doesn't display a block based on the block visibility
        */
        const { block, context } = this.props
        const { innerStyles, outerStyles } = this.getStyles()
        const blockTypeClass = this.getBlockType().blockElement

        let styles = outerStyles
        if (block.type === 'container') {
            // Seems to be a necessary hack to keep compatibility with the old engine
            const direction = this.getContentsDirection()
            styles = {
                flexDirection: direction,
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                display: 'flex',
                ...outerStyles,
                ...innerStyles,
            }
        }
        if (block.type === 'gridcard') {
            // This allows the card to grow within its wrapper, even if there's only a min-height set.
            styles = {
                flexDirection: 'column',
                display: 'flex',
                ...outerStyles,
                ...innerStyles,
            }
        }
        if (block.type === 'view') {
            styles = {
                flexDirection: 'column',
                display: 'flex',
                ...outerStyles,
                ...innerStyles,
            }
        }

        if (block.type === 'attribute') {
            // Under certain circumstances (e.g. disbled fields) we don't want to show
            // fields on the layout
            let fieldId = block.config.attributes.fieldId

            if (context.isFieldDisabled && context.isFieldDisabled(fieldId)) {
                styles = {
                    display: 'none',
                    ...outerStyles,
                    ...innerStyles,
                }
            }
        }

        // If it's a modal type block then we only render a small placeholder most of the time
        if (blockTypeClass.isModalType) styles = {}

        const selectBlock = (e) => {
            if (block.config.locked) return
            context.editor.actions.selectBlock(block.id)
            e.stopPropagation()
        }
        const isSelected = this.isSelected()

        const isLoading = context.view?.isLoading

        return (
            <>
                <BlockVisibility block={block} context={context}>
                    <BlockLoader block={block} isLoading={isLoading}>
                        <div
                            className={cx('block', `block-${block.id}`, `${block.type}-block`, {
                                isSelected,
                                isOver: false,
                                isDragging: false,
                                inRow: context.direction === 'row',
                                inColumn: context.direction === 'column',
                            })}
                            style={styles}
                            onClick={context.editor.isEditing ? selectBlock : null}
                            onMouseOver={($event) => {
                                if (context.editor.isEditing && !block.config.locked) {
                                    context.editor.actions.onHover(block.id)
                                    return $event.stopPropagation()
                                }
                            }}
                            aria-live="polite"
                            aria-busy={isLoading}
                        >
                            {children}
                        </div>
                    </BlockLoader>
                </BlockVisibility>
            </>
        )
    }

    // <Draggable
    //                     key={childBlock.id || index}
    //                     id={childBlock.id}
    //                     position={index}
    //                     onDrop={this.props.context.editor.actions.onDrop}
    //                 >
    //                     {({ getRootProps, isDragging }) => (

    isSelected = () => {
        return this.props.context.editor.selected === this.props.block.id
    }

    getBlockType = () => {
        const { block } = this.props
        return getBlockByType(block.type)
    }

    ChildBlocks = ({ blocks, extraContext }) => {
        /*
            This is a utility component that lets a block render its children in the right place
        */
        extraContext = extraContext || {}
        const direction = this.getContentsDirection()
        const childBlocks = blocks || this.props.block.childBlocks

        if (!childBlocks || !childBlocks.length) {
            return <DropZone payload={{ id: this.props.block.id }} />
        }

        return (
            <>
                {(childBlocks || []).map((childBlock, index) => (
                    <Block
                        position={index}
                        key={childBlock.id}
                        block={childBlock}
                        context={{
                            ...this.props.context,
                            direction,
                            ...extraContext,
                        }}
                    />
                ))}
            </>
        )
    }

    getStyles = () => {
        /*
            Compute the inner and outer styles for use by the block content and wrapper, respectively
        */
        const { block, context } = this.props
        const evalFormulas = evaluateFormulas(context)
        const blockType = this.getBlockType()
        const innerStyles = getInnerStyle(
            // Work out the style that can be used within the block
            block.config.style.extraStyles,
            block.config.style,
            context.deviceType,
            block.config.attributes.direction || context.direction,
            blockType.blockElement.childStyles
        )
        const outerStyles = getOuterStyle(
            // Work out which styles are used for the wrapper
            block.config.style.extraStyles,
            block.config.style,
            context.deviceType,
            block.config.attributes.direction || context.direction,
            blockType.blockElement.childStyles
        )

        // Allow a block to set its own grid width
        if (block.config.attributes.fullWidth) {
            outerStyles.gridColumn = '1 / -1'
        }

        return {
            innerStyles: evalFormulas(innerStyles),
            outerStyles: evalFormulas(outerStyles),
        }
    }

    getContentsDirection = () => {
        const { context, block } = this.props
        const { innerStyles } = this.getStyles()
        return innerStyles.flexDirection || block.config.attributes.direction || context.direction
    }

    subscribeToPropertyStore = () => {
        // Find any time we use a global property and subscribe to the store with it
        const vars = JSON.stringify(this.props.block.config)
        const re = /block\.[a-z0-9_]+\.property\.[a-z0-9_]+/g
        const matches = vars.match(re)
        if (matches) {
            const subscriptions = matches.map((match) => match.slice(6)) // Remove "block."
            this.props.context.propertyStore.subscribe(this.props.block.id, subscriptions, () => {
                // console.count(`[BLOCK] ${this.props.block.id} Updated because of subscriptions`)
                // console.log("[BLOCK] Property store right now", this.props.context.propertyStore)
                this.forceUpdate()
            })
        }
    }

    unsubscribeFromPropertyStore = () => {
        // Find any time we use a global property and subscribe to the store with it

        this.props.context.propertyStore.unsubscribe(this.props.block.id)
    }

    UNSAFE_componentWillReceiveProps = () => {
        // console.count(
        //     `[BLOCK] ${this.props.block.id} Updating because of parent ${
        //         this.props.isOuter ? "Outer" : "x"
        //     }`
        // )
    }

    componentDidMount() {
        // console.count(
        //     `[BLOCK] ${this.props.block.id} Initial render ${this.props.isOuter ? "Outer" : "x"}`
        // )
        this.subscribeToPropertyStore()
    }

    componentWillUnmount() {
        this.unsubscribeFromPropertyStore()
    }

    shouldComponentUpdate(nextProps) {
        // If we're editing, then we're very happy to render
        if (nextProps.context.editor.isEditing) return true
        // Only update on meaningful changes. Sound expensive, but it prevents a lot of recursive checking!
        // We want to compare the contexts without the property store
        // eslint-disable-next-line unused-imports/no-unused-vars
        let { propertyStore: junk0, ...newContext } = nextProps.context
        // eslint-disable-next-line unused-imports/no-unused-vars
        let { propertyStore: junk1, ...oldContext } = this.props.context
        return (
            JSON.stringify(nextProps.block) !== JSON.stringify(this.props.block) ||
            JSON.stringify(newContext) !== JSON.stringify(oldContext)
        )
    }
}

export const Block = withRouter(UnRoutedBlock)

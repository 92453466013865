import React, { memo, useMemo } from 'react'
import {
    Draggable,
    DraggableStateSnapshot,
    DraggingStyle,
    NotDraggingStyle,
} from 'react-beautiful-dnd'

import { HStack, Spinner } from '@chakra-ui/react'
import { css, keyframes } from '@emotion/react'
import get from 'lodash/get'
import { OpenRecordFunction, ViewCellBaseProps } from 'v2/views/List/types'

import EditableFieldWrapper from 'features/admin/edit-mode/ListView/EditableListFieldWrapper'
import { ListRecordWrapper } from 'features/admin/edit-mode/ListView/ListRecordWrapper'
import { isRecordPending } from 'features/datagrid/hooks/isRecordPending'
import { RecordStatsIcons } from 'features/records/components/RecordStatsIcons'
import { useRecordEditManagerContext } from 'features/records/useRecordEditManagerContext'

import { BackgroundImage, Box, ConditionalWrapper, Container, Flex, Link } from 'v2/ui'
import { getDefaultDisplayType } from 'v2/ui/components/Attribute/attributeDisplayOptions'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { HoverContainerStyle } from 'ui/styles/hoverUtils.css'

type KanbanCardProps = {
    colIndex: number
    rowIndex: number
    rowLink?: (row: any) => string
    label: string
    cells: ViewCellBaseProps[]
    coverImage?: { url: string | null; fitImage: 'contain' | 'cover' } | false
    props: { [keyof: string]: any }
    row: { [keyof: string]: any }
    justDropped: boolean
    field: FieldDto
    viewOptions: ListViewOptions
    setConfig?: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    object?: ObjectDto
    isFirstCard: boolean
    isContainerHovered: boolean
    openRecord?: OpenRecordFunction
}

export const KanbanCard = memo(
    ({
        colIndex,
        rowIndex,
        rowLink,
        label,
        cells,
        coverImage,
        props,
        row,
        justDropped,
        field,
        viewOptions,
        setConfig,
        object,
        isFirstCard,
        isContainerHovered,
        openRecord,
    }: KanbanCardProps): JSX.Element => {
        const link = rowLink ? rowLink(row) : ''

        const recordManager = useRecordEditManagerContext()
        const isPending = useMemo(
            () => isRecordPending(row.original, recordManager),
            [row.original, recordManager]
        )

        const isDraggable = useMemo(
            () => canUpdateStatus(row.original, field) || isPending,
            [row.original, field, isPending]
        )

        return (
            <Draggable
                draggableId={row.original._sid}
                index={rowIndex}
                isDragDisabled={!isDraggable}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        data-recordid={row.original._sid}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getCardStyle(provided.draggableProps.style, snapshot)}
                    >
                        <ConditionalWrapper
                            condition={!!link}
                            wrapper={(children) => (
                                <Link
                                    simplifiedComponent
                                    className={
                                        rowIndex === 0 && colIndex === 0
                                            ? ONBOARDING_CLASSES.TABLE_ITEM
                                            : ''
                                    }
                                    href={link}
                                    disabled={isPending}
                                    onClick={(e: React.MouseEvent) => {
                                        if (!e.ctrlKey && !e.metaKey && openRecord) {
                                            openRecord(row.original._sid)

                                            e.preventDefault()
                                            e.stopPropagation()
                                        }
                                    }}
                                >
                                    {children}
                                </Link>
                            )}
                        >
                            <ListRecordWrapper
                                recordId={row.original._sid}
                                key={row.original._sid}
                                h="100%"
                                highlight={isContainerHovered}
                            >
                                {({ isActive, lastHoveredRecordId }) => {
                                    const showEditControls =
                                        isActive || (isFirstCard && !lastHoveredRecordId)
                                    return (
                                        <Container
                                            position="relative"
                                            title={label}
                                            mb={2}
                                            {...props}
                                            css={justDropped ? cssAnimation : null}
                                            className={HoverContainerStyle}
                                        >
                                            <Box
                                                p="container.padding"
                                                display="flex"
                                                flexWrap="wrap"
                                                className="kanban-card-field-text"
                                            >
                                                {cells.map(
                                                    (
                                                        { label, value, column, originalValue },
                                                        index
                                                    ) => {
                                                        const displayType =
                                                            column.renderOptions?.displayType ||
                                                            getDefaultDisplayType(
                                                                column.field,
                                                                'kanban'
                                                            )
                                                        const displayLabel =
                                                            column.renderOptions &&
                                                            (column.renderOptions.displayAsButton ||
                                                                (!column.renderOptions.hideLabel &&
                                                                    !column.renderOptions
                                                                        .treatAsUrl &&
                                                                    displayType !== 'pill'))
                                                        const fullWidth = displayType === 'normal'
                                                        if (!originalValue) return null
                                                        return (
                                                            <React.Fragment key={column.id}>
                                                                {index === 0 && (
                                                                    <ConditionalWrapper
                                                                        condition={!!coverImage}
                                                                        wrapper={(children) => (
                                                                            <Flex
                                                                                wrap="nowrap"
                                                                                alignItems="center"
                                                                                width="100%"
                                                                            >
                                                                                {children}
                                                                            </Flex>
                                                                        )}
                                                                    >
                                                                        {index === 0 &&
                                                                            coverImage &&
                                                                            coverImage.url && (
                                                                                <BackgroundImage
                                                                                    alignSelf="start"
                                                                                    src={get(
                                                                                        coverImage,
                                                                                        'url'
                                                                                    )}
                                                                                    size={[
                                                                                        'kanban.coverImage',
                                                                                        null,
                                                                                        null,
                                                                                        'kanban.coverImageLg',
                                                                                    ]}
                                                                                    borderRadius="full"
                                                                                    flexShrink={0}
                                                                                    mr="table.rowLg"
                                                                                />
                                                                            )}
                                                                        <h2
                                                                            className="kanban-record-label"
                                                                            style={{
                                                                                margin: '-8px -6px',
                                                                                padding: '6px',
                                                                                borderRadius: '6px',
                                                                                marginBottom: '0',
                                                                                position:
                                                                                    'relative',
                                                                                display: 'grid',
                                                                                overflow: 'hidden',
                                                                                width: '100%',
                                                                                fontSize:
                                                                                    '0.875rem',
                                                                            }}
                                                                        >
                                                                            <EditableFieldWrapper
                                                                                position="horizontal"
                                                                                fieldId={
                                                                                    column.renderOptions &&
                                                                                    column
                                                                                        ?.renderOptions
                                                                                        .fieldId
                                                                                }
                                                                                objectId={
                                                                                    object &&
                                                                                    object._sid
                                                                                }
                                                                                viewOptions={
                                                                                    viewOptions
                                                                                }
                                                                                setConfig={
                                                                                    setConfig
                                                                                }
                                                                                hideLabelOptions
                                                                                showControls={
                                                                                    showEditControls
                                                                                        ? 'default'
                                                                                        : 'never'
                                                                                }
                                                                            >
                                                                                <Box
                                                                                    display="flex"
                                                                                    justifyContent="space-between"
                                                                                    alignItems="center"
                                                                                    pt={3}
                                                                                    pb={1}
                                                                                >
                                                                                    <HStack
                                                                                        spacing={2}
                                                                                    >
                                                                                        {isPending && (
                                                                                            <Spinner
                                                                                                size="xs"
                                                                                                color="userInterface.neutral.600"
                                                                                            />
                                                                                        )}
                                                                                        <Box>
                                                                                            {value}
                                                                                        </Box>
                                                                                    </HStack>
                                                                                </Box>
                                                                            </EditableFieldWrapper>
                                                                        </h2>
                                                                    </ConditionalWrapper>
                                                                )}
                                                                {index > 0 && (
                                                                    <div
                                                                        style={{ paddingBottom: 4 }}
                                                                    >
                                                                        <h3
                                                                            className="kanban-card-label"
                                                                            style={{
                                                                                width: fullWidth
                                                                                    ? '100%'
                                                                                    : undefined,

                                                                                display:
                                                                                    !displayLabel
                                                                                        ? 'none'
                                                                                        : undefined,
                                                                            }}
                                                                        >
                                                                            <EditableFieldWrapper
                                                                                position="horizontal"
                                                                                fieldId={
                                                                                    column.renderOptions &&
                                                                                    column
                                                                                        .renderOptions
                                                                                        .fieldId
                                                                                }
                                                                                objectId={
                                                                                    object &&
                                                                                    object._sid
                                                                                }
                                                                                viewOptions={
                                                                                    viewOptions
                                                                                }
                                                                                setConfig={
                                                                                    setConfig
                                                                                }
                                                                                showControls={
                                                                                    showEditControls
                                                                                        ? 'default'
                                                                                        : 'never'
                                                                                }
                                                                            >
                                                                                {displayLabel &&
                                                                                    label}
                                                                            </EditableFieldWrapper>
                                                                        </h3>
                                                                        {fullWidth ? (
                                                                            <div
                                                                                className="kanban-card-field-text"
                                                                                style={{
                                                                                    wordBreak:
                                                                                        'break-word',
                                                                                    width: '100%',
                                                                                    paddingBottom:
                                                                                        '8px',
                                                                                }}
                                                                            >
                                                                                {value}
                                                                            </div>
                                                                        ) : (
                                                                            value
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    }
                                                )}
                                            </Box>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    marginRight: '8px',
                                                    paddingBottom: '8px',
                                                }}
                                            >
                                                <RecordStatsIcons
                                                    record={row.original}
                                                    object={object}
                                                />
                                            </div>
                                        </Container>
                                    )
                                }}
                            </ListRecordWrapper>
                        </ConditionalWrapper>
                    </div>
                )}
            </Draggable>
        )
    }
)

/** this disabled the default dnd drop animation.
 * we don't want it in our case because we're not
 * animating into a specific drop index at this time.
 * if we add support for ordering, then we will remove this
 */
const getCardStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot
) => {
    if (!snapshot.isDropAnimating) {
        return style
    }
    return {
        ...style,
        transitionDuration: `0.001s`,
    }
}

const droppedAnimation = keyframes`
  0% {
    background-color: rgb(200,255,200);
  }
  100% {
    background-color: white;
  }
`
const cssAnimation = css`
    animation: ${droppedAnimation} 2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
`
const canUpdateStatus = (record: Partial<RecordDto>, field: FieldDto) => {
    const { may_update_fields, may_update } = record._permissions || {}
    return may_update && may_update_fields.includes(field.api_name)
}

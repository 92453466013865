import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { isDefined } from 'data/utils/ts_utils'
import { getSearchTerms } from 'features/Search/helpers'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { extractStandardProps, StandardComponentProps } from 'ui/helpers/styles'

import * as Parts from './Combobox.parts'
import { ComboboxContext, Item } from './ComboboxContext'
import { ComboboxInput } from './ComboboxInput'
import { ComboboxList } from './ComboboxList'
import { ItemProvider, useComboboxProviders } from './useComboboxProviders'

type RenderOptions = {
    renderItem?: (item: Item) => React.ReactNode
    labelField?: string
}
type ComboboxProps = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.Root>,
    'inputValue' | 'onInputValueChange'
> &
    RenderOptions &
    StandardComponentProps & {
        providers?: ItemProvider<any>[]
        onInputValueChange?: (value: string) => void
        menuProps?: React.ComponentPropsWithoutRef<typeof Parts.Menu>
        autoFocus?: boolean
        showDropdownIndicator?: boolean
        placeholder?: string
        style?: React.CSSProperties
    }

export function Combobox({
    renderItem,
    labelField,
    items: suppliedItems, // can supply a list of items directly
    providers: suppliedProviders, // and/or a list of item providers
    onInputValueChange,
    menuProps,
    autoFocus,
    showDropdownIndicator,
    placeholder,
    style,
    ...props
}: ComboboxProps) {
    const [standardProps, rootProps] = extractStandardProps(props)
    const [inputValue, setInputValue] = useState(props.defaultInputValue ?? '')
    const [queryTerms, setQueryTerms] = useState<string[]>([])

    const effectiveInputValue = inputValue

    useEffect(() => {
        setQueryTerms(getSearchTerms(effectiveInputValue || ''))
    }, [effectiveInputValue])

    // wrap any supplied items in a simple provider object
    const suppliedItemsProvider = useSimpleProvider({
        id: 'suuplied-items',
        items: suppliedItems,
        renderItem,
        labelField,
    })

    const providers = [suppliedItemsProvider, ...(suppliedProviders || [])].filter(isDefined)
    const { items, collections, showMore, isLoading } = useComboboxProviders({
        query: effectiveInputValue,
        queryTerms,
        providers,
    })

    const suppliedItemToString = props.itemToString
    const itemToString = useCallback(
        (item: Item | null) => {
            if (suppliedItemToString) {
                return suppliedItemToString(item)
            }

            return defaultItemToString(item, labelField)
        },
        [labelField, suppliedItemToString]
    )

    const handleInputValueChange = useCallback(
        ({ inputValue }) => {
            setInputValue(inputValue)
            onInputValueChange?.(inputValue)
        },
        [onInputValueChange]
    )

    return (
        <Parts.Root
            {...rootProps}
            items={items}
            onInputValueChange={handleInputValueChange}
            itemToString={itemToString}
        >
            <ComboboxContext.Consumer>
                {(context) => (
                    <Box position="relative" cursor="pointer" {...standardProps} style={style}>
                        <ComboboxInput autoFocus={autoFocus} placeholder={placeholder} />
                        {showDropdownIndicator && (
                            <Icon
                                // TODO: fix typing
                                // @ts-expect-error
                                name="chevron-down"
                                position="absolute"
                                top={0}
                                bottom={0}
                                // TODO: fix typing
                                // @ts-expect-error
                                right={2}
                                height={4}
                                // TODO: fix typing
                                // @ts-expect-error
                                my="auto"
                                pointerEvents="none"
                            />
                        )}
                        {items?.length > 0 && (
                            <Parts.Menu
                                position="absolute"
                                width="full"
                                data-state={context.isOpen ? 'open' : 'closed'}
                                {...menuProps}
                            >
                                <ComboboxList
                                    collections={collections}
                                    queryTerms={queryTerms}
                                    showMore={showMore}
                                    isLoading={isLoading}
                                />
                            </Parts.Menu>
                        )}
                    </Box>
                )}
            </ComboboxContext.Consumer>
        </Parts.Root>
    )
}

function defaultItemToString(item: Item | null, labelField?: string) {
    if (typeof item === 'string') {
        return item
    }

    if (item && typeof item === 'object') {
        if (labelField) {
            return item[labelField] as string
        } else {
            return Object.values(item)?.[0] as string
        }
    }

    return ''
}

function useSimpleProvider({
    id,
    items,
    renderItem,
    labelField,
}: RenderOptions & { id: string; items: Item[] }): ItemProvider<any> | undefined {
    return useMemo(
        () =>
            items
                ? {
                      id,
                      getItems: () => Promise.resolve({ items }),
                      renderItem: (props: { item: Item }) => {
                          const { item } = props
                          return renderItem?.({ item }) ?? defaultItemToString(item, labelField)
                      },
                  }
                : undefined,
        [id, items, renderItem, labelField]
    )
}

import React, { useCallback, useMemo, useReducer, useRef } from 'react'

import { DataGridCellProvider } from 'features/datagrid/types'
import { TipTapDocumentEditor } from 'features/tiptap/TipTapDocumentEditor'
import { TipTapEditorHandle } from 'features/tiptap/TipTapEditor'

import { TipTapValue } from 'v2/ui/components/Attribute/DocumentAttribute'

import { DataGridContext } from './DataGridContext'
import { dataGridStateForContextReducer, defaultState } from './dataGridContextReducer'

type DataGridContextProviderProps = React.PropsWithChildren<{
    cellDataProvider: DataGridCellProvider
    canAddRecords: boolean
    canDeleteRecords: boolean
    canEditRecords: boolean
    canRestoreRecords: boolean
    isTrashMode: boolean
    pageIndex: number
    setPageIndex: (pageIndex: number) => void
    pageSize: number
}>
export const DataGridContextProvider: React.FC<DataGridContextProviderProps> = ({
    cellDataProvider,
    canAddRecords,
    canDeleteRecords,
    canEditRecords,
    canRestoreRecords,
    isTrashMode,
    pageIndex,
    setPageIndex,
    pageSize,
    children,
}) => {
    const [state, dispatch] = useReducer(dataGridStateForContextReducer, defaultState)

    const tiptapRef = useRef<TipTapEditorHandle | null>(null)

    const getDocumentFieldHTMLContent = useCallback((content: TipTapValue) => {
        tiptapRef.current?.editor?.commands.setContent(content.content, false)

        return tiptapRef.current?.editor?.getHTML() ?? ''
    }, [])

    const getDocumentFieldContentFromHTML = useCallback((content: string) => {
        tiptapRef.current?.editor?.commands.setContent(content, false)

        const tiptapValue: TipTapValue = {
            content: tiptapRef.current?.editor?.getJSON() ?? null,
            format: 'tiptap',
            plainTextContent: tiptapRef.current?.editor?.getText() ?? '',
        }

        return tiptapValue
    }, [])

    const contextValue = useMemo(
        () => ({
            ...state,
            cellDataProvider,
            canAddRecords,
            canDeleteRecords,
            canEditRecords,
            canRestoreRecords,
            isTrashMode,
            dispatch,
            getDocumentFieldHTMLContent,
            getDocumentFieldContentFromHTML,
            pageIndex,
            setPageIndex,
            pageSize,
        }),
        [
            state,
            cellDataProvider,
            canAddRecords,
            canDeleteRecords,
            canEditRecords,
            canRestoreRecords,
            isTrashMode,
            getDocumentFieldHTMLContent,
            getDocumentFieldContentFromHTML,
            pageIndex,
            setPageIndex,
            pageSize,
        ]
    )

    return (
        <DataGridContext.Provider value={contextValue}>
            {children}
            {/* This component is used to render document field content off-screen, before appending it to the clipboard,
            so we would keep the rich text formatting. */}
            <TipTapDocumentEditor
                ref={tiptapRef}
                readOnly={true}
                allowComments={false}
                style={{
                    display: 'none',
                }}
                renderContent={false}
            />
        </DataGridContext.Provider>
    )
}

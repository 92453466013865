import React from 'react'

import { Box } from '@chakra-ui/react'
import { get } from 'lodash'
import keyBy from 'lodash/keyBy'
import FieldsEditor from 'v2/blocks/blockTypes/view/FieldContainerEditor/FieldsEditor'
import getFieldPerms from 'v2/views/utils/getFieldPerms'

import EditableLayoutObject from 'features/utils/EditableLayoutObject'

import { Container, FixedGrid } from 'v2/ui'
import { AttributeDisplay } from 'v2/ui/components/Attribute/AttributeDisplay'

const RightHandSideAttributes = ({
    context,
    config,
    showControls,
    setConfig,
    recordPermissions,
    isEditMode,
    ...props
}) => {
    const fields = config.profileFields
        ? filterProfileFields(config.profileFields, context, recordPermissions)
        : []

    const isInlineCreate = get(context, 'view.isInlineCreate')

    return (
        <EditableLayoutObject
            title="Sidebar fields"
            editor={
                <RightHandSideAttributes.Editor
                    object={context.object}
                    config={config}
                    setConfig={setConfig}
                />
            }
        >
            {(showEditor) => (
                <Container
                    width="100%"
                    display="flex"
                    flexDirection={['column', 'column', 'row', 'column']}
                    alignItems="stretch"
                    mr={4}
                    mb={0}
                    onMouseDownCapture={showControls ? showEditor : null}
                    p={[
                        'container.paddingLg',
                        'container.paddingLg',
                        'container.paddingLg',
                        'container.paddingLg',
                    ]}
                    {...props}
                    style={{ border: 'none', boxShadow: 'none' }}
                >
                    <FixedGrid columns={[1, 1, 2, 1]} gap={4}>
                        {fields.map(
                            ({
                                fieldId,
                                required,
                                fullWidth,
                                readOnly,
                                label,
                                description,
                                ...otherOptions
                            }) => (
                                <AttributeDisplay
                                    isInlineCreate={isInlineCreate}
                                    key={fieldId}
                                    objectId={context.objectId}
                                    fieldId={fieldId}
                                    record={context.record}
                                    required={required}
                                    fullWidth={fullWidth}
                                    readOnly={readOnly}
                                    editing={context.view.editing}
                                    showErrors={context.view.showErrors}
                                    setValue={context.view.actions.setValue}
                                    setValid={context.view.actions.setValid}
                                    valid={context.view.valid}
                                    isLoading={context.view?.isLoading}
                                    isVisible={true}
                                    labelOverride={label}
                                    editDescription={description}
                                    setEditing={context.view.actions.setEditing}
                                    setFocusField={context.view.actions.setFocusField}
                                    focusField={context.view.focusField}
                                    doubleClickToEdit={!isEditMode}
                                    {...otherOptions}
                                />
                            )
                        )}
                    </FixedGrid>
                </Container>
            )}
        </EditableLayoutObject>
    )
}

RightHandSideAttributes.Editor = ({ object, setConfig, config }) => (
    <Box height="100%" mt={4}>
        <FieldsEditor
            object={object}
            items={config.profileFields}
            setItems={(items) =>
                setConfig({
                    profileFields: items,
                })
            }
            disallowSections
            hideFieldFullWidthSetting
            hideColumns
            isLayoutEditorComponent
        />
    </Box>
)

const filterProfileFields = (profileFields, context, recordPermissions) => {
    // Check the record permissions and mark certain fields as read only or not.

    const objectPermissions = get(context, 'object', {}) || {}
    const [allowedFieldNamesToRead, allowedFieldNamesToUpdate, allowedFieldNamesToCreate] =
        getFieldPerms(recordPermissions, objectPermissions)

    const isCreate = get(context, 'view.creating', false)
    let writable = false
    let writableFields = []
    if (isCreate) {
        writable = get(objectPermissions, 'may_create', false)
        writableFields = allowedFieldNamesToCreate
    } else {
        writable = get(recordPermissions, 'may_update', false)
        writableFields = allowedFieldNamesToUpdate
    }

    const fields = keyBy(get(context, 'object.fields', []) || [], (f) => f.api_name)

    let allowedFields = []

    profileFields.forEach(({ fieldId, fieldName, ...rest }) => {
        if (allowedFieldNamesToRead.includes(fieldName)) {
            const fieldIsWritable = writable && writableFields.includes(fieldName)
            const readOnly =
                !fieldIsWritable || get(fields, `${fieldName}.connection_options.read_only`)
            allowedFields.push({ fieldId, fieldName, ...rest, readOnly })
        }
    })

    return allowedFields
}

export default RightHandSideAttributes

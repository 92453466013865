import { FormulaEditorFieldOrFunctionSelection } from 'features/formulas/selection/formulaSelectionTypes'

export const insertAutocompleteText = ({
    editor,
    selection,
    newText,
}: {
    editor: HTMLTextAreaElement
    selection?: FormulaEditorFieldOrFunctionSelection
    newText: string
}): { insertedTextStart: number; insertedTextEnd: number; result: string } => {
    let start = editor.selectionStart
    let end
    let endOffset = 0

    // Move start pointer backwards through formula text until a match with selection.name is found

    if (selection && selection.name) {
        while (
            start > 0 &&
            editor.value.substring(start, start + selection.name.length) !== selection.name
        ) {
            start--
        }
    }

    // selection.name does not include opening or closing bracket when present in the so we need to manually adjust start and endOffset
    if (selection?.type === 'field') {
        if (
            editor.value.substring(
                start + selection.name.length,
                start + selection.name.length + 1
            ) === '}'
        ) {
            endOffset++
        }

        if (editor.value.substring(start - 1, start) === '{') {
            start--
            endOffset++
        }
    } else if (
        selection?.type === 'function' &&
        editor.value.substring(start + selection.name.length, start + selection.name.length + 1) ===
            '('
    ) {
        endOffset++
    }

    end = start + (selection?.name?.length ?? 0) + endOffset

    editor.setRangeText(newText, start, end, 'preserve')

    return {
        insertedTextStart: start,
        insertedTextEnd: start + newText.length,
        result: editor.value,
    }
}

import { useCallback } from 'react'

import { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

import { DuplicateViewModal } from './DuplicateViewModal'
import { DuplicateViewModalData, MODAL_KEY } from './duplicateViewModalConstants'

export const useDuplicateViewModal = () => {
    const { show } = useModalDeclaration<DuplicateViewModalData>(MODAL_KEY, DuplicateViewModal)

    return useCallback(
        (params: { view: ViewDto }) => {
            show({ view: params.view })
        },
        [show]
    )
}

import React from 'react'

type BaseDropdownItemProps = {
    label?: string
    subtitle?: string
}

export function filterDropdownItems(
    items: React.ReactElement<BaseDropdownItemProps>[],
    searchQuery: string
): React.ReactElement<BaseDropdownItemProps>[] {
    const normalizedQuery = searchQuery.toLowerCase().trim()

    return items.filter((item) => {
        let label = ''
        if (typeof item.props?.label === 'string') {
            label = item.props.label.toLowerCase()
        }

        let subtitle = ''
        if (typeof item.props?.subtitle === 'string') {
            subtitle = item.props.subtitle.toLowerCase()
        }

        return label.includes(normalizedQuery) || subtitle.includes(normalizedQuery)
    })
}

export function isTargetEditable(target: HTMLElement | null): boolean {
    if (target?.tagName === 'INPUT' && (target as HTMLInputElement)?.type !== 'checkbox') {
        return true
    }
    if (target?.tagName === 'TEXTAREA') return true
    if (target?.isContentEditable) return true

    return false
}

import React from 'react'

import { LayoutEditorBlock } from 'v2/blocks/types'

import {
    conditionalVisibility,
    longText,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'

import { ConfigureWidgetPlaceholder, Container, RichTextEditor, Text } from 'v2/ui'
import * as SVGIcons from 'v2/ui/svgs'

type TextBlockProps = {}

const TextBlock: LayoutEditorBlock<TextBlockProps, TextBlock> = ({ attrs, context }) => {
    const { content } = attrs
    const { isEditing } = context.editor

    // Conditional Visibility
    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    if (passesFilters && !isEditing) return null

    if (!content) {
        if (!isEditing) return null

        return (
            <ConfigureWidgetPlaceholder
                message="Text"
                subtitle="Display text content"
                Icon={SVGIcons.TextBlock}
            />
        )
    }

    return (
        <Container
            p={['container.padding', null, null, 'container.paddingLg']}
            mb={4}
            alignItems="center"
            flexWrap="wrap"
        >
            {content && (
                <Text variant="widgetContainerDescription">
                    <RichTextEditor>{content}</RichTextEditor>
                </Text>
            )}
        </Container>
    )
}

TextBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    longText(
        {
            field: 'content',
            label: 'Content',
            multiline: true,
            fullWidth: true,
            simple: true,
        },
        {
            label: 'Content',
            height: '60vh',
        }
    ),
]

export default TextBlock

import { fetchWithAuth } from './fetchWithAuth'

/**
 *
 * @param {*} requestedSlug Requested URL slug by user
 * @param {*} zone Current account zone (null if called from the create form)
 * @returns
 */

export enum SlugCheckStatus {
    OK = 'OK',
    IS_NOT_UNIQUE = 'IS_NOT_UNIQUE',
    INVALID_CHARACTERS = 'INVALID_CHARACTERS',
    RESERVED_DOMAIN = 'RESERVED_DOMAIN',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export type SlugCheckResponse =
    | {
          status: SlugCheckStatus.OK
      }
    | {
          status:
              | SlugCheckStatus.IS_NOT_UNIQUE
              | SlugCheckStatus.INVALID_CHARACTERS
              | SlugCheckStatus.RESERVED_DOMAIN
              | SlugCheckStatus.VALIDATION_ERROR

          message: string
      }

export const validateSlug = async (
    requestedSlug: string,
    zone?: AccountZone | null
): Promise<SlugCheckResponse> => {
    const requestedSlugLower = requestedSlug.toLowerCase()

    if (zone?.slug?.toLowerCase() === requestedSlugLower) {
        return { status: SlugCheckStatus.OK }
    }

    try {
        const url = `zone/check-slug?slug=${encodeURIComponent(requestedSlugLower)}`
        return (await fetchWithAuth(url)).json()
    } catch {
        return {
            status: SlugCheckStatus.VALIDATION_ERROR,
            message: 'An error occurred while validating the slug.',
        }
    }
}

import React from 'react'

import { SelectOptionItem } from './types'

export function extractOptionItemsFromChildren(children: React.ReactNode): SelectOptionItem[] {
    return React.Children.toArray(children).reduce<SelectOptionItem[]>((acc, child) => {
        if (React.isValidElement(child)) {
            const { label, value, color } = child.props
            if (!value) return acc

            acc.push({ label: label || value, value: String(value), color })
        }

        return acc
    }, [])
}

export function valueToArray(value: string | string[] | undefined): string[] | undefined {
    if (value === undefined) return undefined

    return Array.isArray(value) ? value : [value]
}

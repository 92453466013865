import { QueryKey } from 'react-query'

function queryKeyToString(queryStringOrDeps: REACT_QUERY_DEPS_NAME | QueryKey) {
    return JSON.stringify(queryStringOrDeps)
}

type FailureLogEntry = {
    failureCount: number
    lastFailedAt: Date
}

type FailureLog = {
    [key: string]: FailureLogEntry
}

const failureLog: FailureLog = {}

const MAX_FAILURES = 10
const FAILURE_WINDOW = 10000

/*
 * Check if a query should be disabled due to too many failures.
 *
 * We disable if there have been more than MAX_FAILURES failures and the
 * last failure was less than FAILURE_BACKOFF milliseconds ago.
 */
export function shouldDisableDueToFailures(queryNameOrDeps: REACT_QUERY_DEPS_NAME | QueryKey) {
    const key = queryKeyToString(queryNameOrDeps)
    return (
        failureLog?.[key]?.failureCount > MAX_FAILURES &&
        failureLog[key].lastFailedAt > new Date(Date.now() - FAILURE_WINDOW)
    )
}

/*
 * Logs a query failure.
 * Increments the failure count for a given query if it has failed within the last FAILURE_WINDOW milliseconds.
 */
export function logQueryFailure(queryNameOrDeps: REACT_QUERY_DEPS_NAME | QueryKey) {
    const key = queryKeyToString(queryNameOrDeps)
    if (!failureLog[key]) {
        failureLog[key] = { failureCount: 1, lastFailedAt: new Date() }
    } else {
        // if the last failure was within the window, increment the failure count
        if (failureLog[key].lastFailedAt > new Date(Date.now() - FAILURE_WINDOW)) {
            failureLog[key].failureCount++
        }
        failureLog[key].lastFailedAt = new Date()
    }
}

/*
 * Resets the failure count for a given query.
 */
export function resetQueryFailures(queryNameOrDeps: REACT_QUERY_DEPS_NAME | QueryKey) {
    const key = queryKeyToString(queryNameOrDeps)
    if (failureLog[key]) {
        failureLog[key].failureCount = 0
    }
}

import React, { useCallback, useImperativeHandle } from 'react'

import { ContextGroup } from 'features/expressions/types'

import { Box } from 'ui/components/Box'
import { ComboboxContext } from 'ui/components/Combobox'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'
import { LoadingIndicator } from 'ui/components/LoadingIndicator'

import { useContextGroupsProvider } from './contextGroupsProvider'
import { ExtensionListProps, ListComponentHandle } from './types'

export const RootGroupsList = React.forwardRef<
    ListComponentHandle,
    ExtensionListProps & { onSelect: (group: ContextGroup) => void }
>(({ query, schema, editor, range, onSelect, renderGroupTitle }, ref) => {
    const provider = useContextGroupsProvider({ schema, renderGroupTitle })
    const onItemSelected = useCallback(
        (item) => {
            queueMicrotask(() => editor.chain().focus().deleteRange(range).run())
            onSelect(item)
        },
        [editor, onSelect, range]
    )
    const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
        isOpen: true,
        inputValue: query,
        onItemSelected,
        providers: [provider],
        itemToString: () => '',
        debounceDelay: 10,
    })

    const { getInputProps } = comboboxState

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            getInputProps().onKeyDown(event)
            return event.defaultPrevented
        },
    }))

    const { isLoading, collections, showMore, items } = itemsState

    return (
        <ComboboxContext.Provider value={comboboxState}>
            {!isLoading && !items.length && (
                <Box py="l" px="xl" fontSize="bodyM">
                    No matching items found
                </Box>
            )}
            <Box {...comboboxState.getMenuProps()}>
                <ComboboxList
                    collections={collections}
                    queryTerms={queryTerms}
                    showMore={showMore}
                    isLoading={isLoading}
                />
            </Box>
            {isLoading && (
                <Box py="l" px="xl" flex center color="textWeakest">
                    <LoadingIndicator />
                </Box>
            )}
        </ComboboxContext.Provider>
    )
})

import React, { isValidElement } from 'react'

import { TypographyProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'

import Box from './Box'
import Flex from './Flex'
import Heading from './Heading'
import Sticky from './Sticky'

type ContainerLabelProps = {
    value?: string
    buttons?: React.ReactNode
    search?: React.ReactNode
    topFilters?: React.ReactNode
    isEditable?: boolean
    contentsCanWrap?: boolean
    sticky?: boolean
    stickyTopOffset?: number
    leftContent?: React.ReactNode
    downloadButton?: React.ReactNode
    bodyContainerStyle?: React.CSSProperties
    inboxMode?: boolean
    onChange?: (value: string) => void
    onCancel?: () => void
    variant?: string
    className?: string
    borderStyle?: string
    textAlign?: TypographyProps['textAlign']
    valueDecorator?: React.ReactNode
}

const ContainerLabel: React.FC<ContainerLabelProps> = ({
    value,
    isEditable,
    buttons,
    topFilters,
    onChange,
    onCancel,
    downloadButton,
    search,
    borderStyle,
    textAlign,
    sticky,
    leftContent,
    stickyTopOffset,
    bodyContainerStyle,
    inboxMode,
    contentsCanWrap = false,
    valueDecorator,
    ...props
}) => {
    const heading = isValidElement(value) ? (
        value
    ) : (
        <Heading
            as="h1"
            variant={props.variant || 'containerLabel'}
            value={value}
            valueDecorator={valueDecorator}
            isEditable={isEditable}
            onChange={onChange}
            onCancel={onCancel}
            className={props.className}
            size={inboxMode ? '18px !important' : undefined}
        />
    )
    return (
        <Sticky rootMargin="24px" disabled={!sticky} topOffset={stickyTopOffset}>
            {(ref, isFixed) => (
                <FixedContainer ref={ref as React.RefObject<HTMLDivElement>} isFixed={isFixed}>
                    <StyledFlex
                        borderBottomStyle="solid"
                        borderBottomWidth="1px"
                        borderBottomColor="container.labelBorder"
                        borderStyle={borderStyle}
                        maxWidth="100%"
                        variant={(props.variant || 'containerLabel') + (isFixed ? 'Sticky' : '')}
                        textAlign={textAlign}
                        style={bodyContainerStyle}
                        flexWrap={contentsCanWrap ? 'wrap' : 'nowrap'}
                    >
                        {!isFixed && leftContent}
                        {inboxMode ? (
                            <>
                                <Flex style={{ flexGrow: 1, marginBottom: 12 }}>
                                    {heading} {downloadButton} {buttons}
                                </Flex>
                                {search}
                                {topFilters}
                            </>
                        ) : (
                            <>
                                {heading}
                                {downloadButton}
                                {search}
                                {buttons}
                                {topFilters}
                            </>
                        )}
                    </StyledFlex>
                </FixedContainer>
            )}
        </Sticky>
    )
}

export const FixedContainer = styled(Box)<{ isFixed?: boolean; zIndexOverride?: number }>`
    background: ${(props) => (props.isFixed ? props.theme.colors.backgroundColor : null)};
    margin-left: ${(props) => (props.isFixed ? '-48px' : null)};
    padding-left: ${(props) => (props.isFixed ? '48px' : null)};
    margin-right: ${(props) => (props.isFixed ? '-48px' : null)};
    padding-right: ${(props) => (props.isFixed ? '48px' : null)};
    box-shadow: ${(props) => (props.isFixed ? '0px 10px 18px -20px rgb(0, 0, 0, 1)' : null)};
    z-index: ${(props) => props.zIndexOverride ?? 4};

    transition: box-shadow 0.2s ease-out;
`
const StyledFlex = styled(Box)<{ variant?: string; borderStyle?: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    ${variant({
        prop: 'borderStyle',
        variants: {
            noBorder: {
                borderBottomWidth: 0,
                pb: 0,
            },
        },
    })},
    ${variant({
        variants: {
            pageHeading: {
                m: 0,
                p: 0,
                mt: ['page.heading.mt', null, null, 'page.heading.mtLg'],
                mb: ['page.heading.mb', null, null, 'page.heading.mbLg'],
            },
            detailPageHeading: {
                m: 0,
                p: 0,
                mt: ['page.heading.mt', null, null, 'page.heading.mtLg'],
            },
            pageHeadingSticky: {
                m: 0,
                p: 0,
                mt: 0,
                mb: 0,
                py: 3,
            },
            detailPageHeadingSticky: {
                m: 0,
                p: 0,
                mt: 0,
                mb: 0,
                py: 3,
            },
            containerLabel: {
                m: 0,
                mb: '0.5rem',
                p: 0,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            fixedContainerLabel: {
                m: 0,
                mb: '0.5rem',
                p: 0,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            inboxViewHeading: {
                mt: '8px',
                pl: '12px',
                pr: '12px',
            },
        },
    })}
`

export default React.memo(ContainerLabel)

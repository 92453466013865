import React, { useCallback, useImperativeHandle, useMemo } from 'react'

import { ContextGroup } from 'features/expressions/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { ComboboxContext } from 'ui/components/Combobox'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { LoadingIndicator } from 'ui/components/LoadingIndicator'

import { getContextItemsProviders } from './contextItemsProvider'
import { BaseExtensionProps, ListComponentHandle } from './types'

type ListProps = BaseExtensionProps & {
    group: ContextGroup
    onClose?: () => void
}

export const ContextGroupItemsList = React.forwardRef<ListComponentHandle, ListProps>(
    ({ query, editor, range, group, renderGroupTitle, onClose, onItemSelected }, ref) => {
        const providers = useMemo(() => getContextItemsProviders(group), [group])

        const handleItemSelected = useCallback(
            (item, provider) => {
                provider.onSelect({ editor, range, item })
                onItemSelected?.(item)
            },
            [editor, range, onItemSelected]
        )
        const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
            isOpen: true,
            inputValue: query,
            onItemSelected: handleItemSelected,
            providers,
            itemToString: () => '',
            debounceDelay: 10,
        })

        function handleCloseClick(e: React.MouseEvent) {
            e.preventDefault()
            e.stopPropagation()
            editor.chain().focus().deleteRange(range).run()
            onClose?.()
        }
        const { getInputProps } = comboboxState

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }) => {
                getInputProps().onKeyDown(event)
                return event.defaultPrevented
            },
        }))

        const { isLoading, collections, showMore, items } = itemsState

        return (
            <ComboboxContext.Provider value={comboboxState}>
                {/* {!query && (
                        <Box flex center px={3} py={3} color="textWeakest">
                            <Icon name="search" mr={2} /> Start typing to search for records...
                        </Box>
                    )} */}
                <Box
                    as={Button}
                    variant="ghost"
                    background="surfaceStrongest"
                    style={{
                        borderRadius: 0,
                        pointerEvents: onClose ? 'auto' : 'none',
                    }}
                    onMouseDown={onClose ? handleCloseClick : undefined}
                    width="full"
                >
                    <Box flex center grow fontWeight="bodyRegular">
                        {renderGroupTitle?.({ item: group, queryTerms })}
                    </Box>

                    {!!onClose && <Icon name="ChevronUp" color="textWeakest" />}
                </Box>
                {query && !isLoading && !items.length && (
                    <Box py="l" px="xl" fontSize="bodyM">
                        No results found
                    </Box>
                )}
                {!query && items.length > 0 && <Divider mb="xs" />}
                <ComboboxList
                    collections={collections}
                    queryTerms={queryTerms}
                    showMore={showMore}
                    isLoading={isLoading}
                />

                {isLoading && (
                    <Box py="l" px="xl" flex center color="textWeakest">
                        <LoadingIndicator />
                    </Box>
                )}
            </ComboboxContext.Provider>
        )
    }
)

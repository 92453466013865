import React from 'react'

import { Container } from 'ui/components/Container'
import { theme } from 'ui/styling/Theme.css'

import { ExecutionHistoryList } from './ExecutionHistoryList'

type ExecutionHistorySidebarProps = React.ComponentPropsWithoutRef<typeof Container> & {
    executions: WorkflowExecutionDto[]
    selectedExecutionId: string | null
    setSelectedExecutionId: (id: string) => void
}

export const ExecutionHistorySidebar: React.FC<ExecutionHistorySidebarProps> = ({
    executions,
    selectedExecutionId,
    setSelectedExecutionId,
    ...props
}) => {
    return (
        <Container
            width="200px"
            flex
            column
            py="xl"
            px="m"
            boxShadow="m"
            noShrink
            style={{
                borderLeft: `1px solid ${theme.color.border}`,
                borderRadius: '0px',
                zIndex: 1,
            }}
            overflowY="auto"
            {...props}
        >
            <ExecutionHistoryList
                executions={executions}
                selectedExecutionId={selectedExecutionId}
                setSelectedExecutionId={setSelectedExecutionId}
            />
        </Container>
    )
}

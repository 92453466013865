import { useCallback } from 'react'

import { useOmnibarContext } from 'features/Omnibar/OmnibarContext'

import { useKeyboardShortcutLabel } from 'ui/helpers/useKeyboardShortcutLabel'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useNavigationContext } from './useNavigationContext'

export function useSystemNavigationItemSearchState() {
    const { tryCollapseWorkspaceNav } = useNavigationContext()

    const { setIsOpen: setIsOmnibarOpen } = useOmnibarContext()

    const onClick = useCallback(() => {
        setIsOmnibarOpen(true)
        tryCollapseWorkspaceNav()
    }, [setIsOmnibarOpen, tryCollapseWorkspaceNav])

    const keyboardShortcutLabel = useKeyboardShortcutLabel({
        mac: '⌘K',
        default: 'Ctrl+K',
    })

    const showKeyboardShortcutLabel = useResponsiveValue({
        mobile: false,
        tablet: true,
    })

    return {
        onClick,
        keyboardShortcutLabel,
        showKeyboardShortcutLabel,
    }
}

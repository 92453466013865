import without from 'lodash/without'

import { byID } from 'data/utils/byID'
import {
    EMAIL_TEMPLATE_CREATED,
    EMAIL_TEMPLATE_REMOVED,
    EMAIL_TEMPLATE_UPDATED,
    EMAIL_TEMPLATES_FETCHED,
    FETCH_COMPLETED,
    FETCH_STARTED,
    STACK_SELECTED,
} from 'data/utils/constants'
import { unique } from 'utils/utils'

// STATE
export const initialState = {
    emailTemplates: [],
    entities: {},
    fetching: [],
}

// REDUCERS
export function emailTemplateReducer(state = initialState, action) {
    let emailTemplates
    let emailTemplate
    let fetching
    switch (action.type) {
        case EMAIL_TEMPLATES_FETCHED:
            if (!action.payload) return state
            return {
                ...state,
                emailTemplates: unique([
                    ...state.emailTemplates,
                    ...action.payload.map((et) => et._sid),
                ]),
                entities: { ...state.entities, ...byID(action.payload) },
                fetching: state.fetching.filter((item) => item !== action.key),
            }
        case EMAIL_TEMPLATE_CREATED:
            if (!action.payload) return state
            emailTemplates = unique([...state.emailTemplates, action.payload._sid])
            return {
                ...state,
                emailTemplates,
                entities: { ...state.entities, [action.payload._sid]: action.payload },
            }
        case EMAIL_TEMPLATE_UPDATED:
            if (!action.payload) return state
            emailTemplate = { ...state.entities[action.id], ...action.payload }
            return {
                ...state,
                entities: { ...state.entities, [emailTemplate._sid]: emailTemplate },
            }
        case EMAIL_TEMPLATE_REMOVED:
            emailTemplates = state.emailTemplates.map((ets) => without(ets, action.id))
            return {
                ...state,
                emailTemplates,
            }
        case FETCH_STARTED:
            return { ...state, fetching: [...state.fetching, action.key] }
        case FETCH_COMPLETED:
            fetching = state.fetching.filter((item) => item !== action.key)
            return { ...state, fetching }
        case STACK_SELECTED:
            return initialState
        default:
            return state
    }
}

import { useMemo, useRef } from 'react'

import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Extension } from '@tiptap/react'
import Suggestion from '@tiptap/suggestion'

import { renderSuggestionComponent } from 'features/tiptap/Extensions/extensionHelpers'
import { hasTrAddedChar } from 'features/tiptap/utilities'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { CommandExtensionView } from './CommandExtensionComponents'
import { CommandProps, CommandsProvider } from './CommandsProvider'

declare module '@tiptap/core' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Commands<ReturnType> {
        commands: {
            closeCommandSuggestion: () => ReturnType
        }
    }
}

type CommandsExtensionOptions = {
    allowedPrefixes?: string[] | null
}
export function createCommandsExtension(
    providersFn: () => CommandsProvider<any>[],
    options: CommandsExtensionOptions = {}
) {
    const suggestionPluginKey = new PluginKey('commandSuggestion')

    return Extension.create({
        name: 'commands',
        addCommands() {
            return {
                closeCommandSuggestion: () => {
                    return ({ tr }) => {
                        this.storage.canShow = false

                        tr.setMeta(suggestionPluginKey, {
                            active: false,
                        })
                        tr.setMeta('addToHistory', false)

                        return true
                    }
                },
            }
        },
        addProseMirrorPlugins() {
            return [
                new Plugin({
                    filterTransaction: (tr, state) => {
                        const { selection, selectionSet } = tr
                        if (!selectionSet) return true

                        const hasAddedSlashChar = hasTrAddedChar('/', tr)
                        if (hasAddedSlashChar) {
                            this.storage.canShow = true
                            this.storage.lastAddedCharPos = selection.$from.pos
                        }

                        const hasAddedSpaceChar = hasTrAddedChar(' ', tr)
                        if (hasAddedSpaceChar) {
                            const currentPos = state.selection.$from.pos
                            if (currentPos < 1) return true

                            const prevCharPos = currentPos - 1
                            const charBeforeCursor = state.doc.textBetween(prevCharPos, currentPos)
                            if (charBeforeCursor !== '/') return true

                            this.editor.commands.closeCommandSuggestion()
                        }

                        return true
                    },
                }),
                Suggestion<CommandProps>({
                    pluginKey: suggestionPluginKey,
                    editor: this.editor,
                    char: '/',
                    allowedPrefixes: options.allowedPrefixes,
                    allowSpaces: true,
                    items: () => [],
                    render: () => {
                        const onHide = () => {
                            this.editor.commands.closeCommandSuggestion()
                        }

                        // console.log('#props', editor.isFocused)
                        // if (!editor.isFocused) return null
                        return renderSuggestionComponent(
                            CommandExtensionView,
                            { providersFn },
                            onHide
                        )
                    },
                    allow: ({ editor, range }) => {
                        if (!this.storage.canShow) return false
                        if (this.storage.lastAddedCharPos !== range.from + 1) return false

                        return editor.isFocused
                    },
                }),
            ]
        },
        addStorage() {
            return {
                canShow: false,
                lastAddedCharPos: -1,
            }
        },
    })
}

export function useCommandsExtension({
    providers: suppliedProviders,
    options: suppliedOptions,
}: {
    providers: CommandsProvider<any>[]
    options?: CommandsExtensionOptions
}) {
    const options = useDeepEqualsMemoValue(suppliedOptions)
    // this ensures that even if the caller passes in a new array, we don't re-create the extension
    const providers = useRef(suppliedProviders)
    providers.current = suppliedProviders
    return useMemo(() => {
        return createCommandsExtension(() => providers.current, options)
    }, [options])
}

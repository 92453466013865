import React from 'react'

import { NodeViewContent, NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react'
import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Checkbox } from 'ui/components/Checkbox'

import { CheckItemCheckboxLabelStyle, CheckItemStyle } from './CheckItemExtension.css'

type CheckItemComponentProps = NodeViewRendererProps & {}
export const CheckItemComponent: React.FC<CheckItemComponentProps> = ({ node, editor, getPos }) => {
    const isChecked = node.attrs.checked

    const handleCheckedChange = () => {
        if (!editor.isEditable) return

        editor
            .chain()
            .focus(undefined, { scrollIntoView: false })
            .command(({ tr }) => {
                if (typeof getPos !== 'function') return false

                const pos = getPos()
                const currNode = tr.doc.nodeAt(pos)

                tr.setNodeMarkup(pos, undefined, {
                    ...currNode?.attrs,
                    checked: !isChecked,
                })

                return true
            })
            .run()
    }

    return (
        <NodeViewWrapper
            as={Box}
            flex
            alignItems="flex-start"
            flexWrap="nowrap"
            className={classNames('check-item', CheckItemStyle)}
        >
            <Checkbox checked={isChecked} onCheckedChange={handleCheckedChange} />
            <NodeViewContent as={Box} ml="m" className={CheckItemCheckboxLabelStyle} />
        </NodeViewWrapper>
    )
}

const getUser = (): AuthedUserDto | undefined => {
    const user = localStorage.getItem('user')
    return user ? JSON.parse(user) : undefined
}

const setUser = (user: UserDto | undefined) => {
    localStorage.setItem('user', user ? JSON.stringify(user) : '')
}

export const AuthContextStorage = {
    getUser,
    setUser,
}

import { isAtEndOfNode, isMarkActive } from '@tiptap/core'
import Link, { LinkOptions } from '@tiptap/extension-link'

export function createLinkExtension(options?: Partial<LinkOptions>) {
    return Link.extend({
        addKeyboardShortcuts() {
            return {
                ...this.parent?.(),
                Space: ({ editor }) => {
                    return editor.commands.command(({ tr, state }) => {
                        if (isMarkActive(state, this.name) && isAtEndOfNode(state)) {
                            tr.insert(tr.selection.$to.pos, this.editor.schema.text(' '))

                            return true
                        }

                        return false
                    })
                },
            }
        },
    }).configure(options)
}

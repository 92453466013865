import React from 'react'
// @ts-ignore
import ReactHighlighter from 'react-highlight-words'
type HighlighterProps = React.PropsWithChildren<{ terms?: string[] }>
export function Highlighter({ terms, children, ...props }: HighlighterProps) {
    return (
        <ReactHighlighter
            searchWords={terms || []}
            textToHighlight={children || ''}
            highlightClassName="search-result-higlight"
            autoEscape
            {...props}
        />
    )
}

import { useCallback, useMemo, useRef } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import {
    changeFilterOperation,
    getDefaultValueForOperation,
    getOperationLabel,
    getSupportedOperations,
    operationRequiresUserInput,
} from 'features/views/ListView/ListHeader/Filters/Advanced/operations'
import { AdvancedFilter } from 'features/views/ListView/ListHeader/Filters/Advanced/types'
import { useListHeaderContext } from 'features/views/ListView/ListHeader/useListHeaderContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseAdvancedFiltersItemStateOptions = {
    filter: AdvancedFilter
    onUpdateFilter: (filter: AdvancedFilter) => void
    onCommitDraft: () => void
    onRemoveFilter: () => void
}

export function useAdvancedFiltersItemState(options: UseAdvancedFiltersItemStateOptions) {
    const { filter, onUpdateFilter, onCommitDraft } = options
    const filterRef = useRef(filter)
    filterRef.current = filter

    const field = filter.field
    const fieldRef = useRef(field)
    fieldRef.current = field

    const { view } = useListHeaderContext()
    const column = view.options.columns?.find((column) => column.fieldId === field?._sid)
    const label = column?.label || field?.label || 'Filter'

    let operation = filter.options.option
    if (field?.type === 'checkbox' && filter.options.value === 'false') {
        operation = 'isnt'
    }

    const onChangeOperation = useCallback(
        (op: string) => {
            const currentFilter = filterRef.current
            const field = fieldRef.current as FieldDto

            const newOp = op as FilterOperation
            const defaultValue = getDefaultValueForOperation(field, newOp)

            let newFilter: AdvancedFilter = {
                ...currentFilter,
                options: {
                    ...currentFilter.options,
                    option: newOp,
                    value: defaultValue,
                },
            }

            if (field.type === 'checkbox') {
                if (newOp === 'is') {
                    newFilter.options.value = 'true'
                } else if (newOp === 'isnt') {
                    newFilter.options.option = 'is'
                    newFilter.options.value = 'false'
                }
            } else {
                newFilter = changeFilterOperation(currentFilter, newOp)
            }

            onUpdateFilter(newFilter)

            const opRequiresValue = operationRequiresUserInput(field, newOp)
            if (currentFilter.isDraft && !opRequiresValue) {
                queueMicrotask(() => {
                    onCommitDraft()
                })
            }
        },
        [onCommitDraft, onUpdateFilter]
    )

    const operationOptions = useMemo(() => {
        const supportedOperations = field ? getSupportedOperations(field as FieldDto) : []

        if (field?.type === 'checkbox') {
            return [
                {
                    label: 'is checked',
                    value: 'is',
                },
                {
                    label: 'is not checked',
                    value: 'isnt',
                },
            ]
        }

        return supportedOperations.map((op) => {
            const label = getOperationLabel(field as FieldDto, op)

            return {
                label,
                value: op,
            }
        })
    }, [field])

    const onChangeValue = useCallback(
        (newValue: AdvancedFilter['options']['value']) => {
            const currentFilter = filterRef.current
            onUpdateFilter({
                ...currentFilter,
                options: {
                    ...currentFilter.options,
                    value: newValue,
                },
            })

            if (currentFilter.isDraft) {
                queueMicrotask(() => {
                    onCommitDraft()
                })
            }
        },
        [onCommitDraft, onUpdateFilter]
    )

    const icon = useDeepEqualsMemoValue(getFieldIcon(field as FieldDto))

    return useMemo(
        () => ({
            label,
            operation,
            onChangeOperation,
            operationOptions,
            onChangeValue,
            icon,
        }),
        [label, operation, onChangeOperation, operationOptions, onChangeValue, icon]
    )
}

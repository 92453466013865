import React, { useEffect, useState } from 'react'

import { useWorkflowExecutions } from 'data/hooks/workflows/workflows'

import { LoadingScreen } from 'v2/ui'

import { Box } from 'ui/components/Box'

import { ExecutionHistoryDetail } from './ExecutionHistoryDetail'
import { ExecutionHistorySidebar } from './ExecutionHistorySidebar'

type ExecutionHistoryPaneProps = React.ComponentPropsWithoutRef<typeof Box> & {
    workflow: WorkflowDto
}

export const ExecutionHistoryPane: React.FC<ExecutionHistoryPaneProps> = (props) => {
    const { data: executions, isLoading } = useWorkflowExecutions({ flow_id: props.workflow._sid })

    const [selectedExecutionId, setSelectedExecutionId] = useState<string | null>(null)

    const selectedExecution = executions?.find(
        (execution) => execution._sid === selectedExecutionId
    )

    useEffect(() => {
        // Pre-select the first execution if none is selected.
        if (!selectedExecutionId && executions?.length) {
            setSelectedExecutionId(executions[0]._sid)
        }
    }, [executions, selectedExecutionId])

    return (
        <Box overflowY="auto" {...props}>
            <LoadingScreen isLoading={isLoading} display="flex" alignItems="stretch">
                <ExecutionHistorySidebar
                    executions={executions as WorkflowExecutionDto[]}
                    selectedExecutionId={selectedExecutionId}
                    setSelectedExecutionId={setSelectedExecutionId}
                />
                <ExecutionHistoryDetail
                    height="full"
                    overflowY="auto"
                    grow
                    selectedExecution={selectedExecution}
                    key={selectedExecutionId}
                />
            </LoadingScreen>
        </Box>
    )
}

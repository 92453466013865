import { useMemo } from 'react'

import { BoardViewAttribute } from 'features/views/ListView/BoardView/types'
import { useBoardViewContext } from 'features/views/ListView/BoardView/useBoardViewContext'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

type UseRecordLinksAttributeStateOptions = {
    attribute: BoardViewAttribute
    value?: string | string[]
    isLoading?: boolean
    displayStyle?: ListViewOptions['boardFieldStyle']
}

export function useRecordLinksAttributeState(options: UseRecordLinksAttributeStateOptions) {
    const { value, isLoading, displayStyle, attribute } = options
    const { field, type } = attribute
    const isFooter = type === 'footer'

    const { dereferencedRecords, attributeStyle } = useBoardViewContext()

    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    const isSingle = !Array.isArray(value) || value.length === 1

    const usersObject = useStackerUsersObject()
    const objectSid = field.link_target_object_id
    const isUsersObject = objectSid === usersObject?._sid

    let displayAsTag = displayStyle === 'tags'
    if (isFooter && !isUsersObject) {
        displayAsTag = true
    }

    const isTagListAttributeStyle = attributeStyle === 'tags'

    return useMemo(
        () => ({
            dereferencedRecords,
            isEmpty,
            isSingle,
            displayAsTag,
            isTagListAttributeStyle,
        }),
        [dereferencedRecords, displayAsTag, isEmpty, isSingle, isTagListAttributeStyle]
    )
}

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PropTypes from 'prop-types'
import { DocumentPage } from 'v2/views/Document/DocumentPage'

import { getUrl, getWorkspaceUrl, Urls } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import useLDFlags from 'data/hooks/useLDFlags'
import AcceptInvitePage from 'features/auth/AcceptInvitePage'
import { SignupStartPage } from 'features/auth/admin-registration/SignupStartPage'
import { AuthWithToken } from 'features/auth/AuthWithToken'
import { ResetPasswordPage as NewResetPasswordPage } from 'features/auth/new-stacker-auth/ResetPasswordPage'
import { SignUpPage } from 'features/auth/new-stacker-auth/SignUpPage'
import { ResetPasswordPage as OldResetPasswordPage } from 'features/auth/ResetPasswordPage'
import SupportLogin from 'features/auth/SupportLogin'
import { EmailVerificationCallbackPage } from 'features/auth/workspace/EmailVerificationCallbackPage'
import { LoginLinkCallbackPage } from 'features/auth/workspace/LoginLinkCallbackPage'
import { LoginPageRenderer } from 'features/auth/workspace/LoginPageRenderer'
import { ThirdPartyCallbackPage } from 'features/auth/workspace/ThirdPartyCallbackPage'
import { HomePage } from 'features/Onboarding/HomePage'
import { OnboardingProductSplit } from 'features/Onboarding/OnboardingProductSplit'
import { OnboardingSignupPage } from 'features/Onboarding/OnboardingSignupPage'
import { OnboardingStartPage } from 'features/Onboarding/OnboardingStartPage'
import { OnboardingWhatAreYouBuilding } from 'features/Onboarding/OnboardingWhatAreYouBuilding'
import { PageByUrl } from 'features/pages/page/PageByUrl'
import { AccountSettings } from 'features/stackerSettings/AccountSettings'
import { StackSettingsPage } from 'features/studio/stacks/StackSettingsPage'
import { TasksPage } from 'features/tasks/TasksPage'
import { WorkflowPlayground } from 'features/workflows/WorkflowPlayground'
import { WorkspaceHome as NewWorkspaceHome } from 'features/WorkspaceHome/WorkspaceHome'

import { ComponentBenchmarkPlayground } from 'ui/ComponentBenchmarkPlayground'

import { LogoutRoute } from './LogoutRoute'
import { PrivateRoute } from './PrivateRoute'

export const Routes = () => {
    const { selectedStack, workspaceAccount } = useAppContext()
    const { flags } = useLDFlags()

    const useNewAuth = !Boolean(localStorage.getItem('useOldAuth'))

    const ResetPasswordPage = useNewAuth ? NewResetPasswordPage : OldResetPasswordPage
    const workspaceRoutes = [
        { path: Urls.AccountSettings, component: AccountSettings },
        { path: Urls.Tasks, component: TasksPage, isPrivate: true },
        { path: Urls.Benchmark, component: ComponentBenchmarkPlayground },
        // This is to support workspace-specific logins as in the case of a workspace
        // which requires SSO
        { path: Urls.Login, component: LoginPageRenderer },
        {
            path: Urls.Root,
            exact: selectedStack?._sid !== undefined,
            component: NewWorkspaceHome,
            isPrivate: true,
        },

        {
            path: Urls.WorkspaceHome,
            component: NewWorkspaceHome,
            isPrivate: true,
            exact: selectedStack?._sid !== undefined,
        },
    ]

    // If we have a workspace account, but no selected stack, just show the workspace routes
    if (workspaceAccount && !selectedStack?._sid) {
        return (
            <Switch>
                <LogoutRoute path={Urls.Logout} isUserDomain />
                <Route path={`/:slug?${Urls.Login}`} component={LoginPageRenderer} />
                <Route
                    path={`/:slug?${Urls.AuthThirdPartyCallback}/:provider`}
                    component={ThirdPartyCallbackPage}
                />
                <Route
                    path={`/:slug?${Urls.LoginLinkCallback}`}
                    component={LoginLinkCallbackPage}
                />
                <Route
                    path={`${Urls.EmailVerificationCallback}`}
                    component={EmailVerificationCallbackPage}
                />
                {workspaceRoutes.map(({ isPrivate, path, ...route }, index) =>
                    isPrivate ? (
                        <PrivateRoute key={index} path={getWorkspaceUrl(path)} {...route} />
                    ) : (
                        <Route key={index} path={getWorkspaceUrl(path)} {...route} />
                    )
                )}
            </Switch>
        )
    }
    return (
        <>
            <Switch>
                {/* Studio root urls */}
                <Route path={Urls.RegisterHidden} component={SignupStartPage} />

                <Route path={Urls.AcceptInvitation} component={AcceptInvitePage} />
                <Route path={Urls.Login} component={LoginPageRenderer} />
                <PrivateRoute
                    path={Urls.OnboardingStart}
                    component={OnboardingStartPage}
                    emailVerificationRequired={false}
                />
                <Route path={Urls.OnboardingSignup} component={OnboardingSignupPage} />
                <Route path={Urls.OnboardingProductSplit} component={OnboardingProductSplit} />
                <Route
                    path={Urls.OnboardingWhatAreYouBuilding}
                    component={OnboardingWhatAreYouBuilding}
                />
                <Route path={Urls.SignUp} component={SignUpPage} />
                <LogoutRoute path={Urls.Logout} />
                <Route path={`${Urls.ResetPassword}/:authToken?`} component={ResetPasswordPage} />
                <Route path={`${Urls.SetPassword}/:authToken?`} component={ResetPasswordPage} />
                <Route path={`/:slug${Urls.Login}`} component={LoginPageRenderer} />
                <Route
                    path={`/:slug?${Urls.AuthThirdPartyCallback}/:provider`}
                    component={ThirdPartyCallbackPage}
                />
                <Route
                    path={`/:slug?${Urls.LoginLinkCallback}`}
                    component={LoginLinkCallbackPage}
                />
                <Route
                    path={`${Urls.EmailVerificationCallback}`}
                    component={EmailVerificationCallbackPage}
                />
                <Route path={Urls.Auth} component={AuthWithToken} />
                <PrivateRoute
                    path={Urls.Root}
                    component={HomePage}
                    exact
                    emailVerificationRequired={false}
                />

                {/* end studio root urls */}
                {workspaceAccount &&
                    workspaceRoutes.map(({ isPrivate, path, ...route }, index) =>
                        isPrivate ? (
                            <PrivateRoute key={index} path={getWorkspaceUrl(path)} {...route} />
                        ) : (
                            <Route key={index} path={getWorkspaceUrl(path)} {...route} />
                        )
                    )}
                <LogoutRoute path={Urls.Logout} isUserDomain={workspaceAccount} />
                {/* STUDIO PAGES */}
                <PrivateRoute path={getUrl(Urls.StackSettings)} component={StackSettingsPage} />
                {/* PANEL ADMIN PAGES */}
                <Route path={getUrl(Urls.AdminSupportLogin)} component={SupportLogin} />
                <Route path={`${getUrl(Urls.Docs)}/:documentId?`} component={DocumentPage} />
                {flags.automations && (
                    <Route path={getUrl(Urls.Workflows)} component={WorkflowPlayground} />
                )}
                {/* CATCH-ALL TO DISPLAY PAGES */}
                {selectedStack?._sid ? (
                    <Route component={PageByUrl} />
                ) : (
                    <RedirectRoute to={Urls.Root} />
                )}
            </Switch>
        </>
    )
}

const RedirectRoute = ({ path, to }) => <Route path={path} component={() => <Redirect to={to} />} />
RedirectRoute.propTypes = {
    path: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
}

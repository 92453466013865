import React from 'react'

import { useTheme } from '@chakra-ui/react'
import ListViewMenuToggleTrigger from 'v2/views/List/ListViewMenuToggleTrigger'

import MenuToggle from 'features/views/List/MenuToggle'

import { Box } from 'ui/components/Box'
import { Icon, IconName } from 'ui/components/Icon/Icon'

type ListViewControlItemProps = {
    label: string
    icon: IconName
    children: React.ReactNode
}

export const ListViewControlItem: React.FC<ListViewControlItemProps> = ({
    label,
    icon,
    children,
}) => {
    const { colors } = useTheme()

    return (
        // @ts-ignore
        <MenuToggle
            style={{
                marginLeft: '-8px',
                marginRight: '-8px',
            }}
            contentProps={{
                style: {
                    maxHeight: '70vh',
                    overflowY: 'auto',
                },
            }}
            customToggleComponent={(props) => (
                <ListViewMenuToggleTrigger
                    label={
                        <Box flex center gap="m">
                            <Icon
                                name={icon}
                                size="s"
                                style={{ color: colors.admin.menuToggle.icon }}
                            />
                            {label}
                        </Box>
                    }
                    {...props}
                />
            )}
        >
            {children}
        </MenuToggle>
    )
}

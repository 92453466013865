export enum ActivityType {
    Comment = 1,
    RecordCreated = 2,
    RecordUpdated = 3,
    RecordDeleted = 4,
    Reaction = 5,
    TaskCompleted = 6,
    TaskDueDateChanged = 7,
    TaskAssigneesChanged = 8,
    RecordRestored = 9,
}
export enum RelatedToType {
    Record = 1,
    Activity = 2,
    Task = 3,
    Document = 4,
}
export enum NotificationType {
    Mention = 1,
    Reply = 2,
    FollowedFeed = 3,
    Reaction = 4,
    TaskAssigned = 5,
}

import React from 'react'

import { Thumbnail } from 'features/views/attributes/Thumbnail'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'

import { useBoardViewProfileImageState } from './hooks/useBoardViewProfileImageState'

import { CardProfileImageStyles } from './BoardView.css'

type BoardViewProfileImageProps = React.ComponentPropsWithoutRef<typeof Box> & {
    record: RecordDto
    isLoading?: boolean
    hasCoverImage?: boolean
}

export const BoardViewProfileImage: React.FC<BoardViewProfileImageProps> = ({
    record,
    isLoading,
    style,
    hasCoverImage,
    ...props
}) => {
    const { hasThumbnail, imageSrc } = useBoardViewProfileImageState({ record })

    if (!hasThumbnail) return null

    return (
        <Box
            px={hasCoverImage ? 's' : 'xs'}
            pt={hasCoverImage ? 's' : 'xs'}
            pb={hasCoverImage ? 0 : '2xs'}
            noShrink
            style={{
                marginTop: hasCoverImage ? '-56px' : undefined,
                ...style,
            }}
            {...props}
        >
            <Skeleton isLoading={isLoading} display="block">
                <Thumbnail
                    src={imageSrc}
                    size="manual"
                    alt="Record profile image"
                    className={CardProfileImageStyles.styleFunction({ hasCoverImage })}
                />
            </Skeleton>
        </Box>
    )
}

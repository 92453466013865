import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeDividerVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            separatorWeak: s.color.dividerWeak,
            separatorStrong: s.color.divider,
        },
        gap: {
            text: s.space.m,
            vertical: s.space.m,
        },
        size: {
            separator: pxToRem(1),
            verticalSeparatorHeight: pxToRem(20),
        },
        borderStyles: {
            default: s.borderStyles.base,
            or: s.borderStyles.base,
            dotted: s.borderStyles.dashed,
            vertical: s.borderStyles.base,
        },
    }
}

import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeDropdownVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surface: s.color.surface,
            surfaceHover: s.color.gray100,
            surfaceDivider: s.color.gray200,
            surfaceIcon: s.color.gray100,
            surfaceIconHover: s.color.gray200,
            surfaceChecked: s.color.theme50,
            surfaceIconChecked: s.color.theme200,

            border: s.color.borderWeak,
            borderAccentHover: s.color.borderThemeWeak,

            textTitle: s.color.textWeaker,
            textItemLabel: s.color.text,
            textItemLabelDisabled: s.color.textDisabled,
            textItemSubtitle: s.color.textWeak,
            textShortcut: s.color.textWeakest,
            textSearch: s.color.text,
            textPlaceholder: s.color.gray400,
        },
        borderStyles: {
            container: 'solid',
        },
        borderWidths: {
            container: s.borderWidths[1],
            accent: s.borderWidths[2],
        },
        radius: {
            container: s.radius.l,
            complexIcon: s.radius.s,
        },
        size: {
            title: pxToRem(24),
            item: pxToRem(32),
            itemComplex: pxToRem(56),
            width: pxToRem(293),
            complexIcon: pxToRem(36),
            separator: pxToRem(1),
        },
        shadows: {
            container: s.shadows.m,
        },
        padding: {
            containerVertical: s.space.m,
            itemVertical: s.space.m,
            itemHorizontal: s.space.xl,
        },
        gap: {
            item: s.space.m,
            dividerVertical: s.space.s,
        },
        fontSizes: {
            itemLabel: s.fontSizes.bodyM,
            title: s.fontSizes.bodyXs,
            search: s.fontSizes.bodyM,
        },
        fontWeights: {
            itemLabel: s.fontWeights.bodyRegular,
            title: s.fontWeights.bodyBold,
            search: s.fontWeights.bodyRegular,
        },
    }
}

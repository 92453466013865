import { APIError, APIErrorData } from 'data/api/APIError'

export function fetchWithCheckForServerErrors(
    input: RequestInfo,
    init?: RequestInit
): Promise<Response> {
    return fetch(input, init).then(async (response) => {
        if (!response.ok) {
            let errorData: APIErrorData = {}

            try {
                errorData = await response.json()
            } catch {
                errorData.details = await response.text()
            }

            throw new APIError(response.status, errorData)
        }
        return response
    })
}

import React, { useCallback, useMemo, useState } from 'react'

import styled from '@emotion/styled'

import { AttachmentFinalValue } from 'features/views/attributes/hooks/useAttachmentsAttributeDisplayState'

import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'

import { AttributeAttachment } from './types'

const FILESTACK_URL_REGEX1 = /^https:\/\/cdn\.files\.stackerhq\.com\/[a-zA-Z0-9]+$/
const FILESTACK_URL_REGEX2 = /^https:\/\/cdn\.filestackcontent\.com\/[A-Za-z0-9]+$/
const FILESTACK_CDN_BASE_URL = 'https://cdn.filestackcontent.com/'

type AttachmentValue = AttachmentFinalValue | AttributeAttachment | string

type DownloadAttachmentsProps = {
    attachments: AttachmentValue[]
    fieldName?: string
}

export const DownloadAttachmentsButton: React.FC<DownloadAttachmentsProps> = ({
    attachments,
    fieldName,
}) => {
    const [isDownloading, setIsDownloading] = useState(false)

    const getAttachmentUrl = (attachment: AttachmentValue): string => {
        return typeof attachment === 'object' ? attachment.url : attachment
    }

    const filestackUrls = useMemo(() => {
        return attachments
            .map((att: AttachmentValue) => getAttachmentUrl(att))
            .filter((url) => FILESTACK_URL_REGEX1.test(url) || FILESTACK_URL_REGEX2.test(url))
    }, [attachments])

    const onDownloadAll = useCallback(() => {
        setIsDownloading(true)

        downloadFiles(filestackUrls, fieldName)

        setIsDownloading(false)
    }, [filestackUrls, fieldName])

    if (filestackUrls.length < 1) return null

    return (
        <DownloadButton variant="ghost" disabled={isDownloading} onClick={onDownloadAll} size="xs">
            <Icon name="Download" />
            Download all ({attachments.length})
        </DownloadButton>
    )
}

const downloadFiles = (urlList: string[], name: string = 'files') => {
    if (urlList.length < 1) return

    const urls = `[${urlList.join(',')}]`
    const filestackUrl = `${FILESTACK_CDN_BASE_URL}zip/content=filename:"${name}.zip"/${urls}`

    const link = document.createElement('a')
    link.href = filestackUrl
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
}

const DownloadButton = styled(Button)`
    color: #999;
    &:hover {
        color: #fff;
    }
`

import React from 'react'

import { Editor } from '@tiptap/core'

import * as Parts from 'ui/components/Textarea/Textarea.parts'

import { TextareaToolbarItem } from './TextareaToolbarItem'

type TextareaToolbarTextFormatProps = React.ComponentPropsWithoutRef<typeof Parts.ToolbarGroup> & {
    editor: Editor
    disabled?: boolean
}

export const TextareaToolbarTextFormat: React.FC<TextareaToolbarTextFormatProps> = ({
    editor,
    disabled,
    ...props
}) => {
    return (
        <Parts.ToolbarGroup aria-label="Text format" {...props}>
            <TextareaToolbarItem
                icon={{ name: 'Bold' }}
                label="Bold"
                isActive={editor.isActive('bold')}
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={disabled || !editor.can().toggleBold()}
            />
            <TextareaToolbarItem
                icon={{ name: 'Italic' }}
                label="Italic"
                isActive={editor.isActive('italic')}
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={disabled || !editor.can().toggleItalic()}
            />
            <TextareaToolbarItem
                icon={{ name: 'Underline' }}
                label="Underline"
                isActive={editor.isActive('underline')}
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                disabled={disabled || !editor.can().toggleUnderline()}
            />
            <TextareaToolbarItem
                icon={{ name: 'Strikethrough' }}
                label="Strikethrough"
                isActive={editor.isActive('strike')}
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={disabled || !editor.can().toggleStrike()}
            />
        </Parts.ToolbarGroup>
    )
}

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { ScrollArea } from 'ui/components/ScrollArea'

import { useQuickFiltersScrollAreaState } from './hooks/useQuickFiltersScrollAreaState'

import { LeftButtonStyle, RightButtonStyle } from './QuickFiltersScrollArea.css'

type QuickFiltersScrollAreaProps = React.ComponentPropsWithoutRef<typeof Box> & {
    rootProps?: React.ComponentPropsWithoutRef<typeof Box>
}

export const QuickFiltersScrollArea: React.FC<QuickFiltersScrollAreaProps> = React.memo(
    function QuickFiltersScrollArea({ style, children, rootProps, ...props }) {
        const {
            targetRef,
            scrollAreaRef,
            onLeftButtonMouseEnter,
            onLeftButtonMouseLeave,
            onRightButtonMouseEnter,
            onRightButtonMouseLeave,
        } = useQuickFiltersScrollAreaState()

        return (
            <Box position="relative" grow minWidth={0} ref={targetRef} {...rootProps}>
                <Box position="absolute" className={LeftButtonStyle}>
                    <Button
                        variant="secondary"
                        size="s"
                        startIcon={{ name: 'ArrowLeft' }}
                        aria-label="Scroll to the left"
                        onMouseEnter={onLeftButtonMouseEnter}
                        onMouseLeave={onLeftButtonMouseLeave}
                    />
                </Box>
                <Box position="absolute" className={RightButtonStyle}>
                    <Button
                        variant="secondary"
                        size="s"
                        startIcon={{ name: 'ArrowRight' }}
                        aria-label="Scroll to the right"
                        onMouseEnter={onRightButtonMouseEnter}
                        onMouseLeave={onRightButtonMouseLeave}
                    />
                </Box>
                <ScrollArea
                    type="hidden"
                    direction="horizontal"
                    /* Expand element to show nested focus shadows. */
                    style={{
                        padding: '5px',
                        margin: '-5px',
                    }}
                    rootProps={{
                        style: {
                            padding: '5px',
                            margin: '-5px',
                            ...style,
                        },
                        ...props,
                    }}
                    ref={scrollAreaRef}
                >
                    {children}
                </ScrollArea>
            </Box>
        )
    }
)

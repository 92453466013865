export const SHOW_PASTING_BANNER_DURATION_MS = 3000

export const DELETED_FIELDS = [
    {
        label: 'Deleted At',
        api_name: '_date_deleted',
        type: 'datetime',
        is_primary: false,
        options: {},
        connection_options: {},
    },
    {
        label: 'Deleted By',
        api_name: '_deleted_by',
        type: 'user_ref',
        is_primary: false,
        options: {},
        connection_options: {},
    },
]

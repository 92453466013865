import React from 'react'

import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { stopPropagation } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useRichTextAttributeTagState } from './hooks/useRichTextAttributeTagState'
import { AttributeDisplayComponent, RichTextValue } from './types'

type RichTextAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    maxOverflowLength?: number
    showOverflowTooltip?: ResponsiveValue<boolean>
    isLoading?: boolean
    showIcon?: boolean
}

export const RichTextAttributeTag: AttributeDisplayComponent<
    RichTextValue,
    RichTextAttributeTagProps
> = React.memo(function RichTextAttributeTag({
    field,
    value,
    maxOverflowLength,
    showOverflowTooltip = true,
    isLoading,
    showIcon,
    ...props
}) {
    const { icon } = useRichTextAttributeTagState({
        field,
        showIcon,
    })

    const effectiveShowOverflowTooltip = useResponsiveValue(showOverflowTooltip)

    if (!value) return null

    const children = (
        <Box>
            <Skeleton isLoading={isLoading}>
                <Tag
                    size="2xs"
                    type="outline"
                    startIcon={icon ?? { name: 'Text' }}
                    aria-label="View rich text content"
                    onClick={stopPropagation}
                    {...props}
                />
            </Skeleton>
        </Box>
    )

    if (effectiveShowOverflowTooltip) {
        return (
            <Tooltip
                content={
                    <Body
                        style={{
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            lineHeight: 'inherit',
                        }}
                        maxLength={maxOverflowLength}
                    >
                        {value.plainTextContent}
                    </Body>
                }
                zIndex={200}
                side="bottom"
                asChild
            >
                {children}
            </Tooltip>
        )
    }

    return children
}, isEqual)

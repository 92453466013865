import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { HoverContainerStyle, OpacityOnContainerHover } from 'ui/styles/hoverUtils.css'

type OutputSchemaEditorNodeDividerProps = React.ComponentPropsWithoutRef<typeof Box> & {
    onAddClick: () => void
}

export const OutputSchemaEditorNodeDivider: React.FC<OutputSchemaEditorNodeDividerProps> = ({
    onAddClick,
    ...props
}) => {
    return (
        <Box position="relative" width="full" className={HoverContainerStyle} {...props}>
            <Box width="full" className={OpacityOnContainerHover}>
                <Box
                    height="2xs"
                    width="full"
                    background="admin500"
                    fullAbsolute
                    style={{
                        margin: 'auto',
                        zIndex: -1,
                    }}
                />
                <Box width="full" flex center justifyContent="center">
                    <Button
                        type="button"
                        startIcon={{ name: 'Plus' }}
                        size="2xs"
                        onClick={onAddClick}
                    />
                </Box>
            </Box>
        </Box>
    )
}

import React, { useEffect, useState } from 'react'

import { Editable, EditableInput, EditablePreview, Heading } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'

import STYLE_CLASSES from 'v2/ui/styleClasses'

const BASE_CLASS = STYLE_CLASSES.HEADING

const variants = {
    variants: {
        appName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            lineHeight: '24px',
            alignItems: 'center',
            width: '100%',
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        workspaceName: {
            fontSize: ['headings.h300Lg', null, null, 'headings.h300Lg'],
            m: 0,
            ml: 3,
        },
        pageHeading: {
            fontSize: ['page.heading', null, null, 'page.headingLg'],
            m: 0,
        },
        detailPageHeading: {
            fontSize: ['page.heading', null, null, 'page.headingLg'],
            m: 0,
        },
        containerLabel: {
            fontSize: ['container.label', null, null, 'container.labelLg'],
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            m: 0,
        },
        h100: {
            fontSize: ['headings.h100', null, null, 'headings.h100Lg'],
        },
        h200: {
            fontSize: ['headings.h200', null, null, 'headings.h200Lg'],
        },
        h300: {
            fontSize: ['headings.h300', null, null, 'headings.h300Lg'],
        },
        h400: {
            fontSize: ['headings.h400', null, null, 'headings.h400Lg'],
        },
        h500: {
            fontSize: ['headings.h500', null, null, 'headings.h500Lg'],
        },
        widgetContainerTitle: {
            fontSize: ['widgetContainer.title', null, null, 'widgetContainer.titleLg'],
            mt: 0,
            mb: '0.5rem',
        },
        widgetContainerTitleNoMargin: {
            fontSize: 'widgetContainer.title',
            mt: 0,
            mb: 0,
        },
        actionStepTitle: {
            fontSize: ['headings.h100', null, null, 'headings.h100Lg'],
            mt: 0,
        },
        modal: {
            fontSize: ['modal.heading', null, null, 'modal.headingLg'],
            color: 'modal.heading',
            mb: 4,
        },
        sideMenuSubHeading: {
            fontSize: ['admin.sideMenu.subHeading', null, null, 'admin.sideMenu.subHeading'],
            color: 'neutral.1000',
        },
        sideMenuTypeHeading: {
            fontSize: ['admin.sideMenu.heading', null, null, 'admin.sideMenu.headingLg'],
            color: 'admin.sideMenu.heading',
        },
        setupChecklistHeading: {
            fontSize: ['headings.h600', null, null, 'headings.h600Lg'],
            mt: 0,
            mb: 6,
            maxHeight: 60,
        },
        inboxViewHeadingMobile: {
            mt: '1.85rem',
        },
    },
}

const StyledHeading = styled(Heading)`
    ${variant(variants)}
`

const StyledPreview = styled(EditablePreview)`
    ${variant(variants)}
`

const StyledInput = styled(EditableInput)`
    ${variant(variants)}
`

type StackerHeadingProps = Omit<
    React.ComponentPropsWithoutRef<typeof StyledHeading>,
    'children' | 'defaultValue'
> & {
    value?: string
    isEditable?: boolean
    onChange?: (value: string) => void
    onCancel?: () => void
    onSubmit?: () => void
}

const StackerHeading: React.FC<StackerHeadingProps> = ({
    value,
    isEditable,
    onChange,
    onSubmit,
    onCancel,
    size,
    as,
    flex = 1,
    className = '',
    color = 'text.heading',
    valueDecorator,
    ...props
}) => {
    const mergedClassName = `${className} ${BASE_CLASS} ${
        props.variant ? BASE_CLASS + '-' + props.variant : ''
    }`

    const [inputValue, setValue] = useState(value)

    useEffect(() => {
        setValue(value)
    }, [value])
    const HeadingComponent = (p: React.ComponentPropsWithoutRef<typeof StyledHeading>) => (
        <StyledHeading
            flex={flex}
            color={color}
            fontSize={size}
            variant={props.variant}
            as={as}
            p={0}
            wordBreak="break-word"
            {...p}
            className={mergedClassName}
        >
            {value}
            {p.children}
            {valueDecorator}
        </StyledHeading>
    )

    if (isEditable) {
        return (
            <Editable
                defaultValue={value?.trim() ? value : ''}
                value={inputValue?.trim() ? inputValue : ''}
                onSubmit={() => {
                    onChange?.(inputValue ?? '')
                    onSubmit && onSubmit()
                }}
                onChange={(value) => {
                    setValue(value)
                }}
                onCancel={(value) => {
                    setValue(value)
                    onCancel && onCancel()
                }}
                submitOnBlur={true}
                color={color}
                fontSize={size}
                flex={flex}
                {...props}
            >
                <HeadingComponent
                    p={0}
                    variant={props.variant}
                    as={StyledPreview}
                    fontWeight="bold"
                />
                <StyledInput variant={props.variant} />
            </Editable>
        )
    }
    return <HeadingComponent {...props} />
}

export default React.memo(StackerHeading)

import React, { useEffect } from 'react'

import { useUnreadNotificationCount } from 'data/hooks/activities/notifications'
import { BadgeStyle } from 'features/Notifications/Notifications.css'
import { useNotificationPaneContext } from 'features/Notifications/useNotificationPaneContext'
import { useNavContext } from 'features/utils/useNavContext'

import { Box } from 'ui/components/Box'
import { Button, ButtonProps } from 'ui/components/Button'

export default function NotificationButton(props: ButtonProps) {
    const { data: count = 0 } = useUnreadNotificationCount()
    const { isOpen, setIsOpen } = useNotificationPaneContext()
    const { setHasMenuOpen } = useNavContext()

    useEffect(() => {
        setHasMenuOpen(isOpen)
    }, [isOpen, setHasMenuOpen])

    return (
        <Button
            aria-label="Notifications"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
            size="m"
            variant="ghost"
            startIcon={{ name: 'Bell' }}
            position="relative"
            {...props}
        >
            {count > 0 && (
                <Box className={BadgeStyle}>
                    {Math.min(count, 99)}
                    {count > 99 ? '+' : ''}
                </Box>
            )}
        </Button>
    )
}

import { createContext } from 'react'

import type { FilterAction, RecordFiltersState } from './types'

export const RecordFiltersContext = createContext<{
    state: RecordFiltersState
    object: ObjectDto | undefined
    dispatch: (action: FilterAction) => void
    onAddFilter: (filter: Omit<Filter, '_id'>) => void
    onFieldChanged: (filter: Filter & { id: number }) => void
    onDeleteFilter: (id: number) => void
    onResetFilters: (filters: Filter[]) => void
}>({
    state: { filters: [] },
    object: undefined,
    dispatch: () => undefined,
    onAddFilter: () => undefined,
    onFieldChanged: () => undefined,
    onDeleteFilter: () => undefined,
    onResetFilters: () => undefined,
})

import { QueryClient, UseQueryOptions } from 'react-query'

// https://react-query.tanstack.com/docs/api/#querycache
// careful, there are sensible default (ie. refetchOnWindowFocus: true)

export const STALETIME = 1000 * 3600 * 6 // 6 hours

// Used for storybook components
const TEST_DEFAULT_CONFIG = {
    defaultOptions: {
        queries: {
            log: console.log,
            warn: console.warn,
            // ✅ turns retries off
            retry: false,
            cacheTime: Infinity,
        },
        mutations: {
            log: console.log,
            warn: console.warn,
            // ✅ turns retries off
            retry: false,
        },
    },
}

export const DEFAULT_QUERY_CONFIG: UseQueryOptions = {
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    staleTime: STALETIME,
    retry: false,
    keepPreviousData: true, // stops the flashing when re-fetching
}

const DEFAULT_CONFIG = {
    defaultOptions: { queries: DEFAULT_QUERY_CONFIG },
}

// For stack specific queries, we don't want to cache them indefinitely as this will slow down apps
// We cache them for 10 minutes and remove them if they are not used in that time
export const STACK_QUERY_CONFIG: UseQueryOptions = {
    ...DEFAULT_QUERY_CONFIG,
    cacheTime: 1000 * 60 * 10,
}

// We need slightly different config for react query for testing
// const isTestEnv = process.env.JEST_WORKER_ID !== undefined || window.IS_STORYBOOK
// TODO: detect if we're in tests
// @ts-expect-error
const isTestEnv = window.IS_STORYBOOK

export default new QueryClient(isTestEnv ? TEST_DEFAULT_CONFIG : DEFAULT_CONFIG)

import React, { forwardRef } from 'react'

import { Box } from 'ui/components/Box'

import { MenuIcon } from './Dropdown.parts'
import { SlotContentProps } from './types'

type DropdownEndIndicatorSubmenuRef = HTMLDivElement

type DropdownEndIndicatorSubmenuProps = React.ComponentPropsWithoutRef<typeof Box> &
    SlotContentProps & {}

export const DropdownEndIndicatorSubmenu = forwardRef<
    DropdownEndIndicatorSubmenuRef,
    DropdownEndIndicatorSubmenuProps
>((props, ref) => {
    return (
        <Box {...props} ref={ref}>
            <MenuIcon size="m" name="ChevronRight" />
        </Box>
    )
})

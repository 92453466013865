import React from 'react'

import get from 'lodash/get'

import Attribute from 'features/pages/blocks/settings/attributes/RenderRowAttribute'
import { ObjectFieldsFilterV4 as Filters } from 'features/records/components/RecordFilters'
import FieldPicker from 'features/studio/ui/FieldPicker'
import { ObjectPicker } from 'features/studio/ui/ObjectPicker'
import MenuToggle from 'features/views/List/MenuToggle'

import LongTextAttribute from 'v2/ui/components/Attribute/LongTextAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import CheckboxAttribute from './form/Checkbox'
import DropdownAttribute from './form/Dropdown'
import TextAttribute from './form/Text'

export const text = (attributeProps = {}, renderedElementProps = {}) => (
    <Attribute
        field="sortBy"
        label="sort by"
        render={(props) => <TextAttribute label="Sort By" {...props} {...renderedElementProps} />}
        {...attributeProps}
    />
)

export const longText = (attributeProps = {}, renderedElementProps = {}) => (
    <Attribute
        field="sortBy"
        label="sort by"
        render={(props) => (
            <LongTextAttribute
                showRichTextEditor
                enableMarkdown
                mode={modes.editing}
                label="Sort By"
                {...props}
                convertToMarkdown={false}
                height="200px"
                {...renderedElementProps}
            >
                {props.value}
            </LongTextAttribute>
        )}
        {...attributeProps}
    />
)

export const multiline = (attributeProps = {}, renderedElementProps = {}) => (
    <Attribute
        field="sortBy"
        label="sort by"
        render={(props) => (
            <TextAttribute multiline label="Sort By" {...props} {...renderedElementProps} />
        )}
        {...attributeProps}
    />
)

export const boolean = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <CheckboxAttribute {...props} />} />
)

export const options = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <DropdownAttribute {...props} />} />
)

export const objectPicker = (attributeProps) => (
    <Attribute {...attributeProps} render={(props) => <ObjectPicker {...props} />} />
)

export const fieldPicker = (attributeProps) => (
    <Attribute
        {...attributeProps}
        render={({ filter, ...props }) => {
            const objectId =
                props.params.objectId ||
                props.objectId ||
                (props.context.object && props.context.object._sid)
            if (!objectId) {
                return ''
            }
            const combinedFilter = (field) => {
                return !get(field, 'connection_options.is_disabled') && (!filter || filter(field))
            }
            return <FieldPicker objectId={objectId} filter={combinedFilter} {...props} />
        }}
    />
)

// This is a generic configurator wrapper than can be used
// to wrap whatever component is supplied by the Element prop
export const configuratorWrapper = ({ Element, requireObject = true, ...attributeProps }) => (
    <Attribute
        noLabel={!attributeProps.label}
        {...attributeProps}
        render={(props) => {
            const objectId = props.params.objectId
            if (requireObject && !objectId) {
                return ''
            }
            return <Element objectId={objectId} {...props} />
        }}
    />
)

// This is an attribute that will make some layouts invisible
// depending on the dynamic value of the record
export const conditionalVisibility = (attributeProps) => (
    <Attribute
        {...attributeProps}
        render={(props) => {
            const viewType = props.context.viewType

            const isBlankPage = viewType === 'blankpage'

            const object = isBlankPage ? null : props.context.object

            const hasFields = props.field && object
            if (!hasFields && !isBlankPage) return null

            let filters = props.params[props.field] || []

            const label =
                !filters || filters.length === 0
                    ? 'Set conditional visibility'
                    : filters.length + ' visibility filter' + (filters.length > 1 ? 's' : '')
            const fields =
                object?.fields.filter((field) => !field.connection_options?.is_disabled) ?? []

            return (
                <div style={{ height: '27px' }}>
                    <MenuToggle
                        faicon="eye"
                        label={label}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            left: '0px',
                            height: '27px',
                            backgroundColor: '#F4F5F8',
                            textAlign: 'center',
                            color: '#555555',
                            zIndex: 101,
                        }}
                        textStyle={{
                            color: '#555555',
                            marginLeft: '8px',
                        }}
                        toggleMenuCursor="default"
                    >
                        <div style={{ minWidth: '400px', maxWidth: '400px' }}>
                            <Filters
                                object={object}
                                value={filters}
                                showRoleFilter
                                fields={fields}
                                onChange={props.onChange}
                                withHeader={true}
                                place="bottom-end"
                                securityInfoMessage="Conditional visibility lets you show and hide an element, based on a filter."
                                hideTheRecordFilter={true}
                            />
                        </div>
                    </MenuToggle>
                </div>
            )
        }}
    />
)

import React from 'react'

import { Box, BoxProps } from 'ui/components/Box/Box'
import { Checkbox } from 'ui/components/Checkbox'
import { stopPropagation } from 'ui/helpers/utilities'

type TasksCheckboxEditorProps = Omit<BoxProps, 'onChange'> & {
    value: boolean
    onChange: (value: boolean) => void
    isLoading?: boolean
}

export const TasksCheckboxEditor: React.FC<TasksCheckboxEditorProps> = ({
    value,
    onChange,
    isLoading,
    ...props
}) => {
    return (
        <Box onClick={stopPropagation} {...props}>
            <Checkbox checked={value} disabled={isLoading} onCheckedChange={onChange} />
        </Box>
    )
}

import React from 'react'

import { ActionMenuContextProvider } from 'features/views/ListView/Actions/ActionMenuContext'
import { FiltersContextProvider } from 'features/views/ListView/Filters/FiltersContext'
import { SearchContextProvider } from 'features/views/ListView/Search/SearchContext'
import { SortContextProvider } from 'features/views/ListView/Sort/SortContext'

import { CardViewPaginationContextProvider } from './Pagination/CardViewPaginationContext'
import { CardViewContextProvider } from './CardViewContext'
import { CardViewData } from './CardViewData'
import { CardViewListHeader } from './CardViewListHeader'

type CardViewProps = {}

export const CardView: React.FC<CardViewProps> = React.memo(function CardView() {
    return (
        <FiltersContextProvider>
            <SearchContextProvider>
                <SortContextProvider>
                    <CardViewPaginationContextProvider>
                        <CardViewContextProvider>
                            <ActionMenuContextProvider>
                                <CardViewListHeader />
                                <CardViewData />
                            </ActionMenuContextProvider>
                        </CardViewContextProvider>
                    </CardViewPaginationContextProvider>
                </SortContextProvider>
            </SearchContextProvider>
        </FiltersContextProvider>
    )
})

import React, { useImperativeHandle } from 'react'

import { Dropdown, DropdownTrigger } from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useActionContextMenuState } from './hooks/useActionContextMenuState'
import { ActionMenuContent } from './ActionMenuContent'

export type ActionContextMenuHandle = {
    openAt: (x: number, y: number) => void
    close: () => void
}

type ActionContextMenuProps = React.ComponentPropsWithoutRef<typeof ActionMenuContent> & {}

const ActionContextMenuInner = React.forwardRef<ActionContextMenuHandle, ActionContextMenuProps>(
    function ActionContextMenuInner(props, ref) {
        const { openAt, close, isOpen, setIsOpen, posX, posY, isDisabled } =
            useActionContextMenuState()

        useImperativeHandle(
            ref,
            () => ({
                openAt,
                close,
            }),
            [close, openAt]
        )

        if (isDisabled || !isOpen) return null

        return (
            <Dropdown open={isOpen} onOpenChange={setIsOpen}>
                <DropdownTrigger
                    ref={(el) => {
                        if (!el) return

                        // This is a hack to make the dropdown trigger behave like a fixed element,
                        // even if the parent has transform set.
                        el.getBoundingClientRect = () => {
                            return {
                                width: 0,
                                height: 0,
                                x: posX,
                                left: posX,
                                bottom: posY,
                                y: posY,
                                right: posX,
                                top: posY,
                            } as DOMRect
                        }
                    }}
                    position="fixed"
                    pointerEvents="none"
                    display="none"
                />
                <ActionMenuContent onCloseAutoFocus={stopPropagation} {...props} />
            </Dropdown>
        )
    }
)

export const ActionContextMenu = React.memo(ActionContextMenuInner)

import React, { useCallback } from 'react'

import { useActionButton } from 'features/actions/hooks/useActionButton'

import { DropdownItem } from 'ui/components/Dropdown'

type ActionMenuItemActionButtonRef = HTMLDivElement

type ActionMenuItemActionButtonProps = React.ComponentPropsWithoutRef<typeof DropdownItem> & {
    action: ActionDto
    record: RecordDto
    includeFields: string[]
}

export const ActionMenuItemActionButton = React.forwardRef<
    ActionMenuItemActionButtonRef,
    ActionMenuItemActionButtonProps
>(({ action, record, includeFields, onClick, ...props }, ref) => {
    const { executeAction } = useActionButton({
        action,
        recordId: record._sid,
        record,
        includeFields,
    })

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()

            executeAction()
            onClick?.(e)
        },
        [executeAction, onClick]
    )

    return (
        <DropdownItem
            ref={ref}
            onClick={handleClick}
            label={action.name}
            startIcon={{ name: 'Workflow' }}
            {...props}
        />
    )
})

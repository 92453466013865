import { useContext, useMemo } from 'react'

import { LookupFilterContext } from './lookupFilterContext'

export const useLookupFilter = () => {
    const { searchString, setSearchString, isFetching, setIsFetching } =
        useContext(LookupFilterContext)

    return useMemo(
        () => ({
            searchString,
            setSearchString,
            isFetching,
            setIsFetching,
        }),
        [isFetching, searchString, setIsFetching, setSearchString]
    )
}

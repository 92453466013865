import React from 'react'

import type { IconName, IconStyle } from '@fortawesome/fontawesome-common-types'

import { StandardComponentProps, transformStandardProps } from 'ui/helpers/styles'

export type IconNameVariantType = { name: IconName; variant?: IconStyle }

type IconProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> &
    StandardComponentProps &
    IconNameVariantType

export const Icon: React.FC<IconProps> = ({ name, variant = 'solid', ...props }) => {
    const { className, ...otherProps } = transformStandardProps(props)
    return (
        <i
            className={`fa-${variant} fa-${name} ${className}`}
            aria-hidden="true"
            {...otherProps}
        ></i>
    )
}

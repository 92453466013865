import React, { useEffect, useRef, useState } from 'react'
// @ts-ignore
import ContentEditable from 'react-sane-contenteditable'

import { selectTextInElement } from 'utils/selectTextInElement'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { WorkflowTitleStyle } from './WorkflowTitle.css'

type WorkflowTitleProps = React.ComponentPropsWithoutRef<typeof Box> & {
    title: string
    onChange: (title: string) => void
    autoFocus?: boolean
}

export const WorkflowTitle: React.FC<WorkflowTitleProps> = ({
    title,
    onChange,
    autoFocus = false,
    ...props
}) => {
    const titleInputRef = useRef<{ _element: HTMLElement; setCaret: () => void }>(null)

    const [isEditable, setIsEditable] = useState(autoFocus)
    const [newTitle, setNewTitle] = useState(title)

    useEffect(() => {
        setNewTitle(title)

        // This is a hack to force the contenteditable to update when not in edit mode.
        const titleRef = titleInputRef.current
        if (titleRef) titleRef._element.textContent = title
    }, [title])

    const handleCancel = () => {
        if (!isEditable) return

        setIsEditable(false)
        setNewTitle(title)
    }

    const handleToggleEditable = () => {
        if (isEditable) {
            handleCancel()
            return
        }

        setIsEditable(true)

        queueMicrotask(() => {
            const ref = titleInputRef.current
            if (!ref) return

            ref._element.focus()
            ref.setCaret()
        })
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            handleCancel()
        }
    }

    const handleCommit = (e: React.MouseEvent) => {
        // If the user clicked on the edit button, don't commit the change.
        const target = e.relatedTarget as HTMLElement | null
        if (target?.classList.contains('workflow__title__edit-button')) {
            return
        }

        const newValue = newTitle.trim()
        setNewTitle(newValue)

        onChange(newValue)
        setIsEditable(false)
    }

    return (
        <Box fontSize="headlineXs" flex center {...props}>
            <ContentEditable
                content={newTitle}
                editable={isEditable}
                onChange={(_: unknown, value: string) => setNewTitle(value)}
                ref={titleInputRef}
                multiLine={false}
                caretPosition="end"
                onDoubleClick={() => setIsEditable(true)}
                onBlur={handleCommit}
                onKeyDown={handleKeyDown}
                autoFocus={autoFocus}
                className={WorkflowTitleStyle({ isEditable })}
                onFocus={(e: React.FocusEvent<HTMLElement>) => {
                    const target = e.target as HTMLElement
                    queueMicrotask(() => selectTextInElement(target))
                }}
            />
            <Button
                type="button"
                variant="ghost"
                size="xs"
                startIcon={{ name: isEditable ? 'X' : 'Pencil' }}
                justifyContent="center"
                color={isEditable ? 'text' : 'textWeakest'}
                onClick={handleToggleEditable}
                className="workflow__title__edit-button"
                noShrink
            />
        </Box>
    )
}

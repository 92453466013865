import React from 'react'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { EmailVerificationPage } from 'features/auth/workspace/EmailVerificationPage'

type EmailVerificationCheckProps = {
    children: React.ReactNode
}

export const EmailVerificationCheck: React.FC<EmailVerificationCheckProps> = ({ children }) => {
    const { user, isEmailVerified } = useAuthContext()

    if (!!user && !isEmailVerified) {
        return <EmailVerificationPage />
    }

    return <>{children}</>
}

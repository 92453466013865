import { useEffect, useRef, useState } from 'react'

export function useOverflowing(element: HTMLElement | null): {
    left: boolean
    right: boolean
    up: boolean
    down: boolean
} {
    const [overflow, setOverflow] = useState<{
        left: boolean
        right: boolean
        up: boolean
        down: boolean
    }>({ left: false, right: false, up: false, down: false })
    const prevOverflow = useRef<{ left: boolean; right: boolean; up: boolean; down: boolean }>(
        overflow
    )

    useEffect(() => {
        function checkOverflow() {
            if (element) {
                const el = element

                const isLeftOverflowing = el.scrollLeft > 0
                const isRightOverflowing = el.scrollLeft + el.clientWidth < el.scrollWidth
                const isUpOverflowing = el.scrollTop + el.clientHeight < el.scrollHeight
                const isDownOverflowing = el.scrollTop > 0

                if (
                    isLeftOverflowing !== prevOverflow.current.left ||
                    isRightOverflowing !== prevOverflow.current.right ||
                    isUpOverflowing !== prevOverflow.current.up ||
                    isDownOverflowing !== prevOverflow.current.down
                ) {
                    setOverflow({
                        left: isLeftOverflowing,
                        right: isRightOverflowing,
                        up: isUpOverflowing,
                        down: isDownOverflowing,
                    })
                }

                prevOverflow.current = {
                    left: isLeftOverflowing,
                    right: isRightOverflowing,
                    up: isUpOverflowing,
                    down: isDownOverflowing,
                }
            }
        }

        checkOverflow()
        element?.addEventListener('scroll', checkOverflow)

        return () => element?.removeEventListener('scroll', checkOverflow)
    }, [element])

    return overflow
}

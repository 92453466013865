import React from 'react'

import { DropdownItem } from 'ui/components/Dropdown'

import { useQuickFilterCheckboxState } from './hooks/useQuickFilterCheckboxState'
import { QuickFilter, QuickFilterButton, QuickFilterContent } from './QuickFiltersItemBase'
import { QuickFilterComponent } from './types'

type QuickFilterCheckboxProps = {}

export const QuickFilterCheckbox: QuickFilterComponent<QuickFilterCheckboxProps> = ({ field }) => {
    const { value, isActive, label, onFilterRemove, onSetFilterValue, filterOptions } =
        useQuickFilterCheckboxState({
            field,
        })

    return (
        <QuickFilter>
            <QuickFilterButton isActive={isActive} onRemove={onFilterRemove}>
                {label}
            </QuickFilterButton>
            <QuickFilterContent>
                {filterOptions.map((item) => (
                    <DropdownItem
                        key={item.value}
                        multiSelect
                        label={item.label}
                        closeOnSelect
                        checked={value === item.value}
                        onCheckedChange={(checked) => {
                            onSetFilterValue(item.value, checked)
                        }}
                    />
                ))}
            </QuickFilterContent>
        </QuickFilter>
    )
}

import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

type UseListHeaderPortalHeaderImageStateOptions = {
    src: string
}

export function useListHeaderPortalHeaderImageState(
    options: UseListHeaderPortalHeaderImageStateOptions
) {
    const { src } = options

    const [isLoaded, setIsLoaded] = useState(false)
    useLayoutEffect(() => {
        // Reset the loaded state when the image changes.
        setIsLoaded(false)
    }, [src])

    const onLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
        const image = e.target as HTMLImageElement
        if (!image.complete) return

        setIsLoaded(true)
    }, [])

    return useMemo(
        () => ({
            isLoaded,
            onLoad,
        }),
        [onLoad, isLoaded]
    )
}

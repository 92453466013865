import { AppContextProps } from './appContextType'

export const defaultContext: AppContextProps = {
    workspaceAccount: null,
    selectedStack: null,
    workspaceZone: null,
    lastVisitedStack: null,
    isLoading: false,
    initialLoadComplete: false,
    setSelectedStack: () => {},
    loadingAccountFailed: false,
    isSubscribed: false,
}

import React, { FC, ReactNode } from 'react'

import { Grid } from '@chakra-ui/react'
import ProfileCard from 'v2/views/Detail/ProfileCard'

import { Box } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { DocumentDetailLayout } from './DocumentDetailLayout'

type Props = {
    title: string
    layout: string
    showControls: boolean
    context: any
    config: DetailViewOptions
    recordPermissions: any
    header: ReactNode
    profileImage: ReactNode
    buttons: ReactNode
    setConfig: (config: Partial<DetailViewOptions>, setDirty: boolean) => void
    isRecordList?: boolean
}

const LayoutWrapper: FC<Props> = ({
    title,
    layout,
    showControls,
    context,
    config,
    recordPermissions,
    header,
    profileImage,
    setConfig,
    buttons,
    children,
    isRecordList,
}) => {
    if (layout === 'profile') {
        return (
            <>
                {header}
                <Grid
                    templateColumns={[
                        'minmax(0, 1fr)',
                        'minmax(0, 1fr)',
                        'minmax(0, 1fr)',
                        'minmax(0, 1fr) minmax(0, 3fr)',
                    ]}
                    gap={4}
                    className={ONBOARDING_CLASSES.DETAIL_VIEW_CONTAINER}
                >
                    <Box mt={[4, 4, 4, 6]}>
                        <ProfileCard
                            config={config}
                            context={context}
                            showControls={showControls}
                            setConfig={setConfig}
                            profileImage={profileImage}
                            title={title}
                            recordPermissions={recordPermissions}
                        />
                    </Box>
                    <Box>{children}</Box>
                </Grid>
            </>
        )
    } else if (layout === 'document') {
        return context.record ? (
            <DocumentDetailLayout
                header={header}
                config={config}
                context={context}
                showControls={showControls}
                setConfig={setConfig}
                profileImage={profileImage}
                recordPermissions={recordPermissions}
                buttons={buttons}
                isRecordList={isRecordList}
            >
                {children}
            </DocumentDetailLayout>
        ) : null
    } else {
        return (
            <>
                {header}
                <Grid
                    templateColumns={['minmax(0, 1fr)']}
                    className={ONBOARDING_CLASSES.DETAIL_VIEW_CONTAINER}
                >
                    {children}
                </Grid>
            </>
        )
    }
}

export default LayoutWrapper

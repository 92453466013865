// @ts-strict-ignore
import React, { forwardRef, useEffect, useMemo, useState } from 'react'

import { composeRefs } from '@radix-ui/react-compose-refs'
import type { DropdownMenuSubContentProps } from '@radix-ui/react-dropdown-menu'
import classNames from 'classnames'

import { ScrollAreaStyle } from 'ui/components/Menu/Menu.css'
import { ScrollArea } from 'ui/components/ScrollArea'
import { StandardComponentProps } from 'ui/helpers/styles'
import { useTheme } from 'ui/styling/themes/useTheme'

import { Portal, SubContent } from './Dropdown.parts'
import { useDropdownContentContext } from './dropdownContentContext'
import { DropdownContentContextProvider } from './DropdownContentContextProvider'
import { useDropdownContext } from './dropdownContext'
import { DropdownEmptySearch } from './DropdownEmptySearch'
import { useContentClose } from './useContentClose'
import { useContentKeyDown } from './useContentKeyDown'
import { filterDropdownItems } from './utils'

type DropdownSubContentRef = HTMLDivElement

type DropdownSubContentProps = Omit<DropdownMenuSubContentProps, 'asChild'> &
    StandardComponentProps & {
        usePortal?: boolean
        head?: React.ReactNode
        searchQuery?: string
        setSearchQuery?: (value: string) => void
        scrollCheckedIntoView?: boolean
    }

const DropdownSubContentInner = forwardRef<DropdownSubContentRef, DropdownSubContentProps>(
    (
        {
            usePortal = true,
            sideOffset = 4,
            head,
            children,
            onEscapeKeyDown,
            className,
            scrollCheckedIntoView,
            ...props
        },
        ref
    ) => {
        const { searchQuery, isSearchQueryControlled } = useDropdownContentContext()

        const filteredChildren = useMemo(() => {
            const childrenArray = React.Children.toArray(children)
            if (isSearchQueryControlled) return childrenArray

            return filterDropdownItems(childrenArray as React.ReactElement[], searchQuery)
        }, [children, isSearchQueryControlled, searchQuery])

        const handleKeyDown = useContentKeyDown()
        const handleEscapeKeyDown = useContentClose(onEscapeKeyDown)

        const hasSearchQuery = searchQuery.trim().length > 0

        const { isOpen } = useDropdownContext()!

        const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)

        useEffect(() => {
            if (!isOpen || !scrollCheckedIntoView) return

            // Automatically scroll to the selected item.
            const checkedItem = contentRef?.querySelector('[data-state="checked"]')
            if (!checkedItem) return

            requestAnimationFrame(() => {
                checkedItem.scrollIntoView({
                    block: 'start',
                })
            })
        }, [isOpen, contentRef, scrollCheckedIntoView])

        const { themeClassName } = useTheme()

        const content = (
            <SubContent
                ref={composeRefs(setContentRef as React.RefCallback<HTMLDivElement>, ref)}
                sideOffset={sideOffset}
                onKeyDown={handleKeyDown}
                onEscapeKeyDown={handleEscapeKeyDown}
                {...props}
                className={classNames(themeClassName, 'ag-custom-component-popup', className)}
            >
                <ScrollArea direction="vertical" className={ScrollAreaStyle} type="auto">
                    {head}
                    {filteredChildren}
                </ScrollArea>
                {hasSearchQuery && filteredChildren.length === 0 && <DropdownEmptySearch />}
            </SubContent>
        )

        if (usePortal) {
            return <Portal>{content}</Portal>
        }

        return content
    }
)

export const DropdownSubContent: React.FC<DropdownSubContentProps> = ({
    searchQuery,
    setSearchQuery,
    ...props
}) => {
    return (
        <DropdownContentContextProvider searchQuery={searchQuery} setSearchQuery={setSearchQuery}>
            <DropdownSubContentInner {...props} />
        </DropdownContentContextProvider>
    )
}

import React, { useCallback, useMemo, useReducer } from 'react'

import { useListViewContext } from 'features/views/ListView/useListViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useInlineFiltersAvailableFields } from './hooks/useInlineFiltersAvailableFields'
import { FiltersContext } from './useFiltersContext'
import { getInlineFilterType } from './utils'

export type FiltersContextProviderProps = {}

export const FiltersContextProvider: React.FC<FiltersContextProviderProps> = ({ children }) => {
    const { view, additionalFilters } = useListViewContext()

    const adminFilter = view.options.filters
    const [inlineFilters, dispatch] = useReducer(filtersReducer, [])

    const effectiveFilters = ([] as Filter[])
        .concat(additionalFilters ?? [])
        .concat(adminFilter ?? [])
        .concat(inlineFilters)
    const effectiveFiltersMemo = useDeepEqualsMemoValue(effectiveFilters)

    const addFilter = useCallback((filter: Filter) => {
        dispatch({ type: 'ADD_FILTER', payload: filter })
    }, [])

    const removeFilter = useCallback((id: number) => {
        dispatch({ type: 'REMOVE_FILTER', id })
    }, [])

    const updateFilter = useCallback((filter: Filter) => {
        dispatch({ type: 'UPDATE_FILTER', payload: filter })
    }, [])

    const setFilters = useCallback((filters: Filter[]) => {
        dispatch({ type: 'SET_FILTERS', payload: filters })
    }, [])

    const clearFilters = useCallback(() => {
        dispatch({ type: 'CLEAR_FILTERS' })
    }, [])

    const inlineFilterType = getInlineFilterType(view.options)

    const availableInlineFilterFields = useInlineFiltersAvailableFields(inlineFilterType)

    const value = useMemo(
        () => ({
            filters: effectiveFiltersMemo,
            inlineFilters,
            addFilter,
            removeFilter,
            updateFilter,
            setFilters,
            clearFilters,
            availableInlineFilterFields,
            inlineFilterType,
        }),
        [
            addFilter,
            inlineFilters,
            clearFilters,
            effectiveFiltersMemo,
            removeFilter,
            setFilters,
            updateFilter,
            availableInlineFilterFields,
            inlineFilterType,
        ]
    )

    return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
}

type AddFilterAction = {
    type: 'ADD_FILTER'
    payload: Filter
}

type RemoveFilterAction = {
    type: 'REMOVE_FILTER'
    id: number
}

type UpdateFilterAction = {
    type: 'UPDATE_FILTER'
    payload: Filter
}

type SetFiltersAction = {
    type: 'SET_FILTERS'
    payload: Filter[]
}

type ClearFiltersAction = {
    type: 'CLEAR_FILTERS'
}

type FilterAction =
    | AddFilterAction
    | RemoveFilterAction
    | UpdateFilterAction
    | ClearFiltersAction
    | SetFiltersAction

function filtersReducer(state: Filter[], action: FilterAction): Filter[] {
    switch (action.type) {
        case 'ADD_FILTER':
            return [
                ...state,
                {
                    _id: Math.random(),
                    ...action.payload,
                },
            ]
        case 'REMOVE_FILTER':
            return state.filter((filter) => filter._id !== action.id)
        case 'UPDATE_FILTER':
            return state.map((filter) =>
                filter._id === action.payload._id ? action.payload : filter
            )
        case 'CLEAR_FILTERS':
            return []
        case 'SET_FILTERS':
            return action.payload.map((filter) => ({
                _id: Math.random(),
                ...filter,
            }))
        default:
            return state
    }
}

import React from 'react'

import { useTableViewLoaderState } from './hooks/useTableViewLoaderState'
import { TableViewRow } from './TableViewRow'

type TableViewLoaderProps = {}

export const TableViewLoader: React.FC<TableViewLoaderProps> = () => {
    const { placeholderRows } = useTableViewLoaderState()

    return (
        <>
            {placeholderRows.map((index) => (
                <TableViewRow key={index} isLoading />
            ))}
        </>
    )
}

import React from 'react'

import { Icon } from 'ui/components/Icon'
import { makeComponent } from 'ui/helpers/recipes'

import { SpinnerStyles } from './Spinner.css'

export const SpinnerBase = makeComponent(Icon, SpinnerStyles)

export type SpinnerProps = Omit<React.ComponentPropsWithoutRef<typeof SpinnerBase>, 'name'> & {}

export const Spinner = React.forwardRef<SVGSVGElement, SpinnerProps>((props, ref) => {
    return <SpinnerBase name="Loader2" {...props} ref={ref} />
})

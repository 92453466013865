import { Table } from '@tiptap/extension-table'

import { TableStyle } from './TableExtension.css'

export function createTableExtension() {
    return Table.configure({
        HTMLAttributes: {
            class: TableStyle,
        },
    })
}

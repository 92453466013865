import React from 'react'

import { NoRecords } from 'v2/ui/svgs/list-view-empty-states/NoRecords'

import { Button } from 'ui/components/Button'

import { useNoRecordsEmptyStateState } from './hooks/useNoRecordsEmptyStateState'
import { BaseEmptyState } from './BaseEmptyState'

type NoRecordsEmptyStateProps = React.ComponentPropsWithoutRef<typeof BaseEmptyState> & {}

export const NoRecordsEmptyState: React.FC<NoRecordsEmptyStateProps> = React.memo(
    function NoRecordsEmptyState(props) {
        const { onClickAddNew } = useNoRecordsEmptyStateState()

        return (
            <BaseEmptyState
                image={<NoRecords />}
                title="This list is empty"
                description="Start by creating your first record."
                footer={
                    <Button
                        variant="primary"
                        size="m"
                        startIcon={{ name: 'Plus' }}
                        onClick={onClickAddNew}
                    >
                        Add new
                    </Button>
                }
                {...props}
            />
        )
    }
)

import React, { FC, useContext } from 'react'

import { ChartsBlockEditor } from 'v2/blocks/blockTypes/view/aggregationBlocks/ChartsBlock/ChartsBlockEditor'
import { AggregationBlockContext } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/AggregationBlockContext'
import { MetricsBlockEditor } from 'v2/blocks/blockTypes/view/aggregationBlocks/MetricsBlock/MetricsBlockEditor'
import { ViewLayoutContext } from 'v2/blocks/types'

import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { LayoutObjectEditor } from 'features/utils/LayoutObjectEditor'
import {
    COLLAPSIBLE_CHARTS_EDITOR_ID,
    COLLAPSIBLE_METRICS_EDITOR_ID,
} from 'features/views/ViewHeader/ViewHeaderModular/constants'

type Props = {
    isOpen: boolean
    viewLayoutContext?: ViewLayoutContext
    metrics: AggregateBlockAttributes[]
    updatePageHeader: (
        widgetsType: PageHeaderModular['collapsibleWidgetsType'],
        widgets: AggregateBlockAttributes[]
    ) => void
    onClose: () => void
}

export const ViewHeaderModularBlocksEditor: FC<Props> = ({
    isOpen,
    viewLayoutContext,
    metrics,
    updatePageHeader,
    onClose,
}) => {
    const { editingWidgetOrder, onStopEditingWidget } = useContext(AggregationBlockContext)
    const { activeEditor, closeEditor } = useContext(LayoutEditorContext)

    const onCloseLayoutObjectEditor = () => {
        if (typeof editingWidgetOrder === 'number') {
            onStopEditingWidget()
            return
        }

        closeEditor()
        onClose()
    }

    const onChange = (
        widgetsType: PageHeaderModular['collapsibleWidgetsType'],
        widgets: AggregateBlockAttributes[]
    ) => {
        updatePageHeader(widgetsType, widgets)
    }

    if (!viewLayoutContext) {
        return null
    }

    return (
        <LayoutObjectEditor
            isOpen={isOpen}
            title={activeEditor?.title}
            editorId={activeEditor?.id}
            onCloseOverride={onCloseLayoutObjectEditor}
        >
            {activeEditor?.id === COLLAPSIBLE_METRICS_EDITOR_ID && (
                <MetricsBlockEditor
                    value={metrics}
                    context={viewLayoutContext}
                    onChange={(metrics) => onChange('metrics', metrics)}
                />
            )}
            {activeEditor?.id === COLLAPSIBLE_CHARTS_EDITOR_ID && (
                <ChartsBlockEditor
                    value={metrics}
                    context={viewLayoutContext}
                    onChange={(charts) => onChange('charts', charts)}
                />
            )}
        </LayoutObjectEditor>
    )
}

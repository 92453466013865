import React from 'react'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'

type LayoutEditorSchemaOutdatedProps = {}

export const LayoutEditorSchemaOutdated: React.FC<LayoutEditorSchemaOutdatedProps> = () => {
    return (
        <Box py="3xl" px="4xl" flex center>
            <Banner
                icon={{
                    name: 'XCircle',
                }}
                title="Aplication outdated"
                helper="You are using an outdated version of our application. Please refresh the page to use the latest."
                type="error"
                link={{
                    label: 'Refresh page',
                    href: window.location.pathname,
                }}
            />
        </Box>
    )
}

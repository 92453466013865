import { getUrl, Urls } from 'app/UrlService'

export function getAfterCreateRedirect(stack: StackDto, preselectedDataSourceType: string) {
    let url = getUrl(Urls.Home, stack)

    // if there is a preselected data source type set,
    // no need to offer the choice to the user on the splash screen,
    // just go ahead and show the correct add data source modal by adding the 'data' query param
    if (preselectedDataSourceType) {
        url += `${url.indexOf('?') > -1 ? '&' : '?'}data=${preselectedDataSourceType}`
    }

    return url
}

import React, { useEffect, useState } from 'react'

import type { ContextMenuCheckboxItemProps as BaseProps } from '@radix-ui/react-context-menu'

import { IconName } from 'ui/components/Icon/Icon'
import { StandardComponentProps } from 'ui/helpers/styles'

import {
    CheckboxItem,
    CheckIcon,
    ItemIndicator,
    ItemIndicatorPlaceholder,
    LeftSlot,
    MenuIcon,
    RightSlot,
} from './ContextMenu.parts'

type ContextMenuCheckboxProps = Omit<BaseProps, 'asChild'> &
    StandardComponentProps & {
        icon?: IconName
    }

export const ContextMenuCheckbox: React.FC<ContextMenuCheckboxProps> = ({
    icon,
    children,
    defaultChecked,
    checked,
    onCheckedChange,
    ...props
}) => {
    const isControlled = typeof checked !== 'undefined'
    const [isChecked, setIsChecked] = useState(checked ?? defaultChecked ?? false)
    useEffect(() => {
        setIsChecked(checked ?? defaultChecked ?? false)
    }, [checked, defaultChecked])

    const handleCheckedChange = (checked: boolean) => {
        if (!isControlled) {
            setIsChecked(checked)
        }
        onCheckedChange?.(checked)
    }

    return (
        <CheckboxItem onCheckedChange={handleCheckedChange} checked={isChecked} {...props}>
            {icon && (
                <LeftSlot>
                    <MenuIcon name={icon} />
                </LeftSlot>
            )}
            {children}
            <RightSlot>
                <ItemIndicator forceMount>
                    {isChecked ? (
                        <CheckIcon
                            name="Check"
                            size="m"
                            color={props.disabled ? undefined : 'iconTheme'}
                        />
                    ) : (
                        <ItemIndicatorPlaceholder />
                    )}
                </ItemIndicator>
            </RightSlot>
        </CheckboxItem>
    )
}

export type DisplayTypes = 'normal' | 'pill' | 'badge'

export function shouldShowDisplayTypeOption(
    field: FieldDto,
    viewDisplayType: ListViewDisplay
): boolean {
    return (
        ['card', 'kanban'].includes(viewDisplayType) &&
        !['string', 'long_text'].includes(field.type)
    )
}
export function getDefaultDisplayType(
    field?: FieldDto,
    viewDisplayType?: ListViewDisplay
): DisplayTypes {
    if (!field || !viewDisplayType) return 'normal'

    return ['card', 'kanban'].includes(viewDisplayType) &&
        !['string', 'long_text'].includes(field.type)
        ? 'pill'
        : 'normal'
}

import { getDefaultPageSize as getDefaultListPageSize } from 'v2/ui/components/List/utils'

export function getServerDefaultPageSize(
    displayType: string,
    showDetailView: boolean,
    view?: ViewDto
): number | undefined {
    const enablePaging = displayType !== 'kanban'
    if (enablePaging) {
        let clientSidePageSize = view?.options?.pageSize
        if (!clientSidePageSize) {
            clientSidePageSize = getDefaultListPageSize(displayType, view?.type)
        }

        return showDetailView ? 1 : clientSidePageSize
    }
    return undefined
}

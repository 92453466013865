import React from 'react'

export type GlobalModalProps = {
    modalKey: string
}

export const GlobalModalContext = React.createContext<{
    registerModal: (key: string, Component?: React.ComponentType<GlobalModalProps>) => void
    unregisterModal: (key: string) => void
}>({
    registerModal: () => {},
    unregisterModal: () => {},
})

import React, { FC, useCallback, useMemo } from 'react'

import { useTheme } from '@chakra-ui/react'
import {
    Cell as PieSection,
    Legend as RechartsLegend,
    Pie,
    PieChart,
    Tooltip as RechartsTooltip,
} from 'recharts'
import { DEFAULT_MAX_ITEMS } from 'v2/blocks/blockTypes/view/aggregationBlocks/ChartsBlock/constants'

import { renderNumberValueAsText } from 'features/charts/NumberValue'
import { COLOR_PALETTE } from 'features/charts/recharts/constants'
import { PieChartContainer } from 'features/charts/recharts/ui'
import { getTooltipLabel } from 'features/charts/recharts/utils/getTooltipLabel'

import {
    NONE_VALUE_SECTION_COLOR,
    NONE_VALUE_SECTION_LABEL,
    NONE_VALUE_SECTION_LABEL_COLOR,
} from './constants'
import { Label } from './Label'
import { Legend } from './Legend'
import { calculatePercentages, prepareData } from './utils'

import 'features/charts/recharts/pie.css'

const RechartsPie: FC<RechartsPieParam> = ({
    metrics,
    display,
    width,
    height,
    config,
    chartObject,
    options: {
        legend,
        maxItems = DEFAULT_MAX_ITEMS,
        showLabels,
        innerRadius,
        outerRadius,
        hideLegendForSmallSlices = false,
        startAngle = 0,
    },
    legacy = false,
}) => {
    const theme = useTheme()

    const percentages = useMemo(() => calculatePercentages(metrics.data), [metrics.data])
    const chartData = useMemo(
        () =>
            prepareData(
                metrics.data,
                {
                    maxItems,
                    percentages,
                    display,
                    metrics,
                    config,
                    fields: chartObject?.fields,
                },
                theme
            ),
        [chartObject?.fields, config, display, maxItems, metrics, percentages, theme]
    )

    const formatter = useCallback(
        (value: string, name: string, payload: any): [string, string] => {
            const label = getTooltipLabel(name, payload, config?.group?.bucketBy)
            const formattedValue = Number.isNaN(value)
                ? value
                : renderNumberValueAsText({
                      value: Number(value),
                      display,
                      config,
                      metrics,
                  })
            const valueWithPercent = `${formattedValue} (${payload?.payload.percentage}%)`
            return [valueWithPercent, label]
        },
        [config, display, metrics]
    )

    const hasLegendOnSides = legend === 'right' || legend === 'left'
    const numSections = Math.min(chartData.length, maxItems)
    return (
        <PieChartContainer>
            <PieChart width={width} height={height}>
                <Pie
                    data={chartData}
                    nameKey="name"
                    dataKey="value"
                    label={(props) => (
                        <Label
                            {...props}
                            showLabels={showLabels}
                            hideLegendForSmallSlices={hideLegendForSmallSlices}
                            chartWidth={width}
                        />
                    )}
                    labelLine={false}
                    isAnimationActive={false}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    cx={!hasLegendOnSides ? '50%' : legend === 'right' ? '40%' : '70%'}
                    endAngle={360 + startAngle}
                    startAngle={startAngle}
                >
                    {chartData.map((item, idx) => {
                        let fill = item.color
                        if (legacy) {
                            fill =
                                item.name === NONE_VALUE_SECTION_LABEL
                                    ? NONE_VALUE_SECTION_LABEL_COLOR
                                    : item._isOther
                                      ? NONE_VALUE_SECTION_COLOR
                                      : COLOR_PALETTE[idx % numSections]
                        }
                        return <PieSection key={`pie-chart-section-${idx}`} fill={fill} />
                    })}
                </Pie>
                <RechartsTooltip isAnimationActive={false} formatter={formatter} />
                {(legend === 'right' || legend === 'left') && (
                    <RechartsLegend
                        verticalAlign="middle"
                        align={legend}
                        width={200}
                        // @ts-expect-error
                        content={<Legend />}
                    />
                )}
                {/* @ts-expect-error */}
                {legend === 'bottom' && <Legend verticalAlign="bottom" />}
            </PieChart>
        </PieChartContainer>
    )
}

export default RechartsPie

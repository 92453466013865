import { Mark as ProseMirrorMark } from '@tiptap/pm/model'
import { EditorState, Plugin } from '@tiptap/pm/state'
import { EditorView } from '@tiptap/pm/view'
import { Mark, mergeAttributes } from '@tiptap/react'
import { v4 as uuid } from 'uuid'

import { ActivityType } from 'data/hooks/activityTypes'
import { deserializeActivityLocationParameters } from 'features/Activity/utils'
import { removeMarkFromSlice } from 'features/tiptap/utilities'

import { ActivityBubblePlugin, ChangeCommentCountsEvent, CloseEvent } from './ActivityBubblePlugin'
import { getActivityMark } from './utils'

import { ActivityMarkStyle } from './Activities.css'

export type ActivityMarkOptions = {
    hideMark?: boolean
}

declare module '@tiptap/core' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Commands<ReturnType> {
        activity: {
            setActivityComment: () => ReturnType
            openActivityBubble: () => ReturnType
        }
    }
}

/**
 * This extension is responsible for adding the activity mark to the schema,
 * as well as opening the activity bubble when the mark is clicked, and all the operations related to the mark.
 */
export function createActivityMark(options: ActivityMarkOptions = {}) {
    return Mark.create({
        name: 'activity',

        keepOnSplit: true,
        exitable: true,
        inclusive: false,
        excludes: 'activity',

        addAttributes() {
            return {
                id: {
                    default: null,
                    parseHTML: (el) => el.getAttribute('data-id'),
                    renderHTML: (attrs) => ({ 'data-id': attrs.id }),
                },
                activityType: {
                    default: null,
                    parseHTML: (el) => el.getAttribute('data-activity-type'),
                    renderHTML: (attrs) => ({ 'data-activity-type': attrs.activityType }),
                },
                // This can help us differentiate between multiple marks.
                addedAt: {
                    default: null,
                    parseHTML: (el) => el.getAttribute('data-added-at'),
                    renderHTML: (attrs) => ({ 'data-added-at': attrs.addedAt }),
                },
                hidden: {
                    default: false,
                    parseHTML: (el) => el.getAttribute('data-hidden') === 'true',
                    renderHTML: (attrs) => ({ 'data-hidden': attrs.hidden }),
                },
            }
        },

        parseHTML() {
            return [
                {
                    tag: `span[data-type="${this.name}"]`,
                    getAttrs: (el) => {
                        if (!el) return null

                        const id = (el as HTMLElement).getAttribute('data-id')?.trim()
                        const activityType = (el as HTMLElement)
                            .getAttribute('data-activity-type')
                            ?.trim()
                        const addedAt = (el as HTMLElement).getAttribute('data-added-at')?.trim()
                        const hidden = (el as HTMLElement).getAttribute('data-hidden')?.trim()
                        if (!id || !activityType || addedAt || hidden) return null

                        return {
                            id,
                            activityType,
                            addedAt,
                            hidden: hidden === 'true',
                        }
                    },
                },
            ]
        },

        renderHTML({ HTMLAttributes, mark }) {
            const isHidden = mark.attrs.hidden || options.hideMark

            return [
                'span',
                mergeAttributes(HTMLAttributes, {
                    'data-type': this.name,
                    class: isHidden ? undefined : ActivityMarkStyle,
                }),
                0,
            ]
        },

        addCommands() {
            return {
                setActivityComment: () => {
                    return ({ state, chain }) => {
                        const plugin = getActivityBubblePlugin(state)
                        if (!plugin) return false

                        let id = generateMarkId()
                        const activityType = String(ActivityType.Comment)
                        let addedAt = new Date().getTime()

                        const commands = chain()

                        const { $from, $to } = state.selection
                        const existingMark = getActivityMark($from.pos, $to.pos, state.doc)
                        if (existingMark) {
                            id = existingMark.attrs.id
                            addedAt = existingMark.attrs.addedAt

                            commands.unsetMark(existingMark.type)
                        }

                        const handled = commands
                            .setMark(this.name, {
                                id,
                                activityType,
                                addedAt,
                                hidden: false,
                            })
                            .command(({ tr }) => {
                                // This is to prevent users from being able to press CMD/Ctrl+Z to undo the mark.
                                tr.setMeta('addToHistory', false)
                                return true
                            })
                            .run()

                        return handled
                    }
                },
                // This command is used to open the activity bubble at the current position.
                openActivityBubble: () => {
                    return ({ view, tr }) => {
                        const fromPos = tr.selection.$from.pos

                        return openActivityBubbleAt(fromPos, view)
                    }
                },
            }
        },

        addProseMirrorPlugins() {
            return [
                new Plugin({
                    props: {
                        handleClick: (view: EditorView, pos: number) => {
                            if (options.hideMark) return false

                            return openActivityBubbleAt(pos, view)
                        },
                        transformPasted: (slice) => {
                            // Remove the activity mark from the pasted input.
                            const newSlice = removeMarkFromSlice(slice, this.type)

                            return newSlice
                        },
                        transformCopied: (slice) => {
                            // Remove the activity mark from the copied input.
                            const newSlice = removeMarkFromSlice(slice, this.type)

                            return newSlice
                        },
                    },
                }),
            ]
        },

        onCreate() {
            const plugin = getActivityBubblePlugin(this.editor.state)
            if (!plugin) return

            plugin.on('close', handleActivityBubbleClose)
            plugin.on('changeCommentCounts', handleChangeCommentCounts)
        },

        onDestroy() {
            const plugin = getActivityBubblePlugin(this.editor.state)
            if (!plugin) return

            plugin.off('close', handleActivityBubbleClose)
            plugin.off('changeCommentCounts', handleChangeCommentCounts)
        },
    })
}

function generateMarkId(): string {
    return uuid()
}

function getActivityBubblePlugin(state: EditorState): ActivityBubblePlugin | undefined {
    return state.plugins.find((plugin) => plugin instanceof ActivityBubblePlugin) as
        | ActivityBubblePlugin
        | undefined
}

function openActivityBubbleAt(pos: number, view: EditorView): boolean {
    const { state } = view

    const markNode = state.doc.nodeAt(pos)
    if (!markNode) return false

    // Get the activity mark from the node (if it exists).
    const { schema } = state
    const activityMarkName = schema.marks.activity.name
    const marks = markNode.marks.filter((m) => m.type.name === activityMarkName)
    if (marks.length < 1) return false
    const mark = determineLatestMark(marks)
    if (!mark) return false

    if (mark.attrs.hidden) return false

    const { node: textElement } = view.domAtPos(pos, 1)
    if (!textElement) return false

    let markElement: HTMLElement
    if (textElement.nodeType === Node.TEXT_NODE) {
        markElement = textElement.parentElement as HTMLElement
    } else {
        markElement = textElement as HTMLElement
    }
    if (!markElement) return false

    const plugin = getActivityBubblePlugin(state)
    if (!plugin) return false

    // We attach the activity bubble to an actual element, so we get responsive positioning.
    plugin.viewProps.setTargetElement(markElement)
    plugin.viewProps.setTargetMarkId(mark.attrs.id)
    plugin.viewProps.setLocationPreviewContent(markNode.textContent)
    requestAnimationFrame(() => {
        plugin.viewProps.open()
    })

    return true
}

function handleActivityBubbleClose(payload: CloseEvent['payload']) {
    const { editor, targetLocation, activityCount, keepTargetMark } = payload
    if (!editor) return
    if (activityCount > 0) return
    if (keepTargetMark) return

    const plugin = getActivityBubblePlugin(editor.state)
    if (!plugin) return false

    const activityMarkType = editor.schema.marks.activity
    const { mark: markId } = deserializeActivityLocationParameters(targetLocation)

    editor.commands.command(({ tr, state }) => {
        // This is to prevent users from being able to press CMD/Ctrl+Z to undo updating the mark.
        tr.setMeta('addToHistory', false)

        let handled = false

        state.doc.descendants((node, pos) => {
            if (!node.isText) return

            for (const mark of node.marks) {
                const { type, attrs } = mark

                // Hide the mark if there are no comments.
                if (type === activityMarkType && attrs?.id === markId) {
                    tr.removeMark(pos, pos + node.nodeSize, mark)

                    const newMark = mark.type.create({
                        ...attrs,
                        hidden: true,
                    })
                    tr.addMark(pos, pos + node.nodeSize, newMark)

                    handled = true
                }
            }
        })

        return handled
    })
}

function determineLatestMark(marks: ProseMirrorMark[]): ProseMirrorMark | undefined {
    if (marks.length < 1) return undefined

    let latestMark: ProseMirrorMark | undefined
    let latestAddedAt = -1

    for (const mark of marks) {
        const addedAt = mark.attrs.addedAt
        if (addedAt > latestAddedAt) {
            latestMark = mark
            latestAddedAt = addedAt
        }
    }

    return latestMark
}

function handleChangeCommentCounts(payload: ChangeCommentCountsEvent['payload']) {
    const { editor, commentsByLocation } = payload
    if (!editor) return

    const marksToShow = new Set<string | number>()
    for (const [location, commentCount] of Object.entries(commentsByLocation)) {
        if (commentCount < 1) continue

        const { mark: markId } = deserializeActivityLocationParameters(location)
        marksToShow.add(markId)
    }

    const activityMarkType = editor.schema.marks.activity

    editor.commands.command(({ tr, state }) => {
        let handled = false

        state.doc.descendants((node, pos) => {
            if (!node.isText) return

            for (const mark of node.marks) {
                const { type, attrs } = mark
                const id = attrs?.id

                if (type === activityMarkType && id) {
                    const shouldHide = !marksToShow.has(id)

                    // If the mark already has the desired state, ignore.
                    if (attrs.hidden === shouldHide) continue

                    // Show or hide the mark if it has comments.
                    tr.removeMark(pos, pos + node.nodeSize, mark)
                    const newMark = mark.type.create({
                        ...attrs,
                        hidden: shouldHide,
                    })
                    tr.addMark(pos, pos + node.nodeSize, newMark)

                    handled = true
                }
            }
        })

        return handled
    })
}

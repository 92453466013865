import React from 'react'

import { useAppContext } from 'app/useAppContext'
import { ObjectPicker } from 'features/studio/ui/ObjectPicker'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { WorkflowActionEditorComponent } from './types'

export const CreateRecordActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const { selectedStack } = useAppContext()

    return (
        <>
            <ConfigurationLabel>Table</ConfigurationLabel>
            <ObjectPicker
                value={action.settings?.object_id}
                onChange={(item: { value: string }) => {
                    onChange({
                        settings: {
                            ...action.settings,
                            object_id: item.value,
                            stack_id: selectedStack?._sid,
                        },
                    })
                }}
                filter={(o: ObjectDto) => !o.connection_options.read_only}
                returnObject
                isClearable={false}
            />
        </>
    )
}

import { basicLogger as LDBasicLogger, LDLogLevel } from 'launchdarkly-js-client-sdk'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import { Root } from './Root'
import settings from './settings'

export const RootWithLdProvider = withLDProvider({
    clientSideID: settings.LAUNCH_DARKLY.clientSideId,
    context: {
        kind: 'user',
        key: 'anonymous',
        anonymous: true,
    },
    options: {
        logger: LDBasicLogger({ level: settings.LAUNCH_DARKLY.logLevel as LDLogLevel }),
    },
})(Root)

import React, { FC, MouseEvent } from 'react'

import { ButtonProps } from '@chakra-ui/react'

import { Button } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { useActionButton } from './hooks/useActionButton'

export type ActionButtonProps = ButtonProps & {
    item: ActionDto
    recordId: string
    record: RecordDto
    includeFields: string[]
}

const ActionButton: FC<ActionButtonProps> = ({
    item,
    recordId,
    record,
    includeFields,
    ...props
}) => {
    const { executeAction } = useActionButton({ action: item, recordId, record, includeFields })

    const actionIDClassname = item?._sid.replace(/\./g, '-')

    return (
        <Button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                e.preventDefault()
                return executeAction()
            }}
            variant="App"
            buttonSize="medium"
            {...props}
            label={item.name.length > 20 ? item.name : null}
            className={actionIDClassname}
        >
            <span
                className={STYLE_CLASSES.ACTION_BUTTON_TEXT + '-' + `${actionIDClassname}`}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden', padding: '3px 0' }}
            >
                {item.name}
            </span>
        </Button>
    )
}

export default ActionButton

export type DetailViewTab = {
    name: string
    id: string
    type?: (typeof SYSTEM_TAB_TYPES)[number]
    active?: boolean
    treeIndex?: number
    conditions?: Filter[]
}

export const SYSTEM_TAB_TYPES = ['activity', 'tasks', 'documents'] as const

import { ActivityType, RelatedToType } from 'data/hooks/activityTypes'

import { FeedItemRelationshipInfo } from './types'

const recordActivityTypes = [
    { id: ActivityType.Comment, label: 'Messages' },
    { id: ActivityType.RecordCreated, label: 'Record created' },
    { id: ActivityType.RecordUpdated, label: 'Record updated' },
    { id: ActivityType.RecordDeleted, label: 'Record deleted' },
    { id: ActivityType.RecordRestored, label: 'Record restored' },
]

const taskActivityTypes = [
    { id: ActivityType.Comment, label: 'Messages' },
    { id: ActivityType.TaskCompleted, label: 'Task completed' },
    { id: ActivityType.TaskDueDateChanged, label: 'Task due date changed' },
    { id: ActivityType.TaskAssigneesChanged, label: 'Task assignee changed' },
]

const allActivityTypes = [
    { id: ActivityType.Comment, label: 'Messages' },
    { id: ActivityType.RecordCreated, label: 'Record created' },
    { id: ActivityType.RecordUpdated, label: 'Record updated' },
    { id: ActivityType.RecordDeleted, label: 'Record deleted' },
    { id: ActivityType.RecordRestored, label: 'Record restored' },
    { id: ActivityType.TaskCompleted, label: 'Task completed' },
    { id: ActivityType.TaskDueDateChanged, label: 'Task due date changed' },
    { id: ActivityType.TaskAssigneesChanged, label: 'Task assignee changed' },
]

export function getActivityTypesForTarget(
    target?: FeedItemRelationshipInfo
): { id: ActivityType; label: string }[] {
    switch (target?.relatedToType) {
        case RelatedToType.Record:
            return recordActivityTypes
        case RelatedToType.Task:
            return taskActivityTypes
        default:
            return allActivityTypes
    }
}

import React from 'react'

import { ListHeader } from 'features/views/ListView/ListHeader/ListHeader'

import { useBoardViewListHeaderState } from './hooks/useBoardViewListHeaderState'

type BoardViewListHeaderProps = {}

export const BoardViewListHeader: React.FC<BoardViewListHeaderProps> = React.memo(
    function BoardViewListHeader() {
        const { recordCount, requestFilters, requestIncludedFields } = useBoardViewListHeaderState()

        return (
            <ListHeader
                recordCount={recordCount}
                requestFilters={requestFilters}
                requestIncludedFields={requestIncludedFields}
            />
        )
    }
)

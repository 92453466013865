import React from 'react'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

export function OnboardingProgress({ steps, currentStep }: { steps: number; currentStep: number }) {
    return (
        <Box flex gap="m" center>
            <Box flex grow gap="xs">
                {Array.from({ length: steps }).map((_, i) => (
                    <Box
                        key={i}
                        height="2xs"
                        style={{ flexBasis: 100 / steps + '%' }}
                        background={i < currentStep ? 'theme950' : 'gray200'}
                    />
                ))}
            </Box>
            <Body size="s" color="textWeaker">
                {currentStep}/{steps}
            </Body>
        </Box>
    )
}

import { queryClient } from 'data/hooks/_helpers'
import { includeFieldsToRelatedFieldSuffix } from 'data/hooks/recordQueryKeySuffix'

import { getCachedRecord } from './getCachedRecord'
import { getMergedRecord } from './getMergedRecord'
import { getRecord } from './getRecord'
import { getSchemaTimestamp } from './getSchemaTimestamp'
import { RECORD_QUERY_CONFIG } from './recordConstants'
import { updateObjectRecordQuery } from './updateObjectRecordQuery'

/**
 * Updates records in the query cache
 * setting updateObjectQueries to true will also update in all of the object record queries
 *
 * @param {array} records
 * @param {bool} updateObjectQueries
 * @param requiredFields
 * @param onMergeRecord
 */

export const updateRecordQuery = (
    records: RecordDto[],
    updateObjectQueries = false,
    requiredFields: string[] | undefined,
    onMergeRecord?: (cachedRecord: RecordDto, newRecord: RecordDto) => RecordDto
) => {
    if (!Array.isArray(records)) return
    records.forEach(async (rec) => {
        // We just directly update the record cache here to save another request
        const schemaTimestamp = getSchemaTimestamp(rec._object_id)
        const record = { ...rec, schemaTimestamp }
        if ((record as any)._dereferenced) {
            delete (record as any)._dereferenced
        }
        const currentRecord = getCachedRecord(record._sid, requiredFields)
        // No current record exists so we need to setup the query first
        if (!currentRecord) {
            getRecord(record._sid, requiredFields, { initialData: record })
        } else {
            const queryKeyWithSuffix = [
                'record',
                record._sid,
                includeFieldsToRelatedFieldSuffix(requiredFields),
            ]
            queryClient.setQueryData(
                queryKeyWithSuffix,
                (old: any) => {
                    let newRecord = onMergeRecord ? onMergeRecord(old, record) : record
                    newRecord = getMergedRecord(newRecord, old)
                    return newRecord
                },
                RECORD_QUERY_CONFIG as any
            )
        }
        if (updateObjectQueries) {
            updateObjectRecordQuery(record, onMergeRecord)
        }
    })
}

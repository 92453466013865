import React from 'react'

import {
    TrayButtonBase,
    TrayButtonBaseProps,
} from 'features/workspace/AdminSideTray/common/TrayButtonBase'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import useTrack from 'utils/useTrack'

type PreviewAsButtonProps = TrayButtonBaseProps & {}
export const PreviewAsButton: React.VFC<PreviewAsButtonProps> = ({}) => {
    const { state } = useSlidingPane()

    const { track } = useTrack()

    const { showPreviewAs } = useSlidingPane()

    const handleClick = async () => {
        track('WIP - Frontend - Admin Side Tray - Preview As - Clicked')
        showPreviewAs()
    }
    return (
        <TrayButtonBase
            icon="play"
            tooltip="Preview as"
            onClick={handleClick}
            disabled={!state.contextInfo.objectId && !state.contextInfo.viewId}
            data-testid="admin-side-tray.preview-as-button"
        />
    )
}

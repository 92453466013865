/** @jsxRuntime classic */
/** @jsx jsx */
/* Code Quality: Not audited */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { jsx } from '@emotion/react'

import { boolean, text } from 'features/pages/blocks/settings/attributes/items/primitives'

import { Container, FixedGrid } from 'v2/ui'

class GridCardBlock extends Component {
    render() {
        let { attrs, evaluateFormulas, ChildBlocks } = this.props
        const linkWrapper = (children) => {
            const link = evaluateFormulas(attrs.link)
            if ((link.indexOf('//') >= 0 && link.indexOf('//') < 8) || link.startsWith('mailto:')) {
                //Absolute link
                return (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a href={link} target={attrs.new_tab ? '_blank' : null}>
                        {children}
                    </a>
                )
            } else {
                //Relative link
                return (
                    <Link target={attrs.new_tab ? '_blank' : null} to={link}>
                        {children}
                    </Link>
                )
            }
        }
        const wrapLink = attrs.link && !this.props.context.editor.isEditing ? linkWrapper : (a) => a

        return wrapLink(
            <>
                <Container
                    p={['container.padding', null, null, 'container.paddingLg']}
                    m={0}
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                >
                    {this.props.block.childBlocks.length ? (
                        <FixedGrid columns={[1, 1, 2, 2]} spacing="1.5rem" maxWidth="100%">
                            <ChildBlocks />
                        </FixedGrid>
                    ) : (
                        <ChildBlocks />
                    )}
                </Container>
            </>
        )
    }
}

GridCardBlock.propTypes = {}

GridCardBlock.childStyles = ['boxShadow']

GridCardBlock.attributes = [
    text({ field: 'link', label: 'Link to page' }),
    boolean({ field: 'new_tab', label: 'Open link in new tab?' }),
]

GridCardBlock.behaviours = {
    onClick: true,
}

export default GridCardBlock

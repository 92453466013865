import React, { useState } from 'react'

import QueryString from 'qs'
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-web-js/recipe/thirdparty'

import { getAbsoluteUrl, Urls } from 'app/UrlService'
import publicAsset from 'utils/publicAsset'

import { Box } from 'ui/components/Box'
import { Button, ButtonProps } from 'ui/components/Button'

type Props = Omit<ButtonProps, 'onClick' | 'isLoading'>

export function GoogleLoginButton({
    variant = 'secondary',
    children = 'Sign in with Google',
    ...props
}: Props) {
    const [isRedirecting, setIsRedirecting] = useState(false)
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })

    async function startGoogleFlow() {
        setIsRedirecting(true)
        sessionStorage.setItem(
            'redirectAfterCallback',
            decodeURIComponent(query.r?.toString() ?? '')
        )
        try {
            const url = await getAuthorisationURLWithQueryParamsAndSetState({
                thirdPartyId: 'google',
                frontendRedirectURI: getAbsoluteUrl(`${Urls.AuthThirdPartyCallback}/google`),
            })
            window.location.assign(url)
        } catch (ex) {
            console.error(ex)
            setIsRedirecting(false)
        }
    }

    return (
        <Button onClick={startGoogleFlow} variant={variant} isLoading={isRedirecting} {...props}>
            <Box
                as="img"
                height="full"
                style={{ height: '60%' }}
                src={publicAsset('/static/media/g-logo.svg')}
            />
            {children}
        </Button>
    )
}

import React, { useMemo } from 'react'

import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'

type Option = {
    value: string
    label: string
}

const STATIC_OPTIONS: Option[] = [
    { value: 'GET', label: 'GET' },
    { value: 'POST', label: 'POST' },
    { value: 'PUT', label: 'PUT' },
    { value: 'PATCH', label: 'PATCH' },
    { value: 'DELETE', label: 'DELETE' },
    { value: 'HEAD', label: 'HEAD' },
    { value: 'OPTIONS', label: 'OPTIONS' },
]

type RequestMethodDropdownProps = {
    value: string
    onChange: (value: string) => void
}

export const RequestMethodDropdown: React.FC<RequestMethodDropdownProps> = ({
    value,
    onChange,
}) => {
    const currentOption = useMemo(() => {
        return STATIC_OPTIONS.find((o) => o.value === value)
    }, [value])

    return (
        <Dropdown>
            <DropdownButton variant="secondary" size="xs" endIcon={{ name: 'ChevronDown' }}>
                {currentOption?.label ?? 'Pick a method'}
            </DropdownButton>
            <DropdownContent style={{ maxHeight: '50vh' }} overflowY="auto">
                {STATIC_OPTIONS.map((option) => (
                    <DropdownItem
                        key={option.value}
                        label={option.label}
                        multiSelect
                        closeOnSelect
                        checked={option.value === value}
                        onCheckedChange={(checked) => {
                            if (checked) onChange(option.value)
                        }}
                    />
                ))}
            </DropdownContent>
        </Dropdown>
    )
}

import React, { useRef } from 'react'

import { buildPermissionRuleRequestDataFromFormData } from './PermissionRuleForm/buildPermissionRuleRequestDataFromFormData'
import { PermissionsRuleForm } from './PermissionRuleForm'

const AppSettingsModalEditPermissionModal = ({
    onClose,
    onSubmit,
    permissionRules,
    roles,
    object,
    userObject,
    objects,
    editingPermissionRule: originalRule,
}) => {
    // Clone this object because we mutate it further down
    const editingPermissionRule = { ...originalRule }
    // Account for old-style permission rules where create or update may be disabled entirely
    // Note: create permission is stored *outside* record_permissions
    const recordPermissions = {
        read: !!editingPermissionRule.record_permissions.read,
        update: !!editingPermissionRule.record_permissions.update,
        create: !!editingPermissionRule.create_permission,
    }
    for (const permission in recordPermissions) {
        if (!recordPermissions[permission]) {
            for (const field in editingPermissionRule.field_permissions) {
                editingPermissionRule.field_permissions[field][permission] = false
            }
        }
    }

    // Also propagate the all-fields perms to any fields which don't have it set.
    // (Either because they are newly added, or from the previous editor version, which allowed this)
    const fields = object.fields.filter(
        (field) => !(field.connection_options && field.connection_options.is_disabled)
    )
    for (const permission in editingPermissionRule.field_permissions['*'] || {}) {
        if (editingPermissionRule.field_permissions['*'][permission]) {
            for (const field of fields) {
                // Make sure we don't set write/create perms to true if it's a read only field and
                const isFieldReadOnly = field.is_read_only ?? false
                const fieldApiName = field.api_name
                if (fieldApiName !== '*' && (permission === 'read' || !isFieldReadOnly)) {
                    editingPermissionRule.field_permissions[fieldApiName] = {
                        ...editingPermissionRule.field_permissions[fieldApiName],
                        [permission]: true,
                    }
                }
            }
        }
    }
    // Due to past bugs, some fields may be listed as having update/create perms
    // even though they are readonly fields. We will update that here so that our
    // UI correctly displays
    for (const field of fields.filter((field) => field.is_read_only)) {
        editingPermissionRule.field_permissions[field.api_name] = {
            ...editingPermissionRule.field_permissions[field.api_name],
            update: false,
            create: false,
        }
    }

    const formRef = useRef()

    const editPermissionRule = async (data) => {
        // validate data
        const nameLowercase = data.name.toLowerCase()
        const isDuplicateRuleName = permissionRules.some((rule) => {
            return (
                rule._sid !== editingPermissionRule._sid && // it's ok to rename the existing rule using different casing!
                rule.label.toLowerCase() === nameLowercase
            )
        })

        if (isDuplicateRuleName) {
            formRef.current &&
                formRef.current.setError('name', {
                    type: 'manual',
                    message: 'There is already a rule with that name',
                })
            return
        }

        const reqData = buildPermissionRuleRequestDataFromFormData(data, object)

        return onSubmit(editingPermissionRule._sid, reqData)
    }

    return (
        <PermissionsRuleForm
            title="Edit permission"
            defaultValues={{
                name: editingPermissionRule.label,
                roles: editingPermissionRule.granted_by_roles?.length
                    ? editingPermissionRule.granted_by_roles
                    : [], // Default value of a permission rule if it has no roles
                allRoles: editingPermissionRule.all_roles,
                allRecords: !(
                    editingPermissionRule.options?.filters_unformatted?.length ||
                    editingPermissionRule.options.permissionSelected === 'private'
                ),
                permissionType: editingPermissionRule.options.permissionSelected || 'global',
                recordPermissionCreate: editingPermissionRule.create_permission,
                recordPermissionUpdate: editingPermissionRule.record_permissions.update,
                recordPermissionDelete: editingPermissionRule.record_permissions.delete,
                recordFilter: editingPermissionRule.options.filters_unformatted?.length
                    ? editingPermissionRule.options.filters_unformatted
                    : [],
                fieldPermissions: editingPermissionRule.field_permissions,
                ownershipFilter: {
                    active: editingPermissionRule.options.permissionSelected === 'private',
                    objectFieldSelected: editingPermissionRule.options.objectFieldSelected,
                    userFieldSelected: editingPermissionRule.options.userFieldSelected,
                },
            }}
            formRef={formRef}
            onClose={onClose}
            onSubmit={editPermissionRule}
            object={object}
            objects={objects}
            userObject={userObject}
            roles={roles}
            enableUnsavedChangesModal={true}
            isNew={false}
        />
    )
}

export default AppSettingsModalEditPermissionModal

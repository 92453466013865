import * as ToastPrimitive from '@radix-ui/react-toast'

import { makeComponent } from 'ui/helpers/recipes'

import {
    DescriptionStyles,
    DismissButtonStyles,
    RootStyles,
    TitleStyles,
    ViewportStyles,
} from './Toast.css'

export const Provider = ToastPrimitive.Provider
export const Viewport = makeComponent(ToastPrimitive.Viewport, ViewportStyles)
export const Root = makeComponent(ToastPrimitive.Root, RootStyles)
export const Title = makeComponent(ToastPrimitive.Title, TitleStyles)
export const Description = makeComponent(ToastPrimitive.Description, DescriptionStyles)
export const DismissButton = makeComponent(ToastPrimitive.Close, DismissButtonStyles)

import { useDetailViewConfig } from 'v2/views/useDetailViewConfig'

import { useViews } from 'data/hooks/views'
import { useProcessFilter } from 'features/records/components/RecordFilters'

type UseIsCommentCountVisibleOptions = {
    record?: RecordDto
    object?: ObjectDto
}

export function useIsCommentCountVisible(options: UseIsCommentCountVisibleOptions) {
    const { record, object } = options

    const { data: views = [] } = useViews()
    const { filterTabs, systemTabs } = useDetailViewConfig(object!)

    const processFilter = useProcessFilter()

    const objectSid = object?._sid
    if (!objectSid) return false

    const detailView = views?.find((v) => v.object_id === objectSid && v.type === 'detail')
    const detailViewTabs = detailView?.options.tabs
        ? filterTabs(detailView.options.tabs)
        : systemTabs

    const feedTab = detailViewTabs.find((tab) => tab.type === 'activity' && !!tab.active)

    // If the feed tab is disabled, hide the comment count.
    if (!feedTab) return false

    // If the feed tab has conditions, check if the record matches them.
    if (feedTab.conditions) {
        const isHiddenByConditions =
            !record || processFilter([record], feedTab.conditions).length < 1

        return !isHiddenByConditions
    }

    return true
}

import React, { forwardRef } from 'react'

import * as Parts from './ScrollArea.parts'

type ScrollAreaRef = HTMLDivElement

type ScrollAreaProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Viewport>, 'type'> & {
    direction?: 'vertical' | 'horizontal' | 'both'
    rootProps?: React.ComponentPropsWithoutRef<typeof Parts.Root>
    type?: React.ComponentPropsWithoutRef<typeof Parts.Root>['type'] | 'hidden'
}

export const ScrollArea = forwardRef<ScrollAreaRef, ScrollAreaProps>(
    ({ children, direction = 'both', type, rootProps, ...props }, ref) => {
        const hideScrollBars = type === 'hidden'

        let effectiveType = type as React.ComponentPropsWithoutRef<typeof Parts.Root>['type']
        if (hideScrollBars) {
            effectiveType = 'auto'
        }

        return (
            <Parts.Root type={effectiveType} {...rootProps}>
                <Parts.Viewport ref={ref} {...props}>
                    {children}
                </Parts.Viewport>
                {(direction === 'vertical' || direction === 'both') && (
                    <Parts.Scrollbar orientation="vertical" hidden={hideScrollBars}>
                        <Parts.Thumb />
                    </Parts.Scrollbar>
                )}
                {(direction === 'horizontal' || direction === 'both') && (
                    <Parts.Scrollbar orientation="horizontal" hidden={hideScrollBars}>
                        <Parts.Thumb />
                    </Parts.Scrollbar>
                )}
                {direction === 'both' && <Parts.Corner hidden={hideScrollBars} />}
            </Parts.Root>
        )
    }
)

import { TaskList } from '@tiptap/extension-task-list'
import classNames from 'classnames'

import { CheckListStyle } from './CheckListExtension.css'

export function createCheckListExtension() {
    return TaskList.configure({
        HTMLAttributes: {
            class: classNames(CheckListStyle, 'check-list'),
        },
        itemTypeName: 'taskItem',
    })
}

import React from 'react'

import useLDFlags from 'data/hooks/useLDFlags'
import {
    TrayButtonBase,
    TrayButtonBaseProps,
} from 'features/workspace/AdminSideTray/common/TrayButtonBase'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

type DataConnectionsButtonProps = TrayButtonBaseProps & {}

export const DataConnectionsButton: React.VFC<DataConnectionsButtonProps> = ({}) => {
    const { state, showDataConnections, close } = useSlidingPane()
    const { flags } = useLDFlags()

    if (!flags.externalData) return null

    const handleClick = () => {
        if (state.key !== 'data-connections') {
            showDataConnections()
        } else {
            close()
        }
    }

    return (
        <TrayButtonBase
            icon="fa-database"
            tooltip="Manage Data Connections"
            onClick={handleClick}
            isActive={state.key === 'data-connections'}
            data-testid="admin-side-tray.data-connections-button"
        />
    )
}

import { useCallback, useMemo } from 'react'

import { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-enterprise'

export function useContextMenuItems() {
    const getContextMenuItems = useCallback(
        (_params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
            // We always show the paste option, just to let the user know that it's possible.
            // It will always be disabled in JS due to security reasons.
            // More on this here: https://www.ag-grid.com/react-data-grid/context-menu/
            return ['cut', 'copy', 'copyWithHeaders', 'copyWithGroupHeaders', 'paste']
        },
        []
    )

    return useMemo(() => ({ getContextMenuItems }), [getContextMenuItems])
}

/* eslint-disable simple-import-sort/imports -- Disabled to avoid circular dependencies, remove when file is removed from .freezircularDeps */

/* Code Quality: Not audited */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import { isEqual } from 'lodash'

class RenderBlockAttributes extends Component {
    state = {
        attributes: this.props.block.config.attributes,
        onlyVisible: null, //Store the id (index) of the attribute to display, if not set, we will display all attributes
        updates: {},
    }

    renderField({
        attribute,
        value,
        params,
        style,
        setAttr,
        setAttrs,
        field,
        context,
        key,
        simpleOnly,
        id,
    }) {
        // allows us to hide this attribute based off other attribute values
        if (attribute && attribute.props && typeof attribute.props.hide == 'function') {
            if (attribute.props.hide(params, context)) return null
        }

        if (typeof attribute === 'function') {
            attribute = attribute(params, context)
            if (!attribute) {
                // conditionnal rendering
                return null
            }
        }

        if (React.isValidElement(attribute)) {
            const { props } = attribute
            return React.cloneElement(attribute, {
                context, // deprecated
                'data-testId': `${context.block.type}.${props.testId || props.field}`,
                key,
                params,
                styleContext: style, // deprecated
                style,
                setAttr,
                setAttrs,
                fieldRef: field, // deprecated
                field,
                value,
                simpleOnly,
                setOnlyVisible: (visible) => {
                    this.setOnlyVisible(visible ? id : null)
                },
                ...props,
            })
        }

        console.log(
            'something went wrong, expected a component but got',
            typeof attribute,
            attribute
        )
        throw new Error('an error occurred')
    }

    constructor(props) {
        super(props)
        this.sendUpdates = debounce(this.sendUpdates.bind(this), 300)
    }

    setOnlyVisible(id) {
        // Flag an attribute id as visible only
        // this is used in FieldContainerEditor to display the field,
        // without any other attributes, like conditionalVisibility
        this.setState({ onlyVisible: id })
    }

    sendUpdates() {
        this.setState((state) => {
            this.props.context.editor.actions.updateAttributes(this.props.block.id, state.updates)
            return { updates: {} }
        })
    }

    //
    // Maintain local state, and update it immediately,
    // but use the debounced sendUpdates() method to actualy
    // update the tree. This ensures that any components below
    // that depend on the supplied state get immediate updates
    // but the actual layout rendering is debounced.
    handleSetAttr = (field, value) => {
        // Store the pending updates in local state, and when the
        // debounced sendUpdates() method runs, it will send them
        // all at once.
        const hasChanges = !isEqual(this.state.attributes[field], value)
        if (hasChanges) {
            this.setState(
                (state) => {
                    return {
                        attributes: { ...state.attributes, [field]: value },
                        updates: { ...state.updates, [field]: value },
                    }
                },
                () => {
                    this.sendUpdates()
                }
            )
        }
    }

    // Allows updates of multiple attributes at once
    handleSetAttrs = (patch) => {
        // Store the pending updates in local state, and when the
        // debounced sendUpdates() method runs, it will send them
        // all at once.
        this.setState(
            (state) => {
                return {
                    attributes: { ...state.attributes, ...patch },
                    updates: { ...state.updates, ...patch },
                }
            },
            () => {
                this.sendUpdates()
            }
        )
    }

    render() {
        const { block, context, getBlockByType, simpleOnly } = this.props

        const blockType = getBlockByType(block.type)
        if (!blockType) {
            throw new Error(`no blockType ${block.type}`)
        }
        const { attributes } = blockType.blockElement

        if (!attributes) {
            console.warn(`no attributes for ${block.type}`, getBlockByType(block.type).blockElement)
            throw new Error(`no attributes for ${block.type}`)
        }

        if (attributes.length === 0) {
            return ''
        }

        const renderAttribute = (attribute, id) =>
            this.renderField({
                attribute,
                value: this.state.attributes[attribute.field],
                params: this.state.attributes,
                style: block.style,
                field: block.field,
                onChange: (value) => this.handleSetAttr(attribute.field, value),
                setAttr: this.handleSetAttr,
                setAttrs: this.handleSetAttrs,
                setStyle: (field, value) =>
                    context.editor.actions.updateStyle(block.id, field, value),
                key: block.field || id,
                context,
                simpleOnly,
                id,
            })

        if (blockType.blockElement.renderAttributes) {
            return blockType.blockElement.renderAttributes({
                attributes,
                renderAttribute,
                configuredAttributes: block.config.attributes,
            })
        }

        // The a prop of onlyVisibleAttributes fields can be sent through which will allow only certain attributes to be displayed
        if (this.props.onlyVisibleAttributes) {
            return this.props.onlyVisibleAttributes.reduce((acc, id) => {
                const index = attributes.findIndex((attribute) => attribute.props.field === id)
                if (index) acc.push(renderAttribute(attributes[index], index))
                return acc
            }, [])
        }

        // If an attribute is flagged as onlyVisible (by using setOnlyVisible method in props)
        // we will display only that attribute
        if (this.state.onlyVisible) {
            const id = this.state.onlyVisible
            return renderAttribute(attributes[id], id)
        }

        return attributes.map((attribute, id) => {
            return renderAttribute(attribute, id)
        })
    }
}

RenderBlockAttributes.propTypes = {
    block: PropTypes.object.isRequired,
    setAttr: PropTypes.func,
    setStyle: PropTypes.func,
}

export default RenderBlockAttributes

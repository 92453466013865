import React from 'react'

import { composeRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAdvancedFilterInputState } from './hooks/useAdvancedFilterInputState'

import { AdvancedFiltersInputStyle, AdvancedFiltersInputWrapperStyle } from './AdvancedFilters.css'

type AdvancedFilterInputRef = HTMLInputElement

type AdvancedFilterInputProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: () => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    maxLength?: number
    prefix?: React.ReactNode
    suffix?: React.ReactNode
    autoFocus?: boolean
}

export const AdvancedFilterInput = React.forwardRef<
    AdvancedFilterInputRef,
    AdvancedFilterInputProps
>(
    (
        {
            className,
            maxLength,
            value,
            onChange,
            onBlur,
            onKeyDown,
            prefix,
            suffix,
            autoFocus,
            ...props
        },
        ref
    ) => {
        const { onWrapperClick, inputRef, textRef } = useAdvancedFilterInputState({
            value,
        })

        const inputSize = useResponsiveValue<'s' | 'm'>({
            mobile: 's',
            tablet: 'm',
        })

        return (
            <Box
                className={classNames(className, AdvancedFiltersInputWrapperStyle)}
                onClick={onWrapperClick}
                {...props}
            >
                {prefix}
                {/* This element is used to calculate the width of the text,
                 *  so that the input can be sized accordingly.
                 */}
                <Body
                    size={{ mobile: 's', tablet: 'm' }}
                    weight="medium"
                    ref={textRef}
                    style={{
                        userSelect: 'none',
                        position: 'absolute',
                        visibility: 'hidden',
                        opacity: 0,
                        pointerEvents: 'none',
                        whiteSpace: 'pre',
                    }}
                >
                    {value}
                </Body>
                <Input
                    ref={composeRefs(ref, inputRef)}
                    variant="borderless"
                    size={inputSize}
                    className={AdvancedFiltersInputStyle}
                    inputProps={{
                        className: 'ignore-ios-zoom',
                    }}
                    maxLength={maxLength}
                    onInput={onChange}
                    style={{ minWidth: '2px' }}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    value={value}
                    autoFocus={autoFocus}
                />
                {suffix}
            </Box>
        )
    }
)

import { useLayoutEffect, useMemo, useRef } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationState() {
    const { workspaceNavState, mainNavState, closeMobileNavigation, tryCollapseWorkspaceNav } =
        useNavigationContext()

    const showMobileControls =
        workspaceNavState === 'mobile-expanded' || mainNavState === 'mobile-expanded'

    const navWrapperRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (workspaceNavState !== 'expanded' && !showMobileControls) return

        // Close on click outside the navigation.
        const handleClickOutside = (event: MouseEvent) => {
            const navWrapper = navWrapperRef.current
            if (!navWrapper) return

            const targetEl = event.target as HTMLElement | null
            if (!targetEl) return

            // If the click target is inside a popup, don't close the nav.

            const isRadixPopperOpen = document.querySelector('[data-radix-popper-content-wrapper]')
            if (isRadixPopperOpen) return

            const isAgGridPopupOpen = document.querySelector('.ag-custom-component-popup')
            if (isAgGridPopupOpen) return

            if (!navWrapper.contains(targetEl)) {
                tryCollapseWorkspaceNav()
            }
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [showMobileControls, tryCollapseWorkspaceNav, workspaceNavState])

    return useMemo(
        () => ({
            workspaceNavState,
            showMobileControls,
            closeMobileNavigation,
            mainNavState,
            navWrapperRef,
        }),
        [workspaceNavState, showMobileControls, closeMobileNavigation, mainNavState]
    )
}

import React, { FC, Fragment } from 'react'

import { isArray } from 'lodash'

import { useRecordsByIds } from 'data/hooks/records'
import { RecordDropdown } from 'features/records/components/RecordDropdown'

import { RecordLinkDisplay } from 'v2/ui/components/Attribute/RecordLinkDisplay'

import type { AdminFilterProps } from './types'

export const FilterRelationship: FC<AdminFilterProps> = ({
    value,
    field,
    multi,
    editMode = true,
    ...props
}) => {
    // if we're not in edit mode, then just display the selected values
    // not the input control
    const values = isArray(value) ? value : [value]

    const fetchOptions = {
        includeFields: ['_primary'],
        forcePartials: true,
    }

    const {
        data: { records = [] } = {},
        isError,
        isSuccess,
    } = useRecordsByIds({
        objectSid: field?.link_target_object_id ?? '',
        recordIds: values,
        fetchOptions,
        options: {
            refetchOnMount: 'always',
        },
    })

    if (!editMode) {
        if (!isError && !isSuccess) return null

        return (
            <>
                {values.map((id, idx) => {
                    const record = records.find((r: RecordDto) => r._sid === id)
                    return (
                        <Fragment key={id}>
                            {idx > 0 && ', '}
                            <RecordLinkDisplay record={record} recordId={id} noLink />
                        </Fragment>
                    )
                })}
            </>
        )
    }

    return (
        <RecordDropdown
            objectId={field?.link_target_object_id ?? ''}
            value={value as string | string[]}
            multi={multi}
            {...props}
            isMulti={field?.type === 'multi_select' || multi}
            //@ts-expect-error drilled to untyped Dropdown
            maxWidth="100%"
        />
    )
}

import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import queryString from 'qs'

import { getUrl, Urls } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import { useLoginUserByTemporaryAuthToken } from 'data/hooks/auth/auth'
import { useInitialMetadata } from 'data/hooks/useInitialMetadata'
import { withUser } from 'data/wrappers/withUser'
import publicAsset from 'utils/publicAsset'

import { Flex, Text } from 'v2/ui'
import useRunOnce from 'v2/ui/utils/useRunOnce'

const WorkspaceAuth = ({ isLoggedIn }) => {
    const [loginFailed, setLoginFailed] = useState()
    const { workspaceAccount } = useAppContext()
    const loginByToken = useLoginUserByTemporaryAuthToken()
    const { isFetching: loadingMetadata } = useInitialMetadata()
    let query = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })

    useRunOnce(() => {
        // If we have a token in the URL, try to authenticate with it.
        const token = query.token

        if (token) {
            // remove from the URL so if we remount we don't try again with the same
            // token which will no longer work.
            const url = new URL(window.location.href)
            url.searchParams.delete('token')
            window.history.pushState({}, '', url)

            loginByToken(token).catch(() => {
                setLoginFailed(true)
            })
        }
    })

    // Don't redirect if we're still re-fetching the meta data
    // after authenticating the user
    if (!loadingMetadata && isLoggedIn) {
        if (query.r) {
            return <Redirect to={query.r} />
        }
        return <Redirect to={getUrl(Urls.Root)} />
    }

    if (loginFailed) {
        if (workspaceAccount) {
            return (
                <Flex width="100%" height="100%" column justify="center">
                    <Text>You do not have access to this workspace.</Text>
                </Flex>
            )
        } else {
            return (
                <Flex width="100%" height="100%" column justify="center">
                    <Text>This login link has expired.</Text>
                </Flex>
            )
        }
    }

    return (
        <Flex width="100%" height="100dvh" column justify="center">
            <img
                alt=""
                src={publicAsset('/static/media/Spinner.svg')}
                style={{
                    marginLeft: 0,
                    marginRight: 8,
                    height: 64,
                }}
            />
        </Flex>
    )
}
export const AuthWithToken = withUser(WorkspaceAuth)

import React, { FC, ReactNode } from 'react'

import { InfoTooltip } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/panels/InfoTooltip'
import { fieldTypeAggregateFilter } from 'v2/blocks/blockTypes/view/aggregationBlocks/utils'

import FieldPicker from 'features/studio/ui/FieldPicker'

import { Box, Flex, Icon, Text } from 'v2/ui'
import { CustomRadioGroup } from 'v2/ui/components/CustomRadio'

const AGGREGATION_TYPE_OPTIONS: {
    value: MetricsBlockAttributes['aggregate']['type']
    label: string
    icon: React.ReactNode
}[] = [
    {
        value: 'count',
        label: 'Records count',
        icon: <Icon icon="faCalculatorSimple" size="30px" color="userInterface.accent.1000" />,
    },
    {
        value: 'sum',
        label: 'Field sum',
        icon: <Icon icon="faPlusSquare" size="30px" color="userInterface.accent.1000" />,
    },
    {
        value: 'avg',
        label: 'Field average',
        icon: <Icon icon="faWaveSine" size="30px" color="userInterface.accent.1000" />,
    },
]

type Props = {
    aggregationWidget: AggregateBlockAttributes
    onChange: (
        aggregationWidget: Pick<AggregateBlockAttributes, 'objectId'> &
            Partial<AggregateBlockAttributes>
    ) => void
    label: ReactNode
    tooltip: string
}

export const AggregationConfiguration: FC<Props> = ({
    aggregationWidget: { objectId, aggregate },
    onChange,
    label,
    tooltip,
}) => {
    const onChangeAggregate = (newAggregateType: AggregateBlockAttributes['aggregate']['type']) => {
        if (newAggregateType === 'count') {
            onChange({ objectId, aggregate: { type: newAggregateType } })
            return
        }

        onChange({
            objectId,
            aggregate: {
                type: newAggregateType,
                field_sid:
                    (aggregate as ChartAggregationAverage | ChartAggregationSum).field_sid || '',
            },
        })
    }

    const onChangeAggregateField = (newAggregateFieldId: string) => {
        onChange({
            objectId,
            aggregate: {
                ...(aggregate as ChartAggregationAverage | ChartAggregationSum),
                field_sid: newAggregateFieldId,
            },
        })
    }

    return (
        <>
            <Box mb={-4}>
                <Flex
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    columnGap={1}
                >
                    <Text
                        variant="paletteSectionLabel"
                        as="label"
                        htmlFor="metric-aggregate"
                        display="block"
                    >
                        {label}
                    </Text>
                    <InfoTooltip label={tooltip} />
                </Flex>
                <CustomRadioGroup
                    selectedOption={aggregate.type}
                    options={AGGREGATION_TYPE_OPTIONS}
                    onChange={onChangeAggregate}
                />
            </Box>
            {(aggregate.type === 'sum' || aggregate.type === 'avg') && (
                <Box>
                    <Text
                        variant="paletteSectionLabel"
                        as="label"
                        htmlFor="metric-field"
                        display="block"
                    >
                        Field
                    </Text>
                    <FieldPicker
                        id="metric-field"
                        variant="admin"
                        objectId={objectId}
                        value={aggregate.field_sid}
                        onChange={onChangeAggregateField}
                        filter={(field) => fieldTypeAggregateFilter(aggregate.type, field)}
                        isDisabled={false}
                        isClearable={false}
                    />
                </Box>
            )}
        </>
    )
}

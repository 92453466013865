import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { throttle } from 'lodash'

import { ListHeaderPortalActionsStickyStyle } from 'features/views/ListView/ListHeader/ListHeaderPortal/ListHeaderPortal.css'
import { useListHeaderContext } from 'features/views/ListView/ListHeader/useListHeaderContext'

import { Box } from 'ui/components/Box'

const THROTTLE_RATE = 100

export function useListHeaderPortalState() {
    const {
        value,
        viewTitle,
        recordCount,
        view,
        isEmbedded,
        allowCreate,
        inlineFilterType,
        availableInlineFilterFields,
        clearInlineFilters,
    } = useListHeaderContext()

    const formattedRecordCount = formatRecordCount(recordCount)

    const supportsInlineSorting = !!view.options.inlineSorting
    const supportsQuickFilters =
        inlineFilterType === 'quick' && !!availableInlineFilterFields.length

    const supportsAdvancedFilters = inlineFilterType === 'advanced'
    const [advancedFiltersVisible, setAdvancedFiltersVisible] = useState(false)
    const toggleAdvancedFilters = useCallback(() => {
        setAdvancedFiltersVisible((prev) => !prev)
    }, [])

    // Add a classname to the actions wrapper when it's sticky.
    const actionsRef = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if (isEmbedded) return

        const scrollParent = document.querySelector('#contentWrapper')
        if (!scrollParent) return

        const scrollHandler = throttle(() => {
            const headerEl = actionsRef.current
            if (!headerEl) return

            const windowTopOffset = headerEl.getBoundingClientRect().top

            if (windowTopOffset === 0) {
                if (!headerEl.classList.contains(ListHeaderPortalActionsStickyStyle)) {
                    headerEl.classList.add(ListHeaderPortalActionsStickyStyle)
                }
            } else {
                headerEl.classList.remove(ListHeaderPortalActionsStickyStyle)
            }
        }, THROTTLE_RATE)

        scrollParent.addEventListener('scroll', scrollHandler)
        return () => {
            scrollParent.removeEventListener('scroll', scrollHandler)
        }
    }, [isEmbedded])

    // Clear filters when switching between inline filter types.
    useLayoutEffect(() => {
        setAdvancedFiltersVisible(false)
        clearInlineFilters()
    }, [clearInlineFilters, inlineFilterType])

    const title = value.title || viewTitle

    const subtitle = value.subtitle

    const eyebrow = (value as ListViewHeaderPortal).eyebrow

    const coverImage = (value as ListViewHeaderPortal).coverImageUrl

    const backgroundColor = getBackgroundColor(value as ListViewHeaderPortal)

    const headerStyle = (value as ListViewHeaderPortal).style

    const [showRecordCount, setShowRecordCount] = useState(true)

    const searchPlacement = (value as ListViewHeaderPortal).searchPlacement

    const textAlignment = (value as ListViewHeaderPortal).textAlignment

    return useMemo(
        () => ({
            actionsRef,
            title,
            subtitle,
            recordCount: formattedRecordCount,
            supportsInlineSorting,
            supportsQuickFilters,
            supportsAdvancedFilters,
            isEmbedded,
            allowCreate,
            advancedFiltersVisible,
            toggleAdvancedFilters,
            coverImage,
            backgroundColor,
            eyebrow,
            headerStyle,
            showRecordCount,
            setShowRecordCount,
            searchPlacement,
            textAlignment,
        }),
        [
            title,
            subtitle,
            formattedRecordCount,
            supportsInlineSorting,
            supportsQuickFilters,
            supportsAdvancedFilters,
            isEmbedded,
            allowCreate,
            advancedFiltersVisible,
            toggleAdvancedFilters,
            coverImage,
            backgroundColor,
            eyebrow,
            headerStyle,
            showRecordCount,
            searchPlacement,
            textAlignment,
        ]
    )
}

function formatRecordCount(count: number) {
    if (count === 0) return ''

    if (count === 1) {
        return `${count} record`
    }

    return `${count} records`
}

function getBackgroundColor(
    value: ListViewHeaderPortal
): React.ComponentPropsWithoutRef<typeof Box>['background'] {
    if (!value.backgroundColor) {
        return 'surface'
    }

    return `${value.backgroundColor.toLowerCase()}600`
}

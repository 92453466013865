import React, { forwardRef } from 'react'

import { Box } from 'ui/components/Box'
import { EndIndicatorShortcutStyle } from 'ui/components/Menu/Menu.css'
import { Body } from 'ui/components/Text'
import {
    KeyboardShortcutLabel,
    useKeyboardShortcutLabel,
} from 'ui/helpers/useKeyboardShortcutLabel'

import { SlotContentProps } from './types'

type DropdownEndIndicatorShortcutRef = HTMLDivElement

type DropdownEndIndicatorShortcutProps = React.ComponentPropsWithoutRef<typeof Box> &
    SlotContentProps & {
        label?: KeyboardShortcutLabel
    }

export const DropdownEndIndicatorShortcut = forwardRef<
    DropdownEndIndicatorShortcutRef,
    DropdownEndIndicatorShortcutProps
>(({ label, ...props }, ref) => {
    const labelValue = useKeyboardShortcutLabel(label)

    return (
        <Box {...props} ref={ref}>
            <Body size="m" weight="regular" className={EndIndicatorShortcutStyle}>
                {labelValue}
            </Body>
        </Box>
    )
})

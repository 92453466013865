import React, { useCallback, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import { DefaultValue } from 'v2/views/utils/useDefaultValuesForView'

import { Box, Flex, Link, Text } from 'v2/ui'

import { KanbanNewCardPlaceholder } from './KanbanNewCardPlaceholder'
import { MAX_CARDS } from './kanbanUtils'

import { KanbanColumnStyle } from './KanbanStyles.css'

type KanbanColumnProps = {
    children?: React.ReactNode[]
    statusKey: string
    statusValue: string
    label: string
    showDropZone?: boolean
    columnNumber: number
    columnColor: any
    reorderDisabled: boolean
    statusField: FieldDto
    object: ObjectDto
    relatedListField?: FieldDto
    relatedListFieldValue?: string
    defaultValues?: DefaultValue[]
}

export const KanbanColumn = ({
    children,
    statusKey,
    statusValue,
    label,
    showDropZone,
    columnNumber,
    columnColor,
    reorderDisabled,
    statusField,
    object,
    relatedListField,
    relatedListFieldValue,
    defaultValues,
    ...props
}: KanbanColumnProps) => {
    const [maxCards, setMaxCards] = useState(MAX_CARDS)

    const handleShowMoreClick = useCallback(
        (e) => {
            e.preventDefault()
            setMaxCards(maxCards + MAX_CARDS)
        },
        [maxCards]
    )
    return (
        <Box position="relative" display="contents">
            <Flex
                bg="kanban.header.bg"
                borderTop={`5px solid ${columnColor}`}
                height="60px"
                px={2}
                py={2}
                borderRadius="6px 6px 6px 6px"
                position="relative"
                gridRow="1"
                justifyContent="start"
                wrap="nowrap"
                boxShadow="0px 1px 4px 1px rgb(89 94 106 / 2%), 0px 5px 8px -5px rgb(89 94 106 / 7%);"
            >
                <div
                    style={{
                        marginLeft: '8px',
                        width: '8px',
                        height: '8px',
                        minWidth: '8px',
                        borderRadius: '100%',
                        backgroundColor: `${columnColor}`,
                    }}
                ></div>
                <Text
                    variant="kanbanColumnHeader"
                    align="center"
                    verticalAlign="middle"
                    wordBreak="break-word"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    color="userInterface.neutral.900"
                    ml="6px"
                    fontSize={200}
                    fontWeight={600}
                >
                    {label}
                </Text>
                {children && children.length > 0 && (
                    <Text
                        align="center"
                        verticalAlign="middle"
                        ml={1}
                        px={1}
                        display="inline-block"
                        bg="userInterface.neutral.400"
                        borderRadius="4px 4px 4px 4px"
                        lineHeight="21px"
                        height="21px"
                        fontSize={100}
                        fontWeight={600}
                    >
                        {children.length}
                    </Text>
                )}
            </Flex>
            <Droppable
                droppableId={reorderDisabled ? statusKey + '_source' : statusKey}
                isDropDisabled={reorderDisabled}
            >
                {(provided, snapshot) => (
                    <Box
                        pt={3}
                        pb="100px"
                        px={0}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        transition="background .2s, min-height .2s"
                        bg={snapshot.isDraggingOver ? 'grey.200' : ''}
                        minHeight={snapshot.isDraggingOver ? '100px' : '40px'}
                        className={KanbanColumnStyle}
                        {...props}
                    >
                        {children && children.slice(0, maxCards)}

                        {children && children.length > maxCards && (
                            <Link
                                mt={4}
                                display="block"
                                opacity={0.7}
                                fontSize="sm"
                                textAlign="center"
                                onClick={handleShowMoreClick}
                            >
                                and {children.length - maxCards} more...
                            </Link>
                        )}
                        <KanbanNewCardPlaceholder
                            statusValue={statusValue}
                            statusField={statusField}
                            object={object}
                            relatedListField={relatedListField}
                            relatedListFieldValue={relatedListFieldValue}
                            defaultValues={defaultValues}
                        />
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
            {reorderDisabled && (
                <Droppable droppableId={statusKey}>
                    {(provided, snapshot) => (
                        <Box
                            position="absolute"
                            gridArea={`1 / ${columnNumber} / 3 / ${columnNumber + 1}`}
                            width="100%"
                            height="100%"
                            // bg={'rgba(0, 255, 0, 0.2)'}
                            display={showDropZone ? 'block' : 'none'}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            transition="background .2s, min-height .2s"
                            bg={
                                snapshot.isDraggingOver
                                    ? 'rgba(0, 255, 0, 0.2)'
                                    : 'rgba(0, 255, 0, 0.05)'
                            }
                            minHeight={snapshot.isDraggingOver ? '100px' : '40px'}
                            zIndex={2}
                            {...props}
                        >
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            )}
        </Box>
    )
}

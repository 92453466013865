import React from 'react'

import { JSONContent } from '@tiptap/react'

import { TipTapEditorBase } from 'features/tiptap/TipTapEditorBase'

type ExecutionHistoryJSONPreviewProps = {
    value: unknown
}

export const ExecutionHistoryJSONPreview: React.FC<ExecutionHistoryJSONPreviewProps> = ({
    value,
}) => {
    const convertedValue: JSONContent = {
        type: 'doc',
        content: [
            {
                type: 'codeBlock',
                attrs: {},
                content: [
                    {
                        type: 'text',
                        text: JSON.stringify(value, null, 4),
                    },
                ],
            },
        ],
    }

    return <TipTapEditorBase content={convertedValue} readOnly={true} />
}

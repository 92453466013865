// @ts-strict-ignore
import React, { useCallback, useState } from 'react'

import { useDuplicateWorkflow } from 'data/hooks/workflows/workflows'

import { Icon as V2Icon, Modal, Text as V2Text } from 'v2/ui'
import { useToast } from 'v2/ui/components/useToast'
import { DuplicateLayout as DuplicateLayoutIcon } from 'v2/ui/svgs'
import useModalToggle from 'v2/ui/utils/useModalToggle'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'

import { USE_DUPLICATE_WORKFLOW_MODAY_KEY } from './duplicateWorkflowModalConstants'

type DuplicateWorkflowModalProps = {}

export const DuplicateWorkflowModal: React.FC<DuplicateWorkflowModalProps> = () => {
    const modal = useModalToggle<{
        workflow: WorkflowDto
        onSuccess?: (workflow: WorkflowDto) => void
    }>(USE_DUPLICATE_WORKFLOW_MODAY_KEY)
    const { isOpen, toggle, data: modalParams } = modal

    const handleClose = () => {
        toggle()
    }

    const handleSuccess = (workflow: WorkflowDto) => {
        handleClose()
        modalParams?.onSuccess?.(workflow)
    }

    return (
        <Modal
            isOpen={isOpen}
            zIndex={9999}
            onClose={handleClose}
            noDividers
            size="370px"
            body={<DuplicateWorkflow workflow={modalParams?.workflow} onSuccess={handleSuccess} />}
            autoFocus={false}
        />
    )
}

type DuplicateWorkflowProps = {
    workflow: WorkflowDto
    onSuccess: (workflow: WorkflowDto) => void
}

const DuplicateWorkflow: React.FC<DuplicateWorkflowProps> = ({ workflow, onSuccess }) => {
    const toast = useToast()

    const { mutateAsync: duplicateWorkflow } = useDuplicateWorkflow({
        onSuccess: () => {
            toast({ title: 'Workflow duplicated and opened successfully.', status: 'success' })
        },
        onError: () => {
            toast({
                title: 'There was a problem duplicating your workflow. Please try again later.',
                status: 'error',
            })
        },
    })

    const [newName, setNewName] = useState('')

    const isSubmitDisabled = newName.length < 1

    const handleSubmit = async (e?: React.FormEvent) => {
        e?.preventDefault()

        if (isSubmitDisabled) return

        const newFlow = await duplicateWorkflow({
            workflow,
            name: newName,
        })

        onSuccess(newFlow)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            handleSubmit()
        }
    }

    const focusOnMount = useCallback((node: HTMLInputElement | null) => {
        if (node === null) return

        queueMicrotask(() => {
            node.focus()
        })
    }, [])

    return (
        <Box>
            <V2Icon svg={<DuplicateLayoutIcon />} size="56px" ml="15px" />
            <Box textAlign="center" mb="l">
                <V2Text as="label" htmlFor="dup-flow-input" variant="modalTitle" fontWeight="bold">
                    Enter new workflow name
                </V2Text>
            </Box>
            <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                <Input
                    ref={focusOnMount}
                    id="dup-flow-input"
                    placeholder="Workflow name"
                    onChange={(e) => setNewName(e.target.value)}
                    value={newName}
                    maxLength={30}
                    autoFocus
                />
                <Button type="submit" size="m" full mt="m" disabled={isSubmitDisabled}>
                    <Box full>Duplicate</Box>
                </Button>
            </form>
        </Box>
    )
}

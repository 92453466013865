// @ts-strict-ignore
/* Code Quality: Not audited */

import React from 'react'

import { useObject } from 'data/hooks/objects'
import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { isDisabled } from 'utils/fieldUtils'

import { Dropdown } from 'v2/ui'

const useObjectForFieldOptions = (
    object: ObjectDto | undefined,
    onChange: (value: string) => void,
    filter?: (field: any) => boolean,
    provideOptionLabel?: (object: any, record: any) => string | JSX.Element,
    autoSelectSingleOption?: boolean,
    autoSelectAnyOption?: boolean,
    value?: any
) => {
    const sortByFieldsOrder = useSortingByFieldsOrder(object)
    let fields = object?.fields?.filter((field) => !isDisabled(field))
    if (!fields) return []

    if (filter) {
        fields = fields.filter(filter)
    }
    const options = sortByFieldsOrder(fields).map((record) => ({
        label: provideOptionLabel ? provideOptionLabel(record, object) : record.label,
        value: record._sid,
    }))

    if (!value) {
        if (
            (autoSelectSingleOption && options.length === 1) ||
            (autoSelectAnyOption && options.length > 0)
        ) {
            onChange(options[0].value)
        }
    }

    return options
}

const getField = (fieldId: string, object?: ObjectDto) =>
    object?.fields?.find(({ _sid }) => fieldId === _sid)

type FieldPickerProps = React.ComponentPropsWithoutRef<typeof Dropdown> & {
    /**
     * Object to get the fields from
     */
    objectId: string
    isDisabled: boolean
    onChange: (value: string) => void
    placeholder?: string
    /**
     * Used to filter on fields
     */
    filter?: (field: any) => boolean
    value?: string
    style?: any
    id?: string
    returnField?: boolean
    returnObject?: any
    noOptionsPlaceholder?: string
    autoSelectSingleOption?: boolean
    autoSelectAnyOption?: boolean
    /**
     * Returns the dropdown options
     */
    provideOptionLabel?: (object: any, record: any) => string | JSX.Element
}

const FieldPicker: React.FC<FieldPickerProps> = ({
    objectId,
    isDisabled,
    onChange,
    placeholder,
    filter,
    value,
    style,
    id,
    returnField,
    returnObject,
    provideOptionLabel,
    noOptionsPlaceholder,
    autoSelectSingleOption,
    autoSelectAnyOption,
    ...props
}: FieldPickerProps) => {
    const { object } = useObject(objectId)

    const options = useObjectForFieldOptions(
        object,
        onChange,
        filter,
        provideOptionLabel,
        autoSelectSingleOption,
        autoSelectAnyOption,
        value
    )

    const hasPreselectedOption =
        (autoSelectSingleOption && options.length === 1) ||
        (autoSelectAnyOption && options.length > 0)

    // Instead of showing nothing when we have no objectId (yet)
    // show an empty dropdown with no options
    if (!objectId) {
        return (
            <Dropdown
                isDisabled={isDisabled}
                placeholder={placeholder || 'Select data source'}
                clearable={false}
                options={[]}
                value={value}
                style={style}
                adminTheme
                usePortal
                id={id}
                {...props}
            />
        )
    }

    return (
        <Dropdown
            disabled={!options.length || isDisabled}
            onChange={(value) => {
                if (returnField) {
                    const fieldId = returnObject ? value.value : value
                    const field = getField(fieldId, object)

                    return onChange(field)
                } else {
                    return onChange(value)
                }
            }}
            placeholder={
                options.length
                    ? placeholder || 'Select data source'
                    : noOptionsPlaceholder || 'No options available'
            }
            isClearable={!hasPreselectedOption}
            options={options}
            value={value}
            style={!options.length || isDisabled ? { ...style, backgroundColor: '#F5F6F6' } : style}
            adminTheme
            usePortal
            id={id}
            {...props}
        />
    )
}

export default FieldPicker

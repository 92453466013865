import React, { forwardRef } from 'react'

import * as Parts from './Radio.parts'

type RadioCardGroupRef = HTMLDivElement

type RadioCardGroupProps = React.ComponentPropsWithoutRef<typeof Parts.RadioCardsRoot> & {}

export const RadioCardGroup = forwardRef<RadioCardGroupRef, RadioCardGroupProps>(
    ({ children, ...props }, ref) => {
        return (
            <Parts.RadioCardsRoot {...props} ref={ref}>
                {children}
            </Parts.RadioCardsRoot>
        )
    }
)

import { useCallback, useMemo } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'

import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { truncateText } from 'ui/helpers/utilities'

type FieldOption = {
    label: string
    value: string
    icon?: IconNameVariantType
}

type UseRecommendedFieldsSelectorStateOptions = {
    fields: FieldDto[]
    value: string[]
    onChange: (value: string[]) => void
    maxLength?: number
}

export function useRecommendedFieldsSelectorState(
    options: UseRecommendedFieldsSelectorStateOptions
) {
    const { fields, value, onChange, maxLength } = options

    const onCheckedChange = useCallback(
        (fieldSid: string, checked: boolean) => {
            if (checked) {
                onChange([...value, fieldSid])
            } else {
                onChange(value.filter((sid) => sid !== fieldSid))
            }
        },
        [onChange, value]
    )

    const fieldOptions: FieldOption[] = useMemo(() => {
        return fields.map((field) => {
            const label = maxLength ? truncateText(field.label, maxLength) : field.label
            const fieldIcon = getFieldIcon(field)

            return {
                label,
                value: field._sid,
                icon: fieldIcon?.type === 'lucide' ? fieldIcon : undefined,
            }
        }, [])
    }, [fields, maxLength])

    const valueSet = useMemo(() => new Set(value), [value])

    const selectedOptions = useMemo(
        () => fieldOptions.filter((option) => valueSet.has(option.value)),
        [fieldOptions, valueSet]
    )

    return { onCheckedChange, fieldOptions, valueSet, selectedOptions }
}

import { getUrl } from 'app/UrlService'

export const getCreateViewUrl = (
    createView: ViewDto,
    pages: PageDto[],
    object: ObjectDto | undefined,
    queryParams = '',
    selectedStack?: StackDto | null,
    workspaceAccount?: Account | null
) => {
    const viewPage = pages?.find((page) => page.options?.view_id === createView._sid)
    if (!object || !viewPage) return null
    return getUrl(
        `${object.url}${viewPage.url}${queryParams || ''}`,
        selectedStack,
        workspaceAccount
    )
}

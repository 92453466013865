import React, { forwardRef } from 'react'

import { Box } from 'ui/components/Box'
import { EndIndicatorTagStyle } from 'ui/components/Menu/Menu.css'
import { Tag } from 'ui/components/Tag'

import { SlotContentProps } from './types'

type InheritedTagProps = Pick<
    React.ComponentPropsWithoutRef<typeof Tag>,
    'color' | 'startIcon' | 'shade' | 'type' | 'shape' | 'size'
>

type DropdownEndIndicatorTagRef = HTMLDivElement

type DropdownEndIndicatorTagProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'color'> &
    InheritedTagProps &
    SlotContentProps & {
        label?: string
    }

export const DropdownEndIndicatorTag = forwardRef<
    DropdownEndIndicatorTagRef,
    DropdownEndIndicatorTagProps
>(
    (
        {
            color = 'Theme',
            startIcon,
            shade = 'light',
            type = 'solid',
            label,
            shape = 'rectangle',
            size = '2xs',
            ...props
        },
        ref
    ) => {
        return (
            <Box {...props} ref={ref}>
                <Tag
                    color={color}
                    startIcon={startIcon}
                    shade={shade}
                    type={type}
                    shape={shape}
                    size={size}
                    disabled={props.disabled}
                    className={EndIndicatorTagStyle}
                >
                    {label}
                </Tag>
            </Box>
        )
    }
)

import React from 'react'
import { DropTarget } from 'react-dnd'

import styled from '@emotion/styled'
import classNames from 'classnames'

import types from './types'

const dropTarget = {
    drop(props, monitor) {
        if (monitor.didDrop()) return
        const payload = props.payload
        return payload
    },
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver({ shallow: true }),
    }
}

export const Wrapper = styled.div`
    background: ${({ isOver }) => (isOver ? '#00f' : 'none')};
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    margin: 5px;
    flex-grow: 1;
`

export function Droppable({
    connectDropTarget,
    isOver,
    children,
    style,
    className,
    isOverClassName,
}) {
    return (
        <Wrapper
            style={style}
            isOver={isOver}
            className={classNames(className, isOver ? isOverClassName : undefined)}
            ref={connectDropTarget}
        >
            {children}
        </Wrapper>
    )
}

export const DropZone = DropTarget(types.BLOCK, dropTarget, collect)(Droppable)

import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { DataGridCellRendererComponent } from './types'

type AddNewRecordCellRendererProps = {
    addNewRecord: () => void
}
export const AddNewRecordCellRenderer: DataGridCellRendererComponent<
    AddNewRecordCellRendererProps
> = ({ addNewRecord }) => {
    return (
        <Box flex center height="full" width="full">
            <Button
                type="button"
                variant="ghost"
                startIcon={{ name: 'Plus' }}
                width="full"
                height="full"
                style={{ borderRadius: 0, border: 'none', justifyContent: 'flex-start' }}
                px="xl"
                py="m"
                color="textWeak"
                onClick={addNewRecord}
            >
                Add new record
            </Button>
        </Box>
    )
}

import { JSONContent } from '@tiptap/react'
import isEmpty from 'lodash/isEmpty'

import { TaskDescription } from 'data/hooks/tasks/types'

export function isDescriptionEmpty(description: TaskDescription): boolean {
    if (!description) return true

    if (typeof description === 'string') {
        return description.trim().length < 1
    }

    return isEmpty(description)
}

export function getDescriptionPreview(description: TaskDescription): string {
    if (!description) return ''

    let descriptionText = ''
    if (typeof description === 'string') {
        descriptionText = description.trim()
    } else if (description.plainTextContent) {
        descriptionText = description.plainTextContent.trim()
    }

    return descriptionText.split('\n').join(' ')
}

export function getDescriptionEditorValue(description: TaskDescription): JSONContent | null {
    if (!description) return null

    if (typeof description === 'string') {
        const content = description.split('\n').map((text) => {
            if (text) {
                return {
                    type: 'paragraph',
                    content: [
                        {
                            type: 'text',
                            text,
                        },
                    ],
                }
            }

            return {
                type: 'paragraph',
            }
        })

        return {
            type: 'doc',
            content,
        }
    }

    return description.content ?? null
}

import { useMemo } from 'react'

import { useActionsFromObjectId } from 'features/actions/helpers'
import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import { IconNameVariantType } from 'ui/components/Icon/Icon'

export type FieldValue = string | '_record_stats'

type UseListViewControlFieldInputStateOptions = {
    objectSid: string
    fields: FieldDto[]
    value?: FieldValue
    showActions?: boolean
}

export type Option = {
    label: string
    value: string
    icon?: IconNameVariantType
}

export function useListViewControlFieldInputState(
    options: UseListViewControlFieldInputStateOptions
) {
    const { objectSid, fields = [], value, showActions } = options

    const { data: actions = [] } = useActionsFromObjectId(objectSid)

    const selectedField = fields.find((field) => field._sid === value)

    const usersObject = useStackerUsersObject()
    const linkObjectSid =
        ['lookup', 'multi_lookup'].includes(selectedField?.type ?? '') &&
        selectedField?.link_target_object_id
    const isUsersObjectLink = linkObjectSid === usersObject?._sid

    const dynamicOptions: Option[] = useMemo(
        () => [
            { label: 'Record stats', value: '_record_stats', icon: { name: 'MessageSquareText' } },
        ],
        []
    )
    const fieldOptions: Option[] = useMemo(
        () =>
            fields.map((field) => {
                return {
                    label: field.label,
                    value: field._sid,
                    icon: getFieldIcon(field),
                }
            }),
        [fields]
    )
    const actionOptions: Option[] = useMemo(() => {
        if (!showActions) return []

        return actions.map((action) => {
            return {
                label: action.name,
                value: action._sid,
            }
        })
    }, [actions, showActions])

    return {
        selectedField,
        isUsersObjectLink,
        dynamicOptions,
        fieldOptions,
        actionOptions,
    }
}

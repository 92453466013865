import { Editor } from '@tiptap/core'
import { Range } from '@tiptap/react'

import { updateRecordQuery } from 'data/hooks/records/updateRecordQuery'

type RecordSearchResult = {
    _sid: string
    url: string
    _stack_id?: string
    _object_id?: string
    _primary?: string
}

export function insertRecordLink(editor: Editor, range: Range, item: RecordSearchResult) {
    // increase range.to by one when the next node is of type "text"
    // and starts with a space character
    const nodeAfter = editor.view.state.selection.$to.nodeAfter
    const overrideSpace = nodeAfter?.text?.startsWith(' ')

    if (overrideSpace) {
        range.to += 1
    }
    // drop this record info into the record cache incase it isn't already
    // there. This ensures that the record link plugin will be able to render
    // it straight away without hitting the server
    updateRecordQuery(
        [
            {
                _sid: item._sid,
                _object_id: item._object_id!,
                _primary: item._primary,
                _partial: true,
            },
        ],
        false,
        undefined
    )

    queueMicrotask(() => {
        editor
            .chain()
            .focus()
            .insertContentAt(range, [
                {
                    type: 'recordLink',
                    attrs: {
                        id: item._sid,
                        stack_id: item._stack_id,
                        url: item.url,
                    },
                },
                { type: 'text', text: ' ' },
            ])
            .run()

        window.getSelection()?.collapseToEnd()
    })
}

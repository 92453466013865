import React from 'react'

import { Button } from 'ui/components/Button'

type WorkflowCreateButtonProps = {
    onClick?: () => void
}

export const WorkflowCreateButton: React.FC<WorkflowCreateButtonProps> = ({ onClick }) => {
    return (
        <Button type="button" startIcon={{ name: 'Plus' }} size="s" onClick={onClick}>
            New workflow
        </Button>
    )
}

import { useCallback, useMemo, useState } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

export function useLayoutEditorSaveButtonState() {
    const { isViewDirty, commands } = useLayoutEditorContext()

    const [isSaving, setIsSaving] = useState(false)

    const isSaveable = isViewDirty
    let label = isSaveable ? 'Save changes' : 'Saved'
    if (isSaving) {
        label = 'Saving...'
    }

    const onSaveClick = useCallback(async () => {
        setIsSaving(true)
        await commands.saveViewChanges()
        setIsSaving(false)
    }, [commands])

    return useMemo(
        () => ({
            label,
            isSaveable,
            onSaveClick,
        }),
        [label, isSaveable, onSaveClick]
    )
}

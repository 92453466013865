import { formatDate } from 'utils/date/dateUtils'

export function formatCreatedDate(value: string) {
    const { dateValue } = formatDate(value, 'UTC', false)
    return dateValue?.local?.().format?.('ll')
}

export function formatCreatedByLabel(source: string, created_at?: string): string {
    let label = `Created by ${source}`

    if (created_at) {
        label += ` on ${formatCreatedDate(created_at)}`
    }

    return label
}

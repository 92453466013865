import React, { CSSProperties, FC } from 'react'

import { ButtonProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAppContext } from 'app/useAppContext'
import { sortFieldsByOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { AddFilterButton } from 'features/records/components/RecordFilters/AddFilterButton'
import { InlineLabel } from 'features/records/components/RecordFilters/InlineLabel'
import { RecordFiltersProvider } from 'features/records/components/RecordFilters/provider/RecordFiltersProvider'

import { ConditionalWrapper } from 'v2/ui'

import { FieldsFilters } from './FieldsFilters'
import { SecurityInfoMessage } from './SecurityInfoMessage'

const FieldsFiltersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`

type Props = {
    object: ObjectDto | undefined
    fields: FieldDto[] | undefined
    value: Filter[] | undefined
    onChange: (filters: Filter[]) => void
    hideTheRecordFilter?: boolean
    hideCurrentUserOption?: boolean
    showRoleFilter?: boolean
    hideAddButton?: boolean
    overhangOperators?: string[]
    wrapItems?: boolean
    itemStyle?: CSSProperties
    buttonLabel?: string
    prefix?: string
    color?: string
    addButtonVariant?: string
    withHeader?: boolean
    includeIncompleteFilters?: boolean
    closeOnOuterAction?: boolean
    place?: string
    usePortal?: boolean
    securityInfoMessage?: string
    showRelativeDateFilters?: boolean
    contextRecordObject?: ObjectDto
    buttonProps?: ButtonProps
    customButtonRender?: (props: { onClick: React.MouseEventHandler }) => React.ReactNode
}

export const ObjectFieldsFilterV4: FC<Props> = ({
    object,
    fields,
    hideTheRecordFilter,
    hideCurrentUserOption,
    showRoleFilter,
    onChange,
    value = [],
    hideAddButton,
    overhangOperators,
    wrapItems = false,
    itemStyle = {},
    buttonLabel,
    prefix,
    color = 'gray',
    addButtonVariant = 'adminTertiary',
    withHeader = false,
    includeIncompleteFilters = false,
    closeOnOuterAction = false,
    place,
    usePortal = true,
    securityInfoMessage,
    showRelativeDateFilters = false,
    contextRecordObject,
    buttonProps = {},
    customButtonRender,
}) => {
    const { selectedStack: stack } = useAppContext()

    let filters = value

    const shouldShowHeader = Boolean(withHeader && stack)

    return (
        <RecordFiltersProvider
            initialFilters={filters}
            object={object}
            includeIncompleteFilters={includeIncompleteFilters}
            onChange={onChange}
        >
            <SecurityInfoMessage
                message={securityInfoMessage}
                shouldShowHeader={shouldShowHeader}
            />
            <ConditionalWrapper
                condition={wrapItems}
                wrapper={(children) => <FieldsFiltersWrapper>{children}</FieldsFiltersWrapper>}
            >
                {prefix && filters.length === 0 && <InlineLabel mb="5px">{prefix}</InlineLabel>}
                <FieldsFilters
                    color={color}
                    place={place}
                    prefix={prefix}
                    usePortal={usePortal}
                    wrapItems={wrapItems}
                    itemStyle={itemStyle}
                    overhangOperators={overhangOperators}
                    closeOnOuterAction={closeOnOuterAction}
                    hideCurrentUserOption={hideCurrentUserOption}
                    showRelativeDateFilters={showRelativeDateFilters}
                    contextRecordObject={contextRecordObject}
                />
            </ConditionalWrapper>
            {!hideAddButton && (
                <AddFilterButton
                    object={object}
                    fields={sortFieldsByOrder(fields, object)}
                    hideTheRecordFilter={hideTheRecordFilter}
                    showRoleFilter={showRoleFilter}
                    hideCurrentUserOption={hideCurrentUserOption}
                    alignSelf="start"
                    label={buttonLabel}
                    addButtonVariant={addButtonVariant}
                    usePortal={usePortal}
                    contextRecordObject={contextRecordObject}
                    customRender={customButtonRender}
                    {...buttonProps}
                />
            )}
        </RecordFiltersProvider>
    )
}

import React from 'react'

import { TabsContext } from './tabsContext'

export type TabsProviderProps = {
    children: React.ReactNode
    value: string
    setValue: (value: string) => void
    type: 'card' | 'underlined'
}

export const TabsContextProvider: React.FC<TabsProviderProps> = ({
    value,
    setValue,
    type,
    children,
}) => {
    return (
        <TabsContext.Provider
            value={{
                value,
                setValue,
                type,
            }}
        >
            {children}
        </TabsContext.Provider>
    )
}
